import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const CLOUDTRAIL_DUPLICATE_TRAILS_PROPERTIES = {
  CLOUD_TRAIL: {
    label: 'Trails info',
    getterFunction: (recommendation) => recommendation?.recData?.trails,
    isTable: true,
    columns: [
      {
        id: 'trail_id',
        label: 'Trail ID',
      },
      {
        id: 'trail_name',
        label: 'Trail Name',
      },
    ],
  },
  TRAILS: {
    label: 'Trails',
    getterFunction: (recommendation) => recommendation?.recData?.trails?.length,
  },
};

const CloudtrailDuplicateTrails = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      comment:
        // eslint-disable-next-line max-len
        'This operation must be called from the region in which the trail was created. DeleteTrail cannot be called on the shadow trails (replicated trails in other regions) of a trail that is enabled in all regions.',
      instructions: [
        {
          actionText: '',
          actionCommand: `aws cloudtrail delete-trail --TRAIL_NAME Trail1`,
        },
      ],
    },
  ];
  const description =
    // eslint-disable-next-line max-len
    'Management events that are delivered for both member account and management account will occur duplicated charges.\n' +
    'We therefore suggest to terminate the trail associated with the member account.';

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={CLOUDTRAIL_DUPLICATE_TRAILS_PROPERTIES}
    />
  );
};

CloudtrailDuplicateTrails.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default CloudtrailDuplicateTrails;
