import React from 'react';
import { Pie, PieChart } from 'recharts';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

const RadialChart = ({ percentages, innerRadiusText }) => {
  const data = [
    { value: percentages, fill: '#38ae53' },
    {
      value: 100 - percentages,
      fill: '#cce5fe',
    },
  ];
  return (
    <div className="dashboard">
      <div className="dashboard__stat">
        <div className="dashboard__stat-chart">
          <PieChart height={120} width={120}>
            <Pie data={data} dataKey="value" cx={55} cy={55} innerRadius={50} outerRadius={60} />
          </PieChart>
          <Tooltip title="Monthly Saving %">
            <p className="dashboard__stat-label" style={{ color: '#38ae53', fontSize: '24px' }}>
              {innerRadiusText}
            </p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default RadialChart;

RadialChart.propTypes = {
  percentages: PropTypes.number.isRequired,
  innerRadiusText: PropTypes.string.isRequired,
};
