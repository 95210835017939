/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import styles from './Input.module.scss';

const Input = React.forwardRef(
  (
    {
      disabled,
      isSearch,
      clearable,
      searchComponent,
      value,
      icon,
      onChange,
      onBlur,
      accept,
      onFocus,
      onKeyDown,
      isAutoFocus,
      automationId,
      isInvalid,
      invalidComponent,
      placeholder,
      onKeyPress,
      className,
      type,
      min,
      max,
      id,
      name,
      readOnly,
      overrideStyles,
      autoComplete,
    },
    ref,
  ) => {
    const classes = `${styles.andtInput} ${isInvalid ? styles.invalid : ''} ${className} ${
      isSearch ? styles.search : ''
    }`;

    const handleFocus = (val) => {
      segmentEvent({
        type: 'focus',
        target: automationId || name || 'Input',
        automationId,
      });
      if (onFocus) {
        onFocus(val);
      }
    };

    const renderInput = () => (
      <React.Fragment>
        <input
          ref={ref}
          accept={accept}
          className={classes}
          placeholder={placeholder}
          disabled={disabled}
          value={type === 'file' ? undefined : value}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autoComplete}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          autoFocus={isAutoFocus}
          // eslint-disable-next-line react/no-unknown-property
          automation-id={automationId}
          type={type}
          min={min}
          max={max}
          id={id}
          name={name}
          readOnly={readOnly}
          style={{ ...overrideStyles, ...(type === 'file' ? { display: 'none' } : {}) }}
        />
        {isSearch ? <div className={styles.searchIcon}>{searchComponent}</div> : null}
        {isInvalid && <p className={styles.error}>{invalidComponent}</p>}
      </React.Fragment>
    );

    if (type === 'file') {
      return (
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor={id}
            style={{ ...overrideStyles }}
            data-placeholder={!value}
            className={classes}
            aria-disabled={disabled}
          >
            {value && clearable ? (
              <Chip
                label={value}
                onDelete={(e) => {
                  e.stopPropagation();
                  onChange(null);
                }}
              />
            ) : (
              value || placeholder
            )}
            <span className={styles.uploadButton}>
              {icon}
              Browse
            </span>
          </label>
          {renderInput()}
        </>
      );
    }

    return renderInput();
  },
);

Input.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  isAutoFocus: PropTypes.bool,
  automationId: PropTypes.string,
  icon: PropTypes.object,
  searchComponent: PropTypes.object,
  isInvalid: PropTypes.bool,
  invalidComponent: PropTypes.node,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  accept: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  isSearch: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  overrideStyles: PropTypes.object,
};

Input.defaultProps = {
  disabled: false,
  value: '',
  id: undefined,
  onChange: null,
  icon: null,
  onBlur: null,
  onFocus: null,
  onKeyDown: null,
  onKeyPress: null,
  isAutoFocus: false,
  isSearch: false,
  automationId: '',
  isInvalid: false,
  placeholder: '',
  accept: '',
  searchComponent: null,
  invalidComponent: null,
  type: 'text',
  min: null,
  max: null,
  autoComplete: undefined,
  name: '',
  className: '',
  readOnly: false,
  clearable: false,
  overrideStyles: {},
};

export default Input;
