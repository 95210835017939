import React from 'react';
import PropTypes from 'prop-types';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import { Col } from 'reactstrap';

const MonthPicker = (props) => (
  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
    <div className="filter-bar-inner-col-wrapper-large">
      <h5 className="filter-bar-inner-col-title">Period (month)</h5>
      <OneChoiceFieldFilter
        type="selectedDate"
        value={props.selectedDate}
        options={props.monthOptions}
        handleChange={props.handleMonthChange}
        isClearable={false}
      />
    </div>
  </Col>
);

MonthPicker.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  monthOptions: PropTypes.array.isRequired,
  handleMonthChange: PropTypes.func.isRequired,
};

export default MonthPicker;
