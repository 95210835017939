import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { RolesProvider } from './contexts/rolesContext';
import RolesHeader from './RolesHeader';
import RolesTable from './RolesTable';
import { GET_USERS_QUERY_PARAMS, PAGINATION_DIRECTION } from '../consts';

import styles from './Roles.module.scss';

const Roles = () => {
  const [paginationToken, setPaginationToken] = useState(null);
  const [search, setSearch] = useState();

  const { NewTableWrapper } = useTable();

  const { fetchRoles } = useRoles();
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_USERS_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setSearch(searchParam);
    }
  }, []);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.roles}>
      <RolesProvider>
        <NewTableWrapper>
          <RolesHeader total={data?.total} pageChanged={pageChanged} search={search} setSearch={setSearch} />
          <RolesTable isLoading={isLoading} roles={data?.roles} />
        </NewTableWrapper>
      </RolesProvider>
    </div>
  );
};

export default Roles;
