import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const TableHeaderContext = createContext();

export const TableHeaderProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isExpired, setIsExpired] = useState(true);

  return (
    <TableHeaderContext.Provider
      value={{
        filtersOpen,
        setFiltersOpen,
        isExpanded,
        setIsExpanded,
        setIsCreateMode,
        setSearchText,
        searchText,
        isCreateMode,
        expandedRowIds,
        setExpandedRowIds,
        isActive,
        setIsActive,
        isExpired,
        setIsExpired,
      }}
    >
      {children}
    </TableHeaderContext.Provider>
  );
};

export const withTableHeaderProvider = (Component) => (props) =>
  (
    <TableHeaderProvider>
      <Component {...props} />
    </TableHeaderProvider>
  );

TableHeaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function withTableHeaderConsumer(Component) {
  return function TableHeaderConsumer({ ...props }) {
    return <TableHeaderContext.Consumer>{(data) => <Component {...data} {...props} />}</TableHeaderContext.Consumer>;
  };
}

export const useTableHeaderContext = () => useContext(TableHeaderContext);
