import React, { PureComponent } from 'react';
import { Row } from 'reactstrap';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import CustomTooltip from 'shared/components/CustomTooltip';
import ChartKeysFilter from 'shared/components/ChartKeysFilter/ChartKeysFilter';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const dataKeys = (data) => {
  const result = data.reduce((acc, { date, ...rest }) => {
    Object.keys(rest).forEach((key) => {
      if (!acc[key]) {
        acc[key] = { name: key, cost: 0 };
      }
      acc[key].cost += Number(rest[key]);
    });
    return acc;
  }, {});
  let arrOfResult = Object.values(result);
  arrOfResult = arrOfResult.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost));
  return arrOfResult;
};

const getColorByKeyName = (keyName) => {
  let keyColor = '#FAC6C6'; // base is on-demand
  switch (keyName) {
    case 'Spot':
      keyColor = '#57B3F9';
      break;
    case 'Reserved':
      keyColor = '#86b582';
      break;
    case 'Savings Plans':
      keyColor = '#abd6c4';
      break;

    default:
      break;
  }
  return keyColor;
};

class CostBarLineChartToggler extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    dataKey: PropTypes.string,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };
  static defaultProps = {
    dataKey: 'date',
  };

  constructor(props) {
    super(props);
    this.state = {
      isLineChart: false,
      currKey: '',
      favourites: [],
      filteredKeys: [],
      filteredDataKeys: [],
    };
  }
  componentDidMount() {
    this.initLegendKeys();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { data: prevData } = prevProps;
    if (data.length !== prevData.length && data.length > 0) {
      this.initLegendKeys();
    }
  }

  initLegendKeys = () => {
    const { data, dataKey } = this.props;
    if (!data || !data.length) {
      return;
    }
    const filteredKeys = [...new Set(data.reduce((acc, row) => [...acc, ...Object.keys(row)], []))].filter(
      (k) => k !== dataKey,
    );
    const favourites = new Array(filteredKeys.length).fill(0).map((_, ind) => ind);
    this.setState({
      favourites,
      filteredKeys,
      filteredDataKeys: filteredKeys.map((key, ind) => ({
        id: favourites[ind],
        name: key,
      })),
    });
  };

  toggleCharts = () => {
    const { isLineChart } = this.state;
    this.setState({
      isLineChart: !isLineChart,
    });
  };

  renderBar = (keys) => {
    const { filteredKeys } = this.state;
    return keys
      .filter(({ name }) => filteredKeys.includes(name))
      .map((key) => {
        const customMouseOver = () => {
          if (key.name !== this.state.currKey) {
            this.setState({ currKey: key.name });
          }
        };
        return (
          <Bar
            onMouseMove={customMouseOver}
            key={key.name}
            barSize={20}
            dataKey={key.name}
            stackId="a"
            fill={getColorByKeyName(key.name)}
          />
        );
      });
  };

  render() {
    const { data, dataKey, numStrAbriviaionByDisplayMetric, height } = this.props;
    const { filteredDataKeys, favourites, filteredKeys, currKey } = this.state;
    return (
      <>
        <ResponsiveContainer height={height || 300}>
          <BarChart
            width={1000}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={dataKey}
              tickFormatter={(value) => {
                if (value.toLowerCase().indexOf('w') > -1) {
                  return value;
                }
                return createDateDisplayStr(value, 'month');
              }}
            />
            <YAxis
              label={{
                value: 'Running hours',
                angle: -90,
                position: 'left',
              }}
              type="number"
              tickFormatter={(value) => numStrAbriviaionByDisplayMetric(value, value, 'Usage')}
            />
            <Tooltip
              content={
                <CustomTooltip
                  showAllItems="true"
                  data={data}
                  currKey={currKey}
                  displayedMetric={DisplayMetricTypes.USAGE}
                  showPrecent="true"
                />
              }
              wrapperStyle={{ opacity: '0.9', zIndex: '1' }}
            />
            {this.renderBar(dataKeys(data))}
          </BarChart>
        </ResponsiveContainer>
        <Row className="m-auto">
          <ChartKeysFilter
            hidePanel
            data={filteredDataKeys}
            favourites={favourites}
            filteredKeys={filteredKeys}
            getLabelColor={getColorByKeyName}
            addKeysFilterHandler={([elem]) => {
              this.setState((prevState) => ({
                favourites: [...prevState.favourites, elem.id],
                filteredKeys: [...prevState.filteredKeys, elem.name],
              }));
            }}
            removeKeysFilterHandler={([elem]) => {
              this.setState((prevState) => ({
                favourites: prevState.favourites.filter((f) => f !== elem.id),
                filteredKeys: prevState.filteredKeys.filter((f) => f !== elem.name),
              }));
            }}
            isShowOthers={false}
            isShowOthersChange={null}
            filteredDataKeys={filteredDataKeys}
          />
        </Row>
      </>
    );
  }
}

const ObserverCostBarLineChartToggler = withUserSettingsConsumer(CostBarLineChartToggler);

export default ObserverCostBarLineChartToggler;
