import React from 'react';
import PropTypes from 'prop-types';

import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { VOLUMES_PROPERTIES } from '../../recommendationPropertyUtils';

const EC2_STOPPED_INSTANCE_PROPERTIES = {
  PUBLIC_IP_ADDRESS_COST: {
    label: 'Public IP Address Cost',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.public_ip_address_cost,
  },
  STOP_REASON: {
    label: 'Stop Reason',
    getterFunction: (recommendation) => recommendation?.recData?.stop_reason,
  },
  STOP_TIME: {
    label: 'Stop Time',
    getterFunction: (recommendation) => recommendation?.recData?.stop_time,
  },
};

const Ec2StoppedInstance = ({ recommendation }) => {
  const description =
    // eslint-disable-next-line max-len
    'This EC2 instance is stopped but carries additional EBS and/or Public IP charges. You should consider terminating it.';

  const dynamicVolumeInstructions = () => {
    const instructions = [];
    Object.keys(recommendation?.recData?.attached_volumes_costs).forEach((key) => {
      instructions.push({ actionCommand: `aws ec2 delete-volume --volume-id ${key}` });
    });
    return instructions;
  };
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `aws ec2 terminate-instances --instance-ids ${recommendation?.resourceId}`,
        },
      ],
    },
    {
      type: COMMAND_TYPES.CONSOLE,
      instructions: [
        {
          actionLink: 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html',
          actionText: 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html',
        },
        {
          actionText:
            'In addition, consider terminating these EBS/IP if they are not associated with other EC2 instances.',
        },
      ],
    },
    {
      type: COMMAND_TYPES.CLI,
      instructions: dynamicVolumeInstructions(),
    },
  ];

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...VOLUMES_PROPERTIES, ...EC2_STOPPED_INSTANCE_PROPERTIES }}
    />
  );
};

Ec2StoppedInstance.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Ec2StoppedInstance;
