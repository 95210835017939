import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const CLOUDTRAIL_DUPLICATE_TRAILS_PROPERTIES = {
  ATTACHED_VOLUMES: {
    label: 'Attached Volumes',
    getterFunction: (recommendation) => recommendation?.recData?.attached_volumes_count,
  },
  ATTACHED_VOLUMES_MONTHLY_COST: {
    label: 'Attached Volumes Monthly Cost',
    getterFunction: (recommendation) =>
      Object.entries(recommendation?.recData?.attached_volumes_monthly_cost).map(([id, cost]) => ({ id, cost })),
    isTable: true,
    columns: [
      {
        id: 'id',
        label: 'Volume ID',
      },
      {
        id: 'cost',
        label: 'Cost',
        isCurrency: true,
      },
    ],
  },
  STOP_REASON: {
    label: 'Stop Reason',
    getterFunction: (recommendation) => recommendation?.recData?.stop_reason,
  },
  STOP_TIME: {
    label: 'Stop Time',
    getterFunction: (recommendation) => recommendation?.recData?.stop_time,
  },
};

const GcpVmStopped = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `gcloud compute instances delete ${recommendation.resourceName} `,
        },
      ],
    },
  ];
  const description = 'This VM instance is stopped but carries additional charges. You should consider terminating it.';

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...CLOUDTRAIL_DUPLICATE_TRAILS_PROPERTIES }}
    />
  );
};

GcpVmStopped.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default GcpVmStopped;
