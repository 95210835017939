/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CostTrackingConstants,
  CostTypeModes,
  DisplayMetricTypes,
  mapDataAttributesToDisplayMetricTypes,
} from 'usage/constants/costAndUsageConstants';
import ImmediateFilterBar from 'shared/components/ImmediateFilterBar';
import { buildStartAndEndDate, datePickerSyntexDates } from 'shared/utils/dateUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import { AppCommonFields, GranularityLevelsTypes } from 'shared/constants/appConstants';
import { Button, Col, Row } from 'reactstrap';
import ChartTimelineVariantIcon from 'mdi-react/ChartLineVariantIcon';
import ChartBarStackedIcon from 'mdi-react/ChartBarStackedIcon';
import SimpleLineChart from 'shared/components/charts/SimpleLineChart';
import CostChart from 'usage/containers/CostAndUsageExplorer/components/CostChart';
import NoDataFound from 'shared/components/NoDataFoundComponent';
import { groupDataByUsageDate, prepareDataForDisplayChart, prepareDataKeys } from 'shared/utils/dataPrepareUtil';
import LegendKeysFilterHandler from 'shared/components/ChartKeysFilter/legendKeysFilterHandler';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import { kFormatterNoDecimal } from 'shared/utils/strUtil';
import { useUserSettingsContext, withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import azureVmUsageProvider from 'usage/hooks/react-query/useAzureVmUsage';
import PieChartComp from './PieChartComponent';

const propTypes = {
  isPpApplied: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  initialStartDate: PropTypes.object.isRequired,
  initialEndDate: PropTypes.object.isRequired,
  xl: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
};

function AzureVmUsagePanel(props) {
  const { getCurrencyNumber } = useUserSettingsContext();
  const { xl, lg, md, sm, xs, initialStartDate, initialEndDate, isPpApplied } = props;

  const initialState = {
    filteredKeys: [],
    favourites: [],
    isInitialLoad: true,
    isInitialDataKeyFilterLoad: true,
    isLineChart: false,
    currPeriodGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
    startDate: initialStartDate,
    endDate: initialEndDate,
    currMetricType: AppCommonFields.TOTAL_QUANTITY,
  };
  const [state, setState] = useState(initialState);

  const azureVmUsageHook = azureVmUsageProvider({
    startDate: state.startDate,
    endDate: state.endDate,
    periodGranLevel: state.currPeriodGranLevel,
    isPpApplied,
  });
  const azureVmUsage = azureVmUsageHook?.fetchVmUsageCost();
  const azureVmUsageData = azureVmUsage?.data;

  useEffect(() => {
    if (state.isInitialLoad && azureVmUsageData) {
      setState({ ...state, isInitialLoad: false });
    }
  }, [azureVmUsageData]);

  useEffect(() => {
    async function fetchDataAsync() {
      await azureVmUsageHook.fetchAllVmUsageCost();
    }

    fetchDataAsync();
  }, [isPpApplied]);

  const mainLegendKeysFilterHandler = new LegendKeysFilterHandler({
    setState(data) {
      setState({ ...state, ...data });
    },
    get state() {
      return state;
    },
  });

  const { setKeysFilterHandler, addKeysFilterHandler, removeKeysFilterHandler } = mainLegendKeysFilterHandler;

  function filterData(dataArr, list) {
    return dataArr?.filter((item) => list.indexOf(item.groupBy) !== -1);
  }

  function calcTotalCost(data) {
    return data?.reduce((acc, { totalCost }) => {
      // eslint-disable-next-line no-param-reassign
      acc += totalCost || 0;
      return acc;
    }, 0);
  }

  function toggleCharts() {
    const { isLineChart } = state;
    setState({
      ...state,
      isLineChart: !isLineChart,
    });
  }

  function prepareChartData(data) {
    const groupByDateData = groupDataByUsageDate(data);
    const valueField = state.currMetricType;
    return prepareDataForDisplayChart(
      groupByDateData,
      AppCommonFields.USAGE_DATE,
      AppCommonFields.GROUP_BY,
      valueField,
    );
  }

  function prepareChartFiltersAndData(data) {
    const { filteredKeys, favourites } = state;
    const modifiedDailyBalances = data ? prepareChartData(data) : [];
    const dataKeys = prepareDataKeys(modifiedDailyBalances, 'name', 'cost');
    const filteredDataKeys = mainLegendKeysFilterHandler.filterDataKeys(
      dataKeys,
      filteredKeys,
      state.isInitialDataKeyFilterLoad,
    );
    const { isInitialDataKeyFilterLoad } = state;
    const chartKeysData = mainLegendKeysFilterHandler.prepareFilterKeysFromDataKeys(dataKeys);
    return {
      modifiedDailyBalances,
      chartKeysData,
      filteredKeys,
      filteredDataKeys,
      dataKeys,
      favourites,
      isInitialDataKeyFilterLoad,
    };
  }

  async function handleChangeGranLevel(granLevel) {
    let modifiedEnd = state.endDate;
    if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY) {
      modifiedEnd = state.startDate;
    }
    setState({
      ...state,
      currPeriodGranLevel: granLevel,
      endDate: modifiedEnd,
    });
  }

  async function handleDateChange({ startDate, endDate }) {
    const start = startDate || state.startDate;
    let end = endDate || state.endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modifiedStart = buildStartAndEndDate(start, end).startDate;
    const modifiedEnd = buildStartAndEndDate(start, end).endDate;
    setState({
      ...state,
      startDate: modifiedStart,
      endDate: modifiedEnd,
    });
  }

  function renderLineChart(
    height,
    data,
    chartKeysData,
    filteredDataKeys,
    dataKeys,
    favourites,
    chartId,
    currPeriodGranLevel,
  ) {
    return (
      <SimpleLineChart
        setKeysFilterHandler={setKeysFilterHandler}
        addKeysFilterHandler={addKeysFilterHandler}
        removeKeysFilterHandler={removeKeysFilterHandler}
        onShowOthersChecked={mainLegendKeysFilterHandler.onShowOthersChecked}
        data={data}
        mainLegendKeysFilterHandler={mainLegendKeysFilterHandler}
        dataKeys={dataKeys}
        chartKeysData={chartKeysData}
        favourites={favourites}
        filteredDataKeys={filteredDataKeys}
        XAxisDataKey={AppCommonFields.USAGE_DATE}
        YAxisLabelTitle={mapDataAttributesToDisplayMetricTypes.get(state.currMetricType)}
        title=""
        chartId={chartId}
        wrapInCardContainer={false}
        height={height}
        displayedMetric={state.currMetricType}
        granLevel={currPeriodGranLevel}
      />
    );
  }

  function renderCostChart(
    height,
    data,
    chartKeysData,
    filteredKeys,
    dataKeys,
    favourites,
    isInitialDataKeyFilterLoad,
    chartId,
    currPeriodGranLevel,
  ) {
    return (
      <CostChart
        setKeysFilterHandler={setKeysFilterHandler}
        addKeysFilterHandler={addKeysFilterHandler}
        removeKeysFilterHandler={removeKeysFilterHandler}
        height={height || 300}
        data={data}
        filteredKeys={filteredKeys}
        dataKeys={dataKeys}
        chartId={chartId}
        isInitialDataKeyFilterLoad={isInitialDataKeyFilterLoad}
        chartKeysData={chartKeysData}
        favourites={favourites}
        yAxisLabelTitle={mapDataAttributesToDisplayMetricTypes.get(state.currMetricType)}
        mainLegendKeysFilterHandler={mainLegendKeysFilterHandler}
        barClickable={false}
        isRenderKeysFilter
        displayedMetric={state.currMetricType}
        granLevel={currPeriodGranLevel}
        isFromServices
        selectedUsageType={DisplayMetricTypes.QUANTITY}
        costTypeMode={CostTypeModes.USAGE}
      />
    );
  }

  const { startDate, endDate, currPeriodGranLevel, isLineChart } = state;

  if (!azureVmUsageData) {
    return (
      <div style={{ height: '400px' }}>
        <Spinner />
      </div>
    );
  }
  const { height } = props;
  const {
    modifiedDailyBalances,
    chartKeysData,
    filteredKeys,
    filteredDataKeys,
    dataKeys,
    favourites,
    isInitialDataKeyFilterLoad,
  } = prepareChartFiltersAndData(azureVmUsageData);
  const finalVmQtyChartId = `VM-qty-chart-${random5DigitNumber()}`;
  const finalPieChartId = `pie-chart-${random5DigitNumber()}`;
  const PieData = filterData(azureVmUsageData, filteredKeys);
  const totalCost = calcTotalCost(PieData);

  return (
    <>
      <ImmediateFilterBar
        startDate={datePickerSyntexDates(startDate, null).startDate}
        endDate={datePickerSyntexDates(null, endDate).endDate}
        handleDateChange={handleDateChange}
        renderDatePickerFilter
        currPeriodGranLevel={currPeriodGranLevel}
        handleChangeGranLevel={handleChangeGranLevel}
        renderGranPeriodButton
      />
      {!azureVmUsageData?.length ? (
        <NoDataFound />
      ) : (
        <Row id={finalVmQtyChartId}>
          <Col md={md} lg={lg} xl={xl} sm={sm} xs={xs}>
            <Button
              style={{ padding: '2px', marginBottom: '10px', float: 'right' }}
              id={0}
              outline
              onClick={toggleCharts}
            >
              {isLineChart ? <ChartBarStackedIcon /> : <ChartTimelineVariantIcon />}
            </Button>
          </Col>
          <Col md={md} lg={lg} xl={xl} sm={sm} xs={xs}>
            {isLineChart
              ? renderLineChart(
                  height,
                  modifiedDailyBalances,
                  chartKeysData,
                  filteredDataKeys,
                  dataKeys,
                  favourites,
                  finalVmQtyChartId,
                  currPeriodGranLevel,
                )
              : renderCostChart(
                  height,
                  modifiedDailyBalances,
                  chartKeysData,
                  filteredKeys,
                  dataKeys,
                  favourites,
                  isInitialDataKeyFilterLoad,
                  finalVmQtyChartId,
                  currPeriodGranLevel,
                )}
          </Col>
          <Col md={12} lg={2} xl={2} sm={12} xs={12}>
            <div style={{ position: 'relative' }}>
              <p
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  height: 'calc(50% - 2em)',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <span style={{ display: 'inline-block', fontSize: '1em' }}>Total cost</span>
                <b style={{ display: 'inline-block', fontSize: '1.5em' }}>
                  {getCurrencyNumber(kFormatterNoDecimal(totalCost))}
                </b>
              </p>
              {azureVmUsageData?.length ? (
                <PieChartComp chartId={finalPieChartId} data={PieData} height={height} isRenderDownloadAsPng />
              ) : null}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

AzureVmUsagePanel.propTypes = propTypes;
AzureVmUsagePanel.defaultProps = {
  xl: 6,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 12,
};
const observerAzureVmUsagePanel = withUserSettingsConsumer(AzureVmUsagePanel);
export default observerAzureVmUsagePanel;
