import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/users/share-settings';

const createShareSettingApi = ({ body }) => API.post('billings', root, { body });
const getShareSettingApi = async (id) => {
  try {
    return await API.get('billings', `${root}/${id}`);
  } catch (e) {
    return null;
  }
};
const updateShareSettingApi = ({ id, body }) => API.put('billings', `${root}/${id}`, { body });
const deleteShareSettingApi = ({ id }) => API.del('billings', `${root}/${id}`);

export default function useShareSettings() {
  const queryClient = useQueryClient();
  const queryKey = apiConstants.QUERY_KEYS.SHARE_SETTINGS;

  const addShareSetting = useMutation(createShareSettingApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
    },
  });
  const updateShareSetting = useMutation(updateShareSettingApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
    },
  });
  const deleteShareSetting = useMutation(deleteShareSettingApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
    },
  });

  return {
    fetchShareSettingById: (id, options = {}) =>
      useQuery([id, queryKey], () => getShareSettingApi(id), { retry: false, ...options }),
    addShareSetting,
    updateShareSetting,
    deleteShareSetting,
  };
}
