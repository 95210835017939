import React from 'react';
import PropTypes from 'prop-types';
import { MapSpanToWidth } from 'recommendations/constants/recommendationsConstants';

const CustomDashboardPreviewPanelLayout = ({ panels }) => (
  <div className="custom-dashboard-layout-card__wrapper">
    <h5>Dashboard Layout Preview</h5>
    {panels
      .sort((a, b) => a.order - b.order)
      .map((panel) => (
        <div
          key={panel.uuid}
          className="custom-dashboard-layout-card"
          style={{ width: `${MapSpanToWidth[panel.span]}%` }}
        >
          <div>{panel.name}</div>
        </div>
      ))}
  </div>
);

CustomDashboardPreviewPanelLayout.propTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      span: PropTypes.oneOf([1, 2, 3]),
      order: PropTypes.number,
    }),
  ).isRequired,
};

export default CustomDashboardPreviewPanelLayout;
