import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import AddSubRolesModal from './AddSubRolesModal';

const AddSubRolesToRole = ({ role }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        automationId="addSubRolesToRole"
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        onClick={onClick}
        text="Add Roles"
      />
      {isOpen && <AddSubRolesModal isOpen={isOpen} setIsOpen={setIsOpen} role={role} />}
    </>
  );
};

AddSubRolesToRole.propTypes = {
  role: PropTypes.string.isRequired,
};

export default AddSubRolesToRole;
