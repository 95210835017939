import React, { useEffect } from 'react';
import { usePermissionCategories } from 'usage/hooks/react-query/usePermissionCategories';
import { querystring } from 'shared/utils/apiUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import { isMenuOptionAllowed } from 'shared/constants/routesMapping';
import { Routes } from 'shared/constants/routes';

const LandingPage = ({ history, usersStore, appStore }) => {
  const { fetchAccessibleCategories } = usePermissionCategories();
  const { data: permissionCategories, isLoading } = fetchAccessibleCategories();

  useEffect(() => {
    // if fetch permission categories is still loading, do nothing
    if (isLoading) {
      return;
    }
    // define default page based on available accounts
    const availableAccounts = usersStore.getAllCurrDisplayedUserAccounts();
    const defaultPage = availableAccounts.length ? Routes.DASHBOARD : Routes.ACCOUNTS_OVERVIEW;
    let nextRoutePath = defaultPage;
    // Check if there is a redirect query param to override default page
    const redirect = querystring('redirect');
    if (redirect) {
      nextRoutePath = redirect;
    }
    if (isMenuOptionAllowed(nextRoutePath, usersStore, appStore, permissionCategories)) {
      // if user permitted for next route, redirect to it
      history.push(nextRoutePath);
    } else if (isMenuOptionAllowed(defaultPage, usersStore, appStore, permissionCategories)) {
      // if not permitted for next route, check if permitted to default page
      history.push(defaultPage);
    } else if (
      defaultPage === Routes.DASHBOARD &&
      isMenuOptionAllowed(Routes.COST_USAGE_EXPLORER, usersStore, appStore, permissionCategories)
    ) {
      // if not permitted for for dashboard page, check if permitted to cost usage explorer page as a secondary default
      history.push(Routes.COST_USAGE_EXPLORER);
    } else {
      // if not permitted to default page, redirect to not allowed page
      history.push(Routes.NOT_ALLOWED);
    }
  }, [permissionCategories, isLoading, usersStore, appStore]);

  if (isLoading) {
    return <Spinner />;
  }
  // if not loading, effect should have redirected to another page
  // retuning null to avoid rendering anything
  return null;
};

export default LandingPage;
