/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import moment from 'moment';
import CostComparisonChart from './CostComparisonChart';
import BreakEvenGraph from './BreakEvenGraph';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  objSort = (obj) => {
    const orderedDates = {};
    Object.keys(obj)
      .sort((a, b) => {
        a = moment(a, 'YYYY-MM-DD');
        b = moment(b, 'YYYY-MM-DD');
        return a < b ? -1 : 1;
      })
      .forEach((key) => {
        orderedDates[key] = obj[key];
      });
    return orderedDates;
  };

  modifyRiTableData = () => {
    const tempArr = [];
    const dataArr = [];

    const data = this.objSort(this.props.rec.riTable);
    for (let i = 0; i < 24; i++) {
      dataArr.push({ name: `${i}:00` });
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      value.forEach((row, index) => {
        // eslint-disable-next-line no-unused-expressions
        tempArr[index] ? tempArr[index].push({ x: key, y: row }) : (tempArr[index] = [{ x: key, y: row }]);
      });

      dataArr.map((row, index) => {
        // eslint-disable-next-line no-param-reassign
        row.data = tempArr[index];
        return row;
      });
    }

    return dataArr;
  };

  createBreakEvenData = ({ yearlyCost }) => {
    const monthSum = yearlyCost.currTotalCost / 12;
    const riCost = yearlyCost.recommTotalCost;
    const breakEvenData = [];
    for (let i = 1; i <= 12; ++i) {
      breakEvenData.push({ name: i, 'RI Cost': riCost, 'Pay as you go Cost': (monthSum * i).toFixed(1) });
    }

    return breakEvenData;
  };

  // eslint-disable-next-line class-methods-use-this
  riPricesCompare(a, b) {
    if (a.Ri > b.Ri || a['Suggested Ri'] > b.Ri || a.Ri > b['Suggested Ri']) {
      return -1;
    }
    if (a.Ri < b.Ri || a['Suggested Ri'] < b.Ri || a.Ri < b['Suggested Ri']) {
      return 1;
    }
    return 0;
  }

  createRiVsDemandData = (rec) => {
    const riVsOnDemandData = [];
    if (rec.currTotalCost) {
      riVsOnDemandData.push({
        name: 'Pay as you go',
        'Pay as you go': rec.currTotalCost,
      });
    }
    if (rec.recommTotalCost) {
      riVsOnDemandData.push({
        name: rec.recommPlan === 'P3Y' ? '3 Year Plan' : rec.recommPlan,
        'Suggested RI': rec.recommTotalCost,
      });
    }
    if (rec.additionalRecommPlans.P3Y) {
      riVsOnDemandData.push({
        name: '3 Year Plan',
        RI: rec.additionalRecommPlans.P3Y,
      });
    }

    riVsOnDemandData.sort(this.riPricesCompare);
    return riVsOnDemandData;
  };

  render() {
    const { rec } = this.props;
    if (!rec) {
      return <div>Loading</div>;
    }
    // const riTableData = this.modifyRiTableData();
    const riVsOnDemandData = this.createRiVsDemandData(rec);
    const breakEvenData = this.createBreakEvenData(rec);

    return (
      <div>
        {this.state.update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Cost Comparison</p>
          <br />
          <CostComparisonChart data={riVsOnDemandData} />
        </div>
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Break-Even Point</p>
          <br />
          <BreakEvenGraph breakEvenData={breakEvenData} />
        </div>
        {/* <div className="project-summary__statistic_heatmap">
          <p className="project-summary__statistic-title" />
          <br />
          <RiHeatMapChart series={riTableData} />
        </div> */}
      </div>
    );
  }
}
