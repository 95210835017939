/* eslint-disable no-param-reassign */
import { getEmailFromRecipients } from 'shared/utils/sharedUtils';
import {
  mapReportDatePeriodToDisplayName,
  mapReportFrequencyToDisplayName,
} from 'usage/constants/costAndUsageConstants';

export const getFrequency = (reportParams) => {
  let displayedFrequency = '';
  if (reportParams.frequency === 4) {
    // 4 is custom frequency
    displayedFrequency = `Every ${reportParams.customFrequencyInDays} days`;
  } else {
    const numFrequency = parseInt(reportParams.frequency, 10);
    displayedFrequency = mapReportFrequencyToDisplayName.get(numFrequency);
  }
  return displayedFrequency;
};

export const getPeriod = (reportParams) => {
  const { period, periodType } = reportParams;
  const newPeriod =
    periodType === 'relativeDate'
      ? mapReportDatePeriodToDisplayName.get(+period)
      : mapReportDatePeriodToDisplayName.get(periodType);
  return newPeriod;
};

export const prepareTableData = (data) => {
  const prepData = !data
    ? []
    : data.map((item) => ({
        ...item,
        period: getPeriod(item),
        frequency: getFrequency(item),
        email: getEmailFromRecipients(item.recipients),
      }));
  return prepData;
};
