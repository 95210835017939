import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const IP_UNATTACHED_PROPERTIES = {
  PUBLIC_IP: {
    label: 'Public IP',
    getterFunction: (recommendation) => recommendation?.recData?.public_ip,
  },
};

const IpUnattached = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'In case of IP:',
          actionCommand: `aws ec2 release-address --public-ip ${recommendation?.recData?.public_ip}`,
        },
        {
          actionText: 'In case of VPC:',
          actionCommand: `aws ec2 release-address --allocation-id ${recommendation?.recData?.public_ip}`,
        },
      ],
    },
  ];

  const description = 'This IP is not attached to any instance';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={IP_UNATTACHED_PROPERTIES}
    />
  );
};

IpUnattached.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default IpUnattached;
