/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { VersionUpgradeRecommendation } from 'recommendations/models/recommTypes';
import { Ec2InstanceBasicData } from 'recommendations/models/recommCommonTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';
import { isEmptyObj } from 'shared/utils/dataPrepareUtil';
import { isEmptyArray } from 'shared/utils/arrayUtils';

export default class VersionUpgradeEc2CostRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new VersionUpgradeRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_VERSION_UPGRADE;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action} EC2 Instances Generation`;
  }

  build() {
    super.build();
    const {
      instance_type_size: size,
      project,
      zone_tag: region,
      operation_system: os,
      quantity: resourcesQuantity,
      environment,
      resources,
      additional_recommended_models: additionalRecModels,
      new_model: recommVersion,
      specs_details_current: specsCurr,
      specs_details_recommended: specsRecomm,
      resource_tags: resourceTags,
      resource_name: resourceName,
      resource_id: resourceId,
      physical_processor: physicalProcessor,
    } = this._rawRecommendation;

    const recommEc2Instance = new Ec2InstanceBasicData(recommVersion, size, os, region, environment);
    this._recommendation.recommEc2Instance = recommEc2Instance;

    this._recommendation.project = project;
    this._recommendation.resourcesQuantity = resourcesQuantity;
    this._recommendation.resources = resources;
    this._recommendation.additionalRecModels = additionalRecModels;
    this._recommendation.specsCurr = specsCurr;
    this._recommendation.specsRecomm = specsRecomm;

    this._recommendation.rawResourceTags = resourceTags;
    this._recommendation.resourceName = resourceName;
    this._recommendation.resourceId = resourceId;
    this._recommendation.physicalProcessor = physicalProcessor;
    // old version of this recommendation contains resources as array
    this._recommendation.separateResource = resourceId && resourceName && isEmptyArray(resources);

    this._recommendation.resourceTags = Object.keys(resourceTags || {})
      .filter((key) => !isEmptyObj(resourceTags[key]))
      .map((key) => {
        const objStr = JSON.stringify(resourceTags[key]);
        const val = `${key}: ${objStr}`;
        return val;
      });
  }
}
