 
 
 
import { API } from 'shared/utils/apiMiddleware';

export default class DivisionsApi {
  // Division Group
  getDivisionGroups = async () => {
    const getUrl = `/api/v1/divisions/i/?includeEmpty=true`;
    return API.get('billings', getUrl);
  };

  updateDivisionGroupName = async (divId, body) => {
    const updateUrl = `/api/v1/divisions/name?divId=${divId}`;
    return API.put('billings', updateUrl, { body });
  };
  updateDivisionGroup = async (divId, body) => {
    const updateUrl = `/api/v1/divisions?divId=${divId}`;
    return API.put('billings', updateUrl, { body });
  };

  updateDivisionGroupLinkAccounts = async (divId, linkedAccIds, divisionName, divisionCode, divisionTypeId, roleId) => {
    const body = { divId, linkedAccIds, divisionName, divisionCode, divisionTypeId, roleId };
    const updateUrl = '/api/v1/divisions/link-acc-new';
    return API.put('billings', updateUrl, { body });
  };

  deleteDivisionGroup = async (divisionId, divisionName) => {
    const deleteUrl = `/api/v1/divisions?divId=${divisionId}&divisionName=${divisionName}`;
    return API.del('billings', deleteUrl);
  };
  createDivisionGroup = async (body) => {
    const creareUrl = `/api/v1/divisions`;
    return API.post('billings', creareUrl, { body });
  };
}
