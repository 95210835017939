import * as React from 'react';
import { useContext } from 'react';
import PropTypes from 'prop-types';

const RecommendationBuilderContext = React.createContext({
  selectedOption: 0,
  onOptionSelected: () => {},
});

export function RecommendationBuilderContextProvider(props) {
  const { children } = props;
  return React.createElement(RecommendationBuilderContext.Provider, { value: props }, children);
}

export function useRecommendationBuilderContext() {
  return useContext(RecommendationBuilderContext);
}

RecommendationBuilderContextProvider.propTypes = {
  children: PropTypes.node,
};

RecommendationBuilderContextProvider.defaultProps = {
  children: undefined,
};
