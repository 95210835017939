import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, RECOMMENDATIONS_HEATMAP_ROOT, STALE_TIME } from 'recommendationsNew/consts';
import {
  transformFiltersContextToApiFormat,
  transformSelectDataToClientFormat,
} from 'recommendationsNew/hooks/react-query/apiUtils';
import { useRootStore } from 'app/contexts/RootStoreContext';

async function fetchDynamicRanges(filters) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const data = await API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/dynamicRanges`, {
    body: {
      filters: transformedFilters || {},
    },
  });
  // Transform data to match the format of react-select
  const pageData = transformSelectDataToClientFormat(data?.page);
  return data?.page ? { ...data, page: pageData } : data;
}

export default function useDynamicRanges(isEnabled = undefined) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_DYNAMIC_RANGES,
    userAccountKey,
  ];

  return {
    invalidate: ({ filters }) => queryClient.invalidateQueries([...queryKey, filters] || queryKey),
    reset: ({ filters }) => queryClient.resetQueries([...queryKey, filters] || queryKey),
    fetchDynamicRanges: (filters) =>
      useQuery([...queryKey, filters], () => fetchDynamicRanges(filters), {
        retry: false,
        staleTime: STALE_TIME,
        enabled: isEnabled,
      }),
  };
}
