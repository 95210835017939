import React from 'react';
import logo from 'shared/img/login_logo_plain.png';
import PropTypes from 'prop-types';

const LoginPileusLogo = ({ align }) => (
  <div
    className="login-pileus-logo"
    style={{
      display: 'block',
      margin: `${align === 'left' ? '35px 0 20px 45px' : '0 auto 20px'}`,
      width: '230px',
    }}
  >
    <img src={logo} alt="welcome" />
  </div>
);

LoginPileusLogo.propTypes = {
  align: PropTypes.string,
};

LoginPileusLogo.defaultProps = {
  align: 'center',
};

export default LoginPileusLogo;
