import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { SelectMulti, ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import { observer } from 'mobx-react';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from './AddDivisionModal.module.scss';

const AddDivisionModal = ({ onClose, saveDivision }) => {
  const [divisionName, setDivisionName] = useState('');
  const [divisionCode, setDivisionCode] = useState('');
  const [selectedLinkedAccounts, setSelectedLinkedAccounts] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [errors, setErrors] = useState({
    divisionName: '',
    linkedAccounts: '',
  });
  const { usersStore } = useRootStore();
  const { availbleDivisionLinkedAccounts } = usersStore.usersModel;
  const onSaveClick = async (e) => {
    setSaveClicked(true);
    if (!divisionName) {
      setErrors((prevErrors) => ({ ...prevErrors, divisionName: 'Division name is mandatory' }));
    }
    if (!selectedLinkedAccounts?.length) {
      setErrors((prevErrors) => ({ ...prevErrors, linkedAccounts: 'You must select at least one linekd account' }));
    }
    if (!divisionName || !selectedLinkedAccounts?.length) {
      return;
    }
    saveDivision(e, { divisionName, divisionCode, linkedAccounts: selectedLinkedAccounts });
  };
  const renderErrorMsg = (fieldName) =>
    saveClicked && errors && errors[fieldName] ? <span className={styles.errorMsg}>{errors[fieldName]}</span> : null;
  const onDivisionNameChange = (value = '') => {
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, divisionName: '' }));
    }
    setDivisionName(value);
  };
  const onDivisionCodeChange = (e) => {
    const { value } = e.target;
    setDivisionCode(value);
  };
  const onSelectedLinkedAccountChange = (selectedLinkedAccounts) => {
    if (selectedLinkedAccounts?.length) {
      setErrors((prevErrors) => ({ ...prevErrors, linkedAccounts: '' }));
    }
    setSelectedLinkedAccounts(selectedLinkedAccounts);
  };
  return (
    <CustomModal
      closeOnSave={false}
      onClose={onClose}
      open
      title="Create Cost Center"
      overrideStyles={{ width: 600 }}
      headerMode="add"
      onSave={onSaveClick}
      className={{ footer: styles.footer }}
    >
      <div className={`d-flex flex-column ${styles.modalContent}`}>
        <div className={`d-flex ${styles.nameCodeContainer}`}>
          <div className={styles.nameInput}>
            <span>Cost Center Name</span>
            <Input
              label="Cost Center Name"
              name="divisionName"
              overrideStyles={{ height: 36 }}
              value={divisionName}
              onChange={(e) => onDivisionNameChange(e?.target?.value?.replace(/[^a-z0-9_-]/gi, ''))}
              placeholder="Enter Cost Center name"
              invalidComponent={renderErrorMsg('divisionName')}
              isInvalid={saveClicked && !!errors?.divisionName}
            />
          </div>
          <div>
            <span>Cost Center Code</span>
            <Input
              label="Cost Center Code"
              name="divisionCode"
              overrideStyles={{ height: 36 }}
              value={divisionCode}
              onChange={onDivisionCodeChange}
              placeholder="Enter Cost Center code"
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className={styles.selectContainer}>
            <span>Linked Account</span>
            <SelectMulti
              label="Linked Accounts"
              options={availbleDivisionLinkedAccounts.map(({ linkedAccountId, linkedAccountName }) => ({
                value: linkedAccountId,
                label: linkedAccountName ? `${linkedAccountName} (${linkedAccountId})` : linkedAccountId,
              }))}
              selected={selectedLinkedAccounts}
              handleSelectionChange={onSelectedLinkedAccountChange}
            />
            {renderErrorMsg('linkedAccounts')}
          </div>
          {selectedLinkedAccounts.length > 0 && (
            <div className={styles.chipList}>
              {selectedLinkedAccounts.map((ln) => (
                <Chip
                  key={ln.value}
                  label={ln.label}
                  deleteIcon={<GenerateIcon iconName={ICONS.xMark.name} className="chip-delete-icon" />}
                  onDelete={() =>
                    setSelectedLinkedAccounts(
                      selectedLinkedAccounts.filter(({ linkedAccountId }) => ln.linkedAccountId !== linkedAccountId),
                    )
                  }
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

AddDivisionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  saveDivision: PropTypes.func.isRequired,
};
export default observer(AddDivisionModal);
