import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import Spinner from 'shared/components/andtComponents/Spinner';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import {
  VM_AND_STORAGE_COST_COLUMN_WIDTHS,
  VM_AND_STORAGE_COST_CURRENCIES_COLUMNS,
  VM_AND_STORAGE_COST_DEFAULT_SORTING,
  VM_AND_STORAGE_COST_TABLE_COLUMN_EXTENSIONS,
  VM_AND_STORAGE_COST_TABLE_COLUMNS,
} from 'usage/containers/AzureVirtualMachines/constants/azureVMAndStorageCostTableConstants';
import { Col, Row } from 'reactstrap';
import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import vmAndStorageCostProvider from 'usage/hooks/react-query/useVmAndStorageCost';
import { datePickerSyntaxDates } from 'usage/containers/AzureVirtualMachines/constants/constants';

import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import classes from './azureVmUsagePanel.module.scss';

const propTypes = {
  initialStartDate: PropTypes.object.isRequired,
  initialEndDate: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

const ROWS_LIMIT = 5000;

const VMAndStorageCostPanel = ({ initialStartDate, initialEndDate, rootStore }) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const { getCurrencyNumber } = useUserSettingsContext();
  const [vmAndStorageCostColumns, setVmAndStorageCostColumns] = useState(
    Object.values(VM_AND_STORAGE_COST_TABLE_COLUMNS),
  );

  const vmAndStorageCostDataHook = vmAndStorageCostProvider({
    startDate,
    endDate,
    isPpApplied: rootStore?.appStore?.isPpApplied,
    limit: ROWS_LIMIT,
  });
  const vmAndStorageCostDataProvider = vmAndStorageCostDataHook?.fetchVmAndStorageCost();
  const isDataLoading = vmAndStorageCostDataProvider?.isLoading;
  const vmAndStorageCostData = vmAndStorageCostDataProvider?.data;

  const handleDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    const start = newStartDate || startDate;
    let end = newEndDate || endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    setStartDate(modStartDate);
    setEndDate(modEndDate);
  };

  // On cleanup - invalidate the reactQuery cache. This is done so when reseller is going through customers -
  // he won't see the data of previous customer before the data of the new customer arrived.
  useEffect(
    () => () => {
      vmAndStorageCostDataHook.reset();
    },
    [],
  );

  const displayStartDate = useMemo(() => datePickerSyntaxDates(startDate, null).startDate, [startDate]);

  const displayEndDate = useMemo(() => datePickerSyntaxDates(null, endDate).endDate, [endDate]);

  useEffect(() => {
    const columns = vmAndStorageCostColumns.filter(
      (column) =>
        !(rootStore?.appStore?.isPpApplied && column.name === VM_AND_STORAGE_COST_TABLE_COLUMNS.CUSTOMER_NAME.name),
    );
    setVmAndStorageCostColumns(columns);
  }, [rootStore?.appStore?.isPpApplied]);

  async function getCsvData() {
    const data = await vmAndStorageCostDataHook?.fetchAllVmAndStorageCost(true);
    return [{ data, filename: 'VM_AND_STORAGE_COST' }];
  }

  return isDataLoading ? (
    <div className={classes.spinnerContainer}>
      <Spinner />
    </div>
  ) : (
    <div>
      <div className={classes.container}>
        <DatePickerFilter
          onDateChange={handleDateChange}
          startDate={displayStartDate}
          endDate={displayEndDate}
          currPeriodGranLevel={GranularityLevelsTypes.GRAN_LEVEL_DAILY}
        />
        {vmAndStorageCostData?.length === ROWS_LIMIT ? (
          <div className={classes.notice}>
            Result is limited to {ROWS_LIMIT} rows. To get full data click `Export as CSV`.
          </div>
        ) : (
          <div />
        )}
      </div>
      {vmAndStorageCostData?.length > 0 ? (
        <Row>
          <Col xs="12" md="12" lg="12" xl="12">
            <DevExpSearchAndVisibilityTable
              data={vmAndStorageCostData}
              fetchCsvData={() => getCsvData()}
              isLoading={vmAndStorageCostDataProvider?.isFetching}
              columns={vmAndStorageCostColumns}
              costColumnsArray={VM_AND_STORAGE_COST_CURRENCIES_COLUMNS}
              currenciesFormatterComponent={({ value }) => `${getCurrencyNumber(value, 2)}`}
              tableColumnExtensions={VM_AND_STORAGE_COST_TABLE_COLUMN_EXTENSIONS}
              defaultSorting={VM_AND_STORAGE_COST_DEFAULT_SORTING}
              csvTitle="VM_AND_STORAGE_COST"
              columnWidths={VM_AND_STORAGE_COST_COLUMN_WIDTHS}
              defaultHiddenColumnNames={[]}
              isResize
              isVirtual
              isVisibility
            />
          </Col>
        </Row>
      ) : (
        <div className={classes.notice}>No data in the selected date range.</div>
      )}
    </div>
  );
};

VMAndStorageCostPanel.propTypes = propTypes;
export default observer(VMAndStorageCostPanel);
