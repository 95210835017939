// import { serviceDisplayNameToFullName } from 'shared/constants/awsConstants';
import BaseReportParams from './baseReportParams';

export default class TopFiveServReportParams extends BaseReportParams {
  createTopFiveMap = (arrOfTopServices) => {
    // const mapTopServices = new Map();
    const asArray = Array.isArray(arrOfTopServices) ? [...arrOfTopServices] : [arrOfTopServices];
    // const asArrayWithAwsNames = asArray.map(name => serviceDisplayNameToFullName.get(name));
    // mapTopServices.set('service', [...asArrayWithAwsNames]);
    const filteredKeys = [...asArray];
    const favourites = filteredKeys.length > 0 ? [...Array(filteredKeys.length).keys()] : [];
    // this.updateBaseReportParams({ filteredKeys }, null);
    this.updateBaseReportParams({ favourites, filteredKeys }, null);
  };
}
