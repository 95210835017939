import React from 'react';
import { usePreferences } from 'users/hooks/react-query/usePreferences';

export function withPreferencesContextProvider(Component) {
  function PreferencesContextProvider({ ...props }) {
    const { fetchPreferences, useSaveMutation } = usePreferences();
    const { data } = fetchPreferences();
    const handleSavePreferences = useSaveMutation();
    const params = {
      preferences: data || [],
      handleSavePreferences,
    };
    return <Component {...props} {...params} />;
  }
  return PreferencesContextProvider;
}
