import * as awsUtils from 'shared/utils/awsUtils';
import * as gcpUtils from 'shared/utils/gcpUtils';
import * as azureUtils from 'shared/utils/azureUtils';

export const getFieldDisplayLabel = (field, secondLevelItemsValuesMap) => {
  const awsLabel = awsUtils.getFieldDisplayLabel(field, secondLevelItemsValuesMap);
  const azureLabel = azureUtils.getFieldDisplayLabel(field, secondLevelItemsValuesMap);
  const gcpLabel = gcpUtils.getFieldDisplayLabel(field, secondLevelItemsValuesMap);

  return [awsLabel, azureLabel, gcpLabel].find((label) => label !== field) || field;
};

export const isServiceHasTypes = (serviceName) =>
  azureUtils.isServiceHasTypes(serviceName) ||
  awsUtils.isServiceHasTypes(serviceName) ||
  gcpUtils.isServiceHasTypes(serviceName);

export const isServiceHasFamilyType = (serviceName) =>
  azureUtils.isServiceHasFamilyType(serviceName) ||
  awsUtils.isServiceHasFamilyType(serviceName) ||
  gcpUtils.isServiceHasFamilyType(serviceName);

export const isFamilyTypeHasTypes = (familyType) =>
  azureUtils.isFamilyTypeHasTypes(familyType) ||
  awsUtils.isFamilyTypeHasTypes(familyType) ||
  gcpUtils.isFamilyTypeHasTypes(familyType);
