import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  CustomClickableFormatter,
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import {
  GET_USERS_QUERY_PARAMS,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
  TAB_PATHS,
  USER_ROLES_COLUMNS,
} from '../../consts';
import RemoveButton from '../../components/RemoveButton';
import { ReactComponent as NoResults } from '../img/no-results.svg';

import styles from './UserRolesTab.module.scss';

export const UserRolesNoData = (userRoles) => {
  if (!userRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoResults />
      <span className={styles.mainText}>No Roles for this user</span>
    </div>
  );
};

const onRoleNameClick = (row) => {
  const currentUrl = new URL(window.location.href);
  if (currentUrl.pathname.endsWith(`/${TAB_PATHS.USERS}`)) {
    currentUrl.pathname = currentUrl.pathname.replace(`/${TAB_PATHS.USERS}`, `/${TAB_PATHS.ROLES}`);
  } else {
    currentUrl.pathname = `${currentUrl.pathname}/${TAB_PATHS.ROLES}`;
  }
  currentUrl.searchParams.set(GET_USERS_QUERY_PARAMS.SEARCH.name, row?.displayName);
  window.open(currentUrl.toString(), '_blank');
};

const UserRolesTabTable = ({ userRoles, selectedUserRoles, setSelectedUserRoles, removeRoleClicked }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(USER_ROLES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  return (
    userRoles && (
      <Grid rows={userRoles} columns={getColumns(USER_ROLES_COLUMNS)} automationId="user-roles-tab-list">
        <SortingState
          columnExtensions={getSortingColumns(USER_ROLES_COLUMNS)}
          defaultSorting={getDefaultSorting(USER_ROLES_COLUMNS)}
        />
        <SelectionState selection={selectedUserRoles} onSelectionChange={setSelectedUserRoles} />
        <IntegratedSelection />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.ROLE_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomClickableFormatter {...props} onClick={() => onRoleNameClick(props?.row)} />
          )}
        />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.ASSIGNED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip
              value={props?.row?.assignedAt ? moment(props?.row?.assignedAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
        />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.ASSIGNED_BY.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltip isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.REMOVE_USER_ROLE.columnName]}
          formatterComponent={(props) => (
            <RemoveButton props={props} removeClicked={() => removeRoleClicked(props.row)} />
          )}
        />
        <TableWrapper virtual noDataCellComponent={UserRolesNoData} height="auto" />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      </Grid>
    )
  );
};

UserRolesTabTable.propTypes = {
  userRoles: PropTypes.array.isRequired,
};

export default UserRolesTabTable;
