/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureAppSerRsrvdCapacityRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureAppSerRsrvdCapacityRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureAppSerRsrvdCapacityRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Unused Cluster`;
  }

  build() {
    super.build();
    const {
      sku_name: skuName,
      sku_display_name: skuDisplayName,
      zone_tag: zoneTag,
      recommended_plan: recommendedPlan,
      annual_savings_value: savingAmount,
      service,
      scope,
      quantity,
    } = this._rawRecommendation;
    this._recommendation.skuName = skuName;
    this._recommendation.skuDisplayName = skuDisplayName;
    this._recommendation.region = zoneTag;
    this._recommendation.recommendedPlan = recommendedPlan;
    this._recommendation.scope = scope;
    this._recommendation.service = service;
    this._recommendation.savingAmount = parseFloat(savingAmount);
    this._recommendation.quantity = quantity;
  }
}
