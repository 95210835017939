/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import Panel from 'shared/components/Panel';

export default function GcpComputeEngine() {
  return (
    <Container>
      <PageHeader title={PageNames.GcpComputeEngine} showDate />
      <Panel divider title="Google Cloud Compute Engine">
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>
              Select the right VM for your needs, whether general purpose, or workload optimized, in predefined or
              custom machine sizes
            </span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>
              Integrate compute with other Google Cloud services such as AI/ML and data analytics
            </span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>
              Make reservations to help ensure your applications have the capacity they need as they scale. Save money
              just for running compute with sustained-use discounts, and achieve greater savings when you use
              committed-use discounts
            </span>
          </h5>
        </p>
      </Panel>
    </Container>
  );
}
