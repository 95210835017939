/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import If from 'shared/components/SimpleIf';
import { DataTable } from 'shared/components/DataTable';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionHeader } from '../../../shared/components/ActionComponents';

const createSpecsComparisonData = (rec) => {
  const { instance, recommEc2Instance, specsRecomm, specsCurr } = rec;

  const headings = [' ', 'Model', 'Memory (GB)', 'vCPU', 'ECU', 'Storage', 'Network Performance'];
  const rows = [];
  if (specsCurr) {
    rows[0] = ['Current', instance];
    rows[0] = rows[0].concat(specsCurr);
  }

  if (specsRecomm) {
    rows[1] = ['Suggested', recommEc2Instance.instance];
    rows[1] = rows[1].concat(specsRecomm);
  }

  return { headings, rows };
};

const Action = ({ rec }) => {
  const { separateResource, resourceId } = rec;
  const { model: recommModel, instance: recommInstance } = rec.recommEc2Instance;
  const tableData = createSpecsComparisonData(rec);

  return (
    <div className="recommendations__version-upgrade-action" style={{ 'line-height': '40px', 'font-size': '14px' }}>
      <If cond={rec.specsRecomm && rec.specsCurr}>
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Specs Comparison</p>
          <p style={{ 'font-size': '12px' }}>
            <DataTable headings={tableData.headings} rows={tableData.rows} />
          </p>
          <br />
        </div>
      </If>
      <div className="recs__execute-container">
        <ActionHeader />
        <ul className="recs__execute-steps">
          <li>Using the AWS CLI:</li>
          <ol>
            <li>
              {'Modify grub.cfg file'}
              <CodeBlock
                text="sudo vim /boot/grub/grub.cfg add nvme.io_timeout=4294967295 parameter in grub configuration {{example}}: linux /boot/vmlinuz-4.4.0-1057-aws root=UUID=60d051e7-7afa-41f2-1e3-df75bd6ff8bb ro console=tty1 console=ttyS0 nvme.io_timeout=4294967295"
                variables={{
                  example: 'Example',
                }}
              />
              <br />
            </li>
            <li>
              {'Stop instance'}
              <CodeBlock
                text="aws ec2 stop-instances --instance-id {{resourceId}}"
                variables={{
                  resourceId: separateResource ? resourceId : 'RESOURCE_ID',
                }}
              />
              <br />
            </li>
            <li>
              {'Enabling Enhanced Networking'}
              <CodeBlock
                text="aws ec2 modify-instance-attribute --instance-id {{resourceId}} --ena-support"
                variables={{
                  resourceId: separateResource ? resourceId : 'RESOURCE_ID',
                }}
              />
              <br />
            </li>
            <li>
              {`Change instance type to ${recommModel} type`}
              <CodeBlock
                text={
                  'aws ec2 modify-instance-attribute --instance-id {{resourceId}} --instance-type "{ \\"Value\\": \\"{{recommInstance}}\\" }"'
                }
                variables={{
                  resourceId: separateResource ? resourceId : 'RESOURCE_ID',
                  recommInstance,
                }}
              />
              <br />
            </li>
            <li>
              {'Start the instance'}
              <CodeBlock
                text="aws ec2 start-instances --instance-id {{resourceId}}"
                variables={{
                  resourceId: separateResource ? resourceId : 'RESOURCE_ID',
                }}
              />
              <br />
            </li>
          </ol>
          <li>
            Using the AWS Console:
            <NavLink
              target="_blank"
              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html"
            >
              Changing EC2 Instance Type using the AWS Console
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
