import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import RoleSubRolesTabHeader from './RoleSubRolesTabHeader';
import RoleSubRolesTabTable from './RoleSubRolesTabTable';

import styles from './RoleTab.module.scss';

const RoleSubRolesTab = ({ row }) => {
  const [subRoles, setSubRoles] = useState([]);
  const [selectedSubRoles, setSelectedSubRoles] = useState([]);
  const [search, setSearch] = useState();
  const [isInProgress, setIsInProgress] = useState(false);

  const { NewTableWrapper } = useTable();

  const { fetchRoleById, removeSubRoles, fetchRoles } = useRoles();

  const { data, isLoading } = fetchRoleById(row?.id);
  const { data: rolesData } = fetchRoles(); // To get the createdAt and updatedAt values for the grid

  useEffect(() => {
    if (data?.subRoles && rolesData?.roles) {
      const subRolesWithInfo = [];
      data.subRoles.forEach((subRole) => {
        const role = rolesData.roles.find((role) => role.id === subRole.id);
        if (role) {
          subRolesWithInfo.push(role);
        }
      });
      const searchedSubRoles = search
        ? subRolesWithInfo.filter((role) => role.displayName.indexOf(search) !== -1)
        : subRolesWithInfo;
      setSubRoles(searchedSubRoles);

      setSelectedSubRoles([]);
    }
  }, [data?.subRoles, rolesData?.roles, search]);

  const removeSubRolesClicked = async (subRolesToRemove) => {
    setIsInProgress(true);
    await removeSubRoles.mutateAsync({
      roleId: row?.id,
      subRolesIds: subRolesToRemove.map((u) => u?.id),
    });
    setIsInProgress(false);
  };

  const removeSubRolesListClicked = async () => {
    const subRolesToRemove = subRoles.filter((_, index) => selectedSubRoles.includes(index));
    return removeSubRolesClicked(subRolesToRemove);
  };

  return isLoading || isInProgress ? (
    <div className="position-relative">
      <Spinner />
    </div>
  ) : (
    <div className={styles.rolesSubTabTableContainer}>
      <NewTableWrapper className="sub-table" isCompact>
        <RoleSubRolesTabHeader
          removeSubRoleClicked={removeSubRolesListClicked}
          subRolesCount={subRoles?.length}
          selectedSubRoles={selectedSubRoles}
          search={search}
          setSearch={setSearch}
          role={data}
        />
        <RoleSubRolesTabTable
          removeSubRoleClicked={(role) => removeSubRolesClicked([role])}
          subRoles={subRoles}
          selectedSubRoles={selectedSubRoles}
          setSelectedSubRoles={setSelectedSubRoles}
        />
      </NewTableWrapper>
    </div>
  );
};

export default RoleSubRolesTab;
