import React from 'react';
import useOrganization from '../hooks/reactQuery/useOrganization';

const OrgOnboardingContext = React.createContext();

export function withOrgOnboardingContextProvider(Component) {
  return function OrgOnboardingContextProvider({ ...props }) {
    const orgOnboarding = useOrganization();
    return (
      <OrgOnboardingContext.Provider
        value={{
          onboardOrganization: orgOnboarding.onboardOrganization,
        }}
      >
        <Component {...props} onboardOrganization={orgOnboarding.onboardOrganization} />
      </OrgOnboardingContext.Provider>
    );
  };
}
