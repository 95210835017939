/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';

class ExportChartTableToolbarButton extends PureComponent {
  static propTypes = {
    tableModifiedDailyBalance: PropTypes.object.isRequired,
    csvTitle: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  getHeaders = (data) => {
    const headers = {};
    if (data) {
      data.forEach((currData) => {
        Object.keys(currData).forEach((currKey) => {
          headers[currKey] = { label: currKey, key: currKey.includes('.') ? currKey.split('.').join('') : currKey };
        });
      });
    }
    return Object.values(headers);
  };

  render() {
    const { tableModifiedDailyBalance, csvTitle } = this.props;
    const headers = this.getHeaders(tableModifiedDailyBalance);
    return (
      <div className="topbar__profile">
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '35px',
              minWidth: '45px',
              padding: '10px',
            }}
          >
            <p>
              <CustomCSVDownload
                data={[{ data: tableModifiedDailyBalance, filename: csvTitle }]}
                headers={headers}
                showDownloadIcon
                isLoading={false}
                filesNumber={1}
              />
            </p>
          </div>
        </Row>
      </div>
    );
  }
}

export default ExportChartTableToolbarButton;
