/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-param-reassign, no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import DownloadAsPngLink from 'shared/components/DownloadAsPngLink';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import { createDateDisplayStr, dateFormats, parseDateFormatFromDate } from 'shared/utils/dateUtil';
import { StringToColor } from 'shared/utils/graphicUtil';
import If from 'shared/components/SimpleIf';
import ChartKeysFilter from 'shared/components/ChartKeysFilter/ChartKeysFilter';
import CustomTooltip from 'shared/components/CustomTooltip';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { observer } from 'mobx-react';

class SimpleLineChart extends PureComponent {
  static propTypes = {
    chartId: PropTypes.string.isRequired,
    addKeysFilterHandler: PropTypes.func.isRequired,
    setKeysFilterHandler: PropTypes.func.isRequired,
    removeKeysFilterHandler: PropTypes.func.isRequired,
    onShowOthersChecked: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    chartKeysData: PropTypes.object.isRequired,
    favourites: PropTypes.object.isRequired,
    filteredDataKeys: PropTypes.object.isRequired,
    XAxisDataKey: PropTypes.string.isRequired,
    YAxisLabelTitle: PropTypes.string.isRequired,
    title: PropTypes.number.isRequired,
    showTitleHeader: PropTypes.bool.isRequired,
    isRenderDownloadAsPng: PropTypes.bool,
    wrapInCardContainer: PropTypes.bool.isRequired,
    displayedMetric: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    granLevel: PropTypes.string,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isRenderDownloadAsPng: false,
    granLevel: null,
  };

  constructor() {
    super();
    this.state = {
      currKey: '',
    };
    this.chartRef = React.createRef();
  }

  prepareFilterKeysFromDataKeys = (dataKeys) => {
    if (dataKeys) {
      const filterKeys = dataKeys.map((key, idx) => ({ id: idx, name: key.name }));
      return filterKeys;
    }

    return [];
  };

  modifyXAxisTickFormatter = (timeVal, granLevel) => {
    const format = granLevel || parseDateFormatFromDate(timeVal);
    if (format && format !== dateFormats.weekly) {
      return createDateDisplayStr(timeVal, granLevel || undefined);
    }
    return timeVal || '';
  };

  renderLineChart = () => {
    const {
      data,
      favourites,
      isRenderDownloadAsPng,
      XAxisDataKey,
      YAxisLabelTitle,
      addKeysFilterHandler,
      setKeysFilterHandler,
      removeKeysFilterHandler,
      onShowOthersChecked,
      // dataKeys,
      // filteredDataKeys,
      filteredDataKeys,
      chartKeysData,
      chartId,
      displayedMetric,
      granLevel,
      numStrAbriviaionByDisplayMetric,
    } = this.props;
    const modifiedData = data;
    const finalChartId = chartId || `line-chart-${random5DigitNumber()}`;
    return (
      <>
        <If cond={isRenderDownloadAsPng}>
          <DownloadAsPngLink chartRef={this.chartRef} />
        </If>

        <Row id={finalChartId} className="chart-box-style">
          <ResponsiveContainer height={this.props.height || 300} ref={this.chartRef}>
            <LineChart
              data={modifiedData}
              // data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis
                // interval={0}
                dataKey={XAxisDataKey || 'date'}
                tickFormatter={(value) => this.modifyXAxisTickFormatter(value, granLevel)}
                interval="preserveStartEnd"
                // angle={30}
              />
              <YAxis
                domain={['dataMin', 'dataMax']}
                interval={0}
                label={{
                  value: `${YAxisLabelTitle}`,
                  offset: 0,
                  angle: -90,
                  position: 'left',
                }}
                tickFormatter={(value) =>
                  numStrAbriviaionByDisplayMetric(value, value, displayedMetric, false, { isTrunc: true })
                }
              />
              <Tooltip
                content={
                  <CustomTooltip data={modifiedData} currKey={this.state.currKey} displayedMetric={displayedMetric} />
                }
              />
              <CartesianGrid strokeDasharray="3 3" />
              {/* {this.renderLines()} */}
              {this.renderLines(filteredDataKeys)}
            </LineChart>
          </ResponsiveContainer>
        </Row>
        <Row style={{ 'align-self': 'center' }}>
          <ChartKeysFilter
            data={chartKeysData}
            favourites={favourites}
            filteredKeys={filteredDataKeys}
            addKeysFilterHandler={addKeysFilterHandler}
            setKeysFilterHandler={setKeysFilterHandler}
            removeKeysFilterHandler={removeKeysFilterHandler}
            onShowOthersChecked={onShowOthersChecked}
            filteredDataKeys={filteredDataKeys.map((dataKey) => dataKey.name)}
          />
        </Row>
      </>
    );
  };

  renderLines = (dataKeys) =>
    dataKeys.map((dataKey) => {
      const customMouseOver = () => {
        if (dataKey.name !== this.state.currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return (
        <Line
          strokeWidth={1.5}
          type="monotone"
          dataKey={dataKey.name}
          stroke={
            dataKey.name === CostTrackingConstants.OTHERS
              ? CostTrackingConstants.OTHERS_NAME_COLOR
              : StringToColor.next(dataKey.name)
          }
          onMouseOver={customMouseOver}
        />
      );
    });

  render() {
    const { showTitleHeader, title, wrapInCardContainer } = this.props;

    return wrapInCardContainer ? (
      <Container>
        <Row>
          <Card>
            {showTitleHeader && (
              <CardHeader style={{ backgroundColor: 'white' }}>
                <Row>
                  <Col>
                    <h5 className="bold-text">{title}</h5>
                  </Col>
                </Row>
              </CardHeader>
            )}
            <CardBody>{this.renderLineChart()}</CardBody>
          </Card>
        </Row>
      </Container>
    ) : (
      this.renderLineChart()
    );
  }
}

const observerSimpleLineChart = withUserSettingsConsumer(SimpleLineChart);
export default observerSimpleLineChart;
