/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

// TODO update description
const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      {`This EC2 instance is stopped but carries additional EBS and/or Public IP charges.
You should consider terminating it.`}
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
