import LabelCoordinator from 'shared/modules/labelCoordinator';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { azureCommonFieldToDisplayField } from '../constants/azureConstants';
import { getAccountTagFilterLabel, getBusinessMappingFilterLabel } from './awsUtils';
import { AwsCommonFields } from '../constants/awsConstants';
import { getCustomTagFilterLabel } from './sharedUtils';

export const getDisplayAzureLabel = (rawAzureName) => LabelCoordinator.getServiceNameDisplayValue(rawAzureName);

const familyTypesWithTypes = [];

export const getFieldDisplayLabel = (field, secondLevelItemsValuesMap) => {
  let label = '';
  if (field) {
    // eslint-disable-next-line no-nested-ternary,max-len
    label = field.includes('customtags')
      ? getCustomTagFilterLabel(field, CLOUD_TYPE_IDS.AZURE)
      : azureCommonFieldToDisplayField.has(field.toLowerCase())
      ? azureCommonFieldToDisplayField.get(field)
      : field;
    if (field.includes('accounttags')) {
      label = getAccountTagFilterLabel(field);
    }
    if (field.includes(AwsCommonFields.BUSINESS_MAPPING)) {
      let fieldValue = field;
      if (secondLevelItemsValuesMap) {
        fieldValue = ([...secondLevelItemsValuesMap.entries()].find(([, v]) => v === field) || [])[0] || field;
      }
      label = getBusinessMappingFilterLabel(fieldValue);
    }
  }

  return label;
};

// const servicesWithFamilyTypes = ['Bandwidth', 'Storage', 'Virtual Machines', 'Virtual Network'];
const servicesWithFamilyTypes = ['Storage'];

// this function is needed
export const isServiceHasTypes = () => false;

export const isServiceHasFamilyType = (serviceName) => servicesWithFamilyTypes.includes(serviceName);

export const isFamilyTypeHasTypes = (familyType) => familyTypesWithTypes.includes(familyType);
