import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper';
import { ReactComponent as GCPIcon } from 'shared/img/cloud-providers/simple/gcp.svg';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { GCP_ONBOARDING_FIELDS, GCP_ONBOARDING_STEPS } from './GcpOnboardingConstants';
import useFormValidation from './hooks/useFormValidation';
import useFormNavigation from './hooks/useFormNavigation';
import AccountDetailsModal from './pages/AccountDetailsModal';
import LoadingGif from '../assets/page-loading.gif';
import { getPageForPausedOnboarding } from './GcpOnboardingUtils';
import Footer from '../SharedPages/Footer/Footer';
import { ONBOARDING_TYPES } from '../utils/OnboardingConstants';
import { GCP_ONBOARDING_STEPS_CONFIG } from './GcpOnboardingStepsConstants';
import styles from './GcpOnboarding.module.scss';
import WarningsList from '../SharedPages/WarningsList/WarningsList';

const GCPOnboarding = withRouter(({ usersStore, location, history }) => {
  const { getValues } = useFormContext();
  const onboardingType = getValues(GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const screens = [
    { id: GCP_ONBOARDING_STEPS.CREATE_APP, disabled: true },
    // TODO: enable when videos are ready onboardingType === ONBOARDING_TYPES.AUTOMATIC },
    {
      id: GCP_ONBOARDING_STEPS.GCP_DETAILS,
    },
    { id: GCP_ONBOARDING_STEPS.K8S_INTEGRATION, subTitle: '(Optional)', isDotted: true },
    { id: GCP_ONBOARDING_STEPS.PROCESS_DATA },
  ]
    .filter(({ disabled }) => !disabled)
    .map(({ id, subTitle, isDotted }) => ({
      id,
      subTitle,
      isDotted,
      title: GCP_ONBOARDING_STEPS_CONFIG[id].titleShort,
    }));
  const screen = getValues(GCP_ONBOARDING_FIELDS.SCREEN);
  const screenIndex = screens.findIndex((scr) => scr.id === screen);
  useWatch(GCP_ONBOARDING_FIELDS.SCREEN);
  useWatch(GCP_ONBOARDING_FIELDS.ACCOUNT_ID);
  const { prevStep, nextStep } = useFormNavigation({ screen, location, history, screens });
  if (screen === GCP_ONBOARDING_STEPS.ACCOUNT_TYPE) {
    return <AccountDetailsModal nextStep={nextStep} />;
  }
  const { component: Page, title, automaticTitle } = GCP_ONBOARDING_STEPS_CONFIG[screen];
  const getTitle = () => {
    if (onboardingType === ONBOARDING_TYPES.AUTOMATIC && automaticTitle) {
      return automaticTitle;
    }
    return title;
  };
  return (
    <div>
      <div className={styles.container}>
        <Stepper activeStepIndex={screenIndex} steps={screens} />
        <div className={styles.pageScreen}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              {screenIndex + 1}. {getTitle()}
            </div>
            <div className={styles.headerLogo}>
              <GCPIcon />
            </div>
          </div>
          <div className={styles.body}>{Page && <Page usersStore={usersStore} />}</div>
          <Footer steps={GCP_ONBOARDING_STEPS_CONFIG} prevStep={prevStep} nextStep={nextStep} screen={screen} />
        </div>
      </div>
    </div>
  );
});

const GCPOnboardingWrapper = withRouter(({ usersStore, divisionsStore, location }) => {
  const isMSP = usersStore.isCurrentUserReseller;
  const DEFAULT_FORM_STATE = {
    accountId: '',
    accountName: '',
    [GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE]: ONBOARDING_TYPES.MANUAL,
    [GCP_ONBOARDING_FIELDS.ACCOUNT_TYPE]: isMSP ? 'dedicated' : null,
    [GCP_ONBOARDING_FIELDS.RESELLER_CUSTOMER]: '',
    [GCP_ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID]: '',
    [GCP_ONBOARDING_FIELDS.IS_MSP]: isMSP,
    [GCP_ONBOARDING_FIELDS.USER_KEY]: usersStore.currentDisplayedUserKey,
    [GCP_ONBOARDING_FIELDS.ACCOUNT_KEY]: usersStore.currDispUserAccountKey,
    screen: GCP_ONBOARDING_STEPS.ACCOUNT_TYPE,
  };

  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get('accountId');
  const screen = searchParams.get('screen');
  const { fetchOnboardingStatus } = useOnboarding();
  const methods = useForm({
    defaultValues: {
      ...DEFAULT_FORM_STATE,
      [GCP_ONBOARDING_FIELDS.SCREEN]: screen || DEFAULT_FORM_STATE.screen,
    },
    resolver: (currentStream) => useFormValidation(currentStream),
    shouldFocusError: true,
  });
  const accountIdOnboarding = accountId || methods.getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_ID);
  const { data: onboarding, isLoading } = fetchOnboardingStatus(accountIdOnboarding, {
    enabled: !!accountIdOnboarding,
    refetchInterval: 15 * 1000,
  });
  const [isLoadingDebounced, setIsLoadingDebounced] = useState(!!accountId);
  useEffect(() => {
    if (isLoading) {
      setIsLoadingDebounced(true);
    } else {
      setTimeout(() => setIsLoadingDebounced(false), 1000);
    }
  }, [isLoading]);
  useEffect(() => {
    if (!onboarding || !accountId || accountId === methods.getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_ID)) {
      return;
    }
    if (!onboarding?.accountId) {
      methods.reset({
        ...DEFAULT_FORM_STATE,
        [GCP_ONBOARDING_FIELDS.ACCOUNT_ID]: accountId,
      });
    } else {
      const newScreen = screen || getPageForPausedOnboarding(onboarding);
      methods.reset({
        ...DEFAULT_FORM_STATE,
        ...onboarding,
        [GCP_ONBOARDING_FIELDS.ACCOUNT_ID]: onboarding.accountId,
        [GCP_ONBOARDING_FIELDS.ACCOUNT_NAME]: onboarding.accountName,
        [GCP_ONBOARDING_FIELDS.BILLING_DATASET_NAME]: onboarding.billingDatasetName,
        [GCP_ONBOARDING_FIELDS.BILLING_TABLE_NAME]: onboarding.billingTableName,
        [GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY]: onboarding.serviceAccountKey,
        [GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID]: onboarding.k8sProjectId,
        [GCP_ONBOARDING_FIELDS.K8S_DATASET_ID]: onboarding.k8sDatasetId,
        [GCP_ONBOARDING_FIELDS.CURRENCY_TYPE]: onboarding.currencyType,
        [GCP_ONBOARDING_FIELDS.STARTED]: true,
        [GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE]: onboarding.onboardingType,
        [GCP_ONBOARDING_FIELDS.VALIDATION_STARTED]: onboarding.validationStarted,
        [GCP_ONBOARDING_FIELDS.ACCOUNT_TYPE]: onboarding.accountType,
        [GCP_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS]: onboarding.autoAssignLinkedAccounts,
        [GCP_ONBOARDING_FIELDS.SCREEN]: newScreen,
      });
    }
  }, [onboarding?.accountId]);
  return (
    <FormProvider {...methods}>
      {isLoadingDebounced ? (
        <div className={styles.loadingPage}>
          <img src={LoadingGif} alt="loading" />
        </div>
      ) : (
        <div className="h-100">
          <GCPOnboarding usersStore={usersStore} divisionsStore={divisionsStore} />
          {onboarding?.validationStatus?.errors?.length ? (
            <WarningsList warnings={onboarding.validationStatus.errors} />
          ) : null}
        </div>
      )}
    </FormProvider>
  );
});

GCPOnboardingWrapper.propTypes = {
  usersStore: PropTypes.object.isRequired,
  divisionsStore: PropTypes.object.isRequired,
};
GCPOnboarding.propTypes = {
  usersStore: PropTypes.object.isRequired,
  divisionsStore: PropTypes.object.isRequired,
};

export default GCPOnboardingWrapper;
