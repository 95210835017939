import React from 'react';
import PropTypes from 'prop-types';

const CustomizedLabel = (props) => {
  const { x, y, value, fontSize } = props;
  return (
    <text x={x} y={y} dy={-4} fontSize={fontSize} fontFamily="sans-serif" textAnchor="middle" fill="8a85ea">
      {value}
    </text>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  value: PropTypes.object.isRequired,
};

export default CustomizedLabel;
