import React from 'react';
import lodash from 'lodash';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import PropTypes from 'prop-types';
import { copyStrToClipboard } from '../utils/strUtil';

const copyToClipboard = (id) => {
  const copyText = document.getElementById(id).textContent;
  copyStrToClipboard(copyText);
};

// eslint-disable-next-line react/prop-types
const CopyFromCmdBoxButton = ({ boxId }) => (
  <button
    className="form__form-group-button"
    style={{ float: 'right' }}
    onClick={() => copyToClipboard(boxId)}
    type="button"
  >
    <ContentCopyIcon />
  </button>
);

const CodeBlock = React.memo(
  ({ text, variables, quote = '' }) => {
    const randId = Math.random().toString();
    const renderText = () => {
      let textResult = text;
      while (textResult?.match(/{{[\w\d]*}}/)) {
        const variable = textResult.match(/{{([\w\d]*)}}/)[1];
        textResult = textResult.replace(
          `{{${variable}}}`,
          `<span style="color: red">${quote}${variables[variable]}${quote}</span>`,
        );
      }
      return textResult;
    };
    return (
      <div className="project-summary__copy-button" style={{ maxWidth: '100%' }}>
        <CopyFromCmdBoxButton boxId={randId} />
        <pre id={randId}>
          <code dangerouslySetInnerHTML={{ __html: renderText() }} style={{ whiteSpace: 'normal' }} />
        </pre>
      </div>
    );
  },
  (props, prev) => {
    const { text = '', variables } = props;
    const { text: prevText = '', variables: prevVariables } = prev;
    return text === prevText && lodash.deepEqual(variables, prevVariables);
  },
);

CodeBlock.propTypes = {
  text: PropTypes.string.isRequired,
  variables: PropTypes.object.isRequired,
  quote: PropTypes.string,
};

CodeBlock.defaultProps = {
  quote: '',
};

export default CodeBlock;
