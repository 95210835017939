/* eslint-disable max-len */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { UsersType } from 'users/constants/usersConstants';
import apiConstants from 'shared/api/apiConstants';
import { getRealmLocally } from 'users/containers/LogIn/keycloak.service';

class AppStore {
  isLoading = true;
  isAccountLoading = true;
  isAdminStoreDataReq = true;
  isPpApplied = false;
  isCustomerPricingMode = true;

  constructor(rootStore, apiGateway) {
    this.rootStore = rootStore;
    this.apiGateway = apiGateway;
    makeObservable(this, {
      isLoading: observable,
      isAccountLoading: observable,
      isPpApplied: observable,
      isKeyCloakManagement: computed,
      isCustomerPricingMode: observable,
      setIsAccountLoading: action,
      updatePricingMode: action,
    });
  }

  get isKeyCloakManagement() {
    return !!getRealmLocally();
  }

  updateMarginIfOfTypeReseller = () => {
    const { usersStore } = this.rootStore;
    const user = usersStore && usersStore.getUserByKey(usersStore.currentDisplayedUserKey);
    if (!user) {
      return;
    }
    if (
      user.userType !== UsersType.RESELLER &&
      user.userType !== UsersType.RESELLER_CUSTOMER &&
      user.userType !== UsersType.RESELLER_CUSTOMER_EDP
    ) {
      this.updatePricingMode(false);
    } else {
      const isCustomerPricingMode = !!JSON.parse(
        window.localStorage.getItem('isPpApplied') ?? this.isCustomerPricingMode,
      );
      this.updatePricingMode(isCustomerPricingMode);
    }
  };
  getIsPpApplied = (currDispUser) => {
    if (currDispUser === UsersType.RESELLER) {
      return this.isCustomerPricingMode;
    }
    return [UsersType.RESELLER_CUSTOMER, UsersType.RESELLER_CUSTOMER_EDP].includes(currDispUser);
  };
  updateMargin = () => {
    const { currentDisplayedUserType } = this.rootStore.usersStore;
    const { queryClient } = this.rootStore;
    const isPpApplied = this.getIsPpApplied(currentDisplayedUserType);
    const oldPpApplied = this.isPpApplied;
    this.isPpApplied = isPpApplied;
    if (queryClient && oldPpApplied !== isPpApplied) {
      queryClient.invalidateQueries([apiConstants.QUERY_KEYS.PRICING_MODE_CHANGED]);
      window.localStorage.setItem('isPpApplied', isPpApplied);
    }
  };
  updatePricingMode = (isCustomerPricingMode = true) => {
    this.isCustomerPricingMode = isCustomerPricingMode;
    const cloudAccountType = this.rootStore.usersStore.currDispUserCloudAccountType;
    this.updateMargin(cloudAccountType);
  };
  getCommonParams = () => {
    this.updateMarginIfOfTypeReseller();
    return { isPpApplied: this.isPpApplied };
  };

  fetchData = async () => {
    try {
      runInAction(() => {
        this.isLoading = false;
        this.isAdminStoreDataReq = false;
      });
    } catch (error) {
      this.state = 'error';
    }
  };

  setIsAccountLoading = (isAccountLoading) => {
    this.isAccountLoading = isAccountLoading;
  };

  invalidateStore = () => {
    this.isLoading = true;
    this.isAdminStoreDataReq = true;
    this.isCustomerPricingMode = true;
    this.isPpApplied = false;
  };
}

export default AppStore;
