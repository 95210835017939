import React from 'react';
import PropTypes from 'prop-types';
import { DB_PROPERTIES, OUTDATED_SNAPSHOT_PROPERTIES } from '../../recommendationPropertyUtils';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const BackupOutdatedSnapshot = ({ recommendation }) => {
  const description = `This AWS backup snapshot age is 2m 22d, to avoid unnecessary costs,
   we recommend to delete the snapshot after 21 days.`;

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `With the list of old snapshots or snapshots associated with a specific tag, you can execute the delete-snapshot command to delete them.`,
        },
      ],
    },
  ];
  return (
    <BaseRecommendation
      recommendation={recommendation}
      dynamicProps={{ ...DB_PROPERTIES, ...OUTDATED_SNAPSHOT_PROPERTIES }}
      commandsList={commandsList}
      description={description}
    />
  );
};

BackupOutdatedSnapshot.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default BackupOutdatedSnapshot;
