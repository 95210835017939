import React from 'react';

import { HeatMapProvider } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import { RecommendationsNewProvider } from 'recommendationsNew/contexts/recommendationsNewContext';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import HeatMap from 'recommendationsNew/components/heatMap/heatMap';
import NewRecommendationContext from '../NewRecommendationDashboardContext';

const NewRecommendationHeatMap = (props) => {
  const { state, usersStore } = props;
  return (
    <RecommendationsNewProvider>
      <TableHeaderProvider>
        <HeatMapProvider>
          <NewRecommendationContext filterState={state} />
          <HeatMap usersStore={usersStore} />
        </HeatMapProvider>
      </TableHeaderProvider>
    </RecommendationsNewProvider>
  );
};

export default NewRecommendationHeatMap;
