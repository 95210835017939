export const CUSTOM_DASHBOARDS_PANELS_TABLE_COLUMNS = [
  { name: 'name', title: 'Name' },
  { name: 'createdBy', title: 'Created By' },
  { name: 'creationDate', title: 'Creation Date' },
  { name: 'userAction', title: ' ' },
];

export const CUSTOM_DASHBOARDS_PANELS_COLUMN_WIDTHS = [
  { columnName: 'selectPanelCheckbox', width: 50 },
  { columnName: 'name', width: 200 },
  { columnName: 'createdBy', width: 200 },
  { columnName: 'creationDate', width: 200 },
  { columnName: 'userAction', width: 50 },
];

export const CUSTOM_DASHBOARDS_PANELS_TABLE_COLUMN_EXTENSIONS = [];
