/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SubViewPanel from './SubViewPanel';
import * as constants from '../constants/viewsConstants';

const SubViewsWrapper = memo((props) => {
  if (!props.subViews || !props.subViews.length) {
    return <></>;
  }
  return (
    <>
      {props.subViews.map((subView) => (
        <SubViewPanel
          usersStore={props.usersStore}
          isBlock={props.isBlock}
          subView={subView}
          keysToDisplayMap={constants.keysToDisplayMap}
          handlers={props.handlers}
          isActionsButton={props.isActionsButton}
        />
      ))}
    </>
  );
});

export default SubViewsWrapper;
SubViewsWrapper.propTypes = {
  usersStore: PropTypes.object.isRequired,
  subViews: PropTypes.object.isRequired,
  isBlock: PropTypes.bool,
  isActionButtons: PropTypes.bool,
  handlers: PropTypes.object,
};
SubViewPanel.defaultProp = {
  handlers: {},
  isActionsButton: false,
  isBlock: false,
};
