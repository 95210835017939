import PropTypes from 'prop-types';
import React from 'react';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import AssetsTable from '../../../Assets/components/AssetsTable';
import * as tableHelpers from '../../../Assets/helpers/assetsTableHelper';

const AssetsPanelTable = ({ data, selectedColumns, isK8S, costTypes, usersStore, overrideCurrency, granularity }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const columns = tableHelpers.prepareTableColumns(
    selectedColumns,
    isK8S,
    costTypes,
    usersStore.currDispUserCloudAccountType,
    getCurrencyNumber,
    overrideCurrency,
  );
  return (
    <AssetsTable
      usersStore={usersStore}
      data={data}
      columns={columns}
      columnsWidths={tableHelpers.prepareTableColumnsWidths(columns)}
      costColumnsArray={tableHelpers.prepareTableCostColumns(costTypes || [])}
      currenciesColumns={[]}
      tableColumnExtensions={[]}
      overrideCurrency={overrideCurrency}
      granularity={granularity}
    />
  );
};

AssetsPanelTable.propTypes = {
  data: PropTypes.object.isRequired,
  isK8S: PropTypes.bool.isRequired,
  selectedColumns: PropTypes.object.isRequired,
  costTypes: PropTypes.array,
  usersStore: PropTypes.object.isRequired,
  granularity: PropTypes.string.isRequired,
  overrideCurrency: PropTypes.string,
};
AssetsPanelTable.defaultProps = {
  costTypes: [],
  overrideCurrency: null,
};

export default AssetsPanelTable;
