import { useEffect, useRef, useState } from 'react';

export function useDynamicSvgImport(cloudTypeString, cloudMapper, label) {
  const importedIconRef = useRef();
  const [loading, setLoading] = useState(false);

  const defaultCloudIcon = `${cloudTypeString?.toUpperCase()}Generic`;

  useEffect(() => {
    setLoading(true);
    const importSvgIcon = async () => {
      try {
        importedIconRef.current = (
          await import(`../servicesIcons/${cloudTypeString}/${cloudMapper[label]}.svg`)
        ).ReactComponent;
      } catch {
        importedIconRef.current = (
          await import(`../servicesIcons/${cloudTypeString}/${defaultCloudIcon}.svg`)
        ).ReactComponent;
      } finally {
        setLoading(false);
      }
    };

    importSvgIcon();
  }, [cloudTypeString, cloudMapper, label]);

  return { loading, SvgIcon: importedIconRef.current };
}
