import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { usePreferences } from 'users/hooks/react-query/usePreferences';
import { useRootStore } from 'app/contexts/RootStoreContext';

import { startCase } from 'lodash';
import classes from './recommendationPreferences.module.scss';

const hiddenPreferences = ['isPreferencesHidden', 'enabled'];
const RecommendationPreferences = ({ recommendationType }) => {
  const { usersStore } = useRootStore();

  const [recommendationPreferences, setRecommendationPreferences] = useState([]);

  const preferencesHook = usePreferences();
  const { data: preferences } = preferencesHook.fetchPreferences(usersStore.currDispUserAccountKey);

  useEffect(() => {
    if (preferences) {
      const recPreferences = preferences?.find((pref) => pref.preferenceKey === recommendationType);
      const pref = recPreferences?.preferences || {};
      const filteredPreferencesKeys = Object.keys(pref)
        .filter((key) => !hiddenPreferences.includes(key))
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = pref[key];
          return obj;
        }, {});

      setRecommendationPreferences(filteredPreferencesKeys);
    }
  }, [preferences, recommendationType]);

  const getPreferenceValue = (preferenceValue) => {
    if (typeof preferenceValue === 'boolean') {
      return preferenceValue ? 'Yes' : 'No';
    }
    return preferenceValue.toString();
  };

  return Object.keys(recommendationPreferences).length > 0 ? (
    <div className={classes.preferencesContainer}>
      <div className={classes.titleContainer}>
        <GenerateIcon iconName={ICONS.gearRegular.name} className={classes.titleIcon} />
        <div className={classes.titleText}>Preferences</div>
      </div>
      <div className={classes.preferences}>
        {Object.keys(recommendationPreferences)?.map((key) => (
          <div className={classes.preferenceProperty}>
            <div className={classes.propertyTitle}>
              {preferences.preferencesTitles && preferences.preferencesTitles[key]
                ? preferences.preferencesTitles[key]
                : `${startCase(key)}:`}
            </div>
            <div className={classes.propertyValue}>{getPreferenceValue(recommendationPreferences[key])}</div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

RecommendationPreferences.propTypes = {
  recommendationType: PropTypes.string.isRequired,
};

export default RecommendationPreferences;
