import React from 'react';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>
      Management events that are delivered for both member account and management account will occur duplicated charges.
      <br />
      We therefore suggest to terminate the trail associated with the member account.
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {};

export default Description;
