/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const UserAuthorizationDisplayWrapper = (props) => {
  const disabledStyle = { pointerEvents: 'none', opacity: '0.4' };
  const hideStyle = { display: 'none' };

  const checkIfUserOnAuthorizedList = (authorizedUsersTypeList, viewerUserType) => {
    let isOnList = false;
    isOnList = authorizedUsersTypeList.some((ut) => ut === viewerUserType);
    return isOnList;
  };
  const getStyle = () => {
    let style = {};
    let isAuthorized = false;
    try {
      isAuthorized = checkIfUserOnAuthorizedList(props.authorizedUsersTypeList, props.viewerUserType);
    } catch (error) {
      isAuthorized = false;
    }
    if (!isAuthorized) {
      style = props.isHide ? hideStyle : disabledStyle;
    }
    return style;
  };
  return <div style={getStyle()}>{props.children}</div>;
};

UserAuthorizationDisplayWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  viewerUserType: PropTypes.number.isRequired,
  authorizedUsersTypeList: PropTypes.array.isRequired,
  isHide: PropTypes.bool,
};
UserAuthorizationDisplayWrapper.defaultProps = {
  isHide: true,
};

export default UserAuthorizationDisplayWrapper;
