import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import classes from './statusSelection.module.scss';

export const StatusSelection = ({ isSelected, onClick, automationId }) => (
  <div
    className={`${classes.selectionButton} ${isSelected ? classes.selected : ''}`}
    onClick={onClick}
    automation-id={automationId}
  >
    {isSelected ? <GenerateIcon iconName={ICONS.check.name} className={classes.checkMark} /> : ''}
  </div>
);
