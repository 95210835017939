const prepareTableColumns = (columns, data) => {
  const isNotActiveRow = data.some((row) => row.isActive === 0);
  const isLastUpdateCol = columns.some((column) => column.name === 'lastUpdate');
  if (isNotActiveRow && !isLastUpdateCol) {
    // do something
    const newColumns = columns;
    newColumns.push({
      name: 'lastUpdate',
      title: 'Last Update Time',
      getCellValue: (row) => (row.isActive ? null : row.usageDate.slice(0, 10)),
    });
    return newColumns;
  }
  return columns;
};

export default prepareTableColumns;
