import { FilterTypes } from 'usage/constants/usageConstants';

export const prepareSPAnalyzerFilters = (selectedOptions, filterStatusTypeMap) => {
  let includeFilterParams = '';
  let excludeFilterParams = '';
  if (selectedOptions && selectedOptions.size > 0) {
    selectedOptions.forEach((filterValues, filter) => {
      if (filterValues && filterValues.length) {
        filterValues.forEach((filterValue) => {
          if (filterStatusTypeMap.get(filter) === FilterTypes.INCLUDE) {
            includeFilterParams = includeFilterParams.concat(`&includeFilters[${filter}]=${filterValue.value}`);
          } else if (filterStatusTypeMap.get(filter) === FilterTypes.EXCLUDE) {
            excludeFilterParams = excludeFilterParams.concat(`&excludeFilters[${filter}]=${filterValue.value}`);
          }
        });
      }
    });
  }

  return { includeFilterParams, excludeFilterParams };
};
