import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { createReportObject } from 'usage/utils/reportsUtil';
import { queryClient } from 'queryClient';

export const BILLINGS_API_NAME = 'billings';
const root = '/api/v1/usage/reports';

const fetchReportsApi = async (userKey) => {
  const result = await API.get(BILLINGS_API_NAME, `${root}/all`);
  return result.map((report) => createReportObject(userKey, report));
};

export const reportsProvider = () => ({
  fetchReports: async (userKey, accountKey) => {
    try {
      const queryKeyFetch = [apiConstants.QUERY_KEYS.REPORTS, userKey, accountKey];
      const data = await queryClient.fetchQuery(queryKeyFetch, () => fetchReportsApi(userKey), {
        retry: false,
        staleTime: Infinity,
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
});
