import React, { useEffect, useMemo, useState } from 'react';

import useTable from 'shared/hooks/customHooks/useTable';
import useRecsList from 'recommendationsNew/hooks/react-query/useRecsList';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { getTagsColumnsForCsv } from 'shared/utils/exportTagUtil';
import useUserRecAction from 'recommendationsNew/hooks/react-query/useUserRecAction';
import { camelCase, startCase } from 'lodash';
import {
  ACTION_STATUS,
  getColumnHeaderByName,
  ACTION_TYPE,
  FILTERS,
  getSortOrder,
  RESERVED_INSTANCE_TYPES,
  AlternativesColumns,
  getSavingColumnHeader,
} from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import moment from 'moment/moment';
import { useFilterStatus } from 'recommendationsNew/hooks/useFilterStatus';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import RecommendationsTableHeader from './recommendationsTableHeader';
import RecommendationExcluded from './recommendationExcluded';
import RecommendationLabel from './recommendationLabel';

import classes from './recommendationsList.module.scss';
import RecommendationGrid from './recommendationGrid';

const recommendationsColumns = (cloudType, statusFilter, typeIds) => {
  const columns = [
    {
      name: 'savings',
      title: getSavingColumnHeader(statusFilter),
      getCellValue: (row) => row.annualSavings?.unblended,
      statusFilter,
    },
    {
      name: 'annualCost',
      title: 'Current Annual Cost',
      getCellValue: (row) => row.annualCurrentCost?.unblended,
    },
    { name: 'typeName', title: 'Recommendation Type' },
    {
      name: 'linkedAccount',
      title: getLinkedAccountName(cloudType),
      getCellValue: (row) =>
        row.linkedAccountId ? `${row.linkedAccountName || ''} (${row.linkedAccountId})` : row.linkedAccountName,
    },
    {
      name: 'resource',
      title: 'Resource',
      getCellValue: (row) => {
        if (!row.resourceName) {
          return row.resourceId;
        }
        return row.resourceId && row.resourceName !== row.resourceId
          ? `${row.resourceName || ''} (${row.resourceId})`
          : row.resourceName;
      },
    },
    { name: 'age', title: 'Age' },
    { name: 'region', title: 'Region' },
    { name: 'customTags', title: 'Tags', getCellValue: (row) => row.customTags },
    { name: 'enrichmentTags', title: 'Enrichment Tags', getCellValue: (row) => row.customTags },
  ];
  const completeColumns = [
    { name: 'closeReason', title: 'Completed Reason', getCellValue: (row) => startCase(camelCase(row?.closeReason)) },
    { name: 'selectedInstanceType', title: 'Selected Instance Type', getCellValue: (row) => row.recData?.selected_instance_type },
  ];
  if (typeIds?.length === 1 && RESERVED_INSTANCE_TYPES.includes(typeIds[0].value)) {
    columns.push({
      name: 'recommendedPlan',
      title: 'Recommended Plan',
      isRecData: true,
      getCellValue: (row) => row?.recData?.recommended_plan,
    });
  }
  if (statusFilter.isExclude) {
    const excludedColumns = [
      ...columns,
      {
        name: 'excludedPeriod',
        title: 'Exclusion Period',
        getCellValue: (row) => (row.userStatus?.status === ACTION_STATUS.excluded ? row.userStatus?.period : ''),
      },
      {
        name: 'excludedReason',
        title: 'Exclusion Reason',
        getCellValue: (row) => (row.userStatus?.status === ACTION_STATUS.excluded ? row.userStatus?.reason : ''),
      },
      { name: 'instanceType', title: 'Instance Type' },
    ];
    if(statusFilter.isCompleted) {
      return [...excludedColumns, ...completeColumns];
    }
    return excludedColumns;
  }

  const mergedColumns = [...columns, { name: 'instanceType', title: 'Instance Type' }]
  if(statusFilter.isCompleted) {
    return [...mergedColumns, ...completeColumns];
  }
  return mergedColumns;
};
const lastColumns =  [
  { name: 'labels', title: 'Labels' },
  {
    name: 'createdAtDateTime',
    title: 'Open Date',
    getCellValue: (row) => moment(row.createdAtDateTime).format('YYYY-MM-DD'),
  },
  {
    name: 'lastProcessingDate',
    title: 'Completed Date',
    getCellValue: (row) => (row.open ? '' : row.lastProcessingDate),
  },
  {
    name: 'userActionDate',
    title: 'User Action Date',
    getCellValue: (row) => {
      const formattedUserActionDate = row.userStatus?.updatedTime
        ? moment(row.userStatus?.updatedTime).format('yyyy-MM-DD HH:mm')
        : '';
      return formattedUserActionDate;
    },
  },
  {
    name: 'userActionCreated',
    title: 'Marked By',
    getCellValue: (row) => row.userStatus?.createdByDisplayName || 'N/A',
  },
  { name: 'recId', title: 'Id' },
  {
    name: 'monthlySavings',
    title: 'Monthly Potential Savings',
    getCellValue: (row) => row.monthlySavings?.unblended,
  }];

const pageSize = 20;

const actionsColumns = [
  // { name: 'assignee', title: 'Assignee' },

  { name: 'userStatus', title: '' },
  { name: 'star', title: '' },
  { name: 'comment', title: '' },
  { name: 'status', title: '' },
  { name: 'userActions', title: '' },
];

const recommendationColumnsWidths = [
  { columnName: 'savings', width: 170 },
  { columnName: 'typeName', width: 180 },
  { columnName: 'linkedAccount', width: 207 },
  { columnName: 'resource', width: 205 },
  { columnName: 'age', width: 75 },
  { columnName: 'region', width: 85 },
  { columnName: 'instanceType', width: 110 },
  { columnName: 'recommendedPlan', width: 180 },
  { columnName: 'customTags', width: 100 },
  { columnName: 'enrichmentTags', width: 123 },
  { columnName: 'closeReason', width: 183 },
  { columnName: 'selectedInstanceType', width: 183 },
  // { columnName: 'assignee', width: 60 },
  { columnName: 'star', width: 50 },
  { columnName: 'comment', width: 50 },
  { columnName: 'status', width: 60 },
  { columnName: 'userStatus', width: 40 },
  { columnName: 'userActions', width: 40 },
  { columnName: 'monthlySavings', width: 120 },
  { columnName: 'excludedPeriod', width: 140 },
  { columnName: 'excludedReason', width: 140 },
  { columnName: 'labels', width: 120 },
  { columnName: 'recId', width: 120 },
  { columnName: 'createdAtDateTime', width: 105 },
  { columnName: 'lastProcessingDate', width: 120 },
  { columnName: 'userActionDate', width: 140 },
  { columnName: 'userActionCreated', width: 125 },
  { columnName: 'annualCost', width: 180 },
];

const exportColumn = (cloudType, statusFilter) => [
  { label: getSavingColumnHeader(statusFilter), key: 'savings' },
  { label: 'Current Annual Cost', key: 'annualCost' },
  { label: 'Recommendation Type', key: 'typeName' },
  { label: `${getLinkedAccountName(cloudType)} Name`, key: 'linkedAccountName' },
  { label: `${getLinkedAccountName(cloudType)} ID`, key: 'linkedAccountId' },
  { label: 'resource Name', key: 'resourceName' },
  { label: 'resource ID', key: 'resourceId' },
  { label: 'Age', key: 'age' },
  { label: 'Region', key: 'region' },
  { label: 'Instance Type', key: 'instanceType' },
  { label: 'Open Date', key: 'createdDT' },
  { label: 'Completed Date', key: 'lastProcessingDate' },
  { label: 'Labels', key: 'labels' },
  { label: 'Id', key: 'recId' },
  { label: 'Monthly Potential Savings', key: 'monthlySavings' },
  { label: 'Recommendation Status', key: 'recommendationStatus' },
  { label: 'User Status', key: 'userStatus' },
  { label: 'Marked By', key: 'userActionCreated' },
  { label: 'User Action Date', key: 'userActionDate' },
];

const excludeExportColumns = [
  { label: 'Excluded Period', key: 'excludedPeriod' },
  { label: 'Excluded Reason', key: 'excludedReason' },
];
const defaultSortColumn = { columnName: 'savings', direction: 'desc' };

const getAlternativesColumns = (alternativeCols, getCurrencyNumber) => {
  const alternativesColumns = [];
  alternativeCols.forEach((alt) => {
    const { recTypes } = alt;
    recTypes.forEach((recType) => {
      const alternativeSettings = AlternativesColumns[recType];
      if (alternativeSettings) {
        for (let i = 1; i < alternativeSettings.maxOptions; i++) {
          alternativeSettings.properties.forEach((prop) => {
            const existColumn = alternativesColumns.find((c) => c.name === `${prop.label}-${i}`);
            if (existColumn) {
              existColumn.recTypes.push(recType);
            } else {
              alternativesColumns.push({
                name: `${prop.label}-${i}`,
                title: `${prop.label}-option ${i + 1}`,
                recTypes: [recType],
                getCellValue: (row, column) => {
                  const index = column.split('-')[1];
                  if (row.recData?.alternatives && row.recData.alternatives[index]) {
                    return prop.isCost && row.recData.alternatives[index][prop.key]
                      ? getCurrencyNumber(Number(row.recData.alternatives[index][prop.key]))
                      : row.recData.alternatives[index][prop.key];
                  }
                  return '';
                },
              });
            }
          });
        }
      }
    });
  });

  return alternativesColumns;
};

const RecommendationsList = () => {
  const {
    recommendationFilters: filtersContext,
    heatMapGroupByOptions,
    recommendationExternalFilters: externalFilters,
    setRecommendationExternalFilters: setExternalFilters,
    hiddenColumnNames,
    setHiddenColumnNames,
    showCustomColumnsNames,
  } = useRecommendationsNewContext();
  const { usersStore } = useRootStore();
  const { NewTableWrapper } = useTable();
  const recsListHook = useRecsList();
  const recActionHook = useUserRecAction();
  const [sorting, setSorting] = useState([defaultSortColumn]);
  const [searchText, setSearchText] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [recommendationsData, setRecommendationsData] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [recColumnsParams, setRecColumnsParams] = useState({ filters: filtersContext });
  const [isPrevPage, setIsPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [columnNames, setColumnNames] = useState([]);
  const statusFilter = useFilterStatus(filtersContext);
  const { getCurrencyNumber } = useUserSettingsContext();
  const [columns, setColumns] = useState(
    ...[
      recommendationsColumns(usersStore.currDispUserCloudAccountType, statusFilter, filtersContext[FILTERS.TYPES.id]),
    ],
  );
  const [chooserColumns, setChooserColumns] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [columnsWidth, setColumnsWidth] = useState(recommendationColumnsWidths);

  const {
    data: recsData = {},
    refetch: refetchRecs,
    isLoading: isRecsDataLoading,
  } = recsListHook.fetchRecommendations(queryParams, () => {
    setSelectedRows([]);
  });

  const { data: recColumns, isLoading: isGetColumnsLoading } =
    recsListHook.fetchRecommendationColumns(recColumnsParams);

  const { refetch: refetchFullRecs, isLoading: isExportLoading } = recsListHook.fetchFullRecommendations(queryParams);
  const { recUserAction } = recActionHook;
  const onUserActionSuccess = () => {
    refetchRecs().then(() => {
      setShowLabelModal(false);
    });
  };

  const { mutateAsync: userActionAsync } = recUserAction(onUserActionSuccess);

  const getFirstRowForPagingToken = () => {
    const firstRow = {};
    if (!recsData.paginationToken) {
      return recommendationsData[0];
    }
    Object.keys(recsData.paginationToken).forEach((key) => {
      firstRow[key] = recommendationsData[0][key];
    });
    return firstRow;
  };

  const updateSearchQuery = () => {
    const sortColumns = sorting.filter((sortObj) => columns.some((column) => column.name === sortObj.columnName));
    if (sortColumns.length === 0) {
      sortColumns.push(defaultSortColumn);
      setSorting([defaultSortColumn]);
    }
    const recColumnSorting = sortColumns.map((sCol) => {
      const recData = dynamicColumns.find((dc) => dc.name === sCol.columnName);
      return { ...sCol, isRecData: recData !== undefined };
    });
    setQueryParams({
      filters: filtersContext,
      tableFilters: externalFilters,
      groupBy: heatMapGroupByOptions || queryParams.groupBy,
      sortBy: recColumnSorting || queryParams.sortBy,
      lastItem: currentPage > 0 && !isPrevPage && recommendationsData.length ? recsData.paginationToken : null,
      firstItem: isPrevPage && recommendationsData.length ? getFirstRowForPagingToken() : null,
      pageSize,
      pageNumber: currentPage,
      searchText,
    });
  };
  const enrichmentColData = useMemo(() => recsData.page?.filter((r) => r.enrichmentTags)?.length > 0, [recsData]);

  useEffect(() => {
    setRecColumnsParams({ filters: filtersContext });
  }, [filtersContext]);

  useEffect(() => {
    updateSearchQuery();
  }, [filtersContext, heatMapGroupByOptions, isPrevPage, currentPage]);

  const getDynamicColumnValue = (columnName, row) => {
    const value = row.recData[columnName];
    if (columnName.startsWith('is_')) {
      return value ? 'Yes' : 'No';
    }
    return value || '';
  };
  const manageListColumns = () => {
    if (!recColumns) {
      return;
    }
    const defaultColumns = recommendationsColumns(
      usersStore.currDispUserCloudAccountType,
      statusFilter,
      filtersContext[FILTERS.TYPES.id],
    );
    if (!recColumns.length) {
      setColumns([...defaultColumns, ...lastColumns, ...actionsColumns]);
      setChooserColumns(
        [...defaultColumns, ...lastColumns].map((column) => ({
          label: column.title,
          value: column.name,
          recTypes: column.recTypes,
        })),
      );
      setColumnNames([]);
      setDynamicColumns([]);
      if (recColumnsParams.filters?.[FILTERS.TYPES.id]?.length === 1) {
        setHiddenColumnNames([]);
      }
      return;
    }
    const cColumns = recColumns
      .filter((c) => c.columnName !== 'alternatives')
      .map((r) => ({
        name: r.columnName,
        recTypes: r.recTypes,
        title: getColumnHeaderByName(r.columnName),
        sortObj: { by: 'rec_data', recDataColumn: r.columnName },
        getCellValue: (row) => (row?.recData ? getDynamicColumnValue(r.columnName, row) : ''),
      }));

    const customColumns = cColumns
      .filter((c) => !defaultColumns.find((col) => col.title === c.title))
      .sort((a, b) => getSortOrder(a.name) - getSortOrder(b.name));
    let alternativesColumns = [];
    let updateHiddenColumns = [];
    if (
      !recColumnsParams.filters?.[FILTERS.TYPES.id]?.length ||
      recColumnsParams.filters?.[FILTERS.TYPES.id]?.length > 1
    ) {
      updateHiddenColumns = [
        ...hiddenColumnNames,
        ...customColumns.map((c) => c.name).filter((name) => !showCustomColumnsNames.includes(name)),
        'annualCost',
      ];
    } else {
      alternativesColumns = getAlternativesColumns(
        recColumns.filter((c) => c.columnName === 'alternatives'),
        getCurrencyNumber,
      );
    }
    if (statusFilter.isNotCompleted && !updateHiddenColumns.includes('lastProcessingDate')) {
      updateHiddenColumns.push('lastProcessingDate');
    } else {
      updateHiddenColumns = updateHiddenColumns.filter((c) => c !== 'lastProcessingDate');
    }
    if (recsData?.page !== undefined) {
      if (dynamicColumns.length && !enrichmentColData && !hiddenColumnNames.includes('enrichmentTags')) {
        updateHiddenColumns.push('enrichmentTags');
      } else if (enrichmentColData && updateHiddenColumns.includes('enrichmentTags')) {
        updateHiddenColumns = updateHiddenColumns.filter((c) => c !== 'enrichmentTags');
      }
    }
    setHiddenColumnNames(updateHiddenColumns);
    setColumnNames([...customColumns, ...alternativesColumns].map((c) => c.title));
    setDynamicColumns([...customColumns, ...alternativesColumns]);
    setColumns([...defaultColumns, ...customColumns, ...lastColumns, ...alternativesColumns, ...actionsColumns]);
    setChooserColumns(
      [...defaultColumns, ...customColumns, ...lastColumns, ...alternativesColumns].map((column) => ({
        label: column.title,
        value: column.name,
        recTypes: column.recTypes,
      })),
    );
    setColumnsWidth([
      ...columnsWidth,
      ...recColumns.map((r) => ({
        columnName: r.columnName,
        width: r.columnName.length < 10 ? 100 : 70 + r.columnName.length * 5,
      })),
      ...alternativesColumns.map((r) => ({
        columnName: r.name,
        width: r.name?.length < 10 ? 100 : 70 + (r.name?.length ?? 0) * 8,
      })),
    ]);
  };

  useEffect(() => {
    if (!isPrevPage && currentPage === 0) {
      updateSearchQuery();
    } else {
      setIsPrevPage(false);
      setCurrentPage(0);
    }
  }, [searchText, sorting, externalFilters, filtersContext, heatMapGroupByOptions]);

  useEffect(() => {
    if (isRecsDataLoading) {
      return;
    }
    setRecommendationsData(recsData?.page || recommendationsData);
    setExpandedRowIds([]);
    if (recsData?.page !== undefined && recsData?.page.length === 0) {
      setTotalPages(0);
    } else {
      if (!recsData || !recsData?.total) {
        setTotalPages(totalPages);
        return;
      }
      setTotalPages(
        (recsData.tableTotal || recsData.total) > pageSize
          ? Math.round((recsData.tableTotal || recsData.total) / pageSize)
          : 1,
      );
    }
  }, [recsData]);

  useEffect(() => {
    if (isGetColumnsLoading) {
      return;
    }
    manageListColumns();
  }, [recColumns, enrichmentColData, recColumnsParams.filters?.[FILTERS.TYPES.id]]);
  useEffect(() => {
    if (!isExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(recommendationsData.map((row, index) => index));
    }
  }, [isExpanded]);

  const onCurrentPageChange = (newPageIndex) => {
    setIsPrevPage(newPageIndex < currentPage);
    setCurrentPage(newPageIndex);
  };

  const fetchDataForExport = async (isFromBulk = false) => {
    let exportData;
    if (isFromBulk) {
      exportData = recommendationsData.filter((r, index) => selectedRows.includes(index));
    } else {
      const { data } = await refetchFullRecs();
      exportData = data?.page;
    }
    // prepare columns for export
    let extraColumnForExport = dynamicColumns
      .filter((c) => !hiddenColumnNames.includes(c.name))
      .map((c) => ({ label: c.title, key: c.name }));

    if (statusFilter.isExclude) {
      extraColumnForExport = [
        ...extraColumnForExport,
        ...excludeExportColumns.filter((c) => !hiddenColumnNames.includes(c.key)),
      ];
    }
    const exportColumns = exportColumn(usersStore.currDispUserCloudAccountType, statusFilter).filter(
      (c) => !hiddenColumnNames.includes(c.key),
    );
    // prepare data for export
    const dataToExport = (exportData || []).map((rec) => {
      const { annualSavings, recData, annualCurrentCost, monthlySavings, userStatus, open, ...rest } = rec;
      const tagsColumn = getTagsColumnsForCsv(rec, 'customTags', 'enrichmentTags');
      tagsColumn.exportTagColumns.forEach((column) => {
        if (!exportColumns.find((c) => c.key === column.key && c.label === column.label)) {
          exportColumns.push(column);
        }
      });
      const extractData = {};
      extraColumnForExport.forEach((ec) => {
        extractData[ec.key] = recData[ec.key];
      });
      const userActionDate = userStatus?.updatedTime ? moment(userStatus?.updatedTime).format('yyyy-MM-DD hh:mm') : '';
      const alternativesColumns = getAlternativesColumns(
        recColumns.filter((c) => c.columnName === 'alternatives'),
        getCurrencyNumber,
      );
      alternativesColumns.forEach((alt) => {
        const value = alt.getCellValue(rec, alt.name);
        extractData[alt.name] = value;
      });
      return {
        savings: annualSavings?.unblended,
        annualCost: annualCurrentCost?.unblended,
        createdDT: moment(rest.createdAtDateTime).format('YYYY-MM-DD'),
        monthlySavings: monthlySavings?.unblended,
        lastProcessingDate: rest.open ? '' : rest.lastProcessingDate,
        ...rest,
        ...tagsColumn.data,
        ...extractData,
        userActionDate,
        userActionCreated: userStatus?.createdByDisplayName || 'N/A',
        excludedReason: rec?.userStatus?.reason,
        excludedPeriod: rec?.userStatus?.period,
        userStatus: rec?.userStatus?.status || '',
        recommendationStatus: open ? 'OPEN' : 'COMPLETED',
      };
    });

    return [
      {
        data: dataToExport || [],
        filename:
          recColumnsParams.filters?.[FILTERS.TYPES.id]?.length === 1
            ? `Recommendation ${recColumnsParams.filters?.[FILTERS.TYPES.id][0].label}.csv`
            : 'Recommendations Export.csv',
        headers: [...exportColumns, ...extraColumnForExport],
      },
    ];
  };

  const getLabels = () => {
    if (!currentRow?.labels) {
      return [];
    }
    return currentRow.labels?.split(',').map((label) => ({ label, recIds: [currentRow.recId] }));
  };

  const labelsOptions = useMemo(() => getLabels(), [currentRow]);

  return (
    <div className={classes.recommendationList}>
      {(isRecsDataLoading || isGetColumnsLoading) && (
        <div className={classes.spinner}>
          <Spinner top="90%" />
        </div>
      )}

      <RecommendationsTableHeader
        rowsCount={recsData?.page?.length > 0 ? recsData?.total : 0}
        displayCount={recsData?.tableTotal}
        searchText={searchText}
        setSearchText={setSearchText}
        showFilters={showFilters}
        setShowFilters={(showFilters) => {
          setShowFilters(showFilters);
          if (!showFilters) {
            setExternalFilters(null);
          }
        }}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        fetchExportData={fetchDataForExport}
        isDataExporting={isExportLoading}
        hiddenColumnNames={hiddenColumnNames}
        setHiddenColumnNames={(columns) => {
          setHiddenColumnNames(columns);
        }}
        pageChange={onCurrentPageChange}
        currentPage={currentPage}
        totalPages={totalPages}
        options={chooserColumns}
        selectedItems={chooserColumns.filter((column) => !hiddenColumnNames.includes(column.value))}
      />
      <NewTableWrapper className={selectedRows?.length > 0 && 'custom-pager'}>
        <RecommendationGrid
          recommendationsData={recommendationsData}
          columns={columns}
          dynamicColumns={dynamicColumns}
          isLoading={isRecsDataLoading || isGetColumnsLoading}
          showFilters={showFilters}
          columnNames={columnNames}
          hiddenColumnNames={hiddenColumnNames}
          setCurrentRow={setCurrentRow}
          setShowExcludeModal={setShowExcludeModal}
          refetchRecs={refetchRecs}
          setShowLabelModal={setShowLabelModal}
          fetchDataForExport={fetchDataForExport}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
          userActionAsync={userActionAsync}
          columnsWidth={columnsWidth}
          setColumnsWidth={setColumnsWidth}
          setExternalFilters={setExternalFilters}
          sorting={sorting}
          setSorting={setSorting}
        />
      </NewTableWrapper>
      {showExcludeModal && (
        <RecommendationExcluded
          recs={[currentRow]}
          closeModal={(obj) => {
            if (obj) {
              userActionAsync({
                action:
                  currentRow.userStatus?.status === ACTION_STATUS.excluded ? ACTION_TYPE.include : ACTION_TYPE.exclude,
                recId: currentRow.recId,
                actionParams: obj,
              });
            }
            setShowExcludeModal(false);
          }}
        />
      )}
      {showLabelModal && (
        <RecommendationLabel
          closeModal={() => setShowLabelModal(false)}
          recLabels={labelsOptions}
          recIds={[currentRow.recId]}
          saveLabels={(addLabels, removeLabels) => {
            if (!addLabels.length && !removeLabels.length) {
              return;
            }
            userActionAsync({
              action: 'label',
              recId: currentRow.recId,
              actionParams: { data: { label_add: addLabels.join(','), label_delete: removeLabels.join(',') } },
            });
          }}
        />
      )}
    </div>
  );
};

export default RecommendationsList;

RecommendationsList.propTypes = {};
