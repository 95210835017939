import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import useDraggableInPortal from './useDraggablePortal';
import styles from './DraggableList.module.scss';

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const DraggableList = ({ items, renderItem, onChangeOrder, noWidth, columns }) => {
  // https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-669083882
  const renderDraggable = useDraggableInPortal();
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    onChangeOrder(source.index, destination.index);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provide) => (
          <div {...provide.droppableProps} ref={provide.innerRef} style={{ width: noWidth ? undefined : 573 }}>
            {columns ? (
              <div className="d-flex justify-content-between mb-2 ps-4 pe-2">
                {columns.map((col) => (
                  <div key={col} className={styles.colTitle}>
                    {col}
                  </div>
                ))}
              </div>
            ) : null}
            {items.map((item, index) => (
              <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                {renderDraggable((provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    {renderItem(item, provided.dragHandleProps, snapshot.isDragging, index)}
                  </div>
                ))}
              </Draggable>
            ))}
            {provide.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object),
  onChangeOrder: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  noWidth: PropTypes.bool,
};

DraggableList.defaultProps = {
  noWidth: false,
  columns: null,
};

export default DraggableList;
