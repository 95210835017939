/* eslint-disable max-len */
/* eslint-disable indent */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import RiEc2CostRecommendationBuilder from 'recommendations/models/builders/riRecommBuilder';
import IpPublicIpv4RecommendationBuilder from 'recommendations/models/builders/ipPublicIpv4RecommBuilder';
import EcsFargateRecommendationBuilder from 'recommendations/models/builders/ecsFargateRecommBuilder';
import KmsIdleRecommendationBuilder from 'recommendations/models/builders/kmsIdleRecommBuilder';
import KmsOldRecommendationBuilder from 'recommendations/models/builders/kmsOldRecommBuilder';
import SpotEc2CostRecommendationBuilder from 'recommendations/models/builders/spotRecommBuilder';
import RegionEc2CostRecommendationBuilder from 'recommendations/models/builders/regionRecommBuilder';
import OsCostRecommendationBuilder from 'recommendations/models/builders/osRecommBuilder';
import VersionUpgradeEc2CostRecommendationBuilder from 'recommendations/models/builders/versionUpgradeRecommBuilder';
import IpCostRecommendationBuilder from 'recommendations/models/builders/ipCostRecommendationBuilder';
import Ec2IdleCostRecommendationBuilder from 'recommendations/models/builders/ec2IdleCostRecommendationBuilder';
import EbsUnattachedRecommendationBuilder from 'recommendations/models/builders/ebsUnattachedRecommendationBuilder';
import EbsTypeAndSizeChangeRecommendationBuilder from 'recommendations/models/builders/ebsTypeAndSizeChangeRecommendationBuilder';
import IdleLoadBalancerRecommendationBuilder from 'recommendations/models/builders/idleLoadBalancerRecommendationBuilder';
import RegionMigrationRecommendationBuilder from 'recommendations/models/builders/regionMigrationRecommendationBuilder';
import Ec2LowCpuUsageRecommendationBuilder from 'recommendations/models/builders/ec2LowCpuRecommBuilder';
import DynamoDbIdleRecommendationBuilder from 'recommendations/models/builders/ddbIdleRecommBuilder';
import RdsIdleRecommendationBuilder from 'recommendations/models/builders/rdsIdleRecommBuilder';
import RdsRiRecommendationBuilder from 'recommendations/models/builders/rdsRiRecommBuilder';
import RdsTypeChangeRecommendationBuilder from 'recommendations/models/builders/rdsTypeChangeRecommendationBuilder';
import RdsVersionUpgradeRecommendationBuilder from 'recommendations/models/builders/rdsVersionUpgradeRecommBuilder';
import RdsClassChangeRecommendationBuilder from 'recommendations/models/builders/rdsClassChangeRecommBuilder';
import RdsIopsChangeRecommendationBuilder from 'recommendations/models/builders/rdsIopsChangeRecommBuilder';
import S3IdleRecommendationBuilder from 'recommendations/models/builders/s3IdleRecommendationBuilder';
import S3VersioningRecommendationBuilder from 'recommendations/models/builders/s3VersioningRecommBuilder';
import S3StorageClassRecommendationBuilder from 'recommendations/models/builders/s3StorageClassRecommBuilder';
import S3MultipartUploadRecommBuilder from 'recommendations/models/builders/s3MultipartUploadRecommBuilder';
import ElasticacheRiRecommendationBuilder from 'recommendations/models/builders/elasticacheRiRecommBuilder';
import OpenSearchRiRecommendationBuilder from 'recommendations/models/builders/openSearchRiRecommBuilder';
import Ec2ScheduleRecommendationBuilder from 'recommendations/models/builders/ec2ScheduleRecommBuilder';
import Ec2SavingsPlansRecommendationBuilder from 'recommendations/models/builders/ec2SavingsPlansRecommendationBuilder';
import EbsOutdatedSnapshotRecommendationBuilder from 'recommendations/models/builders/ebsOutdatedSnapshotRecommendationBuilder';
import EbsUpgradeRecommendationBuilder from 'recommendations/models/builders/ebsUpgradeRecommendationBuilder';
import Ec2UnnecessaryDataTransferRecommendationBuilder from 'recommendations/models/builders/ec2UnnecessaryDataTransferRecommBuilder';
import Ec2StoppedInstanceRecommendationBuilder from 'recommendations/models/builders/ec2StoppedInstanceRecommBuilder';
import RedshiftLowUtilizationRecommendationBuilder from 'recommendations/models/builders/redshiftLowUtilizationRecommendationBuilder';
import ElasticsearchLowUtilizationRecommendationBuilder from 'recommendations/models/builders/elasticsearchLowUtilizationRecommendationBuilder';
import ElasticacheLowUtilizationRecommendationBuilder from 'recommendations/models/builders/elasticacheLowUtilizationRecommendationBuilder';
import NatGatewayLowUtilizationRecommendationBuilder from 'recommendations/models/builders/natGatewayLowUtilizationRecommendationBuilder';
import DocumentDbLowUtilizationRecommendationBuilder from 'recommendations/models/builders/documentDbLowUtilizationRecommendationBuilder';
import NeptuneLowUtilizationRecommendationBuilder from 'recommendations/models/builders/neptuneLowUtilizationRecommendationBuilder';
import KinesisLowUtilizationRecommendationBuilder from 'recommendations/models/builders/kinesisLowUtilizationRecommendationBuilder';
import CloudTrailDuplicatesTrailsBuilder from 'recommendations/models/builders/cloudTrailDuplicatesTrailsBuilder';
// Azure
import AzureRiRecommBuilder from 'recommendations/models/builders/azureRiRecommBuilder';
import AzureIpCostRecommendationBuilder from 'recommendations/models/builders/azureIpCostRecommendationBuilder';
import AzureMariaDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azureMariaDbIdleCostRecommendationBuilder';
import AzureMysqlDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azureMysqlDbIdleCostRecommendationBuilder';
import AzureCosmosDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azureCosmosDbIdleCostRecommendationBuilder';
import AzurePostgresDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azurePostgresDbIdleCostRecommendationBuilder';
import AzureSqlDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azureSqlDbIdleCostRecommendationBuilder';
import AzureVmStoppedCostRecommendationBuilder from 'recommendations/models/builders/azureVmStoppedCostRecommendationBuilder';
import AzureDiskUnattachedRecommendationBuilder from 'recommendations/models/builders/azureDiskUnattachedRecommBuilder';
import AzureVmIdleCostRecommendationBuilder from 'recommendations/models/builders/azureVmIdleCostRecommendationBuilder';
import AzureIdleLoadBalancerRecommendationBuilder from 'recommendations/models/builders/azureIdleLoadBalancerRecommendationBuilder';
import AzureAppRightSiznigRecommendationBuilder from 'recommendations/models/builders/azureAppRightSizingCostRecommendationBuilder';
import AzureDbRiRecommendationBuilder from 'recommendations/models/builders/azureDbRiRecommBuilder';
import AzureVmRightSizingRecommendationBuilder from 'recommendations/models/builders/azureVmRightSizingRecommBuilder';
import AzureStorageRecommendationBuilder from 'recommendations/models/builders/azureStorageRecommBuilder';
import AzureCosmosRightSizingRecommendationBuilder from 'recommendations/models/builders/azureCosmosRightSizingRecommBuilder';
import AzureDiskTypeChangeBuilder from 'recommendations/models/builders/azureDiskTypeChangeRecommendationBuilder';
import AzureReservedCapacityBuilder from 'recommendations/models/builders/azureReservedCapacityBuilder';
import AzureSnapshotMigrationBuilder from 'recommendations/models/builders/azureSnapshotMigrationBuilder';
import AzureOutdatedSnapshotBuilder from 'recommendations/models/builders/azureOutdatedSnapshotBuilder';
import AzureKustoUnusedDataRecommendationBuilder from 'recommendations/models/builders/azureKustoUnusedDataRecommendationBuilder';
import AzureAppSerRsrvdCapacityRecommendationBuilder from 'recommendations/models/builders/azureAppSerRsrvdCapacityRecommendationBuilder';
// GCP
import GcpVmStoppedCostRecommendationBuilder from 'recommendations/models/builders/gcpVmStoppedCostRecommendationBuilder';
import GcpVmIdleCostRecommendationBuilder from 'recommendations/models/builders/gcpVmIdleCostRecommendationBuilder';
import GcpVmRightSizingCostRecommendationBuilder from 'recommendations/models/builders/gcpVmRightSizingCostRecommendationBuilder';
import GcpDiskUnattachedCostRecommendationBuilder from 'recommendations/models/builders/gcpDiskUnattachedCostRecommendationBuilder';
import GcpIpIdleCostRecommendationBuilder from 'recommendations/models/builders/gcpIpIdleCostRecommendationBuilder';
import GcpUsageCommitmentRecommendationBuilder from 'recommendations/models/builders/gcpUsageCommitmentRecommendationBuilder';

class RecommBuilderFactory {
  static create(rawRecommendation, recommType) {
    let output;
    switch (recommType) {
      case RecommendationTypes.EC2_RI:
        output = new RiEc2CostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.IP_PUBLIC_IPV4:
        output = new IpPublicIpv4RecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.ECS_FARGATE:
        output = new EcsFargateRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.KMS_IDLE:
        output = new KmsIdleRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.KMS_OLD:
        output = new KmsOldRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_SPOT:
        output = new SpotEc2CostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_REGION:
        output = new RegionEc2CostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_VERSION_UPGRADE:
        output = new VersionUpgradeEc2CostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_OPERATION_SYS:
        output = new OsCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_IP_UNATTACHED:
        output = new IpCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_IDLE:
        output = new Ec2IdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_EBS_UNATTACHED:
        output = new EbsUnattachedRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EBS_TYPE_CHANGE:
        output = new EbsTypeAndSizeChangeRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EBS_TYPE_SIZE_CHANGE:
        output = new EbsTypeAndSizeChangeRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_IDLE_LOAD_BALANCER:
        output = new IdleLoadBalancerRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_REGION_MIGRATION:
        output = new RegionMigrationRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_LOW_CPU_USAGE:
        output = new Ec2LowCpuUsageRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.DDB_IDLE:
        output = new DynamoDbIdleRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.RDS_RI:
        output = new RdsRiRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.RDS_VERSION_UPGRADE:
        output = new RdsVersionUpgradeRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.RDS_IDLE:
        output = new RdsIdleRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.RDS_CLASS_CHANGE:
        output = new RdsClassChangeRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.RDS_IOPS_CHANGE:
        output = new RdsIopsChangeRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.S3_VERSIONING:
        output = new S3VersioningRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.S3_STORAGE_CLASS:
        output = new S3StorageClassRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.S3_MULTIPART_UPLOAD:
        output = new S3MultipartUploadRecommBuilder(rawRecommendation);
        break;
      case RecommendationTypes.S3_IDLE:
        output = new S3IdleRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.ELASTICACHE_RI:
        output = new ElasticacheRiRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.OPEN_SEARCH_RI:
        output = new OpenSearchRiRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_SCHEDULE:
        output = new Ec2ScheduleRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_SAVINGS_PLANS:
        output = new Ec2SavingsPlansRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.RDS_TYPE_CHANGE:
        output = new RdsTypeChangeRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EBS_OUTDATED_SNAPSHOT:
        output = new EbsOutdatedSnapshotRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EBS_UPGRADE:
        output = new EbsUpgradeRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER:
        output = new Ec2UnnecessaryDataTransferRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.EC2_STOPPED_INSTANCE:
        output = new Ec2StoppedInstanceRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.REDSHIFT_LOW_UTILIZATION:
        output = new RedshiftLowUtilizationRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS:
        output = new CloudTrailDuplicatesTrailsBuilder(rawRecommendation);
        break;
      case RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION:
        output = new ElasticsearchLowUtilizationRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.ELASTICACHE_LOW_UTILIZATION:
        output = new ElasticacheLowUtilizationRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION:
        output = new NatGatewayLowUtilizationRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION:
        output = new DocumentDbLowUtilizationRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.NEPTUNE_LOW_UTILIZATION:
        output = new NeptuneLowUtilizationRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.KINESIS_LOW_UTILIZATION:
        output = new KinesisLowUtilizationRecommendationBuilder(rawRecommendation);
        break;

      // Azure ********************************************
      case RecommendationTypes.AZURE_VM_RI:
        output = new AzureRiRecommBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_IP_UNATTACHED:
        output = new AzureIpCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_MARIA_DB_IDLE:
        output = new AzureMariaDbIdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_SQL_DB_IDLE:
        output = new AzureSqlDbIdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_COSMOS_DB_IDLE:
        output = new AzureCosmosDbIdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE:
        output = new AzurePostgresDbIdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_MYSQL_DB_IDLE:
        output = new AzureMysqlDbIdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_VM_STOPPED:
        output = new AzureVmStoppedCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_DISK_UNATTACHED:
        output = new AzureDiskUnattachedRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_VM_IDLE:
        output = new AzureVmIdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_IDLE_LOAD_BALANCER:
        output = new AzureIdleLoadBalancerRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_APP_RIGHT_SIZING:
        output = new AzureAppRightSiznigRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_DB_RI:
        output = new AzureDbRiRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_VM_RIGHT_SIZING:
        output = new AzureVmRightSizingRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_STORAGE:
        output = new AzureStorageRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_COSMOS_RIGHT_SIZING:
        output = new AzureCosmosRightSizingRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_DISK_TYPE_CHANGE:
        output = new AzureDiskTypeChangeBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_PG:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_DB_RESERVED_CAPACITY:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH:
        output = new AzureReservedCapacityBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_SNAPSHOT_MIGRATION:
        output = new AzureSnapshotMigrationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_OUTDATED_SNAPSHOT:
        output = new AzureOutdatedSnapshotBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_KUSTO_UNUSED_DATA:
        output = new AzureKustoUnusedDataRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY:
        output = new AzureAppSerRsrvdCapacityRecommendationBuilder(rawRecommendation);
        break;

      // GCP ********************************************
      case RecommendationTypes.GCP_VM_IDLE:
        output = new GcpVmIdleCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.GCP_USAGE_COMMITMENT:
        output = new GcpUsageCommitmentRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.GCP_VM_STOPPED:
        output = new GcpVmStoppedCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.GCP_IP_IDLE:
        output = new GcpIpIdleCostRecommendationBuilder(rawRecommendation);
        break;

      case RecommendationTypes.GCP_VM_RIGHT_SIZING:
        output = new GcpVmRightSizingCostRecommendationBuilder(rawRecommendation);
        break;
      case RecommendationTypes.GCP_DISK_UNATTACHED:
        output = new GcpDiskUnattachedCostRecommendationBuilder(rawRecommendation);
        break;
      // Default ********************************************
      default:
        output = undefined;
        break;
    }
    return output;
  }
}

export default RecommBuilderFactory;
