/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { Ec2IdleRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class Ec2IdleCostRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new Ec2IdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EC2 Idle`;
  }

  build() {
    super.build();
    const {
      zone_tag: tagZone,
      resource_name: resourceName,
      num_of_days: numOfDays,
      starting_time: startingTime,
      max_cpu_util: maxCpuUtil,
      cpu_util_statistics_usage: cpuUtilStatisticsUsage,
      max_network: maxNetwork,
      network_in_statistics_usage: networkInStatisticsUsage,
      network_out_statistics_usage: networkOutStatisticsUsage,
      potential_savings: potentialSavings,
      ri_savings: riSavings,
      ri_coverage: riCoverage,
      sp_savings: spSavings,
      sp_coverage: spCoverage,
      cpu_util: cpuUtil,
    } = this._rawRecommendation;
    this._recommendation.maxCpuUtil = maxCpuUtil;
    this._recommendation.cpuUtilStatisticsUsage = cpuUtilStatisticsUsage;
    this._recommendation.maxNetwork = maxNetwork;
    this._recommendation.networkInStatisticsUsage = networkInStatisticsUsage;
    this._recommendation.networkOutStatisticsUsage = networkOutStatisticsUsage;
    this._recommendation.region = tagZone;
    this._recommendation.resourceName = resourceName;
    this._recommendation.numOfDays = numOfDays;
    this._recommendation.startingTime = startingTime;
    this._recommendation.riSavings = riSavings;
    this._recommendation.riCoverage = riCoverage;
    this._recommendation.spSavings = spSavings;
    this._recommendation.spCoverage = spCoverage;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.cpuUtil = parseFloat(cpuUtil);
  }
}
