export class Recommendation {}

export class CostRecommendation extends Recommendation {}

export class Ec2CostRecommendation extends CostRecommendation {}

export class RdsCostRecommendation extends CostRecommendation {}

export class S3CostRecommendation extends CostRecommendation {}

export class DynamoDbCostRecommendation extends CostRecommendation {}

export class RiRecommendation extends Ec2CostRecommendation {}

export class VersionUpgradeRecommendation extends Ec2CostRecommendation {}

export class SpotRecommendation extends Ec2CostRecommendation {}

export class RegionRecommendation extends Ec2CostRecommendation {}

export class OsRecommendation extends Ec2CostRecommendation {}

export class IpRecommendation extends Ec2CostRecommendation {}

export class Ec2IdleRecommendation extends Ec2CostRecommendation {}

export class Ec2ScheduleRecommendation extends Ec2CostRecommendation {}

export class EbsRecommendation extends CostRecommendation {}

export class IdleLoadBalancerRecommendation extends Ec2CostRecommendation {}

export class RegionMigrationRecommendation extends Ec2CostRecommendation {}

export class Ec2LowCpuUsageRecommendation extends Ec2CostRecommendation {}

export class DynamoDbIdleRecommendation extends DynamoDbCostRecommendation {}

export class RdsRiRecommendation extends RdsCostRecommendation {}

export class RdsTypeChangeRecommendation extends RdsCostRecommendation {}

export class RdsIdleRecommendation extends RdsCostRecommendation {}

export class RdsVersionUpgradeRecommendation extends RdsCostRecommendation {}

export class RdsIopsChangeRecommendation extends CostRecommendation {}

export class RdsClassChangeRecommendation extends CostRecommendation {}

export class S3MultipartUploadRecommendation extends S3CostRecommendation {}

export class S3IdleRecommendation extends S3CostRecommendation {}

export class EbsUnattachedRecommendation extends EbsRecommendation {}

export class EbsTypeChangeRecommendation extends EbsRecommendation {}

export class EbsTypeAndSizeChangeRecommendation extends EbsRecommendation {}

export class Ec2SavingsPlansRecommendation extends CostRecommendation {}
export class CloudTrailsDuplicatesTrailsRecommendation extends CostRecommendation {}

export class EbsOutdatedSnapshotRecommendation extends EbsRecommendation {}

export class EbsUpgradeRecommendation extends EbsRecommendation {}

export class Ec2UnnecessaryDataTransferRecommendation extends Ec2CostRecommendation {}

export class Ec2StoppedInstanceRecommendation extends Ec2CostRecommendation {}

export class RedshiftLowUtilizationRecommendation extends S3CostRecommendation {}

export class ElasticsearchLowUtilizationRecommendation extends S3CostRecommendation {}

export class ElasticacheLowUtilizationRecommendation extends S3CostRecommendation {}

export class NatGatewayLowUtilizationRecommendation extends S3CostRecommendation {}

export class DocumentDbLowUtilizationRecommendation extends S3CostRecommendation {}

export class NeptuneLowUtilizationRecommendation extends S3CostRecommendation {}

export class KinesisLowUtilizationRecommendation extends S3CostRecommendation {}

// ***** AZURE TYPES

export class AzureCostRecommendation extends CostRecommendation {}

export class AzureDbIdleRecommendation extends CostRecommendation {}
export class AzureMariaDbIdleRecommendation extends CostRecommendation {}
export class AzureMysqlIdleRecommendation extends CostRecommendation {}
export class AzureSqlIdleRecommendation extends CostRecommendation {}
export class AzurePostgresIdleRecommendation extends CostRecommendation {}
export class AzureCosmosIdleRecommendation extends CostRecommendation {}

export class AzureVmStoppedRecommendation extends CostRecommendation {}

export class AzureRiRecommendation extends AzureCostRecommendation {}

export class AzureIpRecommendation extends AzureCostRecommendation {}

export class AzureDiskUnattachedRecommendation extends AzureCostRecommendation {}

export class AzureIdleLoadBalancerRecommendation extends AzureCostRecommendation {}

export class AzureVmIdleRecommendation extends AzureCostRecommendation {}

export class AzureAppRightSizingRecommendation extends AzureCostRecommendation {}

export class AzureDbRiRecommendation extends AzureCostRecommendation {}

export class AzureVmRightSizingRecommendation extends AzureCostRecommendation {}

export class AzureStorageRecommendation extends AzureCostRecommendation {}

export class AzureCosmosRightSizingRecommendation extends AzureCostRecommendation {}

export class AzureDiskTypeChangeRecommendation extends AzureCostRecommendation {}

export class AzureReservedCapacityRecommendation extends AzureCostRecommendation {}

export class AzureSnapshotMigrationRecommendation extends AzureCostRecommendation {}

export class AzureKustoUnusedDataRecommendation extends AzureCostRecommendation {}

export class AzureAppSerRsrvdCapacityRecommendation extends AzureCostRecommendation {}

// ***** GCP TYPES
export class GcpCostRecommendation extends CostRecommendation {}

export class GcpIpIdleRecommendation extends GcpCostRecommendation {}

export class GcpVmStoppedRecommendation extends GcpCostRecommendation {}

export class GcpVmIdleRecommendation extends GcpCostRecommendation {}

export class GcpVmRightSizingRecommendation extends GcpCostRecommendation {}

export class GcpDiskUnattachedRecommendation extends GcpCostRecommendation {}
export class KmsOldRecommendation extends CostRecommendation {}
export class KmsIdleRecommendation extends CostRecommendation {}
export class S3StorageClassRecommendation extends CostRecommendation {}
export class S3VersioningRecommendation extends CostRecommendation {}
export class EcsFargateRecommendation extends CostRecommendation {}
export class IpPublicIpv4Recommendation extends CostRecommendation {}
