import React, { useEffect, useState } from 'react';
import { randomItemFromArray } from 'shared/utils/arrayUtils';
import { COLORS, OPACITIES, RECT_PROPORTIONS, ROTATIONS, SHAPES } from './ConfettiShape';
import styles from './ConfettiAnimation.module.scss';

const generateConfetti = () => {
  const color = randomItemFromArray(COLORS);
  const rotation = randomItemFromArray(ROTATIONS);
  const shape = randomItemFromArray(Object.values(SHAPES));
  const opacity = randomItemFromArray(OPACITIES);
  const rectProportion = randomItemFromArray(RECT_PROPORTIONS);
  return {
    id: Math.random(),
    Component: () => shape(color, opacity, rectProportion),
    style: {
      animationDuration: `${1 + Math.random() * 2}s`,
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 20}%`,
    },
    rotation,
  };
};

const items = new Array(100).fill(null).map(generateConfetti);

const ConfettiAnimation = () => {
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setStartAnimation(true);
    }, 1000);
  }, []);
  if (!startAnimation) {
    return null;
  }
  return (
    <>
      {items.map((item) => {
        const { Component, style, id, rotation } = item;
        return (
          <span className={styles.confetti} style={style} key={id}>
            <span style={{ transform: `rotate(${rotation})` }}>
              <Component />
            </span>
          </span>
        );
      })}
    </>
  );
};

export default ConfettiAnimation;
