import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import DateFilter from 'shared/modules/dateFilter';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';

const root = '/api/v1/divisions/customers';

const fetchCustomers = async () => {
  const startDate = DateFilter.getFirstDayOfCurrMonthDate();
  const endDate = DateFilter.getDateStr();
  const periodGranLevel = CostTrackingConstants.GRAN_LEVEL_MONTHLY;
  return API.get('billings', `${root}?startDate=${startDate}&endDate=${endDate}&periodGranLevel=${periodGranLevel}`);
};

const fetchCurrentMonthBillingStatusData = async () => {
  const startDate = DateFilter.getFirstDayOfCurrMonthDate();
  const endDate = DateFilter.getDateStr();
  const billingsStatusData = await API.get('billings', `${root}/aws/costs?startDate=${startDate}&endDate=${endDate}`);
  return billingsStatusData.filter((data) => data.divisionId !== 0);
};

const fetchExplainCustomerFlexibilityMarginData = async ({
  divisionId,
  divisionName,
  accountKey,
  flexibilityType,
  startDate = null,
  endDate = null,
}) => {
  const { startDate: formattedStartDate, endDate: formattedEndDate } = buildStartAndEndDate(startDate, endDate);
  // eslint-disable-next-line max-len
  const url = `${root}/aws/flexibility/${flexibilityType}?divisionId=${divisionId}&divisionName=${divisionName}&accountKey=${accountKey}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
  return API.get('billings', url);
};

const fetchBillingRules = async () => {
  const result = await API.get('billings', `${root}/reseller-rules-rates`);
  return result?.resellerRatesData;
};

const addBillingRule = async (pricingParams = {}) => {
  const body = { ...pricingParams };
  return API.post('billings', `${root}/add-pp`, { body });
};

const updateBillingRule = async (newPricingParams) => {
  const body = { ...newPricingParams };
  const addUrl = `${root}/update-pp`;
  return API.put('billings', addUrl, { body });
};

const deleteBillingRule = async (id, payerAccId = null) =>
  API.del('billings', `${root}/del-pp/${id}?accId=${payerAccId}`);

export const useCustomers = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.CUSTOMERS];

  return {
    reset: () => queryClient.resetQueries(queryKey),
    getCustomers: () =>
      useQuery({
        queryKey,
        queryFn: fetchCustomers,
        onError: () =>
          toast.error('Error fetching user data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
  };
};

export const useBillingsStatus = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.BILLING_STATUS];

  return {
    reset: () => queryClient.resetQueries(queryKey),
    getBillingsStatus: () =>
      useQuery({
        retry: false,
        queryKey,
        queryFn: fetchCurrentMonthBillingStatusData,
        onError: () =>
          toast.error('Error fetching user data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
  };
};

export const useFlexibilityMargin = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.FLEXIBILTY_MARGIN];
  return {
    reset: () => queryClient.resetQueries(queryKey),
    getFlexibilityMarginData: ({
      divisionId,
      divisionName,
      accountKey,
      flexibilityType,
      startDate,
      endDate,
      onErrorCB = () => {},
      onSuccessCB = () => {},
    }) =>
      useQuery({
        retry: false,
        queryKey: [...queryKey, { divisionId, divisionName, accountKey, flexibilityType, startDate, endDate }],
        enabled: !!(!!divisionName && flexibilityType),
        queryFn: () =>
          fetchExplainCustomerFlexibilityMarginData({
            divisionId,
            divisionName,
            accountKey,
            flexibilityType,
            startDate,
            endDate,
          }),
        onError: () => {
          onErrorCB();
          toast.error('Error fetching user data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        },
        onSuccess: onSuccessCB,
      }),
  };
};

export const useBillingRules = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.RESELLER_RULE_RATES];
  return {
    reset: () => queryClient.resetQueries(queryKey),
    getBillingRules: () =>
      useQuery({
        retry: false,
        queryKey,
        queryFn: fetchBillingRules,
        onError: () =>
          toast.error('Error fetching user data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    postBillingRule: ({ ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: addBillingRule,
        onSuccess: async (billingRules) => {
          queryClient.setQueryData(queryKey, (previousBillingRules) => [...previousBillingRules, ...billingRules]);
        },
        ...mutationSettings,
      }),
    putBillingRule: ({ ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: updateBillingRule,
        onSuccess: async (billingRules) => {
          queryClient.setQueryData(queryKey, (previousBillingRules) => {
            const newBillingRules = previousBillingRules.filter((rule) => rule.id !== billingRules[0].id);
            newBillingRules.push(billingRules[0]);
            return newBillingRules;
          });
        },
        ...mutationSettings,
      }),
    delBillingRule: ({ ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: deleteBillingRule,
        onSuccess: async (id) => {
          queryClient.setQueryData(queryKey, (previousBillingRules) => {
            const newBillingRules = previousBillingRules.filter((rule) => rule.id !== id);
            return newBillingRules;
          });
        },
        ...mutationSettings,
      }),
  };
};
