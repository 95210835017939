/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from 'recommendations/shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      This in-use public IP uses format v4, and starting 1 February 2024 will start to carry costs due to AWS new
      charges. We recommended upgrading to format v6 which is more secure and does not carry costs. For guidance on how
      to make migration click{' '}
      <a href="https://docs.aws.amazon.com/vpc/latest/userguide/vpc-migrate-ipv6.html" target="_blank" rel="noreferrer">
        here
      </a>
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
