import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/material';
import Button from 'shared/components/andtComponents/Button';
import Input from 'shared/components/andtComponents/Input';
import { ReactComponent as EmptyComponent } from 'shared/img/icons/empty-component.svg';
import AlertRulesTable from './AlertRulesTable';
import styles from './alertRulesListModal.module.scss';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  alertRules: PropTypes.array,
  onDeleteAlertRule: PropTypes.func.isRequired,
  onEditAlertRule: PropTypes.func.isRequired,
  addAlertRule: PropTypes.func.isRequired,
};

const defaultProps = {
  alertRules: [],
};

const AlertRulesListModal = ({ onClose, alertRules, onDeleteAlertRule, onEditAlertRule, addAlertRule }) => {
  const [searchInput, setSearchInput] = useState('');
  return (
    <div className={styles.container}>
      <ClickAwayListener onClickAway={() => onClose()}>
        <div className={`${styles.modal} flex flex-column`}>
          <div>
            <h3 className={styles.title}>{`Alert Rules List (${alertRules.length})`}</h3>
          </div>
          <Input
            name="search"
            placeholder="Search"
            onInput={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            overrideStyles={{
              marginInlineStart: 'auto',
              width: '100px',
              height: '38px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              marginBottom: '5px',
            }}
          />
          {!alertRules || !alertRules.length ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <EmptyComponent />
              <span className="mb-1">No alert yet</span>
              <Button onClick={addAlertRule} text="+ New Alert" automationId="AddNewAlertButtonInListModal" />
            </div>
          ) : (
            <div className={styles.content}>
              <AlertRulesTable
                onEditAlertRule={onEditAlertRule}
                onDeleteAlertRule={onDeleteAlertRule}
                alertRules={alertRules}
                searchInput={searchInput}
              />
            </div>
          )}
          {alertRules && alertRules.length ? (
            <div className={`d-flex ${styles.footer}`}>
              <Button
                overrideStyles={{ marginInlineStart: 'auto' }}
                onClick={addAlertRule}
                text="+ New Alert"
                automationId="AddNewAlertButtonInListModal"
              />
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
};

AlertRulesListModal.propTypes = propTypes;
AlertRulesListModal.defaultProps = defaultProps;
export default AlertRulesListModal;
