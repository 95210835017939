import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import {
  RecommendationCommonConstants,
  RecommFieldsAndFiltersValues,
} from 'recommendations/constants/recommendationsConstants';
import { queryClient } from 'queryClient';
import {
  useRecommendationByType,
  fetchRecommendationDataByType,
} from 'recommendations/hooks/react-query/useRecommendationByType';

const setRecommendationsActive = (recommendations, isActive) => {
  if (Array.isArray(recommendations)) {
    return recommendations.map((rec) => ({ ...rec, isActive }));
  }
  return { ...recommendations, isActive };
};

const getFilteredRecsByStatus = (recommendations, status) => recommendations.filter((rec) => rec.status === status);

// Check if all the current daily recommendations are completed
const isDailyRecommendationsCompleted = (recommendations) => {
  if (!recommendations?.length > 0) {
    return [];
  }
  let result = false;
  if (!recommendations?.length === 0) {
    result =
      getFilteredRecsByStatus(recommendations, RecommFieldsAndFiltersValues.COMPLETED).length ===
      recommendations.length;
  }
  return result;
};

const acquireNewDailyRecommendations = (recommendations) => {
  if (!recommendations?.length > 0) {
    return [];
  }
  const uncompletedRecs = getFilteredRecsByStatus(recommendations, RecommFieldsAndFiltersValues.OPEN);
  const numOfDailyRecs =
    uncompletedRecs.length < RecommendationCommonConstants.NUM_OF_DAILY_RECS
      ? uncompletedRecs.length
      : RecommendationCommonConstants.NUM_OF_DAILY_RECS;
  const dailyRecs = uncompletedRecs.slice(0, numOfDailyRecs);
  return setRecommendationsActive(dailyRecs, true);
};

const getMoreDailyRecommendations = (recommendations) => {
  setRecommendationsActive(recommendations, false);
  acquireNewDailyRecommendations(recommendations);
};

const completeRecommendations = (recommendationsHook, recommendations) =>
  recommendationsHook.excludeRecommendation({
    recommendations,
  });

const excludeRecommendations = (recommendationsHook, recommendations, excludeData, excludeMessageMap) =>
  recommendationsHook.excludeRecommendation(recommendations, excludeData, excludeMessageMap);

export const useDailyRecommendations = ({ isEnabled, currentDisplayedUserType, mapLinkedAccIdToDivisionName }) => {
  const queryKey = [apiConstants.QUERY_KEYS.DAILY_RECOMMENDATIONS];

  const recommendationsByTypeHook = useRecommendationByType({
    isEnabled,
    recommendationType: null,
    currentDisplayedUserType,
    mapLinkedAccIdToDivisionName,
  });

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchDailyRecommendations: () =>
      useQuery(
        queryKey,
        async () => {
          const allDailyRecommendations = await fetchRecommendationDataByType(
            null,
            currentDisplayedUserType,
            mapLinkedAccIdToDivisionName,
          );
          if (allDailyRecommendations) {
            return {
              dailyRecommendations: acquireNewDailyRecommendations(allDailyRecommendations),
              isDailyRecsCompleted: isDailyRecommendationsCompleted(allDailyRecommendations),
              isDailyRecsEmpty: !(allDailyRecommendations?.length > 0),
              getMoreDailyRecs: () => getMoreDailyRecommendations(allDailyRecommendations),
            };
          }
          return allDailyRecommendations;
        },
        {
          enabled: isEnabled,
          retry: false,
        },
      ),
    excludeRecommendations: async (recommendations, excludeData, excludeMessageMap) => {
      if (recommendations) {
        await excludeRecommendations(recommendationsByTypeHook, recommendations, excludeData, excludeMessageMap);
        await queryClient.resetQueries(queryKey);
      }
    },
    completeRecommendations: async (recommendations) => {
      if (recommendations) {
        await completeRecommendations(recommendationsByTypeHook, recommendations);
        await queryClient.invalidateQueries(queryKey);
      }
    },
  };
};
