import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Row } from 'reactstrap';
import DateFilter from 'shared/modules/dateFilter';
import CostBarLineChartToggler from './CostBarLineChartToggler';

function PricingMethodChart(props) {
  return (
    <Row style={{ padding: '20px 10px', margin: '5px 0px 0px 2px' }} className="chart-box-style">
      {/* <div style={{ width: '100%', textAlign: 'center' }}>Usage by pricing method</div> */}
      <CostBarLineChartToggler
        data={props.data}
        dateFilter={DateFilter}
        showHeader
        showOnlyBarChart
        height={props.height}
      />
    </Row>
  );
}

PricingMethodChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.string,
};
PricingMethodChart.defaultProps = {
  height: 300,
  id: 'PricingMethodChart-1',
};

export default PricingMethodChart;
