/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import {
  AZURE_STORAGE_COLUMN_WIDTHS,
  AZURE_STORAGE_CURRENCIES_COLUMNS,
  AZURE_STORAGE_DEFAULT_HIDDEN_COLUMN_NAMES,
  AZURE_STORAGE_DEFAULT_SORTING,
  AZURE_STORAGE_TABLE_COLUMN_EXTENSIONS,
  AZURE_STORAGE_TABLE_COLUMNS,
} from 'usage/constants/usageConstants';
import { buildStartAndEndDate, datePickerSyntexDates } from 'shared/utils/dateUtil';
import DatePickerFilter from 'shared/components/DatePickerFilter';
// import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import AzureStorageTable from './components/AzureStorageTable';

class AzureStorage extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataIsLoading: false,
      startDate: buildStartAndEndDate(null, null).startDate,
      endDate: buildStartAndEndDate(null, null).endDate,
    };
  }

  async componentDidMount() {
    // await this.props.usageStore.getAzureStorageUsageData(this.state.startDate, this.state.endDate);
    if (this.state.data.length === 0) {
      this.setState({ dataIsLoading: true });
      const { data } = await this.props.usageStore.getAzureStorageUsageData(this.state.startDate, this.state.endDate);
      // const preparedData = this.prepareData(data);
      this.setState({ dataIsLoading: false, data });
    }
  }

  prepareData = (data) => {
    // combine quantity with units
    if (data) {
      data.forEach((item) => {
        let newQuantity = '';
        const arrOfTypeAndValue = item.usageQuantityType.split(' ');
        const newValue = item.totalUsageQuantity * parseInt(arrOfTypeAndValue[0], 0);
        newQuantity = isNaN(newValue)
          ? `${item.totalUsageQuantity} X ${item.usageQuantityType}`
          : `${newValue.toFixed(0)} ${arrOfTypeAndValue[1]}`;
        item.totalUsageQuantity = newQuantity;
      });
    }
    return data;
  };

  handleDateChange = async ({ startDate, endDate }) => {
    const start = startDate || this.state.startDate;
    let end = endDate || this.state.endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    this.setState({
      startDate: modStartDate,
      endDate: modEndDate,
      dataIsLoading: true,
    });
    const { data } = await this.props.usageStore.getAzureStorageUsageData(modStartDate, modEndDate);
    this.setState({ dataIsLoading: false, data });
  };

  render() {
    const { startDate, endDate, data, dataIsLoading } = this.state;
    if (dataIsLoading) {
      return <Spinner />;
    }

    return (
      <Container>
        <PageHeader title={PageNames.AZURE_STORAGE_USAGE} showDate />
        <Card>
          <CardBody style={{ minHeight: '300px' }}>
            <Row style={{ marginLeft: '1px' }}>
              <DatePickerFilter
                onDateChange={this.handleDateChange}
                startDate={datePickerSyntexDates(startDate, null).startDate}
                endDate={datePickerSyntexDates(null, endDate).endDate}
                currPeriodGranLevel="day"
              />
            </Row>
            <Row>
              <AzureStorageTable
                data={data}
                columns={AZURE_STORAGE_TABLE_COLUMNS}
                columnWidths={AZURE_STORAGE_COLUMN_WIDTHS}
                tableColumnExtensions={AZURE_STORAGE_TABLE_COLUMN_EXTENSIONS}
                defaultHiddenColumnNames={AZURE_STORAGE_DEFAULT_HIDDEN_COLUMN_NAMES}
                defaultSorting={AZURE_STORAGE_DEFAULT_SORTING}
                currenciesColumns={AZURE_STORAGE_CURRENCIES_COLUMNS}
                csvTitle={`VM_data_${DateFilter.month}_${DateFilter.year}`}
              />
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const ObserverAzureStorage = observer(AzureStorage);
export default ObserverAzureStorage;
