/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { downloadChartAsPng } from 'shared/utils/downloadAsPng';

const DownloadAsPng = ({ chartRef }) => (
  <a href="#" onClick={() => downloadChartAsPng(chartRef?.current)}>
    Download as PNG
    <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
  </a>
);

export default DownloadAsPng;
DownloadAsPng.propTypes = {
  chartRef: PropTypes.object.isRequired,
};
