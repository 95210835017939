/* eslint-disable */
import { compact, get, groupBy, isFinite, isNil, isString, merge, omit, sortBy } from 'lodash';
import {
  CHART_COLORS,
  getAxisZones,
  getLineChartDefaultDisplayProperties,
  getYAxisDefaults,
  HCHART_TYPES,
  METRIC_SERIES,
  POINT_HOVER_DIAMETER,
  SIMULATION_CHART_COLORS,
} from './timeSeriesHchartSettingsService';
import { palette, COLORS_PICKER } from './theme';
import { getJsTime } from './timeSeriesDataService';

const METRIC_ORIGIN_TYPES = {
  COMPOSITE: 'COMPOSITE',
  ALERT: 'ALERT',
  STREAM: 'STREAM',
  FORECAST: 'FORECAST',
};

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const isSameMetric = (a, b) => {
  if (a.compositeId || b.compositeId) {
    return a.id === b.id && a.compositeId === b.compositeId;
  }
  if (a.expressionTreeId || b.expressionTreeId) {
    return a.id === b.id && a.expressionTreeId === b.expressionTreeId;
  }
  if (a.origin || b.origin) {
    return a.id === b.id && get(a.origin, 'id') === get(b.origin, 'id');
  }
  return a.id === b.id;
};

let andtValTrackingIndex = 0;
const createSeriesAndtVal = (metric, type, dataPointsMeta) => ({
  metric,
  type,
  trackingIndex: (andtValTrackingIndex += 1),
  dataPointsMeta,
});

const getSeriesProperties = (seriesProperties, metric) => {
  if (!seriesProperties) {
    return;
  }
  const props = getLineChartDefaultDisplayProperties();
  const treeProps = seriesProperties.byTreeExp.find((s) => s.id === metric.expressionTreeId);
  merge(props, treeProps && treeProps.options); // for anomalies

  return props;
};

const syncYAxisIndex = (hchart, expTreeProps) => {
  // used to be called getYAxisIndex
  if (expTreeProps === undefined || expTreeProps.yAxis.opposite === false) {
    // if there is one yAxis and its on the right
    if (hchart.yAxis.length === 1 && hchart.yAxis[0].opposite === true) {
      hchart.addAxis({ ...getYAxisDefaults(false), ...hchart.yAxis[0].options });
      return 1;
    }
    // if there is one yAxis and its in the left position
    if (hchart.yAxis.length === 1 && (hchart.yAxis[0].opposite === undefined || hchart.yAxis[0].opposite === false)) {
      hchart.yAxis[0].update({ inUse: true }, false); // set a flag that it can be known that someone owns this axis
      return 0;
    }
    // there are two yAxis

    return hchart.yAxis[0].opposite === true ? 1 : 0;
  }
  if (expTreeProps.yAxis.opposite === true) {
    // if there is currently one yAxis and its in the right side
    if (hchart.yAxis.length === 1 && hchart.yAxis[0].opposite === true) {
      return 0;
    }
    // if there is one yAxis and its in the left position and already owned by another series
    if (
      hchart.yAxis.length === 1 &&
      (hchart.yAxis[0].opposite === undefined || hchart.yAxis[0].opposite === false) &&
      hchart.yAxis[0].options.inUse === true
    ) {
      hchart.addAxis({ ...getYAxisDefaults(true), ...hchart.yAxis[0].options });
      return 1;
    }
    // if it is the first one and it is still in the default for left we want to flip the side
    if (hchart.yAxis.length === 1 && (hchart.yAxis[0].opposite === undefined || hchart.yAxis[0].opposite === false)) {
      hchart.yAxis[0].update({ opposite: true }, false);
      return 0;
    }
    // there are two yAxis

    return hchart.yAxis[0].opposite === true ? 0 : 1;
  }
};

const extractYAxisMostExtremeValue = (expTreeExtremeValue, currentExtremeValue, isDirty) => {
  if (expTreeExtremeValue !== currentExtremeValue) {
    currentExtremeValue = expTreeExtremeValue;
    isDirty = true;
  }
  return { extremeValue: currentExtremeValue, isDirty };
};

const setYAxisRange = (hchart, yAxisIndex, seriesProperties) => {
  const EXP_TREE_MAX_Y_AXIS = isNil(seriesProperties.yAxis.max) ? null : +seriesProperties.yAxis.max;
  const EXP_TREE_MIN_Y_AXIS = isNil(seriesProperties.yAxis.min) ? null : +seriesProperties.yAxis.min;
  let yAxisMax = hchart.yAxis[yAxisIndex].getExtremes().userMax;
  let yAxisMin = hchart.yAxis[yAxisIndex].getExtremes().userMin;
  let isDirty = false;

  const resMax = extractYAxisMostExtremeValue(EXP_TREE_MAX_Y_AXIS, yAxisMax, isDirty);
  const resMin = extractYAxisMostExtremeValue(EXP_TREE_MIN_Y_AXIS, yAxisMin, isDirty);
  yAxisMax = resMax.extremeValue;
  yAxisMin = resMin.extremeValue;
  isDirty = resMax.isDirty || resMin.isDirty;

  if (isDirty) {
    //clear all axis
    hchart.yAxis.forEach((ax) =>
      ax.update(
        {
          max: null,
          min: null,
        },
        false,
      ),
    );
    //set current one
    hchart.yAxis[yAxisIndex].update(
      {
        max: yAxisMax,
        min: yAxisMin,
      },
      false,
    );
  }
};

const setYAxisOptions = (hchart, yAxisIndex, seriesProperties) => {
  if (hchart.yAxis[yAxisIndex].options.type !== seriesProperties.yAxis.type) {
    hchart.yAxis[yAxisIndex].update(
      {
        type: seriesProperties.yAxis.type,
        minorTickInterval: seriesProperties.yAxis.minorTickInterval,
      },
      false,
    );
  }
};

const getForecastHSeries = (hchart, metric, type) =>
  hchart.series.find((item) => {
    const itemMetricOrigin = item.options.andtVal.metric.origin;
    const metricOrigin = metric.origin;
    return (
      isSameMetric(item.options.andtVal.metric, metric) &&
      itemMetricOrigin &&
      metricOrigin &&
      itemMetricOrigin.title === metricOrigin.title &&
      itemMetricOrigin.type === METRIC_ORIGIN_TYPES.FORECAST &&
      metricOrigin.type === METRIC_ORIGIN_TYPES.FORECAST &&
      item.options.andtVal.type === type
    );
  });

const setForecastVisibilityForSeries = (hchart, visibleGroup) => {
  let forecastBaseline;
  if (!visibleGroup) {
    return null;
  }

  const itemFound = visibleGroup.find((item) => {
    return get(item.options.andtVal.metric, 'origin.type', null) === METRIC_ORIGIN_TYPES.FORECAST;
  });

  if (itemFound) {
    forecastBaseline = getForecastHSeries(hchart, itemFound.options.andtVal.metric, METRIC_SERIES.AVERAGE_BASELINE);
    if (forecastBaseline) {
      forecastBaseline.setVisible(true, false);
    }
  } else {
    visibleGroup.forEach((series) => {
      forecastBaseline = getForecastHSeries(hchart, series.options.andtVal.metric, METRIC_SERIES.AVERAGE_BASELINE);
      if (forecastBaseline) {
        forecastBaseline.setVisible(false, false);
      }
    });
  }
};

export const setBaseLineVisibilityForSeries = (hchart) => {
  let hseriesBaseline;
  const visibleGroup = groupBy(hchart.series, (series) => {
    const types = [METRIC_SERIES.ANOMALY_PRIMARY, METRIC_SERIES.AVERAGE_BASELINE, METRIC_SERIES.ANOMALY_SECONDARY];
    return series.name && series.visible && !types.includes(series.options.andtVal.type);
  }).true;
  if (!visibleGroup) {
    return;
  }

  if (visibleGroup.length === 1) {
    hseriesBaseline = getHSeries(hchart, visibleGroup[0].options.andtVal.metric, METRIC_SERIES.AVERAGE_BASELINE);
    if (hseriesBaseline) {
      hseriesBaseline.setVisible(true, false);
    }
  } else {
    visibleGroup.forEach((series) => {
      hseriesBaseline = getHSeries(hchart, series.options.andtVal.metric, METRIC_SERIES.AVERAGE_BASELINE);
      if (hseriesBaseline) {
        hseriesBaseline.setVisible(false, false);
      }
    });

    setForecastVisibilityForSeries(hchart, visibleGroup);
  }
};

export const getYAxisDataMinMax = (hchart) => {
  const res = { min: Number.MAX_VALUE, max: Number.MIN_VALUE };
  hchart.series.forEach((series) => {
    if (get(series, 'options.andtVal.dataPointsMeta.dataMin') < res.min) {
      res.min = series.options.andtVal.dataPointsMeta.dataMin;
    }
    if (get(series, 'options.andtVal.dataPointsMeta.dataMax') > res.max) {
      res.max = series.options.andtVal.dataPointsMeta.dataMax;
    }
  });
  return res;
};

const setYAxisGridLines = (hchart) => {
  let yAxisInUse = [false, false];
  hchart.series.forEach((series) => {
    yAxisInUse[series.options.yAxis] = true;
  });
  yAxisInUse = compact(yAxisInUse);

  // reset grid lines behaviour
  hchart.yAxis.forEach((y) => {
    y.update(
      {
        gridLineWidth: 1,
        tickWidth: 0,
      },
      false,
    );
  });

  // both axis are in use and in opposite sides
  if (yAxisInUse.length === 2) {
    const left = hchart.yAxis.find((y) => y.opposite === false);
    const right = hchart.yAxis.find((y) => y.opposite === true);
    if (left && right) {
      right.update(
        {
          gridLineWidth: 0,
          tickWidth: 1,
          tickLength: 5,
        },
        false,
      );
    }
  }
};

const ensureYAxisPlotLineAndBandsVisible = (hchart, shouldRedraw) => {
  for (let i = 0; i < hchart.yAxis.length; i++) {
    const yAxis = hchart.yAxis[i];
    if (yAxis.options.plotLines) {
      const maxDataPointVal = hchart.series[0].options.andtVal.dataPointsMeta.dataMax;
      const minDataPointVal = hchart.series[0].options.andtVal.dataPointsMeta.dataMin;
      let localMax = maxDataPointVal + maxDataPointVal * yAxis.options.minPadding;
      let localMin = minDataPointVal - Math.abs(maxDataPointVal) * yAxis.options.minPadding;
      const plotLineValues = yAxis.options.plotLines.map((plotLine) => plotLine.value);
      for (let j = 0; j < plotLineValues.length; j++) {
        const plotLineValue = plotLineValues[j];
        if (plotLineValue > localMax) {
          // if max threshold is above yAxis max plotLine then update yAxis max
          localMax = plotLineValue + plotLineValue * yAxis.options.maxPadding;
        }
        if (plotLineValue < localMin) {
          localMin = plotLineValue - Math.abs(plotLineValue) * yAxis.options.minPadding;
        }
      }
      yAxis.update({ min: localMin, max: localMax }, shouldRedraw);
    }
  }
};

const setSeriesYAxisThreshold = (hchart) => {
  const updateThreshold = (series, val) => {
    if (!series.threshold || series.threshold !== val) {
      series.update({ threshold: val }, false); // 4.1.4 undocumented use
    }
  };

  let threshold = 0;
  const res = getYAxisDataMinMax(hchart);
  if (res.min < 0 && res.max < 0) {
    threshold = Math.min(0, 0.8 * res.max);
  } else if (res.min > 0 && res.max > 0) {
    threshold = Math.max(0, 0.8 * res.min);
  }
  hchart.series.forEach((series) => {
    if (series.type === HCHART_TYPES.AREA || series.type === HCHART_TYPES.COLUMN || series.type === HCHART_TYPES.LINE) {
      if (!isString(series.options.stacking)) {
        updateThreshold(series, threshold);
      } else {
        updateThreshold(series, 0);
      }
    } else {
      updateThreshold(series, undefined);
    }
  });
};

const redraw = (hchart) => hchart.redraw();

//* ******
// EXPORTS

export const addPlotLine = (hchart, options, axisType) => {
  const axis = axisType === 'x' ? hchart.xAxis[0] : hchart.yAxis[0];
  axis.addPlotLine(options);
};
export const removePlotLine = (hchart, id, axisType) => {
  const axis = axisType === 'x' ? hchart.xAxis[0] : hchart.yAxis[0];
  axis.removePlotLine(id);
};

export const addPlotBand = (hchart, options, axisType) => {
  const axis = axisType === 'x' ? hchart.xAxis[0] : hchart.yAxis[0];
  axis.addPlotBand(options);
};
export const removePlotBand = (hchart, id, axisType) => {
  const axis = axisType === 'x' ? hchart.xAxis[0] : hchart.yAxis[0];
  axis.removePlotBand(id);
};

export const getLegendMetrics = (hchart) => {
  // used to be updateLegendMetrics
  let { series } = hchart;
  series = series.filter((s) => s.options.andtVal.type === METRIC_SERIES.AVERAGE);
  series = series.map((series) => ({
    options: {
      andtVal: series.options.andtVal,
    },
    name: series.name,
    visible: series.visible,
    color: series.color,
    hasPoints: !!get(series, 'options.data.length', 0),
  }));
  series = sortBy(series, (s) => s.options.andtVal.metric.uiIndex);
  return series;
};

export const startLoad = (hchart) => {
  hchart.hideNoData();
  // hchart.showNoData(' '); // hack making sure no data is really hidden
  hchart.showLoading();
};

export const endLoad = (hchart, startDate, endDate, staticLine, maxDataPoint, isTooMuchData) => {
  hchart.series = hchart.series || [];

  hchart.hideLoading(); // must come before no-data
  const computedEndDate = () => {
    if (endDate && maxDataPoint > endDate) {
      return getJsTime(maxDataPoint);
    }

    if (endDate) {
      return endDate * 1000;
    }

    return null;
  };
  hchart.xAxis[0].setExtremes(startDate ? startDate * 1000 : null, computedEndDate(), false);
  setBaseLineVisibilityForSeries(hchart);
  setSeriesYAxisThreshold(hchart);
  setYAxisGridLines(hchart);
  !staticLine && ensureYAxisPlotLineAndBandsVisible(hchart, false);
  if (!hchart.series[0]?.yData?.length && isTooMuchData) {
    hchart.hideNoData();
    hchart.showNoData('Too many results');
  }
  redraw(hchart);
};

const createForcastZone = (dataPoints, metric, confidenceColor) => {
  // const hIndexFound = metric.properties.find((prop) => prop.key === 'horizon' && prop.value !== '0');
  const hIndexFound = null;
  if (hIndexFound) {
    return {
      zoneAxis: 'x',
      zones: [
        {
          dashStyle: 'ShortDash',
        },
      ],
    };
  }

  if (metric.forecastTime) {
    const futurePointStartIndex = dataPoints.findIndex((dataPoint) => dataPoint[0] === metric.forecastTime * 1000);

    return {
      zoneAxis: 'x',
      zones: [
        {
          value: futurePointStartIndex === -1 ? metric.forecastTime * 1000 : dataPoints[futurePointStartIndex - 1][0],
        },
        {
          dashStyle: 'ShortDash',
          fillColor: confidenceColor,
        },
      ],
    };
  }

  return {};
};

export const getHSeries = (hchart, metric, type) =>
  hchart.series.find((item) => isSameMetric(item.options.andtVal.metric, metric) && item.options.andtVal.type === type);

export const updateColor = ({ metric, tileData, index }) => {
  const existingColor = (
    [
      ...get(tileData, 'lineChart.legend.colors', EMPTY_ARRAY),
      ...get(tileData, 'andtGauge.legend.colors', EMPTY_ARRAY),
    ].find((o) => (metric.id || '').includes(o.id)) || EMPTY_OBJECT
  ).color;
  return existingColor
    ? palette[COLORS_PICKER[existingColor].color][COLORS_PICKER[existingColor].contrast]
    : CHART_COLORS.SERIES_COLORS[index % CHART_COLORS.SERIES_COLORS.length];
};

export const pushSeries = (hchart, seriesProperties, metric, data, isVisible, theme, zoneConfig, tileData) => {
  const chartConfig = theme === 'simulation' ? SIMULATION_CHART_COLORS : CHART_COLORS;
  const hseriesLine = getHSeries(hchart, metric, METRIC_SERIES.AVERAGE);
  if (hseriesLine) {
    hseriesLine.setData(data.line.dataPoints, false, false);
    hseriesLine.update({ name: metric.name, id: metric.id + metric.expressionTreeId }, false);
    hseriesLine.options.andtVal = createSeriesAndtVal(metric, METRIC_SERIES.AVERAGE, data.line.dataPointsMeta);

    if (data.baseline.dataPoints) {
      const hseriesBaseline = getHSeries(hchart, metric, METRIC_SERIES.AVERAGE_BASELINE);
      if (hseriesBaseline) {
        hseriesBaseline.setData(data.baseline.dataPoints, false, false);
        hseriesBaseline.update({ name: metric.name, id: metric.id + metric.expressionTreeId }, false);
        hseriesBaseline.options.andtVal = createSeriesAndtVal(
          metric,
          METRIC_SERIES.AVERAGE_BASELINE,
          data.baseline.dataPointsMeta,
        );
      }
    }

    if (data.anomaly.dataPoints) {
      const hseriesCurrentAnomaly = getHSeries(hchart, metric, METRIC_SERIES.ANOMALY_PRIMARY);
      if (hseriesCurrentAnomaly) {
        hseriesCurrentAnomaly.setData(data.anomaly.dataPoints, false, false);
        hseriesCurrentAnomaly.update({ name: metric.name, id: metric.id + metric.expressionTreeId }, false);
        hseriesCurrentAnomaly.options.andtVal = createSeriesAndtVal(
          metric,
          METRIC_SERIES.ANOMALY_PRIMARY,
          omit(data.anomaly, ['dataPoints', 'unSatisfiedDataPoints']),
        );
      }
    }
    if (data.anomaly.unSatisfiedDataPoints) {
      const hseriesOtherAnomaly = getHSeries(hchart, metric, METRIC_SERIES.ANOMALY_SECONDARY);
      if (hseriesOtherAnomaly) {
        hseriesOtherAnomaly.setData(data.anomaly.unSatisfiedDataPoints, false, false);
        hseriesOtherAnomaly.update({ name: metric.name, id: metric.id + metric.expressionTreeId }, false);
        hseriesOtherAnomaly.options.andtVal = createSeriesAndtVal(
          metric,
          METRIC_SERIES.ANOMALY_SECONDARY,
          omit(data.anomaly, ['dataPoints', 'unSatisfiedDataPoints']),
        );
      }
    }
  } else {
    const expTreeProps = getSeriesProperties(seriesProperties, metric);
    const seriesExpTreeProps = omit(expTreeProps, 'yAxis');
    const yAxisIndex = syncYAxisIndex(hchart, expTreeProps, metric);
    const colorIndex = isFinite(metric.uiIndex)
      ? metric.uiIndex
      : Object.keys(groupBy(hchart.series, (series) => series.name.toLowerCase())).length;
    const computedColor = updateColor({ metric, tileData, index: colorIndex });
    const forecastZones = createForcastZone(
      data.line.dataPoints,
      metric,
      CHART_COLORS.CONFIDANCE_COLORS[colorIndex % CHART_COLORS.CONFIDANCE_COLORS.length],
    );
    setYAxisRange(hchart, yAxisIndex, expTreeProps); // This function is used to manually set min & max from matrics explorer settings
    setYAxisOptions(hchart, yAxisIndex, expTreeProps);

    let seriesZones = {};
    if (zoneConfig) {
      seriesZones = getAxisZones({
        primeColor: CHART_COLORS.SERIES_COLORS[colorIndex % CHART_COLORS.SERIES_COLORS.length],
        primeDashStyle: 'Solid',
        ...zoneConfig,
      });
    }
    hchart.addSeries(
      Object.assign(
        {
          andtVal: createSeriesAndtVal(metric, METRIC_SERIES.AVERAGE, data.line.dataPointsMeta),
          name: metric.name,
          id: metric.id + metric.expressionTreeId,
          data: data.line.dataPoints,
          marker: {
            enabled: data.line.dataPoints.length === 1,
            symbol: 'circle',
            states: {
              hover: {
                radius: POINT_HOVER_DIAMETER / 2,
              },
            },
          },
          lineWidth: 2,
          zIndex: 1,
          color: computedColor,
          yAxis: yAxisIndex,
          visible: data.line.dataPoints.length > 0 && isVisible,
        },
        seriesExpTreeProps,
        forecastZones,
        seriesZones,
      ),
      false,
      false,
    );

    if (data.baseline.dataPoints) {
      hchart.addSeries(
        {
          andtVal: createSeriesAndtVal(metric, METRIC_SERIES.AVERAGE_BASELINE, data.baseline.dataPointsMeta),
          enableMouseTracking: false,
          includeInCSVExport: false,
          name: metric.name,
          linkedTo: metric.id + metric.expressionTreeId, // ':previous',
          data: data.baseline.dataPoints,
          type: HCHART_TYPES.AREA_RANGE,
          lineWidth: 0,
          fillOpacity: 0.35,
          zIndex: 0,
          color: computedColor,
          yAxis: yAxisIndex,
          stacking: null,
          visible: false,
        },
        false,
        false,
      );
    }

    if (data.anomaly.dataPoints.length) {
      hchart.addSeries(
        Object.assign(
          {
            andtVal: createSeriesAndtVal(
              metric,
              METRIC_SERIES.ANOMALY_PRIMARY,
              omit(data.anomaly, ['dataPoints', 'unSatisfiedDataPoints']),
            ),
            enableMouseTracking: true,
            name: metric.name,
            linkedTo: metric.id + metric.expressionTreeId,
            data: data.anomaly.dataPoints,
            marker: {
              enabled: data.anomaly.isMainSinglePoint,
              symbol: 'circle',
              states: {
                hover: {
                  radius: POINT_HOVER_DIAMETER / 2,
                },
              },
            },
            lineWidth: 3,
            color: chartConfig.ANOMALY_LINE_COLOR,
            yAxis: yAxisIndex,
            zIndex: 2,
            visible: data.anomaly.dataPoints.length > 0,
          },
          seriesExpTreeProps,
          { stacking: null },
        ),
        false,
        false,
      );
    }
    if (data.anomaly.unSatisfiedDataPoints.length) {
      hchart.addSeries(
        Object.assign(
          {
            andtVal: createSeriesAndtVal(
              metric,
              METRIC_SERIES.ANOMALY_SECONDARY,
              omit(data.anomaly, ['dataPoints', 'unSatisfiedDataPoints']),
            ),
            enableMouseTracking: true,
            name: metric.name,
            linkedTo: metric.id + metric.expressionTreeId,
            data: data.anomaly.unSatisfiedDataPoints,
            lineWidth: 3,
            color: chartConfig.UNSATISFIED_ANOMALY_LINE_COLOR,
            yAxis: yAxisIndex,
            zIndex: 2,
            visible: data.anomaly.unSatisfiedDataPoints.length > 0,
          },
          seriesExpTreeProps,
          { stacking: null },
        ),
        false,
        false,
      );
    }
  }
};
