/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { IpPublicIpv4Recommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class IpPublicIpv4RecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new IpPublicIpv4Recommendation();
  }

  getRecommType() {
    return RecommendationTypes.IP_PUBLIC_IPV4;
  }

  buildRecommTitle() {}

  build() {
    super.build();
    const { region, operation_name } = this._rawRecommendation;
    if (region && !this._recommendation.region) {
      this._recommendation.region = region;
    }
    if (operation_name && !this._recommendation.operationName) {
      this._recommendation.operationName = operation_name;
    }
  }
}
