import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class WorkloadTypeDataState extends BaseCostUsageDataState {
  getNewDataStateType = () => {
    const newDataState = CostUsageStates.WORKLOAD;
    return newDataState;
  };

  getDataStateType = () => CostUsageStates.WORKLOAD_TYPE;
}
