import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './AnomalyTooltip.module.scss';

const AnomalyTooltip = ({ date, minPoint, color }) => (
  <div className={styles.anomalyTooltip}>
    <div className={styles.date}>{moment(date).format('ddd, MMM D [@] h:mm A')}</div>
    <span className={styles.minPoint} style={{ background: color }}>
      {minPoint}
    </span>
  </div>
);

AnomalyTooltip.propTypes = {
  date: PropTypes.string.isRequired,
  minPoint: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default AnomalyTooltip;
