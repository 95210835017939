import React from 'react';
import PropTypes from 'prop-types';
import MaxReadWriteChart from 'recommendations/shared/components/MaxReadWriteChart';

const getSortedDateList = (dates) => {
  const sortDates = dates.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });
  return sortDates;
};

const createReadWriteBytesData = (rec) => {
  const { maxDailyReadBytes, maxDailyWriteBytes } = rec;

  if (!maxDailyReadBytes || !maxDailyWriteBytes) return [];
  const bytesData = getSortedDateList(Object.keys(maxDailyReadBytes)).map((key) => ({
    usageDate: key,
    write: maxDailyWriteBytes[key],
    read: maxDailyReadBytes[key],
  }));

  return bytesData;
};

const createReadWriteIopsData = (rec) => {
  const { maxDailyReadIops, maxDailyWriteIops } = rec;

  if (!maxDailyReadIops || !maxDailyWriteIops) return [];
  const iopsData = getSortedDateList(Object.keys(maxDailyReadIops)).map((key) => ({
    usageDate: key,
    read: maxDailyReadIops[key],
    write: maxDailyWriteIops[key],
  }));

  return iopsData;
};

const Statistics = ({ rec }) => {
  const dailyReadWriteBytesData = createReadWriteBytesData(rec);
  const dailyReadWriteIopsData = createReadWriteIopsData(rec);

  return (
    <div>
      {!!dailyReadWriteIopsData?.length && (
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">DAILY MAX IOPS PERFORMANCE</p>
          <br />
          <MaxReadWriteChart data={dailyReadWriteIopsData} dataType="IOPs" />
        </div>
      )}
      {!!dailyReadWriteBytesData?.length && (
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">DAILY MAX BYTES PER SEC PERFORMANCE </p>
          <br />
          <MaxReadWriteChart data={dailyReadWriteBytesData} dataType="Bytes" />
        </div>
      )}
    </div>
  );
};

export default Statistics;

Statistics.propTypes = {
  rec: PropTypes.object.isRequired,
};
