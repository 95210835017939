/* eslint-disable max-len */
/* eslint-disable arrow-parens */

import React from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import CustomizedLabel from 'shared/components/CustomizedLabel';
import { kIntFormmater } from 'shared/utils/strUtil';
import { roundNumber } from 'shared/utils/mathUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const BreakEvenGraph = ({ breakEvenData }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={252}>
      <LineChart width={550} height={252} data={breakEvenData}>
        <XAxis dataKey="name">
          <Label value="Months" offset={35} position="insideBottomRight" />
        </XAxis>
        <YAxis
          label={{
            value: 'Cost',
            offset: 0,
            angle: -90,
            position: 'left',
          }}
          tickFormatter={(value) => getCurrencyNumber(kIntFormmater(value))}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend height={-40} />
        <ReferenceLine
          y={breakEvenData[0]['RI Cost']}
          label={
            <CustomizedLabel
              x={300}
              y={58}
              value={`RI Cost: ${getCurrencyNumber(roundNumber(breakEvenData[0]['RI Cost']), 0)}`}
              fontSize={14}
            />
          }
          stroke="#8a85ea"
          strokeDasharray="6 6"
        />
        <Line type="monotone" dataKey="On-Demand Cost" stroke="#70bbfd" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

BreakEvenGraph.propTypes = {
  breakEvenData: PropTypes.object.isRequired,
};

export default BreakEvenGraph;
