/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import ViewsTable from './components/ViewsTable';

const ViewsPage = (props) => (
  <Container>
    <PageHeader showDate title={PageNames.VIEWS} />
    <Card>
      <CardBody>
        <Row>
          <ViewsTable {...props} />
        </Row>
      </CardBody>
    </Card>
  </Container>
);

ViewsPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

export default ViewsPage;
