import React from 'react';
import PropTypes from 'prop-types';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import { OPERATORS, isOperatorInput, isOperatorSelect, OPERATORS_KEYS } from 'shared/constants/appConstants';

const OperatorSelect = ({ value: { operator, field, prefix, values, ruleId, index }, filterProps, handleChange }) => {
  const { strictMatch, strictPositiveMatch, notStrictMatch: notStrictMatchFromField } = filterProps[field] || {};
  const { notStrictMatch: notStrictMatchFromPrefix } = filterProps[prefix] || {};
  const notStrictMatch = notStrictMatchFromField || notStrictMatchFromPrefix;
  return (
    <SimpleSelect
      value={operator}
      label="Operator"
      isClearable={false}
      onChange={(value) => {
        const changedToInput = isOperatorInput(value) && isOperatorSelect(operator);
        const changedToSelect = isOperatorSelect(value) && isOperatorInput(operator);
        let newValues = values;
        if (changedToInput) {
          newValues = '';
        }
        if (changedToSelect) {
          newValues = [];
        }
        handleChange({
          values: newValues,
          operator: value,
          field,
          prefix,
          ruleId,
          index,
        });
      }}
      options={Object.keys(OPERATORS)
        .filter((key) => !strictPositiveMatch || key === OPERATORS_KEYS.IS)
        .filter((key) => !strictMatch || isOperatorSelect(key))
        .filter((key) => !notStrictMatch || isOperatorInput(key))
        .map((op) => ({
          label: OPERATORS[op],
          value: op,
        }))}
    />
  );
};

OperatorSelect.propTypes = {
  value: PropTypes.shape({
    values: PropTypes.array.isRequired,
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    ruleId: PropTypes.number,
    index: PropTypes.number,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  filterProps: PropTypes.object.isRequired,
};

export default OperatorSelect;
