import { FILTERS, STATUS_FILTER_PRESETS, USER_STATUS_FILTERS } from '../consts';

export const useFilterStatus = (filters) => {
  const isNotCompletedFilter = (filterData) =>
    [STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS.id].includes(filterData[FILTERS.STATUS_FILTER.id]) ||
    (filterData[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id &&
      filterData[FILTERS.IS_OPEN.id] === true);

  const isOnlyOpenFilter = (filterData) =>
    [STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS.id].includes(filterData[FILTERS.STATUS_FILTER.id]) ||
    (filterData[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id &&
      filterData[FILTERS.IS_OPEN.id] === true &&
      filterData[FILTERS.USER_STATUS.id]?.[USER_STATUS_FILTERS.ITEMS.DONE.id] === false);
  const isOpenFilter = (filterData) =>
    [
      STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS.id,
      STATUS_FILTER_PRESETS.EXCLUDED.id,
      STATUS_FILTER_PRESETS.USER_ACTIONS.id,
    ].includes(filterData[FILTERS.STATUS_FILTER.id]) ||
    (filterData?.[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id &&
      filterData?.[FILTERS.IS_OPEN.id] !== false);

  const isExcludeFilter = (filterData) =>
    [STATUS_FILTER_PRESETS.EXCLUDED.id, STATUS_FILTER_PRESETS.USER_ACTIONS.id].includes(
      filterData[FILTERS.STATUS_FILTER.id],
    ) ||
    (filterData?.[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id &&
      filterData?.[FILTERS.USER_STATUS.id][USER_STATUS_FILTERS.ITEMS.EXCLUDED.id] !== false);

  const isDoneFilter = (filterData) =>
    [STATUS_FILTER_PRESETS.ACTUAL_SAVINGS.id, STATUS_FILTER_PRESETS.USER_ACTIONS.id].includes(
      filterData[FILTERS.STATUS_FILTER.id],
    ) ||
    (filterData[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id &&
      filterData?.[FILTERS.USER_STATUS.id]?.[USER_STATUS_FILTERS.ITEMS.DONE.id] !== false);

  const isCompletedFilter = (filterData) =>
    [
      STATUS_FILTER_PRESETS.ACTUAL_SAVINGS.id,
      STATUS_FILTER_PRESETS.EXCLUDED.id,
      STATUS_FILTER_PRESETS.USER_ACTIONS.id,
    ].includes(filterData[FILTERS.STATUS_FILTER.id]) ||
    (filterData[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id &&
      filterData?.[FILTERS.IS_OPEN.id] !== true);

  const isDoneOrCompletedOnlyFilter = (filterData) =>
    [
      STATUS_FILTER_PRESETS.ACTUAL_SAVINGS.id,
      STATUS_FILTER_PRESETS.EXCLUDED.id,
      STATUS_FILTER_PRESETS.USER_ACTIONS.id,
    ].includes(filterData[FILTERS.STATUS_FILTER.id]) ||
    (filterData[FILTERS.STATUS_FILTER.id] === STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id &&
      filterData[FILTERS.IS_OPEN.id] === false);

  return {
    isOpen: isOpenFilter(filters),
    isOnlyOpen: isOnlyOpenFilter(filters),
    isNotCompleted: isNotCompletedFilter(filters),
    isExclude: isExcludeFilter(filters),
    isDone: isDoneFilter(filters),
    isCompleted: isCompletedFilter(filters),
    isDoneOrCompletedOnly: isDoneOrCompletedOnlyFilter(filters),
  };
};
