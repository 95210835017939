/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Legend, Pie, PieChart, ResponsiveContainer, Text, Tooltip } from 'recharts';
import { Row } from 'reactstrap';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const renderLegend = ({ payload }) => (
  // <ul className="dashboard__chart-legend">
  <ul style={{ display: 'flex', 'justify-content': 'space-evenly', 'font-size': '14px' }}>
    {payload
      .filter((payloadItem) => payloadItem.payload.value > 0)
      .map((entry, index) => (
        <li key={`item-${index}`} style={{ color: `${entry.color}`, 'list-style-type': 'square' }}>
          {/* <span  /> */}
          {entry.value}
        </li>
      ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      vslue: PropTypes.string,
    }),
  ).isRequired,
};

const PieChartBasic = memo((props) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <Row id={props.chartId} className="chart-box-style">
      <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={props.height}>
        <PieChart className="dashboard__chart-pie-container">
          <Pie data={props.data} dataKey="value" nameKey="name" cy={140} innerRadius="60%" outerRadius="85%" />
          {props.showLegend && <Legend content={renderLegend} />}
          <Tooltip
            formatter={(value) =>
              `${
                props.isAbsValues
                  ? `-${getCurrencyNumber(value, 0, { roundNumber: true })}`
                  : getCurrencyNumber(value, 0, { roundNumber: true })
              }`
            }
          />
          <Text x={400} y={200} textAnchor="middle" dominantBaseline="middle">
            Donut
          </Text>
        </PieChart>
      </ResponsiveContainer>
    </Row>
  );
});

PieChartBasic.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.object.isRequired,
  isAbsValues: PropTypes.bool,
  showLegend: PropTypes.bool,
  chartId: PropTypes.string,
};

PieChartBasic.defaultProps = {
  chartId: '',
  isAbsValues: false,
  showLegend: false,
};

export default PieChartBasic;
