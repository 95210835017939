/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

// eslint-disable-next-line no-unused-vars
const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul>
      <li>Using the AWS CLI:</li>
      <ol>
        <li>
          {'Use the following request-spot-instances command to create a one-time request:'}
          <CodeBlock
            text={
              'aws ec2 request-spot-instances --instance-count {{instanceCount}} --type "one-time" --launch-specification {{specification}}'
            }
            variables={{
              instanceCount: 1,
              specification: 'file://specification.json',
            }}
          />
          <br />
        </li>
      </ol>
      <li>
        Using the AWS Console:
        <NavLink target="_blank" href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/spot-requests.html">
          Spot Instance Requests
        </NavLink>
      </li>
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
