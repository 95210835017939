import React from 'react';
import classNames from 'classnames';
import { ReactComponent as AWSSimple } from 'shared/img/cloud-providers/simple/aws.svg';
import { ReactComponent as AzureSimple } from 'shared/img/cloud-providers/simple/azure.svg';
import { ReactComponent as GCPSimple } from 'shared/img/cloud-providers/simple/gcp.svg';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

import styles from './RowIconNameAndText.module.scss';

const RowIconNameAndText = ({ cloudType, title, subTitle, isMain }) => (
  <div className={styles.iconNameAndTextContainer}>
    {cloudType === CLOUD_TYPE_IDS.AWS && <AWSSimple className={styles.iconNameAndText} />}
    {cloudType === CLOUD_TYPE_IDS.AZURE && <AzureSimple className={styles.iconNameAndText} />}
    {cloudType === CLOUD_TYPE_IDS.GCP && <GCPSimple className={styles.iconNameAndText} />}
    <div className={classNames(styles.title, { [styles.isMain]: isMain })}>{title}</div>
    <div className={classNames(styles.subTitle, { [styles.isMain]: isMain })}>{subTitle}</div>
  </div>
);

export default RowIconNameAndText;
