import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { AZURE_PROPERTIES, DB_PROPERTIES, ENGINE_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const AZURE_KUSTO_UNUSED_DATA_PROPERTIES = {
  CLUSTER_NAME: {
    label: 'Cluster Name',
    getterFunction: (recommendation) => recommendation?.recData?.cluster_name,
  },
};
const AzureKustoUnusedData = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `az kusto cluster delete --cluster-name ${recommendation?.recData?.cluster_name} --resource-group ${recommendation?.recData?.resource_group}`,
        },
      ],
    },
  ];
  const description =
    // eslint-disable-next-line max-len
    'This cluster has a small amount of data, queries, and ingestion events during the past 30 days, low CPU usage during the past two days, and no followers during the past day';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...ENGINE_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
        ...AZURE_KUSTO_UNUSED_DATA_PROPERTIES,
      }}
    />
  );
};

AzureKustoUnusedData.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureKustoUnusedData;
