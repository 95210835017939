import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { copyStrToClipboard } from 'shared/utils/strUtil';
import styles from './TextCopy.module.scss';
import Tooltip from './Tooltip';
import tooltipStyles from './Tooltip.module.scss';

const TextCopy = ({ text, charsLimit, copyTitle }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    e.stopPropagation();
    copyStrToClipboard(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <Tooltip
    title={copied ? 'Copied!' : copyTitle}
    classes={{ tooltip: tooltipStyles.darkGrayTooltip }}
    placement="top"
    arrow={false}
    >
      <div className={styles.textCopy} onClick={copyToClipboard} style={{ width: `${charsLimit}ch` }}>
        {copied ? <GenerateIcon iconName={ICONS.check.name} /> : <span>{text.slice(0, charsLimit)}</span>}
      </div>
    </Tooltip>
  );
};

TextCopy.propTypes = {
  text: PropTypes.string.isRequired,
  charsLimit: PropTypes.number,
  copyTitle: PropTypes.string,
};

TextCopy.defaultProps = {
  charsLimit: 8,
  copyTitle: 'Copy full ID',
};

export default TextCopy;
