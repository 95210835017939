import React, { useEffect, useState } from 'react';
import { Action, HierarchicalEntityCategory, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Button from 'shared/components/andtComponents/Button';
import toast from 'shared/components/andtComponents/Toast';
import styles from './NotificationSettings.module.scss';

export default function NotificationSettings({ usersStore }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isBudget, setIsBudget] = useState(false);
  const [isAnomalyDetection, setIsAnomalyDetection] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const onFirstRender = async () => {
      try {
        const notifications = await usersStore.fetchUserNotifications();
        if (notifications) {
          setIsBudget(notifications.isBudget);
          setIsAlert(notifications.isAlert);
          setIsAnomalyDetection(notifications.isAnomalyDetection);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    onFirstRender();
  }, []);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSaving(true);
      await usersStore.updateUserNotificationSettings({
        isBudget,
        isAlert,
        isAnomalyDetection,
      });
      toast.success('Notifications settings saved.');
    } finally {
      setIsSaving(false);
    }
  };

  function renderNotificationsSubSection(states, arrLabels, setters) {
    return Object.keys(states).map((key, index) => (
      <div key={key}>
        <FormControlLabel
          control={<Checkbox isChecked={states[key]} onChange={() => setters[index](!states[key])} primary />}
          label={arrLabels[index]}
          style={{ float: 'left' }}
        />
      </div>
    ));
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>Notification Settings</div>
      </div>
      {isLoading ? <Spinner /> : null}
      <div>
        <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Organization} action={Action.Update}>
          <div className={styles.label}>
            <p>What type of notifications would you like to get?</p>
          </div>
          <div className={styles.checkboxList}>
            {renderNotificationsSubSection(
              {
                isBudget,
                isAnomalyDetection,
                isAlert,
              },
              ['Budget', 'Anomaly Detection', 'Alerts'],
              [setIsBudget, setIsAnomalyDetection, setIsAlert],
            )}
          </div>
          <div>
            <Button isLoading={isSaving} text="Save" onClick={onFormSubmit} />
          </div>
        </ReadOnlyDisplayWrapper>
      </div>
    </>
  );
}

NotificationSettings.propTypes = {
  usersStore: PropTypes.object.isRequired,
};
