import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { OPERATORS, PageNames } from 'shared/constants/appConstants';
import { AwsCommonFields, CUSTOM_TAGS_NOT_TAGGED } from 'shared/constants/awsConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import Button from 'shared/components/andtComponents/Button';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { SPLIT_TYPES } from '../../constants/businessMappingConstants';
import styles from './ExpandedMappingInfo.module.scss';

const ExpandedMappingInfo = ({ row, groups, usersStore, virtualTags, getPageFilters }) => {
  const [expanded, setExpanded] = useState(false);
  const { filters, split, splitOptions } = row || {};
  const fieldToFieldDistinctValuesMap = getPageFilters(
    PageNames.BUSINESS_MAPPING,
    usersStore.currDispUserCloudAccountType,
  );
  const renderFilterInfo = (filter) => {
    const { values, operator, field, prefix } = filter;
    const isVirtualCustomTags = field === 'virtualcustomtags';
    const isLinkedAccId = field === AwsCommonFields.LINKED_ACCOUNT_ID;
    const renderValuesArray = () => {
      if (prefix === CUSTOM_TAGS_NOT_TAGGED) {
        return 'Not tagged';
      }
      if (isLinkedAccId) {
        const linkedAccounts = fieldToFieldDistinctValuesMap.get(AwsCommonFields.LINKED_ACCOUNT_ID);
        return values
          .map((f) => {
            const linkedAcc = (linkedAccounts || []).find((l) => l.linkedAccountId === f);
            return linkedAcc ? `${linkedAcc.linkedAccountName} (${linkedAcc.linkedAccountId})` : f;
          })
          .join(', ');
      }
      if (isVirtualCustomTags && !prefix) {
        return values
          .map((f) => {
            const vt = virtualTags?.find((v) => v.uuid === f);
            return (vt || {}).name || f;
          })
          .join(', ');
      }
      return values.map((v) => (prefix ? `${prefix}: ${v}` : v)).join(', ');
    };
    const renderValuesString = () => (prefix ? `${prefix}: ${values}` : values);
    return (
      <Fragment key={field}>
        <div className={styles.conditionLabel}>
          {LabelCoordinator.getFieldLabelByCloudType(field, usersStore.currDispUserCloudAccountType)}:
        </div>
        <div className={styles.conditionValues}>
          <span className="bold-text">{OPERATORS[operator]}:&nbsp;</span>
          {Array.isArray(values) ? renderValuesArray() : renderValuesString()}
        </div>
      </Fragment>
    );
  };
  const groupedByRule = groupBy(filters, (v) => v.ruleId || 1);
  const getSplitTypeDescription = () => {
    if (!splitOptions) {
      return null;
    }
    if (splitOptions.splitType === SPLIT_TYPES.EQUAL) {
      return 'Split evenly';
    }
    if (splitOptions.splitType === SPLIT_TYPES.RATIO) {
      return 'Split by ratio';
    }
    if (splitOptions.splitType === SPLIT_TYPES.RATIO_MONTHLY) {
      return 'Split by monthly cost ratio';
    }
    return 'Custom split';
  };
  return (
    <div className={styles.container} onDragStart={(e) => e.preventDefault()}>
      {Object.keys(groupedByRule)
        .slice(0, expanded ? Infinity : 2)
        .map((ruleId, index) => (
          <div className={styles.rule} key={ruleId}>
            <div>
              <div>Rule {index + 1}</div>
            </div>
            <div className={styles.conditions}>{groupedByRule[ruleId].map(renderFilterInfo)}</div>
          </div>
        ))}
      {Object.keys(groupedByRule).length > 2 && (
        <Button onClick={() => setExpanded(!expanded)} text={expanded ? 'Show less' : 'Show More'} isTextButton />
      )}
      {split ? (
        <div className={styles.splitOptions}>
          <span>{getSplitTypeDescription()} among: </span>
          {splitOptions.mappings
            .map((id) => {
              const mapping = groups.find((g) => g.uuid === id);
              return (mapping || {}).name;
            })
            .filter(Boolean)
            .join(', ')}
        </div>
      ) : null}
    </div>
  );
};

ExpandedMappingInfo.propTypes = {
  row: PropTypes.object.isRequired,
  virtualTags: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  usersStore: PropTypes.object.isRequired,
  getPageFilters: PropTypes.func.isRequired,
};

export default withInvoiceFiltersContextConsumer(ExpandedMappingInfo);
