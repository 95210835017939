/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      {`AWS introduces different types of instances according to application loads, memory, storage and networking capacity to offer better performance.
We recommend you to use the latest generation of instances to get the best performance and pricing.`}
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
