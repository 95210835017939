import React from 'react';
import PropTypes from 'prop-types';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  RESERVED_INSTANCE_PROPERTIES,
} from 'recommendationsNew/components/detailedRecommendation/recommendationPropertyUtils';

import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import RecommendationOptionTable from '../../recommendationGenericComponents/recommendationOptionTable';

const RDS_AURORA_PROPERTIES = {
  CLUSTER_NAME: {
    label: 'Cluster',
    getterFunction: (recommendation) => recommendation?.recData?.cluster_name,
  },
};

const RdsAuroraIops = ({ recommendation }) => {
  const description = `Your Aurora Cluster has high I/O activity.
     We recommend switching to the new AWS Aurora I/O-Optimized configuration. \n
     With this setup, you'll pay a higher rate for storage and instance hours,
     but you won't incur charges for I/O operations.`;

  const properties = {
    ...COST_PROPERTIES,
    ...RDS_AURORA_PROPERTIES,
    ...RESERVED_INSTANCE_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const RECOMMENDED_COLUMNS = [
    {
      label: 'Instance',
      currentGetterFunction: (recommendation) => recommendation?.recData.current_instance_cost,
      recommendedGetterFunction: (recommendation) => recommendation?.recData.expected_instance_cost,
    },
    {
      label: 'Storage',
      currentGetterFunction: (recommendation) => recommendation?.recData.current_storage_cost,
      recommendedGetterFunction: (recommendation) => recommendation?.recData.expected_storage_cost,
    },
    {
      label: 'IOPS',
      currentGetterFunction: (recommendation) => recommendation?.recData?.current_iops_cost,
      recommendedGetterFunction: () => 0,
    },
    {
      label: 'Backup',
      currentGetterFunction: (recommendation) => recommendation?.recData?.current_storage_bckp_cost,
      recommendedGetterFunction: (recommendation) => recommendation?.recData?.current_storage_bckp_cost,
    },
    {
      label: 'Total',
      currentGetterFunction: (recommendation) => properties.CURRENT_ANNUAL_COST?.getterFunction(recommendation),
      recommendedGetterFunction: (recommendation) => properties.RECOMMENDED_ANNUAL_COST?.getterFunction(recommendation),
    },
  ];

  const actionCommand = `aws rds modify-db-instance 
                        --db-instance-identifier ${recommendation?.resourceId}
                        --storage-type aurora-iopt1 
                        --apply-immediately`;
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionCommand,
        },
      ],
    },
  ];

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const recommendationOptionTable = (
    <RecommendationOptionTable
      columns={RECOMMENDED_COLUMNS}
      recommendation={recommendation}
      header="Cluster breakdown"
      description="costs from the last 30 days"
    />
  );
  const descriptionComponent = <RecommendationInsideLook description={description} />;

  const commandComponent = <RecommendationCommand commandsList={commandsList} />;
  return (
    <RecommendationDetailsLayout
      details={detailsComponent}
      preferences={preferencesComponent}
      insideLook={descriptionComponent}
      command={commandComponent}
      recommendationOptionTable={recommendationOptionTable}
    />
  );
};

RdsAuroraIops.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RdsAuroraIops;
