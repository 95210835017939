import React from 'react';
import DescriptionWrapper from 'recommendations/shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>
      {`The objects in this bucket are rarely used. 
        We recommend changing this bucket's storage class to a more suitable one.`}
    </p>
  </DescriptionWrapper>
);

export default Description;
