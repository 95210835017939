/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { Ec2LowCpuUsageRecommendation } from 'recommendations/models/recommTypes';
import { Ec2InstanceBasicData } from 'recommendations/models/recommCommonTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class Ec2LowCpuUsageRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new Ec2LowCpuUsageRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_LOW_CPU_USAGE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EC2 Low CPU Usage`;
  }

  build() {
    super.build();
    const {
      instance_type_size_recommended: recommSize,
      network_in: networkIn,
      network_out: networkOut,
      num_of_days: numOfDays,
      instance_type_model: recommVersion,
      project,
      zone_tag: region,
      operation_system: os,
      quantity: resourcesQuantity,
      environment,
      resources,
      additional_recommended_models: additionalRecModels,
      specs_details_current: specsCurr,
      specs_details_recommended: specsRecomm,
      cpu_util: cpuUtil,
      resource_id: resourceId,
      resource_name: resourceName,
      // ec2_id: resourceId,
      max_cpu_util: maxCpuUtil,
      cpu_util_statistics_usage: cpuUtilStatisticsUsage,
      model,
      model_recommended: modelRecommended,
      ri_savings: riSavings,
      ri_coverage: riCoverage,
      sp_savings: spSavings,
      sp_coverage: spCoverage,
      cpu_util_recommended_usage: cpuUtilRecommendedUsage,
      max_memory: maxMemory,
      // cpu_util_usage_capacity_recommended: cpuUtilUsageCapacityRecommended,
      network_in_statistics_usage: networkInStatisticsUsage,
      network_out_statistics_usage: networkOutStatisticsUsage,
      mem_used_percent_statistics_usage: memUsedPercentStatisticsUsage,
      cpu_util_statistics_avg_usage: cpuUtilStatisticsAvgUsage,
      mem_used_percent_statistics_avg_usage: memUsedPercentStatisticsAvgUsage,
      mem_used_percent_statistics_monthly_avg_usage: memUsedPercentStatisticsMonthlyAvgUsage,
      cpu_util_statistics_monthly_avg_usage: cpuUtilStatisticsMonthlyAvgUsage,
    } = this._rawRecommendation;

    const recommEc2Instance = new Ec2InstanceBasicData(recommVersion, recommSize, os, region, environment, resourceId);
    this._recommendation.recommEc2Instance = recommEc2Instance;
    this._recommendation.recommSize = recommSize;
    this._recommendation.networkIn = networkIn;
    this._recommendation.networkOut = networkOut;
    this._recommendation.numOfDays = parseInt(numOfDays, 10);
    this._recommendation.cpuUtil = parseFloat(cpuUtil);
    this._recommendation.resourceId = resourceId;
    this._recommendation.resourceName = resourceName;
    this._recommendation.project = project;
    this._recommendation.resourcesQuantity = resourcesQuantity;
    this._recommendation.resources = resources;
    this._recommendation.additionalRecModels = additionalRecModels;
    this._recommendation.specsCurr = specsCurr;
    this._recommendation.specsRecomm = specsRecomm;
    this._recommendation.maxCpuUtil = maxCpuUtil;
    this._recommendation.maxMemory = maxMemory;
    this._recommendation.cpuUtilStatisticsUsage = cpuUtilStatisticsUsage;
    this._recommendation.model = model;
    this._recommendation.modelRecommended = modelRecommended;
    this._recommendation.riSavings = riSavings;
    this._recommendation.riCoverage = riCoverage;
    this._recommendation.spSavings = spSavings;
    this._recommendation.spCoverage = spCoverage;
    this._recommendation.cpuUtilRecommendedUsage = cpuUtilRecommendedUsage;
    this._recommendation.networkInStatisticsUsage = networkInStatisticsUsage;
    this._recommendation.networkOutStatisticsUsage = networkOutStatisticsUsage;
    this._recommendation.cpuUtilStatisticsAvgUsage = cpuUtilStatisticsAvgUsage;
    this._recommendation.memUsedPercentStatisticsAvgUsage = memUsedPercentStatisticsAvgUsage;
    this._recommendation.memUsedPercentStatisticsMonthlyAvgUsage = memUsedPercentStatisticsMonthlyAvgUsage;
    this._recommendation.cpuUtilStatisticsMonthlyAvgUsage = cpuUtilStatisticsMonthlyAvgUsage;
    this._recommendation.memUsedPercentStatisticsUsage =
      memUsedPercentStatisticsUsage === 'Not Available' ? null : memUsedPercentStatisticsUsage;

    // BETA
    // this._recommendation.isBeta = true;
  }
}
