import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { CSVLink } from 'react-csv';
import { Card, CardBody, Col, Container } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import CustomersTable from 'divisions/containers/Customers/components/CustomersTable';
import { useCustomers } from '../hooks/useCustomers';

export const BILLING_STATUS_TABLE_COLUMNS = [
  {
    name: 'customerName',
    title: 'Customer',
    getCellValue: (row) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', row.customerName),
  },
  { name: 'mtdResellerCost', title: 'MTD Reseller Cost' },
  { name: 'mtdCustomerCost', title: 'MTD Customer Cost' },
  { name: 'riMargin', title: 'RI Margin' },
  { name: 'usageMargin', title: 'Usage Margin' },
  { name: 'totalMarginCost', title: 'Total Margin' },
  {
    name: 'userAction',
    title: 'Actions',
  },
];

export const BILLING_STATUS_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'customerName', width: 300 },
  { columnName: 'mtdResellerCost', width: 200 },
  { columnName: 'mtdCustomerCost', width: 200 },
  { columnName: 'riMargin', width: 200 },
  { columnName: 'usageMargin', width: 200 },
  { columnName: 'totalMarginCost', width: 200 },
  { columnName: 'userAction', width: 200 },
];

const CustomersPage = ({ usersStore, rootStore, invoiceStore }) => {
  const { getCustomers } = useCustomers();
  const { isLoading, data: customersTableData } = getCustomers();
  const [isProcessing] = useState(false);

  const handleChangeToCustomerView = (e, divId, divisionName, accountKey) => {
    usersStore.updateCurrDisplayedDivIdAndName(divId, divisionName, accountKey);
    rootStore.invalidateStores();
  };

  const prepareCsvModifiedRows = (data) => {
    let csvModifiedRows = cloneDeep(data);
    csvModifiedRows = csvModifiedRows.map(({ accountKey, divisionId, divisionTypeId, ...rest }) => rest);
    return csvModifiedRows;
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container>
      <PageHeader title={PageNames.CUSTOMERS} />
      <Card>
        <CardBody>
          {isProcessing && <Spinner />}
          <Col xs={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CSVLink data={prepareCsvModifiedRows(customersTableData)} headers={null} filename="billing-status.csv">
              Export as CSV
              <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
            </CSVLink>
          </Col>
          <CustomersTable
            tableData={customersTableData}
            handleChangeToCustomerView={handleChangeToCustomerView}
            usersStore={usersStore}
            invoiceStore={invoiceStore}
            columns={BILLING_STATUS_TABLE_COLUMNS}
            columnWidths={BILLING_STATUS_TABLE_COLUMNS_WIDTHS}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

CustomersPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

const ObserverCustomersPage = observer(CustomersPage);
export default ObserverCustomersPage;
