import React, { PureComponent } from 'react';
import Select, { createFilter } from 'react-select';
import SelectAsync from 'react-select/async';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import MenuList from './menuList/MenuList';

const debouncedLoader = debounce((str, onLoad, callBack) => {
  onLoad(str).then((result) => {
    callBack(
      result.map((value) => ({
        value,
        label: value,
      })),
    );
  });
}, 500);

class FieldFilter extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    handleChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    style: PropTypes.object,
    placeHolder: PropTypes.string,
    isAutoComplete: PropTypes.bool,
    singleSelect: PropTypes.bool,
    isLoading: PropTypes.bool,
    onLoad: PropTypes.func,
    styles: PropTypes.object,
    isClearable: PropTypes.bool,
    automationId: PropTypes.string,
  };
  static defaultProps = {
    type: '',
    automationId: '',
    isClearable: true,
    isDisabled: false,
    style: {},
    styles: null,
    placeHolder: null,
    isAutoComplete: false,
    singleSelect: false,
    isLoading: false,
    onLoad: () => {},
  };

  constructor() {
    super();
    this.state = {
      inputValue: '',
    };
  }

  onLoadDebounced = (str, callBack) => {
    const { onLoad } = this.props;
    return debouncedLoader(str, onLoad, callBack);
  };

  menuStyles = {
    menu: (provided) => ({
      ...provided,
      // width: '200px',
      width: '430px',
      'max-width': '430px',
    }),
    option: (provided) => ({
      ...provided,
      width: '430px',
      'max-width': '430px',
    }),
  };

  render() {
    const {
      isLoading,
      isAutoComplete,
      singleSelect,
      styles,
      isClearable,
      placeHolder,
      type,
      isDisabled,
      options,
      value,
      style,
      handleChange,
      automationId,
    } = this.props;
    const { inputValue } = this.state;

    const segmentSelectReporting = (selectedOptions) => {
      segmentEvent({
        type: 'select',
        target: automationId || `${type || ''}FieldFilter`,
        data: selectedOptions
          ? {
              id: Array.isArray(selectedOptions)
                ? selectedOptions.map((option) => option.value)
                : [selectedOptions.value],
            }
          : null,
      });
    };
    const segmentSearchReporting = (search) => {
      segmentEvent({
        type: 'search',
        target: automationId || `${type || ''}FieldFilter`,
        data: {
          searchValue: search,
        },
      });
    };

    return (
      <div style={style} automation-id={automationId}>
        {isAutoComplete ? (
          <SelectAsync
            styles={styles || this.menuStyles}
            value={value}
            cacheOptions
            inputValue={inputValue}
            isLoading={isLoading}
            closeMenuOnSelect={false}
            onInputChange={(str, { action }) => {
              if (action === 'input-change') {
                this.setState({ inputValue: str });
                segmentSearchReporting(str);
              }
            }}
            loadOptions={this.onLoadDebounced}
            isMulti={!singleSelect}
            isClearable
            isDisabled={isDisabled}
            onChange={(selectedOptions) => {
              this.setState({ inputValue: '' });
              segmentSelectReporting(selectedOptions);
              handleChange(type, selectedOptions);
            }}
            placeholder={placeHolder || 'Select...'}
            components={{ MenuList }}
            filterOption={createFilter({ ignoreAccents: false })}
          />
        ) : (
          <Select
            styles={styles || this.menuStyles}
            value={value}
            options={options}
            isMulti={!singleSelect}
            isSearchable
            isClearable={isClearable}
            isDisabled={isDisabled}
            onChange={(selectedOptions) => {
              segmentSelectReporting(selectedOptions);
              handleChange(type, selectedOptions);
            }}
            placeholder={placeHolder || 'Select...'}
            components={{ MenuList }}
            filterOption={createFilter({ ignoreAccents: false })}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  }
}

export default FieldFilter;
