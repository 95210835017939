import { AwsCommonFields } from 'shared/constants/awsConstants';
import { AzureCommonFields } from 'shared/constants/azureConstants';

export const FILTER_PARAMS_KEYS = {
  ALERTED: 'alerted',
  SEARCH: 'search',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  EXCLUSIVE_START_KEY: 'exclusiveStartKey',
  LIMIT: 'limit',
};

export const ALERT_RULE_ERROR_MESSAGES = {
  EMPTY_RULE_NAME: 'Rule name is mandatory',
  RULE_NAME_EXISTS: 'Rule name exists',
  INVALID_RULE_NAME: 'Rule name is invalid',
  NO_MIN_IMPACT: 'Select at least one minimum impact change condition',
  NEGATIVE_MIN_IMPACT: `Min impact cost should be greater than 50 and percentage can't be negative`,
  INVALID_MIN_IMPACT: `At least one Min impact value is in valid`,
  NO_DIRECTION: 'Direction is mandatory',
  INVALID_SERVICE_FILTER: 'Filter service is invalid',
  INVALID_LINKED_ACCOUNT_FILTER: 'Filter linked account is invalid',
  INVALID_DIVISION_FILTER: 'Filter division is invalid',
  INVALID_RECIPIENTS: 'One or more recipients emails are not valid',
  RECIPIENTS_MANDATORY: 'Select at least one recipient',
  INVALID_DIRECTION: 'Direction is invalid',
};

export const DIRECTION_VALUES = {
  SPIKE: 'UP',
  DROP: 'DOWN',
};

export const directionValueToLabel = new Map([
  [DIRECTION_VALUES.SPIKE, 'spike'],
  [DIRECTION_VALUES.DROP, 'drop'],
]);

export const cubeletDimensionNameToCommonField = {
  // AWS
  operation_title: AwsCommonFields.OPERATION,
  family_type_name: AwsCommonFields.FAMILY_TYPE,
  instance_type: AwsCommonFields.INSTANCE_TYPE,
  resource_group: AwsCommonFields.RESOURCE_GROUP,
  usage_type: AwsCommonFields.USAGE_TYPE,
  instance_family_type: 'NA', // Not supported by CUE
  os: 'NA',
  item_description: 'NA',
  charge_type: 'NA',
  cost_type_name: 'NA',
  db_type: 'NA',
  db_name: 'NA',
  resource_name: 'NA',
  // AZURE
  meter_name: AzureCommonFields.METER_MAME,
  meter_category: AzureCommonFields.METER_CATEGORY,
  sub_meter_category: AzureCommonFields.SUB_METER_CATEGORY,
  meter_title: 'NA',
  zone_name: 'NA',
  // GCP
  resource_type: 'NA',
  family_type_service: 'NA',
  instance_type_model: 'NA',
  instance_type_size: 'NA',
  project_name: 'NA',
};
