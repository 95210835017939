import React, { PureComponent } from 'react';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import MaxIopsChart from 'recommendations/shared/components/MaxIopsChart';
import MaxThroughputChart from 'recommendations/shared/components/MaxThroughputChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createMaxIopsData = (rec) => {
    const {
      maxIopsData,
      ebsType,
      newEbsType,
      iops: currentIops,
      maxIops,
      [`${newEbsType}Iops`]: recommendedIops,
    } = rec;
    const maxIopsDataEntries = Object.entries(maxIopsData).sort((a, b) => b[0].localeCompare(a[0]));
    maxIopsDataEntries.reverse();
    const iopsData = maxIopsDataEntries.map(([key, value]) => ({
      date: createDateDisplayStr(key),
      iops: parseFloat(value),
    }));

    return {
      iopsData,
      ebsType,
      newEbsType,
      iops: currentIops,
      maxIops,
      [`${newEbsType}Iops`]: recommendedIops,
    };
  };

  createMaxThroughputData = (rec) => {
    const {
      maxThroughputData,
      ebsType,
      newEbsType,
      throughput,
      maxThroughput,
      [`${newEbsType}Throughput`]: recommendedThroughput,
    } = rec;
    const maxThroughputDataEntries = Object.entries(maxThroughputData).sort((a, b) => b[0].localeCompare(a[0]));
    maxThroughputDataEntries.reverse();
    const throughputData = maxThroughputDataEntries.map(([key, value]) => ({
      date: createDateDisplayStr(key),
      throughput: parseFloat(value),
    }));

    return {
      throughputData,
      ebsType,
      newEbsType,
      throughput,
      maxThroughput,
      [`${newEbsType}Throughput`]: recommendedThroughput,
    };
  };

  render() {
    const { rec } = this.props;
    const { update } = this.state;
    const maxIopsData = this.createMaxIopsData(rec);
    const maxThroughputData = this.createMaxThroughputData(rec);
    return (
      <div>
        {update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">IOPS PERFORMANCE</p>
          <br />
          <MaxIopsChart data={maxIopsData} />
        </div>
        <hr />
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">THROUGHPUT PERFORMANCE</p>
          <br />
          <MaxThroughputChart data={maxThroughputData} />
        </div>
      </div>
    );
  }
}
