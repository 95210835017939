/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import AzureDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azureDbIdleCostRecommendationBuilder';
import { AzureSqlIdleRecommendation } from '../recommTypes';

export default class AzureSqlDbIdleCostRecommendationBuilder extends AzureDbIdleCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureSqlIdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_SQL_DB_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} SQL DB Idle`;
  }
}
