import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => {
  const { resourceGroup, server, dbName, dbAccount, subscriptionId, linkedAccountId } = rec;

  return (
    <ActionContainer>
      <ActionHeader />
      <ul style={{ 'list-style-type': 'none' }}>
        <li>Using CLI:</li>
        <CodeBlock
          variables={{
            server: server || dbAccount,
            subscriptionId: subscriptionId || linkedAccountId,
            dbName,
            resourceGroup,
          }}
          /* eslint-disable-next-line max-len */
          text="DELETE https://management.azure.com/subscriptions/{{subscriptionId}}/resourceGroups/{{resourceGroup}}/providers/Microsoft.DBforMariaDB/servers/{{server}}/databases/{{dbName}}"
        />
        <br />
      </ul>
    </ActionContainer>
  );
};

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
