import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from 'recommendations/shared/components/DescriptionWrapper';

const Description = ({ rec }) => {
  const description = `We recommend you to change this EBS type ${rec.subType === 'change_size' ? 'and size ' : ''}${
    rec?.attentionComment ? 'and IOPS ' : ''
  }to reduce costs and to suit your performance needs.`;

  return <DescriptionWrapper>{description}</DescriptionWrapper>;
};

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
