import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import {
  AZURE_PROPERTIES,
  AZURE_RESERVED_INSTANCE_PROPERTIES,
  LOAD_BALANCER_PROPERTIES,
} from '../../recommendationPropertyUtils';

const AZURE_COSMOS_DB_RIGHT_SIZING_PROPERTIES = {
  DB_ACCOUNT_NAME: {
    label: 'DB Account Name',
    getterFunction: (recommendation) => recommendation?.recData?.db_account_name,
  },
  NUM_OF_DBS: {
    label: 'Num Of DBs',
    getterFunction: (recommendation) => recommendation?.recData?.num_of_databases,
  },
  REJECT_RESOURCE_ID: {
    label: 'Reject Resource Id',
    getterFunction: (recommendation) => recommendation?.recData?.rejected_reason_id,
  },
  RECOMMENDED_THROUGHPUT: {
    label: 'Recommended Throughput',
    getterFunction: (recommendation) => recommendation?.recData?.recommended_throughput,
  },
  USAGE_THROUGHPUT: {
    label: 'Usage Throughput',
    getterFunction: (recommendation) => recommendation?.recData?.usage_throughput,
  },
};
const AzureCosmosDbRightSizing = ({ recommendation }) => {
  const description = 'Cosmos Right Sizing';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      dynamicProps={{
        ...AZURE_RESERVED_INSTANCE_PROPERTIES,
        ...LOAD_BALANCER_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...AZURE_COSMOS_DB_RIGHT_SIZING_PROPERTIES,
      }}
    />
  );
};

AzureCosmosDbRightSizing.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureCosmosDbRightSizing;
