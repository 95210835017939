export const ROLE_TYPES = {
  USER: 'userRole',
  DEFAULT_ADMIN: 'defaultAdminRole',
  CUSTOMER: 'customerRole',
};

export const ROLES_COLUMN_EXTENSIONS = [
  { columnName: 'roleName', width: '35%' },
  { columnName: 'details', width: 'auto' },
];

export const USERS_TABLE_COLUMNS = [
  { name: 'userName', title: 'User Name', getCellValue: (row) => row.userName },
  { name: 'permissions', title: 'Permissions' },
  // { name: 'creationDate', title: 'Member Since', getCellValue: (row) => formatDateToLabel(row.creationDate) },
  // TODO: enable once creationDate is valid
  { name: 'actions', title: ' ' },
];
export const USERS_COLUMN_EXTENSIONS = [
  { columnName: 'userName', width: '50%' },
  { columnName: 'permissions', width: '25%' },
  { columnName: 'actions', width: 'auto', align: 'right' },
];
