/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
export default class CauAlertsValidationHelper {
  constructor(context, usageStore, invoiceStore, updatePreSaveAlertsValidationStatus, alertId) {
    this.context = context;
    this.usageStore = usageStore;
    this.invoiceStore = invoiceStore;
    this.updatePreSaveAlertsValidationStatus = updatePreSaveAlertsValidationStatus;
    this.alertId = alertId;
  }

  emailIsValid = (email) => {
    const recipientsEmailsArr = email.replace(/ /g, '').split(',');
    const result = email && recipientsEmailsArr.every((mail) => !mail || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail));
    return !!result;
  };
  inputSelfValidation = (attr, value) => {
    const { validationStatus, alertStatus } = this.context.state;
    if (attr === 'recipientsEmails') {
      validationStatus[attr].isValid = this.emailIsValid(value);
    } else {
      validationStatus[attr].isValid = value && value.length > 0;
    }
    return validationStatus;
  };
  isValidationStatusValid = (alertType, validationStatus) => {
    const { isValid, ...rest } = validationStatus;
    const statusValues = Object.values(rest);
    const result = statusValues.every((value) => value.isValid);
    this.updatePreSaveAlertsValidationStatus(alertType, result);
    return result;
  };
  validateAlertBeforeAdd = () => this.context.validateAlertBeforeAdd();
}
