/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <h5 className="bold-text card__title">Steps To Execute</h5>
    <ul>
      <li>Using the AWS CLI:</li>
      <ol>
        <li>
          {'Find available Reserved Instances using the describe-reserved-instances-offerings command:'}
          <CodeBlock
            text={
              'aws ec2 describe-reserved-instances-offerings --region {{region}} --instance-type {{instanceType}} --offering-class convertible --product-description {{os}} --instance-tenancy default --filters Name=scope,Values=Region'
            }
            variables={{
              region: rec.region,
              instanceType: `${rec.model}.${rec.size}`,
              os: rec.os,
            }}
          />
          <br />
        </li>
        <li>
          {`Use the purchase-reserved-instances-offering command to buy your Reserved Instance.
You must specify the Reserved Instance offering ID you obtained the previous step and you must specify
the number of instances for the reservation:`}
          <CodeBlock
            text={
              'aws ec2 purchase-reserved-instances-offering --reserved-instances-offering-id {{RESERVED_INSTANCE_OFFERING_ID}} --instance-count {{numOfRecommRIs}}'
            }
            variables={{
              RESERVED_INSTANCE_OFFERING_ID: 'RESERVED_INSTANCE_OFFERING_ID',
              numOfRecommRIs: rec.numOfRecommRIs,
            }}
          />
          <br />
        </li>
        <li>
          {'Use the describe-reserved-instances command to get the status of your Reserved Instance:'}
          <CodeBlock text="aws ec2 describe-reserved-instances" />
          <br />
        </li>
      </ol>
      <li>
        Using the AWS Console:
        <NavLink
          target="_blank"
          href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ri-market-concepts-buying.html#ri-buying-convertible"
        >
          Buy RI using the AWS Console
        </NavLink>
      </li>
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
