import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { FlatObject } from 'shared/types/commonTypes.ts';
import { RawRdsInstanceCost, RdsInstanceCost } from '../types.ts';

const root = '/api/v1/usage/rds/instance-costs';

const fetchRdsInstanceCosts = (startDate: string, endDate: string) => (): Promise<RdsInstanceCost[]> =>
  API.get('billings', `${root}?startDate=${startDate}&endDate=${endDate}`).then((data: RawRdsInstanceCost[]) =>
    data.map(({ instanceType, ...rest }) => {
      const [family, size] = instanceType.split('.');
      return { ...rest, family, size };
    }),
  );

export const useRdsInstanceCosts = () => ({
  fetchRdsInstanceCosts: ({ startDate, endDate }: FlatObject<string>) =>
    useQuery(
      [apiConstants.QUERY_KEYS.RDS_INSTANCE_COSTS, startDate, endDate],
      fetchRdsInstanceCosts(startDate, endDate),
    ),
});
