import React from 'react';
import PropTypes from 'prop-types';
import { palette } from 'shared/constants/colorsConstants';
import styles from './Spinner.module.scss';

export const SPINNER_SIZES = {
  XXX_BIG: 90,
  XX_BIG: 60,
  X_BIG: 50,
  BIG: 35,
  MEDIUM: 30,
  SMALL: 15,
  X_SMALL: 13,
};

const Spinner = ({ color, size, position, top, transform }) => (
  <div
    style={{
      height: `${size}px`,
      width: `${size}px`,
      position,
      left: '50%',
      top: top || '50%',
      transform: transform || 'translate(-50%, -50%)',
      zIndex: 2,
    }}
  >
    <div className={styles.anodotLoader} style={{ borderTop: `3px solid ${color}`, width: size, height: size }} />
  </div>
);

Spinner.propTypes = {
  color: PropTypes.string,
  position: PropTypes.string,
  size: PropTypes.number,
  top: PropTypes.string,
  transform: PropTypes.string,
};

Spinner.defaultProps = {
  color: palette.blue[500],
  position: 'absolute',
  size: SPINNER_SIZES.X_BIG,
  transform: null,
  top: '50%',
};
export default Spinner;
