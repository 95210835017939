import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import PropTypes from 'prop-types';
import CpuChart from './CpuChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createCpuChartData = (rec) => {
    const { cpuUtilStatisticsUsage } = rec;
    const cpuUtilDataEntries = Object.entries(cpuUtilStatisticsUsage).sort((a, b) => b[0].localeCompare(a[0]));
    cpuUtilDataEntries.reverse();
    const cpuUtilData = cpuUtilDataEntries.map(([key, value]) => ({
      date: createDateDisplayStr(key),
      cpu: parseFloat(value),
    }));

    return {
      cpuUtilData,
    };
  };

  render() {
    const { rec } = this.props;
    const cpuData = this.createCpuChartData(rec);
    return (
      <div>
        {this.state.update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">CPU Performance</p>
          <br />
          <CpuChart data={cpuData} />
        </div>
      </div>
    );
  }
}
