/* eslint-disable react/no-unused-state,react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Routes } from 'shared/constants/routes';
import { dateToStr } from 'shared/utils/dateUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const SpHeatMapChart = (props) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const { selectedHourlyCost, maxValue, series } = props;
  const isPageTypeSpAnalyzer = String(window.location.href).includes(Routes.SAVINGS_PLANS_ANALYZER);
  const options = {
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        // shadeIntensity: 0.9,
        enableShades: false,
        colorScale: {
          ranges: [
            {
              from: -Infinity,
              to: Number(selectedHourlyCost) - 0.01,
              name: `Lower than ${getCurrencyNumber(selectedHourlyCost)}`,
              color: '#e4f3d4',
            },
            {
              from: Number(selectedHourlyCost) - 0.01,
              to: Number(selectedHourlyCost) + 0.01,
              name: `Recommended hourly cost: ${getCurrencyNumber(selectedHourlyCost)}`,
              color: '#75e948',
            },
            {
              from: Number(selectedHourlyCost) + 0.01,
              to: Infinity,
              name: `Higher than ${getCurrencyNumber(selectedHourlyCost)}`,
              color: '#468c25',
            },
          ],
        },
      },
    },
    title: {
      text: '',
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 6,
    },
    responsive: [
      {
        breakpoint: Infinity,
        options: {
          chart: {
            width: '1400',
          },
        },
      },
      {
        breakpoint: 2730,
        options: {
          chart: {
            width: '1400',
          },
        },
      },
      {
        breakpoint: 1730,
        options: {
          chart: {
            width: '1200',
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: '1000',
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: '800',
          },
        },
      },
      {
        breakpoint: 1230,
        options: {
          chart: {
            width: '700',
          },
        },
      },
      {
        breakpoint: 1110,
        options: {
          chart: {
            width: '500',
          },
        },
      },
    ],
  };

  const spAnalyzerOptions = {
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return dateToStr(new Date(timestamp), 'yyyy-mm-dd');
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        // autoSelected: 'zoom',
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        // shadeIntensity: 0.9,
        enableShades: false,
        colorScale: {
          ranges: [
            {
              from: -Infinity,
              to: Number(selectedHourlyCost) - 0.01,
              name: `Lower than ${getCurrencyNumber(selectedHourlyCost)}`,
              color: '#e4f3d4',
            },
            {
              from: Number(selectedHourlyCost) - 0.01,
              to: Number(selectedHourlyCost) + 0.01,
              name: `Hourly cost: ${getCurrencyNumber(selectedHourlyCost)}`,
              color: '#75e948',
            },
            {
              from: Number(selectedHourlyCost) + 0.01,
              to: Infinity,
              name: `Higher than ${getCurrencyNumber(selectedHourlyCost)}`,
              color: '#468c25',
            },
          ],
        },
      },
    },
    title: {
      text: '',
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 6,
    },
    responsive: [
      {
        breakpoint: Infinity,
        options: {
          chart: {
            width: '700',
          },
        },
      },
      {
        breakpoint: 2730,
        options: {
          chart: {
            width: '700',
          },
        },
      },
      {
        breakpoint: 1730,
        options: {
          chart: {
            width: '600',
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: '500',
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: '400',
          },
        },
      },
      {
        breakpoint: 1230,
        options: {
          chart: {
            width: '350',
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: '650',
          },
        },
      },
      {
        breakpoint: 1110,
        options: {
          chart: {
            width: '500',
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      {/* <Collapse open={props.series.length && options.length}
      openIcon={<MinusIcon />} closeIcon={<PlusIcon />} title="Cost Per Hour">
        <Chart options={options} series={props.series} type="heatmap" height="500" />
      </Collapse> */}

      <Chart options={isPageTypeSpAnalyzer ? spAnalyzerOptions : options} series={series} type="heatmap" height="500" />
    </div>
  );
};

SpHeatMapChart.propTypes = {
  series: PropTypes.object.isRequired,
  selectedHourlyCost: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
};

export default SpHeatMapChart;
