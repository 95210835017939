import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class DeploymentDataState extends BaseCostUsageDataState {
  getNewDataStateType = () => {
    const newDataState = CostUsageStates.DEPLOYMENT;
    return newDataState;
  };

  getDataStateType = () => CostUsageStates.DEPLOYMENT;
}
