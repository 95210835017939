import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';

const DeleteBillingRuleModal = ({ isOpen, onClose, onDelete, title, content }) => (
  <CustomModal
    onClose={onClose}
    onCloseClick={onClose}
    open={isOpen}
    onSave={onDelete}
    automationId="delete-billing-rule-modal"
    saveTitle="Delete"
    saveWarning
    title={title}
  >
    <div>{content}</div>
  </CustomModal>
);

DeleteBillingRuleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default DeleteBillingRuleModal;
