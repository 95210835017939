import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import ResellerPrivatePricing from 'shared/components/resellerPrivatePricing/ResellerPrivatePricing';

const AzureBillingRules = ({ invoiceStore, usersStore }) => (
  <Container>
    <PageHeader title={PageNames.AZURE_BILLIBG_RULES} />
    <Card>
      <CardBody>
        <ResellerPrivatePricing usersStore={usersStore} invoiceStore={invoiceStore} />
      </CardBody>
    </Card>
  </Container>
);

AzureBillingRules.propTypes = {
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};

export default AzureBillingRules;
