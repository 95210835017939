import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import LinkedAccounts from 'users/containers/LinkedAccounts';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { AWS_ONBOARDING_FIELDS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import styles from './LinkedAccountsPage.module.scss';

const LinkedAccountsPage = ({ usersStore }) => {
  const { getValues } = useFormContext();
  const { fetchOnboardingLinkedAccounts } = useOnboarding();
  const { data: linkedAccounts, isLoading } = fetchOnboardingLinkedAccounts(
    getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID),
  );
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <TableHeaderProvider>
        <LinkedAccounts onboardingMode usersStore={usersStore} linkedAccounts={linkedAccounts || []} />
      </TableHeaderProvider>
    </div>
  );
};

LinkedAccountsPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default LinkedAccountsPage;
