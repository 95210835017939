import React, { useContext, useEffect, useState } from 'react';
import Select from 'shared/components/Select';
import PropTypes from 'prop-types';
import { ReportContext } from 'shared/components/reportModal/ReportModal';
import MultiSelect, { styles as fieldFilterStyles } from 'shared/components/andtComponents/MultiSelect';
import Input from 'shared/components/andtComponents/Input';
import FieldFilter from 'shared/components/FieldFilter';
import { isIncludesAll, validateNumberInput, formatValuesForSelect } from 'shared/components/reportModal/reportsUtil';
import {
  ALL_OPTIONS,
  GROUP_BY_OPTIONS,
  RECOMMENDATIONS_STATUS_OPTIONS,
  REPORT_TYPE_OPTIONS,
} from 'recommendations/containers/Reports/components/recommendationReportsConstants';
import { PeriodOptions, ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import styles from './ReportModal.module.scss';

const RecommendationsReportContent = ({ recDbCustomTagsFetcher }) => {
  const { reportData, setReportData, data, errors, setErrors } = useContext(ReportContext);
  const [reportGroupBy, setReportGroupBy] = useState([]);
  const [recsStatus, setRecsStatus] = useState([]);
  const [linkedAccountIds, setLinkedAccountIds] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [services, setServices] = useState([]);
  const [recsTypes, setRecsTypes] = useState([]);

  useEffect(() => {
    const initGroupBy = data.reportGroupBy || [GROUP_BY_OPTIONS[0].value];
    const initRecsStatus = data.recsStatus || ALL_OPTIONS.value;
    const initLinkedAccountIds = data.linkedAccountIds || ALL_OPTIONS.value;
    const initDivisions = data.divisions || ALL_OPTIONS.value;
    const initServices = data.services || ALL_OPTIONS.value;
    const initRecsTypes = data.recsTypes || ALL_OPTIONS.value;
    const initPeriod = data.period || ReportPeriodTime.THIRTY_DAYS;
    setReportData((prev) => ({
      ...prev,
      reportType: data.reportType || REPORT_TYPE_OPTIONS[0].value,
      reportGroupBy: initGroupBy,
      divisions: initDivisions,
      linkedAccountIds: initLinkedAccountIds,
      services: initServices,
      tags: data.tags || [],
      recommendationAgeDays: data.recommendationAgeDays || 1,
      recsStatus: initRecsStatus,
      recsTypes: initRecsTypes,
      period: initPeriod,
    }));

    setReportGroupBy(formatValuesForSelect(initGroupBy, GROUP_BY_OPTIONS));
    setRecsStatus(formatValuesForSelect(initRecsStatus, RECOMMENDATIONS_STATUS_OPTIONS));
    setLinkedAccountIds(formatValuesForSelect(initLinkedAccountIds, data.linkedAccountsOptions));
    setDivisions(formatValuesForSelect(initDivisions, data.divisionsOptions));
    setServices(formatValuesForSelect(initServices, data.servicesOptions));
    setRecsTypes(formatValuesForSelect(initRecsTypes, data.recsTypesOptions));
  }, [JSON.stringify(data)]);

  const onGroupByChange = (values) => {
    let error = null;
    if (!values || values.length === 0) {
      error = 'Report must have at least one Group By';
    }
    setReportData((prev) => ({
      ...prev,
      reportGroupBy: values ? values.map(({ value }) => value) : values,
    }));
    setErrors((prev) => ({
      ...prev,
      reportGroupBy: error,
    }));
    setReportGroupBy(values);
  };

  const onChangeSelectWithAll = (values, field, displayName, setter) => {
    let selected = values;
    let error = null;
    if (!values || values.length === 0) {
      error = `Report must have at least one ${displayName}`;
    }
    setReportData((prev) => {
      if (isIncludesAll(prev[field])) {
        const valuesNoAll = values ? values.filter(({ value }) => value.toLowerCase() !== 'all') : [];
        selected = valuesNoAll;
      } else if (isIncludesAll(values)) {
        selected = [ALL_OPTIONS];
        return {
          ...prev,
          [field]: ALL_OPTIONS.value,
        };
      }
      return {
        ...prev,
        [field]: selected ? selected.map(({ value }) => value) : selected,
      };
    });
    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
    setter(selected);
  };
  return (
    <>
      {/* Report Type */}
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Report Type</div>
        <Select
          className={styles.select}
          value={`${reportData.reportType}`}
          options={REPORT_TYPE_OPTIONS}
          onChange={(event) => {
            setReportData((prev) => ({ ...prev, reportType: event.target.value }));
          }}
        />
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Group By *</div>
        <MultiSelect options={GROUP_BY_OPTIONS} value={reportGroupBy} onChange={onGroupByChange} />
        {errors.reportGroupBy ? <div className={styles.errorLabel}>{errors.reportGroupBy}</div> : null}
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Cost Centers *</div>
        <MultiSelect
          options={data.divisionsOptions}
          value={divisions}
          onChange={(values) => {
            onChangeSelectWithAll(values, 'divisions', 'Cost Center', setDivisions);
          }}
        />
        {errors.divisions ? <div className={styles.errorLabel}>{errors.divisions}</div> : null}
      </div>
      {/* Linked Accounts */}
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Linked Accounts *</div>
        <MultiSelect
          options={data.linkedAccountsOptions}
          value={linkedAccountIds}
          onChange={(values) => {
            onChangeSelectWithAll(values, 'linkedAccountIds', 'Linked Accounts', setLinkedAccountIds);
          }}
        />
        {errors.linkedAccountIds ? <div className={styles.errorLabel}>{errors.linkedAccountIds}</div> : null}
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Services *</div>
        <MultiSelect
          options={data.servicesOptions}
          value={services}
          onChange={(values) => {
            onChangeSelectWithAll(values, 'services', 'Services', setServices);
          }}
        />
        {errors.services ? <div className={styles.errorLabel}>{errors.services}</div> : null}
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Tags</div>
        <FieldFilter
          type="customtags"
          value={reportData.tags ? reportData.tags.map((value) => ({ value, label: value })) : []}
          options={recDbCustomTagsFetcher.values}
          styles={fieldFilterStyles}
          handleChange={(type, selectedOptions) => {
            setReportData((prev) => ({
              ...prev,
              tags: selectedOptions ? selectedOptions.map(({ value }) => value) : selectedOptions,
            }));
          }}
          onLoad={recDbCustomTagsFetcher.onLoad}
          isLoading={recDbCustomTagsFetcher.isLoading}
          isAutoComplete
          placeHolder="Type to search"
        />
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Recommendation Age (in days) *</div>
        <Input
          type="number"
          className={styles.input}
          name="recommendationAge"
          id="recommendationAge"
          onChange={(event) => {
            const { value } = event.target;
            setReportData((prev) => ({
              ...prev,
              recommendationAgeDays: validateNumberInput(value, 1, 999),
            }));
            setErrors((prev) => ({
              ...prev,
              recommendationAgeDays: value > 0 ? null : 'Age must be greater then 0',
            }));
          }}
          value={reportData.recommendationAgeDays}
        />
        {errors.recommendationAgeDays ? <div className={styles.errorLabel}>{errors.recommendationAgeDays}</div> : null}
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Recommendations Status *</div>
        <MultiSelect
          options={RECOMMENDATIONS_STATUS_OPTIONS}
          value={recsStatus}
          onChange={(values) => {
            onChangeSelectWithAll(values, 'recsStatus', 'Recommendations Status', setRecsStatus);
          }}
        />
        {errors.recsStatus ? <div className={styles.errorLabel}>{errors.recsStatus}</div> : null}
      </div>
      <div className={styles.field}>
        <div className={styles.fieldTitle}>Recommendation Types *</div>
        <MultiSelect
          options={data.recsTypesOptions}
          value={recsTypes}
          onChange={(values) => {
            onChangeSelectWithAll(values, 'recsTypes', 'Recommendation Type', setRecsTypes);
          }}
        />
        {errors.recsTypes ? <div className={styles.errorLabel}>{errors.recsTypes}</div> : null}
      </div>

      <div className={`${styles.field} ${styles.halfField}`}>
        <div className={styles.fieldTitle}>
          Report period
          <Tooltip title="Report period is relevant for historical recommendation only" arrow className="toolTip">
            <span className={styles.infoIcon}>
              <InfoIcon />
            </span>
          </Tooltip>
        </div>
        <Select
          className={styles.select}
          value={`${reportData.period}`}
          options={PeriodOptions}
          onChange={(event) => {
            setReportData((prev) => ({ ...prev, period: event.target.value }));
          }}
        />
      </div>
    </>
  );
};

RecommendationsReportContent.propTypes = {
  recDbCustomTagsFetcher: PropTypes.object.isRequired,
};

export default withInvoiceFiltersContextConsumer(RecommendationsReportContent);
