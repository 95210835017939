import React from 'react';
import PropTypes from 'prop-types';
import {
  HierarchicalEntityCategory,
  OrganizationEntityCategory,
  Action,
  CategoryAction,
} from '@anodot-cost/rbac-client';
import { usePermissions } from 'app/contexts/PermissionsContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import checkFeatureFlag from '../utils/featureFlagUtil';

const ReadOnlyDisplayWrapper = ({ isHide, children, category, action, legacyUserTypeList, block }) => {
  const { isHasPermission } = usePermissions();
  const disabledStyle = { pointerEvents: 'none', opacity: '0.4' };
  const hideStyle = { display: 'none' };
  const { usersStore } = useRootStore();
  const showDebugTooltip = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.PERMISSION_TOOLTIP_DEBUG);
  const getIsPermissionBlocked = () => {
    if (block) {
      return false;
    }
    return !isHasPermission(category, action, legacyUserTypeList);
  };
  const getStyle = () => {
    let style = {};
    if (getIsPermissionBlocked()) {
      style = isHide ? hideStyle : disabledStyle;
    }
    return style;
  };
  const renderBody = () => <div style={getStyle()}>{children}</div>;
  if (showDebugTooltip) {
    return (
      <Tooltip placement="left" title={`Permission: ${category}:${action}`}>
        {renderBody()}
      </Tooltip>
    );
  }
  return renderBody();
};

ReadOnlyDisplayWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  block: PropTypes.bool,
  legacyUserTypeList: PropTypes.array,
  isHide: PropTypes.bool,
  category: PropTypes.oneOf([
    ...Object.values(HierarchicalEntityCategory),
    ...Object.values(OrganizationEntityCategory),
  ]).isRequired,
  action: PropTypes.oneOf([...Object.values(Action), ...Object.values(CategoryAction)]).isRequired,
};
ReadOnlyDisplayWrapper.defaultProps = {
  isHide: true,
  block: false,
  legacyUserTypeList: undefined,
};

export default ReadOnlyDisplayWrapper;
