/* eslint-disable jsx-a11y/anchor-is-valid,,arrow-parens */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import If from 'shared/components/SimpleIf';
import Spinner from 'shared/components/andtComponents/Spinner';
import { DataTable } from 'shared/components/DataTable';
import {
  ContentPageTypes,
  mapRecpageTypeToDefaultDetailsDiaply,
} from 'recommendations/constants/recommendationsConstants';
import ContentDetail from 'recommendations/shared/components/ContentDetail';
import ContentDetails from 'recommendations/containers/DailyRecommendations/components/ContentDetails';
import { mapRecTypetoContentCreator } from 'recommendations/constants/recommContentCreators';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class DetailsContent extends Component {
  static propTypes = {
    rec: PropTypes.object.isRequired,
    contentPageType: PropTypes.object.isRequired,
    contentDetailsDisplay: PropTypes.number,
    getCurrencyNumber: PropTypes.func.isRequired,
    currencyCode: PropTypes.string.isRequired,
  };

  static defaultProps = {
    contentDetailsDisplay: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      isShowTable: false,
      EbsUpgradeRecResourcesData: [],
      isLoadingData: false,
    };
  }

  buildTableRows = () => {
    const rows = [];
    if (this.state.EbsUpgradeRecResourcesData) {
      this.state.EbsUpgradeRecResourcesData.forEach((recRow) => {
        const newRow = [
          this.buildResourceIdAwsConsoleLink(this.props.rec.region, recRow.resource_id),
          this.buildResourceNameAwsConsoleLink(this.props.rec.region, recRow.resource_name),
        ];
        rows.push(newRow);
      });
    }
    return rows;
  };

  buildResourceNameAwsConsoleLink = (region, resourceName) => (
    <div className="rec__vu-resources-links">
      <a
        href={`https://console.aws.amazon.com/ec2/v2/home?region=${region}#Instances:tag:Name=${resourceName};sort=tag:Name`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {resourceName}
      </a>
    </div>
  );

  buildResourceIdAwsConsoleLink = (region, resourceId) => (
    <div className="rec__vu-resources-links">
      <a
        href={`https://console.aws.amazon.com/ec2/v2/home?region=${region}#Volumes:search=${resourceId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {resourceId}
      </a>
    </div>
  );

  // eslint-disable-next-line consistent-return
  handleShowDetails = async (e) => {
    e.preventDefault();
    this.toggleTable();
    const { rec } = this.props;
    const { isShowTable } = this.state;
    if (!isShowTable) {
      if (this.state.EbsUpgradeRecResourcesData.length) {
        return this.state.EbsUpgradeRecResourcesData;
      }
      const EbsUpgradeRecResourcesData = rec.resources;
      this.setState({ EbsUpgradeRecResourcesData, isLoadingData: false });
    }
  };

  toggleTable = () => {
    const { isShowTable } = this.state;
    this.setState({ isShowTable: !isShowTable });
  };

  createResourcesDetailsData = () => {
    const headings = [
      <div className="rec__vu-resources-links">Volume ID</div>,
      <div className="rec__vu-resources-links">Volume Name</div>,
    ];
    const rows = [];

    return { headings, rows };
  };

  renderContentDetails = (rec, contentPageType) => {
    const { getCurrencyNumber, currencyCode } = this.props;
    const contentDetailsArray = [];
    const RecTypeContentDetailsClass = mapRecTypetoContentCreator.get(rec.type);
    const recTypeContentDetails = new RecTypeContentDetailsClass(rec, contentPageType, getCurrencyNumber, currencyCode);
    const content = recTypeContentDetails.createContent(rec);
    if (content) {
      contentDetailsArray.push(...content);
    }
    if (!rec.separateResource) {
      contentDetailsArray.push(
        <ContentDetail
          display={
            <a href="#" onClick={(e) => this.handleShowDetails(e, rec)}>
              Volumes List
            </a>
          }
          value=""
        />,
      );
    }
    return [...contentDetailsArray];
  };

  render() {
    const { rec, contentPageType, contentDetailsDisplay } = this.props;
    const { isShowTable, isLoadingData } = this.state;
    if (!rec) {
      return <div>Loading</div>;
    }
    const tableData = this.createResourcesDetailsData();
    const tableDataRows = this.buildTableRows();
    return (
      <div style={{ 'line-height': '30px', 'font-size': '13px' }}>
        <table
          className={`project-summary__info ${
            contentPageType === ContentPageTypes.DAILY_RECS ? 'daily-rec__width' : ''
          }`}
        >
          <ContentDetails contentDisplay={mapRecpageTypeToDefaultDetailsDiaply.get(contentPageType)}>
            {this.renderContentDetails(rec, contentPageType, contentDetailsDisplay)}
          </ContentDetails>
        </table>
        <If cond={isShowTable}>
          {isLoadingData ? <Spinner /> : <DataTable headings={tableData.headings} rows={tableDataRows} />}
        </If>
      </div>
    );
  }
}

const ObserverDetailsContent = withUserSettingsConsumer(DetailsContent);
export default ObserverDetailsContent;
