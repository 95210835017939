import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { AZURE_PROPERTIES, DB_PROPERTIES, ENGINE_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const AzureSqlIdle = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `DELETE https://management.azure.com/subscriptions/${recommendation?.linkedAccountId}/resourceGroups/${recommendation?.recData?.resource_group}/providers/Microsoft.Sql/servers/${recommendation?.recData?.server}/databases/${recommendation?.recData?.db_name}`,
        },
      ],
    },
  ];
  const description = 'This DB instance was idle in the past 14 days. We recommend that you terminate it.';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...ENGINE_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
      }}
    />
  );
};

AzureSqlIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureSqlIdle;
