import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS } from './helperFunctions';

export async function fetchDataAccessPayerAccounts() {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/payer-accounts`);
}

export async function fetchDataAccessCostCenters() {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers`);
}

export async function fetchDataAccessResellerCustomers() {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/reseller-customers`);
}
