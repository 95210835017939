import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RadioButton from './RadioButton';
import styles from './RadioButtonList.module.scss';

const propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderDescription: PropTypes.func,
  direction: PropTypes.string,
  className: PropTypes.string,
  optionClassName: PropTypes.string,
};

const defaultProps = {
  direction: 'column',
  className: '',
  renderDescription: null,
  optionClassName: '',
};

const RadioButtonsList = ({ options, value, onChange, direction, className, optionClassName, renderDescription }) => (
  <div className={`${styles.radioButtonList} ${styles[direction]} ${className}`}>
    {options.map((opt) => (
      <div key={opt.key || opt.label} className={classnames(optionClassName, opt.optionClassName)}>
        <RadioButton
          key={opt.label}
          checked={opt.value === value}
          label={opt.label}
          onClick={() => onChange(opt.value)}
          isDisabled={opt.disabled}
          primary={opt.primary}
          className={opt.labelClassName}
          automationId={opt.automationId}
        />
        {renderDescription ? renderDescription(opt.value) : null}
      </div>
    ))}
  </div>
);

RadioButtonsList.propTypes = propTypes;
RadioButtonsList.defaultProps = defaultProps;

export default RadioButtonsList;
