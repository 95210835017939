import React, { memo } from 'react';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';

const LinkIconButton = memo((props) => {
  // eslint-disable-next-line react/prop-types
  const { children, automationId = '', ...restProps } = props;
  const elements = React.Children.toArray(children);
  const link = React.cloneElement(elements[0]);
  const icon = elements.length === 2 ? React.cloneElement(elements[1]) : null;
  const linkWithIcon = React.cloneElement(link, [], [icon]);

  const handleClick = () => {
    segmentEvent({
      target: automationId || 'LinkIconButton',
    });
  };

  return (
    <div style={restProps.containerStyle} className="linkWithIcon" onClick={handleClick}>
      {linkWithIcon}
    </div>
  );
});

export default LinkIconButton;
