export const mapPanelTypeToChartType = new Map([
  ['cue', 'costchart'],
  ['assets-table', 'assetstable'],
  ['k8s-assets-table', 'assetstable'],
  ['k8s', 'costchart'],
  ['k8s-usage', 'usagelinechart'],
  ['cue-table', 'cuetable'],
  ['k8s-table', 'k8stable'],
  ['k8s-pod-details-table', 'k8spoddetailstable'],
  ['cue-resource-table', 'cueresourcetable'],
  ['cue-pie-chart', 'piechart'],
  ['k8s-pie-chart', 'k8spiechart'],
  ['recomm-cloud-waste', 'two-axes-linechart'],
  ['recomm-summery-table', 'recomm-summery-table'],
  ['budget-chart', 'budget-chart'],
  ['heat-map', 'heat-map'],
  ['cloud-waste', 'cloud-waste'],
  ['saving-opportunities', 'saving-opportunities'],
]);
export const mapPanelTypeToRoute = new Map([
  ['cue', ''],
  ['k8s', ''],
  ['cue-table', ''],
  ['assets-table', ''],
  ['k8s-assets-table', ''],
  ['k8s-table', ''],
  ['k8s-pod-details-table', ''],
  ['cue-resource-table', ''],
  ['cue-pie-chart', ''],
  ['k8s-pie-chart', ''],
  ['recomm-summery-table', ''],
  ['budget-chart', ''],
]);
export const PREDEFINED_PANELS = 'kpis';
