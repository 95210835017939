import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const MarkRecAsDoneButton = ({ rec, recHandler, backToPage }) => (
  <Button onClick={() => recHandler(rec.key, rec.type)} color="primary" size="sm">
    <Link style={{ color: 'white' }} to={backToPage}>
      Mark as Done
    </Link>
  </Button>
);

MarkRecAsDoneButton.propTypes = {
  rec: PropTypes.object.isRequired,
  recHandler: PropTypes.func.isRequired,
  backToPage: PropTypes.string.isRequired,
};

export default MarkRecAsDoneButton;
