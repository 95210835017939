/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-parens */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { kIntFormmater } from 'shared/utils/strUtil';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';

const NetworkChart = ({ data }) => (
  <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
    <LineChart width={550} height={284} data={data.networkData} margin={{ top: 20, right: 20, bottom: 0, left: 20 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" tick={<CustomizedAxisTick fontSize={12} />} />
      <YAxis
        type="number"
        label={{
          value: 'Network I/O (MB)',
          offset: 0,
          angle: -90,
          position: 'left',
        }}
        tickFormatter={(value) => `${kIntFormmater(value)} MB`}
      />
      <Tooltip formatter={(value) => `${kIntFormmater(value)} MB`} />
      <Legend />
      <Line type="linear" dataKey="out" dot={false} stroke="#EEB67D" strokeWidth={2} name="Network out" />
      <Line type="linear" dataKey="in" dot={false} stroke="#00D15D" strokeWidth={2} name="Network in" />
    </LineChart>
  </ResponsiveContainer>
);

NetworkChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NetworkChart;
