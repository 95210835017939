/* eslint-disable react/prop-types, no-mixed-operators */
import React from 'react';

const RADIAN = Math.PI / 180;

const renderCustomizedPieLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, value },
  firstRowFormatter,
  secondRowFormatter,
) => {
  if (percent < 0.08) {
    return null;
  }
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontWeight={500}>
        {firstRowFormatter(value)}
      </text>
      <text x={x} y={y + 20} fill="white" textAnchor="middle" dominantBaseline="central" fontWeight={400}>
        {secondRowFormatter(percent * 100)}
      </text>
    </>
  );
};

export default renderCustomizedPieLabel;
