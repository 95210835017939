import React, { useContext, useEffect } from 'react';
import { useUsers } from 'shared/hooks/react-query/useUsers';

const PermissionsContext = React.createContext({
  permissions: [],
  isHasPermission: () => false,
});

export function PermissionsContextProvider({ children, usersStore }) {
  const { fetchPermissions } = useUsers();
  const { data: permissions } = fetchPermissions();
  useEffect(() => {
    if (permissions) {
      // save it to users store as a second place to be able to define is read only for new um from there
      usersStore.updatePermissions(permissions);
    }
  }, [permissions]);
  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        isHasPermission: usersStore.isHasPermission,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}

export const usePermissions = () => useContext(PermissionsContext);
