import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from 'recommendations/shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <il>
      <li>
        Using the AWS CLI:
        <CodeBlock
          text="aws ec2 terminate-instances --instance-ids {{resourceId}}"
          variables={{
            resourceId: rec.resourceId,
          }}
        />
      </li>
      <li style={{ marginTop: '10px' }}>
        Using the AWS Console:
        <NavLink target="_blank" href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html">
          https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html
        </NavLink>
      </li>
    </il>
    {Object.keys(rec?.attachedVolumesCosts)?.length > 0 ? (
      <>
        In addition, consider terminating these EBS/IP if they are not associated with other EC2 instances.
        <li>
          Using the AWS CLI:
          <ol>
            {Object.keys(rec.attachedVolumesCosts).map((volume) => (
              <li className="pb-2">
                <CodeBlock
                  text={`aws ec2 delete-volume --volume-id ${volume}`}
                  variables={{
                    resourceId: rec.resourceId,
                  }}
                />
              </li>
            ))}
          </ol>
        </li>
      </>
    ) : null}
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
