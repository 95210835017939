import React from 'react';

const Description = () => (
  <div style={{ 'line-height': '30px', 'font-size': '13px', whiteSpace: 'normal' }}>
    <p>
      RI purchase recommendations are based on your historical usage. These recommendations are calculated by analyzing
      all incurred usage eligible to be covered by an RI (e.g. On-Demand usage) and using our unique algorithms to
      simulate possible combinations of RI’s that would cover that usage.
    </p>
  </div>
);

Description.propTypes = {};

export default Description;
