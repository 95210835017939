/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Col, Collapse } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';

export default class SingleGroupByButton extends PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    size: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    selectedItem: PropTypes.string.isRequired,
    itemsValuesMap: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: props.selectedItem,
      collapse: false,
      secondaryCollapse: false,
    };
  }

  handleClick = (item, handler) => {
    handler(this.props.itemsValuesMap.get(item));
    this.setState({ selectedItem: item, collapse: false });
  };

  toggle = () => {
    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };
  toggleSecondary = () => {
    this.setState((prevState) => ({
      secondaryCollapse: !prevState.secondaryCollapse,
    }));
  };
  renderItems = (items, handler) =>
    items.map((item) => (
      <p className="collapse-item" onClick={() => this.handleClick(item, handler)}>
        {item}
      </p>
    ));

  render() {
    const { title, items, size, handler } = this.props;
    const { selectedItem, collapse } = this.state;
    return (
      <div className="topbar__profile">
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '15px' }}>
          <Col style={{ paddingLeft: '0px' }} xs="auto" md="auto" lg="auto" xl="auto">
            {size ? <h5>{title}</h5> : <h4>{title}</h4>}
          </Col>
          <Col style={{ padding: '3px 0px' }} xs="auto" md="auto" lg="auto" xl="auto">
            {size ? (
              <h5>
                <b>{` ${selectedItem}`}</b>
              </h5>
            ) : (
              <h4>
                <b>{` ${selectedItem}`}</b>
              </h4>
            )}
          </Col>
          <Col style={{ padding: '1px 0px' }} xs="auto" md="auto" lg="auto" xl="auto">
            <LinkIconButton className="topbar__avatar" onClick={this.toggle} style={{ cursor: 'pointer' }}>
              <p onClick={this.toggle} />
              <ChevronDown size={size ? 16 : 22} style={{ cursor: 'pointer' }} />
            </LinkIconButton>
          </Col>
        </div>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="menu-wrap">
          <div className="drop__menu">{this.renderItems(items, handler)}</div>
        </Collapse>
      </div>
    );
  }
}
