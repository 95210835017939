import React from 'react';
import { Collapse } from 'reactstrap';
import { AwsCommonFields } from '../constants/awsConstants';
import Button from './andtComponents/Button';
import Checkbox from './andtComponents/Checkbox';
import styles from './GroupBySecondLevelChecklist.module.scss';

const GroupBySecondLevelChecklist = ({ field, items, handler, apply, open, checked }) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <Collapse
      isOpen={open}
      className="menu-wrap-stretch cue"
      style={{
        overflowY: 'auto',
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
      }}
    >
      <div className="drop__menu-stretch">
        <div className="w-100">
          {items.map((item) => (
            <Checkbox
              key={item}
              className={styles.checkbox}
              primary
              isChecked={checked.includes(item)}
              onChange={() => handler(field, item)}
              text={item.replace(`${AwsCommonFields.WORKLOAD}:`, '')}
            />
          ))}
          <Button isTextButton onClick={() => apply(field)} disabled={!checked.length} text="Apply" />
        </div>
      </div>
    </Collapse>
  );
};

export default GroupBySecondLevelChecklist;
