/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-parens */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { kIntFormmater } from 'shared/utils/strUtil';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';

// eslint-disable-next-line consistent-return

const CpuChart = ({ data }) => (
  <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
    <LineChart width={550} height={284} data={data.cpuUtilData} margin={{ top: 20, right: 20, bottom: 0, left: 20 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" tick={<CustomizedAxisTick fontSize={12} />} />
      <YAxis
        type="number"
        domain={[0, (dataMax) => Math.ceil(dataMax)]}
        label={{
          value: 'CPU Utilization',
          offset: 0,
          angle: -90,
          position: 'left',
        }}
        tickFormatter={(value) => `${kIntFormmater(value, 2, false)}%`}
      />
      <Tooltip formatter={(value) => `${kIntFormmater(value, 2, false)}%`} />
      <Legend />
      <ReferenceLine
        y={data.maxCpuUtil}
        label={{
          position: 'top',
          value: `Max CPU Utilization Benchmark: ${data.maxCpuUtil}%`,
          fill: '#7d0756',
          fontSize: 14,
        }}
        stroke="#7d0756"
        alwaysShow
        strokeDasharray="6 6"
      />
      <Line type="linear" dataKey="cpu" dot={false} stroke="#1f5189" strokeWidth={2} name="CPU" />
    </LineChart>
  </ResponsiveContainer>
);

CpuChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CpuChart;
