import { useQuery, useMutation, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { useInvalidateFiltersDistinctValues } from 'invoices/hooks/react-query/useInvoiceFilters';

const root = '/api/v1/usage/business-mapping';
const fetchBusinessMappingViewpoints = () =>
  useQuery([apiConstants.QUERY_KEYS.BUSINESS_MAPPING_VIEWPOINTS], () => API.get('billings', `${root}/viewpoints`));

const fetchBusinessMappingViewpoint = (id) =>
  useQuery([apiConstants.QUERY_KEYS.BUSINESS_MAPPING_VIEWPOINT, id], () =>
    API.get('billings', `${root}/viewpoints/${id}`),
  );

const addBusinessMappingViewpoint = (queryClient, invalidatePlainFilters) =>
  useMutation({
    mutationFn: ({ viewpoint }) => API.post('billings', `${root}/viewpoints`, { body: viewpoint }),
    onSuccess: () => {
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_VIEWPOINTS);
      invalidatePlainFilters();
    },
  });

const cloneBusinessMappingViewpoint = (queryClient, invalidatePlainFilters) =>
  useMutation({
    mutationFn: ({ id }) => API.post('billings', `${root}/viewpoints/${id}/clone`),
    onSuccess: () => {
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_VIEWPOINTS);
      invalidatePlainFilters();
    },
  });

const updateBusinessMappingViewpoint = (queryClient, invalidatePlainFilters) =>
  useMutation({
    mutationFn: ({ viewpoint, id }) => API.put('billings', `${root}/viewpoints/${id}`, { body: viewpoint }),
    onSuccess: () => {
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_VIEWPOINTS);
      invalidatePlainFilters();
    },
  });

const deleteBusinessMappingViewpoint = (queryClient, invalidatePlainFilters) =>
  useMutation({
    mutationFn: ({ id }) => API.del('billings', `${root}/viewpoints/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_VIEWPOINTS);
      invalidatePlainFilters();
    },
  });

const fetchBusinessMappingGroups = (viewpointId) =>
  useQuery(
    [apiConstants.QUERY_KEYS.BUSINESS_MAPPING_GROUPS, viewpointId],
    () => API.get('billings', `${root}/viewpoints/${viewpointId}/mappings`),
    { retry: false },
  );

const addBusinessMappingGroup = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ viewpointId, group }) =>
      API.post('billings', `${root}/viewpoints/${viewpointId}/mappings`, { body: group }),
    onSuccess,
  });

const updateBusinessMappingGroup = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ viewpointId, groupId, group }) =>
      API.put('billings', `${root}/viewpoints/${viewpointId}/mappings/${groupId}`, {
        body: group,
      }),
    onSuccess,
  });

const addBusinessMappingGroupToViewpoint = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ viewpointId, groupId, group }) =>
      API.post('billings', `${root}/viewpoints/${viewpointId}/mappings/${groupId}`, {
        body: group,
      }),
    onSuccess,
  });

const updateBusinessMappingGroupRank = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ viewpointId, data }) =>
      API.put('billings', `${root}/viewpoints/${viewpointId}/mappings/rank`, {
        body: data,
      }),
    onSuccess,
  });

const deleteBusinessMappingGroup = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ viewpointId, groupId }) =>
      API.del('billings', `${root}/viewpoints/${viewpointId}/mappings/${groupId}`),
    onSuccess,
  });

const fetchGlobalBusinessMappingGroups = () =>
  useQuery([apiConstants.QUERY_KEYS.BUSINESS_MAPPING_GLOBAL_GROUPS], () =>
    API.get('billings', `${root}/global-mappings`),
  );

const addGlobalBusinessMappingGroup = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ group }) => API.post('billings', `${root}/global-mappings`, { body: group }),
    onSuccess,
  });

const updateGlobalBusinessMappingGroup = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ groupId, group }) => API.put('billings', `${root}/global-mappings/${groupId}`, { body: group }),
    onSuccess,
  });

const makeBusinessMappingGlobal = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ uuid }) => API.put('billings', `${root}/global-mappings/${uuid}/make-global`),
    onSuccess,
  });

const deleteGlobalBusinessMappingGroup = ({ onSuccess }) =>
  useMutation({
    mutationFn: ({ groupId }) => API.del('billings', `${root}/global-mappings/${groupId}`),
    onSuccess,
  });

const fetchBusinessMappingPreferences = () =>
  useQuery([apiConstants.QUERY_KEYS.BUSINESS_MAPPING_PREFERENCES], () => API.get('billings', `${root}/preferences`));

const updateHideChangeRankWarning = ({ onSuccess }) =>
  useMutation({
    mutationFn: () => API.post('billings', `${root}/preferences/hide-ranking-warning`),
    onSuccess,
  });

const fetchBusinessMappingPreview = (viewpoint, kubernetesStore, invoiceStore) =>
  useQuery(
    [apiConstants.QUERY_KEYS.BUSINESS_MAPPING_PREVIEW, viewpoint.uuid],
    viewpoint.k8s
      ? kubernetesStore.fetchDefaultDataByBusinessMapping(viewpoint.uuid)
      : invoiceStore.fetchDefaultDataByBusinessMapping(viewpoint.uuid),
    {
      enabled: viewpoint.groups.length > 0,
    },
  );

export const useBusinessMapping = () => {
  const queryClient = useQueryClient();
  const { invalidatePlainFilters } = useInvalidateFiltersDistinctValues();

  return {
    invalidateBusinessMappingGroups: () =>
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_GROUPS),
    invalidateBusinessMappingViewpoint: () =>
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_VIEWPOINT),
    invalidateBusinessMappingGlobalGroups: () =>
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_GLOBAL_GROUPS),
    invalidateBusinessMappingPreferences: () =>
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_PREFERENCES),
    invalidateBusinessMappingPreview: () =>
      queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BUSINESS_MAPPING_PREVIEW),
    fetchBusinessMappingViewpoints,
    fetchBusinessMappingViewpoint,
    addBusinessMappingViewpoint: () => addBusinessMappingViewpoint(queryClient, invalidatePlainFilters),
    cloneBusinessMappingViewpoint: () => cloneBusinessMappingViewpoint(queryClient, invalidatePlainFilters),
    updateBusinessMappingViewpoint: () => updateBusinessMappingViewpoint(queryClient, invalidatePlainFilters),
    deleteBusinessMappingViewpoint: () => deleteBusinessMappingViewpoint(queryClient, invalidatePlainFilters),
    fetchBusinessMappingGroups,
    addBusinessMappingGroup,
    updateBusinessMappingGroup,
    addBusinessMappingGroupToViewpoint,
    updateBusinessMappingGroupRank,
    deleteBusinessMappingGroup,
    fetchGlobalBusinessMappingGroups,
    addGlobalBusinessMappingGroup,
    updateGlobalBusinessMappingGroup,
    makeBusinessMappingGlobal,
    deleteGlobalBusinessMappingGroup,
    updateHideChangeRankWarning,
    fetchBusinessMappingPreferences,
    fetchBusinessMappingPreview,
  };
};
