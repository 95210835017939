import React from 'react';
import { useRootStore } from 'app/contexts/RootStoreContext';
import t from '../texts.ts';

interface MyEmailBtnProps {
  className?: string;
  onClick(meEmail: string, e: React.MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
}

const MyEmailBtn: React.FC<MyEmailBtnProps> = ({ className, disabled, onClick }) => {
  const { usersStore } = useRootStore();
  return (
    <button
      className={className}
      type="button"
      onClick={(e) => onClick(usersStore.currUserName, e)}
      disabled={disabled}
      automation-id="my-email-button"
      style={{
        color: '#2671FF',
        fontSize: '14px',
        background: 'none',
      }}
    >
      {t('MY_EMAIL')}
    </button>
  );
};

export default MyEmailBtn;
