/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { Ec2ScheduleRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class Ec2LowCpuUsageRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new Ec2ScheduleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_SCHEDULE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EC2 Schedule`;
  }

  build() {
    super.build();
    const {
      start_of_working_day: startOfWorkingDay,
      end_of_working_day: endOfWorkingDay,
      days_to_check: daysToCheck,
      running_hours: runningHours,
      after_working_hours: afterWorkingHours,
      environment,
    } = this._rawRecommendation;
    this._recommendation.environment = environment;
    this._recommendation.startOfWorkingDay = startOfWorkingDay;
    this._recommendation.endOfWorkingDay = endOfWorkingDay;
    this._recommendation.daysToCheck = parseInt(daysToCheck, 10);
    this._recommendation.runningHours = parseInt(runningHours, 10);
    this._recommendation.afterWorkingHours = parseInt(afterWorkingHours, 10);
  }
}
