/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';
// TODO update description
const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>This VM instance is idle:</p>
    <ul>
      <li>CPU utilization is less than 0.03 vCPUs for 97% of VM runtime.</li>
      <li>Received network traffic is less than 2000 bytes per second (B/s) for 95% of VM runtime.</li>
      <li>Sent network traffic is less than 1000 B/s for 95% of VM runtime.</li>
    </ul>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
