import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Filter } from 'react-feather';
import If from 'shared/components/SimpleIf';
import PeriodGranularityFilter from 'shared/components/PeriodGranularityFilter';
import DatePickerFilter from '../DatePickerFilter';

class FilterSidebar extends PureComponent {
  static propTypes = {
    renderDatePickerFilter: PropTypes.bool.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    handleDateSelection: PropTypes.func,
    isDateRangeError: PropTypes.bool.isRequired,
    isShowYearly: PropTypes.bool,
    isShowHourly: PropTypes.bool,
    isShowQuarterly: PropTypes.bool,
    containerStyle: PropTypes.object,
    renderGranPeriodButton: PropTypes.bool.isRequired,
    currPeriodGranLevel: PropTypes.string.isRequired,
    handleChangeGranLevel: PropTypes.func.isRequired,
    renderFilters: PropTypes.bool.isRequired,
    handleToggleFilters: PropTypes.func.isRequired,
    isFiltersOpen: PropTypes.bool,
    isFilters: PropTypes.bool,
    filtersCount: PropTypes.number,
  };
  static defaultProps = {
    containerStyle: {},
    isFiltersOpen: false,
    isFilters: false,
    isShowQuarterly: false,
    isShowYearly: false,
    isShowHourly: false,
    filtersCount: 0,
    handleDateSelection: () => {},
  };

  render() {
    const {
      startDate,
      endDate,
      currPeriodGranLevel,
      renderGranPeriodButton,
      renderDatePickerFilter,
      renderFilters,
      isShowQuarterly,
      isShowYearly,
      isShowHourly,
      containerStyle,
      isDateRangeError,
      handleChangeGranLevel,
      handleToggleFilters,
      isFiltersOpen,
      isFilters,
      filtersCount,
      handleDateChange,
      handleDateSelection,
    } = this.props;

    return (
      <>
        <div className="d-flex position-relative" style={{ alignItems: 'center', ...containerStyle }}>
          <If cond={renderGranPeriodButton}>
            <PeriodGranularityFilter
              isShowQuarterly={isShowQuarterly}
              isShowYearly={isShowYearly}
              isShowHourly={isShowHourly}
              currPeriodGranLevel={currPeriodGranLevel}
              handleChangeGranLevel={handleChangeGranLevel}
              isDateRangeError={isDateRangeError}
              className="filter-bar-inner-col-text cue"
              startDate={startDate}
              endDate={endDate}
            />
          </If>
          <If cond={renderDatePickerFilter}>
            <Col className="ps-3">
              <DatePickerFilter
                onDateChange={handleDateChange}
                handleDateSelection={handleDateSelection}
                startDate={startDate}
                endDate={endDate}
                currPeriodGranLevel={currPeriodGranLevel}
                isDateRangeError={isDateRangeError}
                isCue
              />
            </Col>
          </If>
          <If cond={renderFilters}>
            <div
              className={`cue-filters-button ${!isFiltersOpen ? '' : 'filter-open'} `}
              onClick={handleToggleFilters}
              automation-id="cue-filters-button"
            >
              <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: filtersCount ? '8px' : '15px' }} className="filters-icon">
                  <Filter size={16} />
                  <div className={`filter-applied-notification ${isFilters ? 'active' : null}`} />
                </div>
                <h4
                  style={{
                    fontSize: '0.85rem',
                    fontWeight: '200',
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}
                >
                  {filtersCount ? `(${filtersCount})` : null}
                </h4>
              </div>
            </div>
          </If>
        </div>
      </>
    );
  }
}

export default FilterSidebar;
