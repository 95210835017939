import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './tableHeader.module.scss';
import { useTableHeaderContext } from './TableHeaderContext';

const TableHeader = ({
  hideSearch,
  actionButton,
  tableName,
  includeDetails,
  totalRows,
  rightMenuClassName,
  titleComponent,
  filteredRows,
  csvData,
  isCreatable,
  infoTooltip,
  showFilters,
  titleInfo,
  fetchCsvData,
  expanded,
  onCreate,
  setExpanded,
  hideTitle,
  csvTooltip,
  isCreateLast,
  isInline,
  filters,
  showActiveFilter,
  disableCreateOption,
  cloudType,
  titleClassName,
  createButtonComponent,
  filtersPosition,
}) => {
  const {
    filtersOpen,
    setFiltersOpen,
    isExpanded: isExpandedC,
    setIsExpanded: setIsExpandedC,
    isActive,
    setIsActive,
    isExpired,
    setIsExpired,
    searchText,
    setIsCreateMode,
    setSearchText,
  } = useTableHeaderContext();
  const isExpanded = expanded === null ? isExpandedC : expanded;
  const setIsExpanded = setExpanded === null ? setIsExpandedC : setExpanded;
  const renderTitle = () => {
    if (titleComponent) {
      return titleComponent;
    }
    if (hideTitle) {
      return null;
    }
    if (filteredRows === null && totalRows === null) {
      return tableName;
    }
    if (filteredRows === null) {
      return (
        <p className={styles.titleLabel}>
          <span className={styles.titleNumber}>{totalRows}</span>
          <span>{tableName}s</span>
        </p>
      );
    }
    return (
      <>
        <div className={styles.titleWrapper}>
          <span className={styles.titleNumber}>
            {filteredRows}/{totalRows}
          </span>
          <span className={styles.paddingTop}>{tableName}s </span>
        </div>
        {showActiveFilter && (
          <div className={styles.filterWrapper}>
            <Checkbox
              isChecked={!!isActive}
              primary
              onChange={() => {
                setIsActive(!isActive);
              }}
              text="Active"
            />
            <Checkbox
              isChecked={!!isExpired}
              primary
              onChange={() => {
                setIsExpired(!isExpired);
              }}
              text="Expired"
            />
          </div>
        )}
      </>
    );
  };
  const renderCreateButton = () =>
    createButtonComponent || (
      <Button
        text={`Create ${cloudType} ${tableName}`}
        onClick={() => {
          if (onCreate) {
            onCreate();
            return;
          }
          setIsCreateMode(true);
        }}
        disabled={disableCreateOption}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        automationId={`new${tableName}`}
      />
    );
  const renderExportCSV = () =>
    (csvData || fetchCsvData) && (
      <CustomCSVDownload
        {...(csvData ? { data: [csvData] } : { fetchData: fetchCsvData })}
        data={[csvData]}
        showDownloadIcon
        isLoading={false}
        filesNumber={1}
        isSecondary
        hideText
        style={{
          height: 36,
        }}
      >
        CSV
      </CustomCSVDownload>
    );
  return (
    <div className={styles.header} data-inline={isInline}>
      <div className={classNames(styles.title, titleClassName)}>
        {includeDetails && (
          <Button
            automationId="expand-rows"
            text=""
            isSecondary
            icon={() => <GenerateIcon iconName={isExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
        )}
        <>
          {renderTitle()}
          {infoTooltip ? (
            <span className={styles.info}>
              <InfoPopover isSimple>{infoTooltip}</InfoPopover>
            </span>
          ) : null}
          {filtersPosition !== 'right' ? (filters || null) : null}
        </>
      </div>
      <div className={classNames(styles.rightMenu, rightMenuClassName)}>
        {filtersPosition === 'right' ? (filters || null) : null}
        {isCreatable && !isCreateLast && renderCreateButton()}
        {titleInfo ? <>{titleInfo}</> : null}
        {actionButton}
        {!hideSearch && (
          <div className="position-relative d-flex align-items-center">
            <Input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              className={styles.input}
              automationId="search-input"
              name={`search-${tableName}`}
              isSearch
              searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
              type="text"
            />
          </div>
        )}
        {showFilters &&
          (!filtersOpen ? (
            <Button
              text=""
              onClick={() => setFiltersOpen(true)}
              isSecondary
              automationId="open-filters"
              icon={() => <GenerateIcon iconName={ICONS.barsFilter.name} />}
            />
          ) : (
            <Button
              automationId="close-filters"
              text="Close Filters"
              icon={() => <GenerateIcon iconName={ICONS.xMark.name} />}
              onClick={() => setFiltersOpen(false)}
              isTextButton
            />
          ))}
        {csvTooltip ? (
          <Tooltip title={csvTooltip}>
            <span>{renderExportCSV()}</span>
          </Tooltip>
        ) : (
          renderExportCSV()
        )}
        {isCreatable && isCreateLast && renderCreateButton()}
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  tableName: PropTypes.string.isRequired,
  totalRows: PropTypes.number,
  filteredRows: PropTypes.number,
  csvData: PropTypes.object,
  filters: PropTypes.object,
  titleComponent: PropTypes.object,
  fetchCsvData: PropTypes.func,
  hideSearch: PropTypes.bool,
  isCreatable: PropTypes.bool,
  includeDetails: PropTypes.bool,
  showFilters: PropTypes.bool,
  actionButton: PropTypes.element,
  infoTooltip: PropTypes.string,
  csvTooltip: PropTypes.string,
  titleClassName: PropTypes.string,
  titleInfo: PropTypes.string || PropTypes.element,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  onCreate: PropTypes.func,
  hideTitle: PropTypes.bool,
  isCreateLast: PropTypes.bool,
  isInline: PropTypes.bool,
  showActiveFilter: PropTypes.bool,
  disableCreateOption: PropTypes.bool,
  cloudType: PropTypes.string,
  rightMenuClassName: PropTypes.string,
  filtersPosition: PropTypes.oneOf(['left', 'right']),
};

TableHeader.defaultProps = {
  rightMenuClassName: '',
  includeDetails: false,
  isCreatable: true,
  actionButton: null,
  csvData: null,
  hideSearch: false,
  totalRows: null,
  filteredRows: null,
  infoTooltip: null,
  titleInfo: null,
  showFilters: false,
  fetchCsvData: null,
  expanded: null,
  filters: null,
  setExpanded: null,
  onCreate: null,
  hideTitle: false,
  isCreateLast: false,
  isInline: false,
  titleComponent: null,
  showActiveFilter: false,
  disableCreateOption: false,
  csvTooltip: null,
  cloudType: '',
  titleClassName: '',
  filtersPosition: 'left',
};

export default TableHeader;
