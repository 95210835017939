import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FilteringState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  RowDetailState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  TableRowDetail,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { CSVLink } from 'react-csv';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableWrapper from 'shared/components/tables/TableWrapper';
import {
  IDLE_INSTANCES_TABLE_COLUMNS,
  IDLE_INSTANCES_COLUMN_WIDTHS,
  IDLE_INSTANCES_DEFAULT_SORTING,
} from 'usage/constants/usageConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { useIdleInstances } from 'usage/hooks/react-query/useIdleInstances';
import IdleInstanceHeatmapChart from './IdleInstanceHeatmapChart';

const RowDetail = ({ row, startDate, endDate }) => {
  const { fetchIdleInstancesRowData } = useIdleInstances();
  const { data, isLoading } = fetchIdleInstancesRowData(row.criteriaId, row.resourceId, startDate, endDate);

  const dataIsEmpty = !isLoading && Object.entries(data || {}).length === 0;
  if (dataIsEmpty) {
    return <div style={{ padding: '10px', width: '60vw' }}>No data found</div>;
  }
  return (
    <div style={{ padding: '10px', width: '60vw' }}>
      {isLoading ? (
        <Spinner customStyle={{ width: '200px' }} position="relative" />
      ) : (
        <IdleInstanceHeatmapChart isLoadingIdleInstancesRowData={isLoading} series={data} />
      )}
    </div>
  );
};

RowDetail.propTypes = {
  row: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

const numericSort = (a, b) => {
  let newA = a;
  let newB = b;
  if (typeof newA === 'string' && newA.includes('$')) {
    newA = +newA.replace('$', '');
  }
  if (typeof newB === 'string' && newB.includes('$')) {
    newB = +newB.replace('$', '');
  }
  return newA - newB;
};

const IdleInstancesTable = ({ data, startDate, endDate, getCurrencyNumber }) => {
  const pageSizes = [10, 15, 20, 0];
  const columns = [
    { columnName: 'totalWaste', compare: numericSort },
    { columnName: 'numOfDays', compare: numericSort },
    { columnName: 'dailyAvg', compare: numericSort },
  ];
  const [columnWidths, setColumnWidths] = useState(IDLE_INSTANCES_COLUMN_WIDTHS);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const formatters = {
    instanceNameColValue: (row) => `${row.resourceName} (${row.resourceId})`,
    regionColValue: (row) => row.regionTagName,
    dailyAvgColValue: (row) => row.dailyAvg.toFixed(2),
    totalWasteColValue: (row) => getCurrencyNumber(row.totalWaste, 3, { roundNumber: true }),
  };
  const prepareCsvModifiedRows = (data) => JSON.parse(JSON.stringify(data));
  const prepareColumns = () =>
    IDLE_INSTANCES_TABLE_COLUMNS.map((col) => ({
      name: col.name,
      title: col.title,
      getCellValue: formatters[`${col.name}ColValue`] ? formatters[`${col.name}ColValue`] : null,
    }));

  return (
    <div className="card">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <CSVLink
          data={prepareCsvModifiedRows(data)}
          headers={IDLE_INSTANCES_TABLE_COLUMNS.title}
          filename="idle-instances.csv"
        >
          Download as CSV
          <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
        </CSVLink>
      </div>
      <Grid rows={data} columns={prepareColumns()}>
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={(rows) => setExpandedRowIds([rows[rows.length - 1]])}
        />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <SortingState defaultSorting={IDLE_INSTANCES_DEFAULT_SORTING} />
        <IntegratedSorting columnExtensions={columns} />
        <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
        <IntegratedPaging />
        <TableWrapper />
        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableRowDetail
          contentComponent={({ row }) => <RowDetail row={row} startDate={startDate} endDate={endDate} />}
        />
        <TableFilterRow />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>
    </div>
  );
};

export default withUserSettingsConsumer(IdleInstancesTable);
