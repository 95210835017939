import { API } from 'shared/utils/apiMiddleware';
import { isNil } from 'lodash';
import { BILLINGS_API_NAME, USER_MANAGEMENT_ROLES } from './helperFunctions';

export async function fetchRoles(params) {
  const queryString = params
    ? Object.keys(params)
        ?.filter((key) => !isNil(params[key]))
        ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        ?.join('&')
    : null;
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}${queryString ? `?${queryString}` : ''}`);
}

export async function createRole(role) {
  const payload = {
    body: { role },
  };
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}`, payload);
}

export async function deleteRoles(rolesIds) {
  const payload = {
    body: { rolesIds },
  };
  return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}`, payload);
}

export async function fetchRoleById(roleId) {
  const encodedRoleId = encodeURIComponent(roleId);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}`);
}

export async function fetchRoleDataAccess(roleId) {
  const encodedRoleId = encodeURIComponent(roleId);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/data-access`);
}

export async function fetchRolePermissions(roleId) {
  const encodedRoleId = encodeURIComponent(roleId);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/permissions`);
}

export async function setRolePermissions(roleId, roleCategoryPermissions) {
  const payload = {
    body: { roleCategoryPermissions },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/permissions`, payload);
}

export async function fetchPartialRolePermissions(roleId, category, actionId) {
  const encodedRoleId = encodeURIComponent(roleId);
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/permissions/${category}/${actionId}`);
}

export async function addPartialRolePermissions(roleId, category, action, rolePartialPermission) {
  const payload = {
    body: { rolePartialPermission },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.put(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/permissions/${category}/${action}`,
    payload,
  );
}

export async function deletePartialRolePermissions(roleId, category, action, rolePartialPermission) {
  const payload = {
    body: { rolePartialPermission },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.del(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/permissions/${category}/${action}`,
    payload,
  );
}

export async function addSubRolesToRole(roleId, subRolesIds) {
  const payload = {
    body: { subRolesIds },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/sub-roles`, payload);
}

export async function removeSubRolesFromRole(roleId, subRolesIds) {
  const payload = {
    body: { subRolesIds },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/sub-roles`, payload);
}

export async function fetchMergedPermissionsOfRoles(rolesIds) {
  const payload = {
    body: { rolesIds },
  };
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/get-merged-permissions`, payload);
}

export async function updateResellerCustomersAccessibilityForRole(roleId, roleDataAccess) {
  const payload = {
    body: { roleDataAccess },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.put(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/data-access/reseller-customers`,
    payload,
  );
}

export async function updateAccountsAccessibilityForRole(roleId, roleDataAccess) {
  const payload = {
    body: { roleDataAccess },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/data-access/accounts`, payload);
}

export async function updateCostCentersAccessibilityForRole(roleId, roleDataAccess) {
  const payload = {
    body: { roleDataAccess },
  };
  const encodedRoleId = encodeURIComponent(roleId);
  return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedRoleId}/data-access/cost-centers`, payload);
}
