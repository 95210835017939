import download from '../img/icons/download.png';
import filter from '../img/icons/filter.png';
import table from '../img/icons/table.png';
import barChart from '../img/icons/bar_chart.png';
import save from '../img/icons/save.png';
import calendar from '../img/icons/calendar.png';
import upArrow from '../img/icons/up_arrow.png';
import downArrow from '../img/icons/down_arrow.png';
import cloud from '../img/icons/cloud.png';
import { ReactComponent as reportGlass } from '../img/icons/report_glass.svg';
import notification from '../img/icons/notification.png';
import profileWhite from '../img/icons/profile_white.png';
import subAccount from '../img/icons/sub_account.png';
import { ReactComponent as subAccounts } from '../img/icons/sub_accounts.svg';
import { ReactComponent as plus } from '../img/icons/plus.svg';
import { ReactComponent as plusNoFill } from '../img/icons/plus_no_fill.svg';
import { ReactComponent as dashboard } from '../img/icons/dashboard.svg';
import { ReactComponent as costUsage } from '../img/icons/cost_usage.svg';
import { ReactComponent as budget } from '../img/icons/budget.svg';
import { ReactComponent as recommendations } from '../img/icons/recommendations.svg';
import { ReactComponent as service } from '../img/icons/service.svg';
import { ReactComponent as anomaly } from '../img/icons/anomaly_detection.svg';
import { ReactComponent as tags } from '../img/icons/tags.svg';
import { ReactComponent as cpu } from '../img/icons/CPU.svg';
import { ReactComponent as logoDark } from '../img/logo/logo_dark.svg';
// import { ReactComponent as  logoWhite } from '../img/logo/logo_white.png';
import { ReactComponent as ssl } from '../img/icons/ssl.svg';
import pci from '../img/icons/pci.png';
import { ReactComponent as registerCostUsage } from '../img/icons/register_cost_usage.svg';
import { ReactComponent as registerTags } from '../img/icons/register_tags.svg';
import { ReactComponent as registerRecommendations } from '../img/icons/register_recommendations.svg';
import { ReactComponent as users } from '../img/icons/users.svg';
import pencilEdit from '../img/icons/PencilIcon';
import { ReactComponent as computeIcon } from '../img/icons/Compute.svg';
import { ReactComponent as dataBaseIcon } from '../img/icons/Database.svg';
import { ReactComponent as networkIcon } from '../img/icons/Network.svg';
import { ReactComponent as storageIcon } from '../img/icons/Storage.svg';
import { ReactComponent as commitmentIcon } from '../img/icons/commitment.svg';
import { ReactComponent as noDataIcon } from '../img/icons/database-search.svg';
// import { ReactComponent as  monitoringIcon } from '../img/icons/database-search-white.svg';
import { ReactComponent as monitoringIcon } from '../img/icons/monitor-line.svg';
import { ReactComponent as buttonX } from '../img/icons/button_x.svg';
import { ReactComponent as pencilOutline } from '../img/icons/pencil-circle-outline.svg';
import pencilOutlinePng from '../img/icons/pencil-circle-outline-png.png';
import { ReactComponent as squareEdit } from '../img/icons/square_edit.svg';
import { ReactComponent as k8sIcon } from '../img/icons/kubernetes_icon.svg';
import { ReactComponent as costAllocationIcon } from '../img/icons/cost-allocation.svg';
import { ReactComponent as DiskSaveIcon } from '../img/icons/disk-save-icon.svg';
import { ReactComponent as refresh } from '../img/icons/refresh.svg';
import { ReactComponent as remove } from '../img/icons/remove.svg';
import { ReactComponent as resume } from '../img/icons/resume.svg';
import { ReactComponent as eyeLine } from '../img/icons/eye-line.svg';
import { ReactComponent as formLine } from '../img/icons/form-line.svg';
import { ReactComponent as eventLine } from '../img/icons/event-line.svg';
import { ReactComponent as storageLine } from '../img/icons/storage-line.svg';
import eyeOff from '../img/icons/eye-off.png';
import eye from '../img/icons/eye.png';
import { ReactComponent as hamburger } from '../img/icons/hamburger.svg';
import { ReactComponent as notifications } from '../img/icons/topbar/notifications.svg';
import { ReactComponent as accounts } from '../img/icons/topbar/accounts.svg';
import { ReactComponent as userAccount } from '../img/icons/topbar/userAccount.svg';
import { ReactComponent as userProfile } from '../img/icons/topbar/userProfile.svg';
import { ReactComponent as logout } from '../img/icons/topbar/logout.svg';
import { ReactComponent as userImpersonate } from '../img/icons/topbar/userImpersonate.svg';
import { ReactComponent as mainUser } from '../img/icons/topbar/mainUser.svg';
import { ReactComponent as info } from '../img/icons/info.svg';
import { ReactComponent as emptyOptions } from '../img/icons/emptyOptions.svg';
import { ReactComponent as alert } from '../img/icons/alert.svg';
import { ReactComponent as alertBlack } from '../img/icons/alert-black.svg';
import { ReactComponent as emptyAlert } from '../img/icons/emptyAlert.svg';
import { ReactComponent as collapse } from '../img/icons/collapse.svg';
import { ReactComponent as exportIcon } from '../img/icons/export.svg';
import { ReactComponent as andtCalendar } from '../img/icons/andtCalendar.svg';
import { ReactComponent as clone } from '../img/icons/clone.svg';
import { ReactComponent as deleteIcon } from '../img/icons/delete.svg';
import { ReactComponent as circleCheckedSolid } from '../img/icons/circle-check-solid.svg';
import { ReactComponent as circleXmark } from '../img/icons/circle-xmark-solid.svg';

const ICONS = {
  exportIcon,
  collapse,
  download,
  filter,
  table,
  barChart,
  save,
  calendar,
  upArrow,
  downArrow,
  cloud,
  reportGlass,
  plus,
  // logoWhite,
  logoDark,
  dashboard,
  costUsage,
  budget,
  recommendations,
  service,
  anomaly,
  tags,
  cpu,
  notification,
  profileWhite,
  subAccounts,
  subAccount,
  ssl,
  pci,
  registerCostUsage,
  registerTags,
  registerRecommendations,
  users,
  pencilEdit,
  noDataIcon,
  monitoringIcon,
  commitmentIcon,
  buttonX,
  pencilOutline,
  pencilOutlinePng,
  plusNoFill,
  squareEdit,
  k8sIcon,
  costAllocationIcon,
  DiskSaveIcon,
  recDashboard: {
    computeIcon,
    dataBaseIcon,
    networkIcon,
    storageIcon,
  },
  refresh,
  remove,
  resume,
  eyeLine,
  formLine,
  eventLine,
  storageLine,
  eyeOff,
  eye,
  hamburger,
  notifications,
  accounts,
  userAccount,
  userProfile,
  logout,
  userImpersonate,
  mainUser,
  info,
  emptyOptions,
  alert,
  alertBlack,
  emptyAlert,
  andtCalendar,
  clone,
  deleteIcon,
  circleCheckedSolid,
  circleXmark,
};

export default ICONS;
