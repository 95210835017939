/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { action, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { toast } from 'react-toastify';
import { camelCase, groupBy as lodashGroupBy } from 'lodash';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { FilterTypes } from 'usage/constants/usageConstants';
import {
  alignedStartEndDateByGranLevel,
  buildStartAndEndDate,
  getMonthNameFromNumber,
  replaceDateByGranularity,
} from 'shared/utils/dateUtil';
import {
  CostTrackingConstants,
  CostTypes,
  GROUP_BY_LOV,
  GroupByLovToAttributes,
  numOfCUStates,
} from 'usage/constants/costAndUsageConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import {
  buildFilterConfigParams,
  buildFilterParams,
  buildWhereParams,
  convertObjToMap,
  getIncludeExcludeMaps,
} from 'shared/utils/apiUtil';
import DateFilter from 'shared/modules/dateFilter';
import { getCostAndUsageData } from '../hooks/react-query/cost-and-usage';

class InvoiceStore {
  isLoading = false;
  concurrentCalls = 0;
  isTimeoutError = false;
  isDistinctValuesLoading = true;
  isMetricsLoading = false;
  finOpsSevenDaysServiceCost = [];
  currVsPrevMonthCost = [];
  dataStates = [...new Array(numOfCUStates)].map(() => ({}));
  metricDataStates = [...new Array(numOfCUStates)].map(() => ({}));

  constructor(rootStore, apiGateway) {
    this.rootStore = rootStore;
    this.apiGateway = apiGateway;
    makeObservable(this, {
      dataStates: observable,
      metricDataStates: observable,
      setIsLoading: action,
      isLoading: observable,
      isTimeoutError: observable,
      isMetricsLoading: observable,
      // currGroupByBalance: observable,
      currVsPrevMonthCost: observable,
      finOpsSevenDaysServiceCost: observable,
      isDistinctValuesLoading: observable,
      fetchMetricsData: action,
      fetchDataByState: action,
    });
  }

  fetchDefaultDataByBusinessMapping = (viewpointId) => async () => {
    const { currDispUserCloudAccountType } = this.rootStore.usersStore;
    const isAws = currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS;
    const filterParams = isAws ? buildFilterParams(new Map([['chargetype', ['Tax']]]), FilterTypes.EXCLUDE) : '';
    const { startDate, endDate } = buildStartAndEndDate(null, null, false);
    const data = await getCostAndUsageData(
      `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: ${viewpointId}`,
      '',
      '',
      startDate,
      endDate,
      CostTrackingConstants.GRAN_LEVEL_QUARTERLY,
      filterParams,
      [CostTypes.COST, CostTypes.DISCOUNT],
    );
    const groupedData = lodashGroupBy(data, (a) => a.group_by);
    return Object.values(groupedData).map((row) => ({
      ...row[0],
      total_cost: row.reduce((acc, item) => acc + (item.total_cost || 0), 0),
    }));
  };

  fetchMonthlyServicesBalance = async (
    groupByLevel,
    secondaryGroupByLevel = 'none',
    groupByValue,
    whereParamsMap,
    start,
    end,
    periodGranLevel,
    { isRateUsageBased, excludedFiltersStatusMap, likedFiltersStatus, filtersMap, filtersConfig },
    currCostType = CostTypes.COST,
    isShowAmortize,
    isApplyMargin,
    isNetAmortize,
    isNetUnblended,
    isPublicCost,
    isDistributed,
    lastProcessTime = '',
    panelId,
    dashboardId,
  ) => {
    runInAction(() => {
      this.isTimeoutError = false;
    });
    start = replaceDateByGranularity(start, periodGranLevel, true, lastProcessTime);
    end = replaceDateByGranularity(end, periodGranLevel, false, lastProcessTime);
    const { startDate, endDate } =
      periodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY
        ? { startDate: start, endDate: end }
        : buildStartAndEndDate(start, end, false);
    const { includeFiltersMap, excludeFiltersMap, likeFiltersStatus, likeExcludeFiltersStatus } = getIncludeExcludeMaps(
      filtersMap,
      excludedFiltersStatusMap,
      likedFiltersStatus,
    );
    const filterParams = buildFilterParams(includeFiltersMap);
    const excludeFilterParams = buildFilterParams(excludeFiltersMap, FilterTypes.EXCLUDE);
    const likeFilterParams = buildFilterParams(convertObjToMap(likeFiltersStatus), FilterTypes.LIKE);
    const likeExcludeFilterParams = buildFilterParams(convertObjToMap(likeExcludeFiltersStatus), FilterTypes.NOT_LIKE);
    const filtersConfigParams = buildFilterConfigParams(convertObjToMap(filtersConfig));
    const whereParams = buildWhereParams(whereParamsMap);
    const arrCostType = Array.isArray(currCostType) ? [...currCostType] : [currCostType];
    const secondaryGroupBy = secondaryGroupByLevel !== 'none' ? secondaryGroupByLevel : false;
    const isUsageCountNeeded = false;
    try {
      const invoicesCurrGroupByBalance = await getCostAndUsageData(
        groupByValue,
        secondaryGroupBy,
        whereParams,
        startDate,
        endDate,
        periodGranLevel,
        filterParams,
        arrCostType,
        isUsageCountNeeded,
        isShowAmortize,
        isApplyMargin,
        excludeFilterParams,
        likeFilterParams,
        isNetAmortize,
        isNetUnblended,
        isPublicCost,
        isDistributed,
        panelId,
        dashboardId,
        true,
        filtersConfigParams,
        likeExcludeFilterParams,
        isRateUsageBased,
      );
      const resultBalance = invoicesCurrGroupByBalance.map((dailyBalance) => {
        if (
          groupByValue === AwsCommonFields.LINKED_ACCOUNT_ID ||
          groupByValue === AwsCommonFields.LINKED_ACCOUNT_NAME
        ) {
          const groupBy =
            dailyBalance.linked_account_name && dailyBalance.linked_account_id
              ? `${dailyBalance.linked_account_name} (${dailyBalance.linked_account_id})`
              : dailyBalance.group_by;
          return {
            awsAccountId: dailyBalance.account_id,
            usageDate: dailyBalance.usage_date,
            groupBy,
            groupBySecondary: dailyBalance.group_by_secondary,
            totalCost: dailyBalance.total_cost,
            totalUsage: dailyBalance.total_usage_quantity,
            totalUsageSecondary: dailyBalance.total_usage_quantity_secondary,
            totalQuantity: +dailyBalance.group_num_of_items,
            customTags: dailyBalance.custom_tags,
            linkedAccountId: dailyBalance.linked_account_id,
            linkedAccountName: dailyBalance.linked_account_name,
          };
        }
        if (
          secondaryGroupBy === AwsCommonFields.LINKED_ACCOUNT_ID ||
          secondaryGroupBy === AwsCommonFields.LINKED_ACCOUNT_NAME
        ) {
          const groupBySecondary =
            dailyBalance.linked_account_name && dailyBalance.linked_account_id
              ? `${dailyBalance.linked_account_name} (${dailyBalance.linked_account_id})`
              : dailyBalance.group_by_secondary;
          return {
            awsAccountId: dailyBalance.account_id,
            usageDate: dailyBalance.usage_date,
            groupBy: dailyBalance.group_by,
            groupBySecondary,
            totalCost: dailyBalance.total_cost,
            totalUsage: dailyBalance.total_usage_quantity,
            totalUsageSecondary: dailyBalance.total_usage_quantity_secondary,
            totalQuantity: +dailyBalance.group_num_of_items,
            customTags: dailyBalance.custom_tags,
            linkedAccountId: dailyBalance.linked_account_id,
            linkedAccountName: dailyBalance.linked_account_name,
          };
        }
        if (groupByLevel !== GroupByLovToAttributes.get(GROUP_BY_LOV.BY_RESOURCE)) {
          return {
            awsAccountId: dailyBalance.account_id,
            usageDate: dailyBalance.usage_date,
            groupBy: dailyBalance.group_by,
            groupBySecondary: dailyBalance.group_by_secondary,
            totalCost: dailyBalance.total_cost,
            totalUsage: dailyBalance.total_usage_quantity,
            totalUsageSecondary: dailyBalance.total_usage_quantity_secondary,
            totalQuantity: +dailyBalance.group_num_of_items,
            customTags: dailyBalance.custom_tags,
            linkedAccountId: dailyBalance.linked_account_id,
            linkedAccountName: dailyBalance.linked_account_name,
          };
        }
        return {
          regionTagName: dailyBalance.region_tag_name,
          awsAccountId: dailyBalance.account_id,
          linkedAccountName: dailyBalance.linked_account_name,
          linkedAccountId: dailyBalance.linked_account_id,
          usageDate: dailyBalance.usage_date,
          groupBy: dailyBalance.group_by,
          groupBySecondary: dailyBalance.group_by_secondary,
          totalCost: dailyBalance.total_cost,
          resourceId: dailyBalance.resource_id,
          resourceName: dailyBalance.resource_name,
          resourceGroup: dailyBalance.resource_group,
          resourceDescription: dailyBalance.item_description,
          totalUsage: dailyBalance.total_usage_quantity,
          totalUsageSecondary: dailyBalance.total_usage_quantity_secondary,
          totalQuantity: +dailyBalance.group_num_of_items,
          customTags: dailyBalance.custom_tags,
          projectName: dailyBalance.project_name,
          familyType: dailyBalance.family_type_name,
          usageQuantityType: dailyBalance.usage_quantity_type,
        };
      });
      return resultBalance;
    } catch (error) {
      const { response } = error;
      if (response?.status === 400 && response?.data?.clientMessage) {
        toast.error(response.data.clientMessage);
      }
      // It means server timeout / user lost internet connection
      // Currently is not possible to check by error.status===504 as nginx error has wrong format and axios cant parse it
      if (error.message === 'Network Error') {
        runInAction(() => {
          this.isTimeoutError = true;
        });
      }
      // eslint-disable-next-line no-console
      console.log({ ...error });
      return [];
    }
  };
  fetchMetricsTypes = () => this.apiGateway.getMetricsTypes();
  fetchMetricsData = async ({
    currDataState,
    groupBy,
    start,
    end,
    periodGranLevel,
    filtersMap,
    dimensions,
    metric,
    excludedFiltersMap: excludeFilters,
  }) => {
    try {
      this.isMetricsLoading = true;
      end = replaceDateByGranularity(end || DateFilter.getDate(), periodGranLevel, false);
      start = replaceDateByGranularity(start || moment(end).add(-30, 'day'), periodGranLevel, true);
      if (periodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY) {
        end = start;
      }
      const { startDate, endDate } = buildStartAndEndDate(start, end, false);
      const { includeFiltersMap, excludeFiltersMap } = getIncludeExcludeMaps(filtersMap, excludeFilters);
      const filterParams = buildFilterParams(includeFiltersMap);
      const excludeFilterParams = buildFilterParams(excludeFiltersMap, FilterTypes.EXCLUDE);
      const { start: startAligned, end: endAligned } = alignedStartEndDateByGranLevel(
        startDate,
        endDate,
        periodGranLevel,
      );

      const data = await this.apiGateway.getMetricsData({
        groupBy,
        startDate: startAligned,
        endDate: endAligned,
        periodGranLevel,
        filtersMap: filterParams,
        excludedFiltersMap: excludeFilterParams,
        dimensions,
        metric,
      });
      const result = data.map((dailyBalance) =>
        Object.keys(dailyBalance).reduce(
          (acc, key) => ({
            ...acc,
            [camelCase(key)]: dailyBalance[key],
          }),
          {},
        ),
      );
      LabelCoordinator.populateNewDisplayKeyMapBasedOnDataState('cueDisplayCoordinator', currDataState, result);
      runInAction(() => {
        this.metricDataStates[currDataState] = result;
        this.isMetricsLoading = false;
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  fetchDataByState = async (
    currDataState,
    groupByLevel,
    secondaryGroupByLevel = 'usagedate',
    groupByValue,
    whereParamsMap,
    start,
    end,
    periodGranLevel,
    { isRateUsageBased, filtersMap, filtersConfig, likedFiltersStatus, excludedFiltersStatusMap },
    currCostType,
    isShowAmortize,
    isApplyMargin,
    isNetAmortize,
    isNetUnblended,
    isPublicCost,
    isDistributed,
  ) => {
    this.isLoading = true;
    this.concurrentCalls++;
    try {
      const resultBalance = await this.fetchMonthlyServicesBalance(
        groupByLevel,
        secondaryGroupByLevel,
        groupByValue,
        whereParamsMap,
        start,
        end,
        periodGranLevel,
        {
          isRateUsageBased,
          filtersMap,
          filtersConfig,
          likedFiltersStatus,
          excludedFiltersStatusMap: convertObjToMap(excludedFiltersStatusMap),
        },
        currCostType,
        isShowAmortize,
        isApplyMargin,
        isNetAmortize,
        isNetUnblended,
        isPublicCost,
        isDistributed,
      );
      LabelCoordinator.populateNewDisplayKeyMapBasedOnDataState('cueDisplayCoordinator', currDataState, resultBalance);
      runInAction(() => {
        this.dataStates[currDataState] = resultBalance;
        this.concurrentCalls--;
        this.isLoading = this.concurrentCalls > 0;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        this.concurrentCalls--;
      });
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  invalidateStore = () => {
    this.finOpsSevenDaysServiceCost.splice(0);
    this.currVsPrevMonthCost.splice(0);
    this.isLoading = false;
    this.concurrentCalls = 0;
    this.clearDataStates();
  };

  clearDataStates = () => {
    this.dataStates = [...new Array(numOfCUStates)].map(() => ({}));
  };

  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };
}

export default InvoiceStore;
