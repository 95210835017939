import React from 'react';

/* eslint-disable max-len */
export const getOnboardingError = ({ code }) =>
  ({
    ERR_HANDSHAKE_GCP_BQ_CLIENT_NOT_CREATED:
      'It seems like the Service Account Key file you uploaded in step #1 of the onboarding is incorrect or malformed.  Please update it and click on “Next” in step #2 of the onboarding.',
    ERR_HANDSHAKE_GCP_BQ_PROJECT_NOT_FOUND:
      'It seems like the project ID you entered in step #1 of the onboarding is incorrect. Please update it and click on “Next” in step #2 of the onboarding.',
    ERR_HANDSHAKE_GCP_BQ_TABLE_NOT_FOUND:
      'It seems like the table name you entered in step #1 of the onboarding is incorrect. Please update it and click on “Next” in step #2 of the onboarding.',
    ERR_HANDSHAKE_GCP_BQ_TABLE_FORBIDDEN: (
      <>
        <p>We do not have permission to query your billing data set. Please verify:</p>
        <ul>
          <li>The role “BigQuery Job User” is part of your Service Account.</li>
          <li>
            You assigned the role &quot;BigQuery Data Viewer&quot; to your billing data set and associated it with your
            Service Account.
          </li>
          <li>The values you entered are correct.</li>
        </ul>
        <p>Please update the following and click on “Next” in step #2 of the onboarding.</p>
      </>
    ),
    ERR_HANDSHAKE_GCP_NO_RECORDS:
      'It appears that your billing table does not contain updated data. Please check this and once there is data click on “Next” in step #2 of the onboarding.',
  }[code]);
