import React from 'react';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import styles from '../featureflags.module.scss';

type Props = {
  onFeatureToggle(featureName: string): (isChecked: boolean) => void;
  allFeatures: { featureName: string; isActive: boolean }[];
  showGlobalSwitchers?: boolean;
};
const HeaderRow: React.FC<Props> = ({ allFeatures, onFeatureToggle, showGlobalSwitchers }) => (
  <div className={styles.flagsHeaderRow}>
    <div className={styles.nameCell}>Companies</div>
    <div className={styles.nameCell}>Accounts</div>
    {Object.values(allFeatures).map(({ featureName, isActive }) => (
      <div key={featureName} className={styles.flagHeaderCell} title={featureName}>
        {showGlobalSwitchers && (
          <Checkbox
            className={isActive ? styles.flagCheckboxChecked : styles.flagCheckbox}
            isChecked={isActive}
            onChange={onFeatureToggle(featureName)}
          />
        )}
        {featureName}
      </div>
    ))}
  </div>
);

export default HeaderRow;
