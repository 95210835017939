import React from 'react';
import MultiSelect from 'shared/components/FieldFilter';
import OneChoiceSelect from 'shared/components/OneChoiceFieldFilter';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const createOptions = (fieldValues) => fieldValues.map((fieldValue) => ({ value: fieldValue, label: fieldValue }));

const RenderFilters = ({ disticedValuesMap, selectedOptionsMap, handleSelectFilter }) => (
  <Row style={{ width: '500px', marginLeft: '30px', marginTop: '-20px' }}>
    <Col>
      <div className="filter-bar-inner-col-wrapper">
        <h5 className="filter-bar-inner-col-title">Projects</h5>
        <MultiSelect
          type="projects"
          value={selectedOptionsMap.get('projects') ? createOptions(selectedOptionsMap.get('projects')) : 'Select...'}
          options={createOptions(disticedValuesMap.get('projects'))}
          handleChange={handleSelectFilter}
        />
      </div>
    </Col>
    <Col>
      <div className="filter-bar-inner-col-wrapper">
        <h5 className="filter-bar-inner-col-title">Status Code</h5>
        <OneChoiceSelect
          type="statusCode"
          value={
            selectedOptionsMap.get('statusCode') ? createOptions(selectedOptionsMap.get('statusCode'))[0] : 'Select...'
          }
          options={createOptions(disticedValuesMap.get('statusCode'))}
          handleChange={handleSelectFilter}
          isClearable={false}
        />
      </div>
    </Col>
  </Row>
);

RenderFilters.propTypes = {
  disticedValuesMap: PropTypes.object.isRequired,
  selectedOptionsMap: PropTypes.object.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
};

export default RenderFilters;
