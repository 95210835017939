import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { querystring } from 'shared/utils/apiUtil';
import { Routes } from 'shared/constants/routes';
import integrationsUtils, { validateIntegrationType } from 'shared/utils/integrationsUtils';

const UnauthenticatedRoute = ({ component: C, props: cProps, location, ...rest }) => {
  useEffect(() => {
    const integrationType = new URLSearchParams(location.search).get('type');
    if (validateIntegrationType(integrationType)) {
      sessionStorage.setItem('integration', location.search);
      integrationsUtils.completeAuthForIntegrations();
    }
  }, []);

  const currPath = querystring('redirect') || String(window.location.pathname);
  let nextRoutePath = `${Routes.LANDING}`;

  if (currPath !== '/' && currPath !== `${Routes.LOG_IN}` && currPath !== `${Routes.ACCOUNTS_OVERVIEW}`) {
    // pass the redirect query param and search params to the landing page
    nextRoutePath += `?redirect=${currPath}`;
    if (window.location.search && !querystring('redirect')) {
      nextRoutePath += window.location.search;
    }
  }
  const isRedirectNeeded = nextRoutePath && nextRoutePath !== String(window.location.pathname);
  return (
    <Route
      {...rest}
      // eslint-disable-next-line
      render={(props) =>
        cProps.isSessionStorageAuth() && isRedirectNeeded ? (
          <Redirect to={nextRoutePath} />
        ) : (
          <C {...props} {...cProps} />
        )
      }
    />
  );
};

UnauthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired,
  location: PropTypes.object,
};

UnauthenticatedRoute.defaultProps = {
  location: {},
};

export default UnauthenticatedRoute;
