import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class BusinessMappingDataState extends BaseCostUsageDataState {
  // eslint-disable-next-line class-methods-use-this
  getNewDataStateType() {
    const newDataState = CostUsageStates.BUSINESS_MAPPING;
    return newDataState;
  }

  // eslint-disable-next-line class-methods-use-this
  getDataStateType() {
    return CostUsageStates.BUSINESS_MAPPING;
  }
}
