import React from 'react';
import cube from 'shared/img/images/opt_cube3d.png';
import cloud from 'shared/img/images/cloud.png';
import styles from './Login.module.scss';

const LeftSideIllustration = () => (
  <div>
    <div className={styles.mainImgWrapper} />
    <div className={styles.timeline} />
    <div className={styles.cube}>
      <img src={cube} alt="cube" />
    </div>
    <div className={styles.cloud}>
      <img src={cloud} alt="cloud" />
    </div>
  </div>
);

export default LeftSideIllustration;
