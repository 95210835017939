import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import classes from './normalizedFactorUnitsTable.module.scss';

const NormalizedFactorUnits = () => {
  const normalizedFactorUnits = {
    nano: '0.25',
    micro: '0.5',
    small: '1',
    medium: '2',
    large: '4',
    xlarge: '8',
    '2xlarge': '16',
    '3xlarge': '24',
    '4xlarge': '32',
    '6xlarge': '48',
    '8xlarge': '64',
    '9xlarge': '72',
    '10xlarge': '80',
    '12xlarge': '96',
    '16xlarge': '128',
    '18xlarge': '144',
    '24xlarge': '192',
    '32xlarge': '256',
    '48xlarge': '384',
    '56xlarge': '448',
    '112xlarge': '896',
  };

  const [isViewAllOpened, setIsViewAllOpened] = useState(false);

  return (
    <div className={classes.nfuContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.titleText}>Normalized Factor Units (NFU)</div>
        <div className={classes.subTitleText}>
          The NFU provides an estimated saving factor per instance size footprint.
        </div>
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.tableHeaders}>
          <div>Instance Type</div>
          <div>Normalized Factor</div>
        </div>
        <div className={classes.tableBody}>
          {(isViewAllOpened
            ? Object.entries(normalizedFactorUnits)
            : Object.entries(normalizedFactorUnits)?.slice(0, 5)
          )?.map((entry) => (
            <div className={classes.dataPair}>
              <div>{entry[0]}</div>
              <div>{entry[1]}</div>
            </div>
          ))}
          <div className={classes.viewAll} onClick={() => setIsViewAllOpened(!isViewAllOpened)}>
            <div>{isViewAllOpened ? 'View Less' : 'View All'}</div>
            <GenerateIcon
              iconName={isViewAllOpened ? ICONS.chevronUp.name : ICONS.chevronDown.name}
              className={classes.chevronIcon}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NormalizedFactorUnits;
