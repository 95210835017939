import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureSnapshotMigrationRecommendation } from 'recommendations/models/recommTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';
import { calcNumOfMonthsToBreakEven } from 'shared/utils/calcUtil';

export default class AzureOutdatedSnapshotBuilder extends AzureCostRecommendationBuilder {
  createRecommendation() {
    this._recommendation = new AzureSnapshotMigrationRecommendation();
  }

  // eslint-disable-next-line class-methods-use-this
  getRecommType() {
    return RecommendationTypes.AZURE_OUTDATED_SNAPSHOT;
  }

  buildRecommTitle() {
    this._recommendation.title = 'Outdated Snapshot';
  }

  build() {
    super.build();
    const {
      account_id: accountId,
      yearly_cost_current: currYearlyCost,
      yearly_cost_recommended: recommYearlyCost,
      recommended_plan: recommPlan,
      zone_tag: zoneTag,
      resource_name: resourceName,
      source,
      service,
      creation_time: creationTime,
    } = this._rawRecommendation;

    const yearlyRecommCost = new RecommendationCost(currYearlyCost, recommYearlyCost);
    this._recommendation.breakEvenMonth = calcNumOfMonthsToBreakEven(recommYearlyCost, currYearlyCost);
    this._recommendation.region = zoneTag;
    this._recommendation.creationTime = creationTime;
    this._recommendation.yearlyCost = yearlyRecommCost;
    this._recommendation.recommPlan = recommPlan;
    this._recommendation.accountId = accountId;
    this._recommendation.resourceName = resourceName;
    // eslint-disable-next-line prefer-destructuring
    this._recommendation.resourceGroup = ((source || '').match(/resourceGroups\/([^/]*)\//) || [])[1];
    this._recommendation.service = service;
  }
}
