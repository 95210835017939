import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { FlatObject } from 'shared/types/commonTypes.ts';
import { mapEachEntry } from 'shared/utils/helpers.ts';
import Spinner from 'shared/components/andtComponents/Spinner';
import SingleRow from './SingleRow.tsx';
import styles from '../featureflags.module.scss';
import HeaderRow from './HeaderRow.tsx';
import VirtualScroll from './VirtualScroll.tsx';
import { AccountFeaturesT, FeatureT } from '../types.ts';
import { ALL_ACCOUNTS_DEFAULT, ROWS_HEIGHT } from '../constants.ts';

type FlagsTableProps = {
  accountsFeatures: FlatObject<AccountFeaturesT>;
  allFeatures: { featureName: string }[];
  setAccountsFeaturesState: React.Dispatch<React.SetStateAction<FlatObject<AccountFeaturesT>>>;
  showSpinner?: boolean;
};
const FeaturesTable: React.FC<FlagsTableProps> = ({
  allFeatures: initialAllFeatures = [],
  accountsFeatures,
  setAccountsFeaturesState,
  showSpinner,
}) => {
  const allFeaturesMap = useMemo(
    () =>
      Object.fromEntries(initialAllFeatures.map(({ featureName }) => [featureName, { featureName, isActive: false }])),
    [initialAllFeatures],
  );
  const [headerFeaturesState, setHeaderFeaturesState] = useState(allFeaturesMap);
  const setRow = useCallback(
    (row: AccountFeaturesT, { featureName, isActive }: FeatureT) => {
      setAccountsFeaturesState((state) => {
        const newState = { ...state };
        newState[row.id] = row;
        return newState;
      });
      if (isActive === false) {
        setHeaderFeaturesState((state) => ({
          ...state,
          [featureName]: { featureName, isActive: false },
        }));
      }
    },
    [setAccountsFeaturesState],
  );

  const onHeaderFeatureToggle = useCallback(
    (featureName: string) => (isChecked: boolean) => {
      setAccountsFeaturesState((state) =>
        mapEachEntry<AccountFeaturesT>(state, ([id, row]) => [
          id,
          {
            ...row,
            changedFeatures: { ...(row.changedFeatures || {}), [featureName]: isChecked ? '1' : '0' },
          } as AccountFeaturesT,
        ]),
      );
      setHeaderFeaturesState((state) => ({
        ...state,
        [featureName]: { ...state[featureName], isActive: isChecked },
      }));
    },
    [],
  );

  useEffect(() => {
    setHeaderFeaturesState((state) => {
      const newState = {};
      initialAllFeatures.forEach(({ featureName }) => {
        newState[featureName] = state[featureName] || { featureName, isActive: false };
      });
      return newState;
    });
  }, [initialAllFeatures]);

  const allFeatures = Object.values(headerFeaturesState);
  const defaultFeatures = {
    ...(accountsFeatures[ALL_ACCOUNTS_DEFAULT]?.features || {}),
    ...(accountsFeatures[ALL_ACCOUNTS_DEFAULT]?.changedFeatures || {}),
  };
  const bodyWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={styles.flagsTable}>
        <HeaderRow allFeatures={allFeatures} onFeatureToggle={onHeaderFeatureToggle} showGlobalSwitchers={false} />
        <div ref={bodyWrapperRef}>
          <VirtualScroll
            bodyWrapperRef={bodyWrapperRef}
            className={styles.flagsTableBody}
            rowsDataLength={Object.values(accountsFeatures).length}
            rowHeight={ROWS_HEIGHT}
          >
            {(start, end) =>
              Object.values(accountsFeatures)
                ?.slice(start, end)
                .map((row) => (
                  <SingleRow
                    key={row.id}
                    row={row}
                    defaultFeatures={defaultFeatures}
                    allFeatures={allFeatures}
                    setRow={setRow}
                  />
                ))
            }
          </VirtualScroll>
        </div>
      </div>
      {showSpinner && <Spinner />}
    </>
  );
};

export default FeaturesTable;
