/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len,react/no-unused-prop-types,arrow-body-style */
import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import PropTypes from 'prop-types';
import NetworkChart from './NetworkChart';
import CpuChart from './CpuChart';
import MemoryChart from './MemoryChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createNetworkChartData = (rec) => {
    const { maxNetwork, networkInStatisticsUsage, networkOutStatisticsUsage } = rec;

    // Netowrk in data
    const networkInDataEntries = Object.entries(networkInStatisticsUsage).sort((a, b) => b[0].localeCompare(a[0]));
    networkInDataEntries.reverse();
    const networkInData = networkInDataEntries.map(([key, value]) => {
      return { date: createDateDisplayStr(key), in: parseFloat(value) };
    });

    // Netowrk out data
    const networkOutDataEntries = Object.entries(networkOutStatisticsUsage).sort((a, b) => b[0].localeCompare(a[0]));
    networkOutDataEntries.reverse();
    const networkOutData = networkOutDataEntries.map(([key, value]) => {
      return { date: createDateDisplayStr(key), out: parseFloat(value) };
    });

    const networkData = networkInData.map((currInData, idx) => {
      return { ...currInData, out: networkOutData[idx].out };
    });

    return {
      maxNetwork,
      networkData,
    };
  };

  createCpuChartData = (rec) => {
    const { cpuPercentile, maxCpuUtil, cpuUtilStatisticsUsage } = rec;
    const cpuUtilDataEntries = Object.entries(cpuUtilStatisticsUsage).sort((a, b) => b[0].localeCompare(a[0]));
    cpuUtilDataEntries.reverse();
    const cpuUtilData = cpuUtilDataEntries.map(([key, value]) => {
      return { date: createDateDisplayStr(key), cpu: parseFloat(value) };
    });

    return {
      cpuUtilData,
      maxCpuUtil,
      cpuPercentile,
    };
  };

  createMemoryPerformanceData = (rec) => {
    const { memUtilizationPercentage } = rec;
    if (!memUtilizationPercentage) {
      return null;
    }
    const modifiedData = Object.entries(memUtilizationPercentage).map(([key]) => {
      const dataObj = {
        date: key,
        usageDate: createDateDisplayStr(key),
        usage: memUtilizationPercentage ? parseFloat(memUtilizationPercentage[key]) : null,
      };
      return dataObj;
    });

    modifiedData.sort((a, b) => {
      const [aYear, aMonth, aDay] = a.date.split('-');
      const [bYear, bMonth, bDay] = b.date.split('-');
      if (+bYear !== +aYear) {
        return aYear - bYear;
      }
      if (+bMonth !== +aMonth) {
        return aMonth - bMonth;
      }
      if (+bDay !== +aDay) {
        return aDay - bDay;
      }
      return 1;
    });
    return modifiedData;
  };

  render() {
    const { rec } = this.props;
    const { update } = this.state;
    const networkData = this.createNetworkChartData(rec);
    const cpuData = this.createCpuChartData(rec);
    const memUtilizationPercentage = this.createMemoryPerformanceData(rec);

    return (
      <div>
        {update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          <div />
        )}
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Network Performance</p>
          <br />
          <NetworkChart data={networkData} />
        </div>
        <hr />
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">CPU Performance</p>
          <br />
          <CpuChart data={cpuData} />
        </div>
        <hr />
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Memory Performance</p>
          <br />
          <MemoryChart data={memUtilizationPercentage} />
        </div>
      </div>
    );
  }
}
