import React from 'react';
import PropTypes from 'prop-types';

export const ActionContainer = ({ children }) => (
  <div style={{ 'line-height': '40px', 'font-size': '14px' }}>{children}</div>
);

ActionContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

export const ActionHeader = ({ title = 'Steps To Execute' }) => (
  <h5 className="bold-text card__title project-summary__statistic-title" style={{ 'text-transform': 'uppercase' }}>
    {title}
  </h5>
);

ActionHeader.propTypes = {
  title: PropTypes.string,
};

ActionHeader.defaultProps = {
  title: null,
};
