/* eslint-disable function-paren-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { Ec2SavingsPlansRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class Ec2SavingsPlansRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new Ec2SavingsPlansRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_SAVINGS_PLANS;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action} AWS Savings Plans`;
  }

  build() {
    super.build();
    const {
      days_to_check: daysToCheck,
      ec2_daily_cost_statistics: ec2DailyCostStatistics,
      ec2_savings_plans_avg_daily_costs: ec2SavingsPlansAvgDailyCosts,
      savings_plans_daily_cost_statistics: spDailyCostsStatistics,
      compute_savings_plans_avg_daily_costs: computeSavingsPlansAvgDailyCosts,
      ec2_heat_map_statistics: ec2HeatMapStatistics,
      monthly_recurring_charges: monthlyRecurringCharges,
      avg_hourly_cost: avgHourlyCost,
      recommended_hourly_commitment: recommendedHourlyCommitment,
      recommended_partial_upfront_payment: recommendedPartialUpfrontPayment,
      recommended_upfront_payment: recommendedUpfrontPayment,
      savings_plan_type: savingsPlanType,
      payment_option: paymentOption,
      term,
      discount_table: discountTable,
      total_year_commitment: totalYearCommitment,
    } = this._rawRecommendation;
    this._recommendation.discountTable =
      discountTable !== undefined
        ? discountTable.map(
            ({ instance_type, region, os, discount, total_usage, total_cost, total_cost_after_discount }) => {
              return {
                region,
                instanceType: instance_type,
                os,
                discount: parseFloat(discount),
                totalUsage: parseFloat(total_usage),
                totalCost: parseFloat(total_cost),
                totalCostAfterDiscount: parseFloat(total_cost_after_discount),
              };
            },
          )
        : discountTable;
    this._recommendation.paymentOption = paymentOption;
    this.recommendation.term = term;
    this._recommendation.ec2DailyCostStatistics = ec2DailyCostStatistics;
    this._recommendation.spDailyCostsStatistics = spDailyCostsStatistics;
    this._recommendation.ec2HeatMapStatistics = ec2HeatMapStatistics;
    this._recommendation.monthlyRecurringCharges = parseFloat(monthlyRecurringCharges);
    this._recommendation.recommendedHourlyCommitment = parseFloat(recommendedHourlyCommitment);
    this._recommendation.avgHourlyCost = parseFloat(avgHourlyCost);
    this._recommendation.recommendedUpfrontPayment =
      recommendedUpfrontPayment !== undefined
        ? parseFloat(recommendedUpfrontPayment)
        : parseFloat(recommendedPartialUpfrontPayment);
    this._recommendation.savingsPlanType = savingsPlanType;
    this._recommendation.ec2SavingsPlansAvgDailyCosts = ec2SavingsPlansAvgDailyCosts;
    this._recommendation.computeSavingsPlansAvgDailyCosts = computeSavingsPlansAvgDailyCosts;
    this._recommendation.totalYearCommitment = parseFloat(totalYearCommitment);
    this._recommendation.daysToCheck = daysToCheck;
  }
}
