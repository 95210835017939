import React from 'react';
import PropTypes from 'prop-types';
import { RECOMMENDATION_TYPES_MAPPING } from './recommendationTypesMapping';
import { RecommendationOptionsContextProvider } from './recommendationGenericComponents/recommendationOptionsContext';

const RecommendationDataContainer = ({ row }) => {
  const typeId = row?.typeId;
  const RecommendationTypeComponent =
    Object.values(RECOMMENDATION_TYPES_MAPPING)?.find((r) => r.typeId === typeId)?.component ||
    RECOMMENDATION_TYPES_MAPPING.DEFAULT.component;

  return (
    <RecommendationOptionsContextProvider>
      {RecommendationTypeComponent && <RecommendationTypeComponent recommendation={row} />}
    </RecommendationOptionsContextProvider>
  );
};

RecommendationDataContainer.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RecommendationDataContainer;
