import Content from '../../shared/components/Content';
import RdsVuContent from '../../containers/RdsVersionUpgrade/components/Content';
import VersionUpgradeContent from '../../containers/VersionUpgrade/components/Content';
import EbsUpgradeContent from '../../containers/EbsUpgrade/components/Content';
import AzureVmRiContent from '../../containers/AzureVmRi/components/Content';
import RiContent from '../../containers/Ri/components/Content';
import { RecommendationTypes } from '../../constants/recommendationsConstants';

class RecContentFactory {
  static create = (data) => {
    switch (data.type) {
      case RecommendationTypes.RDS_VERSION_UPGRADE:
        return RdsVuContent;
      case RecommendationTypes.EC2_VERSION_UPGRADE:
        return VersionUpgradeContent;
      case RecommendationTypes.EBS_UPGRADE:
        return EbsUpgradeContent;
      case RecommendationTypes.AZURE_VM_RI:
        return AzureVmRiContent;
      case RecommendationTypes.RI:
        return RiContent;
      default:
        return Content;
    }
  };
}

export default RecContentFactory;
