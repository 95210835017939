import React from 'react';
import HeatMapDimensions from 'recommendationsNew/components/heatMap/heatMapDimensions';
import HeatMapCardList from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCardList';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import CloudWaste from './cloudWaste/cloudWaste';
import { useRecommendationsNewContext } from '../../contexts/recommendationsNewContext';

import classes from './heatMap.module.scss';

const HeatMapContent = () => {
  const { heatMapGroupByOptions: groupByOptionsContext, setHeatMapGroupByOptions, isWasteChart } = useHeatMapContext();
  const { isDashboardPanel } = useRecommendationsNewContext();

  const optionClicked = (option) => {
    const optionClicked = groupByOptionsContext?.find((obj) => obj.id === option.id);
    if (!optionClicked) {
      setHeatMapGroupByOptions([...groupByOptionsContext, { ...option, default: true }]);
    } else {
      setHeatMapGroupByOptions([...groupByOptionsContext.filter((obj) => obj.id !== option.id)]);
    }
  };

  return (
    <div className={classes.heatMapContentContainer}>
      {!isWasteChart && groupByOptionsContext?.length ? (
        <div className={`${classes.content} ${isDashboardPanel && classes.flex}`}>
          <div className={`${classes.heatMapContent} ${isDashboardPanel && classes.limitWidth}`}>
            <HeatMapCardList onCardClose={optionClicked} />
            {!isDashboardPanel && <HeatMapDimensions onGroupByOptionClick={optionClicked} />}
          </div>
        </div>
      ) : null}
      {isWasteChart && <CloudWaste />}
    </div>
  );
};

export default HeatMapContent;
