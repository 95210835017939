/* eslint-disable no-unused-vars */
import RiStatistics from 'recommendations/containers/Ri/components/Statistics';
import SpotStatistics from 'recommendations/containers/Spot/components/Statistics';
import RegionStatistics from 'recommendations/containers/Region/components/Statistics';
import VuStatistics from 'recommendations/containers/VersionUpgrade/components/Statistics';
import EbsTypeChangeStatistics from 'recommendations/containers/EbsTypeChange/components/Statistics';
import RdsTypeChangeStatistics from 'recommendations/containers/RdsTypeChange/components/Statistics';
import SavingsPlansStatistics from 'recommendations/containers/SavingsPlans/components/Statistics';
import Ec2IdleStatistics from 'recommendations/containers/Ec2Idle/components/Statistics';
import Ec2LowCpuUsageStatistics from 'recommendations/containers/Ec2LowCpu/components/Statistics';
import ElasticacheRiStatistics from 'recommendations/containers/ElasticacheRi/components/Statistics';
import OpenSearchRiStatistics from 'recommendations/containers/OpenSearchRi/components/Statistics';
import RdsRiStatistics from 'recommendations/containers/RdsRi/components/Statistics';
import RdsClassChangeStatistics from 'recommendations/containers/RdsClassChange/components/Statistics';
import RdsIopsChangeStatistics from 'recommendations/containers/RdsIopsChange/components/Statistics';
import EcsFargetStatistics from 'recommendations/containers/EcsFargate/components/Statistics';
// AZURE ******
import AzureVmRiStatistics from 'recommendations/containers/AzureVmRi/components/Statistics';
import AzureDiskTypeStatistics from 'recommendations/containers/AzureDiskTypeChange/components/Statistics';
import AzureVmIdleStatistics from 'recommendations/containers/AzureVmIdle/components/Statistics';
import AzureVmRightSizingStatistics from 'recommendations/containers/AzureVmRightSizing/components/Statistics';

// GCP ******
import GcpUsageCommitmentStatistics from 'recommendations/containers/GcpUsageCommitment/components/Statistics';
import GcpVmIdleStatistics from 'recommendations/containers/GcpVmIdle/components/Statistics';
import GcpVmRightSizingStatistics from 'recommendations/containers/GcpVmRightSizing/components/Statistics';

import { RecommendationTypes } from '../../constants/recommendationsConstants';

class RecStatisticsFactory {
  static create(data) {
    let output;
    switch (data.type) {
      case RecommendationTypes.EC2_RI:
        output = RiStatistics;
        break;
      case RecommendationTypes.EC2_SPOT:
        output = SpotStatistics;
        break;
      case RecommendationTypes.EC2_REGION:
        output = RegionStatistics;
        break;
      // TODO: Temporarily remove the statistics Cost Comparison Table, until BE fixes the incorrect displayed values.
      // case RecommendationTypes.EC2_VERSION_UPGRADE:
      //   output = VuStatistics;
      //   break;
      case RecommendationTypes.EBS_TYPE_CHANGE:
        output = EbsTypeChangeStatistics;
        break;
      case RecommendationTypes.EBS_TYPE_SIZE_CHANGE:
        output = EbsTypeChangeStatistics;
        break;
      case RecommendationTypes.EC2_IDLE:
        output = Ec2IdleStatistics;
        break;
      case RecommendationTypes.RDS_TYPE_CHANGE:
        output = RdsTypeChangeStatistics;
        break;
      case RecommendationTypes.EC2_SAVINGS_PLANS:
        output = SavingsPlansStatistics;
        break;
      case RecommendationTypes.EC2_LOW_CPU_USAGE:
        output = Ec2LowCpuUsageStatistics;
        break;
      case RecommendationTypes.RDS_RI:
        output = RdsRiStatistics;
        break;
      case RecommendationTypes.RDS_CLASS_CHANGE:
        output = RdsClassChangeStatistics;
        break;
      case RecommendationTypes.RDS_IOPS_CHANGE:
        output = RdsIopsChangeStatistics;
        break;
      case RecommendationTypes.ELASTICACHE_RI:
        output = ElasticacheRiStatistics;
        break;
      case RecommendationTypes.OPEN_SEARCH_RI:
        output = OpenSearchRiStatistics;
        break;
      case RecommendationTypes.ECS_FARGATE:
        output = EcsFargetStatistics;
        break;
      // AZURE ********
      case RecommendationTypes.AZURE_VM_RI:
        output = AzureVmRiStatistics;
        break;
      case RecommendationTypes.AZURE_DISK_TYPE_CHANGE:
        output = AzureDiskTypeStatistics;
        break;
      case RecommendationTypes.AZURE_VM_IDLE:
        output = AzureVmIdleStatistics;
        break;
      case RecommendationTypes.AZURE_VM_RIGHT_SIZING:
        output = AzureVmRightSizingStatistics;
        break;

      // GCP ********
      case RecommendationTypes.GCP_VM_IDLE:
        output = GcpVmIdleStatistics;
        break;
      case RecommendationTypes.GCP_USAGE_COMMITMENT:
        output = GcpUsageCommitmentStatistics;
        break;
      case RecommendationTypes.GCP_VM_RIGHT_SIZING:
        output = GcpVmRightSizingStatistics;
        break;
      // DEFAULT ********
      default:
        output = undefined;
        break;
    }
    return output;
  }
}

export default RecStatisticsFactory;
