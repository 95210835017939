import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import {
  AZURE_PROPERTIES,
  AZURE_RESERVED_INSTANCE_PROPERTIES,
  DB_PROPERTIES,
  LOAD_BALANCER_PROPERTIES,
} from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const AzureIdleLoadBalancer = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.POWERSHELL,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `Remove-AzureRmResource --ResourceId "${recommendation?.resourceId}"`,
        },
      ],
    },
  ];
  const description = 'No VM is attached to this Load balancer';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...AZURE_RESERVED_INSTANCE_PROPERTIES,
        ...LOAD_BALANCER_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
      }}
    />
  );
};

AzureIdleLoadBalancer.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureIdleLoadBalancer;
