import { AwsCommonFields } from 'shared/constants/awsConstants';
import { CAU_ALERTS_GRAN_LEVELS } from 'usage/constants/usageConstants';
import { OPERATORS_KEYS } from 'shared/constants/appConstants';
import { cloneDeep } from 'lodash';
import BaseCauAlert from './baseCauAlert';

export default class CostChangesAlert extends BaseCauAlert {
  constructor(alertParams) {
    super(alertParams);
    this.filters = alertParams.filters;
    this[AwsCommonFields.LINKED_ACCOUNT_NAME] =
      alertParams[AwsCommonFields.LINKED_ACCOUNT_NAME] || alertParams.linkedAccountIds;
    this[AwsCommonFields.SERVICE] = alertParams[AwsCommonFields.SERVICE] || alertParams.services;
    this[AwsCommonFields.USAGE_TYPE] = alertParams[AwsCommonFields.USAGE_TYPE] || alertParams.usageTypes;
    this.changeType = alertParams.changeType;
    this.operatorType = alertParams.operatorType;
    this.changePercent = alertParams.changePercent;
    this.changeValue = alertParams.changeValue;
    this.granularity = alertParams.granularity;
    this.dayInWeek = alertParams.dayInWeek;
    this.editedParams = null;
  }

  getValueIfHasAll = (values) => (values.includes('all') ? 'all' : values);
  getFormattedValues = (values, isForUi = false) => {
    if (Array.isArray(values)) {
      return isForUi ? values : this.getValueIfHasAll(values.map(({ value }) => value));
    } else if (typeof values === 'object') {
      return Object.values(values);
    }
    return values;
  };
  getFilterDBKey = (key) => {
    const mapUiKeyToDbKey = new Map([
      [AwsCommonFields.LINKED_ACCOUNT_NAME, 'linkedAccountIds'],
      [AwsCommonFields.SERVICE, 'services'],
      [AwsCommonFields.USAGE_TYPE, 'usageTypes'],
    ]);
    return mapUiKeyToDbKey.get(key);
  };
  getFiltersValues(isForUi = false) {
    if (this.filters) {
      return Object.entries(this.filters).reduce(
        (acc, [key, { value, operator }]) => ({
          ...acc,
          [key]: {
            operator,
            value: this.getFormattedValues(value, isForUi),
          },
        }),
        {},
      );
    }
    return {
      [AwsCommonFields.LINKED_ACCOUNT_NAME]: {
        value: this.linkedAccountsValues,
        operator: OPERATORS_KEYS.IS,
      },
      [AwsCommonFields.SERVICE]: {
        value: this[AwsCommonFields.SERVICE],
        operator: OPERATORS_KEYS.IS,
      },
      [AwsCommonFields.USAGE_TYPE]: {
        value: this[AwsCommonFields.USAGE_TYPE],
        operator: OPERATORS_KEYS.IS,
      },
    };
  }
  get linkedAccountsValues() {
    const values = Array.isArray(this[AwsCommonFields.LINKED_ACCOUNT_NAME])
      ? this[AwsCommonFields.LINKED_ACCOUNT_NAME].map(({ value }) => value)
      : Object.values(this[AwsCommonFields.LINKED_ACCOUNT_NAME]);
    const result = this.getValueIfHasAll(values);
    return result;
  }

  get servicesValues() {
    const values = Array.isArray(this[AwsCommonFields.SERVICE])
      ? this[AwsCommonFields.SERVICE].map(({ value }) => value)
      : Object.values(this[AwsCommonFields.SERVICE]);
    const result = this.getValueIfHasAll(values);
    return result;
  }

  get usageTypesValues() {
    const values = Array.isArray(this[AwsCommonFields.USAGE_TYPE])
      ? this[AwsCommonFields.USAGE_TYPE].map(({ value }) => value)
      : Object.values(this[AwsCommonFields.USAGE_TYPE]);
    const result = this.getValueIfHasAll(values);
    return result;
  }

  get granularityValue() {
    return this.granularity ? this.granularity.value : CAU_ALERTS_GRAN_LEVELS.DAILY;
  }

  get dayInWeekValue() {
    return this.dayInWeek ? this.dayInWeek.value : null;
  }

  get operatorTypeValue() {
    const value = this.operatorType ? this.operatorType.value : [];
    return value;
  }

  get changeTypeValue() {
    const value = this.changeType ? this.changeType.value : [];
    return value;
  }

  get alertModifiedParams() {
    const baseParams = super.alertModifiedParams;
    return {
      ...baseParams,
      filters: this.getFiltersValues(false),
      changeType: this.changeTypeValue,
      operatorType: this.operatorTypeValue,
      changePercent: this.changePercent,
      changeValue: this.changeValue,
      granularity: this.granularityValue,
      dayInWeek: this.dayInWeekValue,
    };
  }

  get alertParams() {
    const { id, ...baseParams } = super.alertParams;
    const alertParams = {
      filters: this.getFiltersValues(true),
      granularity: this.granularity,
      dayInWeek: this.dayInWeek,
      changeType: this.changeType,
      operatorType: this.operatorType,
      changePercent: this.changePercent,
      changeValue: this.changeValue,
      ...baseParams,
    };
    return alertParams;
  }

  updateEditedParams = (editedParam) => {
    const copyEditParams = cloneDeep(editedParam);
    if (!editedParam || !Object.keys(editedParam).length) {
      return;
    }
    const editedParamKey = Object.keys(copyEditParams)[0];
    if (editedParamKey === AwsCommonFields.LINKED_ACCOUNT_NAME) {
      copyEditParams.linkedAccountIds = copyEditParams[AwsCommonFields.LINKED_ACCOUNT_NAME];
      delete copyEditParams[AwsCommonFields.LINKED_ACCOUNT_NAME];
    } else if (editedParamKey === AwsCommonFields.SERVICE) {
      copyEditParams.services = copyEditParams[AwsCommonFields.SERVICE];
      delete copyEditParams[AwsCommonFields.SERVICE];
    } else if (editedParamKey === AwsCommonFields.USAGE_TYPE) {
      copyEditParams.usageTypes = copyEditParams[AwsCommonFields.USAGE_TYPE];
      delete copyEditParams[AwsCommonFields.USAGE_TYPE];
    }
    const newEditedParams = { ...(this.editedParams || {}), ...copyEditParams };
    this.editedParams = { ...newEditedParams };
  };
  getLinkedAccountsValues = () =>
    this[AwsCommonFields.LINKED_ACCOUNT_NAME]
      ? this[AwsCommonFields.LINKED_ACCOUNT_NAME].map(({ value }) => value)
      : [];
  getServicesValues = () =>
    this[AwsCommonFields.SERVICE] ? this[AwsCommonFields.SERVICE].map(({ value }) => value) : [];
}
