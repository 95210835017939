/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { downloadChartAsPng } from 'shared/utils/downloadAsPng';

function HamburgerToolbar(props) {
  const { data, chartRef } = props;
  const csvLinkRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  function exportAsCsv() {
    csvLinkRef.current.link.click();
    setIsOpen(false);
  }

  return (
    <div className="apexcharts-toolbar" style={{ position: 'relative', top: '-10px' }}>
      <div className="apexcharts-menu-icon" title="Menu">
        <button className="btn-no-style" onClick={() => setIsOpen(!isOpen)} type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
          </svg>
        </button>
      </div>
      <div className={isOpen ? 'apexcharts-menu apexcharts-menu-open' : 'apexcharts-menu'}>
        <div role="button" className="apexcharts-menu-item" onClick={() => downloadChartAsPng(chartRef)}>
          Download PNG
        </div>
        <div role="button" className="apexcharts-menu-item" onClick={exportAsCsv}>
          Download CSV
          <CSVLink
            data={(data && data.avgHourlyCostData) || []}
            ref={csvLinkRef}
            filename="avg-compute-costy-hourly.csv"
          />
        </div>
      </div>
    </div>
  );
}

HamburgerToolbar.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HamburgerToolbar;
