import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { AZURE_PROPERTIES, AZURE_RESERVED_INSTANCE_PROPERTIES, DB_PROPERTIES } from '../../recommendationPropertyUtils';

const AZURE_SNAPSHOT_MIGRATION_PROPERTIES = {
  SNAPSHOT_NAME: {
    label: 'Snapshot Name',
    getterFunction: (recommendation) => recommendation?.recData?.snapshot_name,
  },
};
const AzureSnapshotMigration = ({ recommendation }) => {
  const description =
    "This Snapshots is stored in premium storage, it's recommended to migrate from premium to standard storage.";
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      descriptionUrl={{
        // eslint-disable-next-line max-len
        url: 'https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/move-resource-group-and-subscription',
        fullSentence: 'How to move resources to a new resource group or subscription',
      }}
      dynamicProps={{
        ...AZURE_RESERVED_INSTANCE_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
        ...AZURE_SNAPSHOT_MIGRATION_PROPERTIES,
      }}
    />
  );
};

AzureSnapshotMigration.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureSnapshotMigration;
