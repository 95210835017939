/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import {
  AZURE_DB_COSTS_COLUMN_WIDTHS,
  AZURE_DB_COSTS_CURRENCIES_COLUMNS,
  AZURE_DB_COSTS_DEFAULT_HIDDEN_COLUMN_NAMES,
  AZURE_DB_COSTS_DEFAULT_SORTING,
  AZURE_DB_COSTS_TABLE_COLUMN_EXTENSIONS,
  AZURE_DB_COSTS_TABLE_COLUMNS,
} from 'usage/constants/usageConstants';
import { buildStartAndEndDate, datePickerSyntexDates } from 'shared/utils/dateUtil';
// import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import AzureDbCostsTable from './components/AzureDbCostTable';

class AzureDbCosts extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataIsLoading: true,
      startDate: buildStartAndEndDate(null, null).startDate,
      endDate: buildStartAndEndDate(null, null).endDate,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  async componentDidMount() {
    if (this.state.data.length === 0) {
      this.setState({ dataIsLoading: true });
      const { data } = await this.props.usageStore.getAzureDbCostData(this.state.startDate, this.state.endDate);
      // const preparedData = this.prepareData(data);
      this.setState({ dataIsLoading: false, data });
    }
  }

  componentWillUnmount() {
    this.props.usageStore.clearAzureDbCostData();
  }

  handleDateChange = async ({ startDate, endDate }) => {
    const start = startDate || this.state.startDate;
    let end = endDate || this.state.endDate;
    try {
      if (start > end && end !== null) {
        end = start;
      }
      const modStartDate = buildStartAndEndDate(start, end).startDate;
      const modEndDate = buildStartAndEndDate(start, end).endDate;
      this.setState({
        startDate: modStartDate,
        endDate: modEndDate,
        dataIsLoading: true,
      });
      const { data } = await this.props.usageStore.getAzureDbCostData(modStartDate, modEndDate);
      this.setState({ dataIsLoading: false, data });
    } catch (error) {
      // console.log(error);
    }
  };

  render() {
    const { startDate, endDate, dataIsLoading, data } = this.state;
    if (dataIsLoading) {
      return <Spinner />;
    }

    return (
      <Container>
        <PageHeader title={PageNames.AZURE_DB_COSTS_USAGE} showDate />
        <Card>
          <CardBody style={{ minHeight: '300px' }}>
            <Row style={{ marginLeft: '1px' }}>
              <DatePickerFilter
                onDateChange={this.handleDateChange}
                startDate={datePickerSyntexDates(startDate, null).startDate}
                endDate={datePickerSyntexDates(null, endDate).endDate}
                currPeriodGranLevel="day"
              />
            </Row>
            <Row>
              <AzureDbCostsTable
                data={data}
                columns={AZURE_DB_COSTS_TABLE_COLUMNS}
                columnWidths={AZURE_DB_COSTS_COLUMN_WIDTHS}
                tableColumnExtensions={AZURE_DB_COSTS_TABLE_COLUMN_EXTENSIONS}
                defaultHiddenColumnNames={AZURE_DB_COSTS_DEFAULT_HIDDEN_COLUMN_NAMES}
                defaultSorting={AZURE_DB_COSTS_DEFAULT_SORTING}
                currenciesColumns={AZURE_DB_COSTS_CURRENCIES_COLUMNS}
                csvTitle={`VM_data_${DateFilter.month}_${DateFilter.year}`}
              />
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const ObserverAzureDbCosts = observer(AzureDbCosts);
export default ObserverAzureDbCosts;
