import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => {
  const startDate = moment(rec.creationTime);
  const now = moment();
  const diff = now.diff(startDate, 'day');
  return (
    <DescriptionWrapper>
      <p>
        This Disk Outdated Snapshot age is {diff} days, to avoid unnecessary costs, It’s recommended to delete the
        snapshot after 21 days
      </p>
    </DescriptionWrapper>
  );
};

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
