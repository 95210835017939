/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const getBarColor = (name) => {
  let color = '';
  switch (name) {
    case 'Recommended Plan':
      color = '#6764af';
      break;
    case 'Current Cost':
      color = '#c5c5ff';
      break;
    default:
      color = '#70bbfd';
      break;
  }
  return color;
};

const renderBars = (recs) => recs.map((rec) => <Bar barSize={12} dataKey={rec.name} fill={getBarColor(rec.name)} />);

const CostComparisonChart = ({ data }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
      <BarChart barGap={-12} width={550} height={284} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tickMargin={0} interval={0} tick={<CustomizedAxisTick fontSize={10} />} />
        <YAxis
          label={{
            value: 'Cost',
            offset: 0,
            angle: -90,
            position: 'left',
          }}
          tickFormatter={(value) => `${getCurrencyNumber(value, 0)}`}
        />
        <Tooltip formatter={(value) => `${getCurrencyNumber(value, 2)}`} />
        {renderBars(data)}
      </BarChart>
    </ResponsiveContainer>
  );
};

CostComparisonChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CostComparisonChart;
