/* eslint-disable no-useless-constructor */
/* eslint-disable constructor-super */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { CostUsageStates, mapCostUsageStateToDisplay } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class DoubleGroupDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType = (dataKey) => null;

  getDataStateType = () => CostUsageStates.SECOND_GROUP_BY;
}
