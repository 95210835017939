import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const IP_UNATTACHED_PROPERTIES = {
  EBS_TYPE: {
    label: 'Ebs Type',
    getterFunction: (recommendation) => recommendation?.recData?.ebs_type,
  },
  PUBLIC_IP: {
    label: 'Detached EBS Id',
    getterFunction: (recommendation) => recommendation?.recData?.detached_ebs_id,
  },
  EBS_IOPS: {
    label: 'IOPS',
    getterFunction: (recommendation) => recommendation?.recData?.ebs_iops,
  },
};

const EbsUnattached = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `aws ec2 delete-volume --volume-id ${recommendation?.recData?.detached_ebs_id}`,
        },
      ],
    },
  ];
  const description =
    // eslint-disable-next-line max-len
    "Please note that this EBS is active, but no longer attached to any instance, if it's no longer needed it should be turned off";
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={IP_UNATTACHED_PROPERTIES}
    />
  );
};

EbsUnattached.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default EbsUnattached;
