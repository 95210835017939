/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the AWS CLI:</li>
      {rec?.attentionComment ? (
        <CodeBlock text={rec.command} />
      ) : (
        <CodeBlock
          text={`aws ec2 modify-volume --volume-type {{newEbsType}} ${
            rec.type === 'ebs-type-and-size-change' ? '--size {{gp2Size}}' : ''
          } --volume-id {{ebsId}}`}
          variables={{
            gp2Size: rec.gp2Size,
            ebsId: rec.ebsId,
            newEbsType: rec.newEbsType,
          }}
        />
      )}
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
