import moment from 'moment';

class _DateFilter {
  constructor() {
    this.date = new Date();
    this.locales = 'en-GB';
  }

  setDate = (date) => {
    this.date = date || new Date();
  };

  getDate = () => this.date;

  getUiLastProcessTimeString = () => {
    let day = this.getPreviousDayDate().getDate();
    day = this.fixDateParam(day);
    let month = this.date.getUTCMonth() + 1;
    month = this.fixDateParam(month);
    const year = this.date.getUTCFullYear();
    return `${year}-${month}-${day}`;
  };

  fixDateParam = (dateParam) => {
    // validating date param is in the form of 0X or XX
    const fixedParam = `${0}${dateParam}`.slice(-2);
    return fixedParam;
  };

  getFirstDayOfCurrMonthDate = () => {
    let month = this.date.getUTCMonth() + 1;
    month = this.fixDateParam(month);
    const year = this.date.getUTCFullYear();
    return `${year}-${month}-01`;
  };

  getLastDayOfCurrMonthDate = () => {
    let month = this.date.getUTCMonth() + 1;
    const year = this.date.getUTCFullYear();
    const lastDayDate = new Date(year, month, 0);
    let lastDay = lastDayDate.getUTCDate();
    lastDay = this.fixDateParam(lastDay);
    month = this.fixDateParam(month);
    return `${year}-${month}-${lastDay}`;
  };

  getFirstDayOfNextMonthDate = () => {
    let month = this.date.getMonth();
    switch (month) {
      case 11:
        month = 1;
        break;
      case 12:
        month = 2;
        break;
      default:
        month += 2;
        break;
    }
    month = this.fixDateParam(month);
    const year = this.date.getFullYear();
    return `${year}-${month}-01`;
  };

  getFirstDayOfCurrMonthNextYearDate = () => {
    let month = this.date.getMonth() + 1;
    month = this.fixDateParam(month);
    let year = this.date.getFullYear();
    year += 1;
    return `${year}-${month}-01`;
  };

  getDateStr = () => {
    let day = this.date.getDate();
    day = this.fixDateParam(day);
    let month = this.date.getMonth() + 1;
    month = this.fixDateParam(month);
    const year = this.date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  getMonthStr = () => {
    const month = this.date.toLocaleString(this.locales, { month: 'long' });
    return month;
  };

  get day() {
    const day = this.date.getDate();
    return day;
  }

  get month() {
    const month = this.date.getMonth();
    return month + 1;
  }

  get year() {
    const year = this.date.getFullYear();
    return year;
  }

  getDateFilterRangeStr = () => moment(this.date).format('MMM D, YYYY');

  getCurrMonthAndYear = () => {
    const month = this.date.getMonth() + 1; // months are indexed 0, 1, ..
    const year = this.date.getFullYear();
    return { month, year };
  };

  getPrevMonthAndYear = () => {
    let month = this.date.getMonth(); // months are indexed 0, 1, ..
    let year = this.date.getFullYear();
    // in case month is zero, the previous month is 12 so we need to explicitly
    // set the month and the year
    if (month === 0) {
      month = 12;
      year -= 1;
    }

    return { month, year };
  };

  getNextMonthAndYear = () => {
    let month = this.date.getMonth() + 2; // months are indexed 0, 1, ..
    let year = this.date.getFullYear();
    // in case month is 10/11, the next month is 12/13 so we need to explicitly
    // set the month and the year
    if (month === 12) {
      month = 0;
      year += 1;
    } else if (month === 13) {
      month = 1;
      year += 1;
    }

    return { month, year };
  };

  getPreviousDayDate = () => {
    const prevDayDate = new Date(this.date);
    // const prevDayDate = new Date();
    prevDayDate.setDate(prevDayDate.getDate() - 1);
    return prevDayDate;
  };

  getCurrMonthDateFormat = () => {
    const year = this.date.getFullYear();
    let month = this.date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    return `${year}-${month}`;
  };

  getPrevMonthDateFormat = () => {
    let year = this.date.getFullYear();
    const currMonth = this.date.getMonth() + 1;
    let prevMonth = currMonth - 1;

    if (currMonth === 1) {
      year -= 1;
    } else if (currMonth === 12) {
      year += 1;
    }

    if (prevMonth < 10) {
      prevMonth = `0${prevMonth}`;
    }
    return `${year}-${prevMonth}`;
  };

  getDefaultDateForReq = (lastProccessDate, diffDays = 30) => {
    const nowObj = moment();
    const latProcessDateObj = moment(lastProccessDate);
    if (nowObj.diff(latProcessDateObj, 'days') > diffDays) {
      return lastProccessDate;
    }
    return new Date();
  };
}

const DateFilter = new _DateFilter();
export default DateFilter;
