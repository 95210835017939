/* eslint-disable no-mixed-operators */
import { SpAnalyzerPaymentOptions, SpAnalyzerTerm } from 'usage/constants/usageConstants';
import { weightedMean } from 'shared/utils/mathUtil';

const SP_TERM_TO_NUM_OF_YERS = {
  [SpAnalyzerTerm.ONE_YEAR]: 1,
  [SpAnalyzerTerm.THREE_YEAR]: 3,
};

export const calcCoverage = (recHourlyCommitment, discount) => {
  const coverage = parseFloat(recHourlyCommitment) / (1 - discount);
  return coverage;
};

export const calcDiscount = (discountTable) => {
  // const discount = TERM_PAYMENT_OPTION_TO_SAVINGS_PERCENT[selectedNumOfYears][selectedPaymentOption];
  let discount = 0;
  if (discountTable && discountTable.length > 0) {
    const discountArray = discountTable.map((entry) => entry.discount).filter((discount) => !Number.isNaN(discount));
    const usageArray = discountTable.map((entry) => entry.totalUsage);
    discount = weightedMean(discountArray, usageArray);
  }

  return discount;
};

export const calcCharges = (recHourlyCommitment, selectedPaymentOption, selectedTermNumOfYears) => {
  let totalYearCommitment = 0;
  let recommendedUpfrontPayment = 0;
  let monthlyRecurringCharges = 0;
  const termNumOfYears = SP_TERM_TO_NUM_OF_YERS[selectedTermNumOfYears];

  totalYearCommitment = recHourlyCommitment * 24 * 365 * termNumOfYears;

  if (selectedPaymentOption === SpAnalyzerPaymentOptions.NO_UPFRONT) {
    monthlyRecurringCharges = totalYearCommitment / (12 * termNumOfYears);
  } else if (selectedPaymentOption === SpAnalyzerPaymentOptions.ALL_UPFRONT) {
    recommendedUpfrontPayment = totalYearCommitment;
  } else if (selectedPaymentOption === SpAnalyzerPaymentOptions.PARTIAL_UPFRONT) {
    recommendedUpfrontPayment = Math.abs((totalYearCommitment / 2).toFixed(3));
    monthlyRecurringCharges = recommendedUpfrontPayment / (12 * termNumOfYears);
  }
  return { recommendedUpfrontPayment, totalYearCommitment, monthlyRecurringCharges };
};
