import React from 'react';
import { useCUEGoals } from 'users/hooks/react-query/useCUEGoals';

const CUEGoalsContext = React.createContext();

export function withCUEGoalsContextProvider(Component) {
  function CUEGoalsContextProvider({ ...props }) {
    const { fetchCUEGoals } = useCUEGoals();
    const { data = [] } = fetchCUEGoals();
    return (
      <CUEGoalsContext.Provider
        value={{
          CUEGoals: data || [],
        }}
      >
        <Component {...props} CUEGoals={data || []} />
      </CUEGoalsContext.Provider>
    );
  }
  return CUEGoalsContextProvider;
}

export function withCUEGoalsContextConsumer(Component) {
  return function CUEGoalsContextConsumer({ ...props }) {
    return <CUEGoalsContext.Consumer>{(data) => <Component {...data} {...props} />}</CUEGoalsContext.Consumer>;
  };
}
