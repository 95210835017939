/* eslint-disable no-plusplus, no-param-reassign */
/* eslint-disable no-unused-vars */
import moment from 'moment';

const createSpChartData = (rawSavingsPlansData) => {
  if (rawSavingsPlansData) {
    const savingsPlansDataEntries = Object.entries(rawSavingsPlansData).sort((a, b) => b[0].localeCompare(a[0]));
    let savingsPlansData = [];
    if (savingsPlansDataEntries.length !== 0) {
      savingsPlansDataEntries.reverse();
      savingsPlansData = savingsPlansDataEntries.map(([key, value]) => ({ date: key, cost: parseFloat(value) }));
    }
    return savingsPlansData;
  }
  return rawSavingsPlansData;
};

export const createAvgHourlyCostChartData = (rec, selectedHourlyCost, coverage) => {
  const {
    ec2DailyCostStatistics,
    ec2SavingsPlansAvgDailyCosts,
    computeSavingsPlansAvgDailyCosts,
    spDailyCostsStatistics,
    reducePercent,
  } = rec;
  // const { selectedHourlyCost, coverage } = this.state;

  // ec2 on-demand daily cost
  const ec2DailyCost = createSpChartData(ec2DailyCostStatistics);

  let avgCost = 0.0;
  if (rec.avgHourlyCost) {
    avgCost = rec.avgHourlyCost;
  } else if (ec2DailyCost) {
    avgCost =
      ec2DailyCost.reduce((acculmelator, currDailyCost) => acculmelator + currDailyCost.cost, 0) / ec2DailyCost.length;
  }

  let dates = [];
  let hasEc2OnDemand = false;
  const ec2OnDemandDates = ec2DailyCostStatistics ? Object.keys(ec2DailyCostStatistics) : [];
  dates.push(...ec2OnDemandDates);
  // Savings Plans data

  const spDailyCost = spDailyCostsStatistics ? createSpChartData(spDailyCostsStatistics) : [];
  const ec2SavingsPlansData = createSpChartData(ec2SavingsPlansAvgDailyCosts);
  const computeSavingsPlansData = createSpChartData(computeSavingsPlansAvgDailyCosts);

  // sp
  let hasSavingsPlans = false;
  const spDates = spDailyCostsStatistics ? Object.keys(spDailyCostsStatistics) : [];
  dates.push(...spDates);

  // // ec2 sp
  // let hasEc2SavingsPlans = false;
  // const ec2SpDates = ec2SavingsPlansData.map(curr => curr.date);
  // dates.push(...ec2SpDates);

  // // compute Sp
  // let hasComputeSavingsPlans = false;
  // const computeSpDates = computeSavingsPlansData.map(curr => curr.date);
  // dates.push(...computeSpDates);

  // Merging dates from on-demand / ec2-savings-plans / compute-savings-plan
  dates = Array.from(new Set(dates));
  dates.sort();
  const coveragePercent = coverage;
  const avgHourlyCostData = dates.map((currDate, idx) => {
    const entry = { date: currDate, 'Savings Plan Coverage': coveragePercent };
    if (ec2OnDemandDates.includes(currDate)) {
      hasEc2OnDemand = true;
      const { cost } = ec2DailyCost.filter((curr) => curr.date === currDate)[0];
      entry['On-Demand'] = cost;
      // const savingsPlanCost = spDailyCost.filter(curr => curr.date === currDate)[0].cost;
      // entry['On-Demand'] = cost - (coveragePercent + savingsPlanCost);
    }

    if (spDates.includes(currDate)) {
      hasSavingsPlans = true;
      const savingsPlanCost = spDailyCost.filter((curr) => curr.date === currDate)[0].cost;
      entry['Purchased Savings Plans Coverage'] = savingsPlanCost;
    }

    // if (ec2SpDates.includes(currDate)) {
    //   hasEc2SavingsPlans = true;
    //   const savingsPlanCost = ec2SavingsPlansData.filter(curr => curr.date === currDate)[0].cost;
    //   entry['EC2 Savings Plans'] = savingsPlanCost;
    // }

    // if (computeSpDates.includes(currDate)) {
    //   hasComputeSavingsPlans = true;
    //   const savingsPlanCost = computeSavingsPlansData.filter(curr => curr.date === currDate)[0].cost;
    //   entry['Compute Savings Plans'] = savingsPlanCost;
    // }

    return entry;
  });

  return {
    recommendedHourlyCommitment: selectedHourlyCost,
    avgHourlyCostData,
    hasSavingsPlans,
  };
};

const objSort = (obj) => {
  if (obj) {
    const orderedDates = {};
    Object.keys(obj)
      .sort((a, b) => {
        a = moment(a, 'YYYY-MM-DD');
        b = moment(b, 'YYYY-MM-DD');
        return a < b ? -1 : 1;
      })
      .forEach((key) => {
        orderedDates[key] = obj[key];
      });
    return orderedDates;
  }
  return obj;
};

export const modifySpTableData = (rec) => {
  const tempArr = [];
  const dataArr = [];
  let maxValue = 0;
  const data = rec.ec2HeatMapStatistics ? objSort(rec.ec2HeatMapStatistics) : {};
  for (let i = 0; i < 24; i++) {
    dataArr.push({ name: `${i}:00` });
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    // eslint-disable-next-line no-loop-func
    value.forEach((row, index) => {
      // eslint-disable-next-line no-unused-expressions
      tempArr[index]
        ? tempArr[index].push({ x: key, y: Number(row).toFixed(2) })
        : (tempArr[index] = [{ x: key, y: Number(row).toFixed(2) }]);
      if (Number(row) > maxValue) {
        maxValue = Number(row).toFixed(2);
      }
    });
    dataArr.map((row, index) => {
      // eslint-disable-next-line no-param-reassign
      row.data = tempArr[index];
      return row;
    });
  }
  return { dataArr, maxValue };
};
