import { useMemo } from 'react';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';

const useFormattedLinkedAccounts = (cloudAccountsLinkedAccount) => {
  const { usersStore } = useRootStore();
  return useMemo(() => {
    const copy = new Map(JSON.parse(JSON.stringify(Array.from(cloudAccountsLinkedAccount))));
    const accounts = usersStore.getAllCurrDisplayedUserAccounts();
    [...copy.entries()].forEach(([key, value]) => {
      const account = accounts.find((acc) => acc.accountId === key);
      if (account && account.cloudTypeId === CLOUD_TYPE_IDS.AWS && !value.some((ln) => ln.linkedAccountId === key)) {
        value.unshift({
          linkedAccountId: key,
          linkedAccountName: account.accountName.split('(')[0] || key,
        });
      }
    });
    return copy;
  }, [cloudAccountsLinkedAccount]);
};

export default useFormattedLinkedAccounts;
