import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { kFormatter } from 'shared/utils/strUtil';
import { mapServiceNameToColor } from 'shared/constants/awsConstants';
import CustomTooltip from 'shared/components/CustomTooltip';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const getLabelFontSize = (width) => {
  if (width < 150) {
    return 14;
  }
  if (width < 300) {
    return 20;
  }
  return 36;
};

const AreaWithAvg = ({ dataLabelKey, data, title, service, overrideCurrency }) => {
  const ref = React.useRef();
  const { getCurrencyNumber } = useUserSettingsContext();
  const [width, setWidth] = useState(null);
  const updateWidth = () => {
    const newWidth = ref.current.clientWidth;
    setWidth(+newWidth);
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    updateWidth();
  }, [(ref.current || {}).clientWidth]);
  useEffect(() => {
    window.addEventListener('resize', updateWidth, false);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  if (!data || !data.length) {
    return null;
  }
  const getAvgVal = () => data.reduce((acc, currData) => acc + currData[dataLabelKey], 0) / data.length;
  // 1.32:1 its scale size of half-pie-chart panel, align this panel to that height
  const height = (width || 0) / 1.32;
  return (
    <div ref={ref} className="d-flex justify-content-end flex-column" style={{ height }}>
      <p className="m-0">{title}</p>
      <div className="d-flex">
        <div className="w-25 d-flex flex-column" style={{ minWidth: 'max-content' }}>
          <p
            style={{ color: '#000000', fontSize: getLabelFontSize(width) }}
            className="mb-0 mt-auto h1 font-weight-normal text-nowrap"
          >
            {getCurrencyNumber(kFormatter(getAvgVal(), 3), 2, {}, overrideCurrency)}
          </p>
        </div>
        <div className="w-75">
          <ResponsiveContainer height={height - 20}>
            <AreaChart height={height - 20} data={data}>
              <Tooltip
                content={
                  <CustomTooltip
                    data={data}
                    hideTotal
                    currKey={dataLabelKey}
                    displayedMetric={DisplayMetricTypes.COST}
                    decimalPoints={3}
                    overrideCurrency={overrideCurrency}
                  />
                }
              />
              <Area
                strokeWidth={2}
                type="monotone"
                fillOpacity={0.2}
                dataKey={dataLabelKey}
                stroke={mapServiceNameToColor.get(service)}
                fill={mapServiceNameToColor.get(service)}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

AreaWithAvg.propTypes = {
  title: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  dataLabelKey: PropTypes.string.isRequired,
  overrideCurrency: PropTypes.string,
};

AreaWithAvg.defaultProps = {
  overrideCurrency: null,
};
export default AreaWithAvg;
