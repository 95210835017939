export const prepareParamsForNewOppCustomDashboardPanel = (allParams) => {
  const {
    recommType,
    filterSelectedValues,
    currPeriodGranLevel,
    isShowActualCost,
    yearlyTotlaCost,
    recommTypeSelectHandler,
  } = allParams;
  const prepareRouteParamsObj = {
    recommType,
    filterSelectedValues,
    currPeriodGranLevel,
    isShowActualCost,
    yearlyTotlaCost,
    recommTypeSelectHandler,
    isSelectTypeActive: false,
  };
  return prepareRouteParamsObj;
};

export const prepareBaseParams = (baseParams) => {
  const baseParamObj = {
    newDashboardName: baseParams.customDashboardName,
    existingDashboardUuid: baseParams.existingDashboard && baseParams.existingDashboard.value,
    panelName: baseParams.customDashboardPanelName,
    type: baseParams.type,
  };
  return baseParamObj;
};
export const preparePeriodParams = (periodParams) => {
  const baseParamObj = {
    periodType: 'relativeDate',
    relativeDatesDaysDiff: periodParams.relativeDatesPeriod,
    granularity: periodParams.granularity,
  };
  return baseParamObj;
};

export const createNewCustomDashboardPanelObj = (baseParams, periodParams, routeParams) => {
  const preparedObj = { ...baseParams, periodParams: { ...periodParams }, routeParams: { ...routeParams } };
  return preparedObj;
};

export const prepareRecsSummeryTableData = (data) => data;
