import React, { useMemo, useState } from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  GenerateIcon,
  ICONS,
  SelectMulti,
} from '@pileus-cloud/anodot-frontend-common';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import Button from 'shared/components/andtComponents/Button';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import DateFilter from 'shared/modules/dateFilter';
import { getMonthsBeforeToday } from 'shared/utils/dateUtil.js';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { isRoleIdOfAdminType } from 'users/utils/rolesUtil';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import useRebilling from 'divisions/hooks/react-query/useRebilling';
import styles from '../CustomersTable/CustomersTable.module.scss';

const RebillingPopover = ({ selectedRows, roles, setSelectedRows, customers }) => {
  const { usersStore } = useRootStore();
  const lastProcessTime = DateFilter.getDate();
  const [rebillingModalOpen, setRebillingModalOpen] = useState(null);
  const [rebillingSelectedMonth, setRebillingSelectedMonth] = useState([moment(lastProcessTime).format('YYYY-MM')]);
  const { triggerRebillingMutation } = useRebilling();
  const selectedCustomers = selectedRows.map((ind) => customers[ind]);
  const rebillingDisabled = useMemo(() => {
    return (
      selectedCustomers.some(c => Object.values(c.history).some(
        (row) =>
          row.type === 'Manual' &&
          moment(row.date).add(8, 'hours').isAfter(moment()),
      ))
    );
  }, [selectedCustomers]);
  const handleTriggerRebilling = async () => {
    if (!rebillingSelectedMonth.length) {
      return
    }
    const customersNames = selectedRows.map((ind) => customers[ind].customerId);
    await triggerRebillingMutation.mutateAsync({
      customers: customersNames,
      months: rebillingSelectedMonth,
    });
    setRebillingModalOpen(null);
    setSelectedRows([]);
  };
  const rebillingMonthsOptions = useMemo(() => getMonthsBeforeToday(lastProcessTime, 3), [lastProcessTime]);
  const isAdmin = isRoleIdOfAdminType(roles || [], usersStore.getCurrDisplayedUserRole());
  const renderRebillingPopover = (open, id) => (
    <Popover
      open={open}
      onOpenChange={() => {
        if (open || rebillingDisabled) {
          setRebillingModalOpen(null);
        } else {
          setRebillingModalOpen(id);
        }
      }}
    >
      <PopoverTrigger as="button">
        <Tooltip
          title="Some of the selected customers were triggerred in the last 8 hours"
          disabled={!rebillingDisabled}
        >
          <span>
            <Button
              isTextButton
              disabled={rebillingDisabled}
              text="Add Trigger Rebilling"
              icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
              onClick={() => {}}
            />
          </span>
        </Tooltip>
      </PopoverTrigger>
      <div className={styles.rebillingModal}>
        <PopoverContent className={styles.rebillingModalContent} side="bottom" sideOffset={1}>
          <div>
            <p className={styles.label}>Select Month</p>
            <SelectMulti
              hideFooter
              height={120}
              hideTooltip
              label={rebillingSelectedMonth.length ? rebillingSelectedMonth.map(a => rebillingMonthsOptions.find(m => m.value === a).label).join(',') : 'Select Month'}
              options={rebillingMonthsOptions}
              handleSelectionChange={(vals) => {
                setRebillingSelectedMonth(vals.map(v => v.value));
              }}
              selected={rebillingSelectedMonth.map(s => ({
                label: rebillingMonthsOptions.find(m => m.value === s).label,
                value: s,
              }))}
            />
            <p>
              <span>
                <InfoIcon /> Please note:
              </span>
              New data will be added as the re-billing process runs on the data consumed for that month from the payer
              account.
              <br />
              Running re-billing might cause inconsistencies between the data the user sees in the platform to the
              invoices that were already issued
            </p>
            <div className={styles.rebillingModalFooter}>
              <Button text="Cancel" isSecondary onClick={() => setRebillingModalOpen(null)} />
              <Button text="Apply" onClick={handleTriggerRebilling} isLoading={triggerRebillingMutation.isLoading} />
            </div>
          </div>
        </PopoverContent>
      </div>
    </Popover>
  );

  return (
    <ReadOnlyDisplayWrapper
      isHide={false}
      block={!isAdmin}
      category={OrganizationEntityCategory.ResellerCustomers}
      action={Action.Update}
    >
      <div className={styles.rebillingContainer}>
        {rebillingDisabled ? (
          <div className="d-flex align-items-center">
            <Button
              text={'Add Trigger Rebilling'}
              icon={() =>
                  <GenerateIcon iconName={ICONS.plus.name} />
              }
              disabled
              isTextButton
              onClick={() => {}}
            />
            <InfoPopover>
              You will be able to trigger another re-billing within 8 hours from the last triggering.
            </InfoPopover>
          </div>
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            {renderRebillingPopover(rebillingModalOpen === selectedCustomers[0]?.customerId, selectedCustomers[0]?.customerId)}
          </div>
        )}
      </div>
    </ReadOnlyDisplayWrapper>
  );
};

RebillingPopover.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
};

export default withRolesContextConsumer(RebillingPopover);
