import { useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { AWS_RECOMMENDATIONS_ROOT, BILLINGS_API_NAME } from 'recommendations/hooks/react-query/api';
import { queryClient } from 'queryClient';
import { prepareRecommendations, DEFAULT_PAGE_SIZE } from 'recommendations/hooks/react-query/recommendationHelpers';

const fetchRecommendationsHistoryData = async (pageSize, startDate, endDate, limit, historyRecLastPageKey) => {
  const pageKey = historyRecLastPageKey === 'start' ? null : historyRecLastPageKey;
  const body = { lastEvaluatedKey: pageKey, pageSize, limit, startDate, endDate };
  const result = await API.post(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/i/history`, { body });
  return {
    startDate,
    endDate,
    lastPageKey: result.lastPageKey,
    recommendationsHistory: prepareRecommendations(result.data),
  };
};

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const updateHistoryRecommendationStatus = async (recs) => {
  const body = { recs };
  await API.del('billings', '/api/v1/recommendations/history/update-status/revert', { body });
};

export default function useRecommendationsHistory({
  pageSize = DEFAULT_PAGE_SIZE,
  limit = null,
  startDate = null,
  endDate = null,
  historyRecLastPageKey = 'start',
}) {
  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_HISTORY,
    pageSize,
    startDate,
    endDate,
    limit,
    historyRecLastPageKey,
  ];

  return {
    invalidate: (params) => queryClient.invalidateQueries([apiConstants.QUERY_KEYS.RECOMMENDATIONS_HISTORY, ...params]),
    reset: (params) => queryClient.resetQueries([apiConstants.QUERY_KEYS.RECOMMENDATIONS_HISTORY, ...params]),
    fetchRecommendationsHistory: () =>
      useQuery(
        queryKey,
        () => fetchRecommendationsHistoryData(pageSize, startDate, endDate, limit, historyRecLastPageKey),
        {
          retry: false,
        },
      ),
    updateHistoryRecommendationStatus: async (params) => {
      await updateHistoryRecommendationStatus(params);
      // There is a Backend bug that response of the function above is back before the recommendation status
      // is actually updated in the DB. Usually, immediate invalidation of History will not bring the updated result.
      // Thus need to wait.
      await sleep(3000);
      await queryClient.invalidateQueries([apiConstants.QUERY_KEYS.RECOMMENDATIONS_HISTORY]);
    },
  };
}
