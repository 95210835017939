import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { MoreVertical } from 'react-feather';
import DismissRecModal from 'recommendations/shared/components/DismissRecResModal';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { LabelActionTypes } from '../../../constants/recommendationsConstants';

class RecActionButton extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    segmentEvent({ target: 'recommendationsTableActionsList' });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAction = (action) => {
    segmentEvent({ target: `recommendationsTableActions-${action}` });
  };

  renderLabelActions = () => {
    const { rec, recHandlers } = this.props;
    const isLabelExists = !!rec.labels;
    return isLabelExists ? (
      <>
        <Divider />
        <MenuItem
          onClick={() => {
            recHandlers.recLabelHandler([rec], LabelActionTypes.EDIT);
            this.handleAction('EditLabel');
          }}
          className="material-table__filter-menu-item"
        >
          <h6>Edit Label</h6>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            recHandlers.recLabelHandler([rec], LabelActionTypes.REMOVE);
            this.handleAction('RemoveLabel');
          }}
          className="material-table__filter-menu-item"
        >
          <h6>Remove Label</h6>
        </MenuItem>
      </>
    ) : (
      <>
        <Divider />
        <MenuItem
          onClick={() => {
            recHandlers.recLabelHandler([rec], LabelActionTypes.ADD);
            this.handleAction('AddLabel');
          }}
          className="material-table__filter-menu-item"
        >
          <h6>Add Label</h6>
        </MenuItem>
      </>
    );
  };

  render() {
    const { anchorEl } = this.state;
    const { rec, recHandlers, cloudTypeId, isMondayFeatureEnabled } = this.props;
    const { recCompletedHandler, recExcludeHandler, addRecToMonday } = recHandlers;
    return (
      <div>
        <IconButton
          className="material-table__toolbar-button"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          size="large">
          <MoreVertical size={22} color="black" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          <MenuItem
            onClick={() => {
              recCompletedHandler(rec.key);
              this.handleAction('Done');
            }}
            className="material-table__filter-menu-item"
          >
            <h6>Done</h6>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              this._dismissRecModal.toggle();
              this.handleAction('Exclude');
            }}
            className="material-table__filter-menu-item"
          >
            <h6>Exclude...</h6>
          </MenuItem>
          {this.renderLabelActions()}
          {isMondayFeatureEnabled ? <Divider /> : null}
          {isMondayFeatureEnabled ? (
            <MenuItem
              onClick={() => {
                addRecToMonday([rec]);
                this.handleAction('AddToMonday');
              }}
              className="material-table__filter-menu-item"
            >
              <h6>Add to Monday.com board</h6>
            </MenuItem>
          ) : null}
        </Menu>
        <DismissRecModal
          rec={rec}
          recExcludeHandler={recExcludeHandler}
          // afterActionHandler={this.props.afterActionHandler}
          cloudTypeId={cloudTypeId}
          ref={(dismissRecModal) => {
            this._dismissRecModal = dismissRecModal;
          }}
        />
      </div>
    );
  }
}

export default RecActionButton;
RecActionButton.propTypes = {
  recHandlers: PropTypes.object.isRequired,
  rec: PropTypes.object.isRequired,
  cloudTypeId: PropTypes.number.isRequired,
  isMondayFeatureEnabled: PropTypes.bool,
};

RecActionButton.defaultProps = {
  isMondayFeatureEnabled: false,
};
