import React from 'react';
import { ClarityLogo } from '@pileus-cloud/anodot-frontend-common';
import { DEFAULT_THEME } from 'app/containers/App/components/CustomThemeApplier';
import { useRootStore } from 'app/contexts/RootStoreContext';
import HolidayLogo from 'shared/components/HolidayThemedIcons/HolidayLogo';

const AppLogo = () => {
  const { usersStore } = useRootStore();
  const currUserTheme = usersStore.getCurrDisplayedUserTheme();
  if (!currUserTheme) {
    return <div />;
  }
  if (currUserTheme === DEFAULT_THEME) {
    return usersStore.currentHoliday ? (
      <div className="h-100 d-flex align-items-center">
        <HolidayLogo holiday={usersStore.currentHoliday} />
      </div>
    ) : (
      <ClarityLogo />
    );
  }
  return <div id="header-logo-main" style={{ height: 34 }} />;
};

export default AppLogo;
