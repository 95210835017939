import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from 'shared/components/PageHeader';
import If from 'shared/components/SimpleIf';
import Panel from 'shared/components/Panel';
import { Routes } from 'shared/constants/routes';
import RecActionFactory from 'recommendations/helpers/factories/RecActionFactory';
import RecContentFactory from 'recommendations/helpers/factories/RecContentFactory';
import RecStatisticsFactory from 'recommendations/helpers/factories/RecStatisticsFactory';
import RecDescriptionFactory from 'recommendations/helpers/factories/RecDescriptionFactory';
import { Action as OrganizationAction, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { ContentPageTypes, RecommFieldsAndFiltersValues } from 'recommendations/constants/recommendationsConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { useRecommendationByType } from 'recommendations/hooks/react-query/useRecommendationByType';
import useRecommendationsHistory from 'recommendations/hooks/react-query/useRecommendationsHistory';
import MarkRecAsDoneButton from './MarkRecAsDoneButton';

const recommendation = ({ Content, Description, Statistics, Action }) => {
  const Recommendation = ({ rec, recHandler, backToPage, state, recHelpers }) => {
    const renderBackToRecommendationLink = () => {
      const currentSearchParams = new URLSearchParams(window.location.search);
      const params = Array.from(currentSearchParams.entries())
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      return (
        <div>
          <Link
            style={{ color: '#5AAAFD', 'font-size': '12.5px', 'font-weight': 'strong' }}
            to={{
              search: params,
              pathname: backToPage,
              state,
            }}
          >
            Back
          </Link>
        </div>
      );
    };
    return (
      <Container>
        <PageHeader title="Recommendation" showDate />
        <Row>
          <Panel
            label={rec.status}
            lg={11}
            xl={11}
            md={11}
            title={rec.title}
            divider
            subhead={renderBackToRecommendationLink()}
            panelClass="dashboard__booking-reservations-panel"
          >
            <div className="project-summary fs-exclude">
              {/* <div className="project-summary fs-exclude" style={{ 'white-space': 'pre' }}> */}
              <Content rec={rec} recHelpers={recHelpers} contentPageType={ContentPageTypes.RECS_PAGE} />
              {/* <Content rec={rec} recHelpers={recHelpers} contentPageType={ContentPageTypes.RECS_GENERAL} /> */}
              <Description rec={rec} />
              {Statistics && (
                <>
                  <hr />
                  <Statistics rec={rec} />
                </>
              )}
              {Action && (
                <>
                  <hr />
                  <Action recHandler={recHandler} rec={rec} />
                </>
              )}
              <If cond={rec.status === RecommFieldsAndFiltersValues.OPEN}>
                <hr />
                <ReadOnlyDisplayWrapper
                  isHide={false}
                  category={OrganizationEntityCategory.Organization}
                  action={OrganizationAction.Update}
                >
                  <div style={{ float: 'right' }}>
                    <MarkRecAsDoneButton rec={rec} recHandler={recHandler} backToPage={backToPage} />
                  </div>
                </ReadOnlyDisplayWrapper>
              </If>
            </div>
          </Panel>
        </Row>
      </Container>
    );
  };

  Recommendation.propTypes = {
    rec: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    recHandler: PropTypes.func.isRequired,
    backToPage: PropTypes.string.isRequired,
    recHelpers: PropTypes.object.isRequired,
  };

  return Recommendation;
};

const RecommendationBuilder = ({ location, usersStore }) => {
  const { currentDisplayedUserType, usersModel } = usersStore;
  const { mapLinkedAccIdToDivisionName } = usersModel;

  const recommendationsHook = useRecommendationByType({
    isEnabled: usersStore.currentDisplaydUser?.isPayingUser,
    recommendationType: null,
    currentDisplayedUserType,
    mapLinkedAccIdToDivisionName,
  });
  const recommendationDataHook = recommendationsHook.fetchRecommendationsByTypeData();
  const { data: recommendations } = recommendationDataHook;

  const params = new URLSearchParams(location.search);
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');

  const historyHook = useRecommendationsHistory({
    startDate,
    endDate,
    limit: 'all',
  });
  const recommendationsHistoryDataHook = historyHook.fetchRecommendationsHistory();
  const recommendationsHistory = recommendationsHistoryDataHook?.data?.recommendationsHistory;

  const recommendationFromPath = useMemo(() => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const recommendationKey = pathParts[pathParts.length - 1];

    let recommendation = recommendations?.find((rec) => recommendationKey === rec.key);
    if (!recommendation) {
      recommendation = recommendationsHistory?.find((rec) => recommendationKey === rec.key);
    }
    return recommendation;
  }, [recommendations, recommendationsHistory, window.location.pathname]);

  if (!recommendationFromPath) {
    return null;
  }

  const recHandler = (recommendationKeys, recommendationType) =>
    recommendationsHook.completeRecommendationByKeys(recommendationKeys, recommendationType);

  let backToPage = Routes.RECOMMENDATIONS;
  if (location.backToPage) {
    // eslint-disable-next-line prefer-destructuring
    backToPage = location.backToPage.backToPage;
  }
  const { state } = location;
  const Content = RecContentFactory.create(recommendationFromPath);
  const Statistics = RecStatisticsFactory.create(recommendationFromPath);
  const Action = RecActionFactory.create(recommendationFromPath);
  const Description = RecDescriptionFactory.create(recommendationFromPath);

  const Recommendation = recommendation({
    Content,
    Description,
    Statistics,
    Action,
  });
  return <Recommendation rec={recommendationFromPath} recHandler={recHandler} backToPage={backToPage} state={state} />;
};

RecommendationBuilder.propTypes = {
  location: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
};

const ObserverRecommendationBuilder = observer(RecommendationBuilder);
export default ObserverRecommendationBuilder;
