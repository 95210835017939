import { Table, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import PropTypes from 'prop-types';

const TableWrapper = ({ containerStyle = {}, horizontalScroll = true, virtual = false, ...props }) => {
  const ContainerComponent = (containerProps) => (
    <Table.Container
      style={{ ...containerStyle }}
      className={horizontalScroll ? 'show-scroll show-scroll-horizontal table-wrapper' : 'table-wrapper'}
      {...containerProps}
    />
  );
  const TableComponentBase = ({ ...restProps }) => <Table.Table {...restProps} />;
  return (
    <>
      {virtual ? (
        <VirtualTable {...props} tableComponent={TableComponentBase} />
      ) : (
        <Table {...props} tableComponent={TableComponentBase} containerComponent={ContainerComponent} />
      )}
    </>
  );
};

TableWrapper.propTypes = {
  containerStyle: PropTypes.objectOf(PropTypes.any),
  horizontalScroll: PropTypes.bool,
  virtual: PropTypes.bool,
  height: PropTypes.number,
};
TableWrapper.defaultProps = {
  containerStyle: {},
  horizontalScroll: false,
  virtual: false,
  height: 530,
};

export default TableWrapper;
