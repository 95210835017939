import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { ENGINE_PROPERTIES } from '../../recommendationPropertyUtils';

const ELASTICACHE_UTIL_LOW_PROPERTIES = {
  STATUS: {
    label: 'Status',
    getterFunction: (recommendation) => recommendation?.recData?.status,
  },
  NODE_TYPE: {
    label: 'Node Type',
    getterFunction: (recommendation) => recommendation?.recData?.node_type,
  },
  NUM_OF_NODES: {
    label: 'Number of Nodes',
    getterFunction: (recommendation) => recommendation?.recData?.num_nodes,
  },
  REPLICATION_GROUP_ID: {
    label: 'Replication Group Id',
    getterFunction: (recommendation) => recommendation?.recData?.replication_group_id,
  },
};
const ElastiCacheUtilLow = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'List the current multipart objects:',
          // eslint-disable-next-line max-len
          actionCommand: `aws elasticache delete-cache-cluster --cache-cluster-id ${recommendation?.recData?.replication_group_id} --region ${recommendation?.region}`,
        },
      ],
    },
  ];

  const description =
    // eslint-disable-next-line max-len
    'The Elasticache service has been underutilized for the past 7 days. We recommend terminating this service.';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...ENGINE_PROPERTIES, ...ELASTICACHE_UTIL_LOW_PROPERTIES }}
    />
  );
};

ElastiCacheUtilLow.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default ElastiCacheUtilLow;
