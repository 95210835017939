/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the AWS CLI:</li>
      <b>Note that as long as the keys are pending deletion, you can reverse this action.</b>
      <br />
      <div>
        <a
          href="https://docs.aws.amazon.com/kms/latest/developerguide/deleting-keys.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          For more information
        </a>
      </div>
      <CodeBlock
        text="aws kms schedule-key-deletion --key- {{resourceId}} --pending-window-in-days {{days}}"
        variables={{
          resourceId: rec.resourceId,
          days: 'NUM_BTWEEN_7_TO_30',
        }}
      />
      <br />
      <b>To reverse the deletion you may use the following command:</b>
      <CodeBlock
        text="aws kms cancel-key-deletion --key-id {{resourceId}}"
        variables={{
          resourceId: rec.resourceId,
        }}
      />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
