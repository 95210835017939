import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import Spinner from 'shared/components/andtComponents/Spinner';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { PreferencesTypes } from 'users/constants/usersConstants';
import { usePreferences } from 'users/hooks/react-query/usePreferences';
import PreferencesTable from './PreferencesTable';
import { RecommendationPreference } from './types.ts';
import toast from '../../../shared/components/andtComponents/Toast';
import { typeFormatter } from './utils.ts';

type Props = {
  usersStore: {
    currDispUserAccountKey: string;
    getCurrentDisplayedUserType: string;
  };
  history: any;
  location: any;
};
const RecommendationsPreferences: React.FC<Props> = ({ usersStore, history, location }) => {
  const { fetchPreferences, useSaveMutation } = usePreferences();
  const saveMutation = useSaveMutation();
  const { data: preferences = [] as RecommendationPreference[], isLoading } = fetchPreferences(
    usersStore.currDispUserAccountKey,
  );

  const deleteSearchParam = (paramName = '') => {
    const currentSearchParams = new URLSearchParams(location.search);
    const existingSearch = currentSearchParams.get(paramName);
    if (existingSearch) {
      currentSearchParams.delete(paramName);
      history.push({ search: currentSearchParams.toString() });
    }
  };

  const onPreferenceExpand = useCallback(() => {
    deleteSearchParam('prefId');
  }, [history, location]);

  const setSearchToUrl = useCallback(() => {
    deleteSearchParam('search');
  }, [history, location]);

  if (isLoading) {
    return <Spinner />;
  }

  const preferencesFiltered = (preferences || [])
    .filter((p) => p.preferenceType === PreferencesTypes.RECOMMENDATION && !p.preferences.isPreferencesHidden)
    .map((p) => ({ ...p, formattedTitle: typeFormatter(p) }))
    .sort((a, b) => a.formattedTitle.localeCompare(b.formattedTitle));

  const handleSavePreferences = async (preferencesToSave: RecommendationPreference[]) => {
    await saveMutation.mutateAsync(preferencesToSave);
    toast.success('Changes saved');
  };

  return (
    <Container>
      <PageHeader title={PageNames.RECOMMENDATIONS_PREFERENCES} />
      <PreferencesTable
        onPrefClicked={onPreferenceExpand}
        onSearch={setSearchToUrl}
        preferences={preferencesFiltered}
        handleSave={handleSavePreferences}
        currentUserType={usersStore.getCurrentDisplayedUserType}
      />
    </Container>
  );
};

const ObserverRecommendationsPreferences = observer(RecommendationsPreferences);
export default ObserverRecommendationsPreferences;
