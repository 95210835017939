import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ReactComponent as EditButton } from 'shared/img/icons/edit.svg';
import { MULTI_ACCOUNTS_RESELLER_CUSTOMER_ROLES_TABLE_COLUMNS } from '../anodotAdminPanel.helpers';
import SyncButton from './SyncButton';
import styles from './multiAccountCustomerRolesTable.module.scss';

const propTypes = {
  rows: PropTypes.array,
  onEditClick: PropTypes.func.isRequired,
  onSyncClick: PropTypes.func.isRequired,
};

const defaultProps = {
  rows: [],
};

const MultiAccountCustomerRolesTable = ({ rows, onEditClick, onSyncClick }) => (
  <Grid rows={rows} columns={MULTI_ACCOUNTS_RESELLER_CUSTOMER_ROLES_TABLE_COLUMNS}>
    <SortingState
      defaultSorting={[{ columnName: 'sync', direction: 'desc' }]}
      columnExtensions={[
        {
          columnName: 'sync',
          sortingEnabled: false,
        },
      ]}
    />
    <IntegratedSorting />
    <DataTypeProvider
      for={['sync']}
      formatterComponent={({ value }) => <SyncButton value={value} onClick={onSyncClick} />}
    />
    <DataTypeProvider
      for={['editButton']}
      formatterComponent={({ row }) =>
        row.linkedDivisions ? <EditButton className={styles['edit-button']} onClick={() => onEditClick(row)} /> : null
      }
    />
    <TableWrapper />
    <TableHeaderRow />
  </Grid>
);

MultiAccountCustomerRolesTable.propTypes = propTypes;
MultiAccountCustomerRolesTable.defaultProps = defaultProps;
export default MultiAccountCustomerRolesTable;
