/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

const TextField = (props) => (
  <div>
    <label htmlFor={props.name}>{props.label}</label>
    <input
      className="insert-budget-input"
      type="text"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeHolder={props.placeHolder}
      disabled={props.disabled}
      autoComplete={props.autoComplete}
    />
  </div>
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
};
TextField.defaultProps = {
  disabled: false,
  autoComplete: 'off',
};

export default TextField;
