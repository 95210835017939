import React, { useMemo } from 'react';
import { isDecimalNeeded, strNumToSize } from 'shared/utils/strUtil';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { Y_AXIS_UNIT } from './chartConsts';

import classes from './genericChart.module.scss';

export const CustomizedTooltip = ({ active, payload, turnedOffLines, clickedLineId, yAxisUnit }) => {
  const getTooltipFormat = (value) => {
    if (value === null || value === undefined) {
      return '-';
    }
    let tooltip = '';
    switch (yAxisUnit) {
      case Y_AXIS_UNIT.PERCENT:
        tooltip = `${value.toFixed(isDecimalNeeded(value, 2) ? 2 : 0)} %`;
        break;
      case Y_AXIS_UNIT.BYTE:
        tooltip = strNumToSize(value);
        break;
      case Y_AXIS_UNIT.MILICPU:
        tooltip = `${value.toFixed(isDecimalNeeded(value, 2) ? 2 : 0)} milliCores`;
        break;
      case Y_AXIS_UNIT.OTHER:
      default:
        tooltip = value.toFixed(isDecimalNeeded(value, 2) ? 2 : 0);
        break;
    }
    return tooltip;
  };
  const turnedOnLines = useMemo(() => {
    let lines = payload?.filter((entry) => !turnedOffLines?.includes(`${entry.dataKey}Legend`));
    if (clickedLineId) {
      const clickedLine = lines?.filter((entry) => entry.id === clickedLineId);
      if (clickedLine) {
        lines = clickedLine;
      }
    }
    lines.sort((a, b) => b.value - a.value);
    return lines;
  }, [payload, turnedOffLines, clickedLineId]);

  if (active && payload?.length && turnedOnLines?.length) {
    return (
      <div className={classes.customizedTooltip}>
        <div className={classes.tooltipTitleContainer}>
          <GenerateIcon iconName={ICONS.calendarDay.name} />
          <div className={classes.tooltipTitle}>{payload[0].payload.usageDate}</div>
        </div>
        <div className={classes.tooltipDataContainer}>
          {turnedOnLines?.map((entry) => (
            <div className={classes.tooltipDataPair}>
              <div className={classes.tooltipDataCircle} style={{ backgroundColor: entry.color }} />
              <div className={classes.tooltipData}>
                {`${entry.name}:`}
                <span className={classes.tooltipDataValue}> {`${getTooltipFormat(entry.value)}`}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};
