import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ThreeStateSwitchButton } from 'recommendationsNew/components/shared/threeStateSwitchButton';

import classes from './switchButtonsContainer.module.scss';

export const SwitchButtonsContainer = ({ onFiltersChange, filtersSearchString, threeStateSwitchButtons }) => {
  const displayedSwitchButtons = useMemo(
    () =>
      Object.values(threeStateSwitchButtons)?.filter((option) =>
        option.switchLabel?.toLowerCase().includes(filtersSearchString?.toLowerCase()),
      ),
    [filtersSearchString, threeStateSwitchButtons],
  );

  return (
    <div className={classes.switchButtonsContainer}>
      {Object.values(displayedSwitchButtons).map((switchButton) => (
        <ThreeStateSwitchButton
          automationId={`heatmap-${switchButton.switchLabel}-switch-button-filter`}
          switchLabel={switchButton.switchLabel}
          infoIconTooltipText={switchButton.infoIconTooltipText}
          icon={switchButton.icon}
          isDisabled={switchButton.isDisabled}
          changeState={(event) => {
            const eventData = { ...event, filter: switchButton.switchLabel };
            return onFiltersChange(eventData);
          }}
          optionsList={Object.values(switchButton.optionsList)}
        />
      ))}
    </div>
  );
};

SwitchButtonsContainer.propTypes = {
  filtersSearchString: PropTypes.string,
  onFiltersChange: PropTypes.func.isRequired,
  threeStateSwitchButtons: PropTypes.object.isRequired,
};

SwitchButtonsContainer.defaultProps = {
  filtersSearchString: '',
};
