/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
import RecsStatsCenter from 'recommendations/modules/recsStatsCenter';
import {
  mapRecommendationsTypeToDisplay,
  RecommendationTypes,
} from 'recommendations/constants/recommendationsConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

export const prepSummayTable = (summaryData, forecastedMonthEndTotalCost) => {
  const summaryEntry = { type: 'total', typeDisplay: 'Total', savings: 0, quantity: 0 };
  const data = summaryData.map((item) => {
    const savings = +item.savings * 12;
    const quantity = +item.count || 0;
    summaryEntry.savings += savings;
    summaryEntry.quantity += quantity;
    const dataEntry = {
      quantity,
      // completed: `${completedRecomms}/${totalRecomms}`,
      savings,
      percentOfTotal: forecastedMonthEndTotalCost
        ? `${((savings / forecastedMonthEndTotalCost) * 100).toFixed(1)} %`
        : '0 %',
      typeDisplay: mapRecommendationsTypeToDisplay.get(item.type),
      type: item.type,
      recommType: item.type,
    };
    return dataEntry;
  });
  summaryEntry.percentOfTotal = forecastedMonthEndTotalCost
    ? `${((summaryEntry.savings / forecastedMonthEndTotalCost) * 100).toFixed(1)} %`
    : '0 %';
  data.unshift(summaryEntry);
  return data;
};
export const prepareSummaryTableData = (recommendations, forecastedMonthEndTotalCost) => {
  const data = [];
  const recommTypes = Object.values(RecommendationTypes);
  const summaryEntry = { type: 'total', typeDisplay: 'Total', savings: 0, quantity: 0 };
  for (let i = 0; i < recommTypes.length; i++) {
    const recommType = recommTypes[i];
    const typeFilterRecomms = RecsStatsCenter.getFilteredRecsByType(recommendations, recommType);
    if (typeFilterRecomms.length === 0) {
      continue;
    }

    const incompletedFilterRecomms = RecsStatsCenter.getFilteredCompletedRecs(typeFilterRecomms, false);
    const completedFilterRecomms = RecsStatsCenter.getFilteredCompletedRecs(typeFilterRecomms, true);
    const currSavingAmount =
      RecsStatsCenter.calcActualCost(completedFilterRecomms) -
      RecsStatsCenter.calcPotentialCost(completedFilterRecomms);
    const totalSavingAmount =
      currSavingAmount +
      (RecsStatsCenter.calcActualCost(incompletedFilterRecomms) -
        RecsStatsCenter.calcPotentialCost(incompletedFilterRecomms));
    // const completedRecomms = completedFilterRecomms.length;
    const totalRecomms = typeFilterRecomms.length;
    const dataEntry = {
      quantity: totalRecomms,
      // completed: `${completedRecomms}/${totalRecomms}`,
      savings: totalSavingAmount,
      percentOfTotal: forecastedMonthEndTotalCost
        ? `${((totalSavingAmount / forecastedMonthEndTotalCost) * 100).toFixed(1)} %`
        : '0 %',
      typeDisplay: mapRecommendationsTypeToDisplay.get(recommType),
      type: recommType,
      recommType,
    };
    data.push(dataEntry);
    summaryEntry.savings += totalSavingAmount;
    summaryEntry.quantity += totalRecomms;
  }
  data.sort((a, b) => b.savings - a.savings);
  summaryEntry.percentOfTotal = forecastedMonthEndTotalCost
    ? `${((summaryEntry.savings / forecastedMonthEndTotalCost) * 100).toFixed(1)} %`
    : '0 %';
  data.unshift(summaryEntry);
  return data;
};

export const prepareFilterObj = (recommType = 'All', filterSelectedValuesMap = new Map()) => {
  const filterKeysToRecDataKey = {
    linkedaccid: 'linkedAccountId',
    customtags: 'resourceTags',
    accounttags: 'accountTags',
  };
  const filterObj = { type: [recommType] };
  const filterCritKeys = [...filterSelectedValuesMap.keys()];
  if (filterCritKeys && filterCritKeys.length) {
    filterCritKeys.forEach((key) => {
      try {
        filterObj[filterKeysToRecDataKey[key]] = Array.isArray(filterSelectedValuesMap.get(key))
          ? [...filterSelectedValuesMap.get(key).map(({ value }) => value)]
          : [filterSelectedValuesMap.get(key)].map(({ value }) => value);
      } catch (error) {
        filterObj[filterKeysToRecDataKey[key]] = [];
      }
    });
  }
  return filterObj;
};

export const prepareLinkAccountLabel = (currCloudType, isSingle) => {
  switch (currCloudType) {
    case CLOUD_TYPE_IDS.AWS:
      return isSingle ? 'Linked Account' : 'Linked Accounts';
    case CLOUD_TYPE_IDS.AZURE:
      return isSingle ? 'Subscription' : 'Subscriptions';
    case CLOUD_TYPE_IDS.GCP:
      return isSingle ? 'Project' : 'Projects';
    default:
      return isSingle ? 'Linked Account' : 'Linked Accounts';
  }
};
