import { sanitizeParam } from 'shared/utils/apiUtil';
import { API } from 'shared/utils/apiMiddleware';
import { alignedStartEndDateByGranLevel } from 'shared/utils/dateUtil';

// eslint-disable-next-line import/prefer-default-export
export const getCostAndUsageData = (
  groupBy,
  secondaryGroupBy = false,
  whereParams,
  startRaw,
  endRaw,
  periodGranLevel,
  filterParams,
  costType,
  isUsageCountNeeded = false,
  isShowAmortize = false,
  isApplyMargin = false,
  excludeFilterParams = '',
  likeFilterParams = '',
  isNetAmortize = false,
  isNetUnblended = false,
  isPublicCost = false,
  isDistributed = false,
  panelId,
  dashboardId,
  formatDates = false,
  filtersConfig = '',
  likeExcludeFilterParams = '',
  isRateUsageBased = false,
) => {
  const buildCostAndUsageQuery = () => {
    const { start: startDate, end: endDate } = formatDates
      ? alignedStartEndDateByGranLevel(startRaw, endRaw, periodGranLevel)
      : { start: startRaw, end: endRaw };
    const formattedGroupBy = sanitizeParam(groupBy);
    const formattedSecondaryGroupBy = secondaryGroupBy ? sanitizeParam(secondaryGroupBy) : secondaryGroupBy;
    const arrCostType = costType.map((ct) => `&costType=${ct}`).join('');
    const secondaryGroupByParam = formattedSecondaryGroupBy ? `&groupBy=${formattedSecondaryGroupBy}` : '';
    const usageCount = isUsageCountNeeded ? '&countNeeded=true' : '';
    const showAmortize = isShowAmortize ? '&isAmortized=true' : '';
    const netAmortize = isNetAmortize ? '&isNetAmortized=true' : '';
    const netUnblended = isNetUnblended ? '&isNetUnblended=true' : '';
    const publicCost = isPublicCost ? '&isPublicCost=true' : '';
    const isRateUsageBasedParam = isRateUsageBased ? '&isRateUsageBased=true' : '';
    const distributedCost = isDistributed ? '&isDistributed=true' : '';
    const applyMargin = isApplyMargin ? '&isPpApplied=true' : '';
    const panelIdQuery = panelId ? `&panelId=${panelId}` : '';
    const dashboardIdQuery = dashboardId ? `&dashboardId=${dashboardId}` : '';
    // eslint-disable-next-line max-len
    return `/api/v1/invoices/caui?groupBy=${formattedGroupBy}${secondaryGroupByParam}${whereParams}&startDate=${startDate}&endDate=${endDate}&periodGranLevel=${periodGranLevel}${arrCostType}${filterParams}${excludeFilterParams}${likeFilterParams}${likeExcludeFilterParams}${filtersConfig}${usageCount}${showAmortize}${netAmortize}${netUnblended}${publicCost}${distributedCost}${isRateUsageBasedParam}${applyMargin}${panelIdQuery}${dashboardIdQuery}`;
  };
  return API.get('billings', buildCostAndUsageQuery());
};
