/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>
        Using Azure Console:
        <NavLink
          rel="noopener noreferrer"
          target="_blank"
          href="https://docs.microsoft.com/en-us/azure/cost-management-billing/reservations/prepay-app-service"
        >
          Prepay for Azure App Service with reserved instance
        </NavLink>
      </li>
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
