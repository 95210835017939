import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const CustomSelect = ({
  value,
  label,
  onChange,
  options,
  multiple,
  selectStyle,
  IconComponent,
  automationId,
  ...props
}) => (
  <div automation-id={automationId}>
    <TextField
      select
      variant="outlined"
      fullWidth
      size="small"
      value={value}
      label={label}
      SelectProps={{
        multiple,
        MenuProps: {
          IconComponent,
          style: {
            maxHeight: 400,
            ...selectStyle,
          },
          getContentAnchorEl: null,
        },
      }}
      onChange={onChange}
      {...props}
    >
      {options.map(({ label: opLabel, value: opValue }) => (
        <MenuItem value={opValue} key={opValue}>
          {opLabel}
        </MenuItem>
      ))}
    </TextField>
  </div>
);
CustomSelect.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectStyle: PropTypes.object,
  multiple: PropTypes.bool,
  IconComponent: PropTypes.func,
  automationId: PropTypes.string,
};

CustomSelect.defaultProps = {
  label: null,
  selectStyle: {},
  multiple: false,
  IconComponent: null,
  automationId: '',
};

export default CustomSelect;
