import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import { FilterOption } from './types.ts';

export const ALL_FILERS_MAP: Record<string, FilterOption> = {
  CATEGORIES: { id: 'cat_id', label: 'Categories', icon: ICONS.memo.name },
  TYPES: { id: 'type_id', label: 'Types', icon: ICONS.list.name },
  SERVICES: { id: 'service', label: 'Services', icon: ICONS.cloud.name },
  PERIOD: { id: 'period', label: 'Period', icon: ICONS.cloud.name },
  ASSIGNEE: {
    id: 'assignee',
    label: 'Assignee',
    icon: ICONS.clipboardUser.name,
  },
  COST_CENTER: {
    id: 'costCenter',
    label: 'Cost Center',
    icon: ICONS.listTree.name,
  },
  CUSTOM_TAGS: {
    id: 'customtags',
    label: 'Custom Tags',
    icon: ICONS.tag.name,
  },
  BUSINESS_MAP: {
    id: 'business_map',
    label: 'Business Map',
    icon: ICONS.pieChart.name,
  },
  ENRICHMENT_TAGS: {
    id: 'enrichment_tags',
    label: 'Enrichment Tags',
    icon: ICONS.tags.name,
  },
  INSTANCE_TYPE: {
    id: 'instance_type',
    label: 'Instance Type',
    icon: ICONS.gearsRegular.name,
  },
  TYPE: {
    id: 'instancetype',
    label: 'Instance Type',
    icon: ICONS.gearsRegular.name,
  },
  LINKED_ACCOUNT_ID: {
    id: 'linked_account_id',
    label: 'Linked Account',
    icon: ICONS.link.name,
  },
  REGION: {
    id: 'region',
    label: 'Region',
    icon: ICONS.earth.name,
  },
  RECOMMENDED_INSTANCE_TYPE: {
    id: 'recommended_instance_type',
    label: 'Recommended Instance Type',
    icon: ICONS.gearsRegular.name,
  },
  RESOURCE_ID: {
    id: 'resource_id',
    label: 'Resource Name',
    icon: ICONS.server.name,
  },
  VIRTUAL_TAGS: {
    id: 'virtual_tags',
    label: 'Virtual Tags',
    icon: ICONS.tags.name,
  },
  LINKEDACCNAME: { id: 'linkedaccname', icon: ICONS.link.name },
  LINKEDACCID: { id: 'linkedaccid', icon: ICONS.link.name },
  USAGETYPE: { id: 'usagetype', icon: ICONS.gauge.name },
  CUSTOMER: { id: 'customer', label: 'Customer', icon: ICONS.user.name },
  DIVISION: { id: 'division', label: 'Customer', icon: ICONS.mainUserRegular.name },
  ACCOUNT_TAGS: { id: 'accounttags', icon: ICONS.tags.name },
  VIRTUAL_CUSTOM_TAGS: { id: 'virtualcustomtags', icon: ICONS.tags.name },
  FAMILY_TYPE: { id: 'familytype', icon: ICONS.shapes.name },
  BUSINESS_MAPPING: { id: 'businessmapping', icon: ICONS.pieChart.name },
  CHARGE_TYPE: { id: 'chargetype', icon: ICONS.wallet.name },
  QUANTITY_TYPE: { id: 'quantitytype', icon: ICONS.shapes.name },
  SUB_SERVICE: { id: 'subservice', icon: ICONS.clouds.name },
  OPERATION: { id: 'operation', icon: ICONS.memo.name },
  NORMALIZATION_FACTOR: { id: 'normalizationfactor', icon: ICONS.calculator.name },
  PLATFORM: { id: 'platform', icon: ICONS.windowRestore?.name },
  DB_ENGINE: { id: 'dbengine', icon: ICONS.database?.name },
  BILLING_ENTITY: { id: 'billingentity', icon: ICONS.receipt?.name },
  LEGAL_ENTITY: { id: 'legalentity', icon: ICONS.fileContract?.name },
  PAYMENT_OPTION: { id: 'paymentoption', icon: ICONS.fileInvoiceDollar?.name },
  PURCHASE_OPTION: { id: 'purchaseoption', icon: ICONS.bagShopping?.name },
  SUB_VIEWS_CUSTOM_TAGS: { id: 'subviewscustomtags', icon: ICONS.arrowsToEye?.name },
  AVAILABILITY_ZONE: { id: 'zonetag', icon: ICONS.locationDot?.name },
  RESOURCEID: { id: 'resourceid', icon: ICONS.server?.name },
  RESOURCE_GROUP: { id: 'resourcegroup', icon: ICONS.server?.name },
  FILTER_GROUP: { id: 'categories', icon: ICONS.objectGroup?.name },
  COST_TYPE_DESCRIPTION: { id: 'costtypedescription', icon: ICONS.messageDollar?.name },
  K8S_NAMESPACE: { id: 'k8snamespace', icon: ICONS.kubernetes?.name },
  CUE_K8S_LABEL_TAGS: { id: 'k8slabels', icon: ICONS.kubernetes?.name },
  K8S_CLUSTER: { id: 'k8scluster', icon: ICONS.kubernetes?.name },
  K8S_NODE_GROUP: { id: 'k8snodegroup', icon: ICONS.kubernetes?.name },
  BENEFITS: { id: 'benefit', icon: ICONS.faceSmile?.name },
  COST_TYPE: { id: 'costtype', icon: ICONS.messageDollar?.name },
  CLOUD_FRONT_REGION: { id: 'cloudfrontregion', icon: ICONS.earth?.name },
};
export const ALL_FILTERS_BY_ID = Object.fromEntries(Object.values(ALL_FILERS_MAP).map((filter) => [filter.id, filter]));
