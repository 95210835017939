import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import Spinner from 'shared/components/andtComponents/Spinner';
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import { useEC2Usage } from 'usage/hooks/react-query/useEC2Usage';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import RiCoverageTable from './RiCoverageTable';

const RiCoveragePanel = ({ initialStartDate, initialEndDate, filtersValuesMap }) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const { fetchRiCoverage } = useEC2Usage();
  const { data, isLoading, isFetching } = fetchRiCoverage(startDate, endDate);

  const handleDateChange = ({ startDate: startDateProps, endDate: endDateProps }) => {
    const start = startDateProps || startDate;
    let end = endDateProps || endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    setStartDate(modStartDate);
    setEndDate(modEndDate);
  };

  const datePickerSyntexDates = (start, end) => {
    let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  const formatLinkedAccountDisplay = (riCoverage) => {
    if (!riCoverage) {
      return [];
    }
    const linkedAccountsArr = filtersValuesMap.get('linkedaccid');
    if (!linkedAccountsArr) {
      return riCoverage;
    }
    const data = riCoverage.map((row) => {
      let index;
      linkedAccountsArr.some((obj, i) => {
        if (row.linkedAccountId === obj.linkedAccountId) {
          index = i;
          return true;
        }
        return false;
      });
      const linkedAccountDisplayLabel = index ? linkedAccountsArr[index]?.displayLabel : undefined;
      return { ...row, linkedAccountDisplayLabel };
    });
    return data;
  };
  return isLoading || isFetching ? (
    <div style={{ height: '400px' }}>
      <Spinner />
    </div>
  ) : (
    <div>
      <div style={{ paddingBottom: '20px' }}>
        <DatePickerFilter
          onDateChange={handleDateChange}
          startDate={datePickerSyntexDates(startDate, null).startDate}
          endDate={datePickerSyntexDates(null, endDate).endDate}
          currPeriodGranLevel={GranularityLevelsTypes.GRAN_LEVEL_DAILY}
        />
      </div>
      <RiCoverageTable data={formatLinkedAccountDisplay(data)} />
    </div>
  );
};

RiCoveragePanel.propTypes = {
  filtersValuesMap: PropTypes.object.isRequired,
  initialStartDate: PropTypes.object.isRequired,
  initialEndDate: PropTypes.object.isRequired,
};

const observerRiCoveragePanel = withInvoiceFiltersContextConsumer(observer(RiCoveragePanel));
export default observerRiCoveragePanel;
