/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { getFullAwsServiceName } from 'shared/utils/awsUtils';
import { isServiceHasFamilyType, isServiceHasTypes } from 'shared/utils/cloudUtils';
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class ServiceDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    const { currDataState } = this.context.state;
    const { usersStore } = this.context.props;
    const { currDispUserCloudAccountType } = usersStore;
    let newDataState = currDataState;
    if (isServiceHasFamilyType(dataKey, currDispUserCloudAccountType)) {
      newDataState = CostUsageStates.FAMILY_TYPE;
    } else if (isServiceHasTypes(dataKey, currDispUserCloudAccountType)) {
      newDataState = CostUsageStates.TYPE;
    } else {
      newDataState = CostUsageStates.OPERATION;
    }
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.SERVICE;
  }
}
