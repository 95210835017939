import React from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/hooks/react-query/useRoles';
import { ROLE_TYPES } from '../containers/RolesAndUsers/constants/rolesAndUsersConstants';

const RolesContext = React.createContext();

export function withRolesProvider(Component) {
  function RolesProvider({ ...props }) {
    const { fetchRoles, createNewRole, updateRole, deleteRole } = useRoles();

    const { data = [], refetch: refetchRoles, isLoading } = fetchRoles();
    const { mutateAsync: handleAdd } = createNewRole();
    const { mutateAsync: handleUpdate } = updateRole();
    const { mutateAsync: handleDelete } = deleteRole();
    const rolesFiltered = (rolesData) =>
      (rolesData || []).filter((rawRole) => [ROLE_TYPES.USER, ROLE_TYPES.DEFAULT_ADMIN].includes(rawRole.roleType)) ||
      [];

    return (
      <RolesContext.Provider
        value={{
          roles: rolesFiltered(data),
          rolesLoading: isLoading,
          refetchRoles,
          createNewRole: handleAdd,
          updateRole: handleUpdate,
          deleteRole: handleDelete,
        }}
      >
        <Component
          {...props}
          roles={rolesFiltered(data)}
          rolesLoading={isLoading}
          refetchRoles={refetchRoles}
          createNewRole={handleAdd}
          updateRole={handleUpdate}
          deleteRole={handleDelete}
        />
      </RolesContext.Provider>
    );
  }
  RolesProvider.propTypes = {
    usersStore: PropTypes.object.isRequired,
  };
  return RolesProvider;
}

export function withRolesContextConsumer(Component) {
  return function RolesConsumer({ ...props }) {
    return <RolesContext.Consumer>{(data) => <Component {...data} {...props} />}</RolesContext.Consumer>;
  };
}
