import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { OUTDATED_SNAPSHOT_PROPERTIES } from '../../recommendationPropertyUtils';

const EbsOutdatedSnapshot = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `aws ec2 delete-snapshot --snapshot-id ${recommendation?.resourceId}`,
        },
      ],
    },
  ];

  const description =
    // eslint-disable-next-line max-len
    `Ebs Outdated Snapshot, this snapshot age is ${recommendation?.recData?.elapsed_time_since_creation}, to avoid unnecessary costs, we recommend to delete the snapshot after 21 days. Note this recommendation does NOT include AMI snapshots.`;
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={OUTDATED_SNAPSHOT_PROPERTIES}
    />
  );
};

EbsOutdatedSnapshot.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default EbsOutdatedSnapshot;
