import { useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { toast } from 'react-toastify';

const root = '/api/v1/user-management/customers';

const fetchMspCustomers = async () => API.get('billings', root);

export const useMspCustomers = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.MSP_CUSTOMERS];

  return {
    reset: () => queryClient.resetQueries(queryKey),
    fetchMspCustomersData: (userKey) =>
      useQuery({
        queryKey: [...queryKey, userKey],
        queryFn: fetchMspCustomers,
        onError: () =>
          toast.error('Error fetching reseller customers data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
  };
};
