import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as RefreshIcon } from 'shared/img/icons/refresh.svg';
import { ReactComponent as CircleCheckedSolid } from 'shared/img/icons/circle-check-solid.svg';
import { ReactComponent as CircleXmark } from 'shared/img/icons/circle-xmark-solid.svg';
import styles from './syncButton.module.scss';

const propTypes = {
  value: PropTypes.number,
};
const defaultProps = {
  value: 0,
};

const SyncButton = ({ value }) => {
  if (!value) {
    return <CircleXmark className={`${styles.icon} ${styles.red}`} />;
  }
  if (value === 1) {
    return <RefreshIcon style={{ width: '25px' }} />;
  }
  return <CircleCheckedSolid className={`${styles.icon} ${styles.green}`} />;
};

SyncButton.propTypes = propTypes;
SyncButton.defaultProps = defaultProps;
export default SyncButton;
