import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Panel from 'shared/components/Panel';
import ContentInfo from 'shared/components/ContentInfo';

const percentFields = ['totalUtilizationPercent'];
function SavingsPlansStatPanel(props) {
  const { data } = props;
  const camelToStrings = (camelStr) => camelStr.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  const renderStats = (data) =>
    data.map((dataObj) =>
      Object.keys(dataObj).map((key) => (
        <Col xs={12} md={12} lg={6} xl="auto" className="sp-analyzer__content-col">
          <ContentInfo
            title={camelToStrings(key)}
            value={dataObj[key]}
            isCost={percentFields.indexOf(key) < 0}
            isPercent={percentFields.indexOf(key) >= 0}
          />
        </Col>
      )),
    );
  return (
    <Panel divider title="Saving Plans Utilization Summary">
      <Row style={{ justifyContent: 'space-between' }}>{renderStats(data)}</Row>
    </Panel>
  );
}

// camelToStrings(key)
SavingsPlansStatPanel.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SavingsPlansStatPanel;
