export default class MainDbConstants {
  static PREV_MONTH = 'prevMonth';
  static PREV_MONTH_TO_DATE = 'prevMonthTd';
  static CURRENT_MONTH = 'currMonth';
  static OBTAINED_SAVINGS = 'obtained';
  static POTENTIAL_SAVINGS = 'potential';
  static TITLE_USG_COST_MTD = 'Month to-date costs';
  static TITLE_USG_COST_FORECASTED = 'Forecasted monthly Costs';
  static TITLE_USG_COST_PREV = 'Previous month costs';
  static TITLE_USG_COST_TOGGLE = 'Annual Expected savings';
  static TOOLTIP_USG_COST_TOGGLE = 'Based on potential savings along with savings already actualized';
  static TOOLTIP_USG_COST = 'Total monthly cost since last complete billing cycle';
  static TOOLTIP_FORCASTED = 'Expected monthly costs based on previous months usage';
  static TOOLTIP_USG_COST_PREV = 'Previous month total costs';
  static TOTAL_COST_PREV_VS_CURRENT = 'Daily Cost Comparison - Current vs Previous Month';
  static CURR_VS_PREV_X_LABEL = 'Day of month';
  static CURR_VS_PREV_Y_LABEL = 'Cost';
  static REGION_ACTIVITY_MAP_TITLE = 'Region Activity Cost Map';
}

export class PREV_VS_CURRENT_CHARTS_KEYS {
  static PREVIOUS_MONTH = 'prevMonth';
  static CURRENT_MONTH = 'currMonth';
  static FORCASTED_COST = 'currExpected';
}
