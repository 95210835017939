/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the AWS CLI:</li>
      <CodeBlock
        text="aws docdb delete-db-instance --db-instance-identifier {{primaryDbInstanceId}}"
        variables={{
          primaryDbInstanceId: rec.primaryDbInstanceId,
        }}
      />
      <p>
        {`Be aware:
      If you delete the last instance in an Amazon DocumentDB cluster, you also delete that cluster and the automatic snapshots and continuous backups associated with that cluster
      To delete the last instance in your cluster, you can delete the cluster and optionally create a final snapshot. For more information, see `}
        <a target="blank" href="https://docs.aws.amazon.com/documentdb/latest/developerguide/db-cluster-delete.html">
          Deleting an Amazon DocumentDB Cluster.
        </a>
      </p>
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
