/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';

export default class AlertComponent extends PureComponent {
  static propTypes = {
    divider: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string,
    subhead: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
  };

  static defaultProps = {
    divider: false,
    color: '',
    title: '',
    subhead: '',
    label: '',
    icon: '',
  };

  constructor() {
    super();

    this.state = {
      visible: true,
      collapse: true,
      refresh: false,
    };
  }

  onShow = () => {
    this.setState({ visible: true });
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  onCollapse = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  onRefresh = () => {
    // your async logic here
    this.setState({ refresh: !this.state.refresh });
    setTimeout(() => this.setState({ refresh: false }), 5000);
  };

  render() {
    const { color, divider, icon, title, subhead, label } = this.props;

    if (this.state.visible) {
      return (
        <Card
          className={`panel${this.props.color ? ` panel--${color}` : ''}
            ${divider ? ' panel--divider' : ''}${this.state.collapse ? '' : ' panel--collapse'}`}
        >
          <CardBody className="panel__body">
            {this.state.refresh ? (
              <div className="panel__refresh">
                <LoadingIcon />
              </div>
            ) : (
              ''
            )}
            <div className="">
              <h5 className="daily-recs__panel-title" style={{ color: '#4e98cb' }}>
                {icon ? <span className={`panel__icon lnr lnr-${icon}`} /> : ''}
                {title}
                {/* <RecommStatusBadge badgeType={label} addLeftMargin /> */}
              </h5>
              <h5 className="subhead">{subhead}</h5>
            </div>
            <Collapse isOpen={this.state.collapse}>
              <div className="panel__content">{this.props.children}</div>
            </Collapse>
          </CardBody>
        </Card>
      );
    }

    return '';
  }
}
