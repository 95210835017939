import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import classes from './heatMap.module.scss';

const HeatMapCloseButton = ({ isClosed, onClick }) => {
  const onCloseButtonClick = () => {
    onClick();
  };

  return (
    <div
      className={classes.heatMapCloseButton}
      onClick={onCloseButtonClick}
      automation-id={isClosed ? 'open-heatmap' : 'close-heatmap'}
    >
      <GenerateIcon iconName={isClosed ? ICONS.chevronDown.name : ICONS.chevronUp.name} />
      <div>{isClosed ? 'open' : 'close'}</div>
    </div>
  );
};

HeatMapCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isClosed: PropTypes.bool,
};

HeatMapCloseButton.defaultProps = {
  isClosed: false,
};

export default HeatMapCloseButton;
