/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const customStyles = {
  control: (base, state) => ({
    ...base,
    '&:hover': { borderColor: 'gray' }, // border style on hover
    border: '1px solid lightgray', // default border color
    boxShadow: 'none', // no box-shadow
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isDisabled ? null : isSelected ? '#FFFFFF' : isFocused ? '#FFFFFF' : null,
    color: isDisabled,
    '&:hover': {
      borderColor: '#a6a7a7',
      backgroundColor: '#a6a7a7',
    },
    ':active': {
      ...styles[':active'],
      backgroundColor: isDisabled,
    },
  }),
};

class FieldFilter extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {};
  }

  prepareData = (data) => {
    if (data) {
      const midifiedData = data.map((i) => {
        if (typeof i === 'string' || typeof i === 'number') {
          return { value: i, label: i };
        } else if (typeof i === 'object' && (i.label || i.value)) {
          return i;
        }
        return null;
      });
      return midifiedData;
    }
    return data;
  };

  render() {
    return (
      <Select
        styles={customStyles}
        defaultValue={this.props.options ? this.props.options[0] : ''}
        options={this.prepareData(this.props.options)}
        isClearable={this.props.isClearable}
        isLoading={this.props.isLoading}
        onChange={(selectedOptions) => this.props.handleChange(this.props.type, selectedOptions)}
        value={this.props.value}
        isDisabled={this.props.isDisabled}
        placeholder={this.props.placeholder}
      />
    );
  }
}

FieldFilter.defaultProps = {
  isDisabled: false,
  placeholder: 'Select...',
  isClearable: true,
  isLoading: false,
};

export default FieldFilter;
