import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';
import { k8sGranLevel } from '../../../../../../../shared/constants/k8sConstants';

export default class WorkloadDataState extends BaseCostUsageDataState {
  getNewDataStateType = () => {
    const newDataState =
      this.context.state.k8SGranularityState === k8sGranLevel.PODS ? CostUsageStates.NAMESPACE : CostUsageStates.NODE;
    return newDataState;
  };

  getDataStateType = () => CostUsageStates.WORKLOAD;
}
