// @noflow
import { cloneDeep, isNumber } from 'lodash';
import moment from 'moment';

// check by number of digits in integer, 10 for timestamp
export const getJsTime = (time) => (Math.floor(Math.log10(time)) + 1 === 10 ? time * 1000 : time);

const addAnomalyPoint = (data, pointTime, pointValue, anomalyInterval) => {
  const dataPoint = {
    x: pointTime,
    y: pointValue,
    anomalyOptions: {
      significance: anomalyInterval.significance,
      delta: anomalyInterval.delta,
      duration: anomalyInterval.duration,
      durationAsSeconds: anomalyInterval.durationAsSeconds,
      deltaAbsolute: anomalyInterval.deltaAbsolute,
      deltaPercentage: anomalyInterval.deltaPercentage,
    },
  };

  data.anomaly.anomalyStorageArray.push(dataPoint);
  if (!anomalyInterval.isFilteredAnomaly && !anomalyInterval.isOtherAnomaly) {
    // orange anomaly
    data.anomaly.dataPoints.push([pointTime, pointValue]);
    data.anomaly.unSatisfiedDataPoints.push([pointTime, null]);
  } else {
    // gray anomaly
    data.anomaly.unSatisfiedDataPoints.push([pointTime, pointValue]);
    data.anomaly.dataPoints.push([pointTime, null]);
  }
};

const roundNumber = (num) => {
  const precision = 10000;

  if (num > precision) {
    return Math.round(num);
  }
  if (num * precision < 1) {
    return 0;
  }
  return Math.round(num * precision) / precision;
};

const roundNumberTofixed = (value, fixedBy = 2) => Number.parseFloat(value).toFixed(fixedBy);

/**
 *
 * @param anomalyId
 * @param startDate
 * @param endDate
 * @param duration - supplied by simulation, otherwise is calculated by start and end date
 * @param state
 * @param score
 * @param isDirectionUp
 * @param peakValue - either use peakValue || delta together with deltaType
 * @param deltaAbsolute - (simulation)
 * @param deltaPercentage - (simulation)
 * @param isFilteredAnomaly -
 * for simulation where some anomalies does not meet filter conditions but are still displayed
 * @param isOtherAnomaly - for anoboard where some anomalies are not the current anomaly
 * @returns {{anomalyId: *, startDate: *, endDate: *, duration: string, state: *, significance: *,
 * isDirectionUp: *, peakValue: *, delta: *, meetsCondition: *}}
 */
const getAnomalyInterval = (
  startDate,
  endDate,
  duration,
  state,
  score,
  isDirectionUp,
  peakValue,
  deltaAbsolute,
  deltaPercentage,
  isFilteredAnomaly,
  isOtherAnomaly,
) => {
  let deltaText = '';
  if (deltaAbsolute) {
    deltaText += roundNumberTofixed(deltaAbsolute, 2);
  }
  if (deltaPercentage) {
    deltaText += ` (${roundNumberTofixed(deltaPercentage, 2)}%)`;
  }
  /*  eslint no-param-reassign: "off" */
  peakValue = roundNumber(peakValue);
  return {
    startDate,
    endDate,
    duration: duration
      ? moment.duration(duration, 'seconds').humanize()
      : moment.duration((endDate - startDate) / 1000, 'seconds').humanize(),
    durationAsSeconds: duration
      ? moment.duration(duration, 'seconds').asSeconds()
      : moment.duration((endDate - startDate) / 1000, 'seconds').asSeconds(),
    state,
    significance: score,
    isDirectionUp,
    peakValue,
    delta: deltaText,
    deltaAbsolute,
    deltaPercentage,
    isFilteredAnomaly,
    isOtherAnomaly,
  };
};

const getAnomalyIntervalsModel = (anomalyId, currentAnomalyIntervals, otherAnomalyIntervals) => {
  const cur = (currentAnomalyIntervals || []).map((a) =>
    getAnomalyInterval(
      getJsTime(a.startTime),
      getJsTime(a.endTime),
      a.duration,
      a.status?.toLowerCase() || a.state?.toLowerCase(),
      a.score,
      a.direction?.toLowerCase() === 'up' || a.directionUp,
      a.peak,
      a.deltaAbsolute,
      a.deltaPercentage,
      null,
      null,
    ),
  );

  const other = (otherAnomalyIntervals || []).map((a) =>
    getAnomalyInterval(
      getJsTime(a.startTime),
      getJsTime(a.endTime),
      a.duration,
      a.status?.toLowerCase() || a.state?.toLowerCase(),
      a.score,
      a.direction?.toLowerCase() === 'up' || a.directionUp,
      a.peak,
      a.deltaAbsolute,
      a.deltaPercentage,
      null,
      true,
    ),
  );

  return cur.concat(other);
};

export const processSeriesData = (
  lineDataPoints,
  baselineDataPoints,
  currentAnomalyIntervals,
  otherAnomalyIntervals,
) => {
  const updateDataMinMax = (typeData, newVal) => {
    typeData.dataPointsMeta = typeData.dataPointsMeta || {};
    if (newVal < typeData.dataPointsMeta.dataMin || !isNumber(typeData.dataPointsMeta.dataMin)) {
      typeData.dataPointsMeta.dataMin = newVal;
    }
    if (newVal > typeData.dataPointsMeta.dataMax || !isNumber(typeData.dataPointsMeta.dataMax)) {
      typeData.dataPointsMeta.dataMax = newVal;
    }
  };

  const anomalyIntervals = getAnomalyIntervalsModel(null, currentAnomalyIntervals, otherAnomalyIntervals);
  const data = {
    line: { dataPoints: cloneDeep(lineDataPoints), dataPointsMeta: {} },
    baseline: { dataPoints: cloneDeep(baselineDataPoints), dataPointsMeta: {} },
    anomaly: {
      dataPoints: [],
      dataPointsMeta: {},
      unSatisfiedDataPoints: [],
      anomalyStorageArray: [],
      intervals: anomalyIntervals,
    },
  };

  if (data.line.dataPoints && data.line.dataPoints.length) {
    let mainAnomalyDataPointsCount = 0;
    const now = moment().unix() * 1000;

    for (let j = 0; j < data.line.dataPoints.length; j++) {
      /*  eslint no-multi-assign: "off" */
      const hammerTime = (data.line.dataPoints[j][0] = getJsTime(data.line.dataPoints[j][0]));

      if (data.anomaly.intervals && data.anomaly.intervals.length) {
        let isAnomalyIndex = false;

        for (let k = 0; k < data.anomaly.intervals.length; k++) {
          const endDate = data.anomaly.intervals[k].endDate ? getJsTime(data.anomaly.intervals[k].endDate) : now;
          const startDate = getJsTime(data.anomaly.intervals[k].startDate);
          if (hammerTime <= endDate && hammerTime >= startDate) {
            if (!data.anomaly.intervals[k].isFilteredAnomaly && !data.anomaly.intervals[k].isOtherAnomaly) {
              // orange anomaly
              mainAnomalyDataPointsCount += 1;
            }
            addAnomalyPoint(data, hammerTime, data.line.dataPoints[j][1], data.anomaly.intervals[k]);
            updateDataMinMax(data.anomaly, data.line.dataPoints[j][1]);
            isAnomalyIndex = true;
          }
        }

        if (!isAnomalyIndex) {
          data.anomaly.dataPoints.push([hammerTime, null]);
          data.anomaly.unSatisfiedDataPoints.push([hammerTime, null]);
          data.anomaly.anomalyStorageArray.push(null);
        }
      }

      updateDataMinMax(data.line, data.line.dataPoints[j][1]);
    }

    data.anomaly.isMainSinglePoint = mainAnomalyDataPointsCount === 1;
  }

  if (data.baseline.dataPoints && data.baseline.dataPoints.length) {
    for (let i = 0; i < data.line.dataPoints.length; i++) {
      data.baseline.dataPoints[i][0] = getJsTime(data.baseline.dataPoints[i][0]);
      updateDataMinMax(data.baseline, data.baseline.dataPoints[i][1]);
    }
  }

  return data;
};

export const generateChartSeriesMetricModel = (metric, expressionTreeId, uiIndex) => ({
  id: metric.id,
  name: metric.name,
  what: metric.what,
  properties: metric.properties,
  forecastTime: metric.forecastTime,
  tags: metric.tags,
  compositeId: metric.compositeId,
  origin: metric.origin,
  expressionTreeId,
  uiIndex,
});
