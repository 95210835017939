import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import styles from './recommPlanDetails.module.scss';
import PsPieChart from './SpPieChart';
import { HourlyCommitment } from './HourlyCommitment';
import { PlanPreferences } from './PlanPrefernces';
import { RECOMMENDATION_PLAN_PROPERTIES } from '../utils/consts';
import { ReactComponent as NoData } from '../img/no-calculated-data.svg';
import { calculateFieldValue, getPropertyValue } from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils.js';
import {useUserSettingsContext} from "~/users/utils/contexts/UserSettingsContext.jsx";

const convertToCapital = (text) =>
  text
    .split(/(?=[A-Z])/)
    .join(' ')
    .toUpperCase();
const RecommendedPlanCube = ({
  plan,
  planKey,
  preferencePlanKey,
  shareProperties,
  selectedKey,
  setSelectedPlan,
  automationId,
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <div
      className={`${styles.planWrapper} ${selectedKey === planKey && styles.selected}`}
      onClick={() => setSelectedPlan(planKey)}
      automation-id={automationId}
    >
      <div className={styles.top}>
        <div className={styles.detailsHeader}>
          {convertToCapital(planKey)}
          {selectedKey === planKey && (
            <a
              target="_blank"
              href="https://console.aws.amazon.com/costmanagement/home?/savings-plans/purchase#/savings-plans/purchase"
              className={styles.tag}
              rel="noreferrer"
            >
              BUY
            </a>
          )}
        </div>
        {preferencePlanKey === planKey && <PlanPreferences />}
      </div>
      {!plan ? (
        <div className={styles.noRecommendedWrapper}>
          <NoData />
          <span className={styles.noRecommendedText}>This plan is not recommended</span>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.listWrapper}>
            <ul className={styles.list}>
              {RECOMMENDATION_PLAN_PROPERTIES.map((property) => (
                <>
                  {property.groupHeader && (
                    <li className={styles.groupHeader}>
                      {property.groupHeader}:
                      {property.headerInfo && (
                        <InfoPopover isSimple mode="outline" className={styles.infoIcon}>
                          {property.headerInfo}
                        </InfoPopover>
                      )}
                    </li>
                  )}
                  <li key={property} className={`${property.isEndGroup && styles.endGroup}`}>
                    <div className={styles.name}>
                      {property.name}:
                      <span
                        className={classNames(styles.value, plan.potentialSavings < 0 && styles.noProfitable)}
                        automation-id={`${property.key}Value`}
                      >
                        {property.calculatedFields?.length ? calculateFieldValue(property, shareProperties, plan, getCurrencyNumber) : getPropertyValue(property, shareProperties, plan, getCurrencyNumber)}
                      </span>
                      {property.info && (
                        <InfoPopover isSimple mode="outline" className={styles.infoIcon}>
                          {property.info}
                        </InfoPopover>
                      )}
                      {property.isEditable && (
                        <HourlyCommitment planKey={planKey} paymentOption={plan.paymentOption} term={plan.term} />
                      )}
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </div>
          <PsPieChart percentValue={plan?.potentialSavings} />
        </div>
      )}
    </div>
  );
};

RecommendedPlanCube.propTypes = {
  plan: PropTypes.object,
  planKey: PropTypes.string.isRequired,
  preferencePlanKey: PropTypes.string,
  shareProperties: PropTypes.object,
  selectedKey: PropTypes.string,
  setSelectedPlan: PropTypes.func.isRequired,
  automationId: PropTypes.string,
};

export default RecommendedPlanCube;
