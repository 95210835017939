import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Alert } from 'shared/img/icons/alert.svg';
import { ReactComponent as EmptyAlert } from 'shared/img/icons/empty-alert.svg';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import styles from './budgets.module.scss';

const AlertedColumn = ({ value: alerts, budgetSpend, forecastVs }) => {
  if (!alerts || !alerts.length) {
    return (
      <div className={styles.emptySvgWrapper}>
        <EmptyAlert />
      </div>
    );
  }

  const isForecastAlert = (alert) => alert.whenToAlert?.find((val) => val === 'forecasted');
  const isActualAlert = (alert) => alert.whenToAlert?.find((val) => val === 'actualUsage');
  const getAlertTrigger = (alert) => {
    const forecast = isForecastAlert(alert);
    const actual = isActualAlert(alert);
    if (forecast && actual) {
      return 'Actual/Forecast';
    }
    if (forecast) {
      return 'Forecast cost';
    }
    if (actual) {
      return 'Actual cost';
    }
    return '';
  };

  const isOverusedAlert = (alert) =>
    budgetSpend > alert.budgetPercentToAlertFrom ||
    (isForecastAlert(alert) && forecastVs > alert.budgetPercentToAlertFrom);
  return (
    <Tooltip
      placement="left"
      title={
        <div className={`${styles.tooltipWrap}`}>
          {alerts.map((alert) => (
            <div className={styles.tooltipRow}>
              <span className={isOverusedAlert(alert) ? styles.exceeded : ''}>
                {`${getAlertTrigger(alert)} >  ${alert.budgetPercentToAlertFrom}%`}
              </span>
              <div className={styles.svgWrapper}>
                <Alert className={isOverusedAlert(alert) ? styles.pink : styles.green} />
              </div>
              <br />
            </div>
          ))}
        </div>
      }
      classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}
      arrow
    >
      <div className={styles.svgWrapper}>
        <Alert className={alerts.find((a) => isOverusedAlert(a)) ? styles.pink : styles.green} />
      </div>
    </Tooltip>
  );
};

AlertedColumn.propTypes = {
  value: PropTypes.array,
  budgetSpend: PropTypes.number.isRequired,
};
AlertedColumn.defaultProps = {
  value: [],
};
export default AlertedColumn;
