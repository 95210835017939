import { fetchOnboardingFileUploadStatusApi } from 'app/hooks/react-query/useOnboarding';
import toast from 'shared/components/andtComponents/Toast';
import {
  AWS_ONBOARDING_FIELDS,
  AWS_ONBOARDING_STEPS,
} from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import { getOnboardingError } from '../utils/errors';

function grantAccessValidation(currentScreen) {
  const errors = {};

  if (!currentScreen[AWS_ONBOARDING_FIELDS.ROLE_ARN]) {
    errors[AWS_ONBOARDING_FIELDS.ROLE_ARN] = 'ARN number is required';
  } else if (!currentScreen[AWS_ONBOARDING_FIELDS.ROLE_ARN].match(/^arn:aws:iam::[\d\w]+:role\/.+$/)) {
    errors[AWS_ONBOARDING_FIELDS.ROLE_ARN] = 'ARN number must start with arn:aws:iam:';
  }

  return errors;
}

function accountTypeValidation(currentScreen) {
  const errors = {};
  if (
    currentScreen[AWS_ONBOARDING_FIELDS.IS_MSP] &&
    currentScreen[AWS_ONBOARDING_FIELDS.ACCOUNT_TYPE] === 'dedicated' &&
    !currentScreen[AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER]
  ) {
    errors[AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER] = 'Customer is required';
  }
  return errors;
}

function awsDetailsValidation(currentScreen) {
  const errors = {};

  if (!currentScreen[AWS_ONBOARDING_FIELDS.ACCOUNT_ID]) {
    errors[AWS_ONBOARDING_FIELDS.ACCOUNT_ID] = 'Account id is required';
  }
  if (!currentScreen[AWS_ONBOARDING_FIELDS.ACCOUNT_NAME]) {
    errors[AWS_ONBOARDING_FIELDS.ACCOUNT_NAME] = 'Account name is required';
  }
  if (!currentScreen[AWS_ONBOARDING_FIELDS.BUCKET_REGION]) {
    errors[AWS_ONBOARDING_FIELDS.BUCKET_REGION] = 'Region is required';
  }
  if (!currentScreen[AWS_ONBOARDING_FIELDS.BUCKET_NAME]) {
    errors[AWS_ONBOARDING_FIELDS.BUCKET_NAME] = 'Bucket name is required';
  }
  return errors;
}

async function validateAccessValidation(currentScreen) {
  const errors = {};
  const accountId = currentScreen[AWS_ONBOARDING_FIELDS.ACCOUNT_ID];
  const bucketName = currentScreen[AWS_ONBOARDING_FIELDS.BUCKET_NAME];
  const roleARN = currentScreen[AWS_ONBOARDING_FIELDS.ROLE_ARN];
  try {
    await fetchOnboardingFileUploadStatusApi(
      accountId,
      bucketName,
      currentScreen[AWS_ONBOARDING_FIELDS.BUCKET_REGION],
      roleARN,
      currentScreen[AWS_ONBOARDING_FIELDS.EXTERNAL_ID],
    );
  } catch (e) {
    if (e.response.status === 401) {
      errors[AWS_ONBOARDING_FIELDS.FILE_UPLOADED] = getOnboardingError({
        code: 'BUCKET_NO_ACCESS',
        accountId,
        bucketName,
        roleARN,
      });
    } else if (e.response.status === 404) {
      errors[AWS_ONBOARDING_FIELDS.FILE_UPLOADED] = getOnboardingError({
        code: e.response.data?.message?.message || 'BUCKET_FILE_NOT_FOUND',
        accountId,
        bucketName,
        roleARN,
      });
    }
    toast.error(errors[AWS_ONBOARDING_FIELDS.FILE_UPLOADED], { autoClose: false });
  }

  return errors;
}

export default async function useFormValidation(currentScreen) {
  switch (currentScreen.screen) {
    case AWS_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return {
        errors: accountTypeValidation(currentScreen),
        currentScreen,
      };
    case AWS_ONBOARDING_STEPS.CREATE_CUR_FILE:
      return {
        errors: {},
        currentScreen,
      };
    case AWS_ONBOARDING_STEPS.AWS_DETAILS:
      return {
        errors: awsDetailsValidation(currentScreen),
        currentScreen,
      };
    case AWS_ONBOARDING_STEPS.VALIDATE_ACCESS:
      return {
        errors: await validateAccessValidation(currentScreen),
        currentScreen,
      };
    case AWS_ONBOARDING_STEPS.GRANT_ACCESS:
      return {
        errors: grantAccessValidation(currentScreen),
        currentScreen,
      };
    case AWS_ONBOARDING_STEPS.PROCESS_DATA:
      return {
        errors: {},
        currentScreen,
      };
    case AWS_ONBOARDING_STEPS.BILLING_PROFILE:
      return {
        errors: {},
        currentScreen,
      };
    case AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS:
      return {
        errors: {},
        currentScreen,
      };
    default:
      return null;
  }
}
