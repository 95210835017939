import React from 'react';
import PropTypes from 'prop-types';
import {
  CostTypes,
  CostTypesDbValues,
  Dimensions,
  mapCostTypesToDisplay,
  mapDimensionsToDisplay,
} from 'usage/constants/costAndUsageConstants';
// eslint-disable-next-line max-len
import { formatFilterOptionValueLabel } from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import {
  createSelectedDimensionsMap,
  getDefaultFiltersConfig,
  getLabel,
} from 'shared/components/FilterSidebar/filterUtil';
import FieldSidebarFilter from 'shared/components/FilterSidebar/FieldSidebarFilter';
import classNames from 'classnames';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import { ALL_FILTERS_BY_ID } from './constants';
import styles from './FieldSidebarFilter.module.scss';

const createOptions = (fieldValues, filterType) =>
  fieldValues.map((fieldValue) => formatFilterOptionValueLabel(fieldValue, filterType));

const createSelectedCostTypesMap = (selectedCostTypes) => {
  const selectedMap = new Map([]);
  const selectedArr = selectedCostTypes
    ? selectedCostTypes.map((selectedCostType) => ({
        value: selectedCostType,
        label: mapCostTypesToDisplay.get(selectedCostType),
      }))
    : [];
  selectedMap.set('Cost Type', selectedArr);
  return selectedMap;
};

const getFieldTooltip = (field, label, filterTooltipsFields) => {
  if (filterTooltipsFields?.recommendations?.includes(field)) {
    return `The selected filter “${label}” isn’t applicable for Recommendation panels`;
  }
  if (filterTooltipsFields?.K8SAndRecommendation?.includes(field)) {
    return `The selected filter “${label}” isn’t applicable for K8S/Recommendation panels`;
  }
  return null;
};

const renderFilterByType = (selectedOptionsMap, props, generalOptions) => {
  const {
    handleFilterChange,
    likeFiltersStatus,
    likeCaseConfig,
    handleChangeFilterType,
    excludedFiltersStatusMap,
    likeOperator,
    fieldsWidth,
  } = props;
  const {
    field,
    options,
    isOneChoiceFieldFilter = false,
    isAutoComplete,
    isAsyncLoad,
    isAsyncLoadClick,
    subMenu,
    filter,
    label,
    tooltip,
    disableLike,
    isDisabled = false,
    keys,
    fetchData,
    ...other
  } = generalOptions;
  const icon = ALL_FILTERS_BY_ID[field]?.icon || ICONS.filterList?.name;
  return (
    <div key={field} className={classNames({ [styles.disabledFilters]: props.isDisabled })}>
      <FieldSidebarFilter
        {...other}
        label={label}
        field={field}
        keys={keys}
        options={options}
        likeOperator={likeOperator && !disableLike}
        isAutoComplete={isAutoComplete}
        isAsyncLoad={isAsyncLoad}
        isAsyncLoadClick={isAsyncLoadClick}
        subMenu={subMenu}
        fetchData={fetchData || (() => ({ data: [] }))}
        filter={filter}
        tooltip={tooltip}
        fieldsWidth={fieldsWidth}
        handleChange={handleFilterChange}
        handleChangeFilterType={handleChangeFilterType}
        selectedOptions={selectedOptionsMap.get(field) || []}
        excludeMode={excludedFiltersStatusMap.get(field)}
        likeCaseConfig={likeCaseConfig}
        likeMode={!!likeFiltersStatus && likeFiltersStatus[field]}
        isOneChoiceFieldFilter={isOneChoiceFieldFilter}
        isDisabled={isDisabled || props.isDisabled}
        filterIcon={icon}
      />
    </div>
  );
};

const FilterContentList = ({ fieldToFieldDistincValuesMap, selectedOptionsMap, isForK8S, generalProps }) => {
  const {
    fieldToFieldDistincValuesProps,
    isCostTypeFilter,
    isCloudFrontRegionFilter,
    handleCostTypeChange,
    excludedFiltersStatusMap,
    handleChangeFilterType,
    currDispUserCloudAccountType,
    disallowedFilters,
    isDimensionsFilter,
    filterConfig,
    selectedCostTypes,
    handleDimensionsChange,
    selectedDimensions,
    handleFilterChange,
    disableCostTypeExclude = true,
    isBackendQuery = false,
  } = generalProps;
  // TODO - add sub title "Kubernetes" before namespace and labels filters in CUE
  const filters = [];
  const selectedCostTypesMap = createSelectedCostTypesMap(selectedCostTypes);
  if (!fieldToFieldDistincValuesMap) {
    return null;
  }
  if (isDimensionsFilter) {
    const selectedDimensionsMap = createSelectedDimensionsMap(selectedDimensions);
    filters.push(
      <div className={classNames({ [styles.disabledFilters]: generalProps.isDisabled })}>
        <FieldSidebarFilter
          field="Measure"
          options={[
            {
              value: Dimensions.AVERAGE,
              label: mapDimensionsToDisplay.get(Dimensions.AVERAGE),
            },
            {
              value: Dimensions.MAX,
              label: mapDimensionsToDisplay.get(Dimensions.MAX),
            },
            {
              value: Dimensions.MIN,
              label: mapDimensionsToDisplay.get(Dimensions.MIN),
            },
          ]}
          handleChange={handleDimensionsChange}
          selectedOptions={selectedDimensionsMap.get('Measure') || []}
          excludeMode={excludedFiltersStatusMap.has('Measure')}
          handleChangeFilterType={handleChangeFilterType}
          isOneChoiceFieldFilter={false}
          disableExclude
          isDisabled={generalProps.isDisabled}
        />
      </div>,
    );
  }
  if (isCostTypeFilter) {
    filters.push(
      <div className={classNames({ [styles.disabledFilters]: generalProps.isDisabled })} key="cost-type-filter">
        <FieldSidebarFilter
          field={AwsCommonFields.COST_TYPE}
          options={[
            {
              value: isBackendQuery ? CostTypesDbValues.COST : CostTypes.COST,
              label: mapCostTypesToDisplay.get(CostTypes.COST),
            },
            {
              value: isBackendQuery ? CostTypesDbValues.REFUND : CostTypes.REFUND,
              label: mapCostTypesToDisplay.get(CostTypes.REFUND),
            },
            {
              value: isBackendQuery ? CostTypesDbValues.DISCOUNT : CostTypes.DISCOUNT,
              label: mapCostTypesToDisplay.get(CostTypes.DISCOUNT),
            },
            {
              value: isBackendQuery ? CostTypesDbValues.CREDIT : CostTypes.CREDIT,
              label: mapCostTypesToDisplay.get(CostTypes.CREDIT),
            },
          ]}
          handleChange={handleCostTypeChange}
          selectedOptions={
            selectedOptionsMap.get(AwsCommonFields.COST_TYPE) || selectedCostTypesMap.get('Cost Type') || []
          }
          excludeMode={excludedFiltersStatusMap.get(AwsCommonFields.COST_TYPE)}
          handleChangeFilterType={handleChangeFilterType}
          fetchData={() => ({ data: [] })}
          isDisabled={generalProps.isDisabled}
          filterIcon={ALL_FILTERS_BY_ID[AwsCommonFields.COST_TYPE]?.icon || ICONS.filterList?.name}
          disableExclude={disableCostTypeExclude}
        />
      </div>,
    );
  }
  if (isCloudFrontRegionFilter) {
    filters.push(
      <div
        className={classNames({ [styles.disabledFilters]: generalProps.isDisabled })}
        key="cloud-front-region-filter"
      >
        <FieldSidebarFilter
          field={AwsCommonFields.CLOUD_FRONT_REGION}
          options={[
            'Asia',
            'Australia',
            'Canada',
            'Europe',
            'India',
            'Japan',
            'Middle East',
            'South Africa',
            'South America',
            'United States',
          ].map((region) => ({ value: region, label: region }))}
          handleChange={handleFilterChange}
          selectedOptions={selectedOptionsMap.get(AwsCommonFields.CLOUD_FRONT_REGION) || []}
          excludeMode={excludedFiltersStatusMap.get(AwsCommonFields.CLOUD_FRONT_REGION)}
          handleChangeFilterType={handleChangeFilterType}
          fetchData={() => ({ data: [] })}
          isDisabled={generalProps.isDisabled}
          filterIcon={ALL_FILTERS_BY_ID[AwsCommonFields.CLOUD_FRONT_REGION]?.icon || ICONS.filterList?.name}
        />
      </div>,
    );
  }
  if (fieldToFieldDistincValuesMap.size > 0) {
    fieldToFieldDistincValuesMap.forEach((fieldValues, field) => {
      const passedProps = (fieldToFieldDistincValuesProps && fieldToFieldDistincValuesProps[field]) || {};
      const {
        filter,
        isAutoComplete,
        values,
        subMenu,
        isAsyncLoad,
        isAsyncLoadClick,
        keys,
        disableLike,
        fetchData,
        ...other
      } = {
        ...(filterConfig
          ? filterConfig(field, passedProps, generalProps)
          : getDefaultFiltersConfig(field, passedProps, generalProps) || {}),
        ...passedProps,
      };
      const options = createOptions(
        isAutoComplete || isAsyncLoad || isAsyncLoadClick ? values || [] : fieldValues,
        field,
      );
      const label = getLabel(field, currDispUserCloudAccountType);
      const generalOptions = {
        ...other,
        keys,
        isAutoComplete,
        subMenu,
        isAsyncLoad,
        isAsyncLoadClick,
        field,
        disableLike,
        options,
        filter,
        label,
        tooltip: getFieldTooltip(field, label, generalProps.filterTooltipsFields),
        isOneChoiceFieldFilter: AwsCommonFields.QUANTITY_TYPE === field,
        isDisabled: currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP ? disallowedFilters?.includes(field) : null,
        fetchData,
      };
      if (generalOptions.isOneChoiceFieldFilter && isForK8S) {
        return;
      }
      filters.push(renderFilterByType(selectedOptionsMap, generalProps, generalOptions));
    });
  }
  return filters;
};

export default FilterContentList;

FilterContentList.propTypes = {
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
  selectedOptionsMap: PropTypes.object.isRequired,
  isForK8S: PropTypes.bool,
  generalProps: PropTypes.object.isRequired,
};
FilterContentList.defaultProps = {
  isForK8S: false,
};
