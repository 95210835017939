export const REVIEW_MULTI_ACCOUNT_ROLE_TABLE_COLUMN = [
  { name: 'divisionName', title: 'Division Name (unique)' },
  {
    name: 'divisionNameDisplay',
    title: 'Division Name Display',
    getCellValue: (row) => row.divisionNameDisplay || row.divisionName,
  },
  { name: 'accountId', title: 'Account ID' },
  { name: 'accountKey', title: 'Account Key' },
  { name: 'divisionId', title: 'Division ID' },
  { name: 'userActions', title: ' ' },
];

export const MULTI_ACCOUNTS_RESELLER_CUSTOMER_ROLES_TABLE_COLUMNS = [
  { name: 'uuid', title: 'Role ID' },
  { name: 'roleName', title: 'Role Name' },
  {
    name: 'sync',
    title: 'Sync',
    getCellValue: (row) => {
      if (!row.linkedDivisions) {
        return 0;
      }
      if (!row.isSync) {
        return 1;
      }
      return 2;
    },
  },
  {
    name: 'editButton',
    title: ' ',
  },
];
