import S3VersioningOptions from 'recommendations/containers/S3Versioning/components/Options';
import S3StorageClassOptions from 'recommendations/containers/S3StorageClass/components/Options';
import RdsClassChangeOptions from 'recommendations/containers/RdsClassChange/components/Options';
import RdsVersionUpgradeOptions from 'recommendations/containers/RdsVersionUpgrade/components/Options';
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';

class RecOptionsFactory {
  static create(data) {
    let output;
    switch (data.type) {
      case RecommendationTypes.S3_VERSIONING:
        output = S3VersioningOptions;
        break;
      case RecommendationTypes.S3_STORAGE_CLASS:
        output = S3StorageClassOptions;
        break;
      case RecommendationTypes.RDS_VERSION_UPGRADE:
        output = RdsVersionUpgradeOptions;
        break;
      case RecommendationTypes.RDS_CLASS_CHANGE:
        output = RdsClassChangeOptions;
        break;
      default:
        output = undefined;
        break;
    }
    return output;
  }
}

export default RecOptionsFactory;
