import React, { useMemo, useState } from 'react';
import Input from 'shared/components/andtComponents/Input';
import { debounce } from 'lodash';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import styles from './Search.module.scss';

export const Search = ({ search, setSearch, isDebounce }) => {
  const [searchText, setSearchText] = useState(search);

  const debouncedSetSearchTerm = useMemo(() => {
    const setSearchFunction = (term) => {
      if (typeof setSearch === 'function') {
        setSearch(term);
      }
    };
    if (isDebounce) {
      return debounce((term) => {
        setSearchFunction(term);
      }, 1500);
    }
    return (term) => {
      setSearchFunction(term);
    };
  }, []);

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSetSearchTerm(event.target.value);
  };

  return (
    <div className={styles.search}>
      <Input
        className={styles.searchInput}
        value={searchText}
        onChange={handleInputChange}
        placeholder="Search"
        name="search"
        isSearch
        searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
        type="text"
      />
    </div>
  );
};
