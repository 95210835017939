/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { Collapse } from 'reactstrap';
import { ClickAwayListener } from '@mui/material';

export default class SaveReportToolbarButton extends React.PureComponent {
  static propTypes = {
    buttonStyles: PropTypes.object,
    handleSaveReport: PropTypes.func.isRequired,
    handleSaveDashboardPanelButtonClick: PropTypes.func.isRequired,
    isDiveFromCustomDb: PropTypes.bool,
    isDiveFromReports: PropTypes.bool,
    currUserMultiCloud: PropTypes.bool,
  };
  static defaultProps = {
    isDiveFromCustomDb: false,
    isDiveFromReports: false,
    currUserMultiCloud: false,
    buttonStyles: {},
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
    };
  }

  saveAsReportHandler = (e) => {
    e.preventDefault();
    this.props.handleSaveReport(false);
    this.toggle();
  };
  saveExistingReportHandler = (e) => {
    e.preventDefault();
    this.props.handleSaveReport(true);
    this.toggle();
  };
  saveAsDashboardHandler = (e) => {
    e.preventDefault();
    this.props.handleSaveDashboardPanelButtonClick(false);
    this.toggle();
  };
  saveExistingPanelHandler = (e) => {
    e.preventDefault();
    this.props.handleSaveDashboardPanelButtonClick(true);
    this.toggle();
  };
  toggle = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  render() {
    const { isDiveFromCustomDb, isDiveFromReports, currUserMultiCloud } = this.props;
    return (
      <ClickAwayListener onClickAway={() => this.setState({ collapse: false })}>
        <div className="topbar__profile">
          <Button
            text="Save as"
            icon={() => <GenerateIcon iconName={ICONS.download.name} />}
            isTextButton
            onClick={this.toggle}
          />
          <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
            <div className="drop__menu">
              {!currUserMultiCloud && (
                <>
                  <button className="btn-no-style btn-link" onClick={(e) => this.saveAsReportHandler(e)}>
                    <p className="collapse-item">Save As Report</p>
                  </button>
                  {isDiveFromCustomDb && (
                    <button className="btn-no-style btn-link" onClick={(e) => this.saveExistingPanelHandler(e)}>
                      <p className="collapse-item">Overwrite Existing Panel</p>
                    </button>
                  )}
                  {isDiveFromReports && (
                    <button className="btn-no-style btn-link" onClick={(e) => this.saveExistingReportHandler(e)}>
                      <p className="collapse-item">Overwrite Existing Report</p>
                    </button>
                  )}
                </>
              )}
              <button className="btn-no-style btn-link" onClick={(e) => this.saveAsDashboardHandler(e)}>
                <p className="collapse-item">Save As Dashboard Panel</p>
              </button>
            </div>
          </Collapse>
        </div>
      </ClickAwayListener>
    );
  }
}
