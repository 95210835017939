/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { kIntFormmater } from 'shared/utils/strUtil';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

// eslint-disable-next-line consistent-return
const renderAdditionalRecsBars = (recs) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < recs.length - 1; i++) {
    return <Bar barSize={12} dataKey="RI" fill="#b7b4ea" />;
  }
};

const renderBars = (recs) => {
  const currModel = <Bar barSize={12} dataKey="Pay as you go" fill="#70bbfd" />;
  const recomModel = <Bar barSize={12} dataKey="Suggested RI" fill="#6764af" />;

  if (recs.length > 2) {
    return [currModel, renderAdditionalRecsBars(recs), recomModel];
  }
  return [currModel, recomModel];
};

const CostComparisonChart = (props) => {
  const { data } = props;
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
      <BarChart barGap={-12} width={550} height={284} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tickMargin={0} interval={0} tick={<CustomizedAxisTick fontSize={10} />} />
        <YAxis
          label={{
            value: 'Cost',
            offset: 0,
            angle: -90,
            position: 'left',
          }}
          tickFormatter={(value) => getCurrencyNumber(kIntFormmater(value))}
        />
        <Tooltip formatter={(value) => getCurrencyNumber(kIntFormmater(value))} />
        <Legend />
        {renderBars(data)}
      </BarChart>
    </ResponsiveContainer>
  );
};

CostComparisonChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CostComparisonChart;
