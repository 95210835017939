import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import ExpirationAlert from './ExpirationAlert';

const ExpirationAlertsWrapper = (props) => (
  <Col>
    {Array.isArray(props.expirationAlerts) &&
      props.expirationAlerts.map((alert) => (
        <ExpirationAlert
          key={alert.id}
          invoiceStore={props.invoiceStore}
          usageStore={props.usageStore}
          alertId={alert.id}
          alertType={alert.alertType}
          updatePreSaveAlertsValidationStatus={props.updatePreSaveAlertsValidationStatus}
          setIsInitialDataFetch={props.setIsInitialDataFetch}
        />
      ))}
    {/* this is the first empty alert to enable user create new alerts */}
    <ExpirationAlert
      key="blankTemplate"
      invoiceStore={props.invoiceStore}
      usageStore={props.usageStore}
      expirationType={props.expirationType}
      alertType={props.alertType}
      updatePreSaveAlertsValidationStatus={props.updatePreSaveAlertsValidationStatus}
      setIsInitialDataFetch={props.setIsInitialDataFetch}
    />
  </Col>
);

ExpirationAlertsWrapper.propTypes = {
  expirationAlerts: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  expirationType: PropTypes.string.isRequired,
  alertType: PropTypes.string.isRequired,
  updatePreSaveAlertsValidationStatus: PropTypes.func.isRequired,
  setIsInitialDataFetch: PropTypes.func.isRequired,
};

export default ExpirationAlertsWrapper;
