import React, { useMemo, useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import {
  getColumns,
  getDefaultSorting,
  getSortingColumns,
  ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS,
} from '../../consts';
import RemoveButton from '../RemoveButton';
import LinkedAccountInfoIcon from './LinkedAccountInfoIcon';
import AssignedBy from './AssignedBy';

import styles from './LinkedAccountsComponent.module.scss';
import {
  getAccountRemoveOrInfo,
  getIsAccountSelected,
  getLinkedAccountIsSelectionEnabled,
  getLinkedAccountsIsSelectionHidden,
  STATES,
} from './dataAccessHelperFunctions';

export const LinkedAccountsNoData = (userRoles) => {
  if (!userRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <span className={styles.mainText}>No Accounts for this Role</span>
    </div>
  );
};

const LinkedAccountsTable = ({
  accountsData,
  additionalData,
  isCreateEditMode,
  payerAccount,
  linkedAccountsColumns,
  onRemoveLinkedAccount,
  onSelectionChange,
  modifiedSelectionById,
}) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS).map((c) => ({
      columnName: c.columnName,
      width: c.width,
    })),
  );

  const isLinkedAccountsSelectionHidden = payerAccount?.id
    ? getLinkedAccountsIsSelectionHidden(payerAccount, modifiedSelectionById[payerAccount?.id])
    : undefined;

  const selectedRowIndexes = useMemo(
    () =>
      accountsData
        ?.map((la, index) => (getIsAccountSelected(la, modifiedSelectionById) ? index : -1))
        .filter((index) => index !== -1),
    [modifiedSelectionById],
  );

  const handleSelectedRowsChanged = (selectedRowIndexes) => {
    const selectedLinkedAccountsIds = accountsData
      .filter((la, index) => selectedRowIndexes.includes(index))
      .map((la) => la.id);

    onSelectionChange({
      type: 'linkedAccount',
      allSelectedIds: selectedLinkedAccountsIds,
      parentPayerAccount: payerAccount,
    });
  };

  const renderRemoveOrInfoButton = ({ row }) => {
    if (row) {
      const removeOrInfo = getAccountRemoveOrInfo(row, isCreateEditMode);
      if (removeOrInfo === STATES.REMOVE) {
        return <RemoveButton removeClicked={() => onRemoveLinkedAccount?.(row, 'linkedAccount', payerAccount)} />;
      }
      if (removeOrInfo === STATES.INFO) {
        return <LinkedAccountInfoIcon row={row} />;
      }
    }
    return null;
  };

  const renderLinkedAccountName = (props) => (
    <CustomFormatterWithTooltip overrideStyles={{ fontSize: 12 }} {...props} />
  );

  const renderAssignedBy = ({ row }) => {
    if (row) {
      return (
        <AssignedBy
          additionalData={additionalData}
          isCreateEditMode={isCreateEditMode}
          linkedAccount={row}
          modifiedSelectionById={modifiedSelectionById}
          payerAccount={payerAccount}
        />
      );
    }
    return null;
  };

  const renderCustomSelectionCell = (props) => {
    const { row } = props;
    const isSelected = getIsAccountSelected(row, modifiedSelectionById);
    const isEnabled = getLinkedAccountIsSelectionEnabled(row);
    const newProps = { ...props, selected: isSelected };

    return <CustomSelectionCell {...newProps} isDisabled={!isEnabled} />;
  };

  return (
    <Grid
      rows={accountsData}
      columns={getColumns(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS)}
      automationId="role-linked-accounts-data-access"
    >
      <SortingState
        columnExtensions={getSortingColumns(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS)}
        defaultSorting={getDefaultSorting(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS)}
      />
      <DataTypeProvider
        for={[ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.LINKED_ACCOUNT.columnName]}
        formatterComponent={renderLinkedAccountName}
      />
      {linkedAccountsColumns.find((c) => c === ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.ASSIGNED_BY.columnName) && (
        <DataTypeProvider
          for={[ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.ASSIGNED_BY.columnName]}
          formatterComponent={renderAssignedBy}
        />
      )}
      {linkedAccountsColumns.find(
        (c) => c === ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName,
      ) && (
        <DataTypeProvider
          for={[ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName]}
          formatterComponent={renderRemoveOrInfoButton}
        />
      )}
      <TableWrapper virtual noDataCellComponent={() => LinkedAccountsNoData(accountsData)} height="fit-content" />
      <SelectionState selection={selectedRowIndexes} onSelectionChange={handleSelectedRowsChanged} />
      <IntegratedSelection />
      <TableSelection
        showSelectAll={false}
        showSelectionColumn={!isLinkedAccountsSelectionHidden}
        cellComponent={renderCustomSelectionCell}
        selectionColumnWidth={20}
      />
      <TableColumnResizing
        resizingMode="nextColumn"
        columnWidths={localColumnsWidth}
        onColumnWidthsChange={setLocalColumnsWidth}
      />
      {accountsData.length > 0 && <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />}
    </Grid>
  );
};

LinkedAccountsTable.propTypes = {
  accountsData: PropTypes.array.isRequired,
};

export default LinkedAccountsTable;
