import React from 'react';
import DatasetIDImage from './assets/DatasetID.jpg';
import ProjectIDImage from './assets/ProjectID.jpg';
import commonStyles from '../Pages.module.scss';

const K8sIntegrationPageHelp = {};

K8sIntegrationPageHelp.projectID = {
  component: () => (
    <p className={commonStyles.helpText}>
      Copy the <span>ID of the project</span> that will host your BigQuery billing dataset.
    </p>
  ),
  image: ProjectIDImage,
  imageHeight: 246,
};

K8sIntegrationPageHelp.datasetID = {
  component: () => (
    <p className={commonStyles.helpText}>
      Copy of the <span>name of the dataset</span> you created for the K8s metadata and labels data.
    </p>
  ),
  image: DatasetIDImage,
  imageHeight: 246,
};

export default K8sIntegrationPageHelp;
