import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import Modal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';

const EditLinkedAccountNameModal = ({ onSave, onClose, title, linkedAccountName, open }) => {
  const [name, setName] = useState('');
  useEffect(() => {
    setName(linkedAccountName);
  }, [linkedAccountName]);
  return (
    <Modal
      title={`Edit ${title
        .split(' ')
        .map((word) => capitalize(word))
        .join(' ')}`}
      onSave={() => onSave(name)}
      open={open}
      onClose={onClose}
      saveDisabled={!name}
    >
      <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter name" />
    </Modal>
  );
};

EditLinkedAccountNameModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  linkedAccountName: PropTypes.string.isRequired,
};

export default EditLinkedAccountNameModal;
