import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from 'shared/components/Select';
import { PreferencesKeys, PreferencesTypes } from 'users/constants/usersConstants';
import { addPreference, usePreferences } from 'users/hooks/react-query/usePreferences';
import CustomModal from 'shared/components/andtComponents/Modal';
import { CALC_METHODS } from '../constants/podPreferences.constants';

const AddOrUpdatePodPreferenceModal = ({
  isOpen,
  toggle,
  updateMode,
  usersStore,
  item,
  filtersValuesMap,
  namespacePreferences,
  allNamespacesPreferences,
  uuid,
}) => {
  const namespaces = filtersValuesMap.get('namespace') || [];
  const [namespace, setNamespace] = useState(updateMode ? item.namespace : null);
  const [calcMethod, setCalcMethod] = useState(updateMode ? item.calcMethod : null);
  const { useSaveMutation, fetchPreferences } = usePreferences();
  const handleSave = useSaveMutation();
  const { data: preferences, isLoading } = fetchPreferences(usersStore.currDispUserAccountKey);
  const handleSavePodPreferences = async () => {
    await handleSave.mutateAsync(
      addPreference(preferences, {
        preferences: {
          namespacePreferences: {
            ...allNamespacesPreferences,
            [namespace]: { calcMethod },
          },
        },
        preferenceKey: PreferencesKeys.POD_COST_CALCULATION,
        preferenceType: PreferencesTypes.K8S,
        uuid,
      }),
    );
    toggle();
  };
  const formattedNamespaces = namespaces
    .filter(
      (v) =>
        handleSave.isLoading ||
        !namespacePreferences.some((p) => p.namespace === v) ||
        (updateMode && v === item.namespace),
    )
    .map((v) => ({
      label: v,
      value: v,
    }));
  formattedNamespaces.sort((a, b) => a.value.localeCompare(b.value));
  if (isLoading) {
    return null;
  }
  return (
    <CustomModal
      open={isOpen}
      onClose={toggle}
      title={`${updateMode ? 'Update' : 'Add'} Pod Calculation Preferences`}
      saveDisabled={!namespace || !calcMethod || handleSave.isLoading}
      onSave={handleSavePodPreferences}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={(e) => e.stopPropagation()}>
        <div>
          <CustomSelect
            value={namespace}
            disabled={updateMode || handleSave.isLoading}
            className="mb-4"
            label="Namespace"
            onChange={(e) => {
              setNamespace(e.target.value);
            }}
            options={formattedNamespaces}
          />
        </div>
        <div>
          <CustomSelect
            value={calcMethod}
            disabled={handleSave.isLoading}
            label="Calculation Method"
            onChange={(e) => {
              setCalcMethod(e.target.value);
            }}
            options={CALC_METHODS}
          />
        </div>
      </div>
    </CustomModal>
  );
};

AddOrUpdatePodPreferenceModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  namespacePreferences: PropTypes.array.isRequired,
  item: PropTypes.object,
  updateMode: PropTypes.bool,
  filtersValuesMap: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  uuid: PropTypes.string,
  allNamespacesPreferences: PropTypes.array,
};
AddOrUpdatePodPreferenceModal.defaultProps = {
  item: null,
  updateMode: false,
  uuid: null,
  allNamespacesPreferences: [],
};

export default AddOrUpdatePodPreferenceModal;
