/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RdsClassChangeRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class RdsClassChangeRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RdsClassChangeRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.RDS_CLASS_CHANGE;
  }

  buildRecommTitle() {}

  build() {
    super.build();
    const {
      allocated_storage: allocatedStorage,
      command,
      command_comment: commandComment,
      cpu,
      cpu_max: cpuMax,
      cpu_avg_data: cpuAvgData,
      cpu_max_data: cpuMaxData,
      cpu_p95_data: cpuPercentile95Data,
      cpu_p99_data: cpuPercentile99Data,
      current_annual_cost: currentAnnualCost,
      days_to_check: daysToCheck,
      db_connections_avg_data: dbConnectionsAvgData,
      db_connections_max_data: dbConnectionsMaxData,
      db_connections_p95_data: dbConnectionsPercentileData,
      engine,
      instance_type_family: instanceTypeFamily,
      instance_family: instanceFamily,
      instance_type: instanceType,
      is_multi_az: isMultiAz,
      max_allocated_storage: maxAllocatedStorage,
      max_iops: maxIops,
      iops_avg_data: iopsAvgData,
      iops_max_data: iopsMaxData,
      iops_p95_data: iopsPercentileData,
      mem_max: memMax,
      mem_avg_data: memAvgData,
      mem_max_data: memMaxData,
      mem_p95_data: memPercentileData,
      memory,
      network_avg_data: networkAvgData,
      network_max_data: networkMaxData,
      network_p95_data: networkPercentileData,
      physical_processor: physicalProcessor,
      recommended_instance_type: recommendedInstanceType,
      resource_arn: resourceArn,
      resource_id: resourceId,
      ri_coverage: riCoverage,
      starting_time: startingTime,
      storage_type: storageType,
      throughput_avg_data: throughputAvgData,
      throughput_max_data: throughputMaxData,
      throughput_p95_data: throughputPercentileData,
      zone_tag: region,
      cpu_p95: cpuP95,
      mem_p95: memP95,
    } = this._rawRecommendation;

    this._recommendation.allocatedStorage = allocatedStorage;
    this._recommendation.command = command;
    this._recommendation.commandComment = commandComment;
    this._recommendation.cpu = cpu;
    this._recommendation.cpuMax = cpuMax;
    this._recommendation.cpuAvgData = cpuAvgData;
    this._recommendation.cpuMaxData = cpuMaxData;
    this._recommendation.cpuPercentile95Data = cpuPercentile95Data;
    this._recommendation.cpuPercentile99Data = cpuPercentile99Data;
    this._recommendation.currentAnnualCost = currentAnnualCost;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.dbConnectionsAvgData = dbConnectionsAvgData;
    this._recommendation.dbConnectionsMaxData = dbConnectionsMaxData;
    this._recommendation.dbConnectionsPercentileData = dbConnectionsPercentileData;
    this._recommendation.engine = engine;
    this._recommendation.isMultiAz = isMultiAz;
    this._recommendation.iopsAvgData = iopsAvgData;
    this._recommendation.iopsMaxData = iopsMaxData;
    this._recommendation.iopsPercentileData = iopsPercentileData;
    this._recommendation.instanceTypeFamily = instanceTypeFamily;
    this._recommendation.instanceFamily = instanceFamily;
    this._recommendation.instanceType = instanceType;
    this._recommendation.maxAllocatedStorage = maxAllocatedStorage;
    this._recommendation.maxIops = maxIops;
    this._recommendation.memMax = memMax;
    this._recommendation.memAvgData = memAvgData;
    this._recommendation.memMaxData = memMaxData;
    this._recommendation.memPercentileData = memPercentileData;
    this._recommendation.memory = memory;
    this._recommendation.networkAvgData = networkAvgData;
    this._recommendation.networkMaxData = networkMaxData;
    this._recommendation.networkPercentileData = networkPercentileData;
    this._recommendation.physicalProcessor = physicalProcessor;
    this._recommendation.recommendedInstanceType = recommendedInstanceType;
    this._recommendation.region = region;
    this._recommendation.resourceArn = resourceArn;
    this._recommendation.resourceId = resourceId;
    this._recommendation.riCoverage = riCoverage;
    this._recommendation.startingTime = startingTime;
    this._recommendation.storage = `${storageType} allocated ${allocatedStorage}`;
    this._recommendation.storageType = storageType;
    this._recommendation.throughputAvgData = throughputAvgData;
    this._recommendation.throughputMaxData = throughputMaxData;
    this._recommendation.throughputPercentileData = throughputPercentileData;
    this._recommendation.cpuP95 = cpuP95;
    this._recommendation.memP95 = memP95;
  }
}
