/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-parens */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { isDecimalNeeded } from 'shared/utils/strUtil';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';

const MemoryChart = ({ data }) => (
  <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
    <LineChart width={550} height={284} data={data} margin={{ top: 20, right: 20, bottom: 0, left: 20 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="usageDate" tick={<CustomizedAxisTick fontSize={12} />} />
      <YAxis
        // type="number"
        label={{
          value: 'Memory Utilization',
          offset: 0,
          angle: -90,
          position: 'left',
        }}
        tickFormatter={(value) => `${value} %`}
      />
      <Tooltip formatter={(value) => (value ? `${value.toFixed(isDecimalNeeded(value, 2) ? 2 : 0)} %` : '-')} />
      <Legend />
      <Line type="linear" dataKey="usage" dot={false} stroke="#541aaf" strokeWidth={2} name="Utilization" />
      <Line type="linear" dataKey="usageAvg" dot={false} stroke="#1f5189" strokeWidth={2} name="Average Utilization" />
    </LineChart>
  </ResponsiveContainer>
);

MemoryChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MemoryChart;
