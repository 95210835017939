import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Col, Input, InputGroup } from 'reactstrap';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import CustomModal from 'shared/components/andtComponents/Modal';
import { cloneDeep } from 'lodash';
import { useFiltersCustomTagsBySearchStr } from 'invoices/hooks/react-query/useInvoiceFilters';
import SubViewAddOrEditModal from './SubViewCreateEditModal';
import SubViewsWrapper from './SubViewsWrapper';
import ViewSubmitEditModal from './ViewSubmitEditModal';

const ViewCreateEditModal = ({
  usersStore,
  view,
  handlers,
  isOpen,
  getCustomTagsKeys,
  nameValidError: viewNameValidError,
}) => {
  const [subViewForEdit, setSubViewForEdit] = useState(null);
  const [isSubViewAddOrEditModalOpen, setIsSubViewAddOrEditModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [subViewNameValidError, setSubViewNameValidError] = useState('');
  const [subViewForDelete, setSubViewForDelete] = useState(null);
  const [viewUpdating, setViewUpdating] = useState(false);
  const { customTagsFetcher } = useFiltersCustomTagsBySearchStr();
  const { onLoad, values: customTags, isLoading: customTagsLoading } = customTagsFetcher;

  const handleChange = (value, handler) => {
    handler(value);
  };

  const handleSaveSubView = (subView) => {
    const currSubViews = cloneDeep(view.subViews);
    const subViewForUpdate = { ...subView, values: subView.values.map((value) => value.value) };
    const subViewForUpdateIdx = currSubViews.findIndex(({ uuid }) => uuid === subView.uuid);
    if (subViewForUpdateIdx > -1) {
      currSubViews.splice(subViewForUpdateIdx, 1, subViewForUpdate);
    } else {
      currSubViews.push(subViewForUpdate);
    }
    handleChange(currSubViews, handlers.updateSubViews);
    setSubViewForEdit(null);
    setIsSubViewAddOrEditModalOpen(false);
  };

  const handleAddSubView = () => {
    setIsSubViewAddOrEditModalOpen(true);
  };

  const handleEditSubView = (subView) => {
    const subViewForModify = cloneDeep(subView);
    subViewForModify.values = subViewForModify.values.map((value) => ({ label: value, value }));
    handlers.selectTagKey(view.tagKey, false);
    setIsSubViewAddOrEditModalOpen(true);
    setSubViewForEdit(subViewForModify);
  };

  const deleteSubView = (subView) => setSubViewForDelete(subView);

  const subViewHandlers = useRef({
    edit: handleEditSubView,
    del: deleteSubView,
  });

  const handleDeleteSubView = (action) => {
    if (action === 'delete') {
      const updatedSubViews = view?.subViews?.filter((subView) => subView.uuid !== subViewForDelete.uuid);
      handleChange(updatedSubViews, handlers.updateSubViews);
    }
    setSubViewForDelete(null);
  };

  return (
    <>
      <CustomModal
        open={isOpen}
        closeOnOutside={false}
        overrideStyles={{
          width: 800,
        }}
        size="big"
        onClose={() => handlers.closeModal()}
        closeOnSave={false}
        title="Create View"
        onSave={() => {
          if (view && view.uuid) {
            setViewUpdating(true);
          } else {
            handlers.saveView();
          }
        }}
        saveDisabled={
          viewNameValidError || (view && (!view.tagKey || !view?.subViews || !view?.subViews.length || !view.name))
        }
      >
        <>
          <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
            <Col style={{ textAlign: 'start', paddingLeft: 0 }} xl={2} lg={2}>
              <span style={{ textAlign: 'start', marginInlineEnd: '20px' }}>View name:</span>
            </Col>
            <InputGroup style={{ width: 'fit-content', marginBottom: '5px' }}>
              <Input
                type="text"
                onChange={(e) => handleChange(e.target.value, handlers.changeViewName)}
                value={view && view.name}
                placeholder="Enter view name"
              />
            </InputGroup>
            {viewNameValidError && (
              <p style={{ width: '100%', textAlign: 'start', color: 'red', marginBlock: '15px' }}>
                {viewNameValidError}
              </p>
            )}
          </div>
          <div style={{ display: 'flex', marginBottom: '30px' }} className="align-items-center">
            <Col style={{ textAlign: 'start', paddingLeft: 0 }} xl={2} lg={2}>
              <span style={{ textAlign: 'start', marginInlineEnd: '20px' }}>Tag Key:</span>
            </Col>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                }),
              }}
              value={view.tagKey}
              options={(getCustomTagsKeys() || []).map((tagKey) => ({ label: tagKey, value: tagKey }))}
              isSearchable
              placeholder={view && (view.tagKey || 'Select Tag Key')}
              isClearable
              onChange={(selected) => {
                onLoad('', selected.value);
                handlers.selectTagKey(selected.value, true);
              }}
            />
          </div>
          <div>
            <Button outline color="primary" size="sm" disabled={!view.tagKey} onClick={handleAddSubView}>
              Add Sub View
            </Button>
          </div>
          <div>
            <SubViewsWrapper
              isActionsButton
              usersStore={usersStore}
              isBlock="true"
              subViews={view.subViews}
              handlers={subViewHandlers.current}
            />
          </div>
        </>
      </CustomModal>
      {isSubViewAddOrEditModalOpen && (
        <SubViewAddOrEditModal
          isOpen={isSubViewAddOrEditModalOpen}
          onClose={() => setIsSubViewAddOrEditModalOpen(false)}
          saveSubView={handleSaveSubView}
          subViewForEdit={subViewForEdit}
          tagsLoading={customTagsLoading}
          customTags={customTags}
          nameValidError={subViewNameValidError}
          view={view}
        />
      )}
      {subViewForDelete && (
        <DeleteWarningModal
          isOpen={subViewForDelete}
          handleDelete={handleDeleteSubView}
          modalTitle="Delete Sub View"
          warningMessage={`Be advised, You are about to delete ${
            (subViewForDelete && subViewForDelete.name) || ''
          } Sub View`}
          executeButtonTitle="Delete"
        />
      )}
      <ViewSubmitEditModal
        isOpen={viewUpdating}
        onClose={() => setViewUpdating(false)}
        handleApplyForAll={() => handlers.saveView()}
        handleApplyFromNow={() => handlers.saveView(true)}
      />
    </>
  );
};

ViewCreateEditModal.propTypes = {
  usersStore: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  getCustomTagsKeys: PropTypes.func.isRequired,
  nameValidError: PropTypes.string.isRequired,
};

ViewCreateEditModal.defaultProps = {
  isOpen: false,
};

export default ViewCreateEditModal;
