/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len,arrow-parens */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import ButtonDropdown from 'shared/components/ButtonDropdown';
import { ChevronDown } from 'react-feather';
import { Col, Collapse } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';

const UsageSelectStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0px',
  padding: '0 10px 0 0',
};

export default class CostUsageDisplayPanel extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    usageItemSelectChangeHandler: PropTypes.func,
    SelectedUsageType: PropTypes.string,
    toggleChartOrTable: PropTypes.func.isRequired,
    isDisplayTable: PropTypes.bool.isRequired,
    isAreaChart: PropTypes.bool.isRequired,
    isPieChart: PropTypes.bool,
    isLineChart: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    usageItemSelectChangeHandler: null,
    SelectedUsageType: undefined,
    isPieChart: false,
    className: '',
    isLineChart: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  onClickHandler = (value) => {
    this.props.toggleChartOrTable(value);
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  getCurrLabelDisplayed = () => {
    // this function assumes only one of the following is true:
    const { isDisplayTable, isAreaChart, isPieChart, isLineChart } = this.props;

    switch (true) {
      case isDisplayTable:
        return ' Table';
      case isAreaChart:
        return ' Stacked Area';
      case isPieChart:
        return ' Pie Chart';
      case isLineChart:
        return ' Line Chart';
      default:
        return ' Stacked Bar';
    }
  };

  handleClick = (item, handler) => {
    handler(item);
  };

  toggleUsageDropDown = () => {
    let { isInitialFlip } = this.state;
    isInitialFlip = false;
    this.setState({
      collapse: !this.state.collapse,
      isInitialFlip,
    });
  };

  renderItems = (items, handler) =>
    items.map((item) => (
      <p className="collapse-item" onClick={() => this.onClickHandler(item.value)} key={item.value}>
        {item.name}
      </p>
    ));

  render() {
    const { title, items, usageItemSelectChangeHandler, SelectedUsageType } = this.props;
    const { collapse, isInitialFlip } = this.state;
    const UsageSelectStyleToggle = { ...UsageSelectStyle };
    return (
      <div className={`cost-usage-display-panel ${this.props.className}`}>
        <Col style={UsageSelectStyleToggle}>
          <Col style={{ paddingLeft: '0px', paddingRight: '10px' }} xs="auto" md="auto" lg="auto" xl="auto">
            <p>{title}</p>
          </Col>
          <Col
            style={{ padding: '3px 0px', cursor: 'pointer' }}
            xs="auto"
            md="auto"
            lg="auto"
            xl="auto"
            onClick={() => this.toggleUsageDropDown()}
          >
            <p style={{ cursor: 'pointer', fontWeight: 'bolder', letterSpacing: '0.5px' }}>
              <span>{this.getCurrLabelDisplayed()}</span>
            </p>
          </Col>
          <Col style={{ padding: '1px 0px' }} xs="auto" md="auto" lg="auto" xl="auto" className="ms-2">
            <LinkIconButton className="topbar__avatar" onClick={this.toggleUsageDropDown} style={{ cursor: 'pointer' }}>
              <p onClick={this.toggleUsageDropDown} style={{ cursor: 'pointer', fontWeight: 'bolder' }} />
              <ChevronDown size={16} style={{ cursor: 'pointer' }} />
            </LinkIconButton>
          </Col>
        </Col>
        {collapse && <button className="topbar__back" onClick={this.toggleUsageDropDown} />}
        <Collapse isOpen={collapse} className="menu-wrap-align-to-left" style={{ top: '30px', right: '-4vw' }}>
          <div className="drop__menu">{this.renderItems(items, usageItemSelectChangeHandler)}</div>
        </Collapse>
      </div>
    );
  }
}
