/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      You are still being charged for disabled KMS that are no longer activated, and we recommend to schedule deleting
      them.
      <br />
      By running the following command, these KMS keys will be scheduled to be deleted within 7-30 days.
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
