import React, { useEffect, useState } from 'react';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import useSpAnalyzer from 'commitment/containers/spAnalyzerNew/hooks/useSpAnalyzer';
import { usePreferences } from 'users/hooks/react-query/usePreferences';
import { useRootStore } from 'app/contexts/RootStoreContext';
import AnalyzerFilters from './AnalyzerFilters';
import RecommendedPlanData from './RecommendedPlanData';
import SpSpecialMode from './SpSpecialMode';
import NoteModal from 'shared/components/noteModal/NoteModal';
import styles from './recommendedPlanData.module.scss';
import { SpLoader } from './SpLoader';
import _ from 'lodash';
import { PLAN_KEYS_SORT_ORDER } from "commitment/containers/spAnalyzerNew/utils/consts.js";

const RecommendedPlan = () => {
  const { usersStore, appStore } = useRootStore();
  const [isShowNoteMsg, setIsShowNoteMsg] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const { fetchPreferences } = usePreferences();
  const { data: preferences, isLoading: isPreferencesLoading } = fetchPreferences(usersStore.currDispUserAccountKey);
  const {
    selectedOptions,
    filterStatusType,
    daysToCheck,
    coverage,
    paymentOptions,
    terms,
    hourlyCommitment,
    setHourlyCommitment,
    setPreferences,
    setRecommendationPlanData,
    setPlanDataByKey,
    setModifiedPlanKeyHourlyCommitment,
  } = spAnalyzerContext();

  const { fetchSavingsPlansAnalyzerData } = useSpAnalyzer();
  const { data, isLoading, refetch, isFetching } = fetchSavingsPlansAnalyzerData(
    selectedOptions,
    daysToCheck,
    filterStatusType,
    paymentOptions,
    terms,
    coverage,
    hourlyCommitment,
  );

  const fetchDataForExport = () => {
    let exportData;
    exportData = data.recommendationPerPlan;

    const exportColumns = [
      { key: 'key' , label: 'Plan' },
      { key: 'totalSaving' , label: 'Estimated Savings' },
      { key: 'hourlyCommitment' , label: 'Hourly Commitment' },
      { key: 'esr' , label: 'ESR' }
    ];
    // prepare data for export
    const dataToExport = Object.keys(exportData || {}).sort((a, b) => PLAN_KEYS_SORT_ORDER.indexOf(a) - PLAN_KEYS_SORT_ORDER.indexOf(b))
        .map((plan) => {
      return {
        key: _.toUpper(_.startCase(plan)),
        hourlyCommitment: exportData[plan]?.recommendedHourlyCommitment,
        totalSaving: exportData[plan] ? Number(data.simulatedCurrentMonthlyNetCost - exportData[plan]?.recommendedMonthlyNetCost).toFixed(2) : '',
        esr: exportData[plan]?.potentialSavings,
      };
    });
    return [
      {
        data: dataToExport || [],
        filename: 'sp-analyzer-recommendations-plans.csv',
        headers: [...exportColumns],
      },
    ];
  }

  useEffect(() => {
    if (data) {
      setRecommendationPlanData(data);
    }
  }, [data]);

  useEffect(() => {
    if (preferences) {
      const savingPreferences = preferences.find((pref) => pref.preferenceKey === 'ec2-savings-plans');
      setPreferences(savingPreferences?.preferences || {});
    }
  }, [preferences]);

  useEffect(() => {
    if (appStore.isCustomerPricingMode) {
      setIsShowNoteMsg(true);
    }
  }, [appStore.isCustomerPricingMode]);
  const isPlanDataEmpty = () => {
    if (!data || Object.keys(data).length === 0) {
      return true;
    }
    return !!(data.recommendationPerPlan && Object.values(data.recommendationPerPlan).every((value) => value === null));
  };
  const componentToRender = () => {
    if (isPreferencesLoading || isLoading || isFetching || isShowLoader) {
      return (
        <div className={styles.loaderWrapper}>
          <SpLoader dataReceived={!isPlanDataEmpty()} setIsLoading={setIsShowLoader} />
        </div>
      );
    }
    if (isPlanDataEmpty()) {
      return (
        <SpSpecialMode
          isEmpty
          specialText={
            <>
              <span>Based on the selected filters, your expenses are already well-covered by savings plans.</span>
              <span>Therefore, we don’t have any new plans to recommend.</span>
            </>
          }
          isLongText
          type="emptyState"
        />
      );
    }
    return <RecommendedPlanData />;
  };
  return (
    <div>
      <AnalyzerFilters
        analyze={() => {
          setHourlyCommitment(null);
          setPlanDataByKey({});
          setModifiedPlanKeyHourlyCommitment({});
          refetch();
        }}
        disabled={isLoading || isFetching}
        fetchExportData={fetchDataForExport}
      />
      {componentToRender()}
      {isShowNoteMsg && (
        <NoteModal
          close={() => setIsShowNoteMsg(false)}
          btnText="Got it"
          message={
            <>
              For best results, use the SP Analyzer in <b>Partner Pricing mode</b> or within the <b>Customer scope.</b>
            </>
          }
        />
      )}
    </div>
  );
};

export default RecommendedPlan;
