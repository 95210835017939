import { saveRecommendationsLabel } from 'recommendations/hooks/react-query/api';
import RecommBuilderFactory from 'recommendations/models/recommBuilderFactory';
import { descendingNumSortComparatorFunc } from 'shared/utils/sortUtil';

export const DEFAULT_PAGE_SIZE = 50;

export const saveRecommsLabel = async (recommendations, labels, recSignaturesForLabel, isFromBatch = false) => {
  try {
    const signatures = recSignaturesForLabel.map((s) => {
      const currentRecommendation = recommendations.find((rec) => rec.signature === s);
      const currRecommLabels = currentRecommendation.labels || [];
      return {
        signature: s,
        labels: isFromBatch ? [...currRecommLabels, ...labels] : labels,
      };
    });
    return await saveRecommendationsLabel(signatures);
  } catch (error) {
    return false;
  }
};

export const prepareRecommendation = (rawRecommendation, recommType) => {
  const recommendationBuilder = RecommBuilderFactory.create(rawRecommendation, recommType);
  if (recommendationBuilder) {
    recommendationBuilder.build();
    return recommendationBuilder.recommendation;
  }
  return null;
};

export const prepareRecommendations = (rawRecommendations) => {
  const recommendations = [];
  rawRecommendations.forEach((rawRecommendation) => {
    const { type: recommType } = rawRecommendation;
    const recommendation = prepareRecommendation(rawRecommendation, recommType);
    if (recommendation) {
      recommendations.push(recommendation);
    }
  });
  return recommendations;
};

export const getFilteredRecsByTypeAndLinkedAcc = (recommendations, type, linkedAccountId) => {
  if ((!type && !linkedAccountId) || (type === 'All' && linkedAccountId === 'All')) {
    return recommendations;
  }
  if (linkedAccountId === 'All') {
    return recommendations.filter((rec) => rec.type === type);
  }
  if (type === 'All') {
    return recommendations.filter((rec) => rec.linkedAccountId === linkedAccountId);
  }
  return recommendations.filter((rec) => rec.type === type && rec.linkedAccountId === linkedAccountId);
};

export const sortRecommendations = (recommendations) =>
  recommendations?.sort((curr, prev) =>
    descendingNumSortComparatorFunc(
      curr.currTotalCost - curr.recommTotalCost,
      prev.currTotalCost - prev.recommTotalCost,
    ),
  );

export const getDistinctLinkedAccIds = (recommendations, linkedAccounts) => {
  const linkedAccountIds = recommendations?.map((rec) => rec.linkedAccountId).filter((id) => !!id);
  const arrLinkedAccountIds = Array.from(new Set(linkedAccountIds));
  return arrLinkedAccountIds?.map((accId) => {
    const linkAccObj = linkedAccounts?.find((linkAcc) => linkAcc.linkedAccountId === accId);
    return { label: linkAccObj?.linkedAccountName || accId, value: accId };
  });
};

export const getDistinctTypes = (recommendations) => {
  const types = recommendations.map((rec) => rec.type);
  return Array.from(new Set(types));
};
