import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Input from 'shared/components/andtComponents/Input';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { AZURE_ONBOARDING_FIELDS } from '../../AzureOnboardingConstants';
import commonStyles from '../Pages.module.scss';
import AppRegPageHelp from './AppRegPageHelp';
import styles from './AppRegPage.module.scss';
import { getTenantParams } from '../../../utils/OnboardingUtils';

const AppRegPage = () => {
  const {
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { usersStore } = useRootStore();
  useWatch(AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME);
  const accountName = getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME);
  useEffect(() => {
    const { tenantName, tenantSchemaName } = getTenantParams(usersStore.currentDisplaydUser, accountName);
    setValue(AZURE_ONBOARDING_FIELDS.TENANT_NAME, tenantName);
    setValue(AZURE_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME, tenantSchemaName);
  }, [accountName]);

  const renderInput = ({ fieldName, name, label, icon }) => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            value={getValues(fieldName)}
            isInvalid={errors?.[fieldName] && errors?.[fieldName] !== 'GeneralError'}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={(e) => {
              setValue(fieldName, e.target.value);
              clearErrors(fieldName);
            }}
            placeholder=""
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 20,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        list={[
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.APPLICATION_DISPLAY_NAME,
                name: 'onboarding-app-display-name',
                label: 'Application Display name',
                icon: 'fileLines',
              }),
            key: 'onboarding-app-display-name',
          },
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.APPLICATION_ID,
                name: 'onboarding-app-id',
                label: 'Application (Client) ID',
                icon: 'cloud',
              }),
            key: 'onboarding-app-id',
          },
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.DIRECTORY_ID,
                name: 'onboarding-directory-id',
                label: 'Directory (Tenant) ID',
                icon: 'server',
              }),
            key: 'onboarding-directory-id',
          },
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.CLIENT_SECRET,
                name: 'onboarding-client-secret',
                label: 'Client Secret Value',
                icon: 'key',
              }),
            key: 'onboarding-client-secret',
          },
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME,
                name: 'onboarding-account-name',
                label: 'Account name displayed in Anodot',
                icon: 'fileLines',
              }),
            key: 'onboarding-account-name',
          },
        ]}
        help={{
          'onboarding-app-display-name': AppRegPageHelp.appDisplayName,
          'onboarding-app-id': AppRegPageHelp.appID,
          'onboarding-directory-id': AppRegPageHelp.directoryID,
          'onboarding-client-secret': AppRegPageHelp.clientSecret,
          'onboarding-account-name': AppRegPageHelp.accountName,
        }}
      />
    </div>
  );
};

export default AppRegPage;
