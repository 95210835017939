/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';
// TODO update description

const Description = ({ rec }) => {
  const isShowCommitmentMessage = rec.riSavings || rec.spSavings;
  let riSpText = '';
  if (isShowCommitmentMessage) {
    riSpText = rec.riSavings ? 'RI' : '';
    riSpText = rec.spSavings ? riSpText.concat(`${rec.riSavings ? ' & ' : ''}SP`) : riSpText;
  }
  return (
    <DescriptionWrapper>
      {isShowCommitmentMessage && (
        <>
          <p>
            <b>{`Currently this instance is using ${riSpText}, moving to a new model will reduce ${riSpText} usage`}</b>
          </p>
          <p style={{ margin: '0', fontWeight: 'bold' }}>
            <span>{rec.riSavings ? `RI Coverage - ${rec.riCoverage}%` : ''}</span>
            <span>{rec.spSavings ? `, SP Coverage - ${rec.spCoverage}%` : ''}</span>
          </p>
        </>
      )}
      <p>
        This Ec2 instance has a low CPU utilization, we recommend to switch to a more suitable instance type which will
        cost less.
      </p>
    </DescriptionWrapper>
  );
};

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
