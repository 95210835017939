import {
  AMORTIZE_COST_TYPES,
  AMORTIZE_COST_TYPES_ITEMS,
  CostUsageStates,
  K8S_GROUP_BY_LOV,
} from 'usage/constants/costAndUsageConstants';
import { K8sCommonFields } from 'shared/constants/k8sConstants';
import { GcpCommonFields } from 'shared/constants/gcpConstants';
import { strNumToSize, numberWithCommas } from 'shared/utils/strUtil';

export const mapCostBreakDownGroupByValueToDisplay = new Map([
  [CostUsageStates.CLUSTER, K8S_GROUP_BY_LOV.BY_CLUSTER],
  [CostUsageStates.NAMESPACE, K8S_GROUP_BY_LOV.BY_NAMESPACE],
  [CostUsageStates.NODE_GROUP, K8S_GROUP_BY_LOV.BY_NODE_GROUP],
]);
export const mapCostBreakDownGroupByDisplayToValue = new Map([
  [K8S_GROUP_BY_LOV.BY_CLUSTER, CostUsageStates.CLUSTER],
  [K8S_GROUP_BY_LOV.BY_NAMESPACE, CostUsageStates.NAMESPACE],
  [K8S_GROUP_BY_LOV.BY_NODE_GROUP, CostUsageStates.NODE_GROUP],
]);

export const COST_BREAKDOWN_TABLE_COLUMNS = [
  { name: 'groupBy', title: 'Name' },
  { name: 'totalCost', title: 'Total Cost' },
  { name: 'totalCostCompute', title: 'Compute Cost' },
  { name: 'totalCostOther', title: 'Data traffic & Storage Cost' },
  {
    name: 'memoryTotalUsage',
    title: 'Memory Actual',
    getCellValue: ({ memoryTotalUsage }) => strNumToSize(memoryTotalUsage, 3),
  },
  {
    name: 'memoryTotalWaste',
    title: 'Memory Waste',
    getCellValue: ({ memoryTotalWaste }) => strNumToSize(memoryTotalWaste, 3),
  },
  {
    name: 'cpuTotalUsage',
    title: 'CPU Actual',
    getCellValue: ({ cpuTotalUsage }) => numberWithCommas(+cpuTotalUsage / 1000, 3),
  },
  {
    name: 'cpuTotalWaste',
    title: 'CPU Waste',
    getCellValue: ({ cpuTotalWaste }) => numberWithCommas(+cpuTotalWaste / 1000, 3),
  },
];
export const COST_BREAKDOWN_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'groupBy', width: 250 },
  { columnName: 'totalCost', width: 300 },
  { columnName: 'totalCostCompute', width: 300 },
  { columnName: 'totalCostOther', width: 300 },
  { columnName: 'memoryTotalUsage', width: 300 },
  { columnName: 'memoryTotalWaste', width: 300 },
  { columnName: 'cpuTotalUsage', width: 300 },
  { columnName: 'cpuTotalWaste', width: 300 },
];

export const COST_BREAKDOWN_TABLE_COLUMNS_EXTENSIONS = [
  { columnName: 'totalCost', align: 'center' },
  { columnName: 'totalCostCompute', align: 'center' },
  { columnName: 'totalCostOther', align: 'center' },
];
export const COST_BREAKDOWN_TABLE_COST_COLUMNS = ['totalCost', 'totalCostCompute', 'totalCostOther'];

export const K8S_USAGE_TYPES = {
  MEMORY: 'Memory',
  CPU: 'CPU',
  COST: 'Cost',
  BYTES: 'Bytes',
  QUANTITY: 'Quantity',
  QUANTITY_PODS: 'Pods Quantity',
  QUANTITY_NAMESPACES: 'Namespaces Quantity',
  QUANTITY_CLUSTERS: 'Clusters Quantity',
  QUANTITY_NODES: 'Nodes Quantity',
  HOURS: 'Pod Hours',
};
export const k8sNodesFields = [
  K8sCommonFields.NODE,
  K8sCommonFields.NODE_GROUP,
  K8sCommonFields.INSTANCE_TYPE,
  GcpCommonFields.K8S_INSTANCE_TYPE,
];
export const k8sGroupByNodesFields = [...k8sNodesFields]; // for group by list
export const k8sFiltersNodesFields = [...k8sNodesFields];
export const k8sUsageNodesFields = [K8S_USAGE_TYPES.QUANTITY_CLUSTERS, K8S_USAGE_TYPES.QUANTITY_NODES];
export const k8sGranularityNodesFields = [...k8sFiltersNodesFields, ...k8sUsageNodesFields];

export const k8sPodsFields = [K8sCommonFields.POD, K8sCommonFields.NAMESPACE];
export const k8sGroupByPodsFields = [...k8sPodsFields, 'K8S Labels']; // for group by list
export const k8sFiltersPodsFields = [...k8sPodsFields, 'labels'];
export const k8sUsagePodsFields = [
  K8S_USAGE_TYPES.QUANTITY_PODS,
  K8S_USAGE_TYPES.QUANTITY_NAMESPACES,
  K8S_USAGE_TYPES.HOURS,
];
export const k8sGranularityPodsFields = [...k8sFiltersPodsFields, ...k8sUsagePodsFields];

export const K8S_USAGE_QUANTITY_TYPES = {
  BYTES: 'Bytes',
  COST: 'Cost',
  CORES: 'Cores',
  HOURS: 'Pod Hours',
};

export const AmortizeCostTypesK8SItems = Object.values(AMORTIZE_COST_TYPES_ITEMS).filter(
  (item) => item.value.toLowerCase() !== AMORTIZE_COST_TYPES.PUBLIC_COST.toLowerCase() && item.value.toLowerCase() !== AMORTIZE_COST_TYPES.DISTRIBUTED.toLowerCase(),
);
