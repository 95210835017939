import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TopBarSearch from 'shared/components/TopBarSearch';
import { Col, Collapse, Row } from 'reactstrap';
import InfiniteList from 'shared/components/InfiniteList';
import toast from './andtComponents/Toast';

const AddMoreFiltersList = ({
  handleAddOrRemoveMoreFilters,
  toggleOrForce,
  collapse,
  selected,
  listOfValues,
  groupBy,
  isFiltersAmountLimit,
  filtersAmountLimit,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [newSelectedFilters, setNewSelectedFilters] = useState([]);

  useEffect(() => {
    setNewSelectedFilters(selected);
  }, [selected]);

  const getUnSelectedLengthItemsFromList = (list) => list.filter((item) => !item.checked).length;

  const filterSearchValues = (values) => {
    // filter list of values list according to serach value
    const filteredList = values.filter((val) => {
      let result = '';
      try {
        const fullString = val.name.toLowerCase();
        const subString = searchValue.toLowerCase();
        result = subString.length === 0 || fullString.includes(subString);
      } catch (error) {
        result = '';
      }
      return result;
    });
    return filteredList;
  };

  const handleFilterSelecteChange = (id) => {
    const newFilterId = id;
    const currentIndex = newSelectedFilters.indexOf(newFilterId);
    if (currentIndex === -1) {
      if (!isFiltersAmountLimit || newSelectedFilters.length < filtersAmountLimit) {
        // add filter id to list
        setNewSelectedFilters((prev) => [...prev, newFilterId]);
      } else {
        toast.error(`The items selection is limited to ${filtersAmountLimit}`);
      }
    } else {
      // remove filter from list
      setNewSelectedFilters((prev) => prev.filter((_, i) => i !== currentIndex));
    }
  };

  const clearAllFilters = () => {
    setNewSelectedFilters([]);
  };

  const selectAllFilters = (filteredValues) => {
    const len = newSelectedFilters.length;
    if (len + getUnSelectedLengthItemsFromList(filteredValues) > filtersAmountLimit) {
      toast.error(`The items selection is limited to ${filtersAmountLimit}`);
      return;
    }
    setNewSelectedFilters((prev) => [...prev, ...filteredValues.filter(({ checked }) => !checked).map(({ id }) => id)]);
  };

  const addOrRemoveMoreFiltersHandler = (e) => {
    e.preventDefault();
    // user press Apply button
    toggleOrForce(false);
    setSearchValue('');
    handleAddOrRemoveMoreFilters(newSelectedFilters);
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const renderFunc = () => {
    const filteredValues = filterSearchValues(listOfValues);
    const preparedList = filteredValues.map(({ id, name }) => ({
      id,
      name,
      handler: handleFilterSelecteChange,
      checked: newSelectedFilters.indexOf(id) > -1,
    }));

    return (
      <>
        {collapse && <button type="button" className="topbar__back" onClick={() => toggleOrForce()} />}
        {collapse && (
          <Collapse isOpen={collapse} style={{ zIndex: '110', height: '0px' }}>
            <Row className="list-outer-container">
              <Col className="list-head-search" xs={12} sm={12} md={12} lg={12} xl={12}>
                <TopBarSearch handler={handleSearchInputChange} />
              </Col>
              <Col className="list-head-action-buttons" xs={12} sm={12} md={12} lg={12} xl={12}>
                <button type="button" onClick={clearAllFilters}>
                  Clear all
                </button>
                {!isFiltersAmountLimit ||
                getUnSelectedLengthItemsFromList(preparedList) + newSelectedFilters.length <= filtersAmountLimit ? (
                  <button type="button" onClick={() => selectAllFilters(preparedList)}>
                    Select all
                  </button>
                ) : null}
              </Col>
              <Col className="list-body" xs={12} sm={12} md={12} lg={12} xl={12}>
                <InfiniteList preparedList={preparedList} groupBy={groupBy} />
              </Col>
              <Col className="list-footer-action-buttons" xs={12} sm={12} md={12} lg={12} xl={12}>
                <button type="button" onClick={(e) => addOrRemoveMoreFiltersHandler(e)}>
                  Apply
                </button>
              </Col>
            </Row>
          </Collapse>
        )}
      </>
    );
  };

  return renderFunc();
};
AddMoreFiltersList.propTypes = {
  handleAddOrRemoveMoreFilters: PropTypes.func.isRequired,
  toggleOrForce: PropTypes.func.isRequired,
  collapse: PropTypes.bool.isRequired,
  selected: PropTypes.object.isRequired,
  listOfValues: PropTypes.array.isRequired,
  groupBy: PropTypes.string,
  isFiltersAmountLimit: PropTypes.bool,
  filtersAmountLimit: PropTypes.number,
};

AddMoreFiltersList.defaultProps = {
  filtersAmountLimit: 120,
  isFiltersAmountLimit: false,
  groupBy: null,
};

export default AddMoreFiltersList;
