import { useState, useRef, useEffect } from 'react';

export const useAutoResizeTextArea = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);

  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    const { current } = textAreaRef;
    if (current) {
      const { borderWidth } = getComputedStyle(current);
      const textAreaBorders = parseInt(borderWidth, 10) * 2;
      current.style.height = 'auto';
      current.style.height = `${current.scrollHeight + textAreaBorders}px`;
    }
  };

  useEffect(() => {
    resizeTextArea();
  }, [value]);

  return {
    textAreaRef,
    value,
    setValue,
    resizeTextArea, // Force resize for edge cases
  };
};
