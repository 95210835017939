import React from 'react';
import PropTypes from 'prop-types';
import { S3AttentionComment } from 'recommendationsNew/consts';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const base = 1000;
const S3_MULTIPART_UPLOAD_PROPERTIES = {
  BUCKET_NAME: {
    label: 'Bucket Name',
    getterFunction: (recommendation) => recommendation?.recData?.bucket_name,
  },
  TOTAL_UPLOAD_SIZE: {
    label: 'Total Upload Size',
    isGBSize: true,
    getterFunction: (recommendation) =>
      recommendation?.recData?.total_upload_size
        ? // eslint-disable-next-line no-mixed-operators
          parseInt(recommendation?.recData?.total_upload_size, 10) / base ** 3
        : null,
  },
};

const S3MultipartUpload = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'List the current multipart objects:',
          actionCommand: `aws s3api list-multipart-uploads --bucket ${recommendation?.recData?.bucket_name}`,
        },
        {
          actionText: 'List all the objects in the multipart upload:',
          // eslint-disable-next-line max-len
          actionCommand: `aws s3api list-parts --upload-id UPLOAD_ID --bucket ${recommendation?.recData?.bucket_name} --key KEY`,
        },
        {
          actionText: 'Delete a multipart upload object manually:',
          // eslint-disable-next-line max-len
          actionCommand: `aws s3api abort-multipart-upload --upload-id UPLOAD_ID --bucket ${recommendation?.recData?.bucket_name} --key KEY`,
        },
        {
          additionalText:
            'Besides, it is recommended to create a lifecycle rule that will delete incomplete multipart objects.\n' +
            'More information can be found in the following article ',
          actionText: 'Discovering and Deleting Incomplete Multipart Uploads',
          actionLink:
            // eslint-disable-next-line max-len
            'https://aws.amazon.com/blogs/aws-cloud-financial-management/discovering-and-deleting-incomplete-multipart-uploads-to-lower-amazon-s3-costs/',
        },
      ],
    },
  ];

  const recommendationWithAttentionComment = {
    ...recommendation,
    recData: {
      ...recommendation.recData,
      attention_comment: S3AttentionComment,
    },
  };
  const description =
    // eslint-disable-next-line max-len
    'This S3 Bucket has multipart uploaded parts, its recommended to create a lifecycle rule that will automatically delete incomplete multipart objects.';
  return (
    <BaseRecommendation
      recommendation={recommendationWithAttentionComment}
      description={description}
      commandsList={commandsList}
      dynamicProps={S3_MULTIPART_UPLOAD_PROPERTIES}
    />
  );
};

S3MultipartUpload.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default S3MultipartUpload;
