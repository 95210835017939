import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ADD_USERS_TO_ROLE_MODAL_COLUMNS, getColumns, getDefaultSorting, getSortingColumns } from '../../../consts';
import { ReactComponent as NoRoles } from '../../img/no-roles.svg';

import styles from './AddUsersToRoleModal.module.scss';

export const UsersNoData = (users) => {
  if (!users) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoRoles />
      <span className={styles.mainText}>No users for this role</span>
    </div>
  );
};

const AddUsersToRoleModalTable = ({ users, setSelectedUserIds }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ADD_USERS_TO_ROLE_MODAL_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const onSelectionChange = (selected) => {
    const tmp = !selected?.length ? [] : selected.map((index) => users[index].id);
    setSelectedUserIds(tmp);
  };

  return (
    users && (
      <div className={styles.addUsersToRoleContainer}>
        <Grid rows={users} columns={getColumns(ADD_USERS_TO_ROLE_MODAL_COLUMNS)} automationId="add-users-to-role-list">
          <SortingState
            columnExtensions={getSortingColumns(ADD_USERS_TO_ROLE_MODAL_COLUMNS)}
            defaultSorting={getDefaultSorting(ADD_USERS_TO_ROLE_MODAL_COLUMNS)}
          />
          <SelectionState onSelectionChange={onSelectionChange} />
          <IntegratedSelection />
          <DataTypeProvider
            for={[ADD_USERS_TO_ROLE_MODAL_COLUMNS.USER_NAME.columnName]}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <DataTypeProvider
            for={[ADD_USERS_TO_ROLE_MODAL_COLUMNS.FIRST_NAME.columnName]}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <DataTypeProvider
            for={[ADD_USERS_TO_ROLE_MODAL_COLUMNS.LAST_NAME.columnName]}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <TableWrapper virtual noDataCellComponent={() => UsersNoData(users)} height={400} />
          <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
          <TableColumnResizing
            resizingMode="nextColumn"
            defaultColumnWidths={localColumnsWidth}
            onColumnWidthsChange={setLocalColumnsWidth}
          />
          <TableHeaderRow cellComponent={CustomHeaderCell} />
        </Grid>
      </div>
    )
  );
};

AddUsersToRoleModalTable.propTypes = {
  users: PropTypes.array.isRequired,
};

export default AddUsersToRoleModalTable;
