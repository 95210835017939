/* eslint-disable arrow-parens, no-unused-vars */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { MoreVertical } from 'react-feather';
import { RecommendationStatus } from '../../../../constants/recommendationsConstants';
// import { RecommendationStatus, RecommendationRejectReason } from 'recommendations/constants/recommendationsConstants';
// import DismissRecModal from 'recommendations/shared/components/DismissRecResModal';

class RecActionButton extends React.Component {
  state = {
    anchorEl: null,
  };

  getActionButtonByStatus = (rec) => {
    const { recHandlers } = this.props;
    const { revertRecomm } = recHandlers;
    return (
      <MenuItem
        onClick={() => {
          this.handleMenuItemClick(rec, revertRecomm);
        }}
        className="material-table__filter-menu-item"
      >
        {Number(rec.status) === RecommendationStatus.COMPLETED_BY_SYSTEM ? <h6>Dismiss</h6> : <h6>Revert</h6>}
      </MenuItem>
    );
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (recomm, handler) => {
    this.handleClose();
    handler(recomm);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { recomm } = this.props;
    return (
      <div>
        <IconButton
          className="material-table__toolbar-button"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          size="large">
          <MoreVertical size={22} color="black" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          {this.getActionButtonByStatus(recomm)}
          <Divider />
        </Menu>
      </div>
    );
  }
}

const ObserverRecActionButton = observer(RecActionButton);
export default ObserverRecActionButton;
RecActionButton.propTypes = {
  recomm: PropTypes.object.isRequired,
  recHandlers: PropTypes.object.isRequired,
};
