import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AlertConfiguration } from 'shared/img/images/alert_configuration.svg';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as Alert } from 'shared/img/icons/alert.svg';
import styles from './noAlertRule.module.scss';

const propTypes = {
  openAddAlertRuleModal: PropTypes.func.isRequired,
};

const NoAlertRules = ({ openAddAlertRuleModal }) => (
  <div className="d-flex flex-column align-items-center">
    <AlertConfiguration />
    <span style={{ fontWeight: 500 }}>You haven&#x2019;t set an alert,</span>
    <span className="mb-3" style={{ fontWeight: 500 }}>
      please configure an alert rule in order to receive anomaly alerts to your email
    </span>
    <Button
      onClick={openAddAlertRuleModal}
      icon={() => <Alert className={styles['alert-icon']} />}
      text="Set an alert"
    />
  </div>
);

NoAlertRules.propTypes = propTypes;
export default NoAlertRules;
