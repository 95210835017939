import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import { DataTable } from 'shared/components/DataTable';
import RegionActivityMap from 'shared/components/RegionActivityMap';
import CostComparisonChart from './CostComparisonChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createCostComparisonData = (rec) => {
    const costCompareData = [
      {
        name: 'Current Region',
        'Current Region': rec.currTotalCost,
      },
      {
        name: 'Suggested Region',
        'Suggested Region': rec.recommTotalCost,
      },
    ];

    return costCompareData;
  };

  createSpecsComparisonData = (rec) => {
    const { region, regionName, recommRegion, recommRegionName, instance, service } = rec;

    const headings = [' ', 'Region Name', 'Region Tag', 'Instance', 'Service'];

    const rows = [
      ['Current', region, regionName, instance, service],
      ['Suggested', recommRegion, recommRegionName, instance, service],
    ];

    return { headings, rows };
  };

  createMapData = (rec) => {
    const mapData = [
      {
        regionTag: rec.region,
        usageCost: rec.currTotalCost,
      },
      {
        regionTag: rec.recommRegion,
        usageCost: rec.recommTotalCost,
      },
    ];
    return mapData;
  };

  render() {
    const { rec } = this.props;
    if (!rec) {
      return <div>Loading</div>;
    }
    const costCompareData = this.createCostComparisonData(rec);
    const tableData = this.createSpecsComparisonData(rec);
    const mapData = this.createMapData(rec);
    return (
      <div className="project-summary__statistics">
        {this.state.update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div className="project-summary__statistic">
          <p className="project-summary__statistic-title">Cost Comparison</p>
          <br />
          <CostComparisonChart data={costCompareData} />
        </div>
        <br />
        <div className="project-summary__statistic">
          <p className="project-summary__statistic-title">Spec Comparison</p>
          <br />
          <DataTable headings={tableData.headings} rows={tableData.rows} />
          <br />
        </div>
        <br />
        <div className="project-summary__statistic_big">
          <RegionActivityMap title="Region Map" size={8} data={mapData} markSizeRange={{ min: 7, max: 14 }} />
        </div>
      </div>
    );
  }
}
