/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import Panel from 'shared/components/Panel';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { CAU_ALERTS_TYPES } from 'usage/constants/usageConstants';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import toast from 'shared/components/andtComponents/Toast';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import SendAlertsWrapper from './components/SendAlertsWrapper';
import ExpirationAlertsWrapper from './components/ExpirationAlertsWrapper';
import CostChangesAlertsWrapper from './components/CostChangesAlertsWrapper';

class CostAndUsageAlerts extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    usageTypesFetcher: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
  };
  state = {
    isInitialDataFetch: false,
    isUpdate: false,
    preSaveAlertsAreValid: {
      [CAU_ALERTS_TYPES.COST_CHANGES]: true,
      [CAU_ALERTS_TYPES.RI_EXPIRATION]: true,
      [CAU_ALERTS_TYPES.SP_EXPIRATION]: true,
    },
  };

  async componentDidMount() {
    const { usageTypesFetcher } = this.props;
    this.setState({ isInitialDataFetch: true });
    try {
      usageTypesFetcher.onLoad(); // Do not wait for this to display the page
      await Promise.all([this.fetchAlertsShowStatus(), this.fetchAlerts()]);
      this.setState({ isInitialDataFetch: false });
    } catch (error) {
      this.setState({ isInitialDataFetch: false });
    }
  }

  fetchAlertsShowStatus = async () => {
    let isShowAlert = false;
    if (
      !this.props.usersStore.usersModel.usersNotifications ||
      Object.keys(this.props.usersStore.usersModel.usersNotifications).length === 0
    ) {
      await this.props.usersStore.fetchUserNotifications();
      isShowAlert = { ...this.props.usersStore.cauAlertsShowStatus };
    } else {
      isShowAlert = { ...this.props.usersStore.cauAlertsShowStatus };
    }
    return isShowAlert;
  };
  fetchAlerts = async () => {
    await this.props.usageStore.fetchCauAlerts();
  };
  toggleSendAlerts = async (alertType) => {
    const dictionary = ['cost_change', 'before_my_RI_expires', 'before_saving_plans_expires'];
    const { usersStore } = this.props;
    segmentEvent(
      { type: 'click-event', target: `send_alerts_before_expiring_toggle_${dictionary[alertType]}` },
      usersStore,
    );
    const isShowAlert = this.props.usersStore.cauAlertsShowStatus;
    const newStatus = !isShowAlert[alertType];
    try {
      this.setState({ isUpdate: true });
      await this.props.usageStore.updateCuaAlertsSendStatus(alertType, newStatus);
      this.setState({ isUpdate: false });
    } catch (error) {
      this.setState({ isUpdate: false });
    }
  };
  getCueAlertsOfType = (type) => {
    const list = this.props.usageStore.getCauAlerts();
    const filteredList = list.filter((item) => item.alertType === type);
    return filteredList;
  };

  handleCostChangeSaveClicked = async () => {
    const { preSaveAlertsAreValid } = this.state;
    try {
      this.setState({ isUpdate: true });
      await this.props.usageStore.saveAllCauAlerts(preSaveAlertsAreValid);
      this.setState({ isUpdate: false });
    } catch (error) {
      this.setState({ isUpdate: false });
    }
    if (Object.values(preSaveAlertsAreValid).some((isValid) => !isValid)) {
      toast.error('You have an error in one of the alerts you are attempting to save');
    }
  };
  handleCostChangeCancelClicked = () => {
    this.props.usageStore.cancelAllUnsavedCauAlerts();
  };
  updatePreSaveAlertsValidationStatus = (alertType, isValid) => {
    const { preSaveAlertsAreValid } = this.state;
    preSaveAlertsAreValid[alertType] = isValid;
    this.setState({ preSaveAlertsAreValid });
  };
  actionButtonsParams = [
    {
      title: 'Apply',
      name: 'save',
      handler: () => this.handleCostChangeSaveClicked(),
      style: {
        marginRight: '12px',
        height: '35px',
        'border-radius': '3px',
        'background-color': '#09a0ff',
        padding: '2px',
        width: '100px',
        color: '#fff',
        'font-size': '1vw',
        'text-align': 'center',
        cursor: 'pointer',
      },
    },
    {
      title: 'Cancel',
      name: 'cancel',
      handler: () => this.handleCostChangeCancelClicked(),
      style: {
        height: '35px',
        border: 'solid 0.5px #09a0ff',
        'background-color': '#ffff',
        padding: '2px',
        width: '100px',
        color: '#09a0ff',
        'font-size': '1vw',
        'text-align': 'center',
        cursor: 'pointer',
      },
    },
  ];
  setIsInitialDataFetch = (newVal) => {
    this.setState({ isInitialDataFetch: newVal });
  };

  render() {
    const { usersStore, invoiceStore, usageStore } = this.props;
    const { isInitialDataFetch, isUpdate } = this.state;
    const isShowAlert = usersStore.cauAlertsShowStatus;
    const {
      [CAU_ALERTS_TYPES.COST_CHANGES]: isShowCostChange,
      [CAU_ALERTS_TYPES.RI_EXPIRATION]: isShowRiExiprationChange,
      [CAU_ALERTS_TYPES.SP_EXPIRATION]: isShowSpExiprationChange,
    } = isShowAlert;
    if (invoiceStore.isLoading || usageStore.isLoading || isInitialDataFetch) {
      return (
        <Container>
          <PageHeader title={PageNames.CUE_ALERTS} />
          <Spinner />
        </Container>
      );
    }
    const costChangesAlerts = this.getCueAlertsOfType(CAU_ALERTS_TYPES.COST_CHANGES);
    const riExpirationAlerts = this.getCueAlertsOfType(CAU_ALERTS_TYPES.RI_EXPIRATION);
    const spExpirationAlerts = this.getCueAlertsOfType(CAU_ALERTS_TYPES.SP_EXPIRATION);
    return (
      <Container>
        <PageHeader title={PageNames.CUE_ALERTS} />
        {isUpdate && <Spinner />}
        {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && (
          <Panel divider title="ALERTS FOR COMMITMENT EXPIRATION">
            <div>
              <SendAlertsWrapper
                isOpen={isShowSpExiprationChange}
                toggleButtonText="Saving Plan Expiration"
                alertType={CAU_ALERTS_TYPES.SP_EXPIRATION}
                onToggleBtnClick={this.toggleSendAlerts}
              >
                <ExpirationAlertsWrapper
                  expirationAlerts={spExpirationAlerts}
                  invoiceStore={invoiceStore}
                  usageStore={usageStore}
                  alertType={CAU_ALERTS_TYPES.SP_EXPIRATION}
                  updatePreSaveAlertsValidationStatus={this.updatePreSaveAlertsValidationStatus}
                  setIsInitialDataFetch={this.setIsInitialDataFetch}
                />
              </SendAlertsWrapper>
            </div>
            <br />
            <div>
              <SendAlertsWrapper
                isOpen={isShowRiExiprationChange}
                toggleButtonText="RI Expiration"
                alertType={CAU_ALERTS_TYPES.RI_EXPIRATION}
                onToggleBtnClick={this.toggleSendAlerts}
              >
                <ExpirationAlertsWrapper
                  expirationAlerts={riExpirationAlerts}
                  invoiceStore={invoiceStore}
                  usageStore={usageStore}
                  alertType={CAU_ALERTS_TYPES.RI_EXPIRATION}
                  updatePreSaveAlertsValidationStatus={this.updatePreSaveAlertsValidationStatus}
                  setIsInitialDataFetch={this.setIsInitialDataFetch}
                />
              </SendAlertsWrapper>
            </div>
          </Panel>
        )}
        <Panel divider title="ALERTS FOR SERVICE COST CHANGES">
          <div>
            <SendAlertsWrapper
              isOpen={isShowCostChange}
              toggleButtonText="Send alerts if cost changes"
              alertType={CAU_ALERTS_TYPES.COST_CHANGES}
              onToggleBtnClick={this.toggleSendAlerts}
            >
              <CostChangesAlertsWrapper
                costChangesAlerts={costChangesAlerts}
                invoiceStore={invoiceStore}
                usageStore={usageStore}
                updatePreSaveAlertsValidationStatus={this.updatePreSaveAlertsValidationStatus}
                currentCloudType={usersStore.currDispUserCloudAccountType}
                setIsInitialDataFetch={this.setIsInitialDataFetch}
              />
            </SendAlertsWrapper>
          </div>
        </Panel>
      </Container>
    );
  }
}

const ObserverCostAndUsageAlerts = withInvoiceFiltersContextConsumer(observer(CostAndUsageAlerts));
export default ObserverCostAndUsageAlerts;
