import React from 'react';
import { useMainDBData } from 'app/hooks/react-query/useMainDBData';
import { useRootStore } from './RootStoreContext';

export function withMainDashboardContextConsumer(Component) {
  return function MainDashboardContextConsumer({ ...props }) {
    const { usersStore } = useRootStore();
    const { currDispUserCloudAccountType, currDispUserAccountKey } = usersStore;
    const { mainDbData, mainDbDataLoading } = useMainDBData(currDispUserCloudAccountType, currDispUserAccountKey);
    return <Component mainDbData={mainDbData} mainDbDataLoading={mainDbDataLoading} {...props} />;
  };
}
