/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { GcpIpIdleRecommendation } from 'recommendations/models/recommTypes';
import GcpCostRecommendationBuilder from 'recommendations/models/builders/gcpCostRecommBuilder';

export default class GcpIpIdleCostRecommendationBuilder extends GcpCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new GcpIpIdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.GCP_VM_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} IP Idle`;
  }

  build() {
    super.build();
    const {
      zone_tag: tagZone,
      public_ip: publicIp,
      potential_savings: potentialSavings,
      linked_account_id: linkedAccountID,
    } = this._rawRecommendation;

    this._recommendation.region = tagZone;
    this._recommendation.publicIp = publicIp;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.linkedAccountID = linkedAccountID;
  }
}
