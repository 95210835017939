import React, { memo } from 'react';
import { ButtonToolbar, Card, CardBody, CardHeader, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { removeDecPoint } from 'shared/utils/strUtil';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import UsageCostInfoGrid from './UsageCostInfoGrid';

const UsageCost = memo(
  ({
    colSize,
    xlColSize,
    containerClassName,
    withHeader,
    title,
    showIcon,
    percentArrowIsUp,
    infoValue,
    id,
    iconStyle,
    cardBodyClassName,
    value,
    color,
    showPercent,
    percent,
    overrideCurrency,
  }) => {
    const { getCurrencyNumber } = useUserSettingsContext();
    return (
      <Col className={containerClassName} md={colSize} lg={colSize} xs={colSize} xl={xlColSize}>
        <Card className="saving-panel">
          {withHeader ? (
            <CardHeader style={{ backgroundColor: '#FFFFFF' }}>
              <div className="d-flex align-items-center justify-content-between ps-2 pe-2">
                <h5 style={{ whiteSpace: 'pre' }} className="dashboard__description">
                  {title}
                </h5>
                {showIcon ? (
                  <ButtonToolbar style={{ margin: 0 }}>
                    <Tooltip placement="top" title={infoValue} arrow>
                      <button type="button" id={`TooltipTop${id}`} className="panel__btn_lg">
                        <IconFromPng icon={ICONS.info} size={iconStyle.size} />
                      </button>
                    </Tooltip>
                  </ButtonToolbar>
                ) : null}
              </div>
            </CardHeader>
          ) : null}
          <CardBody className={`dashboard__card ${cardBodyClassName}`}>
            <div className="d-flex justify-content-center" style={{ alignItems: 'center', minHeight: '84px' }}>
              <div>
                <h2 style={{ fontSize: '1.6vw' }} className={`dashboard__title dashboard__title--${color}`}>
                  {`${getCurrencyNumber(removeDecPoint(value, 10), 0, {}, overrideCurrency)}`}{' '}
                </h2>
              </div>
              {showPercent ? (
                <div className="smallKpi">
                  <UsageCostInfoGrid
                    value={value}
                    percent={percent}
                    percentArrowIsUp={percentArrowIsUp}
                    iconStyle={iconStyle}
                  />
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  },
);

UsageCost.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cardBodyClassName: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
  colSize: PropTypes.number.isRequired,
  percent: PropTypes.string.isRequired,
  percentArrowIsUp: PropTypes.bool.isRequired,
  iconStyle: PropTypes.object,
  infoValue: PropTypes.string,
  showPercent: PropTypes.bool,
  showIcon: PropTypes.bool,
  xlColSize: PropTypes.number,
  containerClassName: PropTypes.object,
  withHeader: PropTypes.bool,
  overrideCurrency: PropTypes.string,
};

UsageCost.defaultProps = {
  color: '#4ce1b6',
  infoValue: '',
  showIcon: false,
  withHeader: true,
  showPercent: false,
  xlColSize: 4,
  containerClassName: '',
  cardBodyClassName: '',
  iconStyle: {
    color: '#727272',
    size: 16,
  },
  overrideCurrency: null,
};

export default UsageCost;
