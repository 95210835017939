import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

export const awsMarkers = [
  {
    subOffsetX: 0,
    subOffsetY: 50,
    markerOffsetX: 0,
    markerOffsetY: 30,
    regionTag: 'me-central-1',
    displayName: 'UAE (ME)',
    coordinates: [53.85, 23.42],
  },
  {
    subOffsetX: 0,
    subOffsetY: 50,
    markerOffsetX: 0,
    markerOffsetY: 30,
    regionTag: 'af-south-1',
    displayName: 'Cape Town (AF)',
    coordinates: [18.42, -33.92],
  },
  {
    subOffsetX: 0,
    subOffsetY: 50,
    markerOffsetX: 0,
    markerOffsetY: 30,
    regionTag: 'ap-southeast-3',
    displayName: 'Jakarta (AP)',
    coordinates: [106.84, 6.21],
  },
  {
    subOffsetX: -20,
    subOffsetY: -30,
    markerOffsetX: -20,
    markerOffsetY: -15,
    regionTag: 'ap-southeast-4',
    displayName: 'Melbourne (AP)',
    coordinates: [144.946457, -37.840935],
  },
  {
    subOffsetX: 0,
    subOffsetY: 50,
    markerOffsetX: 0,
    markerOffsetY: 30,
    regionTag: 'ap-east-1',
    displayName: 'Hong Kong (AP)',
    coordinates: [114.17, 22.32],
  },
  {
    subOffsetX: 0,
    subOffsetY: 50,
    markerOffsetX: 0,
    markerOffsetY: 30,
    regionTag: 'ap-northeast-3',
    displayName: 'Osaka (AP)',
    coordinates: [135.5, 34.69],
  },
  {
    subOffsetX: 0,
    subOffsetY: 50,
    markerOffsetX: 0,
    markerOffsetY: 30,
    regionTag: 'me-south-1',
    displayName: 'Bahrain (ME)',
    coordinates: [50.56, 26.07],
  },
  {
    subOffsetX: 0,
    subOffsetY: 50,
    markerOffsetX: 0,
    markerOffsetY: 30,
    regionTag: 'eu-south-1',
    displayName: 'Milan (EU)',
    coordinates: [9.19, 45.46],
  },
  {
    subOffsetX: 50,
    subOffsetY: 45,
    markerOffsetX: 50,
    markerOffsetY: 30,
    regionTag: 'us-east-1',
    displayName: 'N. Virginia (US East)',
    coordinates: [-77.8505, 38.161],
  },
  {
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
    regionTag: 'us-east-2',
    displayName: 'Ohio (US East)',
    coordinates: [-82.691, 40.1191],
  },
  {
    subOffsetX: 30,
    subOffsetY: 45,
    markerOffsetX: 30,
    markerOffsetY: 30,
    regionTag: 'us-west-1',
    displayName: 'N. California (US West)',
    coordinates: [-120.9042, 39.0167],
  },
  {
    subOffsetX: 0,
    subOffsetY: -35,
    markerOffsetX: 0,
    markerOffsetY: -20,
    regionTag: 'us-west-2',
    displayName: 'Oregon (US West)',
    coordinates: [-120.84327, 44.251754],
  },
  {
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
    regionTag: 'ap-south-1',
    displayName: 'Mumbai (Asia Pacific)',
    coordinates: [72.8757, 19.0815],
  },
  {
    subOffsetX: 0,
    subOffsetY: 35,
    markerOffsetX: 0,
    markerOffsetY: 20,
    regionTag: 'ap-southeast-1',
    displayName: 'Singapore (Asia Pacific)',
    coordinates: [103.866, 1.367232],
  },
  {
    subOffsetX: -40,
    subOffsetY: -30,
    markerOffsetX: -40,
    markerOffsetY: -15,
    regionTag: 'ap-southeast-2',
    displayName: 'Sydney (Asia Pacific)',
    coordinates: [151.170027, -33.859309],
  },
  {
    subOffsetX: 0,
    subOffsetY: 30,
    markerOffsetX: 0,
    markerOffsetY: 15,
    regionTag: 'ap-northeast-1',
    displayName: 'Tokyo (Asia Pacific)',
    coordinates: [139.731691, 35.711669],
  },
  {
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
    regionTag: 'ap-northeast-2',
    displayName: 'Seoul (Asia Pacific)',
    coordinates: [126.9868, 37.556],
  },
  {
    subOffsetX: 0,
    subOffsetY: -10,
    markerOffsetX: 0,
    markerOffsetY: -25,
    regionTag: 'ca-central-1',
    displayName: 'Central (Canada)',
    coordinates: [-71.751606, 48.54815],
  },
  {
    subOffsetX: 60,
    subOffsetY: 20,
    markerOffsetX: 60,
    markerOffsetY: 2,
    regionTag: 'eu-central-1',
    displayName: 'Frankfurt (EU)',
    coordinates: [8.681729, 50.113514],
  },
  {
    subOffsetX: -60,
    subOffsetY: 15,
    markerOffsetX: -60,
    markerOffsetY: 0,
    regionTag: 'eu-west-1',
    displayName: 'Ireland (EU)',
    coordinates: [-7.736497, 53.149084],
  },
  {
    subOffsetX: 40,
    subOffsetY: -30,
    markerOffsetX: 40,
    markerOffsetY: -10,
    regionTag: 'eu-west-2',
    displayName: 'London (EU)',
    coordinates: [-0.127123, 51.507705],
  },
  {
    subOffsetX: 0,
    subOffsetY: 30,
    markerOffsetX: 0,
    markerOffsetY: 15,
    regionTag: 'eu-west-3',
    displayName: 'Paris (EU)',
    coordinates: [2.3516, 48.0],
  },
  {
    subOffsetX: 0,
    subOffsetY: -35,
    markerOffsetX: 0,
    markerOffsetY: -20,
    regionTag: 'eu-north-1',
    displayName: 'Stockholm (EU)',
    coordinates: [18.067491, 59.330244],
  },
  {
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
    regionTag: 'sa-east-1',
    displayName: 'São Paulo (South America)',
    coordinates: [-46.634172, -23.543503],
  },
  {
    regionTag: 'il-central-1',
    displayName: 'Israel (Tel Aviv)',
    coordinates: [34.855499, 32.109333],
    subOffsetX: 25,
    subOffsetY: -25,
    markerOffsetX: 25,
    markerOffsetY: -10,
  },
];

export const azureMarkers = [
  {
    regionTag: 'ap-east',
    displayName: 'East Asia',
    coordinates: [114.188, 22.267],
    subOffsetX: 0,
    subOffsetY: 33,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'ap-southeast',
    displayName: 'Southeast Asia',
    coordinates: [103.833, 1.283],
    subOffsetX: 0,
    subOffsetY: 33,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'us-central',
    displayName: 'Central US',
    coordinates: [-93.6208, 41.5908],
    subOffsetX: -10,
    subOffsetY: 23,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'us-east',
    displayName: 'East US',
    coordinates: [-79.8164, 37.3719],
    subOffsetX: 30,
    subOffsetY: -30,
    markerOffsetX: 30,
    markerOffsetY: -15,
  },
  {
    regionTag: 'us-east2',
    displayName: 'East US 2',
    coordinates: [-79.8164, 37.3719],
    subOffsetX: 30,
    subOffsetY: 33,
    markerOffsetX: 30,
    markerOffsetY: 18,
  },
  {
    regionTag: 'us-northcentral',
    displayName: 'North Central US',
    coordinates: [-87.6278, 41.8819],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'us-southcentral',
    displayName: 'South Central US',
    coordinates: [-98.5, 29.4167],
    subOffsetX: -10,
    subOffsetY: 33,
    markerOffsetX: -10,
    markerOffsetY: 18,
  },
  {
    subOffsetX: -50,
    subOffsetY: -30,
    markerOffsetX: -50,
    markerOffsetY: -15,
    regionTag: 'eu-north',
    displayName: 'North Europe',
    coordinates: [-6.2597, 53.3478],
  },
  {
    subOffsetX: 40,
    subOffsetY: 33,
    markerOffsetX: 40,
    markerOffsetY: 18,
    regionTag: 'eu-west',
    displayName: 'West Europe',
    coordinates: [4.9, 52.3667],
  },
  {
    regionTag: 'japan-west',
    displayName: 'Japan West',
    coordinates: [135.5022, 34.6939],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'japan-east',
    displayName: 'Japan East',
    coordinates: [139.77, 35.68],
    subOffsetX: 0,
    subOffsetY: 33,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'brazil-south',
    displayName: 'Brazil South',
    coordinates: [-46.633, -23.55],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'au-southeast',
    displayName: 'Australia Southeast',
    coordinates: [144.9631, -37.8136],
    subOffsetX: -15,
    subOffsetY: 33,
    markerOffsetX: -15,
    markerOffsetY: 18,
  },
  {
    regionTag: 'au-east',
    displayName: 'Australia East',
    coordinates: [151.2094, -33.86],
    subOffsetX: -30,
    subOffsetY: -30,
    markerOffsetX: -30,
    markerOffsetY: -15,
  },
  {
    regionTag: 'ap-southeast',
    displayName: 'Southeast Asia',
    coordinates: [103.833, 1.283],
    subOffsetX: 0,
    subOffsetY: 33,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'india-south',
    displayName: 'South India',
    coordinates: [80.1636, 12.9822],
    subOffsetX: 30,
    subOffsetY: 33,
    markerOffsetX: 30,
    markerOffsetY: 18,
  },
  {
    regionTag: 'india-central',
    displayName: 'Central India',
    coordinates: [73.9197, 18.5822],
    subOffsetX: 45,
    subOffsetY: 13,
    markerOffsetX: 45,
    markerOffsetY: -2,
  },
  {
    regionTag: 'india-west',
    displayName: 'West India',
    coordinates: [72.868, 19.088],
    subOffsetX: -40,
    subOffsetY: -20,
    markerOffsetX: -40,
    markerOffsetY: -5,
  },
  {
    regionTag: 'canada-central',
    displayName: 'Canada Central',
    coordinates: [-79.383, 43.653],
    subOffsetX: 40,
    subOffsetY: -30,
    markerOffsetX: 40,
    markerOffsetY: -15,
  },
  {
    regionTag: 'canada-east',
    displayName: 'Canada East',
    coordinates: [-71.217, 46.817],
    subOffsetX: 40,
    subOffsetY: -30,
    markerOffsetX: 40,
    markerOffsetY: -15,
  },
  {
    regionTag: 'uk-south',
    displayName: 'UK South',
    coordinates: [-0.799, 50.941],
    subOffsetX: -50,
    subOffsetY: 10,
    markerOffsetX: -50,
    markerOffsetY: 25,
  },
  {
    regionTag: 'uk-west',
    displayName: 'UK West',
    coordinates: [-3.084, 53.427],
    subOffsetX: 35,
    subOffsetY: -20,
    markerOffsetX: 35,
    markerOffsetY: -5,
  },
  {
    regionTag: 'us-westcentral',
    displayName: 'West Central US',
    coordinates: [-110.234, 40.89],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'us-centralwest',
    displayName: 'West Central US',
    coordinates: [-110.234, 40.89],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'us-west',
    displayName: 'West US',
    coordinates: [-122.417, 37.783],
    subOffsetX: -40,
    subOffsetY: -15,
    markerOffsetX: -40,
    markerOffsetY: 0,
  },
  {
    regionTag: 'us-west2',
    displayName: 'West US 2',
    coordinates: [-119.852, 47.233],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'korea-central',
    displayName: 'Korea Central',
    coordinates: [126.978, 37.5665],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'korea-south',
    displayName: 'Korea South',
    coordinates: [129.0756, 35.1796],
    subOffsetX: -40,
    subOffsetY: 33,
    markerOffsetX: -40,
    markerOffsetY: 18,
  },
  {
    regionTag: 'france-central',
    displayName: 'France Central',
    coordinates: [2.373, 46.3772],
    subOffsetX: -60,
    subOffsetY: 33,
    markerOffsetX: -60,
    markerOffsetY: 18,
  },
  {
    regionTag: 'france-south',
    displayName: 'France South',
    coordinates: [2.1972, 43.8345],
    subOffsetX: 0,
    subOffsetY: 38,
    markerOffsetX: 0,
    markerOffsetY: 25,
  },
  {
    regionTag: 'au-central',
    displayName: 'Australia Central',
    coordinates: [149.1244, -35.3075],
    subOffsetX: 0,
    subOffsetY: 33,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'au-central2',
    displayName: 'Australia Central 2',
    coordinates: [149.1244, -35.3075],
    subOffsetX: -30,
    subOffsetY: -20,
    markerOffsetX: -30,
    markerOffsetY: -10,
  },
  {
    regionTag: 'uae-central',
    displayName: 'UAE Central',
    coordinates: [54.366669, 24.466667],
    subOffsetX: 0,
    subOffsetY: 33,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'uae-north',
    displayName: 'UAE North',
    coordinates: [55.316666, 25.266666],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'southafrica-north',
    displayName: 'South Africa North',
    coordinates: [28.21837, -25.73134],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'southafrica-west',
    displayName: 'South Africa West',
    coordinates: [18.843266, -34.075691],
    subOffsetX: 0,
    subOffsetY: 33,
    markerOffsetX: 0,
    markerOffsetY: 18,
  },
  {
    regionTag: 'switzerland-north',
    displayName: 'Switzerland North',
    coordinates: [8.564572, 47.451542],
    subOffsetX: 50,
    subOffsetY: 27,
    markerOffsetX: 50,
    markerOffsetY: 12,
  },
  {
    regionTag: 'switzerland-west',
    displayName: 'Switzerland West',
    coordinates: [6.143158, 46.204391],
    subOffsetX: 40,
    subOffsetY: 33,
    markerOffsetX: 40,
    markerOffsetY: 18,
  },
  {
    regionTag: 'germany-north',
    displayName: 'Germany North',
    coordinates: [8.806422, 53.073635],
    subOffsetX: 50,
    subOffsetY: -30,
    markerOffsetX: 50,
    markerOffsetY: -15,
  },
  {
    regionTag: 'germany-westcentral',
    displayName: 'Germany West Central',
    coordinates: [8.682127, 50.110924],
    subOffsetX: 30,
    subOffsetY: -30,
    markerOffsetX: 30,
    markerOffsetY: -15,
  },
  {
    regionTag: 'norway-west',
    displayName: 'Norway West',
    coordinates: [5.733107, 58.969975],
    subOffsetX: -50,
    subOffsetY: -30,
    markerOffsetX: -50,
    markerOffsetY: -15,
  },
  {
    regionTag: 'norway-east',
    displayName: 'Norway East',
    coordinates: [10.752245, 59.913868],
    subOffsetX: 50,
    subOffsetY: -30,
    markerOffsetX: 50,
    markerOffsetY: -15,
  },
];
export const gcpMarkers = [
  {
    regionTag: 'asia-east1',
    displayName: 'Taiwan',
    coordinates: [121.597366, 25.105497],
    subOffsetX: 30,
    subOffsetY: 15,
    markerOffsetX: 30,
    markerOffsetY: 0,
  },
  {
    regionTag: 'asia-east2',
    displayName: 'Hong Kong',
    coordinates: [114.177216, 22.302711],
    subOffsetX: 0,
    subOffsetY: 15,
    markerOffsetX: 0,
    markerOffsetY: 30,
  },
  {
    regionTag: 'asia-northeast1',
    displayName: 'Tokyo',
    coordinates: [139.839478, 35.652832],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'asia-northeast2',
    displayName: 'Osaka',
    coordinates: [135.484802, 34.6939],
    subOffsetX: -40,
    subOffsetY: 15,
    markerOffsetX: -30,
    markerOffsetY: 5,
  },
  {
    regionTag: 'asia-northeast3',
    displayName: 'Seoul',
    coordinates: [127.024612, 37.5326],
    subOffsetX: -40,
    subOffsetY: -20,
    markerOffsetX: -40,
    markerOffsetY: -5,
  },
  {
    regionTag: 'asia-south1',
    displayName: 'Mumbai',
    coordinates: [72.849998, 19.155001],
    subOffsetX: 0,
    subOffsetY: 15,
    markerOffsetX: 0,
    markerOffsetY: 30,
  },
  {
    regionTag: 'asia-south2',
    displayName: 'Delhi',
    coordinates: [77.06971, 28.679079],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'asia-southeast1',
    displayName: 'Singapore',
    coordinates: [103.851959, 1.29027],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'asia-southeast2',
    displayName: 'Indonesia',
    coordinates: [106.816666, -6.2],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'australia-southeast1',
    displayName: 'Sydney',
    coordinates: [151.2099, -33.865143],
    subOffsetX: -40,
    subOffsetY: -30,
    markerOffsetX: -40,
    markerOffsetY: -15,
  },
  {
    regionTag: 'australia-southeast2',
    displayName: 'Melbourne',
    coordinates: [144.946457, -37.840935],
    subOffsetX: -20,
    subOffsetY: -30,
    markerOffsetX: -20,
    markerOffsetY: -15,
  },
  {
    regionTag: 'europe-central2',
    displayName: 'Poland',
    coordinates: [21.017532, 52.237049],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'europe-north1',
    displayName: 'Finland',
    coordinates: [24.945831, 60.192059],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'europe-west1',
    displayName: 'Belgium',
    coordinates: [4.402771, 51.260197],
    subOffsetX: 10,
    subOffsetY: -30,
    markerOffsetX: 10,
    markerOffsetY: -15,
  },
  {
    regionTag: 'europe-west2',
    displayName: 'London',
    coordinates: [-0.118092, 51.509865],
    subOffsetX: -25,
    subOffsetY: 30,
    markerOffsetX: -25,
    markerOffsetY: 15,
  },
  {
    regionTag: 'europe-west3',
    displayName: 'Frankfurt',
    coordinates: [8.682127, 50.110924],
    subOffsetX: 40,
    subOffsetY: 15,
    markerOffsetX: 40,
    markerOffsetY: 0,
  },
  {
    regionTag: 'europe-west4',
    displayName: 'Netherlands',
    coordinates: [4.896029, 52.371807],
    subOffsetX: -60,
    subOffsetY: -15,
    markerOffsetX: -60,
    markerOffsetY: 0,
  },
  {
    regionTag: 'europe-west6',
    displayName: 'Zurich',
    coordinates: [7.588576, 47.559601],
    subOffsetX: 10,
    subOffsetY: 30,
    markerOffsetX: 10,
    markerOffsetY: 15,
  },
  {
    regionTag: 'northamerica-northeast1',
    displayName: 'Montréal, Québec',
    coordinates: [-73.519997, 45.630001],
    subOffsetX: 30,
    subOffsetY: -30,
    markerOffsetX: 30,
    markerOffsetY: -15,
  },
  {
    regionTag: 'northamerica-northeast2',
    displayName: 'Toronto, Ontario',
    coordinates: [-79.347015, 43.65107],
    subOffsetX: 10,
    subOffsetY: -30,
    markerOffsetX: 10,
    markerOffsetY: -15,
  },
  {
    regionTag: 'southamerica-east1',
    displayName: 'São Paulo',
    coordinates: [-46.62529, -23.533773],
    subOffsetX: 10,
    subOffsetY: -30,
    markerOffsetX: 10,
    markerOffsetY: -15,
  },
  {
    regionTag: 'us-central1',
    displayName: 'Iowa, US',
    coordinates: [-91.530167, 41.661129],
    subOffsetX: 10,
    subOffsetY: -30,
    markerOffsetX: 10,
    markerOffsetY: -15,
  },
  {
    regionTag: 'us-east1',
    displayName: 'South Carolina, US',
    coordinates: [-81.163727, 33.836082],
    subOffsetX: 10,
    subOffsetY: 15,
    markerOffsetX: 10,
    markerOffsetY: 30,
  },
  {
    regionTag: 'us-east4',
    displayName: 'Virginia, US',
    coordinates: [-78.024902, 37.926868],
    subOffsetX: 35,
    subOffsetY: 5,
    markerOffsetX: 30,
    markerOffsetY: -10,
  },
  {
    regionTag: 'us-west1',
    displayName: 'Oregon, US',
    coordinates: [-120.5, 44.0],
    subOffsetX: 0,
    subOffsetY: -30,
    markerOffsetX: 0,
    markerOffsetY: -15,
  },
  {
    regionTag: 'us-west2',
    displayName: 'California, US',
    coordinates: [-119.417931, 36.778259],
    subOffsetX: 10,
    subOffsetY: 15,
    markerOffsetX: 10,
    markerOffsetY: 30,
  },
  {
    regionTag: 'us-west3',
    displayName: 'Utah, US',
    coordinates: [-111.950684, 39.41922],
    subOffsetX: 25,
    subOffsetY: 10,
    markerOffsetX: 20,
    markerOffsetY: -5,
  },
  {
    regionTag: 'us-west4',
    displayName: 'Nevada, US',
    coordinates: [-117.224121, 39.876019],
    subOffsetX: -40,
    subOffsetY: -15,
    markerOffsetX: -40,
    markerOffsetY: 0,
  },
  {
    regionTag: 'me-west1',
    displayName: 'Israel (Tel Aviv)',
    coordinates: [32.109333, 34.855499],
    subOffsetX: -40,
    subOffsetY: -15,
    markerOffsetX: -40,
    markerOffsetY: 0,
  },
];

export const cloudTypeToRegionMarkersMap = new Map([
  [CLOUD_TYPE_IDS.AWS, awsMarkers],
  [CLOUD_TYPE_IDS.AZURE, azureMarkers],
  [CLOUD_TYPE_IDS.GCP, gcpMarkers],
]);
