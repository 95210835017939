import { useQuery } from 'react-query';
import { isUsersAccountTypeTenant } from 'users/constants/usersConstants';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

export const getMainDBData = () => API.get('billings', '/api/v1/dashboards/main-dashboard');
export const getMainDBDataForPanel = async () => {
  const response = await getMainDBData();
  return [response];
};

export function useMainDBData(accountType, currDispUserAccountKey) {
  const { data: mainDbData, isLoading: mainDbDataLoading } = useQuery(
    [apiConstants.QUERY_KEYS.MAINDB_DATA, accountType, currDispUserAccountKey],
    async () => {
      try {
        if (isUsersAccountTypeTenant(+accountType) || !currDispUserAccountKey) {
          return null;
        }
        const mainDashBoardData = await getMainDBData();
        const modifiedMainDashBoardData = {
          monthToDateCosts: mainDashBoardData.month_to_date_costs,
          forecastedMonthEndTotalCost: mainDashBoardData.forecasted_current_month_end_costs,
          annualforecastedTotalCost: mainDashBoardData.forecasted_current_month_end_costs * 12,
          prevMonthCostToDate: mainDashBoardData.sum_cost_month_befor,
          forecastedMonthEndPercentages: mainDashBoardData.forecasted_current_month_end_percentages,
          costPerRegion: mainDashBoardData.cost_per_region,
          monthToDatePercentages: mainDashBoardData.month_to_date_percentages,
          expectedSavings: mainDashBoardData.expected_savings,
          annualExpectedSavings: mainDashBoardData.annual_expected_savings,
          expectedSavingsPercent: mainDashBoardData.expected_savings_percent,
          mainDashboardDate: mainDashBoardData.main_dashboard_date,
          prevMonthTotalCost: mainDashBoardData.month_befor_total_cost,
          preparedCostPerDay: mainDashBoardData.prepared_cost_per_day_data,
          preparedCostPerRegion: mainDashBoardData.prepared_cost_per_region,
          preparedCostPerMonth: mainDashBoardData.prepared_cost_per_month,
          preparedTopServices: mainDashBoardData.prepared_top_services_costs,
          yesterdayTopServices: mainDashBoardData.prepared_yesterday_top_5_services,
          isCustomDefaultDbSet: mainDashBoardData.is_custom_default_db_set,
        };
        return {
          mainDashBoardData: modifiedMainDashBoardData,
        };
      } catch (e) {
        return null;
      }
    },
    {
      staleTime: 3 * 1000 * 60,
    },
  );
  return {
    mainDbDataLoading,
    mainDbData: mainDbData || {},
  };
}
