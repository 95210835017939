import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import { BASE_PROPERTIES, COST_PROPERTIES, RDS_PROPERTIES } from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';

const GcpVmRightsizing = ({ recommendation }) => {
  const properties = {
    ...COST_PROPERTIES,
    ...BASE_PROPERTIES,
    ...RDS_PROPERTIES,
  };

  const description = `This VM instance has a low CPU utilization, we recommend to switch to a more
    suitable instance type which will cost less.`;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'Stop the instance',
          actionCommand: `gcloud compute instances stop ${recommendation?.resourceName}`,
        },
        {
          actionText: 'Change the machine type',
          // eslint-disable-next-line max-len
          actionCommand: `gcloud compute instances set-machine-type ${recommendation?.recData.instance_type} --machine-type ${recommendation?.recData.instance_type_size}`,
        },
      ],
    },
  ];

  const recommendationCommandComponent = <RecommendationCommand commandsList={commandsList} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const chartsData = [
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'CPU Performance',
      yAxisLabel: 'CPU Utilization',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      linesList: [
        {
          id: 'cpuUtilization',
          label: 'CPU',
          data: recommendation?.recData?.cpu_util_statistics_usage,
        },
      ],
    },
  ];

  const recommendationChartsComponent = <RecommendationChartData chartsData={chartsData} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      command={recommendationCommandComponent}
      preferences={preferencesComponent}
      charts={recommendationChartsComponent}
    />
  );
};

GcpVmRightsizing.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default GcpVmRightsizing;
