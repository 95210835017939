import React from 'react';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Video from '../../assets/CUR.mp4';
import CurImage from './assets/cur.jpg';
import commonStyles from '../Pages.module.scss';
import styles from './CreateCurPage.module.scss';

const CreateCurPage = () => (
  <div>
    <p className={commonStyles.description}>Create a CUR file so Anodot can analyze your costs.</p>
    <p className={commonStyles.subDescription}>Watch the tutorial for step-by-step instructions.</p>
    <div className={styles.content}>
      <ListWithHelp
        styles={{
          hideControl: true,
          helpWidth: 334,
        }}
        list={[
          {
            key: 'main-body',
            render: () => (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video width={500} height={300} controls="controls" className={styles.video}>
                <source src={Video} type="video/mp4" />
              </video>
            ),
          },
        ]}
        title="Notice"
        help={{
          'main-body': {
            component: () => (
              <p className={commonStyles.helpText}>
                Ensure that you choose the <span>&quot;Legacy CUR Export&quot;</span>{' '}
                export type when creating the data export in AWS.
              </p>
            ),
            image: CurImage,
            imageHeight: 129,
          },
        }}
      />
    </div>
  </div>
);

export default CreateCurPage;
