import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { UsersType } from 'users/constants/usersConstants';
import Sidebar from './sidebar/Sidebar';
import TopNavbar from './topNavbar/TopNavbar';

const Layout = (props) => {
  const { childProps, location, history } = props;
  return (
    <div className="layout">
      <TopNavbar
        childProps={childProps}
        currDisplayedAccount={childProps && childProps.usersStore.getCurrDisplayedAccount}
        location={location}
        history={history}
      />
      {childProps?.usersStore.getCurrDisplayedUserTypeAndCloudId().userType !== UsersType.NEW_USER && (
        <Sidebar childProps={childProps} />
      )}
    </div>
  );
};

Layout.propTypes = {
  childProps: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(observer(Layout));
