export const VIRTUAL_TAGS_TABLE_COLUMNS = [
  // { name: 'virtualTag', title: 'Virtual Tag' },
  { name: 'name', title: 'Virtual Tag Name' },
  { name: 'createdBy', title: 'Created By' },
  { name: 'creationDate', title: 'Created' },
  { name: 'userAction', title: ' ' },
];
export const VIRTUAL_TAGS_TABLE_CUSTOM_TAGS_COLUMNS = [
  // { name: 'virtualTag', title: 'Virtual Tag' },
  { name: 'name', title: 'Tag' },
  { name: 'assignedBy', title: 'Created By' },
  { name: 'assignDate', title: 'Created' },
];

export const VIRTUAL_TAGS_COLUMN_WIDTHS = [
  // { columnName: 'virtualTag', width: 200 },
  { columnName: 'name', width: 200 },
  { columnName: 'createdBy', width: 200 },
  { columnName: 'creationDate', width: 200 },
  { columnName: 'userAction', width: 200 },
];
export const VIRTUAL_TAGS_COLUMN_CUSTOM_TAGS_WIDTHS = [
  // { columnName: 'virtualTag', width: 200 },
  { columnName: 'name', width: 200 },
  { columnName: 'assignedBy', width: 200 },
  { columnName: 'assignDate', width: 200 },
];
export const VIRTUAL_TAGS_TABLE_COLUMN_EXTENSIONS = [{ columnName: 'userAction', align: 'right' }];
export const VIRTUAL_TAGS_DEFAULT_HIDDEN_COLUMN_NAMES = [];
export const VIRTUAL_TAGS_DEFAULT_SORTING = [];
export const VIRTUAL_TAGS_CURRENCIES_COLUMNS = [];
