export const LINKED_ACCOUNTS_TABLE_COLUMNS = [
  { name: 'linkedAccountName', title: 'Linked Account Name', getCellValue: (row) => row.linkedAccountName },
  { name: 'linkedAccountId', title: 'Linked Account ID', getCellValue: (row) => row.linkedAccountId },
  { name: 'actions', title: ' ' },
];
export const LINKED_ACCOUNTS_COLUMN_EXTENSIONS = [
  { columnName: 'linkedAccountName', width: '50%' },
  { columnName: 'linkedAccountId', width: 'auto' },
  { columnName: 'actions', width: 188, align: 'right' },
];
