import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import Paper from '@mui/material/Paper';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, TableColumnResizing, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import Spinner from 'shared/components/andtComponents/Spinner';
import { Action, CategoryAction, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useFiltersDistinctValues } from 'invoices/hooks/react-query/useInvoiceFilters';
import ViewsActionButton from './ViewsActionButton';
import SubViewsWrapper from './SubViewsWrapper';
import AddOrEditViewModal from './ViewCreateEditModal';
import * as constants from '../constants/viewsConstants';
import { useViews } from '../hooks/useViews';

const CreateViewButton = (props) => (
  <Row className="dotted_box">
    <div className="dotted_box-title">
      <span>Create New View</span>
    </div>
    <br />
    <div>
      <LinkIconButton>
        <a href="/" onClick={(e) => props.handler(e)} />
        <IconFromPng size="auto" icon={ICONS.plus} />
      </LinkIconButton>
    </div>
  </Row>
);

const propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ViewsTable = ({ usersStore }) => {
  const [isAddOrEditModalOpen, setIsAddOrEditModalOpen] = useState(false);
  const [editedView, setEditedView] = useState(null);
  const [editedOriginalView, setEditedOriginalView] = useState(null);
  const [viewForDelete, setViewForDelete] = useState(null);
  const [nameValidError, setNameValidError] = useState('');
  const [columnWidths, setColumnWidths] = useState(constants.VIEWS_TABLE_COLUMN_WIDTHS);
  const { currDispUserCloudAccountType, currDispUserAccountKey } = usersStore;
  const accountFeatures = usersStore.getCurrentDisplayedAccountFeatures(currDispUserAccountKey);
  const { getCustomTagsKeys, invalidateTagsFilters } = useFiltersDistinctValues({
    accountType: currDispUserCloudAccountType,
    accountKey: currDispUserAccountKey,
    fetchK8S: (accountFeatures || []).includes(ACCOUNT_FEATURES.K8S),
  });
  const { getViews, postView, putView, delView } = useViews();
  const { data: views, isLoading: isGetViewLoading, refetch: refetchViews } = getViews();
  const { isLoading: isAddingView, mutateAsync: addView } = postView({ onSuccess: refetchViews });
  const { isLoading: isUpdatingView, mutateAsync: updateView } = putView({ onSuccess: refetchViews });
  const { isLoading: isDeletingView, mutateAsync: deleteView } = delView({ onSuccess: refetchViews });

  function closeModal() {
    setIsAddOrEditModalOpen(false);
    setEditedView(null);
    setEditedOriginalView(null);
  }

  const handleValidateViewName = (value) => {
    const filteredViews = views?.filter(({ uuid }) => uuid !== editedView?.uuid) || [];
    const subViewWithSameNameOfEdit = filteredViews.find(({ name }) => name.toLowerCase() === value.toLowerCase());
    let nameValidError = '';
    if (subViewWithSameNameOfEdit) {
      nameValidError = 'Name already exists';
    }
    if (!value) {
      nameValidError = 'Name must have a value';
    }
    return nameValidError;
  };

  const handleSaveView = async (applyFromNow) => {
    if (editedView?.uuid) {
      await updateView({ viewParams: editedView, historyView: applyFromNow ? editedOriginalView : undefined });
    } else {
      await addView({ view: editedView, createdBy: usersStore.currentDisplayedUserName });
    }
    invalidateTagsFilters();
    closeModal();
  };

  const handleDeleteView = async (action) => {
    if (action === 'delete') {
      await deleteView(viewForDelete.uuid);
      invalidateTagsFilters();
    }
    setViewForDelete(null);
  };

  const addViewClickHandler = (e) => {
    e.preventDefault();
    setIsAddOrEditModalOpen(true);
    setEditedView({ name: '', tagKey: '', subViews: [] });
  };

  function handleSelectTagKey(tagKey, isTagKeyChanged) {
    setEditedView((prevState) => {
      const subViews = isTagKeyChanged ? [] : prevState.subViews;
      return { ...prevState, tagKey, subViews };
    });
  }

  function editViewClickHandler(view) {
    setEditedView({ ...view });
    setEditedOriginalView({ ...view });
    setIsAddOrEditModalOpen(true);
    handleSelectTagKey(view.tagKey, false);
  }

  function deleteViewHandler(view) {
    setViewForDelete(view);
  }

  const changeColumnWidths = (columnWidths) => {
    setColumnWidths(columnWidths);
  };

  const RowDetail = ({ row }) => (
    <div>
      <SubViewsWrapper usersStore={usersStore} {...row} />
    </div>
  );
  RowDetail.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const userActionsFormatter = (data) => (
    <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostAllocation} action={Action.Update}>
      <ViewsActionButton row={data.row} handlers={{ edit: editViewClickHandler, del: deleteViewHandler }} />
    </ReadOnlyDisplayWrapper>
  );

  function handleChangeViewName(name) {
    const nameValidError = handleValidateViewName(name);
    setNameValidError(nameValidError);
    setEditedView((prevState) => ({ ...prevState, name }));
  }

  function handleUpdateSubViews(subViews) {
    setEditedView((prevState) => ({ ...prevState, subViews }));
  }

  const handlersForModal = {
    closeModal,
    saveView: handleSaveView,
    selectTagKey: handleSelectTagKey,
    changeViewName: handleChangeViewName,
    updateSubViews: handleUpdateSubViews,
  };

  if (isGetViewLoading || isAddingView || isUpdatingView || isDeletingView) {
    return <Spinner />;
  }
  return (
    <div style={{ width: '100%' }}>
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.CostAllocation}
        action={CategoryAction.Create}
      >
        <Col xs={12} md={12} lg={12} xl={12}>
          <CreateViewButton handler={addViewClickHandler} />
        </Col>
      </ReadOnlyDisplayWrapper>
      <Col xs={12} md={12} lg={12} xl={12}>
        <Paper>
          <Grid rows={views || []} columns={constants.VIEWS_TABLE_COLUMNS}>
            <RowDetailState />
            <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
            <TableWrapper />
            <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={changeColumnWidths} />
            <TableHeaderRow />
            <TableRowDetail contentComponent={RowDetail} />
          </Grid>
        </Paper>
      </Col>
      {isAddOrEditModalOpen && (
        <AddOrEditViewModal
          usersStore={usersStore}
          isOpen={isAddOrEditModalOpen}
          view={editedView}
          handlers={handlersForModal}
          nameValidError={nameValidError}
          getCustomTagsKeys={getCustomTagsKeys}
        />
      )}
      {viewForDelete && (
        <DeleteWarningModal
          isOpen={viewForDelete}
          handleDelete={handleDeleteView}
          modalTitle="Delete View"
          warningMessage={`Be advised, You are about to delete ${(viewForDelete && viewForDelete.name) || ''} View`}
          executeButtonTitle="Delete"
        />
      )}
    </div>
  );
};

ViewsTable.propTypes = propTypes;
export default observer(ViewsTable);
