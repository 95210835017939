/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { IdleLoadBalancerRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class IdleLoadBalancerRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new IdleLoadBalancerRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_IDLE_LOAD_BALANCER;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Idle Load Balancer`;
  }

  build() {
    super.build();
    const {
      zone_tag: tagZone,
      potential_savings: potentialSavings,
      dns_name: dnsName,
      load_balancer_name: loadBalancerName,
      load_balancer_type: loadBalancerType,
    } = this._rawRecommendation;

    this._recommendation.dnsName = dnsName;
    this._recommendation.loadBalancerName = loadBalancerName;
    this._recommendation.loadBalancerType = loadBalancerType;
    this._recommendation.region = tagZone;
    this._recommendation.potentialSavings = potentialSavings;
  }
}
