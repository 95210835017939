import {
  GCP_ONBOARDING_FIELDS,
  GCP_ONBOARDING_STEPS,
} from 'app/containers/Onboarding/GcpOnboarding/GcpOnboardingConstants';

function accountTypeValidation(currentScreen) {
  const errors = {};
  if (
    currentScreen[GCP_ONBOARDING_FIELDS.IS_MSP] &&
    currentScreen[GCP_ONBOARDING_FIELDS.ACCOUNT_TYPE] === 'dedicated' &&
    !currentScreen[GCP_ONBOARDING_FIELDS.RESELLER_CUSTOMER]
  ) {
    errors[GCP_ONBOARDING_FIELDS.RESELLER_CUSTOMER] = 'Customer is required';
  }
  return errors;
}

async function gcpDetailsValidation(currentScreen) {
  const errors = {};
  const billingTableName = currentScreen[GCP_ONBOARDING_FIELDS.BILLING_TABLE_NAME];
  const billingDatasetName = currentScreen[GCP_ONBOARDING_FIELDS.BILLING_DATASET_NAME];
  const serviceAccountKey = currentScreen[GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY];
  const accountName = currentScreen[GCP_ONBOARDING_FIELDS.ACCOUNT_NAME];
  const projectID = currentScreen[GCP_ONBOARDING_FIELDS.PROJECT_ID];
  if (!billingTableName) {
    errors[GCP_ONBOARDING_FIELDS.BILLING_TABLE_NAME] = 'Billing table name is required';
  }
  if (!projectID) {
    errors[GCP_ONBOARDING_FIELDS.PROJECT_ID] = 'Project ID is required';
  }
  if (!billingDatasetName) {
    errors[GCP_ONBOARDING_FIELDS.BILLING_DATASET_NAME] = 'Billing dataset name is required';
  }
  if (!serviceAccountKey) {
    errors[GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY] = 'Service account key is required';
  }
  if (!accountName) {
    errors[GCP_ONBOARDING_FIELDS.ACCOUNT_NAME] = 'Account Name is required';
  }

  if (Object.values(errors).length) {
    return errors;
  }

  return errors;
}

export default async function useFormValidation(currentScreen) {
  switch (currentScreen.screen) {
    case GCP_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return {
        errors: accountTypeValidation(currentScreen),
        currentScreen,
      };
    case GCP_ONBOARDING_STEPS.CREATE_APP:
      return {
        errors: {},
        currentScreen,
      };
    case GCP_ONBOARDING_STEPS.GCP_DETAILS:
      return {
        errors: await gcpDetailsValidation(currentScreen),
        currentScreen,
      };
    case GCP_ONBOARDING_STEPS.PROCESS_DATA:
      return {
        errors: {},
        currentScreen,
      };
    case GCP_ONBOARDING_STEPS.K8S_INTEGRATION:
      return {
        errors: {},
        currentScreen,
      };
    default:
      return {
        errors: {},
        currentScreen,
      };
  }
}
