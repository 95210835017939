import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/divisions/customers/billing-profile';

const getBillingProfileInfoApi = (accountId) => API.get('billings', `${root}?accountId=${accountId}`);

const updateBillingProfileApi = ({ accountId, isOnboarding, data }) =>
  API.put('billings', `${root}?accountId=${accountId}&isOnboarding=${isOnboarding}`, { body: data });

export default function useBillingProfile() {
  const queryClient = useQueryClient();

  const queryKey = apiConstants.QUERY_KEYS.BILLING_PROFILE;

  const updateBillingProfileMutation = useMutation(updateBillingProfileApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
    },
  });

  return {
    fetchBillingProfile: (accountId) => useQuery([queryKey, accountId], () => getBillingProfileInfoApi(accountId)),
    updateBillingProfileMutation,
  };
}
