/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { Recommendation } from 'recommendations/models/recommTypes';
import { RecommendationIdentifier } from 'recommendations/models/recommCommonTypes';
// import DateFilter from 'shared/modules/dateFilter';

export default class BaseRecommendationBuilder {
  constructor(rawRecommendation) {
    this._rawRecommendation = rawRecommendation;
    this.createRecommendation();
  }

  createRecommendation() {
    this._recommendation = new Recommendation();
  }

  getRecommType() {
    return RecommendationTypes.INVALID_TYPE;
  }

  buildRecommTitle() {
    this._recommendation.title = 'Title';
  }

  build() {
    const {
      uuid,
      type,
      action,
      suggestion_status_id: status,
      is_suggestion_active: isActive,
      is_recommendation_active: isRecommActive,
      recommendation_status_id: recommStatus,
      recommendation_creation_time: creationTime,
      initial_creation_date: initialCreationDate,
      recommendation_update_time: updateTime,
      alert_id: alertId,
      rejected_reason_id: rejReason,
      exclude_message: excludeMessage,
      linked_account_id: linkedAccountId,
      subscription_id: subscriptionId,
      linked_account_name: linkedAccountName,
      custom_tags: customTags,
      resource_id: resourceId,
      resource_tags: resourceTags,
      signature,
      labels,
      zone_tag: region,
    } = this._rawRecommendation;

    const recommIdentifier = new RecommendationIdentifier(uuid, type);

    // FLAT OBJECT FOR NOW
    this._recommendation.key = recommIdentifier.key;
    this._recommendation.type = recommIdentifier.type;
    this._recommendation.linkedAccountId = linkedAccountId || subscriptionId;
    this._recommendation.subscriptionId = subscriptionId;
    this._recommendation.linkedAccountName = linkedAccountName;
    this._recommendation.customTags = customTags;
    this._recommendation.resourceId = resourceId;
    this._recommendation.resourceTags =
      !!resourceTags && typeof resourceTags === 'object'
        ? Object.entries(resourceTags).map(([k, v]) => `${k}: ${v}`)
        : null;
    this._recommendation.action = action;
    this._recommendation.status = recommStatus !== undefined ? parseInt(recommStatus, 10) : parseInt(status, 10);
    this._recommendation.isActive =
      isRecommActive !== undefined ? parseInt(isRecommActive, 10) === 1 : parseInt(isActive, 10) === 1;
    this._recommendation.age = initialCreationDate
      ? Math.ceil((Date.parse(new Date()) - Date.parse(new Date(initialCreationDate))) / 86400000)
      : '-';
    this._recommendation.creationTime = creationTime;
    this._recommendation.updateTime = updateTime;
    this._recommendation.alertId = parseInt(alertId, 10);
    this._recommendation.rejReason = parseInt(rejReason, 10);
    this._recommendation.excludeMessage = excludeMessage;
    this._recommendation.signature = signature;
    this._recommendation.labels = labels;
    this._recommendation.region = region || '';

    this.buildRecommTitle();
  }

  get recommendation() {
    return this._recommendation;
  }
}
