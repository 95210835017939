import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBox from 'shared/components/andtComponents/Checkbox';
import Select from 'shared/components/Select';
import { PeriodOptions, ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import { ReportContext } from 'shared/components/reportModal/ReportModal';
import styles from './ReportModal.module.scss';

const CueReportContent = ({ hideCumulativeCheckBox }) => {
  const { reportData, setReportData, data } = useContext(ReportContext);

  useEffect(() => {
    setReportData((prev) => ({
      ...prev,
      isCumulativeChecked: data.chartType === 'cumulative',
      isSnapshotChecked: data.keepDates || false,
      period: data.relativeDates || data.periodType || ReportPeriodTime.THIRTY_DAYS,
    }));
  }, [data]);
  return (
    <>
      {!hideCumulativeCheckBox ? (
        <div className={styles.field}>
          <CheckBox
            onChange={() => {
              setReportData((prev) => ({ ...prev, isCumulativeChecked: !prev.isCumulativeChecked }));
            }}
            isChecked={reportData.isCumulativeChecked}
            text="Cumulative chart"
          />
        </div>
      ) : null}
      <div className={styles.field}>
        <CheckBox
          onChange={() => {
            setReportData((prev) => ({ ...prev, isSnapshotChecked: !prev.isSnapshotChecked }));
          }}
          isChecked={reportData.isSnapshotChecked}
          text="Save as snapshot"
        />
      </div>
      {!reportData.isSnapshotChecked ? (
        <div className={`${styles.field} ${styles.halfField}`}>
          <div className={styles.fieldTitle}>Report period</div>
          <Select
            className={styles.select}
            value={`${reportData.period}`}
            options={PeriodOptions}
            onChange={(event) => {
              setReportData((prev) => ({ ...prev, period: event.target.value }));
            }}
            automationId="report-period"
          />
        </div>
      ) : null}
    </>
  );
};

CueReportContent.propTypes = {
  hideCumulativeCheckBox: PropTypes.bool,
};

CueReportContent.defaultProps = {
  hideCumulativeCheckBox: false,
};
export default CueReportContent;
