/* eslint-disable max-len,no-restricted-syntax */

import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentPageTypes,
  mapRecpageTypeToDefaultDetailsDiaply,
} from 'recommendations/constants/recommendationsConstants';
import { DataTable } from 'shared/components/DataTable';
import ContentDetail from 'recommendations/shared/components/ContentDetail';
import ContentDetails from 'recommendations/containers/DailyRecommendations/components/ContentDetails';
import { mapRecTypetoContentCreator } from 'recommendations/constants/recommContentCreators';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const createRiUnitsData = (rec) => {
  const { mapInstanceTypeToCount } = rec;

  const headings = ['Instance Type', 'Num of Instances'];
  const rows = [];
  const entries = Object.entries(mapInstanceTypeToCount);
  for (const [instanceType, count] of entries) {
    rows.push([instanceType, count]);
  }

  return { headings, rows };
};

const renderContentDetails = (rec, contentPageType) => {
  const { getCurrencyNumber, currencyCode } = useUserSettingsContext();
  const showRiUnits =
    contentPageType === ContentPageTypes.RECS_PAGE || contentPageType === ContentPageTypes.RECS_GENERAL;
  const tableData = createRiUnitsData(rec);
  const contentDetailsArray = [];
  const RecTypeContentDetailsClass = mapRecTypetoContentCreator.get(rec.type);
  const recTypeContentDetails = new RecTypeContentDetailsClass(rec, contentPageType, getCurrencyNumber, currencyCode);
  const content = recTypeContentDetails.createContent(rec);
  if (content) {
    contentDetailsArray.push(...content);
  }

  if (showRiUnits) {
    contentDetailsArray.push(
      <ContentDetail display="RI Units:" value={<DataTable headings={tableData.headings} rows={tableData.rows} />} />,
    );
  }
  return [...contentDetailsArray];
};

const Content = ({ rec, contentPageType }) => (
  <div style={{ 'line-height': '30px', 'font-size': '13px' }}>
    <table
      className={`project-summary__info ${contentPageType === ContentPageTypes.DAILY_RECS ? 'daily-rec__width' : ''}`}
    >
      <ContentDetails contentDisplay={mapRecpageTypeToDefaultDetailsDiaply.get(contentPageType)}>
        {renderContentDetails(rec, contentPageType)}
      </ContentDetails>
    </table>
  </div>
);

Content.propTypes = {
  rec: PropTypes.object.isRequired,
  contentPageType: PropTypes.object.isRequired,
};

export default Content;
