import React, { useMemo } from 'react';
import { ComposedChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Bar, Line, Legend, Tooltip } from 'recharts';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { StringToColor } from 'shared/utils/graphicUtil';
import { formatDateToShortLabel } from 'shared/utils/dateUtil';
import styles from './DailyCostBarChart.module.scss';

const DailyCostBarChart = ({ data, servicePlan }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const formattedData = useMemo(() => {
    const grouped = groupBy(data, (d) => d.date);
    return Object.keys(grouped)
      .map((date) =>
        grouped[date].reduce(
          (acc, curr) => ({
            ...acc,
            date,
            [curr.appName]: curr.totalCost,
            type: curr.type,
          }),
          {},
        ),
      )
      .sort((a, b) => a.date.localeCompare(b.date));
  }, [data]);
  const apps = useMemo(() => [...new Set(data.map((d) => d.appName))], [data]);
  const renderLegend = ({ payload }) => (
    <ul className={styles.legend}>
      {[...payload]
        .sort((a, b) => +(b.type === 'line') - +(a.type === 'line'))
        .map((entry) => (
          <li key={`item-${entry.value}`} data-line={entry.type === 'line'}>
            <span style={{ backgroundColor: entry.color }} />
            {entry.value}
          </li>
        ))}
    </ul>
  );
  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={formattedData}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 20,
          }}
          stackOffset="sign"
        >
          <CartesianGrid stroke="#E9E9F0" />
          <Legend layout="vertical" verticalAlign="middle" align="right" content={renderLegend} />
          <XAxis
            dataKey="date"
            stroke="#8995A0"
            axisLine={{ stroke: '#D4D9E0' }}
            tickFormatter={(value) => formatDateToShortLabel(value)}
          />
          <YAxis
            stroke="#8995A0"
            axisLine={{ stroke: '#D4D9E0' }}
            tickFormatter={(value) => getCurrencyNumber(value, 2)}
          />
          <Tooltip formatter={(value) => getCurrencyNumber(value, 2)} />
          {[...apps].reverse().map((app) => {
            if (app === servicePlan) {
              return <Line dataKey={app} key={app} stroke="#000000" />;
            }
            return <Bar dataKey={app} key={app} barSize={44} stackId="stack" fill={StringToColor.next(app)} />;
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

DailyCostBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  servicePlan: PropTypes.string.isRequired,
};

export default DailyCostBarChart;
