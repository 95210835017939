import currencyToSymbolMap from 'currency-symbol-map/map';

export const filterOptions = (option, inputValue) =>
  option.data.key.toLowerCase().includes(inputValue.toLowerCase()) ||
  option.data.value.toLowerCase().includes(inputValue.toLowerCase());

export const getCurrenciesSymbol = () => {
  const currencyCodes = Object.keys(currencyToSymbolMap);

  const currencies = currencyCodes.map((code) => ({
    key: code,
    value: currencyToSymbolMap[code],
  }));
  return currencies;
};
