/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { IpRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class IpCostRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new IpRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_IP_UNATTACHED;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} IP Unattached`;
  }

  build() {
    super.build();
    const {
      zone_tag: tagZone,
      // allocation_id: allocationId,
      public_ip: publicIp,
      potential_savings: potentialSavings,
      linked_account_id: linkedAccountID,
    } = this._rawRecommendation;

    this._recommendation.region = tagZone;
    this._recommendation.publicIp = publicIp;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.linkedAccountID = linkedAccountID;
  }
}
