import React from 'react';
import { ReactComponent as SavingsPlansImg } from '../assets/savings-plans.svg';
import styles from './empty.module.scss';

const Empty = () => (
  <div className={styles.empty}>
    <SavingsPlansImg />
    <h4>Most of your costs are already covered by savings plans.</h4>
    <h4>Purchasing more savings plans would increase your current cost. Therefore, we have no plans to recommend.</h4>
  </div>
);

export default Empty;
