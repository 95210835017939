import React from 'react';
import DescriptionWrapper from 'recommendations/shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>
      We have observed that the current utilization of this RDS instance is relatively low. As a cost-saving measure, we
      highly recommend downsizing the instance, which will help reduce your expenses.
    </p>
  </DescriptionWrapper>
);

export default Description;
