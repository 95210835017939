import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';

export const getDivisionUsers = (users, divisionName) => users.filter((user) => user.divisionName === divisionName);
export const getInitialDate = () => {
  const { startDate: startDateStr } = buildStartAndEndDate(null, null);
  const month = new Date(startDateStr).getUTCMonth();
  const year = new Date(startDateStr).getUTCFullYear();
  const rawStartDate = new Date(year, month - 1, 1);
  const rawEndDate = new Date(year, month, 0);
  const startDate = createTimeZoneAgnosticDateFromStr(buildStartAndEndDate(rawStartDate, null).startDate);
  const endDate = createTimeZoneAgnosticDateFromStr(buildStartAndEndDate(null, rawEndDate).endDate);
  return { startDate, endDate };
};
