import React, { useContext, useMemo } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { useHeatMapContext } from '../../contexts/heatMapContext';
import HeatMapActionsContext from '../../contexts/heatMapActionsContext';
import { getFiltersForSavingOpportunities } from '../filterStates';

import classes from './toggleWasteChart.module.scss';

export const ToggleWasteChart = () => {
  const { isWasteChart, setIsWasteChart } = useHeatMapContext();
  const { isSavingsTracker, setIsSavingsTracker } = useContext(HeatMapActionsContext);
  const { recommendationFilters: filtersContext, setRecommendationFilters } = useRecommendationsNewContext();

  const toggleTitle = useMemo(() => (isWasteChart ? 'Waste Chart' : 'Waste Map'), [isWasteChart]);

  const onToggle = () => {
    const newIsWasteChart = !isWasteChart;
    if (newIsWasteChart) {
      if (isSavingsTracker) {
        setIsSavingsTracker(false);
      }
      setRecommendationFilters(getFiltersForSavingOpportunities(filtersContext));
    }
    setIsWasteChart((prev) => !prev);
  };

  return (
    <div className="d-flex flex-column" automation-id="heatmap-toggle-cloud-waste">
      <div className={classes.toggleContainer} onClick={onToggle}>
        <div className={classes.toggleTitle}>
          <GenerateIcon
            iconName={isWasteChart ? ICONS.fileInvoiceDollarSolid.name : ICONS.fire.name}
            className={classes.icon}
          />
          {toggleTitle}
        </div>
        <div className={classes.toggleSwitch}>
          <GenerateIcon
            iconName={ICONS.caretUp.name}
            className={`${classes.upperCaret} ${isWasteChart ? classes.selected : ''}`}
          />
          <GenerateIcon
            iconName={ICONS.caretDown.name}
            className={`${classes.lowerCaret} ${isWasteChart ? '' : classes.selected}`}
          />
        </div>
      </div>
    </div>
  );
};

ToggleWasteChart.propTypes = {};
