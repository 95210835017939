/* eslint-disable no-plusplus */
/* eslint-disable max-len,arrow-body-style */
import React, { PureComponent } from 'react';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import MaxIopsChart from 'recommendations/shared/components/MaxIopsChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createMaxIopsData = (rec) => {
    const { maxIopsData, maxIopsPossibleGp2, maxIopsPossibleIo1 } = rec;
    const maxIopsDataEntries = Object.entries(maxIopsData).sort((a, b) => b[0].localeCompare(a[0]));
    maxIopsDataEntries.reverse();
    const iopsData = maxIopsDataEntries.map(([key, value]) => {
      return { date: createDateDisplayStr(key), iops: parseFloat(value) };
    });

    return {
      iopsData,
      maxIopsPossibleGp2,
      maxIopsPossibleIo1,
    };
  };

  render() {
    const { rec } = this.props;

    const maxIopsData = this.createMaxIopsData(rec);
    return (
      <div>
        {this.state.update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Daily Max IOPS Performance</p>
          <br />
          <MaxIopsChart data={maxIopsData} />
        </div>
      </div>
    );
  }
}
