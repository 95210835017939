import LabelCoordinator from 'shared/modules/labelCoordinator';
import { mapK8sCostTypesToDisplay } from 'shared/constants/k8sConstants';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants';
import { capitalize } from 'shared/utils/strUtil';
import { AwsCommonFields, awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS, mapCloudTypeIdToDescription } from 'users/constants/usersConstants';

const getTitleFormat = (field, isK8S, number) => {
  if (field === 'deployment') {
    return 'deploymentName';
  }
  if (isK8S && field !== 'view') {
    return `${field}_${number}Value`;
  }
  return `${field}Value_${number}`;
};

const prepareCellName = (col, index, isK8S, counters) => {
  let colType = '';
  let k8sIndex = null;
  if (col.value.includes('viewscustomtags')) {
    counters.incViews();
    colType = 'view';
    k8sIndex = counters.getViews();
  } else if (col.value.includes('customtags')) {
    counters.incTags();
    colType = 'customTag';
    k8sIndex = counters.getTags();
  } else if (col.value.includes('labels')) {
    counters.incLabels();
    colType = 'label';
    k8sIndex = counters.getLabels();
  } else if (col.value.includes('accounttags')) {
    counters.incAccountTags();
    colType = 'accountTag';
    k8sIndex = counters.getAccountTags();
  } else if (col.value === 'deployment') {
    colType = 'deployment';
  }
  return colType ? getTitleFormat(colType, isK8S, isK8S ? k8sIndex : index + 1) : col.value;
};

const prepareCellValue = (col, row, name) =>
  // k8s data is returning like: Label1, Label2, Tags1, Region, Tags2, while CUE data has index of groupBy attribute:
  // Label1, Label2, Tags3, Tags4, Region, Tags5
  row[name];
const prepareCellTitle = (col) => {
  let colVal = col.value;
  if (colVal.includes('customtags') || colVal.includes('labels') || colVal.includes('accounttags')) {
    colVal = col.label;
    return colVal;
  }
  return LabelCoordinator.getFieldLabel(colVal);
};
const addTotalUsageQuantity = (preparedColumns, groupByColumns) => {
  const colValues = groupByColumns && groupByColumns.map((col) => col.value);
  if (Array.isArray(colValues) && colValues.includes('quantitytype')) {
    preparedColumns.push({ name: 'totalUsageQuantity', title: 'Quantity' });
  }
};

export const prepareTableColumns = (
  groupByColumns,
  isK8S,
  costTypes = [],
  currDispUserCloudAccountType,
  getCurrencyNumber,
  overrideCurrency = null,
) => {
  try {
    const makeCounter = () => {
      let labels = 0;
      let tags = 0;
      let accountTags = 0;
      let views = 0;
      return {
        getLabels: () => labels,
        getTags: () => tags,
        getAccountTags: () => accountTags,
        getViews: () => views,
        incLabels: () => {
          labels += 1;
        },
        incTags: () => {
          tags += 1;
        },
        incAccountTags: () => {
          accountTags += 1;
        },
        incViews: () => {
          views += 1;
        },
      };
    };
    const getLabel = (name) => {
      const label = GCPCommonFieldLabels.get(name);
      if (label && currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP) {
        return label;
      }
      return null;
    };
    const counters = makeCounter();
    const preparedColumns = groupByColumns
      ? groupByColumns
          .map((col, index) => {
            const name = prepareCellName(col, index, isK8S, counters);
            if (name === AwsCommonFields.CLOUD_PROVIDER) {
              return {
                title: awsCommonFieldToDisplayField.get(name),
                name,
                getCellValue: (row) => capitalize(mapCloudTypeIdToDescription.get(row.cloudTypeId)),
              };
            }
            if (name === AwsCommonFields.COST_TYPE) {
              return {
                title: awsCommonFieldToDisplayField.get(name),
                name,
                getCellValue: (row) => capitalize(row.costTypeName),
              };
            }
            if (name === AwsCommonFields.WORKLOAD_TYPE) {
              return {
                title: prepareCellTitle(col),
                name,
                getCellValue: (row) => row.creatorKindName,
              };
            }
            if (name.startsWith(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS)) {
              return {
                title: LabelCoordinator.getDataKeyDisplayName(
                  'cueDisplayCoordinator',
                  name.replace(`${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: `, ''),
                ),
                name,
                getCellValue: (row) => prepareCellValue(col, row, name),
              };
            }
            return {
              name,
              title: getLabel(name) || prepareCellTitle(col).replace(/[.]/g, ' '),
              getCellValue: (row) => prepareCellValue(col, row, name),
            };
          })
          .reduce((acc, col) => {
            // merge all workload values columns into one column
            if (!col.name?.startsWith(AwsCommonFields.WORKLOAD)) {
              return [...acc, col];
            }
            const ind = acc.findIndex((col) => col.name?.startsWith(AwsCommonFields.WORKLOAD));
            const colParams = {
              title: 'Workload',
              name: AwsCommonFields.WORKLOAD,
              getCellValue: (row) =>
                row.groupBy === 'Other Workload Types' ? row.groupBy : `${row.creatorKindName}:${row.groupBy}`,
            };
            if (ind === -1) {
              return [...acc, { ...col, ...colParams }];
            }

            return acc;
          }, [])
      : [];
    // separate link id and name for 2 columns in assets table
    const linkAccountColumn = preparedColumns.find((c) => c.name === AwsCommonFields.LINKED_ACCOUNT_ID);
    if (linkAccountColumn) {
      preparedColumns.splice(preparedColumns.indexOf(linkAccountColumn), 0, {
        name: AwsCommonFields.LINKED_ACCOUNT_NAME,
        title: `${linkAccountColumn.title} Name`,
      });
      linkAccountColumn.title = `${linkAccountColumn.title} Id`;
    }

    preparedColumns.push({ name: 'usagedate', title: 'Date' });
    if (isK8S) {
      costTypes.forEach((costType) => {
        const name = `${costType}Cost`;
        preparedColumns.push({
          name,
          title: mapK8sCostTypesToDisplay.get(costType),
          getCellValue: (row) => `${getCurrencyNumber(row[name], 2, null, overrideCurrency)}`,
        });
      });
    }
    preparedColumns.push({ name: 'totalcost', title: 'Total Cost' });
    addTotalUsageQuantity(preparedColumns, groupByColumns);
    const resourceInd = preparedColumns.findIndex((c) => c.name === 'resourceid');
    if (resourceInd >= 0) {
      preparedColumns.splice(resourceInd, 0, { title: 'Resource Name', name: 'resource' });
    }
    return preparedColumns || [];
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const prepareTableColumnsWidths = (columns) => {
  const getColumnWidth = (columnName) => {
    if (columnName === AwsCommonFields.LINKED_ACCOUNT_NAME) {
      return 200;
    }
    if (columnName === AwsCommonFields.LINKED_ACCOUNT_ID) {
      return 160;
    }
    return 150;
  };
  const preparedColumns =
    columns &&
    columns.map((col) => ({
      columnName: col.name,
      width: getColumnWidth(col.name),
    }));
  return preparedColumns || [];
};
export const prepareTableCostColumns = () => {
  const preparedColumns = ['totalcost', 'unblended', 'netunblended', 'amortized', 'netamortized'];
  return preparedColumns;
};
