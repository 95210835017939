import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { Grid, PagingPanel, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedFiltering,
  FilteringState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SortingState,
  SummaryState,
} from '@devexpress/dx-react-grid';
import { groupBy } from 'lodash';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { formatDateToShortLabel } from 'shared/utils/dateUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import { Routes } from 'shared/constants/routes';
import Button from 'shared/components/andtComponents/Button';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { APP_SERVICE_TYPES } from '../../constants';
import DailyCostBarChart from '../DailyCostBarChart';
import styles from './RowDetails.module.scss';

const RowDetails = ({ row, fetchWebApplications, startDate, endDate }) => {
  const { data: webApplications, isLoading } = fetchWebApplications(row.servicePlanId);
  const { getCurrencyNumber } = useUserSettingsContext();

  const { customTags, webAppsN } = row;
  const formattedData = useMemo(() => {
    const grouped = groupBy(webApplications || [], (d) => d.appName);
    return Object.values(grouped || {}).map((group) =>
      group.reduce(
        (acc, curr) => ({
          ...acc,
          resourceId: curr.resourceId,
          appName: curr.appName,
          type: curr.type,
          [curr.date]: curr.totalCost,
        }),
        {},
      ),
    );
  }, [webApplications]);
  const [formattedColumns, columnsWidths] = useMemo(() => {
    const dates = [...new Set((webApplications || []).map((d) => d.date))].sort((a, b) => a.localeCompare(b));
    return [
      [
        { name: 'appName', title: 'App Service Plan & Web Apps' },
        {
          name: 'type',
          title: 'Type',
          getCellValue: ({ type, resourceId }) => (
            <p>
              {APP_SERVICE_TYPES[type]}{' '}
              {type === 'WEB_APP' ? (
                <Button
                  text=""
                  isTextButton
                  icon={() => <GenerateIcon iconName={ICONS.magnifyingGlass.name} />}
                  onClick={() => {
                    const filterParams = new URLSearchParams();
                    filterParams.set('fieldToFilterdValuesMap', JSON.stringify({ resourceid: [resourceId] }));
                    filterParams.set('startDate', startDate);
                    filterParams.set('endDate', endDate);
                    window.open(`${Routes.COST_USAGE_EXPLORER}?${filterParams.toString()}`, '_blank');
                  }}
                />
              ) : null}
            </p>
          ),
        },
        ...dates.map((d) => ({
          name: d,
          title: formatDateToShortLabel(d),
          getCellValue: (row) => getCurrencyNumber(row[d], 4),
        })),
      ],
      [
        { columnName: 'appName', width: 250, type: 'count' },
        { columnName: 'type', width: 150, type: 'count' },
        ...dates.map((d) => ({ columnName: d, width: 100, type: 'sum' })),
      ],
    ];
  }, [webApplications]);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner position="relative" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.shortInfo}>
          <div className={styles.webApps}>
            <GenerateIcon iconName={ICONS.earth.name} />
            Web Apps: {webAppsN}{' '}
            <InfoPopover isSimple>
              The list of Web Apps that associated to this App Service Plan is corresponding to the selected Date Range,
              the list might be vary per different time period
            </InfoPopover>
          </div>
          <div className={styles.tags}>
            Tags:{' '}
            <div className={styles.tagsList}>
              {Object.keys(customTags || {}).map((ct) => (
                <Badge color="inherit" key={ct} className={styles.tagBadge}>
                  {ct}: <span>{customTags[ct]}</span>
                </Badge>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className={styles.chartTitle}>Cost ($)</div>
          <div className={styles.chartWrapper}>
            <DailyCostBarChart data={webApplications} servicePlan={row.servicePlan} />
          </div>
          <div className={`sub-table ${styles.gridWrapper}`}>
            <Grid rows={formattedData} columns={formattedColumns}>
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <SortingState defaultSorting={[{ columnName: 'appName', direction: 'asc' }]} />
              <IntegratedSorting />
              <SummaryState totalItems={columnsWidths} />
              <IntegratedSummary />
              <PagingState defaultCurrentPage={0} pageSize={20} />
              <IntegratedPaging />
              <TableWrapper virtual height={Math.min(formattedData?.length * 50 + 55, 500)} />
              <TableColumnResizing defaultColumnWidths={columnsWidths} resizingMode="nextColumn" />
              <TableHeaderRow showSortingControls />
              <PagingPanel />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

RowDetails.propTypes = {
  row: PropTypes.shape({
    customTags: PropTypes.object,
    servicePlanId: PropTypes.string.isRequired,
    servicePlan: PropTypes.string.isRequired,
    webAppsN: PropTypes.number.isRequired,
  }).isRequired,
  fetchWebApplications: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default RowDetails;
