import { useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { sanitizeQuery } from 'shared/utils/apiUtil';
import { queryClient } from 'queryClient';
import { prepareSPAnalyzerFilters } from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils';

const root = '/api/v1/commitment/sp-analyzer';

const getSavingsPlansAnalyzerData = async (
  selectedOptions,
  daysToCheck,
  filterStatusType,
  selectedPaymentOption,
  selectedTerm,
  coverage,
  hourlyCommitment,
) => {
  const { includeFilterParams, excludeFilterParams } = prepareSPAnalyzerFilters(selectedOptions, filterStatusType);
  // eslint-disable-next-line max-len
  let apiQuery = `${root}?daysToCheck=${daysToCheck}&paymentOption=${selectedPaymentOption}&term=${selectedTerm}&coverage=${
    coverage / 100
  }${includeFilterParams}${excludeFilterParams}${hourlyCommitment ? `&hourlyCommitment=${hourlyCommitment}` : ''}`;
  apiQuery = sanitizeQuery(apiQuery);
  return API.get('billings', apiQuery);
};

export default function useSpAnalyzer() {
  const queryKey = apiConstants.QUERY_KEYS.SP_ANALYZER_DATA;

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    fetchSavingsPlansAnalyzerData: (
      selectedOptions,
      daysToCheck = 30,
      filterStatusType,
      selectedPaymentOption,
      selectedTerm,
      coverage,
    ) =>
      useQuery(
        [queryKey],
        () =>
          getSavingsPlansAnalyzerData(
            selectedOptions,
            daysToCheck,
            filterStatusType,
            selectedPaymentOption,
            selectedTerm,
            coverage,
            null,
          ),
        { retry: false, enabled: true },
      ),

    fetchSingleOptionPlanAnalyzerData: (
      selectedOptions,
      daysToCheck,
      filterStatusType,
      paymentOption,
      term,
      coverage,
      hourlyCommitment,
    ) =>
      useQuery(
        [queryKey, hourlyCommitment],
        () =>
          getSavingsPlansAnalyzerData(
            selectedOptions,
            daysToCheck,
            filterStatusType,
            paymentOption,
            term,
            coverage,
            hourlyCommitment,
          ),
        {
          retry: false,
          enabled:
            daysToCheck !== null &&
            coverage !== null &&
            term !== null &&
            paymentOption !== null &&
            hourlyCommitment !== null,
        },
      ),
  };
}
