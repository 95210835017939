import React, { useMemo, useState } from 'react';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common';

import styles from './TabsSelector.module.scss';

const TabsSelector = ({ tabsInfo, row }) => {
  const [selectedTab, setSelectedTab] = useState(tabsInfo.find((o) => o.isSelected === true));

  const onSelectedTabComponentChange = (tabId) => {
    setSelectedTab(tabsInfo.find((o) => o.id === tabId));
  };

  const selectedTabGetComponent = useMemo(() => selectedTab?.getComponent?.(row), [selectedTab]);

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        {tabsInfo?.map((tab) => (
          <div
            key={tab.id}
            className={`${tab.id === selectedTab.id ? styles.selected : ''} ${styles.tab}`}
            onClick={() => onSelectedTabComponentChange(tab.id)}
          >
            <GenerateIcon iconName={tab.iconName} className={styles.icon} />
            {tab.label}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>{selectedTabGetComponent}</div>
    </div>
  );
};

export default TabsSelector;
