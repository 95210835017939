/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-empty */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import { TextField } from '@mui/material';
import { alertStatusToActionButtonsArray, CAU_ALERTS_STATUS, CAU_ALERTS_TYPES } from 'usage/constants/usageConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import toast from 'shared/components/andtComponents/Toast';
import CauHelper from '../helpers/cauAlertsHelpers';
import CauValidationHelper from '../helpers/cauAlertsValidationHelper';

class CostChangesAlert extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    alertId: PropTypes.string.isRequired,
    alertType: PropTypes.string.isRequired,
    updatePreSaveAlertsValidationStatus: PropTypes.func.isRequired,
    setIsInitialDataFetch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      alertStatus: CAU_ALERTS_STATUS.ADD,
      alertType: this.props.alertType,
      // deleteAlertWarningMessage: false,
      isDeleteAlertWarning: false,
      selectedFilterValues: {
        daysBeforeExpiry: '1',
        recipientsEmails: '',
      },
      validationStatus: {
        isValid: false,
        daysBeforeExpiry: { isValid: true, errorMessage: 'You must enter at least one day' },
        recipientsEmails: { isValid: true, errorMessage: 'Recipients mail is not valid' },
      },
    };
    this.cauHelper = new CauHelper(
      this,
      this.props.usageStore,
      this.props.invoiceStore,
      this.props.updatePreSaveAlertsValidationStatus,
      this.props.alertId,
    );
    this.cauValidationHelper = new CauValidationHelper(
      this,
      this.props.usageStore,
      this.props.invoiceStore,
      this.props.updatePreSaveAlertsValidationStatus,
      this.props.alertId,
    );
    this.resetToBaseState = this.resetToBaseState.bind(this);
  }

  componentDidMount() {
    if (this.props.alertId) {
      const alert = this.props.usageStore.getCauAlert(this.props.alertId);
      const { alertStatus, alertType, selectedFilterValues } = this.cauHelper.prepareAlertDataToState(alert);
      this.setState({ alertStatus, alertType, selectedFilterValues });
    }
  }

  componentDidUpdate() {
    if (this.props.alertId) {
      const alert = this.props.usageStore.getCauAlert(this.props.alertId);
      if (alert.alertStatus !== this.state.alertStatus) {
        const { alertStatus, alertType, selectedFilterValues } = this.cauHelper.prepareAlertDataToState(alert);
        if (alert.alertStatus === CAU_ALERTS_STATUS.SAVE && this.state.alertStatus === CAU_ALERTS_STATUS.EDIT) {
          this.resetToValidationBaseState();
        }
        this.setState({ alertStatus, alertType, selectedFilterValues });
      }
    }
  }

  renderActionButtons = (alertStatus) => {
    const buttons = alertStatusToActionButtonsArray[alertStatus];
    if (JSON.stringify(buttons) === JSON.stringify(['add'])) {
      return null;
    }
    return buttons.map((button, index) => (
      <img
        className={
          index === 1 ? 'alerts-buttonplus right' : buttons.includes('add') ? 'alerts-save' : 'alerts-buttonplus'
        }
        key={`b-${index}`}
        // className="alerts-buttonplus"
        src={this.cauHelper.getActionButtonIcon(button)}
        alt="close"
        name={button}
        onClick={(e) => this.cauHelper.getActionButtonHandler(e, this.props.alertId)}
      />
    ));
  };

  validateAlertBeforeAdd = () => {
    const { recipientsEmails, daysBeforeExpiry } = this.state.selectedFilterValues;
    const { validationStatus } = this.state;
    validationStatus.recipientsEmails.isValid = this.cauValidationHelper.emailIsValid(recipientsEmails);
    validationStatus.daysBeforeExpiry.isValid = !!daysBeforeExpiry;
    validationStatus.isValid = this.cauValidationHelper.isValidationStatusValid(this.state.alertType, validationStatus);
    return validationStatus;
  };

  resetToBaseState = () => {
    const baseState = {
      alertStatus: CAU_ALERTS_STATUS.ADD,
      alertType: this.props.alertType,
      // deleteAlertWarningMessage: false,
      isDeleteAlertWarning: false,
      selectedFilterValues: {
        daysBeforeExpiry: '1',
        recipientsEmails: '',
      },
      validationStatus: {
        isValid: false,
        daysBeforeExpiry: { isValid: true, errorMessage: 'You must provide number of days (higher than 0)' },
        recipientsEmails: { isValid: true, errorMessage: 'Recipients mail is not valid' },
      },
    };
    const { validationStatus, selectedFilterValues, alertStatus, alertType, isDeleteAlertWarning } = baseState;
    this.setState({ validationStatus, selectedFilterValues, alertStatus, alertType, isDeleteAlertWarning });
  };
  resetToValidationBaseState = () => {
    const validationStatus = {
      isValid: false,
      daysBeforeExpiry: { isValid: true, errorMessage: 'You must provide number of days (higher than 0)' },
      recipientsEmails: { isValid: true, errorMessage: 'Recipients mail is not valid' },
    };
    this.setState({ validationStatus });
  };

  deleteAlertHandler = async (action) => {
    if (action === 'cancel') {
      this.setState({ isDeleteAlertWarning: false });
    } else if (action === 'delete') {
      let { alertStatus } = this.state;
      if (alertStatus !== CAU_ALERTS_STATUS.SAVE) {
        return;
      }
      try {
        const { setIsInitialDataFetch, usageStore } = this.props;
        this.setState({ isDeleteAlertWarning: false });
        setIsInitialDataFetch(true);
        const isAlertDeleted = await usageStore.deleteCauAlert(this.props.alertId);
        setIsInitialDataFetch(false);
        if (!isAlertDeleted) {
          toast.error('Something went wrong please try again later');
        }
      } catch (error) {
        this.setState({ isDeleteAlertWarning: false });
      }
    }
  };

  renderValidationMessage = (attr) =>
    this.state.validationStatus[attr].isValid ? null : (
      <p>
        <span style={{ color: 'red' }}> {this.state.validationStatus[attr].errorMessage} </span>
      </p>
    );

  render() {
    const { invoiceStore } = this.props;
    const { selectedFilterValues, alertStatus } = this.state;
    return (
      <Container className="alerts-cost-changes-container">
        <div>
          <div className="alerts-buttons-wrapper">{this.renderActionButtons(alertStatus)}</div>
        </div>
        <div className={`alerts-cost-changes-inputs-wrapper ${alertStatus === CAU_ALERTS_STATUS.SAVE ? 'saved' : ''}`}>
          <div className="alerts-cost-changes-firts-row">
            <div className="alerts-inner-connecting-text">Send alert</div>
            <div className="alerts-inner-text-input-small">
              <TextField
                name="daysBeforeExpiry"
                onChange={(e) => this.cauHelper.handleTextInputChange(e)}
                value={selectedFilterValues.daysBeforeExpiry}
                min={1}
                size="small"
                type="number"
              />
              {this.renderValidationMessage('daysBeforeExpiry')}
            </div>
            <div className="alerts-inner-connecting-text">
              {`days before my ${
                this.state.alertType === CAU_ALERTS_TYPES.RI_EXPIRATION ? 'RI' : 'saving plans'
              } expires.`}
            </div>
            <div className="recipients-container">
              <div className="alerts-inner-connecting-text">Recipients:</div>
              <div className="alerts-inner-text-input-large flex-grow-1">
                <TextField
                  name="recipientsEmails"
                  onChange={(e) => this.cauHelper.handleTextInputChange(e)}
                  value={`${selectedFilterValues.recipientsEmails}`}
                  fullWidth
                  size="small"
                  placeholder="email@example.com, email@example.com"
                />
                {this.renderValidationMessage('recipientsEmails')}
              </div>
            </div>
            <div className="alerts-inner-connecting-text">
              <Button
                name="add"
                color="primary"
                size="sm"
                onClick={(e) => this.cauHelper.saveAlertHandler(e, this.props.alertId)}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <DeleteWarningModal
          deletedItemName="Expiration alert"
          isOpen={this.state.isDeleteAlertWarning}
          handleDelete={this.deleteAlertHandler}
          warningMessage="Be advise you are about to delete a"
          modalTitle="Delete Alert"
        />
      </Container>
    );
  }
}

// const ObserverCostChangesAlert = observer(CostChangesAlert);
// export default ObserverCostChangesAlert;
export default CostChangesAlert;
