import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { toast } from 'react-toastify';

const root = '/api/v1/msp/billing-rules-new';

const getBillingRules = () => API.get('billings', root);
const createBillingRule = (billingRule) => API.post('billings', root, { body: billingRule });
const updateBillingRule = (billingRule) => API.put('billings', root, { body: billingRule });
const deleteBillingRule = (ruleId) => API.del('billings', `${root}/${ruleId}`);

export default function useBillingRules() {
  const queryClient = useQueryClient();

  return {
    getBillingRules: (options = {}) =>
      useQuery([apiConstants.QUERY_KEYS.BILLING_RULES], () => getBillingRules(), {
        onError: () => toast.error('Error fetching Billing Rules'),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
    createBillingRule: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation(createBillingRule, {
        onSuccess: () => {
          toast.success('Billing Rule created');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BILLING_RULES);
          onSuccess();
        },
        onError: () => toast.error('Error creating Billing Rule'),
        ...options,
      }),
    updateBillingRule: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation(updateBillingRule, {
        onSuccess: () => {
          toast.success('Billing Rule updated');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BILLING_RULES);
          onSuccess();
        },
        onError: () => toast.error('Error updating Billing Rule'),
        ...options,
      }),
    deleteBillingRule: (options = {}) =>
      useMutation(deleteBillingRule, {
        onSuccess: () => {
          toast.success('Billing Rule deleted');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.BILLING_RULES);
        },
        onError: () => toast.error('Error deleting Billing Rule'),
        ...options,
      }),
  };
}
