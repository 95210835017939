import BaseReportParams from './baseReportParams';

export default class RegionReportParams extends BaseReportParams {
  constructor() {
    super();
    this.filterBarGroupBy = 'region';
    this.currentGroupBy = 'region';
    this.selectedFilterBarGroupBy = 'region';
    this.currDataState = 4;
    super.updateBaseReportParams({
      filterBarGroupBy: this.filterBarGroupBy,
      currentGroupBy: this.currentGroupBy,
      selectedFilterBarGroupBy: this.selectedFilterBarGroupBy,
      currDataState: this.currDataState,
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  // eslint-disable-next-line no-unused-vars
  updateDates = (year, currMonth, currDay, prevMonth) => {
    const start = new Date(year, currMonth - 1, 1);
    const end = new Date(year, currMonth - 1, currDay);
    this.defaultDates(start, end);
    this.updateBaseReportParams({ startDate: this.startDate, endDate: this.endDate });
  };
}
