import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { AZURE_PROPERTIES, DB_PROPERTIES, ENGINE_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const AZURE_OUTDATE_SNAPSHOT_PROPERTIES = {
  SERVICE: {
    label: 'Service',
    getterFunction: (recommendation) => recommendation.service,
  },
  SNAPSHOT_NAME: {
    label: 'Snapshot Name',
    getterFunction: (recommendation) => recommendation.resourceName,
  },
  RESOURCE_GROUP: {
    label: 'Resource Group',
    getterFunction: (recommendation) =>
      ((recommendation?.recData?.source || '').match(/resourceGroups\/([^/]*)\//) || [])[1],
  },
};
const AzureOutdatedSnapshot = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `az snapshot delete --name ${recommendation?.resourceName} --resource-group ${
            ((recommendation?.recData?.source || '').match(/resourceGroups\/([^/]*)\//) || [])[1]
          } --subscription ${recommendation?.linkedAccountId}`,
        },
      ],
    },
  ];
  const description =
    // eslint-disable-next-line max-len
    'This Disk Outdated Snapshot age is 290 days, to avoid unnecessary costs, It’s recommended to delete the snapshot after 21 days';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...ENGINE_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
        ...AZURE_OUTDATE_SNAPSHOT_PROPERTIES,
      }}
    />
  );
};

AzureOutdatedSnapshot.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureOutdatedSnapshot;
