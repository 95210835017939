import React, { useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import { cloneDeep } from 'lodash';
import toast from 'shared/components/andtComponents/Toast';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { EDIT_USER_MODAL_FIELDS, USER_ACTIONS } from '../../consts';

import styles from './EditUserModal.module.scss';

const EditUserModal = ({ isOpen, setIsOpen, isLoading, user }) => {
  const [saveClicked] = useState(false);
  const [localUser, setLocalUser] = useState(cloneDeep(user));

  const { updateUser } = useUsers();

  const onSave = async () => {
    try {
      await updateUser.mutateAsync({ user: localUser });
      setIsOpen(false);
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while updating user');
      }
    }
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.EDIT}
      footerDisabled={isLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="edit-user-modal"
      saveTitle="Save"
      saveDisabled={false}
      title={USER_ACTIONS.EDIT.label}
    >
      <div className={styles.editUserContainer}>
        <div className={styles.propertyContainer}>
          <span>{EDIT_USER_MODAL_FIELDS.USER_NAME.label}</span>
          <Input
            value={localUser?.username}
            placeholder={EDIT_USER_MODAL_FIELDS.USER_NAME.placeholder}
            onChange={(e) => {
              setLocalUser({ ...localUser, [EDIT_USER_MODAL_FIELDS.USER_NAME.id]: e?.target?.value });
            }}
          />
        </div>
        <div className={styles.twoPropertiesContainer}>
          <div className={styles.propertyContainer}>
            <span>{EDIT_USER_MODAL_FIELDS.FIRST_NAME.label}</span>
            <Input
              value={localUser?.firstName}
              placeholder={EDIT_USER_MODAL_FIELDS.FIRST_NAME.placeholder}
              onChange={(e) => {
                setLocalUser({ ...localUser, [EDIT_USER_MODAL_FIELDS.FIRST_NAME.id]: e?.target?.value });
              }}
            />
          </div>
          <div className={styles.propertyContainer}>
            <span>{EDIT_USER_MODAL_FIELDS.LAST_NAME.label}</span>
            <Input
              value={localUser?.lastName}
              placeholder={EDIT_USER_MODAL_FIELDS.LAST_NAME.placeholder}
              onChange={(e) => {
                setLocalUser({ ...localUser, [EDIT_USER_MODAL_FIELDS.LAST_NAME.id]: e?.target?.value });
              }}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default EditUserModal;
