import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import ActionButton from './ActionButton';

const COLUMN_EXTENSIONS = [
  { columnName: 'userActions', align: 'center' },
  { columnName: 'isVerified', align: 'center' },
];

class AppRegistrationTable extends React.PureComponent {
  static propTypes = {
    regAppsData: PropTypes.object.isRequired,
    tableColumns: PropTypes.object.isRequired,
    editApp: PropTypes.func.isRequired,
    deleteApp: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      appRegForDelete: null,
    };
  }

  getColor = (isVerified) => {
    if (!isVerified) {
      return '#d95350';
    }
    return '#449d44';
  };

  verificationStatus = (data) => (
    <div
      style={{
        'background-color': this.getColor(data.row.isVerified),
        height: '15px',
        width: '15px',
        'border-radius': '50%',
        margin: '0 auto',
      }}
    />
  );

  handleDeleteAppReg = async (action) => {
    if (action === 'delete') {
      await this.props.deleteApp(this.state.appRegForDelete);
    }
    this.setState({ appRegForDelete: null });
  };
  handleDeleteClick = (appReg) => this.setState({ appRegForDelete: appReg });

  render() {
    const { regAppsData, tableColumns, editApp } = this.props;
    const { appRegForDelete } = this.state;
    const userActionsFormatter = (data) => {
      return (
        <i>
          <Row>
            <Col>
              <ActionButton regApp={data.row} editApp={editApp} deleteApp={this.handleDeleteClick} />
            </Col>
          </Row>
        </i>
      );
    };

    return (
      <div className="card">
        <Grid rows={regAppsData} columns={tableColumns}>
          <DataTypeProvider for={['isVerified']} formatterComponent={this.verificationStatus} />
          <DataTypeProvider for={['userActions']} formatterComponent={userActionsFormatter} />
          <TableWrapper columnExtensions={COLUMN_EXTENSIONS} />
          <TableHeaderRow />
        </Grid>
        <DeleteWarningModal
          deletedItemName={appRegForDelete?.appName}
          isOpen={appRegForDelete}
          handleDelete={this.handleDeleteAppReg}
        />
      </div>
    );
  }
}

export default AppRegistrationTable;
