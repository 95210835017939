import React from 'react';
import { toast } from 'react-toastify';
import { ErrorNotificationsLabels } from '../../constants/notificationsConstants';

const errorNotificationsStorage = new Set();

export default {
  ...toast,
  success: (text, options = {}) =>
    toast.success(
      <div>
        <p>Info</p>
        <span>{text}</span>
      </div>,
      { ...options },
    ),
  error: (text, options = {}) => {
    const textStr = JSON.stringify(text || '');
    if (errorNotificationsStorage.has(textStr)) {
      return;
    }
    errorNotificationsStorage.add(textStr);
    toast.error(
      <div>
        <p>Error</p>
        <span>{text || ErrorNotificationsLabels.GENERAL}</span>
      </div>,
      {
        ...options,
        onClose: () => {
          errorNotificationsStorage.delete(textStr);
          if (options.onClose) {
            options.onClose();
          }
        },
      },
    );
  },
  warning: (text, options = {}) =>
    toast.warning(
      <div>
        <p>Warning</p>
        <span>{text}</span>
      </div>,
      { ...options },
    ),
};
