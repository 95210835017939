/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
// import { RecommTypeValuesToFilterText } from 'recommendations/containers/RecommendationsHistory/constants/recommHistoryConstants';

export default class RecommHistoryStatusBadge extends PureComponent {
  static propTypes = {
    badgeType: PropTypes.object.isRequired,
    addLeftMargin: PropTypes.bool.isRequired,
    recommKey: PropTypes.string,
    tooltipValue: PropTypes.string,
  };
  static defaultProps = {
    tooltipValue: '',
    recommKey: '',
  };

  render() {
    const { badgeType, addLeftMargin, tooltipValue, recommKey } = this.props;
    const leftMarginClassName = addLeftMargin ? 'add_left_margin' : null;
    const mainClassName = `recomm_status_badge_${badgeType} ${leftMarginClassName}`;
    return (
      <>
        <span id={`toolTip${recommKey}`}>
          <Badge color="inherit" className={mainClassName}>{badgeType}</Badge>
        </span>
        {tooltipValue ? (
          <UncontrolledTooltip placement="top" target={`toolTip${recommKey}`}>
            {tooltipValue}
          </UncontrolledTooltip>
        ) : null}
      </>
    );
  }
}
