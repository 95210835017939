import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup } from 'reactstrap';
import FieldFilter from 'shared/components/FieldFilter';
import CustomModal from 'shared/components/andtComponents/Modal';

class VirtualTagCreateEditModal extends PureComponent {
  static propTypes = {
    virtualTag: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    nameValidError: PropTypes.string.isRequired,
  };

  static defaultProp = {
    isOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (value, handler) => {
    handler(value);
  };

  render() {
    const { virtualTag, nameValidError, handlers, onClose, isOpen, options } = this.props;
    return (
      <>
        <CustomModal
          open={isOpen}
          onClose={onClose}
          title="Create Virtual Tag"
          saveDisabled={
            nameValidError ||
            (virtualTag && (!virtualTag.customTags || !virtualTag.customTags.length || !virtualTag.name))
          }
          onSave={handlers.saveVirtualTag}
        >
          <>
            <div>
              <p>Virtual tag name:</p>
              <InputGroup>
                <Input
                  type="text"
                  onChange={(e) => this.handleChange(e.target.value, handlers.changeVirtualTagsName)}
                  value={virtualTag && virtualTag.name}
                  placeholder="Enter virtual tag name"
                />
              </InputGroup>
              {nameValidError && <p style={{ color: 'red', marginTop: '15px' }}>{nameValidError}</p>}
            </div>
            <div>
              <div style={{ width: '100%' }}>
                <h5 style={{ marginTop: '15px', textAlign: 'left', marginBottom: '0.5rem' }}>Tags</h5>
                <FieldFilter
                  type="tags"
                  name="tags"
                  value={virtualTag && virtualTag.customTags}
                  options={options}
                  handleChange={(type, selectedOptions) =>
                    this.handleChange(selectedOptions, handlers.changeVirtualTagCustomTags)
                  }
                  placeholder="Select specific tags"
                />
              </div>
            </div>
          </>
        </CustomModal>
      </>
    );
  }
}

export default VirtualTagCreateEditModal;
