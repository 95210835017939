import { useMemo } from 'react';
import { linkedAccountsSelectedCounter, totalLinkedAccountsCounter } from '../utils';

export const useFilteredAccounts = (search, accounts, linkedAccounts) =>
  useMemo(() => {
    if (!search) {
      return accounts;
    }
    return Object.keys(accounts).reduce((acc, cloud) => {
      const filtered = accounts[cloud].filter(
        ({ accountName, accountId }) =>
          accountName.toLowerCase().includes(search.toLowerCase()) ||
          accountId.toLowerCase().includes(search.toLowerCase()) ||
          (linkedAccounts.get(accountId) || []).some(
            ({ linkedAccountId, linkedAccountName }) =>
              linkedAccountName.toLowerCase().includes(search.toLowerCase()) ||
              linkedAccountId.toLowerCase().includes(search.toLowerCase()),
          ),
      );

      acc[cloud] = filtered;
      return acc;
    }, {});
  }, [accounts, search, linkedAccounts]);

export const useFilteredLinkedAccounts = (secondSearch, linkedAccounts, accountIdOpened) =>
  useMemo(() => {
    if (!accountIdOpened) {
      return [];
    }
    return (linkedAccounts.get(accountIdOpened) || []).filter(
      ({ linkedAccountId, linkedAccountName }) =>
        linkedAccountName.toLowerCase().includes(secondSearch.toLowerCase()) ||
        linkedAccountId.toLowerCase().includes(secondSearch.toLowerCase()),
    );
  }, [secondSearch, linkedAccounts, accountIdOpened]);

export const useTotalLinkedAccounts = (accounts, linkedAccounts) => {
  const totalLinkedAccounts = useMemo(
    () => totalLinkedAccountsCounter(accounts, linkedAccounts),
    [accounts, linkedAccounts],
  );
  return totalLinkedAccounts;
};

export const useTotalLinkedAccountsSelected = (accounts, selectedAccounts, linkedAccounts, filter) =>
  useMemo(
    () => linkedAccountsSelectedCounter(accounts, selectedAccounts, linkedAccounts, filter),
    [selectedAccounts, linkedAccounts, filter, accounts],
  );
