/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state,react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import moment from 'moment';
import Spinner from 'shared/components/andtComponents/Spinner';
import { createDateDisplayStr } from 'shared/utils/dateUtil';

const objSort = (obj) => {
  if (obj) {
    const orderedDates = {};
    Object.keys(obj)
      .sort((a, b) => {
        a = moment(a, 'YYYY-MM-DD');
        b = moment(b, 'YYYY-MM-DD');
        return a < b ? -1 : 1;
      })
      .forEach((key) => {
        orderedDates[key] = obj[key];
      });
    return orderedDates;
  }
  return obj;
};

export const modifyIdleInstanceData = (data) => {
  const tempArr = [];
  const dataArr = [];
  for (let i = 0; i < 24; i++) {
    dataArr.push({ name: `${i}:00` });
  }
  const sortedData = objSort(data);
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(sortedData)) {
    // eslint-disable-next-line no-loop-func
    for (const [nestedKey, nestedValue] of Object.entries(value)) {
      tempArr[nestedKey]
        ? tempArr[nestedKey].push({ x: key, y: Number(nestedValue) })
        : (tempArr[nestedKey] = [{ x: key, y: Number(nestedValue) }]);
    }
    dataArr.map((row, index) => {
      // eslint-disable-next-line no-param-reassign
      row.data = tempArr[index];
      return row;
    });
  }
  return dataArr;
};

const IdleInstanceHeatMapChart = ({ isLoadingIdleInstancesRowData, series }) => {
  const options = {
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.9,
        enableShades: false,
        colorScale: {
          ranges: [
            {
              from: -1,
              to: 1,
              name: 'Inactive',
              color: '#ff8e88',
            },
            {
              from: 1,
              to: 1,
              name: 'Idle',
              color: '#ffed4d',
            },
            {
              from: 0,
              to: 0,
              name: 'Active',
              color: '#c5e747',
            },
          ],
        },
      },
    },
    title: {
      text: '',
    },
    xaxis: {
      //   type: 'datetime',
      tickAmount: 1,
      labels: {
        formatter(value, timestamp) {
          return createDateDisplayStr(value); // The formatter function overrides format property
        },
      },
    },
    responsive: [
      {
        breakpoint: Infinity,
        options: {
          chart: {
            // width: '1400',
          },
        },
      },
      {
        breakpoint: 2730,
        options: {
          chart: {
            // width: '1400',
          },
        },
      },
      {
        breakpoint: 1730,
        options: {
          chart: {
            // width: '1200',
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            // width: '1000',
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            // width: '800',
          },
        },
      },
      {
        breakpoint: 1230,
        options: {
          chart: {
            // width: '700',
          },
        },
      },
      {
        breakpoint: 1110,
        options: {
          chart: {
            // width: '500',
          },
        },
      },
    ],
  };
  return (
    <div id="chart">
      {isLoadingIdleInstancesRowData ? (
        <Spinner />
      ) : (
        <Chart options={options} series={modifyIdleInstanceData(series)} type="heatmap" height="500" />
      )}
    </div>
  );
};

IdleInstanceHeatMapChart.propTypes = {
  series: PropTypes.object.isRequired,
  isLoadingIdleInstancesRowData: PropTypes.bool.isRequired,
};

export default IdleInstanceHeatMapChart;
