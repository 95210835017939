import React from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/hooks/react-query/useRoles';

const LinkedAccountsContext = React.createContext();

export function withLinkedAccountsProvider(Component) {
  function LinkedAccountsProvider({ ...props }) {
    const { fetchAllCloudAccountsLinkedAccounts } = useRoles();

    const { data: cloudAccountsLinkedAccount = [] } = fetchAllCloudAccountsLinkedAccounts(
      props.usersStore.getAllCurrDisplayedUserAccounts(),
    );

    return (
      <LinkedAccountsContext.Provider
        value={{
          cloudAccountsLinkedAccount: new Map(cloudAccountsLinkedAccount),
        }}
      >
        <Component {...props} cloudAccountsLinkedAccount={new Map(cloudAccountsLinkedAccount)} />
      </LinkedAccountsContext.Provider>
    );
  }
  LinkedAccountsProvider.propTypes = {
    usersStore: PropTypes.object.isRequired,
  };
  return LinkedAccountsProvider;
}

export function withLinkedAccountsContextConsumer(Component) {
  return function LinkedAccountsConsumer({ ...props }) {
    return (
      <LinkedAccountsContext.Consumer>{(data) => <Component {...data} {...props} />}</LinkedAccountsContext.Consumer>
    );
  };
}
