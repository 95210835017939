import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Col, Collapse } from 'reactstrap';
import { mapRecommendationsTypeToDisplay, LabelActionTypes } from 'recommendations/constants/recommendationsConstants';
import DismissRecModal from 'recommendations/shared/components/DismissRecResModal';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { ClickAwayListener } from '@mui/material';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import styles from './RecommendationDropdown.module.scss';

class RecommendationDropdown extends PureComponent {
  static propTypes = {
    recHandlers: PropTypes.object.isRequired,
    recommType: PropTypes.string.isRequired,
    cloudTypeId: PropTypes.string.isRequired,
    prepareCsvModifiedRowsForRecomm: PropTypes.object.isRequired,
    selectedRec: PropTypes.object,
  };

  static defaultProps = {
    selectedRec: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleDone = () => {
    const { selectedRec, recHandlers } = this.props;
    recHandlers.recCompletedHandler(selectedRec.map((rec) => rec.key));
    segmentEvent({
      target: 'RecommendationsBatchOperationDone',
    });
  };

  handleExclude = (selectedRec, excludeDate, excludeMessage) => {
    const { recHandlers } = this.props;
    recHandlers.recExcludeHandler(selectedRec, excludeDate, excludeMessage);
    segmentEvent({
      target: 'RecommendationsBatchOperationExclude',
    });
  };
  addLabel = () => {
    const { recHandlers, selectedRec } = this.props;
    recHandlers.recLabelHandler(selectedRec, LabelActionTypes.ADD, true);
    segmentEvent({
      target: 'RecommendationsBatchOperationAddLabel',
    });
  };
  removeLabels = () => {
    const { recHandlers, selectedRec } = this.props;
    recHandlers.recLabelHandler(selectedRec, LabelActionTypes.REMOVE);
    segmentEvent({
      target: 'RecommendationsBatchOperationRemoveLabel',
    });
  };

  toggle = () => {
    this.setState((state) => ({
      open: !state.open,
    }));
  };

  closeMenu = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { cloudTypeId, recommType, selectedRec, prepareCsvModifiedRowsForRecomm } = this.props;
    const { open } = this.state;
    if (selectedRec.length === 0) {
      return null;
    }
    const isLablelsExist = selectedRec.some((rec) => rec.labels);
    return (
      <>
        <DismissRecModal
          recs={selectedRec}
          recExcludeHandler={this.handleExclude}
          cloudTypeId={cloudTypeId}
          ref={(dismissRecModal) => {
            this._dismissRecModal = dismissRecModal;
          }}
        />
        <ClickAwayListener onClickAway={this.closeMenu}>
          <div
            className="d-flex position-relative date-picker-container"
            style={{ width: '180px', flexWrap: 'nowrap' }}
            onClick={this.toggle}
          >
            <Col xl="auto" lg="auto" sm="auto" xs="auto">
              <div style={{ display: 'flex', width: '80%' }}>
                <h5 style={{ whiteSpace: 'nowrap' }}>Batch Operation</h5>
              </div>
            </Col>
            <Col style={{ padding: '1px 0px' }} xs={2} md={2} lg={2} xl={2}>
              <LinkIconButton className="topbar__avatar">
                <ChevronDown size={22} />
              </LinkIconButton>
            </Col>
            <Collapse isOpen={open} className="billing-topbar__menu-wrap">
              <div className="drop__menu">
                <div className="collapse-item d-flex" onClick={() => this._dismissRecModal.toggle()}>
                  <p>Exclude</p>
                </div>
                <div className="collapse-item d-flex" onClick={this.handleDone}>
                  <p>Done</p>
                </div>
                <div className="collapse-item d-flex" onClick={this.addLabel}>
                  <p>Add Labels</p>
                </div>
                {isLablelsExist && (
                  <div className="collapse-item d-flex" onClick={this.removeLabels}>
                    <p>Remove Labels</p>
                  </div>
                )}
                <CustomCSVDownload
                  data={[
                    {
                      data: prepareCsvModifiedRowsForRecomm(selectedRec),
                      filename: `${mapRecommendationsTypeToDisplay.get(recommType)}.csv`,
                    },
                  ]}
                  className={styles.exportButton}
                  filesNumber={1}
                  isLoading={false}
                  showDownloadIcon={false}
                />
              </div>
            </Collapse>
          </div>
        </ClickAwayListener>
      </>
    );
  }
}

export default RecommendationDropdown;
