/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
// TODO update description
const Description = ({ rec }) => (
  <div style={{ 'line-height': '30px', 'font-size': '13px' }}>
    <p>
      {'We recommend you to commit to this Savings plan, since your daily hourly usage is above the stated commitment.'}
    </p>
  </div>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
