import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import Spinner from 'shared/components/andtComponents/Spinner';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import azureRiCoverageProvider from 'usage/hooks/react-query/useAzureRiCoverage';
import { datePickerSyntaxDates } from 'usage/containers/AzureVirtualMachines/constants/constants';
import { Col, Row } from 'reactstrap';
import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import {
  RI_COVERAGE_COLUMN_WIDTHS,
  RI_COVERAGE_CURRENCIES_COLUMNS,
  RI_COVERAGE_DEFAULT_HIDDEN_COLUMN_NAMES,
  RI_COVERAGE_DEFAULT_SORTING,
  RI_COVERAGE_TABLE_COLUMN_EXTENSIONS,
  RI_COVERAGE_TABLE_COLUMNS,
} from 'usage/containers/AzureVirtualMachines/constants/azureRiCoverageTableConstants';
import DateFilter from 'shared/modules/dateFilter';

import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import classes from './azureVmUsagePanel.module.scss';

const propTypes = {
  initialStartDate: PropTypes.object.isRequired,
  initialEndDate: PropTypes.object.isRequired,
};

const RiCoveragePanel = ({ initialStartDate, initialEndDate }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const azureRiCoverageHook = azureRiCoverageProvider({ startDate, endDate });
  const azureRiCoverageDataProvider = azureRiCoverageHook?.fetchAzureRiCoverage();
  const dataIsLoading = azureRiCoverageDataProvider?.isLoading;
  const azureRiCoverageData = azureRiCoverageDataProvider?.data;

  const handleDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    const start = newStartDate || startDate;
    let end = newEndDate || endDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    setStartDate(modStartDate);
    setEndDate(modEndDate);
  };

  // On cleanup - invalidate the reactQuery cache. This is done so when reseller is going through customers -
  // he won't see the data of previous customer before the data of the new customer arrived.
  useEffect(
    () => () => {
      azureRiCoverageHook.reset();
    },
    [],
  );

  async function getCsvData() {
    const data = await azureRiCoverageHook?.fetchAllAzureRiCoverage();
    return [{ data, filename: `RI_Coverage_Data_${DateFilter.month}_${DateFilter.year}` }];
  }

  const displayStartDate = useMemo(() => datePickerSyntaxDates(startDate, null).startDate, [startDate]);

  const displayEndDate = useMemo(() => datePickerSyntaxDates(null, endDate).endDate, [endDate]);

  return dataIsLoading ? (
    <div style={{ height: '400px' }}>
      <Spinner />
    </div>
  ) : (
    <div>
      <div style={{ paddingBottom: '20px' }}>
        <DatePickerFilter
          onDateChange={handleDateChange}
          startDate={displayStartDate}
          endDate={displayEndDate}
          currPeriodGranLevel={GranularityLevelsTypes.GRAN_LEVEL_DAILY}
        />
      </div>
      {azureRiCoverageData?.length > 0 ? (
        <Row>
          <Col xs="12" md="12" lg="12" xl="12">
            <DevExpSearchAndVisibilityTable
              data={azureRiCoverageData}
              fetchCsvData={() => getCsvData()}
              isLoading={azureRiCoverageDataProvider?.isFetching}
              columns={Object.values(RI_COVERAGE_TABLE_COLUMNS)}
              costColumnsArray={RI_COVERAGE_CURRENCIES_COLUMNS}
              currenciesFormatterComponent={({ value }) => `${getCurrencyNumber(value, 2)}`}
              tableColumnExtensions={RI_COVERAGE_TABLE_COLUMN_EXTENSIONS}
              defaultSorting={RI_COVERAGE_DEFAULT_SORTING}
              csvTitle={`RI_Coverage_Data_${DateFilter.month}_${DateFilter.year}`}
              columnWidths={RI_COVERAGE_COLUMN_WIDTHS}
              defaultHiddenColumnNames={RI_COVERAGE_DEFAULT_HIDDEN_COLUMN_NAMES}
              isResize
              isPaging
              isVisibility
            />
          </Col>
        </Row>
      ) : (
        <div className={classes.notice}>No data in the selected date range.</div>
      )}
    </div>
  );
};

RiCoveragePanel.propTypes = propTypes;
export default observer(RiCoveragePanel);
