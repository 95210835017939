/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

// FIXME chaneg to Azure
const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using Azure PowerShell:</li>
      <CodeBlock
        text="Remove-AzureRmResource -ResourceId {{resourceId}}"
        variables={{
          resourceId: rec.resourceId,
        }}
        quote={'"'}
      />
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
