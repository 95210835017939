import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti } from '@pileus-cloud/anodot-frontend-common';
import { getMonthsBeforeToday } from 'shared/utils/dateUtil.js';
import DateFilter from 'shared/modules/dateFilter.js';
import styles from './Filters.module.scss';

const statusOptions = [
  { label: 'Succeeded', value: 'success' },
  { label: 'Failed', value: 'error' },
  { label: 'Pending', value: 'pending' },
];

const Filters = ({ filters, setFilters, customers }) => {
  const lastProcessTime = DateFilter.getDate();
  const customersOptions = useMemo(() => {
    return customers.map(c => ({
      label: c.name,
      value: c.id,
    }));
  }, [JSON.stringify(customers)]);
  const monthsOptions = useMemo(() => {
    return getMonthsBeforeToday(lastProcessTime, 6);
  }, [lastProcessTime]);
  return (
    <div className={styles.container}>
      <div>
        <SelectMulti
          label="Process Month"
          options={monthsOptions}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, months: vals });
          }}
          selected={filters.months}
        />
      </div>
      <div>
        <SelectMulti
          label="Status"
          width={250}
          options={statusOptions}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, statuses: vals });
          }}
          selected={filters.statuses}
        />
      </div>
      <div>
        <SelectMulti
          label="Customer Name"
          options={customersOptions}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, customers: vals });
          }}
          selected={filters.customers}
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  divisions: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default Filters;
