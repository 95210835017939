import React from 'react';
import PropTypes from 'prop-types';
import styles from './ResetZoomButton.module.scss';

const ResetZoomButton = ({ resetZoomClicked }) => (
  <button className={styles.resetZoomBtn} onClick={resetZoomClicked} type="button">
    Reset Zoom
  </button>
);

ResetZoomButton.propTypes = {
  resetZoomClicked: PropTypes.func.isRequired,
};

export default ResetZoomButton;
