import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class CostTypeDataState extends BaseCostUsageDataState {
  // eslint-disable-next-line class-methods-use-this
  getNewDataStateType() {
    return CostUsageStates.SERVICE;
  }

  // eslint-disable-next-line class-methods-use-this
  getDataStateType() {
    return CostUsageStates.COST_TYPE;
  }
}
