import React, { useMemo } from 'react';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { Container } from 'reactstrap';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { Tabs, TabsContent, TabsList, TabsTrigger, GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import styles from '../ResourceExplorer/ResourceExplorer.module.scss';
import ServiceCostTab from './ServiceCostTab';
import CommitmentExpirationTab from './CommitmentExpirationTab';
import { AlertTypesEnum, CommitmentExpAlert, ServiceCostAlert } from './types.ts';
import { useAlerts } from './hooks/useAlerts';
import t from './texts.ts';

enum AlertTabs {
  SERVICE_COST = t('SERVICE_COST'),
  COMMITMENT_EXPIRATION = t('COMMITMENT_EXPIRATION'),
}

const AlertsNew: React.FC = () => {
  const { usersStore } = useRootStore();
  const { fetchAlertsList } = useAlerts();
  const { data: allAlerts = [], isLoading: isAlertsLoading = [], refetch } = fetchAlertsList();
  const showStaticAlerts = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.ALERTS_STATIC);

  const { serviceCostAlerts, staticAlerts, riExpirationAlerts, spExpirationAlerts } = useMemo(
    () =>
      (Array.isArray(allAlerts) ? allAlerts : []).reduce(
        (acc, alert) => {
          if (alert.alertType === AlertTypesEnum.COST_CHANGES) {
            acc.serviceCostAlerts.push(alert as ServiceCostAlert);
          }
          if (showStaticAlerts && String(alert.alertType) === String(AlertTypesEnum.STATIC)) {
            acc.staticAlerts.push(alert as ServiceCostAlert);
          }
          if (alert.alertType === AlertTypesEnum.RI_EXPIRATION) {
            acc.riExpirationAlerts.push(alert as CommitmentExpAlert);
          }
          if (alert.alertType === AlertTypesEnum.SP_EXPIRATION) {
            acc.spExpirationAlerts.push(alert as CommitmentExpAlert);
          }
          return acc;
        },
        {
          serviceCostAlerts: [] as ServiceCostAlert[],
          staticAlerts: [] as ServiceCostAlert[],
          riExpirationAlerts: [] as CommitmentExpAlert[],
          spExpirationAlerts: [] as CommitmentExpAlert[],
        },
      ),
    [allAlerts],
  );

  const isAWS = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS;
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;

  return (
    <Container>
      <PageHeader showDate title={PageNames.CUE_ALERTS} />
      {isAlertsLoading ? (
        <Spinner />
      ) : (
        <Tabs defaultValue={AlertTabs.SERVICE_COST}>
          <TabsList className={styles.navbar}>
            <TabsTrigger
              value={AlertTabs.SERVICE_COST}
              className={styles.navbarButton}
              automation-id="service-cost-tab"
            >
              <GenerateIcon iconName={ICONS.coins.name} />{' '}
              <span>
                {AlertTabs.SERVICE_COST} ({serviceCostAlerts.length + staticAlerts.length})
              </span>
            </TabsTrigger>
            {[CLOUD_TYPE_IDS.AWS, CLOUD_TYPE_IDS.AZURE].includes(usersStore.currDispUserCloudAccountType) && (
              <TabsTrigger
                value={AlertTabs.COMMITMENT_EXPIRATION}
                className={styles.navbarButton}
                automation-id="commitments-tab"
              >
                <GenerateIcon iconName={ICONS.calendarDay.name} />{' '}
                <span>
                  {AlertTabs.COMMITMENT_EXPIRATION} (
                  {spExpirationAlerts.length + (isAWS ? riExpirationAlerts.length : 0)})
                </span>
              </TabsTrigger>
            )}
          </TabsList>

          <TabsContent value={AlertTabs.SERVICE_COST}>
            <ServiceCostTab costChangesAlerts={serviceCostAlerts} staticAlerts={staticAlerts} refetchAlerts={refetch} />
          </TabsContent>
          <TabsContent value={AlertTabs.COMMITMENT_EXPIRATION}>
            <CommitmentExpirationTab
              spExpirationAlerts={spExpirationAlerts}
              riExpirationAlerts={isAWS || isAzure ? riExpirationAlerts : undefined}
              refetchAlerts={refetch}
            />
          </TabsContent>
        </Tabs>
      )}
    </Container>
  );
};

export default AlertsNew;
