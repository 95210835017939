/* eslint-disable no-unused-vars */
// import * as FS from '@fullstory/browser';
import config from '../../config';

export const init = () => {
  if (config.env === 'prod') {
    // FS.init({ orgId: config.FS_ORG_IDENTIFIER }); // 12/7/20 Cancel full-story for now
  }
};

export const identify = (userKey, displayName, email) => {
  if (window.FS && config.env === 'prod') {
    window.FS.identify(userKey, { displayName, email });
  }
};
