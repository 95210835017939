/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => {
  const { separateResource, resourceId } = rec;
  return (
    <ActionContainer>
      <ActionHeader />
      <ul style={{ 'list-style-type': 'none' }}>
        <li>Using the AWS CLI:</li>
        <CodeBlock
          text="aws rds modify-db-instance --db-instance-identifier {{RESOURCE_ID}} --db-instance-class {{instance}} --no-apply-immediately"
          variables={{
            RESOURCE_ID: separateResource ? resourceId : 'RESOURCE_ID',
            instance: rec.recommendedInstance,
          }}
        />
        <br />
      </ul>
    </ActionContainer>
  );
};

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
