import React from 'react';
import PropTypes from 'prop-types';
import {
  DB_PROPERTIES,
  ENGINE_PROPERTIES,
  RDS_PROPERTIES,
} from 'recommendationsNew/components/detailedRecommendation/recommendationPropertyUtils';

import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const RDS_IDLE_PROPERTIES = {
  DB_SIZE: {
    label: 'DB Size',
    isGBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.db_storage_size,
  },
};

const RdsIdle = ({ recommendation }) => {
  const description =
    'This RDS instance was with no more than 1 connection at the last 7 days. We recommend that you terminate it.';

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `aws rds delete-db-instance --db-instance-identifier ${recommendation?.recData?.db_name} --skip-final-snapshot --no-delete-automated-backups`,
        },
      ],
    },
  ];
  return (
    <BaseRecommendation
      recommendation={recommendation}
      dynamicProps={{ ...DB_PROPERTIES, ...RDS_PROPERTIES, ...ENGINE_PROPERTIES, ...RDS_IDLE_PROPERTIES }}
      commandsList={commandsList}
      description={description}
    />
  );
};

RdsIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RdsIdle;
