import React, { HTMLProps, ReactNode, useCallback, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import classNames from 'classnames';
import styles from './rowLikeAccordion.module.scss';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

export interface SingleAlertBoxProps extends HTMLProps<HTMLDivElement> {
  isExternalExpand?: boolean;
  isExpandDisabled?: boolean;
  headerContent?: string | ReactNode;
  actionsBlock?: ReactNode;
  automationId?: string;
  className?: string;
  onExpand?(e: any, expanded: boolean): void;
}

export const RowLikeAccordion: React.FC<SingleAlertBoxProps> = ({
  children,
  isExpandDisabled,
  isExternalExpand,
  headerContent,
  actionsBlock,
  automationId,
  className,
  onExpand,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(!!isExternalExpand);
  }, [isExternalExpand]);

  const onExpandHandler = useCallback(
    (e, expanded) => {
      if (!isExpandDisabled) {
        setIsExpanded(expanded);
        onExpand?.(e, expanded);
      }
    },
    [onExpand, isExpandDisabled],
  );

  return (
    <Accordion
      className={classNames(className, 'w-100', styles.rowAccordion, { [styles.rowAccordionExpanded]: isExpanded })}
      expanded={isExpanded}
      onChange={onExpandHandler}
      automation-id={automationId}
    >
      <AccordionSummary
        className={styles.rowAccordionSummary}
        expandIcon={<GenerateIcon iconName={ICONS.chevronDown.name} className={styles.expandIcon} />}
      >
        {typeof headerContent === 'string' ? (
          <div style={{ minWidth: 0, display: 'flex', alignItems: 'center' }}>{headerContent}</div>
        ) : (
          headerContent
        )}
        {actionsBlock}
      </AccordionSummary>
      <AccordionDetails>{isExpanded && children}</AccordionDetails>
    </Accordion>
  );
};

export default RowLikeAccordion;
