import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
// import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import LinkedAccountsComponentWrapper from '../../../components/LinkedAccounts/LinkedAccountsComponentWrapper';
import { ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS, ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS } from '../../../consts';
import {
  provideAccountsData,
  getInitialAccountsDataWithCounters,
} from '../../../components/LinkedAccounts/dataAccessHelperFunctions';

import styles from './DataAccessTab.module.scss';

const DATA_ACCESS_TABS = {
  ACCOUNTS: { id: 'accounts', label: 'Accounts' },
  COST_CENTERS: { id: 'costCenters', label: 'Cost Centers' },
};

const DataAccessTab = ({ row }) => {
  const [selectedTab, setSelectedTab] = React.useState(DATA_ACCESS_TABS.ACCOUNTS);
  const [isInProgress, setIsInProgress] = useState(false);

  // const { usersStore } = useRootStore();
  // const organizationId = usersStore.getCurrentUser?.organizationId;

  const onTabClick = (tab) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab);
    }
  };

  const isTabActive = (tab) => tab.id === selectedTab.id;

  // TODO: In case the Role data access is derived from more than one Organization, need to call the API for each?
  // TODO: Or should I only display the data for the User organization?
  // TODO: If the user is a reseller, then the data access is derived from all organizations? And then call for each?
  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();

  // const { fetchDataAccessCostCenters } = useDataAccessAccounts();
  // const { data: costCenterData, isLoading: isCostCentersDataLoading } = fetchDataAccessCostCenters();
  //
  // const { fetchDataAccessResellerCustomers } = useDataAccessAccounts();
  // eslint-disable-next-line max-len
  // const { data: resellerCustomersData, isLoading: isResellerCustomersDataLoading } = fetchDataAccessResellerCustomers();

  const { fetchRoleDataAccess, updateAccountsAccessibilityForRole } = useRoles();
  const { data: roleDataAccess, isLoading: isRoleDataAccessLoading } = fetchRoleDataAccess(row?.id);

  const dataToDisplay = useMemo(() => {
    if (!accountsData || !roleDataAccess?.length) {
      return null;
    }
    const combinedData = getInitialAccountsDataWithCounters(accountsData);
    const combinedPayerAccountsData = provideAccountsData({
      accountsData,
      roleDataAccess,
      currentRoleId: row?.id,
    });

    return { ...combinedData, accountsData: combinedPayerAccountsData };
  }, [accountsData, roleDataAccess, row?.id]);

  const handleRemoveLinkedAccounts = async (payload) => {
    setIsInProgress(true);
    await updateAccountsAccessibilityForRole.mutateAsync({ roleId: row?.id, roleDataAccess: payload });
    setIsInProgress(false);
  };

  const renderTab = useCallback(() => {
    let tabComponent;
    switch (selectedTab.id) {
      case DATA_ACCESS_TABS.ACCOUNTS.id:
        tabComponent =
          isRoleDataAccessLoading || isAccountsDataLoading || isInProgress ? (
            <div className="position-relative ">
              <Spinner />
            </div>
          ) : (
            <LinkedAccountsComponentWrapper
              allAccountsData={dataToDisplay}
              onRemoveLinkedAccount={handleRemoveLinkedAccounts}
              linkedAccountsColumns={Object.values(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
              payerAccountsColumns={Object.values(ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
              additionalData={{ roleId: row?.id }}
            />
          );
        break;
      case DATA_ACCESS_TABS.COST_CENTERS.id:
        tabComponent = <div>{DATA_ACCESS_TABS.COST_CENTERS.label}</div>;
        break;
      default:
        tabComponent = null;
    }
    return tabComponent;
  }, [selectedTab, dataToDisplay, isAccountsDataLoading, isRoleDataAccessLoading]);

  return (
    <div className={styles.dataAccessContainer}>
      <div className={styles.menu}>
        <div className={styles.dataAccessTabs}>
          {Object.values(DATA_ACCESS_TABS).map((tab) => (
            <div
              className={classNames(styles.dataAccessTab, { [styles.isActive]: isTabActive(tab) })}
              onClick={() => onTabClick(tab)}
              key={tab.id}
            >
              <div key={tab.id} className={styles.title}>
                {tab.label}
              </div>
              <div className={classNames(styles.selectionIndicator, { [styles.isActive]: isTabActive(tab) })}>
                {isTabActive(tab) ? (
                  <GenerateIcon iconName={ICONS.caretRight.name} className={styles.indicator} />
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.side} />
      </div>
      <div className={styles.data}>{renderTab()}</div>
    </div>
  );
};

export default DataAccessTab;
