/* eslint-disable jsx-a11y/no-autofocus,react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';

import styles from './TextArea.module.scss';

const TextArea = React.forwardRef(
  (
    {
      automationId,
      className,
      disabled,
      id,
      invalidComponent,
      isAutoFocus,
      isInvalid,
      isResizable,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      overrideStyles,
      placeholder,
      readOnly,
      rows,
      value,
    },
    ref,
  ) => {
    const classes = `${styles.andtTextArea} ${isInvalid ? styles.invalid : ''} ${className}}`;

    const handleFocus = (val) => {
      segmentEvent({
        type: 'focus',
        target: automationId || name || 'Input',
        automationId,
      });
      if (onFocus) {
        onFocus(val);
      }
    };

    const renderTextArea = () => (
      <React.Fragment>
        <textarea
          rows={rows}
          ref={ref}
          className={classes}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          autoFocus={isAutoFocus}
          automation-id={automationId}
          id={id}
          name={name}
          readOnly={readOnly}
          style={{ ...overrideStyles, ...(isResizable ? {} : { resize: 'none' }) }}
        />
        {isInvalid && <p className={styles.error}>{invalidComponent}</p>}
      </React.Fragment>
    );

    return renderTextArea();
  },
);

TextArea.propTypes = {
  automationId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  invalidComponent: PropTypes.node,
  isAutoFocus: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isResizable: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  overrideStyles: PropTypes.object,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextArea.defaultProps = {
  disabled: false,
  value: '',
  id: undefined,
  onChange: null,
  onBlur: null,
  onFocus: null,
  onKeyDown: null,
  isAutoFocus: false,
  automationId: '',
  isInvalid: false,
  placeholder: '',
  invalidComponent: null,
  name: '',
  className: '',
  readOnly: false,
  overrideStyles: {},
  isResizable: false,
  rows: 3,
};

export default TextArea;
