import { useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import { buildWhereParams, sanitizeQuery } from 'shared/utils/apiUtil';
import apiConstants from 'shared/api/apiConstants';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';

const root = '/api/v1/services/big-query';

const getBigQueryData = async (start, end, whereParams) => {
  const { startDate, endDate } = buildStartAndEndDate(start, end, false);
  const apiQuery = `${root}?startDate=${startDate}&endDate=${endDate}${buildWhereParams(whereParams)}`;
  return API.get('billings', sanitizeQuery(apiQuery));
};

const getBigQueryTableData = async (start, end, whereParams) => {
  const { startDate, endDate } = buildStartAndEndDate(start, end, false);
  const apiQuery = `${root}/table?startDate=${startDate}&endDate=${endDate}${buildWhereParams(whereParams)}`;
  return API.get('billings', sanitizeQuery(apiQuery));
};

const getBigQueryDistinctValues = async () => {
  const apiQuery = `${root}/distinct-values`;
  return API.get('billings', apiQuery);
};

export function useBigQueryUsage() {
  return {
    fetchBigQueryData: (startDate, endDate, whereParams) =>
      useQuery(
        [
          apiConstants.QUERY_KEYS.PRICING_MODE_CHANGED,
          apiConstants.QUERY_KEYS.BIG_QUERY_USAGE,
          startDate,
          endDate,
          JSON.stringify(whereParams),
        ],
        () => getBigQueryData(startDate, endDate, whereParams),
      ),
    fetchBigQueryTableData: (startDate, endDate, whereParams) =>
      useQuery(
        [
          apiConstants.QUERY_KEYS.PRICING_MODE_CHANGED,
          apiConstants.QUERY_KEYS.BIG_QUERY_USAGE_TABLE,
          startDate,
          endDate,
          JSON.stringify(whereParams),
        ],
        () => getBigQueryTableData(startDate, endDate, whereParams),
      ),
    fetchBigQueryDistinctValues: () =>
      useQuery([apiConstants.QUERY_KEYS.BIG_QUERY_USAGE_DISTINCT], getBigQueryDistinctValues),
  };
}
