import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Button from 'shared/components/andtComponents/Button';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import styles from './alertModal.module.scss';
import { validateAlert } from '../creditHelpers';

const propTypes = {
  selectedAlert: PropTypes.object,
  creditAmount: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  modalType: 'New' || 'Edit',
  handleSaveAlert: PropTypes.func.isRequired,
  creditExpirationDate: PropTypes.string.isRequired,
  getMyEmail: PropTypes.func.isRequired,
};
const defaultProps = {
  open: false,
  selectedAlert: null,
  modalType: 'New',
};

const initiateAlert = (selectedAlert) => {
  if (selectedAlert) {
    const { alerts, recipientsEmails } = selectedAlert;
    return {
      alerts,
      recipientsEmails: recipientsEmails
        .split(',')
        .map((s) => s.trim())
        .join(','),
    };
  }
  return {
    alerts: {
      expiration: {
        daysBeforeExpiry: 0,
        isAlert: true,
      },
      utilization: {
        creditCompletion: false,
        minAmount: null,
        minPercentage: null,
      },
    },
    recipientsEmails: '',
  };
};

const AlertModal = ({
  selectedAlert,
  modalType,
  open,
  handleSaveAlert,
  onClose,
  creditAmount,
  creditExpirationDate,
  getMyEmail,
}) => {
  const [alert, setAlert] = useState(initiateAlert(selectedAlert));
  const [alertValidationErrors, setAlertValidation] = useState(null);
  const [saveClicked, setSaveClicked] = useState(false);
  const { currencySymbol, getCurrencyNumber } = useUserSettingsContext();
  useEffect(() => {
    const alertValidation = validateAlert(alert, creditAmount);
    setAlertValidation(alertValidation);
  }, [alert]);

  const setUtilizationPercentage = (val) => {
    let value = +val;
    if (Number.isNaN(value)) {
      return;
    }
    if (value > 100) {
      value = 100;
    }
    const minAmount = +(creditAmount * (value.toFixed(2) / 100)).toFixed(2);
    setAlert((prevAlert) => ({
      ...prevAlert,
      alerts: {
        ...prevAlert.alerts,
        utilization: {
          ...prevAlert.alerts.utilization,
          minPercentage: +value.toFixed(2),
          minAmount,
        },
      },
    }));
  };

  const setUtilizationAmount = (val) => {
    let value = +val;
    if (Number.isNaN(value)) {
      return;
    }
    if (value > creditAmount) {
      value = creditAmount;
    }
    const minPercentage = +((value.toFixed(2) / creditAmount) * 100).toFixed(2);
    setAlert((prevAlert) => ({
      ...prevAlert,
      alerts: {
        ...prevAlert.alerts,
        utilization: {
          ...prevAlert.alerts.utilization,
          minAmount: +value.toFixed(2),
          minPercentage,
        },
      },
    }));
  };

  const setRecipients = (val) => {
    setAlert((prevAlert) => ({ ...prevAlert, recipientsEmails: val }));
  };

  const addMyEmail = () => {
    const myEmail = getMyEmail();
    if (!alert.recipientsEmails.length) {
      setRecipients(myEmail);
      return;
    }
    const recipients = alert.recipientsEmails.split(',').map((email) => email.trim());
    if (!recipients.some((email) => email === myEmail.trim())) {
      recipients.push(myEmail);
      setRecipients(recipients.join(', '));
    }
  };

  const setExpirationAlert = (val) => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      alerts: {
        ...prevAlert.alerts,
        expiration: {
          ...prevAlert.alerts.expiration,
          isAlert: val,
        },
      },
    }));
  };
  const setCompletionAlert = (val) => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      alerts: {
        ...prevAlert.alerts,
        utilization: {
          ...prevAlert.alerts.utilization,
          creditCompletion: val,
        },
      },
    }));
  };

  const onSave = async () => {
    setSaveClicked(true);
    if (alertValidationErrors) {
      return;
    }
    await handleSaveAlert(alert);
  };

  const renderErrorMsg = (fieldName) =>
    saveClicked && alertValidationErrors && alertValidationErrors[fieldName] ? (
      <span className={styles.errorMsg}>{alertValidationErrors[fieldName]}</span>
    ) : null;

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={`${modalType} Alert`}
      saveTitle="Save"
      onSave={onSave}
      saveDisabled={saveClicked && alertValidationErrors}
      closeOnSave={false}
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column">
          <div className="d-flex mb-3">
            <div className="d-flex flex-column me-3">
              <h5>Alert Credit Utilization</h5>
              <Input
                value={alert.alerts.utilization.minPercentage}
                onChange={(e) => setUtilizationPercentage(e.target.value)}
                placeholder="20%"
                name="utilizationPercentage"
              />
              {renderErrorMsg('utilizationPercentage')}
            </div>
            <div className="d-flex flex-column">
              <h5>Amount ({currencySymbol})</h5>
              <div className={styles.utilizationContainer}>
                <Input
                  value={alert.alerts.utilization.minAmount}
                  onChange={(e) => setUtilizationAmount(e.target.value)}
                  placeholder={`i.g. 1,000${currencySymbol}`}
                  name="utilizationAmount"
                />
              </div>
              {renderErrorMsg('utilizationAmount')}
            </div>
          </div>
          <Checkbox
            isChecked={alert.alerts.expiration.isAlert}
            onChange={setExpirationAlert}
            text={`Alert credit expired (${moment(creditExpirationDate).format('MMM YYYY')})`}
            className="mb-4"
          />
          <Checkbox
            isChecked={alert.alerts.utilization.creditCompletion}
            onChange={setCompletionAlert}
            text={`Alert credit completion (${getCurrencyNumber(creditAmount)})`}
            className="mb-4"
          />
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between">
              <h5>Recipients ({alert.recipientsEmails.split(',').filter((str) => str.trim()).length})</h5>
              <Button
                type="button"
                text="My Email"
                onClick={addMyEmail}
                overrideStyles={{ backgroundColor: 'transparent', color: '#2671FF', fontSize: '12px' }}
                disabled={alert.recipientsEmails.split(',').some((email) => email.trim() === getMyEmail().trim())}
              />
            </div>
            <div className="d-flex flex-column">
              <Input
                value={alert.recipientsEmails}
                onChange={(e) => setRecipients(e.target.value)}
                placeholder="example@gmail.com, example@gmail.com "
                name="recipients"
              />
              {renderErrorMsg('recipientsEmails')}
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

AlertModal.propTypes = propTypes;
AlertModal.defaultProps = defaultProps;
export default AlertModal;
