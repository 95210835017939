import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from 'shared/components/andtComponents/Modal';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { ReactComponent as AWSIcon } from 'shared/img/cloud-providers/simple/aws.svg';
import { ReactComponent as CloudsDimmed } from 'app/containers/OnboardingWelcome/assets/clouds-dimmed.svg';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import onboardingStyles from 'app/containers/Onboarding/SharedPages/Footer/Footer.module.scss';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import OnboardingLinks from 'app/containers/Onboarding/utils/OnboardingLinks';
import { AWS_ONBOARDING_FIELDS } from '../../AwsOnboardingConstants';
import SharedAccountImg from './assets/shared-img.png';
import { ReactComponent as RocketIcon } from './assets/rocket.svg';
import styles from './AccountDetailsModal.module.scss';
import CustomerInput from '../../../SharedPages/CustomerInput/CustomerInput';

const AccountDetailsModal = ({ nextStep }) => {
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const accountType = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_TYPE);
  const setAccountType = (value) => {
    setValue(AWS_ONBOARDING_FIELDS.ACCOUNT_TYPE, value);
    if (value === 'dedicated') {
      setValue(AWS_ONBOARDING_FIELDS.BILLING_PROFILE, {
        rebilling: 1,
        copyAwsCredits: 1,
      });
    } else {
      setValue(AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER, '');
      setValue(AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID, '');
      setValue(AWS_ONBOARDING_FIELDS.BILLING_PROFILE, {
        rebilling: 0,
      });
    }
  };
  const onboardingType = getValues(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const setOnboardingType = (value) => {
    setValue(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE, value);
    setValue(AWS_ONBOARDING_FIELDS.ROLE_ARN, '');
    setValue(AWS_ONBOARDING_FIELDS.BUCKET_NAME, '');
  };
  const autoAssignLinkedAccounts = getValues(AWS_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS);
  const setAutoAssignLinkedAccounts = (value) => setValue(AWS_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS, value);
  return (
    <div className={styles.background}>
      <div className={styles.bgFooter}>
        <CloudsDimmed className={styles.bgClouds} />
      </div>
      <CustomModal
        open
        closeOnOutside={false}
        overrideStyles={{
          width: 968,
        }}
        size="big"
        onClose={() => {}}
        className={{
          container: styles.modalContainer,
          modal: `${styles.modalBody} ${getValues(AWS_ONBOARDING_FIELDS.IS_MSP) ? '' : styles.direct}`,
          title: styles.modalTitle,
        }}
        closeOnSave={false}
        title={
          <div className={styles.header}>
            <div className={styles.headerContent}>Account details and onboarding experience</div>
            <div className={styles.headerLogo}>
              <AWSIcon />
            </div>
          </div>
        }
        footerDisabled
      >
        <div className={styles.body}>
          {getValues(AWS_ONBOARDING_FIELDS.IS_MSP) ? (
            <div>
              <p className={styles.subTitle}>Which type of account do you want?</p>
              <div className={styles.accountTypes}>
                <div className={classNames(styles.accountTypeBlock, { [styles.active]: accountType === 'dedicated' })}>
                  <div className={styles.accountTypeHeader}>
                    <RadioButtonList
                      options={[{ value: 'dedicated', label: 'Dedicated Account', primary: true }]}
                      value={accountType}
                      onChange={(val) => {
                        setAccountType(val);
                      }}
                    />
                  </div>
                  <div className={styles.accountTypeBody}>
                    <p className={styles.description}>
                      This is for a single customer. If this is not the case, please select <span>Shared Account</span>.{' '}
                      <Button
                        onClick={() => window.open(OnboardingLinks.AWS.customerType, '_blank')}
                        text="Learn more"
                        isTextButton
                        overrideStyles={{ paddingLeft: 0, height: 20 }}
                      />
                    </p>
                    <div className={styles.customerInfo} data-disabled={accountType !== 'dedicated'}>
                      <CustomerInput />
                      <Checkbox
                        primary
                        className={styles.checkbox}
                        isChecked={!!autoAssignLinkedAccounts}
                        isDisabled={getValues(AWS_ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED)}
                        onChange={() => setAutoAssignLinkedAccounts(!autoAssignLinkedAccounts)}
                        text="Auto assign linked accounts to the customer"
                      />
                      {!!autoAssignLinkedAccounts && (
                        <div>
                          <label className={styles.excludeLinkedAccountsLabel} htmlFor="customer-name">
                            Excluded linked accounts
                            <span>
                              Define the pattern for linked accounts that are excluded from the auto-assignment process
                              of accounts to customers. You can use ‘*’ as a wildcard in this pattern.
                              <br />
                              For example: *SP*. (to exclude any linked accounts with SP in their name)
                            </span>
                          </label>
                          <Input
                            name="customer-excluded-linked-accounts"
                            value={getValues(AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH)}
                            placeholder="Type pattern"
                            isInvalid={!!errors[AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH]}
                            invalidComponent={errors[AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH]}
                            onChange={(e) => {
                              setValue(AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH, e.target.value);
                            }}
                            className={styles.input}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.note}>
                    <InfoIcon />
                    The customer will be able to see the Commitments section including ALL SP and RI purchases in the
                    AWS Organization. If there are purchases that do not belong to the customer, they will see all
                    purchases. In addition, the customer will get visibility to all cost types including Amortize cost.
                  </div>
                </div>
                <div className={classNames(styles.accountTypeBlock, { [styles.active]: accountType === 'shared' })}>
                  <div className={styles.accountTypeHeader}>
                    <RadioButtonList
                      options={[{ value: 'shared', label: 'Shared Account', primary: true }]}
                      value={accountType}
                      onChange={(val) => {
                        setAccountType(val);
                        setAutoAssignLinkedAccounts(false);
                      }}
                    />
                  </div>
                  <div className={styles.accountTypeBody}>
                    <p>
                      This is for multiple customer, every linked account must be manually assigned to the relevant
                      customer.
                    </p>
                    <Button
                      onClick={() => window.open(OnboardingLinks.AWS.customerType, '_blank')}
                      text="Learn more"
                      isTextButton
                      overrideStyles={{ paddingLeft: 0, height: 20 }}
                    />
                    <img src={SharedAccountImg} className={styles.accountTypeIllustration} alt="illustration" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div>
            <p className={styles.subTitle}>How would you like to complete the onboarding?</p>
            <div className={styles.onboardingTypeBlock}>
              <RadioButtonList
                options={[
                  {
                    value: ONBOARDING_TYPES.AUTOMATIC,
                    key: 1,
                    label: (
                      <div className={styles.onboardingTypeLabel}>
                        <p>
                          Automatically{' '}
                          <RocketIcon
                            className={styles.rocketIcon}
                            data-active={ONBOARDING_TYPES.AUTOMATIC === onboardingType}
                          />
                          <span>Run scripts automatically in order to grant access.</span>
                        </p>
                        <Button
                          onClick={() => window.open(OnboardingLinks.AWS.onboardingType, '_blank')}
                          text="Learn more"
                          isTextButton
                          overrideStyles={{ height: 20 }}
                        />
                      </div>
                    ),
                    primary: true,
                  },
                  {
                    value: ONBOARDING_TYPES.MANUAL,
                    key: 2,
                    label: (
                      <div className={styles.onboardingTypeLabel}>
                        <p>
                          Manually{' '}
                          <span>Grant Anodot access to your Cloud accounts and update each script manually.</span>
                        </p>
                        <Button
                          text="Learn more"
                          onClick={() => window.open(OnboardingLinks.AWS.onboardingType, '_blank')}
                          isTextButton
                          overrideStyles={{ height: 20 }}
                        />
                      </div>
                    ),
                    primary: true,
                  },
                ]}
                value={onboardingType}
                onChange={(val) => {
                  setOnboardingType(val);
                }}
              />
            </div>
          </div>
        </div>
        <div className={onboardingStyles.footer}>
          <div />
          <div className={onboardingStyles.footerMenu}>
            <Button
              onClick={async () => {
                await nextStep();
              }}
              text="Next"
              iconPlacement="right"
              icon={() => <GenerateIcon iconName={ICONS.chevronRight.name} />}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

AccountDetailsModal.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default AccountDetailsModal;
