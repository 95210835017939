import React, { useCallback } from 'react';
import Switch from 'shared/components/andtComponents/Switch';
import classNames from 'classnames';
import styles from '../featureflags.module.scss';
import { AccountFeaturesT } from '../types.ts';
import { ALL_ACCOUNTS_DEFAULT } from '../constants.ts';

type Props = {
  setRow(row: AccountFeaturesT, feature: { featureName: string; isActive: boolean }): void;
  row: AccountFeaturesT;
  allFeatures: { featureName: string; isActive: boolean }[];
  defaultFeatures: { [featureName: string]: '0' | '1' };
};
const SingleRow: React.FC<Props> = ({ row, defaultFeatures, setRow, allFeatures }) => {
  const isDefaultRow = row.id === ALL_ACCOUNTS_DEFAULT;
  const onFlagSwitch = useCallback(
    (featureName: string) => (isActive: boolean) => {
      const newFlags = { ...(row.changedFeatures || {}) };
      newFlags[featureName] = isActive ? '1' : '0';
      setRow({ ...row, changedFeatures: newFlags }, { featureName, isActive });
    },
    [row],
  );

  const getSwitch = ({ featureName }) => {
    const isInOwnFeatures = featureName in row.features || featureName in (row.changedFeatures || {});
    const isInDefaultFeatures = featureName in defaultFeatures;
    const isChecked =
      (isInOwnFeatures
        ? row.changedFeatures?.[featureName] || row.features[featureName]
        : defaultFeatures[featureName]) === '1';
    const isOwnUnChecked = !isDefaultRow && row.changedFeatures?.[featureName] === '0';

    return (
      <div key={featureName} className={styles.flagCell}>
        <Switch
          isChecked={isChecked}
          onChange={onFlagSwitch(featureName)}
          className={classNames({
            [styles.flagSwitchDefault]: isDefaultRow || (isInDefaultFeatures && !isInOwnFeatures),
            [styles.flagSwitch]: !(isDefaultRow || (isInDefaultFeatures && !isInOwnFeatures)),
            [styles.flagSwitchOwnUnchecked]: isOwnUnChecked,
          })}
        />
      </div>
    );
  };

  return (
    <div className={classNames(styles.flagsRow, { [styles.flagsDefaultRow]: isDefaultRow })}>
      <div className={styles.nameCell} title={row.companyName}>
        <b>{row.companyName}</b>
      </div>
      <div className={styles.nameCell} title={`${row.accountName} (${row.accountId})`}>
        {isDefaultRow ? <b>Default Features</b> : row.accountName || row.accountId}
      </div>
      {allFeatures.map(getSwitch)}
    </div>
  );
};

export default SingleRow;
