import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import styles from './anomalyDetectionTableCell.module.scss';

const propTypes = {
  value: PropTypes.any,
  column: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const defaultProps = {
  value: null,
  children: null,
};
const AnomalyDetectionTableCell = (props) => {
  const { value, column, children } = props;
  if (!value || ['explore', 'alerts', 'status', 'comments', 'feedbacks', 'userStatuses'].includes(column.name)) {
    return <Table.Cell {...props} />;
  }
  let tooltipTitle = value;
  if (['accountName'].includes(column.name)) {
    tooltipTitle = children;
  }
  return (
    <Table.Cell {...props}>
      <Tooltip placement="top" title={tooltipTitle} arrow>
        <div className={styles.tableCell}>{children || value}</div>
      </Tooltip>
    </Table.Cell>
  );
};

AnomalyDetectionTableCell.propTypes = propTypes;
AnomalyDetectionTableCell.defaultProps = defaultProps;
export default AnomalyDetectionTableCell;
