/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { numberWithCommas, percentStr } from 'shared/utils/strUtil';

class NumberField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  getValueWithSign = (val) => {
    if (!val) {
      return this.props.placeHolder;
    }
    return this.props.isPercent ? percentStr(this.props.value) : numberWithCommas(this.props.value);
  };

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    return (
      <div>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        {this.state.isEditing ? (
          <input
            className="insert-budget-input"
            type="number"
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.toggleEditing}
            autoComplete="off"
            // min={this.props.min}
            // max={this.props.max}
          />
        ) : (
          <input
            className="insert-budget-input"
            type="text"
            name={this.props.name}
            value={this.getValueWithSign(this.props.value)}
            onFocus={this.toggleEditing}
            placeHolder={this.props.placeHolder}
            readOnly
            autoComplete="off"
            // min={this.props.min}
            // max={this.props.max}
          />
        )}
      </div>
    );
  }
}

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  isPercent: PropTypes.bool.isRequired,
  // min: PropTypes.string.isRequired,
  // max: PropTypes.string.isRequired,
};

export default NumberField;
