import React from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';

const InvoiceOpeningSentenceInput = ({ preference, onChange }) => {
  const { preferences } = preference || {};
  const { openingSentence } = preferences || {};
  const updateInvoiceOpeningSentence = (event) => {
    const validValue = event.target.value.replace(/[^a-z0-9 ]/gi, '').slice(0, 100);
    onChange(validValue);
  };
  return (
    <div>
      <div className="h6 mb-2 pt-2 pb-2">
        <span className="h6">Update Invoice Opening Sentence</span>
      </div>
      <Input label="Up to 100 chars, alpha numeric" value={openingSentence} onChange={updateInvoiceOpeningSentence} />
    </div>
  );
};

InvoiceOpeningSentenceInput.propTypes = {
  preference: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InvoiceOpeningSentenceInput;
