/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { API } from 'shared/utils/apiMiddleware';
import { sanitizeQuery } from 'shared/utils/apiUtil';

class CommitmentApi {
  getCommitmentDashboardData = (granLevel = 'monthly', startDate, endDate, filterParams = '') => {
    const query = `/api/v1/commitment/dashboard?periodGranLevel=${granLevel}&startDate=${startDate}&endDate=${endDate}${filterParams}`;
    const result = API.get('billings', query);
    return result;
  };
  getCommitmentTableData = async (date, commitmentService, linkedAccount, commitmentType, payerAccount) => {
    const apiQuery = `/api/v1/commitment/utilization/i?date=${date}&commitmentType=${commitmentType}&commitmentService=${commitmentService}&linkedAccount=${linkedAccount}&payerAccount=${payerAccount}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getCommitmentChartsAndStatisticsData = async (date, commitmentType) => {
    const apiQuery = `/api/v1/commitment/utilization/statistics?date=${date}&commitmentType=${commitmentType}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getRiFlexabilityData = async (arn, startDate, endDate) => {
    const apiQuery = `/api/v1/commitment/utilization/flexibility/ri?arn=${arn}&startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getRiChargeBackData = async (arn, startDate, endDate) => {
    const apiQuery = `/api/v1/commitment/utilization/chargeback/ri?arn=${arn}&startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getServicePlansChargeBackData = async (arn, startDate, endDate) => {
    const apiQuery = `/api/v1/commitment/utilization/chargeback/sp?arn=${arn}&startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
}

export default CommitmentApi;
