import React, { PureComponent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Card, CardBody, Col, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { MoreHorizontal } from 'react-feather';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import CustomCSVDownload from './buttons/CustomCSVDownload';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

export default class PanelWithActions extends PureComponent {
  static propTypes = {
    isShowActions: PropTypes.bool.isRequired,
    isShowAdditionalAction: PropTypes.bool.isRequired,
    usersStore: PropTypes.object.isRequired,
    handleCSVDownload: PropTypes.func,
    panelAccId: PropTypes.string.isRequired,
    panelAccName: PropTypes.string.isRequired,
    showRefreshButton: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.element,
    handleRefresh: PropTypes.func.isRequired,
    actionListParams: PropTypes.object.isRequired,
    additionalActionButtons: PropTypes.object,
    permission: PropTypes.object,
  };

  static defaultProps = {
    additionalActionButtons: [],
    children: null,
    handleCSVDownload: null,
    permission: {},
    showRefreshButton: false,
    title: '',
  };

  constructor() {
    super();

    this.state = {
      actionListIsOpen: false,
    };
  }

  getAccountInfoSubTitle = () => {
    const { usersStore, panelAccId, panelAccName } = this.props;
    if (usersStore.getCurrDisplayedAccountId() !== panelAccId && panelAccName) {
      return ` (${panelAccName})`;
    }
    return '';
  };
  toggleActionList = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ actionListIsOpen: !prevState.actionListIsOpen }));
  };
  activeHandler = (e, handler) => {
    handler();
    this.toggleActionList(e);
  };
  renderActionCollapseList = () => (
    <>
      {this.state.actionListIsOpen && <button className="topbar__back" onClick={(e) => this.toggleActionList(e)} />}
      <Collapse isOpen={this.state.actionListIsOpen} className="topbar__menu-wrap-bottom">
        <div className="drop__menu-no-hover">
          {this.props.actionListParams.map((param) => (
            // eslint-disable-next-line react/jsx-key
            <p className="collapse-item-light-small" onClick={(e) => this.activeHandler(e, param.handler)}>
              {param.title}
            </p>
          ))}
        </div>
      </Collapse>
    </>
  );

  render() {
    const {
      title,
      isShowActions,
      isShowAdditionalAction,
      handleRefresh,
      showRefreshButton,
      additionalActionButtons,
      children,
      permission,
      handleCSVDownload,
    } = this.props;
    return (
      <Col>
        <Card className="panel panel--divider">
          <CardBody className="panel__body">
            <div className="panel__title">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tooltip title={title}>
                  <h5 className="bold-text">
                    {title}
                    {this.getAccountInfoSubTitle()}
                  </h5>
                </Tooltip>
                <div className="d-flex align-items-center" style={{ gap: 8 }}>
                  {handleCSVDownload && (
                    <CustomCSVDownload
                      fetchData={handleCSVDownload}
                      filesNumber={1}
                      isLoading={false}
                      showDownloadIcon={false}
                      classNames="btn-no-style me-3"
                    />
                  )}
                  {showRefreshButton && (
                    <IconButton className="panel__refresh-button" onClick={handleRefresh} size="large">
                      <Tooltip title="Refresh data">
                        <div>
                          <GenerateIcon iconName={ICONS.refresh.name} size={'xs'} />
                        </div>
                      </Tooltip>
                    </IconButton>
                  )}
                  {!showRefreshButton && isShowAdditionalAction
                    ? additionalActionButtons.map((compObj) => compObj.func(...compObj.props))
                    : null}
                  {isShowActions && (
                    <>
                      <ReadOnlyDisplayWrapper isHide={false} category={permission.category} action={permission.action}>
                        <LinkIconButton>
                          <button
                            className="more-options-active btn-no-style"
                            onClick={(e) => this.toggleActionList(e)}
                          />
                          <MoreHorizontal size={23} />
                        </LinkIconButton>
                      </ReadOnlyDisplayWrapper>
                      {this.renderActionCollapseList()}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="panel__content">{children}</div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
