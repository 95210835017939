import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { mapRegionNamesToDescription } from 'shared/constants/awsConstants';
import {
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  FilteringState,
  SortingState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnVisibility,
  TableFilterRow,
  PagingPanel,
  TableColumnResizing,
  Toolbar,
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import { kFormatterWithDecimal } from 'shared/utils/strUtil';
import { Link } from 'react-router-dom';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

const Cell = (props) => <Table.Cell {...props} />;

const resourceIdPredicate = (value, filter, row) => {
  const searchValue = filter.value;
  const searchTarget = row.resourceId || '';
  return searchTarget.toLowerCase().includes(searchValue.toLowerCase());
};

class FilterTable extends React.PureComponent {
  static propTypes = {
    localRowsStorage: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    cloudType: PropTypes.number.isRequired,
    setSelectedPageSize: PropTypes.func.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      columns: this.columnsByCloudType[props.cloudType],
      columnWidths: [
        { columnName: 'resourceId', width: 200 },
        { columnName: 'linkedAccountName', width: 200 },
        { columnName: 'serviceName', width: 300 },
        { columnName: 'resourceName', width: 400 },
        { columnName: 'resourceGroup', width: 400 },
        { columnName: 'totalCost', width: 100 },
        { columnName: 'totalUsage', width: 100 },
        { columnName: 'resourceDescription', width: 600 },
        { columnName: 'customTags', width: 200 },
        { columnName: 'projectName', width: 200 },
        { columnName: 'familyType', width: 200 },
        { columnName: 'region', width: 200 },
      ],
      tableColumnExtensions: [
        { columnName: 'totalCost', align: 'center' },
        { columnName: 'totalUsage', align: 'center' },
      ],
      hiddenColumnNames: ['serviceName'],
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      rows: props.localRowsStorage,
      pageSizes: [10, 20, 25, 50, 0],
      integratedFilteringColumnExtensions: [{ columnName: 'resourceId', predicate: resourceIdPredicate }],
    };
  }
  componentDidMount() {
    const { setSelectedPageSize } = this.props;
    setSelectedPageSize(15);
  }
  idColumn = [
    {
      name: 'resourceId',
      title: 'Resource ID',
      getCellValue: (row) => {
        if (row.resourceId !== 'Not Available') {
          return (
            <Link
              // to={Routes.RESOURCE_EXPLORER}
              to={{
                pathname: `${Routes.RESOURCE_EXPLORER}`,
                state: {
                  startDate: this.props.startDate,
                  endDate: this.props.endDate,
                  selectedResourceId: row.groupBy,
                },
              }}
            >
              {row.groupBy}
            </Link>
          );
        }
        return row.resourceId;
      },
    },
  ];
  costColumns = [
    { name: 'totalCost', title: 'Cost', getCellValue: (row) => row.totalCost },
    { name: 'totalUsage', title: 'Quantity', getCellValue: (row) => parseFloat(row.totalUsage) },
  ];
  commonColumns = [
    {
      name: 'region',
      title: 'Region Name',
      getCellValue: ({ regionTagName }) => mapRegionNamesToDescription.get(regionTagName) || regionTagName,
    },
    { name: 'serviceName', title: 'Service name', getCellValue: (row) => row.serviceName },
  ];
  awsColumns = [
    { name: 'resourceName', title: 'Resource Name', getCellValue: (row) => row.resourceName },
    ...this.costColumns,
    { name: 'resourceDescription', title: 'Description', getCellValue: (row) => row.resourceDescription },
    { name: 'linkedAccountName', title: 'Linked Account Name', getCellValue: (row) => row.linkedAccountName },
    { name: 'customTags', title: 'Custom Tags', getCellValue: (row) => row.customTags },
    { name: 'familyType', title: 'Family Type', getCellValue: (row) => row.familyType },
  ];
  gcpColumns = [
    { name: 'resourceName', title: 'Resource Name', getCellValue: (row) => row.resourceName },
    ...this.costColumns,
    { name: 'resourceDescription', title: 'Description', getCellValue: (row) => row.resourceDescription },
    { name: 'customTags', title: 'Custom Tags', getCellValue: (row) => row.customTags },
    { name: 'projectName', title: 'Project Name', getCellValue: (row) => row.projectName },
  ];

  azureColumns = [
    { name: 'linkedAccountName', title: 'Subscription Name', getCellValue: (row) => row.linkedAccountName },
    { name: 'resourceGroup', title: 'Resource Group', getCellValue: (row) => row.resourceGroup },
    { name: 'resourceName', title: 'Resource Name', getCellValue: (row) => row.resourceName },
    ...this.costColumns,
    this.idColumn[0],
    {
      name: 'region',
      title: 'Region Name',
      getCellValue: ({ regionTagName }) => mapRegionNamesToDescription.get(regionTagName) || regionTagName,
    },
    { name: 'customTags', title: 'Tags', getCellValue: (row) => row.customTags },
  ];

  columnsByCloudType = {
    [CLOUD_TYPE_IDS.AWS]: [...this.idColumn, ...this.awsColumns, ...this.commonColumns],
    [CLOUD_TYPE_IDS.AZURE]: this.azureColumns,
    [CLOUD_TYPE_IDS.GCP]: [...this.idColumn, ...this.gcpColumns, ...this.commonColumns],
  };

  currenciesFormatter = (value) => this.props.getCurrencyNumber(kFormatterWithDecimal(value.value, 1));

  // eslint-disable-next-line class-methods-use-this
  numberFormatter = (value) => kFormatterWithDecimal(value.value, 1);

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  addUsageQuantityTypeColumn = (isUsageQuantityType) => {
    if (isUsageQuantityType && !this.state.columns.some((column) => column.name === 'quantityType')) {
      const index = this.state.columns.findIndex((column) => column.name === 'totalUsage');
      this.state.columns.splice(index + 1, 0, {
        name: 'quantityType',
        title: 'Quantity Type',
        getCellValue: (row) => {
          if (row.usageQuantityType === 'Bytes') {
            return 'GB';
          }
          return row.usageQuantityType;
        },
      });
      this.state.columnWidths.push({ columnName: 'quantityType', width: 200 });
    }
  };

  render() {
    const { setSelectedPageSize, localRowsStorage } = this.props;
    const {
      rows,
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames,
      numericFilterOperations,
      integratedFilteringColumnExtensions,
      pageSizes,
    } = this.state;
    const isUsageQuantityType = rows.some((row) => row.usageQuantityType);
    this.addUsageQuantityTypeColumn(isUsageQuantityType);
    return (
      <div style={{ width: '100%' }} ref={this.refCallback}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={localRowsStorage} columns={columns}>
              <FilteringState defaultFilters={[{}]} />
              <SortingState
                defaultSorting={[
                  { columnName: 'totalCost', direction: 'desc' },
                  { columnName: 'totalUsage', direction: 'desc' },
                ]}
              />
              <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
              <IntegratedSorting />
              <PagingState
                defaultCurrentPage={0}
                defaultPageSize={20}
                onPageSizeChange={(size) => setSelectedPageSize(size)}
              />
              <IntegratedPaging />
              <DataTypeProvider
                for={['totalCost']}
                formatterComponent={this.currenciesFormatter}
                availableFilterOperations={numericFilterOperations}
              />
              <DataTypeProvider
                for={['totalUsage']}
                formatterComponent={this.numberFormatter}
                availableFilterOperations={numericFilterOperations}
              />
              <TableWrapper cellComponent={Cell} columnExtensions={tableColumnExtensions} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <PagingPanel pageSizes={pageSizes} />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </Paper>
        </Col>
      </div>
    );
  }
}

const ObserverFilterTable = withUserSettingsConsumer(FilterTable);
export default ObserverFilterTable;
