import React from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import ConnectK8s from './components/ConnectK8s';

const KubernetesPreview = () => (
  <Container>
    <PageHeader title={PageNames.K8S_PREVIEW} />
    <ConnectK8s />
  </Container>
);

export default KubernetesPreview;
