import { AwsCommonFields, mapRegionNamesToDescription } from 'shared/constants/awsConstants';
import { convertObjToMap } from 'shared/utils/apiUtil';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { modifiedPurchaseOptionToDisplay, modifyRegionNameToDisplay } from 'shared/utils/awsUtils';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { CostUsageStates } from '../../../constants/costAndUsageConstants';

export const prepareCauParamsForNewCustomDashboardPanel = (allParams) => {
  const {
    currentGroupBy: groupByLevel,
    filterBarGroupBySecondary: secondaryGroupByLevel,
    currentGroupBy: groupByValue,
    dataKeyToWhereParamsMap: whereParamsMap,
    currPeriodGranLevel: periodGranLevel,
    fieldToFilterdValuesMap: filtersMap,
    likeFiltersStatus,
    filtersConfig,
    currCostType,
    isShowAmortizeCost: isShowAmortize,
    isApplyMargin,
    excludedFiltersStatusMap,
    isNetAmortize,
    isNetUnblended,
    isPublicCost,
    isDistributed,
    isRateUsageBased,
    displayMetricTypes,
  } = allParams;
  const prepareRouteParamsObj = {
    groupByLevel,
    secondaryGroupByLevel,
    groupByValue,
    whereParamsMap: convertObjToMap(whereParamsMap),
    periodGranLevel,
    filtersMap: convertObjToMap(filtersMap),
    filtersConfig,
    currCostType,
    isShowAmortize,
    isApplyMargin,
    likeFiltersStatus,
    excludedFiltersStatusMap: convertObjToMap(excludedFiltersStatusMap),
    isNetAmortize,
    isNetUnblended,
    isPublicCost,
    isDistributed,
    isRateUsageBased,
    displayMetricTypes,
  };
  return prepareRouteParamsObj;
};

export const prepareCauParamsForNewAssetsCustomDashboardPanel = (allParams) => {
  const {
    currPeriodGranLevel: periodGranLevel,
    selectedOptionsMap: filtersMap,
    excludedFiltersStatusMap,
    selectedMeasure,
    selectedColumns,
    filtersConfig,
    likeFiltersStatus,
    startDate,
    endDate,
    currCostType,
  } = allParams;
  const filtersMapValues = new Map();
  [...filtersMap.keys()].forEach((key) => {
    filtersMapValues.set(key, filtersMap.get(key));
  });

  const prepareRouteParamsObj = {
    selectedMeasure,
    selectedColumns,
    periodGranLevel,
    filtersMap: filtersMapValues,
    excludedFiltersStatusMap,
    filtersConfig,
    likeFiltersStatus,
    startDate,
    endDate,
    currCostType,
  };
  return prepareRouteParamsObj;
};

export const prepareBaseParams = (baseParams) => {
  const baseParamObj = {
    newDashboardName: baseParams.customDashboardName,
    existingDashboardUuid: baseParams.existingDashboard && baseParams.existingDashboard.value,
    panelName: baseParams.customDashboardPanelName,
    type: baseParams.type,
    state: baseParams.state,
    arrBreadCrumbs: baseParams.arrBreadCrumbs,
  };
  return baseParamObj;
};

export const preparePeriodParams = (periodParams) => {
  const baseParamObj = {
    periodType: 'relativeDate',
    relativeDatesDaysDiff: periodParams.relativeDatesPeriod,
    granularity: periodParams.granularity,
  };
  return baseParamObj;
};

export const createNewCustomDashboardPanelObj = (baseParams, periodParams, routeParams) => {
  const preparedObj = { ...baseParams, periodParams: { ...periodParams }, routeParams: { ...routeParams } };
  return preparedObj;
};

export const getBreadcrumbLabelDisplayName = (label, type, groupBy, cloudTypeId = 0) => {
  if (type === CostUsageStates.CUSTOM_TAGS) {
    const prefix = cloudTypeId === CLOUD_TYPE_IDS.GCP ? 'Label' : 'Custom Tag';
    return `${prefix} - ${groupBy.split('customtags:')[1]}`;
  }
  if (type === CostUsageStates.ACCOUNT_TAGS) {
    return `Enrichment Tag - ${groupBy.split('accounttags:')[1]}`;
  }
  return mapRegionNamesToDescription.has(label) ? mapRegionNamesToDescription.get(label) : label;
};

// CHANGES HERE SHOULD BE APPLIED IN getFilterOptionByValue TOO
export const formatFilterOptionValueLabel = (() => {
  const valueToOptionMap = new Map();
  return (fieldValue, filterType) => {
    const cacheKey = `${JSON.stringify(fieldValue)}___${filterType}`;
    if (valueToOptionMap.has(cacheKey)) {
      return valueToOptionMap.get(cacheKey);
    }
    let value = null;
    let label = null;
    let chip = null;
    if (typeof fieldValue === 'object' && fieldValue && fieldValue.linkedAccountId) {
      value = fieldValue.linkedAccountId;
      label = fieldValue.displayLabel;
    } else if (filterType === AwsCommonFields.DIVISION) {
      value = fieldValue.divisionName;
      label = LabelCoordinator.getDataKeyDisplayName(
        'cueDisplayCoordinator',
        fieldValue.divisionNameDisplay || fieldValue.divisionName,
      );
    } else if (filterType === AwsCommonFields.BUSINESS_MAPPING) {
      value = fieldValue.id;
      label = fieldValue.name;
      chip = fieldValue.split ? 'split cost' : null;
    } else if (filterType === AwsCommonFields.VIRTUAL_CUSTOM_TAGS) {
      value = fieldValue.uuid;
      label = fieldValue.name;
    } else if (filterType === AwsCommonFields.PURCHASE_OPTION) {
      value = fieldValue.purchaseOptionName;
      label = modifiedPurchaseOptionToDisplay(fieldValue.purchaseOptionDisplayName);
    } else {
      value = fieldValue === 'Not Allocated' ? 'Not Available' : fieldValue;
      label = modifyRegionNameToDisplay(filterType, fieldValue);
    }
    const data = { value, label, chip };
    valueToOptionMap.set(cacheKey, data);
    return data;
  };
})();

const getFilterOptionByValue = (field, optionValue, distinctOptionsMap) => {
  const options = distinctOptionsMap.get(field);
  if (isEmptyArray(options)) {
    return { label: optionValue, value: optionValue };
  }
  const option = options.find((fieldValue) => {
    let value = null;
    if (typeof fieldValue === 'object' && fieldValue && fieldValue.linkedAccountId) {
      value = fieldValue.linkedAccountId;
    } else if (field === AwsCommonFields.DIVISION) {
      value = fieldValue.divisionName;
    } else if (field === AwsCommonFields.BUSINESS_MAPPING) {
      value = fieldValue.id;
    } else if (field === AwsCommonFields.VIRTUAL_CUSTOM_TAGS) {
      value = fieldValue.uuid;
    } else if (field === AwsCommonFields.PURCHASE_OPTION) {
      value = fieldValue;
    } else {
      value = fieldValue === 'Not Allocated' ? 'Not Available' : fieldValue;
    }
    return value === optionValue;
  });
  return option ? formatFilterOptionValueLabel(option, field) : { label: optionValue, value: optionValue };
};

export const formatFiltersMapToSelectOptionsMap = (filtersMap, distinctOptionsMap) => {
  const newMap = new Map();
  [...filtersMap.entries()].forEach(([field, options]) => {
    newMap.set(
      field,
      options.map((o) => getFilterOptionByValue(field, o, distinctOptionsMap)),
    );
  });
  return newMap;
};
