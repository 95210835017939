import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Paper from '@mui/material/Paper';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, TableFilterRow, Toolbar, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { prepareRawReportToTableDisplay } from 'divisions/containers/Customers/helpers/customerReportsHelpers';
import * as TABLE_CONSTANTS from '../constants/customerReportsConstants';
import UserActions from './UserActions';

// eslint-disable-next-line react/prop-types
const FilterIcon = ({ type, event, dataField, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

const CustomerReportsTable = (props) => {
  const { updateReportHandler, deleteHandler, sendReportHandler, reports } = props;
  const [pageSizes] = useState([5, 10, 15, 0]);
  const prepredRows = reports.map((row) => prepareRawReportToTableDisplay(row));
  return (
    <Paper>
      <Grid rows={prepredRows} columns={TABLE_CONSTANTS.CUSTOMER_REPORTS_TABLE_COLUMNS}>
        <SortingState defaultSorting={TABLE_CONSTANTS.CUSTOMER_REPORTS_DEFAULT_SORTING} />
        <FilteringState defaultFilters={[]} />
        <IntegratedSorting />

        <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
        <IntegratedPaging />

        <IntegratedFiltering />
        <DataTypeProvider
          for={['userAction']}
          formatterComponent={(tableProps) => (
            <UserActions
              {...{
                ...tableProps,
                ...{
                  updateReportHandler,
                  deleteHandler,
                  sendReportHandler,
                },
              }}
            />
          )}
        />
        <TableWrapper />
        <TableHeaderRow showSortingControls />
        <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
        <PagingPanel pageSizes={pageSizes} />
        <Toolbar />
      </Grid>
    </Paper>
  );
};

CustomerReportsTable.propTypes = {
  updateReportHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  sendReportHandler: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
};

export default observer(CustomerReportsTable);
