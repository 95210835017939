import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const KmsOld = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      comment: 'Note that as long as the Keys are pending deletion this action is reversible.',
      instructions: [
        {
          actionText: 'For more information',
          actionLink: 'https://docs.aws.amazon.com/kms/latest/developerguide/deleting-keys.html',
          // eslint-disable-next-line max-len
          actionCommand: `aws kms schedule-key-deletion --key- ${recommendation?.resourceId} --pending-window-in-days NUM_BETWEEN_7_TO_30`,
        },
      ],
    },
  ];
  const description =
    'You are currently being charged for an old KMS.\n' +
    'A KMS is defined as old, per Recommendation preferences (default is 365 days).\n' +
    "While they still might be in use, it's recommended to rotate any keys that are older than 365 days.\n" +
    'We recommend rotating these keys, which will create new ones, and then to disable the old ones.\n' +
    '\n' +
    'If you are confident keys are already rotated, use the following command for scheduling a deletion.\n' +
    'Deletion will happen within the preferred window time that is 7-30 days.\n' +
    'Note that as long as the Keys are pending deletion this action is reversible.';
  return <BaseRecommendation recommendation={recommendation} description={description} commandsList={commandsList} />;
};

KmsOld.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default KmsOld;
