import React from 'react';
import PropTypes from 'prop-types';
import FieldFilter from 'shared/components/FieldFilter';
import { propComparator } from 'shared/utils/sortUtil';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { AwsCommonFields } from 'shared/constants/awsConstants';

const MultiTypeFieldFilterSelector = ({
  selectedValues,
  filtersValuesMap,
  handler,
  type,
  name,
  valueParam,
  labelParam,
  placeHolder,
  addAllOption,
  style,
  usageTypesFetcher,
}) => {
  const createOptions = () => {
    const values = filtersValuesMap.get(type);
    const options =
      !values || !Array.isArray(values)
        ? []
        : values.map((fieldValue) => {
            const value = fieldValue[valueParam] || fieldValue;
            const label = fieldValue[labelParam] || fieldValue;
            //   const value = fieldValue.linkedAccountId;
            //   const label = fieldValue.displayLabel;
            return { value, label };
          });
    if (options.length) {
      options.sort(propComparator('label'));
    }
    if (addAllOption) {
      options.unshift({ value: 'all', label: addAllOption });
    }
    return options;
  };
  return (
    <div>
      <FieldFilter
        type={type}
        name={name}
        // type={AwsCommonFields.LINKED_ACCOUNT_NAME}
        // name={AwsCommonFields.LINKED_ACCOUNT_NAME}
        value={selectedValues}
        options={createOptions()}
        handleChange={handler}
        placeHolder={placeHolder}
        style={style}
        isLoading={type === AwsCommonFields.USAGE_TYPE ? usageTypesFetcher.isLoading : false}
      />
    </div>
  );
};

MultiTypeFieldFilterSelector.propTypes = {
  selectedValues: PropTypes.object.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  valueParam: PropTypes.string.isRequired,
  labelParam: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  usageTypesFetcher: PropTypes.func.isRequired,
  addAllOption: PropTypes.bool,
  style: PropTypes.object,
};
MultiTypeFieldFilterSelector.defaultProps = {
  addAllOption: false,
  style: {},
};

export default withInvoiceFiltersContextConsumer(MultiTypeFieldFilterSelector);
