import html2canvas from 'html2canvas';

export const downloadChartAsPng = async (chartRef) => {
  const chartElement = chartRef;
  if (chartElement?.current) {
    html2canvas(chartElement.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'chart-to-image.png';
      link.click();
    });
  }
};

export const downloadTableAsPng = async (tableRef, tableId) => {
  const tableElement = tableRef.current;
  const tables = tableElement?.getElementsByTagName('table');
  if (!tables.length) {
    return;
  }
  if (tableElement) {
    html2canvas(tableElement, {
      onclone: (document) => {
        // Get all tables within the tableElement
        const tables = document.getElementById(tableId).getElementsByTagName('table');
        // Set the background color for each table
        for (let i = 0; i < tables.length; i++) {
          tables[i].style.backgroundColor = '#ffffff';
        }
        const columnChooser = document.getElementsByClassName('MuiToolbar-root');

        if (columnChooser?.length) {
          columnChooser[0].style.backgroundColor = '#ffffff';
        }
      },
      backgroundColor: '#ffffff',
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'table-to-image.png';
      link.click();
    });
  }
};
