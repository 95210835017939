import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { palette } from 'shared/constants/colorsConstants';
import { isDecimalNeeded } from 'shared/utils/strUtil';
import moment from 'moment/moment';
import { EXPORT_COLUMNS } from 'commitment/containers/spAnalyzerNew/utils/consts';
import { downloadChartAsPng } from 'shared/utils/downloadAsPng';
import { CustomizedLegend } from 'shared/components/chartComponents/Legends/CustomizedLegend';
import styles from './recommendedChart.module.scss';
import CustomChartTooltip  from './CustomChartTooltip';
import ChartHeader from './ChartHeader';

const legendWrapperStyle = {
  bottom: '-10px',
  left: '0',
  position: 'absolute',
  width: 'auto',
};

const CustomizedAxisTick = ({ x, y, payload, isDailyMode, isDateFormat }) => {
  const { value } = payload;
  const dateFormat = moment(value).format('MMM DD');
  if (isDailyMode) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" className={styles.chartTick}>
          <tspan x="0" dy="1.2em">
            {isDateFormat ? dateFormat:  value}
          </tspan>
        </text>
      </g>
    );
  }
  const timeFormat = moment(value).format('HH:mm');
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" className={styles.chartTick}>
        <tspan x="0" dy="0.8em">
          {isDateFormat ? dateFormat : value.split('-')[0]}
        </tspan>
        <tspan x="0" dy="1.25em">
          {isDateFormat ? timeFormat : value.split('-')[1]}
        </tspan>
      </text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
  isDailyMode: PropTypes.bool.isRequired,
  isDateFormat: PropTypes.bool.isRequired,
};
const isLegendDisplay = (legend, includeEstimatedOption, isEstimatedMode) => {
  if (includeEstimatedOption) {
    return !legend.isSimulate && (!legend.isEstimated || isEstimatedMode);
  }
  return true;
};
// when includeEstimatedOption = true - The chart can include 4 mode:
// actual, actual+estimate, simulate, simulate+estimate
// set the display legend and relevant tooltip according to the mode
const RecommendedChart = ({
  data,
  legend,
  header,
  info,
  chartType,
  includeEstimatedOption,
  tooltipMapData,
  isAnimationActive,
  automationId,
  isDateFormat,
  isCurrentTab
}) => {
  const [isDailyMode, setIsDailyMode] = useState(true);
  const [isEstimatedMode, setIsEstimatedMode] = useState(false);
  const chartRef = useRef();
  const [chartData, setChartData] = useState(isDailyMode ? data.daily : data.hourly);

  const [turnedOffLines, setTurnedOffLines] = useState([]);

  // Calculate the maximum value in your data
  const maxValue = Math.max(
    ...chartData.map((item) =>
      Math.max(...legend.map((leg) => (leg.estimateKey ? item[leg.estimateKey] : item[leg.key]))),
    ),
  );
  // Calculate the chart width for long data
  const chartWidth = isDailyMode || chartData.length < 20 ? '100%' : 50 * chartData.length;
  const fetchChartToCsv = () => {
    const exportColumns = EXPORT_COLUMNS.filter(
      (col) => col.key === 'date' || legend.find((l) => l.exportKey === col.key || l.key === col.key),
    );
    let headers = [...exportColumns];
    if (includeEstimatedOption) {
      headers = headers.filter((col) => col.key === 'date' || col.isSimulateColumn === false || (isEstimatedMode && col.isEstimateColumn));
    }
    if(isCurrentTab) {
      headers = headers.map((col) => ({
        key: col.key,
        label: col.currentLabel || col.label,
      }));
    }
    return [
      {
        data: chartData || [],
        filename: `${header}.csv`,
        headers,
      },
    ];
  };
  const downloadAsSvg = () => {
    const chartElement = chartRef.current;
    if (chartElement) {
      const svgElement = chartElement.current.getElementsByTagName('svg')[0];
      const serializer = new XMLSerializer();
      const svgStr = serializer.serializeToString(svgElement);

      const blob = new Blob([svgStr], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${header}.svg`;
      link.click();

      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    setChartData(isDailyMode ? data.daily : data.hourly);
  }, [isDailyMode, data]);

  const currentTooltipMapData = useMemo(() => {
    if (isEstimatedMode) {
      return tooltipMapData?.estimatedMode;
    }
    return tooltipMapData.regularMode;
  }, [isEstimatedMode, tooltipMapData]);

  const onClickLegendPair = (entry) => {
    if (!isEstimatedMode && tooltipMapData.tpType === 'actual') {
      return;
    }
    if (turnedOffLines.includes(entry.currentTarget.id)) {
      setTurnedOffLines(turnedOffLines.filter((lineId) => lineId !== entry.currentTarget.id));
    } else {
      setTurnedOffLines([...turnedOffLines, entry.currentTarget.id]);
    }
  };

  useEffect(() => {
    if(!includeEstimatedOption && isEstimatedMode){
      setIsEstimatedMode(false);
    }
  },[includeEstimatedOption, isEstimatedMode])
  return (
    <div className={styles.chartWrapper}>
      <ChartHeader
        header={header}
        includeEstimatedOption={includeEstimatedOption}
        info={info}
        exportToCsv={fetchChartToCsv}
        exportToPng={() => downloadChartAsPng(chartRef.current)}
        exportToSvg={downloadAsSvg}
        isDailyMode={isDailyMode}
        setIsDailyMode={setIsDailyMode}
        isEstimatedMode={isEstimatedMode}
        setIsEstimatedMode={setIsEstimatedMode}
        automationId={automationId}
      />
      <div className={styles.chart}>
        <ResponsiveContainer width={chartWidth} height={285} ref={chartRef}>
          <AreaChart width={500} height={400} data={chartData} margin={{top: 20, right: 0, bottom: 0, left: 0}}>
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgb(200, 60, 238)" stopOpacity={0.4}/>
                <stop offset="100%" stopColor="rgb(200, 60, 238)" stopOpacity={0.0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 0" vertical={false}/>
            <XAxis dataKey="date" axisLine={false} tickLine={false} interval={0}
                   tick={<CustomizedAxisTick isDailyMode={isDailyMode} isDateFormat={isDateFormat}/>}/>
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) =>
                  `${value ? '$' : ''}${Number(value).toFixed(isDecimalNeeded(value, 2) ? 2 : 0)}`
              }
              tick={{fill: palette.gray[400]}}
              domain={[0, maxValue * 1.1]}
            />
            <Tooltip
              content={
                <CustomChartTooltip tooltipMapData={{...currentTooltipMapData, type: tooltipMapData.tpType}}/>
              }
            />
            {legend.map((leg) =>
                isLegendDisplay(leg, includeEstimatedOption, isEstimatedMode) ? (
                    <Area
                      type={chartType}
                      key={leg.key}
                      className={`${turnedOffLines.includes(`${leg.key}Legend`) && styles.hiddenLine}`}
                      dataKey={isEstimatedMode && leg.estimateKey ? leg.estimateKey : leg.key}
                      stroke={leg.color}
                      stackId={!isEstimatedMode && tooltipMapData.tpType === 'actual' ? '1' : undefined}
                      fill={leg.isGradientFill ? 'url(#colorGradient)' : leg.color}
                      fillOpacity={isEstimatedMode && leg.estimateOpacity ? leg.estimateOpacity : leg.opacity}
                      strokeWidth={2}
                      id={leg.key}
                      name={leg.name}
                      isAnimationActive={isAnimationActive}
                      strokeDasharray={leg.isDash ? '4 3' : 'none'}
                    />
                ) : null,
            )}
            <Legend
              wrapperStyle={legendWrapperStyle}
              content={
                <CustomizedLegend
                    className={{customizedLegend: styles.customizedLegend}}
                    turnedOffLines={turnedOffLines}
                    onClickLegendPair={onClickLegendPair}
                    dashLegends={legend.filter((l) => l.isDash).map((l) => isEstimatedMode ? l.estimateKey || l.key : l.key)}
                />
              }
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

RecommendedChart.propTypes = {
  data: PropTypes.shape({
    daily: PropTypes.array.isRequired,
    hourly: PropTypes.array.isRequired,
  }).isRequired,
  legend: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  info: PropTypes.string,
  chartType: PropTypes.string.isRequired,
  includeEstimatedOption: PropTypes.bool.isRequired,
  tooltipMapData: PropTypes.object.isRequired,
  isAnimationActive: PropTypes.bool,
  automationId: PropTypes.string,
  isDateFormat: PropTypes.bool,
  isCurrentTab: PropTypes.bool,
};

export default RecommendedChart;
