/* eslint-disable indent */
import RiDescription from '../../containers/Ri/components/Description';
import IpPublicIpv4Description from '../../containers/IpPublicIpv4/components/Description';
import EcsFargateDescription from '../../containers/EcsFargate/components/Description';
import RdsClassChangeDescription from '../../containers/RdsClassChange/components/Description';
import RdsIopsChangeDescription from '../../containers/RdsIopsChange/components/Description';
import S3VersioningDescription from '../../containers/S3Versioning/components/Description';
import S3StorageClassDescription from '../../containers/S3StorageClass/components/Description';
import CloudtrailDuplicateTrailsDescription from '../../containers/CloudtrailDuplicateTrails/components/Description';
import KmsIdleDescription from '../../containers/KmsIdle/components/Description';
import KmsOldDescription from '../../containers/KmsOld/components/Description';
import SpotDescription from '../../containers/Spot/components/Description';
import RegionDescription from '../../containers/Region/components/Description';
import VuDescription from '../../containers/VersionUpgrade/components/Description';
import OsDescription from '../../containers/OperationSystem/components/Description';
import IpDescription from '../../containers/IpUnattached/components/Description';
import Ec2IdleDescription from '../../containers/Ec2Idle/components/Description';
import EbsUnattachedDescription from '../../containers/EbsUnattached/components/Description';
import IdleLoadBalancDescription from '../../containers/IdleLoadBalancer/components/Description';
import RegionMigrationDescription from '../../containers/RegionMigration/components/Description';
import Ec2LowCpuUsageDescription from '../../containers/Ec2LowCpu/components/Description';
import DynamoDbIdleDescription from '../../containers/DdbIdle/components/Description';
import RdsRiDescription from '../../containers/RdsRi/components/Description';
import RdsVuDescription from '../../containers/RdsVersionUpgrade/components/Description';
import RdsIdleDescription from '../../containers/RdsIdle/components/Description';
import S3MultipartUploadDescription from '../../containers/S3MultipartUpload/components/Description';
import S3IdleDescription from '../../containers/S3Idle/components/Description';
import EbsTypeChangeDescription from '../../containers/EbsTypeChange/components/Description';
import ElasticacheRiDescription from '../../containers/ElasticacheRi/components/Description';
import OpenSearchRiDescription from '../../containers/OpenSearchRi/components/Description';
import EbsUpgradeDescription from '../../containers/EbsUpgrade/components/Description';
import RdsTypeChangeDescription from '../../containers/RdsTypeChange/components/Description';
import Ec2ScheduleDescription from '../../containers/Ec2Schedule/components/Description';
import SavingsPlansDescription from '../../containers/SavingsPlans/components/Description';
import EbsOutdatedSnapshotDescription from '../../containers/EbsOutdatedSnapshot/components/Description';
import Ec2UnnecessaryDataTransferDescription from '../../containers/Ec2UnnecessaryDataTransfer/components/Description';
import Ec2StoppedInstanceDescription from '../../containers/Ec2StoppedInstance/components/Description';
import RedshiftLowUtilization from '../../containers/RedshiftLowUtilization/components/Description';
import ElasticsearchLowUtilization from '../../containers/ElasticsearchLowUtilization/components/Description';
import ElasticacheLowUtilization from '../../containers/ElasticacheLowUtilization/components/Description';
import NatGatewayLowUtilization from '../../containers/NatGatewayLowUtilization/components/Description';
import DocumentDbLowUtilization from '../../containers/DocumentDbLowUtilization/components/Description';
import NeptuneLowUtilization from '../../containers/NeptuneLowUtilization/components/Description';
import KinesisLowUtilization from '../../containers/KinesisLowUtilization/components/Description';

// AZURE ****
import AzureVmRiDescription from '../../containers/AzureVmRi/components/Description';
import AzureIpDescription from '../../containers/AzureIpUnattached/components/Description';
import AzureVmStopped from '../../containers/AzureVmStopped/components/Description';
import AzureMariaDBIdle from '../../containers/AzureMariaDbIdle/components/Description';
import AzureCosmosDbIdle from '../../containers/AzureCosmosDbIdle/components/Description';
import AzureMysqlDbIdle from '../../containers/AzureMysqlDbIdle/components/Description';
import AzurePostgresDbIdle from '../../containers/AzurePostgresDbIdle/components/Description';
import AzureSqlDbIdle from '../../containers/AzureSqlDbIdle/components/Description';
import AzureDiskUnattachedDescription from '../../containers/AzureDiskUnattached/components/Description';
import AzureIdleLoadBalancerDescription from '../../containers/AzureIdleLoadBalancer/components/Description';
import AzureVmIdleDescription from '../../containers/AzureVmIdle/components/Description';
import AzureAppRightSizingDescription from '../../containers/AzureAppRightSizing/components/Description';
import AzureDbRiDescription from '../../containers/AzureDbRi/components/Description';
import AzureVmRightSizingDescription from '../../containers/AzureVmRightSizing/components/Description';
import AzureStorageDescription from '../../containers/AzureStorage/components/Description';
import AzureCosmosRightSizingDescription from '../../containers/AzureCosmosRightSizing/components/Description';
import AzureDiskTypeChangeDescription from '../../containers/AzureDiskTypeChange/components/Description';
import AzureReservedCapacityDescription from '../../containers/AzureReservedCapacity/components/Description';
import AzureSnapshotMigrationDescription from '../../containers/AzureSnapshotMigration/components/Description';
import AzureOutdatedSnapshotDescription from '../../containers/AzureOutdatedSnapshot/components/Description';
import AzureKustoUnusedDataDescription from '../../containers/AzureKustoUnusedData/components/Description';
/* eslint-disable max-len */
import AzureAppSerRsrvdCapacityDescription from '../../containers/AzureAppServiceReservedCapacity/components/Description';
import { RecommendationTypes } from '../../constants/recommendationsConstants';

// GCP ****
import GcpIpIdleDescription from '../../containers/GcpIPIdle/components/Description';
import GcpVmStoppedDescription from '../../containers/GcpVmStopped/components/Description';
import GcpVmIdleDescription from '../../containers/GcpVmIdle/components/Description';
import GcpUsageCommitmentDescription from '../../containers/GcpUsageCommitment/components/Description';
import GcpVmRightSizingDescription from '../../containers/GcpVmRightSizing/components/Description';
import GcpDiskUnattchedDescription from '../../containers/GcpDiskUnattched/components/Description';

class RecDescriptionFactory {
  static create(data) {
    let output;
    switch (data.type) {
      case RecommendationTypes.IP_PUBLIC_IPV4:
        output = IpPublicIpv4Description;
        break;
      case RecommendationTypes.ECS_FARGATE:
        output = EcsFargateDescription;
        break;
      case RecommendationTypes.RDS_CLASS_CHANGE:
        output = RdsClassChangeDescription;
        break;
      case RecommendationTypes.RDS_IOPS_CHANGE:
        output = RdsIopsChangeDescription;
        break;
      case RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS:
        output = CloudtrailDuplicateTrailsDescription;
        break;
      case RecommendationTypes.KMS_IDLE:
        output = KmsIdleDescription;
        break;
      case RecommendationTypes.KMS_OLD:
        output = KmsOldDescription;
        break;
      case RecommendationTypes.EC2_RI:
        output = RiDescription;
        break;
      case RecommendationTypes.EC2_SPOT:
        output = SpotDescription;
        break;
      case RecommendationTypes.EC2_REGION:
        output = RegionDescription;
        break;
      case RecommendationTypes.EC2_VERSION_UPGRADE:
        output = VuDescription;
        break;
      case RecommendationTypes.EC2_OPERATION_SYS:
        output = OsDescription;
        break;
      case RecommendationTypes.EC2_IP_UNATTACHED:
        output = IpDescription;
        break;
      case RecommendationTypes.EC2_IDLE:
        output = Ec2IdleDescription;
        break;
      case RecommendationTypes.EC2_EBS_UNATTACHED:
        output = EbsUnattachedDescription;
        break;
      case RecommendationTypes.EC2_IDLE_LOAD_BALANCER:
        output = IdleLoadBalancDescription;
        break;
      case RecommendationTypes.EC2_REGION_MIGRATION:
        output = RegionMigrationDescription;
        break;
      case RecommendationTypes.EC2_LOW_CPU_USAGE:
        output = Ec2LowCpuUsageDescription;
        break;
      case RecommendationTypes.DDB_IDLE:
        output = DynamoDbIdleDescription;
        break;
      case RecommendationTypes.RDS_RI:
        output = RdsRiDescription;
        break;
      case RecommendationTypes.RDS_VERSION_UPGRADE:
        output = RdsVuDescription;
        break;
      case RecommendationTypes.RDS_IDLE:
        output = RdsIdleDescription;
        break;
      case RecommendationTypes.S3_IDLE:
        output = S3IdleDescription;
        break;
      case RecommendationTypes.S3_VERSIONING:
        output = S3VersioningDescription;
        break;
      case RecommendationTypes.S3_STORAGE_CLASS:
        output = S3StorageClassDescription;
        break;
      case RecommendationTypes.S3_MULTIPART_UPLOAD:
        output = S3MultipartUploadDescription;
        break;
      case RecommendationTypes.EBS_TYPE_CHANGE:
        output = EbsTypeChangeDescription;
        break;
      case RecommendationTypes.ELASTICACHE_RI:
        output = ElasticacheRiDescription;
        break;
      case RecommendationTypes.OPEN_SEARCH_RI:
        output = OpenSearchRiDescription;
        break;
      case RecommendationTypes.EBS_UPGRADE:
        output = EbsUpgradeDescription;
        break;
      case RecommendationTypes.EBS_TYPE_SIZE_CHANGE:
        output = EbsTypeChangeDescription;
        break;
      case RecommendationTypes.EC2_SCHEDULE:
        output = Ec2ScheduleDescription;
        break;
      case RecommendationTypes.RDS_TYPE_CHANGE:
        output = RdsTypeChangeDescription;
        break;
      case RecommendationTypes.EC2_SAVINGS_PLANS:
        output = SavingsPlansDescription;
        break;
      case RecommendationTypes.EBS_OUTDATED_SNAPSHOT:
        output = EbsOutdatedSnapshotDescription;
        break;
      case RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER:
        output = Ec2UnnecessaryDataTransferDescription;
        break;
      case RecommendationTypes.EC2_STOPPED_INSTANCE:
        output = Ec2StoppedInstanceDescription;
        break;
      case RecommendationTypes.REDSHIFT_LOW_UTILIZATION:
        output = RedshiftLowUtilization;
        break;
      case RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION:
        output = ElasticsearchLowUtilization;
        break;
      case RecommendationTypes.ELASTICACHE_LOW_UTILIZATION:
        output = ElasticacheLowUtilization;
        break;
      case RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION:
        output = NatGatewayLowUtilization;
        break;
      case RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION:
        output = DocumentDbLowUtilization;
        break;
      case RecommendationTypes.NEPTUNE_LOW_UTILIZATION:
        output = NeptuneLowUtilization;
        break;
      case RecommendationTypes.KINESIS_LOW_UTILIZATION:
        output = KinesisLowUtilization;
        break;
      // AZURE *****
      case RecommendationTypes.AZURE_VM_RI:
        output = AzureVmRiDescription;
        break;
      case RecommendationTypes.AZURE_IP_UNATTACHED:
        output = AzureIpDescription;
        break;
      case RecommendationTypes.AZURE_VM_STOPPED:
        output = AzureVmStopped;
        break;
      case RecommendationTypes.AZURE_MARIA_DB_IDLE:
        output = AzureMariaDBIdle;
        break;
      case RecommendationTypes.AZURE_SQL_DB_IDLE:
        output = AzureSqlDbIdle;
        break;
      case RecommendationTypes.AZURE_COSMOS_DB_IDLE:
        output = AzureCosmosDbIdle;
        break;
      case RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE:
        output = AzurePostgresDbIdle;
        break;
      case RecommendationTypes.AZURE_MYSQL_DB_IDLE:
        output = AzureMysqlDbIdle;
        break;
      case RecommendationTypes.AZURE_DISK_UNATTACHED:
        output = AzureDiskUnattachedDescription;
        break;
      case RecommendationTypes.AZURE_IDLE_LOAD_BALANCER:
        output = AzureIdleLoadBalancerDescription;
        break;
      case RecommendationTypes.AZURE_VM_IDLE:
        output = AzureVmIdleDescription;
        break;
      case RecommendationTypes.AZURE_APP_RIGHT_SIZING:
        output = AzureAppRightSizingDescription;
        break;
      case RecommendationTypes.AZURE_DB_RI:
        output = AzureDbRiDescription;
        break;
      case RecommendationTypes.AZURE_VM_RIGHT_SIZING:
        output = AzureVmRightSizingDescription;
        break;
      case RecommendationTypes.AZURE_STORAGE:
        output = AzureStorageDescription;
        break;
      case RecommendationTypes.AZURE_COSMOS_RIGHT_SIZING:
        output = AzureCosmosRightSizingDescription;
        break;
      case RecommendationTypes.AZURE_DISK_TYPE_CHANGE:
        output = AzureDiskTypeChangeDescription;
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS:
        output = AzureReservedCapacityDescription;
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER:
        output = AzureReservedCapacityDescription;
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL:
        output = AzureReservedCapacityDescription;
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_PG:
        output = AzureReservedCapacityDescription;
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS:
        output = AzureReservedCapacityDescription;
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL:
        output = AzureReservedCapacityDescription;
        break;
      case RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH:
        output = AzureReservedCapacityDescription;
        break;
      case RecommendationTypes.AZURE_SNAPSHOT_MIGRATION:
        output = AzureSnapshotMigrationDescription;
        break;
      case RecommendationTypes.AZURE_OUTDATED_SNAPSHOT:
        output = AzureOutdatedSnapshotDescription;
        break;
      case RecommendationTypes.AZURE_KUSTO_UNUSED_DATA:
        output = AzureKustoUnusedDataDescription;
        break;
      case RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY:
        output = AzureAppSerRsrvdCapacityDescription;
        break;
      case RecommendationTypes.AZURE_DB_RESERVED_CAPACITY:
        output = AzureReservedCapacityDescription;
        break;
      // GCP *****
      case RecommendationTypes.GCP_IP_IDLE:
        output = GcpIpIdleDescription;
        break;
      case RecommendationTypes.GCP_VM_STOPPED:
        output = GcpVmStoppedDescription;
        break;
      case RecommendationTypes.GCP_VM_IDLE:
        output = GcpVmIdleDescription;
        break;
      case RecommendationTypes.GCP_USAGE_COMMITMENT:
        output = GcpUsageCommitmentDescription;
        break;

      case RecommendationTypes.GCP_VM_RIGHT_SIZING:
        output = GcpVmRightSizingDescription;
        break;
      case RecommendationTypes.GCP_DISK_UNATTACHED:
        output = GcpDiskUnattchedDescription;
        break;
      // DEFAULT *****
      default:
        output = undefined;
        break;
    }
    return output;
  }
}

/*
ip-unattached, ec2-idle, ebs-unattached, idle-load-balancer, region-migration
*/

export default RecDescriptionFactory;
