import React from 'react';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';

const LinkedAccountInfoIcon = ({ row }) =>
  row.derivedFrom?.length ? (
    <div className="d-flex justify-content-end pe-4">
      <InfoPopover>
        {row.derivedFrom?.[0]?.roleId
          ? 'This account is assigned to a sub-role. To remove it, go to the sub-role and remove it there.'
          : null}
        {row.derivedFrom?.[0]?.costCenterId
          ? `This account was assigned to the role through a Cost Center,
              you need to remove the Cost Center to detach it.`
          : null}
      </InfoPopover>
    </div>
  ) : null;

export default LinkedAccountInfoIcon;
