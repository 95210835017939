import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import styles from './recommPlanDetails.module.scss';
import RecommendedPlanCube from './RecommendedPlanCube';
import {PLAN_KEYS_SORT_ORDER} from "~/commitment/containers/spAnalyzerNew/utils/consts.js";

const RecommendedPlanDetails = ({ selectedPlan, setSelectedPlan, shareProperties }) => {
  const { preferences, recommendationPlanData: recPlanData, planDataByKey } = spAnalyzerContext();
  const [preferencePlanKey, setPreferencePlanKey] = useState(null);
    const recPerPlan = recPlanData?.recommendationPerPlan || {};

  useEffect(() => {
    const preferenceKey = Object.keys(recPerPlan).find(
      (key) =>
        recPerPlan[key]?.term === preferences?.term &&
        recPerPlan[key]?.paymentOption.toLowerCase() === preferences?.paymentOption.toLowerCase(),
    );
    setPreferencePlanKey(preferenceKey);
    setSelectedPlan(preferenceKey);
  }, [recPerPlan, preferences]);
  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsWrapper}>
        {Object.keys(recPerPlan || {}).sort((a, b) => PLAN_KEYS_SORT_ORDER.indexOf(a) - PLAN_KEYS_SORT_ORDER.indexOf(b))
          .map((key) => (
            <RecommendedPlanCube
              key={key}
              plan={planDataByKey?.[key] || recPerPlan[key]}
              planKey={key}
              preferencePlanKey={preferencePlanKey}
              shareProperties={shareProperties}
              selectedKey={selectedPlan}
              setSelectedPlan={(key) => {
                if (recPerPlan[key]) {
                  setSelectedPlan(key);
                }
              }}
              automationId={`${key}-plan-cube`}
            />
          ))}
      </div>
      <div className={styles.centerContainer}>
        <GenerateIcon iconName={ICONS.caretDown.name} className={styles.arrowIcon} />
      </div>
    </div>
  );
};

RecommendedPlanDetails.propTypes = {
    selectedPlan: PropTypes.string,
    setSelectedPlan: PropTypes.func.isRequired,
    shareProperties: PropTypes.object.isRequired,
};
export default RecommendedPlanDetails;
