import React from 'react';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import BusinessMappingViewpoints from './components/BusinessMappingViewpoints/BusinessMappingViewpoints';

const BusinessMapping = () => (
  <div className="ps-4 pe-4">
    <PageHeader title={PageNames.BUSINESS_MAPPING} />
    <BusinessMappingViewpoints />
  </div>
);

export default BusinessMapping;
