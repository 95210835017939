/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import CostComparisonChart from './CostComparisonChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createCostComparisonData = (rec) => {
    const compareData = [
      {
        name: 'Current EC2',
        'EC2 Instance': rec.currTotalCost,
      },
      {
        name: 'Suggested Spot',
        'Spot Instance': rec.recommTotalCost,
      },
    ];

    return compareData;
  };

  render() {
    const { rec } = this.props;
    if (!rec) {
      return <div>Loading</div>;
    }
    const CurrModelVsSuggestedData = this.createCostComparisonData(rec);
    return (
      <div className="project-summary__statistics">
        {this.state.update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div className="project-summary__statistic">
          <p className="project-summary__statistic-title">Cost Comparison</p>
          <br />
          <CostComparisonChart data={CurrModelVsSuggestedData} />
        </div>
      </div>
    );
  }
}
