import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import { TABS_IDS } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import { Routes } from 'shared/constants/routes';
import { OrganizationEntityCategory, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { usePermissionCategories } from 'usage/hooks/react-query/usePermissionCategories';
import CloudAccounts from 'users/containers/CloudAccounts/CloudAccounts';
import { TAB_PATHS } from './consts';
import Users from './Users/Users';
import Roles from './Roles/Roles';
import Channels from '../Channels';

const Organization = ({ usersStore, location, ...props }) => {
  const { fetchAccessibleCategories } = usePermissionCategories();
  const { data: permissionCategories, isLoading: isLoadingAccessibleCategories } = fetchAccessibleCategories();

  if (usersStore.isLoading || isLoadingAccessibleCategories) {
    return <Spinner />;
  }

  const navObjectsMap = new Map([
    // [TABS_IDS.COST_CENTER, { id: 0, name: 'Cost Centers', title: '', icon: 'listTree' }],
    [
      TABS_IDS.USERS,
      {
        id: 0,
        name: 'Users',
        title: '',
        icon: ICONS.mainUserRegular.name,
        path: TAB_PATHS.USERS,
        permissionCategories: [OrganizationEntityCategory.UsersManagement],
      },
    ],
    [
      TABS_IDS.ROLES,
      {
        id: 1,
        name: 'Roles',
        title: '',
        icon: ICONS.userGear.name,
        path: TAB_PATHS.ROLES,
        permissionCategories: [OrganizationEntityCategory.Roles],
      },
    ],
    [
      TABS_IDS.CLOUD_ACCOUNTS,
      {
        id: 2,
        name: 'Cloud Accounts',
        title: '',
        icon: 'cloud',
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
    [
      TABS_IDS.CHANNELS,
      {
        id: 3,
        name: 'Channels',
        title: '',
        icon: 'objectIntersect',
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
  ]);
  const navAccessibleObjectsMap = useMemo(
    () =>
      new Map(
        [...navObjectsMap.entries()].filter(([, value]) =>
          value.permissionCategories.every((category) => permissionCategories.includes(category)),
        ),
      ),
    [permissionCategories],
  );

  const componentsMap = new Map([
    // [TABS_IDS.COST_CENTER, () => 'New Cost Centers'],
    [TABS_IDS.USERS, Users],
    [TABS_IDS.ROLES, Roles],
    [TABS_IDS.CLOUD_ACCOUNTS, CloudAccounts],
    [TABS_IDS.CHANNELS, Channels],
  ]);

  const getArrOfComponents = (tabOrderArray) =>
    tabOrderArray.map((tabId) => {
      const C = componentsMap.get(tabId);
      return (
        <TableHeaderProvider>
          <C key={tabId} {...props} usersStore={usersStore} location={location} />
        </TableHeaderProvider>
      );
    });

  const defaultAccountIndex = location.state?.defaultTabId
    ? [...navObjectsMap.keys()].indexOf(location.state?.defaultTabId)
    : location.defaultAccountIndex || location.state?.defaultAccountIndex;

  const lastPathSegment = window.location.pathname.split('/').pop();
  const tabId = [...navObjectsMap.values()].find((o) => o.path === lastPathSegment)?.id;

  return (
    <Container>
      <PageHeader title={PageNames.ORGANIZATION} />
      <Row>
        <ColoredTabs
          onTabChange={(tabId) => {
            const tab = navObjectsMap.values().find((t) => t.id === tabId);
            window.history.pushState(null, null, `${Routes.ACCOUNT}/${tab.path}`);
          }}
          arrOfNavItems={[...navAccessibleObjectsMap.values()]}
          defaultAccountIndex={defaultAccountIndex ?? tabId}
        >
          {getArrOfComponents([...componentsMap.keys()])}
        </ColoredTabs>
      </Row>
    </Container>
  );
};

Organization.propTypes = {
  history: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
};

export default observer(Organization);
