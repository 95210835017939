import React from 'react';
import PropTypes from 'prop-types';
import { palette } from 'shared/constants/colorsConstants';
import Modal from 'shared/components/andtComponents/Modal';
import { ReactComponent as CircleCheckedSolid } from 'shared/img/icons/circle-check-solid.svg';
import { ReactComponent as CircleXmark } from 'shared/img/icons/circle-xmark-solid.svg';
import styles from './processStatusModal.module.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  processes: PropTypes.array.isRequired,
};

const defaultProps = {
  isOpen: false,
};

const PROCESS_STATUSES = {
  LOADING: 0,
  SUCCEEDED: 1,
  FAILED: 2,
};

const getRectUnit = (rotate, color, begin, key) => (
  <g key={key} transform={`rotate(${rotate} 50 50)`}>
    <rect x="46.5" y="24" rx="9.3" ry="4.8" width="7" height="12" fill={color}>
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin={begin} repeatCount="indefinite" />
    </rect>
  </g>
);

const getAllRect = (color) => {
  const count = 10;
  return [...new Array(count)].map((_, idx) =>
    getRectUnit((360 / count) * +idx, color, `-${0.9 - +idx / count}s`, `key-${idx}`),
  );
};

const Spinner = () => {
  const innerSvg = getAllRect(palette.gray[500]);
  return (
    <svg width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{ background: 'none' }}>
      {innerSvg}
    </svg>
  );
};

const getStatusIcon = (status) => {
  if (status === PROCESS_STATUSES.FAILED) {
    return <CircleXmark className={`${styles.icon} ${styles.failed}`} />;
  }
  if (status === PROCESS_STATUSES.SUCCEEDED) {
    return <CircleCheckedSolid className={`${styles.icon} ${styles.success}`} />;
  }
  return <Spinner />;
};

const ProcessStatusModal = ({ isOpen, onClose, processes }) => (
  <Modal open={isOpen} onClose={onClose} title="Multi Accounts Customer Process">
    <div className="d-flex flex-column justify-content-center">
      {processes.map((process) => (
        <div className="d-flex align-items-center">
          <span className="me-2">{process.title}:</span>
          <div className="d-flex align-items-center">{getStatusIcon(process.status)}</div>
        </div>
      ))}
    </div>
  </Modal>
);

ProcessStatusModal.propTypes = propTypes;
ProcessStatusModal.defaultProps = defaultProps;
export default ProcessStatusModal;
