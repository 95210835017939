import React, { useState } from 'react';
import classnames from 'classnames';
import HOLIDAYS from '../constants';
import { ReactComponent as SpiderIcon } from '../assets/halloween/spider.svg';
import { ReactComponent as NetIcon } from '../assets/halloween/net.svg';
import styles from '../HolidayThemedIcons.module.scss';

const HolidayUnderHeaderRightIcon = ({ holiday }) => {
  const [animationActive, setAnimationActive] = useState(false);
  const [firstAnimation, setFirstAnimation] = useState(true);
  switch (holiday) {
    case HOLIDAYS.HALLOWEEN:
      return (
        <span className={styles.underHeaderRightIcon}>
          <NetIcon className={styles.net} />
          <div
            onAnimationEnd={() => {
              setAnimationActive(false);
              setFirstAnimation(false);
            }}
            className={classnames({
              [styles.thread]: true,
              [styles.threadFirstActive]: firstAnimation,
              [styles.threadActive]: animationActive,
            })}
          />
          <SpiderIcon
            className={classnames({
              [styles.spider]: true,
              [styles.spiderFirstActive]: firstAnimation,
              [styles.spiderActive]: animationActive,
            })}
            onClick={() => {
              setAnimationActive(true);
            }}
          />
        </span>
      );
    default:
      return null;
  }
};

export default HolidayUnderHeaderRightIcon;
