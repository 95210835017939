import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import classes from './heatMap.module.scss';

const HeatMapSummaryItem = ({ summaryItem }) => {
  const { main } = summaryItem;
  const { additional } = summaryItem;

  const displayItem = main?.number > 0 || additional?.value > 0;

  const getValueColorClass = () => {
    if (!additional.isValueColored) {
      return '';
    }
    if (main?.isPotentialValue) {
      return classes.boldValueColored;
    }
    return classes.valueColored;
  };
  return summaryItem && displayItem ? (
    <div className={classes.summaryItem}>
      {main ? (
        <Tooltip title={main.tooltip} placement="top" disabled={!main.tooltip}>
          <div className={`${classes.main} ${main.isPotentialValue && classes.potential}`}>
            <div className={classes.title}>{main.title}</div>
            <div className={classes.number}>
              <div>{main.unit}</div>
              <div>{main.number?.toLocaleString()}</div>
              {main.count >= 0 ? <div className={classes.count}>({main.count})</div> : null}
            </div>
          </div>
        </Tooltip>
      ) : null}
      {additional ? (
        <Tooltip title={additional.tooltip} placement="top" disabled={!additional.tooltip}>
          <div className={classes.additional}>
            <div className={classes.labelAndIcon}>
              {additional.icon ? (
                <GenerateIcon
                  iconName={additional.icon}
                  className={classes.icon}
                  style={{ color: additional.iconColor }}
                />
              ) : null}
              <div className={classes.title}>{additional.title}</div>
            </div>
            {additional.value >= 0 ? (
              <div className={`${classes.value} ${getValueColorClass()}`}>
                {additional.unit && additional.isUnitOnTheLeft && <span>{`${additional.unit} `}</span>}
                {additional.value?.toLocaleString()}
                {additional.unit && !additional.isUnitOnTheLeft && <span>{additional.unit}</span>}
              </div>
            ) : null}
            {additional.count >= 0 ? <div className={classes.count}>({additional.count})</div> : null}
          </div>
        </Tooltip>
      ) : null}
    </div>
  ) : null;
};

HeatMapSummaryItem.propTypes = {
  summaryItem: PropTypes.object.isRequired,
};

export default HeatMapSummaryItem;
