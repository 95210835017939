/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

// TODO update description
const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      {`AWS charges for inter-Availability Zones data transfer between Amazon EC2 instances within the same region.
This resource has inter-Availability Zones Data Transfer and if possible you should consider to co-located it within
the same Availability Zone to avoid unnecessary data transfer charges.`}
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
