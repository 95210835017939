import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CostTrackingConstants, ItemsOptionsValues } from 'usage/constants/costAndUsageConstants';
import { AwsCommonFields } from '../../../constants/awsConstants';
import { modifyRegionNameToDisplay } from '../../../utils/awsUtils';
import LabelCoordinator from '../../../modules/labelCoordinator';
import { StringToColor } from '../../../utils/graphicUtil';
import {
  DEFAULT_SELECTED_FILTERS_LENGTH,
  LINECHART_SELECTED_FILTERS_LENGTH,
} from '../../ChartKeysFilter/legendKeysFilterHandler';
import styles from './LegendsPanel.module.scss';

const prepareDisplayName = (groupBy, info) => {
  if (!info) {
    return '';
  }
  const { name } = info;
  if (groupBy?.startsWith(AwsCommonFields.ACCOUNT_TAGS)) {
    return name;
  }
  if (groupBy === AwsCommonFields.REGION) {
    return modifyRegionNameToDisplay(groupBy, info.name);
  }
  return LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', name);
};

const Name = ({ info, selected, handleFavourite, groupBy, color }) => (
  <button type="button" className={`${styles.legend} btn-no-style`} onClick={() => handleFavourite(info)}>
    <li
      style={selected ? { backgroundColor: color, borderColor: color } : { borderColor: color }}
      className={styles.legendIcon}
    />
    <span className={styles.legendText}>{prepareDisplayName(groupBy, info)}</span>
  </button>
);

Name.propTypes = {
  info: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  handleFavourite: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

const LegendsPanel = ({
  dataKeys,
  uncheckedKeys,
  handleKeysUpdate,
  isShowOthers,
  handleShowOthersChange,
  groupBy,
  isLineChart,
  numberOfItems,
}) => {
  const legendsSize = (() => {
    const limit = isLineChart ? LINECHART_SELECTED_FILTERS_LENGTH : DEFAULT_SELECTED_FILTERS_LENGTH;
    if (numberOfItems !== ItemsOptionsValues.ALL) {
      return Math.min(limit, +numberOfItems);
    }
    return limit;
  })();
  useEffect(() => {
    if (legendsSize < dataKeys.length) {
      handleKeysUpdate([...dataKeys.slice(legendsSize).map((key) => key.name)]);
    } else {
      handleKeysUpdate([]);
    }
  }, [dataKeys]);
  const handleUpdateLegend = (info) => {
    const isChecked = !uncheckedKeys.includes(info.name);
    if (isChecked) {
      handleKeysUpdate([...uncheckedKeys, info.name]);
    } else {
      handleKeysUpdate(uncheckedKeys.filter((a) => a !== info.name));
    }
  };
  const slicedLegends = dataKeys.slice(0, legendsSize);
  return (
    <div>
      {slicedLegends.map((key) => (
        <Name
          key={key.name}
          groupBy={groupBy}
          handleFavourite={handleUpdateLegend}
          info={key}
          color={StringToColor.next(key.name)}
          selected={!(uncheckedKeys || []).includes(key.name)}
        />
      ))}
      {(uncheckedKeys || []).length > 0 || isShowOthers ? (
        <Name
          handleFavourite={() => handleShowOthersChange(!isShowOthers)}
          info={{
            name: CostTrackingConstants.OTHERS,
          }}
          color={CostTrackingConstants.OTHERS_NAME_COLOR}
          selected={isShowOthers}
        />
      ) : null}
    </div>
  );
};

LegendsPanel.propTypes = {
  dataKeys: PropTypes.array.isRequired,
  uncheckedKeys: PropTypes.array.isRequired,
  handleKeysUpdate: PropTypes.func.isRequired,
  isShowOthers: PropTypes.bool.isRequired,
  handleShowOthersChange: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  numberOfItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLineChart: PropTypes.bool.isRequired,
};

export default LegendsPanel;
