import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from './Button';
import styles from './pagination.module.scss';

/**
 * Pagination component for navigating through pages.
 *
 * For cursor-based pagination (DynamoDB): provide `totalPages` only after retrieving all pages and `isNextPageLoading`
 * should be provided to disable the next page button indicate if the next page is loading.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.currPageIdx - The current page index (first page is 0).
 * @param {function} props.onPageChange - The function to call when changing pages.
 * @param {number} [props.totalPages] - The total number of pages.
 * @param {boolean} props.isNextPageLoading
 * - Indicates if the next page is currently loading (used for cursor-based pagination).
 */
const Pagination = ({ currPageIdx, onPageChange, totalPages, isNextPageLoading }) => (
  <div className={styles.pagerWrapper}>
    <Button
      onClick={() => onPageChange(currPageIdx - 1)}
      icon={() => <GenerateIcon iconName={ICONS.chevronLeft.name} />}
      automation-id="previous-page"
      className={styles}
      disabled={currPageIdx === 0}
      isTextButton
    />
    <span className={styles.pager}>
      Page {currPageIdx + 1} {totalPages ? `of ${totalPages}` : ''}
    </span>
    <Button
      onClick={() => onPageChange(currPageIdx + 1)}
      icon={() => <GenerateIcon iconName={ICONS.chevronRight.name} />}
      automation-id="next-page"
      className={styles}
      isLoading={isNextPageLoading}
      isTextButton
      disabled={totalPages != null && currPageIdx + 1 >= totalPages}
      overrideStyles={{
        borderRadius: '0 6px 6px 0',
      }}
    />
  </div>
);

Pagination.propTypes = {
  currPageIdx: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  isNextPageLoading: PropTypes.bool,
};

Pagination.defaultProps = {
  totalPages: null,
  isNextPageLoading: false,
};

export default Pagination;
