/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul>
      <li>Using the AWS CLI:</li>
      <ol>
        <li>
          {'Create an AMI from the EC2 instance'}
          <CodeBlock
            text={
              'aws ec2 create-image --instance-id {{resourceId}} --name "ec2 name" --description "region migration" Output: { "ImageId": "{{amiId}}" }'
            }
            variables={{
              amiId: 'ami-ID',
              resourceId: rec.resourceId,
            }}
          />
          <br />
        </li>
        <li>
          {'Copy the AMI to the new region'}
          <CodeBlock
            text={
              'aws ec2 copy-image --source-image-id {{amiId}} --source-region {{region}} --region {{recommRegion}} --name "My server" Output: {"ImageId": "ami-5731123e"}'
            }
            variables={{
              amiId: 'ami-ID',
              region: rec.region,
              recommRegion: rec.recommRegion,
            }}
          />
          <br />
        </li>
      </ol>
      <li>
        Using the AWS Console:
        <NavLink
          rel="noopener noreferrer"
          target="_blank"
          href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/CopyingAMIs.html"
        >
          Changing EC2 Instance Region using the AWS Console
        </NavLink>
      </li>
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
