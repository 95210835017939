import { CostTrackingConstants, CostUsageStates } from 'usage/constants/costAndUsageConstants';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class OperationDataState extends BaseCostUsageDataState {
  // eslint-disable-next-line class-methods-use-this
  getNewDataStateType() {
    const newDataState = CostUsageStates.RESOURCE;
    return newDataState;
  }

  getBreadCrumbLabel = (value, date) => {
    let dateString = null;
    if (this.context.getUrlState().currPeriodGranLevel === CostTrackingConstants.GRAN_LEVEL_DAILY) {
      dateString = createDateDisplayStr(date);
    }
    return `${LabelCoordinator.getServiceNameDisplayValue(value)} ${dateString ? `(${dateString})` : ''}`;
  };

  // eslint-disable-next-line class-methods-use-this
  getDataStateType() {
    return CostUsageStates.OPERATION;
  }
}
