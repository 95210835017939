/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import Panel from 'shared/components/Panel';

export default function GcpAppEngine() {
  return (
    <Container>
      <PageHeader title={PageNames.GcpAppEngine} showDate />
      <Panel divider title="Google Cloud App Engine">
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Fully managed serverless application platform</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Open and familiar languages and tools</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Just add code</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Pay only for what you use</span>
          </h5>
        </p>
      </Panel>
    </Container>
  );
}
