export default function checkFeatureFlag(usersStore, featureName, enabledUserTypes) {
  const currAuthUser = (usersStore.usersModel.users || []).find(({ userKey }) => userKey === usersStore.authUserKey);
  const authUserType = currAuthUser ? currAuthUser.userType : null;
  const accountFeatures = usersStore.getCurrentDisplayedAccountFeatures(usersStore.currDispUserAccountKey);
  const enabledUserTypesArr = enabledUserTypes || [];
  if (enabledUserTypesArr.includes(authUserType) || (accountFeatures || []).includes(featureName)) {
    return true;
  }
  return false;
}
