import React, { useEffect, useState } from 'react';
import PageHeader from 'shared/components/PageHeader';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import style from './slackIntegration.module.scss';
import TestSlackButton from './testSlackButton';

const MESSAGE = {
  SUCCESS: 'Integration for Slack succeeded',
  FAILURE: 'Integration for Slack failed',
};

// eslint-disable-next-line react/prop-types
export default function SlackIntegration({ usersStore }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const processParams = () => {
      const isSuccessFromURL = params.get('success') === 'true';
      setIsSuccess(isSuccessFromURL);
      setIsLoading(false);
      setMounted(true);
    };
    if (!mounted && usersStore.usersModel.users.length > 0) {
      processParams();
    }
  }, [usersStore.usersModel.users]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className={style.pageTitleWrapper}>
        <PageHeader title={isSuccess ? MESSAGE.SUCCESS : MESSAGE.FAILURE} />
      </div>
      <div>
        {isSuccess ? (
          <div>
            <TestSlackButton />
          </div>
        ) : null}
      </div>
    </div>
  );
}

SlackIntegration.propTypes = {
  usersStore: PropTypes.object.isRequired,
};
