/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { EbsUnattachedRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class EbsUnattachedRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new EbsUnattachedRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_EBS_UNATTACHED;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EBS Unattached`;
  }

  build() {
    super.build();
    const {
      detached_ebs_id: detachedEbsId,
      ebs_type: ebsType,
      zone_tag: zoneTag,
      ebs_size: ebsSize,
      ebs_name: ebsName,
      ebs_iops: iops,
      potential_savings: potentialSavings,
    } = this._rawRecommendation;
    this._recommendation.region = zoneTag;
    this._recommendation.detachedEbsId = detachedEbsId;
    this._recommendation.ebsType = ebsType;
    this._recommendation.ebsSize = ebsSize;
    this._recommendation.ebsName = ebsName;
    this._recommendation.iops = iops;

    this._recommendation.potentialSavings = potentialSavings;
  }
}
