import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { segmentPageEvent } from 'shared/modules/segmentAndAptrinsicHandler';

class MainWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    history: PropTypes.object.isRequired,
    childProps: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { childProps } = this.props;
    if (prevProps.history.location.pathname !== this.oldPath) {
      segmentPageEvent(childProps.usersStore);
    }
  }

  oldPath = this.props.history.location.pathname;

  render() {
    const { children } = this.props;
    return (
      <div className="theme-light_dark_outer_layout">
        <div className="wrapper" automation-id="wrapper">
          {children}
        </div>
      </div>
    );
  }
}

export default withRouter(MainWrapper);
