import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { MoreVertical } from 'react-feather';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import classes from './creditActions.module.scss';

const propTypes = {
  row: PropTypes.object.isRequired,
  onCloneCredit: PropTypes.func.isRequired,
  onDeleteCredit: PropTypes.func.isRequired,
};

const CreditActions = ({ row, onCloneCredit, onDeleteCredit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleMenu = (value) => {
    setAnchorEl(value);
  };
  return (
    <div automation-id="credits-actions">
      <IconButton
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={(e) => toggleMenu(e.currentTarget)}
        size="large"
      >
        <MoreVertical size={22} color="black" />
      </IconButton>
      <Menu
        classes={{ list: classes.menu }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => toggleMenu(null)}
      >
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => onCloneCredit(row.id)}
          automation-id="credis-actoions-clone"
        >
          <div>
            <IconFromPng className="me-2" icon={ICONS.clone} size="15px" matchHeight="15px" />
            <span className={classes.menuItem}>Clone</span>
          </div>
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => onDeleteCredit(row.id)}
          automation-id="credis-actoions-delete"
        >
          <div>
            <IconFromPng className="me-2" icon={ICONS.deleteIcon} size="12px" matchHeight="14px" />
            <span className={classes.menuItem}>Delete Credit</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

CreditActions.propTypes = propTypes;
export default CreditActions;
