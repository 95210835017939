/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { GcpDiskUnattachedRecommendation } from 'recommendations/models/recommTypes';
import GcpCostRecommendationBuilder from 'recommendations/models/builders/gcpCostRecommBuilder';

export default class GcpDiskUnattachedCostRecommendationBuilder extends GcpCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new GcpDiskUnattachedRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.GCP_DISK_UNATTACHED;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Disk Unattached`;
  }

  build() {
    super.build();
    const {
      unattached_disk_id: unattachedDiskId,
      disk_sku_name: diskSkuName,
      zone_tag: zoneTag,
      disk_size_gb: diskSize,
      disk_name: diskName,
      disk_type: diskType,
      resource_group: resourceGroup,
      resource_id: resourceId,
      disk_status: diskStatus,
      project: projectName,
    } = this._rawRecommendation;
    this._recommendation.region = zoneTag;
    this._recommendation.unattachedDiskId = unattachedDiskId;
    this._recommendation.diskSkuName = diskSkuName;
    this._recommendation.diskSize = diskSize;
    this._recommendation.diskName = diskName;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.resourceId = resourceId;
    this._recommendation.diskType = diskType;
    this._recommendation.diskStatus = diskStatus;
    this._recommendation.projectName = projectName;
  }
}
