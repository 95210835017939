import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import { strToTitleCase } from 'shared/utils/strUtil';
import BreakEvenGraph from './BreakEvenGraph';
import CostComparisonChart from './CostComparisonChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  getBreakEvenData = ({ yearlyCost }) => {
    const monthSum = yearlyCost.currTotalCost / 12;
    const riCost = yearlyCost.recommTotalCost;
    const breakEvenData = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 12; ++i) {
      breakEvenData.push({ name: i, 'RI Cost': riCost, 'On-Demand Cost': (monthSum * i).toFixed(1) });
    }

    return breakEvenData;
  };

  getCostComparisonData = (rec) => {
    const modifiedAdditionalRecsData = Object.keys(rec.additionalRecommPlans).map((key) => ({
      name: strToTitleCase(key).replace('Standard', ''),
      [strToTitleCase(key).replace('Standard', '')]: rec.additionalRecommPlans[key] * 12,
    }));
    return [
      {
        name: 'On-Demand (Current Plan)',
        'On-Demand (Current Plan)': rec.currTotalCost,
      },
      ...modifiedAdditionalRecsData,
    ];
  };

  render() {
    const { rec } = this.props;
    if (!rec) {
      return <div>Loading</div>;
    }
    const costComparisonData = this.getCostComparisonData(rec).sort((a, b) => b[b.name] - a[a.name]);
    const breakEvenData = this.getBreakEvenData(rec);
    return (
      <div>
        {this.state.update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Cost Comparison</p>
          <br />
          <CostComparisonChart data={costComparisonData} />
        </div>
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Break-Even Point</p>
          <br />
          <BreakEvenGraph breakEvenData={breakEvenData} />
        </div>
      </div>
    );
  }
}
