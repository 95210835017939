import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const RolesContext = createContext();

export const RolesProvider = ({ children }) => {
  const [searchText, setSearchText] = useState();
  const [tableFilters, setTableFilters] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [paginationToken, setPaginationToken] = useState(null);
  const [backwardPaginationToken, setBackwardPaginationToken] = useState(null);

  return (
    <RolesContext.Provider
      value={{
        backwardPaginationToken,
        isAllExpanded,
        setIsAllExpanded,
        paginationToken,
        showFilters,
        setShowFilters,
        searchText,
        selectedRows,
        setSearchText,
        setSelectedRows,
        setBackwardPaginationToken,
        setPaginationToken,
        setTableFilters,
        tableFilters,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRolesContext = () => useContext(RolesContext);
