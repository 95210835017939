import React from 'react';
import DescriptionWrapper from 'recommendations/shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>
      Your ECS Fargate containers may need tuning to improve utilization and cost. Refer to AWS documentation for more
      details
    </p>
  </DescriptionWrapper>
);

export default Description;
