import toast from 'shared/components/andtComponents/Toast';

export const waitForToast = async (message) =>
  new Promise((resolve) => {
    toast.error(message, {
      onClose: () => {
        resolve();
      },
      autoClose: false,
    });
  });
