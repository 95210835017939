/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { kFormatterNoDecimal } from 'shared/utils/strUtil';
import { StringToColor } from 'shared/utils/graphicUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import PieChartBasic from './PieChartBasic';

const PieChartWithTotalSummary = (props) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const { data, height, dontShowLegendForTitle } = props;
  const prepareData = (dataObj) => {
    let isAbsValues = false;
    const keys = dataObj ? Object.keys(dataObj) : undefined;
    const preparedData = keys
      ? keys.map((key) => {
          const tempObj = { value: Math.abs(+dataObj[key]), name: key, fill: StringToColor.next(key) };
          return tempObj;
        })
      : [];
    const arrValues = Object.values(dataObj).map((el) => +el);
    isAbsValues = arrValues.some((el) => el < 0);
    return { data: preparedData, isAbsValues };
  };
  const { totalCost, chartTitle, ...rest } = data || {};
  return !data ? null : (
    <Col md={12} lg={12} xl={12} sm={12} xs={12}>
      <p style={{ 'font-size': '1.2em', width: '100%', 'text-align': 'center' }}>
        <span>{chartTitle}</span>
      </p>
      <div style={{ position: 'relative' }}>
        <p
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: 'calc(55%)',
            width: '100%',
            textAlign: 'center',
            // lineHeight: '2em',
            // fontSize: '1.5em',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              fontSize: '1em',
            }}
          >
            {chartTitle === 'Total Savings By Service' ? 'Total Savings' : 'Total cost'}
          </span>
          <b style={{ display: 'inline-block', fontSize: '1.5em' }}>
            {getCurrencyNumber(kFormatterNoDecimal(+totalCost))}
          </b>
        </p>
        <PieChartBasic
          //   chartId={finalPieChartId}
          data={prepareData(rest).data}
          height={height}
          isAbsValues={prepareData(rest).isAbsValues}
          showLegend={chartTitle !== dontShowLegendForTitle}
        />
      </div>
    </Col>
  );
};

PieChartWithTotalSummary.propTypes = {
  data: PropTypes.object.isRequired,
  customToolTip: PropTypes.object,
  height: PropTypes.number,
  dontShowLegendForTitle: PropTypes.string,
};
PieChartWithTotalSummary.defaultProps = {
  height: 300,
  customToolTip: undefined,
  dontShowLegendForTitle: undefined,
};

export default PieChartWithTotalSummary;
