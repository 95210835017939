/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RegionRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class RegionEc2CostRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RegionRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_REGION;
  }

  buildRecommTitle() {
    const { suggested_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action} for EC2 Instances`;
  }

  build() {
    const {
      region_name: regionName,
      suggested_region_name: recommRegionName,
      suggested_region_tag: recommRegion,
      rate_per_hour_suggested: recommRatePerHour,
      service_name: service,
      resource_id: resourceId,
      suggested_action: action,
    } = this._rawRecommendation;

    this._recommendation.resourceId = resourceId;
    this._recommendation.regionName = regionName;
    this._recommendation.recommRegionName = recommRegionName;
    this._recommendation.recommRegion = recommRegion;
    this._recommendation.service = service;
    this._recommendation.recommRatePerHour = recommRatePerHour;
    this._recommendation.action = action;

    super.build();
  }
}
