/* allows to use textMaps in a i18n-like way */

export const getFakeT =
  <T>(textMap: T) =>
  (key: keyof T, ...args) => {
    if (!textMap[key]) {
      return key;
    }
    if (typeof textMap[key] === 'string' || Array.isArray(textMap[key])) {
      return textMap[key];
    }
    // @ts-expect-error: safely call the handler
    return textMap[key]?.(...args);
  };
