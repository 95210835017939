import { cloneDeep, isEqual } from 'lodash';
import {
  CLOSED_AND_DONE_RECS_DATES_FILTERS,
  CLOSED_AND_DONE_RECS_DATES_OPERATOR,
  DEFAULT_RECS_DATE_RANGE,
  DROPDOWN_ADDITIONAL_FILTERS,
  DROPDOWN_MAIN_FILTERS,
  FILTERS,
  STATUS_FILTER_PRESETS,
} from 'recommendationsNew/consts';

const initialFiltersState = {
  [FILTERS.STATUS_FILTER.id]: STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS.id,
  [FILTERS.IS_OPEN.id]: true,
  [FILTERS.USER_STATUS.id]: { done: false, excluded: false },
  [FILTERS.OPEN_RECS_CREATION_DATE.id]: DEFAULT_RECS_DATE_RANGE,
  [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: {
    [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: DEFAULT_RECS_DATE_RANGE,
    [CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR]: CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND,
  },
};

const historyViewFiltersState = {
  [FILTERS.STATUS_FILTER.id]: STATUS_FILTER_PRESETS.ACTUAL_SAVINGS.id,
  [FILTERS.IS_OPEN.id]: false,
  [FILTERS.USER_STATUS.id]: { done: true, excluded: false },
  [FILTERS.OPEN_RECS_CREATION_DATE.id]: DEFAULT_RECS_DATE_RANGE,
  [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: {
    [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: DEFAULT_RECS_DATE_RANGE,
    [CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR]: CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND,
  },
};

const emptyFiltersState = {
  [FILTERS.IS_OPEN.id]: null,
  [FILTERS.USER_STATUS.id]: { done: null, excluded: null },
  [FILTERS.OPEN_RECS_CREATION_DATE.id]: DEFAULT_RECS_DATE_RANGE,
  [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: {
    [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: DEFAULT_RECS_DATE_RANGE,
    [CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR]: CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND,
  },
};

export const isFiltersEqual = (filters, filtersToCompareTo) => {
  const tmpFilters = Object.keys(filters)
    .filter(
      (key) =>
        Object.prototype.hasOwnProperty.call(filtersToCompareTo, key) ||
        (filters[key] !== undefined && !(Array.isArray(filters[key]) && filters[key].length === 0)),
    )
    .reduce((obj, key) => ({ ...obj, [key]: filters[key] }), {});
  return isEqual(filtersToCompareTo, tmpFilters);
};

export const checkIfDateRangeFilterChanged = (filters) =>
  !isEqual(filters[FILTERS.OPEN_RECS_CREATION_DATE.id], initialFiltersState[FILTERS.OPEN_RECS_CREATION_DATE.id]) ||
  !isEqual(filters[FILTERS.CLOSED_AND_DONE_RECS_DATES.id], initialFiltersState[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]);

export const getInitialFiltersStateCopy = () => cloneDeep(initialFiltersState);
export const getEmptyFiltersStateCopy = () => cloneDeep(emptyFiltersState);
export const getHistoryFiltersViewStateCopy = () => cloneDeep(historyViewFiltersState);

export const isFiltersEqualToInitialState = (filters) => isFiltersEqual(filters, initialFiltersState);
export const isFilterEqualToEmptyState = (filters) => isFiltersEqual(filters, emptyFiltersState);
export const isFilterEqualToHistoryViewState = (filters) => isFiltersEqual(filters, historyViewFiltersState);

export const getFiltersForSavingOpportunities = (filters) => {
  const tmpFilters = cloneDeep(filters);
  tmpFilters[FILTERS.IS_OPEN.id] = true;
  tmpFilters[FILTERS.USER_STATUS.id] = { done: false, excluded: false };
  tmpFilters[FILTERS.STATUS_FILTER.id] = STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS.id;
  return tmpFilters;
};

export const getFiltersForWasteChart = (filters) => {
  const tmpFilters = cloneDeep(filters);
  tmpFilters[FILTERS.STATUS_FILTER.id] = STATUS_FILTER_PRESETS.CUSTOM_SETTINGS.id;
  tmpFilters[FILTERS.IS_OPEN.id] = null;
  tmpFilters[FILTERS.USER_STATUS.id] = { ...tmpFilters[FILTERS.USER_STATUS.id], done: null };
  tmpFilters[FILTERS.CLOSED_AND_DONE_RECS_DATES.id] = {
    ...tmpFilters[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
    [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: { ...tmpFilters[FILTERS.OPEN_RECS_CREATION_DATE.id] },
  };
  return tmpFilters;
};

export const getFiltersForDynamicFilterUnselectedValues = (filters) => {
  const tmpFilters = cloneDeep(filters);
  const dropDownFiltersToRemove = [
    ...Object.values(DROPDOWN_MAIN_FILTERS).map((filter) => filter.id),
    ...Object.values(DROPDOWN_ADDITIONAL_FILTERS).map((filter) => filter.id),
  ];
  Object.keys(tmpFilters).forEach((prop) => {
    if (dropDownFiltersToRemove.includes(prop)) {
      delete tmpFilters[prop];
    }
  });
  return tmpFilters;
};
