import React, { useCallback, useState } from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import {
  RDS_COLUMN_WIDTHS,
  RDS_CURRENCIES_COLUMNS,
  RDS_DEFAULT_HIDDEN_COLUMN_NAMES,
  RDS_DEFAULT_SORTING,
  RDS_TABLE_COLUMN_EXTENSIONS,
  RDS_TABLE_COLUMNS,
  RDS_TABLE_CSV_COLUMNS,
} from 'usage/constants/usageConstants';
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import DevExpSearchAndVisibilityTable from './components/RdsDevExpTable';
import prepareTableColumns from './helpers/prepareTableColumns';
import { useRdsInstanceCosts } from './hooks/useRdsInstanceCosts';

const datePickerSyntexDates = (start, end) => {
  let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
  startDate = createTimeZoneAgnosticDateFromStr(startDate);
  endDate = createTimeZoneAgnosticDateFromStr(endDate);
  return { startDate, endDate };
};

const RdsUsagePage = () => {
  const initialDateRange = datePickerSyntexDates(null, null);
  const [startDate, setStartDate] = useState(initialDateRange.startDate);
  const [endDate, setEndDate] = useState(initialDateRange.endDate);

  const { fetchRdsInstanceCosts } = useRdsInstanceCosts();
  const { data: rdsData, isLoading } = fetchRdsInstanceCosts(buildStartAndEndDate(startDate, endDate));

  const handleDateChange = useCallback(
    ({ startDate: newStartDate, endDate: newEndDate }) => {
      const start = newStartDate || startDate;
      let end = newEndDate || endDate;
      if (start > end && end !== null) {
        end = start;
      }
      const { startDate: startD, endDate: endD } = buildStartAndEndDate(start, end);
      setStartDate(startD);
      setEndDate(endD);
    },
    [buildStartAndEndDate, startDate, endDate],
  );

  return (
    <Container>
      <PageHeader title={`${PageNames.RDS_USAGE}`} showDate />
      <Card>
        <CardBody style={{ minHeight: '300px' }}>
          <Row style={{ marginLeft: '1px' }}>
            <DatePickerFilter
              onDateChange={handleDateChange}
              startDate={datePickerSyntexDates(startDate, null).startDate}
              endDate={datePickerSyntexDates(null, endDate).endDate}
              currPeriodGranLevel="month"
            />
          </Row>
          {isLoading ? (
            <Spinner />
          ) : (
            <Row>
              <DevExpSearchAndVisibilityTable
                data={rdsData}
                columns={prepareTableColumns(RDS_TABLE_COLUMNS, rdsData)}
                columnWidths={RDS_COLUMN_WIDTHS}
                tableColumnExtensions={RDS_TABLE_COLUMN_EXTENSIONS}
                defaultHiddenColumnNames={RDS_DEFAULT_HIDDEN_COLUMN_NAMES}
                defaultSorting={RDS_DEFAULT_SORTING}
                currenciesColumns={RDS_CURRENCIES_COLUMNS}
                csvColumns={RDS_TABLE_CSV_COLUMNS}
                csvTitle={`RDS_data_${DateFilter.month}_${DateFilter.year}`}
                isPaging
              />
            </Row>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default RdsUsagePage;
