import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export default class CheckBoxItem extends PureComponent {
  static propTypes = {
    ContainerProps: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: props.ContainerProps.checked,
      id: props.ContainerProps.id,
      name: props.ContainerProps.name,
    };
  }

  componentDidUpdate(prevProps) {
    const { ContainerProps } = this.props;
    if (prevProps.ContainerProps !== ContainerProps) {
      const { checked, id, name } = ContainerProps;
      this.setState({ checked, id, name });
    }
  }

  onBoxCheck = (id) => () => {
    let { checked } = this.state;
    checked = !checked;
    this.setState({ checked }, () => {
      this.props.ContainerProps.handler(id);
    });
  };

  render() {
    const { style } = this.props;
    const { checked, id, name } = this.state;
    const labelId = `checkbox-list-label-${id}`;
    return (
      <ListItem key={id} role={undefined} dense style={style} onClick={this.onBoxCheck(id)}>
        <ListItemIcon>
          <Checkbox
            id={id}
            edge="start"
            value="dense"
            color="primary"
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={name} />
      </ListItem>
    );
  }
}
