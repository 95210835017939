import { useState, useEffect } from 'react';

/*
TODO - replace the history prop by useHistory hook
just after upgrade react-router & react-router-dom
*/
const useStateParams = ({ history, initialState, paramsName, serialize, deserialize }) => {
  const search = new URLSearchParams(history.location.search);
  const existingValue = search.get(paramsName);
  const [state, setState] = useState(existingValue ? deserialize(existingValue) : initialState);

  useEffect(() => {
    if (existingValue && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue));
    }
  }, [existingValue]);

  const onChange = (s) => {
    setState(s);
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set(paramsName, serialize(s));
    const { pathname } = history.location;
    history.push({ pathname, search: searchParams.toString() });
  };

  return [state, onChange];
};

export default useStateParams;
