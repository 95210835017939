import { AZURE_ONBOARDING_STEPS, AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES } from './AzureOnboardingConstants';

export const handleNextScreen = (screen) => {
  switch (screen) {
    case AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return AZURE_ONBOARDING_STEPS.APP_REGISTRATION;
    // TODO: change once videos are ready
    // return isAutomatic ? AZURE_ONBOARDING_STEPS.CREATE_APP : AZURE_ONBOARDING_STEPS.CREATE_APP;
    case AZURE_ONBOARDING_STEPS.CREATE_APP:
      return AZURE_ONBOARDING_STEPS.APP_REGISTRATION;
    case AZURE_ONBOARDING_STEPS.APP_REGISTRATION:
      return AZURE_ONBOARDING_STEPS.BILLING_EXPORT;
    case AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT:
      return AZURE_ONBOARDING_STEPS.PROCESS_DATA;
    case AZURE_ONBOARDING_STEPS.BILLING_EXPORT:
      return AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT;
    default:
      return screen;
  }
};

export const handlePrevScreen = (screen) => {
  switch (screen) {
    case AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return screen;
    case AZURE_ONBOARDING_STEPS.CREATE_APP:
      return AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE;
    case AZURE_ONBOARDING_STEPS.APP_REGISTRATION:
      return AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE;
    case AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT:
      return AZURE_ONBOARDING_STEPS.BILLING_EXPORT;
    case AZURE_ONBOARDING_STEPS.BILLING_EXPORT:
      return AZURE_ONBOARDING_STEPS.APP_REGISTRATION;
    case AZURE_ONBOARDING_STEPS.PROCESS_DATA:
      return AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT;
    default:
      return screen;
  }
};

export const getPageForPausedOnboarding = (onboarding) => {
  const {
    validationStatus,
    accountSetupStatus,
    userManagementStatus,
    validationShouldStart,
    invoiceShouldStart,
    invoiceStatus,
    validationStarted,
  } = onboarding;
  if (
    ['SUCCESS', 'FAILED', 'RUNNING'].includes(invoiceStatus?.status) ||
    (userManagementStatus?.status === 'SUCCESS' && invoiceShouldStart)
  ) {
    if (invoiceShouldStart && invoiceStatus?.status === 'RUNNING') {
      return AZURE_ONBOARDING_STEPS.PROCESS_DATA;
    }
    return AZURE_ONBOARDING_STEPS.PROCESS_DATA;
  }
  if (accountSetupStatus?.status === 'RUNNING' || userManagementStatus?.status === 'RUNNING') {
    return AZURE_ONBOARDING_STEPS.PROCESS_DATA;
  }
  const isRunning = validationStatus?.status === 'RUNNING';
  if (validationStatus?.status === 'SUCCESS') {
    return AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT;
  }
  if ((validationShouldStart && isRunning) || validationStarted) {
    return AZURE_ONBOARDING_STEPS.BILLING_EXPORT;
  }
  if (validationShouldStart && ['FAILED'].includes(validationStatus?.status)) {
    return AZURE_ONBOARDING_STEPS.BILLING_EXPORT;
  }
  return AZURE_ONBOARDING_STEPS.APP_REGISTRATION;
};

export const getPageTitleForPausedOnboarding = (onboarding) =>
  AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES[getPageForPausedOnboarding(onboarding)];

export const getAzureAccountTypeServer = (accountType) => accountType;
