import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import PieChartComp from 'shared/components/charts/FillPieChart';
import ChartKeysFilter from 'shared/components/ChartKeysFilter/ChartKeysFilter';

const getColorByKeyName = (keyName) => {
  let keyColor = '#FAC6C6'; // base is on-demand
  switch (keyName) {
    case 'Spot':
      keyColor = '#57B3F9';
      break;
    case 'Reserved':
      keyColor = '#86b582';
      break;
    case 'Savings Plans':
      keyColor = '#abd6c4';
      break;
    default:
      break;
  }
  return keyColor;
};

const prepareChartData = (dataObj) => {
  const keys = Object.keys(dataObj).filter((key) => key !== 'totalHours');
  return keys.map((key) => {
    let newKey = 'Spot';
    switch (key) {
      case 'onDemand':
        newKey = 'On-Demand';
        break;
      case 'ri':
        newKey = 'Reserved';
        break;
      case 'sp':
        newKey = 'Savings Plans';
        break;
      default:
        break;
    }
    return { [newKey]: dataObj[key].precent };
  });
};

const PricingMethodSummaryChart = ({ height, data, finalPieChartId }) => {
  const [favourites, setFavourites] = useState([]);
  const [filteredKeys, setFilteredKeys] = useState([]);
  const [filteredDataKeys, setFilteredDataKeys] = useState([]);
  const preparedData = prepareChartData(data);
  useEffect(() => {
    if (!preparedData || !preparedData.length) {
      return;
    }
    const filteredKeysNew = Object.keys(
      preparedData.reduce(
        (acc, item) => ({
          ...acc,
          ...item,
        }),
        {},
      ),
    ).filter(Boolean);
    const favouritesNew = new Array(filteredKeysNew.length).fill(0).map((_, ind) => ind);
    setFilteredKeys(filteredKeysNew);
    setFavourites(favouritesNew);
    setFilteredDataKeys(
      filteredKeysNew.map((key, ind) => ({
        id: favouritesNew[ind],
        name: key,
      })),
    );
  }, [data]);

  return (
    <>
      <Row style={{ padding: '20px 10px', margin: '5px 0px 0px 2px' }} className="chart-box-style">
        <PieChartComp
          chartId={finalPieChartId}
          data={preparedData.filter((d) => filteredKeys.includes(Object.keys(d)[0]))}
          height={height}
          isRenderDownloadAsPng
          colorFunc={getColorByKeyName}
          showLegend={false}
        />
        <ChartKeysFilter
          hidePanel
          data={filteredDataKeys}
          favourites={favourites}
          filteredKeys={filteredKeys}
          getLabelColor={getColorByKeyName}
          addKeysFilterHandler={([elem]) => {
            setFavourites([...favourites, elem.id]);
            setFilteredKeys([...filteredKeys, elem.name]);
          }}
          setKeysFilterHandler={null}
          removeKeysFilterHandler={([elem]) => {
            setFavourites(favourites.filter((f) => f !== elem.id));
            setFilteredKeys(filteredKeys.filter((f) => f !== elem.name));
          }}
          isShowOthers={false}
          isShowOthersChange={null}
          filteredDataKeys={filteredDataKeys}
        />
      </Row>
    </>
  );
};

PricingMethodSummaryChart.propTypes = {
  finalPieChartId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  height: PropTypes.number,
};
PricingMethodSummaryChart.defaultProps = {
  height: 300,
};

export default PricingMethodSummaryChart;
