import {
  mapReportDatePeriodToDisplayName,
  mapReportFrequencyToDisplayName,
  ReportSendFrequency,
  //   ReportPeriodTime,
} from 'usage/constants/costAndUsageConstants';
// eslint-disable-next-line max-len
import { mapCustomerReportTypeToDisplay } from 'divisions/containers/Customers/reports/constants/customerReportsConstants';

const getFrequency = (rawReport) => {
  let displayedFrequency = '';
  if (+rawReport.frequency === ReportSendFrequency.CUSTOM) {
    // 4 is custom frequency
    displayedFrequency = `Every ${rawReport.customFrequencyInDays} days`;
  } else {
    const numFrequency = parseInt(rawReport.frequency, 0);
    displayedFrequency = mapReportFrequencyToDisplayName.get(numFrequency);
  }
  return displayedFrequency;
};

const getPeriod = (rawReport) => mapReportDatePeriodToDisplayName.get(rawReport.period);

const getType = (rawReport) => {
  if (!rawReport.reportTypes || !Array.isArray(rawReport.reportTypes)) {
    return '';
  }
  return rawReport.reportTypes.map((type) => mapCustomerReportTypeToDisplay.get(type)).join(' & ');
};

export const prepareRawReportToTableDisplay = (rawReport) => {
  const prepReport = { ...rawReport };
  prepReport.frequency = getFrequency(rawReport);
  prepReport.period = getPeriod(rawReport);
  prepReport.reportType = getType(rawReport);
  prepReport.creationDate = prepReport.creationDate || '-';
  return prepReport;
};
