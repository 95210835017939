/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using Azure CLI:</li>
      <CodeBlock
        text="az kusto cluster delete --cluster-name {{clusterName}} --resource-group {{resourceGroup}}"
        variables={{
          resourceGroup: rec.resourceGroup,
          clusterName: rec.clusterName,
        }}
      />
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
