/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      {`Building applications that will reliably grow starts with choosing the right AWS region.
There are substantial differences in price, performance, number of Availability Zones and product selection among AWS regions.
We recommend on region migration based on usage and the cost of the current and suggested regions.`}
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
