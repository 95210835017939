/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Eye from 'mdi-react/EyeIcon';
import { Routes } from 'shared/constants/routes';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';

function ChangeToCustomerViewActionButton({ data, handleChangeToCustomerView }) {
  const buttonContainerStyle = {
    display: 'flex',
    flexFlow: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  };

  const linkStyle = {
    display: 'inline-block',
    position: 'relative',
    zIndex: 1,
    padding: '1em',
    margin: '-1em',
  };
  return (
    <i>
      <Row>
        <Col>
          <LinkIconButton containerStyle={buttonContainerStyle}>
            <Link
              linkStyle={linkStyle}
              key={data.row.id}
              onClick={(e) =>
                handleChangeToCustomerView(
                  e,
                  data.row.divisionId || data.row.id,
                  data.row.divisionNameDisplay || data.row.divisionName || data.row.customerName,
                  data.row.accountKey,
                )
              }
              to={{
                pathname: Routes.COST_USAGE_EXPLORER,
              }}
            />
            <Eye />
          </LinkIconButton>
        </Col>
      </Row>
    </i>
  );
}

ChangeToCustomerViewActionButton.propTypes = {
  data: PropTypes.object.isRequired,
  handleChangeToCustomerView: PropTypes.func.isRequired,
};

export default ChangeToCustomerViewActionButton;
