import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import { BASE_PROPERTIES, COST_PROPERTIES, RDS_PROPERTIES } from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';

const GcpVmIdle = ({ recommendation }) => {
  const properties = {
    ...COST_PROPERTIES,
    ...BASE_PROPERTIES,
    ...RDS_PROPERTIES,
  };

  const description = `This VM instance is idle:
                       CPU utilization is less than 0.03 vCPUs for 97% of VM runtime.
                       Received network traffic is less than 2000 bytes per second (B/s) for 95% of VM runtime.
                       Sent network traffic is less than 1000 B/s for 95% of VM runtime.`;

  const formattedDescription = description.split('\n').map((line, index) => (
    <>
      {index > 0 ? '\u2022 ' : <span>&nbsp;&nbsp;</span>}
      {index > 0 ? line : <b>{line}</b>}
      <br />
    </>
  ));

  const insideLookComponent = <RecommendationInsideLook description={formattedDescription} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `gcloud compute instances delete ${recommendation?.resourceName}`,
        },
      ],
    },
  ];

  const recommendationCommandComponent = <RecommendationCommand commandsList={commandsList} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const chartsData = [
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'Network Performance',
      yAxisLabel: 'Network I/O (MB)',
      yAxisUnit: Y_AXIS_UNIT.MB,
      linesList: [
        {
          id: 'networkIn',
          label: 'Network In',
          data: recommendation?.recData?.network_in_statistics_usage,
        },
        {
          id: 'networkOut',
          label: 'Network Out',
          data: recommendation.recData?.network_out_statistics_usage,
        },
      ],
    },
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'CPU Performance',
      yAxisLabel: 'CPU Utilization',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      linesList: [
        {
          id: 'cpuUtilization',
          label: 'CPU',
          data: recommendation?.recData?.cpu_util_statistics_usage,
        },
      ],
      referenceLines: [
        {
          yAxisValue: recommendation?.recData?.max_cpu_util,
          label: `Max CPU Utilization Benchmark ${recommendation?.recData?.max_cpu_util}%`,
        },
      ],
    },
  ];

  const recommendationChartsComponent = <RecommendationChartData chartsData={chartsData} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      command={recommendationCommandComponent}
      preferences={preferencesComponent}
      charts={recommendationChartsComponent}
    />
  );
};

GcpVmIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default GcpVmIdle;
