/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { MoreVertical } from 'react-feather';

class ActionButton extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { regApp, editApp, deleteApp } = this.props;
    return (
      <div>
        <IconButton
          className="material-table__toolbar-button"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          size="large">
          <MoreVertical size={22} color="black" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          <MenuItem onClick={() => editApp(regApp)} className="material-table__filter-menu-item">
            <h6>Edit</h6>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => deleteApp(regApp)} className="material-table__filter-menu-item">
            <h6>Delete</h6>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default ActionButton;
ActionButton.propTypes = {
  editApp: PropTypes.func.isRequired,
  deleteApp: PropTypes.func.isRequired,
  regApp: PropTypes.object.isRequired,
};
