import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { PageNames } from 'shared/constants/appConstants';
import Panel from 'shared/components/Panel';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import { AZURE_VM_USAGE_TITLES } from 'usage/containers/AzureVirtualMachines/constants/constants';
import VMAndStorageCostPanel from 'usage/containers/AzureVirtualMachines/components/VMAndStorageCostPanel';
import AzureVmUsagePanel from './components/AzureVmUsagePanel';
import RiCoveragePanel from './components/RiCoveragePanel';

const chartsHeight = 400;
const AzureVirtualMachines = ({ rootStore, usageStore }) => {
  const lastProcessTime = DateFilter.getDate();
  const riStartDate = moment(lastProcessTime).startOf('month').format('YYYY-MM-DD');
  const { isPpApplied } = rootStore?.appStore;

  return (
    <Container>
      <PageHeader title={PageNames.AZURE_VIRTUAL_MACHINES_USAGE} showDate />
      <Panel title={AZURE_VM_USAGE_TITLES.AZURE_VIRTUAL_MACHINE_USAGE} divider>
        <AzureVmUsagePanel
          height={chartsHeight}
          initialStartDate={DateFilter.getFirstDayOfCurrMonthDate()}
          initialEndDate={DateFilter.getUiLastProcessTimeString()}
          isPpApplied={isPpApplied}
          md={12}
          lg={10}
          xl={10}
          sm={12}
          xs={12}
        />
      </Panel>
      <Panel divider title={AZURE_VM_USAGE_TITLES.AZURE_VIRTUAL_MACHINE_AND_STORAGE_COST}>
        <VMAndStorageCostPanel
          initialStartDate={riStartDate}
          initialEndDate={DateFilter.getDateStr()}
          rootStore={rootStore}
        />
      </Panel>
      <Panel divider title={AZURE_VM_USAGE_TITLES.AZURE_RI_COVERAGE}>
        <RiCoveragePanel
          usageStore={usageStore}
          initialStartDate={riStartDate}
          initialEndDate={DateFilter.getDateStr()}
        />
      </Panel>
    </Container>
  );
};

AzureVirtualMachines.propTypes = {
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

const ObserverAzureVirtualMachines = observer(AzureVirtualMachines);
export default ObserverAzureVirtualMachines;
