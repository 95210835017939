import apiConstants from 'shared/api/apiConstants';
import { fetchRecommendationOpportunitiesData } from 'recommendations/hooks/react-query/useRecommendationOpportunities';
import { queryClient } from 'queryClient';

export default function recommendationOpportunitiesProvider() {
  return {
    fetchRecommendationOpportunities: (
      startDate,
      endDate,
      filterParams,
      periodGranLevel,
      recommendationType,
      dashboardId,
      limit,
    ) => {
      const queryKeyFetch = [
        apiConstants.QUERY_KEYS.RECOMMENDATION_OPPORTUNITIES,
        {
          startDate,
          endDate,
          filterParams,
          periodGranLevel,
          recommendationType,
          dashboardId,
          limit,
        },
      ];
      return queryClient.fetchQuery(
        queryKeyFetch,
        () =>
          fetchRecommendationOpportunitiesData(
            startDate,
            endDate,
            filterParams,
            periodGranLevel,
            recommendationType,
            dashboardId,
            limit,
          ),
        {
          retry: false,
        },
      );
    },
  };
}
