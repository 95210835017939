/* eslint-disable no-unused-vars */
import RiAction from '../../containers/Ri/components/Action';
import RdsClassChangeAction from '../../containers/RdsClassChange/components/Action';
import RdsIopsChangeAction from '../../containers/RdsIopsChange/components/Action';
import KmsIdleAction from '../../containers/KmsIdle/components/Action';
import KmsOldAction from '../../containers/KmsOld/components/Action';
import SpotAction from '../../containers/Spot/components/Action';
import RegionAction from '../../containers/Region/components/Action';
import VuAction from '../../containers/VersionUpgrade/components/Action';
import LcAction from '../../containers/Ec2LowCpu/components/Action';
import EbsUnattachedAction from '../../containers/EbsUnattached/components/Action';
import IdleLoadBalancerAction from '../../containers/IdleLoadBalancer/components/Action';
import IpUnattachedAction from '../../containers/IpUnattached/components/Action';
import DdbIdleAction from '../../containers/DdbIdle/components/Action';
import EbsTypeChangeAction from '../../containers/EbsTypeChange/components/Action';
import EbsUpgradeAction from '../../containers/EbsUpgrade/components/Action';
import EbsOutdatedSnapshot from '../../containers/EbsOutdatedSnapshot/components/Action';
import S3MultipartUploadAction from '../../containers/S3MultipartUpload/components/Action';
import EC2IdleAction from '../../containers/Ec2Idle/components/Action';
import RdsIdleAction from '../../containers/RdsIdle/components/Action';
import RdsVuAction from '../../containers/RdsVersionUpgrade/components/Action';
import Ec2UnnecessaryDataTransferAction from '../../containers/Ec2UnnecessaryDataTransfer/components/Action';
import Ec2StoppedInstanceAction from '../../containers/Ec2StoppedInstance/components/Action';
import RedshiftLowUtilization from '../../containers/RedshiftLowUtilization/components/Action';
import CloudtrailDuplicateTrails from '../../containers/CloudtrailDuplicateTrails/components/Action';
import ElasticsearchLowUtilization from '../../containers/ElasticsearchLowUtilization/components/Action';
import ElasticacheLowUtilization from '../../containers/ElasticacheLowUtilization/components/Action';
import DocumentDbLowUtilization from '../../containers/DocumentDbLowUtilization/components/Action';
import NatGatewayLowUtilization from '../../containers/NatGatewayLowUtilization/components/Action';
import NeptuneLowUtilization from '../../containers/NeptuneLowUtilization/components/Action';
import KinesisLowUtilization from '../../containers/KinesisLowUtilization/components/Action';
import S3VersioningAction from '../../containers/S3Versioning/components/Action';
import S3StorageClassAction from '../../containers/S3StorageClass/components/Action';

import AzureIpUnattachedAction from '../../containers/AzureIpUnattached/components/Action';
import AzureDiskUnattached from '../../containers/AzureDiskUnattached/components/Action';
import AzureVmRiAction from '../../containers/AzureVmRi/components/Action';
import AzureVmStopped from '../../containers/AzureVmStopped/components/Action';
import AzureVmIdleAction from '../../containers/AzureVmIdle/components/Action';
import AzureVmRightSizingAction from '../../containers/AzureVmRightSizing/components/Action';
import AzureMariaDBIdle from '../../containers/AzureMariaDbIdle/components/Action';
import AzureCosmosDbIdle from '../../containers/AzureCosmosDbIdle/components/Action';
import AzureMysqlDbIdle from '../../containers/AzureMysqlDbIdle/components/Action';
import AzurePostgresDbIdle from '../../containers/AzurePostgresDbIdle/components/Action';
import AzureSqlDbIdle from '../../containers/AzureSqlDbIdle/components/Action';
import AzureIdleLoadBalancer from '../../containers/AzureIdleLoadBalancer/components/Action';
import AzureAppIdleAction from '../../containers/AzureAppRightSizing/components/Action';
import AzureDiskTypeChangeAction from '../../containers/AzureDiskTypeChange/components/Action';
import AzureSnapshotMigrationAction from '../../containers/AzureSnapshotMigration/components/Action';
import AzureOutdatedSnapshotAction from '../../containers/AzureOutdatedSnapshot/components/Action';
import AzureKustoUnusedDataAction from '../../containers/AzureKustoUnusedData/components/Action';
import AzureAppSerRsrvdCapacityAction from '../../containers/AzureAppServiceReservedCapacity/components/Action';

import GcpVmStopped from '../../containers/GcpVmStopped/components/Action';
import GcpIpIdle from '../../containers/GcpIPIdle/components/Action';
import GcpVmIdleAction from '../../containers/GcpVmIdle/components/Action';
import GcpUsageCommitmentAction from '../../containers/GcpUsageCommitment/components/Action';
import GcpVmRightSizingAction from '../../containers/GcpVmRightSizing/components/Action';
import GcpDiskUnattchedAction from '../../containers/GcpDiskUnattched/components/Action';

import { RecommendationTypes } from '../../constants/recommendationsConstants';

class RecActionFactory {
  static create(data) {
    let output;
    switch (data.type) {
      case RecommendationTypes.KMS_IDLE:
        output = KmsIdleAction;
        break;
      case RecommendationTypes.KMS_OLD:
        output = KmsOldAction;
        break;
      case RecommendationTypes.EC2_RI:
        output = RiAction;
        break;
      case RecommendationTypes.EC2_LOW_CPU_USAGE:
        output = LcAction;
        break;
      case RecommendationTypes.EC2_SPOT:
        output = SpotAction;
        break;
      case RecommendationTypes.EC2_REGION:
        output = RegionAction;
        break;
      case RecommendationTypes.EC2_VERSION_UPGRADE:
        output = VuAction;
        break;
      case RecommendationTypes.EC2_EBS_UNATTACHED:
        output = EbsUnattachedAction;
        break;
      case RecommendationTypes.EC2_IDLE_LOAD_BALANCER:
        output = IdleLoadBalancerAction;
        break;
      case RecommendationTypes.EC2_IP_UNATTACHED:
        output = IpUnattachedAction;
        break;
      case RecommendationTypes.EC2_IDLE:
        output = EC2IdleAction;
        break;
      case RecommendationTypes.RDS_IDLE:
        output = RdsIdleAction;
        break;
      case RecommendationTypes.RDS_VERSION_UPGRADE:
        output = RdsVuAction;
        break;
      case RecommendationTypes.RDS_CLASS_CHANGE:
        output = RdsClassChangeAction;
        break;
      case RecommendationTypes.RDS_IOPS_CHANGE:
        output = RdsIopsChangeAction;
        break;
      case RecommendationTypes.DDB_IDLE:
        output = DdbIdleAction;
        break;
      case RecommendationTypes.EBS_TYPE_CHANGE:
        output = EbsTypeChangeAction;
        break;
      case RecommendationTypes.EBS_UPGRADE:
        output = EbsUpgradeAction;
        break;
      case RecommendationTypes.EBS_OUTDATED_SNAPSHOT:
        output = EbsOutdatedSnapshot;
        break;
      case RecommendationTypes.EBS_TYPE_SIZE_CHANGE:
        output = EbsTypeChangeAction;
        break;
      case RecommendationTypes.S3_VERSIONING:
        output = S3VersioningAction;
        break;
      case RecommendationTypes.S3_STORAGE_CLASS:
        output = S3StorageClassAction;
        break;
      case RecommendationTypes.S3_MULTIPART_UPLOAD:
        output = S3MultipartUploadAction;
        break;
      case RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER:
        output = Ec2UnnecessaryDataTransferAction;
        break;
      case RecommendationTypes.EC2_STOPPED_INSTANCE:
        output = Ec2StoppedInstanceAction;
        break;
      case RecommendationTypes.REDSHIFT_LOW_UTILIZATION:
        output = RedshiftLowUtilization;
        break;
      case RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS:
        output = CloudtrailDuplicateTrails;
        break;
      case RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION:
        output = ElasticsearchLowUtilization;
        break;
      case RecommendationTypes.ELASTICACHE_LOW_UTILIZATION:
        output = ElasticacheLowUtilization;
        break;
      case RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION:
        output = NatGatewayLowUtilization;
        break;
      case RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION:
        output = DocumentDbLowUtilization;
        break;
      case RecommendationTypes.NEPTUNE_LOW_UTILIZATION:
        output = NeptuneLowUtilization;
        break;
      case RecommendationTypes.KINESIS_LOW_UTILIZATION:
        output = KinesisLowUtilization;
        break;
      // AZURE ************************************
      case RecommendationTypes.AZURE_VM_RI:
        output = AzureVmRiAction;
        break;
      case RecommendationTypes.AZURE_VM_IDLE:
        output = AzureVmIdleAction;
        break;
      case RecommendationTypes.AZURE_VM_STOPPED:
        output = AzureVmStopped;
        break;
      case RecommendationTypes.AZURE_MARIA_DB_IDLE:
        output = AzureMariaDBIdle;
        break;
      case RecommendationTypes.AZURE_SQL_DB_IDLE:
        output = AzureSqlDbIdle;
        break;
      case RecommendationTypes.AZURE_COSMOS_DB_IDLE:
        output = AzureCosmosDbIdle;
        break;
      case RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE:
        output = AzurePostgresDbIdle;
        break;
      case RecommendationTypes.AZURE_MYSQL_DB_IDLE:
        output = AzureMysqlDbIdle;
        break;
      case RecommendationTypes.AZURE_IP_UNATTACHED:
        output = AzureIpUnattachedAction;
        break;
      case RecommendationTypes.AZURE_DISK_UNATTACHED:
        output = AzureDiskUnattached;
        break;
      case RecommendationTypes.AZURE_IDLE_LOAD_BALANCER:
        output = AzureIdleLoadBalancer;
        break;
      case RecommendationTypes.AZURE_APP_RIGHT_SIZING:
        output = AzureAppIdleAction;
        break;
      case RecommendationTypes.AZURE_DISK_TYPE_CHANGE:
        output = AzureDiskTypeChangeAction;
        break;
      case RecommendationTypes.AZURE_VM_RIGHT_SIZING:
        output = AzureVmRightSizingAction;
        break;
      case RecommendationTypes.AZURE_KUSTO_UNUSED_DATA:
        output = AzureKustoUnusedDataAction;
        break;
      case RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY:
        output = AzureAppSerRsrvdCapacityAction;
        break;
      // case RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS:
      //   output = AzureReservedCapacityAction;
      //   break;
      // case RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER:
      //   output = AzureReservedCapacityAction;
      //   break;
      // case RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL:
      //   output = AzureReservedCapacityAction;
      //   break;
      // case RecommendationTypes.AZURE_RESERVED_CAPACITY_PG:
      //   output = AzureReservedCapacityAction;
      //   break;
      // case RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS:
      //   output = AzureReservedCapacityAction;
      //   break;
      // case RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL:
      //   output = AzureReservedCapacityAction;
      //   break;
      // case RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH:
      //   output = AzureReservedCapacityAction;
      //   break;
      case RecommendationTypes.AZURE_SNAPSHOT_MIGRATION:
        output = AzureSnapshotMigrationAction;
        break;
      case RecommendationTypes.AZURE_OUTDATED_SNAPSHOT:
        output = AzureOutdatedSnapshotAction;
        break;
      // GCP ************************************
      case RecommendationTypes.GCP_VM_IDLE:
        output = GcpVmIdleAction;
        break;
      case RecommendationTypes.GCP_USAGE_COMMITMENT:
        output = GcpUsageCommitmentAction;
        break;
      case RecommendationTypes.GCP_VM_STOPPED:
        output = GcpVmStopped;
        break;
      case RecommendationTypes.GCP_IP_IDLE:
        output = GcpIpIdle;
        break;
      case RecommendationTypes.GCP_VM_RIGHT_SIZING:
        output = GcpVmRightSizingAction;
        break;
      case RecommendationTypes.GCP_DISK_UNATTACHED:
        output = GcpDiskUnattchedAction;
        break;
      // DEFAULT ************************************
      default:
        output = undefined;
        break;
    }
    return output;
  }
}

export default RecActionFactory;
