import React from 'react';
import PropTypes from 'prop-types';
import RecommendationOptionsPanel from 'recommendations/shared/components/RecommendationOptionsPanel';

const Options = ({ recommendation }) => {
  if (Array.isArray(recommendation?.alternatives)) {
    const topThreeAlternatives = recommendation?.alternatives.slice(0, 3);
    const current = { storageClass: recommendation?.currentStorageClass, annualCost: recommendation?.currTotalCost };
    const recommendationData = [{ propertyTitle: 'Storage class', property: 'storageClass' }];

    return (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        current={current}
        recommendationData={recommendationData}
      />
    );
  }
  return null;
};

Options.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Options;
