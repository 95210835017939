import apiConstants from 'shared/api/apiConstants';
import DateFilter from 'shared/modules/dateFilter';
import moment from 'moment';
import { queryClient } from 'queryClient';
import { fetchDashboardSummaryTableData } from 'recommendations/hooks/react-query/useDashboardSummary';

const fetchCustomDashboardSummaryTableData = async (filters, lastProcessDate, limit) => {
  const filtersMap = new Map([]);
  if (filters?.length) {
    filters.forEach((filter) => {
      filtersMap.set(filter[0], filter[1]);
    });
  }
  return fetchDashboardSummaryTableData(filtersMap, lastProcessDate, limit);
};

export default function dashboardSummaryProvider() {
  return {
    fetchCustomDashboardPanelSummaryTableData: (filtersMap, lastProcessDate, currentLimit) => {
      const queryKeyCustomDashboardDashboardSummaryData = [
        apiConstants.QUERY_KEYS.RECOMMENDATION_DASHBOARD_SUMMARY,
        {
          filterMap: filtersMap,
          lastProcessDate: moment(lastProcessDate || DateFilter.getDate()),
          currentLimit,
        },
      ];
      return queryClient.fetchQuery(
        queryKeyCustomDashboardDashboardSummaryData,
        () => fetchCustomDashboardSummaryTableData(filtersMap, lastProcessDate, currentLimit),
        {
          retry: false,
        },
      );
    },
  };
}
