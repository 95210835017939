import moment from 'moment';
import { createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import DateFilter from 'shared/modules/dateFilter';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import BaseReportParams from './baseReportParams';

export default class YesterdayTopFiveServReportParams extends BaseReportParams {
  createYesterdayTopFiveMap = (arrOfTopServices) => {
    const asArray = Array.isArray(arrOfTopServices) ? [...arrOfTopServices] : [arrOfTopServices];
    const filteredKeys = [...asArray];
    const favourites = filteredKeys.length > 0 ? [...Array(filteredKeys.length).keys()] : [];
    this.updateBaseReportParams({ favourites, filteredKeys }, null);
  };

  updateDates = () => {
    const prevDay = moment(DateFilter.getPreviousDayDate());
    let endDate = prevDay.format('YYYY-MM-DD');
    let startDate = prevDay.subtract(1, 'days').format('YYYY-MM-DD');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    this.updateBaseReportParams({ startDate, endDate, currPeriodGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY });
  };
}
