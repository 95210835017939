/* eslint-disable no-unused-vars */
import moment from 'moment';
import { AwsServiceNames } from 'shared/constants/awsConstants';

export const costChartAlignParamsToFetchFunc = (routeParams, lastProcessTime, panelId, dashboardId) => {
  const {
    groupByLevel,
    secondaryGroupByLevel,
    groupByValue,
    whereParamsMap,
    startDate,
    endDate,
    periodGranLevel,
    filtersMap,
    currCostType,
    isShowAmortize,
    isApplyMargin,
    excludedFiltersStatusMap,
    likeFiltersStatus,
    filtersConfig,
    isNetAmortize,
    isNetUnblended,
    isPublicCost,
    isDistributed,
    isRateUsageBased,
  } = routeParams;
  const arrangedArray = [
    groupByLevel,
    secondaryGroupByLevel,
    groupByValue,
    new Map(whereParamsMap),
    startDate,
    endDate,
    periodGranLevel,
    {
      isRateUsageBased,
      filtersMap: new Map(filtersMap),
      excludedFiltersStatusMap: new Map(excludedFiltersStatusMap),
      likedFiltersStatus: likeFiltersStatus,
      filtersConfig,
    },
    currCostType,
    isShowAmortize,
    isApplyMargin,
    isNetAmortize,
    isNetUnblended,
    isPublicCost,
    isDistributed,
    lastProcessTime,
    panelId,
    dashboardId,
  ];
  return arrangedArray;
};

export const utilizationByServiceParamsToFetchFunc = (routeParams) => {
  const { service, startDate, endDate } = routeParams;
  const [year, startMonth] = startDate.split('-');
  const endMonth = endDate.split('-')[1];
  const serviceLabelToValue = {
    [AwsServiceNames.EC2]: 'EC2',
    [AwsServiceNames.RDS]: 'RDS',
    [AwsServiceNames.ELASTICACHE]: 'ElastiCache',
    [AwsServiceNames.REDSHIFT]: 'Redshift',
    [AwsServiceNames.ELASTICSEARCH]: 'ES',
  };
  return [serviceLabelToValue[service] || service, year, +startMonth, +endMonth];
};

export const savingOnDemandParamsToFetchFunc = (routeParams) => {
  const { startDate } = routeParams;
  const yearQuarter = moment(startDate).format('Q-YYYY');
  return [yearQuarter];
};

export const assetsParamsToFetchFunc = (isK8S) => (routeParams, _, panelId, dashboardId) => {
  const {
    selectedColumns,
    selectedMeasure,
    startDate,
    endDate,
    periodGranLevel,
    filtersMap,
    excludedFiltersStatusMap,
    currCostType,
  } = routeParams;

  const measuresMap = new Map([[selectedMeasure.value, 1]]);
  return [
    {
      startDate,
      endDate,
      granLevel: periodGranLevel,
      columns: selectedColumns,
      filtersMap: new Map(filtersMap),
      excludeFiltersMap: new Map(excludedFiltersStatusMap),
      measuresMap,
      costTypes: currCostType,
      isK8S,
      panelId,
      dashboardId,
    },
  ];
};

export const k8sCostChartAlignParamsToFetchFunc = (routeParams, lastProcessTime, panelId, dashboardId) => {
  const {
    currDataState,
    groupByLevel,
    secondaryGroupByLevel,
    groupByValue,
    whereParamsMap,
    startDate,
    endDate,
    periodGranLevel,
    filtersMap,
    currCostType,
    SelectedUsageType,
    selectedUsageTypeMetric,
    excludedFiltersStatusMap,
    likeFiltersStatus,
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
    filtersConfig,
    wasteCostAllocationChecked,
  } = routeParams;
  const arrangedArray = [
    currDataState,
    secondaryGroupByLevel,
    groupByValue,
    new Map(whereParamsMap),
    startDate,
    endDate,
    periodGranLevel,
    {
      filtersMap: new Map(filtersMap),
      excludedFiltersStatusMap: new Map(excludedFiltersStatusMap),
      likedFiltersStatus: likeFiltersStatus,
      filtersConfig,
    },
    currCostType,
    SelectedUsageType,
    selectedUsageTypeMetric,
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
    lastProcessTime,
    panelId,
    dashboardId,
    wasteCostAllocationChecked,
  ];
  return arrangedArray;
};

export const cloudSavingsChartAlignParamsToFetchFunc = (
  routeParams,
  panelLastProcessDate = null,
  uuid = null,
  dashboardId = null,
) => {
  const { recommType, filterSelectedValues, currPeriodGranLevel, startDate, endDate } = routeParams;

  const arrangedArray = [
    startDate,
    endDate,
    new Map(filterSelectedValues),
    currPeriodGranLevel,
    recommType,
    dashboardId,
  ];
  return arrangedArray;
};

export const recommSummeryTableAlignParamsToFetchFunc = (routeParams, panelLastProcessDate) => {
  const { filterSelectedValues } = routeParams;
  const arrangedArray = [
    // check if the filters type is map, if so - convert the map into array:
    filterSelectedValues instanceof Map ? Array.from(filterSelectedValues) : filterSelectedValues,
    panelLastProcessDate,
  ];
  return arrangedArray;
};

export const prepareDataToKpiHalfPieChart = (data) => {
  const modifiedData = [];
  const { value } = data[0];
  const getColor = () => {
    if (value < 40) {
      return '#d95350';
    }
    if (value < 80) {
      return '#eea64e';
    }
    return '#449d44';
  };
  modifiedData.push({ value, fill: getColor() });
  modifiedData.push({ value: 100 - value, fill: '#f2f4f7' });
  return modifiedData;
};
