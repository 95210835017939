/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader title="Steps To Execute" />
    <ul style={{ 'list-style-type': 'none' }}>
      {/* <li>Using the AWS CLI:</li>
      <div className="project-summary__copy-button">
        <CopyFromCmdBoxButton boxId="1" />
        <pre id="1">
          <code>
            aws ec2 terminate-instances --instance-ids <span style={{ color: 'red' }}>{rec.resourceId}</span>
          </code>
        </pre>
      </div>
      <br /> */}
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
