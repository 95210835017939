import React, { useEffect, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { removeTrailingZeroes } from 'shared/utils/strUtil';

// data prop format example:
// [{ value: 60, fill: '#b8e986' },
//  { value: 40, fill: '#f2f4f7' }];

const getFontSizeByWidth = (width) => {
  const fontSizeByWidth = {
    150: 12,
    200: 20,
    300: 32,
    400: 40,
  };
  let result = null;
  Object.keys(fontSizeByWidth)
    .reverse()
    .forEach((w) => {
      if (width < w) {
        result = fontSizeByWidth[w];
      }
    });
  return result || fontSizeByWidth[400];
};

const HalfPieChart = ({ data }) => {
  const ref = React.useRef();
  const [width, setWidth] = useState(null);
  const updateWidth = () => {
    const newWidth = ref.current.clientWidth;
    setWidth(+newWidth);
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    updateWidth();
  }, [(ref.current || {}).clientWidth]);
  useEffect(() => {
    window.addEventListener('resize', updateWidth, false);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  if (!data || !data.length) {
    return null;
  }
  const { value } = data[0];
  const percentValue = removeTrailingZeroes(value, 1);

  const innerWidth = width - 30;
  const pieRadius = innerWidth / 2;
  const fontSize = getFontSizeByWidth(width);

  return (
    <div className="charts__half-pie-container" ref={ref}>
      <Col lg={12} xl={12} md={12}>
        <div className="charts__half-pie">
          <div className="charts__half-pie-chart" style={{ paddingTop: pieRadius / 2 }}>
            <ResponsiveContainer width="100%" height={innerWidth}>
              <PieChart width={400} height={400}>
                <Pie
                  data={data}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  startAngle={180}
                  endAngle={0}
                  innerRadius={pieRadius * 0.8}
                  outerRadius={pieRadius}
                  paddingAngle={0}
                />
              </PieChart>
            </ResponsiveContainer>
            <p
              className="charts__half-pie-label"
              style={{ marginTop: -pieRadius - fontSize * 0.9, fontSize, lineHeight: `${fontSize}px` }}
            >
              {percentValue}
            </p>
          </div>
        </div>
      </Col>
    </div>
  );
};

HalfPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fill: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

export default HalfPieChart;
