/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len,arrow-body-style,react/no-unused-state,react/no-unused-prop-types,no-unused-vars */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import If from 'shared/components/SimpleIf';
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { calcCoverage } from 'commitment/containers/SpAnalyzer/helpers/spCalculator';
import { Routes } from 'shared/constants/routes';
import SpHeatMapChart from './SpHeatMapChart';
import AvgHourlyCostChart from './AvgHourlyCostChart';
import { createAvgHourlyCostChartData, modifySpTableData } from '../helpers/helpers';

class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
    coverage: PropTypes.number.isRequired,
    disableEdit: PropTypes.bool,
  };
  static defaultProps = {
    disableEdit: false,
  };

  constructor(props) {
    super(props);
    const { rec } = this.props;
    this.state = {
      update: false,
      selectedHourlyCost: rec.recommendedHourlyCommitment,
      coverage: calcCoverage(rec.recommendedHourlyCommitment, rec.potentialSavings / 100),
    };
  }

  handleHourlyCommitmentChange = (event) => {
    event.preventDefault();
    let newHourlyCost = parseFloat(event.target.value);
    if (!event.target.value || parseFloat(event.target.value) < 0.001) {
      newHourlyCost = 0.001;
    }
    const newCoverage = calcCoverage(newHourlyCost, this.props.rec.potentialSavings / 100);
    this.setState({ selectedHourlyCost: newHourlyCost, coverage: newCoverage });
  };

  render() {
    const { rec, disableEdit } = this.props;
    const { dataArr, maxValue } = modifySpTableData(rec);
    const { selectedHourlyCost, coverage } = this.state;
    const avgHourlyCostData = createAvgHourlyCostChartData(rec, selectedHourlyCost, coverage);
    const isPageTypeSpAnalyzer = String(window.location.href).includes(Routes.SAVINGS_PLANS_ANALYZER);
    return (
      <div className={isPageTypeSpAnalyzer ? 'sp-analyzer__charts-container' : null}>
        <div style={{ minWidth: '500px' }}>
          {disableEdit ? null : (
            <TextField
              id="standard-number"
              label="Hourly Commitment ($)"
              type="number"
              value={selectedHourlyCost}
              onChange={(event) => this.handleHourlyCommitmentChange(event)}
              className="hourlyCommitment"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              style={{ whiteSpace: 'nowrap' }}
            />
          )}
          <br />
          <div style={isPageTypeSpAnalyzer ? { width: '100%', marginRight: '30px' } : null}>
            <p className="project-summary__statistic-title">Average Compute Cost Hourly</p>
            <AvgHourlyCostChart data={avgHourlyCostData} selectedHourlyCost={selectedHourlyCost} />
            <br />
          </div>
        </div>
        <If cond={String(window.location.href).includes(RecommendationTypes.EC2_SAVINGS_PLANS) || isPageTypeSpAnalyzer}>
          {/* <hr /> */}
          <div className="project-summary__statistic_heatmap" style={{ marginTop: '30px' }}>
            <p className="project-summary__statistic-title" />
            <br />
            <SpHeatMapChart
              series={dataArr}
              maxValue={maxValue}
              selectedHourlyCost={selectedHourlyCost}
              hourlyCostPrefix={selectedHourlyCost === rec.recommendedHourlyCommitment ? 'Recommended' : 'Selected'}
            />
          </div>
        </If>
      </div>
    );
  }
}

export default Statistics;
