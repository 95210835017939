/* eslint-disable no-unused-vars */
import {
  mapRecommendationsTypeToDisplay,
  RecommendationTypes,
  RecommFieldsAndFiltersValues,
  RecommTableFieldTypes,
} from 'recommendations/constants/recommendationsConstants';
import DbTableFieldTypes from 'shared/constants/tablesConstants';
import IdCell from '../helpers/historyTblIDCellClass';
import StringCell from '../helpers/historyTblStringCellClass';
import CostCell from '../helpers/historyTableCostCell';
import DateCell from '../helpers/historyTableDateCellClass';
import PercentCell from '../helpers/historyTablePercentCell';

export const FieldTypeToTableTitles = new Map([
  [RecommTableFieldTypes.ID, 'Id'],
  [RecommTableFieldTypes.TITLE, 'Type'],
  [RecommTableFieldTypes.REC_STATUS, 'Status'],
  [RecommTableFieldTypes.REDUCE_PERCENT, 'Savings'],
  [RecommTableFieldTypes.SUGG_UPDATE_DATE, 'Date'],
  [RecommTableFieldTypes.EXCLUDE_REASON, 'Exclude Reason'],
]);

export const FieldTypeToFilterTitles = new Map([
  [RecommTableFieldTypes.REC_STATUS, 'Status'],
  [RecommTableFieldTypes.TYPE, 'Type'],
  [RecommTableFieldTypes.SUGG_UPDATE_DATE, 'Date'],
]);

export const RecommTypesToStoreNames = new Map([
  [RecommTableFieldTypes.ID, 'id'],
  [RecommTableFieldTypes.KEY, 'key'],
  [RecommTableFieldTypes.TITLE, 'title'],
  [RecommTableFieldTypes.REC_STATUS, 'status'],
  [RecommTableFieldTypes.CURR_INSTANCE, 'instance'],
  [RecommTableFieldTypes.REGION, 'region'],
  [RecommTableFieldTypes.TYPE, 'type'],
  [RecommTableFieldTypes.CURR_TOTAL_COST, 'currTotalCost'],
  [RecommTableFieldTypes.SUGG_CREATION_DATE, 'Creation Date'],
  [RecommTableFieldTypes.SUGG_TOTAL_COST, 'recommTotalCost'],
  [RecommTableFieldTypes.REDUCE_PERCENT, 'reducePercent'],
  [RecommTableFieldTypes.SUGG_CREATION_DATE, 'creationTime'],
  [RecommTableFieldTypes.SUGG_UPDATE_DATE, 'updateTime'],
  [RecommTableFieldTypes.EXCLUDE_REASON, 'excludeMessage'],
]);

export const HeadersToSortable = new Map([
  [RecommTableFieldTypes.ID, true],
  [RecommTableFieldTypes.TITLE, false],
  [RecommTableFieldTypes.REC_STATUS, false],
  [RecommTableFieldTypes.REDUCE_PERCENT, true],
  [RecommTableFieldTypes.SUGG_UPDATE_DATE, true],
  [RecommTableFieldTypes.EXCLUDE_REASON, false],
]);

export const TableMetaTypesToStoreNames = new Map([
  [RecommTableFieldTypes.TYPE, 'Type'],
  [RecommTableFieldTypes.CURR_INSTANCE, 'Instance Model'],
  [RecommTableFieldTypes.REGION, 'Region'],
  [RecommTableFieldTypes.CURR_TOTAL_COST, 'Original Cost'],
  [RecommTableFieldTypes.SUGG_TOTAL_COST, 'Recommendation Cost'],
]);

const it = mapRecommendationsTypeToDisplay.entries();
const recommTypeToDisplayArray = [];
let result = it.next();
while (!result.done) {
  recommTypeToDisplayArray.push(result.value);
  result = it.next();
}

export const RecommTypeValuesToFilterText = new Map([
  [RecommFieldsAndFiltersValues.ALL, 'All'],
  [RecommFieldsAndFiltersValues.OPEN, 'Open'],
  [RecommFieldsAndFiltersValues.COMPLETED, 'Completed'],
  [RecommFieldsAndFiltersValues.COMPLETED_BY_SYSTEM, 'Completed By System'],
  [RecommFieldsAndFiltersValues.DISMISSED_BY_SYSTEM, 'Dismissed - sys'],
  [RecommFieldsAndFiltersValues.DISMISSED_BY_USER, 'Dismissed'],
  [RecommFieldsAndFiltersValues.EXCLUDED, 'Excluded'],
  [RecommFieldsAndFiltersValues.INVALID, 'Invalid'],
  [RecommFieldsAndFiltersValues.CURRENT_MONTH, 'Current month'],
  [RecommFieldsAndFiltersValues.PREVIOUS_MONTH, 'Previous month'],
  [RecommFieldsAndFiltersValues.LAST_Q, 'Last 3 months'],
  [RecommFieldsAndFiltersValues.YTD, 'Year to date'],
  ...recommTypeToDisplayArray,
]);
export const mapStatusToTooltipValue = new Map([
  [RecommFieldsAndFiltersValues.OPEN, 'Saving opportunities waiting for user action'],
  [RecommFieldsAndFiltersValues.COMPLETED, ' recommendation was marked as completed by the user'],
  [RecommFieldsAndFiltersValues.COMPLETED_BY_SYSTEM, 'System has recognized recommendation has been implemented'],
  [RecommFieldsAndFiltersValues.EXCLUDED, 'recommendation was rejected by the user'],
  [RecommFieldsAndFiltersValues.DISMISSED_BY_USER, 'Dismissed by user after system completion'],
]);

export const FiltersListAndValuesByRecommTypes = new Map([
  [
    RecommTableFieldTypes.REC_STATUS,
    [
      RecommFieldsAndFiltersValues.ALL,
      RecommFieldsAndFiltersValues.COMPLETED,
      RecommFieldsAndFiltersValues.COMPLETED_BY_SYSTEM,
      RecommFieldsAndFiltersValues.DISMISSED_BY_USER,
      RecommFieldsAndFiltersValues.EXCLUDED,
    ],
  ],
  [RecommTableFieldTypes.TYPE, [RecommFieldsAndFiltersValues.ALL, ...Object.values(RecommendationTypes)]],
  [
    RecommTableFieldTypes.SUGG_UPDATE_DATE,
    [
      RecommFieldsAndFiltersValues.ALL,
      RecommFieldsAndFiltersValues.CURRENT_MONTH,
      RecommFieldsAndFiltersValues.PREVIOUS_MONTH,
      RecommFieldsAndFiltersValues.LAST_Q,
      RecommFieldsAndFiltersValues.YTD,
    ],
  ],
]);

export const CELL_TYPES = new Map([
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.ID), DbTableFieldTypes.ID],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.TITLE), DbTableFieldTypes.STRING],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.REC_STATUS), DbTableFieldTypes.STRING],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.CURR_INSTANCE), DbTableFieldTypes.STRING],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.REGION), DbTableFieldTypes.STRING],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.TYPE), DbTableFieldTypes.STRING],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.CURR_TOTAL_COST), DbTableFieldTypes.COST],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.SUGG_TOTAL_COST), DbTableFieldTypes.COST],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.REDUCE_PERCENT), DbTableFieldTypes.PERCENT],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.SUGG_CREATION_DATE), DbTableFieldTypes.DATE],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.SUGG_UPDATE_DATE), DbTableFieldTypes.DATE],
  [RecommTypesToStoreNames.get(RecommTableFieldTypes.EXCLUDE_REASON), DbTableFieldTypes.STRING],
]);

export const CELL_CREATORS_BY_TYPE = new Map([
  [DbTableFieldTypes.ID, () => (c, id) => new IdCell(c, id)],
  [DbTableFieldTypes.STRING, () => (c, id) => new StringCell(c)],
  [DbTableFieldTypes.PERCENT, () => (c, id) => new PercentCell(c)],
  [DbTableFieldTypes.COST, () => (c, id) => new CostCell(c)],
  [DbTableFieldTypes.DATE, () => (c, id) => new DateCell(c)],
]);

export const THREE_MONTHS_DUR_MILLIES = 7889238000;
