import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BusinessMappingGlobalTable
  from '../BusinessMapping/components/BusinessMappingGroupsTable/BusinessMappingGlobalTable';

const BusinessMappingGlobalGroups = (props) => (
  <div className="ps-4 pe-4">
    <BusinessMappingGlobalTable {...props} />
  </div>
);

BusinessMappingGlobalGroups.propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};

export default observer(BusinessMappingGlobalGroups);
