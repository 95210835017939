/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Col, Collapse, Row } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { CostTrackingConstants, mapGranLevelToDisplayValue } from 'usage/constants/costAndUsageConstants';

class PeriodGranularityFilter extends PureComponent {
  static propTypes = {
    handleChangeGranLevel: PropTypes.func.isRequired,
    currPeriodGranLevel: PropTypes.string.isRequired,
    isShowQuarterly: PropTypes.bool,
    isShowYearly: PropTypes.bool,
    isShowHourly: PropTypes.bool,
    isDateRangeError: PropTypes.bool.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    isCue: PropTypes.bool,
  };

  static defaultProps = {
    isShowQuarterly: false,
    isShowYearly: false,
    isShowHourly: false,
    isCue: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  onRadioBtnClick = (rSelected) => {
    this.props.handleChangeGranLevel(rSelected);
    this.toggle();
  };

  toggle = () => {
    const { collapse } = this.state;
    this.setState({
      collapse: !collapse,
    });
  };

  render() {
    const { isCue, isShowQuarterly, isShowYearly, isShowHourly, currPeriodGranLevel } = this.props;
    const { collapse } = this.state;
    return (
      <div className={`d-flex date-picker-container ${isCue ? 'cue' : null}`} automation-id="period-granularity">
        <Col onClick={this.toggle} xl="auto" lg="auto" sm="auto" xs="auto">
          <h5 style={isCue ? { fontSize: '0.85rem', letterSpacing: '0.5px' } : null}>
            {`${isCue ? 'View:' : ''}`}
            <b>{` ${mapGranLevelToDisplayValue.get(currPeriodGranLevel)}`}</b>
          </h5>
        </Col>
        <Col style={{ padding: '1px 0px' }} xs={2} md={2} lg={2} xl={2}>
          <LinkIconButton className="topbar__avatar" onClick={this.toggle}>
            <p
              // href="#"
              onClick={this.toggle}
            />
            <ChevronDown size={22} />
          </LinkIconButton>
        </Col>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className={`topbar__menu-wrap filters${isCue ? ' cue' : ''}`}>
          <div className="drop__menu">
            {isShowYearly && (
              <p
                className="collapse-item"
                onClick={() => this.onRadioBtnClick(CostTrackingConstants.GRAN_LEVEL_YEARLY)}
              >
                Yearly
              </p>
            )}
            {isShowQuarterly && (
              <p
                className="collapse-item"
                onClick={() => this.onRadioBtnClick(CostTrackingConstants.GRAN_LEVEL_QUARTERLY)}
              >
                Quarterly
              </p>
            )}
            <p className="collapse-item" onClick={() => this.onRadioBtnClick(CostTrackingConstants.GRAN_LEVEL_MONTHLY)}>
              Monthly
            </p>
            <p className="collapse-item" onClick={() => this.onRadioBtnClick(CostTrackingConstants.GRAN_LEVEL_WEEKLY)}>
              Weekly
            </p>
            <p className="collapse-item" onClick={() => this.onRadioBtnClick(CostTrackingConstants.GRAN_LEVEL_DAILY)}>
              Daily
            </p>
            {isShowHourly && (
              <p
                className="collapse-item"
                onClick={() => this.onRadioBtnClick(CostTrackingConstants.GRAN_LEVEL_HOURLY)}
              >
                Hourly
              </p>
            )}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default PeriodGranularityFilter;
