import React from 'react';
import PropTypes from 'prop-types';
import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './Checkbox.module.scss';
import { palette } from '../../constants/colorsConstants';

const propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isHalfChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  primary: PropTypes.bool,
  children: PropTypes.object,
  automationId: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
const defaultProps = {
  text: '',
  className: '',
  isHalfChecked: false,
  isDisabled: false,
  primary: false,
  children: null,
  automationId: 'checkbox',
  size: 'small',
};

const Checkbox = ({
  className,
  isChecked,
  children,
  isHalfChecked,
  primary,
  isDisabled,
  onChange,
  text,
  automationId,
  size,
}) => {
  const handleChange = (event) => {
    if (isDisabled) {
      return;
    }
    onChange(event.target.checked, event);
  };
  const isReducedSize = size === 'small' || undefined;

  const checkBox = (
    <MaterialCheckbox
      checked={!!isChecked}
      disabled={isDisabled}
      onClick={handleChange}
      indeterminate={isHalfChecked ? isChecked : undefined}
      size={size}
      sx={{
        '.MuiSvgIcon-fontSizeSmall': isReducedSize && {
          width: 15,
          transform: 'translateX(1px)',
          color: primary ? undefined : palette.gray['500'],
        },
      }}
    />
  );
  const content = text ? (
    <FormControlLabel
      label={
        <span className={styles.labelText}>
          {text}
          {children}
        </span>
      }
      control={checkBox}
      sx={{
        '&.MuiFormControlLabel-root': {
          margin: 0,
          paddingLeft: isReducedSize && '7px',
        },
        '.MuiFormControlLabel-label': {
          marginLeft: isReducedSize && '1px',
        },
      }}
    />
  ) : (
    checkBox
  );
  return (
    <div className={className} data-automation-id={automationId}>
      {content}
    </div>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
