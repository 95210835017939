import React from 'react';
import { useFormContext } from 'react-hook-form';

import ErrorFoundImg from 'shared/img/images/error_found.png';
import { CLOUD_TYPE_IDS, mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import CustomModal from 'shared/components/andtComponents/Modal';
import { getOnboardingError as getAzureOnboardingError } from '../../AzureOnboarding/utils/errors';
import { getOnboardingError as getAwsOnboardingError } from '../../AwsOnboarding/utils/errors';
import { getOnboardingError as getGCPOnboardingError } from '../../GcpOnboarding/utils/errors';
import { AZURE_ONBOARDING_FIELDS, AZURE_ONBOARDING_STEPS } from '../../AzureOnboarding/AzureOnboardingConstants';
import { AWS_ONBOARDING_FIELDS, AWS_ONBOARDING_STEPS } from '../../AwsOnboarding/AwsOnboardingConstants';
import styles from './ErrorModal.module.scss';
import { getAzureAccountTypeServer } from '../../AzureOnboarding/AzureOnboardingUtils';
import { GCP_ONBOARDING_FIELDS, GCP_ONBOARDING_STEPS } from '../../GcpOnboarding/GcpOnboardingConstants';

const ErrorModal = ({ status, cloudType, handleRestart }) => {
  const { setError, getValues, setValue } = useFormContext();
  const bucketName = getValues(AWS_ONBOARDING_FIELDS.BUCKET_NAME);
  const bucketRegion = getValues(AWS_ONBOARDING_FIELDS.BUCKET_REGION);
  const accountId = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID);
  const roleARN = getValues(AWS_ONBOARDING_FIELDS.ROLE_ARN);
  const getErrorFunction = () => {
    switch (cloudType) {
      case CLOUD_TYPE_IDS.AWS:
        return getAwsOnboardingError;
      case CLOUD_TYPE_IDS.AZURE:
        return getAzureOnboardingError;
      case CLOUD_TYPE_IDS.GCP:
        return getGCPOnboardingError;
      default:
        return getAwsOnboardingError;
    }
  };
  const errors = (
    status.validationStatus?.errors?.map((error) =>
      getErrorFunction()({
        code: error.code,
        accountId,
        bucketName,
        roleARN,
      }),
    ) || []
  ).filter(Boolean);
  return (
    <CustomModal
      open
      onClose={async () => {
        if (cloudType === CLOUD_TYPE_IDS.AWS) {
          const params = {
            accountId,
            accountName: getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_NAME),
            roleARN,
            cloudTypeId: cloudType,
            externalId: getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID),
            isReseller: +getValues(AWS_ONBOARDING_FIELDS.IS_MSP),
            s3BucketName: bucketName,
            s3BucketRegion: bucketRegion,
            [AWS_ONBOARDING_FIELDS.VALIDATION_STARTED]: false,
          };
          await handleRestart.mutateAsync(params);
          setValue(AWS_ONBOARDING_FIELDS.SCREEN, AWS_ONBOARDING_STEPS.AWS_DETAILS);
          setValue(AWS_ONBOARDING_FIELDS.VALIDATION_STARTED, false);
          return;
        }
        if (cloudType === CLOUD_TYPE_IDS.GCP) {
          const params = {
            accountId,
            cloudTypeId: cloudType,
            accountName: getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_NAME),
            isReseller: +getValues(AWS_ONBOARDING_FIELDS.IS_MSP),
            projectId: getValues(GCP_ONBOARDING_FIELDS.PROJECT_ID),
            billingDatasetName: getValues(GCP_ONBOARDING_FIELDS.BILLING_DATASET_NAME),
            billingTableName: getValues(GCP_ONBOARDING_FIELDS.BILLING_TABLE_NAME),
            serviceAccountKey: getValues(GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY),
            k8sDatasetId: getValues(GCP_ONBOARDING_FIELDS.K8S_DATASET_ID),
            k8sProjectId: getValues(GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID),
            currencyType: getValues(AZURE_ONBOARDING_FIELDS.CURRENCY_TYPE),
            [AWS_ONBOARDING_FIELDS.VALIDATION_STARTED]: false,
          };
          await handleRestart.mutateAsync(params);
          setValue(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED, false);
          setValue(GCP_ONBOARDING_FIELDS.SCREEN, GCP_ONBOARDING_STEPS.GCP_DETAILS);
          return;
        }
        const params = {
          accountId,
          accountName: getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME),
          applicationId: getValues(AZURE_ONBOARDING_FIELDS.APPLICATION_ID),
          directoryId: getValues(AZURE_ONBOARDING_FIELDS.DIRECTORY_ID),
          clientSecret: getValues(AZURE_ONBOARDING_FIELDS.CLIENT_SECRET),
          isReseller: +getValues(AZURE_ONBOARDING_FIELDS.IS_MSP),
          azureAccountType: getAzureAccountTypeServer(getValues(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE)),
          applicationDisplayName: getValues(AZURE_ONBOARDING_FIELDS.APPLICATION_DISPLAY_NAME),
          resellerCustomer: getValues(AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER),
          resellerCustomerId: getValues(AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID),
          cloudTypeId: cloudType,
          accountStorage: getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_STORAGE),
          containerName: getValues(AZURE_ONBOARDING_FIELDS.CONTAINER_NAME),
          directoryPrefix: getValues(AZURE_ONBOARDING_FIELDS.DIRECTORY_PREFIX),
          isMultipleFiles: +getValues(AZURE_ONBOARDING_FIELDS.IS_MULTIPLE_FILES),
          filterOutMicrosoft365: +getValues(AZURE_ONBOARDING_FIELDS.FILTER_OUT_MICROSOFT_365),
          autoCreateCustomersProperty: getValues(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY),
          isMultipleSources: +getValues(AZURE_ONBOARDING_FIELDS.IS_MULTIPLE_SOURCES),
          currencyType: getValues(AZURE_ONBOARDING_FIELDS.CURRENCY_TYPE),
        };
        await handleRestart.mutateAsync(params);
        setValue(AZURE_ONBOARDING_FIELDS.VALIDATION_STARTED, false);
        if (
          !status.validationStatus?.errors?.some(
            (err) =>
              err.code === 'ERR_HANDSHAKE_AZURE_CONTAINER_NOT_FOUND' ||
              err.code === 'ERR_HANDSHAKE_AZURE_STORAGE_NOT_AVAILABLE',
          )
        ) {
          setValue(AZURE_ONBOARDING_FIELDS.SCREEN, AZURE_ONBOARDING_STEPS.APP_REGISTRATION);
        }
        if (status.validationStatus?.errors.some((err) => err.code === 'ERR_HANDSHAKE_AZURE_CONTAINER_NOT_FOUND')) {
          setError(AZURE_ONBOARDING_FIELDS.CONTAINER_NAME, {
            type: 'custom',
            message: 'Container not found',
          });
        }
        if (status.validationStatus?.errors.some((err) => err.code === 'ERR_HANDSHAKE_AZURE_STORAGE_NOT_AVAILABLE')) {
          setError(AZURE_ONBOARDING_FIELDS.ACCOUNT_STORAGE, {
            type: 'custom',
            message: 'Storage account not available',
          });
        }
      }}
      closeOnOutside={false}
      overrideStyles={{
        width: 560,
      }}
      cancelHidden
      className={{
        title: styles.title,
      }}
      title="We encountered a problem"
      saveTitle="Update Details"
    >
      <div className={styles.errorModalBody}>
        <img src={ErrorFoundImg} alt="Error Found" />
        {errors.length > 0 ? (
          // eslint-disable-next-line react/no-array-index-key
          errors.map((err, index) => <p key={index}>{err}</p>)
        ) : (
          <p>
            The {mapCloudTypeIdToDescription.get(cloudType)?.toLowerCase()} account validation failed due to an error.
            Verify that the parameters you entered are correct and that all permissions have been granted.
            <br />
            Please contact our support team if you need assistance.
          </p>
        )}
      </div>
    </CustomModal>
  );
};

export default ErrorModal;
