import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { COST_CONFIGURATION } from 'recommendationsNew/consts';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import SeriesDataChart, { LINE_NAMES } from './seriesDataChart';
import BreakEvenChart from './breakEvenChart';
import GenericChart from './genericChart';

import classes from './recommendationChartData.module.scss';
import RecommendationOptionsContext from '../recommendationOptionsContext';
import { getNumericValue } from '../../recommendationPropertyUtils';
import { RecommendationTypes } from "recommendations/constants/recommendationsConstants";

export const CHART_TYPES = {
  SERIES_DATA: 'seriesData',
  BREAK_EVEN: 'breakEven',
  GENERIC: 'generic',
};

const RecommendationChartData = ({ chartsData }) => {
  const { selectedOptionIndex } = useContext(RecommendationOptionsContext);

  const createSeriesChartData = (data) => {
    const seriesTypes = [
      { property: LINE_NAMES.MAX, value: data.maxDataProperty },
      { property: LINE_NAMES.AVG, value: data.avgDataProperty },
      { property: LINE_NAMES.ESTIMATED, value: data.estimatedDataProperty },
      { property: LINE_NAMES.PERCENTILE, value: data.percentileDataProperty },
      { property: LINE_NAMES.PERCENTILE99, value: data.percentile99DataProperty },
      { property: LINE_NAMES.REQUEST, value: data.requestDataProperty },
    ];

    if (!data.usageDateProperty && !data.maxDataProperty) {
      return null;
    }
    let chartData = [];
    try {
      const arrOfDates = Object.keys(data.usageDateProperty || data.maxDataProperty);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      chartData = arrOfDates.map((date) => {
        const chartDataTmp = {
          usageDate: createDateDisplayStr(date),
        };
        seriesTypes.forEach((seriesType) => {
          // Don't add property if the data for the chart doesn't exist
          if (seriesType.value) {
            chartDataTmp[seriesType.property] =
              seriesType.value[date] !== undefined ? parseFloat(seriesType.value[date]) : null;
          }
        });
        return chartDataTmp;
      });
    } catch (error) {
      // Error
    }
    return { data: chartData };
  };

  const createGenericChartData = (data) => {
    let chartData = [];
    try {
      const arrOfDates = Object.keys(data.linesList[0].data);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      chartData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        ...{
          ...data.linesList.reduce((acc, line) => {
            acc[line.id] = line.data[date] ? parseFloat(line.data[date]) : null;
            return acc;
          }, {}),
        },
      }));
    } catch (error) {
      // Error
    }
    return { data: chartData };
  };

  const createBreakEvenChartData = (recommendation) => {
    const { annualCurrentCost, annualSavings } = recommendation;
    const alternative = recommendation?.recData?.alternatives?.[selectedOptionIndex];
    const monthlyCurrentCost = annualCurrentCost?.[COST_CONFIGURATION.UNBLENDED] / 12;

    const getRecommendedAnnualCost = (alternative) => {
      if (alternative.annual_cost) {
        return getNumericValue(alternative.annual_cost);
      }
      return annualCurrentCost?.[COST_CONFIGURATION.UNBLENDED] - getNumericValue(alternative.saving_amount);
    };

    const riCost =
      selectedOptionIndex >= 0 && alternative
        ? getRecommendedAnnualCost(alternative)
        : annualCurrentCost?.[COST_CONFIGURATION.UNBLENDED] - annualSavings?.[COST_CONFIGURATION.UNBLENDED];
    const breakEvenData = [];
    for (let i = 1; i <= 12; ++i) {
      breakEvenData.push({ name: i, 'RI Cost': riCost, 'On-Demand Cost': (monthlyCurrentCost * i).toFixed(1) });
    }
    return breakEvenData;
  };

  return (
    <div className={classes.chartsContainer}>
      {chartsData?.map((data, index) => (
        <div className={classes.chartContainer} key={index}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{data.chartTitle}</div>
            {data.info && (
              <span className={classes.info}>
                <InfoPopover>{data.info}</InfoPopover>
              </span>
            )}
          </div>
          {data.chartType === CHART_TYPES.SERIES_DATA && (
            <SeriesDataChart
              // To re-render the chart on selected option change, since the data is not always changing between options
              key={selectedOptionIndex}
              data={createSeriesChartData(data)?.data}
              yAxisLabel={data?.yAxisLabel}
              yAxisUnit={data?.yAxisUnit}
              effectiveTimeFrame={data.effectiveTimeFrame}
            />
          )}
          {data.chartType === CHART_TYPES.GENERIC && (
            <GenericChart
              data={createGenericChartData(data)?.data}
              yAxisLabel={data?.yAxisLabel}
              yAxisUnit={data?.yAxisUnit}
              linesList={data?.linesList}
              referenceLinesList={data?.referenceLines}
              effectiveTimeFrame={data.effectiveTimeFrame}
            />
          )}
          {data.chartType === CHART_TYPES.BREAK_EVEN && (
            <BreakEvenChart
              data={createBreakEvenChartData(data?.recommendation)}
              label={data?.recommendation?.typeId === RecommendationTypes.GCP_USAGE_COMMITMENT ? 'CUD' : null}
            />
          )}
        </div>
      ))}
    </div>
  );
};

RecommendationChartData.propTypes = {
  chartsData: PropTypes.array.isRequired,
};

RecommendationChartData.defaultProps = {};

export default RecommendationChartData;
