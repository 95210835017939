import React, { useContext } from 'react';
import { useReports } from 'usage/hooks/react-query/useReports';

const ReportsContext = React.createContext();

export function withReportsContextProvider(Component) {
  return function ReportsContextProvider({ ...props }) {
    const {
      fetchReports,
      useSaveCueReport,
      useSaveAssetReport,
      useSendReport,
      useSaveCustomDashboardReport,
      useDeleteReport,
    } = useReports();
    // eslint-disable-next-line react/prop-types
    const { currentDisplayedUserKey, currDispUserAccountKey } = props.usersStore;
    const { data: reports = [], isLoading } = fetchReports(currentDisplayedUserKey, currDispUserAccountKey);
    const { mutate: saveCueReport } = useSaveCueReport();
    const { mutate: saveAssetReport } = useSaveAssetReport();
    const { mutate: sendReport } = useSendReport();
    const { mutate: saveCustomDashboardReport } = useSaveCustomDashboardReport();
    const { mutate: deleteReport } = useDeleteReport();
    const data = {
      reports,
      reportsLoading: isLoading,
      saveCueReport,
      saveAssetReport,
      sendReport,
      saveCustomDashboardReport,
      deleteReport,
    };
    return (
      <ReportsContext.Provider value={data}>
        <Component {...props} {...data} />
      </ReportsContext.Provider>
    );
  };
}

export function withReportsContextConsumer(Component) {
  return function ReportsContextConsumer({ ...props }) {
    return <ReportsContext.Consumer>{(data) => <Component {...data} {...props} />}</ReportsContext.Consumer>;
  };
}

export const useReportsContext = () => useContext(ReportsContext);
