import React from 'react';

export const COLORS = ['#FF8F23', '#ED9FAF', '#2671FF'];

export const RECT_PROPORTIONS = [1, 2, 3, 4];

export const ROTATIONS = ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'];

export const OPACITIES = [0.35, 0.5, 0.65, 0.85, 1];

export const SHAPES = {
  triangle: (fill, opacity) => (
    <svg width="13" height="14" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8568 13.8845L0.347244 5.67352L12.7129 0.677473L10.8568 13.8845Z" fill={fill} fillOpacity={opacity} />
    </svg>
  ),
  star4: (fill, opacity) => (
    <svg width="20" height="20" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M14.0115 9.88983L8.42372 10.3106L5.10583 14.8263L4.68508 9.23855L0.169337 5.92066L5.75712 5.4999L9.07501 0.984161L9.49576 6.57194L14.0115 9.88983Z"
        fill={fill}
        fillOpacity={opacity}
      />
    </svg>
  ),
  star5: (fill, opacity) => (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M14.9172 0.945058L13.5032 8.21964L19.9848 11.8124L12.6293 12.7156L11.2152 19.9901L8.08331 13.2737L0.727818 14.1769L6.14768 9.12278L3.01577 2.40637L9.49735 5.99917L14.9172 0.945058Z"
        fill={fill}
        fillOpacity={opacity}
      />
    </svg>
  ),
  star6: (fill, opacity) => (
    <svg width="20" height="20" viewBox="0 0 26 28" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M1.37455 21.0213L7.81375 13.9901L0.99481 7.32655L10.3036 9.38747L12.6649 0.15032L15.5345 9.24242L24.7148 6.66882L18.2756 13.7L25.0945 20.3636L15.7857 18.3026L13.4244 27.5398L10.5548 18.4477L1.37455 21.0213Z"
        fill={fill}
        fillOpacity={opacity}
      />
    </svg>
  ),
  rect: (fill, opacity, proportion) => (
    <svg width={20} height={20 * proportion} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <rect width={20} height={20} fill={fill} fillOpacity={opacity} />
    </svg>
  ),
  circle: (fill, opacity, width, height) => (
    <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <circle r={20} fill={fill} fillOpacity={opacity} />
    </svg>
  ),
};
