export const segmentPageEvent = (usersStore) => {
  if (!window.analytics || !usersStore) {
    return;
  }
  const customerInfo = usersStore.getUserDataForSegment();
  const pageInfo = {
    hash: window.location.pathname,
  };

  window.analytics.page(pageInfo.hash, {
    ...customerInfo,
    ...pageInfo,
  });
};

export const segmentEvent = (payload, usersStore) => {
  if (!window.analytics) {
    return;
  }
  const customerInfo = usersStore ? usersStore.getUserDataForSegment() : {};
  const pageInfo = {
    hash: window.location.pathname,
  };
  const { type, ...payloadParams } = payload;
  if (type === 'page') {
    window.analytics.page(type, {
      ...customerInfo,
      ...pageInfo,
      ...payloadParams,
    });
  } else {
    window.analytics.track(type || 'click-event', {
      ...customerInfo,
      ...pageInfo,
      ...payloadParams,
    });
  }
};

export const initAprinsic = (data, userHash) => {
  if (!window.aptrinsic) {
    // eslint-disable-next-line no-console
    console.warn('Aptrinsic load failure');
  } else {
    // Identify user at Gainsight
    window.aptrinsic(
      'identify',
      {
        id: data.userKey,
        email: data.email,
        userName: data.userName,
        userType: data.userType,
        userHash,
      },
      {
        id: data.accountId,
        name: data.account,
        accountType: data.accountType,
        isMSP: data.isMSP,
        companyName: data.companyName,
      },
    );
    window.aptrinsic('set', 'globalContext', {});
    // Report FF based on
    // eslint-disable-next-line max-len
    // https://support.gainsight.com/PX/Integrations/01Technology_Partner_Integrations/Integrate_PX_with_Product_Feature_Flag
    // -- NOT CURRENTLY IN USE ---
    // window.aptrinsic(
    //   'set',
    //   'globalContext',
    //   get(me, 'organization.serverConfiguration.featureFlags.featuresActivation', {}),
    // );
  }
};

export const initSegment = (data) => {
  if (!window.analytics) {
    return;
  }
  const pageInfo = {
    hash: window.location.pathname,
  };
  window.analytics.identify(data.userKey, {
    ...data,
    ...pageInfo,
  });
};
