import { palette } from 'shared/constants/colorsConstants';

export const PAYMENT_OPTIONS = ['all upfront', 'partial upfront', 'no upfront'];

export const TERMS = ['1-year', '3-year'];

export const SP_TABS = [
  { id: '0', name: 'Recommended Plan', title: '', icon: 'memoPad' },
  { id: '1', name: `Forecasted Plans`, title: '', icon: 'binoculars' },
];

export const SIMULATION_PROPERTIES = [

  { name: 'Total Commitment', key: 'totalYearCommitment', isCurrency: true, info: 'Cost figures are based on days-to-check and normalized to a month' },
  { name: 'Upfront Cost', key: 'recommendedUpfrontPayment', isCurrency: true, info: 'Cost figures are based on days-to-check and normalized to a month' },
  { name: 'Monthly Recurring Charges', key: 'monthlyRecurringCharges', isCurrency: true, info: 'Cost figures are based on days-to-check and normalized to a month' },
  { name: 'Current monthly compute cost', key: 'simulatedCurrentMonthlyNetCost', isCurrency: true, info: 'Cost figures are based on days-to-check and normalized to a month', isShareProperty: true },
  { name: 'Expected monthly compute cost', key: 'recommendedMonthlyNetCost', isCurrency: true, info: 'Cost figures are based on days-to-check and normalized to a month' },
  {
    name: 'Current On-Demand Cost',
    key: 'simulatedCurrentMonthlyOdCost',
    isShareProperty: true,
    isCurrency: true,
    info: `Cost figures are based on days-to-check and normalized to a month`,
  },
  {
    name: 'Estimated On-Demand Cost',
    key: 'recommendedMonthlyOdCost',
    info: 'Cost figures are based on days-to-check and normalized to a month',
    isCurrency: true,
  },
]
export const CURRENT_PROPERTIES = [
  {
    name: 'Current Monthly Compute Costs',
    key: 'currentMonthlyNetCost',
    isShareProperty: true,
    isCurrency: true,
    info: `Cost figures are based on days-to-check and normalized to a month`,
  },
  {
    name: 'Current On-Demand Cost',
    key: 'currentMonthlyOdCost',
    isShareProperty: true,
    info: 'Cost figures are based on days-to-check and normalized to a month',
    isCurrency: true,
  },
]
export const RECOMMENDATION_PLAN_PROPERTIES_OLD = [
  { name: 'Hourly Commitment', key: 'recommendedHourlyCommitment', isEditable: true, groupHeader: 'Plan' },
  { name: 'Type', key: 'type', isShareProperty: true },
  { name: 'Term', key: 'term' },
  { name: 'Upfront Cost', key: 'recommendedUpfrontPayment', isCurrency: true },
  { name: 'Monthly Recurring Charges', key: 'monthlyRecurringCharges', isCurrency: true },
  { name: 'Total Commitment', key: 'totalYearCommitment', isCurrency: true, isEndGroup: true },
  {
    name: 'Actual monthly EC2 compute cost',
    key: 'currentMonthlyNetCost',
    isShareProperty: true,
    isCurrency: true,
    groupHeader: 'Estimated Savings Based Actual Costs',
    headerInfo:
      'Savings based on your actual costs and coverage from the "days to check" period, normalized to a month.',
  },
  { name: 'Expected monthly EC2 compute cost', key: 'recommendedMonthlyNetCost', isCurrency: true },
  {
    name: 'Estimated Actual Savings',
    key: 'totalSaving',
    calculatedFields: ['currentMonthlyNetCost', 'recommendedMonthlyNetCost'],
    isCurrency: true,
    isEndGroup: true,
  },
  {
    name: 'Expected monthly EC2 compute costs (simulated)',
    groupHeader: 'Estimated Savings Based Simulated Costs',
    headerInfo: `The simulation calculates your costs for
     the entire month based on today's coverage.
     
     E.g. if last month you had 80% commitment coverage that just got expired,
     and today you have 100% On-Demand costs, the simulation will use only
     On-Demand costs for the full period, as this reflects your expected future costs.`,
    key: 'simulatedCurrentMonthlyNetCost',
    info: `Simulation of a monthly cost based on today's coverage percentage (As the saving plans analyzer algorithm).`,
    isShareProperty: true,
    isCurrency: true,
  },
  {
    name: 'Estimated Simulated Savings',
    key: 'totalSaving',
    calculatedFields: ['simulatedCurrentMonthlyNetCost', 'recommendedMonthlyNetCost'],
    isCurrency: true,
    isEndGroup: true,
  },
  {
    name: 'Current On Demand Cost',
    key: 'currentMonthlyOdCost',
    isShareProperty: true,
    isCurrency: true,
    groupHeader: 'On-Demand costs',
    headerInfo: `Estimated costs based on selected "days to check" and normalized for a month.`,
  },
  {
    name: 'Estimated On Demand Cost',
    key: 'recommendedMonthlyOdCost',
    info: 'Estimated costs based on selected "days to check" and normalized for a month.',
    isCurrency: true,
  },
];

export const RECOMMENDATION_PLAN_PROPERTIES = [
  { name: 'Hourly Commitment', key: 'recommendedHourlyCommitment', isEditable: true },
  {
    name: 'Estimated Savings',
    key: 'totalSaving',
    calculatedFields: [{key: 'simulatedCurrentMonthlyNetCost', isShareProperty: true}, { key:'recommendedMonthlyNetCost', isShareProperty: false}],
    isCurrency: true,
  },
];
export const EXPORT_COLUMNS = [
  { label: 'Date', key: 'date' },
  { label: 'Current Coverage', key: 'currentSP' },
  { label: 'Recommended Coverage', key: 'recommendedSP' },
  { label: 'On Demand Equivalent', key: 'onDemand' },
  { label: 'Current Simulate On-Demand', currentLabel: 'Current On-Demand', key: 'actualOnDemand', isSimulateColumn: false },
  { label: 'Recommended On-Demand', key: 'actualAccOnDemand', isEstimateColumn: true },
  { label: 'Current Simulate Savings Plan', currentLabel: 'Current Savings Plan', key: 'hourlyCommitment' , isSimulateColumn: false },
  { label: 'Recommended Savings Plan', key: 'accHourlyCommitment', isEstimateColumn: true },


];
export const CHART_TYPES = {
  onDemand: 'onDemand',
  actual: 'actual',
};

export const ON_DEMAND_CHART_LEGEND = {

  simulate: [
    {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', opacity: 0.2},
    {key: 'recommendedSP', name: 'Recommended Coverage', color: palette.blue[500], opacity: 0.2},
    {key: 'onDemand', name: 'On Demand Equivalent', color: '#CD84E7', opacity: 0.2},
  ],
  current: [ {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', opacity: 0.2},
    {key: 'onDemand', name: 'On Demand Equivalent', color: '#CD84E7', opacity: 0.2},]
};
export const ACTUAL_CHART_LEGEND = {
  simulate: [
      {
        key: 'hourlyCommitment',
        name: 'Current Simulate Savings Plan ',
        color: '#00BBD4',
        isEstimated: false,
        opacity: 0.4,
        estimateOpacity: 0.2,
        isDash: true,
      },
      { key: 'accHourlyCommitment', name: 'Recommended Savings Plan', color: '#00BBD4', isEstimated: true, opacity: 0.3 },
      {
        estimateKey: 'netCost',
        key: 'actualOnDemand',
        exportKey: 'actualOnDemand',
        name: 'Current Simulate On-Demand',
        color: '#CD84E7',
        isGradientFill: true,
        isEstimated: false,
        opacity: 0.4,
        estimateOpacity: 0.4,
        isDash: true,
      },
      {
        key: 'accNetCost',
        exportKey: 'actualAccOnDemand',
        name: 'Recommended On-Demand',
        color: '#CD84E7',
        isEstimated: true,
        opacity: 0.3,
      }],
  current: [
    {
      key: 'hourlyCommitment',
      name: 'Current Savings Plan ',
      color: '#00BBD4',
      isEstimated: false,
      opacity: 0.4,
      estimateOpacity: 0.2,
      isDash: true,
    },
    {
      estimateKey: 'netCost',
      key: 'actualOnDemand',
      exportKey: 'actualOnDemand',
      name: 'Current On-Demand',
      color: '#CD84E7',
      isEstimated: false,
      opacity: 0.4,
      estimateOpacity: 0.2,
      isDash: true,
    },
  ]};
export const ON_DEMAND_TP_MAP_DATA = {
  simulate: {
    regularMode: {
      fields: [
        {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', percentOf: 'onDemand'},
        {key: 'recommendedSP', name: 'Recommended Coverage', color: palette.blue[500], percentOf: 'onDemand', isBold: true},
        {key: 'onDemand', name: 'On Demand Equivalent', color: '#CD84E7'},
      ]
    },
    tpType: 'onDemand',
  },
  current: {
    regularMode: {
      fields: [
        {key: 'currentSP', name: 'Current Coverage', color: '#21B1D2', percentOf: 'onDemand'},
        {key: 'onDemand', name: 'On Demand Equivalent', color: '#CD84E7'},
      ]
    },
    tpType: 'onDemand',
  }
};
export const ACTUAL_TP_MAP_DATA = {
  simulate: {
    regularMode: {
      fields: [
        {name: 'Current Simulated Savings Plan', key: 'hourlyCommitment', isDash: true, color: '#00BBD4'},
        {name: 'Current Simulated On-Demand', key: 'actualOnDemand', isDash: true, color: '#CD84E7'},
      ],
      summary: [{name: 'Total Current', displayField: 'netCost'}],
    },
    simulateMode: {
      fields: [
        {name: 'Simulate Savings Plan', key: 'simulateHourlyCommitment', color: '#00BBD4'},
        {name: 'Simulate On-Demand', key: 'actualSimulateOnDemand', color: '#CD84E7'},
      ],
      summary: [{name: 'Simulate Cost', displayField: 'simulateNetCost'}],
    },
    estimatedMode: {
      fields: [
        {
          header: 'On-Demand',
          subFields: [
            {name: 'Current', key: 'actualOnDemand', isDash: true, color: '#CD84E7'},
            {name: 'Recommended', key: 'actualAccOnDemand', color: '#CD84E7'},
          ],
        },
        {
          header: 'Savings Plan',
          subFields: [
            {name: 'Recommended', key: 'accHourlyCommitment', color: '#00BBD4'},
            {name: 'Current', key: 'hourlyCommitment', isDash: true, color: '#00BBD4'},
          ],
        },
      ],
      summary: [
        {name: 'Current', key: 'netCost'},
        {name: 'Recommended', key: 'accNetCost'},
        {
          name: 'Savings',
          minusFields: ['netCost', 'accNetCost'],
        },
      ],
    },
    simulateEstimatedMode: {
      fields: [
        {name: 'Recommended Savings Plan', key: 'accHourlyCommitment', color: '#00BBD4'},
        {name: 'Estimated On-Demand', key: 'actualAccOnDemand', color: '#CD84E7'},
      ],
      summary: [
        {name: 'Estimated Cost', displayField: 'accNetCost'},
        {
          name: 'Estimated Savings',
          minusFields: ['simulateNetCost', 'accNetCost'],
        },
        {name: 'Simulate Cost', displayField: 'simulateNetCost'},
      ],
    },
    tpType: 'actual',
  },
  current: {
    regularMode: {
      fields: [
        {name: 'Current On-Demand', key: 'actualOnDemand', isDash: true, color: '#CD84E7'},
        {name: 'Current Savings Plan', key: 'hourlyCommitment', isDash: true, color: '#00BBD4'},
      ],
      summary: [{name: 'Total Current', displayField: 'netCost'}],
    },
    tpType: 'actual',
  }

};

export const ON_DEMAND_CHART = {
  simulate: {
    header: 'Simulated On-Demand Equivalent (Coverage)',
    info: (
        <>
          In this chart:
          <ul style={{paddingLeft: '20px'}}>
            <li>All usage is assumed on-demand.</li>
            <li>Simulate your current and recommended coverage relative to the on-demand equivalent.</li>
            <li>The lines are independent</li>
          </ul>
        </>
    ),
        // 'Simulate your costs based on your current commitments. This chart displays your costs as if all usage were on-demand (the on-demand equivalent), along with your current and recommended covered costs.'
  },
  current : {
    header: 'On-Demand Equivalent (Coverage)',
    info: 'This chart displays your costs as if all usage were on-demand (the on-demand equivalent), along with your current covered costs.'
  }
};
export const ACTUAL_CHART = {
  simulate: {
    header: 'Simulated Compute Cost',
    info: (
        <>
            In this chart:
            <ul style={{paddingLeft: '20px'}}>
              <li>Existing SPs are included</li>
              <li>The presented cost is cumulative</li>
            </ul>
        </>
    ),
    // 'Simulate your costs based on your current commitments. This chart displays your costs as if all usage were on-demand (the on-demand equivalent), along with your current and recommended covered costs.'
  }, current: {
    header: 'Current Compute Cost',
    info: `This chart displays your current savings plans and on-demand costs for the chosen period, determined by the 'days-to-check'. Note: These costs are accumulated.`
  },
};
export const PLAN_KEYS_SORT_ORDER = ['1YearNoUpfront', '1YearPartialUpfront', '1YearAllUpfront', '3YearNoUpfront', '3YearPartialUpfront', '3YearAllUpfront'];
