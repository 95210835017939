/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { MoreVertical } from 'react-feather';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import { ReactComponent as PencilIcon } from 'shared/img/icons/pencil.svg';
import { ReactComponent as DeleteIcon } from 'shared/img/icons/trash.svg';
import { ReactComponent as CalendarIcon } from 'shared/img/icons/calendar.svg';
import { ReactComponent as PersonIcon } from 'shared/img/icons/person.svg';
import { ReactComponent as StarIcon } from 'shared/img/icons/star.svg';
import { ReactComponent as CloneIcon } from 'shared/img/icons/clone.svg';
import { ReactComponent as PinIcon } from 'shared/img/icons/pin.svg';
import { ACCESS_LEVELS } from 'shared/components/SaveShareSettingsModal';

const CustomDashboardActionButton = ({
  currDispUserCloudAccId,
  dashboard,
  currentDisplayedUserKey,
  showEdit,
  dashboardHandlers,
  showDelete,
  showSchedule,
  currDispUserAdminRole,
  currDispUserAccountKey,
}) => {
  const isDeleteDashboardAvailable = (isShowDelete, cloudAccountId) =>
    isShowDelete &&
    cloudAccountId === currDispUserCloudAccId &&
    (!dashboard.setting || dashboard.userKey === currentDisplayedUserKey);

  const isEditAvailable =
    showEdit &&
    (!dashboard.setting || // no setting means it is with level of Current Role as default
      dashboard.setting?.level === ACCESS_LEVELS.EVERYONE ||
      dashboard.userKey === currentDisplayedUserKey);
  const { isDefault } = dashboard;
  const {
    editDashboard,
    deleteDashboard,
    scheduleDashboardReport,
    makeDefaultDashboard,
    shareSettingsModal,
    templateDashboardModal,
    cloneDashboardModal,
  } = dashboardHandlers;
  const options = [
    {
      text: isDefault ? 'Remove Default' : 'Set as Default',
      Icon: StarIcon,
      disabled: +currDispUserAccountKey !== +dashboard.accountKey,
      onClick: () => makeDefaultDashboard(dashboard),
    },
    {
      text: 'Permissions settings',
      Icon: PersonIcon,
      disabled: currentDisplayedUserKey !== dashboard.userKey || +currDispUserAccountKey !== +dashboard.accountKey,
      onClick: () => shareSettingsModal(dashboard),
    },
    +currDispUserAccountKey !== +dashboard.accountKey || !currDispUserAdminRole
      ? null
      : {
          text: 'Save as template',
          Icon: PinIcon,
          onClick: () => templateDashboardModal(dashboard),
        },
    {
      text: 'Schedule Report',
      Icon: CalendarIcon,
      disabled: !showSchedule,
      onClick: () => scheduleDashboardReport(dashboard),
    },
    {
      text: 'Edit',
      Icon: PencilIcon,
      disabled: !isEditAvailable,
      onClick: () => editDashboard(dashboard),
    },
    {
      text: 'Clone',
      Icon: CloneIcon,
      disabled: !isEditAvailable,
      onClick: () => cloneDashboardModal(dashboard),
    },
    {
      text: 'Delete',
      Icon: DeleteIcon,
      disabled: !isDeleteDashboardAvailable(showDelete, dashboard.accountId),
      onClick: () => deleteDashboard(dashboard),
    },
  ];
  return (
    <ButtonDropdown icon={MoreVertical} isTextButton>
      {options.filter(Boolean).map(({ text, Icon, disabled, onClick }) => (
        <li key={text} data-disabled={disabled} onClick={disabled ? null : onClick}>
          <span>
            <Icon />
            {text}
          </span>
        </li>
      ))}
    </ButtonDropdown>
  );
};

CustomDashboardActionButton.propTypes = {
  dashboardHandlers: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  currentDisplayedUserKey: PropTypes.string.isRequired,
  currDispUserAccountKey: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  currDispUserAdminRole: PropTypes.bool,
  showSchedule: PropTypes.bool,
  currDispUserCloudAccId: PropTypes.string.isRequired,
};
CustomDashboardActionButton.defaultProps = {
  showDelete: true,
  showEdit: true,
  currDispUserAdminRole: false,
  showSchedule: true,
};

export default observer(CustomDashboardActionButton);
