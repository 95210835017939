import { FilterTypes } from 'usage/constants/usageConstants';
import {RECOMMENDATION_PLAN_PROPERTIES} from "commitment/containers/spAnalyzerNew/utils/consts.js";
export const prepareSPAnalyzerFilters = (selectedOptions, filterStatusType) => {
  let includeFilterParams = '';
  let excludeFilterParams = '';
  Object.entries(selectedOptions || {}).forEach(([filter, filterValues]) => {
    if (filterValues && filterValues.length) {
      filterValues.forEach((filterValue) => {
        if (filterStatusType[filter] === FilterTypes.INCLUDE) {
          includeFilterParams = includeFilterParams.concat(`&includeFilters[${filter}]=${filterValue.value}`);
        } else if (filterStatusType[filter] === FilterTypes.EXCLUDE) {
          excludeFilterParams = excludeFilterParams.concat(`&excludeFilters[${filter}]=${filterValue.value}`);
        }
      });
    }
  });

  return { includeFilterParams, excludeFilterParams };
};

export const getPropertyValue = (prop, shareProperties, plan, getCurrencyNumber) => {
  const value = prop.isShareProperty ? shareProperties?.[prop.key] : plan?.[prop.key];
  return prop.isCurrency ? getCurrencyNumber(Number(value)) : value;
};

export const calculateFieldValue = (prop, shareProperties, plan, getCurrencyNumber) => {
  const calculatedFields = prop.calculatedFields.map((field) => {
    const prop = RECOMMENDATION_PLAN_PROPERTIES.find((prop) => prop.key === field);
    if(!prop) {
      return  field.isShareProperty ? shareProperties?.[field.key] : plan?.[field.key]
    }
    return prop.isShareProperty ? shareProperties?.[prop.key] : plan?.[prop.key];
  });
  return getCurrencyNumber(calculatedFields.reduce((a, b) => Number(a) - Number(b)));
};
