import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti } from '@pileus-cloud/anodot-frontend-common';
import { uniqBy } from 'lodash';
import styles from './Filters.module.scss';

const Filters = ({ filters, setFilters, divisions }) => {
  const divisionsNames = useMemo(
    () => divisions.map((division) => division.divisionName).map((v) => ({ label: v, value: v })),
    [divisions],
  );
  const divisionsCodes = useMemo(
    () =>
      uniqBy(
        divisions.map((division) => division.divisionCode).map((v) => ({ label: v, value: v })),
        'value',
      ),
    [divisions],
  );
  const linkedAccountNames = useMemo(
    () =>
      divisions
        .map((division) => division.linkedAccounts)
        .flat()
        .map(({ linkedAccountName, linkedAccountId }) => ({
          label: linkedAccountName ? `${linkedAccountName} (${linkedAccountId})` : linkedAccountId,
          value: linkedAccountId,
        })),
    [divisions],
  );
  return (
    <div className={styles.container}>
      <div>
        <SelectMulti
          label="Linked Account"
          options={linkedAccountNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, linkedAccounts: vals });
          }}
          selected={filters.linkedAccounts}
        />
      </div>
      <div>
        <SelectMulti
          label="Cost Center Name"
          width={250}
          options={divisionsNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, divisionsNames: vals });
          }}
          selected={filters.divisionsNames}
        />
      </div>
      <div>
        <SelectMulti
          label="Cost Center Code"
          options={divisionsCodes}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, divisionsCodes: vals });
          }}
          selected={filters.divisionsCodes}
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  divisions: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default Filters;
