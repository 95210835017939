export const prepareCloudHirarcheyObject = (accounts) => {
  const cloudAndAccountsObj = accounts.reduce((acc, currAcc) => {
    const currCloudProviderId = currAcc.cloudTypeId;
    if (currCloudProviderId in acc) {
      const arrCurrentAccounsInObj = [...acc[currCloudProviderId]];
      acc[currCloudProviderId] = [...arrCurrentAccounsInObj, currAcc];
    } else {
      acc[currCloudProviderId] = [currAcc];
    }
    return acc;
  }, {});
  return { cloudAndAccountsObj };
};

// group cloud accounts for each customer division by cloud type
export const prepareCustomerDivisionObject = (customerDivisionsList) => {
  if (!customerDivisionsList) {
    return {};
  }
  const customerList = { ...customerDivisionsList };
  const customerDivisionKeys = Object.keys(customerList);
  customerDivisionKeys.forEach((customerDivision) => {
    const groupedItems = customerDivisionsList[customerDivision]?.reduce((customer, item) => {
      const currCloudProviderId = item.cloudTypeId;
      const updatedCustomer = { ...customer };
      if (currCloudProviderId in updatedCustomer) {
        const arrCurrentAccounsInObj = [...customer[currCloudProviderId]];
        updatedCustomer[currCloudProviderId] = [...arrCurrentAccounsInObj, item];
      } else {
        updatedCustomer[currCloudProviderId] = [item];
      }
      return updatedCustomer;
    }, {});
    customerList[customerDivision] = groupedItems;
  });
  return { customerList };
};

export const getFilteredAccounts = (cloudAndAccountsObj, searchInput) => {
  if (!searchInput) {
    return cloudAndAccountsObj;
  }
  const filteredAccountsObj = {};
  [...Object.entries(cloudAndAccountsObj)].forEach(([cloudTypeId, accounts]) => {
    const fAccounts = accounts.filter(({ accountName, accountId }) =>
      [accountName, accountId].some((str) => str.toLowerCase().includes(searchInput.toLowerCase())),
    );
    if (fAccounts.length) {
      filteredAccountsObj[cloudTypeId] = fAccounts;
    }
  });
  return filteredAccountsObj;
};

export const getFilteredCustomers = (customerList, searchInput) => {
  if (!searchInput) {
    return customerList;
  }
  const filteredAccountsObj = {};

  Object.keys(customerList).forEach((key) => {
    // filter by customer division name
    if (key.toLowerCase().includes(searchInput.toLowerCase())) {
      filteredAccountsObj[key] = customerList[key];
    } else {
      // filter by account name in customer accounts list
      const customerDivisionKeys = Object.keys(customerList[key]);
      customerDivisionKeys.forEach((cloudId) => {
        const fAccounts = customerList[key][cloudId].filter(({ accountName, accountId }) =>
          [accountName, accountId].some((str) => str.toLowerCase().includes(searchInput.toLowerCase())),
        );
        if (fAccounts.length) {
          if (!filteredAccountsObj[key]) {
            filteredAccountsObj[key] = {};
          }
          filteredAccountsObj[key][cloudId] = fAccounts;
        }
      });
    }
  });
  return filteredAccountsObj;
};
