import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  ENGINE_PROPERTIES,
  EXTENDED_SUPPORT_PROPERTIES,
  OPTIONS_PROPERTIES,
  RDS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';

const RdsExtendedSupport = ({ recommendation }) => {
  const properties = {
    ...BASE_PROPERTIES,
    ...COST_PROPERTIES,
    ...EXTENDED_SUPPORT_PROPERTIES,
    ...RDS_PROPERTIES,
    ...ENGINE_PROPERTIES,
  };

  const description = `AWS RDS extended support allows you to continue running your database
                       on a major engine version past the RDS end of standard support date for an additional cost.
                       We recommend you use a cluster/instance version in standard support to get the best pricing.`;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} viewAll />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const CURRENT_PROPERTIES = [
    properties.VERSION,
    properties.EXTENDED_SUPPORT_TYPE,
    properties.START_OF_EXTENDED_SUPPORT_DATE,
    properties.END_OF_EXTENDED_SUPPORT_DATE,
    properties.CURRENT_ANNUAL_COST,
    properties.DB_CONFIG,
    properties.ENGINE,
    properties.CURRENT_INSTANCE_TYPE,
    properties.PHYSICAL_PROCESSOR,
    properties.CPU,
    properties.MEMORY,
    properties.RI_COVERAGE,
    properties.STORAGE,
  ];

  const ALTERNATIVE_PROPERTIES = [
    properties.RECOMMENDED_VERSION,
    properties.RECOMMENDED_SUPPORT_TYPE,
    properties.RECOMMENDED_END_OF_EXTENDED_SUPPORT_DATE,
    properties.RECOMMENDED_ANNUAL_COST,
    OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
    properties.ENGINE,
  ];

  const alternatives = recommendation?.recData?.alternatives;
  const optionsPanelComponent =
    Array.isArray(alternatives) && alternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={alternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        noticeComment={
          properties.START_OF_EXTENDED_SUPPORT_DATE.getterFunction(recommendation) > new Date()
            ? 'Early notice for extended support!'
            : ''
        }
        minHeight={380}
        recommendation={recommendation}
        isRecommendedOption
      />
    ) : null;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      optionsPanel={optionsPanelComponent}
      preferences={preferencesComponent}
    />
  );
};

RdsExtendedSupport.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RdsExtendedSupport;
