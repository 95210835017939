import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UpArrow } from 'shared/img/icons/upArrowSvg.svg';
import { ReactComponent as DownArrow } from 'shared/img/icons/downArrowSvg.svg';
import { numberWithCommas } from 'shared/utils/strUtil';
import styles from './percentChange.module.scss';

const propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({ direction: PropTypes.string.isRequired }).isRequired,
};

const PercentChange = ({ value, row }) => {
  const isDrop = row.direction.toLowerCase() === 'down';
  return (
    <div className="d-flex align-items-center">
      <span className={`me-1 ${styles[isDrop ? 'down' : 'up']}`}>{`${numberWithCommas(Math.min(value, 1000))}%`}</span>
      {isDrop ? <DownArrow className={styles['down-arrow']} /> : <UpArrow className={styles['up-arrow']} />}
    </div>
  );
};

PercentChange.propTypes = propTypes;
export default PercentChange;
