import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { AwsCommonFields, awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import { ALL_FILTERS_BY_ID } from 'shared/components/FilterSidebar/constants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import styles from './billingRuleDetails.module.scss';
import { azureCommonFieldToDisplayField } from 'shared/constants/azureConstants';
import Button from 'shared/components/andtComponents/Button';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';

const FilterComponent = ({ filter, onEdit }) => {
  const maxChars = 36;
  const groupedValuesByKey = filter.values.some((v) => v.includes(': '))
    ? filter.values.reduce((acc, val) => {
        const [currKey, currVal] = val.split(': ');
        const existingKeyIdx = acc.findIndex((accVal) => accVal.split(': ')[0] === currKey);
        if (existingKeyIdx === -1) {
          return [...acc, val];
        }
        acc[existingKeyIdx] = `${acc[existingKeyIdx]}, ${currVal}`;
        return acc;
      }, [])
    : filter.values;

  const overflowIdx = groupedValuesByKey.findIndex(
    (v, i) => groupedValuesByKey.slice(0, i + 1).reduce((acc, val) => acc + val.length, 0) > maxChars,
  );

  const rawValue = groupedValuesByKey.join(filter.conjunction ? ' (OR) ' : ', ');
  const restRawValue = groupedValuesByKey.slice(overflowIdx).join(filter.conjunction ? ' (OR) ' : ', ');

  const displayValue = (
    <div>
      {groupedValuesByKey.map((v, i) => {
        if (i < overflowIdx || overflowIdx === -1) {
          return (
            <span title={rawValue} key={i} className={v.includes('(Excluded)') && styles.excluded}>
              {v}
              {i < groupedValuesByKey.length - 1 && filter.conjunction ? (
                <span className={styles.orConjunction}>Or</span>
              ) : (
                i < groupedValuesByKey.length - 1 && ', '
              )}
            </span>
          );
        }
        if (i === overflowIdx) {
          return (
            <Tooltip key={i} title={restRawValue} classes={{ tooltip: tooltipStyles.darkGrayTooltip }}
            placement="top"
            arrow={false}>
              <Button
                text={`+${filter.values.length - i}`}
                isTextButton
                onClick={onEdit}
                overrideStyles={{ height: 'fit-content', padding: '0' }}
              />{' '}
            </Tooltip>
          );
        }
      })}
    </div>
  );

  return (
    <div>
      <GenerateIcon iconName={filter.iconName} /> {filter.displayName} <span>{displayValue}</span>
    </div>
  );
};

const BillingRuleDetails = ({ billingRule, onEdit }) => {
  const { usersStore } = useRootStore();
  const defaultFilter = (field) => ({
    displayName:
      (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS
        ? awsCommonFieldToDisplayField.get(field)
        : azureCommonFieldToDisplayField.get(field)) || field,
    values: ['All'],
    iconName: ALL_FILTERS_BY_ID[field]?.icon || ICONS.filterList.name,
  });

  const filtersToDisplay = [
    ...Object.entries(billingRule.filters.includeFilters || {}).map(([field, values]) => ({
      ...defaultFilter(field),
      field,
      values,
      conjunction: billingRule.filters.conjunction?.[field],
    })),
    ...Object.entries(billingRule.filters.excludeFilters || {}).map(([field, values]) => ({
      ...defaultFilter(field),
      field,
      values: values.map((v) => `${v} (Excluded)`),
      conjunction: billingRule.filters.conjunction?.[field],
    })),
  ].reduce((acc, filter) => {
    // Merge filters with the same field
    const existingFilterIdx = acc.findIndex(({ field }) => field === filter.field);
    if (existingFilterIdx === -1) {
      return [...acc, filter];
    }
    acc[existingFilterIdx].values = [...acc[existingFilterIdx].values, ...filter.values];
    return acc;
  }, []);

  return (
    <div className={styles.billingRuleDetailsContainer}>
      <FilterComponent
        filter={{
          displayName: 'Customer',
          values: billingRule.scope.customerNames?.length ? billingRule.scope.customerNames : ['All'],
          iconName: ICONS.mainUserRegular.name,
        }}
        onEdit={onEdit}
      />
      <FilterComponent
        filter={{
          displayName:
            usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS ? 'Linked Account' : 'Subscription',
          values: billingRule.scope.linkedAccountIds?.length ? billingRule.scope.linkedAccountIds : ['All'],
          iconName: ICONS.mainUserRegular.name,
        }}
        onEdit={onEdit}
      />
      {billingRule.description.description && (
        <div>
          <GenerateIcon iconName={ICONS.fileRegular.name} /> Description{' '}
          <span title={billingRule.description.description}>{billingRule.description.description}</span>
        </div>
      )}
      <FilterComponent
        filter={
          filtersToDisplay.find(({ field }) => field === AwsCommonFields.SERVICE) ||
          defaultFilter(AwsCommonFields.SERVICE)
        }
        onEdit={onEdit}
      />
      <FilterComponent
        filter={
          filtersToDisplay.find(({ field }) => field === AwsCommonFields.REGION) ||
          defaultFilter(AwsCommonFields.REGION)
        }
        onEdit={onEdit}
      />
      {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && (
        <div>
          <GenerateIcon iconName={ICONS.bagShopping.name} /> AWS Marketplace Excluded{' '}
          <span>{billingRule.filters.excludeAwsMarketplace ? 'Yes' : 'No'}</span>
        </div>
      )}
      {filtersToDisplay
        .filter(({ field }) => ![AwsCommonFields.SERVICE, AwsCommonFields.REGION].includes(field))
        ?.map((filter) => (
          <FilterComponent key={filter.displayName} filter={filter} onEdit={onEdit} />
        ))}
    </div>
  );
};

FilterComponent.propTypes = {
  filter: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};

BillingRuleDetails.propTypes = {
  billingRule: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default BillingRuleDetails;
