import React from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { strNumToSize, numberWithCommas } from 'shared/utils/strUtil';

const CustomTooltip = ({ active, payload, dataType }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const readData = data?.read || 0;
    const writeData = data?.write || 0;
    const total = +readData + +writeData;
    return (
      <div className="chart-tooltip p-2">
        <p className="label">{data?.usageDate}</p>
        <p className="label" style={{ color: '#1f5189' }}>
          Max {dataType} Read: {dataType === 'Bytes' ? strNumToSize(readData) : numberWithCommas(readData, 3)}
        </p>
        <p className="label" style={{ color: '#00D15D' }}>
          Max {dataType} Write: {dataType === 'Bytes' ? strNumToSize(writeData) : numberWithCommas(writeData, 3)}
        </p>
        <p className="label" style={{ color: '#03945D' }}>
          Total: {dataType === 'Bytes' ? strNumToSize(total) : numberWithCommas(total, 3)}
        </p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  dataType: PropTypes.string.isRequired,
};

const renderChart = (data, dataType) => (
  <AreaChart
    data={data}
    margin={{
      top: 20,
      right: 20,
      left: 20,
      bottom: 20,
    }}
  >
    <XAxis
      dataKey="usageDate"
      tickFormatter={(value) => {
        if (value.toLowerCase().indexOf('w') > -1) {
          return value;
        }
        if (value.length > 0 && value.length < 9) {
          return new Date(value).toLocaleString('en-US', { month: 'short', year: 'numeric' });
        }
        return new Date(value).toLocaleString('en-US', { month: 'short', day: 'numeric' });
      }}
    />
    <YAxis
      yAxisId="left"
      tickFormatter={(value) =>
        dataType === 'Bytes' && value !== 0 ? strNumToSize(value) : numberWithCommas(value, 3)
      }
      type="number"
      domain={[0, (dataMax) => dataMax]}
      width={80}
      interval={0}
      allowDataOverflow="true"
    />
    <CartesianGrid strokeDasharray="3 3" />
    <Tooltip content={<CustomTooltip dataType={dataType} />} />
    <Area
      yAxisId="left"
      name={`Max ${dataType} Read`}
      strokeWidth={0}
      baseValue={0}
      dot={false}
      type="monotone"
      dataKey="read"
      stroke="#1f5189"
      fill="#1f5189"
      stackId="1"
    />
    <Area
      yAxisId="left"
      name={`Max ${dataType} Write`}
      strokeWidth={0}
      baseValue={0}
      dot={false}
      type="monotone"
      dataKey="write"
      stroke="#00D15D"
      fill="#00D15D"
      stackId="1"
    />

    <Legend />
  </AreaChart>
);
const MaxReadWriteChart = ({ data, dataType }) => (
  <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
    {renderChart(data, dataType)}
  </ResponsiveContainer>
);

MaxReadWriteChart.propTypes = {
  data: PropTypes.object.isRequired,
  dataType: PropTypes.string.isRequired,
};

export default MaxReadWriteChart;
