import React, { useState, useContext } from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import CombinedChart from 'shared/components/charts/CombinedStackedBarAndLineChart';
import SimplePieChart from 'shared/components/SimplePieChart';
import ContentInfo from 'shared/components/ContentInfo';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { kFormatterNoDecimal, numberWithCommas } from 'shared/utils/strUtil';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import {
  bigQueryColumnsArray,
  bigQueryColumnWidths,
  BigQueryFilters,
  StatusCodeOptions,
} from 'usage/constants/usageConstants';
import { useBigQueryUsage } from 'usage/hooks/react-query/useBigQueryUsage';
import Panel from 'shared/components/Panel';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import UserDataContext from 'users/utils/contexts/UserSettingsContext';
import Filters from './components/Filters';
import BigQueryTable from './components/BigQueryTable';

const chartTitleStyle = {
  whiteSpace: 'nowrap',
  color: '#969696',
  fontFamily: 'rubik, sans-serif',
  'text-align': 'center',
};

const BigQueryPage = () => {
  const [startDate, setStartDate] = useState(buildStartAndEndDate(null, null).startDate);
  const [endDate, setEndDate] = useState(buildStartAndEndDate(null, null).endDate);
  const currPeriodGranLevel = CostTrackingConstants.GRAN_LEVEL_DAILY;
  const [selectedValuesMap, setSelectedValuesMap] = useState(
    new Map([
      [BigQueryFilters.PROJECTS, []],
      [BigQueryFilters.STATUS_CODE, [StatusCodeOptions.ALL]],
    ]),
  );
  const [pageSize, setPageSize] = useState(5);
  const [columnWidths, setColumnWidths] = useState(bigQueryColumnWidths);
  const { getCurrencyNumber } = useContext(UserDataContext);
  const { fetchBigQueryData, fetchBigQueryDistinctValues, fetchBigQueryTableData } = useBigQueryUsage();
  const { isLoading: isTableLoading, data: bigQueryTableData = [] } = fetchBigQueryTableData(
    startDate,
    endDate,
    selectedValuesMap,
  );
  const { isLoading, data: bigQueryData = [] } = fetchBigQueryData(startDate, endDate, selectedValuesMap);
  const { data: projects = [] } = fetchBigQueryDistinctValues();
  const distinctValues = new Map([
    [BigQueryFilters.PROJECTS, projects],
    [BigQueryFilters.STATUS_CODE, [StatusCodeOptions.ALL, StatusCodeOptions.SUCCESS, StatusCodeOptions.FAILED]],
  ]);
  const prepareChartData = (projData = []) => projData.map((proj) => ({ name: proj.projectId, value: proj.totalCost }));

  const prepareDataKeys = (rawData = []) => {
    const dataKeys = [];
    rawData.forEach((dailyData) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(dailyData)) {
        if (key !== 'numberOfQueries' && key !== 'usageDate') {
          const index = dataKeys.map((x) => x.projectId).indexOf(key);
          if (index === -1) {
            dataKeys.push({ projectId: key, totalCost: value });
          }
        }
      }
    });
    return dataKeys;
  };

  const handleDateChange = async (data) => {
    const startDateTemp = data.startDate || startDate;
    const endDateTemp = (data.endDate || endDate) < startDateTemp ? startDateTemp : data.endDate || endDate;
    setStartDate(startDateTemp);
    setEndDate(endDateTemp);
  };

  const handleSelectFilter = async (field, values) => {
    let filterValue;
    if (values) {
      filterValue = field === BigQueryFilters.PROJECTS ? values.map((value) => value.value) : [values.value];
    } else {
      filterValue = '';
    }
    const filtersCopy = new Map(selectedValuesMap);
    filtersCopy.set(field, filterValue);
    setSelectedValuesMap(filtersCopy);
  };

  const renderDataPanel = (data = {}) => {
    if (data && !isLoading) {
      return (
        <Row>
          <ContentInfo title="Successful Queries" value={numberWithCommas(data.successfulQueries)} />
          <ContentInfo title="Failed Queries" value={numberWithCommas(data.failedQueries)} />
          <ContentInfo title="Data Scanned (TB)" value={numberWithCommas(data.totalBilledTerabytes)} />
        </Row>
      );
    }
    return null;
  };

  const renderCharts = (data) => {
    if (isLoading) {
      return (
        <Panel>
          <Spinner />
        </Panel>
      );
    }
    if (!data || (data?.dailySumByProj && !data?.dailySumByProj?.length)) {
      return (
        <div style={{ marginBottom: '5px' }}>
          <NoDataFoundComponent />
        </div>
      );
    }
    return (
      <Row>
        <div style={{ width: '70%' }}>
          <CombinedChart
            data={data.dailySumByProj}
            dataKeys={prepareDataKeys(data.dailySumByProj)}
            xAxis="usageDate"
            yAxisLeft="totalCost"
            yAxisRight="numberOfQueries"
            barName="Total Cost"
            lineName="Queries"
            yLeftCost
            lineColor="#ff7300"
            barColor="#4e98cb"
          />
        </div>
        <div style={{ position: 'relative', width: '305px' }}>
          <h5 style={chartTitleStyle}>Total Query Cost by Project</h5>
          <p
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              height: 'calc(50% - 2.2em)',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <span style={{ display: 'inline-block', fontSize: '1em' }}>Total cost</span>
            <b style={{ display: 'inline-block', fontSize: '1.5em' }}>
              {getCurrencyNumber(kFormatterNoDecimal(data.totalCost))}
            </b>
          </p>
          <SimplePieChart data={prepareChartData(data.totalProjects)} />
        </div>
      </Row>
    );
  };

  const renderTable = (data) => (
    <BigQueryTable
      data={data}
      columns={bigQueryColumnsArray}
      selectedValuesMap={selectedValuesMap}
      costColumnsArray={['totalCost']}
      csvTitle="big-query"
      columnWidths={columnWidths}
      isResize
      isPaging
      isLoading={isTableLoading}
      setPageSize={setPageSize}
      pageSize={pageSize}
      changeColumnWidths={setColumnWidths}
      startDate={startDate}
      endDate={endDate}
    />
  );

  return (
    <Container>
      <PageHeader title={PageNames.BIG_QUERY} showDate />
      <Card>
        <CardBody>
          <div className="d-flex" style={{ marginLeft: '15px', marginTop: '20px' }}>
            <DatePickerFilter
              onDateChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              currPeriodGranLevel={currPeriodGranLevel}
            />
            <Filters
              disticedValuesMap={distinctValues}
              selectedOptionsMap={selectedValuesMap}
              handleSelectFilter={handleSelectFilter}
            />
          </div>
          {renderDataPanel(bigQueryData)}
          {renderCharts(bigQueryData)}
          {renderTable(bigQueryTableData)}
        </CardBody>
      </Card>
    </Container>
  );
};

export default BigQueryPage;
