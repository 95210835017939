import React, { useState } from 'react';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { palette } from 'shared/constants/colorsConstants';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { downloadUsers } from 'users/new-user-management/hooks/reactQuery/apiUsers';
import DeleteUsersModal from './userModals/DeleteUsersModal';
import EnableDisableUsersModal from './userModals/EnableDisableUsersModal';
import ResetPasswordModal from './userModals/ResetPasswordModal';
import { useUsersContext } from './contexts/usersContext';

import styles from './UsersBulkOperations.module.scss';

const UsersBulkOperations = ({ users }) => {
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isEnableDisableUserModalOpen, setIsEnableDisableUserModalOpen] = useState(false);
  const [isResetUserPasswordModalOpen, setIsResetUserPasswordModalOpen] = useState(false);
  const [isEnable, setIsEnable] = useState();

  const { selectedRows, setSelectedRows } = useUsersContext();
  const selectedUsers = selectedRows.map((row) => users[row]);

  const onBulkOperationSuccess = () => {
    setSelectedRows([]);
  };

  const onEnableDisableOpen = (isEnable) => {
    setIsEnable(isEnable);
    setIsEnableDisableUserModalOpen(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <CustomCSVDownload
          fetchData={() => downloadUsers(selectedUsers)}
          automationId="export-recommendations"
          showDownloadIcon
          isLoading={false}
          filesNumber={1}
          isSecondary
          isLightButton
          hideText
          style={{ background: 'none', fontSize: 16, color: palette.gray[400], padding: 0 }}
        />
        <span>Export</span>
      </div>
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={() => <GenerateIcon iconName={ICONS.circleCheck.name} className={styles.icon} />}
        isTextButton
        onClick={() => onEnableDisableOpen(true)}
        text="Enable"
      />
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={() => <GenerateIcon iconName={ICONS.circleMinus.name} className={styles.icon} />}
        isTextButton
        onClick={() => onEnableDisableOpen(false)}
        text="Disable"
      />
      <EnableDisableUsersModal
        setIsOpen={setIsEnableDisableUserModalOpen}
        isOpen={isEnableDisableUserModalOpen}
        users={selectedUsers}
        onSuccess={onBulkOperationSuccess}
        isEnable={isEnable}
      />
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} className={styles.icon} />}
        isTextButton
        onClick={() => setIsDeleteUserModalOpen(true)}
        text="Delete"
      />
      <DeleteUsersModal
        setIsOpen={setIsDeleteUserModalOpen}
        isOpen={isDeleteUserModalOpen}
        users={selectedUsers}
        onSuccess={onBulkOperationSuccess}
      />
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={() => <GenerateIcon iconName={ICONS.reset.name} className={styles.icon} />}
        isTextButton
        onClick={() => setIsResetUserPasswordModalOpen(true)}
        text="Reset Password"
      />
      <ResetPasswordModal
        setIsOpen={setIsResetUserPasswordModalOpen}
        isOpen={isResetUserPasswordModalOpen}
        users={selectedUsers}
        onSuccess={onBulkOperationSuccess}
      />
    </div>
  );
};

export default UsersBulkOperations;
