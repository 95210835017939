import React from 'react';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './anomalyDetectionTableHeaderColumn.module.scss';

const propTypes = {
  column: PropTypes.shape({ name: PropTypes.string.isRequired, title: PropTypes.string.isRequired }).isRequired,
  children: PropTypes.object.isRequired,
};

const AnomalyDetectionTableHeaderColumn = ({ column, children, ...restProps }) => {
  const customTooltips = {
    percentChange: 'The difference between normal expected behavior and actual during the anomaly period in %',
    lastDayImpact: 'The cost impact caused by the last day of the anomaly',
  };

  return (
    <TableHeaderRow.Content column={column} {...restProps}>
      <div className={`d-flex align-items-center ${styles.headerColumn}`}>
        {column.title.trim() ? (
          <Tooltip placement="top" title={customTooltips[column.name] || column.title} arrow>
            <div className={styles.headerColumn}>{children}</div>
          </Tooltip>
        ) : (
          <div className={styles.headerColumn}>{children}</div>
        )}
      </div>
    </TableHeaderRow.Content>
  );
};

AnomalyDetectionTableHeaderColumn.propTypes = propTypes;
export default AnomalyDetectionTableHeaderColumn;
