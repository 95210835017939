 
import LabelCoordinator from 'shared/modules/labelCoordinator';

export const PRIVATE_PRICING_DISCOUNT_TYPES = {
  PERCENTAGE: 'percentage',
  FIXED_COST: 'fix',
  FIXED_RATE: 'rate',
  CUST_SER_CALC_COST: 'custom-service-calc-cost',
  CALC_COST: 'calc-cost',
  REMOVE_AWS_SUPPORT: 'remove-support',
  REMOVE_COST: 'remove-cost',
};
export const discountTypesToDisplayNamesMap = new Map([
  [PRIVATE_PRICING_DISCOUNT_TYPES.PERCENTAGE, 'Percentage'],
  [PRIVATE_PRICING_DISCOUNT_TYPES.FIXED_COST, 'Fixed cost'],
  [PRIVATE_PRICING_DISCOUNT_TYPES.FIXED_RATE, 'Fixed rate'],
  [PRIVATE_PRICING_DISCOUNT_TYPES.CUST_SER_CALC_COST, 'Custom Service Aggregated %'],
  [PRIVATE_PRICING_DISCOUNT_TYPES.CALC_COST, 'AWS Support'],
  [PRIVATE_PRICING_DISCOUNT_TYPES.REMOVE_AWS_SUPPORT, 'Remove AWS Support'],
  [PRIVATE_PRICING_DISCOUNT_TYPES.REMOVE_COST, 'Data Exclusion'],
]);
export const FLEXIBILITY_TABLE_COLUMNS = (type) => {
  let columns = [];
  if (!type) {
    return columns;
  }
  if (type === 'pp') {
    columns = [
      {
        name: 'linkedAccountName',
        title: 'Linked Account',
        getCellValue: (row) => `${row.linkedAccountName} (${row.linkedAccountId})`,
      },
      { name: 'service', title: 'Service' },
      { name: 'publicPriceMargin', title: 'Margin' },
    ];
    return columns;
  }
  if (type === 'creditMargin' || type === 'resellerCredit') {
    columns = [
      {
        name: 'linkedAccountName',
        title: 'Linked Account',
        getCellValue: (row) => `${row.linkedAccountName} (${row.linkedAccountId})`,
      },
      { name: 'amountUsed', title: 'Amount Used' },
      { name: 'usageDate', title: 'Usage Date', getCellValue: (row) => row.usageDate.split('T')[0] },
    ];
    return columns;
  }
  if (type === 'billingRules') {
    columns = [
      {
        name: 'linkedAccountName',
        title: 'Linked Account',
        getCellValue: (row) => `${row.linkedAccountName} (${row.linkedAccountId})`,
      },
      { name: 'service', title: 'Service' },
      { name: 'marginType', title: 'Type' },
      { name: 'margin', title: 'Margin' },
    ];
    return columns;
  }
  if (type === 'sp') {
    return [
      {
        name: 'linkedAccountName',
        title: 'Linked Account',
        getCellValue: (row) => `${row.linkedAccountName} (${row.linkedAccountId})`,
      },
      {
        name: 'owner',
        title: type === 'ri' ? 'RI Owner' : 'SP Owner',
        getCellValue: (row) => `${row.ownerName} (${row.ownerId})`,
      },
      { name: 'region', title: 'Region', getCellValue: (row) => row.regionTagName },
      { name: 'service', title: 'Service', getCellValue: (row) => row.serviceName },
      { name: 'reservationArn', title: 'Reservation Arn' },
      { name: 'instanceType', title: 'Instance Type' },
      { name: 'customerOnDemandCoverage', title: 'Customer SP Coverage' },
      { name: 'resellerOnDemandCoverage', title: 'Reseller SP Coverage' },
      { name: 'margin', title: 'Margin' },
    ];
  }
  columns = [
    {
      name: 'linkedAccountName',
      title: 'Linked Account',
      getCellValue: (row) => `${row.linkedAccountName} (${row.linkedAccountId})`,
    },
    {
      name: 'owner',
      title: type === 'ri' ? 'RI Owner' : 'SP Owner',
      getCellValue: (row) => `${row.ownerName} (${row.ownerId})`,
    },
    { name: 'service', title: 'Service' },
    { name: 'reservationArn', title: 'Reservation Arn' },
    { name: 'instanceType', title: 'Instance Type' },
    { name: 'margin', title: 'Margin' },
  ];
  return columns;
};
export const FLEXIBILITY_TABLE_COST_COLUMNS = [
  'actualCost',
  'publicCost',
  'margin',
  'customerOnDemandCoverage',
  'resellerOnDemandCoverage',
  'publicPriceMargin',
  'amountUsed',
  'totalCost',
];
export const FLEXIBILITY_TABLE_COLUMNS_WIDTHS = (type) => {
  let columns = [];
  if (!type) {
    return columns;
  }
  columns = [
    { columnName: 'linkedAccountName', width: 200 },
    { columnName: 'owner', width: 150 },
    { columnName: 'instanceType', width: 150 },
    { columnName: 'actualCost', width: 150 },
    { columnName: 'resourceId', width: 150 },
    { columnName: 'reservationArn', width: 150 },
    { columnName: 'region', width: 150 },
    { columnName: 'customerOnDemandCoverage', width: 150 },
    { columnName: 'resellerOnDemandCoverage', width: 150 },
    { columnName: 'margin', width: 150 },
    { columnName: 'totalUsageQuantity', width: 150 },
    { columnName: 'service', width: 200 },
    { columnName: 'publicPriceMargin', width: 200 },
    { columnName: 'amountUsed', width: 200 },
    { columnName: 'usageDate', width: 200 },
    { columnName: 'totalCost', width: 200 },
    { columnName: 'marginType', width: 200 },
    { columnName: 'usageDate', width: 200 },
  ];
  return columns;
};

export const RI_SP_FLEXIBILITY_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'actualCost', align: 'center' },
  { columnName: 'publicCost', align: 'center' },
  { columnName: 'diffCost', align: 'center' },
];

export const AZURE_BILLING_STATUS_TABLE_COLUMNS = [
  {
    name: 'divisionName',
    title: 'Customer Name',
    getCellValue: (row) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', row.divisionName),
  },
  {
    name: 'lastMonthCharges',
    title: 'Last Month Charges',
  },
  {
    name: 'monthToDateCharges',
    title: 'Month to Date Charges',
  },
  { name: 'customerType', title: 'Type' },
  { name: 'divisionMarginPercent', title: 'Margin' },
  { name: 'riMargin', title: 'Ri Margin' },
  { name: 'usageMargin', title: 'Usage Margin' },
  { name: 'totalMarginCost', title: 'Total Margin' },
  {
    name: 'userAction',
    title: 'Actions',
  },
];
export const AZURE_BILLING_STATUS_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'divisionName', width: 150 },
  { columnName: 'lastMonthCharges', width: 150 },
  { columnName: 'monthToDateCharges', width: 150 },
  { columnName: 'customerType', width: 150 },
  { columnName: 'divisionMarginPercent', width: 150 },
  { columnName: 'riMargin', width: 150 },
  { columnName: 'usageMargin', width: 150 },
  { columnName: 'totalMarginCost', width: 150 },
  { columnName: 'userAction', width: 150 },
];
export const AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'divisionName', width: 300 },
  { columnName: 'month', width: 200 },
  { columnName: 'year', width: 200 },
  { columnName: 'mtdResellerCost', width: 200 },
  { columnName: 'mtdCustomerCost', width: 200 },
  { columnName: 'riMargin', width: 200 },
  { columnName: 'usageMargin', width: 200 },
  { columnName: 'totalMarginCost', width: 200 },
];
export const AZURE_BILLING_HISTORY_TABLE_COLUMNS = [
  {
    name: 'customerName',
    title: 'Customer Name',
    getCellValue: (row) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', row.customerName),
  },
  {
    name: 'month',
    title: 'Month',
    getCellValue: (row) => {
      const date = new Date(row.year, row.month - 1, 1);
      return date.toLocaleDateString(undefined, { month: 'long' });
    },
  },
  {
    name: 'year',
    title: 'Year',
  },
  { name: 'mtdResellerCost', title: 'Reseller Cost' },
  { name: 'mtdCustomerCost', title: 'Customer Cost' },
  { name: 'riMargin', title: 'Ri Margin' },
  { name: 'usageMargin', title: 'Usage Margin' },
  { name: 'totalMarginCost', title: 'Total Margin' },
];
