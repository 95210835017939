import React from 'react';
import classes from './recommendationOptionTable.module.scss';

const RecommendationOptionTable = ({ header, description, columns, recommendation }) => (
  <div className={classes.recommendedTableContainer}>
    <div className={classes.titleContainer}>
      <div className={classes.titleText}>{header}</div>
      <div className={classes.subTitleText}>{description}</div>
    </div>
    <div className={classes.tableContainer}>
      <div className={classes.tableHeaders}>
        <div />
        {columns.map((column) => (
          <div>{column.label}</div>
        ))}
      </div>
      <div className={classes.tableBody}>
        <div className={classes.dataPair}>
          <div>Current</div>
          {columns.map(
            (column) => column.currentGetterFunction && <div>{column.currentGetterFunction(recommendation)}</div>,
          )}
        </div>
        <div className={classes.dataPair}>
          <div>Recommended</div>
          {columns.map(
            (column) =>
              column.recommendedGetterFunction && <div>{column.recommendedGetterFunction(recommendation)}</div>,
          )}
        </div>
      </div>
    </div>
  </div>
);

export default RecommendationOptionTable;
