/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import Collapse from 'shared/components/Collapse';

class RiHeatMapChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        dataLabels: {
          enabled: false,
        },
        colors: ['#008FFB'],

        title: {
          text: 'Monthly resource usage, Number of resources used per hour',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
        },
        responsive: [
          {
            breakpoint: 1530,
            options: {
              chart: {
                width: '700',
              },
            },
          },
          {
            breakpoint: 1400,
            options: {
              chart: {
                width: '600',
              },
            },
          },
          {
            breakpoint: 1230,
            options: {
              chart: {
                width: '500',
              },
            },
          },
        ],
      },
    };
  }

  updateData(timeline) {
    this.setState({
      selection: timeline,
    });

    switch (timeline) {
      case '7_days':
        this.setState({
          options: {
            xaxis: {
              min: moment(DateFilter.getDate()).subtract(7, 'days').format('YYYY-MM-DD'),
              max: DateFilter.getDate(),
            },
          },
        });
        break;
      case '30_days':
        this.setState({
          options: {
            xaxis: {
              min: moment(DateFilter.getDate()).subtract(30, 'days').format('YYYY-MM-DD'),
              max: DateFilter.getDate(),
            },
          },
        });
        break;
      case '90_days':
        this.setState({
          options: {
            xaxis: {
              min: moment(DateFilter.getDate()).subtract(90, 'days').format('YYYY-MM-DD'),
              max: DateFilter.getDate(),
            },
          },
        });
        break;
      default:
    }
  }

  render() {
    return (
      <div id="chart" style={{ margin: '35px' }}>
        {/* <div className="toolbar">
          <button onClick={() => this.updateData('7_days')} id="7_days" className={this.state.selection === '7_days' ? 'active' : ''}>
            7 Days
          </button>
          <button onClick={() => this.updateData('30_days')} id="30_days" className={this.state.selection === '30_days' ? 'active' : ''}>
            30 Days
          </button>
          <button onClick={() => this.updateData('90_days')} id="90_days" className={this.state.selection === '90_days' ? 'active' : ''}>
            90 Days
          </button>
        </div> */}
        <Collapse
          style={{ width: '1000px' }}
          open={this.props.series.length && this.state.options.length}
          openIcon={<MinusIcon />}
          closeIcon={<PlusIcon />}
          title="Base data details"
        >
          <Chart
            options={this.state.options}
            series={this.props.series}
            type="heatmap"
            height="500"
            width="900"
            style={{ margin: '15px' }}
          />
        </Collapse>
      </div>
    );
  }
}

RiHeatMapChart.propTypes = {
  series: PropTypes.object.isRequired,
};

export default RiHeatMapChart;
