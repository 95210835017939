/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
/* eslint-disable arrow-parens, no-unused-vars, function-paren-newline, react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import AddMoreColumnsList from 'shared/components/AddMoreFiltersList';
import { mapCloudTypeToDisplayLabelFunc } from 'shared/utils/cloudUtils';
import Tooltip from 'shared/components/andtComponents/Tooltip';

const externalContainerStyle = {
  // width: '30%',
  margin: '0px 0px 15px 0px',
};
const innerContainerStyle = {
  height: '35px',
  borderRadius: '3px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0 0 4px 0 rgba(0,0,0,0.15)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0px 0px 15px 0px',
  hover: {
    cursor: 'pointer',
  },
};

const buttonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  color: 'rgb(78, 152, 203)',
  // marginRight: '5px',
  cursor: 'pointer',
};
const addMoreButtonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  color: 'rgb(78, 152, 203)',
  // marginRight: '5px',
  cursor: 'pointer',
  zIndex: '100',
};

class DisplayedChartColumnsPicker extends PureComponent {
  static propTypes = {
    handleAddOrRemoveMoreColumns: PropTypes.func.isRequired,
    allColumns: PropTypes.object.isRequired,
    visibleColumns: PropTypes.object.isRequired,
    secondaryGroupBy: PropTypes.string.isRequired,
    currentCloudType: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  toggleOrForce = (isForced) => {
    this.setState((prevState) => ({
      collapse: isForced !== undefined ? isForced : !prevState.collapse,
    }));
  };

  render() {
    const { allColumns, handleAddOrRemoveMoreColumns, visibleColumns, secondaryGroupBy, currentCloudType } = this.props;
    const { collapse } = this.state;
    const selectedIds = visibleColumns.map(({ id }) => id);
    const displayLabelCloudBaseMap = mapCloudTypeToDisplayLabelFunc.get(currentCloudType);
    return (
      <Row style={externalContainerStyle}>
        <Col style={innerContainerStyle}>
          <Tooltip title="Results selected are based on highest volume items. Refine query by adjusting the selection.">
            <button type="button" style={addMoreButtonStyle} onClick={() => this.toggleOrForce()}>
              {`Refine ${displayLabelCloudBaseMap(secondaryGroupBy)} (${selectedIds.length}/${
                allColumns.length
              } selected)`}
            </button>
          </Tooltip>
        </Col>
        <AddMoreColumnsList
          toggleOrForce={this.toggleOrForce}
          handleAddOrRemoveMoreFilters={handleAddOrRemoveMoreColumns}
          collapse={collapse}
          listOfValues={allColumns}
          selected={selectedIds}
        />
      </Row>
    );
  }
}

export default DisplayedChartColumnsPicker;
