import React from 'react';
import { useFormContext } from 'react-hook-form';
import BillingProfileForm from 'divisions/containers/Preferences/components/BillingProfileForm';
import { AWS_ONBOARDING_FIELDS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import styles from './BillingProfilePage.module.scss';

const BillingProfilePage = () => {
  const { setValue, getValues } = useFormContext();
  return (
    <div className={styles.container}>
      <BillingProfileForm
        profile={getValues(AWS_ONBOARDING_FIELDS.BILLING_PROFILE)}
        setProfile={(profile) => {
          setValue(AWS_ONBOARDING_FIELDS.BILLING_PROFILE, profile);
        }}
        hideCustomer
      />
    </div>
  );
};

export default BillingProfilePage;
