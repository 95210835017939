export const FILTER_GROUP_TABLE_COLUMNS = [
  { name: 'name', title: 'Filter Group Name' },
  { name: 'createdBy', title: 'Created By' },
  { name: 'creationTime', title: 'Created' },
  { name: 'userAction', title: ' ' },
];

export const FILTER_GROUP_COLUMN_WIDTHS = [
  { columnName: 'name', width: 300 },
  { columnName: 'createdBy', width: 300 },
  { columnName: 'creationTime', width: 200 },
  { columnName: 'userAction', width: 100 },
];
