import React from 'react';
import { NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul>
      <li>
        List the current multipart objects:
        <CodeBlock
          text="aws s3api list-multipart-uploads --bucket {{bucketName}}"
          variables={{
            bucketName: rec.bucketName,
          }}
        />
      </li>
      <li>
        List all the objects in the multipart upload:
        <CodeBlock
          text="aws s3api list-parts --upload-id {{UPLOAD_ID}} --bucket {{bucketName}} --key {{KEY}}"
          variables={{
            UPLOAD_ID: 'UPLOAD_ID',
            bucketName: rec.bucketName,
            KEY: 'KEY',
          }}
        />
      </li>
      <li>
        Delete a multipart upload object manually:
        <CodeBlock
          text="aws s3api abort-multipart-upload --upload-id {{UPLOAD_ID}} --bucket {{bucketName}} --key {{KEY}}"
          variables={{
            UPLOAD_ID: 'UPLOAD_ID',
            bucketName: rec.bucketName,
            KEY: 'KEY',
          }}
        />
      </li>
      <li>
        <NavLink
          target="_blank"
          // eslint-disable-next-line max-len
          href="https://aws.amazon.com/blogs/aws-cost-management/discovering-and-deleting-incomplete-multipart-uploads-to-lower-amazon-s3-costs/"
        >
          Besides, it is recommended to create lifecycle rule that will delete incomplete multipart objects.
        </NavLink>
      </li>
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
