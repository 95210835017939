import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { DISK_PROPERTIES } from '../../recommendationPropertyUtils';

const GCP_DISK_UNATTACHED_PROPERTIES = {
  DISK_STATUS: {
    label: 'Disk Status',
    getterFunction: (recommendation) => recommendation?.recData?.disk_status,
  },
  PROJECT: {
    label: 'Project',
    getterFunction: (recommendation) => recommendation.recData.project,
  },
};

const GcpDiskUnattached = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'Delete the disk',
          // eslint-disable-next-line max-len
          actionCommand: `gcloud compute disks delete ${recommendation?.recData?.disk_name} --zone=${recommendation?.recData?.zone_tag}`,
        },
      ],
    },
  ];
  const description =
    // eslint-disable-next-line max-len
    "Please note that this Disk is active, but no longer attached to any instance, if it's no longer needed it should be turned off";

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...DISK_PROPERTIES, ...GCP_DISK_UNATTACHED_PROPERTIES }}
    />
  );
};

GcpDiskUnattached.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default GcpDiskUnattached;
