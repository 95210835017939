import React from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import AddRolesTable from './AddRolesTable';
import AddRolesHeader from './AddRolesHeader';

const AddRoles = ({ roles, pageChanged, search, setSearch, selectedRoles, onRolesAddedRemoved }) => {
  const { NewTableWrapper } = useTable();

  return (
    <NewTableWrapper className="sub-table" isCompact>
      <AddRolesHeader
        pageChanged={pageChanged}
        selectedRolesCount={selectedRoles?.length}
        search={search}
        setSearch={setSearch}
        total={roles?.length}
      />
      <AddRolesTable roles={roles} selectedRoles={selectedRoles} onRolesAddedRemoved={onRolesAddedRemoved} />
    </NewTableWrapper>
  );
};

export default AddRoles;
