import { TableHeaderRow, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as NoResults } from 'shared/img/icons/no-results.svg';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import classes from './billingSummary.module.scss';

export const CustomNoDataComponent = () => (
  <div className={classes.noResultsWrapper}>
    <NoResults />
    <span className={classes.mainText}>Nothing was found</span>
    <span className={classes.subText}>Try different filters</span>
  </div>
);

export const CustomHeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;
  const { statusFilter, name, title } = column;
  if (!column.title) {
    return (
      <TableHeaderRow.Cell {...restProps} column={column}>
        <Tooltip title={title}>
          <span className={classes.headerList} />
        </Tooltip>
      </TableHeaderRow.Cell>
    );
  }
  return (
    <TableHeaderRow.Cell {...restProps} column={column}>
      {name === 'savings' &&
      (statusFilter.isCompleted || statusFilter.isDone) &&
      (statusFilter.isOpen || !statusFilter.isDone) ? (
        <span className={classes.headerList}>{children}</span>
      ) : (
        <Tooltip title={title}>
          <span className={classes.headerList}>{children}</span>
        </Tooltip>
      )}
    </TableHeaderRow.Cell>
  );
};

export const CustomSelectionCell = (props) => {
  const { row, className, ...restProps } = props;
  return (
    <TableSelection.Cell
      {...restProps}
      row={row}
      className={classNames(row.month === new Date().getMonth() + 1 && classes.disabledSelection, className)}
      automation-id="select-cell"
    />
  );
};

export const CSVButton = ({ fetchCSVData }) => (
  <span onClick={fetchCSVData} className="csv-download" style={{ cursor: 'pointer', color: '#80bbf8' }}>
    CSV
  </span>
);

CSVButton.propTypes = {
  fetchCSVData: PropTypes.func.isRequired,
};

export const CurrencyNumberWithTooltipFormatter = ({ value }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const formattedValue = getCurrencyNumber(value);

  return (
    <Tooltip title={formattedValue}>
      <span>{formattedValue}</span>
    </Tooltip>
  );
};
