// import { decorate, observable } from 'mobx';

export default class CustomDashboardSubStore {
  constructor(usageStore, customDashboardModel, apiGateway) {
    this.usageStore = usageStore;
    this.customDashboardModel = customDashboardModel;
    this.apiGateway = apiGateway;
    this.customDashboardModel.setRootStore(usageStore.rootStore);
  }

  getCustomDashboards = async () => {
    const data = await this.customDashboardModel.getCustomDashboards();
    return data;
  };
  fetchDashboardsAndPanels = async () => {
    await this.customDashboardModel.fetchData();
  };
  fetchCustomDashboards = async () => {
    await this.customDashboardModel.fetchCustomDashboards();
  };
  fetchCustomDbrdPanels = async () => {
    await this.customDashboardModel.fetchCustomDbrdPanels();
  };
  createOrAddNewDashboardPanel = async (params, selectedPanelsUuids) => {
    await this.customDashboardModel.createOrAddNewDashboardPanel(params, selectedPanelsUuids);
  };
  createPanelFromTemplate = async (predefinedPanelId, name) =>
    this.customDashboardModel.createPanelFromTemplate(predefinedPanelId, name);
  cloneDashboard = async (params, clonedPanelSuffix) => {
    await this.customDashboardModel.cloneDashboard(params, clonedPanelSuffix);
  };
  updateCustomDashboardPanel = async (panelNewParams, panelUuid, dashboardId) => {
    await this.customDashboardModel.updateCustomDashboardPanel(panelNewParams, panelUuid, dashboardId);
  };
  overwriteExistingCustomDashboardPanel = async (panelNewParams) => {
    await this.customDashboardModel.overwriteExistingCustomDashboardPanel(panelNewParams);
  };
  isCurrentUserOwner = (dbUuid) => this.customDashboardModel.isCurrentUserOwner(dbUuid);
  getPanelByUuid = (panelUuid) => this.customDashboardModel.getPanelByUuid(panelUuid);
  removeDashboard = (dashboardId) => this.customDashboardModel.removeDashboard(dashboardId);
  updateDashboard = (dashboard, data) => this.customDashboardModel.updateDashboard(dashboard, data);
  invalidateData = () => {
    this.customDashboardModel.invalidateData();
  };
}
