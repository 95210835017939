import { API } from 'shared/utils/apiMiddleware';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import toast from 'shared/components/andtComponents/Toast';

const root = '/api/v1/users/files/account-complement-data';

const fetchAccountTagsDataApi = async () => {
  const result = await API.get('billings', `${root}/add-data`);
  const newDataMap = new Map();
  result.forEach((item) => newDataMap.set(item.linked_account_id, item));
  return newDataMap;
};

const fetchAccountTagsMetadataApi = () => {
  const result = API.get('billings', `${root}/all`);
  return result;
};

const updateAccountTagInfoApi = (data) => {
  const result = API.put('billings', `${root}/${data.linked_account_id}`, { body: data });
  return result;
};

const uploadAccountTagsFileApi = (fileData) => {
  const result = API.put('billings', `${root}/upload`, { body: fileData });
  return result;
};

const uploadAccountTagsFileFromS3Api = ({ bucketName, bucketRegion, fileName, accountId }) => {
  const result = API.put('billings', `${root}/s3-upload`, { body: { accountId, bucketName, bucketRegion, fileName } });
  return result;
};

const deleteAccountTagsFileApi = (fileId) => {
  const result = API.del('billings', `${root}/${fileId}`);
  return result;
};

const attachAccountTagsToCSVReport = (rows, accountTagsData) => {
  if (!rows || !rows.length) {
    return rows;
  }
  return accountTagsData?.size
    ? rows.map((row) => {
        const key = row.linkedAccountId || row['Linked Account Id'];
        const addData = accountTagsData.get(key);
        if (addData) {
          // eslint-disable-next-line camelcase
          const { uuid, linked_account_id, uuid_linked_account_id, upload_date, ...rest } = addData;
          return { ...row, ...rest };
        }
        return row;
      })
    : rows;
};

export const useAccountTags = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.ACCOUNT_TAGS];
  const onSuccess = () => {
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries([apiConstants.QUERY_KEYS.FILTERS_DISTINCT_TAGS_VALUES]);
  };
  return {
    attachAccountTagsToCSVReport,
    fetchAccountTagsData: () => useQuery([...queryKey, 'data'], fetchAccountTagsDataApi, { staleTime: 60 * 5 * 1000 }),
    fetchAccountTagsMetadata: () =>
      useQuery([...queryKey, 'metadata'], fetchAccountTagsMetadataApi, { staleTime: 60 * 5 * 1000 }),
    useUpdateAccountTagInfo: () => useMutation(updateAccountTagInfoApi, { onSuccess }),
    useUploadAccountTagsFile: () =>
      useMutation(uploadAccountTagsFileApi, {
        onSuccess,
        onError: () => {
          toast.error(
            'Error loading the file:\n' +
              ' verify that Linked Account Id header is in the right syntax - linked_account_id',
          );
        },
      }),
    uploadAccountTagsFileFromS3: () =>
      useMutation(uploadAccountTagsFileFromS3Api, {
        onSuccess,
        onError: (error) => {
          toast.error(
            error.response?.data?.clientMessage ||
              'Error loading the file:\n' +
                ' verify that Linked Account Id header is in the right syntax - linked_account_id',
          );
        },
      }),
    useDeleteAccountTagsFile: () => useMutation(deleteAccountTagsFileApi, { onSuccess }),
  };
};
