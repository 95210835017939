import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import CommitmentTypeUtilizationPage from './components/CommitmentTypeUtilizationPage';

const SpUtilizationPage = (props) => (
  <Container>
    <PageHeader title={PageNames.SAVINGS_PLANS_UTILIZATION} showDate />
    <Card>
      <CardBody style={{ minHeight: '300px' }}>
        <CommitmentTypeUtilizationPage {...props} commitmentType="sp" />
      </CardBody>
    </Card>
  </Container>
);

SpUtilizationPage.propTypes = {};
SpUtilizationPage.propTypes = {
  usageStore: PropTypes.object.isRequired,
  commitmenmtStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
};
export default SpUtilizationPage;
