/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import {
  ContentDetailsDisplay,
  RecommendationCommonConstants,
} from 'recommendations/constants/recommendationsConstants';

export default class ContentDetails extends PureComponent {
  // props.children
  static propTypes = {
    contentDisplay: PropTypes.object,
  };

  static defaultProps = {
    contentDisplay: ContentDetailsDisplay.RECS_PAGE,
  };

  constructor(props) {
    super(props);

    this.state = {
      contentDisplay: this.props.contentDisplay,
    };
  }

  onToggleMoreDetails = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onShowMoreDetails = () => {
    this.setState({ contentDisplay: ContentDetailsDisplay.DAILY_RECS_OPEN });
  };

  onShowLessDetails = () => {
    this.setState({ contentDisplay: ContentDetailsDisplay.DAILY_RECS_CLOSE });
  };

  renderContentDetails = () => {
    if (this.state.contentDisplay === ContentDetailsDisplay.DAILY_RECS_CLOSE) {
      const arr = [];
      for (let i = 0; i < RecommendationCommonConstants.NUM_OF_DAILY_RECS_ROWS; i++) {
        arr.push(this.props.children[i]);
      }
      return [...arr];
    }
    return [...this.props.children];
  };

  renderArrows = () => {
    if (
      this.props.children.length > RecommendationCommonConstants.NUM_OF_DAILY_RECS_ROWS &&
      this.state.contentDisplay === ContentDetailsDisplay.DAILY_RECS_CLOSE
    ) {
      return (
        <tr>
          <ChevronDownIcon onClick={() => this.onShowMoreDetails()} style={{ cursor: 'pointer' }} />
        </tr>
      );
    } else if (
      this.props.children.length > RecommendationCommonConstants.NUM_OF_DAILY_RECS_ROWS &&
      this.state.contentDisplay === ContentDetailsDisplay.DAILY_RECS_OPEN
    ) {
      return <ChevronUpIcon onClick={() => this.onShowLessDetails()} style={{ cursor: 'pointer' }} />;
    }
    return null;
  };

  render() {
    return (
      <tbody>
        {this.renderContentDetails()}
        {this.renderArrows()}
      </tbody>
    );
  }
}
