/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the CLI:</li>
      <CodeBlock
        text="az vm resize --resource-group {{resourceGroup}} --name {{resourceName}} --size {{recommVersion}}"
        variables={{
          recommVersion: rec.recommVersion,
          resourceGroup: rec.resourceGroup,
          resourceName: rec.resourceName,
        }}
      />
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
