import React from 'react';
import PropTypes from 'prop-types';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import AlertRuleDetailsFilter from './AlertRuleDetailsFilter';
import styles from './alertRuleDetailRow.module.scss';

const propTypes = {
  row: PropTypes.shape({
    filters: PropTypes.shape({
      include: PropTypes.array,
      exclude: PropTypes.array,
    }).isRequired,
  }).isRequired,
  columnsLength: PropTypes.number.isRequired,
};

const alertRuleDetailRow = ({ row: { filters: { include = [], exclude = [] } = {} }, columnsLength }) => (
  <tr>
    <td colSpan={`${columnsLength + 1}`} className={`${styles.rowDetail}`}>
      <div className={`d-flex flex-column ${styles.detialRow}`}>
        <AlertRuleDetailsFilter title="Service" field={AwsCommonFields.SERVICE} exclude={exclude} include={include} />
        <AlertRuleDetailsFilter
          title="Linked Account"
          field={AwsCommonFields.LINKED_ACCOUNT_ID}
          exclude={exclude}
          include={include}
        />
        <AlertRuleDetailsFilter
          title={LabelCoordinator.getFieldLabel(AwsCommonFields.DIVISION)}
          field={AwsCommonFields.DIVISION}
          exclude={exclude}
          include={include}
        />
      </div>
    </td>
  </tr>
);

alertRuleDetailRow.propTypes = propTypes;
export default alertRuleDetailRow;
