function parseStringToCsvFormat(parseMe) {
  const splitFinder = /,|\r?\n|"(\\"|[^"])*?"/g;
  let currentRow = [];
  const rowsOut = [currentRow];
  // eslint-disable-next-line no-multi-assign
  let lastIndex = (splitFinder.lastIndex = 0);

  // add text from lastIndex to before a found newline or comma
  const pushCell = (endIndex) => {
    // eslint-disable-next-line no-param-reassign
    endIndex = endIndex || parseMe.length;
    const addMe = parseMe.substring(lastIndex, endIndex);
    // remove quotes around the item
    currentRow.push(addMe.replace(/^"|"$/g, ''));
    // eslint-disable-next-line prefer-destructuring
    lastIndex = splitFinder.lastIndex;
  };

  let regexResp;
  // for each regexp match (either comma, newline, or quoted item)
  // eslint-disable-next-line no-cond-assign
  while ((regexResp = splitFinder.exec(parseMe))) {
    const split = regexResp[0];

    // if it's not a quote capture, add an item to the current row
    // (quote captures will be pushed by the newline or comma following)
    if (split.startsWith(`"`) === false) {
      const splitStartIndex = splitFinder.lastIndex - split.length;
      pushCell(splitStartIndex);

      // then start a new row if newline
      const isNewLine = /^\r?\n$/.test(split);
      if (isNewLine) {
        rowsOut.push((currentRow = []));
      }
    }
  }
  // make sure to add the trailing text (no commas or newlines after)
  pushCell();
  return rowsOut;
}

export default parseStringToCsvFormat;
