export const RECOMMENDATIONS_MODAL_TYPE = 'recommendations';
export const ALL_OPTIONS = { label: 'All', value: 'All' };
export const REPORT_TYPE_OPTIONS = [
  { label: 'Summary', value: 'Summary' },
  { label: 'Details', value: 'Details' },
];
export const GROUP_BY_OPTIONS = [
  { label: 'Linked Account', value: 'Linked Account' },
  { label: 'Cost Center', value: 'Cost Center' },
  { label: 'Age', value: 'Age' },
  { label: 'Service', value: 'Service' },
  { label: 'Recommendation Type', value: 'Recommendation Type' },
];

export const RECOMMENDATIONS_STATUS_OPTIONS = [
  ALL_OPTIONS,
  { label: 'Excluded', value: 'Excluded' },
  { label: 'Open', value: 'Open' },
  { label: 'Completed', value: 'Completed' },
];
