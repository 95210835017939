import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  CostTypeModes,
  DisplayMetricTypes,
  mapDataAttributesToDisplayMetricTypes,
} from 'usage/constants/costAndUsageConstants';
import ImmediateFilterBar from 'shared/components/ImmediateFilterBar';
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import { AppCommonFields, PageNames } from 'shared/constants/appConstants';
import { Button, Col, Row } from 'reactstrap';
import ChartTimelineVariantIcon from 'mdi-react/ChartLineVariantIcon';
import ChartBarStackedIcon from 'mdi-react/ChartBarStackedIcon';
import SimpleLineChart from 'shared/components/charts/SimpleLineChart';
import CostChart from 'usage/containers/CostAndUsageExplorer/components/CostChart';
import { groupDataByUsageDate, prepareDataForDisplayChart, prepareDataKeys } from 'shared/utils/dataPrepareUtil';
import LegendKeysFilterHandler from 'shared/components/ChartKeysFilter/legendKeysFilterHandler';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import PieChartComp from './PieChartComponent';
import { ec2UsageWrapper } from './Ec2UsageWrapper';

class Ec2UsagePanel extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    getPageFilters: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    ec2Cost: PropTypes.array.isRequired,
    isEc2CostLoading: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    setDate: PropTypes.func.isRequired,
    currPeriodGranLevel: PropTypes.string.isRequired,
    setCurrPeriodGranLevel: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    selectedOptionsMap: PropTypes.object.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      filteredKeys: [],
      favourites: [],
      isInitialDataKeyFilterLoad: true,
      isLineChart: false,
      currMetricType: AppCommonFields.TOTAL_QUANTITY,
    };
    this.mainLegendKeysFilterHandler = new LegendKeysFilterHandler(this);
    this.setKeysFilterHandler = this.mainLegendKeysFilterHandler.setKeysFilterHandler.bind(this);
    this.addKeysFilterHandler = this.mainLegendKeysFilterHandler.addKeysFilterHandler.bind(this);
    this.removeKeysFilterHandler = this.mainLegendKeysFilterHandler.removeKeysFilterHandler.bind(this);
  }

  filterData = (dataArr, list) => {
    const arr = dataArr.filter((item) => list.indexOf(item.groupBy) !== -1);
    return arr;
  };

  calcTotalCost = (data) => {
    const cost = data.reduce((acc, { totalCost }) => {
      // eslint-disable-next-line no-param-reassign
      acc += totalCost || 0;
      return acc;
    }, 0);
    return cost;
  };

  toggleCharts = () => {
    const { isLineChart } = this.state;
    this.setState({
      isLineChart: !isLineChart,
    });
  };

  datePickerSyntexDates = () => {
    const { startDate: startDateProps, endDate: endDateProps } = this.props;
    let { startDate, endDate } = buildStartAndEndDate(startDateProps, endDateProps);
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  prepareEc2SevenDaysChartData = (sevenDaysData) => {
    const { currMetricType } = this.state;
    const groupByDateData = groupDataByUsageDate(sevenDaysData);
    const modifiedDailyBalances = prepareDataForDisplayChart(
      groupByDateData,
      AppCommonFields.USAGE_DATE,
      AppCommonFields.GROUP_BY,
      currMetricType,
    );
    return modifiedDailyBalances;
  };

  prepareChartFiltersAndData = (data) => {
    const { filteredKeys, favourites, isInitialDataKeyFilterLoad } = this.state;
    const modifiedDailyBalances = data ? this.prepareEc2SevenDaysChartData(data) : [];
    const dataKeys = prepareDataKeys(modifiedDailyBalances, 'name', 'cost');
    const filteredDataKeys = this.mainLegendKeysFilterHandler.filterDataKeys(
      dataKeys,
      filteredKeys,
      isInitialDataKeyFilterLoad,
    );
    const chartKeysData = this.mainLegendKeysFilterHandler.prepareFilterKeysFromDataKeys(dataKeys);
    return {
      modifiedDailyBalances,
      chartKeysData,
      filteredKeys,
      filteredDataKeys,
      dataKeys,
      favourites,
      isInitialDataKeyFilterLoad,
    };
  };

  renderLineChart = (
    height,
    data,
    chartKeysData,
    filteredDataKeys,
    dataKeys,
    favourites,
    chartId,
    currPeriodGranLevel,
  ) => (
    <SimpleLineChart
      setKeysFilterHandler={this.setKeysFilterHandler}
      addKeysFilterHandler={this.addKeysFilterHandler}
      removeKeysFilterHandler={this.removeKeysFilterHandler}
      onShowOthersChecked={this.mainLegendKeysFilterHandler.onShowOthersChecked}
      data={data}
      mainLegendKeysFilterHandler={this.mainLegendKeysFilterHandler}
      dataKeys={dataKeys}
      chartKeysData={chartKeysData}
      favourites={favourites}
      filteredDataKeys={filteredDataKeys}
      XAxisDataKey={AppCommonFields.USAGE_DATE}
      YAxisLabelTitle={mapDataAttributesToDisplayMetricTypes.get(this.state.currMetricType)}
      title=""
      chartId={chartId}
      wrapInCardContainer={false}
      height={height}
      displayedMetric={this.state.currMetricType}
      granLevel={currPeriodGranLevel}
    />
  );

  renderCostChart = (
    height,
    data,
    chartKeysData,
    filteredKeys,
    dataKeys,
    favourites,
    isInitialDataKeyFilterLoad,
    chartId,
    currPeriodGranLevel,
  ) => (
    <CostChart
      setKeysFilterHandler={this.setKeysFilterHandler}
      addKeysFilterHandler={this.addKeysFilterHandler}
      removeKeysFilterHandler={this.removeKeysFilterHandler}
      height={height || 300}
      data={data}
      filteredKeys={filteredKeys}
      dataKeys={dataKeys}
      chartId={chartId}
      isInitialDataKeyFilterLoad={isInitialDataKeyFilterLoad}
      chartKeysData={chartKeysData}
      favourites={favourites}
      yAxisLabelTitle={mapDataAttributesToDisplayMetricTypes.get(this.state.currMetricType)}
      mainLegendKeysFilterHandler={this.mainLegendKeysFilterHandler}
      barClickable={false}
      isRenderKeysFilter
      displayedMetric={this.state.currMetricType}
      granLevel={currPeriodGranLevel}
      toInt
      isFromServices
      selectedUsageType={DisplayMetricTypes.QUANTITY}
      costTypeMode={CostTypeModes.USAGE}
    />
  );

  render() {
    const {
      height,
      isEc2CostLoading,
      setFilters,
      ec2Cost,
      setDate,
      usageStore,
      invoiceStore,
      getPageFilters,
      setCurrPeriodGranLevel,
      startDate,
      endDate,
      currPeriodGranLevel,
      selectedOptionsMap,
      numStrAbriviaionByDisplayMetric,
    } = this.props;
    const { isLineChart } = this.state;
    if (isEc2CostLoading || invoiceStore.isLoading) {
      return (
        <div style={{ height: '400px' }}>
          <Spinner />
        </div>
      );
    }
    const fieldToFieldDistinctValuesMap = getPageFilters(
      PageNames.EC2_USAGE,
      usageStore.rootStore.usersStore.currDispUserCloudAccountType,
    );
    if (!ec2Cost.length) {
      return (
        <>
          <ImmediateFilterBar
            startDate={this.datePickerSyntexDates(startDate, null).startDate}
            endDate={this.datePickerSyntexDates(null, endDate).endDate}
            handleDateChange={setDate}
            renderDatePickerFilter
            // Period Gran
            currPeriodGranLevel={currPeriodGranLevel}
            handleChangeGranLevel={setCurrPeriodGranLevel}
            renderGranPeriodButton
            selectedOptionsMap={selectedOptionsMap}
            fieldToFieldDistinctValuesMap={fieldToFieldDistinctValuesMap}
            handleFilterChange={setFilters}
            currDispUserCloudAccountType={usageStore.rootStore.usersStore.currDispUserCloudAccountType}
            renderFilters
          />
          <NoDataFoundComponent />
        </>
      );
    }
    const {
      modifiedDailyBalances,
      chartKeysData,
      filteredKeys,
      filteredDataKeys,
      dataKeys,
      favourites,
      isInitialDataKeyFilterLoad,
    } = this.prepareChartFiltersAndData(ec2Cost);
    const finalEc2QtyChartId = `ec2-qty-chart-${random5DigitNumber()}`;
    const finalPieChartId = `pie-chart-${random5DigitNumber()}`;
    const totalCost = this.calcTotalCost(this.filterData(ec2Cost, filteredKeys));

    return (
      <>
        <ImmediateFilterBar
          // DatePicker
          startDate={this.datePickerSyntexDates(startDate, null).startDate}
          endDate={this.datePickerSyntexDates(null, endDate).endDate}
          handleDateChange={setDate}
          renderDatePickerFilter
          isShowQuarterly
          // Period Gran
          currPeriodGranLevel={currPeriodGranLevel}
          handleChangeGranLevel={setCurrPeriodGranLevel}
          renderGranPeriodButton
          // Filters
          selectedOptionsMap={selectedOptionsMap}
          fieldToFieldDistinctValuesMap={fieldToFieldDistinctValuesMap}
          handleFilterChange={setFilters}
          currDispUserCloudAccountType={usageStore.rootStore.usersStore.currDispUserCloudAccountType}
          renderFilters
        />
        <Row id={finalEc2QtyChartId}>
          <Col md={12} lg={10} xl={10} sm={12} xs={12}>
            <Button
              style={{ padding: '2px', marginBottom: '10px', float: 'right' }}
              id={0}
              outline
              onClick={this.toggleCharts}
            >
              {isLineChart ? <ChartBarStackedIcon /> : <ChartTimelineVariantIcon />}
            </Button>
          </Col>
          <Col md={12} lg={10} xl={10} sm={12} xs={12}>
            {isLineChart
              ? this.renderLineChart(
                  height,
                  modifiedDailyBalances,
                  chartKeysData,
                  filteredDataKeys,
                  dataKeys,
                  favourites,
                  finalEc2QtyChartId,
                  currPeriodGranLevel,
                )
              : this.renderCostChart(
                  height,
                  modifiedDailyBalances,
                  chartKeysData,
                  filteredKeys,
                  dataKeys,
                  favourites,
                  isInitialDataKeyFilterLoad,
                  finalEc2QtyChartId,
                  currPeriodGranLevel,
                )}
          </Col>
          <Col md={12} lg={2} xl={2} sm={12} xs={12}>
            <div style={{ position: 'relative' }}>
              <p
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  height: 'calc(50% - 2em)',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <span style={{ display: 'inline-block', fontSize: '1em' }}>Total cost</span>
                <b style={{ display: 'inline-block', fontSize: '1.5em' }}>
                  {numStrAbriviaionByDisplayMetric(totalCost, totalCost, DisplayMetricTypes.COST)}
                </b>
              </p>
              <PieChartComp
                chartId={finalPieChartId}
                data={this.filterData(ec2Cost, filteredKeys)}
                height={height}
                isRenderDownloadAsPng
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const observerEc2UsagePanel = withInvoiceFiltersContextConsumer(
  ec2UsageWrapper(withUserSettingsConsumer(observer(Ec2UsagePanel))),
);

export default observerEc2UsagePanel;
