import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import moment from 'moment';
import { PageNames } from 'shared/constants/appConstants';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import Spinner from 'shared/components/andtComponents/Spinner';
import ImmediateFilterBar from 'shared/components/ImmediateFilterBar';
import toast from 'shared/components/andtComponents/Toast';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import PricingMethodChart from './components/PricingMethodChart';
import PricingMethodSummaryChart from './components/PricingMethodSummaryChart';
import ChartPanel from './components/ChartPanel';
import CostSavingWasteBarChart from './components/CostSavingWasteChart';
import ServiceFilter from './components/ServiceFilter';
import { RI_SERVICE_FILTER_OPTIONS } from '../Commitments/constants/commitmentConstants';
import Top10CommitmentTable from './components/Top10CommitmentTable';

class CommitmentDashboardPage extends Component {
  static propTypes = {
    commitmentStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialDefaultDates(),
      dataIsLoading: true,
      selectedCommitmentService: { label: 'EC2', value: 'ec2' },
      isValidRequest: true,
    };
  }

  async componentDidMount() {
    const { selectedCommitmentService, startDate, endDate } = this.state;
    await this.fetchData('month', startDate, endDate, selectedCommitmentService);
  }
  topServicesColumns = [
    {
      name: 'linkedAccount',
      title: 'Linked Account',
      getCellValue: (row) => row.linkedAccountName || row.linkedAccountId,
    },
    {
      name: 'commitment',
      title: 'Commitment',
      getCellValue: (row) => `${row.commitmentType === 'ri' ? row.instanceType : row.HourlyCommitment}`,
    },
    {
      name: 'expirationDate',
      title: 'Expiration Date',
      getCellValue: (row) => moment(row.endDateTime || row.EndDateTime).format('YYYY-MM-DD'),
    },
    {
      name: 'utilizationPercentage',
      title: 'Utilization Percent',
      getCellValue: (row) => +row.UtilizationPercentage,
    },
  ];

  fetchData = async (currPeriodGranLevel = 'month', startDate, endDate, selectedCommitmentService) => {
    try {
      const { commitmentStore } = this.props;
      this.setState({ dataIsLoading: true });
      const serviceFilterMap = selectedCommitmentService
        ? new Map([['service', [selectedCommitmentService.value]]])
        : new Map();
      await commitmentStore.getCommitmentDashboardData(currPeriodGranLevel, startDate, endDate, serviceFilterMap);
      this.setState({ dataIsLoading: false });
    } catch (error) {
      this.setState({ dataIsLoading: false });
    }
  };

  isValidRequest = () => {
    const { selectedCommitmentService } = this.state;
    const result = !!selectedCommitmentService;
    this.setState({ isValidRequest: result });
    if (!result) {
      toast.error('You must select a service');
    }
    return result;
  };

  initialDefaultDates = () => {
    const { endDate } = buildStartAndEndDate();
    const startDate = moment(endDate).add(-180, 'days').format('YYYY-MM-DD');
    return { startDate, endDate };
  };

  handleDateChange = ({ startDate, endDate }) => {
    const { selectedCommitmentService, startDate: startDateState, endDate: endDateState } = this.state;
    const start = moment(startDate || startDateState).format('YYYY-MM-DD');
    let end = moment(endDate || endDateState).format('YYYY-MM-DD');
    if (start > end) {
      end = start;
    }
    this.setState({
      startDate: start,
      endDate: end,
    });
    if (!this.isValidRequest()) {
      return;
    }
    this.fetchData('month', start, end, selectedCommitmentService);
  };

  handleFilterChange = (type, value) => {
    const { startDate, endDate } = this.state;
    if (!value || this.state[type].value === value.value) {
      return;
    } // same value was selected
    this.setState({ [type]: value });
    if (!value) {
      this.setState({ isValidRequest: false });
      toast.error('You must select a service');
      return;
    }
    this.fetchData('month', startDate, endDate, value);
  };

  prepareDataForChart = (data) => {
    let prepData = data;
    prepData =
      data && data.length > 0
        ? data.map(({ onDemand, SPOT, SP, RI, ...rest }) => {
            let newData = { ...rest };
            if (onDemand) {
              newData = { ...newData, 'On-Demand': onDemand };
            }
            if (SPOT) {
              newData = { ...newData, Spot: SPOT };
            }
            if (RI) {
              newData = { ...newData, Reserved: RI };
            }
            if (SP) {
              newData = { ...newData, 'Savings Plans': SP };
            }
            return newData;
          })
        : [];
    return prepData;
  };

  prepareSummaryDataForCsv = (data) => {
    const keys = data && Object.keys(JSON.parse(JSON.stringify(data)));
    return keys.reduce((acc, key) => {
      if (typeof data[key] === 'object') {
        acc.push({ pricingMethod: key, ...data[key] });
      }
      return acc;
    }, []);
  };

  render() {
    const { commitmentStore } = this.props;
    const { startDate, endDate, selectedCommitmentService, dataIsLoading } = this.state;
    const { commitmentModel } = commitmentStore;
    const pricingMethodData = this.prepareDataForChart(commitmentModel.pricingMethodData);
    const { pricingMethodSummaryData, wasteData, top10UnutilizedCommitment, top10ExpiredSoonCommitment } =
      commitmentModel;
    if (dataIsLoading) {
      return (
        <div style={{ height: '400px' }}>
          <Spinner />
        </div>
      );
    }
    return (
      <Container>
        <PageHeader title={PageNames.COMMITMENT_DASHBOARD} showDate />
        <Card>
          <CardBody>
            <Row style={{ margin: '0', display: 'flex', flexWrap: 'wrap' }}>
              <ImmediateFilterBar
                handleDateChange={this.handleDateChange}
                renderDatePickerFilter
                containerStyle={{ margin: '0px' }}
                currPeriodGranLevel="month"
                startDate={startDate}
                endDate={endDate}
              />
              <ServiceFilter
                selectedCommitmentService={selectedCommitmentService}
                handleFilterChange={this.handleFilterChange}
                commitmentServiceOptions={RI_SERVICE_FILTER_OPTIONS}
              />
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody style={{ minHeight: '300px' }}>
            <Row>
              <ChartPanel
                title="Monthly Usage By Pricing Method"
                tableModifiedDailyBalance={pricingMethodData}
                csvTitle="Usage By Pricing Method"
                lg={8}
                xl={8}
              >
                <PricingMethodChart
                  data={pricingMethodData}
                  dateFilter={DateFilter}
                  showHeader
                  showOnlyBarChart
                  height={300}
                />
              </ChartPanel>
              <ChartPanel
                title="Total Hours Distribution"
                tableModifiedDailyBalance={this.prepareSummaryDataForCsv(pricingMethodSummaryData)}
                csvTitle="Total Hours Distribution"
                lg={4}
                xl={4}
              >
                <PricingMethodSummaryChart
                  data={pricingMethodSummaryData}
                  height={300}
                  finalPieChartId="pricing-method-cost"
                />
              </ChartPanel>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody style={{ minHeight: '300px' }}>
            <Row className="m-0">
              {!isEmptyArray(wasteData) ? (
                <ChartPanel
                  title="Commitment Savings & Waste"
                  tableModifiedDailyBalance={wasteData}
                  csvTitle="Commitment Waste"
                  lg={12}
                  xl={12}
                >
                  <CostSavingWasteBarChart data={wasteData} service={selectedCommitmentService.value} />
                </ChartPanel>
              ) : (
                <NoDataFoundComponent bodyText="" />
              )}
            </Row>
          </CardBody>
        </Card>
        {!isEmptyArray(top10UnutilizedCommitment) ? (
          <Card>
            <CardBody style={{ minHeight: '300px' }}>
              <Row className="m-0">
                <ChartPanel
                  title="Top 10 Unutilized Commitment"
                  tableModifiedDailyBalance={top10UnutilizedCommitment || []}
                  csvTitle="Top 10 Unutilized Commitment"
                  lg={12}
                  xl={12}
                >
                  <Top10CommitmentTable
                    tableData={top10UnutilizedCommitment || []}
                    tableColumns={this.topServicesColumns}
                    defaultSortBy="utilizationPercentage"
                    percentColumns={['utilizationPercentage']}
                  />
                </ChartPanel>
              </Row>
            </CardBody>
          </Card>
        ) : null}
        {!isEmptyArray(top10ExpiredSoonCommitment) ? (
          <Card>
            <CardBody style={{ minHeight: '300px' }}>
              <Row className="m-0">
                <ChartPanel
                  title="Top 10 Commitment Expiring Soon"
                  tableModifiedDailyBalance={top10ExpiredSoonCommitment || []}
                  csvTitle="Top 10 Commitment Expiring Soon"
                  lg={12}
                  xl={12}
                >
                  <Top10CommitmentTable
                    percentColumns={['utilizationPercentage']}
                    tableData={top10ExpiredSoonCommitment || []}
                    tableColumns={this.topServicesColumns}
                    defaultSortBy="expirationDate"
                  />
                </ChartPanel>
              </Row>
            </CardBody>
          </Card>
        ) : null}
      </Container>
    );
  }
}

const ObserverCommitmentDashboardPage = observer(CommitmentDashboardPage);
export default ObserverCommitmentDashboardPage;
