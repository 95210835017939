import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Alert } from 'shared/img/icons/alert.svg';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './alertColumn.module.scss';

const propTypes = {
  value: PropTypes.array,
  alertRuleIds: PropTypes.array,
};

const defaultProps = {
  value: [],
  alertRuleIds: [],
};

const AlertedColumn = ({ value: alerts, alertRuleIds }) => {
  if (!alerts || !alerts.length) {
    return null;
  }
  const isClosed = alerts.every((alert) => alert.isClosed || !alertRuleIds.includes(alert.ruleUuid));
  return (
    <Tooltip
      disabled={isClosed}
      placement="bottom"
      title={
        <div className="d-flex flex-column">
          <span>Active alerts:</span>
          {alerts
            .filter((alert) => !alert.isClosed && alertRuleIds.includes(alert.ruleUuid))
            .map((alert) => (
              <span key={alert.ruleUuid}>{alert.ruleName}</span>
            ))}
        </div>
      }
      arrow
    >
      <div>
        <Alert className={styles[`${isClosed ? 'closedAlert' : 'openAlert'}`]} />
      </div>
    </Tooltip>
  );
};

AlertedColumn.propTypes = propTypes;
AlertedColumn.defaultProps = defaultProps;
export default AlertedColumn;
