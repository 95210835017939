import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { copyStrToClipboard } from 'shared/utils/strUtil';

import classes from './cliCommmandBlock.module.scss';

const CliCommandBlock = ({ cliCommand }) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    copyStrToClipboard(cliCommand);
    setCopied(true);
  };

  const commandParts = cliCommand.split(/(?<=\s|^)--/);
  const highlightParameterValues = commandParts?.map((part, index) => {
    if (index === 0) {
      return <span>{part}</span>;
    }
    const [parameter, ...rest] = part.trim().split(' ');
    const value = rest.join(' ');
    return (
      <span>
        --{parameter} <span className={classes.parameterValue}>{`${value} `}</span>
      </span>
    );
  });

  return (
    <div className={classes.commandContainer} data-active={copied}>
      <div className={classes.command}>{highlightParameterValues}</div>
      <div className={classes.copyIcon} onClick={copyToClipboard}>
        <GenerateIcon iconName={ICONS.copy.name} />
      </div>
    </div>
  );
};

CliCommandBlock.propTypes = {
  cliCommand: PropTypes.string.isRequired,
};

export default CliCommandBlock;
