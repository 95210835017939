import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from 'recommendations/shared/components/ActionComponents';
import { useRecommendationBuilderContext } from 'recommendations/shared/components/RecommendationBuilderContext';

const Action = ({ rec }) => {
  const contextData = useRecommendationBuilderContext();
  const actionCommandText =
    contextData?.selectedOption >= 0 && rec.alternatives[contextData.selectedOption]?.command
      ? rec.alternatives[contextData.selectedOption].command
      : rec?.command;

  const actionCommandComment =
    contextData?.selectedOption >= 0 && rec.alternatives[contextData.selectedOption]?.commandComment
      ? rec.alternatives[contextData.selectedOption].commandComment
      : rec?.commandComment;

  return (
    <ActionContainer>
      <ActionHeader />
      <ul style={{ listStyleType: 'none' }}>
        <li>Using the AWS CLI:</li>
        {actionCommandComment ? <li className="red-text">{actionCommandComment}</li> : null}
        <CodeBlock text={actionCommandText} variables={{}} />
        <br />
      </ul>
    </ActionContainer>
  );
};

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
