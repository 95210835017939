import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { isNil } from 'lodash';
import UsersHeader from './UsersHeader';
import UsersTable from './UsersTable';
import { UsersProvider } from './contexts/usersContext';
import { GET_USERS_QUERY_PARAMS, PAGINATION_DIRECTION } from '../consts';

import styles from './Users.module.scss';

const Users = () => {
  const { NewTableWrapper } = useTable();

  const [fetchUsersQueryParams, setFetchUsersQueryParams] = useState({});

  const usersHook = useUsers();
  const { data, isLoading } = usersHook.fetchUsers({ ...fetchUsersQueryParams });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_USERS_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setFetchUsersQueryParams({ ...fetchUsersQueryParams, [GET_USERS_QUERY_PARAMS.SEARCH.name]: searchParam });
    }
  }, []);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setFetchUsersQueryParams({
        ...fetchUsersQueryParams,
        [GET_USERS_QUERY_PARAMS.PAGINATION_TOKEN.name]: data?.paginationToken,
      });
    }
  };

  const userStatusFilterChange = (enabled) => {
    if (
      (isNil(fetchUsersQueryParams?.[GET_USERS_QUERY_PARAMS.ENABLED.name]) && isNil(enabled)) ||
      fetchUsersQueryParams[GET_USERS_QUERY_PARAMS.ENABLED.name] === enabled
    ) {
      return;
    }
    if (isNil(enabled)) {
      const { [GET_USERS_QUERY_PARAMS.ENABLED.name]: _, ...rest } = fetchUsersQueryParams;
      setFetchUsersQueryParams(rest);
    } else {
      setFetchUsersQueryParams({ ...fetchUsersQueryParams, [GET_USERS_QUERY_PARAMS.ENABLED.name]: enabled });
    }
  };

  const searchChanged = (search) => {
    if (search) {
      setFetchUsersQueryParams({
        ...fetchUsersQueryParams,
        [GET_USERS_QUERY_PARAMS.SEARCH.name]: search,
      });
    } else {
      const { [GET_USERS_QUERY_PARAMS.SEARCH.name]: _, ...rest } = fetchUsersQueryParams;
      setFetchUsersQueryParams(rest);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.users}>
      <UsersProvider>
        <NewTableWrapper>
          <UsersHeader
            pageChanged={pageChanged}
            search={fetchUsersQueryParams?.[GET_USERS_QUERY_PARAMS.SEARCH.name]}
            setSearch={searchChanged}
            total={data?.total}
            userStatusFilter={fetchUsersQueryParams?.[GET_USERS_QUERY_PARAMS.ENABLED.name]}
            userStatusFilterChange={userStatusFilterChange}
          />
          <UsersTable isLoading={isLoading} users={data?.users} />
        </NewTableWrapper>
      </UsersProvider>
    </div>
  );
};

export default Users;
