import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import styles from './EditDivisionModal.module.scss';

const EditDivisionModal = ({ editedDivision, onClose, saveDivision }) => {
  const [divisionName, setDivisionName] = useState(editedDivision ? editedDivision.divisionNameDisplay : '');
  const [divisionCode, setDivisionCode] = useState(editedDivision ? editedDivision.divisionCode : '');
  const [saveClicked, setSaveClicked] = useState(false);
  const [errors, setErrors] = useState({
    divisionName: '',
  });
  const onSaveClick = async () => {
    setSaveClicked(true);
    if (!divisionName) {
      setErrors((prevErrors) => ({ ...prevErrors, divisionName: 'Division name is mandatory' }));
      return;
    }
    saveDivision({ divisionName, divisionCode });
  };
  const renderErrorMsg = (fieldName) =>
    saveClicked && errors && errors[fieldName] ? <span className={styles.errorMsg}>{errors[fieldName]}</span> : null;
  const onDivisionNameChange = (e) => {
    const { value } = e.target;
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, divisionName: '' }));
    }
    setDivisionName(value);
  };
  const onDivisionCodeChange = (e) => {
    const { value } = e.target;
    setDivisionCode(value);
  };
  return (
    <CustomModal
      onClose={onClose}
      open
      title="Edit Cost Center"
      closeOnSave={false}
      overrideStyles={{ width: 600 }}
      headerMode="edit"
      onSave={onSaveClick}
    >
      <div className="d-flex">
        <div className="me-2">
          <span>Cost Center Name</span>
          <Input
            label="Cost Center Name"
            name="divisionName"
            overrideStyles={{ height: 36 }}
            value={divisionName}
            onChange={onDivisionNameChange}
            placeholder="Enter Cost Center name"
            invalidComponent={renderErrorMsg('divisionName')}
            isInvalid={saveClicked && !!errors?.divisionName}
          />
        </div>
        <div>
          <span>Cost Center Code</span>
          <Input
            label="Cost Center Code"
            name="divisionCode"
            overrideStyles={{ height: 36 }}
            value={divisionCode}
            onChange={onDivisionCodeChange}
            placeholder="Enter Cost Center code"
          />
        </div>
      </div>
    </CustomModal>
  );
};

EditDivisionModal.propTypes = {
  editedDivision: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  saveDivision: PropTypes.func.isRequired,
};
export default EditDivisionModal;
