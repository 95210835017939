/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ActionContainer } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    {/* <h5 className="bold-text card__title project-summary__statistic-title" style={{ 'text-transform': 'uppercase' }}>
      Steps To Execute
    </h5>
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the AWS CLI:</li>
      <div className="project-summary__copy-button">
        <CopyFromCmdBoxButton boxId="1" />
        <pre id="1">
          <code>
            aws ec2 modify-volume --volume-type gp2 --volume-id <span style={{ color: 'red' }}>{rec.ebsId}</span>
          </code>
        </pre>
      </div>
      <br />
    </ul> */}
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
