import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import FieldFilter from 'shared/components/FieldFilter';

const propTypes = {
  filterType: PropTypes.string.isRequired,
  relation: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  displayError: PropTypes.bool,
  errMsg: PropTypes.string,
};

const defaultProps = {
  displayError: false,
  errMsg: '',
};

const selectStyles = ({ control = {} } = {}) => ({
  control: (base) => ({
    ...base,
    backgroundColor: '#E9E9F0',
    borderStyle: 'none',
    borderRadius: '6px',
    ...control,
  }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: '80px',
    overflowY: 'auto',
  }),
});

const AlertRuleFilter = ({ relation, values, options, setFilter, displayError, errMsg, filterType }) => (
  <div className="d-flex flex-grow-1 mb-1">
    <div className="d-flex flex-column me-2">
      <FieldFilter
        value={relation}
        options={[
          { label: 'Include', value: 'include' },
          { label: 'Exclude', value: 'exclude' },
        ]}
        handleChange={(_, val) => setFilter(filterType, 'relation', val)}
        singleSelect
        isClearable={false}
        styles={selectStyles({ control: { width: '103px' } })}
      />
    </div>
    <div className="d-flex flex-column flex-grow-1">
      <FieldFilter
        type={filterType}
        value={values}
        options={options}
        handleChange={(_, val) => setFilter(filterType, 'values', val || [])}
        styles={selectStyles()}
      />
      <ErrorMessage displayError={displayError} errMsg={errMsg} />
    </div>
  </div>
);

AlertRuleFilter.propTypes = propTypes;
AlertRuleFilter.defaultProps = defaultProps;
export default AlertRuleFilter;
