/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import DateFilter from 'shared/modules/dateFilter';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { buildFilterParams } from 'shared/utils/apiUtil';
import { CommitmentServices } from 'usage/constants/costAndUsageConstants';

export const DASHBOARD_DATA = {
  MONTHLY_DATA: 'modifiedPricingMethodData',
  SUMMARY_DATA: 'modifiedPricingMethodSummary',
  AVG_HOURLY: 'avgHourlyCostData',
  HAS_SAVINGS_PLANS: 'hasSavingsPlans',
  COST_SAVINGS: 'modifiedCostSavingsData',
  COST_WASTE: 'modifiedCostWasteData',
  TOP_10_UNUTILIZED: 'top10UnutilizedCommitment',
  TOP_10_EXPIRED_SOON: 'top10ExpiredSoonCommitment',
};
export default class CommitmentModel {
  constructor(apiGateway) {
    this.apiGateway = apiGateway;
    this.riChartsData = [];
    this.spStatData = [];
    this.currentRiChartDataMonth = -1;
    this.currentSpStatDataMonth = -1;
    this.riCommitmentTableData = [];
    this.spCommitmentTableData = [];
    this.riFlexabilityData = [];
    this.riChargeBackData = [];
    this.servicePlanchargeBackData = [];
    this.isInitialLoading = true;
    this.dashboardData = new Map([
      [DASHBOARD_DATA.MONTHLY_DATA, []],
      [DASHBOARD_DATA.SUMMARY_DATA, []],
      [DASHBOARD_DATA.AVG_HOURLY, []],
      [DASHBOARD_DATA.COST_SAVINGS, []],
      [DASHBOARD_DATA.COST_WASTE, []],
      [DASHBOARD_DATA.TOP_10_UNUTILIZED, []],
      [DASHBOARD_DATA.TOP_10_EXPIRED_SOON, []],
      [DASHBOARD_DATA.HAS_SAVINGS_PLANS, false],
    ]);
    makeObservable(this, {
      riChartsData: observable,
      spStatData: observable,
      // commitmentChartsAndStatisticsData: observable,
      spCommitmentTableData: observable,
      riCommitmentTableData: observable,
      // commitmentTableData: observable,
      currentRiChartDataMonth: observable,
      currentSpStatDataMonth: observable,
      isInitialLoading: observable,
      riChargeBackData: observable,
      servicePlanchargeBackData: observable,
      riFlexabilityData: observable,
      dashboardData: observable,
      getRiChargeBackData: action,
      getRiFlexabilityData: action,
      getCommitmentChartsAndStatisticsData: action,
      getTableDataByParams: action,
      getCommitmentDashboardData: action,
      pricingMethodData: computed,
      pricingMethodSummaryData: computed,
      avgHourlyCostData: computed,
      hasSavingsPlans: computed,
      top10UnutilizedCommitment: computed,
      top10ExpiredSoonCommitment: computed,
    });
    // TODO add ec2pricingmethod data model
  }

  getCommitmentTypeData = async (date, commitmentType, commitmentService, linkedAccount) => {
    try {
      const isMonthUpdate = true;
      await this.getCommitmentChartsAndStatisticsData(date, commitmentType, isMonthUpdate);
      if (commitmentService) {
        await this.getTableDataByParams(date, commitmentService, linkedAccount, commitmentType);
      }
    } catch (error) {
      this.isInitialLoading = false;
    }
  };
  defaultdate = () => {
    const { month, year } = DateFilter.getCurrMonthAndYear();
    return `${year}-${month}-1`;
  };
  prepareRiChartsData = (data) => {
    const { unfulfilledServices, unutiliziedAccounts, unutiliziedServices, totalSavingsServices } = data;
    const arrData = [];
    if (totalSavingsServices) {
      totalSavingsServices.chartTitle = 'RI Savings By Service';
      arrData.push(totalSavingsServices);
    }
    if (unfulfilledServices) {
      unfulfilledServices.chartTitle = 'Unfulfilled RI By Service';
      arrData.push(unfulfilledServices);
    }
    if (unutiliziedServices) {
      unutiliziedServices.chartTitle = 'Unutilized RI By Service';
      arrData.push(unutiliziedServices);
    }
    if (unutiliziedAccounts) {
      unutiliziedAccounts.chartTitle = 'Unutilized RI By Account';
      arrData.push(unutiliziedAccounts);
    }

    return arrData;
  };

  getCommitmentChartsAndStatisticsData = async (date = null, commitmentType, isMonthUpdate) => {
    if (commitmentType === 'ri') {
      if (this.riChartsData.length > 0 && !isMonthUpdate) {
        return;
      }
    } else if (commitmentType === 'sp' && !isMonthUpdate) {
      if (this.spStatData.length > 0) {
        return;
      }
    }
    const endDate = date || this.defaultdate();
    try {
      const data = await this.apiGateway.getCommitmentChartsAndStatisticsData(endDate, commitmentType);
      if (data && Array.isArray(data)) {
        this.isInitialLoading = false;
        if (data.length === 0) {
          if (commitmentType === 'ri') {
            this.riChartsData = [];
          } else if (commitmentType === 'sp') {
            this.spStatData = [];
          }
          return;
        }
        if (commitmentType === 'ri') {
          this.currentRiChartDataMonth = new Date(endDate).getMonth();
          this.riChartsData = this.prepareRiChartsData(data[0]);
        }
        if (commitmentType === 'sp') {
          this.currentSpStatDataMonth = new Date(endDate).getMonth();
          this.spStatData = [data[0].spStats];
        }
      }
    } catch (error) {
      this.isInitialLoading = true;
    }
  };
  getTableDataByParams = async (
    date = null,
    commitmentService = CommitmentServices.EC2_COMPUTE_RI,
    linkedAccount = null,
    commitmentType,
    payerAccount = null,
  ) => {
    const endDate = date || this.defaultdate();
    const data = await this.apiGateway.getCommitmentTableData(
      endDate,
      commitmentService,
      linkedAccount,
      commitmentType,
      payerAccount,
    );
    if (data) {
      if (commitmentType === 'ri') {
        this.riCommitmentTableData = data;
      } else if (commitmentType === 'sp') {
        this.spCommitmentTableData = data;
      }
    }
  };
  getRiFlexabilityData = async (arn, startDate, endDate) => {
    const data = await this.apiGateway.getRiFlexabilityData(arn, startDate, endDate);
    if (data) {
      this.riFlexabilityData = data;
    }
  };
  getRiChargeBackData = async (arn, startDate, endDate) => {
    try {
      // const data = await this.apiGateway.getCommitmentgetChargeBackData(arn, startDate, endDate);
      const data = await this.apiGateway.getRiChargeBackData(arn, startDate, endDate);
      if (data) {
        this.riChargeBackData = data;
      }
    } catch (error) {
      this.riChargeBackData = [];
    }
  };
  getServicePlansChargeBackData = async (arn, startDate, endDate) => {
    try {
      const data = await this.apiGateway.getServicePlansChargeBackData(arn, startDate, endDate);
      if (data) {
        this.servicePlanchargeBackData = data;
      }
    } catch (error) {
      this.servicePlanchargeBackData = [];
    }
  };
  getUtilizationDataByCommitTypeAndDataType = (commitType, dataType) => {
    const dataObj = {
      ri: {
        statData: this.riChartsData,
        tableData: this.riCommitmentTableData,
        currMonth: this.currentRiChartDataMonth,
      },
      sp: {
        statData: this.spStatData,
        tableData: this.spCommitmentTableData,
        currMonth: this.currentSpStatDataMonth,
      },
    };
    const data = commitType && dataType ? dataObj[commitType][dataType] : [];
    return data;
  };
  getCommitDetailedDataByCommitTypeAndModalType = (commitType, modalType) => {
    const dataObj = {
      ri: {
        cb: this.riChargeBackData,
        flexibility: this.riFlexabilityData,
      },
      sp: {
        cb: this.servicePlanchargeBackData,
      },
    };
    const data = commitType && modalType ? dataObj[commitType][modalType] : [];
    return data;
  };

  getCommitmentChartsAndStatDataByType = (commitType) =>
    this.getUtilizationDataByCommitTypeAndDataType(commitType, 'statData');

  getCurrDataMonthByType = (commitType) => this.getUtilizationDataByCommitTypeAndDataType(commitType, 'currMonth');

  getTableDataByType = (commitType) => this.getUtilizationDataByCommitTypeAndDataType(commitType, 'tableData');

  preparePricingMethodData = (data) => {
    if (!data || !data.length) {
      return data;
    }
    const dataAsArray = Array.isArray(data) ? data : [data];
    const prepData = [
      // ...dataAsArray.map(({ usage_date, ri_usage_hours, on_demand_usage_hours, spot_usage_hours, sp_usage_hours }) => ({
      ...dataAsArray.map(({ usageDate, riUsageHours, onDemandUsageHours, spotUsageHours, spUsageHours }) => ({
        // TODO change to new syntax
        date: usageDate,
        RI: riUsageHours,
        onDemand: onDemandUsageHours,
        SPOT: spotUsageHours,
        SP: spUsageHours,
      })),
    ];
    return prepData;
  };

  getCommitmentDashboardData = async (granLevel, inputStartDate, inputEndDate, filtersMap) => {
    const { startDate, endDate } = buildStartAndEndDate(inputStartDate, inputEndDate);
    const filterParams = buildFilterParams(filtersMap);
    try {
      let {
        modifiedPricingMethodData,
        avgHourlyCostData,
        hasSavingsPlans,
        modifiedPricingMethodSummary,
        modifiedCostSavingsData,
        modifiedCostWasteData,
        top10UnutilizedCommitment,
        top10ExpiredSoonCommitment,
      } = await this.apiGateway.getCommitmentDashboardData(granLevel, startDate, endDate, filterParams);
      modifiedPricingMethodData = this.preparePricingMethodData(modifiedPricingMethodData);
      // modifiedPricingMethodSummary = this.preparePricingMethodSummaryData(modifiedPricingMethodSummary);
      const dashboardData = new Map();
      dashboardData.set(DASHBOARD_DATA.MONTHLY_DATA, modifiedPricingMethodData || []);
      dashboardData.set(DASHBOARD_DATA.SUMMARY_DATA, modifiedPricingMethodSummary || []);
      dashboardData.set(DASHBOARD_DATA.AVG_HOURLY, avgHourlyCostData || []);
      dashboardData.set(DASHBOARD_DATA.HAS_SAVINGS_PLANS, hasSavingsPlans || []);
      dashboardData.set(DASHBOARD_DATA.COST_SAVINGS, modifiedCostSavingsData || []);
      dashboardData.set(DASHBOARD_DATA.COST_WASTE, modifiedCostWasteData || []);
      dashboardData.set(DASHBOARD_DATA.TOP_10_UNUTILIZED, top10UnutilizedCommitment || []);
      dashboardData.set(DASHBOARD_DATA.TOP_10_EXPIRED_SOON, top10ExpiredSoonCommitment || []);
      runInAction(() => {
        this.dashboardData = new Map(dashboardData);
      });
    } catch (error) {
      runInAction(() => {
        this.state = error;
      });
    }
  };

  get top10UnutilizedCommitment() {
    return this.dashboardData.get(DASHBOARD_DATA.TOP_10_UNUTILIZED);
  }

  get top10ExpiredSoonCommitment() {
    return this.dashboardData.get(DASHBOARD_DATA.TOP_10_EXPIRED_SOON);
  }

  get pricingMethodData() {
    return this.dashboardData.get(DASHBOARD_DATA.MONTHLY_DATA);
  }

  get pricingMethodSummaryData() {
    return this.dashboardData.get(DASHBOARD_DATA.SUMMARY_DATA);
  }

  get wasteData() {
    return this.dashboardData.get(DASHBOARD_DATA.COST_WASTE);
  }

  get savingsData() {
    return this.dashboardData.get(DASHBOARD_DATA.COST_SAVINGS);
  }

  get avgHourlyCostData() {
    return this.dashboardData.get(DASHBOARD_DATA.AVG_HOURLY);
  }

  get hasSavingsPlans() {
    return this.dashboardData.get(DASHBOARD_DATA.HAS_SAVINGS_PLANS);
  }

  getCurrentDataMonth = (commitmentType) => {
    const month = '';
  };
  invalidateChargeBackAndRiFlexabilityData = () => {
    this.riFlexabilityData = [];
    this.riChargeBackData = [];
  };
  invalidateTableData = () => {
    this.riCommitmentTableData = [];
    this.spCommitmentTableData = [];
    this.servicePlanchargeBackData = [];
  };
  invalidateData = () => {
    this.riChartsData = [];
    this.spStatData = [];
    this.currentRiChartDataMonth = -1;
    this.currentSpStatDataMonth = -1;
    // this.commitmentChartsAndStatisticsData = [];
    // this.commitmentTableData = [];
    this.riCommitmentTableData = [];
    this.spCommitmentTableData = [];
    this.riFlexabilityData = [];
    this.riChargeBackData = [];
    this.servicePlanchargeBackData = [];
    this.isInitialLoading = true;
    this.dashboardData = new Map([
      [DASHBOARD_DATA.MONTHLY_DATA, []],
      [DASHBOARD_DATA.SUMMARY_DATA, []],
      [DASHBOARD_DATA.AVG_HOURLY, []],
      [DASHBOARD_DATA.COST_SAVINGS, []],
      [DASHBOARD_DATA.COST_WASTE, []],
      [DASHBOARD_DATA.HAS_SAVINGS_PLANS, false],
    ]);
  };
}
