import React from 'react';
import PropTypes from 'prop-types';
import { BASE_PROPERTIES, COST_PROPERTIES } from '../../recommendationPropertyUtils';
import RecommendedHourlyCommitment from '../../recommendationGenericComponents/recommendedHourlyCommitment';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';

const SAVINGS_PLAN_PROPERTIES = {
  SAVINGS_PLAN_TYPE: {
    label: 'Savings Plan Type',
    getterFunction: (recommendation) => recommendation?.recData?.savings_plan_type,
  },
  RECOMMENDED_HOURLY_COMMITMENT: {
    label: 'Recommended Hourly Commitment',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.recommended_hourly_commitment,
  },
  UPFRONT_COST: {
    label: 'Upfront Cost',
    isCurrency: true,
    getterFunction: (recommendation) => {
      if (
        typeof recommendation?.recData?.recommended_upfront_payment === 'undefined' &&
        typeof recommendation?.recData?.recommended_partial_upfront_payment === 'undefined'
      ) {
        return undefined;
      }
      return recommendation?.recData?.recommended_upfront_payment !== undefined
        ? parseFloat(recommendation?.recData?.recommended_upfront_payment)
        : parseFloat(recommendation?.recData?.recommended_partial_upfront_payment || 0);
    },
  },
  PAYMENT_OPTION: {
    label: 'Payment Option',
    getterFunction: (recommendation) => recommendation?.recData?.payment_option,
  },
  TERM: {
    label: 'Term',
    getterFunction: (recommendation) => recommendation?.recData?.term,
  },
  MONTHLY_RECURRING_CHARGES: {
    label: 'Monthly Recurring Charges',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.monthly_recurring_charges?.toLocaleString(),
  },
  TOTAL_COMMITMENT: {
    label: 'Total Commitment',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.total_year_commitment?.toLocaleString(),
  },
};

const Ec2SavingPlan = ({ recommendation }) => {
  const properties = { ...BASE_PROPERTIES, ...COST_PROPERTIES, ...SAVINGS_PLAN_PROPERTIES };

  const description =
    'We recommend you to commit to this Savings plan, since your daily hourly usage is above the stated commitment.';
  const descriptionComponent = <RecommendationInsideLook description={description} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation?.typeId} />;

  const hourlyCommitmentComponent = (
    <RecommendedHourlyCommitment hourlyCommitment={recommendation?.recData?.recommended_hourly_commitment} />
  );

  return (
    <RecommendationDetailsLayout
      details={detailsComponent}
      preferences={preferencesComponent}
      insideLook={descriptionComponent}
      hourlyCommitment={hourlyCommitmentComponent}
    />
  );
};

Ec2SavingPlan.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Ec2SavingPlan;
