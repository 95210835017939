/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const handleSelect = (event, handler, mapValuesToDescription) => {
  const getValue = parseInt(event.target.value, 0);
  const validatedValue = mapValuesToDescription && mapValuesToDescription.get(getValue) ? getValue : false;
  //   handler(validatedValue);
  handler(event);
};

const renderList = (selectedValue, arrValues, mapValuesToDescription) =>
  arrValues.map((val, indx) => {
    const value = val;
    const displayedVal = mapValuesToDescription.get(val);
    return (
      <option selected={val === selectedValue} value={value}>
        {displayedVal}
      </option>
    );
  });

const SimpleSelectWithPropList = memo(
  ({ handler, label, name, value, type = 'text', icon, arrValues, mapValuesToDescription, ...rest }) => (
    <div>
      <label style={{ float: 'left' }} htmlFor={name}>
        {label}
      </label>
      <select
        style={{ float: rest.selectStyle.float || 'right', ...rest.selectStyle }}
        id={name}
        name={`${name}`}
        onChange={(event) => handleSelect(event, handler, mapValuesToDescription)}
        className="borderFormField"
      >
        {renderList(value, arrValues, mapValuesToDescription)}
      </select>
    </div>
  ),
);

export default SimpleSelectWithPropList;

SimpleSelectWithPropList.propTypes = {
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string,
  placeHolder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mapValuesToDescription: PropTypes.object.isRequired,
  arrValues: PropTypes.array.isRequired,
};

SimpleSelectWithPropList.defaultProps = {
  type: null,
};
