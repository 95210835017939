/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the AWS CLI:</li>
      <div>Delete a Cluster with No Final Cluster Snapshot</div>
      <CodeBlock
        text="aws redshift delete-cluster --cluster-identifier {{clusterId}} --skip-final-cluster-snapshot"
        variables={{
          clusterId: rec.clusterId,
        }}
      />
      <div>Delete a Cluster, Allowing a Final Cluster Snapshot</div>
      <CodeBlock
        text="aws redshift delete-cluster --cluster-identifier {{clusterId}}  --final-cluster-snapshot-identifier myfinalsnapshot"
        variables={{
          clusterId: rec.clusterId,
        }}
      />
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
