import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import RecommendationDetailsWidthContext from '../../contexts/recommendationDetailsWidthContext';

import classes from './recommendationDetailsLayout.module.scss';

const RecommendationDetailsLayout = ({
  otherResourceIdRecommendations,
  insideLook,
  details,
  optionsPanel,
  charts,
  command,
  preferences,
  normalizedFactorUnitsTable,
  recommendationOptionTable,
  additionalComponents,
  hourlyCommitment,
}) => {
  const { recommendationDetailsWidthRef } = useContext(RecommendationDetailsWidthContext);
  const tableWidth = recommendationDetailsWidthRef?.current?.clientWidth || '100%';

  return (
    <div className={classes.recommendationContainer} style={{ width: tableWidth }}>
      <div className={classes.detailsHeaderContainer}>
        {insideLook}
        {otherResourceIdRecommendations}
      </div>
      {optionsPanel && <div className={classes.recommendationOptionsContainer}>{optionsPanel}</div>}
      <div className={classes.recommendationDataContainer}>
        <div className={classes.leftPanel}>
          {details}
          {command}
          {preferences}
        </div>
        <div className={classes.rightPanel}>
          {hourlyCommitment}
          {charts}
          {normalizedFactorUnitsTable}
          {recommendationOptionTable}
        </div>
      </div>
      {additionalComponents?.length > 0 ? (
        <div className={classes.additionalComponentsContainer}>
          {additionalComponents.map((component) => (
            <div>{component}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

RecommendationDetailsLayout.propTypes = {
  otherResourceIdRecommendations: PropTypes.node,
  insideLook: PropTypes.node,
  details: PropTypes.node.isRequired,
  optionsPanel: PropTypes.node,
  charts: PropTypes.node,
  command: PropTypes.node,
  hourlyCommitment: PropTypes.node,
  preferences: PropTypes.node,
  normalizedFactorUnitsTable: PropTypes.node,
  additionalComponents: PropTypes.arrayOf(PropTypes.node),
};

RecommendationDetailsLayout.defaultProps = {
  otherResourceIdRecommendations: null,
  insideLook: null,
  optionsPanel: null,
  charts: null,
  command: null,
  hourlyCommitment: null,
  preferences: null,
  normalizedFactorUnitsTable: null,
  additionalComponents: null,
};

export default RecommendationDetailsLayout;
