import React from 'react';
import PropTypes from 'prop-types';

import BaseRecommendation from '../baseRecommendation';

const IpMigrateV4ToV6 = ({ recommendation }) => {
  const description = `
      This in-use public IP uses format v4, and starting 1 February 2024 will start to carry costs due to AWS new
      charges. We recommended upgrading to format v6 which is more secure and does not carry costs. For guidance on how
      to make migration click here`;

  const descriptionUrl = {
    text: 'here',
    url: 'https://docs.aws.amazon.com/vpc/latest/userguide/vpc-migrate-ipv6.html',
  };

  return (
    <BaseRecommendation recommendation={recommendation} description={description} descriptionUrl={descriptionUrl} />
  );
};

IpMigrateV4ToV6.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default IpMigrateV4ToV6;
