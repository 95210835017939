import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronRight } from 'react-feather';

export default function MenuCategory({ title, children, id = '', onClick = null, key = '', classes, initial = false }) {
  const [isOpen, setIsOpen] = useState(initial);

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };
  return (
    <div key={key} role="button" id={id} className={classes.menuCategoryEnv} onClick={(event) => handleClick(event)}>
      <div className={classes.menuCategory}>
        <span className={classes.noWrap}>{title}</span>
        {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
      </div>
      <Collapse isOpen={isOpen}>
        <ul>
          <div>{children}</div>
        </ul>
      </Collapse>
    </div>
  );
}

MenuCategory.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]).isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  key: PropTypes.string,
  classes: PropTypes.object.isRequired,
  initial: PropTypes.bool,
};

MenuCategory.defaultProps = {
  id: '',
  onClick: null,
  key: '',
  initial: false,
};
