import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from '../Button';
import styles from './FilesDownload.module.scss';

const downloadFile = (id) => {
  const link = document.getElementById(`${id}`);
  link.click();
};

const FilesDownload = ({ files, buttonTitle, disabled }) => {
  const [downloaded, setDownloaded] = useState({});
  const downloadAllFiles = () => {
    const downloads = {};
    files.forEach((file) => {
      downloads[file.nodeId] = true;
      downloadFile(file.nodeId);
    });
    setDownloaded(downloads);
  };
  const isAllDownloaded = Object.values(downloaded).filter(Boolean).length === files.length;
  return (
    <div className={styles.container}>
      <div className={styles.files}>
        {files.map((file) => (
          <a
            key={file.fileName}
            download={file.fileName}
            href={disabled ? '' : `data:application/octet-stream,${encodeURIComponent(file.jsonGen())}`}
            id={file.nodeId}
            target="_blank"
            data-active={!!downloaded[file.nodeId]}
            className={styles.file}
            rel="noreferrer"
            onClick={() => {
              if (disabled) {
                return;
              }
              setDownloaded({ ...downloaded, [file.nodeId]: true });
            }}
          >
            <GenerateIcon iconName={ICONS.file.name} />
            {file.title}
          </a>
        ))}
      </div>
      <Button
        overrideStyles={{ height: 28 }}
        onClick={downloadAllFiles}
        text={
          buttonTitle ||
          // eslint-disable-next-line no-nested-ternary
          (isAllDownloaded ? 'Download Again' : files.length > 1 ? 'Download JSON files' : 'Download JSON file')
        }
        icon={() => <GenerateIcon iconName={ICONS.download.name} />}
        isTextButton
        disabled={disabled}
      />
    </div>
  );
};

FilesDownload.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      nodeId: PropTypes.string.isRequired,
      jsonGen: PropTypes.func.isRequired,
    }),
  ).isRequired,
  buttonTitle: PropTypes.string,
  disabled: PropTypes.bool,
};

FilesDownload.defaultProps = {
  buttonTitle: '',
  disabled: false,
};

export default FilesDownload;
