/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class PodDetailsDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  TABLE_COLUMNS = [
    // CLOUDRIDE PATCH
    { name: 'parameter', title: ' ' },
    { name: 'requirements', title: 'Requirements' },
    { name: 'usage', title: 'Usage', getCellValue: (row) => row.usage.toFixed(1) },
    { name: 'cost', title: 'Cost' },
  ];
  TABLE_COLUMNS_WIDTHS = [
    { columnName: 'parameter', width: 200 },
    { columnName: 'requirements', width: 150 },
    { columnName: 'usage', width: 150 },
    { columnName: 'cost', width: 150 },
  ];

  TABLE_COLUMNS_EXTENSIONS = [
    { columnName: 'cost', align: 'center' },
    { columnName: 'usage', align: 'center' },
  ];

  getNewDataStateType(dataKey) {
    const newDataState = CostUsageStates.POD_DETAILS;
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.POD_DETAILS;
  }
}
