/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ControlledToggleButton = memo((props) => {
  const OnClickHandler = (e) => {
    e.preventDefault();
    if (props.handler && (props.selectedItem || props.selectedItem === 0)) {
      props.handler(props.selectedItem);
    }
  };
  return (
    <div style={{ width: 'auto' }} className={`toggle-btn customizer__toggle ${props.className}`}>
      <input
        disabled={props.disabled}
        className="toggle-btn__input"
        type="checkbox"
        name="top_menu_toggle"
        checked={props.checked}
        onChange={() => {}}
      />
      {
        // eslint-disable-next-line react/button-has-type
        <button
          disabled={props.disabled}
          className={`toggle-btn__input-label${props.isFixColor ? '-fix-blue' : ''}`}
          onClick={(e) => OnClickHandler(e)}
        />
      }
    </div>
  );
});

ControlledToggleButton.propTypes = {
  handler: PropTypes.func.isRequired,
  className: PropTypes.string,
  isFixColor: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  selectedItem: PropTypes.bool || PropTypes.string || PropTypes.number,
};

ControlledToggleButton.defaultProps = {
  checked: false,
  className: '',
  isFixColor: false,
  disabled: false,
  selectedItem: undefined,
};

export default ControlledToggleButton;
