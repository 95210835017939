import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Container } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import FilterGroupTable from 'usage/containers/FilterGroup/components/FilterGroupTable';

const FilterGroup = (props) => (
  <Container className="categories">
    <PageHeader title={PageNames.FILTER_GROUP} />
    <Card>
      <CardBody>
        <FilterGroupTable {...props} />
      </CardBody>
    </Card>
  </Container>
);

FilterGroup.propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};

export default observer(FilterGroup);
