import moment from 'moment';
import { defaultsDeep, isEmpty, isString, isNumber } from 'lodash';
import { palette, COLOR_NAME } from './theme';

const intlFormat = (number) => new Intl.NumberFormat().format(Math.round(number * 10) / 10);
const abbrNum = (number, decPlaces = null, isAbbrKilo = false) => {
  if (!isNumber(number)) {
    return null;
  }
  if (isString(number)) {
    return number;
  }

  if (isAbbrKilo && number > 999) {
    const num = Math.floor(number);
    if (num >= 1000000000) {
      return `${intlFormat(num / 1000000000)}G`;
    }
    if (num >= 1000000) {
      return `${intlFormat(num / 1000000)}M`;
    }
    if (num >= 1000) {
      return `${intlFormat(num / 1000)}k`;
    }
    return intlFormat(num);
  }

  const cleanDecimal = (dec) => Number.parseFloat(dec);

  const precisionFactor = 6;
  const symbols = {
    '-8': 'y',
    '-7': 'z',
    '-6': 'a',
    '-5': 'f',
    '-4': 'p',
    '-3': 'n',
    '-2': 'µ',
    // '-1': 'm',
    // '0':  '',
    // '1': 'k',
    2: 'M',
    3: 'G',
    4: 'T',
    5: 'P',
    6: 'E',
    7: 'Z',
    8: 'Y',
    9: 'H',
  };
  const negativeCoeficiant = number < 0 ? -1 : 1;
  let res = Math.abs(number);
  let retNum = null;
  let scale = 0;
  // very big number
  const pow9 = 1000 ** 9;
  if (res / pow9 > 1) {
    res = negativeCoeficiant * res.toExponential(decPlaces !== null ? decPlaces : precisionFactor - 1);
    return cleanDecimal(res);
  }

  // very small number
  const pow8 = 1000 ** 8;
  if (res * pow8 < 1) {
    res = negativeCoeficiant * res.toExponential(decPlaces !== null ? decPlaces : precisionFactor - 1);
    return cleanDecimal(res);
  }

  if (res > 1) {
    res /= 1000;
    while (res >= 1) {
      scale += 1;
      res /= 1000;
    }

    if (scale < 2) {
      res =
        decPlaces !== null
          ? parseFloat(number.toPrecision(precisionFactor)).toFixed(decPlaces)
          : number.toPrecision(precisionFactor);
      return cleanDecimal(res);
    }

    retNum =
      decPlaces !== null
        ? parseFloat((negativeCoeficiant * res * 1000).toPrecision(precisionFactor)).toFixed(decPlaces)
        : (negativeCoeficiant * res * 1000).toPrecision(precisionFactor);
    return !symbols[scale] ? number : cleanDecimal(retNum) + symbols[scale];
  }
  if (res > 0 && res < 1) {
    res *= 1000;
    scale = -1;
    while (res < 1) {
      scale -= 1;
      res *= 1000;
    }

    if (scale > -3) {
      if (Math.abs(number) >= 0.0001) {
        res = decPlaces !== null ? parseFloat(number).toFixed(decPlaces) : parseFloat(number).toFixed(precisionFactor);
        return cleanDecimal(res);
      }
    }

    retNum =
      decPlaces !== null
        ? parseFloat((negativeCoeficiant * res).toPrecision(precisionFactor)).toFixed(decPlaces)
        : (negativeCoeficiant * res).toPrecision(precisionFactor);
    return !symbols[scale] ? number : cleanDecimal(retNum) + symbols[scale];
  }
  return negativeCoeficiant * res;
};

export const POINT_HOVER_DIAMETER = 10;

export const METRIC_SERIES = {
  AVERAGE: 'average',
  AVERAGE_BASELINE: 'average_baseline',
  ANOMALY_PRIMARY: 'anomalyPrimary',
  ANOMALY_SECONDARY: 'anomalySecondary',
};

export const HCHART_TYPES = {
  AREA_RANGE: 'arearange',
  LINE: 'line',
  SPLINE: 'spline',
  COLUMN: 'column',
  AREA: 'area',
};

export const CHART_COLORS = {
  LABEL_COLOR: palette.gray[475], // #A2A2A2
  LABEL_COLOR_DARK: palette.gray[525], // #404a52
  GRID_LINE_COLOR: palette.gray[100],
  ANOMALY_LINE_COLOR: palette.mango[500],
  UNSATISFIED_ANOMALY_LINE_COLOR: palette.gray[500],
  NO_DATA_LINE_COLOR: palette.gray[400],
  NO_DATA_COLOR: palette.gray[350],
  SERIES_COLORS: [
    palette.blue[500],
    palette.rose[500],
    palette.teal[500],
    palette.purple[500],
    palette.tomato[500],
    palette.indigo[400],
    palette.eucaliptus[500],
    palette.brown[400],
    palette.azure[500],
    palette.rose[400],
    palette.mint[600],
    palette.lilach[400],
    palette.lime[500],
    palette.eggplant[500],
  ],
  SERIES_COLORS_DASHBOARD: [
    COLOR_NAME.BLUE,
    COLOR_NAME.ROSE,
    COLOR_NAME.TEAL,
    COLOR_NAME.PURPLE,
    COLOR_NAME.TOMATO,
    COLOR_NAME.INDIGO_400,
    COLOR_NAME.EUCALIPTUS,
    COLOR_NAME.BROWN_400,
    COLOR_NAME.AZURE,
    COLOR_NAME.ROSE_400,
    COLOR_NAME.MINT_600,
    COLOR_NAME.LILACH_400,
    COLOR_NAME.LIME,
    COLOR_NAME.EGGPLANT,
  ],
  CONFIDANCE_COLORS: [
    'rgba(0,153,219,0.2)',
    'rgba(126,178,109,0.2)',
    'rgba(234,184,57,0.2)',
    'rgba(110,208,224,0.2)',
    'rgba(239,132,60,0.2)',
    'rgba(226,77,66,0.2)',
    'rgba(31,120,193,0.2)',
    'rgba(186,67,169,0.2)',
    'rgba(112,93,160,0.2)',
    'rgba(80,134,66,0.2)',
    'rgba(204,163,0,0.2)',
    'rgba(68,126,188,0.2)',
    'rgba(193,92,23,0.2)',
    'rgba(137,15,2,0.2)',
    'rgba(10,67,124,0.2)',
    'rgba(109,31,98,0.2)',
    'rgba(88,68,119,0.2)',
    'rgba(183,219,171,0.2)',
    'rgba(244,213,152,0.2)',
    'rgba(112,219,237,0.2)',
    'rgba(249,186,143,0.2)',
    'rgba(242,145,145,0.2)',
    'rgba(130,181,216,0.2)',
    'rgba(229,168,226,0.2)',
    'rgba(174,162,224,0.2)',
    'rgba(98,158,81,0.2)',
    'rgba(229,172,14,0.2)',
    'rgba(100,176,200,0.2)',
    'rgba(224,117,45,0.2)',
    'rgba(191,27,0,1)',
    'rgba(10,80,161,0.2)',
    'rgba(150,45,130,0.2)',
    'rgba(97,77,147,0.2)',
    'rgba(154,196,138,0.2)',
    'rgba(242,201,109,0.2)',
    'rgba(101,197,219,0.2)',
    'rgba(249,147,78,0.2)',
    'rgba(234,100,96,0.2)',
    'rgba(81,149,206,0.2)',
    'rgba(214,131,206,0.2)',
    'rgba(128,110,183,0.2)',
    'rgba(63,104,51,0.2)',
    'rgba(150,115,2,0.2)',
    'rgba(47,87,94,0.2)',
    'rgba(153,68,10,0.2)',
    'rgba(88,20,12,0.2)',
    'rgba(5,43,81,0.2)',
    'rgba(81,23,73,0.2)',
    'rgba(63,43,91,0.2)',
    'rgba(224,249,215,0.2)',
    'rgba(252,234,202,0.2)',
    'rgba(207,250,255,0.2)',
    'rgba(249,226,210,0.2)',
    'rgba(252,226,222,0.2)',
    'rgba(186,223,244,0.2)',
    'rgba(249,217,249,0.2)',
    'rgba(222,218,247,0.2)',
  ],
};

export const SIMULATION_CHART_COLORS = {
  ANOMALY_LINE_COLOR: palette.orange[500],
  UNSATISFIED_ANOMALY_LINE_COLOR: palette.gray[400],
};

function yAxisFormatter() {
  return abbrNum(this.value, 3, true);
}

function xAxisFormatter() {
  if (this.dateTimeLabelFormat === "%b '%y") {
    const minXaxisYear = moment(this.axis.min).year();
    const maxXaxisYear = moment(this.axis.max).year();
    if (minXaxisYear === maxXaxisYear) {
      this.dateTimeLabelFormat = '%b';
    }
  }
  return this.axis.defaultLabelFormatter.call(this);
}

const THEMES = {
  preview: {
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b',
        week: '%e %b',
      },
      lineColor: palette.gray[300],
      gridLineColor: palette.gray[200],
      padding: 20,
      labels: {
        style: {
          color: palette.gray[400],
          fontWeight: 500,
          whiteSpace: 'nowrap',
        },
      },
    },
    yAxis: {
      lineColor: palette.gray[300],
      gridLineColor: palette.gray[200],
      title: {
        text: null,
      },
      labels: {
        style: {
          color: palette.gray[500],
          fontWeight: 500,
        },
        formatter: yAxisFormatter,
      },
    },
  },
  simulation: {
    chart: {
      plotBackgroundColor: palette.blue[200],
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b',
      },
      lineColor: palette.blue[300],
      gridLineColor: palette.blue[300],
      gridLineDashStyle: 'dash',
      padding: 20,
      labels: {
        style: {
          color: palette.gray[400],
          fontWeight: 500,
          whiteSpace: 'nowrap',
        },
      },
    },
    yAxis: {
      lineColor: palette.blue[300],
      gridLineColor: palette.blue[300],
      gridLineDashStyle: 'dash',
      title: {
        text: null,
      },
      labels: {
        style: {
          color: palette.gray[500],
          fontWeight: 500,
        },
        formatter: yAxisFormatter,
      },
    },
  },
};

export const getLineChartDefaultDisplayProperties = () => ({
  type: HCHART_TYPES.LINE,
  stacking: null,
  yAxis: {
    opposite: false,
    max: null,
    min: null,
    type: 'linear',
    minorTickInterval: 'auto',
  },
});

export const getYAxisDefaults = (isOpposite) => ({
  opposite: isOpposite,
  endOnTick: false,
  startOnTick: false,
  gridLineWidth: 1,
  gridLineColor: CHART_COLORS.GRID_LINE_COLOR,
  title: {
    text: null,
  },
  labels: {
    style: {
      color: palette.gray[500],
      fontWeight: 500,
    },
    formatter: yAxisFormatter,
  },
  // showEmpty: true,
});

export const getThresholdPlotLineConfig = () => ({
  color: palette.red[500],
  width: 2,
  zIndex: 5,
  dashStyle: 'Dash',
  label: {
    align: 'left',
    x: 10,
    style: {
      fontWeight: 'bold',
      color: palette.red[500],
      fontSize: '12px',
      textTransform: 'uppercase',
      textOutline: palette.white[500],
      textOutlineWidth: 2,
    },
  },
});

export const getGraphTilePlotLineConfig = () => ({
  color: palette.gray[400],
  width: 2,
  zIndex: 5,
  dashStyle: 'Dash',
  label: {
    align: 'left',
    x: 0,
    y: -15,
    useHTML: true,
    style: {
      fontWeight: 500,
      color: palette.white[500],
      fontSize: '14px',
      textTransform: 'uppercase',
      textOutline: palette.white[500],
      textOutlineWidth: 2,
      borderRadius: '3px',
      padding: '4px',
    },
  },
});

export const getCrosshairConfig = () => ({
  dashStyle: 'Dash',
  width: 1,
  color: palette.gray[400],
  zIndex: 2,
});

export const getAxisZones = (config) => {
  if (!config || isEmpty(config) || isEmpty(config.zones)) {
    return {};
  }

  const zones = [];
  (config.zones || []).forEach((aZone) => {
    zones.push({
      value: aZone.startValue,
      color: config.primeColor,
      dashStyle: config.primeDashStyle,
    });
    zones.push({
      value: aZone.endValue,
      color: aZone.color || config.zoneColor,
      dashStyle: aZone.dashStyle || config.zoneDashStyle,
    });
  });

  if (zones.length) {
    zones.push({
      color: config.primeColor,
      dashStyle: config.primeDashStyle,
    });

    return {
      zoneAxis: config.zoneAxis ? config.zoneAxis : 'x',
      zones,
    };
  }

  return {};
};

export const getHchartConfig = (isMobile, theme, isInactiveMode = false) =>
  defaultsDeep(THEMES[theme] || {}, {
    chart: {
      pinchType: isMobile ? 'x' : null,
      zoomType: isMobile ? null : 'x',
      resetZoomButton: {
        theme: {
          style: {
            display: 'none',
          },
        },
      },
      backgroundColor: CHART_COLORS.BACKGROUND_COLOR,
      events: {},
      spacing: [5, 0, 5, 0],
    },
    lang: {
      noData: 'No Data',
    },
    noData: {
      style: {
        fontSize: '18px',
        color: CHART_COLORS.NO_DATA_COLOR,
      },
      useHTML: true,
    },
    boost: {
      // https://www.highcharts.com/docs/advanced-chart-features/boost-module
      enabled: false, // till this is fixed: https://github.com/highcharts/highcharts/issues/8164
      // seriesThreshold: 50, //A number that specifies how many series the chart must contain before
      // it enters boost mode. Defaults to 50
      // at series object level->
      // series|plotOptions.series.boostThreshold The number of points that needs to be in the series
      // or the boost to kick in. Defaults to 5000.
      useGPUTranslations: true,
    },
    tooltip: {
      enabled: true,
      hideDelay: 0,
      shared: false,
      followPointer: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      animation: false,
    },
    xAxis: {
      type: 'datetime',
      tickPosition: 'inside',
      tickWidth: 0,
      lineWidth: 1,
      lineColor: CHART_COLORS.GRID_LINE_COLOR,
      gridLineWidth: 1,
      gridLineColor: CHART_COLORS.GRID_LINE_COLOR,
      labels: {
        style: {
          color: palette.gray[400],
          fontWeight: 500,
          whiteSpace: 'nowrap',
        },
        formatter: xAxisFormatter,
      },
      crosshair: getCrosshairConfig(),
      units: [
        ['minute', [1, 2, 5, 10, 15, 30]],
        ['hour', [1, 2, 3, 4, 6, 8, 12]],
        ['day', [1, 3, 5]],
        ['week', [1, 2]],
        ['month', [1, 3, 6]],
        ['year', null],
      ],
    },
    yAxis: [getYAxisDefaults(false)],
    plotOptions: {
      series: {
        stickyTracking: true,
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
          inactive: {
            opacity: isInactiveMode ? 0.5 : 1,
          },
        },
        turboThreshold: 1,
        events: {},
        marker: {
          lineColor: null,
          states: {
            hover: {
              lineColor: palette.white[500],
              lineWidthPlus: 2,
            },
          },
        },
      },
      column: {
        grouping: false,
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    series: [],
  });

export const getGlobalSettings = () => ({
  // The time setting with the timezone property will be set in "charts/timeSeries/store/epics/epics.js" (AL-12563).
  lang: {
    loading: '<i class="icon ion-load-c spin"></i>',
    noData: 'No Data to display - change time range or metric selection',
  },
  chart: {
    pinchType: 'x',
    zoomType: 'x',
    panning: false,
    animation: false,
    backgroundColor: null,
    alignTicks: false,
    showAxes: true,
    marginBottom: null,
    style: {
      fontFamily: 'inherit',
    },
  },
  plotOptions: {
    series: {
      animation: false,
      fillOpacity: 0.2,
    },
  },
  title: {
    text: null,
  },
  colors: CHART_COLORS.SERIES_COLORS,
  noData: {
    position: {
      x: 0,
    },
    style: {
      fontWeight: 'normal',
      fontSize: '12px',
      color: palette.gray[450],
    },
  },
  loading: {
    style: {
      position: 'absolute',
      left: 0,
      backgroundColor: 'transparent',
      opacity: 0.5,
      textAlign: 'center',
    },
    labelStyle: {
      position: 'absolute',
      left: '0px',
      top: '0px',
    },
  },
  credits: {
    enabled: false,
  },
});
