import React from "react";

export const dashLegend = (color, id) => (
    <svg width={20} height={6}>
        <defs>
            <pattern id={`dashedPattern-${id}`} width="5" height="6" patternUnits="userSpaceOnUse">
                <path d="M 0 3 L 3 3" stroke={color} strokeWidth="4"/>
            </pattern>
        </defs>
        <rect
            width="20"
            height="6"
            fill={`url(#dashedPattern-${id})`}
        />
    </svg>
);
