import React, { useContext } from 'react';
import { Progress } from '@pileus-cloud/anodot-frontend-common';
import PropTypes from 'prop-types';
import { getBudgetAmount, getBudgetColor } from 'usage/containers/Budget/budgetUtil';
import UserDataContext from 'users/utils/contexts/UserSettingsContext';
import classes from './budgets.module.scss';

const CurrentBudgetProgress = ({ budget }) => {
  const { getCurrencyNumber } = useContext(UserDataContext);
  const budgetAmount = getBudgetAmount(budget, new Date(budget.monthlyStartDate));

  const value = Math.round((budget.totalCost / budgetAmount) * 100);
  const progressClasses = {
    root: classes.root,
    indicator: classes.indicator,
  };
  return (
    <>
      <div className={classes.progressWrapper}>
        <span className={classes.text}>Current vs. Budgeted</span>
        <div className={classes.progress}>
          <Progress classes={progressClasses} color={getBudgetColor(value)} value={value} />
        </div>
        <span className={classes.details}>
          {getCurrencyNumber(budget.totalCost)} / {getCurrencyNumber(budgetAmount)}
          <span>{value}%</span>
        </span>
      </div>
    </>
  );
};

export default CurrentBudgetProgress;

CurrentBudgetProgress.propTypes = {
  budget: PropTypes.object.isRequired,
};
