/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

// FIXME change to Azure CLI
const Action = ({ rec }) =>
  rec.resourceGroup ? (
    <ActionContainer>
      <ActionHeader />
      <ul>
        <li style={{ 'list-style-type': 'none' }}>Using Powershell:</li>
        <CodeBlock
          text="Remove-AzPublicIpAddress -Name {{ipName}} -ResourceGroupName {{resourceGroup}}"
          variables={{
            resourceGroup: rec.resourceGroup,
            ipName: rec.ipName,
          }}
          quote={'"'}
        />
      </ul>
    </ActionContainer>
  ) : null;
Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
