export const RI_COVERAGE_TABLE_COLUMNS = [
  { name: 'linkedAccount', title: 'Linked Account', getCellValue: (row) => row.linkedAccountDisplayLabel },
  { name: 'instanceType', title: 'Instance Type', getCellValue: (row) => row.instanceType },
  { name: 'region', title: 'Region', getCellValue: (row) => row.region },
  { name: 'os', title: 'OS', getCellValue: (row) => row.os },
  { name: 'coverage', title: 'Coverage', getCellValue: (row) => row.coverage },
  { name: 'onDemand', title: 'On-Demand Cost', getCellValue: (row) => row.onDemandTotalCost },
];
export const RI_COVERAGE_COLUMN_WIDTHS = [
  { columnName: 'linkedAccount', width: 200 },
  { columnName: 'coverage', width: 200 },
  { columnName: 'region', width: 200 },
  { columnName: 'os', width: 100 },
  { columnName: 'month', width: 200 },
  { columnName: 'instanceType', width: 200 },
  { columnName: 'onDemand', width: 200 },
];
export const RI_COVERAGE_TABLE_COLUMN_EXTENSIONS = [{ columnName: 'coverage', align: 'center' }];
export const RI_COVERAGE_DEFAULT_HIDDEN_COLUMN_NAMES = [];
export const RI_COVERAGE_DEFAULT_SORTING = [
  { columnName: 'coverage', direction: 'desc' },
  {
    columnName: 'onDemand',
    direction: 'desc',
  },
];
export const RI_COVERAGE_CURRENCIES_COLUMNS = ['onDemandCost'];
