import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import CostChangesAlert from './CostChangesAlert';

const CostChangesAlertsWrapper = ({
  costChangesAlerts,
  invoiceStore,
  usageStore,
  updatePreSaveAlertsValidationStatus,
  setIsInitialDataFetch,
  currentCloudType,
}) => {
  const [isLoading, setIsLoading] = useState(null);

  return (
    <Col>
      {Array.isArray(costChangesAlerts) &&
        costChangesAlerts.map((alert) => {
          const alertLoading = alert.id === isLoading
          return (
            <CostChangesAlert
              key={alert.id + alertLoading}
              invoiceStore={invoiceStore}
              usageStore={usageStore}
              isLoading={alertLoading}
              setIsLoading={(loading) => setIsLoading(loading ? alert.id : null)}
              alertId={alert.id}
              alert={alert}
              currentCloudType={currentCloudType}
              updatePreSaveAlertsValidationStatus={updatePreSaveAlertsValidationStatus}
              setIsInitialDataFetch={setIsInitialDataFetch}
            />
          );
        })}
      {/* this is the first empty alert to enable user create new alerts */}
      <CostChangesAlert
        alertId={null}
        key="blankTemplate"
        invoiceStore={invoiceStore}
        isLoading={isLoading === 'add-new'}
        setIsLoading={(loading) => setIsLoading(loading ? 'add-new' : null)}
        usageStore={usageStore}
        updatePreSaveAlertsValidationStatus={updatePreSaveAlertsValidationStatus}
        currentCloudType={currentCloudType}
        setIsInitialDataFetch={setIsInitialDataFetch}
      />
    </Col>
  );
};

CostChangesAlertsWrapper.propTypes = {
  costChangesAlerts: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  updatePreSaveAlertsValidationStatus: PropTypes.func.isRequired,
  currentCloudType: PropTypes.number.isRequired,
  setIsInitialDataFetch: PropTypes.func.isRequired,
};

export default CostChangesAlertsWrapper;
