import { ROLE_TYPES } from '../containers/RolesAndUsers/constants/rolesAndUsersConstants';

export const prepareCloudHirarcheyObject = (accounts = []) => {
  const cloudAndAccountsObj = accounts.reduce((acc, currAcc) => {
    const currCloudProviderId = currAcc.cloudTypeId;
    if (currCloudProviderId in acc) {
      const arrCurrentAccounsInObj = [...acc[currCloudProviderId]];
      acc[currCloudProviderId] = [...arrCurrentAccounsInObj, currAcc];
    } else {
      acc[currCloudProviderId] = [currAcc];
    }
    return acc;
  }, {});
  return cloudAndAccountsObj;
};

export const getAccountIdAllLinkedAccounts = (accountsId, accountId) => {
  if (accountsId instanceof Map && accountsId?.has(accountId)) {
    return accountsId.get(accountId);
  }
  return [];
};
const getRolesContainingAccountId = (roles, accountId) => {
  const rolesWithAccountId = roles.filter(
    (role) => role.accounts.find((acc) => acc.accountId === accountId) && role.roleType !== ROLE_TYPES.DEFAULT_ADMIN,
  );
  return rolesWithAccountId;
};

export const getRoleNamesContainingAccountId = (roles, accountId) => {
  if (!accountId) {
    return [];
  }
  const rolesWithAccountId = getRolesContainingAccountId(roles, accountId);
  const result = rolesWithAccountId.map((role) => role.roleName);
  return result;
};
export const isRoleIdOfAdminType = (roles, roleId) => {
  if (!roleId) {
    return false;
  }
  const role = roles.find((r) => r.uuid === roleId);
  if (!role) {
    return false;
  }
  const result = role.roleType === ROLE_TYPES.DEFAULT_ADMIN;
  return result;
};
