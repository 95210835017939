import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const NAT_GATE_WAY_PROPERTIES = {
  PUBLIC_IP: {
    label: 'Max Active Connections',
    getterFunction: (recommendation) =>
      recommendation?.recData?.max_active_connections === 0 ? '0' : recommendation?.recData?.max_active_connections,
  },
  STATE: {
    label: 'State',
    getterFunction: (recommendation) => recommendation?.recData?.state,
  },
  SUBNET_ID: {
    label: 'Subnet Id',
    getterFunction: (recommendation) => recommendation?.recData?.subnet_id,
  },
  VPC_ID: {
    label: 'Vpc Id',
    getterFunction: (recommendation) => recommendation?.recData?.vpc_id,
  },
};

const NatGatewayUtilLow = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `aws ec2 delete-nat-gateway --nat-gateway-id ${recommendation?.resourceId}`,
        },
      ],
    },
  ];

  const description =
    'The NAT Gateway service has been underutilized for the past 7 days. We recommend terminating this service.';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={NAT_GATE_WAY_PROPERTIES}
    />
  );
};

NatGatewayUtilLow.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default NatGatewayUtilLow;
