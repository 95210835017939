/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import CustomModal from 'shared/components/andtComponents/Modal';
import { LabelActionTypes, MAX_LABEL_LENGTH_CHARS } from '../../../constants/recommendationsConstants';

function AddOrEditRecLabelModal({
  isOpen,
  toggle,
  className,
  modalType,
  labels,
  onRemoveLabel,
  onLabelSave,
  onAddLabel,
  isLablesFromBatch,
}) {
  const [label, setLabel] = useState('');
  const onInputChange = (val) => {
    const lengthVal = val.length > MAX_LABEL_LENGTH_CHARS ? val.slice(0, MAX_LABEL_LENGTH_CHARS) : val;
    const charVal = lengthVal.replace(/[^\w\s/-]/gi, '');
    setLabel(charVal);
  };
  return (
    <CustomModal
      open={isOpen}
      onClose={toggle}
      className={className}
      title={modalType === LabelActionTypes.ADD ? 'Add label' : 'Edit label'}
      onSave={() => onLabelSave(isLablesFromBatch)}
      saveDisabled={!labels || labels.length === 0}
    >
      <div className="add-or-edit-user-modal-wrapper">
        <div className="input-wrapper">
          <label htmlFor="label" className="input-name-title">
            Label
          </label>
          <div>
            <input
              className="input-name"
              type="text"
              name="label"
              id="label"
              placeholder="Max length 50 chars"
              value={label}
              onChange={(e) => onInputChange(e.target.value)}
              autoComplete="off"
            />
            <button
              type="button"
              onClick={() => {
                onAddLabel(label);
                setLabel('');
              }}
              className="btn-no-style"
            >
              <span className="text-primary" style={{ fontSize: 'large', marginRight: '5px' }}>
                +
              </span>
            </button>
          </div>
        </div>
      </div>
      <hr />
      {!isEmptyArray(labels) ? (
        <div>
          {labels.map((lbl, index) => (
            <div className="d-flex align-items-center">
              <button
                type="button"
                // eslint-disable-next-line react/no-array-index-key
                key={`k${index}`}
                className="btn-no-style"
                onClick={() => onRemoveLabel(index)}
              >
                <span className="lnr lnr-trash text-muted" style={{ fontSize: 'smaller' }} />
              </button>
              <div className="ms-1">{lbl}</div>
            </div>
          ))}
          {labels && labels.length === 15 && (
            <div className="mb-2">
              <span className="text-muted">Max 15 labels</span>
            </div>
          )}
        </div>
      ) : null}
    </CustomModal>
  );
}

AddOrEditRecLabelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onRemoveLabel: PropTypes.func.isRequired,
  onAddLabel: PropTypes.func.isRequired,
  onLabelSave: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  className: PropTypes.string,
  labels: PropTypes.object,
  isLablesFromBatch: PropTypes.bool,
};

AddOrEditRecLabelModal.defaultProps = {
  className: '',
  labels: null,
  isLablesFromBatch: false,
};

export default AddOrEditRecLabelModal;
