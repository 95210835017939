import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { nestedObjToFlattenArray } from 'shared/utils/arrayUtils';
import { CAU_ALERTS_TYPES } from 'usage/constants/usageConstants';
import { hashText } from 'shared/utils/strUtil';
import SuperUser from './superUser';
import User from './user';
import SubUser from './subUser';
import LinkedAccount from './linkedAccount';
import { UsersType } from '../constants/usersConstants';
import DivisionGroup from './divisionGroup';

export default class UsersModel {
  constructor(apiGateway) {
    this.users = [];
    this.usersNotifications = {};
    this.userLinkedAccounts = undefined;
    this.userAlerts = [];
    this.divisionGroupsLoading = false;
    this.mainUser = null;
    this.apiGateway = apiGateway;
    this.isKeycloakManagement = false;
    this.subUsers = [];
    this.customersSubUsers = [];
    this.divisionList = [];
    this.divisionGroups = [];
    this.mapLinkedAccIdToDivisionName = new Map();
    this.availableLinkedAccounts = [];
    this.availbleDivisionLinkedAccounts = []; // of type { value: acc id, label: acc id name}
    this.isLoadingUsers = true;
    this.mapUserTypeToSubUsers = new Map([
      [UsersType.USER, 'costCenter'],
      [UsersType.SUB_USER, 'costCenter'],
      [UsersType.SUPER_USER, 'costCenter'],
      [UsersType.BETA_USER, 'customer'],
      [UsersType.RESELLER, 'customer'],
      [UsersType.RESELLER_CUSTOMER, 'customer'],
      [UsersType.RESELLER_CUSTOMER_EDP, 'customer'],
    ]);
    makeObservable(this, {
      users: observable,
      userLinkedAccounts: observable,
      userAlerts: observable,
      mainUser: observable,
      subUsers: observable,
      mapUserTypeToSubUsers: observable,
      customersSubUsers: observable,
      divisionGroups: observable,
      divisionGroupsLoading: observable,
      divisionList: observable,
      availableLinkedAccounts: observable,
      availbleDivisionLinkedAccounts: observable,
      isLoadingUsers: observable,
      usersNotifications: observable,
      isKeycloakManagement: observable,
      replaceUser: action,
      updateLinkedAccounts: action,
      deleteSubUsersBulk: action,
      createNewDivisionGroup: action,
      createNewCustomerSubUser: action,
      deleteDivisionGroup: action,
      updateUserNotificationSettings: action,
      updateDefaultAccountId: action,
      updateUserNewRoleInSubUsers: action,
      setIsKeycloakManagement: action,
      getSubUsers: computed,
      getCustomersSubUsers: computed,
      getCostCentersSubUsers: computed,
      cauAlertsShowStatus: computed,
      divisionAllocatedLinkedAccounts: computed,
    });
  }

  setIsKeycloakManagement = (isKeycloakManagement) => {
    this.isKeycloakManagement = isKeycloakManagement;
  };

  updateUserSlackData = async (slackUserData) => {
    const result = await this.apiGateway.updateUserSlackData(slackUserData);
    return result;
  };

  signIn = async (username, password) => {
    const userID = await this.apiGateway.signIn(username, password);
    return userID;
  };

  resendSignUp = async (username) => {
    const result = await this.apiGateway.resendSignUp(username);
    return result;
  };

  registerUser = async (username, displayName, password) => {
    const registerResult = { result: true, msg: '' };
    const registerData = { username, displayName, password };
    try {
      await this.apiGateway.registerUser(registerData);
    } catch (error) {
      registerResult.result = false;
      registerResult.msg = 'There was a problem with signup';
    }
    return registerResult;
  };

  processMainUserAndUpdateIfNeeded = async () => {
    let result = false;
    if (!this.mainUser) {
      return result;
    }
    const { userKey, userType, lastProcessTime, registrationTime, awsAccountId, pricingPlanId, remainingTrialDays } =
      this.mainUser;
    if (userType === UsersType.USER_ON_BOARDED && new Date(lastProcessTime) > new Date(registrationTime)) {
      this.mainUser.userType = UsersType.USER;
      result = await this.updateUserTypeAndAwsAccId(UsersType.USER, awsAccountId, true);
      if (result) {
        const fieldToUpdate = ['newType'];
        const valuesToUpdate = [UsersType.USER];
        this.updateUserDataLocaly(userKey, fieldToUpdate, valuesToUpdate);
      }
    }
    if (pricingPlanId === 2 && remainingTrialDays <= 0) {
      this.mainUser.userType = UsersType.USER_END_TRIAL;
      result = await this.updateUserTypeAndAwsAccId(UsersType.USER_END_TRIAL, awsAccountId, true);
      if (result) {
        const fieldToUpdate = ['newType'];
        const valuesToUpdate = [UsersType.USER_END_TRIAL];
        this.updateUserDataLocaly(userKey, fieldToUpdate, valuesToUpdate);
      }
    }

    return result;
  };

  createPileusUser = async (
    firstName,
    lastName,
    jobTitle,
    companyName,
    username,
    displayName,
    userKey,
    providerId,
    campaignId,
    messageId,
    isMSP,
    theme,
  ) => {
    const createUserResult = { result: false, msg: '' };
    const userDisplayName = displayName && displayName.length > 0 ? displayName : username;

    // Setting the hours to be the start of the day so next process time will always be newer
    const lastProcessTime = new Date();
    lastProcessTime.setHours(0, 0, 0, 0);
    const newPileusUserData = {
      firstName,
      lastName,
      jobTitle,
      companyName,
      username,
      userKey,
      userDisplayName,
      userType: UsersType.NEW_USER,
      lastProcessTime,
      isResellerMode: isMSP,
      theme,
    };
    const registerUserInfoParams = { username, providerId, campaignId, messageId };
    try {
      const result = await this.apiGateway.createPileusUser(newPileusUserData, registerUserInfoParams);
      if (result) {
        createUserResult.result = true;
      }
    } catch (error) {
      createUserResult.result = false;
      this.state = `error: ${error}`;
    }
    return createUserResult;
  };

  getUserIndexByUserKey = (usrKey) => this.users.findIndex((usr) => usr.userKey === usrKey);

  // this method "fetchUserLinkedAccounts" is for the account page, to enable user connect his Account AWS link Accounts
  fetchUserLinkedAccounts = async (cloudId) => {
    if (!this.mainUser) {
      return false;
    }
    try {
      const arrRawlinkedAccounts = await this.apiGateway.fetchUserLinkedAccounts(cloudId);
      const arrWithExternalId = await Promise.all(
        arrRawlinkedAccounts.map(async (rla) => {
          rla.externalId = await hashText(rla.accountId, 8);
          return rla;
        }),
      );
      const linkedAccounts = arrWithExternalId.map((rawAccount) => new LinkedAccount(rawAccount));
      runInAction(() => {
        this.userLinkedAccounts = [...linkedAccounts];
        return linkedAccounts > 0;
      });
    } catch (error) {
      this.userLinkedAccounts = [];
      return false;
    }
  };

  updateLinkedAccounts = async (linkedAccounts) => {
    const result = await this.apiGateway.updateLinkedAccounts(linkedAccounts);
    Object.keys(result).forEach((linkedAccountId) => {
      const { isVerified } = result[linkedAccountId];
      const updatedAccountIndex = this.userLinkedAccounts.findIndex(
        (account) => account.getLinkedAccountId() === linkedAccountId,
      );
      let updatedAccount = '';
      if (updatedAccountIndex > -1) {
        updatedAccount = new LinkedAccount(this.userLinkedAccounts[updatedAccountIndex]);
        updatedAccount.setIsVerified(isVerified);
        updatedAccount.setConnectMeSent(true);
        runInAction(() => {
          this.userLinkedAccounts = this.userLinkedAccounts.map((account) =>
            account.getLinkedAccountId() === linkedAccountId ? updatedAccount : account,
          );
        });
      }
    });
    return result;
  };

  getNumOfConnectedLinkedAccounts = (linkedAccounts) => {
    const list = linkedAccounts || this.userLinkedAccounts || [];
    const totalLinkedAcc = list.length;
    const connectedLinkedAcc = list.filter((la) => la.getIsVerified()).length;
    return { totalLinkedAcc, connectedLinkedAcc };
  };

  signinWithToken = async (selectedRole = null) => {
    const userKey = await this.apiGateway.signinWithToken(selectedRole);
    return userKey;
  };

  setExternalUserNameToUserKey = async (externalUserName, userKey) => {
    const result = await this.apiGateway.setExternalUserNameToUserKey(externalUserName, userKey);
    return result;
  };

  getUserKeyFromUserName = async (userName) => {
    const userKey = await this.apiGateway.getUserKeyFromUserName(userName);
    return userKey;
  };

  get cauAlertsShowStatus() {
    const { isShowAlertCostChange, isShowAlertRiExpiration, isShowAlertSpExpiration } = this.usersNotifications;
    // const { isShowAlertCostChange: costChange, isShowAlertRiExpiration: riExpiration, isShowAlertSpExpiration: spExpiration } = this.usersNotifications;
    return {
      [CAU_ALERTS_TYPES.COST_CHANGES]: !!isShowAlertCostChange,
      [CAU_ALERTS_TYPES.RI_EXPIRATION]: !!isShowAlertRiExpiration,
      [CAU_ALERTS_TYPES.SP_EXPIRATION]: !!isShowAlertSpExpiration,
    };
    // return { [CAU_ALERTS_TYPES.COST_CHANGES]: !!costChange, riExpiration: !!riExpiration, spExpiration: !!spExpiration };
  }

  // get cauAlertsShowStatus() {
  //   const { isShowAlertCostChange: costChange, isShowAlertRiExpiration: riExpiration, isShowAlertSpExpiration: spExpiration } = this.usersNotifications;
  //   return { costChange: !!costChange, riExpiration: !!riExpiration, spExpiration: !!spExpiration };
  // }
  // getCauAlertsShowStatus = () => {
  //   const { isShowAlertCostChange: costChange, isShowAlertRiExpiration: riExpiration, isShowAlertSpExpiration: spExpiration } = this.usersNotifications;
  //   return { costChange: !!costChange, riExpiration: !!riExpiration, spExpiration: !!spExpiration };
  // }
  fetchUserNotifications = async () => {
    // let fetchResult = false;
    try {
      const { userNotificationRawData } = await this.apiGateway.getUserNotificationSettings();
      if (userNotificationRawData) {
        // fetchResult = true;
        const userNotificationPrepared = this.prepareUserNotificationData(userNotificationRawData[0]);
        runInAction(() => {
          this.usersNotifications = { ...userNotificationPrepared };
        });
      }
    } catch (error) {
      return error;
    }
    return this.usersNotifications;
  };

  prepareUserNotificationData = (userNotificationRawData) => {
    const {
      id,
      division_id,
      user_key,
      is_budget,
      is_anomaly_detection,
      is_alert,
      default_account_id,
      is_email_notification,
      is_slack_notification,
      is_display_report,
      is_promo_notifications,
      update_time,
      is_alert_cost_change,
      is_alert_ri_expiration,
      is_alert_sp_expiration,
      userHash,
    } = userNotificationRawData;
    return {
      id,
      divisionId: division_id,
      userKey: user_key,
      isBudget: is_budget,
      isAnomalyDetection: is_anomaly_detection,
      isAlert: is_alert,
      isEmailNotification: is_email_notification,
      defaultAccountId: default_account_id,
      isSlackNotification: is_slack_notification,
      isPromoNotifications: is_promo_notifications,
      isShowReport: is_display_report,
      updateTime: update_time,
      isShowAlertCostChange: is_alert_cost_change,
      isShowAlertRiExpiration: is_alert_ri_expiration,
      isShowAlertSpExpiration: is_alert_sp_expiration,
      userHash,
    };
  };

  createUpdatedNotificationState = (notificationInputsState, isShowCreateReport) => {
    const updatedNotificationState = {};
    if (isShowCreateReport) {
      updatedNotificationState.isShowCreateReport = isShowCreateReport;
    }
    const arrKeysNotificationInputsState = notificationInputsState ? Object.keys(notificationInputsState) : [];
    arrKeysNotificationInputsState.forEach((key) => {
      updatedNotificationState[key] = notificationInputsState[key];
    });
    return updatedNotificationState;
  };

  updateUserNotificationSettings = async (notificationInputsState, isShowCreateReport) => {
    const updatedNotificationState = this.createUpdatedNotificationState(notificationInputsState, isShowCreateReport);
    // const oldSettings = Object.create(this.usersNotifications);
    const oldSettings = { ...this.usersNotifications };
    this.usersNotifications = { ...oldSettings, ...updatedNotificationState };
    try {
      await this.apiGateway.updateUserNotificationSettings(updatedNotificationState);
      return true;
    } catch (error) {
      this.state = 'error';
    }
    return false;
  };
  updateCuaAlertsSendStatus = async (newSendStatus) => {
    try {
      const result = await this.updateUserNotificationSettings(newSendStatus);
      return result;
    } catch (error) {
      return false;
    }
  };
  fetchUser = async () => {
    try {
      let mainRawUser;
      const { isKeycloakManagement } = this;
      if (isKeycloakManagement) {
        mainRawUser = await this.apiGateway.getUserData();
        const accounts = await this.apiGateway.getAccounts();
        mainRawUser.accounts = accounts;
        mainRawUser = {
          ...mainRawUser,
        };
      } else {
        // legacy users
        mainRawUser = await this.apiGateway.getUser();
      }
      this.mainUser = new User({ ...mainRawUser, isKeycloakManagement: !!isKeycloakManagement });
      const users = this.updateModel(this.mainUser);
      runInAction(() => {
        this.users = [...users];
        this.isLoadingUsers = false;
      });
    } catch (error) {
      this.state = 'error';
    }
    return this.mainUser;
  };

  fetchData = async (currDispUserKey) => {
    try {
      let user = {};
      if (this.mainUser && this.mainUser.getUserKey() === currDispUserKey) {
        // avoid a duplicate user fetch on intial load
        user = { ...this.mainUser };
      } else {
        const mainRawUser = await this.apiGateway.getUser();
        user = new User(mainRawUser);
      }
      runInAction(() => {
        this.mainUser = user;
        this.isLoadingUsers = false;
      });
    } catch (error) {
      this.state = 'error';
    }
  };

  fetchSubUserDetails = async (userKey) => this.apiGateway.fetchSubUserDetails(userKey);

  updateModel = (mainUser) => {
    const arrMainUserAndAllChildsFlat = nestedObjToFlattenArray(mainUser);
    return arrMainUserAndAllChildsFlat;
  };

  arrayContainsOnlyCurrParentChildren = (currVal, allOthers) =>
    allOthers.every((val) => val.parent_user_key === currVal.user_key);

  updateUserDataLocaly = (userKey, paramsToUpdate, newValues) => {
    const userAsArr = this.users.filter((user) => user.userKey === userKey);
    if (userAsArr && userAsArr.length === 0) {
      return;
    }

    const isParamsAsArray = Array.isArray(paramsToUpdate) && Array.isArray(newValues);
    if (userAsArr && !isParamsAsArray) {
      userAsArr[0][paramsToUpdate] = newValues;
    } else if (userAsArr && isParamsAsArray) {
      paramsToUpdate.forEach((param, index) => {
        userAsArr[0][param] = newValues[index];
      });
    }
  };

  prepareUser = (mainRawUser, rawChildUsers = null) => {
    let newUser = null;
    const type = parseInt(mainRawUser.user_type, 10);
    switch (type) {
      case UsersType.NEW_USER:
        newUser = this.prepareSuperUser(mainRawUser, rawChildUsers);
        break;
      case UsersType.USER:
        newUser = this.prepareSuperUser(mainRawUser, rawChildUsers);
        break;
      case UsersType.BETA_USER:
        newUser = this.prepareSuperUser(mainRawUser, rawChildUsers);
        break;
      case UsersType.USER_ON_BOARDED:
        newUser = this.prepareSuperUser(mainRawUser, rawChildUsers);
        break;
      case UsersType.SUPER_USER:
        newUser = this.prepareSuperUser(mainRawUser, rawChildUsers);
        break;
      case UsersType.USER_END_TRIAL:
        newUser = this.prepareSuperUser(mainRawUser, rawChildUsers);
        break;

      default:
        newUser = this.prepareSuperUser(mainRawUser, rawChildUsers);
        break;
    }
    return newUser;
  };

  prepareSuperUser = (rawUser) => new SuperUser(rawUser);

  get usersLength() {
    return this.users.length;
  }

  replaceUser(newUser) {
    runInAction(() => {
      this.users = this.users.map((u) => (u.userKey === newUser.userKey ? newUser : u));
    });
  }

  // also update user aws_acount_id
  updateUserTypeAndAwsAccId = async (newType, awsAccountID, isConnectionSuccess) => {
    const result = await this.apiGateway.updateUserTypeAndAwsAccId(newType, awsAccountID, isConnectionSuccess);
    return result;
  };

  updateUserGeneralData = (userKey, firstName, lastName, jobTitle) => {
    const currUser = this.users.find((user) => user.userKey === userKey);
    currUser.firstName = firstName;
    currUser.lastName = lastName;
    currUser.jobTitle = jobTitle;
    const params = { firstName, lastName, jobTitle };
    return this.apiGateway.updateUserGeneralData(params);
  };

  fetchAvailableLinkedAccounts = async () => {
    const availableLinkedAccounts = await this.apiGateway.getAvailableLinkedAccounts();
    runInAction(() => {
      this.availableLinkedAccounts = [...availableLinkedAccounts];
      return this.availableLinkedAccounts;
    });
    return this.availableLinkedAccounts;
  };

  fetchAvailableDivisionLinkedAccounts = async () => {
    const availbleDivisionLinkedAccounts = await this.apiGateway.getAvailableDivisionLinkedAccounts();
    runInAction(() => {
      this.availbleDivisionLinkedAccounts = [...availbleDivisionLinkedAccounts];
      return this.availbleDivisionLinkedAccounts;
    });
    return this.availbleDivisionLinkedAccounts;
  };

  fetchSubUsers = async () => {
    if (!this.mainUser) {
      return false;
    }
    const { subUsers } = await this.apiGateway.getSubUsers();
    runInAction(() => {
      this.subUsers = [...subUsers];
      return this.subUsers;
    });
    return { subUsers };
  };

  isRoleInSubUsers = (roleId) => {
    let result = true;
    result = this.subUsers.some((su) => su.userRole && su.userRole.uuid === roleId);
    return result;
  };
  updateUserRole = async (users) => {
    const body = { users };
    const result = await this.apiGateway.updateUserRole(body);
    return result;
  };
  createNewUsersInRole = async (emails, roleId, divisionTypeId, userType = null, overrideReadOnly = null) => {
    const body = {
      usernames: emails,
      roleId,
      divisionTypeId,
      userType,
      overrideReadOnly,
    };
    const result = await this.apiGateway.createNewUsersInRole(body);
    return result;
  };

  updateDivisionRoleId = (divisionId, roleId = null) => {
    if (!roleId) {
      return;
    }
    const { division } = this.getDivisionById(divisionId);
    if (division && !division.roleId) {
      division.roleId = roleId;
    }
  };
  createNewCustomerSubUser = async (
    email,
    customerName,
    divisionId,
    divisionTypeId,
    userType,
    currDisplayedAccount,
    roleId,
  ) => {
    const body = {
      username: email,
      divisionName: customerName,
      divisionId,
      divisionTypeId,
      userType,
      roleId,
    };
    const result = await this.apiGateway.createNewCustomerSubUser(body);
    if (result.result) {
      email.split(',').forEach((email) => {
        this.prepareSubUser(
          result[email]?.userKey,
          result.divisionUserTableId,
          result[email]?.divisionId,
          email,
          customerName,
          result[email]?.accountKey,
          currDisplayedAccount,
        );
        const newCustomerSubUser = {
          creationDate: new Date(),
          divisionId: result[email]?.divisionId,
          divisionTypeId,
          divisionName: customerName,
          userName: email,
          userKey: result[email]?.userKey,
          accountKey: result[email]?.accountKey,
          linkedAccounts: result[email]?.linkedAccIds.map((linkedAccId) => ({
            linkedAccountId: linkedAccId,
            linkedAccountName: linkedAccId,
          })),
        };
        this.subUsers = [...this.subUsers, ...[newCustomerSubUser]];
        this.updateDivisionRoleId(divisionId, result[email]?.roleId);
      });
    }
    return result;
  };

  get getSubUsers() {
    const usersList = this.subUsers.filter(
      ({ divisionTypeId }) => divisionTypeId === 0 || divisionTypeId === 3 || divisionTypeId === 4,
    );
    return usersList;
  }

  get getCustomersSubUsers() {
    const usersList = this.subUsers.filter(({ divisionTypeId }) => divisionTypeId === 1 || divisionTypeId === 2);
    return usersList;
  }

  get getCostCentersSubUsers() {
    const usersList = this.subUsers.filter(({ divisionTypeId }) => divisionTypeId === 3 || divisionTypeId === 4);
    return usersList;
  }

  getSubUsersListByDisplayedType = (userType) => {
    let list = [];
    const type = this.mapUserTypeToSubUsers.get(userType);
    if (type === 'costCenter') {
      list = this.subUsers;
    } else if (type === 'customer') {
      list = this.customersSubUsers;
    }
    return list;
  };

  deleteSubUser = async (userKey, accountKey, userName, divisionTypeId) => {
    const result = await this.apiGateway.deleteSubUser(userKey, accountKey);
    if (result.result) {
      this.removeDivUserFromUsers(userKey);
      this.removeSubUserFromSubUsersLocally(divisionTypeId, userName);
    }
    return result;
  };

  deleteSubUsersBulk = async (users) => {
    await this.apiGateway.deleteSubUsersBulk(users);
    await this.fetchUser();
    await this.fetchSubUsers();
  };

  removeSubUserFromSubUsersLocally = (divisionTypeId, userName) => {
    const subUserIndex = this.subUsers.findIndex(
      (usr) => usr.userName === userName && usr.divisionTypeId === divisionTypeId,
    );
    if (subUserIndex > -1) {
      this.subUsers.splice(subUserIndex, 1);
    }
  };

  removeDivUserFromUsers = (divUserKey) => {
    const userIndex = this.users.findIndex((usr) => usr.userKey === divUserKey);
    if (userIndex > -1) {
      this.users.splice(userIndex, 1);
    }
    const rootUserIndex = this.users.findIndex((usr) => usr.isRootUser);
    if (rootUserIndex > -1) {
      this.users[rootUserIndex].deleteChild(divUserKey);
    }
  };

  createSubUserDashbaord = async (userKey, divisionId, accountKey) => {
    const body = { userKey, divisionId, accountKey };
    await this.apiGateway.createSubUserDashbaord(body);
  };

  prepareSubUser = (userKey, userId, divisionId, userName, userDisplayName, accountKey, currDisplayedAccount) => {
    const { lastProcessTime, cloudTypeId, accountId, accountName } = currDisplayedAccount;
    const rawUser = {
      id: userId,
      parent_display_name: '',
      parent_user_id: this.mainUser.userId,
      parent_user_key: this.mainUser.userKey,
      parent_user_name: this.mainUser.userName,
      user_display_name: userDisplayName,
      child_level: 1, // under the assumption that the main user created the sub user
      user_key: userKey,
      user_name: userName,
      user_type: '7',
      accounts: [
        {
          accountKey,
          cloudTypeId,
          userKey,
          lastProcessTime,
          accountName,
          // accountName: accountId,
          accountId,
          divisionId,
        },
      ],
    };
    const preparedUser = new SubUser(rawUser);
    this.mainUser.childs.push(preparedUser);
    this.users.push(preparedUser);
  };
  updateUserNewRole = (userKey, newRole) => {
    this.updateUserNewRoleInSubUsers(userKey, newRole);
  };
  updateUserNewRoleInSubUsers = (userKey, newRole) => {
    const currentUserIndex = this.subUsers.findIndex((su) => su.userKey === userKey);
    if (currentUserIndex) {
      const updatedSubUser = this.subUsers[currentUserIndex];
      updatedSubUser.userRole = newRole;
      const newSubUsers = [...this.subUsers];
      newSubUsers.splice(currentUserIndex, 1, updatedSubUser);
      this.subUsers = [...newSubUsers];
    }
  };
  addNewUserWithRoleToUsers = (userKey, userId, divisionId, userName, accounts, userType) => {
    const rawUser = {
      id: userId,
      parent_display_name: '',
      parent_user_id: this.mainUser.userId,
      parent_user_key: this.mainUser.userKey,
      parent_user_name: this.mainUser.userName,
      user_display_name: userName,
      child_level: 1, // under the assumption that the main user created the sub user
      user_key: userKey,
      user_name: userName,
      user_type: userType || '7',
      accounts,
    };
    const preparedUser = new SubUser(rawUser);
    this.mainUser.childs.push(preparedUser);
    this.users.push(preparedUser);
  };

  // Division Groups **********************************
  isAccountConnectedToDivisions = (accountId) => {
    const result = this.divisionGroups.map((divG) => divG.accountId).includes(accountId);
    return result;
  };
  fetchDivisionGroups = async (mapUpdate = false) => {
    this.divisionGroupsLoading = true;
    const { preparedRawDivisions: rawDivisionGroups, mapLinkedAccIdToDivisionName } =
      await this.apiGateway.getDivisionGroups();
    const divisionGroups = this.prepareDivGroupsToModel(rawDivisionGroups);
    runInAction(() => {
      if (!mapUpdate) {
        this.divisionGroups = [...divisionGroups];
        this.divisionList = [...(rawDivisionGroups || [])];
      }
      this.divisionGroupsLoading = false;
      this.mapLinkedAccIdToDivisionName = new Map(mapLinkedAccIdToDivisionName);
      return this.divisionGroups;
    });
    return this.divisionGroups;
  };

  getDivisionNameByDivId = (divisionId) => {
    const division = this.divisionGroups.find((div) => div.divisionId === divisionId);
    return division?.divisionNameDisplay || division?.divisionName || '';
  };

  get divisionAllocatedLinkedAccounts() {
    const keys = [...this.mapLinkedAccIdToDivisionName.keys()];
    return keys;
  }

  prepareDivGroupsToModel = (rawDivisionGroups) => {
    const divGroups = [];
    if (rawDivisionGroups) {
      rawDivisionGroups.forEach((divGroup) => {
        const newGroup = new DivisionGroup(divGroup);
        divGroups.push(newGroup);
      });
    }
    return divGroups;
  };

  createNewDivisionGroup = async (divGroupName, linkedAccountsIds, newDivision, parentAccountKey, divisionTypeId) => {
    try {
      const { divisionCode, divisionName } = newDivision;
      const createDivisionGroupBody = { divisionCode, divisionName, linkedAccountsIds, divisionTypeId };
      const { newDivisionId, roleId, divisionAvailableLinkedAccounts } = await this.apiGateway.createDivisionGroup(
        createDivisionGroupBody,
      );
      newDivision.divisionId = newDivisionId;
      newDivision.accountKey = parentAccountKey;
      newDivision.divisionTypeId = divisionTypeId;
      newDivision.roleId = roleId;
      if (newDivisionId > -1) {
        this.createSubUserDashbaord(null, newDivisionId, null);
        this.divisionGroups = [...this.divisionGroups, new DivisionGroup(newDivision)];
        this.availbleDivisionLinkedAccounts = divisionAvailableLinkedAccounts;
      }
    } catch (error) {
      throw error;
    }
  };
  deleteDivisionGroup = async (divisionId, divisionName) => {
    try {
      const { isSuccess, divisionAvailableLinkedAccounts } = await this.apiGateway.deleteDivisionGroup(
        divisionId,
        divisionName,
      );
      if (isSuccess) {
        const delDivisionIndex = this.divisionGroups.findIndex((divGroup) => divGroup.divisionId === divisionId);
        if (delDivisionIndex > -1) {
          this.divisionGroups.splice(delDivisionIndex, 1);
        }
        this.availbleDivisionLinkedAccounts = divisionAvailableLinkedAccounts;
      }
      return isSuccess;
    } catch (error) {}
  };
  updateDivisionGroupName = async (divisionId, divGroupNewName) => {
    const editedDivision = this.divisionGroups.find((x) => x.divisionId === divisionId);
    if (!editedDivision) {
      return;
    }
    const body = {
      divisionId,
      divisionName: editedDivision.divisionName,
      newDivisionName: divGroupNewName,
    };
    const result = await this.apiGateway.updateDivisionGroupName(divisionId, body);
    return result;
  };
  updateDivisionGroup = async (divisionId, divGroupNewName, newDivCode) => {
    const editedDivision = this.divisionGroups.find((x) => x.divisionId === divisionId);
    if (!editedDivision) {
      return;
    }
    const body = {
      divisionId,
      divisionName: editedDivision.divisionName,
      newDivisionName: divGroupNewName,
      newDivCode,
    };
    const result = await this.apiGateway.updateDivisionGroup(divisionId, body);
    return result;
  };
  getDivisionById = (divisionId) => {
    let divisionIdx = null;
    let division = null;
    if (divisionId >= 0) {
      divisionIdx = this.divisionGroups.findIndex((div) => div.divisionId === divisionId);
      if (divisionIdx > -1) {
        division = this.divisionGroups[divisionIdx];
      }
    }
    return { division, divisionIdx };
  };

  updateDivisionGroupLinkAccounts = async (
    divisionId,
    linkedAccounts,
    divisionName,
    divisionCode,
    divisionTypeId,
    roleId,
  ) => {
    const currEditedDivision = this.getDivisionById(divisionId);
    if (currEditedDivision) {
      try {
        const linkedAccountIds = linkedAccounts && linkedAccounts.map(({ linkedAccountId }) => linkedAccountId);
        const { isSuccess } = await this.apiGateway.updateDivisionGroupLinkAccounts(
          divisionId,
          linkedAccountIds,
          divisionName,
          divisionCode,
          divisionTypeId,
          roleId,
        );
        if (isSuccess) {
          await this.fetchAvailableDivisionLinkedAccounts();
          await this.fetchDivisionGroups();
        }
        return isSuccess;
      } catch (error) {
        await this.fetchDivisionGroups();
        return false;
      }
    }
  };

  getIsUserSubscribedToMondayWebhook = async () => {
    const isUserSubscribed = await this.apiGateway.getIsUserSubscribedToMondayWebhook();
    return isUserSubscribed;
  };

  getDivisionValues = () => {
    const names = this.divisionGroups.map((div) => div.divisionName);
    return names;
  };
  getDivisionValuesByPayerAccount = (payerAccountId) => {
    const filteredDivisionGroups = this.divisionGroups.filter((div) => div.accountId === payerAccountId);
    return filteredDivisionGroups.map((div) => div.divisionName);
  };
  getDivisionIdByDivName = (name) => {
    const index = this.divisionGroups.findIndex((div) => div.divisionName === name);
    if (index > -1) {
      return this.divisionGroups[index].divisionId;
    }
    return index;
  };

  updateDefaultAccountId = async ({ accountId, userKey }) => {
    await this.apiGateway.updateDefaultAccountId(accountId);
    runInAction(() => {
      this.usersNotifications = {
        ...this.usersNotifications,
        defaultAccountId: accountId,
      };
      this.users = this.users.map((u) => {
        if (u.userKey !== userKey) {
          return u;
        }
        return {
          ...u,
          settings: {
            ...(u.settings || {}),
            defaultAccountId: accountId,
          },
        };
      });
      this.fetchUser();
    });
  };

  invalidateDivisionGroups = () => {
    this.divisionGroups = [];
  };
  invalidateSubUsers = () => {
    this.subUsers = [];
    this.customersSubUsers = [];
  };

  invalidateData = () => {
    this.mainUser = null;
    this.users.splice(0);
    this.userLinkedAccounts = [];
    this.userAlerts.splice(0);
    this.subUsers = [];
    this.customersSubUsers = [];
    this.divisionGroups = [];
    this.divisionList = [];
    this.availableLinkedAccounts = [];
    this.availbleDivisionLinkedAccounts = [];
    this.usersNotifications = {};
  };
}
