/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      You are currently being charged for an old KMS.
      <br />
      A KMS is defined as old, per Recommendation preferences (default is 365 days).
      <br />
      While they still might be in use, it&apos;s recommended to rotate any keys that are older than 365 days.
      <br />
      We recommend rotating these keys, which will create new ones, and then to disable the old ones.
      <br />
    </p>
    <p>
      If you are confident keys are already rotated, use the following command for scheduling a deletion.
      <br />
      Deletion will happen within the preferred window time that is 7-30 days.
      <br />
      Note that as long as the Keys are pending deletion this action is reversible.
      <br />
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
