import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { getDashboardFilter } from 'recommendationsNew/consts';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';

const NewRecommendationDashboardContext = (props) => {
  const {
    setRecommendationFilters: setFiltersContext,
    setRecommendationExternalFilters: setExternalFiltersContext,
    setIsDashboardPanel,
  } = useRecommendationsNewContext();
  const { setHeatMapSortByOption, setHeatMapGroupByOptions } = useHeatMapContext();
  const { filterState } = props;
  const { filterContext: panelFilters, sortBy, groupBy } = filterState || {};
  const { usageStore } = useRootStore();
  const transformKeys = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      newObj[newKey] = obj[key];
    });
    return newObj;
  };
  const getDashboardIdFromLocation = () => {
    const { id: idFromProps, match } = props;
    if (idFromProps) {
      return idFromProps;
    }
    const { id } = match.params;
    return id;
  };

  useEffect(() => {
    const dashboardId = getDashboardIdFromLocation();
    if (dashboardId) {
      const dashboardFilters = getDashboardFilter(dashboardId, usageStore.customDbSubStore.customDashboardModel);
      setExternalFiltersContext(dashboardFilters);
      if (panelFilters) {
        setFiltersContext(transformKeys(panelFilters));
      }
    }
    setHeatMapSortByOption(sortBy);
    setHeatMapGroupByOptions(groupBy);
    setIsDashboardPanel(true);
  }, []);

  return null;
};

export default withRouter(observer(NewRecommendationDashboardContext));
