/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';
// TODO update description
const Description = ({ rec }) => (
  <DescriptionWrapper style={{ 'line-height': '30px', 'font-size': '13px' }}>
    <p>Cosmos Right Sizing</p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
