import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import AccountTagsTable from './AccountTagsTable';

const AccountTagsUploadModal = (props) => {
  const { toggle, isOpen, className, title, data, columns, handleUpdateFile } = props;
  return (
    <CustomModal
      cancelTitle="Close"
      open={isOpen}
      onClose={toggle}
      className={className}
      overrideStyles={{ width: 800 }}
      title={title}
      saveHidden
    >
      <AccountTagsTable
        tableData={data}
        columns={columns}
        defaultColumnWidths={[]}
        tableColumnExtensions={[]}
        numericColumns={[]}
        defaultHiddenColumnNames={[]}
        defaultSorting={[]}
        currenciesColumns={[]}
        costColumnsArray={[]}
        isResize
        isPaging
        isVisibility
        rowUpdateHandler={handleUpdateFile}
      />
    </CustomModal>
  );
};

AccountTagsUploadModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  handleUpdateFile: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.object.isRequired,
  className: PropTypes.bool,
};
AccountTagsUploadModal.defaultProps = {
  className: '',
};

export default AccountTagsUploadModal;
