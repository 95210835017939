import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import DateFilter from 'shared/modules/dateFilter';
import {
  createTimeZoneAgnosticDateFromStr,
  datePickerSyntexDates,
  getLastDayOfQuarterDate,
  getLastDayOfYearDate,
  getLstDayOfMonthDate,
  getLastDayOfWeekDate,
} from 'shared/utils/dateUtil';
import { DAYS_LIMIT_BY_GRAN_LEVEL } from 'usage/constants/usageConstants';
import 'react-datepicker/dist/react-datepicker.css';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from '@mui/material/Tooltip';

import classes from './DatePickerFilter.module.scss';

const Input = ({
  startDate,
  endDate,
  openCalendar,
  granLevel,
  isCue,
  isOpen,
  isStartDateSelection,
  isStartDisable,
  isEndDisable,
  isDateAdjusted,
  maxDaysInRange,
  isDateColumnView,
  dateFormat,
  hideEnd,
}) => {
  const getDateFormat = () => {
    if (dateFormat) {
      return dateFormat;
    }
    if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY) {
      return 'MMM Do YYYY, HH:00';
    }
    if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_YEARLY) {
      return 'YYYY';
    }
    if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_QUARTERLY) {
      return '[Q]Q YYYY';
    }
    return `MMM ${granLevel === 'month' ? '' : 'Do'} ${isCue ? ', YYYY' : 'YY'}`;
  };

  const datesDisplay = ({ title, date }) => (
    <div className={`${isDateColumnView ? classes.dateColumnView : classes.regularView}`}>
      <h5>
        <span>{title}</span>
      </h5>
      <div className={classes.dateText}>
        <span>{date}</span>
        <GenerateIcon iconName={ICONS.calendar.name} style={{ paddingLeft: 10 }} />
      </div>
    </div>
  );

  return (
    <div className="d-flex">
      <div
        className={`d-flex date-picker-container ${isCue ? 'cue' : ''} ${
          granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY ? 'hour' : ''
        }`}
      >
        <div
          onClick={() => openCalendar(!isOpen, true)}
          aria-disabled={isStartDisable}
          className={classNames({
            'date-picker__label date-picker__label-divider': true,
            active: isOpen && isStartDateSelection,
            'hide-end': hideEnd,
          })}
        >
          {datesDisplay({ title: `From:`, date: moment(startDate).format(getDateFormat(granLevel)) })}
        </div>
        {!hideEnd && (
          <div
            onClick={() => openCalendar(!isOpen, false)}
            aria-disabled={isEndDisable}
            className={`date-picker__label ${isOpen && !isStartDateSelection ? 'active' : ''}`}
          >
            <Tooltip
              title={
                isDateAdjusted
                  ? `The end date you’ve set has been adjusted to fit the time granularity limitations:
              ${maxDaysInRange} days.
              In case a higher range of time is needed please choose a different time granularity. ${
                granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY &&
                'Please contact Anodot Support if more then one month of hourly data is required.'
              }`
                  : null
              }
              arrow
              open
              placement={granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY ? 'right' : 'top'}
            >
              {datesDisplay({ title: 'To:', date: moment(endDate).format(getDateFormat(granLevel)) })}
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  openCalendar: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  granLevel: PropTypes.string.isRequired,
  isCue: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isStartDateSelection: PropTypes.bool.isRequired,
  isStartDisable: PropTypes.bool,
  isEndDisable: PropTypes.bool,
  isDateAdjusted: PropTypes.bool,
  maxDaysInRange: PropTypes.number,
  isDateColumnView: PropTypes.bool,
  hideEnd: PropTypes.bool,
};
Input.defaultProps = {
  isStartDisable: false,
  isEndDisable: false,
  isDateAdjusted: false,
  maxDaysInRange: 150,
  isDateColumnView: false,
  hideEnd: false,
};
// todo - mick - extract to utils + add tests
const formatStartDate = (startDateRaw, isMonth, isHour) => {
  let formattedStartDate = startDateRaw;
  if (isMonth) {
    formattedStartDate = moment(startDateRaw).startOf('month').format('YYYY-MM-DD');
  }
  return typeof formattedStartDate === 'string'
    ? createTimeZoneAgnosticDateFromStr(formattedStartDate, isHour)
    : formattedStartDate;
};
const formatEndDate = (endDateRaw, isHour) =>
  typeof endDateRaw === 'string' ? createTimeZoneAgnosticDateFromStr(endDateRaw, isHour) : endDateRaw;

const adjustToEndOfPeriod = (date, granLevel) => {
  let adjustedDate = date;
  if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_YEARLY) {
    adjustedDate = getLastDayOfYearDate(adjustedDate);
  } else if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_QUARTERLY) {
    adjustedDate = getLastDayOfQuarterDate(adjustedDate);
  } else if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_MONTHLY) {
    adjustedDate = getLstDayOfMonthDate(adjustedDate);
  } else if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_WEEKLY) {
    adjustedDate = getLastDayOfWeekDate(adjustedDate);
  }
  return adjustedDate;
};

const calcMaxStartDate = (externalMaxEndDate, granLevel, lastProcessTime) => {
  // calc maximal date based external prop or last process time
  let maximalDateValue = externalMaxEndDate || datePickerSyntexDates(null, lastProcessTime).endDate;
  maximalDateValue = adjustToEndOfPeriod(maximalDateValue, granLevel);
  return maximalDateValue;
};
const calcMaxEndDate = (externalMaxEndDate, startDate, granLevel, lastProcessTime, maxDaysInRange) => {
  // calc maximal date based external prop or last process time
  let maximalDateValue = externalMaxEndDate || datePickerSyntexDates(null, lastProcessTime).endDate;
  maximalDateValue = adjustToEndOfPeriod(maximalDateValue, granLevel);

  // calc max date based on days limit from start date
  const baseDate = startDate || new Date();
  const calculatedMaxDate = adjustToEndOfPeriod(moment(baseDate).add(maxDaysInRange, 'day').toDate(), granLevel, false);
  // return minimum between both options
  return maximalDateValue > calculatedMaxDate ? calculatedMaxDate : maximalDateValue;
};
const DatePickerFilter = ({
  isDateRangeError,
  onDateChange,
  handleDateSelection,
  currPeriodGranLevel,
  daysLimit,
  isDisabled,
  isStartDisable,
  isEndDisable,
  hideEnd,
  className,
  maxDate: maxDateFromProps,
  isMaxDateNeeded,
  startDate: startDateRaw,
  endDate: endDateRaw,
  isCue,
  andtLook,
  minDate,
  forceNoMaxDate,
  isPopover,
  placement,
  isDateColumnView,
  dateFormat,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isStartDateSelection, setIsStartDateSelection] = useState(true);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [maxStartDate, setMaxStartDate] = useState(new Date());
  const [maxEndDate, setMaxEndDate] = useState(new Date());
  const [maxDaysInRange, setMaxDaysInRange] = useState(daysLimit || DAYS_LIMIT_BY_GRAN_LEVEL[currPeriodGranLevel]);
  const [isDateAdjusted, setIsDateAdjusted] = useState(false);

  const isHourly = currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY;
  const isDaily = currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_DAILY;
  const isWeekly = currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_WEEKLY;
  const isMonth = currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_MONTHLY;
  const isQuarter = currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_QUARTERLY;
  const isYear = currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_YEARLY;
  const lastProcessTime = DateFilter.getDate();

  useEffect(() => {
    if (isDateRangeError) {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    setMaxDaysInRange(daysLimit || DAYS_LIMIT_BY_GRAN_LEVEL[currPeriodGranLevel]);
  }, [daysLimit, currPeriodGranLevel]);

  useEffect(() => {
    let maxStart = null;
    let maxEnd = null;
    if (isMaxDateNeeded) {
      maxStart = calcMaxStartDate(maxDateFromProps, currPeriodGranLevel, lastProcessTime);
      maxEnd = calcMaxEndDate(maxDateFromProps, start, currPeriodGranLevel, lastProcessTime, maxDaysInRange);
    }
    setMaxStartDate(maxStart);
    setMaxEndDate(maxEnd);
  }, [start, end, currPeriodGranLevel, lastProcessTime, isMaxDateNeeded, maxDateFromProps, maxDaysInRange]);

  useEffect(() => {
    // check if current end date is greater than max end date
    if (!forceNoMaxDate && end && maxEndDate) {
      const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
      if (endDate > maxEndDate) {
        setEnd(maxEndDate);
        setIsDateAdjusted(true);
      }
    }
  }, [maxEndDate]);

  useEffect(() => {
    // hide date adjusted tooltip in case time granularity was changed
    setIsDateAdjusted(false);
  }, [maxDaysInRange]);
  useEffect(() => {
    // hide date adjusted tooltip in case the picker was closed
    if (!isStartDateSelection && !isOpen) {
      setIsDateAdjusted(false);
    }
  }, [isOpen, isStartDateSelection]);

  useEffect(() => {
    setStart(formatStartDate(startDateRaw, isMonth, isHourly));
    setEnd(formatEndDate(endDateRaw, isHourly));
  }, [startDateRaw, endDateRaw, isMonth]);

  const handleDateChange = (date, event) => {
    // should close only when hour selection for hourly mode, not date selection
    const shouldClose = !isHourly || !event;
    const selectedDate = date[0] || date;
    if (isStartDateSelection) {
      let newStartDate = selectedDate;
      // adjust date to start of period if weekly resolution
      if (currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_WEEKLY) {
        newStartDate = moment(selectedDate).startOf('isoWeek').toDate();
      }
      setStart(newStartDate);
      // if new start date is after end date, adjust end date
      if (newStartDate > end) {
        setEnd(newStartDate);
      }
      // Since we are using the same datepicker for both start and end dates, we need to close and reopen the datepicker
      // in order to trigger calculations in the date picker component
      if (shouldClose) {
        setIsStartDateSelection(false);
        setIsOpen(false);
        if (!isEndDisable) {
          setTimeout(() => {
            setIsOpen(true);
          }, 50);
        } else {
          // setTimeout(() => {
          onDateChange({ startDate: newStartDate, endDate: null });
          // }, 50);
        }
      }
    } else {
      // adjust date to end of period
      let newEndDate = selectedDate;
      // todo - extrac to common function
      if (currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_YEARLY) {
        newEndDate = getLastDayOfYearDate(newEndDate);
      } else if (currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_QUARTERLY) {
        newEndDate = getLastDayOfQuarterDate(newEndDate);
      } else if (currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_MONTHLY) {
        newEndDate = getLstDayOfMonthDate(newEndDate);
      } else if (currPeriodGranLevel === GranularityLevelsTypes.GRAN_LEVEL_WEEKLY) {
        newEndDate = moment(selectedDate).endOf('isoWeek').toDate();
      }
      setEnd(newEndDate);
      onDateChange({ startDate: start, endDate: newEndDate });
      if (shouldClose) {
        setIsStartDateSelection(true);
        setIsOpen(false);
      }
      // report to segment
      segmentEvent({
        type: 'select',
        target: `datePickerFilter`,
        data: {
          startDate: start,
          endDate: newEndDate,
        },
      });
    }
    setIsDateAdjusted(false);
    handleDateSelection();
  };

  const handleClickOutside = () => {
    onDateChange({ startDate: start, endDate: end });
    setIsOpen(false);
    // report to segment
    segmentEvent({
      type: 'select',
      target: `datePickerFilter`,
      data: {
        startDate: start,
        endDate: end,
      },
    });
  };

  const toggleCalendarState = (calendarState, isStart) => {
    if (!isDisabled && !(isStartDisable && isStart) && !(isEndDisable && !isStart)) {
      // report to segment
      segmentEvent({
        target: `datePickerFilter`,
      });
      setIsOpen(calendarState);
      setIsStartDateSelection(isStart);
    }
  };
  return (
    <div
      className={classNames({
        'date-picker-anodot-customization': true,
        'date-picker': isWeekly,
        'date-picker--interval': isWeekly,
        [className]: true,
        'andt-date-picker': andtLook,
      })}
    >
      <DatePicker
        selected={isStartDateSelection ? start : end}
        showWeekNumbers={isWeekly}
        showWeekPicker={isWeekly}
        showTimeSelect={isHourly}
        timeIntervals={60} // 1 hour
        formatWeekNumber={(date) => moment(date).isoWeek()}
        onWeekSelect={isWeekly && handleDateChange}
        onChange={handleDateChange}
        selectsStart={isStartDateSelection}
        selectsEnd={!isStartDateSelection}
        startDate={start}
        endDate={isEndDisable ? null : end}
        disabledKeyboardNavigation
        popperPlacement={placement || 'bottom'}
        popperProps={isPopover ? { strategy: 'fixed' } : { strategy: 'absolute' }}
        locale="en-GB"
        onClickOutside={handleClickOutside}
        maxDate={
          // eslint-disable-next-line no-nested-ternary
          forceNoMaxDate ? (!isStartDateSelection ? null : end) : isStartDateSelection ? maxStartDate : maxEndDate
        }
        open={isOpen}
        // eslint-disable-next-line no-nested-ternary
        minDate={!isStartDateSelection ? start || minDate : minDate || null}
        showQuarterYearPicker={isQuarter}
        showYearPicker={isYear}
        dateFormat={isMonth ? 'MM/yyyy' : undefined}
        showMonthYearPicker={isMonth}
        customInput={
          <Input
            startDate={start}
            endDate={end}
            granLevel={currPeriodGranLevel}
            openCalendar={toggleCalendarState}
            isCue={isCue}
            isOpen={isOpen}
            isStartDisable={isStartDisable}
            isEndDisable={isEndDisable}
            isStartDateSelection={isStartDateSelection}
            isDateAdjusted={isDateAdjusted}
            maxDaysInRange={maxDaysInRange}
            isDateColumnView={isDateColumnView}
            dateFormat={dateFormat}
            hideEnd={hideEnd}
          />
        }
        monthsShown={isDaily || isWeekly ? 2 : 1}
      />
    </div>
  );
};

export default DatePickerFilter;
DatePickerFilter.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  handleDateSelection: PropTypes.func,
  isDateRangeError: PropTypes.bool,
  currPeriodGranLevel: PropTypes.string.isRequired,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  className: PropTypes.string,
  isCue: PropTypes.bool,
  andtLook: PropTypes.bool,
  daysLimit: PropTypes.number,
  isMaxDateNeeded: PropTypes.bool,
  forceNoMaxDate: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isStartDisable: PropTypes.bool,
  isEndDisable: PropTypes.bool,
  isPopover: PropTypes.bool,
  placement: PropTypes.string,
  isDateColumnView: PropTypes.bool,
  hideEnd: PropTypes.bool,
};

DatePickerFilter.defaultProps = {
  forceNoMaxDate: false,
  isDisabled: false,
  maxDate: undefined,
  minDate: undefined,
  className: '',
  isCue: false,
  andtLook: false,
  daysLimit: null,
  isMaxDateNeeded: true,
  isStartDisable: false,
  isEndDisable: false,
  isPopover: false,
  placement: 'bottom',
  isDateColumnView: false,
  handleDateSelection: () => {},
  isDateRangeError: false,
  hideEnd: false,
};
