/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import PropTypes from 'prop-types';
import RdsChangeChart, { Y_AXIS_UNIT } from 'recommendations/containers/RdsClassChange/components/RdsChangeChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createIopsChartData = () => {
    const { rec } = this.props;
    const { iopsMaxData, iopsAvgData, iopsPercentileData, maxIops } = rec;
    if (!iopsMaxData) {
      return undefined;
    }
    let iopsData = [];
    try {
      const arrOfDates = Object.keys(iopsMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      iopsData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(iopsMaxData[date]),
        avgData: iopsAvgData ? parseFloat(iopsAvgData[date]) : null,
        percentileData: iopsPercentileData ? parseFloat(iopsPercentileData[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: iopsData, max: maxIops };
  };

  createCpuChartData = () => {
    const { rec } = this.props;
    const { cpuMaxData, cpuAvgData, cpuPercentile95Data, cpuPercentile99Data, cpuMax } = rec;
    if (!cpuMaxData) {
      return null;
    }
    let cpuData = [];
    try {
      const arrOfDates = Object.keys(cpuMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      cpuData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(cpuMaxData[date]),
        avgData: cpuAvgData ? parseFloat(cpuAvgData[date]) : null,
        percentileData: cpuPercentile95Data ? parseFloat(cpuPercentile95Data[date]) : null,
        percentile99Data: cpuPercentile99Data ? parseFloat(cpuPercentile99Data[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: cpuData, max: cpuMax };
  };

  createMemoryChartData = () => {
    const { rec } = this.props;
    const { memUtilizationMaxData, memUtilizationAvgData, memUtilizationPercentileData } = rec;
    if (!memUtilizationMaxData) {
      return null;
    }
    let memData = [];
    try {
      const arrOfDates = Object.keys(memUtilizationMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      memData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(memUtilizationMaxData[date]),
        avgData: memUtilizationAvgData ? parseFloat(memUtilizationAvgData[date]) : null,
        percentileData: memUtilizationPercentileData ? parseFloat(memUtilizationPercentileData[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: memData };
  };

  renderIopsChart = (iopsData) => {
    if (iopsData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">IOPS Performance</p>
            <br />
            <RdsChangeChart data={iopsData.data} yLabel="IOPS" />
          </div>
        </>
      );
    }
    return <></>;
  };

  renderCpuChart = (cpuData) => {
    if (cpuData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">CPU Performance</p>
            <br />
            <RdsChangeChart data={cpuData.data} yLabel="CPU" yAxisUnit={Y_AXIS_UNIT.PERCENT} isPercentile99 />
          </div>
        </>
      );
    }
    return <></>;
  };

  renderMemoryChart = (memoryData) => {
    if (memoryData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">Memory Performance</p>
            <br />
            <RdsChangeChart data={memoryData.data} yLabel="Memory" yAxisUnit={Y_AXIS_UNIT.PERCENT} />
          </div>
        </>
      );
    }
    return <></>;
  };

  render() {
    const { update } = this.state;
    const cpuData = this.createCpuChartData();
    const memoryChartData = this.createMemoryChartData();
    const iopsData = this.createIopsChartData();

    return (
      <div>
        {update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          <>
            {this.renderIopsChart(iopsData)}
            {this.renderCpuChart(cpuData)}
            {this.renderMemoryChart(memoryChartData)}
          </>
        )}
      </div>
    );
  }
}
