import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import CustomModal from 'shared/components/andtComponents/Modal';

class AppReqEditModal extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  render() {
    const { appName, open, updateAppName, isLoading, saveEditApp } = this.props;
    return (
      <CustomModal
        title="Application Details"
        onClose={this.handleClose}
        open={open}
        closeOnSave={false}
        saveDisabled={isLoading}
        onSave={saveEditApp}
      >
        <div>
          <div className="general__header-container">
            <div>
              <h3 className="general__header-txt">Application Details</h3>
            </div>
          </div>
          <div className="general__separator" />
          <div className="form__form-group" style={{ marginTop: '10px', marginBottom: '30px' }}>
            <Label for="first-name" className="general-input__label" style={{ marginBottom: '15px' }}>
              Application Name
            </Label>
            <div className="form__form-group-field" id="first-name">
              <Input name="firstName" onChange={updateAppName} value={appName} className="general-input" />
            </div>
          </div>
        </div>
      </CustomModal>
    );
  }
}

AppReqEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  saveEditApp: PropTypes.func.isRequired,
  appName: PropTypes.string.isRequired,
  updateAppName: PropTypes.func.isRequired,
};

export default AppReqEditModal;
