import React, { useContext } from 'react';
import { useAssets } from 'usage/hooks/react-query/useAssets';

const AssetsContext = React.createContext();

export function withAssetsContextProvider(Component) {
  return function AssetsContextProvider({ ...props }) {
    const assetsData = useAssets();
    return (
      <AssetsContext.Provider
        value={{
          clearAssetData: assetsData.clearAssetData,
          fetchAssetData: assetsData.fetchAssetData,
          assetData: assetsData.assetData,
          assetDataLimited: assetsData.assetDataLimited,
          assetDataLoading: assetsData.assetDataLoading,
          assetDataTooBig: assetsData.assetDataTooBig,
        }}
      >
        <Component {...props} />
      </AssetsContext.Provider>
    );
  };
}

export function withAssetsContextConsumer(Component) {
  return function AssetsContextConsumer({ ...props }) {
    return <AssetsContext.Consumer>{(data) => <Component {...data} {...props} />}</AssetsContext.Consumer>;
  };
}

export const useAssetsContext = () => useContext(AssetsContext);
