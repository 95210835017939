import React from 'react';
import { Tooltip } from '@mui/material';

const CustomEventDot = ({ title, ...props }) => (
  <g
    {...props}
    style={{
      transform: `translate(${props.cx - 8}px, 20px) scale(0.4)`,
      cursor: 'pointer',
    }}
  >
    {title ? (
      <Tooltip title={title}>
        <circle r="20" cy="0" cx="21" stroke="#000" fill="#fff" strokeWidth={2} />
      </Tooltip>
    ) : (
      <circle r="20" cy="0" cx="21" stroke="#000" fill="#fff" strokeWidth={2} />
    )}
    {/* warning icon will be needed when add event types */}
    {/* <path */}
    {/* eslint-disable-next-line max-len */}
    {/*  d="M18.295,3.895L1.203,34.555C-0.219,37.146,0.385,39.5,4.228,39.5H36.77c3.854,0,4.447-2.354,3.025-4.945L22.35,3.914C21.996,3.223,21.482,2.49,20.393,2.5C19.233,2.521,18.658,3.203,18.295,3.895z M18.5,13.5h4v14h-4V13.5z M18.5,30.5h4v4h-4V30.5z" */}
    {/*  fill="#FFDE02FF" */}
    {/* /> */}
  </g>
);

export default CustomEventDot;
