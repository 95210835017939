import {
  AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES,
  AZURE_ONBOARDING_FIELDS,
  AZURE_ONBOARDING_STEPS,
} from './AzureOnboardingConstants';
import InstructionsPage from './pages/InstructionsPage/InstructionsPage';
import AppRegPage from './pages/AppRegPage/AppRegPage';
import ProcessDataPage from '../SharedPages/ProcessDataPage/ProcessDataPage';
import BillingExportPage from './pages/BillingExportPage/BillingExportPage';
import DataFetchSimulationPage from './pages/DataFetchSimulationPage/DataFetchSimulationPage';

export const AZURE_MCA_ONBOARDING_STEPS_CONFIG = {
  [AZURE_ONBOARDING_STEPS.CREATE_APP]: {
    title: 'Create App registration and billing export',
    titleShort: AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES.CREATE_APP,
    component: InstructionsPage,
  },
  [AZURE_ONBOARDING_STEPS.APP_REGISTRATION]: {
    title: 'Configure account App registration',
    titleShort: AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES.APP_REGISTRATION,
    component: AppRegPage,
  },
  [AZURE_ONBOARDING_STEPS.BILLING_EXPORT]: {
    title: 'Add billing export parameters',
    titleShort: AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES.BILLING_EXPORT,
    component: BillingExportPage,
  },
  [AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT]: {
    title: 'Data Fetch Simulation',
    titleShort: AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES.VALIDATE_ACCOUNT,
    component: DataFetchSimulationPage,
  },
  [AZURE_ONBOARDING_STEPS.PROCESS_DATA]: {
    title: 'Data Process',
    titleShort: AZURE_MCA_ONBOARDING_STEPS_SHORT_TITLES.PROCESS_DATA,
    component: ProcessDataPage,
    nextText: 'Done',
    noReturn: true,
    nextEnabled: AZURE_ONBOARDING_FIELDS.INVOICE_SUCCESS,
  },
};
