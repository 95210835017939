import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const DYNAMODB_IDLE_PROPERTIES = {
  RDS_TABLE_NAME: {
    label: 'Table Name',
    getterFunction: (recommendation) => recommendation?.recData?.table_name,
  },
  STARTING_TIME: {
    label: 'Starting time',
    getterFunction: (recommendation) => {
      if (!recommendation?.recData?.starting_time) {
        return null;
      }
      const startingDate = new Date(recommendation?.recData?.starting_time * 1000);
      return moment(startingDate).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};

const DynamoDbIdle = ({ recommendation }) => {
  const description =
    // eslint-disable-next-line max-len
    'This DynamoDB table has not been reached in the last 30 days. We recommend to delete it or cancel the IOPS commitment.';
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `aws dynamodb delete-table --table-name ${recommendation?.recData?.table_name}`,
        },
      ],
    },
  ];
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={DYNAMODB_IDLE_PROPERTIES}
    />
  );
};

DynamoDbIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default DynamoDbIdle;
