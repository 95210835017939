import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { MAP_K8S_GCP_DISALLOWED_PROPERTIES_BY_KEYS } from 'shared/constants/k8sConstants';
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import { mapRegionNamesToDescription } from 'shared/constants/awsConstants';

export const prepareK8sParamsForNewCustomDashboardPanel = (allParams) => {
  const {
    currDataState,
    currentGroupBy: groupByLevel,
    filterBarGroupBySecondary: secondaryGroupByLevel,
    currentGroupBy: groupByValue,
    dataKeyToWhereParamsMap: whereParamsMap,
    currPeriodGranLevel: periodGranLevel,
    fieldToFilterdValuesMap: filtersMap,
    likeFiltersStatus,
    currCostType,
    displayMetricTypes,
    SelectedUsageType,
    selectedUsageTypeMetric,
    excludedFiltersStatusMap,
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
  } = allParams;
  const prepareRouteParamsObj = {
    currDataState,
    groupByLevel,
    secondaryGroupByLevel,
    groupByValue,
    whereParamsMap,
    periodGranLevel,
    filtersMap,
    currCostType,
    likeFiltersStatus,
    displayMetricTypes,
    SelectedUsageType,
    selectedUsageTypeMetric,
    excludedFiltersStatusMap,
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
  };
  return prepareRouteParamsObj;
};

export const preparePeriodParams = (periodParams) => {
  const baseParamObj = {
    periodType: 'relativeDate',
    relativeDatesDaysDiff: periodParams.relativeDatesPeriod,
    granularity: periodParams.granularity,
  };
  return baseParamObj;
};

export const getBreadcrumbLabelDisplayName = (label, type, groupBy, cloudTypeId = 0) => {
  if (type === CostUsageStates.CUSTOM_TAGS) {
    const prefix = cloudTypeId === CLOUD_TYPE_IDS.GCP ? 'Label' : 'Custom Tag';
    return `${prefix} - ${groupBy.split('customtags:')[1]}`;
  }
  return mapRegionNamesToDescription.has(label) ? mapRegionNamesToDescription.get(label) : label;
};

export const prepareBaseParams = (baseParams) => {
  const baseParamObj = {
    newDashboardName: baseParams.customDashboardName,
    existingDashboardUuid: baseParams.existingDashboard && baseParams.existingDashboard.value,
    panelName: baseParams.customDashboardPanelName,
    type: baseParams.type,
    state: baseParams.state,
  };
  return baseParamObj;
};

export const createNewCustomDashboardPanelObj = (baseParams, periodParams, routeParams) => {
  const preparedObj = { ...baseParams, periodParams: { ...periodParams }, routeParams: { ...routeParams } };
  return preparedObj;
};

const setFiltersDisallowedKeys = (filterEntries) => {
  let filtersDisallowedKeys = null;
  const filterKeysWithValues = filterEntries.reduce((acc, [key, values]) => {
    if (values.length && !key.includes('customtags')) {
      acc.push(key);
    } else if (values.length && key.includes('customtags')) {
      acc.push('customtags');
    }
    return acc;
  }, []);
  filterKeysWithValues.some((key) => {
    filtersDisallowedKeys = MAP_K8S_GCP_DISALLOWED_PROPERTIES_BY_KEYS.get(key);
    return MAP_K8S_GCP_DISALLOWED_PROPERTIES_BY_KEYS.get(key);
  });
  return filtersDisallowedKeys;
};

export const getDisallowedItems = (
  cloudAccountType,
  fieldToFilterdValuesMap,
  newFilterBarGroupBy,
  filterBarGroupBy,
) => {
  if (cloudAccountType !== CLOUD_TYPE_IDS.GCP) {
    return null;
  }
  const filterEntries = [...fieldToFilterdValuesMap.entries()];
  let disallowedItems = MAP_K8S_GCP_DISALLOWED_PROPERTIES_BY_KEYS.get(
    filterBarGroupBy.includes('customtags') ? 'customtags' : filterBarGroupBy,
  ); // TODO - Change ? : operator by get function that return the needed values.
  // will help us to support another variants of custom tags like views, virtual tags etc easily.
  let filtersDisallowedKeys = null;
  if (newFilterBarGroupBy) {
    disallowedItems = MAP_K8S_GCP_DISALLOWED_PROPERTIES_BY_KEYS.get(
      newFilterBarGroupBy.includes('customtags') ? 'customtags' : newFilterBarGroupBy,
    ); // TODO - Change ? : operator by get function that return the needed values.
    // will help us to support another variants of custom tags like views, virtual tags etc easily.
  }
  if (!disallowedItems) {
    filtersDisallowedKeys = setFiltersDisallowedKeys(filterEntries);
  }
  return disallowedItems || filtersDisallowedKeys;
};
