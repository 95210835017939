import { numberWithCommas, strNumToSize } from 'shared/utils/strUtil';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';

function localCostStrFromString(strValue, displayedMetric, getCurrencyNumber, overrideCurrency = null) {
  if (displayedMetric === DisplayMetricTypes.COST && typeof strValue === 'number') {
    return `${getCurrencyNumber(strValue, 2, {}, overrideCurrency)}`;
  }
  return numberWithCommas(strValue, 2);
}

export const getFormatterComponent = (
  isUsage,
  usageType,
  displayedMetric,
  getCurrencyNumber,
  overrideCurrency = null,
) => {
  const defaultFormatter = (value) =>
    value.value === 0 ? '-' : localCostStrFromString(value.value, displayedMetric, getCurrencyNumber, overrideCurrency);
  let formatter = defaultFormatter;
  if (isUsage && usageType === 'Bytes') {
    const bytesFormatter = (value) => (value.value === 0 ? '-' : `${numberWithCommas(value.value, 2)} GB`);
    formatter = bytesFormatter;
  }
  return formatter;
};

export const getNumToSizeFormatterComponent = (value, cloudTypeId) => {
  const num = strNumToSize(value.value, 2, cloudTypeId);
  return num.split(' ')[0] === '0' ? 0 : num;
};
