import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { LOAD_BALANCER_PROPERTIES } from '../../recommendationPropertyUtils';

const IDLE_LOAD_BALANCER_PROPERTIES = {
  DNS_NAME: {
    label: 'DNS Name',
    getterFunction: (recommendation) => recommendation?.recData?.dns_name,
  },
};

const IdleLoadBalancer = ({ recommendation }) => {
  const description = 'This Load balancer is not attached to any instance';
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `aws elb delete-load-balancer --load-balancer-name ${recommendation?.recData?.load_balancer_name}`,
        },
      ],
    },
  ];

  return (
    <BaseRecommendation
      recommendation={recommendation}
      commandsList={commandsList}
      description={description}
      dynamicProps={{ ...LOAD_BALANCER_PROPERTIES, ...IDLE_LOAD_BALANCER_PROPERTIES }}
    />
  );
};

IdleLoadBalancer.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default IdleLoadBalancer;
