import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, RECOMMENDATIONS_HEATMAP_ROOT, STALE_TIME } from 'recommendationsNew/consts';
import { transformFiltersContextToApiFormat } from 'recommendationsNew/hooks/react-query/apiUtils';
import { useRootStore } from 'app/contexts/RootStoreContext';

function fetchHeatMapSummary(filters) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/summary`, {
    body: {
      filters: transformedFilters || {},
    },
  });
}

export default function useHeatMapSummary(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_HEATMAP_SUMMARY,
    userAccountKey,
  ];

  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries(
        queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
          : queryKey,
      ),
    reset: (queryKeyToInvalidate) =>
      queryClient.resetQueries(
        queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
          : queryKey,
      ),
    fetchHeatMapSummary: (filters) =>
      useQuery([...queryKey, filters], () => fetchHeatMapSummary(filters), {
        retry: false,
        staleTime: STALE_TIME,
        enabled: isEnabled,
        keepPreviousData: true,
      }),
  };
}
