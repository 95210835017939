/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

class ResourceDetailsTable extends PureComponent {
  static propTypes = {
    // resource: PropTypes.object.isRequired,
    resourceDetails: PropTypes.object.isRequired,
    currCloudAccount: PropTypes.number.isRequired,
  };

  renderTags = (tags) => (
    <div>
      <div className="panel__title">
        <h5 className="bold-text">Tags</h5>
        <h5 className="subhead">{''}</h5>
      </div>

      <div style={{ margin: '15px' }}>
        <table className="project-summary__info panel__content">
          <tbody>
            {Object.entries(JSON.parse(tags)).map(([name, value]) => (
              <tr>
                <th style={{ paddingLeft: '15px' }}>{name}</th>
                <td style={{ maxWidth: '280px' }}>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  render() {
    const { resourceDetails } = this.props;
    return (
      <Card className="panel card panel--divider panel--collaps">
        <CardBody className="panel__body">
          <div className="panel__title">
            <h5 className="bold-text">Details</h5>
            <h5 className="subhead">{''}</h5>
          </div>

          <div style={{ margin: '15px' }}>
            <table className="project-summary__info panel__content">
              <tbody>
                {/* <tr>
                  <th style={{ 'text-decoration': 'underline', fontWeight: 'bold' }}>Details</th>
                </tr> */}
                <tr>
                  <th style={{ paddingLeft: '15px' }}>Service:</th>
                  <td>{resourceDetails.serviceName}</td>
                </tr>
                {this.props.currCloudAccount === CLOUD_TYPE_IDS.AWS ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>Instance Type:</th>
                    <td>{resourceDetails.instanceType}</td>
                  </tr>
                ) : this.props.currCloudAccount === CLOUD_TYPE_IDS.AZURE &&
                  resourceDetails.serviceName &&
                  resourceDetails.serviceName === 'Virtual Machines' ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>Instance Type:</th>
                    <td>{resourceDetails.title}</td>
                  </tr>
                ) : null}
                <tr>
                  <th style={{ paddingLeft: '15px' }}>Region:</th>
                  <td>{resourceDetails.regionTagName}</td>
                </tr>
                {resourceDetails.environment ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>Environment:</th>
                    <td>{resourceDetails.environment}</td>
                  </tr>
                ) : null}
                {resourceDetails.project ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>Project:</th>
                    <td>{resourceDetails.project}</td>
                  </tr>
                ) : null}
                {resourceDetails.memory ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>Memory:</th>
                    <td>{resourceDetails.memory} GB</td>
                  </tr>
                ) : null}
                {resourceDetails.vCPU ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>vCPU:</th>
                    <td>{resourceDetails.vCPU}</td>
                  </tr>
                ) : null}
                {resourceDetails.ecu ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>ecu:</th>
                    <td>{resourceDetails.ecu}</td>
                  </tr>
                ) : null}
                {resourceDetails.instanceStorage ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>Storage:</th>
                    <td>{resourceDetails.instanceStorage}</td>
                  </tr>
                ) : null}
                {resourceDetails.networkPerformance ? (
                  <tr>
                    <th style={{ paddingLeft: '15px' }}>Network Performance:</th>
                    <td>{resourceDetails.networkPerformance}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          {resourceDetails.customTags ? this.renderTags(resourceDetails.customTags) : ''}
        </CardBody>
      </Card>
    );
  }
}

export default ResourceDetailsTable;
