import { GCP_ONBOARDING_STEPS, GCP_ONBOARDING_STEPS_SHORT_TITLES } from './GcpOnboardingConstants';

export const handleNextScreen = (screen) => {
  switch (screen) {
    case GCP_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return GCP_ONBOARDING_STEPS.GCP_DETAILS;
    // TODO: change once videos are ready
    // return isAutomatic ? GCP_ONBOARDING_STEPS.CREATE_APP : GCP_ONBOARDING_STEPS.CREATE_APP;
    case GCP_ONBOARDING_STEPS.CREATE_APP:
      return GCP_ONBOARDING_STEPS.GCP_DETAILS;
    case GCP_ONBOARDING_STEPS.GCP_DETAILS:
      return GCP_ONBOARDING_STEPS.K8S_INTEGRATION;
    case GCP_ONBOARDING_STEPS.K8S_INTEGRATION:
      return GCP_ONBOARDING_STEPS.PROCESS_DATA;
    default:
      return screen;
  }
};

export const handlePrevScreen = (screen) => {
  switch (screen) {
    case GCP_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return screen;
    case GCP_ONBOARDING_STEPS.CREATE_APP:
      return GCP_ONBOARDING_STEPS.ACCOUNT_TYPE;
    case GCP_ONBOARDING_STEPS.GCP_DETAILS:
      return GCP_ONBOARDING_STEPS.ACCOUNT_TYPE;
    case GCP_ONBOARDING_STEPS.K8S_INTEGRATION:
      return GCP_ONBOARDING_STEPS.GCP_DETAILS;
    case GCP_ONBOARDING_STEPS.PROCESS_DATA:
      return GCP_ONBOARDING_STEPS.K8S_INTEGRATION;
    default:
      return screen;
  }
};

export const getPageForPausedOnboarding = (onboarding) => {
  const {
    validationStatus,
    accountSetupStatus,
    userManagementStatus,
    validationShouldStart,
    invoiceShouldStart,
    invoiceStatus,
    validationStarted,
  } = onboarding;
  if (
    ['SUCCESS', 'FAILED', 'RUNNING'].includes(invoiceStatus?.status) ||
    (userManagementStatus?.status === 'SUCCESS' && invoiceShouldStart)
  ) {
    if (invoiceShouldStart && invoiceStatus?.status === 'RUNNING') {
      return GCP_ONBOARDING_STEPS.PROCESS_DATA;
    }
    return GCP_ONBOARDING_STEPS.PROCESS_DATA;
  }
  if (accountSetupStatus?.status === 'RUNNING' || userManagementStatus?.status === 'RUNNING') {
    return GCP_ONBOARDING_STEPS.PROCESS_DATA;
  }
  const isRunning = validationStatus?.status === 'RUNNING';
  if (validationStatus?.status === 'SUCCESS') {
    return GCP_ONBOARDING_STEPS.K8S_INTEGRATION;
  }
  if ((validationShouldStart && isRunning) || validationStarted) {
    return GCP_ONBOARDING_STEPS.K8S_INTEGRATION;
  }
  if (validationShouldStart && ['FAILED'].includes(validationStatus?.status)) {
    return GCP_ONBOARDING_STEPS.K8S_INTEGRATION;
  }
  return GCP_ONBOARDING_STEPS.GCP_DETAILS;
};

export const getPageTitleForPausedOnboarding = (onboarding) =>
  GCP_ONBOARDING_STEPS_SHORT_TITLES[getPageForPausedOnboarding(onboarding)];

export const getGcpAccountTypeServer = (accountType) => accountType;
