import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Badge, Card, CardBody, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Spinner from 'shared/components/andtComponents/Spinner';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import style from 'scss/component/recommStatusBadge.module.scss';
import { mapRecommendationsTypeToDisplay } from 'recommendations/constants/recommendationsConstants';
import ExcludeRulesTable from 'recommendations/containers/Manage/components/ExcludeRulesTable';
import useRecommendationExclusions from 'recommendations/hooks/react-query/useRecommendationExclusions';

const propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ManageRecommendationsPage = ({ usersStore }) => {
  const [key, setKey] = useState(null);

  const excludeRulesHook = useRecommendationExclusions();
  const { data: excludeRules, isLoading } = excludeRulesHook.fetchRecommendationExcludeRules();

  const createCol = (colName, colTitle, getCellValueFunc = (row) => row[colName]) => ({
    name: colName,
    title: colTitle,
    getCellValue: getCellValueFunc,
  });

  const getExclusionStatusComponent = (status) => {
    const className = style[`recomm_status_badge_${status ? 'enabled' : 'expired'}`];
    return <Badge color="inherit" className={className}>{status ? 'Enabled' : 'Expired'}</Badge>;
  };

  const columns = () => [
    createCol('type', 'Recommedation'),
    createCol('scope', 'Scope'),
    createCol('period', 'Period (days)'),
    createCol('date', 'Creation'),
    createCol('expire', 'Expiration', ({ expire }) => moment(expire).format('YYYY-MM-DD')),
    createCol('status', 'Status', ({ status }) => getExclusionStatusComponent(status)),
    createCol('key', 'Key', ({ uuid }) => uuid),
  ];

  const processExcludeRule = (excludeRule) => {
    const {
      exclude_data: excludeData,
      recomm_type: recommType,
      creation_time: creationTime,
      expire: expTimestamp,
      status,
    } = excludeRule;
    const { type: excludeDataType } = excludeData.scope || Object.values(excludeData.scopeMap || {})[0];
    const scopeType = excludeDataType || '';
    const type = mapRecommendationsTypeToDisplay.get(recommType || scopeType);
    const date = creationTime.substr(0, 10);
    const reason = excludeData.reason || '-';
    const expire = expTimestamp * 1000;
    const excludeRuleResources = [].concat(
      Object.values(excludeData.scope || {}),
      Object.values(excludeData.scopeMap || {}),
    );
    const resources = [
      ...new Set(excludeRuleResources.map(({ resourceId, linkedAccountId }) => resourceId || linkedAccountId)),
    ];
    const result = {
      type,
      period: excludeData.period,
      date,
      reason,
      expire,
      uuid: excludeRule.uuid,
      resources,
      status,
    };
    const scope = excludeData.scope || Object.values(excludeData.scopeMap || {})[0] || {};
    if (scope.linkedAccountId) {
      result.scope = 'Linked account';
      result.scopeLinkedAccount = scope.linkedAccountId;
    } else if (scope.resourceId) {
      result.scope = 'Resource ID';
      result.scopeResourceId = scope.resourceId;
    }
    return result;
  };

  const removeExcludeRuleClicked = (selectedKey) => {
    setKey(selectedKey);
  };

  const recDeleteWarningExcludeHandler = async (action) => {
    if (action === 'cancel') {
      setKey(null);
    } else if (action === 'delete') {
      try {
        await excludeRulesHook.deleteRecommendationExclusion(key);
        setKey(null);
      } catch (error) {
        setKey(null);
      }
    }
  };
  const renderExcludeRulesTable = () => {
    const detailedExcludeRules = excludeRules.map(processExcludeRule);
    const tableColumns = columns();
    return (
      <ExcludeRulesTable
        excludeRules={detailedExcludeRules}
        tableColumns={tableColumns}
        recExcludeRemoveHandler={removeExcludeRuleClicked}
        cloudTypeId={usersStore.currDispUserCloudAccountType}
      />
    );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Container className="recommendations__main">
      <PageHeader title={PageNames.MANAGE_RECOMMENDATIONS} showDate />
      <Row>
        <Card>
          <CardBody className="recs__recs-container">
            <div>{renderExcludeRulesTable()}</div>
          </CardBody>
        </Card>
      </Row>
      <DeleteWarningModal
        deletedItemName="Exclude rule"
        isOpen={key}
        handleDelete={recDeleteWarningExcludeHandler}
        warningMessage="Be advise you are about to delete the"
        modalTitle="Delete Exclude Rule"
      />
    </Container>
  );
};

ManageRecommendationsPage.propTypes = propTypes;
const ObserverManageRecommendationsPage = observer(ManageRecommendationsPage);
export default ObserverManageRecommendationsPage;
