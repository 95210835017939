import React, { useState } from 'react';
import Select from 'react-select';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import PropTypes from 'prop-types';
import classes from './autoFillModal.module.scss';

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#E9E9F0',
    borderRadius: '6px',
    borderColor: '#E9E9F0',
    height: '36px',
    width: 279,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
};

const AutoFillModal = ({ isOpen, setIsOpen, applyAuto }) => {
  const [amount, setAmount] = useState();
  const [growth, setGrowth] = useState();
  const [type, setType] = useState();
  const { currencySymbol } = useUserSettingsContext();
  const options = [
    { value: 'period', label: 'Divide amount over period', icon: ICONS.gridDividers?.name },
    { value: 'fixed', label: 'Fix amount per month', icon: ICONS.calendarDays?.name },
    { value: 'growth', label: 'Calculated monthly increase', icon: ICONS.percent?.name },
  ];
  const formatOptionLabel = ({ label, icon }) => (
    <div className={classes.customOptionWrapper}>
      <GenerateIcon className={classes.optionIcon} iconName={icon} />
      <div>{label}</div>
    </div>
  );
  if (!isOpen) {
    return null;
  }
  return (
    <div className={classes.modal}>
      <div className={classes.header}>
        <GenerateIcon iconName={ICONS.calculator?.name} />
        <h3 className={classes.title}>Auto-Fill</h3>
      </div>
      <div className={classes.content}>
        <span>Method</span>
        <Select
          open
          styles={selectStyles}
          value={type}
          placeholder="Select"
          options={options}
          formatOptionLabel={formatOptionLabel}
          onChange={(selectedOption) => {
            setType(selectedOption);
          }}
        />
        <div className={classes.amountWrapper}>
          <div className={type?.value === 'growth' ? classes.inputGrowth : ''}>
            <span>
              {type?.value !== 'growth' ? 'Budget Amount' : 'Starting Budget'}({currencySymbol})
            </span>
            <Input
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              automationId="budget-amount-input"
              type="number"
            />
          </div>
          {type?.value === 'growth' && (
            <div className={classes.inputGrowth}>
              <span>Monthly Increase (%)</span>
              <Input
                value={growth}
                onChange={(e) => {
                  setGrowth(e.target.value);
                }}
                automationId="budget-amount-input"
                type="number"
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          isSecondary
          text="Cancel"
          automationId="autoFillCancel"
        />
        <Button
          onClick={() => {
            applyAuto(type, amount, growth);
            setIsOpen(false);
          }}
          text="Apply"
          automationId="autoFillApply"
        />
      </div>
    </div>
  );
};
export default AutoFillModal;

AutoFillModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  applyAuto: PropTypes.func.isRequired,
};
