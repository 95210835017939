import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import { numberWithCommas, removeDecPoint } from 'shared/utils/strUtil';
import { withMainDashboardContextConsumer } from 'app/contexts/MainDashboardContext';
import { getFakeT } from 'shared/utils/translationUtil';
import ExplanationList from './ExplanationList';
import styles from './trial.module.scss';
import { ReactComponent as Illustration } from './illustration-trial.svg';
import { useUserSettingsContext } from '../../../users/utils/contexts/UserSettingsContext';

const textMap = {
  HEADLINE1: (savingAmount) => <>Save up to {savingAmount} of your annual cloud costs</>,
  HEADLINE2:
    'Upgrade your account and get full access to our recommendations - Saving plans ' +
    'and reserved instances, compute and storage resources, databases and load balancers, Kubernetes and more.',
};

const t = getFakeT(textMap);

const propTypes = {
  appStore: PropTypes.object.isRequired,
  mainDbData: PropTypes.object.isRequired,
};

const TrialRecPage = ({ appStore, mainDbData }) => {
  const { getCurrencyNumber } = useUserSettingsContext();

  if (appStore.isLoading || !mainDbData.mainDashBoardData) {
    return <Spinner />;
  }
  const savingAmount = numberWithCommas(removeDecPoint(mainDbData.mainDashBoardData.expectedSavings * 12, 10));
  return (
    <div className={styles.trialRecPageContainer}>
      <div>
        <div className={styles.promotionCard}>
          <h2>{t('HEADLINE1', <span>{getCurrencyNumber(savingAmount)}</span>)}</h2>
          <div>{t('HEADLINE2')}</div>
          <Illustration />
        </div>
        <ExplanationList />
      </div>
    </div>
  );
};

TrialRecPage.propTypes = propTypes;

const ObserverTrialRecPage = withMainDashboardContextConsumer(observer(TrialRecPage));
export default ObserverTrialRecPage;
