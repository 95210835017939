import React from 'react';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>
      This S3 Bucket has multipart uploaded parts, its recommended to create a lifecycle rule that will automatically
      delete incomplete multipart objects.
    </p>
  </DescriptionWrapper>
);

export default Description;
