/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { ReportSendFrequency } from 'usage/constants/costAndUsageConstants';
import { buildTimeDiffDateFromBaseDate } from 'shared/utils/dateUtil';

const getMontyhlyFrequencyStartDate = (saveModalDeliveryTime) => {
  // can be Sunday - Saturday
  const today = new Date();
  const currDay = today.getDate();
  const maxDayInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
  const timeGap =
    currDay >= saveModalDeliveryTime
      ? maxDayInMonth - (currDay - saveModalDeliveryTime)
      : saveModalDeliveryTime - currDay;
  const { endDate } = buildTimeDiffDateFromBaseDate(today, timeGap, 'd');
  return endDate;
};
const getWeeklyFrequencyStartDate = (saveModalDeliveryTime) => {
  // can be Sunday - Saturday
  const today = new Date();
  const currDay = today.getDay();
  const timeGap =
    currDay >= saveModalDeliveryTime ? 7 - (currDay - saveModalDeliveryTime) : saveModalDeliveryTime - currDay;
  const { endDate } = buildTimeDiffDateFromBaseDate(today, timeGap, 'd');
  return endDate;
};
const getFrequencyStartDateByDeliveryTime = (saveModalDeliveryFreq, saveModalDeliveryTime) => {
  let start = new Date();
  if (saveModalDeliveryFreq === ReportSendFrequency.WEEKLY) {
    start = getWeeklyFrequencyStartDate(+saveModalDeliveryTime);
  }
  if (saveModalDeliveryFreq === ReportSendFrequency.MONTHLY) {
    start = getMontyhlyFrequencyStartDate(+saveModalDeliveryTime);
  }
  return start;
};
export const prepareFrequencyStartDate = (
  saveModalDeliveryFreq,
  saveModalCustomMailFrequencyStartDate,
  saveModalDeliveryTime,
) => {
  let startDate = saveModalCustomMailFrequencyStartDate;
  if (+saveModalDeliveryFreq === ReportSendFrequency.WEEKLY || +saveModalDeliveryFreq === ReportSendFrequency.MONTHLY) {
    startDate = getFrequencyStartDateByDeliveryTime(+saveModalDeliveryFreq, saveModalDeliveryTime);
  }
  return startDate;
};
