/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import PropTypes from 'prop-types';
import RdsChangeChart, { Y_AXIS_UNIT } from 'recommendations/containers/RdsClassChange/components/RdsChangeChart';

export default class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
  };

  constructor() {
    super();
    this.state = {
      update: false,
    };
  }

  createIopsChartData = () => {
    const { rec } = this.props;
    const { iopsMaxData, iopsAvgData, iopsPercentileData, maxIops } = rec;
    if (!iopsMaxData) {
      return undefined;
    }
    let iopsData = [];
    try {
      const arrOfDates = Object.keys(iopsMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      iopsData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(iopsMaxData[date]),
        avgData: iopsAvgData ? parseFloat(iopsAvgData[date]) : null,
        percentileData: iopsPercentileData ? parseFloat(iopsPercentileData[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: iopsData, max: maxIops };
  };

  createCpuChartData = () => {
    const { rec } = this.props;
    const { cpuMaxData, cpuAvgData, cpuPercentile95Data, cpuPercentile99Data, cpuMax } = rec;
    const { cpuMaxEstimated } = rec.selectedOption >= 0 ? rec.alternatives[rec.selectedOption] : rec;
    if (!cpuMaxData) {
      return null;
    }
    let cpuData = [];
    try {
      const arrOfDates = Object.keys(cpuMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      cpuData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(cpuMaxData[date]),
        avgData: cpuAvgData ? parseFloat(cpuAvgData[date]) : null,
        estimatedData: cpuMaxEstimated ? parseFloat(cpuMaxEstimated[date]) : null,
        percentileData: cpuPercentile95Data ? parseFloat(cpuPercentile95Data[date]) : null,
        percentile99Data: cpuPercentile99Data ? parseFloat(cpuPercentile99Data[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: cpuData, max: cpuMax };
  };

  createMemoryChartData = () => {
    const { rec } = this.props;
    const { memMaxData, memAvgData, memPercentileData, memMax } = rec;
    const { memMaxUtilizationEstimated } = rec.selectedOption >= 0 ? rec.alternatives[rec.selectedOption] : rec;
    if (!memMaxData) {
      return null;
    }
    let memData = [];
    try {
      const arrOfDates = Object.keys(memMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      memData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(memMaxData[date]),
        avgData: memAvgData ? parseFloat(memAvgData[date]) : null,
        estimatedData: memMaxUtilizationEstimated ? parseFloat(memMaxUtilizationEstimated[date]) : null,
        percentileData: memPercentileData ? parseFloat(memPercentileData[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: memData, max: memMax };
  };

  createNetworkChartData = () => {
    const { rec } = this.props;
    const { networkMaxData, networkAvgData, networkPercentileData } = rec;
    if (!networkMaxData) {
      return undefined;
    }
    let networkData = [];
    try {
      const arrOfDates = Object.keys(networkMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      networkData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(networkMaxData[date]),
        avgData: networkAvgData ? parseFloat(networkAvgData[date]) : null,
        percentileData: networkPercentileData ? parseFloat(networkPercentileData[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: networkData };
  };

  createThroughputChartData = () => {
    const { rec } = this.props;
    const { throughputMaxData, throughputAvgData, throughputPercentileData } = rec;
    if (!throughputMaxData) {
      return undefined;
    }
    let throughputData = [];
    try {
      const arrOfDates = Object.keys(throughputMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      throughputData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(throughputMaxData[date]),
        avgData: throughputAvgData ? parseFloat(throughputAvgData[date]) : null,
        percentileData: throughputPercentileData ? parseFloat(throughputPercentileData[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: throughputData };
  };

  createDbConnectionsChartData = () => {
    const { rec } = this.props;
    const { dbConnectionsMaxData, dbConnectionsAvgData, dbConnectionsPercentileData } = rec;
    if (!dbConnectionsMaxData) {
      return undefined;
    }
    let dbConnectionsData = [];
    try {
      const arrOfDates = Object.keys(dbConnectionsMaxData);
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      dbConnectionsData = arrOfDates.map((date) => ({
        usageDate: createDateDisplayStr(date),
        maxData: parseFloat(dbConnectionsMaxData[date]),
        avgData: dbConnectionsAvgData ? parseFloat(dbConnectionsAvgData[date]) : null,
        percentileData: dbConnectionsPercentileData ? parseFloat(dbConnectionsPercentileData[date]) : null,
      }));
    } catch (error) {
      // Error
    }
    return { data: dbConnectionsData };
  };

  renderIopsChart = (iopsData) => {
    if (iopsData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">IOPS Performance</p>
            <br />
            <RdsChangeChart data={iopsData.data} yLabel="IOPS" />
          </div>
        </>
      );
    }
    return <></>;
  };

  renderCpuChart = (cpuData) => {
    if (cpuData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">CPU Performance</p>
            <br />
            <RdsChangeChart
              data={cpuData.data}
              yLabel="CPU"
              yAxisUnit={Y_AXIS_UNIT.PERCENT}
              isEstimated
              isPercentile99
            />
          </div>
        </>
      );
    }
    return <></>;
  };

  renderMemoryChart = (memoryData) => {
    if (memoryData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">Memory Performance</p>
            <br />
            <RdsChangeChart data={memoryData.data} yLabel="Memory" yAxisUnit={Y_AXIS_UNIT.PERCENT} isEstimated />
          </div>
        </>
      );
    }
    return <></>;
  };

  renderNetworkChart = (networkData) => {
    if (networkData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">Network Performance</p>
            <br />
            <RdsChangeChart data={networkData.data} yLabel="Network I/O (MB)" yAxisUnit={Y_AXIS_UNIT.BYTE} />
          </div>
        </>
      );
    }
    return <></>;
  };

  renderThroughputChart = (throughputData) => {
    if (throughputData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">Throughput Performance</p>
            <br />
            <RdsChangeChart data={throughputData.data} yLabel="Throughput" yAxisUnit={Y_AXIS_UNIT.BYTE} />
          </div>
        </>
      );
    }
    return <></>;
  };

  renderDbConnectionsChart = (dbConnectionsData) => {
    if (dbConnectionsData?.data?.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">DB Connection Performance</p>
            <br />
            <RdsChangeChart data={dbConnectionsData.data} yLabel="DB Connections" isEstimated />
          </div>
        </>
      );
    }
    return <></>;
  };

  render() {
    const { update } = this.state;

    const iopsData = this.createIopsChartData();
    const cpuData = this.createCpuChartData();
    const memoryData = this.createMemoryChartData();
    const networkData = this.createNetworkChartData();
    const throughputData = this.createThroughputChartData();
    const dbConnectionsData = this.createDbConnectionsChartData();

    return (
      <div>
        {update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          <>
            {this.renderCpuChart(cpuData)}
            {this.renderMemoryChart(memoryData)}
            {this.renderIopsChart(iopsData)}
            {this.renderNetworkChart(networkData)}
            {this.renderThroughputChart(throughputData)}
            {this.renderDbConnectionsChart(dbConnectionsData)}
          </>
        )}
      </div>
    );
  }
}
