import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

// Calculate counters for the total linked accounts and total payer accounts to display
// in the LinkedAccountsComponent
export function getInitialAccountsDataWithCounters(accountsData) {
  return {
    accountsData: [],
    totalPayerAccountsByCloudProvider: {
      [CLOUD_TYPE_IDS.AWS]: accountsData?.filter((pa) => +pa.payerAccount.cloudType === CLOUD_TYPE_IDS.AWS).length,
      [CLOUD_TYPE_IDS.AZURE]: accountsData?.filter((pa) => +pa.payerAccount.cloudType === CLOUD_TYPE_IDS.AZURE).length,
      [CLOUD_TYPE_IDS.GCP]: accountsData?.filter((pa) => +pa.payerAccount.cloudType === CLOUD_TYPE_IDS.GCP).length,
    },
    totalLinkedAccountsByCloudProvider: {
      [CLOUD_TYPE_IDS.AWS]: accountsData
        ?.flatMap((pa) => pa.linkedAccounts)
        .filter((la) => +la.cloudType === CLOUD_TYPE_IDS.AWS).length,
      [CLOUD_TYPE_IDS.AZURE]: accountsData
        ?.flatMap((pa) => pa.linkedAccounts)
        .filter((la) => +la.cloudType === CLOUD_TYPE_IDS.AZURE).length,
      [CLOUD_TYPE_IDS.GCP]: accountsData
        ?.flatMap((pa) => pa.linkedAccounts)
        .filter((la) => +la.cloudType === CLOUD_TYPE_IDS.GCP).length,
    },
  };
}

const ENABLE_LOGS = false;

function debugLog(...args) {
  if (ENABLE_LOGS === true) {
    /* eslint-disable no-console */
    console.log('dataAccessHelperFunctions.js:', ...args);
  }
}

export function isAccountAssignedByCurrentRole(account, roleId) {
  debugLog(`[isAccountAssignedByCurrentRole] Checking account ${account?.id} for roleId ${roleId}`);
  const rolesDerivedFrom = account?.derivedFrom?.filter((dr) => dr.roleId);
  const result = !!(rolesDerivedFrom?.length > 0 && rolesDerivedFrom?.find((r) => r.roleId === roleId));
  debugLog(`[isAccountAssignedByCurrentRole] Result: ${result}`);
  return result;
}

function addRoleToAccount(account, roleId, payerId = null) {
  debugLog(
    `[addRoleToAccount] Adding roleId ${roleId} to account ${account.id}${payerId ? ` with payerId ${payerId}` : ''}`,
  );
  const updatedAccount = { ...account };
  if (!updatedAccount.derivedFrom) {
    updatedAccount.derivedFrom = [];
  }
  const derivedInfo = payerId ? { roleId, payerId } : { roleId };
  if (!updatedAccount.derivedFrom.some((d) => d.roleId === roleId && d.payerId === payerId)) {
    updatedAccount.derivedFrom = [...updatedAccount.derivedFrom, derivedInfo];
    debugLog(`[addRoleToAccount] Added new derivedInfo:`, derivedInfo);
  }
  return updatedAccount;
}

function processPayerAccount(payerData, roleId, currentRoleId, processAllLinked, specificLinkedAccounts = []) {
  debugLog(`[processPayerAccount] Processing payer ${payerData.payerAccount.id}`, {
    roleId,
    processAllLinked,
    specificLinkedAccounts,
  });

  const processedPayerData = JSON.parse(JSON.stringify(payerData));

  if (processAllLinked) {
    debugLog(`[processPayerAccount] Processing all linked accounts`);
    processedPayerData.payerAccount = addRoleToAccount(processedPayerData.payerAccount, roleId);
  } else if (specificLinkedAccounts.length > 0) {
    debugLog(`[processPayerAccount] Processing specific linked accounts:`, specificLinkedAccounts);
    processedPayerData.linkedAccounts = processedPayerData.linkedAccounts
      .filter((la) => {
        const included = specificLinkedAccounts.includes(la.id);
        debugLog(`[processPayerAccount] Linked account ${la.id} ${included ? 'included' : 'filtered out'}`);
        return included;
      })
      .map((la) => {
        const updatedLinkedAccount = addRoleToAccount(la, roleId, processedPayerData.payerAccount.id);
        debugLog(`[processPayerAccount] Updated linked account ${la.id}`, {
          derivedFrom: updatedLinkedAccount.derivedFrom,
        });

        return updatedLinkedAccount;
      });

    const hasLinkedAccountsWithRole = processedPayerData.linkedAccounts.some((la) =>
      la.derivedFrom.some((d) => d.roleId === roleId),
    );
    if (!hasLinkedAccountsWithRole) {
      processedPayerData.payerAccount = addRoleToAccount(processedPayerData.payerAccount, roleId);
    }
  } else {
    debugLog(`[processPayerAccount] No linked accounts specified, processing payer account only`);
    processedPayerData.linkedAccounts = [];
    processedPayerData.payerAccount = addRoleToAccount(processedPayerData.payerAccount, roleId);
  }

  return processedPayerData;
}

function mergeAccountDerivedFrom(existingAccount, newAccount) {
  debugLog(`[mergeAccountDerivedFrom] Merging accounts`, {
    existingId: existingAccount.id,
    newId: newAccount.id,
    existingDerived: existingAccount.derivedFrom,
    newDerived: newAccount.derivedFrom,
  });

  const result = {
    ...existingAccount,
    derivedFrom: [
      ...(existingAccount.derivedFrom || []),
      ...(newAccount.derivedFrom || []).filter(
        (newDerived) =>
          !(existingAccount.derivedFrom || []).some(
            (existingDerived) =>
              existingDerived.roleId === newDerived.roleId && existingDerived.payerId === newDerived.payerId,
          ),
      ),
    ],
  };

  debugLog(`[mergeAccountDerivedFrom] Merge result:`, {
    id: result.id,
    derivedFrom: result.derivedFrom,
  });
  return result;
}

function addToResultIfNotExists(result, payerData, currentRoleId) {
  debugLog(`[addToResultIfNotExists] Processing payer ${payerData.payerAccount.id}`, {
    currentRoleId,
    existingPayers: result.map((r) => r.payerAccount.id),
  });

  let updatedResult = result;

  let existingIndex = result.findIndex((p) => p.payerAccount.id === payerData.payerAccount.id);
  if (existingIndex === -1) {
    debugLog(`[addToResultIfNotExists] Adding new payer to result`);
    updatedResult = [...result, payerData];
    existingIndex = updatedResult.findIndex((p) => p.payerAccount.id === payerData.payerAccount.id);
    debugLog(`[addToResultIfNotExists] New payer added at index ${existingIndex}`);
  }

  debugLog(`[addToResultIfNotExists] Updating existing payer at index ${existingIndex}`);
  updatedResult = updatedResult.map((item, index) => {
    debugLog(`[addToResultIfNotExists] Processing item at index ${index}`, {
      isTargetIndex: index === existingIndex,
      itemPayerId: item.payerAccount.id,
      linkedAccountsCount: item.linkedAccounts.length,
    });

    if (index !== existingIndex) {
      debugLog(`[addToResultIfNotExists] Skipping non-target item at index ${index}`);
      return item;
    }

    const existingLinkedAccountsMap = new Map(item.linkedAccounts.map((account) => [account.id, account]));
    const mergedLinkedAccounts = [...item.linkedAccounts];

    debugLog(`[addToResultIfNotExists] Processing linked accounts`, {
      existingAccountsCount: mergedLinkedAccounts.length,
      newAccountsCount: payerData.linkedAccounts.length,
      existingAccountIds: Array.from(existingLinkedAccountsMap.keys()),
    });

    payerData.linkedAccounts.forEach((newAccount) => {
      const existingAccount = existingLinkedAccountsMap.get(newAccount.id);

      debugLog(`[addToResultIfNotExists] Processing linked account ${newAccount.id}`, {
        exists: !!existingAccount,
        accountDetails: {
          name: newAccount.name,
          type: newAccount.type,
        },
      });

      if (!existingAccount) {
        debugLog(`[addToResultIfNotExists] Adding new linked account ${newAccount.id}`);
        mergedLinkedAccounts.push(newAccount);
      } else {
        debugLog(`[addToResultIfNotExists] Updating existing linked account ${newAccount.id}`);
        const mergedAccount = mergeAccountDerivedFrom(existingAccount, newAccount);
        const accountIndex = mergedLinkedAccounts.findIndex((acc) => acc.id === newAccount.id);
        mergedLinkedAccounts[accountIndex] = mergedAccount;
      }
    });

    const mergedPayerAccount = mergeAccountDerivedFrom(item.payerAccount, payerData.payerAccount);

    debugLog(`[addToResultIfNotExists] Completed merging payer account`, {
      payerId: mergedPayerAccount.id,
      finalLinkedAccountsCount: mergedLinkedAccounts.length,
    });

    return {
      ...item,
      payerAccount: mergedPayerAccount,
      linkedAccounts: mergedLinkedAccounts,
    };
  });

  debugLog(`[addToResultIfNotExists] Completed processing`, {
    initialLength: result.length,
    finalLength: updatedResult.length,
    wasExisting: existingIndex !== -1,
  });

  return updatedResult;
}

function addPayerAccountTotals(payerData, accountsData) {
  const originalPayerData = accountsData.find((p) => p.payerAccount.id === payerData.payerAccount.id);
  const result = {
    ...payerData,
    totalPayerLinkedAccounts: originalPayerData ? originalPayerData.linkedAccounts.length : 0,
    totalSelectedPayerAccounts: payerData.linkedAccounts.length,
  };

  debugLog(`[addPayerAccountTotals] Added totals for payer ${payerData.payerAccount.id}`, {
    totalPayerLinkedAccounts: result.totalPayerLinkedAccounts,
    totalSelectedPayerAccounts: result.totalSelectedPayerAccounts,
  });

  return result;
}

export const STATES = {
  BLANK: 'blank',
  DIRECT: 'direct',
  HIDDEN: 'hidden',
  INFO: 'info',
  REMOVE: 'remove',
  ROLE: 'role',
};

function getPayerIsSelectedInitial(payer, isCreateEditMode) {
  if (isCreateEditMode) {
    return payer.isAssignedByCurrentRole || payer.isAssignedByAnyRole;
  }
  return false;
}

export function getIsAccountSelected(account, modifiedSelectionById) {
  const modifiedSelectionValue = modifiedSelectionById[account.id];
  return modifiedSelectionValue ?? account.isSelectedInitial;
}

export function countSelectedLinkedAccounts(accounts, modifiedSelectionById) {
  let total = 0;

  accounts?.forEach((account) => {
    const { linkedAccounts } = account;
    linkedAccounts?.forEach((linkedAccount) => {
      const isSelected = getIsAccountSelected(linkedAccount, modifiedSelectionById);
      if (isSelected) {
        total += 1;
      }
    });
  });

  return total;
}

export function countSelectedPayerAccounts(accounts, modifiedSelectionById) {
  let total = 0;

  accounts?.forEach((pa) => {
    const { payerAccount } = pa;
    const isSelected = getIsAccountSelected(payerAccount, modifiedSelectionById);
    if (isSelected) {
      total += 1;
    }
  });

  return total;
}

export function getLinkedAccountIsSelectedInitial(linkedAccount, isCreateEditMode) {
  if (isCreateEditMode) {
    return linkedAccount.isAssignedByCurrentRole || linkedAccount.isAssignedByAnyRole;
  }
  return false;
}

export function getPayerIsSelectionEnabled(payer, isCreateEditMode) {
  if (isCreateEditMode) {
    return payer.isAssignedByCurrentRole || !payer.isAssignedByAnyRole;
  }
  return payer.isAssignedByCurrentRole;
}

export function getLinkedAccountsIsSelectionHidden(payerAccount, payerModifiedSelection) {
  if (payerAccount.isAssignedByAnyRole || payerModifiedSelection === true) {
    return true;
  }
  return false;
}

export function getLinkedAccountIsSelectionEnabled(linkedAccount) {
  if (linkedAccount.isAssignedByCurrentRole) {
    return true;
  }
  if (linkedAccount.isAssignedByAnyRole) {
    return false;
  }
  return true;
}

export function getPayerAssignedBy(payer, isCreateEditMode, payerModifiedSelection) {
  debugLog(`[getPayerAssignedBy] Processing payer ${payer.id}`);

  let result = STATES.BLANK;

  if (isCreateEditMode) {
    if (payer.isAssignedByCurrentRole) {
      result = payerModifiedSelection === false ? STATES.BLANK : STATES.DIRECT;
    } else if (payer.isAssignedByAnyRole) {
      result = STATES.ROLE;
    } else if (payerModifiedSelection === true) {
      result = STATES.DIRECT;
    }
  } else if (!isCreateEditMode) {
    if (payer.isAssignedByCurrentRole) {
      result = STATES.DIRECT;
    } else if (payer.isAssignedByAnyRole) {
      result = STATES.ROLE;
    }
  }

  debugLog(`[getPayerAssignedBy] Result: ${result}`);
  return result;
}

export function getLinkedAccountAssignedBy(linkedAccount, payerModifiedSelection, linkedAccountModifiedSelection) {
  if (linkedAccount.isPayerFullyAssigned || payerModifiedSelection === true) {
    return STATES.BLANK;
  }
  if (linkedAccount.isAssignedByCurrentRole) {
    return linkedAccountModifiedSelection === false ? STATES.BLANK : STATES.DIRECT;
  }
  if (linkedAccount.isAssignedByAnyRole) {
    return STATES.ROLE;
  }
  if (!linkedAccount.isAssignedByAnyRole && !linkedAccount.isAssignedByCurrentRole) {
    return linkedAccountModifiedSelection === true ? STATES.DIRECT : STATES.BLANK;
  }
  return STATES.BLANK;
}

export function getAccountRemoveOrInfo(account, isCreateOrEditMode) {
  if (!isCreateOrEditMode) {
    if (account.isAssignedByCurrentRole) {
      return STATES.REMOVE;
    }
    if (account.isAssignedByAnyRole) {
      return STATES.INFO;
    }
  }
  return STATES.BLANK;
}

export function provideAccountsData({ accountsData, roleDataAccess, currentRoleId, isCreateEditMode }) {
  debugLog(`[provideAccountsData] Starting combination`, {
    totalAccounts: accountsData.length,
    totalRoles: roleDataAccess.length,
    currentRoleId,
    isCreateEditMode,
  });

  let result = [];

  roleDataAccess.forEach((roleAccess) => {
    const roleId = roleAccess.derivedFromRole.id;
    const { payerAccounts } = roleAccess;

    debugLog(`[provideAccountsData] Processing role ${roleId}`);

    if (payerAccounts.allLinkedAccountsOfAllPayerAccounts) {
      accountsData.forEach((payerData) => {
        debugLog(`[provideAccountsData] Processing all accounts for payer ${payerData.payerAccount.id}`);
        const processedPayerData = processPayerAccount(payerData, roleId, currentRoleId, true);
        result = addToResultIfNotExists(result, processedPayerData, currentRoleId);
      });
    } else {
      Object.entries(payerAccounts.payerToLinkedAccounts).forEach(([payerId, payerAccess]) => {
        const payerData = accountsData.find((p) => p.payerAccount.id === payerId);
        if (!payerData) {
          debugLog(`[provideAccountsData] Payer ${payerId} not found in accountsData`);
          return;
        }

        debugLog(`[provideAccountsData] Processing specific accounts for payer ${payerId}`, {
          processAll: payerAccess.all,
          specificAccounts: payerAccess.items,
        });

        const processedPayerData = processPayerAccount(
          payerData,
          roleId,
          currentRoleId,
          payerAccess.all,
          payerAccess.items,
        );
        result = addToResultIfNotExists(result, processedPayerData, currentRoleId);
      });
    }
  });

  if (isCreateEditMode) {
    debugLog(`[provideAccountsData] Processing create/edit mode accounts`);
    accountsData.forEach((payerData) => {
      debugLog(`[createEditMode]  Processing payer ${payerData.payerAccount.id}`);
      let existingIndex = result.findIndex((p) => p.payerAccount.id === payerData.payerAccount.id);
      if (existingIndex === -1) {
        debugLog(`[createEditMode] Adding new payer to result`);
        result = [...result, payerData];
        existingIndex = result.findIndex((p) => p.payerAccount.id === payerData.payerAccount.id);
      } else {
        const newLinkedAccounts = [...result[existingIndex].linkedAccounts];

        payerData.linkedAccounts.forEach((linkedAccount) => {
          const existingLinkedAccount = newLinkedAccounts.find((la) => la.id === linkedAccount.id);
          if (!existingLinkedAccount) {
            debugLog(`[createEditMode] Adding new linked account ${linkedAccount.id}`);
            newLinkedAccounts.push(linkedAccount);
          }
        });

        result[existingIndex].linkedAccounts = newLinkedAccounts;
        debugLog(`[createEditMode] Updated linked accounts for payer ${payerData.payerAccount.id}`);
      }
    });
  }

  result = result.map((payerData) => addPayerAccountTotals(payerData, accountsData));

  debugLog(`[provideAccountsData] Final result`, result);

  result.forEach((resultElement) => {
    const { payerAccount, linkedAccounts } = resultElement;
    payerAccount.isAssignedByCurrentRole = isAccountAssignedByCurrentRole(payerAccount, currentRoleId);
    payerAccount.isAssignedByAnyRole = payerAccount.derivedFrom?.length > 0;
    payerAccount.isSelectedInitial = getPayerIsSelectedInitial(payerAccount, isCreateEditMode);
    payerAccount.isSelectionEnabled = getPayerIsSelectionEnabled(payerAccount, isCreateEditMode);
    // eslint-disable-next-line no-restricted-syntax
    for (const linkedAccount of linkedAccounts) {
      linkedAccount.isAssignedByCurrentRole = isAccountAssignedByCurrentRole(linkedAccount, currentRoleId);
      linkedAccount.isAssignedByAnyRole = linkedAccount.derivedFrom?.length > 0;
      linkedAccount.isPayerFullyAssigned = payerAccount.isAssignedByAnyRole;
      linkedAccount.isSelectedInitial = getLinkedAccountIsSelectedInitial(linkedAccount, isCreateEditMode);
    }
  });

  return result;
}
