/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureKustoUnusedDataRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureKustoUnusedDataRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureKustoUnusedDataRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_KUSTO_UNUSED_DATA;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Unused Cluster`;
  }

  build() {
    super.build();
    const {
      cluster_name: clusterName,
      zone_tag: zoneTag,
      resource_group: resourceGroup,
      service,
    } = this._rawRecommendation;
    this._recommendation.clusterName = clusterName;
    this._recommendation.region = zoneTag;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.service = service;
  }
}
