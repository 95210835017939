// import react from 'react';
import { useMutation, useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const getCreditsAlertsData = async () => {
  const getUrl = '/api/v1/divisions/customers/credit/alerts';
  return API.get('billings', getUrl);
};
const addCreditAlert = async (alert) => {
  const url = '/api/v1/divisions/customers/credit/alerts';
  const body = { alert };
  return API.post('billings', url, { body });
};
const deleteCreditAlert = async (alertId) => {
  const url = `/api/v1/divisions/customers/credit/alerts/${alertId}`;
  return API.del('billings', url);
};

const useCreditsAlerts = (method, onSuccess) => {
  switch (method) {
    case 'get':
      return useQuery(apiConstants.QUERY_KEYS.RESELLER_CREDITS_ALERTS, () => getCreditsAlertsData(), {
        staleTime: Infinity,
      });
    case 'post':
      return useMutation(addCreditAlert, { onSuccess });
    case 'delete':
      return useMutation(deleteCreditAlert, { onSuccess });
    default:
      return null;
  }
};
export default useCreditsAlerts;
