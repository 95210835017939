/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      {`A Spot Instance is an unused EC2 instance that is available for less than the corresponding On-Demand price.
Spot Instances are a cost-effective choice if you can be flexible about when your applications run and if your applications can be interrupted.
We recommend Spot Instances for data analysis, batch jobs, background processing, and optional tasks.`}
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
