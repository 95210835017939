import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { AZURE_PROPERTIES, DB_PROPERTIES, ENGINE_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const AZURE_DISK_UNATTACHED_PROPERTIES = {
  SKU_NAME: {
    label: 'Instance Type (SKU)',
    getterFunction: (recommendation) => recommendation?.recData?.disk_sku_name,
  },
  UNATTACHED_DISK: {
    label: 'Detached Disk Id',
    getterFunction: (recommendation) => recommendation?.recData?.unattached_disk_id,
  },
  DISK_SIZE: {
    label: 'Disk Size',
    getterFunction: (recommendation) => recommendation?.recData?.disk_size,
  },
};
const AzureDiskUnattached = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.POWERSHELL,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `Remove-AzureRmResource -ResourceId "${recommendation?.resourceId}"`,
        },
      ],
    },
  ];
  const description =
    // eslint-disable-next-line max-len
    "Please note that this Disk is active, but no longer attached to any instance, if it's no longer needed it should be turned off";
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...ENGINE_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
        ...AZURE_DISK_UNATTACHED_PROPERTIES,
      }}
    />
  );
};

AzureDiskUnattached.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureDiskUnattached;
