import React, { useEffect, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import { isEmailValid } from 'shared/utils/strUtil';
import Button from 'shared/components/andtComponents/Button';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import styles from './AddUsersCSV.module.scss';

const AddUsersCSV = ({ onChangeEmails, emails, error: inputError, setError: setInputError }) => {
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [showUsers, setShowUsers] = useState(true);
  const [type, setType] = useState('file');
  useEffect(() => {
    onChangeEmails([]);
    setFile(null);
  }, [type]);
  return (
    <div>
      <p className={styles.title}>Choose one of the options:</p>
      <RadioButtonList
        options={[{ value: 'email', label: 'Email', primary: true }]}
        value={type}
        onChange={(val) => {
          setType(val);
        }}
      />
      <Input
        overrideStyles={{ height: 36 }}
        disabled={type !== 'email'}
        placeholder="Separate addresses by commas. Up to 10 users."
        value={type !== 'email' ? '' : emails.join(',')}
        isInvalid={!!inputError}
        invalidComponent={inputError}
        onChange={(e) => {
          onChangeEmails(e.target.value.split(','));
          setInputError(null);
        }}
        autoComplete="off"
      />
      <RadioButtonList
        options={[{ value: 'file', label: 'Upload CSV file', primary: true }]}
        value={type}
        className={styles.uploadFile}
        onChange={(val) => {
          setType(val);
        }}
      />
      <p className={styles.labelDesc}>
        Upload CSV file with a single column, the column title should be &quot;email&quot;. Limited up to 10 users.
      </p>
      <Input
        type="file"
        overrideStyles={{ height: 36, padding: 4 }}
        isInvalid={error}
        disabled={type !== 'file'}
        accept=".csv"
        icon={<GenerateIcon iconName={ICONS.upload.name} />}
        value={file}
        id="bulk-import-users"
        placeholder="Upload file from your computer"
        invalidComponent={error}
        onClick={(e) => {
          e.target.value = null;
        }}
        onChange={(e) => {
          if (!e.target.files.length) {
            return;
          }
          setFile(e.target.files[0].name);
          const readerObj = new FileReader();
          setError('');
          readerObj.onload = () => {
            const fileText = readerObj.result;
            const [header, ...rows] = fileText.split('\n').map((a) => a.trim());
            if (header !== 'email') {
              e.target.value = '';
              setError('Header is not "email".');
              onChangeEmails([]);
              return;
            }
            if (rows.length > 10) {
              setError('File has more than 10 emails.');
              e.target.value = '';
              onChangeEmails([]);
              return;
            }
            if (rows.some((r) => !isEmailValid(r))) {
              e.target.value = '';
              setError('Some of the emails are not valid.');
              onChangeEmails([]);
              return;
            }
            onChangeEmails(rows.filter(Boolean));
          };
          readerObj.readAsText(e.target.files[0]);
        }}
      />
      {emails.length > 0 && (
        <div className="pt-2">
          {showUsers ? (
            <Button
              onClick={() => setShowUsers(false)}
              text="Close Preview"
              icon={() => <GenerateIcon iconName={ICONS.xMark.name} />}
              isTextButton
            />
          ) : (
            <Button
              onClick={() => setShowUsers(true)}
              text="Preview Users List"
              icon={() => <GenerateIcon iconName={ICONS.eye.name} />}
              isTextButton
            />
          )}
          {showUsers ? (
            <div className={`${styles.usersList} sub-table`}>
              <Grid
                rows={emails.map((email) => ({ email }))}
                columns={[{ name: 'email', title: `Users (${emails.length})` }]}
              >
                <TableWrapper />
                <TableHeaderRow />
              </Grid>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

AddUsersCSV.propTypes = {
  onChangeEmails: PropTypes.func.isRequired,
  emails: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
};

export default AddUsersCSV;
