import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import {
  transformFiltersContextToApiFormat,
  transformSelectDataToClientFormat,
} from 'recommendationsNew/hooks/react-query/apiUtils';
import {
  BILLINGS_API_NAME,
  GROUP_BY_OPTIONS,
  RECOMMENDATIONS_HEATMAP_ROOT,
  SORT_BY_OPTIONS,
  STALE_TIME,
} from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';

async function fetchHeatMap({ filters, groupBy, sortBy, externalFilters, topX = 4 }) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const transformedExternalFilters = transformFiltersContextToApiFormat(externalFilters);
  const result = await API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}`, {
    body: {
      filters: transformedFilters || {},
      group_by: groupBy || [GROUP_BY_OPTIONS.items.TYPE_ID.id],
      sort_by: sortBy || SORT_BY_OPTIONS.items.SAVINGS.property,
      second_level_filters: transformedExternalFilters || {},
      top_x: topX,
    },
  });
  if (result && Object.keys(result).length > 0) {
    Object.keys(result).forEach((key) => {
      result[key] = { ...result[key], page: transformSelectDataToClientFormat(result[key]?.page, key) };
    });
  }
  return result;
}

export default function useHeatMap(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_HEATMAP,
    userAccountKey,
  ];

  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries(
        queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
          : queryKey,
      ),
    reset: (queryKeyToInvalidate) =>
      queryClient.resetQueries(
        queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
          : queryKey,
      ),
    fetchRecommendationsHeatMap: ({ filters, groupBy, sortBy, externalFilters }) =>
      useQuery(
        [...queryKey, filters, groupBy, sortBy, externalFilters],
        () => fetchHeatMap({ filters, groupBy, sortBy, externalFilters }),
        {
          retry: false,
          staleTime: STALE_TIME,
          enabled: isEnabled,
          keepPreviousData: true,
        },
      ),
  };
}
