import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorMsg.module.scss';

const propTypes = {
  display: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
};
const defaultProps = {
  display: false,
};

const ErrorMsg = ({ errorMessage, display }) => {
  if (!display) {
    return null;
  }
  return <span className={styles.errorMsg}>{errorMessage}</span>;
};

ErrorMsg.propTypes = propTypes;
ErrorMsg.defaultProps = defaultProps;
export default ErrorMsg;
