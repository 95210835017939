import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from '../modules/labelCoordinator';

export const getCustomTagFilterLabel = (field, cloudType = CLOUD_TYPE_IDS.AWS) => {
  let label = `${LabelCoordinator.getFieldLabelByCloudType('tag', cloudType)} - ${field.split('customtags:')[1]}`;
  label = field.includes('virtual') ? `Virtual Tag - ${field.split(':')[1]}` : label;
  label = field.includes('views') ? `View - ${field.split(':')[1]}` : label;
  return label;
};

export const getPayerLinkedAccount = ({ account, currDispUserCloudAccountType }) => {
  if (account && currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
    const linkedAccountId = account.accountId;
    const linkedAccountName = account.accountName.split('(')[0];
    return { linkedAccountId, linkedAccountName };
  }
  return null;
};

export const prepareNewRecipients = ({ email = '', recipients = [] }) => {
  // convert email to channel recipients and merge with existing recipients
  const emailRecipients = email
    ? email.split(',').map((email) => ({
        dstId: email.trim(),
        recipientData: {
          dstId: email.trim(),
          type: 'SENDGRID',
        },
      }))
    : [];
  return recipients?.filter((r) => r.recipientData.type !== 'SENDGRID').concat(emailRecipients);
};

export const getEmailFromRecipients = (recipients) =>
  recipients
    ?.filter((recipient) => recipient.recipientData.type === 'SENDGRID')
    .map(({ dstId }) => dstId)
    .join(', ');

export const validateRecipientsEmails = (emailsStr) => {
  const emails = emailsStr
    .split(',')
    .map((currentEmail) => currentEmail.trim())
    .filter((currentEmail) => currentEmail);
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emails.every((email) => re.test(String(email).toLowerCase()));
};

export const validateRecipients = (emails = '', recipients = []) => {
  const nonEmailRecipients = recipients.filter((r) => r.recipientData.type !== 'SENDGRID');
  return emails ? validateRecipientsEmails(emails) : !!nonEmailRecipients.length;
};
