/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { TextField } from '@mui/material';
import Spinner from 'shared/components/andtComponents/Spinner';
import Button from 'shared/components/andtComponents/Button';
import {
  alertStatusToActionButtonsArray,
  CAU_ALERTS_STATUS,
  CAU_ALERTS_TYPES,
  CAU_ALERTS_GRAN_LEVELS,
  costChangeAlertChangeTypesToDisplayLabels,
  alertsGranLevelDisplayedLabels,
  cueAlertAndOrToDisplayLabels,
  weekDaysDisplayedLabels,
} from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { OPERATORS, OPERATORS_KEYS } from 'shared/constants/appConstants';
import CustomSelect from 'shared/components/Select';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import MultiTypeFieldFilterSelector from './MultiTypeFieldFilterSelector';
import {
  getActionButtonIcon,
  createOptipons,
  prepareOptionWithSelectAll,
  validateRecipientsEmails,
  getDefaultFilterState,
  getDefaultComparingValues,
  getDefaultWhenValues,
  getDefaultValidationStatus,
  validateFilters,
  validateGranularity,
  validateWhenValues,
  getGranularityFieldWith,
  getDayInWeekFieldWith,
  getActionButtonHandler,
} from '../helpers/cauAlertsHelper';

const usageTypeOperators = Object.keys(OPERATORS)
  .filter((key) => ![OPERATORS_KEYS.STARTS_WITH, OPERATORS_KEYS.ENDS_WITH].includes(key))
  .map((op) => ({
    label: OPERATORS[op],
    value: op,
  }));

const propTypes = {
  usageStore: PropTypes.object.isRequired,
  alertId: PropTypes.string.isRequired,
  currentCloudType: PropTypes.number.isRequired,
  setIsInitialDataFetch: PropTypes.func.isRequired,
  alert: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

const defaultProps = {
  alert: null,
};

const CostChangesAlertNew = ({
  isLoading,
  setIsLoading,
  usageStore,
  alertId,
  alert,
  currentCloudType,
  setIsInitialDataFetch,
}) => {
  const [alertStatus, setAlertStatus] = useState(alert ? alert.alertParams.alertStatus : CAU_ALERTS_STATUS.ADD);
  const [filters, setFilters] = useState(getDefaultFilterState(alert, currentCloudType));
  const [comparingValues, setComparingValues] = useState(getDefaultComparingValues(alert));
  const [whenValues, setWhenValues] = useState(getDefaultWhenValues(alert));
  const [recipientsEmails, setRecipientsEmails] = useState((alert && alert.recipientsEmails) || '');
  const [validationStatus, setValidationStatus] = useState(getDefaultValidationStatus(currentCloudType));
  const [isDeleteAlertWarning, setIsDeleteAlertWarning] = useState(false);
  const alertType = useRef(alert ? alert.alertParams.alertType : CAU_ALERTS_TYPES.COST_CHANGES);
  const { currencySymbol } = useUserSettingsContext();
  const invalidateStates = () => {
    setAlertStatus(alert ? alert.alertParams.alertStatus : CAU_ALERTS_STATUS.ADD);
    setFilters(getDefaultFilterState(alert, currentCloudType));
    setComparingValues(getDefaultComparingValues(alert));
    setWhenValues(getDefaultWhenValues(alert));
    setRecipientsEmails((alert && alert.recipientsEmails) || '');
    setValidationStatus(getDefaultValidationStatus(currentCloudType));
  };

  const validateAlert = () => {
    const filtersValidation = validateFilters(filters);
    const granularityValidation = validateGranularity(comparingValues);
    const whenValuesValidation = validateWhenValues(whenValues, currencySymbol);
    const emailsValidation = validateRecipientsEmails(recipientsEmails);
    const defaultValidationStatus = getDefaultValidationStatus(currentCloudType);
    const newValidationStatus = Object.entries({
      ...filtersValidation,
      ...granularityValidation,
      ...whenValuesValidation,
      ...emailsValidation,
    }).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          ...defaultValidationStatus[key],
          ...value,
        },
      }),
      {},
    );
    setValidationStatus(newValidationStatus);
    return Object.values(newValidationStatus).every(({ isValid }) => isValid);
  };

  const onFilterChange = (type, value) => {
    const preparedValue = prepareOptionWithSelectAll(value);
    setFilters((prevFilters) => ({ ...prevFilters, [type]: { ...prevFilters[type], value: preparedValue } }));
  };

  const onFilterOperatorChange = (e, type, value) => {
    e.stopPropagation();
    const oldValue = filters[type].operator;
    const likeOperators = [OPERATORS_KEYS.LIKE, OPERATORS_KEYS.NOT_LIKE];
    const isOperators = [OPERATORS_KEYS.IS, OPERATORS_KEYS.IS_NOT];

    const isOperatorGroupChanged = !(
      [value, oldValue].every((v) => likeOperators.includes(v)) ||
      [value, oldValue].every((v) => isOperators.includes(v))
    );

    let filterValue = filters[type].value;

    // check if "IS NOT ALL" condition
    const isConditionDisabled = value === OPERATORS_KEYS.IS_NOT && filterValue[0] && filterValue[0].value === 'all';

    if (isOperatorGroupChanged || isConditionDisabled) {
      if (likeOperators.includes(value)) {
        filterValue = '';
      } else {
        filterValue = value === OPERATORS_KEYS.IS_NOT ? [] : [{ value: 'all', label: 'Any Usage Type' }];
      }
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: { value: filterValue, operator: value },
    }));
  };

  const onComapringValuesChange = (type, value) => {
    setComparingValues((prevState) => ({
      ...prevState,
      dayInWeek: type === 'granularity' && +prevState.granularity.value !== +value.value ? null : prevState.dayInWeek,
      [type]: value,
    }));
  };

  const onWhenValuesChange = (type, value) => {
    setWhenValues((prevState) => ({ ...prevState, [type]: value }));
  };

  const saveAlertHandler = async (e, id) => {
    const isAlertValid = validateAlert();
    if (!isAlertValid) return;
    setIsLoading(true);
    try {
      const {
        // rootStore: { usersStore },
        saveCauAlert,
      } = usageStore;
      const isSaveAfterEdit = alertStatus === CAU_ALERTS_STATUS.EDIT;
      // segmentEvent({ type: 'click-event', target: 'alerts_save' }, usersStore);
      const newAlertParams = {
        alertType: alertType.current,
        alertStatus: CAU_ALERTS_STATUS.SAVE,
        filters,
        ...comparingValues,
        ...whenValues,
        recipientsEmails,
      };
      await saveCauAlert(alertType.current, newAlertParams, id, isSaveAfterEdit);
      setFilters(getDefaultFilterState(currentCloudType));
      invalidateStates();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      if (alertStatus === CAU_ALERTS_STATUS.EDIT) {
        setAlertStatus(CAU_ALERTS_STATUS.SAVE);
      }
    }
  };

  const deleteAlert = async (action) => {
    if (action === 'cancel') {
      setIsDeleteAlertWarning(false);
    } else if (action === 'delete') {
      if (alertStatus !== CAU_ALERTS_STATUS.SAVE) {
        return;
      }
      try {
        setIsInitialDataFetch(true);
        await usageStore.deleteCauAlert(alertId);
        setIsInitialDataFetch(false);
      } catch (error) {
        setIsInitialDataFetch(false);
      }
    }
  };

  const cancelEditHandler = () => setAlertStatus(CAU_ALERTS_STATUS.SAVE);
  const handleDeleteAlertClicked = () => setIsDeleteAlertWarning(true);
  const handleEditAlertClicked = () => setAlertStatus(CAU_ALERTS_STATUS.EDIT);

  const alertHandlers = useRef({
    saveAlertHandler,
    cancelEditHandler,
    handleDeleteAlertClicked,
    handleEditAlertClicked,
  });

  const renderValidationMessage = (attr) =>
    validationStatus[attr].isValid ? null : (
      <p>
        <span style={{ color: 'red' }}> {validationStatus[attr].errorMessage} </span>
      </p>
    );

  const renderActionButtons = () => {
    const buttons = alertStatusToActionButtonsArray[alertStatus];
    return buttons.map((button, index) => (
      <img
        className={`alerts-buttonplus ${index === 1 ? 'right' : ''}`}
        key={`b-${index}`}
        src={getActionButtonIcon(button)}
        alt="close"
        name={button}
        onClick={(e) => getActionButtonHandler(e, alertId, alertStatus, alertHandlers.current)}
      />
    ));
  };

  const renderUsageTypeFilter = () => {
    const { value, operator } = filters[AwsCommonFields.USAGE_TYPE];
    if ([OPERATORS_KEYS.IS, OPERATORS_KEYS.IS_NOT].includes(operator)) {
      return (
        <MultiTypeFieldFilterSelector
          selectedValues={value}
          handler={onFilterChange}
          type={AwsCommonFields.USAGE_TYPE}
          name={AwsCommonFields.USAGE_TYPE}
          placeHolder="Select usage type"
          addAllOption={operator === OPERATORS_KEYS.IS_NOT ? false : 'Any Usage Type'}
          style={{ minWidth: '160px' }}
        />
      );
    }
    return (
      <div className="flex-grow-1">
        <TextField
          fullWidth
          name={AwsCommonFields.USAGE_TYPE}
          onChange={(e) => onFilterChange(AwsCommonFields.USAGE_TYPE, e.target.value)}
          value={value}
          type="text"
          size="small"
        />
      </div>
    );
  };

  const renderOperationFilter = () => {
    const { value = 'all', operator = OPERATORS_KEYS.IS } = filters[AwsCommonFields.OPERATION] || {};
    if ([OPERATORS_KEYS.IS, OPERATORS_KEYS.IS_NOT].includes(operator)) {
      return (
        <MultiTypeFieldFilterSelector
          selectedValues={value}
          handler={onFilterChange}
          type={AwsCommonFields.OPERATION}
          name={AwsCommonFields.OPERATION}
          placeHolder="Select operation"
          addAllOption={operator === OPERATORS_KEYS.IS_NOT ? false : 'Any Operation'}
          style={{ minWidth: '160px' }}
        />
      );
    }
    return (
      <div className="flex-grow-1">
        <TextField
          fullWidth
          name={AwsCommonFields.OPERATION}
          onChange={(e) => onFilterChange(AwsCommonFields.OPERATION, e.target.value)}
          value={value}
          type="text"
          size="small"
        />
      </div>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container className="alerts-cost-changes-container">
      <div>
        <div className="alerts-buttons-wrapper">{renderActionButtons()}</div>
      </div>
      <div className={`alerts-cost-changes-inputs-wrapper ${alertStatus === CAU_ALERTS_STATUS.SAVE ? 'saved' : ''}`}>
        <div className="alerts-cost-changes-firts-row">
          <div className="alerts-inner-connecting-text">for</div>
          <div className="alerts-inner-filters">
            <MultiTypeFieldFilterSelector
              selectedValues={filters[AwsCommonFields.LINKED_ACCOUNT_NAME].value}
              handler={onFilterChange}
              type={AwsCommonFields.LINKED_ACCOUNT_NAME}
              name={AwsCommonFields.LINKED_ACCOUNT_NAME}
              valueParam="linkedAccountId"
              labelParam="displayLabel"
              placeHolder={`Select ${currentCloudType === CLOUD_TYPE_IDS.GCP ? 'projects' : 'linked accounts'}`}
              addAllOption={LabelCoordinator.getFieldLabelByCloudType('AlertsAllAccounts', currentCloudType)}
            />
            {renderValidationMessage(AwsCommonFields.LINKED_ACCOUNT_NAME)}
          </div>
          <div className="alerts-inner-connecting-text">for</div>
          <div className="alerts-inner-filters">
            <MultiTypeFieldFilterSelector
              selectedValues={filters[AwsCommonFields.SERVICE].value}
              handler={onFilterChange}
              type={AwsCommonFields.SERVICE}
              name={AwsCommonFields.SERVICE}
              placeHolder="Select service"
              addAllOption="Any Service"
            />
            {renderValidationMessage(AwsCommonFields.SERVICE)}
          </div>
          <div className="alerts-inner-connecting-text">for</div>
          <div className="alerts-inner-filters">
            <div className="d-flex">
              <div style={{ minWidth: 140 }} className="d-flex me-2 align-items-center">
                <CustomSelect
                  value={
                    OPERATORS_KEYS[
                      filters[
                        currentCloudType === CLOUD_TYPE_IDS.GCP ? AwsCommonFields.OPERATION : AwsCommonFields.USAGE_TYPE
                      ]?.operator
                    ] || OPERATORS_KEYS.IS
                  }
                  label="Operator"
                  onChange={(e) =>
                    onFilterOperatorChange(
                      e,
                      currentCloudType === CLOUD_TYPE_IDS.GCP ? AwsCommonFields.OPERATION : AwsCommonFields.USAGE_TYPE,
                      e.target.value,
                    )
                  }
                  options={usageTypeOperators}
                />
              </div>
              {currentCloudType === CLOUD_TYPE_IDS.GCP ? renderOperationFilter() : renderUsageTypeFilter()}
            </div>
            {renderValidationMessage(
              currentCloudType === CLOUD_TYPE_IDS.GCP ? AwsCommonFields.OPERATION : AwsCommonFields.USAGE_TYPE,
            )}
          </div>
        </div>
        <div className="alerts-cost-changes-second-row-container">
          <div className="alerts-cost-changes-second-row mb-2">
            <div className="alerts-inner-connecting-text">Comparing:</div>
            <div className={`granularity ${getGranularityFieldWith(comparingValues.granularity)}`}>
              <div>
                <OneChoiceFieldFilter
                  type="granularity"
                  name="granularity"
                  value={comparingValues.granularity}
                  options={createOptipons(alertsGranLevelDisplayedLabels)}
                  handleChange={onComapringValuesChange}
                  isClearable={false}
                />
              </div>
            </div>
            {+comparingValues.granularity.value === CAU_ALERTS_GRAN_LEVELS.DAY_IN_WEEK ? (
              <>
                <div className="alerts-inner-connecting-text">being</div>
                <div className={`day-in-week-width ${getDayInWeekFieldWith(comparingValues.dayInWeek || {})}`}>
                  <div>
                    <OneChoiceFieldFilter
                      type="dayInWeek"
                      name="dayInWeek"
                      value={comparingValues.dayInWeek}
                      options={createOptipons(weekDaysDisplayedLabels)}
                      handleChange={onComapringValuesChange}
                      isClearable={false}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className="d-flex flex-row align-items-center pt-2 w-100">
              <div className="alerts-inner-connecting-text">When</div>
              <div className="alerts-inner-filters">
                <div>
                  <OneChoiceFieldFilter
                    type="changeType"
                    name="changeType"
                    value={whenValues.changeType}
                    options={createOptipons(costChangeAlertChangeTypesToDisplayLabels)}
                    handleChange={onWhenValuesChange}
                    isClearable={false}
                  />
                </div>
              </div>
              <div className="alerts-inner-connecting-text">by</div>
              <div className="alerts-inner-text-input-small">
                <TextField
                  name="changePercent"
                  onChange={(e) => onWhenValuesChange('changePercent', e.target.value)}
                  value={whenValues.changePercent}
                  type="number"
                  size="small"
                />
              </div>
              <div className="alerts-inner-connecting-text">%</div>
              <div className="alerts-inner-filters">
                <OneChoiceFieldFilter
                  type="operatorType"
                  name="operatorType"
                  value={whenValues.operatorType}
                  options={createOptipons(cueAlertAndOrToDisplayLabels)}
                  handleChange={onWhenValuesChange}
                  isClearable={false}
                />
              </div>
              <div className="alerts-inner-connecting-text">for</div>
              <div className="alerts-inner-connecting-text">{currencySymbol}</div>
              <div className="alerts-inner-text-input-meduim">
                <TextField
                  size="small"
                  name="changeValue"
                  onChange={(e) => onWhenValuesChange('changeValue', e.target.value)}
                  value={whenValues.changeValue}
                  type="number"
                />
              </div>
              <div className="alerts-inner-connecting-text ms-1 me-3 margin-top-comparing-dot">.</div>
            </div>
          </div>
          <div className="d-flex flex-row grow-1 w-100 align-items-center">
            <div className="recipients-container">
              <div className="alerts-inner-connecting-text">Recipients:</div>
              <div className="alerts-inner-text-input-large flex-grow-1">
                <TextField
                  name="recipientsEmails"
                  onChange={(e) => setRecipientsEmails(e.target.value)}
                  value={recipientsEmails}
                  fullWidth
                  size="small"
                  placeholder="email@example.com, email@ex..."
                />
                {renderValidationMessage('recipientsEmails')}
              </div>
            </div>
            <div className="alerts-inner-connecting-text  ms-auto">
              <Button
                name="add"
                // color="primary"
                // size="sm"
                onClick={(e) => saveAlertHandler(e, alertId)}
                text="Save"
                type="button"
              />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            {renderValidationMessage('changeValueAndPercent')}
            {renderValidationMessage('granularity')}
            {renderValidationMessage('dayInWeek')}
          </div>
        </div>
      </div>
      <DeleteWarningModal
        deletedItemName="Cost change alert"
        isOpen={isDeleteAlertWarning}
        handleDelete={deleteAlert}
        warningMessage="Be advise you are about to delete a"
        modalTitle="Delete Alert"
      />
    </Container>
  );
};

CostChangesAlertNew.propTypes = propTypes;
CostChangesAlertNew.defaultProps = defaultProps;
export default CostChangesAlertNew;
