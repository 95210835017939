import { cloneDeep } from 'lodash';
import { FilterTypes } from 'usage/constants/usageConstants';

export const formatExcludeFilterMap = (excludedFiltersStatusMap, field, subField) => {
  const newMap = cloneDeep(excludedFiltersStatusMap);
  if (subField) {
    const fieldInMap = newMap.get(field);
    if (!fieldInMap) {
      newMap.set(field, { [subField]: FilterTypes.EXCLUDE });
    } else {
      newMap.set(field, {
        ...newMap.get(field),
        [subField]: newMap.get(field)[subField] ? null : FilterTypes.EXCLUDE,
      });
    }
  } else if (newMap.has(field)) {
    newMap.delete(field);
  } else {
    newMap.set(field, FilterTypes.EXCLUDE);
  }
  return newMap;
};
