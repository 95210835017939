/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Legend, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, Label, Text } from 'recharts';
import { StringToColor } from 'shared/utils/graphicUtil';
import { percentStrNoCommas } from 'shared/utils/strUtil';

// const style = {
//   // left: 0,
//   // width: 150,
//   // lineHeight: '24px',
//   // left: 0,
//   // marginTop: '-5px',
//   lineHeight: '16px',
//   // position: 'relative',
//   // top: '-10px',
// };

const renderLegend = ({ payload }) => (
  // <ul className="dashboard__chart-legend">
  <ul style={{}}>
    {payload.map((entry, index) => (
      <li key={`item-${index}`}>
        <span style={{ backgroundColor: `${entry.color}` }} />
        {entry.value}
      </li>
    ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      vslue: PropTypes.string,
    }),
  ).isRequired,
};

const prepareTopPanelPieData = (data) => {
  let keys = [];
  data.forEach((item) => {
    keys.push(...Object.keys(item));
  });
  keys = [...new Set(keys)];
  const aggDataObj = {};
  for (const item of data) {
    for (const key of keys) {
      if (key in item) {
        aggDataObj[key] = key in aggDataObj ? aggDataObj[key] + item[key] : item[key];
      }
    }
  }
  const prepData = Object.keys(aggDataObj).map((key) => ({ value: aggDataObj[key], name: key }));
  return prepData;
};

const FillPieChart = memo((props) => (
  <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={props.height}>
    <PieChart className="dashboard__chart-pie-container">
      <Pie
        data={prepareTopPanelPieData(props.data)}
        dataKey="value"
        nameKey="name"
        cy={140}
        // innerRadius="60%"
        outerRadius="85%"
      >
        {prepareTopPanelPieData(props.data).map((entry, index) => (
          <Cell fill={props.colorFunc ? props.colorFunc(entry.name) : StringToColor.next(entry.name)} />
        ))}
      </Pie>
      <Tooltip formatter={(value) => `${percentStrNoCommas(value)}`} />
      <Text x={400} y={200} textAnchor="middle" dominantBaseline="middle">
        Donut
      </Text>
      {props.showLegend && <Legend />}
    </PieChart>
  </ResponsiveContainer>
));

FillPieChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.object.isRequired,
  colorFunc: PropTypes.func.isRequired,
  showLegend: PropTypes.bool,
};
FillPieChart.defaultProps = {
  showLegend: false,
};

export default FillPieChart;
