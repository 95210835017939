import { cloneDeep } from 'lodash';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import ICONS from 'shared/constants/assetsConstants';
import {
  CAU_ALERTS_STATUS,
  CAU_ALERTS_GRAN_LEVELS,
  CAU_COST_CHANGE_ALERTS_CHANGE_TYPE,
  CAU_ALERTS_OPERATOR_TYPE_VALUES,
  CAU_ALERTS_WEEK_DAYS,
  alertsGranLevelDisplayedLabels,
  costChangeAlertChangeTypesToDisplayLabels,
  cueAlertAndOrToDisplayLabels,
} from 'usage/constants/usageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AwsCommonFields, awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import { OPERATORS_KEYS } from 'shared/constants/appConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';

export const getGranularityFieldWith = (granularity = {}) => {
  switch (+granularity.value) {
    case CAU_ALERTS_GRAN_LEVELS.WEEKLY:
      return 'weekly';
    case CAU_ALERTS_GRAN_LEVELS.MONTHLY:
      return 'monthly';
    case CAU_ALERTS_GRAN_LEVELS.DAY_IN_WEEK:
      return 'day-in-week';
    default:
      return 'daily';
  }
};
export const getDayInWeekFieldWith = (dayInWeek = {}) => {
  switch (+dayInWeek.value) {
    case CAU_ALERTS_WEEK_DAYS.WEDNESDAY:
      return 'wednesday';
    case CAU_ALERTS_GRAN_LEVELS.MONTHLY:
      return 'thursday';
    default:
      return '';
  }
};

export const validateFilters = (filters) =>
  Object.entries(filters).reduce((acc, [key, { value, operator }]) => {
    if ([OPERATORS_KEYS.LIKE, OPERATORS_KEYS.NOT_LIKE].includes(operator)) {
      return {
        ...acc,
        [key]: {
          isValid: !!value.length,
          errorMessage: `You must fill the ${awsCommonFieldToDisplayField.get(key) || key} field`,
        },
      };
    }
    return {
      ...acc,
      [key]: {
        isValid: !isEmptyArray(value),
        errorMessage: `You must select at least one ${awsCommonFieldToDisplayField.get(key) || key}`,
      },
    };
  }, {});
export const validateGranularity = ({ granularity = {}, dayInWeek }) => ({
  granularity: { isValid: !!granularity.value },
  dayInWeek: { isValid: !!(+granularity.value !== +CAU_ALERTS_GRAN_LEVELS.DAY_IN_WEEK || dayInWeek) },
});

export const validateWhenValues = (whenValues, currencySymbol) => {
  const { operatorType, changePercent, changeValue } = whenValues;
  const isOperatorTypeAnd = operatorType.value === CAU_ALERTS_OPERATOR_TYPE_VALUES.AND;
  const isValid = isOperatorTypeAnd ? !!(changePercent && changeValue) : !!(changePercent || changeValue);
  let errorMessage = '';
  if (!isValid) {
    errorMessage = isOperatorTypeAnd
      ? `You must provide both % and ${currencySymbol} values`
      : `You must provide either % or ${currencySymbol} value`;
  }
  return {
    changeValueAndPercent: {
      isValid,
      errorMessage,
    },
  };
};

export const getDefaultFilterState = (alert, currentCloudType) => {
  const { alertParams = {} } = alert || {};
  if (alertParams && alertParams.filters) {
    return alertParams.filters;
  }
  return {
    [AwsCommonFields.LINKED_ACCOUNT_NAME]: {
      value: [
        {
          value: 'all',
          label: LabelCoordinator.getFieldLabelByCloudType('AlertsAllAccounts', currentCloudType),
        },
      ],
      operator: OPERATORS_KEYS.IS,
    },
    [AwsCommonFields.SERVICE]: {
      value: [{ value: 'all', label: 'Any Service' }],
      operator: OPERATORS_KEYS.IS,
    },
    [AwsCommonFields.USAGE_TYPE]: {
      value: [{ value: 'all', label: 'Any Usage Type' }],
      operator: OPERATORS_KEYS.IS,
    },
    [AwsCommonFields.OPERATION]: {
      value: [{ value: 'all', label: 'Any Operation' }],
      operator: OPERATORS_KEYS.IS,
    },
  };
};

export const getDefaultComparingValues = (alert) => {
  const { alertParams = {} } = alert || {};
  const { granularity = {}, dayInWeek } = alertParams;
  return {
    granularity: {
      value: `${granularity.value || CAU_ALERTS_GRAN_LEVELS.DAILY}`,
      label: granularity.label || alertsGranLevelDisplayedLabels[CAU_ALERTS_GRAN_LEVELS.DAILY],
    },
    dayInWeek: dayInWeek || null,
  };
};

export const getDefaultWhenValues = (alert) => {
  const { alertParams = {} } = alert || {};
  const { changeType, operatorType, changePercent, changeValue } = alertParams;
  return {
    changeType: changeType || {
      value: CAU_COST_CHANGE_ALERTS_CHANGE_TYPE.CHANGE,
      label: costChangeAlertChangeTypesToDisplayLabels[CAU_COST_CHANGE_ALERTS_CHANGE_TYPE.CHANGE],
    },
    operatorType: operatorType || {
      value: CAU_ALERTS_OPERATOR_TYPE_VALUES.AND,
      label: cueAlertAndOrToDisplayLabels[CAU_ALERTS_OPERATOR_TYPE_VALUES.AND],
    }, // can be "and" or "or"
    changePercent: changePercent || '',
    changeValue: changeValue || '',
  };
};

export const getDefaultValidationStatus = (currentCloudType) => ({
  isValid: false,
  [AwsCommonFields.LINKED_ACCOUNT_NAME]: {
    isValid: true,
    errorMessage: `You must select at least one ${
      currentCloudType === CLOUD_TYPE_IDS.GCP ? 'project' : 'linked account'
    }`,
  },
  [AwsCommonFields.SERVICE]: { isValid: true, errorMessage: 'You must select at least one service' },
  [AwsCommonFields.USAGE_TYPE]: { isValid: true, errorMessage: 'You must select at least one usage type' },
  [AwsCommonFields.OPERATION]: { isValid: true, errorMessage: 'You must select at least one operation' },
  changePercent: { isValid: true },
  changeValue: { isValid: true },
  changeValueAndPercent: { isValid: true, errorMessage: '' },
  recipientsEmails: { isValid: true, errorMessage: 'Recipients mail is not valid' },
  granularity: { isValid: true, errorMessage: 'You must select granularity' },
  dayInWeek: { isValid: true, errorMessage: 'You must select day in week' },
});

export const prepareOptionWithSelectAll = (selectedOptions) => {
  if (isEmptyArray(selectedOptions)) {
    return selectedOptions;
  }
  const copySelectedOptions = cloneDeep(selectedOptions);
  const allOptionIndex = copySelectedOptions.findIndex(({ value }) => value === 'all');
  if (allOptionIndex > -1) {
    // check if there is all and it's location
    if (allOptionIndex === copySelectedOptions.length - 1) {
      // all was selected
      return copySelectedOptions.splice(allOptionIndex, 1);
    }
    copySelectedOptions.splice(allOptionIndex, 1);
  }
  return copySelectedOptions;
};

export const getAlertHandlers = (alertStatus, handlers) => {
  const actionHandlers = {
    [CAU_ALERTS_STATUS.ADD]: { add: handlers.saveAlertHandler },
    [CAU_ALERTS_STATUS.REMOVE]: { cancel: handlers.removeAlertHandler },
    [CAU_ALERTS_STATUS.EDIT]: {
      add: handlers.saveAlertHandler,
      cancel: handlers.cancelEditHandler,
    },
    [CAU_ALERTS_STATUS.SAVE]: {
      delete: handlers.handleDeleteAlertClicked,
      edit: handlers.handleEditAlertClicked,
    },
  };
  return actionHandlers[alertStatus];
};

export const emailIsValid = (email) => {
  const recipientsEmailsArr = email.replace(/ /g, '').split(',');
  const result = email && recipientsEmailsArr.every((mail) => !mail || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail));
  return !!result;
};

export const prepareAlertDataToState = (alert) => {
  const alertParams = alert ? alert.alertParams : null;
  const { alertStatus, alertType, granularity, dayInWeek, ...others } = alertParams || {};
  const selectedFilterValues = { ...others };
  return { alertStatus, alertType, selectedFilterValues, granularity, dayInWeek };
};

export const getActionButtonIcon = (buttName) => {
  switch (buttName) {
    case 'add':
      return ICONS.DiskSaveIcon;
    case 'delete':
      return ICONS.buttonX;
    case 'cancel':
      return ICONS.buttonX;
    case 'edit':
      return ICONS.squareEdit;
    default:
      return ICONS.plus;
  }
};

export const getActionButtonHandler = (e, id, alertStatus, alertHandlers) => {
  const handlers = getAlertHandlers(alertStatus, alertHandlers);
  const actionName = e.target.name;
  if (!actionName) return;
  const handler = handlers[actionName];
  handler(e, id);
};

export const createOptipons = (typesToDisplayObj) => {
  let options = {};
  try {
    options = Object.entries(typesToDisplayObj).map(([k, v]) => ({ value: k, label: v }));
  } catch (error) {
    return options;
  }
  return options;
};

export const validateRecipientsEmails = (emails) => {
  const recipientsEmailsArr = emails.replace(/ /g, '').split(',');
  const result = emails && recipientsEmailsArr.every((mail) => !mail || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail));
  return { recipientsEmails: { isValid: !!result } };
};
