import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRecommendationBuilderContext } from 'recommendations/shared/components/RecommendationBuilderContext';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import InformationOutline from 'mdi-react/InformationOutlineIcon';

import classes from './recommendationOption.module.scss';

const POTENTIAL_SAVING = 'Potential saving';

const RecommendationOptionButton = ({
  option,
  recommendationData,
  isRecommended,
  isCurrent,
  optionNumber,
  current,
}) => {
  const contextData = useRecommendationBuilderContext();
  const { getCurrencyNumber } = useUserSettingsContext();
  const [title, setTitle] = useState('');
  const isSelected = contextData.selectedOption === optionNumber;

  useEffect(() => {
    let tmpTitle = `OPTION ${optionNumber + 1}`;
    if (isCurrent) {
      tmpTitle = 'CURRENT';
    } else if (isRecommended) {
      tmpTitle = `${tmpTitle} (RECOMMENDED)`;
    }
    setTitle(tmpTitle);
  }, [option]);

  const optionSelectionChanged = () => {
    if (!isCurrent && contextData?.onOptionSelected) {
      return contextData.onOptionSelected(optionNumber);
    }
    return undefined;
  };

  const recommendationOptionData = useMemo(() => {
    const tmp = [
      ...recommendationData,
      { propertyTitle: 'Annual cost', property: 'annualCost', displayCurrency: true },
    ];
    if (!isCurrent) {
      tmp.push({ propertyTitle: `${POTENTIAL_SAVING} (%)`, property: 'potentialSavings' });
    }
    return tmp;
  }, [recommendationData, option]);

  const getPropertyValue = (property, displayCurrency) => {
    let propertyValue = '';
    const value = option[property];

    if (value && displayCurrency) {
      propertyValue = getCurrencyNumber(propertyValue);
    }

    if (typeof value === 'number') {
      const decimal = value.value % 1 > 0 ? 2 : 0;
      propertyValue += value.toFixed(decimal);
    } else if (value !== undefined) {
      if (typeof value === 'boolean' || value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
        propertyValue = value ? 'Yes' : 'No';
      } else {
        propertyValue += value;
      }
    }

    if (property === 'potentialSavings') {
      propertyValue += '%';
    }

    return propertyValue;
  };

  const isPropertyDifferentFromCurrent = (property) => {
    if (isCurrent || !current || property === 'annualCost' || property === 'potentialSavings') {
      return false;
    }
    return option[property] !== current[property];
  };

  return (
    <div className={classes.optionContainer}>
      <div
        className={`${classes.option}${isCurrent ? '' : ` ${classes.notCurrent}`}${
          isSelected ? ` ${classes.selected}` : ''
        }`}
        onClick={optionSelectionChanged}
      >
        <div className={`${classes.titleContainer}${isRecommended ? ` ${classes.recommended}` : ''}`}>
          <div className={classes.title}>{title}</div>
          {isCurrent ? (
            <div />
          ) : (
            <Tooltip placement="top" title={POTENTIAL_SAVING} arrow>
              <div className={classes.savingAmount}>
                {option?.savingAmount ? getCurrencyNumber(option?.savingAmount) : ''}
              </div>
            </Tooltip>
          )}
        </div>
        <div className={classes.dataContainer}>
          {recommendationOptionData?.map((data) => {
            if ((data.showInCurrent && !isCurrent) || (data.showInAlternative && isCurrent)) {
              return null;
            }
            return (
              <div className={classes.dataPair}>
                <div className={classes.dataPairTitle}>
                  {data.propertyTitle} :
                  {data.info ? (
                    <div>
                      <Tooltip title={data.info} arrow placement="top">
                        <div>
                          <InformationOutline size={18} />
                        </div>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
                <div
                  className={`${classes.dataValue} ${
                    isPropertyDifferentFromCurrent(data.property) ? classes.differentFromCurrent : ''
                  }`}
                >
                  {getPropertyValue(data.property, data.displayCurrency)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isSelected && <div className={classes.arrowDown} />}
    </div>
  );
};

RecommendationOptionButton.propTypes = {
  option: PropTypes.object.isRequired,
  recommendationData: PropTypes.array.isRequired,
  isRecommended: PropTypes.bool,
  isCurrent: PropTypes.bool,
  optionNumber: PropTypes.number,
  current: PropTypes.object,
};

RecommendationOptionButton.defaultProps = {
  isRecommended: false,
  isCurrent: false,
  optionNumber: undefined,
  current: undefined,
};

export default RecommendationOptionButton;
