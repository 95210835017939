import React from 'react';
import PropTypes from 'prop-types';
import styles from './errorMessage.module.scss';

const propTypes = {
  errMsg: PropTypes.string,
  displayError: PropTypes.bool,
  automationId: PropTypes.string,
};

const defaultProps = {
  displayError: false,
  errMsg: '',
  automationId: '',
};

const ErrorMessage = ({ displayError, errMsg, automationId }) => {
  if (!displayError) {
    return null;
  }
  return (
    <span automation-id={automationId} className={styles.errorMsg}>
      {errMsg}
    </span>
  );
};

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;
export default ErrorMessage;
