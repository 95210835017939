import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { OUTDATED_SNAPSHOT_PROPERTIES } from '../../recommendationPropertyUtils';

const AmiOrphanedSnapshots = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `aws ec2 delete-snapshot --snapshot-id ${recommendation?.resourceId}`,
        },
      ],
    },
  ];

  const description = `We have identified orphaned EBS snapshots created for 
    AMIs that are no longer in use, an "orphaned AMI"
    means that the AMI itself has been deleted, 
    but its associated snapshots still remain incurring ongoing storage
    costs without serving any active purpose. This AMI snapshot age is
    ${recommendation?.recData?.elapsed_time_since_creation},
    to avoid unnecessary costs, we recommend deleting the snapshot after 21 days.`;

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={OUTDATED_SNAPSHOT_PROPERTIES}
    />
  );
};

AmiOrphanedSnapshots.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AmiOrphanedSnapshots;
