/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RdsRiRecommendation } from 'recommendations/models/recommTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';
import { calcNumOfMonthsToBreakEven } from 'shared/utils/calcUtil';
import CostRecommendationBuilder from './costRecommBuilder';

export default class ElasticacheRiRecommBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RdsRiRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.ELASTICACHE_RI;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Elastic Cache Reserved Instance`;
  }

  build() {
    super.build();
    const {
      quantity: numOfRecommRIs,
      recommended_plan: riType,
      recommended_instance_size: recommendedSize,
      instance_type_size: size,
      yearly_cost_current: currYearlyCost,
      yearly_cost_recommended: recommYearlyCost,
      total_yearly_cost_recommended: totalRecommYearlyCost,
      total_yearly_cost_current: totalCostCurrent,
      monthly_cost_recommended: recommMonthlyCost,
      monthly_cost_current: currMonthlyCost,
      // eslint-disable-next-line no-unused-vars
      total_monthly_cost_recommended: recommTotalCost,
      recommended_plan: recommPlan,
      recommended_action: action,
      zone_tag: tagZone,
      additional_recommended_plans: additionalRecommPlans,
      potential_savings: potentialSavings,
      running_hours_needed: runningHoursNeeded,
      total_hours: totalRunningHours,
      engine_type: engineType,
    } = this._rawRecommendation;

    this._recommendation.numOfRecommRIs = numOfRecommRIs;
    this._recommendation.riType = riType;
    this._recommendation.recommendedSize = recommendedSize;
    this._recommendation.size = size;

    const yearlyRecommCost = new RecommendationCost(currYearlyCost, totalRecommYearlyCost);
    this._recommendation.breakEvenMonth = calcNumOfMonthsToBreakEven(recommYearlyCost, currYearlyCost);
    this._recommendation.region = tagZone;
    this._recommendation.yearlyCost = yearlyRecommCost;
    this._recommendation.recommMonthlyCost = parseFloat(recommMonthlyCost);
    this._recommendation.recommTotalCost = parseFloat(totalRecommYearlyCost);
    this._recommendation.currMonthlyCost = parseFloat(currMonthlyCost);
    this._recommendation.currTotalCost = parseFloat(totalCostCurrent);
    this._recommendation.recommPlan = recommPlan;
    this._recommendation.action = action;
    this._recommendation.additionalRecommPlans = additionalRecommPlans;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.runningHoursNeeded = runningHoursNeeded;
    this._recommendation.totalRunningHours = totalRunningHours;
    this._recommendation.engineType = engineType;
  }
}
