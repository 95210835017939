import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LensFlare } from 'shared/img/images/lens-flare.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import moment from 'moment';
import { toast } from 'react-toastify';
import Button from 'shared/components/andtComponents/Button';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as AnobotFigure } from '../assets/anobot.svg';
import { GptModal } from './GptModal';
import styles from './sidebar.module.scss';

const renderHistory = ({ history, selectHistory, setQuestionToDeleteId, answer }) => (
  <ol className={styles.history}>
    {history?.map((row) => (
      <li key={row.requestId} className={row.requestId === answer?.requestId && styles.selected}>
        <Tooltip title={row.question} placement="right" enterDelay="300" enterNextDelay="300">
          <button
            onClick={() => {
              selectHistory(row);
            }}
            className={styles.historyQuestionButton}
            type="button"
          >
            <span>
              {moment.unix(row.timestamp).format('MMM DD YYYY \xa0 HH:mm')}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(row.question);
                  toast.success('Question copied to clipboard', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }}
                className={styles.copyQuestionButton}
                type="button"
              >
                |<GenerateIcon iconName={ICONS.clone.name} />|
              </button>
              <button
                onClick={() => setQuestionToDeleteId(row.requestId)}
                className={styles.deleteQuestionButton}
                type="button"
              >
                <GenerateIcon iconName={ICONS.deleteRegular.name} />
              </button>
            </span>
            <span>{row.question}</span>
          </button>
        </Tooltip>
      </li>
    ))}
  </ol>
);

export const Sidebar = ({ answer, history, availableData, resetQuestion, selectHistory, deleteQuestion }) => {
  const [isCapabilitiesModalOpen, setIsCapabilitiesModalOpen] = useState(false);
  const [questionToDeleteId, setQuestionToDeleteId] = useState(null);

  return (
    <aside className={styles.sidebar} automation-id="gptSidebar">
      <LensFlare />
      <div className={styles.sidebarHeading}>
        <figure>
          <AnobotFigure />
        </figure>
        <div>
          <h2>
            Cost<span>GPT</span>
          </h2>
          <h3>Your Cost Companion</h3>
        </div>
      </div>
      {answer || history?.length ? (
        <>
          <Button
            text="New Question"
            onClick={resetQuestion}
            icon={() => <GenerateIcon iconName={ICONS.circlePlus.name} />}
            automationId="gptNewQuestionButton"
          />
          {renderHistory({ history, selectHistory, setQuestionToDeleteId, answer })}
        </>
      ) : (
        <p className={styles.about}>
          <span>Introducing CostGPT, your trusted cloud optimization companion!</span>
          <span>With CostGPT, you can receive personalized data analysis and visualizations in real-time.</span>
          <span>
            Simply chat with CostGPT and watch as it instantly analyzes and generates insights and recommendations to
            optimize your cloud spend.
          </span>
          <span>
            You can ask questions like &quot;Which services have the largest month-to-month increase?&quot; Or
            &quot;What percentage of my EBS storage is gp3?&quot;
          </span>
          <span>Monitoring your cloud costs has never been easier!</span>
        </p>
      )}
      <footer className={styles.footer}>
        <button onClick={() => setIsCapabilitiesModalOpen(true)} type="button">
          <GenerateIcon iconName={ICONS.circleInfo.name} />
          Capabilities
        </button>
        <a href="https://cloudcost.anodot.com/hc/en-us/articles/9568761938076" target="_blank" rel="noreferrer">
          <GenerateIcon iconName={ICONS.graduationCap.name} />
          Learn More
        </a>
      </footer>
      {isCapabilitiesModalOpen && (
        <GptModal
          title="Capabilities"
          type="info"
          content={<p>{availableData?.availableDataHint.trim()}</p>}
          onClose={() => setIsCapabilitiesModalOpen(false)}
        />
      )}
      {questionToDeleteId && (
        <GptModal
          type="warning"
          content={<h4>Are you sure you want to delete this thread?</h4>}
          onClose={() => setQuestionToDeleteId(null)}
          onApproval={() => {
            deleteQuestion(questionToDeleteId);
            setQuestionToDeleteId(null);
          }}
        />
      )}
    </aside>
  );
};

Sidebar.propTypes = {
  answer: PropTypes.shape({
    data: PropTypes.array.isRequired,
    images: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    schema: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.array.isRequired,
  availableData: PropTypes.string.isRequired,
  resetQuestion: PropTypes.func.isRequired,
  selectHistory: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
};
