import React from 'react';
import PropTypes from 'prop-types';
import { S3AttentionComment } from 'recommendationsNew/consts';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  OPTIONS_PROPERTIES,
  RDS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';

const S3_VERSIONING_PROPERTIES = {
  BUCKET_SIZE: {
    label: 'Bucket Size',
    isGBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.total_size_gb,
  },
};

const S3Versioning = ({ recommendation }) => {
  const properties = {
    ...BASE_PROPERTIES,
    ...COST_PROPERTIES,
    ...RDS_PROPERTIES,
    ...S3_VERSIONING_PROPERTIES,
  };

  const RECOMMENDATION_OPTION_DATA = [properties.STORAGE_CLASS];

  const CURRENT_PROPERTIES = [...RECOMMENDATION_OPTION_DATA, properties.CURRENT_ANNUAL_COST];

  const ALTERNATIVE_PROPERTIES = [
    ...RECOMMENDATION_OPTION_DATA,
    properties.RECOMMENDED_ANNUAL_COST,
    OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
  ];

  const topThreeAlternatives = recommendation?.recData?.alternatives?.slice(0, 3);

  const optionsPanelComponent =
    Array.isArray(topThreeAlternatives) && topThreeAlternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        recommendation={recommendation}
      />
    ) : null;

  const description =
    // eslint-disable-next-line max-len
    'This S3 bucket is configured with versioning turned on and there are no lifecycle rules to accommodate version overload.\n' +
    'We recommend adding lifecycle rules to address older versions of files in this bucket.';

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const recommendationWithAttentionComment = {
    ...recommendation,
    recData: {
      ...recommendation.recData,
      attention_comment: S3AttentionComment,
    },
  };

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendationWithAttentionComment} />
  );

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: recommendation?.recData?.alternatives[0]?.command || '',
        },
      ],
    },
  ];
  const commandComponent = <RecommendationCommand commandsList={commandsList} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      optionsPanel={optionsPanelComponent}
      details={detailsComponent}
      command={commandComponent}
      preferences={preferencesComponent}
    />
  );
};

S3Versioning.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default S3Versioning;
