import { useState, useEffect } from 'react';

function useDebouncedInput(value, timeout = 1500) {
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
  }, [value, timeout]);

  return [debouncedValue];
}

export default useDebouncedInput;
