import React from 'react';
import PropTypes from 'prop-types';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';
import CodeBlock from '../../../../shared/components/CodeBlock';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using gcloud CLI:</li>
      <CodeBlock
        text="gcloud compute commitments create {{commitmentName}}
          --region {{region}}
          --resources vcpu={{numberVCPUs}},memory={{memory}}
          --plan {{duration}}
          --type {{commitmentType}}"
        variables={{
          commitmentName: 'COMMITMENT_NAME',
          region: rec.region,
          numberVCPUs: rec.vCPUs,
          memory: `${rec.ramSize}GB`,
          commitmentType: rec.commitmentType,
          duration: rec.commitmentPlan,
        }}
      />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
