import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Instructions.module.scss';

const Instructions = ({ steps, className }) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles.progressLine} />
    {steps.map((step, index) => (
      <div className={styles.step}>
        <div className={styles.stepLabel}>{String.fromCharCode(65 + index)}</div>
        <div className={styles.stepBody}>{step}</div>
      </div>
    ))}
  </div>
);

Instructions.propTypes = {
  steps: PropTypes.array.isRequired,
  className: PropTypes.string,
};

Instructions.defaultProps = {
  className: '',
};

export default Instructions;
