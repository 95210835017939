/* eslint-disable max-len */
/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { Ec2StoppedInstanceRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class Ec2StoppedInstanceRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new Ec2StoppedInstanceRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_STOPPED_INSTANCE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EC2 Stopped Instance`;
  }

  build() {
    super.build();
    const {
      public_ip_address: publicIpAddress,
      attached_volumes: attachedVolumes,
      resource_name: resourceName,
      public_ip_address_cost: publicIpAddressCost,
      attached_volumes_costs: attachedVolumesCosts,
      zone_tag: region,
      stop_reason: stopReason,
      stop_time: stopTime,
    } = this._rawRecommendation;
    this._recommendation.publicIpAddress = publicIpAddress;
    this._recommendation.attachedVolumes = attachedVolumes;
    this._recommendation.resourceName = resourceName;
    this._recommendation.attachedVolumesCosts = attachedVolumesCosts;
    this._recommendation.publicIpAddressCost = publicIpAddressCost;
    this._recommendation.region = region;
    this._recommendation.stopReason = stopReason;
    this._recommendation.stopTime = stopTime;

    // BETA
    this._recommendation.isBeta = true;
  }
}
