/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style,arrow-parens,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
/* eslint-disable  jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { cloneDeep, debounce } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import Eye from 'mdi-react/EyeIcon';
import Icons from 'shared/constants/assetsConstants';
import IconFromPng from 'shared/components/IconFromPng';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { getBasePathByRecType } from 'recommendations/utils/recommUtils';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import {
  mapStatusToTooltipValue,
  RecommTypeValuesToFilterText,
} from 'recommendations/containers/RecommendationsHistory/constants/recommHistoryConstants';
import {
  mapRecommendationsTypeToDisplay,
  RecommendationStatus,
} from 'recommendations/constants/recommendationsConstants';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import RecommHistoryStatusBadge from 'shared/components/RecommHistoryStatusBadge';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { kFormatter } from 'shared/utils/strUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { useUserSettingsContext, withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import parseStringToCsvFormat from 'recommendations/utils/parseStringToCsvFormat';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import RecHistoryActionButtons from '../historyRecommTable/RecHistoryActionButtons';
import RecommendationDropdown from '../RecommendationDropdown';
import * as CONS from '../../constants/devExpRecHistoryTableConstants';
import styles from './DevExpRecommendationHistoryTable.module.scss';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const TableHeaderSelectionBaseComponent = ({
  classes,
  disabled,
  onToggle,
  allSelected,
  someSelected,
  ...restProps
}) => {
  return (
    <TableSelection.HeaderCell
      onToggle={() => onToggle(allSelected)}
      allSelected={allSelected}
      someSelected={someSelected}
      {...restProps}
      disabled={disabled}
    />
  );
};

TableHeaderSelectionBaseComponent.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
  someSelected: PropTypes.bool.isRequired,
};

TableHeaderSelectionBaseComponent.defaultProps = {
  classes: { disabled: false },
  disabled: false,
};

const TableSelectionBaseComponent = ({ classes, disabled, tableRow, onToggle, selected, ...restProps }) => (
  <TableSelection.Cell
    selected={selected}
    onToggle={() => onToggle(tableRow)}
    {...restProps}
    className={`${disabled && classes?.disabled}`}
  />
);

TableSelectionBaseComponent.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  tableRow: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
};

TableSelectionBaseComponent.defaultProps = {
  classes: { disabled: false },
  disabled: false,
};

export const TableHeaderSelectionComponent = TableHeaderSelectionBaseComponent;
export const TableSelectionComponent = TableSelectionBaseComponent;

const ToolbarRootBase = ({ classes, ...props }) => (
  <Toolbar.Root className={classNames(styles.customToolbar)} {...props}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {props.children}
      {props.isSearchLoading ? (
        <span style={{ display: 'inline-block', marginInlineStart: 5 }}>
          <CircularProgress className={styles.loader} size={22} />
        </span>
      ) : null}
    </div>
  </Toolbar.Root>
);
ToolbarRootBase.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  isSearchLoading: PropTypes.bool,
};
ToolbarRootBase.defaultProps = {
  children: null,
  isSearchLoading: false,
};
const ToolbarRoot = ToolbarRootBase;

const SummaryCard = (props) => {
  const { value, title, subTitle, icon } = props;
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <Col xs={12} md={12} lg={6} xl="auto">
      <div
        style={{
          width: '250px',
          textAlign: 'center',
          padding: '10px',
          border: '0.5px solid #d8d8d8',
          borderRadius: '3px',
          boxShadow: '0 1px 2px 0 rgb(0 0 0 / 8%)',
        }}
      >
        <div style={{ paddingBottom: '5px' }}>
          <IconFromPng size="25px" icon={icon} />
        </div>
        <h3 className="savigns-plans__content-info-value">{getCurrencyNumber(Number(value?.toFixed(0)))}</h3>
        <h5 className="savigns-plans__content-info-header">{title}</h5>
        <h6 className="savigns-plans__content-info-header">{subTitle}</h6>
      </div>
    </Col>
  );
};

SummaryCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

class DevExpRecommendationHistoryTable extends Component {
  static propTypes = {
    recomms: PropTypes.object.isRequired,
    recHandlers: PropTypes.object.isRequired,
    recHelpers: PropTypes.object.isRequired,
    recommendationsHistoryData: PropTypes.object.isRequired,
    updateHistoryRecommendationStatus: PropTypes.func.isRequired,
    fetchHistoryRecommendation: PropTypes.func.isRequired,
    defaultPageSize: PropTypes.number.isRequired,
    csvTitle: PropTypes.string,
    cloudTypeId: PropTypes.number.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
    currencyCode: PropTypes.string.isRequired,
  };

  static defaultProps = {
    csvTitle: 'Recommendations',
  };
  constructor(props) {
    const { defaultPageSize, recommendationsHistoryData } = props;
    super(props);
    this.state = {
      pageSizes: [10, 15, 20, 50],
      modifiedDataForCsv: [],
      currentPage: 0,
      loadingMoreRecs: false,
      pageSize: defaultPageSize,
      startDate: recommendationsHistoryData.historyInitialStartDate,
      endDate: recommendationsHistoryData.historyInitialEndDate,
      isSearchLoading: false,
      recsToUpdate: [],
      selectedRows: [],
      selectedRecomms: [],
      searchValue: '',
      isApplyButtonDisabled: true,
      filteredRecomms: [],
    };
  }

  componentDidUpdate() {
    const { modifiedDataForCsv } = this.state;
    if (modifiedDataForCsv.length) {
      const csvLink = document.querySelector('#csv-link');
      if (csvLink) {
        csvLink.click();
      }
      this.setModifiedDataForCsv([]);
    }
  }

  onToggle = (tableRow) => {
    const { selectedRows, selectedRecomms } = this.state;
    const { row, rowId } = tableRow;
    const { COMPLETED_BY_SYSTEM, DISMISSED_BY_USER } = RecommendationStatus;
    if ([COMPLETED_BY_SYSTEM, DISMISSED_BY_USER].includes(row.status)) {
      return;
    }
    const isRowSelected = selectedRows.includes(rowId);
    const isRecommSelected = selectedRecomms.includes(row.key);
    this.setSelectedRows(isRowSelected ? selectedRows.filter((id) => id !== rowId) : [...selectedRows, rowId]);
    this.setState({
      selectedRecomms: isRecommSelected
        ? selectedRecomms.filter((key) => key !== row.key)
        : [...selectedRecomms, row.key],
    });
  };

  getModifiedColumnValue = (colName, value) => {
    switch (colName) {
      case 'status':
        return this.getStatusBadgeType(value.value);
      case 'costPotentialSavings':
        return this.costFormatter(value);
      case 'updateTime':
        return this.dateFormatter(value);
      default:
        return value.value;
    }
  };

  getFilteredRecomms = (recomms, searchValue) => {
    return recomms.filter((recomm) => {
      return CONS.RECOMM_HISTORY_TABLE_COLUMNS.some(({ name, getCellValue }) => {
        const columnValue = getCellValue && typeof getCellValue === 'function' ? getCellValue(recomm) : recomm[name];
        const modifiedValue = this.getModifiedColumnValue(name, { value: columnValue });
        return (
          modifiedValue &&
          modifiedValue
            .toString()
            .toLowerCase()
            .includes((searchValue || '').toLowerCase())
        );
      });
    });
  };
  getStatusBadgeType = (value) => {
    if (value === RecommendationStatus.DISMISSED_BY_USER) {
      return 'Dismissed';
    }
    return value;
  };
  getRecommTitleByKey = (recKey) => {
    const { recomms } = this.props;
    const recomm = recomms.find(({ key }) => key === recKey);
    return (recomm && recomm.title) || '';
  };
  setFilteredRecomms = (filteredRecomms) => {
    this.setState({ filteredRecomms });
  };
  setSelectedRows = (selectedRows) => {
    this.setState({ selectedRows });
  };
  setModifiedDataForCsv = (modifiedDataForCsv) => {
    this.setState({ modifiedDataForCsv });
  };
  setPageSize = (newSize) => this.setState({ pageSize: newSize });
  setCurrentPage = async (newPage) => {
    const { pageSize } = this.state;
    const lastPage = Math.floor(this.props.recomms.length / pageSize);
    this.setState({ currentPage: newPage });
    if (newPage === lastPage && this.props.recommendationsHistoryData.historyRecLastPageKey) {
      try {
        this.setState({ loadingMoreRecs: true });
        const { startDate, endDate } = this.state;
        if (startDate && endDate) {
          const start = buildStartAndEndDate(startDate, endDate).startDate;
          const end = buildStartAndEndDate(startDate, endDate).endDate;
          await this.props.fetchHistoryRecommendation(
            pageSize,
            startDate,
            endDate,
            null,
            this.props.recommendationsHistoryData.historyRecLastPageKey,
          );
        } else {
          await this.props.fetchHistoryRecommendation(
            pageSize,
            null,
            null,
            null,
            this.props.recommendationsHistoryData.historyRecLastPageKey,
          );
        }
        this.setState({ loadingMoreRecs: false });
      } catch (error) {
        this.setState({ loadingMoreRecs: false });
      }
    }
  };
  setSearchValue = (searchValue) => {
    const { recomms } = this.props;
    const filteredRecomms = this.getFilteredRecomms(recomms, searchValue);
    this.setState({ searchValue, filteredRecomms });
  };
  isAllSelectDisabled = () => {
    const { recomms } = this.props;
    const { searchValue, filteredRecomms } = this.state;
    let recommsToCheck = recomms;
    if (searchValue) {
      recommsToCheck = filteredRecomms;
    }
    return recommsToCheck.every((recomm) => this.isRowSelectDisabled(recomm));
  };

  // eslint-disable-next-line class-methods-use-this
  isRowSelectDisabled = (row) =>
    row.status === RecommendationStatus.COMPLETED_BY_SYSTEM || row.status === RecommendationStatus.DISMISSED_BY_USER;

  isAllSelected = () => {
    const { recomms } = this.props;
    const { searchValue, filteredRecomms, selectedRecomms } = this.state;
    if ((searchValue && !filteredRecomms.length > 0) || (recomms && recomms.length === 0)) {
      return false;
    }
    const { COMPLETED_BY_SYSTEM, DISMISSED_BY_USER } = RecommendationStatus;
    if (searchValue && filteredRecomms.length > 0) {
      return (
        selectedRecomms.length > 0 &&
        filteredRecomms.every((filteredRecomm) =>
          selectedRecomms.find((key) => this.isRowSelectDisabled(filteredRecomm) || filteredRecomm.key === key),
        )
      );
    }
    return (
      selectedRecomms.length > 0 &&
      recomms.filter((rec) => !this.isRowSelectDisabled(rec)).length === selectedRecomms.length
    );
  };

  isSomeSelected = () => {
    const { recomms } = this.props;
    const { searchValue, filteredRecomms, selectedRecomms } = this.state;
    if ((searchValue && !filteredRecomms.length) || (recomms && !recomms.length)) {
      return false;
    }
    if (searchValue && filteredRecomms.length) {
      return selectedRecomms.some((key) => filteredRecomms.some((filteredRecomm) => filteredRecomm.key === key));
    }
    return selectedRecomms.length > 0;
  };

  isRowSelected = (tableRowKey) => {
    const { selectedRecomms } = this.state;
    const isRowSelected = selectedRecomms.includes(tableRowKey);
    return isRowSelected;
  };

  selectAllFilteredRows = (isSelected) => {
    const { recomms } = this.props;
    const { searchValue, filteredRecomms } = this.state;
    const recommsToSelect = (searchValue.length ? filteredRecomms : recomms).filter(
      (recomm) => !this.isRowSelectDisabled(recomm),
    );
    const selectedRecomms = recommsToSelect?.map((recomm) => recomm.key);
    this.setState({ selectedRecomms: !isSelected ? [...new Set(selectedRecomms)] : [] });
  };

  changeColumnWidths = (newWidths) => {
    this.setState({ columnWidths: newWidths });
  };

  actionRecommClicked = (rec) => {
    this.setState({ recsToUpdate: [rec.key] });
  };

  batchRevertClicked = () => {
    const { selectedRecomms } = this.state;
    this.setState({ recsToUpdate: selectedRecomms });
  };

  recommActionButtonHandlers = {
    revertRecomm: this.actionRecommClicked,
  };

  recHandlers = {
    recRevertHandler: this.batchRevertClicked,
  };

  handleSetDates = ({ startDate, endDate }) => {
    this.setState((prevState) => {
      let newStartDate = startDate || prevState.startDate;
      let newEndDate = endDate || prevState.endDate;
      return {
        startDate: newStartDate,
        endDate: newEndDate,
        isApplyButtonDisabled: false,
      };
    });
  };

  handleRefetchHistoryRecommendations = async ({ startDateInput, endDateInput }) => {
    const { pageSize, startDate, endDate } = this.state;
    const { recommendationsHistoryData } = this.props;
    this.setState({ loadingMoreRecs: true, isApplyButtonDisabled: true });
    recommendationsHistoryData.historyRecommendations.splice(0);
    recommendationsHistoryData.historyRecLastPageKey = 'start';
    startDateInput ||= startDate;
    endDateInput ||= endDate;
    if (startDateInput > endDateInput && endDateInput !== null) {
      endDateInput = startDateInput;
    }

    this.setState({ startDateInput, endDateInput });
    const start = buildStartAndEndDate(startDateInput, endDateInput).startDate;
    const end = buildStartAndEndDate(startDateInput, endDateInput).endDate;
    await this.props.fetchHistoryRecommendation(
      pageSize,
      start,
      end,
      'all',
      recommendationsHistoryData.historyRecLastPageKey,
    );
    this.setState({ loadingMoreRecs: false });
  };

  // eslint-disable-next-line class-methods-use-this
  prepareRowsForCsv = (row, resourcesNum = 1) => {
    const modifiedRow = {};
    if (row.resourceId) {
      modifiedRow['Resource ID'] = parseStringToCsvFormat(row.resourceId);
    }
    if (row.resourceName) {
      modifiedRow['Resource Name'] = parseStringToCsvFormat(row.resourceName);
    }
    if (row.excludeMessage && row.excludeMessage.length) {
      modifiedRow['Exclude Reason'] = parseStringToCsvFormat(row.excludeMessage);
    }
    return {
      ...modifiedRow,
      'Linked Account': parseStringToCsvFormat(
        row.linkedAccountName ? `${row.linkedAccountName} (${row.linkedAccountId})` : row.linkedAccountId,
      ),
      Type: parseStringToCsvFormat(mapRecommendationsTypeToDisplay.get(row.type)),
      Status: parseStringToCsvFormat(RecommTypeValuesToFilterText.get(row.status)),
      Savings: (parseFloat(row.costPotentialSavings) / resourcesNum).toFixed(4),
      Date: parseStringToCsvFormat(row.updateTime?.split(' ')[0]),
      Tags: parseStringToCsvFormat(
        (Array.isArray(row.resourceTags)
          ? row.resourceTags
          : Object.entries(row.resourceTags || {}).map(([key, value]) => `${key}: ${value}`)
        ).join(' | '),
      ),
    };
  };

  prepareCsvModifiedRows = (recomms) => {
    let csvModifiedRows = JSON.parse(JSON.stringify(recomms));
    csvModifiedRows = csvModifiedRows?.reduce((acc, row) => {
      const currRow = row;
      if (row?.resources?.length) {
        const newRows = row.resources.map((resource) => {
          const newRow = { resourceId: resource.resource_id, resourceName: resource.resource_name, ...row };
          return this.prepareRowsForCsv(newRow, row.resources.length);
        });
        acc.push(...newRows);
        return acc;
      }
      const modifiedCurrRow = this.prepareRowsForCsv(currRow);
      acc.push(modifiedCurrRow);
      return acc;
    }, []);
    return csvModifiedRows;
  };

  exportAsCsv = async (recommendationsHistoryData) => {
    const { pageSize, startDate, endDate } = this.state;
    if (recommendationsHistoryData.historyRecLastPageKey) {
      try {
        if (!this.props.recommendationsHistoryData.isAllHistoryRecommFetched) {
          this.setState({ loadingMoreRecs: true });
          await this.props.fetchHistoryRecommendation(
            pageSize,
            startDate,
            endDate,
            'all',
            recommendationsHistoryData.historyRecLastPageKey,
          );
          this.setState({ loadingMoreRecs: false });
        }
      } catch (error) {
        this.setState({ loadingMoreRecs: false });
      }
    }
    const tmpModifiedDataForCsv = this.prepareCsvModifiedRows(recommendationsHistoryData.historyRecommendations);
    this.setModifiedDataForCsv(tmpModifiedDataForCsv);
    return [
      {
        data: tmpModifiedDataForCsv,
        filename: this.props.csvTitle,
      },
    ];
  };

  costFormatter = (value) => {
    const { getCurrencyNumber } = this.props;
    try {
      const cost = getCurrencyNumber(kFormatter(value.value));
      return cost === '$NaN' ? '-' : cost;
    } catch (error) {
      return '-';
    }
  };
  dateFormatter = (value) => value.value?.trim().slice(0, 10);
  toolTipFormatter = (data) => {
    return (
      <Tooltip title={data.value}>
        <span>{data.value}</span>
      </Tooltip>
    );
  };
  linkFormatter = (data) => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const params = Array.from(currentSearchParams.entries())
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return (
      <LinkIconButton containerStyle={buttonContainerStyle}>
        <Link
          key={data.row.key}
          to={{
            search: params,
            pathname: `${getBasePathByRecType(data.row.type)}/${data.row.key}`,
            rec: data.row,
            recHelpers: { recHelpers: this.props.recHelpers },
            backToPage: { backToPage: window.location.pathname },
          }}
        />
        <Eye />
      </LinkIconButton>
    );
  };
  handleUpdateHistoryRecommStatus = async (action) => {
    const { recommendationsHistoryData } = this.props;
    const { recsToUpdate } = this.state;
    const copyRecsToUpdate = cloneDeep(recsToUpdate);
    this.setState({ loadingMoreRecs: true, recsToUpdate: [] });
    try {
      if (action === 'delete') {
        await this.props.updateHistoryRecommendationStatus(copyRecsToUpdate);
        await this.handleRefetchHistoryRecommendations(this.state);
        recommendationsHistoryData.recommendations = [];
        recommendationsHistoryData.isRecommendationInitialLoadDone = false;
        recommendationsHistoryData.isOpportunitiesInitialLoadDone = false;
      }
      this.setState({ loadingMoreRecs: false, selectedRecomms: [], selectedRows: [] });
    } catch (error) {
      this.setState({ recsToUpdate: [], loadingMoreRecs: false });
    }
  };
  statusFormatter = (data) => {
    const tooltipValue = mapStatusToTooltipValue.get(data.row.status);
    const recommKey = data.row?.key?.split('-').join('');
    return (
      <RecommHistoryStatusBadge
        badgeType={this.getStatusBadgeType(data.value)}
        tooltipValue={tooltipValue}
        recommKey={recommKey}
      />
    );
  };
  userActionsFormatter = (data) => {
    if (data.row.status === RecommendationStatus.DISMISSED_BY_USER) {
      return null;
    }
    if (data.row.notToDelete) {
      return null;
    }
    return (
      <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Organization} action={Action.Update}>
        <RecHistoryActionButtons recomm={data.row} recHandlers={this.recommActionButtonHandlers} />
      </ReadOnlyDisplayWrapper>
    );
  };
  calcSavings = (recs) => {
    const sum = recs?.reduce((acc, curr) => {
      acc += +curr.costPotentialSavings || 0;
      return acc;
    }, 0);
    return sum;
  };
  calculateRecsSavings = (recomms) => {
    const nonExcluded = recomms?.filter(
      (rec) =>
        rec.status !== RecommendationStatus.EXCLUDED &&
        rec.status !== RecommendationStatus.DISMISSED_BY_SYSTEM &&
        rec.status !== RecommendationStatus.DISMISSED_BY_USER,
    );
    return this.calcSavings(nonExcluded);
  };
  calculateExcRecsSavings = (recomms) => {
    const excluded = recomms?.filter((rec) => rec.status === RecommendationStatus.EXCLUDED);
    return this.calcSavings(excluded);
  };
  renderToolbar = (isSearchLoading, restProps) => {
    return <ToolbarRoot isSearchLoading={isSearchLoading} {...restProps} />;
  };
  render() {
    const { recomms, recommendationsHistoryData, csvTitle, cloudTypeId, currencyCode } = this.props;
    const {
      isSearchLoading,
      startDate,
      endDate,
      selectedRows,
      recsToUpdate,
      loadingMoreRecs,
      currentPage,
      pageSize,
      pageSizes,
      selectedRecomms,
      isApplyButtonDisabled,
      searchValue,
      filteredRecomms,
    } = this.state;
    return (
      <div className="card">
        {startDate && endDate ? (
          <Row
            style={{
              marginLeft: '0px',
              marginTop: '10px',
              marginRight: '25px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="w-auto d-flex align-items-center">
              <div style={{ marginInlineEnd: '40px' }}>
                <DatePickerFilter
                  onDateChange={this.handleSetDates}
                  startDate={startDate}
                  endDate={endDate}
                  currPeriodGranLevel="day"
                  daysLimit={360}
                  isMaxDateNeeded={false}
                  isDisabled={loadingMoreRecs}
                />
              </div>
              <div>
                <Button
                  className="history-recomms-apply-btn"
                  type="submit"
                  color="success"
                  size="sm"
                  onClick={this.handleRefetchHistoryRecommendations}
                  disabled={loadingMoreRecs || isApplyButtonDisabled}
                >
                  Apply
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <CustomCSVDownload
                filesNumber={1}
                hideText={false}
                showDownloadIcon
                fetchData={() => this.exportAsCsv(recommendationsHistoryData)}
                classNames="btn link"
              />
            </div>
          </Row>
        ) : null}
        <Row style={{ justifyContent: 'space-evenly', marginTop: '30px' }}>
          <SummaryCard
            title="Annual Savings"
            subTitle="* per the selected period"
            value={this.calculateRecsSavings(recomms)}
            icon={Icons.eventLine}
          />
          <SummaryCard
            title="Annual Excluded Savings"
            subTitle="* per the selected period"
            value={this.calculateExcRecsSavings(recomms)}
            icon={Icons.storageLine}
          />
        </Row>
        {loadingMoreRecs ? (
          <Spinner />
        ) : (
          <Grid rows={searchValue ? filteredRecomms : recomms} columns={CONS.RECOMM_HISTORY_TABLE_COLUMNS}>
            {selectedRecomms.length ? (
              <Col
                style={{ display: 'flex', justifyContent: 'flex-start', minWidth: '300px', zIndex: 10, marginTop: -1 }}
              >
                <RecommendationDropdown
                  recHandlers={this.recHandlers}
                  cloudTypeId={cloudTypeId}
                  selectedRec={recomms?.filter(({ key }) => selectedRecomms.includes(key))}
                />
              </Col>
            ) : null}
            <SearchState onValueChange={debounce(this.setSearchValue, 1000)} />
            <SortingState
              defaultSorting={[
                { columnName: 'updateTime', direction: 'desc' },
                { columnName: 'costPotentialSavings', direction: 'desc' },
              ]}
            />
            <SelectionState selection={selectedRows} onSelectionChange={this.setSelectedRows} />
            <IntegratedSelection />
            <IntegratedFiltering />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.setCurrentPage}
              defaultCurrentPage={0}
              pageSize={pageSize}
              onPageSizeChange={this.setPageSize}
            />
            <IntegratedPaging />
            <DataTypeProvider for={['status']} formatterComponent={this.statusFormatter} />
            <DataTypeProvider for={['costPotentialSavings']} formatterComponent={this.costFormatter} />
            <DataTypeProvider for={['updateTime']} formatterComponent={this.dateFormatter} />
            <DataTypeProvider for={['excludeMessage']} formatterComponent={this.toolTipFormatter} />
            <DataTypeProvider for={['link']} formatterComponent={this.linkFormatter} />
            <DataTypeProvider for={['actionButton']} formatterComponent={this.userActionsFormatter} />
            <TableWrapper columnExtensions={CONS.RECOMM_HISTORY_TABLE_COLUMNS_EXTENSIONS} />
            <TableColumnResizing defaultColumnWidths={CONS.RECOMM_HISTORY_TABLE_COLUMNS_WIDTHS} />
            <TableHeaderRow
              showSortingControls
              cellComponent={({ column, children, ...rest }) => {
                if (column.name === 'costPotentialSavings' && currencyCode && currencyCode !== CURRENCY_DETAILS.USD) {
                  return (
                    <TableHeaderRow.Cell {...rest}>
                      <div className="flex-cell">
                        {children}
                        <Tooltip title={CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP} arrow className="toolTip">
                          <span>
                            <InfoIcon />
                          </span>
                        </Tooltip>
                      </div>
                    </TableHeaderRow.Cell>
                  );
                }
                return (
                  <TableHeaderRow.Cell column={column} {...rest}>
                    {children}
                  </TableHeaderRow.Cell>
                );
              }}
            />
            <Toolbar rootComponent={(props) => this.renderToolbar(isSearchLoading, props)} />
            <SearchPanel />
            <TableSelection
              headerCellComponent={(params, style) => (
                <TableHeaderSelectionComponent
                  {...params}
                  styles={style}
                  allSelected={this.isAllSelected()}
                  someSelected={this.isSomeSelected()}
                  onToggle={this.selectAllFilteredRows}
                  disabled={this.isAllSelectDisabled()}
                />
              )}
              cellComponent={(params, style) => (
                <TableSelectionComponent
                  {...params}
                  styles={style}
                  selectedRows={selectedRows}
                  onToggle={this.onToggle}
                  selectedRecomms={selectedRecomms}
                  selected={this.isRowSelected(params.tableRow.row.key)}
                  disabled={this.isRowSelectDisabled(params.tableRow.row)}
                />
              )}
              showSelectAll
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        )}
        <DeleteWarningModal
          executeButtonTitle={
            recsToUpdate && recsToUpdate.status === RecommendationStatus.COMPLETED_BY_SYSTEM ? 'Dismiss' : 'Revert'
          }
          deletedItemName={recsToUpdate && recsToUpdate.resorceId}
          isOpen={recsToUpdate.length}
          handleDelete={this.handleUpdateHistoryRecommStatus}
          warningMessage={`Be advise you are about to ${
            recsToUpdate.length === 1 && recsToUpdate[0].status === RecommendationStatus.COMPLETED_BY_SYSTEM
              ? 'dismiss'
              : 'revert'
          } this ${recsToUpdate.length === 1 ? this.getRecommTitleByKey(recsToUpdate[0]) : ''} recommendation${
            recsToUpdate.length > 1 ? 's' : ''
          }`} // TODO Change recsToUpdate.title to  be more specific for user
          modalTitle={`${
            recsToUpdate.length === 1 && recsToUpdate[0].status === RecommendationStatus.COMPLETED_BY_SYSTEM
              ? 'Dismiss'
              : 'Revert'
          } Warning`}
        />
      </div>
    );
  }
}

const ObserverDevExpRecommendationHistoryTable = withUserSettingsConsumer(DevExpRecommendationHistoryTable);
export default ObserverDevExpRecommendationHistoryTable;
