import React from 'react';
import { useVirtualTags } from 'usage/hooks/react-query/useVirtualTags';

export function withVirtualTagsContextProvider(Component) {
  return function VirtualTagsContextProvider({ ...props }) {
    const { fetchVirtualTags } = useVirtualTags();
    const { data = [] } = fetchVirtualTags();
    return <Component {...props} virtualTags={data} />;
  };
}
