import React from 'react';
import {
  Grid,
  PagingPanel,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SortingState,
} from '@devexpress/dx-react-grid';
import moment from 'moment';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import { AzureCommonFields } from 'shared/constants/azureConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import DateFilter from 'shared/modules/dateFilter';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useAzureServicePlans } from 'usage/hooks/react-query/useAzureServicePlans';
import RowDetails from './components/RowDetails';
import styles from './AzureServicePlansExplorer.module.scss';

const columns = [
  { name: 'servicePlan', title: 'App Service Plan' },
  {
    name: 'webAppN',
    getCellValue: (row) => row.webAppN || 0,
    title: (
      <>
        Web App #{' '}
        <InfoPopover isSimple>
          The list of Web Apps that associated to this App Service Plan is corresponding to the selected Date Range, the
          list might be vary per different time period
        </InfoPopover>{' '}
      </>
    ),
  },
  {
    name: 'totalCost',
    title: (
      <>
        Total Cost{' '}
        <InfoPopover isSimple>
          Total cost of App Service Plans cost + Web Apps cost for the selected Date Range
        </InfoPopover>{' '}
      </>
    ),
    getCellValue: (row) => row.totalAppServiceCost + (row.totalWebAppCost || 0),
  },
  {
    name: 'totalAppServiceCost',
    title: (
      <>
        Total App Service Cost{' '}
        <InfoPopover isSimple>Total App Service Plans cost for the selected Date Range</InfoPopover>{' '}
      </>
    ),
  },
  {
    name: 'totalWebAppCost',
    title: (
      <>
        Total Web Apps Cost <InfoPopover isSimple>Total Web Apps cost for the selected Date Range</InfoPopover>{' '}
      </>
    ),
    getCellValue: (row) => row.totalWebAppCost || 0,
  },
  { name: 'planTier', title: 'Plan Tier' },
  { name: 'subscription', title: 'Subscription' },
  { name: 'region', title: 'Region' },
  { name: 'service', title: 'Service' },
  { name: 'resourceGroup', title: 'Resource Group' },
];

const columnWidth = [
  { columnName: 'servicePlan', width: 200 },
  { columnName: 'webAppN', width: 130 },
  { columnName: 'totalCost', width: 120 },
  { columnName: 'totalAppServiceCost', width: 190 },
  { columnName: 'totalWebAppCost', width: 180 },
  { columnName: 'planTier', width: 150 },
  { columnName: 'subscription', width: 200 },
  { columnName: 'region', width: 100 },
  { columnName: 'service', width: 100 },
  { columnName: 'resourceGroup', width: 150 },
];

const AzureServicePlansExplorer = () => {
  const { filtersValuesMap } = useInvoiceFilters();
  const { getCurrencyNumber } = useUserSettingsContext();
  const { NewTableWrapper } = useTable();

  const [dateRange, setDateRange] = React.useState({
    startDate: DateFilter.getDate(),
    endDate: DateFilter.getDate(),
  });
  const [subscription, setSubscription] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [appServicePlan, setAppServicePlan] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [appServicePlanSelected, setAppServicePlanSelected] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [resourceGroup, setResourceGroup] = React.useState('');
  const [region, setRegion] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [tag, setTag] = React.useState('');

  const { fetchWebApplications, fetchServicePlans } = useAzureServicePlans({
    startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
    endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
    subscriptionId: subscription,
    servicePlan: appServicePlan,
    servicePlanDetails: appServicePlanSelected,
    resourceGroup,
    region,
    customTags: tag,
  });

  const { data: servicePlans, isLoading: servicePlansLoading } = fetchServicePlans();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerMenu}>
          <div>
            <span>Date Range</span>
            <DatePickerFilter
              onDateChange={(value) => {
                setDateRange((prev) => ({
                  ...prev,
                  ...value,
                }));
              }}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              currPeriodGranLevel="day"
              isDateRangeError={false}
            />
          </div>
          {/* <div> */}
          {/*  <span>App Service Plan</span> */}
          {/*  <SimpleSelect */}
          {/*    onChange={setAppServicePlan} */}
          {/*    value={appServicePlan} */}
          {/*    options={filtersValuesMap.get(AzureCommonFields.APP_SERVICE_PLAN) || []} */}
          {/*  /> */}
          {/* </div> */}
          <div>
            <span>Subscription</span>
            <SimpleSelect
              onChange={setSubscription}
              value={subscription}
              options={(filtersValuesMap.get(AzureCommonFields.SUBSCRIPTION_ID) || []).map((item) => ({
                value: item.linkedAccountId,
                label: item.linkedAccountName,
              }))}
            />
          </div>
          {/* <div> */}
          {/*  <span>Resource Group</span> */}
          {/*  <SimpleSelect */}
          {/*    onChange={setResourceGroup} */}
          {/*    value={resourceGroup} */}
          {/*    options={(filtersValuesMap.get(AzureCommonFields.RESOURCE_GROUP) || []).map((item) => ({ */}
          {/*      value: item, */}
          {/*      label: item, */}
          {/*    }))} */}
          {/*  /> */}
          {/* </div> */}
          <div className={styles.regionFilter}>
            <span>Region</span>
            <SimpleSelect
              onChange={setRegion}
              value={region}
              options={(filtersValuesMap.get(AzureCommonFields.REGION) || []).map((item) => ({
                value: item,
                label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item),
              }))}
            />
          </div>
        </div>
        <div className={styles.headerCounter}>
          {!servicePlansLoading && (
            <div>
              {/* <p> */}
              {/*  Total Cost */}
              {/*  <InfoPopover isSimple>App Service Plans cost + Web Apps cost MTD</InfoPopover>{' '} */}
              {/*  <span>{getCurrencyNumber(100, 0, { roundNumber: true })}</span> */}
              {/* </p> */}
              <p>
                # App Service Plans <span>{servicePlans?.length}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      {servicePlansLoading ? (
        <div className={styles.loading}>
          <Spinner position="relative" />
        </div>
      ) : (
        <NewTableWrapper>
          <Grid
            rows={
              servicePlans?.map((row) => ({
                ...row,
                webAppN: row.webAppsN,
              })) || []
            }
            columns={columns}
          >
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <SortingState defaultSorting={[{ columnName: 'servicePlan', direction: 'asc' }]} />
            <IntegratedSorting />
            <RowDetailState />
            <PagingState defaultCurrentPage={0} pageSize={20} />
            <IntegratedPaging />
            <TableWrapper />
            <TableColumnResizing defaultColumnWidths={columnWidth} resizingMode="nextColumn" />
            <TableRowDetail
              contentComponent={(props) => (
                <RowDetails
                  {...props}
                  fetchWebApplications={fetchWebApplications}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                />
              )}
            />
            <TableHeaderRow showSortingControls />
            <DataTypeProvider
              for={['totalCost', 'totalWebAppCost', 'totalAppServiceCost']}
              formatterComponent={({ value }) => getCurrencyNumber(value, 2)}
            />
            <PagingPanel pageSizes={[10, 15, 20, 0]} />
            <TableFilterRow showFilterSelector />
          </Grid>
        </NewTableWrapper>
      )}
    </div>
  );
};

export default AzureServicePlansExplorer;
