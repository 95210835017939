import React from 'react';
import PropTypes from 'prop-types';
import { camelCase, startCase } from 'lodash';
import BreakEvenGraph from './BreakEvenGraph';
import CostComparisonChart from './CostComparisonChart';

const Statistics = ({ rec }) => {
  const getBreakEvenData = ({ currMonthlyCost, additionalRecommendedPlans, recommendedCommitment }) => {
    const monthSum = currMonthlyCost;
    const costs = Object.keys(additionalRecommendedPlans)
      .filter((recKey) => recKey === recommendedCommitment)
      .reduce(
        (acc, key) => ({
          ...acc,
          [startCase(camelCase(key))]: additionalRecommendedPlans[key].recommended_cost * 12,
        }),
        {},
      );
    const breakEvenData = [];
    for (let i = 1; i <= 12; i += 1) {
      breakEvenData.push({ name: i, ...costs, 'On-Demand Cost': (monthSum * i).toFixed(1) });
    }

    return breakEvenData;
  };

  const getCostComparisonData = () => {
    const modifiedAdditionalRecsData = Object.keys(rec.additionalRecommendedPlans).map((key) => ({
      name: startCase(camelCase(key)),
      recommendedCommitment: startCase(camelCase(rec.recommendedCommitment)),
      [startCase(camelCase(key))]: rec.additionalRecommendedPlans[key].recommended_cost * 12,
    }));
    return [
      {
        name: 'On-Demand (Current)',
        'On-Demand (Current)': rec.currTotalCost,
      },
      ...modifiedAdditionalRecsData,
    ];
  };
  if (!rec) {
    return <div>Loading</div>;
  }
  const costComparisonData = getCostComparisonData().sort((a, b) => b[b.name] - a[a.name]);
  const breakEvenData = getBreakEvenData(rec);
  return (
    <div>
      <div style={{ width: '100%' }}>
        <p className="project-summary__statistic-title">Cost Comparison</p>
        <br />
        <CostComparisonChart data={costComparisonData} />
      </div>
      <div style={{ width: '100%' }}>
        <p className="project-summary__statistic-title">Break-Even Point</p>
        <br />
        <BreakEvenGraph breakEvenData={breakEvenData} />
      </div>
    </div>
  );
};

Statistics.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Statistics;
