import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { withRouter } from 'react-router';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import FilesDownload from 'shared/components/andtComponents/FilesDownload';
import Instructions from 'shared/components/andtComponents/Instructions';
import {
  AUTOMATIC_ONBOARDING_SCRIPT,
  AUTOMATIC_ONBOARDING_SCRIPT_HELPER,
  getConnectToAnodotFile,
} from 'app/containers/Onboarding/AwsOnboarding/utils/constants';
import {
  connectToFileDataGenerator,
  generateOnboardingScript,
  generateOnboardingScriptHelper,
} from 'app/containers/Onboarding/AwsOnboarding/utils/jsonTemplatesStringsGenerators';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import {
  AWS_ONBOARDING_FIELDS,
  AWS_ONBOARDING_STEPS,
} from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import ProgressLoader from 'shared/components/andtComponents/ProgressLoader';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { ReactComponent as DetailsIcon } from '../../assets/details.svg';
import commonStyles from '../Pages.module.scss';
import styles from './ValidateAccessPage.module.scss';
import LoadingImg1 from '../../assets/loading1.gif';
import LoadingImg2 from '../../assets/loading2.gif';
import LoadingImg3 from '../../assets/loading3.gif';
import ErrorModal from '../../../SharedPages/ErrorModal/ErrorModal';
import ValidateModal from '../../../SharedPages/ValidateModal/ValidateModal';

const ValidateAccessPage = withRouter(() => {
  const { getValues, setValue } = useFormContext();
  const onboardingType = getValues(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const bucketName = getValues(AWS_ONBOARDING_FIELDS.BUCKET_NAME);
  const bucketRegion = getValues(AWS_ONBOARDING_FIELDS.BUCKET_REGION);
  const accountId = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID);
  const roleARN = getValues(AWS_ONBOARDING_FIELDS.ROLE_ARN);
  const onboardingSuccess = getValues(AWS_ONBOARDING_FIELDS.ONBOARDING_SUCCESS);

  const { fetchOnboardingStatus, restartOnboarding } = useOnboarding();
  const [validationFailed, setValidationFailed] = useState(false);
  const { data: status } = fetchOnboardingStatus(accountId, {
    enabled: !validationFailed && !onboardingSuccess,
    refetchInterval: 15 * 1000,
  });
  const { validationStatus, userManagementStatus, accountSetupStatus } = status || {};
  const handleRestart = restartOnboarding();
  useEffect(() => {
    const isFailed = validationStatus?.status === 'FAILED' && status.validationShouldStart !== false;
    const isSuccess = userManagementStatus?.status === 'SUCCESS';
    if (isSuccess) {
      setValue(AWS_ONBOARDING_FIELDS.SCREEN, AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS);
      return;
    }
    if (isFailed) {
      setValidationFailed(true);
    }
  }, [status]);
  const currentStep = useMemo(() => {
    if (accountSetupStatus?.status === 'SUCCESS') {
      return 2;
    }
    if (validationStatus?.status === 'SUCCESS') {
      return 1;
    }
    return 0;
  }, [status]);
  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.left}>
          <DetailsIcon />
          Review your details:
        </div>
        <div className={styles.right}>
          <p className={styles.listItem}>
            <GenerateIcon iconName={ICONS.cloud.name} />
            Bucket Name:
            <span>{bucketName}</span>
          </p>
          <p className={styles.listItem}>
            <GenerateIcon iconName={ICONS.hashtag.name} />
            ARN Number:
            <span>{roleARN}</span>
          </p>
          <p className={styles.listItem}>
            <GenerateIcon iconName={ICONS.cloud.name} />
            AWS Account ID:
            <span>{accountId}</span>
          </p>
          <p className={styles.listItem}>
            <GenerateIcon iconName={ICONS.earth.name} />
            Bucket Region:
            <span>{bucketRegion}</span>
          </p>
        </div>
      </div>
      {onboardingType === ONBOARDING_TYPES.AUTOMATIC ? (
        <div className={styles.scriptDownloadContainer}>
          <p>Download files and run .sh script. Make sure to put .yaml file in the same directory.</p>
          <div className={styles.scriptDownloadBlock}>
            <FilesDownload
              disabled={!getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID)}
              buttonTitle="Download Files"
              files={[
                {
                  title: 'Cloudformation script',
                  fileName: AUTOMATIC_ONBOARDING_SCRIPT,
                  jsonGen: () => generateOnboardingScript({ externalId: getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID) }),
                  nodeId: 'cloudFormationScript',
                },
                {
                  title: 'Cloudformation yaml',
                  fileName: AUTOMATIC_ONBOARDING_SCRIPT_HELPER,
                  jsonGen: () => generateOnboardingScriptHelper(),
                  nodeId: 'cloudFormationScriptHelper',
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <Instructions
          className={styles.instructions}
          steps={[
            <div className={styles.stepWrapper}>
              <FilesDownload
                files={[
                  {
                    title: 'Connect to Anodot.JSON',
                    fileName: getConnectToAnodotFile(accountId),
                    jsonGen: () =>
                      connectToFileDataGenerator({
                        invoiceBucketName: bucketName,
                        arnRoleNum: roleARN,
                        awsAccountID: accountId,
                      }),
                    nodeId: 'ConnectToAnodotJSON',
                  },
                ]}
              />
            </div>,
            <div className={styles.stepWrapper}>
              <p className={commonStyles.description}>
                Upload the file to{' '}
                <a
                  className={styles.bucketLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  // eslint-disable-next-line max-len
                  href={`https://s3.console.aws.amazon.com/s3/buckets/${bucketName}/?region=${bucketRegion}&tab=overview`}
                >
                  your invoice bucket
                </a>
              </p>
              <p className={styles.warning}>
                <GenerateIcon iconName={ICONS.triangleExclamation.name} />
                In order to continue to the next step, you are required to upload the invoice file to your invoice
                bucket.
              </p>
            </div>,
          ]}
        />
      )}

      {validationFailed && <ErrorModal status={status} handleRestart={handleRestart} cloudType={CLOUD_TYPE_IDS.AWS} />}
      {getValues(AWS_ONBOARDING_FIELDS.VALIDATION_STARTED) && !validationFailed && (
        <ValidateModal
          subtitle={
            <div className={styles.banner}>
              <p>
                We will notify you by email once the validation has been completed so that you can connect your linked
                accounts (to see recommendations) while your CUR file is being processed.
              </p>
            </div>
          }
        >
          <ProgressLoader
            className={styles.progressLoader}
            currentStep={currentStep}
            listClassName={styles.list}
            steps={[
              {
                description: 'Validating your AWS details',
                image: <img src={LoadingImg1} alt="Loading 1" />,
              },
              {
                description: 'Creating an account for you in Anodot',
                image: <img src={LoadingImg2} alt="Loading 2" />,
              },
              {
                description: 'Creating additional configurations for your account',
                image: <img src={LoadingImg3} alt="Loading 3" />,
              },
            ]}
          />
        </ValidateModal>
      )}
    </div>
  );
});

export default ValidateAccessPage;
