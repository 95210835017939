/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';
// TODO update description
const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      {`The Kinesis service has been underutilized for the past ${rec.daysToCheck} days. We recommend terminating this service.`}
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
