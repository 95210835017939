export const RI_COVERAGE_TABLE_COLUMNS = {
  SERVICE_TYPE: { name: 'serviceType', title: 'Type', getCellValue: (row) => row.serviceType || row.operationTitle },
  REGION: { name: 'region', title: 'Region', getCellValue: (row) => row.regionTagName },
  COVERAGE: { name: 'coverage', title: 'Coverage', getCellValue: (row) => row.coverage },
  ON_DEMAND: { name: 'onDemand', title: 'On-Demand Cost', getCellValue: (row) => row.onDemandTotalCost },
};

export const RI_COVERAGE_COLUMN_WIDTHS = [
  { columnName: RI_COVERAGE_TABLE_COLUMNS.SERVICE_TYPE.name, width: 'auto' },
  { columnName: RI_COVERAGE_TABLE_COLUMNS.REGION.name, width: 'auto' },
  { columnName: RI_COVERAGE_TABLE_COLUMNS.COVERAGE.name, width: 'auto' },
  { columnName: RI_COVERAGE_TABLE_COLUMNS.ON_DEMAND.name, width: 'auto' },
];

export const RI_COVERAGE_TABLE_COLUMN_EXTENSIONS = [
  { columnName: RI_COVERAGE_TABLE_COLUMNS.SERVICE_TYPE.name, align: 'center' },
  { columnName: RI_COVERAGE_TABLE_COLUMNS.REGION.name, align: 'center' },
  { columnName: RI_COVERAGE_TABLE_COLUMNS.COVERAGE.name, align: 'center' },
  { columnName: RI_COVERAGE_TABLE_COLUMNS.ON_DEMAND.name, align: 'center' },
];

export const RI_COVERAGE_DEFAULT_HIDDEN_COLUMN_NAMES = [];

export const RI_COVERAGE_DEFAULT_SORTING = [
  { columnName: RI_COVERAGE_TABLE_COLUMNS.COVERAGE.name, direction: 'desc' },
  { columnName: RI_COVERAGE_TABLE_COLUMNS.ON_DEMAND.name, direction: 'desc' },
];

export const RI_COVERAGE_CURRENCIES_COLUMNS = [RI_COVERAGE_TABLE_COLUMNS.ON_DEMAND.name];
