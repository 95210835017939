import {
  GCP_ONBOARDING_STEPS_SHORT_TITLES,
  GCP_ONBOARDING_FIELDS,
  GCP_ONBOARDING_STEPS,
} from './GcpOnboardingConstants';
import InstructionsPage from './pages/InstructionsPage/InstructionsPage';
import ProcessDataPage from '../SharedPages/ProcessDataPage/ProcessDataPage';
import DetailsPage from './pages/GcpDetailsPage/GcpDetailsPage';
import K8sIntegrationPage from './pages/K8sIntegrationPage/K8sIntegrationPage';

export const GCP_ONBOARDING_STEPS_CONFIG = {
  [GCP_ONBOARDING_STEPS.CREATE_APP]: {
    title: 'Create service account and associate it to the BQ dataset',
    titleShort: GCP_ONBOARDING_STEPS_SHORT_TITLES.CREATE_APP,
    component: InstructionsPage,
  },
  [GCP_ONBOARDING_STEPS.GCP_DETAILS]: {
    title: 'Enter GCP details',
    titleShort: GCP_ONBOARDING_STEPS_SHORT_TITLES.GCP_DETAILS,
    component: DetailsPage,
  },
  [GCP_ONBOARDING_STEPS.K8S_INTEGRATION]: {
    title: 'Adding K8s labels',
    titleShort: GCP_ONBOARDING_STEPS_SHORT_TITLES.K8S_INTEGRATION,
    component: K8sIntegrationPage,
  },
  [GCP_ONBOARDING_STEPS.PROCESS_DATA]: {
    title: 'Data Process',
    titleShort: GCP_ONBOARDING_STEPS_SHORT_TITLES.PROCESS_DATA,
    component: ProcessDataPage,
    nextText: 'Done',
    noReturn: true,
    nextEnabled: GCP_ONBOARDING_FIELDS.INVOICE_SUCCESS,
  },
};
