import React from 'react';
import { useFetchResourceDetails } from 'usage/hooks/react-query/useResources';

const ResourcesContext = React.createContext();

export function withResourcesContextProvider(Component) {
  return function ResourcesContextProvider({ ...props }) {
    const { selectedResource, fetchResource, resourceIsLoading } = useFetchResourceDetails();
    return (
      <ResourcesContext.Provider
        value={{
          selectedResource,
          fetchResource,
          resourceIsLoading,
        }}
      >
        <Component {...props} />
      </ResourcesContext.Provider>
    );
  };
}

export function withResourcesContextConsumer(Component) {
  return function ResourcesContextConsumer({ ...props }) {
    return <ResourcesContext.Consumer>{(data) => <Component {...data} {...props} />}</ResourcesContext.Consumer>;
  };
}
