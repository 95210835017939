import React from 'react';
import PropTypes from 'prop-types';
import RecommendationOptionsPanel from 'recommendations/shared/components/RecommendationOptionsPanel';

const Options = ({ recommendation }) => {
  if (Array.isArray(recommendation?.alternatives)) {
    let topThreeAlternatives = recommendation?.alternatives.slice(0, 3);
    topThreeAlternatives = topThreeAlternatives.map((alternative) => {
      if (alternative.riCoverage) {
        const { riCoverage, ...rest } = alternative;
        return {
          ...rest,
          riCoverage: Number(alternative.riCoverage) === 1 ? 'Yes' : 'No',
          isMultiAz: alternative.isMultiAz ? 'Y' : 'N',
        };
      }
      return { ...alternative, isMultiAz: alternative.isMultiAz ? 'Y' : 'N' };
    });

    const RECOMMENDATION_DATA = [
      { propertyTitle: 'Instance type', property: 'instanceType' },
      { propertyTitle: 'Instance family', property: 'instanceFamily' },
      { propertyTitle: 'Instance  type family', property: 'instanceTypeFamily' },
      { propertyTitle: 'vCPUs', property: 'cpu' },
      { propertyTitle: 'Memory', property: 'memory' },
      { propertyTitle: 'Storage', property: 'instanceStorage' },
      { propertyTitle: 'Physical processor', property: 'physicalProcessor' },
      { propertyTitle: 'Multi AZ', property: 'isMultiAz' },
      {
        propertyTitle: 'RI Compatible',
        property: 'riCoverage',
        // eslint-disable-next-line max-len
        info: 'It is possible to purchase RIs for this configuration, please validate current RIs before choosing this option',
      },
    ];

    const current = {
      annualCost: recommendation?.currentAnnualCost,
      instanceType: recommendation?.instanceType,
      instanceFamily: recommendation?.instanceFamily,
      instanceTypeFamily: recommendation?.instanceTypeFamily,
      cpu: recommendation?.cpu,
      memory: recommendation?.memory,
      instanceStorage: recommendation?.storage,
      physicalProcessor: recommendation?.physicalProcessor,
      riCoverage: Number(recommendation?.riCoverage) === 1 ? 'Yes' : 'No',
      isMultiAz: Number(recommendation?.isMultiAz) === 1 ? 'Y' : 'N',
    };

    return (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        current={current}
        recommendationData={RECOMMENDATION_DATA}
      />
    );
  }
  return null;
};

Options.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Options;
