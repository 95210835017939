/* eslint-disable max-len */
/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { EbsTypeAndSizeChangeRecommendation, EbsTypeChangeRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class EbsTypeAndSizeChangeRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation =
      this._rawRecommendation.type === 'ebs-type-and-size-change'
        ? new EbsTypeAndSizeChangeRecommendation()
        : new EbsTypeChangeRecommendation();
  }

  getRecommType() {
    return this._rawRecommendation.type === 'ebs-type-and-size-change'
      ? RecommendationTypes.EBS_TYPE_SIZE_CHANGE
      : RecommendationTypes.EBS_TYPE_CHANGE;
  }

  buildRecommTitle() {
    const { recommended_action: action, type } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EBS Type ${type === 'ebs-type-and-size-change' ? 'And Size' : ''}`;
  }

  build() {
    super.build();
    const {
      zone_tag: zoneTag,
      starting_time: startingTime,
      days_to_check: daysToCheck,
      ebs_type: ebsType,
      new_ebs_type: newEbsType,
      ebs_id: ebsId,
      ebs_size: ebsSize,
      ebs_name: ebsName,
      ebs_iops: iops,
      ebs_throughput: throughput,
      max_iops_possible_gp2: maxIopsPossibleGp2,
      max_iops_possible_io1: maxIopsPossibleIo1,
      max_throughput_possible_io1: maxThroughputPossibleIo1,
      max_throughput_possible_io2: maxThroughputPossibleIo2,
      max_throughput_possible_gp2: maxThroughputPossibleGp2,
      max_throughput_possible_gp3: maxThroughputPossibleGp3,
      max_iops_data: maxIopsData,
      max_throughput_data: maxThroughputData,
      gp2_iops: gp2Iops,
      gp2_size: gp2Size,
      gp3_throughput: gp3Throughput,
      max_iops: maxIops,
      max_throughput: maxThroughput,
      subtype: subType,
      [`${newEbsType}_iops`]: recommendedIops,
      [`${newEbsType}_size`]: recommendedSize,
      size_potential_savings: sizePotentialSavingsMonthly,
      iops_potential_savings: iopsPotentialSavingsMonthly,
      throughput_potential_savings: throughputPotentialSavingsMonthly,
      command,
      command_comment: commandComment,
      attention_comment: attentionComment,
    } = this._rawRecommendation;

    this._recommendation.sizePotentialSavings = sizePotentialSavingsMonthly * 12;
    this._recommendation.iopsPotentialSavings = iopsPotentialSavingsMonthly * 12;
    this._recommendation.throughputPotentialSavings = throughputPotentialSavingsMonthly * 12;
    this._recommendation.command = command;
    this._recommendation.commandComment = commandComment;
    this._recommendation.attentionComment = attentionComment;
    this._recommendation.maxIopsPossibleGp2 = maxIopsPossibleGp2;
    this._recommendation.maxIopsPossibleIo1 = maxIopsPossibleIo1;
    this._recommendation.maxThroughputPossibleIo1 = maxThroughputPossibleIo1;
    this._recommendation.maxThroughputPossibleIo2 = maxThroughputPossibleIo2;
    this._recommendation.maxThroughputPossibleGp2 = maxThroughputPossibleGp2;
    this._recommendation.maxThroughputPossibleGp3 = maxThroughputPossibleGp3;
    this._recommendation.maxIopsData = maxIopsData;
    this._recommendation.maxThroughputData = maxThroughputData;
    this._recommendation.region = zoneTag;
    this._recommendation.startingTime = startingTime;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.newEbsType = newEbsType;
    this._recommendation.ebsId = ebsId;
    this._recommendation.iops = iops;
    this._recommendation.throughput = throughput;
    this._recommendation.ebsType = ebsType;
    this._recommendation.ebsSize = ebsSize;
    this._recommendation.ebsName = ebsName;
    this._recommendation.gp2Iops = gp2Iops;
    this._recommendation.gp2Size = gp2Size;
    this._recommendation.gp3Throughput = gp3Throughput;
    this._recommendation.maxIops = maxIops;
    this._recommendation.maxThroughput = maxThroughput;
    this._recommendation.subType = subType;
    this._recommendation[`${newEbsType}Iops`] = recommendedIops;
    this._recommendation[`${newEbsType}Size`] = recommendedSize;
  }
}
