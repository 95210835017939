import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceDot, ReferenceLine } from 'recharts';
import { useCUEEvents } from 'users/hooks/react-query/useCUEEvents';
import { getCUEEventsLineData } from 'users/utils/eventsUtil';
import CustomEventDot from './CustomEventDot';

// need to use it as hook because of https://github.com/recharts/recharts/issues/41
const useEventsChartBlock = ({ startDate, endDate, granLevel, onEventCollapse, enabled }) => {
  const { fetchCUEEvents } = useCUEEvents();
  const { data: events = [] } = fetchCUEEvents(startDate, endDate, granLevel, enabled);
  const verticalLinesData = getCUEEventsLineData(events, granLevel);
  return [
    (maxSingleValue) => [
      ...verticalLinesData.map((line) => (
        <ReferenceLine
          x={line.x}
          isFront
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onEventCollapse(line.id);
          }}
          strokeWidth={1}
          stroke="#000"
          strokeDasharray="6 6"
        />
      )),
      ...verticalLinesData.map((line) => (
        <ReferenceDot
          x={line.x}
          y={maxSingleValue}
          onClick={(e) => {
            e.stopPropagation();
            onEventCollapse(line.id);
          }}
          shape={<CustomEventDot title={`${line.date}: ${line.label}`} />}
          r={6}
          stroke="none"
        />
      )),
    ],
  ];
};

useEventsChartBlock.propTypes = {
  startDate: PropTypes.string.isRequired,
  granLevel: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onEventCollapse: PropTypes.func.isRequired,
  maxSingleValue: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default useEventsChartBlock;
