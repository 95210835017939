import React from 'react';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from 'app/containers/App/components/AuthenticatedRoute';
import { Routes } from 'shared/constants/routes';
import { HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { checkTrialUser } from 'shared/components/CheckTrialUser';
import Recommendation360 from 'recommendationsNew/menuItems/recommendation360';
import RecommendationExplorer from 'recommendationsNew/menuItems/recommendationExplorer';
import RecommendationTrial from 'recommendations/containers/TrialRecPage';
import { RecommendationsNewProvider } from 'recommendationsNew/contexts/recommendationsNewContext';

const RecommendationsNewRoot = (childProps) => (
  <div>
    <RecommendationsNewProvider>
      <Switch>
        <AuthenticatedRoute
          exact
          path={Routes.RECOMMENDATION_360}
          component={checkTrialUser(Recommendation360, RecommendationTrial, childProps)}
          props={childProps}
          authorizationCategories={[HierarchicalEntityCategory.BillingData]}
        />
        <AuthenticatedRoute
          exact
          path={Routes.RECOMMENDATION_EXPLORER}
          component={checkTrialUser(RecommendationExplorer, RecommendationTrial, childProps)}
          props={childProps}
          authorizationCategories={[HierarchicalEntityCategory.BillingData]}
        />
      </Switch>
    </RecommendationsNewProvider>
  </div>
);

export default RecommendationsNewRoot;
