import React, { useEffect, useState } from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { number } from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import Spinner from 'shared/components/andtComponents/Spinner';
import { palette } from 'shared/constants/colorsConstants';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import styles from './hourlyCommitment.module.scss';
import useSpAnalyzer from '../hooks/useSpAnalyzer';

export const HourlyCommitment = ({ planKey, term, paymentOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hourlyValue, setHourlyValue] = useState('');
  const [modalPosition, setModalPosition] = useState({});
  const [hourlyCommitment, setHourlyCommitment] = useState(null);
  const {
    selectedOptions,
    filterStatusType,
    daysToCheck,
    coverage,
    planDataByKey,
    setPlanDataByKey,
    modifiedPlanKeyHourlyCommitment,
    setModifiedPlanKeyHourlyCommitment,
  } = spAnalyzerContext();
  const { fetchSingleOptionPlanAnalyzerData } = useSpAnalyzer();
  const { data, isLoading } = fetchSingleOptionPlanAnalyzerData(
    selectedOptions,
    daysToCheck,
    filterStatusType,
    paymentOption,
    term,
    coverage,
    hourlyCommitment,
  );

  useEffect(() => {
    if (data && data.recommendationPerPlan[planKey]) {
      setPlanDataByKey({ ...planDataByKey, [planKey]: data.recommendationPerPlan[planKey] });
    }
  }, [data]);
  const onEditHourly = (event) => {
    setModalPosition({ top: event.clientY - 10, left: event.clientX + 5 });
    setIsOpen(!isOpen);
  };

  const calculateSavingWithHourlyCommitment = () => {
    setIsOpen(false);
    setModifiedPlanKeyHourlyCommitment({ ...modifiedPlanKeyHourlyCommitment, [planKey]: hourlyValue });
    setHourlyCommitment(hourlyValue);
  };

  const resetHourlyCommitment = () => {
    setModifiedPlanKeyHourlyCommitment({ ...modifiedPlanKeyHourlyCommitment, [planKey]: null });
    setHourlyCommitment(null);
    setPlanDataByKey({ ...planDataByKey, [planKey]: null });
  };
  return (
    <>
      {modifiedPlanKeyHourlyCommitment?.[planKey] ? (
        <div className={styles.iconWrapper}>
          {isLoading ? (
            <div className={styles.spinnerWrapper}>
              <Spinner color={palette.blue[500]} size={20} />
            </div>
          ) : (
            <div className={styles.reset}>
              <GenerateIcon
                iconName={ICONS.arrowRotateLeft.name}
                className={styles.blueIcon}
                automation-id={`${planKey}Reset`}
                onClick={resetHourlyCommitment}
              />
              <span className={styles.resetText}>Reset</span>
            </div>
          )}
        </div>
      ) : (
        <GenerateIcon
          iconName={ICONS.pencil.name}
          className={styles.blueIcon}
          onClick={onEditHourly}
          automation-id={`${planKey}EditHourly`}
        />
      )}
      <CustomModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSave={() => calculateSavingWithHourlyCommitment()}
        overrideStyles={{ top: modalPosition.top, left: modalPosition.left }}
        className={{
          modal: styles.modal,
          title: styles.modalTitle,
          modalHeader: styles.modalHeader,
        }}
        saveTitle="Apply"
        headerMode="edit"
        title="Edit Hourly Commitment"
      >
        <div>
          <Input
            type={number}
            automationId={`${planKey}HourlyCommitmentInput`}
            value={hourlyValue}
            onChange={(e) => {
              setHourlyValue(e.target.value);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};
