import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => {
  const { dbType, resourceGroup, server, dbName, dbAccount, accountName } = rec;
  const getActionByType = () => {
    switch (dbType) {
      case 'Microsoft.DocumentDB/databaseAccounts/sqlDatabases':
        return 'az cosmosdb database delete --db-name {{dbName}} --name {{server}} --resource-group-name {{resourceGroup}}';
      case 'Microsoft.DocumentDB/databaseAccounts/gremlinDatabases':
        return 'az cosmosdb gremlin database delete --account-name {{accountName}} --name {{dbName}} --resource-group {{resourceGroup}}';
      case 'Microsoft.DocumentDB/databaseAccounts/mongodbDatabases':
        return 'az cosmosdb mongodb database delete --account-name {{accountName}} --name {{dbName}} --resource-group {{resourceGroup}}';
      case 'Microsoft.DocumentDB/databaseAccounts/cassandraKeyspaces':
        return 'az cosmosdb cassandra keyspace delete --account-name {{accountName}} --name {{dbName}} --resource-group {{resourceGroup}}';
      default:
        return null;
    }
  };
  return (
    <ActionContainer>
      <ActionHeader />
      <ul style={{ 'list-style-type': 'none' }}>
        <li>Using CLI:</li>
        <CodeBlock
          variables={{
            server: server || dbAccount,
            accountName,
            dbName,
            resourceGroup,
          }}
          text={getActionByType()}
        />
        <br />
      </ul>
    </ActionContainer>
  );
};

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
