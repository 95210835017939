/* eslint-disable arrow-parens */
import { REPORT_TYPES } from '../../constants/usageConstants';
import RegionReportParams from './regionReportParams';
import CurrVsPrevReportParams from './CurrVsPrevReportParams';
import TopFiveServReportParams from './topFiveServReportParams';
import YesterdayTopFiveServReportParams from './yesterdayTopFiveServReportParams';
import YtdTotalMonthlyCost from './ytdTotalMonthlyCost';

export default class MainDashboardReportHelper {
  reportsParamsBuilder = (reportType, dates) => {
    let reportParams = {};
    switch (reportType) {
      case REPORT_TYPES.CURR_VS_PREV_MONTH:
        reportParams = new CurrVsPrevReportParams(dates);
        break;
      case REPORT_TYPES.REGION:
        reportParams = new RegionReportParams(dates);
        break;
      case REPORT_TYPES.TOP_5_SERVICES:
        reportParams = new TopFiveServReportParams(dates);
        break;
      case REPORT_TYPES.YESTERDAY_TOP_5_SERVICES:
        reportParams = new YesterdayTopFiveServReportParams(dates);
        break;
      case REPORT_TYPES.MONTHLY_COST_YTD:
        reportParams = new YtdTotalMonthlyCost(dates);
        break;
      default:
        break;
    }
    return reportParams;
  };
}
