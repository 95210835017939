import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti } from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from './Filters.module.scss';

const Filters = ({ filters, setFilters }) => {
  const { usersStore } = useRootStore();
  const customers = usersStore.usersModel.divisionGroups;
  const users = usersStore.getSubUsersListByDisplayedType();
  const customerNames = useMemo(
    () => customers.map((customer) => customer.divisionName).map((v) => ({ label: v, value: v })),
    [customers],
  );
  const userNames = useMemo(() => users.map((user) => user.userName).map((v) => ({ label: v, value: v })), [users]);
  const linkedAccountNames = useMemo(
    () =>
      customers
        .map((customer) =>
          customer.linkedAccounts.map((ln) => ({
            label: ln.linkedAccountName,
            value: ln.linkedAccountId,
          })),
        )
        .flat(),
    [customers],
  );
  return (
    <div className={styles.container}>
      <div>
        <SelectMulti
          label="Customer Name"
          options={customerNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, customerName: vals });
          }}
          showCounter
          selected={filters.customerName}
        />
      </div>
      <div>
        <SelectMulti
          label="User Name"
          options={userNames}
          showCounter
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, userName: vals });
          }}
          selected={filters.userName}
        />
      </div>
      <div>
        <SelectMulti
          label="Linked Account"
          options={linkedAccountNames}
          showCounter
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, linkedAccount: vals });
          }}
          selected={filters.linkedAccount}
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default Filters;
