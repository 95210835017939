/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import BaseCauAlert from './baseCauAlert';

export default class ExpirationAlert extends BaseCauAlert {
  // constructor(id, creationDate, alertStatus, userKey, linkedAccountIds, services, changeType, operatorType, changePercent, changeValue, recipientsEmails) {
  constructor(alertParams) {
    super(alertParams);
    this.daysBeforeExpiry = alertParams.daysBeforeExpiry;
    // this.editedParams = null;
  }

  get daysBeforeExpiryValue() {
    const value = this.daysBeforeExpiry ? this.daysBeforeExpiry.value : [];
    return value;
  }

  get alertModifiedParams() {
    const baseParams = super.alertModifiedParams;
    return {
      ...baseParams,
      daysBeforeExpiry: this.daysBeforeExpiry,
    };
  }

  get alertParams() {
    const { id, ...baseParams } = super.alertParams;
    const alertParams = {
      daysBeforeExpiry: this.daysBeforeExpiry,
      ...baseParams,
    };
    return alertParams;
  }
}
