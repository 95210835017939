import React from 'react';
import {
  CompletedStatus,
  DoneStatusWithLabel,
  Excluded,
  NotExcluded,
  OpenStatus,
  StatusOperator,
  UndoneStatusWithLabel,
} from 'recommendationsNew/components/shared/recommendationStatusSymbol';

import classes from './statusFilterOptions.module.scss';

export const PotentialSavingsStatusFilterOption = () => (
  <div className={classes.content}>
    <OpenStatus withBorder />
    <StatusOperator operator="And" />
    <UndoneStatusWithLabel />
    <StatusOperator operator="And" />
    <NotExcluded />
  </div>
);

export const ActualSavingsStatusFilterOption = () => (
  <div className={classes.content}>
    <div className={classes.orWrapper}>
      <CompletedStatus withBorder />
      <StatusOperator operator="Or" />
      <DoneStatusWithLabel />
    </div>
    <StatusOperator operator="And" />
    <NotExcluded />
  </div>
);

export const ExcludedStatusFilterOption = () => (
  <div className={classes.content}>
    <Excluded />
    <StatusOperator operator="And" />
    <div className={classes.orWrapper}>
      <OpenStatus withBorder />
      <StatusOperator operator="Or" />
      <CompletedStatus withBorder />
    </div>
  </div>
);

export const UserActionsStatusFilterOption = () => (
  <div className={classes.content}>
    <Excluded />
    <StatusOperator operator="Or" />
    <DoneStatusWithLabel />
  </div>
);
