import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const HeatMapActionsContext = createContext();

export const HeatMapActionsProvider = ({ children }) => {
  const [isSavingsTracker, setIsSavingsTracker] = useState(false);
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false);

  return (
    <HeatMapActionsContext.Provider
      value={{
        isSavingsTracker,
        setIsSavingsTracker,
        isStatusFilterOpen,
        setIsStatusFilterOpen,
      }}
    >
      {children}
    </HeatMapActionsContext.Provider>
  );
};

HeatMapActionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeatMapActionsContext;
