/* eslint-disable camelcase */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { StringToColor } from 'shared/utils/graphicUtil';
import AndtTooltip from 'shared/components/andtComponents/Tooltip';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { calcTotalChartCost } from '../../utils/dataPrepareUtil';
import { calcPercent } from '../../utils/calcUtil';
import styles from './PieChartGroupByBased.module.scss';
import renderCustomizedPieLabel from '../chartComponents/CustomizedPieLabel';

const renderLegend = ({ data: items, totalCost, numStrAbriviaionByDisplayMetric }) => (
  <ul>
    {items.map((entry) => {
      const { group_by: groupBy, total_cost: cost } = entry;
      const costFormatted = numStrAbriviaionByDisplayMetric(totalCost, cost, 'cost');
      const percentOfTotal = `${Math.round(100 - calcPercent(cost, totalCost))}%`;
      return (
        <li className={styles.legendItem} key={groupBy}>
          <span style={{ background: StringToColor.next(groupBy) }} />
          <AndtTooltip key={groupBy} title={`${groupBy}: ${costFormatted}`}>
            <p>
              {groupBy} {costFormatted}
            </p>
          </AndtTooltip>
          <div>{percentOfTotal}</div>
        </li>
      );
    })}
  </ul>
);

const PieChartGroupByBased = memo(({ chartId, data, height }) => {
  const totalCost = calcTotalChartCost(data.map((r) => ({ totalSum: r.total_cost })));
  const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
  return (
    <div id={chartId}>
      <ResponsiveContainer width="100%" height={height} className={styles.container}>
        <PieChart>
          <Pie
            stroke="none"
            data={data.filter((row) => row.total_cost > 0)}
            dataKey="total_cost"
            nameKey="group_by"
            labelLine={false}
            innerRadius="0"
            outerRadius="85%"
            isAnimationActive={false}
            label={(item) =>
              renderCustomizedPieLabel(
                item,
                (value) => numStrAbriviaionByDisplayMetric(totalCost, value, 'cost'),
                (value) =>
                  numStrAbriviaionByDisplayMetric(totalCost, value, 'cost', false, { showPercent: true, decimal: 0 }),
              )
            }
          >
            {data
              .filter((row) => row.total_cost > 0)
              .map((currData) => (
                <Cell key={currData.group_by} fill={StringToColor.next(currData.group_by)} />
              ))}
          </Pie>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            className={styles.legendContainer}
            content={renderLegend({ data, totalCost, numStrAbriviaionByDisplayMetric })}
            totalCost={totalCost}
            margin={{ left: 25 }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
});

PieChartGroupByBased.propTypes = {
  chartId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      total_cost: PropTypes.number.isRequired,
      group_by: PropTypes.string.isRequired,
    }),
  ).isRequired,
  height: PropTypes.number.isRequired,
};

export default PieChartGroupByBased;
