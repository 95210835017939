import { API } from 'shared/utils/apiMiddleware';

export const BILLINGS_API_NAME = 'billings';
export const AWS_RECOMMENDATIONS_ROOT = '/api/v1/recommendations';

export const saveRecommendationsLabel = (signatures) =>
  API.post(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/label`, { body: { signatures } });

export const deleteRecommendationsLabel = (recSignaturesForLabel) =>
  API.del(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/label`, { body: { signatures: recSignaturesForLabel } });
