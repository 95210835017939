import React from 'react';
import PropTypes from 'prop-types';

export const WRITE_PERMISSION_ENTITIES = {
  USER_MANAGEMENT: 'user-management',
};

const WritePermissionWrapper = ({ children }) => <div>{children}</div>;

WritePermissionWrapper.propTypes = {
  children: PropTypes.object.isRequired,
};

export default WritePermissionWrapper;
