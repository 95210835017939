import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import CustomDashboardsTable from './components/CustomDashboardsTable';

const CustomDashboard = (props) => {
  const [searchStr, setSearchStr] = useState('');
  return (
    <Container>
      <PageHeader
        showDate
        title={PageNames.CUSTOM_DASHBOARD}
        searchParams={{
          value: searchStr,
          onChange: (val) => setSearchStr(val),
        }}
      />
      <Card>
        <CardBody>
          <CustomDashboardsTable {...props} searchStr={searchStr} />
        </CardBody>
      </Card>
    </Container>
  );
};

CustomDashboard.propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

export default CustomDashboard;
