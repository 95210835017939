import { CommitmentServices } from 'usage/constants/costAndUsageConstants';
import { AwsServiceNames } from 'shared/constants/awsConstants';

export const COMMITMENT_TABLE_COLUMNS = [
  { name: 'subscriptionId', title: 'Subscription Id', getCellValue: (row) => row.subscriptionId },
  { name: 'linkedAccountName', title: 'Linked Account', getCellValue: (row) => row.linkedAccountName },
  { name: 'instanceType', title: 'Instance Type', getCellValue: (row) => row.instanceType },
  { name: 'region', title: 'Region', getCellValue: (row) => row.region },
  { name: 'UtilizationPercentage', title: 'Utilization', getCellValue: (row) => row.UtilizationPercentage },
  { name: 'TotalPotentialRISavings', title: 'Potential Savings', getCellValue: (row) => row.TotalPotentialRISavings },
  { name: 'NetRISavings', title: 'Net Savings', getCellValue: (row) => row.NetRISavings },
  { name: 'chargeBack', title: 'Chargeback' },
  {
    name: 'flexibilityUtilizationPercentage',
    title: 'RI Flexibility ',
    getCellValue: (row) => row.flexibilityUtilizationPercentage,
  },
  { name: 'riFlexibility', title: ' ' },
];
export const COMMITMENT_TABLE_COLUMN_WIDTHS_AWS = [
  { columnName: 'linkedAccountId', width: 160 },
  { columnName: 'linkedAccountName', width: 150 },
  { columnName: 'instanceType', width: 150 },
  { columnName: 'riRecommLink', width: 150 },
  { columnName: 'region', width: 150 },
  { columnName: 'UtilizationPercentage', width: 150 },
  { columnName: 'startDateTime', width: 150 },
  { columnName: 'endDateTime', width: 150 },
  { columnName: 'TotalPotentialRISavings', width: 150 },
  { columnName: 'NetRISavings', width: 150 },
  { columnName: 'chargeBack', width: 150 },
  { columnName: 'flexibilityUtilizationPercentage', width: 150 },
  { columnName: 'riFlexibility', width: 150 },
  { columnName: 'status', width: 150 },
];

export const COMMITMENT_TABLE_COLUMN_WIDTHS_AZURE = [
  { columnName: 'reservationId', width: 300 },
  { columnName: 'linkedAccount', width: 300 },
  { columnName: 'skuName', width: 300 },
  { columnName: 'UtilizationPercentage', width: 300 },
];

export const COMMITMENT_TABLE_CSV_HEADERS = [
  { name: 'subscriptionId', title: 'Subscription Id' },
  {
    name: 'linkedAccountName',
    title: 'Account',
  },
  { name: 'instanceType', title: 'Instance Type' },
  { name: 'region', title: 'Region' },
  { name: 'UtilizationPercentage', title: 'Utilization' },
  { name: 'TotalPotentialRISavings', title: 'Potential Savings' },
  { name: 'NetRISavings', title: 'Net Savings' },
  { name: 'chargeBack', title: 'Chargeback' },
  { name: 'flexibilityUtilizationPercentage', title: 'Flexibility Utilization' },
  { name: 'riFlexibility', title: 'RI Flexibility' },
];

export const COMMITMENT_TABLE_COLUMN_EXTENSIONS_AWS = [
  { columnName: 'subscriptionId', align: 'center' },
  { columnName: 'linkedAccountName', align: 'center' },
  { columnName: 'instanceType', align: 'center' },
  { columnName: 'region', align: 'center' },
  { columnName: 'UtilizationPercentage', align: 'center' },
  { columnName: 'startDateTime', align: 'center' },
  { columnName: 'endDateTime', align: 'center' },
  { columnName: 'TotalPotentialRISavings', align: 'center' },
  { columnName: 'NetRISavings', align: 'center' },
  { columnName: 'chargeBack', align: 'center' },
  { columnName: 'flexibilityUtilizationPercentage', align: 'center' },
  { columnName: 'riFlexibility', align: 'center' },
  { columnName: 'status', align: 'center' },
];

export const COMMITMENT_TABLE_COLUMN_EXTENSIONS_AZURE = [
  { columnName: 'reservationId', align: 'center' },
  { columnName: 'linkedAccount', align: 'center' },
  { columnName: 'skuName', align: 'center' },
  { columnName: 'UtilizationPercentage', align: 'center' },
];

export const COMMITMENT_DETAIL_NAMES = [
  'arn',
  'subscriptionStatus',
  'subscriptionType',
  'startDateTime',
  'endDateTime',
  'numberOfInstances',
  'offeringType',
  'platform',
  'AmortizedUpfrontFee',
  'AmortizedRecurringFee',
  'TotalAmortizedFee',
  'upfrontFee',
  'TotalUpfrontFee',
  'edp',
  'totalUpfrontFeeBeforeRiDiscount',
  'totalNetUpfrontFee',
  'reservationOrderId',
  'usedHours',
  'reservedHours',
];
export const MAP_COMMITMENT_DETAIL_VALUES_TO_DISPLAY_NAMES = new Map([
  ['arn', 'ARN'],
  ['subscriptionStatus', 'Subscription Status'],
  ['subscriptionType', 'Subscription Type'],
  ['startDateTime', 'Start Date'],
  ['endDateTime', 'End Date'],
  ['numberOfInstances', 'Number of Instances'],
  ['offeringType', 'Offering Type'],
  ['platform', 'Platform'],
  ['AmortizedUpfrontFee', 'Amortized Upfront Fee'],
  ['AmortizedRecurringFee', 'Amortized Recurring Fee'],
  ['TotalAmortizedFee', 'Total Amortized Fee'],
  ['upfrontFee', 'Upfront Fee'],
  ['TotalUpfrontFee', 'Total Upfront Fee'],
  ['edp', 'EDP'],
  ['totalUpfrontFeeBeforeRiDiscount', 'Total Upfront Fee Before RI Discount'],
  ['totalNetUpfrontFee', 'Total Net Upfront Fee'],
  ['reservationOrderId', 'Reservation Order ID'],
  ['usedHours', 'Used Hours'],
  ['reservedHours', 'Reserved Hours'],
]);
export const RI_SERVICE_FILTER_OPTIONS = [
  { label: 'EC2', value: CommitmentServices.EC2_COMPUTE_RI },
  { label: 'RDS', value: CommitmentServices.RDS_RI },
  { label: 'ElastiCache', value: CommitmentServices.ELASTIC_CACHE_RI },
  { label: 'Redshift', value: CommitmentServices.REDSHIFT_RI },
  { label: 'OpenSearch', value: CommitmentServices.OPEN_SEARCH_RI },
];
export const RI_SERVICE_FILTER_OPTIONS_FULL_NAMES = [
  { label: 'EC2', value: AwsServiceNames.EC2 },
  { label: 'RDS', value: AwsServiceNames.RDS },
  { label: 'ElastiCache', value: AwsServiceNames.ELASTICACHE },
  { label: 'Redshift', value: AwsServiceNames.REDSHIFT },
  { label: 'OpenSearch', value: AwsServiceNames.OPENSEARCH },
];
export const TARGET_GOALS_TYPES = [
  { label: 'Utilization', value: 'utilization' },
  { label: 'Coverage', value: 'coverage' },
];
export const mapAwsServiceNamesToRiFilterNames = new Map([
  [AwsServiceNames.EC2, 'EC2'],
  [AwsServiceNames.RDS, 'RDS'],
  [AwsServiceNames.ELASTICACHE, 'ElastiCache'],
  [AwsServiceNames.REDSHIFT, 'Redshift'],
  [AwsServiceNames.ELASTICSEARCH, 'Elasticsearch'],
]);

export const mapTypeValueToLabel = new Map([
  ['utilization', 'Utilization'],
  ['coverage', 'Coverage'],
]);

// MODAL ///////

export const RI_MODAL_TABLE_COLUMNS = [
  { name: 'resourceId', title: 'Resource Id' },
  { name: 'resourceName', title: 'Resource Name' },
  { name: 'instanceType', title: 'Instance Type' },
  { name: 'linkedAccountId', title: 'Account Id' },
  { name: 'linkedAccountName', title: 'Account Name' },
  { name: 'totalUsageQuantity', title: 'Total Usage' },
  { name: 'publicCost', title: 'Public Cost' },
  { name: 'actualCost', title: 'Actual Cost' },
  { name: 'diffCost', title: 'Cost Difference' },
  { name: 'usageDate', title: 'Usage Date' },
];
export const RI_MODAL_TABLE_CURRENCY_COLUMNS = ['publicCost', 'actualCost', 'diffCost'];
export const RI_MODAL_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'resourceId', width: 150 },
  { columnName: 'resourceName', width: 150 },
  { columnName: 'instanceType', width: 150 },
  { columnName: 'linkedAccountId', width: 150 },
  { columnName: 'linkedAccountName', width: 150 },
  { columnName: 'totalUsageQuantity', width: 150 },
  { columnName: 'publicCost', width: 150 },
  { columnName: 'actualCost', width: 150 },
  { columnName: 'diffCost', width: 150 },
  { columnName: 'usageDate', width: 150 },
];

export const RI_MODAL_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'totalUsageQuantity', align: 'center' },
  { columnName: 'publicCost', align: 'center' },
  { columnName: 'actualCost', align: 'center' },
  { columnName: 'diffCost', align: 'center' },
];

export const CB_MODAL_TABLE_COLUMNS = [
  { name: 'instanceType', title: 'Instance Type' },
  { name: 'linkedAccountId', title: 'Linked Account Id' },
  { name: 'linkedAccountName', title: 'Linked Account Name' },
  { name: 'resourceId', title: 'Resource ID' },
  { name: 'resourceName', title: 'Resource Name' },
  { name: 'usageDate', title: 'Usage Date' },
];
export const CB_MODAL_TABLE_CURRENCY_COLUMNS = [];
export const CB_MODAL_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'instanceType', width: 150 },
  { columnName: 'linkedAccountId', width: 150 },
  { columnName: 'linkedAccountName', width: 150 },
  { columnName: 'resourceId', width: 150 },
  { columnName: 'resourceName', width: 150 },
  { columnName: 'usageDate', width: 150 },
];
export const SP_CB_MODAL_TABLE_COLUMNS = [
  { name: 'instanceType', title: 'Instance Type' },
  { name: 'linkedAccountId', title: 'Linked Account Id' },
  { name: 'linkedAccountName', title: 'Linked Account Name' },
  { name: 'savings', title: 'Savings' },
  { name: 'quantity', title: 'Quantity' },
  { name: 'usageDate', title: 'Usage Date' },
];
export const SP_CB_MODAL_TABLE_CURRENCY_COLUMNS = ['savings'];
export const SP_CB_MODAL_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'instanceType', width: 150 },
  { columnName: 'linkedAccountId', width: 150 },
  { columnName: 'linkedAccountName', width: 150 },
  { columnName: 'savings', width: 150 },
  { columnName: 'quantity', width: 150 },
  { columnName: 'usageDate', width: 150 },
];

export const SP_UTILIZATION_TABLE_COLUMNS = [
  { name: 'linkedAccountId', title: 'Account Id', getCellValue: (row) => row.linkedAccountId },
  { name: 'linkedAccountName', title: 'Account Name', getCellValue: (row) => row.linkedAccountName },
  { name: 'HourlyCommitment', title: 'Hourly Commitment', getCellValue: (row) => row.HourlyCommitment },
  // { name: 'SavingsPlansType', title: 'Savings Plans Type', getCellValue: row => row.SavingsPlansType },
  { name: 'NetSavings', title: 'Net Savings', getCellValue: (row) => row.NetSavings },
  { name: 'UtilizationPercentage', title: 'Utilization', getCellValue: (row) => row.UtilizationPercentage },
  { name: 'startDate', title: 'Start Date', getCellValue: (row) => row.StartDateTime },
  { name: 'endDate', title: 'End Date', getCellValue: (row) => row.EndDateTime },
  { name: 'chargeBack', title: 'Charge Back' },
];
export const SP_UTILIZATION_TABLE_COLUMN_WIDTHS = [
  { columnName: 'linkedAccountId', width: 100 },
  { columnName: 'linkedAccountName', width: 100 },
  // { columnName: 'SavingsPlansType', width: 100 },
  { columnName: 'status', width: 100 },
  { columnName: 'HourlyCommitment', width: 100 },
  { columnName: 'UtilizationPercentage', width: 100 },
  { columnName: 'NetSavings', width: 100 },
  { columnName: 'startDate', width: 120 },
  { columnName: 'endDate', width: 120 },
  { columnName: 'chargeBack', width: 100 },
];

export const SP_UTILIZATION_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'linkedAccountId', align: 'center' },
  { columnName: 'linkedAccountName', align: 'center' },
  // { columnName: 'SavingsPlansType', align: 'center' },
  { columnName: 'HourlyCommitment', align: 'center' },
  { columnName: 'UtilizationPercentage', align: 'center' },
  { columnName: 'NetSavings', align: 'center' },
  { columnName: 'startDate', align: 'center' },
  { columnName: 'endDate', align: 'center' },
  { columnName: 'chargeBack', align: 'center' },
];

export const SP_UTILIZATION_DETAIL_NAMES = [
  'SavingsPlanARN',
  'StartDateTime',
  'EndDateTime',
  'InstanceFamily',
  'Region',
  'PurchaseTerm',
  'PaymentOption',
  'RecurringHourlyFee',
  'UpfrontFee',
  'UnusedCommitment',
  'UsedCommitment',
  'TotalCommitment',
  'OnDemandCostEquivalent',
  'AmortizedUpfrontCommitment',
  'AmortizedRecurringCommitment',
  'TotalAmortizedCommitment',
  'TotalUpfrontFee',
  'SavingsPlanID',
];
export const SP_UTILIZATION_DETAIL_NAMES_MAP = new Map([
  [
    'Details',
    [
      'StartDateTime',
      'EndDateTime',
      'InstanceFamily',
      'Region',
      'PurchaseTerm',
      'PaymentOption',
      'RecurringHourlyFee',
      'UpfrontFee',
      'TotalUpfrontFee',
    ],
  ],
  ['Commitment', ['UnusedCommitment', 'UsedCommitment', 'TotalCommitment', 'OnDemandCostEquivalent']],
  ['Amortized', ['AmortizedUpfrontCommitment', 'AmortizedRecurringCommitment', 'TotalAmortizedCommitment']],
]);
export const SP_UTILIZATION_SERVICE_FILTER_OPTIONS = [
  { label: 'EC2 Instance', value: 'EC2InstanceSavingsPlans' },
  { label: 'Compute', value: 'ComputeSavingsPlans' },
];

export const RI_CSV_HEADERS = [
  'accountId',
  'linkedAccountId',
  'AWS LINKED ACCOUNT',
  'subscriptionId',
  'startDateTime',
  'endDateTime',
  'month',
  'year',
  'subscriptionType',
  'numberOfInstances',
  'upfrontFee',
  'TotalUpfrontFee',
  'totalUpfrontFeeBeforeRiDiscount',
  'edp',
  'totalNetUpfrontFee',
  'NICE AWS COMBINATION',
  'Company',
  'Division',
  'Department',
  'Account',
  'Region',
  'Product',
  'Project',
  'Company Name',
  'Division Name',
  'Top Division',
  'Focal Point',
  'subscriptionStatus',
  'reservationARN',
  'commitmentType',
  'commitmentService',
  'AmortizedUpfrontFee',
  'AmortizedRecurringFee',
  'TotalAmortizedFee',
  'UtilizationPercentage',
  'flexibilityUtilizationPercentage',
  'TotalPotentialRISavings',
  'NetRISavings',
  'hourlyRecurringFee',
  'PurchasedHours',
  'creationTime',
  'OnDemandCostOfRIHoursUsed',
  'TotalActualHours',
  'offeringType',
  'scope',
  'cancellationDateTime',
  'averageOnDemandHourlyRate',
  'modificationStatus',
  'tenancy',
  'UnusedHours',
  'region',
  'leaseId',
  'effectiveHourlyRate',
  'sizeFlexibility',
  'instanceFamily',
  'totalAssetValue',
  'service',
  'platform',
  'linkedAccountName',
  'uuid',
  'instanceType',
  'availabilityZone',
  'arn',
];

export const SP_CSV_HEADERS = [
  'accountId',
  'linkedAccountId',
  'AWS LINKED ACCOUNT',
  'SavingsPlanID',
  'StartDateTime',
  'EndDateTime',
  'month',
  'year',
  'PaymentOption',
  'numberOfInstances',
  'UpfrontFee',
  'TotalUpfrontFee',
  'NICE AWS COMBINATION',
  'Company',
  'Division',
  'Department',
  'Account',
  'Region',
  'Product',
  'Project',
  'Company Name',
  'Division Name',
  'Top Division',
  'Focal Point',
  'Status',
  'SavingsPlanARN',
  'commitmentType',
  'commitmentService',
  'AmortizedRecurringCommitment',
  'AmortizedUpfrontCommitment',
  'HourlyCommitment',
  'OnDemandCostEquivalent',
  'RecurringHourlyFee',
  'TotalAmortizedCommitment',
  'TotalCommitment',
  'UsedCommitment',
  'UtilizationPercentage',
  'NetSavings',
  'creationTime',
  'Region',
  'PurchaseTerm',
  'InstanceFamily',
  'SavingsPlansType',
  'linkedAccountName',
  'uuid',
  'UnusedCommitment',
];

export const mapCsvHeadersKeyToLabel = new Map([
  ['TotalUpfrontFee', 'Total Upfront Fee'],
  ['edp', 'EDP'],
  ['totalUpfrontFeeBeforeRiDiscount', 'Total Upfront Fee Before Ri Discount'],
  ['totalNetUpfrontFee', 'Total Net Up front Fee'],
]);

export const RI_OFFERING_TYPES = {
  CONVERTIBLE: 'convertible',
  STANDARD: 'standard',
};

export const RI_REC_ACTION_OPTIONS = {
  SELL: 'Sell',
  CONVERT: 'Convert',
};
