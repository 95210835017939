import React from 'react';
import PropTypes from 'prop-types';
import AdminAccountItem from './AdminAccountItem';

export default function AdminParentAccountItem({ user, style, handleUserClicked, updateCtgOpenStatus, index }) {
  function toggle() {
    if (updateCtgOpenStatus) {
      updateCtgOpenStatus(user.userKey, !user.isCtgOpen);
    }
  }
  return (
    <div className={`parentAccountItem ${user.isCtgOpen ? 'open' : ''}`}>
      <div style={user.childs.length > 0 ? { width: '80%', display: 'flex' } : {}}>
        <AdminAccountItem id={index} key={index} style={style} user={user} handleUserClicked={handleUserClicked} />
      </div>
      {user.childs.length > 0 ? (
        <button
          className="topbarChevron"
          style={{ width: '20%', height: '32px', alignItems: 'center' }}
          onClick={toggle}
          type="button"
        >
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
      ) : (
        ''
      )}
    </div>
  );
}

AdminParentAccountItem.propTypes = {
  user: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  handleUserClicked: PropTypes.func.isRequired,
  updateCtgOpenStatus: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
