/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    {/* <ul>
      <li>Using the AWS CLI:</li>
      <ol>
        <li>
          {'Find available Reserved Instances using the describe-reserved-instances-offerings command:'}
          <div className="project-summary__copy-button">
            <CopyFromCmdBoxButton boxId="1" />
            <pre id="1">
              <code>
                aws ec2 describe-reserved-instances-offerings --region
                <span style={{ color: 'red' }}> {rec.region} </span>
                --instance-type{' '}
                <span style={{ color: 'red' }}>
                  {rec.model}.{rec.size}
                </span>{' '}
                --offering-class convertible --product-description <span style={{ color: 'red' }}>{`"${rec.os}"`}</span> --instance-tenancy
                default --filters Name=scope,Values=Region
              </code>
            </pre>
          </div>
          <br />
        </li>
        <li>
          {`Use the purchase-reserved-instances-offering command to buy your Reserved Instance.
You must specify the Reserved Instance offering ID you obtained the previous step and you must specify
the number of instances for the reservation:`}
          <div className="project-summary__copy-button">
            <CopyFromCmdBoxButton boxId="2" />
            <pre id="2">
              <code>
                aws ec2 purchase-reserved-instances-offering --reserved-instances-offering-id{' '}
                <span style={{ color: 'red' }}>RESERVED_INSTANCE_OFFERING_ID</span> --instance-count{' '}
                <span style={{ color: 'red' }}>{rec.numOfRecommRIs}</span>
              </code>
            </pre>
          </div>
          <br />
        </li>
        <li>
          {'Use the describe-reserved-instances command to get the status of your Reserved Instance:'}
          <div className="project-summary__copy-button">
            <CopyFromCmdBoxButton boxId="3" />
            <pre id="3">
              <code>aws ec2 describe-reserved-instances</code>
            </pre>
          </div>
          <br />
        </li>
      </ol>
      <li>
        Using the AwsOnboarding Console:
        <NavLink
          target="_blank"
          href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ri-market-concepts-buying.html#ri-buying-convertible"
        >
          Buy RI using the AWS Console
        </NavLink>
      </li>
    </ul> */}
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
