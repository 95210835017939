import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import { Card, CardBody, Container, Row } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { isRoleIdOfAdminType } from 'users/utils/rolesUtil';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { withRolesContextConsumer, withRolesProvider } from 'users/contexts/RolesProvider';
import { usePermissions } from 'app/contexts/PermissionsContext';
import InvoicesPreferences from './components/InvoicesPreferences';
import BillingProfile from './components/BillingProfile';
import PreferencesTableDraggable from './components/PreferencesTableDraggable';
import AccountType from './components/AccountType';

const RebillingPreferences = ({ usersStore, roles, appStore, filtersValuesMap }) => {
  const { isHasPermission } = usePermissions();
  const payerAccounts = filtersValuesMap.get(AwsCommonFields.PAYER_ACCOUNT);
  const isAdmin = appStore.isKeyCloakManagement
    ? isHasPermission(OrganizationEntityCategory.Organization, Action.Update)
    : isRoleIdOfAdminType(roles || [], usersStore.getCurrDisplayedUserRole());
  const arrOfNavItems = [
    { id: 0, name: 'Account Type', disabled: !isEmptyArray(payerAccounts) || !isAdmin },
    { id: 1, name: 'Billing Profile', disabled: usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.AWS },
    { id: 2, name: 'Billing Rules Prioritization' },
    { id: 3, name: 'Invoice' },
  ].filter((a) => !a.disabled);
  const payerOptions = !isEmptyArray(payerAccounts)
    ? payerAccounts.map((value) => ({ label: value, value: value.match(/\s\(([^()]+)\)/)[1] }))
    : [{ value: usersStore.getCurrDisplayedAccountId() }];
  return (
    <Container>
      <PageHeader title={PageNames.REBILLING_PREFERENCES} />
      <Card>
        <CardBody>
          <Row style={{ maxWidth: 1300 }}>
            <ColoredTabs arrOfNavItems={arrOfNavItems} defaultAccountIndex={arrOfNavItems[0].id} vertical>
              <AccountType
                usersStore={usersStore}
                payerAccountOptions={payerOptions}
                isAllAccounts={!isEmptyArray(payerAccounts)}
              />
              <BillingProfile
                usersStore={usersStore}
                payerAccountOptions={payerOptions}
                isAllAccounts={!isEmptyArray(payerAccounts)}
              />
              <PreferencesTableDraggable
                usersStore={usersStore}
                payerAccountOptions={payerOptions}
                isAllAccounts={!isEmptyArray(payerAccounts)}
              />
              <InvoicesPreferences
                usersStore={usersStore}
                payerAccountOptions={payerOptions}
                isAllAccounts={!isEmptyArray(payerAccounts)}
              />
            </ColoredTabs>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

RebillingPreferences.propTypes = {
  usersStore: PropTypes.object.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
};

const ObserverRebillingPreferences = withRolesProvider(
  withRolesContextConsumer(withInvoiceFiltersContextConsumer(observer(RebillingPreferences))),
);
export default ObserverRebillingPreferences;
