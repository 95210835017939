/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import ICONS from 'shared/constants/assetsConstants';
import IconFromPng from 'shared/components/IconFromPng';
// import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

export default class CollapseComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
    open: PropTypes.bool,
    openIcon: PropTypes.object,
    closeIcon: PropTypes.object,
    titleStyle: PropTypes.object,
    style: PropTypes.object,
  };

  static defaultProps = {
    title: '',
    className: '',
    open: false,
    openIcon: <MinusIcon />,
    closeIcon: <PlusIcon />,
    titleStyle: {},
    style: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: this.props.open,
      status: 'closed',
      icon: this.props.closeIcon,
    };
  }

  componentDidUpdate(prevProps) {
    const { open: currOpen } = this.props;
    if (prevProps.open === false && currOpen === true) {
      this.setState({ collapse: currOpen });
    }
  }

  onEntering = () => {
    this.setState({ status: 'opening', icon: this.props.openIcon });
  };

  onEntered = () => {
    this.setState({ status: 'opened', icon: this.props.openIcon });
  };

  onExiting = () => {
    this.setState({ status: 'closing', icon: this.props.closeIcon });
  };

  onExited = () => {
    this.setState({ status: 'closed', icon: this.props.closeIcon });
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { className, title, children, titleStyle, style } = this.props;
    return (
      <div style={style} className={`collapse__wrapper ${this.state.status} ${className}`}>
        <button onClick={this.toggle} className="collapse__title" style={titleStyle}>
          <p style={{ float: 'left' }}>{title}</p>
          <p style={{ float: 'right' }}>
            <LinkIconButton>
              <span />
              <IconFromPng size={15} icon={this.state.status === 'closed' ? ICONS.downArrow : ICONS.upArrow} />
            </LinkIconButton>
          </p>
          {/* {this.state.status === 'closed' ? '<' : '>'} */}
          <p />
          {/* {this.state.icon} */}
        </button>
        <Collapse
          isOpen={this.state.collapse}
          className="collapse__content"
          onEntering={this.onEntering}
          onEntered={this.onEntered}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <div>{children}</div>
        </Collapse>
      </div>
    );
  }
}
