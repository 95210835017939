// basic number comparator creating a descending sort
import moment from 'moment';

export const numSortComparatorFunc = (a, b) => {
  let result = 0;
  if (a < b) {
    result = 1;
  } else if (b < a) {
    result = -1;
  }

  return result;
};

export const descendingNumSortComparatorFunc = (a, b) => numSortComparatorFunc(a, b);

export const ascendingNumSortComparatorFunc = (a, b) => numSortComparatorFunc(b, a);

// uses to compare between object properies by passing an object key
export const propComparator = (objKey) =>
  // eslint-disable-next-line func-names
  function (a, b) {
    if (a[objKey] && typeof a[objKey] !== 'string') {
      if (a[objKey].name?.toLowerCase() < b[objKey].name?.toLowerCase()) {
        return -1;
      }
      if (a[objKey].name?.toLowerCase() > b[objKey].name?.toLowerCase()) {
        return 1;
      }
      return 0;
    }
    if (a[objKey]?.toLowerCase() < b[objKey]?.toLowerCase()) {
      return -1;
    }
    if (a[objKey]?.toLowerCase() > b[objKey]?.toLowerCase()) {
      return 1;
    }
    return 0;
  };

export function trendLinesRowsSort(a, b) {
  function getCompareNumber(value) {
    if (typeof value === 'object') {
      return value.cost ?? '-';
    }
    return value;
  }
  const aCompareNumber = getCompareNumber(a);
  const bCompareNumber = getCompareNumber(b);
  // Rows with values '-' should be displayed last
  if (aCompareNumber === '-') {
    return -1;
  }
  if (bCompareNumber === '-') {
    return 1;
  }
  return aCompareNumber - bCompareNumber;
}

export const sortMonth = (a, b) => moment().month(a).format('M') - moment().month(b).format('M');
