export default class LoginPhases {
  static LOG_PHASE_EMAIL = 'email';
  static LOG_PHASE_LOGIN = 'login';
  static LOG_PHASE_REQUEST_CODE = 'requestCode';
  static LOG_PHASE_NEW_PASSWORD = 'newPasswordEntry';
  static LOG_PHASE_BACK_LOGIN = 'backToLogIn';
  static TEXT_EMAIL = 'Next';
  static LOADING_TEXT_EMAIL = 'Searching domain..';
  static TEXT_LOGIN = 'Login';
  static LOADING_TEXT_LOGIN = 'Logging in..';
  static TEXT_CODE = 'Send code';
  static LOADING_TEXT_CODE = 'Sending code..';
  static TEXT_NEW_PASS = 'Update password';
  static LOADING_TEXT_NEW_PASS = 'Updating password..';
  static TEXT_BACK_LOGIN = 'Back to login';
}
