import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import CreateRoleModal from './CreateRoleModal';

const CreateRole = () => {
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);

  return (
    <>
      <Button
        text="Create Role"
        onClick={() => {
          setIsCreateRoleModalOpen(true);
        }}
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        automationId="newRole"
      />
      {isCreateRoleModalOpen && (
        <CreateRoleModal
          isOpen={isCreateRoleModalOpen}
          setIsOpen={setIsCreateRoleModalOpen}
          saveRole={() => {}}
          editRole={null}
        />
      )}
    </>
  );
};

export default CreateRole;
