/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { DynamoDbIdleRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class DynamoDbIdleRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new DynamoDbIdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.DDB_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.action = 'Terminate';
    this._recommendation.title = `${action || ''} DynamoDB Idle`;
  }

  build() {
    super.build();
    const { table_name: tableName, starting_time: startingTime, days_to_check: daysToCheck } = this._rawRecommendation;
    this._recommendation.startingTime = startingTime;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.tableName = tableName;
  }
}
