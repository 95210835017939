import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from 'mdi-react/SearchIcon';
import Spinner from 'shared/components/andtComponents/Spinner';

const TopbarSearch = ({
  handler,
  currentValue,
  formStyle,
  onSearchClick,
  style,
  inputStyle,
  id,
  placeholder,
  isLoading,
  iconStyle,
}) => (
  <form id={id} style={formStyle} className="topbar__search" onSubmit={(e) => e.preventDefault()}>
    <input
      id={`${id}_input`}
      style={{ minWidth: '200px', ...inputStyle }}
      className="topbar__search-field--open"
      value={currentValue}
      onChange={(e) => handler(e)}
      placeholder={placeholder}
      autoComplete="off"
    />
    <div className="searchIconEnv" style={{ ...iconStyle }} onClick={onSearchClick}>
      <SearchIcon style={{ width: '100%', ...style }} />
    </div>
    {isLoading && (
      <div className="topbar__search-loader">
        <Spinner />
      </div>
    )}
  </form>
);
TopbarSearch.propTypes = {
  handler: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  style: PropTypes.object,
  formStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  onSearchClick: PropTypes.func,
  iconStyle: PropTypes.object,
};

TopbarSearch.defaultProps = {
  currentValue: undefined,
  style: {},
  formStyle: {},
  inputStyle: {},
  id: '',
  placeholder: '',
  isLoading: false,
  iconStyle: {},
  onSearchClick: () => {},
};
export default TopbarSearch;
