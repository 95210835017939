/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { DocumentDbLowUtilizationRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class DocumentDbLowUtilizationRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new DocumentDbLowUtilizationRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Elasticsearch`;
  }

  build() {
    super.build();
    const {
      arn,
      connections,
      engine,
      engine_version: engineVersion,
      db_cluster_resource_id: dbClusterResourceId,
      primary_db_instance_id: primaryDbInstanceId,
      max_connections: maxConnections,
      num_members: numMembers,
      multi_az: multiAz,
      zone_tag: region,
      days_to_check: daysToCheck,
    } = this._rawRecommendation;

    this._recommendation.arn = arn;
    this._recommendation.engine = engine;
    this._recommendation.engineVersion = engineVersion;
    this._recommendation.connections = connections;
    this._recommendation.maxConnections = maxConnections;
    this._recommendation.primaryDbInstanceId = primaryDbInstanceId;
    this._recommendation.dbClusterResourceId = dbClusterResourceId;
    this._recommendation.numMembers = numMembers;
    this._recommendation.multiAz = multiAz;
    this._recommendation.region = region;
    this._recommendation.daysToCheck = daysToCheck;

    // BETA
    // this._recommendation.isBeta = true;
  }
}
