import React from 'react';

export const CustomizedActiveDot = (props) => {
  const { cx, cy, fill } = props;

  return (
    <svg>
      <circle cx={cx} cy={cy} r="3" fill={fill} stroke={fill} strokeOpacity="0.3" strokeWidth="4.5" />
    </svg>
  );
};
