import PropTypes from 'prop-types';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';
import { RecommendationTypes, RecommendationTypesDisplay } from 'recommendations/constants/recommendationsConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import styles from './systemCharts.module.scss';

const recommendationTypeToName = new Map();
Object.keys(RecommendationTypes).forEach((key) => {
  recommendationTypeToName.set(RecommendationTypes[key], RecommendationTypesDisplay[key]);
});

const Top5SavingsChart = ({ data, overrideCurrency }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  if (!data || !data.length) {
    return null;
  }

  const topSavings = data.filter((d) => d.savings > 0);
  const topPotentialSavings = data.filter((d) => d.savings === 0);
  const topSavingsSum = topSavings.reduce((acc, item) => acc + item.savings, 0);
  const topPotentialSavingsSum = topPotentialSavings.reduce((acc, item) => acc + item.potentialSavings, 0);
  return (
    <div className="d-flex flex-column">
      {topSavings.length ? (
        <>
          <h6 className="bold-text mb-2">
            Top Savings: {getCurrencyNumber(topSavingsSum, 2, { roundNumber: true }, overrideCurrency)}
          </h6>
          {topSavings.map(({ recommendationTypeName, savings }) => (
            <div key={recommendationTypeName} className="w-100 mb-3 d-flex">
              <div className="d-flex" style={{ width: '33%' }}>
                <span className="mt-auto pe-2" style={{ lineHeight: 1, color: grey[700] }}>
                  {recommendationTypeToName.get(recommendationTypeName)}
                </span>
              </div>
              <div style={{ width: '67%' }}>
                <p className="text-right initialism" style={{ color: '#3ea3fc' }}>
                  {getCurrencyNumber(savings, 2, { roundNumber: true }, overrideCurrency)}
                </p>
                <LinearProgress
                  title={recommendationTypeName}
                  variant="determinate"
                  value={topSavingsSum === 0 ? 0 : (savings / topSavingsSum) * 100}
                  classes={{ root: styles.root, colorPrimary: styles.colorPrimary, bar: styles.bar }}
                />
              </div>
            </div>
          ))}
        </>
      ) : null}
      {topPotentialSavings.length ? (
        <>
          <h6 className="bold-text mb-2 mt-3">
            Top Potential Savings:{' '}
            {getCurrencyNumber(topPotentialSavingsSum, 2, { roundNumber: true }, overrideCurrency)}
          </h6>
          {topPotentialSavings.map(({ recommendationTypeName, potentialSavings }) => (
            <div key={recommendationTypeName} className="w-100 mb-3 d-flex">
              <div className="d-flex" style={{ width: '33%' }}>
                <span className="mt-auto pe-2" style={{ lineHeight: 1, color: grey[700] }}>
                  {recommendationTypeToName.get(recommendationTypeName)}
                </span>
              </div>
              <div style={{ width: '67%' }}>
                <p className="text-right initialism" style={{ color: '#3ea3fc' }}>
                  {getCurrencyNumber(potentialSavings, 2, { roundNumber: true }, overrideCurrency)}
                </p>
                <LinearProgress
                  title={recommendationTypeName}
                  variant="determinate"
                  value={topPotentialSavingsSum === 0 ? 0 : (potentialSavings / topPotentialSavingsSum) * 100}
                  classes={{ root: styles.root, colorPrimary: styles.colorPrimary, bar: styles.bar }}
                />
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

Top5SavingsChart.propTypes = {
  data: PropTypes.object.isRequired,
  overrideCurrency: PropTypes.string,
};

Top5SavingsChart.defaultProps = {
  overrideCurrency: null,
};

export default Top5SavingsChart;
