// eslint-disable-next-line react/prop-types
import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import classes from './ViewByPanel.module.scss';

export function ViewByPanel({ isIconsViewSelected, onChangeViewClick, viewByPanelWidth, onBlur, containerProps }) {
  const onBlurViewByPanel = (e) => {
    const relatedTargetChanged = { id: containerProps.id };
    onBlur({ ...e, relatedTarget: relatedTargetChanged });
  };

  return (
    <div
      style={{ width: viewByPanelWidth }}
      className={classes.viewByContainer}
      onBlur={onBlurViewByPanel}
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
      tabIndex="0"
      id={containerProps.id}
    >
      <div className={classes.viewByLabel}>View By:</div>
      <GenerateIcon
        className={`${classes.viewByIcon} ${!isIconsViewSelected ? classes.disabled : ''}`}
        iconName={ICONS.grid.name}
        onClick={() => onChangeViewClick({ isIconsViewSelected: true })}
      />
      <GenerateIcon
        className={`${classes.viewByIcon} ${isIconsViewSelected ? classes.disabled : ''}`}
        iconName={ICONS.chartSimpleHorizontal.name}
        onClick={() => onChangeViewClick({ isIconsViewSelected: false })}
      />
    </div>
  );
}

ViewByPanel.propTypes = {
  isIconsViewSelected: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChangeViewClick: PropTypes.func.isRequired,
  viewByPanelWidth: PropTypes.string.isRequired,
  containerProps: PropTypes.object.isRequired,
};
