import { useEffect } from 'react';
import PropTypes from 'prop-types';

const LINK_ID = 'whitelabeling-stylesheet';
export const DEFAULT_THEME = 'default-theme';

const CustomThemeApplier = ({ customThemeName }) => {
  useEffect(() => {
    const link = document.getElementById(LINK_ID);
    link.href = `https://s3.amazonaws.com/whitelabeling-themes/${customThemeName || DEFAULT_THEME}/styles.css`;
  }, [customThemeName]);
  return null;
};

CustomThemeApplier.propTypes = {
  customThemeName: PropTypes.string,
};

CustomThemeApplier.defaultProps = {
  customThemeName: '',
};

export default CustomThemeApplier;
