/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';
import RecommStatusBadge from 'shared/components/RecommStatusBadge';

export default class AlertComponent extends PureComponent {
  static propTypes = {
    divider: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    subhead: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    // backgroundColor: PropTypes.string,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
    isColPaddingZero: PropTypes.bool,
  };

  static defaultProps = {
    isColPaddingZero: false,
    divider: false,
    color: '',
    title: '',
    className: '',
    subhead: '',
    label: '',
    icon: '',
    md: 0,
    lg: 0,
    xl: 0,
    sm: 0,
    xs: 0,
    // backgroundColor: 'none',
  };

  constructor() {
    super();

    this.state = {
      visible: true,
      collapse: true,
      refresh: false,
    };
  }

  onShow = () => {
    this.setState({ visible: true });
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  onCollapse = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  onRefresh = () => {
    // your async logic here
    this.setState({ refresh: !this.state.refresh });
    setTimeout(() => this.setState({ refresh: false }), 5000);
  };

  render() {
    const { md, lg, xl, sm, xs, color, divider, icon, title, subhead, label, className } = this.props;

    if (this.state.visible) {
      return (
        <Col style={{ padding: this.props.isColPaddingZero ? '0px' : null }} md={md} lg={lg} xl={xl} sm={sm} xs={xs}>
          <Card
            className={`panel${this.props.color ? ` panel--${color}` : ''}
            ${divider ? ' panel--divider' : ''}${this.state.collapse ? '' : ' panel--collapse'}
            ${className}`}
          >
            <CardBody className="panel__body">
              {this.state.refresh ? (
                <div className="panel__refresh">
                  <LoadingIcon />
                </div>
              ) : (
                ''
              )}
              <div className="card-header mb-3" style={{ background: 'white' }}>
                <h5 className="panelTitle bold-text text-uppercase">
                  {icon ? <span className={`panel__icon lnr lnr-${icon}`} /> : ''}
                  {title}
                  <RecommStatusBadge badgeType={label} addLeftMargin />
                </h5>
                <h5 className="subhead">{subhead}</h5>
              </div>
              <Collapse isOpen={this.state.collapse}>
                <div className="panel__content">{this.props.children}</div>
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      );
    }
    return '';
  }
}
