import { AWS_ONBOARDING_STEPS, AWS_ONBOARDING_STEPS_SHORT_TITLES } from './AwsOnboardingConstants';

export const handleNextScreen = (screen, isAutomatic, isReseller) => {
  switch (screen) {
    case AWS_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return isAutomatic ? AWS_ONBOARDING_STEPS.AWS_DETAILS : AWS_ONBOARDING_STEPS.CREATE_CUR_FILE;
    case AWS_ONBOARDING_STEPS.CREATE_CUR_FILE:
      return AWS_ONBOARDING_STEPS.AWS_DETAILS;
    case AWS_ONBOARDING_STEPS.AWS_DETAILS:
      if (isAutomatic) {
        return AWS_ONBOARDING_STEPS.VALIDATE_ACCESS;
      }
      return AWS_ONBOARDING_STEPS.GRANT_ACCESS;
    case AWS_ONBOARDING_STEPS.GRANT_ACCESS:
      return AWS_ONBOARDING_STEPS.VALIDATE_ACCESS;
    case AWS_ONBOARDING_STEPS.VALIDATE_ACCESS:
      return AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS;
    case AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS:
      if (isReseller) {
        return AWS_ONBOARDING_STEPS.BILLING_PROFILE;
      }
      return AWS_ONBOARDING_STEPS.PROCESS_DATA;
    case AWS_ONBOARDING_STEPS.BILLING_PROFILE:
      return AWS_ONBOARDING_STEPS.PROCESS_DATA;
    default:
      return screen;
  }
};

export const handlePrevScreen = (screen, isAutomatic, isReseller) => {
  switch (screen) {
    case AWS_ONBOARDING_STEPS.AWS_DETAILS:
      if (isAutomatic) {
        return AWS_ONBOARDING_STEPS.ACCOUNT_TYPE;
      }
      return AWS_ONBOARDING_STEPS.CREATE_CUR_FILE;
    case AWS_ONBOARDING_STEPS.GRANT_ACCESS:
      return AWS_ONBOARDING_STEPS.AWS_DETAILS;
    case AWS_ONBOARDING_STEPS.VALIDATE_ACCESS:
      if (isAutomatic) {
        return AWS_ONBOARDING_STEPS.AWS_DETAILS;
      }
      return AWS_ONBOARDING_STEPS.GRANT_ACCESS;
    case AWS_ONBOARDING_STEPS.PROCESS_DATA:
      if (isReseller) {
        return AWS_ONBOARDING_STEPS.BILLING_PROFILE;
      }
      return AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS;
    case AWS_ONBOARDING_STEPS.CREATE_CUR_FILE:
      return AWS_ONBOARDING_STEPS.ACCOUNT_TYPE;
    case AWS_ONBOARDING_STEPS.BILLING_PROFILE:
      return AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS;
    default:
      return screen;
  }
};

export const trimAccId = (awsAccountID) => awsAccountID.replace(/\b0+/g, '').replace(/[^0-9]/g, '');

export const getPageForPausedOnboarding = (onboarding) => {
  const {
    roleARN,
    validationStatus,
    accountSetupStatus,
    userManagementStatus,
    validationShouldStart,
    invoiceShouldStart,
    invoiceStatus,
  } = onboarding;
  if (
    ['SUCCESS', 'FAILED', 'RUNNING'].includes(invoiceStatus?.status) ||
    (userManagementStatus?.status === 'SUCCESS' && invoiceShouldStart)
  ) {
    return AWS_ONBOARDING_STEPS.PROCESS_DATA;
  }
  const isRunning =
    validationStatus?.status === 'RUNNING' ||
    accountSetupStatus?.status === 'RUNNING' ||
    userManagementStatus?.status === 'RUNNING';
  if (validationShouldStart && isRunning) {
    return AWS_ONBOARDING_STEPS.VALIDATE_ACCESS;
  }
  if (validationShouldStart && ['FAILED'].includes(validationStatus?.status)) {
    return AWS_ONBOARDING_STEPS.VALIDATE_ACCESS;
  }
  return roleARN ? AWS_ONBOARDING_STEPS.VALIDATE_ACCESS : AWS_ONBOARDING_STEPS.GRANT_ACCESS;
};

export const getPageTitleForPausedOnboarding = (onboarding) =>
  AWS_ONBOARDING_STEPS_SHORT_TITLES[getPageForPausedOnboarding(onboarding)];
