import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  CustomClickableFormatter,
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { FILTER_NUMBER_OR_DATE_OPERATIONS } from 'shared/components/andtComponents/TableComponents/const';
import {
  GET_USERS_QUERY_PARAMS,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
  ROLE_SUB_ROLES_COLUMNS,
} from '../../consts';
import { ReactComponent as NoRoles } from '../img/no-roles.svg';
import RemoveButton from '../../components/RemoveButton';

import styles from './RoleTab.module.scss';

export const SubRolesNoData = (subRoles) => {
  if (!subRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoRoles />
      <span className={styles.mainText}>No Sub Roles for this role</span>
    </div>
  );
};

const RoleSubRolesTabTable = ({ subRoles, selectedSubRoles, setSelectedSubRoles, removeSubRoleClicked }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLE_SUB_ROLES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const onSubRoleClick = (row) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(GET_USERS_QUERY_PARAMS.SEARCH.name, row?.displayName);
    window.open(currentUrl.toString(), '_blank');
  };

  return (
    subRoles && (
      <Grid rows={subRoles} columns={getColumns(ROLE_SUB_ROLES_COLUMNS)} automationId="role_sub-roles-tab-list">
        <SortingState
          columnExtensions={getSortingColumns(ROLE_SUB_ROLES_COLUMNS)}
          defaultSorting={getDefaultSorting(ROLE_SUB_ROLES_COLUMNS)}
        />
        <SelectionState selection={selectedSubRoles} onSelectionChange={setSelectedSubRoles} />
        <IntegratedSelection />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.ROLE_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomClickableFormatter {...props} onClick={() => onSubRoleClick(props?.row)} />
          )}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip
              value={props?.row?.createdAt ? moment(props?.row?.createdAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.CREATED_BY.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltip isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.UPDATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip
              value={props?.row?.updatedAt ? moment(props?.row?.updatedAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.UPDATED_BY.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltip isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.REMOVE_SUB_ROLE.columnName]}
          formatterComponent={(props) => (
            <RemoveButton props={props} removeClicked={() => removeSubRoleClicked?.(props.row)} />
          )}
        />
        <TableWrapper virtual noDataCellComponent={SubRolesNoData} height="auto" />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      </Grid>
    )
  );
};

RoleSubRolesTabTable.propTypes = {
  subRoles: PropTypes.array.isRequired,
};

export default RoleSubRolesTabTable;
