import React from 'react';
import PropTypes from 'prop-types';
import { AZURE_PROPERTIES, AZURE_RESERVED_INSTANCE_PROPERTIES } from '../../recommendationPropertyUtils';
import BaseRecommendation from '../baseRecommendation';

const AzureRedisReservedCapacity = ({ recommendation }) => {
  const description = 'Based on past 30 days analysis, we recommend to purchase reserved instance, to optimize costs.';

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      dynamicProps={{
        ...AZURE_PROPERTIES,
        ...AZURE_RESERVED_INSTANCE_PROPERTIES,
      }}
    />
  );
};

AzureRedisReservedCapacity.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureRedisReservedCapacity;
