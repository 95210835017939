import { isEmptyArray } from 'shared/utils/arrayUtils';
import { OPERATORS_KEYS } from 'shared/constants/appConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { AzureCommonFields } from 'shared/constants/azureConstants';
import { GcpCommonFields } from 'shared/constants/gcpConstants';

export const transformCustomFilterFromUI = (filters) =>
  filters.reduce((acc, { field, values, operator }) => {
    if (isEmptyArray(values)) {
      return acc;
    }
    if (field === 'customtags' || field === 'k8slabels') {
      const keys = [...new Set(values.map((v) => v.value.split(': ')[0]))];
      return [
        ...acc,
        ...keys.map((key) => ({
          field,
          prefix: key,
          operator: operator[key] || OPERATORS_KEYS.IS,
          values: values
            .filter((v) => v.value.startsWith(`${key}: `))
            .map((v) => ({
              value: v.value.split(': ')[1],
              label: v.value.split(': ')[1],
            })),
        })),
      ];
    }
    return [...acc, { field, values, operator }];
  }, []);

export const transformCustomFilterFromDB = (filters) =>
  filters.reduce((acc, { field, values, operator, prefix }, index) => {
    if (field === 'customtags' || field === 'k8slabels') {
      const existingFilterInd = acc.findIndex((f, i) => f.field === field && index !== i);
      const existingFilter = acc[existingFilterInd] || {};
      return [
        ...acc.filter((f, ind) => ind !== existingFilterInd),
        {
          field,
          operator: { ...(existingFilter.operator || {}), [prefix]: operator },
          values: [
            ...(existingFilter.values || []),
            ...values.map((v) => ({
              value: `${prefix}: ${v.value}`,
              label: `${prefix}: ${v.label}`,
            })),
          ],
        },
      ];
    }
    return [...acc, { field, values, operator }];
  }, []);

export const filterFieldsTooltip = () => ({
  [CLOUD_TYPE_IDS.AWS]: {
    recommendations: [
      AwsCommonFields.REGION,
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.K8S_NAMESPACE,
      AwsCommonFields.CUE_K8S_LABEL_TAGS,
      AwsCommonFields.K8S_CLUSTER,
      AwsCommonFields.K8S_NODE_GROUP,
      AwsCommonFields.BUSINESS_MAPPING,
    ],
    K8SAndRecommendation: [
      AwsCommonFields.SERVICE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.VIRTUAL_CUSTOM_TAGS,
      AwsCommonFields.OPERATION,
      AwsCommonFields.PURCHASE_OPTION,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.SUB_SERVICE,
      AwsCommonFields.CHARGE_TYPE,
      AwsCommonFields.AVAILABILITY_ZONE,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.USAGE_TYPE,
      AwsCommonFields.CATEGORIES,
    ],
  },
  [CLOUD_TYPE_IDS.AZURE]: {
    recommendations: [AzureCommonFields.REGION, AwsCommonFields.BUSINESS_MAPPING],
    K8SAndRecommendation: [
      AzureCommonFields.RESOURCE_GROUP,
      AzureCommonFields.METER_CATEGORY,
      AzureCommonFields.SUB_METER_CATEGORY,
      AzureCommonFields.SERVICE,
      AzureCommonFields.OPERATION,
      AzureCommonFields.PURCHASE_OPTION,
      AzureCommonFields.LINE_TYPE,
      AzureCommonFields.DIVISION,
      AzureCommonFields.VIRTUAL_CUSTOM_TAGS,
      AzureCommonFields.RESOURCE_ID,
      AzureCommonFields.USAGE_TYPE,
      AzureCommonFields.CATEGORIES,
    ],
  },
  [CLOUD_TYPE_IDS.GCP]: {
    recommendations: [GcpCommonFields.REGION, GcpCommonFields.CUSTOM_TAGS, AwsCommonFields.BUSINESS_MAPPING],
    K8SAndRecommendation: [
      GcpCommonFields.SERVICE,
      GcpCommonFields.OPERATION,
      GcpCommonFields.FAMILY_TYPE,
      GcpCommonFields.DIVISION,
      GcpCommonFields.COST_TYPE_DESCRIPTION,
      GcpCommonFields.RESOURCE_ID,
      GcpCommonFields.CATEGORIES,
    ],
  },
});
