import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DataTypeProvider,
  IntegratedSorting,
  SortingState,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, PagingPanel, TableRowDetail, Table } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ReactComponent as DeleteIcon } from 'shared/img/icons/delete.svg';
import { ReactComponent as PencilIcon } from 'shared/img/icons/pencil.svg';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import AlertRuleDetailRow from './AlertRuleDetailRow';
import AlertRulesTableCell from './AlertRulesTableCell';
import styles from './alertRulesTable.module.scss';

const propTypes = {
  alertRules: PropTypes.array,
  onDeleteAlertRule: PropTypes.func.isRequired,
  onEditAlertRule: PropTypes.func.isRequired,
  searchInput: PropTypes.string,
};

const defaultProps = {
  alertRules: [],
  searchInput: '',
};

const columnExtensions = [
  {
    name: 'name',
    width: 'auto',
  },
  {
    name: 'minAmountChange',
    width: '200px',
  },
  {
    name: 'minPercentChange',
    width: '200px',
  },
  {
    name: 'creationDate',
    width: '150px',
  },
  {
    name: 'updateDate',
    width: '150px',
  },
  {
    name: 'userAction',
    width: '70px',
  },
];

const AlertRulesTable = ({ alertRules, onDeleteAlertRule, onEditAlertRule, searchInput }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const { getCurrencyNumber } = useUserSettingsContext();
  const columns = [
    {
      name: 'name',
      title: 'Alert name',
      getCellValue: (row) => row.ruleName,
    },
    {
      name: 'minAmountChange',
      title: 'Min. amount change',
      getCellValue: (row) => getCurrencyNumber(row.minAmountChange, 0, { roundNumber: true }),
    },
    {
      name: 'minPercentChange',
      title: 'Min. percent change',
      getCellValue: (row) => `${row.minPercentChange}%`,
    },
    {
      name: 'creationDate',
      title: 'Created at',
      getCellValue: (row) => `${moment(row.creationDate).format('YYYY-MM-DD')}`,
    },
    {
      name: 'updateDate',
      title: 'Updated at',
      getCellValue: (row) => `${moment(row.dbUpdateTime).format('YYYY-MM-DD')}`,
    },
    {
      name: 'userAction',
      title: ' ',
    },
  ];
  const renderTableRow = ({ row, tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      /* eslint-disable-next-line react/prop-types */
      className={expandedRows.includes(tableRow.rowId) ? `${styles.expandedRow}` : ''}
    />
  );

  const handleEditAlertRule = (alertRule) => {
    // report to segment
    segmentEvent({
      target: `editAlertRule`,
      data: {
        uuid: alertRule.uuid,
      },
    });
    onEditAlertRule(alertRule.uuid);
  };
  const handleDeleteAlertRule = (uuid) => {
    // report to segment
    segmentEvent({
      target: `deleteAlertRule`,
      data: {
        uuid,
      },
    });
    onDeleteAlertRule(uuid);
  };

  const userActionsFormatter = ({ row }) => (
    <div className="d-flex align-items-center">
      <PencilIcon className={`me-2 ${styles.userActionButton}`} onClick={() => handleEditAlertRule(row)} />
      <DeleteIcon className={styles.userActionButton} onClick={() => handleDeleteAlertRule(row.uuid)} />
    </div>
  );
  return (
    <Grid rows={alertRules} columns={columns}>
      <SortingState defaultSorting={[{ columnName: 'anomalyStart', direction: 'desc' }]} />
      <SearchState value={searchInput} />
      <IntegratedFiltering />
      <IntegratedSorting />
      <PagingState defaultCurrentPage={0} pageSize={5} />
      <IntegratedPaging />
      <TableWrapper
        cellComponent={AlertRulesTableCell}
        rowComponent={renderTableRow}
        columnExtensions={columnExtensions}
      />
      <RowDetailState expandedRowIds={expandedRows} onExpandedRowIdsChange={setExpandedRows} />
      <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
      <TableHeaderRow showSortingControls />
      <TableRowDetail
        messages={{ sortingHint: '' }}
        rowComponent={(props) => <AlertRuleDetailRow {...props} columnsLength={columns.length} />}
      />
      <PagingPanel />
    </Grid>
  );
};

AlertRulesTable.propTypes = propTypes;
AlertRulesTable.defaultProps = defaultProps;
export default AlertRulesTable;
