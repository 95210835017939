/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { Ec2CostRecommendation } from 'recommendations/models/recommTypes';
import { Ec2InstanceBasicData } from 'recommendations/models/recommCommonTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class Ec2CostRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new Ec2CostRecommendation();
  }

  build() {
    super.build();
    const {
      zone_tag: region,
      environment,
      instance_type_model: model,
      instance_type_size: size,
      operation_system: os,
      resource_id: resourceId,
    } = this._rawRecommendation;
    const ec2Instance = new Ec2InstanceBasicData(model, size, os, region, environment, resourceId);
    // FLAT OBJECT FOR NOW
    this._recommendation.model = ec2Instance.model;
    this._recommendation.size = ec2Instance.size;
    this._recommendation.os = ec2Instance.os;
    this._recommendation.region = ec2Instance.region;
    this._recommendation.environment = ec2Instance.environment;
    this._recommendation.instance = ec2Instance.instance;
    this._recommendation.resourceId = ec2Instance.resourceId;
  }
}
