import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import Spinner from 'shared/components/andtComponents/Spinner';
import UserRolesTabHeader from './UserRolesTabHeader';
import UserRolesTabTable from './UserRolesTabTable';

import styles from './UserRolesTab.module.scss';

const UserRolesTab = ({ row }) => {
  const [userRoles, setUserRoles] = useState(row?.roles || []);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [search, setSearch] = useState();
  const [isInProgress, setIsInProgress] = useState(false);

  const { NewTableWrapper } = useTable();
  const { removeRolesFromUsers } = useUsers();

  useEffect(() => {
    setSelectedUserRoles([]);
  }, [row?.roles]);

  useEffect(() => {
    const searchedRoles = search ? row?.roles?.filter((role) => role.displayName.indexOf(search) !== -1) : row?.roles;
    setUserRoles(searchedRoles);
  }, [search, row?.roles]);

  const removeRolesClicked = async () => {
    const rolesToRemove = userRoles.filter((_, index) => selectedUserRoles.includes(index));
    setIsInProgress(true);
    await removeRolesFromUsers.mutateAsync({
      rolesIds: rolesToRemove,
      usersIds: [row?.id],
    });
    setIsInProgress(false);
  };

  const removeRoleClicked = async (role) => {
    setIsInProgress(true);
    await removeRolesFromUsers.mutateAsync({
      rolesIds: [role.id],
      usersIds: [row?.id],
    });
    setIsInProgress(false);
  };

  return isInProgress ? (
    <div className="position-relative">
      <Spinner />
    </div>
  ) : (
    <div className={styles.userRolesTableContainer}>
      <NewTableWrapper className="sub-table" isCompact>
        <UserRolesTabHeader
          removeRoleClicked={removeRolesClicked}
          rolesCount={userRoles?.length}
          selectedUserRoles={selectedUserRoles}
          search={search}
          setSearch={setSearch}
          user={row}
        />
        <UserRolesTabTable
          removeRoleClicked={removeRoleClicked}
          userRoles={userRoles}
          selectedUserRoles={selectedUserRoles}
          setSelectedUserRoles={setSelectedUserRoles}
        />
      </NewTableWrapper>
    </div>
  );
};

export default UserRolesTab;
