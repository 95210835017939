/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RdsTypeChangeRecommendation } from 'recommendations/models/recommTypes';
import RdsCostRecommendationBuilder from 'recommendations/models/builders/rdsCostRecommBuilder';

export default class RdsTypeChangeRecommendationBuilder extends RdsCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RdsTypeChangeRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EBS_TYPE_CHANGE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} RDS Type`;
  }

  build() {
    super.build();
    const {
      starting_time: startingTime,
      days_to_check: daysToCheck,
      iops,
      max_iops_possible_gp2: maxIopsPossibleGp2,
      max_iops_possible_io1: maxIopsPossibleIo1,
      max_iops_data: maxIopsData,
      current_storage_type: currentStorageType,
      recommended_storage_type: recommendedStorageType,
    } = this._rawRecommendation;
    this._recommendation.currentStorageType = currentStorageType;
    this._recommendation.recommendedStorageType = recommendedStorageType;
    this._recommendation.iops = iops;
    this._recommendation.maxIopsPossibleGp2 = maxIopsPossibleGp2;
    this._recommendation.maxIopsPossibleIo1 = maxIopsPossibleIo1;
    this._recommendation.maxIopsData = maxIopsData;
    this._recommendation.startingTime = startingTime;
    this._recommendation.daysToCheck = daysToCheck;
  }
}
