import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>This DB instance was idle in the past {rec.daysToCheck} days. We recommend that you terminate it.</p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
