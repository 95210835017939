/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { EbsUpgradeRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';
import { isEmptyObj } from 'shared/utils/dataPrepareUtil';
import { isEmptyArray } from 'shared/utils/arrayUtils';

export default class ebsUpgradeRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new EbsUpgradeRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EBS_UPGRADE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EBS Upgrade`;
  }

  build() {
    super.build();
    const {
      account_id: accountId,
      zone_tag: zoneTag,
      starting_time: startingTime,
      days_to_check: daysToCheck,
      resources,
      quantity,
      total_cost_recommended: totalCostRecommended,
      total_cost_current: totalCostCurrent,
      potential_savings: potentialSavings,
      ebs_type: ebsType,
      new_ebs_type: newEbsType,
      service,
      signature,
      db_creation_time: dbCreationTime,
      multi_resource_id: multiResourceId,
      resource_tags: resourceTags,
      resource_name: resourceName,
      resource_id: resourceId,
    } = this._rawRecommendation;
    this._recommendation.accountId = accountId;
    this._recommendation.startingTime = startingTime;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.resources = resources;
    this._recommendation.quantity = quantity;
    this._recommendation.region = zoneTag;
    this._recommendation.totalCostRecommended = totalCostRecommended;
    this._recommendation.totalCostCurrent = totalCostCurrent;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.ebsType = ebsType;
    this._recommendation.newEbsType = newEbsType;
    this._recommendation.service = service;
    this._recommendation.signature = signature;
    this._recommendation.dbCreationTime = dbCreationTime;
    this._recommendation.multiResourceId = multiResourceId;
    this._recommendation.rawResourceTags = resourceTags;
    this._recommendation.resourceName = resourceName;
    this._recommendation.resourceId = resourceId;
    // old version of this recommendation contains resources as array
    this._recommendation.separateResource = resourceId && resourceName && isEmptyArray(resources);

    this._recommendation.resourceTags = Object.keys(resourceTags || {})
      .filter((key) => !isEmptyObj(resourceTags[key]))
      .map((key) => {
        const objStr = JSON.stringify(resourceTags[key]);
        return `${key}: ${objStr}`;
      });

    // BETA
    this._recommendation.isBeta = true;
  }
}
