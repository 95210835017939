import { renewToken } from 'users/api/usersApi';
import { getKeycloakInstance } from 'users/containers/LogIn/keycloak.service';

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const setLocalStorage = (key, value, isUseSessionStorage = false) => {
  if (isUseSessionStorage) {
    window.sessionStorage.setItem(key, value);
  } else {
    window.localStorage.setItem(key, value);
  }
};

const getSessionTimeLeft = (tokenPayload) => {
  try {
    const exp = tokenPayload.exp * 1000; // seconds to milliseconds
    const now = Date.now();
    return exp - now;
  } catch {
    return 0;
  }
};

export const checkIfTokenRefreshNeeded = async () => {
  const refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken');
  const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
  if (!authToken || !refreshToken) {
    return false;
  }
  try {
    const username = window.sessionStorage.getItem('username') || window.localStorage.getItem('username');
    const authTokenPayload = parseJwt(authToken);
    const isCognitoToken = authTokenPayload.iss.startsWith('https://cognito-idp');
    const timeLeft = getSessionTimeLeft(authTokenPayload);
    if (username && timeLeft <= 0) {
      // call refresh token api
      if (refreshToken && timeLeft <= 0) {
        let newToken;
        if (isCognitoToken && username) {
          newToken = await renewToken(username, refreshToken);
        } else {
          const keycloakInstance = await getKeycloakInstance();
          await keycloakInstance.updateToken(1);
          newToken = { jwtToken: keycloakInstance.token };
        }
        const { jwtToken: authToken } = newToken || {};
        const decoded = parseJwt(authToken);
        setLocalStorage('authToken', authToken, decoded['custom:useSessionStorage'] === '1');
      }
    }
  } catch (error) {
    // if refresh token fails, remove refresh tokens and logout
    window.localStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('refreshToken');
    window.handleLogout();
  }
  return false;
};
