import React, { useEffect, useState } from 'react';
import SavingsOpportunitiesTable from './savingsOpportunitiesTable';
import CloudWasteChart from './cloudWasteChart';

import classes from './cloudWaste.module.scss';

const CloudWaste = () => {
  const [sizeKey, setSizeKey] = useState(0);
  // window resize event
  useEffect(() => {
    const handleResize = () => {
      setSizeKey((prev) => prev + 1);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={classes.container} key={sizeKey}>
      <SavingsOpportunitiesTable />
      <div className={classes.cloudWasteBorder}>
        <div className={classes.borderArrow} />
      </div>
      <CloudWasteChart />
    </div>
  );
};

export default CloudWaste;
