import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import { handleError } from './helperFunctions';
import {
  fetchDataAccessCostCenters,
  fetchDataAccessPayerAccounts,
  fetchDataAccessResellerCustomers,
} from './apiDataAccessAccounts';

export default function useDataAccessAccounts(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const manageAccountsQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(manageAccountsQueryKey),
    reset: () => queryClient.resetQueries(manageAccountsQueryKey),
    fetchDataAccessPayerAccounts: () =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS],
        () => fetchDataAccessPayerAccounts(),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchDataAccessCostCenters: () =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS],
        () => fetchDataAccessCostCenters(),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchDataAccessResellerCustomers: () =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_RESELLER_CUSTOMERS],
        () => fetchDataAccessResellerCustomers(),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
  };
}
