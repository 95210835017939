import { getFakeT } from 'shared/utils/translationUtil.ts';

const textMap = {
  SAVING_PLAN: 'Saving Plan',
  SAVING_PLANS_EXPIRATION: 'Saving Plans Expiration',
  RESERVED_INSTANCES: 'Reserved Instances',
  DAYS_BEFORE_SAVING_PLAN_EXPIRES: 'Days before my saving plan expires',
  DAYS_BEFORE_RESERVATION_EXPIRES: 'Days before my reservation expires',
  RECIPIENTS: 'Recipients',
  ALERT_RULES: 'Alert Rules',
  EMAIL_PLACEHOLDER: 'email@example',
  MY_EMAIL: 'My Email',
  DELETE_ALERT_TITLE: 'Delete Alert',
  DELETE_ALERT_DESCRIPTION: 'Be advise you are about to delete this alert',
  DELETE: 'Delete',
  COST_CHANGES: 'Cost Changes',
  ALERT_NAME: 'Alert Name',
  ALERT_LEVEL: 'Alert Level',
  ALERT_TYPE: 'Alert Type',
  COMPARING_RULE: 'Comparing Rule',
  DAY: 'Day',
  WHEN: 'When',
  BY_PERCENTS: 'By (%)',
  FOR_$: 'For ($)',
  AND: 'And',
  PRICING_MODE: 'Pricing Mode',
  USAGE_TYPE: 'Usage Type',
  EXAMPLE_EMAIL: 'example@example.com',
  ENABLE_ALL_SAVING_PLANS: 'Enable/Disable all Savings Plans alerts',
  ENABLE_ALL_RESERVED_INSTANCE: 'Enable/Disable all Reserved Instance alerts',
  ENABLE_ALL_SERVICE_COST: 'Enable/Disable all service cost alerts',
  EDIT_INFO: 'To edit - Click the pencil on the upper right.',
  RESERVATION: 'Reservation',
  RESERVATIONS: 'Reservations',
  CREATE_ALERT_RULE: 'Create Alert Rule',
  SERVICE_COST: 'Service Cost Alert Rules',
  COMMITMENT_EXPIRATION: 'Commitment Expiration Alert Rules',
  ALERT_GRANULARITY: 'Alert Granularity',
  COST: 'Cost',
  MIN_COST_DELTA: 'Min Cost Delta',
  MIN_DAILY_COST_DELTA: 'Min. Daily Cost delta',
  EXPLAINER1: 'Enter either Cost impact $ amount, Cost delta % amount, or both',
  EXPLAINER2: 'Represents the difference between the anomalous data point and the expected value for that day.',
};

export default getFakeT(textMap);
