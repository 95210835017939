export class ResellerCommonFields {
  static COST_CENTER = 'Cost Center';
  static DIVISION = 'Division';
  static division = 'division';
}

export const resellerCommonFieldToDisplayField = new Map([
  [ResellerCommonFields.COST_CENTER, 'Customer'],
  [ResellerCommonFields.DIVISION, 'Customer'],
  [ResellerCommonFields.division, 'Customer'],
]);
