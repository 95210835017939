import React, { useState } from 'react';
import Pagination from 'shared/components/andtComponents/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../consts';
import { Search } from '../Search';

import styles from './AddRoles.module.scss';

const AddRolesHeader = ({ selectedRolesCount, search, setSearch, total, pageChanged }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = total >= 0 ? Math.ceil(total / DEFAULT_PAGE_SIZE) : 0;

  const onPageChange = (newPage) => {
    pageChanged(newPage - currentPage);
    setCurrentPage(newPage);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.header}>
          <div className={styles.title}>
            Roles <span className={styles.count}>{selectedRolesCount}</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        <Search search={search} setSearch={setSearch} isDebounce />
        <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default AddRolesHeader;
