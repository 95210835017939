import * as React from 'react';
import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const RecommendationOptionsContext = createContext();

export const RecommendationOptionsContextProvider = ({ children }) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  return (
    <RecommendationOptionsContext.Provider value={{ selectedOptionIndex, setSelectedOptionIndex }}>
      {children}
    </RecommendationOptionsContext.Provider>
  );
};

RecommendationOptionsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RecommendationOptionsContext;
