import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ADD_ROLES_MODAL_COLUMNS, getColumns, ROLES_COLUMNS } from '../../consts';
import { ReactComponent as NoRoles } from '../../Roles/img/no-roles.svg';

import styles from './AddRoles.module.scss';

export const UserRolesNoData = (userRoles) => {
  if (!userRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoRoles />
      <span className={styles.mainText}>No Roles for this user</span>
    </div>
  );
};

const AddRolesTable = ({ roles, selectedRoles, onRolesAddedRemoved }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ADD_ROLES_MODAL_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const selectedRolesIndexes = useMemo(() => {
    if (!selectedRoles) {
      return [];
    }
    return selectedRoles.map((role) => roles?.findIndex((r) => r?.id === role?.id)).filter((index) => index !== -1);
  }, [selectedRoles, roles]);

  const previousSelectedIndexesRef = useRef(selectedRolesIndexes);

  useEffect(() => {
    previousSelectedIndexesRef.current = selectedRolesIndexes;
  }, [JSON.stringify(roles)]);

  const onSelectionChange = (selectedIndexes) => {
    const addedIndexes = selectedIndexes.filter((index) => !previousSelectedIndexesRef.current.includes(index));
    const removedIndexes = previousSelectedIndexesRef.current.filter((index) => !selectedIndexes.includes(index));

    const addedRoles = addedIndexes.map((index) => roles[index]).filter((role) => !!role);
    const removedRoles = removedIndexes.map((index) => roles[index]).filter((role) => !!role);

    previousSelectedIndexesRef.current = selectedIndexes;
    onRolesAddedRemoved({ addedRoles, removedRoles });
  };

  return (
    roles && (
      <div className={styles.assignRolesContainer}>
        <Grid rows={roles} columns={getColumns(ADD_ROLES_MODAL_COLUMNS)} automationId="assign-roles-to-user-list">
          <SelectionState selection={selectedRolesIndexes} onSelectionChange={onSelectionChange} />
          <IntegratedSelection />
          <DataTypeProvider
            for={[ADD_ROLES_MODAL_COLUMNS.ROLE_NAME.columnName]}
            formatterComponent={(props) => (
              <CustomFormatterWithTooltip overrideStyles={{ fontWeight: 500 }} {...props} />
            )}
          />
          <DataTypeProvider
            for={[ADD_ROLES_MODAL_COLUMNS.ROLE_ID.columnName]}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <DataTypeProvider
            for={[ROLES_COLUMNS.ROLE_DESCRIPTION.columnName]}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <TableWrapper virtual noDataCellComponent={() => UserRolesNoData(roles)} height={350} />
          <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
          <TableColumnResizing
            resizingMode="nextColumn"
            columnWidths={localColumnsWidth}
            onColumnWidthsChange={setLocalColumnsWidth}
          />
          <TableHeaderRow cellComponent={CustomHeaderCell} />
        </Grid>
      </div>
    )
  );
};

AddRolesTable.propTypes = {
  roles: PropTypes.array.isRequired,
};

export default AddRolesTable;
