import { action, makeObservable, observable, runInAction } from 'mobx';
import { CAU_ALERTS_STATUS, CAU_ALERTS_TYPES } from 'usage/constants/usageConstants';
import { dateToStr } from 'shared/utils/dateUtil';
import CostChangesAlert from './helperClasses/costChangesAlert';
import ExpirationAlert from './helperClasses/expirationAlerts';

export default class CostAndUsageAlertsModel {
  constructor(apiGateway) {
    this.alerts = [];
    this.initialFetchDone = false;
    this.apiGateway = apiGateway;
    makeObservable(this, {
      alerts: observable,
      // getCostChangesAlerts: action,
      addAlert: action,
      removeAlert: action,
      invalidateData: action,
      // saveAllCauAlertsOfType: action,
    });
  }

  createAlertObjectByType = (alertType, rawAlertObj) => {
    let alert = null;
    switch (alertType) {
      case CAU_ALERTS_TYPES.COST_CHANGES:
        alert = new CostChangesAlert(rawAlertObj);
        break;
      case CAU_ALERTS_TYPES.RI_EXPIRATION:
        alert = new ExpirationAlert(rawAlertObj);
        break;
      case CAU_ALERTS_TYPES.SP_EXPIRATION:
        alert = new ExpirationAlert(rawAlertObj);
        break;
      default:
        break;
    }
    return alert;
  };
  getAlert = (id) => {
    const alert = this.getAlertById(id);
    return alert;
  };
  getAlerts = () => this.alerts;

  getAllAlertsForSave = (preSaveAlertsAreValid) => {
    const filteredList = this.alerts.filter(
      (alert) =>
        preSaveAlertsAreValid[alert.alertType] &&
        (alert.alertStatus === CAU_ALERTS_STATUS.REMOVE || alert.alertStatus === CAU_ALERTS_STATUS.EDIT),
    );
    return filteredList;
  };
  getAlertListByType = (alertType) => {
    const list = this.alerts.filter((alert) => alert.alertType === alertType);
    return list;
  };
  getAlertById = (id) => {
    const alertObj = this.alerts.find((alert) => alert.id === id);
    return alertObj;
  };
  getAlertLinkedAccountsValues = (id) => {
    const alert = this.getAlertById(id);
    return alert.linkedAccountsValues;
  };
  getAlertServicesValues = (id) => {
    const alert = this.getAlertById(id);
    return alert.servicesValues;
  };
  fetchData = async () => {
    if (this.initialFetchDone) {
      return;
    }
    try {
      const rawAlerts = await this.apiGateway.fetchCauAlerts();
      const alerts = this.prepareRawAlerts(rawAlerts);
      runInAction(() => {
        this.initialFetchDone = true;
        this.alerts = [...alerts];
      });
      // return alerts;
    } catch (error) {
      runInAction(() => {
        this.initialFetchDone = false;
        this.alerts = [];
      });
    }
  };
  prepareRawAlerts = (rawAlerts) => {
    const modifiedAlerts = [];
    if (rawAlerts && rawAlerts.length > 0) {
      rawAlerts.forEach((rawAlertObj) => {
        const newAlert = this.createAlertObjectByType(rawAlertObj.alertType, rawAlertObj);
        if (newAlert) {
          modifiedAlerts.push(newAlert);
        }
      });
    }
    return modifiedAlerts;
  };
  prepareAlertsForSave = (alertsForSave) => {
    if (!alertsForSave) {
      return [];
    }
    alertsForSave.forEach((alert) => {
      alert.updateAlertStatus(CAU_ALERTS_STATUS.SAVE);
      alert.saveEditedParams();
    });
    const prepared = alertsForSave.map((alert) => {
      const { id, ...rest } = alert.alertModifiedParams;
      return { uuid: id, ...rest };
    });
    return prepared;
  };
  saveAllAlerts = async (preSaveAlertsAreValid) => {
    if (preSaveAlertsAreValid && preSaveAlertsAreValid.length) {
      const preparedAlerts = this.prepareAlertsForSave(preSaveAlertsAreValid);
      try {
        const result = await this.apiGateway.saveCauAlerts(preparedAlerts);
        if (result) {
          preSaveAlertsAreValid.forEach((alert) => {
            alert.clearEditedParams();
            this.updateAlerts(alert);
          });
        }
        return result;
      } catch (error) {
        return false;
      }
    }
    return false;
  };
  saveAllAlertsOfType = async () => {
    const alertsForSave = this.getAllAlertsForSave();
    if (alertsForSave && alertsForSave.length) {
      const preparedAlerts = this.prepareAlertsForSave(alertsForSave);
      try {
        // const result = true;
        const result = await this.apiGateway.saveCauAlerts(preparedAlerts);
        if (result) {
          alertsForSave.forEach((alert) => {
            // alert.updateAlertStatus(CAU_ALERTS_STATUS.SAVE);
            alert.clearEditedParams();
            this.updateAlerts(alert);
          });
        }
        return result;
      } catch (error) {
        return false;
      }
    }
    return false;
  };
  cancelEditAlert = (alert = null, alertId = null) => {
    const editedAlert = alert || this.getAlertById(alertId);
    editedAlert.updateAlertStatus(CAU_ALERTS_STATUS.SAVE);
    editedAlert.clearEditedParams();
    this.updateAlerts(editedAlert);
  };
  cancelAllUnsavedCauAlerts = () => {
    const openAndEditStateAlerts = this.alerts.filter(
      (alert) => alert.alertStatus === CAU_ALERTS_STATUS.REMOVE || alert.alertStatus === CAU_ALERTS_STATUS.EDIT,
    );
    openAndEditStateAlerts.forEach((currStateAlert) => {
      if (currStateAlert.alertStatus === CAU_ALERTS_STATUS.REMOVE) {
        this.removeAlert(currStateAlert.id);
        return;
      }
      if (currStateAlert.alertStatus === CAU_ALERTS_STATUS.EDIT) {
        this.cancelEditAlert(currStateAlert);
      }
    });
  };
  updateAlerts = (newAlert) => {
    const { id } = newAlert;
    const isInAlertIndex = this.alerts.findIndex((alert) => alert.id === id);
    const newAlerts = this.alerts;
    if (isInAlertIndex > -1) {
      newAlerts.splice(isInAlertIndex, 1, newAlert);
    } else {
      newAlerts.push(newAlert);
    }
    this.alerts = [...newAlerts];
  };
  removeAlert = (alertId) => {
    try {
      const isInAlertIndex = this.alerts.findIndex((alert) => alert.id === alertId);
      const newAlerts = this.alerts;
      if (isInAlertIndex > -1) {
        if (alertId) {
          newAlerts.splice(isInAlertIndex, 1);
        }
        this.alerts = [...newAlerts];
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  };
  replaceAlert = (alertId, newAlert) => {
    try {
      const isInAlertIndex = this.alerts.findIndex((alert) => alert.id === alertId);
      const newAlerts = this.alerts;
      if (isInAlertIndex > -1) {
        if (alertId) {
          newAlerts.splice(isInAlertIndex, 1, newAlert);
        }
        this.alerts = [...newAlerts];
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  };
  deleteAlert = async (alertId) => {
    try {
      const isInAlertIndex = this.alerts.findIndex((alert) => alert.id === alertId);
      if (isInAlertIndex > -1) {
        const isDeleted = await this.apiGateway.deleteCauAlert(alertId);
        if (isDeleted) {
          this.alerts = this.alerts.filter((alert) => alert.id !== alertId);
        }
        return isDeleted;
      }
    } catch (error) {
      return false;
    }
    return false;
  };
  addAlert = (alertType, alertParams) => {
    let result = false;
    try {
      const creationDate = dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss');
      // eslint-disable-next-line no-param-reassign
      alertParams.creationDate = creationDate;
      const newAlert = this.createAlertObjectByType(alertType, alertParams);
      this.updateAlerts(newAlert);
      result = newAlert;
    } catch (error) {
      return false;
    }
    return result;
  };
  updateEditedAlertParam = (alertParam, alertId) => {
    let result = false;
    try {
      const alert = this.getAlertById(alertId);
      if (alert) {
        alert.updateEditedParams(alertParam);
      }
      this.updateAlerts(alert);
      result = true;
    } catch (error) {
      return false;
    }
    return result;
  };
  editAlertStatus = (alertId, newStatus) => {
    let result = false;
    try {
      const alert = this.getAlertById(alertId);
      if (alert) {
        alert.alertStatus = newStatus;
        this.updateAlerts(alert);
      }
      result = true;
    } catch (error) {
      return false;
    }
    return result;
  };

  invalidateData = () => {
    this.alerts.splice();
    this.initialFetchDone = false;
  };
}
