/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import Numeric from 'mdi-react/NumericIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';

const ConfirmationForm = ({
  email,
  code,
  handleCodeChange,
  password,
  handlePasswordChange,
  confirmPassword,
  handleConfirmPasswordChange,
  passwordValidation,
}) => (
  <form className="form">
    <div className="form__form-group">
      <span className="form__form-group-label">Confirmation code</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <Numeric />
        </div>
        <input
          name="code"
          value={code}
          onChange={handleCodeChange}
          type="text"
          placeholder="Confirmation code"
          autoComplete="off"
        />
      </div>
      <span className="form__form-group-description">Please check your email ({email}) for the confirmation code.</span>
    </div>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <KeyVariantIcon />
        </div>
        <input
          name="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Create password"
          autoComplete="off"
        />
      </div>
    </div>
    <div className="form__form-group">
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <KeyVariantIcon />
        </div>
        <input
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Confirm password"
          autoComplete="off"
        />
      </div>
    </div>
    <div>
      {passwordValidation.map((verification) => (
        <h5 style={{ color: '#FF494D' }}>{verification}</h5>
      ))}
    </div>
  </form>
);
export default ConfirmationForm;

ConfirmationForm.propTypes = {
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  handleConfirmPasswordChange: PropTypes.func.isRequired,
  passwordValidation: PropTypes.array.isRequired,
};
