import React from 'react';

const SuccessMessage = () => (
  <form className="form">
    <div className="form__form-group">
      <span className="form__form-group-label">Your password has been reset.</span>
      <div className="form__form-group-field" />
    </div>
  </form>
);

export default SuccessMessage;
