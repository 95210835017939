/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { CloudTrailsDuplicatesTrailsRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class CloudTrailsDuplicatesTrailsRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new CloudTrailsDuplicatesTrailsRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} CloudTrail`;
  }

  build() {
    super.build();
    const { trails, zone_tag: region } = this._rawRecommendation;
    this._recommendation.region = region;
    this._recommendation.trails = trails;
  }
}
