/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Cell = ({ content, header }) => {
  const cellMarkup = header ? (
    <th style={{ textAlign: 'center' }} className="Cell Cell-header">
      {content}
    </th>
  ) : (
    <td style={{ textAlign: 'center' }} className="Cell">
      {content}
    </td>
  );

  return cellMarkup;
};

export const DataTable = ({ headings, rows }) => {
  const renderHeadingRow = (_cell, cellIndex) => {
    return <Cell key={`heading-${cellIndex}`} content={headings[cellIndex]} header />;
  };

  const renderRows = (_row, rowIndex) => {
    return (
      <tr key={`row-${rowIndex}`}>
        {rows[rowIndex].map((rec, cellIndex) => (
          <Cell key={`${rowIndex}-${cellIndex}`} content={rows[rowIndex][cellIndex]} />
        ))}
      </tr>
    );
  };

  const theadMarkup = <tr key="heading">{headings.map(renderHeadingRow)}</tr>;

  const tbodyMarkup = rows.map(renderRows);

  return (
    <div>
      <Table hover size="sm" responsive>
        <thead>{theadMarkup}</thead>
        <tbody>{tbodyMarkup}</tbody>
      </Table>
    </div>
  );
};

DataTable.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  rows: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default DataTable;
