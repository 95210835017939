import { v4 } from 'uuid';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import {
  getPageTitleForPausedOnboarding as getAWSPageTitleForPausedOnboarding,
  getPageForPausedOnboarding as getAWSPageForPausedOnboarding,
} from '../AwsOnboarding/AwsOnboardingUtils';
import {
  getPageTitleForPausedOnboarding as getAzurePageTitleForPausedOnboarding,
  getPageForPausedOnboarding as getAzurePageForPausedOnboarding,
} from '../AzureOnboarding/AzureOnboardingUtils';
import {
  getPageTitleForPausedOnboarding as getGCPPageTitleForPausedOnboarding,
  getPageForPausedOnboarding as getGCPPageForPausedOnboarding,
} from '../GcpOnboarding/GcpOnboardingUtils';

export const getPageTitleForPausedOnboarding = (cloudTypeId, onboarding) => {
  switch (cloudTypeId) {
    case CLOUD_TYPE_IDS.AWS:
      return getAWSPageTitleForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.AZURE:
      return getAzurePageTitleForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.GCP:
      return getGCPPageTitleForPausedOnboarding(onboarding);
    default:
      return getAWSPageTitleForPausedOnboarding(onboarding);
  }
};

export const getPageForPausedOnboarding = (cloudTypeId, onboarding) => {
  switch (cloudTypeId) {
    case CLOUD_TYPE_IDS.AWS:
      return getAWSPageForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.AZURE:
      return getAzurePageForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.GCP:
      return getGCPPageForPausedOnboarding(onboarding);
    default:
      return getAWSPageForPausedOnboarding(onboarding);
  }
};

export const getTenantParams = (currentDisplaydUser, accountName) => {
  const randomSuffix = v4().substring(0, 6).toLowerCase();
  const accountSchemaNameRaw = accountName
    .trim()
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, '')
    .replace(/-/g, '_')
    .replace(/ /g, '_');
  const tenantName =
    currentDisplaydUser.tenantName ||
    (currentDisplaydUser.companyName || accountName)
      .trim()
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/[^a-z0-9_]/g, '')
      .slice(0, 100);
  const accountSchemaName = `${accountSchemaNameRaw}_${randomSuffix}`;
  return { tenantName, tenantSchemaName: accountSchemaName };
};
