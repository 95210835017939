export const CALC_METHODS = [
  { label: 'Max (Actual Usage, Request)', value: 'max' },
  { label: 'Actual Usage', value: 'actual_usage' },
  { label: 'Request', value: 'request' },
];

export const POD_PREFERENCES_TABLE_COLUMNS = [
  { name: 'namespace', title: 'Pod Namespace' },
  { name: 'calcMethod', title: 'Calculation Method', getCellValue: (row) => row.calcMethodLabel },
  { name: 'userAction', title: ' ' },
];
export const POD_PREFERENCES_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'namespace', width: 200 },
  { columnName: 'calcMethod', width: 200 },
  { columnName: 'userAction', width: 150 },
];

export const POD_PREFERENCES_TABLE_COLUMNS_EXTENSIONS = [{ columnName: 'calcMethod', align: 'center' }];
