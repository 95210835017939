import React from 'react';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import EffectivePermissions from '../../components/EffectivePermissions/EffectivePermissions';

const EffectivePermissionsTab = ({ row }) => {
  const { fetchRolePermissions, setRolePermissions } = useRoles();
  const { data, isLoading } = fetchRolePermissions(row?.id);

  const onPermissionChange = (categoryNewPermissions) => {
    setRolePermissions.mutate({
      roleId: row?.id,
      roleCategoryPermissions: [categoryNewPermissions],
    });
  };

  return isLoading ? (
    <div className="position-relative">
      <Spinner />
    </div>
  ) : (
    <EffectivePermissions roleId={row?.id} permissions={data?.permissions} permissionChanged={onPermissionChange} />
  );
};

export default EffectivePermissionsTab;
