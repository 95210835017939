/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { buildStartAndEndDate, buildTimeDiffDateFromBaseDate, getLstDayOfMonthDate } from 'shared/utils/dateUtil';
import {
  mapReportDatePeriodToDisplayName,
  mapReportFrequencyToDisplayName,
  ReportPeriodTime,
} from 'usage/constants/costAndUsageConstants';
import DateFilter from 'shared/modules/dateFilter';
import { getEmailFromRecipients } from 'shared/utils/sharedUtils';

export default class Report {
  constructor(
    id,
    userKey,
    type,
    name,
    creationDate,
    isPpApplied,
    reportParams,
    mailParams,
    reportType,
    sourceKey,
    updateTime,
    recipients,
  ) {
    this.id = id;
    this.userKey = userKey;
    this.type = type;
    this.name = name;
    this.creationDate = creationDate;
    this.updateTime = updateTime;
    this.reportParams = this.modifyStateParams(reportParams);
    this.mailParams = mailParams;
    this.isPpApplied = isPpApplied;
    this.isSnapShot = this.getIsSnapShot(reportParams);
    this.frequency = this.getFrequency();
    this.period = this.getPeriod(reportParams);
    this.baseReportParams = reportParams;
    this.reportType = reportType || 'cueReport'; // temp patch till we will update dynamo table with new report_type field for old reports
    this.sourceKey = sourceKey;
    this.recipients = recipients;
  }

  getIsSnapShot = (reportParams) => (reportParams ? reportParams.saveModalKeepDates : false);

  getMapParamValues = (param) => this.reportParams.get(param);
  getBaseReportParams = () => ({
    isPpApplied: this.isPpApplied,
    baseReportParams: this.baseReportParams,
  });

  getReportMetaDataParams = () => {
    const params = {
      name: this.name,
      relativeDates: this.baseReportParams && this.baseReportParams.saveModalRelativeDates,
      keepDates: this.isSnapShot,
      periodType: this.baseReportParams && this.baseReportParams.periodType,
      ...this.mailParams,
      chartType: this.reportParams.chartType,
      recipients: this.recipients,
      email: getEmailFromRecipients(this.recipients),
    };
    return params;
  };
  getReportHasSecondaryGroupBy = () => this.reportParams.state.filterBarGroupBySecondary !== 'usagedate';

  buildDatesBasedOnReportParams = (saveModalRelativeDates, periodType, params) => {
    let { startDate, endDate } = buildStartAndEndDate('', '');
    if (periodType === 'relativeDate') {
      startDate = buildTimeDiffDateFromBaseDate(endDate, -parseInt(saveModalRelativeDates, 10), 'd').startDate;
    } else if (periodType === ReportPeriodTime.PREVIOUS_MONTH) {
      const { month, year } = DateFilter.getPrevMonthAndYear();
      const monthInDateFormat = month - 1; // I get a calendar month not a Date format month
      const lastDayOfMonth = getLstDayOfMonthDate(new Date(year, monthInDateFormat, 1));
      startDate = buildStartAndEndDate(new Date(year, monthInDateFormat, 1), lastDayOfMonth).startDate;
      endDate = buildStartAndEndDate(new Date(year, monthInDateFormat, 1), lastDayOfMonth).endDate;
    } else if (periodType === 'snapShot') {
      startDate = params.state.startDate;
      endDate = params.state.endDate;
    }
    return { startDate, endDate };
  };

  modifyStateParams = (params) => {
    if (!params) {
      return {};
    }
    const newParams = params;
    const { saveModalRelativeDates, periodType } = newParams.state;
    newParams.state.selectedOptionsMap = new Map([...(params.state.selectedOptionsMap || [])]);
    newParams.state.startDate = this.buildDatesBasedOnReportParams(
      saveModalRelativeDates,
      periodType,
      newParams,
    ).startDate;
    newParams.state.endDate = this.buildDatesBasedOnReportParams(saveModalRelativeDates, periodType, newParams).endDate;
    return newParams;
  };

  getFrequency = () => {
    let displayedFrequency = '';
    if (this.reportParams.periodType === 'snapShot') {
      displayedFrequency = '';
    } else if (+this.mailParams.reportFreq === 4) {
      // 4 is custom frequency
      displayedFrequency = `Every ${this.mailParams.customFrequencyInDays} days`;
    } else {
      const numFrequency = parseInt(this.mailParams.reportFreq, 10);
      displayedFrequency = mapReportFrequencyToDisplayName.get(numFrequency);
    }
    return displayedFrequency;
  };

  getPeriod = (reportParams) => {
    if (!reportParams) {
      return '';
    }
    const { saveModalRelativeDates, periodType, state } = reportParams;
    let period = '';
    if (periodType === 'snapShot') {
      period = this.getSnapShotPeriod(state.startDate, state.endDate);
    } else {
      period =
        periodType === 'relativeDate'
          ? mapReportDatePeriodToDisplayName.get(saveModalRelativeDates)
          : mapReportDatePeriodToDisplayName.get(periodType);
    }
    return period;
  };

  getSnapShotPeriod = (startDate, endDate) => {
    let periodText = '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    let start = startDate ? new Date(startDate) : new Date(buildStartAndEndDate('', '').startDate);
    let end = endDate ? new Date(endDate) : new Date(buildStartAndEndDate('', '').endDate);
    start =
      Object.prototype.toString.call(new Date(start)) === '[object Date]'
        ? start.toLocaleDateString('en-US', options)
        : '';
    end =
      Object.prototype.toString.call(new Date(end)) === '[object Date]' ? end.toLocaleDateString('en-US', options) : '';
    periodText = `${start} - ${end}`;
    return periodText;
  };
}
