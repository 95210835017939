import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { buildStartAndEndDate, buildTimeDiffDateFromBaseDate } from 'shared/utils/dateUtil';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import Panel from 'shared/components/Panel';
import If from 'shared/components/SimpleIf';
import { UsersType } from 'users/constants/usersConstants';
import { EC2_USAGE_CHARTS_TITLES } from 'usage/constants/costAndUsageConstants';
import RiCoveragePanel from './components/RiCoveragePanel';
import Ec2UsagePanel from './components/Ec2UsagePanel';

const chartsHeight = 400;

class Ec2UsagePage extends PureComponent {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
    usageStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
  };

  render() {
    const { usersStore, usageStore, invoiceStore } = this.props;
    const lastProcessTime = DateFilter.getDate();
    const riStartDate = buildTimeDiffDateFromBaseDate(lastProcessTime, -1, 'd').startDate;
    const { startDate, endDate } = buildStartAndEndDate('', '');
    return (
      <Container>
        <PageHeader title={PageNames.EC2_USAGE} showDate />
        <Panel divider title="Ec2 Quantity And Cost Summary By Type">
          <Ec2UsagePanel
            height={chartsHeight}
            initialStartDate={startDate}
            initialEndDate={endDate}
            usageStore={usageStore}
            invoiceStore={invoiceStore}
          />
        </Panel>
        <If cond={usersStore.currentDisplayedUserType !== UsersType.RESELLER_CUSTOMER}>
          <Panel divider title={EC2_USAGE_CHARTS_TITLES.EC2_CURR_MONTH_RI_COVERAGE}>
            <RiCoveragePanel initialStartDate={riStartDate} initialEndDate={DateFilter.getDateStr()} />
          </Panel>
        </If>
      </Container>
    );
  }
}

export default Ec2UsagePage;
