/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-typos */
/* eslint-disable padded-blocks */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent, useContext } from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StringToColor } from 'shared/utils/graphicUtil';
import { prepareDataKeys } from 'shared/utils/dataPrepareUtil';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { observer } from 'mobx-react';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class SimpleBarChart extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    barName: PropTypes.string.isRequired,
    displayedMetric: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    yLabel: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
  };
  static defaultProps = {
    overrideCurrency: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currKey: '',
    };
  }

  getMaxValue = (data) => 13000;

  modifyXAxisTickFormatter = (timeVal, granLevel) => {
    if (timeVal) {
      return createDateDisplayStr(timeVal, granLevel || undefined);
    }
    return '';
  };

  renderBar(dataKeys) {
    const { currKey } = this.state;
    // eslint-disable-next-line arrow-body-style
    return dataKeys.map((dataKey, index) => {
      const customMouseOver = (e) => {
        if (dataKey.name !== currKey) {
          this.setState({ currKey: dataKey.name });
        }
      };
      return (
        <Bar
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          barSize={28}
          dataKey={dataKey.name}
          fill={StringToColor.next(dataKey.name)}
          onMouseOver={customMouseOver}
          onFocus={customMouseOver}
        />
      );
    });
  }

  render() {
    const {
      height,
      data,
      barName,
      displayedMetric,
      dataKey,
      yLabel,
      numStrAbriviaionByDisplayMetric,
      overrideCurrency,
    } = this.props;
    const dataKeys = prepareDataKeys(data, 'name', 'cost');
    const maxValue = this.getMaxValue(data);
    return (
      <ResponsiveContainer width="100%" height={height || 300}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={dataKey}
            tickCount={0}
            tickFormatter={(value) => this.modifyXAxisTickFormatter(value, 'month')}
          />
          <YAxis
            tickLine
            label={{
              value: yLabel,
              offset: 0,
              angle: -90,
              position: 'left',
            }}
            type="number"
            tickFormatter={(value) => numStrAbriviaionByDisplayMetric(value, value, displayedMetric, overrideCurrency)}
          />
          <Tooltip
            formatter={(value) => numStrAbriviaionByDisplayMetric(value, value, displayedMetric, overrideCurrency)}
            itemSorter={() => 1}
          />
          <Legend />
          {this.renderBar(dataKeys)}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

const ObserverSimpleBarChart = withUserSettingsConsumer(SimpleBarChart);

export default ObserverSimpleBarChart;
