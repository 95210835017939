/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      {`RI purchase recommendations are based on your historical usage.
These recommendations are calculated by analyzing all incurred usage eligible to be covered by an RI (e.g. On-Demand usage) 
and using our unique algorithms to simulate possible combinations of RI’s that would cover that usage.`}
    </p>
    <NavLink target="_blank" href="https://player.vimeo.com/video/362413649">
      How to Execute Reserved Instance Recommendation
    </NavLink>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
