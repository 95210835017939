/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import { ReactComponent as NoOptions } from 'shared/img/icons/no-options.svg';
import classes from './empyOptions.module.scss';

const height = 40;

export default class MenuList extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    children: PropTypes.object.isRequired,
    maxHeight: PropTypes.object.isRequired,
    getValue: PropTypes.object.isRequired,
  };

  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    const innerTextStyle = {
      // overflowY: 'scroll',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };
    if (!children?.length) {
      return (
        <div className={classes.emptyStateWrapper}>
          <NoOptions />
          <span>{options?.length ? 'No Results' : 'No Data Found'}</span>
        </div>
      );
    }
    return (
      <List
        height={Math.min(maxHeight, height * children.length, height * 5)}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={{ ...style, ...innerTextStyle }}>{children[index]}</div>}
      </List>
    );
  }
}
