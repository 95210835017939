export const VIEWS_TABLE_COLUMNS = [
  { name: 'name', title: 'Name', getCellValue: (row) => row.name },
  { name: 'tagKey', title: 'Tag key', getCellValue: (row) => row.tagKey },
  { name: 'creationDate', title: 'Created', getCellValue: (row) => row.creationDate },
  { name: 'createdBy', title: 'Created By', getCellValue: (row) => row.createdBy },
  { name: 'userAction', title: ' ', getCellValue: (row) => row.userAction },
];

export const VIEWS_TABLE_COLUMN_WIDTHS = [
  { columnName: 'name', width: 200 },
  { columnName: 'tagKey', width: 200 },
  { columnName: 'creationDate', width: 150 },
  { columnName: 'createdBy', width: 150 },
  { columnName: 'userAction', width: 150 },
];

// export const VIEWS_TABLE_DEFAULT_SORTING = [{ columnName: 'cost', direction: 'desc' }];
// export const VIEWS_TABLE_COLUMN_EXTENSIONS = [{ columnName: 'month', align: 'center' }, { columnName: 'year', align: 'center' }];
// export const VIEWS_TABLE_DEFAULT_HIDDEN_COLUMN_NAMES = ['linkedAccountName', 'linkedAccountId'];

export const keysToDisplayMap = new Map([
  ['values', 'Tag Values'],
  ['services', 'Services'],
]);
