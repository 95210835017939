import React, { useState, useEffect } from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { useAutoResizeTextArea } from 'shared/hooks/customHooks/useAutoResizeTextArea';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { useGpt } from './hooks/useGpt';
import { Sidebar } from './components/Sidebar';
import { Suggestions } from './components/Suggestions';
import { Answer } from './components/Answer';
import styles from './cost-gpt.module.scss';
import loaderStep1 from './assets/gpt-loader-step1.gif';
import loaderStep2 from './assets/gpt-loader-step2.gif';
import loaderStep3 from './assets/gpt-loader-step3.gif';
import loaderStep4 from './assets/gpt-loader-step4.gif';

const askQuestionAutomationId = 'gptAskQuestionButton';

export const CostGpt = () => {
  // Preloading GptLoader gifs
  [loaderStep1, loaderStep2, loaderStep3, loaderStep4].forEach((src) => {
    new Image().src = src;
  });

  const { reset, fetchUserData, fetchAvailableData, postQuestion, removeQuestion, postFeedback } = useGpt();
  const { textAreaRef, value: question, setValue: setQuestion } = useAutoResizeTextArea();
  const [answer, setAnswer] = useState(null);
  const { data: userData } = fetchUserData();
  const { data: availableData } = fetchAvailableData();
  const { mutateAsync: deleteQuestion } = removeQuestion();
  const { isLoading: isAskingQuestion, mutateAsync: askQuestion } = postQuestion({
    onSettled: (payload) => {
      if (payload?.question) {
        segmentEvent({
          target: askQuestionAutomationId,
          automationId: askQuestionAutomationId,
          question: payload.question,
        });
      }
    },
    onSuccess: (answer) => {
      setAnswer(answer);
    },
    onError: () => {
      setAnswer({}); // Displays error message in Answer component
    },
  });
  const { mutateAsync: sendFeedback } = postFeedback();

  const onSubmit = async (e) => {
    e.preventDefault();
    askQuestion(question);
  };

  const handleChange = async (question) => {
    setQuestion(question);
    // Focus textarea on suggestion click
    if (document.activeElement.type !== 'textarea') {
      textAreaRef.current.focus();
    }
  };

  // Reset state on unmount
  useEffect(() => reset, []);

  return (
    <div className={styles.costGptContainer} id="cost-gpt">
      <Sidebar
        answer={answer}
        history={userData?.history}
        availableData={availableData}
        resetQuestion={() => {
          setQuestion('');
          setAnswer(null);
        }}
        selectHistory={(historyQuestion) => {
          setAnswer({ ...historyQuestion });
          setQuestion(historyQuestion.question);
        }}
        deleteQuestion={deleteQuestion}
      />
      <main>
        {question && (answer || isAskingQuestion) ? (
          <Answer
            question={question}
            answer={answer}
            refreshResult={(question) => {
              setAnswer(null);
              askQuestion(question);
            }}
            sendFeedback={({ requestId, feedback }) => {
              setAnswer({ ...answer, feedback });
              sendFeedback({ requestId, feedback });
              segmentEvent({
                target: `answer-feedback-${feedback ? 'positive' : 'negative'}`,
                question: answer.question,
              });
            }}
          />
        ) : (
          <>
            <Suggestions userData={userData} selectSuggestion={handleChange} />
            <form onSubmit={onSubmit}>
              <textarea
                type="text"
                value={question}
                ref={textAreaRef}
                rows={1}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    onSubmit(e);
                  }
                }}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={userData?.history?.length ? 'Ask a question...' : 'Ask your first question...'}
                automation-id="gptQuestionTextArea"
              />
              <button type="submit" disabled={!question.length} automation-id={askQuestionAutomationId}>
                <GenerateIcon iconName={ICONS.send.name} />
              </button>
            </form>
          </>
        )}
      </main>
    </div>
  );
};
