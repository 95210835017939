import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import { AZURE_PROPERTIES, BASE_PROPERTIES, COST_PROPERTIES, DISK_PROPERTIES } from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';

const AZURE_DISK_TYPE_CHANGE_PROPERTIES = {
  DISK_ID: {
    label: 'Disk ID',
    getterFunction: (recommendation) => recommendation?.recData?.disk_id,
  },
  DISK_STARTING_TIME: {
    label: 'Starting Time',
    getterFunction: (recommendation) => {
      if (recommendation?.recData?.disk_time_created) {
        return typeof recommendation?.recData?.disk_time_created === 'string'
          ? recommendation?.recData?.disk_time_created
          : moment(recommendation?.recData?.disk_time_created * 1000).format('YYYY-MM-DD HH:mm:ss');
      }
      return undefined;
    },
  },
  NEW_DISK_TYPE: {
    label: 'New Disk Type',
    getterFunction: (recommendation) => recommendation?.recData?.new_disk_type,
  },
  IOPS: {
    label: 'IOPS',
    getterFunction: (recommendation) => recommendation?.recData?.iops,
  },
};

const AzureVmRightSizing = ({ recommendation }) => {
  const properties = {
    ...DISK_PROPERTIES,
    ...AZURE_DISK_TYPE_CHANGE_PROPERTIES,
    ...AZURE_PROPERTIES,
    ...COST_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const description = `We recommend you to change this Disk type to reduce costs and to suit your performance needs.`;
  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      preferences={preferencesComponent}
    />
  );
};

AzureVmRightSizing.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureVmRightSizing;
