import React from 'react';
import { Container, Row } from 'reactstrap';
import noPermissions from './assets/noPermissions.gif';
import classes from './NotAllowed.module.scss';

const NotAllowedPage = () => (
  <Container>
    <Row>
      <div className={classes.container}>
        <img src={noPermissions} alt="No permissions" />
        <h4 className={classes.title}>It seems you do not have permissions to view this information</h4>
        <div className={classes.content}>
          <p>please validate your permissions with your admin.</p>
          <p>In the meantime you can perform other tasks you are permitted to do in the platform</p>
        </div>
      </div>
    </Row>
  </Container>
);

export default NotAllowedPage;
