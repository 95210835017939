/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const CostComparisonChart = ({ data }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
      <BarChart barGap={-12} width={550} height={284} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tickMargin={0} interval={0} tick={<CustomizedAxisTick fontSize={12} />} />
        <YAxis tickFormatter={(value) => `${getCurrencyNumber(value)}`} />
        <Tooltip formatter={(value) => `${getCurrencyNumber(value)}`} />
        <Legend />
        <Bar barSize={12} dataKey="EC2 Instance" fill="#70bbfd" />
        <Bar barSize={12} dataKey="Spot Instance" fill="#6764af" />
      </BarChart>
    </ResponsiveContainer>
  );
};

CostComparisonChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CostComparisonChart;
