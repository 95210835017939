/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import {
  CAU_ALERTS_STATUS_TEXT,
  CAU_ALERTS_STATUS_VALUES_FROM_TEXT,
  CAU_ALERTS_TYPES_TEXT,
  CUE_ALERTS_TYPES_VALUES_FROM_TEXT,
} from 'usage/constants/usageConstants';

export default class BaseCauAlert {
  // constructor(id, creationDate, alertStatus, userKey, linkedAccountIds, services, changeType, operatorType, changePercent, changeValue, recipientsEmails) {
  constructor(alertParams) {
    this.id = alertParams.id;
    this.recipientsEmails = alertParams.recipientsEmails;
    this.creationDate = alertParams.creationDate;
    this.alertStatus = alertParams.alertStatus;
    this.alertType = alertParams.alertType;
    this.editedParams = null;
    this.db_creation_time = alertParams.db_creation_time;
  }

  get alertStatusParsedAsText() {
    return this.alertStatus !== null || this.alertStatus !== undefined ? CAU_ALERTS_STATUS_TEXT[this.alertStatus] : '';
  }

  getAlertStatusTextAsValue = (text) => {
    const alertStatus = CAU_ALERTS_STATUS_VALUES_FROM_TEXT[text];
    return alertStatus;
  };

  get alertTypeParsedAsText() {
    return this.alertType !== null || this.alertType !== undefined ? CAU_ALERTS_TYPES_TEXT[this.alertType] : '';
  }

  getAlertTypeTextAsValue = (text) => {
    const alertType = CUE_ALERTS_TYPES_VALUES_FROM_TEXT[text];
    return alertType;
  };

  get alertModifiedParams() {
    return {
      id: this.id,
      recipientsEmails: this.recipientsEmails,
      creationDate: this.creationDate,
      alertStatus: this.alertStatusParsedAsText,
      alertType: this.alertTypeParsedAsText,
      db_creation_time: this.db_creation_time,
    };
  }

  get alertParams() {
    const alertParams = {
      id: this.id,
      recipientsEmails: this.recipientsEmails,
      creationDate: this.creationDate,
      alertStatus: this.alertStatus,
      alertType: this.alertType,
      db_creation_time: this.db_creation_time,
    };
    return alertParams;
  }

  updateEditedParams = (editedParam) => {
    // will be implemented by cost change alert seperately
    if (!editedParam || !Object.keys(editedParam).length) {
      return;
    }
    const newEditedParams = { ...(this.editedParams || {}), ...editedParam };
    this.editedParams = { ...newEditedParams };
  };
  saveEditedParams = () => {
    if (this.editedParams) {
      const arrEditedParamsAndValues = Object.entries(this.editedParams);
      arrEditedParamsAndValues.forEach(([param, value]) => {
        if (`${param}` in this) {
          this[param] = value;
        }
      });
    }
  };
  clearEditedParams = () => {
    this.editedParams = null;
  };
  updateAlertStatus = (newStatus) => {
    this.alertStatus = newStatus;
  };
  // saveOriginalParamsBeforeEdit = () => {}
}
