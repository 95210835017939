import React from 'react';
import PropTypes from 'prop-types';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import { GCPCommonFieldLabels, GcpCommonFields } from 'shared/constants/gcpConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './assetsTable.module.scss';

function AssetsTable({
  data,
  columns,
  costColumnsArray,
  currenciesColumns,
  tableColumnExtensions,
  columnsWidths,
  overrideCurrency,
  granularity,
  usersStore,
}) {
  const { getCurrencyNumber } = useUserSettingsContext();
  const renderInformationIcon = ({ columnName, granularity, isGCP }) => {
    const isQuantityInfoNeeded = columnName === 'totalUsageQuantity' && granularity !== 'day' && isGCP;
    if (!isQuantityInfoNeeded) {
      return null;
    }
    let granularityText = '';
    switch (granularity) {
      case 'month':
        granularityText = 'monthly';
        break;
      case 'year':
        granularityText = 'yearly';
        break;
      case 'week':
        granularityText = 'weekly';
        break;
      case 'quarter':
        granularityText = 'quarterly';
        break;
      default:
        break;
    }
    return (
      <Tooltip
        arrow
        placement="top"
        // eslint-disable-next-line max-len
        title={`To calculate the ${granularityText} quantity for the cloud storage service, divide the total quantity by the number of days it was used`}
      >
        <div className="d-flex" id="quantityUsageTypeInfo">
          <InformationOutline size={18} />
        </div>
      </Tooltip>
    );
  };
  const renderTableHeaderContent = ({ column, children, ...restProps }) => {
    const col = Object.assign({}, column);
    const isGCP = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP;
    const isQuantityInfoNeeded = col.name === 'quantity' && granularity !== 'day' && isGCP;

    if (isGCP && GCPCommonFieldLabels.has(column.name)) {
      col.title = GCPCommonFieldLabels.get(column.name);
      if (column.name === GcpCommonFields.LINKED_ACCOUNT_ID) {
        col.title += ' Id';
      }
      if (column.name === GcpCommonFields.LINKED_ACCOUNT_NAME) {
        col.title += ' Name';
      }
    }
    return (
      <TableHeaderRow.Content column={col} {...restProps}>
        <div className={`d-flex align-items-center ${styles.headerColumn}`}>
          <div className={`${styles.headerColumn} ${isQuantityInfoNeeded ? 'me-1' : ''}`}>{children}</div>
          {renderInformationIcon({ columnName: column.name, granularity, isGCP })}
        </div>
      </TableHeaderRow.Content>
    );
  };
  const totalSummaryItems = columns.map(({ name }) => {
    if (name === 'totalcost') {
      return { columnName: name, type: 'sum' };
    }
    return { columnName: name, type: 'count' };
  });
  return (
    <div className="card-body assets-table">
      <DevExpSearchAndVisibilityTable
        data={data}
        tableHeight="calc(100vh - 320px)"
        isTableSummaryRow
        isVirtual
        totalSummaryItems={totalSummaryItems}
        columns={columns}
        costColumnsArray={costColumnsArray}
        currenciesFormatterComponent={({ value }) => `${getCurrencyNumber(value, 2, {}, overrideCurrency)}`}
        currenciesColumns={currenciesColumns}
        tableColumnExtensions={tableColumnExtensions}
        columnWidths={columnsWidths}
        defaultHiddenColumnNames={[]}
        defaultSorting={[]}
        isResize
        isPaging
        isVisibility={false}
        pageSizes={[15, 20, 25, 0]}
        defaultPageSize={15}
        isDownloadAsCsv={false}
        isCurrencyInKFormat={false}
        tableHeaderContent={renderTableHeaderContent}
      />
    </div>
  );
}

AssetsTable.propTypes = {
  usersStore: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  columnsWidths: PropTypes.object.isRequired,
  costColumnsArray: PropTypes.object.isRequired,
  currenciesColumns: PropTypes.object.isRequired,
  tableColumnExtensions: PropTypes.object.isRequired,
  overrideCurrency: PropTypes.string,
  granularity: PropTypes.string.isRequired,
};

AssetsTable.defaultProps = {
  overrideCurrency: null,
};

export default AssetsTable;
