/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul>
      <li>
        Using the AWS Console:
        <NavLink target="_blank" href="https://aws.amazon.com/premiumsupport/knowledge-center/move-ec2-instance/">
          https://aws.amazon.com/premiumsupport/knowledge-center/move-ec2-instance/
        </NavLink>
      </li>
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
