import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentPageTypes,
  mapRecpageTypeToDefaultDetailsDiaply,
} from 'recommendations/constants/recommendationsConstants';
import ContentDetails from 'recommendations/containers/DailyRecommendations/components/ContentDetails';
import { mapRecTypetoContentCreator } from 'recommendations/constants/recommContentCreators';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const renderContentDetails = (rec, contentPageType) => {
  const { getCurrencyNumber, currencyCode } = useUserSettingsContext();
  const contentDetailsArray = [];
  const RecTypeContentDetailsClass = mapRecTypetoContentCreator.get(rec.type);
  const recTypeContentDetails = new RecTypeContentDetailsClass(rec, contentPageType, getCurrencyNumber, currencyCode);
  const content = recTypeContentDetails.createContent(rec);
  if (content) {
    contentDetailsArray.push(...content);
  }
  return [...contentDetailsArray];
};

const Content = ({ rec, contentPageType }) => (
  <div style={{ 'line-height': '30px', 'font-size': '13px' }}>
    <table
      className={`project-summary__info ${contentPageType === ContentPageTypes.DAILY_RECS ? 'daily-rec__width' : ''}`}
    >
      <ContentDetails contentDisplay={mapRecpageTypeToDefaultDetailsDiaply.get(contentPageType)}>
        {renderContentDetails(rec, contentPageType)}
      </ContentDetails>
    </table>
  </div>
);

Content.propTypes = {
  rec: PropTypes.object.isRequired,
  contentPageType: PropTypes.object.isRequired,
};

export default Content;
