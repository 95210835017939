import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import Switch from 'shared/components/andtComponents/Switch';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { k8sGranLevel } from 'shared/constants/k8sConstants';
import styles from './K8sGranularityToggle.module.scss';

const K8sGranularityToggle = ({ kubernetesStore, usersStore }) => {
  const onChange = (isPods) => {
    kubernetesStore.setK8sGranularity(isPods ? k8sGranLevel.PODS : k8sGranLevel.NODES);
  };

  if (usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.GCP) {
    return null;
  }
  return (
    <div className={styles.toggleWrapper}>
      <Tooltip title="Choose to query data by Nodes or Pods" arrow placement="top" style={{ width: '100%' }}>
        <div className={styles.toggleAlignment}>
          <span
            className={`${styles.nodes} ${
              kubernetesStore.k8SGranularity === k8sGranLevel.NODES ? styles.activeGran : null
            }`}
          >
            Nodes
          </span>
          <Switch isChecked={kubernetesStore.k8SGranularity === k8sGranLevel.PODS} onChange={onChange} />
          <span
            className={`${styles.pods} ${
              kubernetesStore.k8SGranularity === k8sGranLevel.PODS ? styles.activeGran : null
            }`}
          >
            Pods
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

K8sGranularityToggle.propTypes = {
  kubernetesStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
};
const ObserverK8sGranularityToggle = observer(K8sGranularityToggle);
export default ObserverK8sGranularityToggle;
