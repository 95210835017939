const originalPalette = {
  azure: {
    500: '#00b7f1',
    450: '#2EA3DD',
    400: '#0982b6',
  },
  indigo: {
    500: '#17406f',
    400: '#3f51b6',
  },
  blue: {
    600: '#44',
    500: '#2671ff',
    300: '#c9dbff',
    250: '#ebf2fc',
    200: '#E9F0FF',
    100: '#e9f0ff',
    50: '#F7FAFF',
    hawkesBlue: '#d7effd',
    transparentBlue: '#ddddff',
  },
  black: {
    700: '#222222',
    600: '#212121',
    500: '#1f292e',
  },
  white: {
    500: '#ffffff',
  },
  offWhite: {
    100: '#ececf8',
    200: '#f9f9fd',
  },
  red: {
    10: '#FAE7EB',
    50: '#ED9FAF',
    100: '#ED9FAF',
    200: '#FACBD5',
    300: '#ED9FAF',
    400: '#DE5773',
    450: '#DF5353',
    475: '#ff3d35',
    500: '#D10F37',
    600: '#aa0000',
  },
  orange: {
    500: '#eb6f07',
    400: '#FF9F30',
    350: '#fcd176',
    300: '#FFD0A5',
    200: '#FFEEDE',
  },
  yellow: {
    100: '#DDDF0D',
    200: '#FFE8B2',
    300: '#FFE400',
    400: '#FFC700',
    500: '#F4B92D',
  },
  green: {
    50: '#dff7e4',
    100: '#c7e8ce',
    400: '#64E17B',
    450: '#94D85F',
    500: '#41ca5a',
    600: '#0fc330',
    650: '#009263',
    700: '#0eab2b',
  },
  cyan: {
    300: '#d2e0fa',
    100: '#d1fbf0',
  },
  gray: {
    neutral: '#666666',
    600: '#27363E',
    525: '#404a52',
    500: '#3D4C59',
    475: '#A2A2A2',
    450: '#979797',
    400: '#8995A0',
    350: '#B5BBC3',
    325: '#c8cdd0',
    300: '#D4D9E0',
    250: '#d8d8d8',
    225: '#dee9fc',
    200: '#E9E9F0',
    150: '#ebf2fc',
    100: '#F4F7FB',
    50: '#f5f5f5',
  },
  rose: {
    500: '#E91E63',
    400: '#F06292',
  },
  tomato: {
    500: '#FF5653',
    400: '#FF7777',
  },
  lime: {
    500: '#7CB342',
    400: '#AFD580',
  },
  mint: {
    600: '#1A896A',
    590: '#279677',
    580: '#34A384',
    570: '#40AF90',
    560: '#4DBC9D',
    550: '#5AC9AA',
    540: '#67D6B7',
    530: '#74E3C4',
    520: '#80EFD0',
    510: '#8DFCDD',
    500: '#1AE395',
    100: '#CAF4E1',
  },
  eucaliptus: {
    500: '#00934E',
    400: '#00875A',
  },
  teal: {
    600: '#017DA5',
    550: '#0E98C4',
    500: '#00BBD4',
    400: '#80DEEA',
  },
  lilach: {
    600: '#8C0DAF',
    500: '#C83CEE',
    400: '#D083E8',
  },
  purple: {
    600: '#6826AB',
    500: '#8947CD',
    200: '#E1D1F2',
  },
  brown: {
    600: '7B3F00',
    500: '#8D5635',
    400: '#BF8563',
  },
  mango: {
    500: '#FF8F24',
    400: '#ffac3d',
  },
  navyblue: {
    500: '#1F2C9C',
    400: '#224057',
  },
  eggplant: {
    500: '#800661',
  },
  brorange: {
    500: '#B2730B',
  },
  pale: {
    500: '#FFF5DD',
  },
  opaqueColors: {
    rose: '#FFE4EAbf',
    clearWhite: '#fffffff2',
    whiteO: '#ffffffbf',
    white5: '#F7FAFFFF',
    white10: '#f2f2f380',
    lighterBlue: '#2671ff0d',
    lightBlue: '#2671ff40',
    deepBlue: '#00002440',
    indigo: '#17406f4d',
    green: '#30de504d',
    red: '#d10f374d',
    orange: '#f9691f4d',
    mango: '#ffac3d4d',
    opRed: '#E9B1C0',
    opOrange: '#F4D9B6',
    opYellow: '#F4E5A8',
    opGreen: '#C9F0D5',
    transparent: '#00FFFFFF',
    blue500_01: '#2671ff1a',
    blue500_05: '#2671ff80',
  },
  titles: {
    newLook: '#ff1551',
    faster: '#0ad7ce',
    simpleFilters: '#ffba21',
    betterEditMode: '#7f4dff',
    newTitles: '#7cb342',
    instantDashboardTemplates: '#24b9ff',
  },
};

export const palette = originalPalette;

export const COLOR_NAME = {
  GRAY: 'gray',
  GRAY_400: 'gray_400',
  GRAY_300: 'gray_300',
  GRAY_350: 'gray_350',
  GRAY_100: 'gray_100',
  PURPLE: 'purple',
  PURPLE_200: 'purple_200',
  AZURE: 'azure',
  YELLOW: 'yellow',
  MANGO: 'mango',
  BLUE: 'blue',
  NAVYBLUE: 'navyblue',
  EUCALIPTUS: 'eucaliptus',
  LIME: 'lime',
  LIME_400: 'lime_400',
  ROSE: 'rose',
  ROSE_400: 'rose_400',
  LILACH_400: 'lilach_400',
  LILACH_600: 'lilach_600',
  EGGPLANT: 'eggplant',
  TEAL: 'teal',
  TEAL_400: 'teal_400',
  TOMATO: 'tomato',
  TOMATO_400: 'tomato_400',
  MINT: 'mint',
  MINT_600: 'mint_600',
  WHITE: 'white',
  YELLOW_200: 'yellow_200',
  YELLOW_400: 'yellow_400',
  ORANGE_300: 'orange_300',
  ORANGE_400: 'orange_400',
  ORANGE: 'orange',
  RED: 'red',
  RED_200: 'red_200',
  RED_400: 'red_400',
  INDIGO: 'indigo',
  INDIGO_400: 'indigo_400',
  BLACK: 'black',
  BROWN: 'brown',
  BROWN_400: 'brown_400',
};

export const COLORS_PICKER = {
  [COLOR_NAME.GRAY]: {
    color: COLOR_NAME.GRAY,
    contrast: 500,
  },
  [COLOR_NAME.GRAY_400]: {
    color: COLOR_NAME.GRAY,
    contrast: 400,
    rgb: '137,149,160',
  },
  [COLOR_NAME.GRAY_300]: {
    color: COLOR_NAME.GRAY,
    contrast: 300,
  },
  [COLOR_NAME.GRAY_350]: {
    color: COLOR_NAME.GRAY,
    contrast: 350,
  },
  [COLOR_NAME.GRAY_100]: {
    color: COLOR_NAME.GRAY,
    contrast: 100,
  },
  [COLOR_NAME.PURPLE]: {
    color: COLOR_NAME.PURPLE,
    contrast: 500,
  },
  [COLOR_NAME.PURPLE_200]: {
    color: COLOR_NAME.PURPLE,
    contrast: 200,
  },
  [COLOR_NAME.AZURE]: {
    color: COLOR_NAME.AZURE,
    contrast: 500,
  },
  [COLOR_NAME.ROSE]: {
    color: COLOR_NAME.ROSE,
    contrast: 500,
  },
  [COLOR_NAME.YELLOW]: {
    color: COLOR_NAME.YELLOW,
    contrast: 500,
  },
  [COLOR_NAME.MANGO]: {
    color: COLOR_NAME.MANGO,
    contrast: 500,
  },
  [COLOR_NAME.NAVYBLUE]: {
    color: COLOR_NAME.NAVYBLUE,
    contrast: 500,
  },
  [COLOR_NAME.BLUE]: {
    color: COLOR_NAME.BLUE,
    contrast: 500,
    rgb: '38,113,255',
  },
  [COLOR_NAME.EUCALIPTUS]: {
    color: COLOR_NAME.EUCALIPTUS,
    contrast: 500,
    rgb: '0,147,78',
  },
  [COLOR_NAME.LIME]: {
    color: COLOR_NAME.LIME,
    contrast: 500,
  },
  [COLOR_NAME.LIME_400]: {
    color: COLOR_NAME.LIME,
    contrast: 400,
  },
  [COLOR_NAME.ROSE_400]: {
    color: COLOR_NAME.ROSE,
    contrast: 400,
  },
  [COLOR_NAME.LILACH_400]: {
    color: 'lilach',
    contrast: 400,
  },
  [COLOR_NAME.LILACH_600]: {
    color: 'lilach',
    contrast: 600,
    rgb: '140,13,175',
  },
  [COLOR_NAME.EGGPLANT]: {
    color: COLOR_NAME.EGGPLANT,
    contrast: 500,
    rgb: '128,6,97',
  },
  [COLOR_NAME.TEAL]: {
    color: COLOR_NAME.TEAL,
    contrast: 500,
  },
  [COLOR_NAME.TEAL_400]: {
    color: COLOR_NAME.TEAL,
    contrast: 400,
  },
  [COLOR_NAME.TOMATO]: {
    color: COLOR_NAME.TOMATO,
    contrast: 500,
    rgb: '255,86,83',
  },
  [COLOR_NAME.TOMATO_400]: {
    color: COLOR_NAME.TOMATO,
    contrast: 400,
  },
  [COLOR_NAME.WHITE]: {
    color: COLOR_NAME.WHITE,
    contrast: 500,
  },
  [COLOR_NAME.YELLOW_200]: {
    color: COLOR_NAME.YELLOW,
    contrast: 200,
  },
  [COLOR_NAME.YELLOW_400]: {
    color: COLOR_NAME.YELLOW,
    contrast: 400,
    rgb: '255,199,0',
  },
  [COLOR_NAME.YELLOW]: {
    color: COLOR_NAME.YELLOW,
    contrast: 500,
  },
  [COLOR_NAME.ORANGE_300]: {
    color: COLOR_NAME.ORANGE,
    contrast: 300,
  },
  [COLOR_NAME.ORANGE_400]: {
    color: COLOR_NAME.ORANGE,
    contrast: 400,
    rgb: '255,159,48',
  },
  [COLOR_NAME.ORANGE]: {
    color: COLOR_NAME.ORANGE,
    contrast: 500,
  },
  [COLOR_NAME.RED_200]: {
    color: COLOR_NAME.RED,
    contrast: 200,
  },
  [COLOR_NAME.RED_400]: {
    color: COLOR_NAME.RED,
    contrast: 400,
    rgb: '222,87,115',
  },
  [COLOR_NAME.RED]: {
    color: COLOR_NAME.RED,
    contrast: 500,
  },
  [COLOR_NAME.INDIGO]: {
    color: COLOR_NAME.INDIGO,
    contrast: 500,
  },
  [COLOR_NAME.INDIGO_400]: {
    color: COLOR_NAME.INDIGO,
    contrast: 400,
  },
  [COLOR_NAME.MINT]: {
    color: COLOR_NAME.MINT,
    contrast: 500,
  },
  [COLOR_NAME.MINT_600]: {
    color: COLOR_NAME.MINT,
    contrast: 600,
  },
  [COLOR_NAME.BLACK]: {
    color: COLOR_NAME.BLACK,
    contrast: 500,
  },
  [COLOR_NAME.BROWN]: {
    color: COLOR_NAME.BROWN,
    contrast: 500,
  },
  [COLOR_NAME.BROWN_400]: {
    color: COLOR_NAME.BROWN,
    contrast: 400,
  },
};
