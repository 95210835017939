import { calcPercent } from 'shared/utils/calcUtil';

export class RecommendationIdentifier {
  constructor(key, type) {
    this.key = key;
    this.type = type;
  }
}

export class RecommendationCost {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);

    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;

    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class RecommendationCloudTrail {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);

    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;

    this.reducePercent = calcPercent(recommCost, currCost);
  }
}

export class RdsInstanceBasicData {
  constructor(model, size, name, type, region, resourceId = '') {
    this.model = model;
    this.size = size;
    this.name = name;
    this.type = type;
    this.region = region;
    this.resourceId = resourceId;
    this.instance = `${model}.${size}`.toLocaleLowerCase();
  }
}

export class Ec2InstanceBasicData {
  constructor(model, size, os, region, environment, resourceId = '') {
    this.model = model;
    this.size = size;
    this.os = os;
    this.region = region;
    this.environment = environment;
    this.resourceId = resourceId;
    this.instance = `${model}.${size}`.toLocaleLowerCase();
  }
}

export class VmInstanceBasicData {
  constructor(instance, os, region, environment, resourceId = '') {
    this.os = os;
    this.region = region;
    this.environment = environment;
    this.resourceId = resourceId;
    this.instance = `${instance}`.toLocaleLowerCase();
  }
}

export class Ec2InstanceAdvancedData {
  constructor(memoryInGB, vCpu, ecu, instanceStorage, networkPerformance) {
    this.memoryInGB = memoryInGB;
    this.vCpu = vCpu;
    this.ecu = ecu;
    this.instanceStorage = instanceStorage;
    this.networkPerformance = networkPerformance;
  }
}
export class KmsOldRecommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class KmsIdleRecommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class S3StorageClassRecommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class S3VersioningRecommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class RdsIopsChangeRecommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class RdsClassChangeRecommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class EcsFargateRecommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
export class IpPublicIpv4Recommendation {
  constructor(currCost, recommCost) {
    this.currTotalCost = parseFloat(currCost);
    this.recommTotalCost = parseFloat(recommCost);
    this.currMonthlyCost = parseFloat(currCost);
    this.recommMonthlyCost = parseFloat(recommCost);
    this.currYearlyCost = parseFloat(currCost) * 12;
    this.recommYearlyCost = parseFloat(recommCost) * 12;
    this.reducePercent = calcPercent(recommCost, currCost);
  }
}
