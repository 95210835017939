import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'reactstrap';
import CustomModal from 'shared/components/andtComponents/Modal';

const EditAccountModal = ({ onClose, isOpen, editAccountHandler, accountData }) => {
  const [name, setName] = React.useState(accountData.accountName);
  const [roleARN, setRoleARN] = React.useState(accountData.accountInfo?.pileus_role_arn);
  const handleOnSaveButtonClick = async (e) => {
    e.preventDefault();
    await editAccountHandler({ name, roleARN });
  };
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      onSave={(e) => handleOnSaveButtonClick(e)}
      title="Edit Account Details"
    >
      <Row style={{ marginBottom: '10px' }}>
        <Col>
          <h5 style={{ marginBottom: '5px' }}>Account Name</h5>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            variant="outlined"
            name="account-name"
            className="name-input"
          />
        </Col>
      </Row>
      {accountData.accountInfo?.pileus_role_arn && (
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <h5 style={{ marginBottom: '5px' }}>Role ARN</h5>
            <Input
              value={roleARN}
              onChange={(e) => setRoleARN(e.target.value)}
              margin="normal"
              variant="outlined"
              name="role-arn"
              className="name-input"
            />
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};

EditAccountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  editAccountHandler: PropTypes.func.isRequired,
  accountData: PropTypes.object.isRequired,
};

export default EditAccountModal;
