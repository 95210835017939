/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ActionContainer } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <h5 style={{ cursor: 'pointer' }}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/move-resource-group-and-subscription"
      >
        How to move resources to a new resource group or subscription
      </a>
    </h5>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
