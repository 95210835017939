/* eslint-disable no-unused-vars */
import { action, makeObservable, observable, runInAction } from 'mobx';
import DateFilter from 'shared/modules/dateFilter';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';

class CommitmentStore {
  isLoading = false;

  constructor(rootStore, commitmentModel) {
    this.rootStore = rootStore;
    this.commitmentModel = commitmentModel;
    makeObservable(this, {
      isLoading: observable,
      // fetchData: action,
      commitmentModel: observable,
    });
  }

  getCommitmentTypeData = async (date, commitmentType, commitmentService, linkedAccount) => {
    this.isLoading = true;
    try {
      await this.commitmentModel.getCommitmentTypeData(date, commitmentType, commitmentService, linkedAccount);
      runInAction(() => {
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  };
  getCommitmentDashboardData = async (granLevel, inputStartDate, inputEndDate, filtersMap) => {
    const result = await this.commitmentModel.getCommitmentDashboardData(
      granLevel,
      inputStartDate,
      inputEndDate,
      filtersMap,
    );
    return result;
  };
  getCommitmentChartsAndStatisticsData = async (date, commitmentType) => {
    await this.commitmentModel.getCommitmentChartsAndStatisticsData(date, commitmentType);
  };
  getCommitmentTableData = async (date, commitmentService, linkedAccount, commitmentType, payerAccount) => {
    await this.commitmentModel.getTableDataByParams(
      date,
      commitmentService,
      linkedAccount,
      commitmentType,
      payerAccount,
    );
  };
  getCommitementInfoDataDates = (month, year) => {
    const { month: currMonth, year: currYear } = DateFilter.getCurrMonthAndYear();
    let start = '';
    let end = '';
    if (month === currMonth && year === currYear) {
      const { startDate, endDate } = buildStartAndEndDate(null, null);
      return { startDate, endDate };
    }
    start = new Date(year, month - 1, 1);
    end = new Date(year, month - 1, 30);
    const { startDate, endDate } = buildStartAndEndDate(start, end);
    return { startDate, endDate };
  };
  getRiFlexabilityData = async (arn, month, year) => {
    const { startDate, endDate } = this.getCommitementInfoDataDates(month, year);
    await this.commitmentModel.getRiFlexabilityData(arn, startDate, endDate);
  };
  getChargeBackData = async (arn, month, year, commitmentType) => {
    const { startDate, endDate } = this.getCommitementInfoDataDates(month, year);
    if (commitmentType === 'ri') {
      await this.commitmentModel.getRiChargeBackData(arn, startDate, endDate);
    } else if (commitmentType === 'sp') {
      await this.commitmentModel.getServicePlansChargeBackData(arn, startDate, endDate);
    }
  };
  getServicePlansChargeBackData = async (arn, month, year) => {
    const { startDate, endDate } = this.getCommitementInfoDataDates(month, year);
    await this.commitmentModel.getServicePlansChargeBackData(arn, startDate, endDate);
  };

  invalidateCommitmentRiAndChargeBackModel = () => {
    this.commitmentModel.invalidateChargeBackAndRiFlexabilityData();
  };
  invalidateStore = () => {
    this.commitmentModel.invalidateData();
    this.isLoading = false;
  };
}

export default CommitmentStore;
