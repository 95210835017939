import { useMutation, useQuery } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import toast from 'shared/components/andtComponents/Toast';

const getAwsCreditsData = async () => {
  const getUrl = '/api/v1/divisions/customers/aws/credit';
  return API.get('billings', getUrl);
};
const addCredit = async (credit) => {
  const postUrl = '/api/v1/divisions/customers/aws/credit';
  const body = { ...credit };
  return API.post('billings', postUrl, { body });
};

const deleteCredit = async (creditId) => {
  const postUrl = `/api/v1/divisions/customers/aws/credit/${creditId}`;
  return API.del('billings', postUrl);
};

const useCredits = (method, params = {}, onSuccess) => {
  switch (method) {
    case 'get':
      return useQuery(
        apiConstants.QUERY_KEYS.RESELLER_CREDITS,
        () => getAwsCreditsData(params.startDate, params.endDate),
        { staleTime: Infinity },
      );
    case 'post':
      return useMutation(addCredit, {
        onSuccess,
        onError: (error) => {
          toast.error(error.response.data.clientMessage || 'Error adding credit');
        },
      });
    case 'delete':
      return useMutation(deleteCredit, {
        onSuccess,
        onError: (error) => {
          toast.error(error.response.data.clientMessage || 'Error deleting credit');
        },
      });
    default:
      return null;
  }
};
export default useCredits;
