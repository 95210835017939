import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { ClickAwayListener } from '@mui/material';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import { cloudTypeToIcon } from 'shared/constants/appConstants';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import styles from './AccountsChooser.module.scss';
import {
  useFilteredAccounts,
  useFilteredLinkedAccounts,
  useTotalLinkedAccounts,
  useTotalLinkedAccountsSelected,
} from './hooks/useCounters';
import {
  getAccountByAccountId,
  getAccountLabelById,
  linkedAccountsSelectedCounter,
  totalLinkedAccountsCounter,
  getLinkedAccountsInAccount,
} from './utils';
import LinkedAccountsList from './LinkedAccountsList';
import SelectedAccounts from './SelectedAccounts';

const AccountsChooser = ({ position, accounts, selectedAccounts, setSelectedAccounts, linkedAccounts }) => {
  const [open, setOpen] = useState(false);
  const [accountIdOpened, setSecondOpen] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [search, setSearch] = useState('');
  const [secondSearch, setSecondSearch] = useState('');
  const accSelected = selectedAccounts.find((acc) => acc.accountId === accountIdOpened);

  const secondItemsFiltered = useFilteredLinkedAccounts(secondSearch, linkedAccounts, accountIdOpened);
  const accountsFiltered = useFilteredAccounts(search, accounts, linkedAccounts);
  const totalLinkedAccounts = useTotalLinkedAccounts(accounts, linkedAccounts);
  const totalLinkedAccountsFiltered = useTotalLinkedAccounts(accountsFiltered, linkedAccounts);
  const totalLinkedAccountsSelected = useTotalLinkedAccountsSelected(accounts, selectedAccounts, linkedAccounts);
  const totalLinkedAccountsFilteredSelected = useTotalLinkedAccountsSelected(
    accountsFiltered,
    selectedAccounts,
    linkedAccounts,
  );

  const renderAccountsUnderCloud = (cloud) => {
    const onClick = (accountId) => {
      setSelectedAccounts((prev) =>
        prev.some((acc) => acc.accountId === accountId)
          ? prev.filter((s) => s.accountId !== accountId)
          : [...prev, { accountId, linkedAccountIds: 'all', label: getAccountLabelById(accounts, accountId) }],
      );
    };
    return (
      <div>
        {accountsFiltered[cloud].map(({ accountName, accountId }) => {
          const lns = (linkedAccounts.get(accountId) || []).length;
          const selected = getAccountByAccountId(selectedAccounts, accountId);
          const lnsSelected = getLinkedAccountsInAccount(selected, linkedAccounts.get(accountId)).length;
          return (
            <div
              className={classNames(styles.menuItem, styles.subMenuItem)}
              key={accountId + !!selected}
              onClick={() => {
                if (lns > 0) {
                  setSecondOpen(accountId);
                  if (
                    search &&
                    !accountId.toLowerCase().includes(search.toLowerCase()) &&
                    !accountName.toLowerCase().includes(search.toLowerCase())
                  ) {
                    setSecondSearch(search);
                  }
                } else {
                  onClick(accountId);
                }
              }}
            >
              <Checkbox
                primary
                isHalfChecked={lnsSelected > 0 && lnsSelected < lns}
                isChecked={!!selected}
                onChange={(_, e) => {
                  e.stopPropagation();
                  onClick(accountId);
                }}
                text={
                  <>
                    {accountName}
                    <span className={classNames(styles.counter, { [styles.activeBadge]: lnsSelected > 0 })}>
                      ({lnsSelected ? `${lnsSelected}/${lns}` : lns})
                    </span>
                  </>
                }
              />
              {lns > 0 ? <GenerateIcon iconName={ICONS.chevronRight.name} /> : null}
            </div>
          );
        })}
      </div>
    );
  };

  const renderFooter = (selected, total, onClear) => (
    <div className={styles.footer}>
      <div>
        Selected:{' '}
        <span className={classNames({ [styles.activeBadge]: selected > 0 })}>
          {selected || 0}/{total}
        </span>
      </div>
      <div>
        <Button isTextButton onClick={onClear} text="Clear All" />
      </div>
    </div>
  );

  const renderSelectAllButton = () => {
    const allItemsSelected = totalLinkedAccountsFilteredSelected === totalLinkedAccountsFiltered;
    if (totalLinkedAccountsFiltered === 0) {
      return null;
    }
    const handleSelectAll = () => {
      if (allItemsSelected) {
        setSelectedAccounts([]);
      } else {
        setSelectedAccounts(
          Object.keys(accountsFiltered).reduce((acc, cloud) => {
            acc.push(
              ...accountsFiltered[cloud].map((item) => ({
                accountId: item.accountId,
                linkedAccountIds: 'all',
                label: getAccountLabelById(accounts, item.accountId),
              })),
            );
            return acc;
          }, []),
        );
      }
    };
    return (
      <div className={styles.menuItem} onClick={handleSelectAll}>
        <div className={styles.menuItemLabel}>
          <Checkbox isChecked={allItemsSelected} primary text="" onChange={handleSelectAll} />
          <span className={styles.selectAll}>Select All</span>
          <span className={styles.counter}>{totalLinkedAccountsFiltered}</span>
        </div>
      </div>
    );
  };
  const renderList = () => (
    <div className={styles.list}>
      {renderSelectAllButton()}
      {Object.keys(accountsFiltered)
        .filter((cloud) => accountsFiltered[cloud]?.length > 0)
        .map((cloud) => {
          const CloudIcon = cloudTypeToIcon[+cloud];
          const isExpanded = expanded[+cloud];
          const onClick = () => setExpanded({ ...expanded, [+cloud]: !isExpanded });
          const selected = linkedAccountsSelectedCounter(accountsFiltered, selectedAccounts, linkedAccounts, { cloud });
          const total = totalLinkedAccountsCounter({ [cloud]: accountsFiltered[cloud] }, linkedAccounts);
          return (
            <React.Fragment key={cloud}>
              <div className={styles.menuItem} onClick={onClick}>
                <div className={styles.menuItemLabel}>
                  <Checkbox
                    isChecked={selected > 0}
                    isHalfChecked={selected > 0 && selected < total}
                    onChange={(_, e) => {
                      e.stopPropagation();
                      if (selected > 0) {
                        setSelectedAccounts((prev) =>
                          prev.filter((s) => !accountsFiltered[cloud].some((item) => item.accountId === s.accountId)),
                        );
                      } else {
                        setSelectedAccounts((prev) => [
                          ...prev.filter((p) => !accountsFiltered[cloud].some((a) => a.accountId === p.accountId)),
                          ...accountsFiltered[cloud].map((item) => ({
                            accountId: item.accountId,
                            linkedAccountIds: 'all',
                            label: getAccountLabelById(accounts, item.accountId),
                          })),
                        ]);
                      }
                    }}
                    primary
                    text=""
                  />
                  <CloudIcon className={styles.cloudIcon} />
                  {mapCloudTypeIdToDescription.get(+cloud)}
                  <span className={classNames(styles.counter, { [styles.activeBadge]: selected > 0 })}>
                    ({selected} / {total})
                  </span>
                </div>
                <GenerateIcon iconName={ICONS.chevronDown.name} />
              </div>
              {isExpanded ? renderAccountsUnderCloud(cloud) : null}
            </React.Fragment>
          );
        })}
    </div>
  );

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <div className={styles.container}>
        <div className={styles.trigger} onClick={() => setOpen(!open)}>
          {totalLinkedAccountsSelected > 0
            ? `Selected Total ${totalLinkedAccountsSelected}/${totalLinkedAccounts}`
            : 'Select'}
          <GenerateIcon iconName={ICONS.caretDown.name} />
        </div>
        {selectedAccounts?.length > 0 && (
          <div onClick={() => setOpen(false)}>
            <SelectedAccounts
              accounts={accounts}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={setSelectedAccounts}
              linkedAccounts={linkedAccounts}
            />
          </div>
        )}
        {open ? (
          <div className={styles.mainMenu} data-position={position}>
            {accountIdOpened ? (
              <>
                <div className={styles.subMenuHeader} onClick={() => setSecondOpen(null)}>
                  <GenerateIcon iconName={ICONS.chevronLeft.name} />
                  {accountIdOpened}
                </div>
                <div className={styles.inputContainer}>
                  <Input
                    className={classNames(styles.searchInput, styles.searchInputSecond)}
                    isSearch
                    value={secondSearch}
                    searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
                    placeholder="Search"
                    onChange={(e) => setSecondSearch(e.target.value)}
                  />
                </div>
                <LinkedAccountsList
                  accounts={accounts}
                  linkedAccounts={linkedAccounts}
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                  accountIdOpened={accountIdOpened}
                  secondSearch={secondSearch}
                />
                {renderFooter(
                  getLinkedAccountsInAccount(accSelected, secondItemsFiltered).length,
                  secondItemsFiltered?.length,
                  () =>
                    setSelectedAccounts((selectedAccounts) =>
                      selectedAccounts.filter((acc) => acc.accountId !== accountIdOpened),
                    ),
                )}
              </>
            ) : (
              <>
                <div className={styles.inputContainer}>
                  <Input
                    className={styles.searchInput}
                    isSearch
                    value={search}
                    searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                {renderList()}
                {renderFooter(totalLinkedAccountsFilteredSelected, totalLinkedAccountsFiltered, () =>
                  setSelectedAccounts([]),
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

AccountsChooser.propTypes = {
  accounts: PropTypes.object.isRequired,
  linkedAccounts: PropTypes.object.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  setSelectedAccounts: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
};

export default AccountsChooser;
