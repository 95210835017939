import React from 'react';
import SaveAsDashboardPanel from './saveAsDashboardPanel';
import ViewsPanel from './viewsPanel';
import SavingsTracker from './savingsTracker';

import classes from './heatMapActions.module.scss';

const HeatMapActions = () => (
  <div className={classes.actions}>
    <SavingsTracker />
    <ViewsPanel />
    <SaveAsDashboardPanel />
  </div>
);

export default HeatMapActions;
