import React from 'react';
import PropTypes from 'prop-types';
import { AZURE_PROPERTIES, AZURE_RESERVED_INSTANCE_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import BaseRecommendation from '../baseRecommendation';

const AZURE_APP_SERVICE_RESERVED_CAPACITY = {
  SCOPE: {
    label: 'Scope',
    getterFunction: (recommendation) => recommendation?.recData?.scope,
  },
};

const AzureVmRightSizing = ({ recommendation }) => {
  const description = `We recommend to buy App Service reserved instance, to reduce cost.`;

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'Prepay for Azure App Service with reserved instance',
          actionLink: 'https://docs.microsoft.com/en-us/azure/cost-management-billing/reservations/prepay-app-service',
        },
      ],
    },
  ];

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...AZURE_PROPERTIES,
        ...AZURE_RESERVED_INSTANCE_PROPERTIES,
        ...AZURE_APP_SERVICE_RESERVED_CAPACITY,
      }}
    />
  );
};

AzureVmRightSizing.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureVmRightSizing;
