/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

// TODO update description
const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>{`Ebs Outdated Snapshot, this snapshot age is ${rec.elapsedTimeSinceCreation}, to avoid unnecessary costs, we recommend to delete the snapshot after 21 days. Note this recommendation does NOT include AMI snapshots. `}</p>
    <NavLink target="_blank" href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ebs-deleting-snapshot.html">
      How to delete an Amazon EBS snapshot
    </NavLink>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
