/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { OsRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';
// import { sliceLastChar } from 'shared/utils/strUtil';

export default class OsCostRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new OsRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_OPERATION_SYS;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Operation System`;
  }

  build() {
    super.build();
    const { zone_tag: tagZone, potential_savings: potentialSavings } = this._rawRecommendation;

    this._recommendation.region = tagZone;
    this._recommendation.potentialSavings = potentialSavings;
  }
}
