import React from 'react';
import { Search } from '../../components/Search';
import RemoveButton from '../../components/RemoveButton';
import AddSubRolesToRole from '../roleModals/AddSubRolesModal/AddSubRolesToRole';

import styles from './RoleTab.module.scss';

const RoleSubRolesTabHeader = ({ subRolesCount, selectedSubRoles, search, setSearch, removeSubRoleClicked, role }) => (
  <div className={styles.headerContainer}>
    <div className={styles.headerLeft}>
      <div className={styles.header}>
        <div className={styles.title}>
          Roles <span className={styles.count}>({subRolesCount})</span>
        </div>
      </div>
    </div>
    <div className={styles.headerRight}>
      <RemoveButton
        showRemoveComponent={selectedSubRoles?.length}
        isMultiple
        selectedCounter={selectedSubRoles?.length}
        removeClicked={removeSubRoleClicked}
      />
      <AddSubRolesToRole role={role} />
      <Search search={search} setSearch={setSearch} />
    </div>
  </div>
);

export default RoleSubRolesTabHeader;
