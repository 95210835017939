/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
// TODO  - REFACTOR ALL TYPES OF ALERTS AND REPALCE THIS FILE BY THE NEW ONE
import * as usageConstants from 'usage/constants/usageConstants';
import ICONS from 'shared/constants/assetsConstants';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import CauValidationHelper from './cauAlertsValidationHelper';

export default class CauAlertsHelper {
  constructor(context, usageStore, invoiceStore, updatePreSaveAlertsValidationStatus, alertId) {
    this.context = context;
    this.usageStore = usageStore;
    this.invoiceStore = invoiceStore;
    this.updatePreSaveAlertsValidationStatus = updatePreSaveAlertsValidationStatus;
    this.alertId = alertId;
    this.cauValidationHelper = new CauValidationHelper(
      context,
      usageStore,
      invoiceStore,
      updatePreSaveAlertsValidationStatus,
      alertId,
    );
  }

  saveAllCueAlertsOfType = async (usageStore, alertyType) => {
    try {
      const result = await usageStore.saveAllCueAlertsOfType(alertyType);
      return result;
    } catch (error) {
      return false;
    }
  };
  saveCueAlertsOfType = async (usageStore, alertyType) => {
    try {
      const result = await usageStore.saveCueAlertsOfType(alertyType);
      return result;
    } catch (error) {
      return false;
    }
  };
  cancelCostChangesAlerts = (usageStore, alertyType) => {
    try {
      const result = usageStore.cancelCostChangesAlerts(alertyType);
      return result;
    } catch (error) {
      return false;
    }
  };
  deleteCostChangesAlert = async (usageStore, alertyType, alertId) => {
    try {
      const result = usageStore.deleteCostChangesAlert(alertyType, alertId);
      return result;
    } catch (error) {
      return false;
    }
  };

  prepareAlertDataToState = (alert) => {
    const alertParams = alert ? alert.alertParams : null;
    const { alertStatus, alertType, granularity, dayInWeek, ...others } = alertParams || {};
    const selectedFilterValues = { ...others };
    return { alertStatus, alertType, selectedFilterValues, granularity, dayInWeek };
  };

  getActionButtonIcon = (buttName) => {
    let icon = ICONS.plus;
    switch (buttName) {
      case 'add':
        icon = ICONS.DiskSaveIcon;
        break;
      case 'delete':
        icon = ICONS.buttonX;
        break;
      case 'cancel':
        icon = ICONS.buttonX;
        break;
      case 'edit':
        icon = ICONS.squareEdit;
        break;

      default:
        icon = ICONS.plus;
        break;
    }
    return icon;
    // let icon = ICONS.plus;
    // if (this.context.state.alertStatus === CAU_ALERTS_STATUS.REMOVE || this.context.state.alertStatus === CAU_ALERTS_STATUS.EDIT) {
    //   icon = ICONS.buttonX;
    // }
    // if (this.context.state.alertStatus === CAU_ALERTS_STATUS.SAVE) {
    //   icon = buttName === 'edit' ? ICONS.squareEdit : ICONS.buttonX;
    // }
    // return icon;
  };

  getActionButtonHandler = (e, id) => {
    const actionName = e.target.name;
    const handler = !actionName
      ? this.actionHandlers[this.context.state.alertStatus]
      : this.actionHandlers[this.context.state.alertStatus][actionName];
    // handler();
    handler(e, id);
  };

  createOptipons = (typesToDisplayObj) => {
    let options = {};
    try {
      options = Object.entries(typesToDisplayObj).map(([k, v]) => ({ value: k, label: v }));
    } catch (error) {
      return options;
    }
    return options;
  };
  updateLocalAlertIfInEdit = (type, selectedOptions, validationStatus) => {
    const { alertType, alertStatus } = this.context.state;
    const isValid = this.cauValidationHelper.isValidationStatusValid(alertType, validationStatus);
    // const isValid = isCheckValidation ? validationStatus[type].isValid : true;
    if (alertStatus === usageConstants.CAU_ALERTS_STATUS.EDIT && isValid) {
      const editedParam = { [type]: selectedOptions };
      this.usageStore.updateEditedCauAlertParam(editedParam, this.alertId);
    }
  };
  addAlertHandler = (e, id) => {
    let { alertStatus, alertType } = this.context.state;
    if (alertStatus !== usageConstants.CAU_ALERTS_STATUS.ADD) {
      return;
    }
    const validationStatus = this.cauValidationHelper.validateAlertBeforeAdd();
    this.context.setState({ validationStatus });
    if (validationStatus.isValid) {
      const alertsParams = this.context.state.selectedFilterValues;
      alertStatus = usageConstants.CAU_ALERTS_STATUS.REMOVE;
      alertsParams.alertStatus = alertStatus;
      alertsParams.alertType = alertType;
      this.usageStore.addCauAlert(alertType, alertsParams, this.alertId);
      this.context.resetToBaseState();
    }
  };
  saveAlertHandler = async (e, id) => {
    const { rootStore } = this.usageStore;
    const { usersStore } = rootStore;
    segmentEvent({ type: 'click-event', target: 'alerts_save' }, usersStore);
    let { alertStatus, alertType, granularity, dayInWeek } = this.context.state;
    const isSaveAfterEdit = alertStatus === usageConstants.CAU_ALERTS_STATUS.EDIT;
    // if (alertStatus !== CAU_ALERTS_STATUS.ADD) return;
    const validationStatus = this.cauValidationHelper.validateAlertBeforeAdd();
    this.context.setState({ validationStatus });
    if (validationStatus.isValid) {
      const alertsParams = this.context.state.selectedFilterValues;
      alertStatus = usageConstants.CAU_ALERTS_STATUS.SAVE;
      alertsParams.alertStatus = alertStatus;
      alertsParams.alertType = alertType;
      alertsParams.granularity = granularity;
      alertsParams.dayInWeek = dayInWeek;
      this.usageStore.isLoading = true;
      const isSaveSuccess = await this.usageStore.saveCauAlert(alertType, alertsParams, id, isSaveAfterEdit);
      if (!isSaveSuccess) {
        this.context.setState((prevState) => ({ isErrorMsg: { ...prevState.isErrorMsg, isOpen: true } }));
      }
      this.context.resetToBaseState();
      this.usageStore.isLoading = false;
    }
  };
  removeAlertHandler = () => {
    const { alertStatus } = this.context.state;
    if (alertStatus !== usageConstants.CAU_ALERTS_STATUS.REMOVE) {
      return;
    }
    this.usageStore.removeCuaAlert(this.alertId);
    this.context.resetToBaseState();
  };
  cancelEditHandler = () => {
    const { alertStatus } = this.context.state;
    if (alertStatus !== usageConstants.CAU_ALERTS_STATUS.EDIT) {
      return;
    }
    // this.resetToValidationBaseState();
    this.usageStore.cancelCuaAlertEdit(this.alertId);
  };
  handleDeleteAlertClicked = () => {
    this.context.setState({ isDeleteAlertWarning: true });
  };
  handleEditAlertClicked = (e) => {
    this.usageStore.editCauAlertStatus(this.alertId, usageConstants.CAU_ALERTS_STATUS.EDIT);
  };
  actionHandlers = {
    [usageConstants.CAU_ALERTS_STATUS.ADD]: { add: this.saveAlertHandler },
    // [CAU_ALERTS_STATUS.ADD]: this.addAlertHandler,
    [usageConstants.CAU_ALERTS_STATUS.REMOVE]: { cancel: this.removeAlertHandler },
    // [CAU_ALERTS_STATUS.EDIT]: this.cancelEditHandler,
    [usageConstants.CAU_ALERTS_STATUS.EDIT]: {
      add: this.saveAlertHandler,
      cancel: this.cancelEditHandler,
    },
    [usageConstants.CAU_ALERTS_STATUS.SAVE]: {
      delete: this.handleDeleteAlertClicked,
      edit: this.handleEditAlertClicked,
    },
  };

  // inputs ********
  handleTextInputChange = (e) => {
    e.preventDefault();
    let val = e.target.value;
    const attr = e.target.name;
    if (attr === 'daysBeforeExpiry') {
      val = val > 0 ? val : '1';
    }
    this.onFilterChangeHandler(attr, val);
  };
  prepareOptionWithSelectAll = (type, selectedOptions) => {
    if (Array.isArray(selectedOptions) && selectedOptions.length > 1) {
      const allOptionIndex = selectedOptions.findIndex((option) => option.value === 'all');
      if (allOptionIndex > -1) {
        // check if there is all and it's location
        if (allOptionIndex === selectedOptions.length - 1) {
          // all was selected
          selectedOptions.splice(0, selectedOptions.length - 1);
          return;
        }
        selectedOptions.splice(allOptionIndex, 1);
      }
    }
  };
  onFilterChangeHandler = (type, selectedOptions) => {
    const { selectedFilterValues, alertType } = this.context.state;
    this.prepareOptionWithSelectAll(type, selectedOptions);
    // const selectedOptionsPrepWithSelectAll = this.prepareOptionWithSelectAll(type, selectedOptions);
    selectedFilterValues[type] = selectedOptions;
    const validationStatus = this.cauValidationHelper.inputSelfValidation(type, selectedOptions);
    if (alertType === usageConstants.CAU_ALERTS_TYPES.COST_CHANGES) {
      validationStatus.changeValueAndPercent = this.context.validateValueAndPercent(
        selectedFilterValues.operatorType.value,
        validationStatus.changePercent.isValid,
        validationStatus.changeValue.isValid,
      );
    }
    this.updateLocalAlertIfInEdit(type, selectedOptions, validationStatus);
    this.context.setState({ selectedFilterValues, validationStatus });
  };
  onOneChoiceFieldFilterChangeHandler = (type, selectedOptions) => {
    const { selectedFilterValues, validationStatus, alertType } = this.context.state;
    selectedFilterValues[type] = selectedOptions;
    if (alertType === usageConstants.CAU_ALERTS_TYPES.COST_CHANGES) {
      validationStatus.changeValueAndPercent = this.context.validateValueAndPercent(
        selectedFilterValues.operatorType.value,
        validationStatus.changePercent.isValid,
        validationStatus.changeValue.isValid,
      );
    }
    this.updateLocalAlertIfInEdit(type, selectedOptions, validationStatus);
    this.context.setState({ selectedFilterValues, validationStatus });
  };
}
