/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes, S3AttentionComment } from 'recommendations/constants/recommendationsConstants';
import { S3MultipartUploadRecommendation } from 'recommendations/models/recommTypes';
import S3CostRecommendationBuilder from 'recommendations/models/builders/s3CostRecommBuilder';

export default class S3MultipartUploadRecommBuilder extends S3CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new S3MultipartUploadRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.S3_MULTIPART_UPLOAD;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} S3 Multipart Upload`;
  }

  build() {
    super.build();
    const { total_upload_size: totalUploadSize } = this._rawRecommendation;
    this._recommendation.totalUploadSize = parseFloat(totalUploadSize);
    this._recommendation.attentionComment = S3AttentionComment;
  }
}
