import React, { memo } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ICONS from 'shared/constants/assetsConstants';
import { Routes } from 'shared/constants/routes';
import IconFromPng from 'shared/components/IconFromPng';

const PanelWithActionButton = memo((props) => {
  const {
    md,
    lg,
    xl,
    sm,
    xs,
    color,
    divider,
    title,
    subhead,
    ActionButtonComp,
    ButtonIcon,
    handler,
    actionButtonStyle,
    link,
    id,
    children,
  } = props;
  return (
    <Col md={md} lg={lg} xl={xl} sm={sm} xs={xs}>
      <Card
        className={`panel${color ? ` panel--${color}` : ''}
        ${divider ? ' panel--divider' : ''}`}
      >
        <CardBody className="panel__body">
          <div className="card-header mb-3" style={{ background: 'white' }}>
            <h5 className="dashboard__description">
              {title}
              <ActionButtonComp
                id={id}
                containerStyle={{
                  ...(actionButtonStyle || {}),
                  height: '100%',
                  position: 'absolute',
                  right: '10px',
                }}
              >
                {link && (
                  <Link
                    key={id}
                    onClick={() => handler(id)}
                    to={{
                      pathname: `${Routes.COST_USAGE_EXPLORER}`,
                      source: {
                        id,
                        isFromReports: true,
                      },
                    }}
                  />
                )}
                {ButtonIcon && <IconFromPng size={23} icon={ICONS.reportGlass} />}
              </ActionButtonComp>
            </h5>
            <h5 className="subhead">{subhead}</h5>
          </div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
});

PanelWithActionButton.propTypes = {
  divider: PropTypes.bool,
  link: PropTypes.bool,
  ButtonIcon: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  subhead: PropTypes.string,
  usageStore: PropTypes.object.isRequired,
  ActionButton: PropTypes.object.isRequired,
  handler: PropTypes.object.isRequired,
  actionButtonStyle: PropTypes.object.isRequired,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
};
PanelWithActionButton.defaultProps = {
  divider: false,
  link: true,
  ButtonIcon: true,
  color: '',
  title: '',
  subhead: '',
  md: 12,
  lg: 12,
  xl: 12,
  sm: 12,
  xs: 12,
};

export default PanelWithActionButton;
