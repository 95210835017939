import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';

export const updatePreferences = (preferences, value, key, uuid) =>
  preferences.map((p) => {
    if (p.uuid !== uuid) {
      return p;
    }
    return {
      ...p,
      updated: 1,
      preferences: {
        ...p.preferences,
        [key]: value,
      },
    };
  });

export const addPreference = (preferences, preference) => [
  ...preferences.filter((p) => !preference.uuid || preference.uuid !== p.uuid),
  {
    ...preference,
    updated: 1,
  },
];

const fetchPreferences = async () => {
  const result = await API.get('billings', '/api/v1/users/preferences');
  return result;
};

const savePreferences = async (preferences) => {
  const result = await API.post('billings', '/api/v1/users/preferences/bulk', { body: preferences });
  return result;
};

const fetchPreferencesByTypeAndKeyWithTenant = async (type, key) => {
  const result = await API.get('billings', `/api/v1/users/preferences/type/${type}/key/${key}`);
  return result;
};

export const STALE_TIME = 60 * 60 * 1000;

export const usePreferences = () => {
  const queryClient = useQueryClient();
  return {
    fetchPreferences: (accountKey) =>
      useQuery([apiConstants.QUERY_KEYS.PREFERENCES, accountKey], fetchPreferences, {
        retry: false,
        staleTime: STALE_TIME,
      }),
    fetchPreferencesByTypeAndKeyWithTenant: (accountKey, type, key) =>
      useQuery([apiConstants.QUERY_KEYS.PREFERENCES_BY_TYPE, accountKey, type, key], () =>
        fetchPreferencesByTypeAndKeyWithTenant(type, key),
      ),
    useSaveMutation: () =>
      useMutation(savePreferences, {
        onSuccess: () => {
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.PREFERENCES);
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.PREFERENCES_BY_TYPE);
        },
      }),
  };
};
