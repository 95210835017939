import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import PermissionsExplanation from './PermissionsExplaination';
import { PERMISSION_ACTIONS, PERMISSION_ENTITIES, PERMISSION_TYPES } from '../../consts';
import Permission from './Permission';

import styles from './EffectivePermissions.module.scss';

const EffectivePermissions = ({ permissions, permissionChanged, roleId, userId, isCreateEditMode }) => {
  const [filterByAvailablePermissions, setFilterByAvailablePermissions] = useState(isCreateEditMode ?? true);
  const [permissionsToDisplay, setPermissionsToDisplay] = useState(null);

  useEffect(() => {
    if (permissions) {
      let tmpPermissionsToDisplay;
      if (filterByAvailablePermissions) {
        tmpPermissionsToDisplay = permissions.filter((p) =>
          p.actionPermissions.some((ap) => ap.permissions.some((p) => p.permissionType !== PERMISSION_TYPES.NONE.id)),
        );
      } else {
        const permissionsCategoriesFromEnum = Object.values(PERMISSION_ENTITIES).map((item) => item.id);
        const rolePermissionCategories = permissions.flatMap((c) => c.category);
        const mergedCategories = [...rolePermissionCategories, ...permissionsCategoriesFromEnum];
        const allCategoriesPermissions = mergedCategories.map((category) => ({
          category,
          actionPermissions: Object.values(PERMISSION_ACTIONS).map((action) => ({
            action: action.id,
            permissions: [],
          })),
        }));
        tmpPermissionsToDisplay = allCategoriesPermissions.map((item) => {
          const found = permissions.find((obj) => obj.category === item.category);
          return {
            ...item,
            actionPermissions: found?.actionPermissions ? [...found.actionPermissions] : [],
          };
        });
      }
      setPermissionsToDisplay(tmpPermissionsToDisplay?.sort((a, b) => a.category.localeCompare(b.category)));
    }
  }, [permissions, filterByAvailablePermissions]);

  const getCategoryNewPermissions = (categoryIndex, actionId, newPermission) => {
    const categoryActionPermission = {
      permissionType: newPermission ? PERMISSION_TYPES.FULL.id : PERMISSION_TYPES.NONE.id,
    };

    return {
      category: permissions[categoryIndex].category,
      actionPermissions: [
        {
          action: actionId,
          permissions: [categoryActionPermission],
        },
      ],
    };
  };

  const onPermissionChange = (categoryPermissions, actionId, newPermission) => {
    let categoryIndex = permissions?.findIndex((c) => c.category === categoryPermissions?.category);
    if (categoryIndex === -1) {
      permissions.push(categoryPermissions);
      categoryIndex = permissions.length - 1;
    }
    const categoryNewPermissions = getCategoryNewPermissions(categoryIndex, actionId, newPermission);
    permissionChanged(categoryNewPermissions);
  };

  return (
    <div className={styles.effectivePermissionsContainer}>
      <div className={styles.titleContainer}>
        <div>Effective Permissions</div>
        <Checkbox
          className={styles.showAvailablePermissions}
          isChecked={filterByAvailablePermissions}
          onChange={() => {
            setFilterByAvailablePermissions(!filterByAvailablePermissions);
          }}
          primary
          text="Show granted permissions"
        />
      </div>
      <div className={styles.container}>
        <div className={styles.permissionsGrid}>
          <div className={classNames(styles.gridCell, styles.headerCell, styles.entityHeaderCell)}>Entities</div>
          {Object.values(PERMISSION_ACTIONS).map((action) => (
            <div className={classNames(styles.gridCell, styles.headerCell)}>
              <div>{action.name}</div>
            </div>
          ))}
          {Array.isArray(permissionsToDisplay) &&
            permissionsToDisplay?.map((categoryPermissions) => (
              <>
                <div className={classNames(styles.gridCell, styles.entityCell)}>
                  {PERMISSION_ENTITIES[categoryPermissions.category]?.title || categoryPermissions.category}
                </div>
                {Object.values(PERMISSION_ACTIONS).map((action) => (
                  <div className={classNames(styles.gridCell, styles.valuesCell)}>
                    <Permission
                      actionId={action.id}
                      category={categoryPermissions.category}
                      userId={userId}
                      onPermissionChange={(newPermission) =>
                        onPermissionChange(categoryPermissions, action.id, newPermission)
                      }
                      permissions={
                        categoryPermissions?.actionPermissions?.find((p) => p.action === action.id)?.permissions
                      }
                      roleId={roleId}
                    />
                  </div>
                ))}
              </>
            ))}
        </div>
        <PermissionsExplanation />
      </div>
    </div>
  );
};

export default EffectivePermissions;
