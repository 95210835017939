/* eslint-disable arrow-parens, no-unused-vars */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { MoreVertical } from 'react-feather';

class RecActionButton extends React.Component {
  state = {
    anchorEl: null,
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (virtualTag, handler) => {
    this.handleClose();
    handler(virtualTag);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { virtualTag, handlers } = this.props;
    const { edit, deleteVirtualTag } = handlers;
    return (
      <div>
        <IconButton
          className="material-table__toolbar-button"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          size="large">
          <MoreVertical size={22} color="black" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="material-table__filter-menu"
        >
          <MenuItem
            onClick={() => {
              this.handleMenuItemClick(virtualTag, edit);
            }}
            className="material-table__filter-menu-item"
          >
            <h6>Edit</h6>
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleMenuItemClick(virtualTag, deleteVirtualTag);
            }}
            className="material-table__filter-menu-item"
          >
            <h6>Delete</h6>
          </MenuItem>
          <Divider />
        </Menu>
      </div>
    );
  }
}

const ObserverRecActionButton = observer(RecActionButton);
export default ObserverRecActionButton;
RecActionButton.propTypes = {
  handlers: PropTypes.object.isRequired,
  virtualTag: PropTypes.object.isRequired,
};
