import React, { HTMLProps, ReactNode } from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { IconNames } from '@pileus-cloud/anodot-frontend-common/dist/types';
import classNames from 'classnames';
import styles from './coloredBlock.module.scss';

export interface ColoredFormBlockProps extends HTMLProps<HTMLDivElement> {
  className?: string;
  icon: IconNames | ReactNode;
  color: 'lilach' | 'orange' | 'blue' | 'eucaliptus' | 'marine';
  scrollable?: boolean;
}

const ColoredFormBlock: React.FC<ColoredFormBlockProps> = ({
  className,
  color,
  icon,
  children,
  scrollable,
  ...divProps
}) => (
  <div className={classNames(styles.coloredBlockWrapper, className, 'coloredBlock')} {...divProps}>
    <div className={classNames(styles.coloredBlockIconBlock, styles[color])}>
      {typeof icon === 'string' ? <GenerateIcon iconName={ICONS[icon].name} /> : icon}
    </div>
    <div
      className={classNames('colored-content', styles.coloredBlockContent)}
      style={scrollable ? { overflowY: 'auto' } : undefined}
    >
      {children}
    </div>
  </div>
);

export default ColoredFormBlock;
