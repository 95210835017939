import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';

export const BILLINGS_API_NAME = 'billings';
export const CUSTOM_DASHBOARD_ROOT = '/api/v1/usage/custom-dashboard';

const fetchCustomDashboards = () => API.get(BILLINGS_API_NAME, `${CUSTOM_DASHBOARD_ROOT}/dashboards`);

const fetchCustomDashboardTemplates = () => API.get(BILLINGS_API_NAME, `${CUSTOM_DASHBOARD_ROOT}/dashboards-templates`);

const fetchCustomDashboardPanels = () => API.get(BILLINGS_API_NAME, `${CUSTOM_DASHBOARD_ROOT}/panels`);

export default function customDashboardProvider() {
  return {
    fetchCustomDashboards: async (userKey) => {
      try {
        const queryKeyFetch = [apiConstants.QUERY_KEYS.CUSTOM_DASHBOARD_DASHBOARDS, userKey];
        const data = await queryClient.fetchQuery(queryKeyFetch, () => fetchCustomDashboards(), {
          retry: false,
          staleTime: Infinity,
        });
        return data;
      } catch (error) {
        throw error;
      }
    },
    fetchCustomDashboardTemplates: async (userKey) => {
      try {
        const queryKeyFetch = [apiConstants.QUERY_KEYS.CUSTOM_DASHBOARD_TEMPLATES, userKey];
        const data = await queryClient.fetchQuery(queryKeyFetch, () => fetchCustomDashboardTemplates(), {
          retry: false,
          staleTime: Infinity,
        });
        return data;
      } catch (error) {
        throw error;
      }
    },
    fetchCustomDashboardPanels: async (userKey) => {
      try {
        const queryKeyFetch = [apiConstants.QUERY_KEYS.CUSTOM_DASHBOARD_PANELS, userKey];
        const data = await queryClient.fetchQuery(queryKeyFetch, () => fetchCustomDashboardPanels(), {
          retry: false,
          staleTime: Infinity,
        });
        return data;
      } catch (error) {
        throw error;
      }
    },
  };
}
