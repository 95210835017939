import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CustomModal from 'shared/components/andtComponents/Modal';
import SelectInputsBar from 'shared/components/resellerPrivatePricing/SelectInputsBar';

import classes from './AddOrUpdatePrivatePricingModal.module.scss';

const AddOrUpdatePrivatePricingModal = (props) => {
  const {
    toggle,
    isOpen,
    className,
    modalState,
    inputListOfValues,
    inputSelectedOption,
    handleOneChoiceFilterChange,
    handleMultiChoiceFilterChange,
    handleSelectCloudFrontRegions,
    handleServiceFilterChange,
    handleNumericFilterChange,
    handleDisableMinimumFee,
    handleTextInputChange,
    handleDateChange,
    handleDiscountTypeChange,
    handleAddOrEditPrivatePricingData,
    handleChangeIncludeExcludeService,
    handleChangeIncludeExcludeMarketPlace,
    selectedInputsErrorMessage,
    scopeListOfValues,
    isShowScope,
    handleToggleScopeType,
    linkedAccountOptions,
    payerAccListOfValues,
    invoiceStore,
    cloudType,
  } = props;
  const getModalTitles = (state) => {
    let modalTitle = '';
    let buttonText = '';
    if (state === 'add') {
      modalTitle = 'Add Billing Rule';
      buttonText = 'Add';
    }
    if (state === 'edit') {
      modalTitle = 'Edit Billing Rule';
      buttonText = 'Edit';
    }
    return { modalTitle, buttonText };
  };

  return (
    <CustomModal
      overrideStyles={{ width: 800 }}
      open={isOpen}
      onClose={toggle}
      className={{ container: className, content: classes.scroll }}
      onSave={() => handleAddOrEditPrivatePricingData(modalState)}
      closeOnSave={false}
      title={getModalTitles(modalState).modalTitle}
    >
      <SelectInputsBar
        handleOneChoiceFilterChange={handleOneChoiceFilterChange}
        handleMultiChoiceFilterChange={handleMultiChoiceFilterChange}
        handleSelectCloudFrontRegions={handleSelectCloudFrontRegions}
        handleServiceFilterChange={handleServiceFilterChange}
        handleNumericFilterChange={handleNumericFilterChange}
        handleDisableMinimumFee={handleDisableMinimumFee}
        handleDateChange={handleDateChange}
        handleDiscountTypeChange={handleDiscountTypeChange}
        handleTextInputChange={handleTextInputChange}
        handleChangeIncludeExcludeService={handleChangeIncludeExcludeService}
        handleChangeIncludeExcludeMarketPlace={handleChangeIncludeExcludeMarketPlace}
        inputListOfValues={inputListOfValues}
        inputSelectedOption={inputSelectedOption}
        selectedInputsErrorMessage={selectedInputsErrorMessage}
        scopeListOfValues={scopeListOfValues}
        isShowScope={isShowScope}
        modalState={modalState}
        invoiceStore={invoiceStore}
        cloudType={cloudType}
        handleToggleScopeType={handleToggleScopeType}
        linkedAccountOptions={linkedAccountOptions}
        payerAccListOfValues={payerAccListOfValues}
      />
    </CustomModal>
  );
};

AddOrUpdatePrivatePricingModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  handleAddOrEditPrivatePricingData: PropTypes.func.isRequired,
  handleOneChoiceFilterChange: PropTypes.func.isRequired,
  handleSelectCloudFrontRegions: PropTypes.func.isRequired,
  handleServiceFilterChange: PropTypes.func.isRequired,
  handleNumericFilterChange: PropTypes.func.isRequired,
  handleDisableMinimumFee: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleDiscountTypeChange: PropTypes.func.isRequired,
  handleMultiChoiceFilterChange: PropTypes.func.isRequired,
  handleTextInputChange: PropTypes.func.isRequired,
  handleChangeIncludeExcludeService: PropTypes.func.isRequired,
  handleChangeIncludeExcludeMarketPlace: PropTypes.func.isRequired,
  inputListOfValues: PropTypes.object.isRequired,
  scopeListOfValues: PropTypes.object.isRequired,
  inputSelectedOption: PropTypes.object.isRequired,
  selectedInputsErrorMessage: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  linkedAccountOptions: PropTypes.array.isRequired,
  handleToggleScopeType: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalState: PropTypes.string.isRequired,
  isShowScope: PropTypes.bool.isRequired,
  cloudType: PropTypes.number.isRequired,
  className: PropTypes.bool,
  payerAccListOfValues: PropTypes.object.isRequired,
};
AddOrUpdatePrivatePricingModal.defaultProps = {
  className: '',
};

const ObserverAAddOrUpdatePrivatePricingModal = observer(AddOrUpdatePrivatePricingModal);
export default ObserverAAddOrUpdatePrivatePricingModal;
