import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import styles from './Tooltip.module.scss';

// eslint-disable-next-line react/prop-types
export default function ({
  children,
  blank = false,
  arrow = true,
  placement = 'bottom',
  title,
  disabled = false,
  classes = {},
  ...other
}) {
  if (disabled) {
    return children;
  }
  const getTooltipClass = () => {
    if (blank) {
      return styles.transparent;
    }
    return classes.tooltip || '';
  };
  return (
    <Tooltip
      arrow={arrow}
      placement={placement}
      title={
        title && (
          <Typography variant="body" style={{ whiteSpace: 'pre-line' }}>
            {title}
          </Typography>
        )
      }
      style={{ cursor: 'pointer' }}
      classes={{ ...classes, tooltip: getTooltipClass() }}
      {...other}
    >
      {children}
    </Tooltip>
  );
}
