import Keycloak from 'keycloak-js';
import config from 'config';
import { parseJwt } from 'shared/utils/tokenUtil';

let keycloak;
let isInitiated = false;

const getRealmLocally = () => {
  try {
    let realm = document.cookie?.split('realm=')[1]?.split(';')[0];
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    if (!realm && authToken) {
      const tokenPayload = parseJwt(authToken);
      realm = tokenPayload.iss.split('/realms/')[1]?.split('/')[0];
    }
    return realm;
  } catch {
    return null;
  }
};

const persistRealm = ({ realmValue }) => {
  const isHttps = window.location.protocol === 'https:';
  const expirationTime = Date.now() + 60 * 1000;
  document.cookie = `realm=${realmValue || ''}; expires=${expirationTime}; path=/; ${isHttps ? 'secure;' : ''}`;
};

const persistUsername = ({ usernameValue }) => {
  const isHttps = window.location.protocol === 'https:';
  const expirationTime = Date.now() + 60 * 1000;
  document.cookie = `username=${usernameValue || ''}; expires=${expirationTime}; path=/; ${isHttps ? 'secure;' : ''}`;
};

const getKeycloakInstance = async ({ initiateInstance } = {}) => {
  try {
    const realm = getRealmLocally();
    const url = config.KEYCLOAK_URL;
    if (realm && (!keycloak || initiateInstance)) {
      keycloak = new Keycloak({
        realm,
        url: `${url}/auth/`,
        clientId: 'anodot-cost-login',
      });
    }
    if (keycloak && !keycloak.authenticated) {
      const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
      const refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken');
      if (authToken && refreshToken) {
        keycloak.token = authToken;
        keycloak.refreshToken = refreshToken;
      }
      const silentCheckSsoRedirectUri = `${window.location.origin}/silent-check-sso.html`;
      if (!isInitiated) {
        const authenticated = await keycloak.init({
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri,
        });
        isInitiated = true;
        if (!authenticated) {
          const username = document.cookie?.split('username=')[1]?.split(';')[0];
          keycloak.login({
            loginHint: username.toLowerCase(),
            // eslint-disable-next-line max-len
            // TODO - In case of multiple IDPs, or not all users use the IdP the IdP alias should be different from default_org_idp
            idpHint: 'default_org_idp',
          });
        }
      }
    }
    return keycloak;
  } catch {
    return null;
  }
};
const getKeycloakTokensFromInstance = (keycloakInstance) => {
  const keycloakToken = keycloakInstance?.token;
  const keycloakRefreshToken = keycloakInstance?.refreshToken;
  return { keycloakToken, keycloakRefreshToken };
};

const resetInstance = () => {
  keycloak = null;
  isInitiated = false;
};

export {
  getKeycloakInstance,
  resetInstance,
  getRealmLocally,
  persistRealm,
  persistUsername,
  getKeycloakTokensFromInstance,
};
