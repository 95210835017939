/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import CodeBlock from 'shared/components/CodeBlock';
import PropTypes from 'prop-types';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => {
  const { separateResource, resourceId } = rec;
  return (
    <ActionContainer>
      <ActionHeader />
      <ul style={{ 'list-style-type': 'none' }}>
        <li>Using the AWS CLI:</li>
        <CodeBlock
          text="aws ec2 modify-volume --volume-type gp3 --volume-id {{VOLUME_ID}}"
          variables={{
            VOLUME_ID: separateResource ? resourceId : 'VOLUME_ID',
          }}
        />
        <br />
      </ul>
    </ActionContainer>
  );
};

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
