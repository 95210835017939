import React, { useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import WritePermissionWrapper, { WRITE_PERMISSION_ENTITIES } from 'shared/components/WritePermissionWrapper';
import Button from 'shared/components/andtComponents/Button';
import { ROLE_ACTIONS } from '../consts';

import styles from './RoleActions.module.scss';

const RoleActions = (/* { row } */) => {
  const [isOpen, setIsOpen] = useState(false);

  // const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  // const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
  // const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.userActionsContainer} onClick={(e) => toggleOpen(e)}>
      <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger as="button" type="button">
          <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.actionsIcon} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={10} side="bottom" className={styles.popoverContent}>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.VIEW.label}
                automationId={ROLE_ACTIONS.VIEW.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.VIEW.icon} className={styles.itemIcon} />}
                // onClick={() => setIsEditRoleModalOpen(true)}
              />
            </WritePermissionWrapper>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.EDIT.label}
                automationId={ROLE_ACTIONS.EDIT.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.EDIT.icon} className={styles.itemIcon} />}
                // onClick={() => setIsEditRoleModalOpen(true)}
              />
            </WritePermissionWrapper>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.DUPLICATE.label}
                automationId={ROLE_ACTIONS.DUPLICATE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.DUPLICATE.icon} className={styles.itemIcon} />}
                onClick={() => {}}
              />
            </WritePermissionWrapper>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={ROLE_ACTIONS.DELETE.label}
                automationId={ROLE_ACTIONS.DELETE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={ROLE_ACTIONS.DELETE.icon} className={styles.itemIcon} />}
                // onClick={() => setIsDeleteUserModalOpen(true)}
              />
            </WritePermissionWrapper>
          </PopoverContent>
        </PopoverPortal>
      </Popover>

      {/* <AssignRolesModal setIsOpen={setIsAssignRolesModalOpen} isOpen={isAssignRolesModalOpen} users={[row]} /> */}
      {/* <EditUserModal setIsOpen={setIsEditRoleModalOpen} isOpen={isEditRoleModalOpen} user={row} /> */}
      {/* <DeleteUsersModal setIsOpen={setIsDeleteUserModalOpen} isOpen={isDeleteUserModalOpen} users={[row]} /> */}
    </div>
  );
};

export default RoleActions;
