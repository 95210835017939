/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';
// TODO update description
const Description = ({ rec }) => (
  <DescriptionWrapper>
    <p>
      This Vm instance has a low CPU utilization, we recommend to switch to a more suitable instance type which will
      cost less.
    </p>
  </DescriptionWrapper>
);

Description.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Description;
