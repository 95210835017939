import React, { useMemo } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import styles from './UserImpersonate.module.scss';

const UserImpersonate = ({ row }) => {
  const impersonateUser = () => {
    // console.log('Impersonate user', row);
  };

  const impersonateTooltipText = useMemo(
    () => `Impersonate ${row.firstName && row.lastName ? ` ${row.firstName} ${row.lastName}` : `${row.username}`}`,
    [row],
  );

  return (
    <Tooltip title={impersonateTooltipText} placement="top">
      <span>
        <GenerateIcon iconName={ICONS.userSecret.name} onClick={impersonateUser} className={styles.impersonateIcon} />
      </span>
    </Tooltip>
  );
};

export default UserImpersonate;
