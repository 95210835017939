import { useState } from 'react';

export const useSubTableState = () => {
  const [selectedRows, setSelectedRows] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [showFilterRows, setShowFilterRows] = useState({});
  const [page, setPage] = useState({});
  const [size, setSize] = useState({});
  const [additionalProps, setAdditionalProps] = useState({});
  return {
    getPage: (id) => page[id] || 0,
    setPage: (id, newPage) => setPage({ ...page, [id]: newPage }),
    getSize: (id) => size[id] || 10,
    setSize: (id, newSize) => setSize({ ...size, [id]: newSize }),
    getAdditionalProps: (id) => additionalProps[id] || {},
    setAdditionalProps: (id, props) => setAdditionalProps({ ...additionalProps, [id]: props }),
    getSelectedRows: (id) => selectedRows[id] || [],
    setSelectedRows: (id, rows) => setSelectedRows({ [id]: rows }),
    getExpandedRows: (id) => expandedRows[id] || [],
    setExpandedRows: (id, rows) => setExpandedRows({ ...expandedRows, [id]: rows }),
    getShowFilterRows: (id) => showFilterRows[id] || [],
    setShowFilterRows: (id, show) => setShowFilterRows({ ...showFilterRows, [id]: show }),
    anySelected: Object.values(selectedRows).some((rows) => rows?.length > 0),
  };
};
