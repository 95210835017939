/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import { Col, Row } from 'reactstrap';

const createOptions = (fieldValues, filterType) =>
  !fieldValues
    ? [{ value: '', label: '' }]
    : fieldValues.map((fieldValue) => {
        let value = '';
        let label = '';
        if (Object.prototype.toString.call(fieldValue) === '[object Object]' && 'linkedAccountId' in fieldValue) {
          value = fieldValue.linkedAccountId;
          label = fieldValue.displayLabel;
        } else {
          value = fieldValue;
          label = fieldValue;
        }
        return { value, label };
      });
const ServiceFilter = (props) => (
  <Row style={{ alignItems: 'center', marginLeft: '15px', width: '400px' }}>
    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
      {/* <div className="filter-bar-inner-col-wrapper-large"> */}
      {/* <h5 className="filter-bar-inner-col-title">Commitment Service (*)</h5> */}
      <OneChoiceFieldFilter
        type="selectedCommitmentService"
        value={props.selectedCommitmentService}
        options={props.commitmentServiceOptions}
        handleChange={props.handleFilterChange}
        isClearable
        placeholder="Select a Service"
      />
      {/* </div> */}
    </Col>
    {/* <Col xl={4} lg={4} md={6} sm={6} xs={12}>
      <div className="filter-bar-inner-col-wrapper-large">
        <h5 className="filter-bar-inner-col-title">Linked Account</h5>
        <OneChoiceFieldFilter
          type="selectedLinkedAccount"
          value={props.selectedLinkedAccount}
          options={createOptions(props.linkedAccountOptions)}
          handleChange={props.handleFilterChange}
          isClearable
        />
      </div>
    </Col>
    <Col xl={3} lg={3} md={6} sm={6} xs={12}>
      <Button
        color="primary"
        style={{
          width: '150px',
          height: '40px',
          fontSize: '16px',
          marginTop: '30px',
          backgroundColor: '#4395e4',
          marginLeft: '15px',
        }}
        onClick={props.getCommitmentTableData}
        disabled={!props.isGetTableDataButtonActive}
      >
        Apply
      </Button>
    </Col> */}
  </Row>
);

ServiceFilter.propTypes = {
  commitmentServiceOptions: PropTypes.array.isRequired,
  selectedCommitmentService: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.object.isRequired,
  //   selectedLinkedAccount: PropTypes.string.isRequired,
  //   linkedAccountOptions: PropTypes.array.isRequired,
  //   getCommitmentTableData: PropTypes.object.isRequired,
  //   isGetTableDataButtonActive: PropTypes.bool.isRequired,
};

export default ServiceFilter;
