import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentPageTypes,
  mapRecpageTypeToDefaultDetailsDiaply,
} from 'recommendations/constants/recommendationsConstants';
import { renderContentDetails } from 'recommendations/constants/recommContentCreators';
import ContentDetails from 'recommendations/containers/DailyRecommendations/components/ContentDetails';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const Content = ({ rec, contentPageType }) => {
  const { getCurrencyNumber, currencySymbol, currencyCode } = useUserSettingsContext();
  return (
    <div style={{ 'line-height': '30px', 'font-size': '13px' }}>
      <table
        className={`project-summary__info ${contentPageType === ContentPageTypes.DAILY_RECS ? 'daily-rec__width' : ''}`}
      >
        <ContentDetails contentDisplay={mapRecpageTypeToDefaultDetailsDiaply.get(contentPageType)}>
          {renderContentDetails(rec, contentPageType, getCurrencyNumber, currencyCode, currencySymbol)}
        </ContentDetails>
      </table>
    </div>
  );
};

Content.propTypes = {
  rec: PropTypes.object.isRequired,
  contentPageType: PropTypes.object.isRequired,
};

export default Content;
