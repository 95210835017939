/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable max-len, */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars,arrow-parens */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import PanelWithActionButton from 'shared/components/panels/PanelWithActionButton';
import { REPORT_TYPES } from 'usage/constants/usageConstants';
import ReportIcon from 'mdi-react/FileDocumentBoxIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import moment from 'moment';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { PREV_VS_CURRENT_CHARTS_KEYS } from '../../../constants/dbConstants';

class CurrVsPreviousMonthCost extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    height: PropTypes.object.isRequired,
    actionButtonStyle: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    xLabel: PropTypes.string.isRequired,
    yLabel: PropTypes.string.isRequired,
    handleGoToCostExplorerReport: PropTypes.func.isRequired,
    xl: PropTypes.number.isRequired,
    lg: PropTypes.number.isRequired,
    md: PropTypes.number.isRequired,
    sm: PropTypes.number.isRequired,
    xs: PropTypes.number.isRequired,
    isCustomDashboard: PropTypes.bool,
    overrideCurrency: PropTypes.string,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };
  static defaultProps = {
    isCustomDashboard: false,
    overrideCurrency: null,
  };

  CustomTooltip = ({ active, payload }) => {
    if (payload.length > 0) {
      const { currMonthValue, currMonthColor, currMonthDate } = this.getItemValAndColorFromPayload(
        PREV_VS_CURRENT_CHARTS_KEYS.CURRENT_MONTH,
        payload,
      );
      const { prevMonthValue, prevMonthColor, prevMonthDate } = this.getItemValAndColorFromPayload(
        PREV_VS_CURRENT_CHARTS_KEYS.PREVIOUS_MONTH,
        payload,
      );
      const { currExpectedValue, currExpectedColor, currExpectedDate } = this.getItemValAndColorFromPayload(
        PREV_VS_CURRENT_CHARTS_KEYS.FORCASTED_COST,
        payload,
      );

      return (
        <div className="chart-tooltip fs-exclude">
          {prevMonthValue ? (
            <p>
              {prevMonthDate}
              <p style={{ color: `${prevMonthColor}` }}>{`Previous month: ${prevMonthValue}`}</p>{' '}
            </p>
          ) : null}
          {/* when payload[0].payload include date field it's old forecast format */}
          {currMonthValue && ((!payload[0].payload.date && !prevMonthValue) || payload[0].payload.date) ? (
            <p>
              {currMonthDate}
              <p style={{ color: `${currMonthColor}` }}>{`Current month: ${currMonthValue}`}</p>
            </p>
          ) : null}
          {currExpectedValue && !currMonthValue ? (
            <p>
              {currExpectedDate}
              <p style={{ color: `${currExpectedColor}` }}>{`Forecasted: ${currExpectedValue}`}</p>
            </p>
          ) : null}
        </div>
      );
    }
    return null;
  };
  CustomizedAxisTick = (props) => {
    const { data } = this.props;
    const { x, y, payload, index } = props;
    const lastDate = new Date(data[data.length - 1].fullDate);
    const numOfDayInLastMonth = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0).getDate();

    const getMonthLabel = () => (
      <text x={x} y={y} dy={32} textAnchor="middle" fill="#3d4c59" stroke="#3d4c59">
        {new Date(data[index].fullDate).toLocaleString('default', { month: 'long' })}
      </text>
    );

    // display the last month label when the month is not full
    if (
      payload.value !== '15' &&
      lastDate.getDate() < numOfDayInLastMonth &&
      payload.value === `${Math.floor(lastDate.getDate() / 2) < 10 ? '0' : ''}${Math.floor(lastDate.getDate() / 2)}` &&
      new Date(data[index].fullDate).getMonth() === lastDate.getMonth()
    ) {
      if (payload.value === '01') {
        return (
          <g x={x} y={y} stroke="#8884d8">
            <text x={x} y={y} dy={16} textAnchor="middle" fontSize={8} fill="#3d4c59" stroke="#3d4c59">
              {payload.value}
            </text>
            {getMonthLabel()}
          </g>
        );
      }
      return getMonthLabel();
    }
    if (payload.value !== '01' && payload.value !== '15') {
      return null;
    }
    // display only 1 and 15 day each month in xAxis
    return (
      <g x={x} y={y} stroke="#8884d8">
        <text x={x} y={y} dy={16} textAnchor="middle" fontSize={8} fill="#3d4c59" stroke="#3d4c59">
          {payload.value}
        </text>
        {payload.value === '15' &&
          (new Date(data[index].fullDate).getMonth() !== lastDate.getMonth() ||
            lastDate.getDate() === numOfDayInLastMonth ||
            Math.floor(lastDate.getDate() / 2) === 15) &&
          getMonthLabel()}
      </g>
    );
  };
  getItemValAndColorFromPayload = (itemName, payload) => {
    const payLoadItem = payload.filter((pl) => pl.dataKey === itemName);
    const { numStrAbriviaionByDisplayMetric, overrideCurrency } = this.props;
    let colorKey = '';
    let valueKey = '';
    let dateKey = '';
    if (payLoadItem && payLoadItem.length >= 1) {
      if (itemName === 'currMonth') {
        colorKey = 'currMonthColor';
        valueKey = 'currMonthValue';
        dateKey = 'currMonthDate';
      } else if (itemName === 'prevMonth') {
        colorKey = 'prevMonthColor';
        valueKey = 'prevMonthValue';
        dateKey = 'prevMonthDate';
      } else if (itemName === 'currExpected') {
        colorKey = 'currExpectedColor';
        valueKey = 'currExpectedValue';
        dateKey = 'currExpectedDate';
      }
      return {
        [`${colorKey}`]: payLoadItem[0].color,
        [`${valueKey}`]: Number.isNaN(payLoadItem[0].value)
          ? null
          : numStrAbriviaionByDisplayMetric(
              payLoadItem[0].value,
              payLoadItem[0].value,
              DisplayMetricTypes.COST,
              overrideCurrency,
            ),
        [`${dateKey}`]: payload[0].payload.fullDate ? moment(payload[0].payload.fullDate).format('MMMM DD YYYY') : null,
      };
    }
    return { [`${colorKey}`]: null, [`${valueKey}`]: null, [`${dateKey}`]: null };
  };

  // eslint-disable-next-line class-methods-use-this
  getMaxValue = (data) => {
    const maxValue = data.reduce((acc, currVal) => {
      acc =
        acc >= currVal.currMonth || acc >= currVal.prevMonth
          ? acc
          : currVal.currMonth > currVal.prevMonth
          ? currVal.currMonth
          : currVal.prevMonth;
      return acc;
    }, 1000);
    return maxValue || 1000;
  };

  newChart = (maxValue) => {
    const { data, yLabel, numStrAbriviaionByDisplayMetric, overrideCurrency } = this.props;
    return (
      <ComposedChart
        data={data.map((d) => ({
          ...d,
          prevMonth: +d.prevMonth,
          currMonth: +d.currMonth,
          currExpected: +d.currExpected,
        }))}
      >
        <XAxis dataKey="day" tick={this.CustomizedAxisTick} interval={0} domain={['dataMin', 'dataMax + 0.5']} />
        <XAxis dataKey="day" interval={0} domain={['dataMin', 'dataMax + 0.5']} />
        <YAxis
          label={{
            value: yLabel || '',
            offset: 0,
            angle: -90,
            position: 'left',
          }}
          tickFormatter={(value) => numStrAbriviaionByDisplayMetric(maxValue, value, 'cost', overrideCurrency)}
        />
        <Tooltip content={this.CustomTooltip} />
        <Tooltip />
        <Legend />
        <CartesianGrid stroke="#dbdbdb" strokeDasharray="3 3" />
        <Line type="linear" dataKey="prevMonth" dot={false} stroke="#4ce2b6" strokeWidth={2} name="Previous month" />
        <Line type="linear" dataKey="currMonth" dot={false} stroke="#5EB6F9" strokeWidth={2} name="Actual month" />
        <Line
          type="linear"
          dataKey="currExpected"
          dot={false}
          stroke="#efa64e"
          strokeWidth={2}
          strokeDasharray="5 5"
          name="Forecast month"
        />
      </ComposedChart>
    );
  };

  oldChart = (maxValue) => {
    const { data, xLabel, yLabel, numStrAbriviaionByDisplayMetric, overrideCurrency } = this.props;

    return (
      <ComposedChart data={data}>
        <XAxis
          dataKey="date"
          interval={0}
          tickCount={0}
          domain={['dataMin', 'dataMax + 0.5']}
          label={{
            value: xLabel || '',
            offset: 0,
            position: 'insideBottom',
          }}
        />
        <YAxis
          label={{
            value: yLabel || '',
            offset: 0,
            angle: -90,
            position: 'left',
          }}
          tickFormatter={(value) => numStrAbriviaionByDisplayMetric(maxValue, value, 'cost', overrideCurrency)}
        />
        <Tooltip content={this.CustomTooltip} />
        <Tooltip />
        <Legend />
        <CartesianGrid stroke="#dbdbdb" strokeDasharray="3 3" />
        <Area
          type="linear"
          dataKey="currMonth"
          dot={false}
          fill="#5EB6F9"
          stroke="#5EB6F9"
          name="Current month"
          fillOpacity={0.3}
        />
        <Line type="linear" dataKey="prevMonth" dot={false} stroke="#4ce2b6" strokeWidth={2} name="Previous month" />
        <Line
          type="linear"
          dataKey="currExpected"
          dot={false}
          stroke="#efa64e"
          strokeWidth={2}
          strokeDasharray="5 5"
          name="Forecast cost"
        />
      </ComposedChart>
    );
  };
  renderBody() {
    const { data, height } = this.props;
    // when data include date field it's old forecast format
    const isOldForecastFormat = data[0].date;
    const maxValue = this.getMaxValue(data) * 10;
    return (
      <Container>
        <Row id="comp-chart" className={!height ? 'overlap_legend' : null}>
          <ResponsiveContainer width="100%" height={height || 300}>
            {isOldForecastFormat ? this.oldChart(maxValue) : this.newChart(maxValue)}
          </ResponsiveContainer>
        </Row>
      </Container>
    );
  }

  render() {
    const { title, actionButtonStyle, handleGoToCostExplorerReport, xl, lg, md, sm, xs, isCustomDashboard } =
      this.props;
    if (isCustomDashboard) {
      return this.renderBody();
    }
    return (
      <PanelWithActionButton
        divider
        link
        title={title}
        panelClass="panel--narrow"
        ActionButtonComp={LinkIconButton}
        ButtonIcon={ReportIcon}
        handler={handleGoToCostExplorerReport}
        id={REPORT_TYPES.CURR_VS_PREV_MONTH}
        actionButtonStyle={actionButtonStyle}
        lg={lg}
        xl={xl}
        md={md}
        sm={sm}
        xs={xs}
      >
        {this.renderBody()}
      </PanelWithActionButton>
    );
  }
}

const ObserverCurrVsPreviousMonthCost = withUserSettingsConsumer(CurrVsPreviousMonthCost);

export default ObserverCurrVsPreviousMonthCost;
