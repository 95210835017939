import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti } from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import { withLinkedAccountsContextConsumer } from 'users/contexts/LinkedAccountsProvider';
import { uniqBy } from 'lodash';
import styles from './Filters.module.scss';

const Filters = ({ filters, setFilters, roles, users, cloudAccountsLinkedAccount }) => {
  const { usersStore } = useRootStore();
  const accounts = usersStore.getAllCurrDisplayedUserAccounts();
  const roleNames = useMemo(() => roles.map((role) => role.roleName).map((v) => ({ label: v, value: v })), [roles]);
  const accountNames = useMemo(
    () =>
      uniqBy(
        accounts.map((acc) => ({ label: acc.accountName, value: acc.accountId })),
        'value',
      ),
    [accounts],
  );
  const createdByUsers = useMemo(
    () =>
      uniqBy(
        roles.map((role) => role.createdBy).map((v) => ({ label: v, value: v })),
        'value',
      ),
    [roles],
  );
  const userNames = useMemo(() => users.map((user) => user.userName).map((v) => ({ label: v, value: v })), [users]);
  const linkedAccountNames = useMemo(
    () =>
      [...cloudAccountsLinkedAccount.values()]
        .flat()
        .map((ln) => ({ label: ln.linkedAccountName, value: ln.linkedAccountId })),
    [cloudAccountsLinkedAccount],
  );
  return (
    <div className={styles.container}>
      <div>
        <SelectMulti
          label="Role Name"
          options={roleNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, roleName: vals });
          }}
          selected={filters.roleName}
        />
      </div>
      <div>
        <SelectMulti
          label="User Name"
          minWidth={250}
          options={userNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, userName: vals });
          }}
          selected={filters.userName}
        />
      </div>
      <div>
        <SelectMulti
          label="Account Name"
          options={accountNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, accountName: vals });
          }}
          selected={filters.accountName}
        />
      </div>
      <div>
        <SelectMulti
          label="Linked Account"
          minWidth={250}
          options={linkedAccountNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, linkedAccount: vals });
          }}
          selected={filters.linkedAccount}
        />
      </div>
      <div>
        <SelectMulti
          label="Created by"
          options={createdByUsers}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, createdBy: vals });
          }}
          selected={filters.createdBy}
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  roles: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  cloudAccountsLinkedAccount: PropTypes.object.isRequired,
};

export default withLinkedAccountsContextConsumer(withRolesContextConsumer(Filters));
