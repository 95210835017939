import React, { useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import toast from 'shared/components/andtComponents/Toast';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { USER_ACTIONS } from '../../consts';

const DeleteUsersModal = ({ isOpen, setIsOpen, users, onSuccess }) => {
  const [saveClicked] = useState(false);

  const { deleteUsers } = useUsers();

  const onDelete = async () => {
    try {
      await deleteUsers.mutateAsync({ usersIds: users.map((user) => user.id) });
      setIsOpen(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while deleting user');
      }
    }
  };

  return (
    <CustomModal
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.SIMPLE}
      footerDisabled={saveClicked}
      onSave={onDelete}
      open={isOpen}
      automationId="delete-user-modal"
      saveTitle="Delete"
      saveWarning
      saveDisabled={false}
      title={USER_ACTIONS.DELETE.label}
    >
      <div className="d-flex flex-column gap-3">
        <div>You are about to delete {users.length > 1 ? 'the users' : 'this user'}. This action cannot be undone.</div>
        <div> Are you sure?</div>
      </div>
    </CustomModal>
  );
};

export default DeleteUsersModal;
