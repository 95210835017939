import React from 'react';
import PropTypes from 'prop-types';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { isDecimalNeeded, kIntFormmater } from 'shared/utils/strUtil';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';

const formatBytes = (bytes, decimals = 0) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let i = 0;
  let tmp = bytes;

  for (i; tmp > 1024; i++) {
    tmp /= 1024;
  }
  return `${parseFloat(tmp.toFixed(decimals))}${units[i]}`;
};

export const Y_AXIS_UNIT = {
  PERCENT: 'percent',
  BYTE: 'byte',
  OTHER: 'other',
};

const RdsChangeChart = ({ data, yLabel, isEstimated, isPercentile99, yAxisUnit }) => {
  const formatYValue = (value) => {
    if (yAxisUnit === Y_AXIS_UNIT.PERCENT) {
      return `${kIntFormmater(value, 2, false)}%`;
    }
    if (yAxisUnit === Y_AXIS_UNIT.BYTE) {
      return formatBytes(value);
    }
    return value;
  };

  const legendStyle = {
    bottom: '-11px',
  };

  const getTooltipFormat = (value) => {
    if (!value) {
      return '-';
    }
    let tooltip = '';
    switch (yAxisUnit) {
      case Y_AXIS_UNIT.PERCENT:
        tooltip = `${value.toFixed(isDecimalNeeded(value, 2) ? 2 : 0)} %`;
        break;
      case Y_AXIS_UNIT.BYTE:
        tooltip = formatBytes(value);
        break;
      case Y_AXIS_UNIT.OTHER:
      default:
        tooltip = value.toFixed(isDecimalNeeded(value, 2) ? 2 : 0);
        break;
    }
    return tooltip;
  };

  return (
    <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
      <LineChart width={550} height={284} data={data} margin={{ top: 20, right: 20, bottom: 0, left: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="usageDate" tick={<CustomizedAxisTick fontSize={12} />} />
        <YAxis
          type="number"
          // domain={[0, maxYAxis]}
          label={{
            value: yLabel,
            offset: 10,
            angle: -90,
            position: 'left',
          }}
          tick={{ style: { fontSize: '11px' } }}
          tickFormatter={(value) => formatYValue(value)}
        />
        <Tooltip formatter={(value) => getTooltipFormat(value)} />

        <Line type="linear" dataKey="maxData" dot={false} stroke="#C83CEE" strokeWidth={2} name={`Max ${yLabel}`} />
        <Line type="linear" dataKey="avgData" dot={false} stroke="#FDDD6C" strokeWidth={2} name={`Average ${yLabel}`} />
        <Legend wrapperStyle={legendStyle} />
        {isEstimated ? (
          <Line
            type="linear"
            dataKey="estimatedData"
            dot={false}
            stroke="#00B7F1"
            strokeWidth={2}
            name={`Estimated ${yLabel}`}
          />
        ) : null}
        <Line
          type="linear"
          dataKey="percentileData"
          dot={false}
          stroke="#F06292"
          strokeWidth={2}
          name={`95Percentile ${yLabel}`}
        />
        {isPercentile99 ? (
          <Line
            type="linear"
            dataKey="percentile99Data"
            dot={false}
            stroke="#F08362"
            strokeWidth={2}
            name={`99Percentile ${yLabel}`}
          />
        ) : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

RdsChangeChart.propTypes = {
  data: PropTypes.object.isRequired,
  isEstimated: PropTypes.bool,
  isPercentile99: PropTypes.bool,
  yLabel: PropTypes.string.isRequired,
  yAxisUnit: PropTypes.string,
};

RdsChangeChart.defaultProps = {
  yAxisUnit: Y_AXIS_UNIT.OTHER,
  isEstimated: false,
  isPercentile99: false,
};

export default RdsChangeChart;
