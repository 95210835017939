import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RdsIopsChangeRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class RdsIopsChangeRecommendationBuilder extends CostRecommendationBuilder {
  // eslint-disable-next-line no-useless-constructor
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RdsIopsChangeRecommendation();
  }

  // eslint-disable-next-line class-methods-use-this
  getRecommType() {
    return RecommendationTypes.RDS_IOPS_CHANGE;
  }

  // eslint-disable-next-line class-methods-use-this
  buildRecommTitle() {}

  build() {
    super.build();
    const {
      allocated_storage: allocatedStorage,
      command,
      command_comment: commandComment,
      cpu,
      cpu_max: cpuMax,
      cpu_max_data: cpuMaxData,
      cpu_avg_data: cpuAvgData,
      cpu_p95_data: cpuPercentile95Data,
      cpu_p99_data: cpuPercentile99Data,
      current_annual_cost: currentAnnualCost,
      days_to_check: daysToCheck,
      engine,
      engine_version: engineVersion,
      instance_family: instanceFamily,
      instance_type: instanceType,
      instance_type_family: instanceTypeFamily,
      iops_max_data: iopsMaxData,
      iops_avg_data: iopsAvgData,
      iops_p95_data: iopsPercentileData,
      is_multi_az: isMultiAz,
      max_allocated_storage: maxAllocatedStorage,
      max_iops: maxIops,
      mem_utilization_max_data: memUtilizationMaxData,
      mem_utilization_avg_data: memUtilizationAvgData,
      mem_utilization_p95_data: memUtilizationPercentileData,
      memory,
      provisioned_iops: provisionedIops,
      recommended_annual_cost: recommendedAnnualCost,
      recommended_monthly_cost: recommendedMonthlyCost,
      recommended_iops: recommendedIops,
      resource_arn: resourceArn,
      resource_id: resourceId,
      service,
      storage_type: storageType,
      zone_tag: region,
    } = this._rawRecommendation;

    this._recommendation.region = region;
    this._recommendation.storageType = storageType;
    this._recommendation.allocatedStorage = allocatedStorage;
    this._recommendation.storage = `${storageType} allocated ${allocatedStorage}`;
    this._recommendation.command = command;
    this._recommendation.commandComment = commandComment;
    this._recommendation.cpu = cpu;
    this._recommendation.cpuMax = cpuMax;
    this._recommendation.cpuMaxData = cpuMaxData;
    this._recommendation.cpuAvgData = cpuAvgData;
    this._recommendation.cpuPercentile95Data = cpuPercentile95Data;
    this._recommendation.cpuPercentile99Data = cpuPercentile99Data;
    this._recommendation.currentAnnualCost = currentAnnualCost;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.engine = engine;
    this._recommendation.engineVersion = engineVersion;
    this._recommendation.instanceFamily = instanceFamily;
    this._recommendation.instanceType = instanceType;
    this._recommendation.instanceTypeFamily = instanceTypeFamily;
    this._recommendation.iopsMaxData = iopsMaxData;
    this._recommendation.iopsAvgData = iopsAvgData;
    this._recommendation.iopsPercentileData = iopsPercentileData;
    this._recommendation.isMultiAz = isMultiAz;
    this._recommendation.maxAllocatedStorage = maxAllocatedStorage;
    this._recommendation.maxIops = maxIops;
    this._recommendation.memUtilizationMaxData = memUtilizationMaxData;
    this._recommendation.memUtilizationAvgData = memUtilizationAvgData;
    this._recommendation.memUtilizationPercentileData = memUtilizationPercentileData;
    this._recommendation.memory = memory;
    this._recommendation.provisionedIops = provisionedIops;
    this._recommendation.recommendedAnnualCost = recommendedAnnualCost;
    this._recommendation.recommendedMonthlyCost = recommendedMonthlyCost;
    this._recommendation.recommendedIops = recommendedIops;
    this._recommendation.resourceArn = resourceArn;
    this._recommendation.resourceId = resourceId;
    this._recommendation.service = service;
  }
}
