import { useQuery, useMutation, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { getAnomaliesQueryParams } from 'usage/containers/AnomalyDetection/anomalyDetectionHelpers';
import { getEmailFromRecipients, prepareNewRecipients } from 'shared/utils/sharedUtils';

const anomaliesRoot = '/api/v1/anomaly-detection';
const alertRulesURL = `${anomaliesRoot}/rules`;
const alertRuleURL = `${anomaliesRoot}/rule`;
const fetchAnomalies = (queryParams, isPpApplied, isFull = false, isPageCount = false) =>
  API.get(
    'billings',
    `${anomaliesRoot}?${isFull ? 'isFull=true&' : ''}${queryParams}&isPpApplied=${isPpApplied}${
      isPageCount ? '&isPageCount=true' : ''
    }`,
  );

export function anomalyDetectionProvider(filterObject, isPpApplied, isPageCount = false) {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.ANOMALY_DETECTION, { ...filterObject, isPpApplied, isPageCount }];
  const queryParams = getAnomaliesQueryParams(filterObject);

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchAllAnomalies: () =>
      queryClient.fetchQuery(queryKey, () => fetchAnomalies(queryParams, isPpApplied, true, false), {
        retry: false,
      }),
    fetchAnomalies: ({ onSuccess }) =>
      useQuery(queryKey, () => fetchAnomalies(queryParams, isPpApplied, false, false), {
        onSuccess,
        retry: false,
      }),
    fetchAnomaliesCount: ({ onSuccess }) =>
      useQuery(queryKey, () => fetchAnomalies(queryParams, isPpApplied, false, true), {
        onSuccess,
        retry: false,
      }),
  };
}

const fetchAlertRules = async () => {
  const alertRules = await API.get('billings', alertRulesURL);
  return alertRules.map((rule) => ({ ...rule, email: getEmailFromRecipients(rule.recipients) }));
};

export function anomaliesAlertRulesProvider(isPpApplied) {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.ANOMALIES_ALERT_RULES, { isPpApplied }];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchAlertRules: () => useQuery(queryKey, () => fetchAlertRules(isPpApplied)),
    createAlert: ({ onSuccess }) =>
      useMutation({
        mutationFn: (alertRule) =>
          API.post('billings', alertRuleURL, {
            body: {
              alertRule: {
                ...alertRule,
                recipients: prepareNewRecipients({ email: alertRule.email, recipients: alertRule.recipients }),
              },
            },
          }),
        onSuccess,
      }),
    updateAlert: ({ onSuccess }) =>
      useMutation({
        mutationFn: (alertRule) =>
          API.put('billings', alertRuleURL, {
            body: {
              alertRule: {
                ...alertRule,
                recipients: prepareNewRecipients({ email: alertRule.email, recipients: alertRule.recipients }),
              },
            },
          }),
        onSuccess,
      }),
    deleteAlert: ({ onSuccess = () => {} } = {}) =>
      useMutation({
        mutationFn: (alertRuleId) => API.del('billings', `${alertRuleURL}/${alertRuleId}`),
        onSuccess,
      }),
  };
}

export function anomalyCommentsProvider(uuid) {
  const queryClient = useQueryClient();
  const url = `${anomaliesRoot}/${uuid}/comment`;
  return {
    createComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post('billings', url, { body: payload }),
        onMutate: (payload) => {
          // Optimistic update
          // get all queries with key ANOMALY_DETECTION and update the comments array
          const queryCache = queryClient.getQueryCache().findAll(apiConstants.QUERY_KEYS.ANOMALY_DETECTION);
          queryCache.forEach((query) => {
            queryClient.setQueryData(query.queryKey, (oldData) => ({
              ...oldData,
              anomalies: oldData.anomalies?.map((anomaly) => {
                if (anomaly.uuid === uuid) {
                  // Add the comment to the comments array
                  return {
                    ...anomaly,
                    comments: [payload, ...(anomaly.comments || [])],
                  };
                }
                return anomaly;
              }),
            }));
          });
        },
        onSuccess,
        onError,
      }),
    deleteComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.del('billings', url, { body: payload }),
        onMutate: (payload) => {
          // Optimistic update
          // get all queries with key ANOMALY_DETECTION and update the comments array
          const queryCache = queryClient.getQueryCache().findAll(apiConstants.QUERY_KEYS.ANOMALY_DETECTION);
          queryCache.forEach((query) => {
            queryClient.setQueryData(query.queryKey, (oldData) => ({
              ...oldData,
              anomalies: oldData.anomalies?.map((anomaly) => {
                if (anomaly.uuid === uuid) {
                  // Remove the comment from the comments array
                  return {
                    ...anomaly,
                    comments: (anomaly.comments || []).filter((comment) => comment.commentUuid !== payload.commentUuid),
                  };
                }
                return anomaly;
              }),
            }));
          });
        },
        onSuccess,
        onError,
      }),
    updateComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.put('billings', url, { body: payload }),
        onMutate: (payload) => {
          // Optimistic update
          // get all queries with key ANOMALY_DETECTION and update the comments array
          const queryCache = queryClient.getQueryCache().findAll(apiConstants.QUERY_KEYS.ANOMALY_DETECTION);
          queryCache.forEach((query) => {
            queryClient.setQueryData(query.queryKey, (oldData) => ({
              ...oldData,
              anomalies: oldData.anomalies?.map((anomaly) => {
                if (anomaly.uuid === uuid) {
                  // Update the comment in the comments array
                  return {
                    ...anomaly,
                    comments: (anomaly.comments || []).map((comment) =>
                      comment.commentUuid === payload.commentUuid ? payload : comment,
                    ),
                  };
                }
                return anomaly;
              }),
            }));
          });
        },
        onSuccess,
        onError,
      }),
  };
}

export function anomalyUserStatusProvider(uuid) {
  const queryClient = useQueryClient();
  const url = `${anomaliesRoot}/${uuid}/userStatus`;
  return {
    addUserStatus: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post('billings', url, { body: payload }),
        onMutate: (payload) => {
          // Optimistic update
          // get all queries with key ANOMALY_DETECTION and update the userStatus array
          const queryCache = queryClient.getQueryCache().findAll(apiConstants.QUERY_KEYS.ANOMALY_DETECTION);
          queryCache.forEach((query) => {
            queryClient.setQueryData(query.queryKey, (oldData) => ({
              ...oldData,
              anomalies: oldData.anomalies?.map((anomaly) => {
                if (anomaly.uuid === uuid) {
                  // update the user status
                  return {
                    ...anomaly,
                    userStatuses: [payload],
                  };
                }
                return anomaly;
              }),
            }));
          });
        },
        onSuccess,
        onError,
      }),
  };
}

export function anomalyFeedbackProvider(uuid) {
  const url = `${anomaliesRoot}/${uuid}/feedback`;
  const queryClient = useQueryClient();
  return {
    addFeedback: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post('billings', url, { body: payload }),
        onMutate: (payload) => {
          // Optimistic update
          // get all queries with key ANOMALY_DETECTION and update the feedbacks array
          const queryCache = queryClient.getQueryCache().findAll(apiConstants.QUERY_KEYS.ANOMALY_DETECTION);
          queryCache.forEach((query) => {
            queryClient.setQueryData(query.queryKey, (oldData) => ({
              ...oldData,
              anomalies: oldData.anomalies?.map((anomaly) => {
                if (anomaly.uuid === uuid) {
                  // Add the feedback to the feedbacks array
                  return {
                    ...anomaly,
                    feedbacks: [
                      ...(anomaly.feedbacks || []).filter((feedback) => feedback.createdBy !== payload.createdBy),
                      payload,
                    ],
                  };
                }
                return anomaly;
              }),
            }));
          });
        },
        onSuccess,
        onError,
      }),
    deleteFeedback: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.del('billings', url, { body: payload }),
        onMutate: (payload) => {
          // Optimistic update
          // get all queries with key ANOMALY_DETECTION and update the feedbacks array
          const queryCache = queryClient.getQueryCache().findAll(apiConstants.QUERY_KEYS.ANOMALY_DETECTION);
          queryCache.forEach((query) => {
            queryClient.setQueryData(query.queryKey, (oldData) => ({
              ...oldData,
              anomalies: oldData.anomalies?.map((anomaly) => {
                if (anomaly.uuid === uuid) {
                  // Remove the feedback from the feedbacks array
                  return {
                    ...anomaly,
                    feedbacks: (anomaly.feedbacks || []).filter((feedback) => feedback.createdBy !== payload.createdBy),
                  };
                }
                return anomaly;
              }),
            }));
          });
        },
        onSuccess,
        onError,
      }),
  };
}
