import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const GCP_IP_IDLE_PROPERTIES = {
  PUBLIC_IP: {
    label: 'Public IP',
    getterFunction: (recommendation) => recommendation?.recData?.public_ip,
  },
};

const GcpIpIdle = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `gcloud compute instances delete ${recommendation?.recData?.public_ip} `,
        },
      ],
    },
  ];
  const description = 'This IP is not attached to any instance.';

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...GCP_IP_IDLE_PROPERTIES }}
    />
  );
};

GcpIpIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default GcpIpIdle;
