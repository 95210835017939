import React, { useState, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import ReportIcon from 'mdi-react/FileDocumentBoxIcon';
import ProcessingDataPanel from 'shared/components/ProcessingDataPanel';
import DateFilter from 'shared/modules/dateFilter';
import CustomDashboardWrapper from 'usage/containers/CustomDashboard/components/CustomDashboardWrapper';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import MainDbConstants from 'app/constants/dbConstants';
import { getMonthNameFromNumber } from 'shared/utils/dateUtil';
import PanelWithActionButton from 'shared/components/panels/PanelWithActionButton';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { REPORT_TYPES } from 'usage/constants/usageConstants';
import MonthlyYtdCostBarChart from 'shared/components/charts/SimpleBarChart';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import RegionActivityMap from 'shared/components/RegionActivityMap';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useMainDBData } from 'app/hooks/react-query/useMainDBData';
import UsageCost from './components/UsageCost';
import UsageCostToggle from './components/UsageCostToggle';
import CurrVsPreviousMonthCost from './components/CurrVsPreviousMonthCost';
import TopServicesCostChart from './components/TopServicesCostChart';
import YesterdayTopServicesCostChart from './components/YesterdayTopServicesCostChart';

const iconStyle = {
  color: '#727272',
  size: 16,
};
const actionButtonStyle = {
  display: 'inline-block',
  float: 'right',
};
const calcNumOfCostsComponentsWithData = (mainDashBoardData) => {
  let count = 1;
  if (mainDashBoardData) {
    count += mainDashBoardData.forecastedMonthEndTotalCost ? 1 : 0;
    count += mainDashBoardData.annualExpectedSavings ? 1 : 0;
    count += mainDashBoardData.prevMonthTotalCost ? 1 : 0;
  }

  return count === 0 ? 1 : 12 / count;
};
const DashboardPage = () => {
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType, currDispUserAccountKey } = usersStore;
  const { mainDbData, mainDbDataLoading } = useMainDBData(currDispUserCloudAccountType, currDispUserAccountKey);
  const [topServicesColumnWidth, setTopServicesColumnWidth] = useState(null);
  const [topServicesColumns, setTopServicesColumns] = useState(null);
  const [defaultDashboard, setDefaultDashboard] = useState(null);
  const { usageStore } = useRootStore();
  const { customDashboardModel } = usageStore.customDbSubStore;
  const { dashboards } = customDashboardModel;
  const { mainDashBoardData } = mainDbData || {};
  const currMonth = DateFilter.getCurrMonthAndYear().month;
  const currYear = DateFilter.getCurrMonthAndYear().year;
  const prevMonth = DateFilter.getPrevMonthAndYear().month;
  const prevYear = DateFilter.getPrevMonthAndYear().year;

  const isShowCurrVsPreviousMonthCost = mainDashBoardData?.preparedCostPerDay?.length > 0;
  const isShowYtdCostPerMonth = mainDashBoardData?.preparedCostPerMonth?.length > 0;
  const xlColSize = calcNumOfCostsComponentsWithData(mainDashBoardData);

  const colSize = 6;
  const setTopServiceColumns = () => {
    let topsServicesColumnWidthTemp = [];
    let topsServicesColumnTemp = [];
    if (mainDbData && mainDbData.mainDashBoardData) {
      // TODO - return forecast data once it's ready
      const isForecastedExist = false;
      const isChangeExist = mainDbData.mainDashBoardData?.preparedTopServices.some((row) => row.change);
      topsServicesColumnWidthTemp = [
        { columnName: 'serviceName', width: 'auto' },
        { columnName: 'prevMonthCost', width: 'auto' },
        { columnName: 'mtdCost', width: 'auto' },
        { columnName: 'forecasted', width: 'auto' },
        { columnName: 'change', width: 'auto' },
      ];
      topsServicesColumnTemp = [
        { name: 'serviceName', title: 'Service', getCellValue: (row) => row.name },
        { name: 'prevMonthCost', title: 'Prev Month Cost', getCellValue: (row) => row['Previous month'] },
        { name: 'mtdCost', title: 'MTD Cost', getCellValue: (row) => row['Current month'] },
      ];
      if (isForecastedExist) {
        topsServicesColumnTemp.push({
          name: 'forecasted',
          title: 'Forecasted',
          getCellValue: (row) => row.forecasted && +row.forecasted.toFixed(1),
        });
        topsServicesColumnTemp.push({ columnName: 'forecasted', width: 'auto' });
      }
      if (isChangeExist && isForecastedExist) {
        topsServicesColumnTemp.push({
          name: 'change',
          title: 'Change',
          getCellValue: (row) => row.change && `${row.change.toFixed()}%`,
        });
        topServicesColumnWidth.push({ columnName: 'change', width: 'auto' });
      }
      setTopServicesColumnWidth(topsServicesColumnWidthTemp);
      setTopServicesColumns(topsServicesColumnTemp);
    }
  };
  const handleGoToCostExplorerReport = async (id) => {
    await usageStore.fetchReportDataOnNavigationToCostExplorer(id, mainDbData.mainDashBoardData);
  };

  useEffect(() => {
    if (!topServicesColumnWidth || !topServicesColumns) {
      setTopServiceColumns();
    }
  }, [mainDbData?.mainDashBoardData]);

  useEffect(() => {
    const dashboard = dashboards.find((d) => d.isDefault);
    setDefaultDashboard(dashboard);
  }, [dashboards]);

  if (
    mainDbDataLoading ||
    (mainDbData.mainDashboardData &&
      mainDbData.mainDashboardData?.isCustomDefaultDbSet &&
      customDashboardModel.modelIsLoading)
  ) {
    return <Spinner />;
  }

  return (
    <>
      {defaultDashboard ? (
        <CustomDashboardWrapper id={defaultDashboard.uuid} />
      ) : (
        <div>
          {!mainDashBoardData ? (
            <Container className="dashboard">
              <PageHeader title={PageNames.DASHBOARD} showDate />
              <Row>
                {' '}
                <ProcessingDataPanel />
              </Row>
            </Container>
          ) : (
            <Container className="dashboard">
              <PageHeader title={PageNames.DASHBOARD} showDate className="scale" />
              <Row className="scale">
                <UsageCost
                  id="1"
                  title={MainDbConstants.TITLE_USG_COST_MTD}
                  value={mainDashBoardData?.monthToDateCosts}
                  percent={mainDashBoardData?.monthToDatePercentages}
                  percentArrowIsUp={mainDashBoardData.monthToDatePercentages > 0}
                  showIcon
                  infoValue={MainDbConstants.TOOLTIP_USG_COST}
                  iconStyle={iconStyle}
                  showPercent
                  xlColSize={xlColSize}
                  colSize={colSize}
                />
                {mainDashBoardData.forecastedMonthEndTotalCost ? (
                  <UsageCost
                    id="2"
                    title={MainDbConstants.TITLE_USG_COST_FORECASTED}
                    value={mainDashBoardData.forecastedMonthEndTotalCost}
                    percent={mainDashBoardData.forecastedMonthEndPercentages}
                    percentArrowIsUp={mainDashBoardData.forecastedMonthEndPercentages > 0}
                    showIcon
                    infoValue={MainDbConstants.TOOLTIP_FORCASTED}
                    iconStyle={iconStyle}
                    showPercent
                    xlColSize={xlColSize}
                    colSize={colSize}
                  />
                ) : (
                  <></>
                )}
                {mainDashBoardData.annualExpectedSavings !== null &&
                mainDashBoardData.annualExpectedSavings !== undefined &&
                mainDashBoardData.annualExpectedSavings !== 0 ? (
                  <UsageCostToggle
                    id="3"
                    title={MainDbConstants.TITLE_USG_COST_TOGGLE}
                    potSavings={mainDashBoardData.annualExpectedSavings}
                    percent={mainDashBoardData.expectedSavingsPercent}
                    infoValue={MainDbConstants.TOOLTIP_USG_COST_TOGGLE}
                    iconStyle={iconStyle}
                    xlColSize={xlColSize}
                    colSize={colSize}
                  />
                ) : (
                  <></>
                )}
                {mainDashBoardData.prevMonthTotalCost !== null &&
                mainDashBoardData.prevMonthTotalCost !== undefined &&
                mainDashBoardData.prevMonthTotalCost !== 0 ? (
                  <UsageCost
                    id="4"
                    title={MainDbConstants.TITLE_USG_COST_PREV}
                    value={mainDashBoardData.prevMonthTotalCost}
                    infoValue={MainDbConstants.TOOLTIP_USG_COST_PREV}
                    iconStyle={iconStyle}
                    xlColSize={xlColSize}
                    colSize={colSize}
                  />
                ) : (
                  <></>
                )}
              </Row>
              <Row className="scale">
                {isShowCurrVsPreviousMonthCost && (
                  <CurrVsPreviousMonthCost
                    data={mainDashBoardData.preparedCostPerDay}
                    height={350}
                    title={`${getMonthNameFromNumber(currMonth)} ${
                      currMonth === 1 ? currYear : ''
                    } vs ${getMonthNameFromNumber(prevMonth)} ${prevYear}`}
                    xLabel={MainDbConstants.CURR_VS_PREV_X_LABEL}
                    yLabel={MainDbConstants.CURR_VS_PREV_Y_LABEL}
                    handleGoToCostExplorerReport={handleGoToCostExplorerReport}
                    actionButtonStyle={actionButtonStyle}
                    xl={isShowYtdCostPerMonth ? 6 : 12}
                    lg={isShowYtdCostPerMonth ? 6 : 12}
                    md={12}
                    sm={12}
                    xs={12}
                  />
                )}
                {isShowYtdCostPerMonth && (
                  <PanelWithActionButton
                    divider
                    link
                    title="Cost History (Month over Month) "
                    panelClass="panel--narrow"
                    ActionButtonComp={LinkIconButton}
                    ButtonIcon={ReportIcon}
                    handler={handleGoToCostExplorerReport}
                    id={REPORT_TYPES.MONTHLY_COST_YTD}
                    actionButtonStyle={actionButtonStyle}
                    xl={isShowCurrVsPreviousMonthCost ? 6 : 12}
                    lg={isShowCurrVsPreviousMonthCost ? 6 : 12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <MonthlyYtdCostBarChart
                      data={mainDashBoardData.preparedCostPerMonth}
                      displayedMetric={DisplayMetricTypes.COST}
                      height={350}
                      dataKey="usageDate"
                      yLabel="Cost"
                    />
                  </PanelWithActionButton>
                  // </Row>
                )}
              </Row>
              <Row className="scale">
                {topServicesColumns && topServicesColumnWidth ? (
                  <TopServicesCostChart
                    data={mainDashBoardData.preparedTopServices}
                    handleGoToCostExplorerReport={handleGoToCostExplorerReport}
                    actionButtonStyle={actionButtonStyle}
                    changeColumnWidths={setTopServicesColumnWidth}
                    columnWidths={topServicesColumnWidth}
                    columns={topServicesColumns}
                  />
                ) : null}
                <YesterdayTopServicesCostChart
                  data={mainDashBoardData.yesterdayTopServices}
                  handleGoToCostExplorerReport={handleGoToCostExplorerReport}
                  actionButtonStyle={actionButtonStyle}
                />
              </Row>
              <Row>
                {mainDashBoardData.preparedCostPerRegion?.length > 1 && (
                  <RegionActivityMap
                    title={MainDbConstants.REGION_ACTIVITY_MAP_TITLE}
                    data={mainDashBoardData.preparedCostPerRegion}
                    handleGoToCostExplorerReport={handleGoToCostExplorerReport}
                    actionButtonStyle={actionButtonStyle}
                    xl={12}
                  />
                )}
              </Row>
            </Container>
          )}
        </div>
      )}
    </>
  );
};
export default DashboardPage;
