/* eslint-disable max-len */
import React from 'react';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = () => (
  // Can get rec in props object if will be needed in the future
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the AWS CLI:</li>
      <div>Delete a trail</div>
      <span>
        <b>
          Notice: This operation must be called from the region in which the trail was created. DeleteTrail cannot be
          called on the shadow trails (replicated trails in other regions) of a trail that is enabled in all regions.
        </b>
      </span>
      <CodeBlock text="aws cloudtrail delete-trail --TRAIL_NAME Trail1" />
      <br />
    </ul>
  </ActionContainer>
);

export default Action;
