import React from 'react';
import { withRouter } from 'react-router-dom';
import CustomModal from 'shared/components/andtComponents/Modal';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as NoteImage } from './img/note.svg';

import styles from './noteModal.module.scss';

const NoteModal = ({ title, message, btnText, close }) => (
    <CustomModal
        open
        onClose={() => {}}
        overrideStyles={{
            width: 560,
        }}
        headerIcon={<NoteImage height={45} width={45} />}
        headerMode="add"
        closeOnOutside={false}
        title={title || 'Please note'}
        footerDisabled
    >
        <div className={styles.modalWrapper}>
            <p className={styles.msg}>
                {message}
            </p>
            <div className={styles.buttonContainer}>
                <Button text={btnText} onClick={close} />
            </div>
        </div>
    </CustomModal>
);

export default withRouter(NoteModal);
