import { AwsCommonFields } from 'shared/constants/awsConstants';
import { k8sGranLevel } from 'shared/constants/k8sConstants';
import { mapDimensionsToDisplay } from 'usage/constants/costAndUsageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants';

export const getDefaultFiltersConfig = (field, passedProps, props) => {
  const {
    getAccountTagsKeys,
    getK8sCustomTagsKeys,
    getCustomTagsKeys,
    getWorkloadTypes,
    customTagsFetcher,
    accountTagsFetcher,
    k8sLabelsFetcher,
    resourcesFetcher,
    usageTypesFetcher,
    namespacesFetcher,
    workloadsFetcher,
    podsFetcher,
    viewpointMappingsFetcher,
    getK8SBusinessMappingViewpoints,
    getBusinessMappingViewpoints,
  } = props;
  const { isK8S, isK8SAndCUE, k8SGranularity } = passedProps || {};
  return {
    [AwsCommonFields.POD]: {
      onLoad: podsFetcher.onLoad,
      fetchData: podsFetcher.fetchData,
      values: podsFetcher.values,
      isLoading: podsFetcher.isLoading,
      isAsyncLoadClick: true,
    },
    [AwsCommonFields.WORKLOAD]: {
      onLoad: workloadsFetcher.onLoad,
      fetchData: workloadsFetcher.fetchData,
      isLoading: workloadsFetcher.isLoading,
      isAutoComplete: true,
      subMenu: true,
      keys: getWorkloadTypes(),
      values: workloadsFetcher.values,
    },
    [AwsCommonFields.CUSTOM_TAGS]: {
      onLoad: customTagsFetcher.onLoad,
      fetchData: customTagsFetcher.fetchData,
      isLoading: customTagsFetcher.isLoading,
      isAutoComplete: true,
      showNotAllocated: true,
      subMenu: true,
      keys: getCustomTagsKeys(),
      values: customTagsFetcher.values,
    },
    [AwsCommonFields.BUSINESS_MAPPING]: {
      onLoad: (...params) => viewpointMappingsFetcher.onLoad(...params),
      fetchData: (...params) => viewpointMappingsFetcher.fetchData(...params),
      isLoading: viewpointMappingsFetcher.isLoading,
      values: viewpointMappingsFetcher.values,
      isAutoComplete: true,
      subMenu: true,
      keys: [
        ...(isK8S || isK8SAndCUE ? getK8SBusinessMappingViewpoints(k8SGranularity === k8sGranLevel.PODS) : []),
        ...(!isK8S || isK8SAndCUE ? getBusinessMappingViewpoints() : []),
      ].map((v) => v.id),
    },
    [AwsCommonFields.ACCOUNT_TAGS]: {
      onLoad: accountTagsFetcher.onLoad,
      fetchData: accountTagsFetcher.fetchData,
      isLoading: accountTagsFetcher.isLoading,
      values: accountTagsFetcher.values,
      isAutoComplete: true,
      subMenu: true,
      keys: getAccountTagsKeys(),
    },
    [AwsCommonFields.K8S_LABEL_TAGS]: {
      onLoad: k8sLabelsFetcher.onLoad,
      fetchData: k8sLabelsFetcher.fetchData,
      isLoading: k8sLabelsFetcher.isLoading,
      isAutoComplete: true,
      subMenu: true,
      values: k8sLabelsFetcher.values,
      keys: getK8sCustomTagsKeys(),
    },
    [AwsCommonFields.RESOURCE]: {
      onLoad: resourcesFetcher.onLoad,
      fetchData: resourcesFetcher.fetchData,
      isLoading: resourcesFetcher.isLoading,
      values: resourcesFetcher.values,
      isAutoComplete: true,
    },
    [AwsCommonFields.USAGE_TYPE]: {
      onLoad: usageTypesFetcher.onLoad,
      fetchData: usageTypesFetcher.fetchData,
      values: usageTypesFetcher.values,
      isLoading: usageTypesFetcher.isLoading,
      isAsyncLoad: true,
    },
    [AwsCommonFields.NAMESPACE]: {
      onLoad: namespacesFetcher.onLoad,
      fetchData: namespacesFetcher.fetchData,
      values: namespacesFetcher.values,
      isLoading: namespacesFetcher.isLoading,
      isAsyncLoad: true,
    },
    [AwsCommonFields.K8S_NAMESPACE]: {
      onLoad: namespacesFetcher.onLoad,
      fetchData: namespacesFetcher.fetchData,
      values: namespacesFetcher.values,
      isLoading: namespacesFetcher.isLoading,
      isAsyncLoad: true,
    },
    [AwsCommonFields.CUE_K8S_LABEL_TAGS]: {
      onLoad: k8sLabelsFetcher.onLoad,
      fetchData: k8sLabelsFetcher.fetchData,
      isLoading: k8sLabelsFetcher.isLoading,
      isAutoComplete: true,
      subMenu: true,
      values: k8sLabelsFetcher.values,
      keys: getK8sCustomTagsKeys(),
    },
  }[field];
};

export const createSelectedDimensionsMap = (selectedDimensions) => {
  const selectedMap = new Map([]);
  const selectedArr = selectedDimensions
    ? selectedDimensions.map((selected) => ({
        value: selected,
        label: mapDimensionsToDisplay.get(selected),
      }))
    : [];
  selectedMap.set('Measure', selectedArr);
  return selectedMap;
};

export const getLabel = (field, currDispUserCloudAccountType) => {
  if (currDispUserCloudAccountType !== CLOUD_TYPE_IDS.GCP) {
    return LabelCoordinator.getFieldLabel(field);
  }
  const label = GCPCommonFieldLabels.get(field);
  if (!label) {
    return LabelCoordinator.getFieldLabel(field);
  }
  return label;
};
