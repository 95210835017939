import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DateFilter from 'shared/modules/dateFilter';
import {
  S3_COLUMN_WIDTHS,
  S3_CURRENCIES_COLUMNS,
  S3_DEFAULT_HIDDEN_COLUMN_NAMES,
  S3_DEFAULT_SORTING,
  S3_TABLE_COLUMN_EXTENSIONS,
  S3_TABLE_COLUMNS,
  S3_TABLE_CSV_COLUMNS,
} from 'usage/constants/usageConstants';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import MarginAppliedReloader from 'shared/components/MarginAppliedReloader';
import DevExpSearchAndVisibilityTable from './components/S3DevExpTable';
import prepareTableData from './helpers/prepareTableData';
import { useS3Usage } from '../../hooks/react-query/useS3Usage';

const S3UsagePage = ({ appStore }) => {
  const [startD, setStartDate] = useState(DateFilter.getFirstDayOfCurrMonthDate());
  const [endD, setEndDate] = useState(DateFilter.date);
  const { startDate, endDate } = buildStartAndEndDate(startD, endD);
  const { getS3BucketCosts } = useS3Usage();
  const { data: s3Data, isLoading: dataIsLoading } = getS3BucketCosts(startDate, endDate);

  const datePickerSyntexDates = (start, end) => {
    let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  const handleDateChange = async ({ startDate, endDate }) => {
    const start = startDate || startD;
    let end = endDate || endD;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    setStartDate(modStartDate);
    setEndDate(modEndDate);
  };
  const updateColumns = (data) => {
    const COL_LINKED_ACC_NAME = {
      name: 'linkedAccountName',
      title: 'Linked Account Name',
      getCellValue: (row) => row.linkedAccountName,
    };
    const isColumnNameInData =
      data && data.length && !S3_TABLE_COLUMNS.find((col) => col.name === 'linkedAccountName')
        ? data.some((item) => item.linkedAccountName)
        : false;
    if (isColumnNameInData) {
      S3_TABLE_COLUMNS.unshift(COL_LINKED_ACC_NAME);
    }
  };

  const preparedData = prepareTableData(s3Data);
  updateColumns(preparedData);
  return (
    <Container>
      <PageHeader title={PageNames.S3_USAGE} showDate />
      <Card>
        <CardBody style={{ minHeight: '300px' }}>
          <Row style={{ marginLeft: '1px' }}>
            <DatePickerFilter
              onDateChange={handleDateChange}
              startDate={datePickerSyntexDates(startD, null).startDate}
              endDate={datePickerSyntexDates(null, endD).endDate}
              currPeriodGranLevel="month"
              yearLimit
            />
          </Row>
          {dataIsLoading ? (
            <Spinner />
          ) : (
            <Row>
              <DevExpSearchAndVisibilityTable
                data={preparedData}
                columns={S3_TABLE_COLUMNS}
                columnWidths={S3_COLUMN_WIDTHS}
                tableColumnExtensions={S3_TABLE_COLUMN_EXTENSIONS}
                defaultHiddenColumnNames={S3_DEFAULT_HIDDEN_COLUMN_NAMES}
                defaultSorting={S3_DEFAULT_SORTING}
                currenciesColumns={S3_CURRENCIES_COLUMNS}
                csvColumns={S3_TABLE_CSV_COLUMNS}
                csvTitle={`S3_data_${DateFilter.month}_${DateFilter.year}`}
              />
            </Row>
          )}
        </CardBody>
      </Card>
      <MarginAppliedReloader
        onChange={() => handleDateChange({ startDate: startD, endDate: endD })}
        isPpApplied={appStore.isPpApplied}
      />
    </Container>
  );
};
S3UsagePage.propTypes = {
  appStore: PropTypes.object.isRequired,
};
const ObserverS3UsagePage = observer(S3UsagePage);
export default ObserverS3UsagePage;
