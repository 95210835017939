/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

function CustomDashboardEditPanelRow(props) {
  const { children, classSufix } = props;
  const getColSize = (index) => {
    const sizeMultipByIndex = {
      0: 5,
      1: 5,
      3: 2,
    };
    const colSizes = {
      xs: sizeMultipByIndex[index],
      sm: sizeMultipByIndex[index],
      md: sizeMultipByIndex[index],
      lg: sizeMultipByIndex[index],
      xl: sizeMultipByIndex[index],
    };
    return colSizes;
  };
  return (
    <div style={{ padding: '0px 0px 5px', width: '100%' }}>
      <div className={`custom-dashboard-panel-row-edit-wrapper ${props.className}`}>
        {children.map((child, index) => (
          <Col
            key={`child-${index}`}
            className={`custom-dashboard-panel-row-edit-col-${classSufix}`}
            {...getColSize(index)}
          >
            {child}
          </Col>
        ))}
      </div>
    </div>
  );
}

CustomDashboardEditPanelRow.propTypes = {
  children: PropTypes.object.isRequired,
  classSufix: PropTypes.string.isRequired,
  className: PropTypes.string,
};
CustomDashboardEditPanelRow.defaultProps = {
  className: '',
};

export default CustomDashboardEditPanelRow;
