/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { GcpVmRightSizingRecommendation } from 'recommendations/models/recommTypes';
import GcpCostRecommendationBuilder from 'recommendations/models/builders/gcpCostRecommBuilder';

export default class GcpVmRightSizingCostRecommendationBuilder extends GcpCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new GcpVmRightSizingRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.GCP_VM_RIGHT_SIZING;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} VM Right Sizing`;
  }

  build() {
    super.build();
    const {
      resource_id: resourceId,
      project: projectName,
      zone_tag: tagZone,
      resource_name: resourceName,
      num_of_days: numOfDays,
      starting_time: startingTime,
      max_cpu_util: maxCpuUtil,
      cpu_util_statistics_usage: cpuUtilStatisticsUsage,
      max_network: maxNetwork,
      network_in_statistics_usage: networkInStatisticsUsage,
      network_out_statistics_usage: networkOutStatisticsUsage,
      potential_savings: potentialSavings,
      instance_type: instanceType,
      recommended_instance_type: recommendedInstanceType,
      cpu_util: cpuUtil,
    } = this._rawRecommendation;
    this._recommendation.projectName = projectName;
    this._recommendation.maxCpuUtil = maxCpuUtil;
    this._recommendation.cpuUtilStatisticsUsage = cpuUtilStatisticsUsage;
    this._recommendation.maxNetwork = maxNetwork;
    this._recommendation.networkInStatisticsUsage = networkInStatisticsUsage;
    this._recommendation.networkOutStatisticsUsage = networkOutStatisticsUsage;
    this._recommendation.region = tagZone;
    this._recommendation.resourceName = resourceName;
    this._recommendation.numOfDays = numOfDays;
    this._recommendation.startingTime = startingTime;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.resourceId = resourceId;
    this._recommendation.instanceType = instanceType;
    this._recommendation.recommendedInstanceType = recommendedInstanceType;
    this._recommendation.cpuUtil = parseFloat(cpuUtil);
    // this._recommendation.size = size;
  }
}
