/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, UncontrolledTooltip } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
// import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import ExportChartTableToolbarButton from './ExportChartTableToolbarButton';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};
const ChartPanel = (props) => (
  <Col sm={12} md={12} lg={props.lg} xl={props.xl}>
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <h5 className="bold-text" style={{ 'text-align': 'left', marginRight: '10px' }}>
        {props.title}
      </h5>
      {props.isShowToolTip && (
        <div>
          <LinkIconButton containerStyle={buttonContainerStyle}>
            <a
              href="#"
              linkStyle={linkStyle}
              onClick={(e) => {
                e.preventDefault();
              }}
              id={props.id}
            />
            <InformationOutline size={18} />
          </LinkIconButton>
          <UncontrolledTooltip placement="right" target={props.id}>
            {props.infoText}
          </UncontrolledTooltip>
        </div>
      )}
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', padding: '0px 17px' }}>
        {/* <Row style={{ padding: '10px 32px', display: 'flex', justifyContent: 'flex-end', justifyItems: 'flex-end' }}> */}
        <ExportChartTableToolbarButton
          // chartId={fullChartComponentsId}
          tableModifiedDailyBalance={props.tableModifiedDailyBalance}
          csvTitle={props.csvTitle}
          // {...this.prepareExportTableToCsv(currDataState)}
        />
        {/* </Row> */}
      </div>
    </div>
    {props.children}
  </Col>
);

ChartPanel.propTypes = {
  children: PropTypes.object.isRequired,
  tableModifiedDailyBalance: PropTypes.object.isRequired,
  title: PropTypes.string,
  infoText: PropTypes.string,
  isShowToolTip: PropTypes.bool,
  id: PropTypes.string,
  lg: PropTypes.number,
  xl: PropTypes.number,
  csvTitle: PropTypes.string.isRequired,
};

ChartPanel.defaultProps = {
  title: '',
  id: 'SpotNotIncluded',
  infoText: 'Spot data not included',
  isShowToolTip: false,
  lg: 6,
  xl: 6,
};
export default ChartPanel;
