/* eslint-disable no-mixed-operators */
export const calcPercent = (numerator, denominator) => 100 - (parseFloat(numerator) / parseFloat(denominator)) * 100;

export const calcNumOfMonthsToBreakEven = (recommendedCost, annualCostCurrent) => {
  const monthCost = annualCostCurrent / 12;
  const breakEvenMonth = Math.trunc(recommendedCost / monthCost) + 1;

  return breakEvenMonth;
};

export const calculateAverage = (obj) => {
  let values = Object.values(obj);
  values = values.map((v) => parseFloat(v));
  let sum = 0;
  values.forEach((num) => {
    sum += num;
  });
  return (sum / values.length).toFixed(2);
};
