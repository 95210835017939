/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import MultiTypeFieldFilterSelector from 'shared/components/MultiTypeFieldFilterSelector';
import CustomModal from 'shared/components/andtComponents/Modal';

const ProcessedLinkedAccountsModal = ({ toggle, isOpen, linkedAccounts, invoiceStore, handleSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [linkedAccs, setLinkedAccs] = useState(linkedAccounts);
  return (
    <CustomModal
      overrideStyles={{ width: 800 }}
      open={isOpen}
      onClose={toggle}
      title="Select linked accounts to inspect metrics"
      saveDisabled={saveLoading}
      closeOnSave={false}
      onSave={() => {
        setSaveLoading(true);
        handleSave(linkedAccs);
      }}
    >
      <Row className="mb-3">
        <Col>
          <MultiTypeFieldFilterSelector
            selectedValues={linkedAccs}
            handler={(_, values) => setLinkedAccs(values)}
            type="linkedaccid"
            name="linkedaccid"
            placeHolder="Select linked accounts"
            labelFunc={(value) => value.displayLabel}
            valueFunc={(value) => value.linkedAccountId}
          />
        </Col>
      </Row>
    </CustomModal>
  );
};

ProcessedLinkedAccountsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  linkedAccounts: PropTypes.array.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default ProcessedLinkedAccountsModal;
