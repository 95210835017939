import React from 'react';
import DescriptionWrapper from 'recommendations/shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>
      This S3 bucket is configured with versioning turned on and there are no lifecycle rules to accommodate version
      overload. <br />
      We recommend adding lifecycle rules to address older versions of files in this bucket.
    </p>
  </DescriptionWrapper>
);

export default Description;
