import React from 'react';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>This IP is not attached to any instance.</p>
  </DescriptionWrapper>
);

Description.propTypes = {};

export default Description;
