import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useBusinessMapping } from 'usage/containers/BusinessMapping/hooks/react-query/useBusinessMapping';
import { BUSINESS_MAPPING_GLOBAL_COLUMNS } from '../../constants/businessMappingConstants';
import BusinessMappingTable from './BusinessMappingTable';

const BusinessMappingGlobalTable = ({ usageStore, invoiceStore, usersStore }) => {
  const [editingBusinessMappingGroup, setEditingBusinessMappingGroup] = useState(null);
  const [cloningBusinessMappingGroup, setCloningBusinessMappingGroup] = useState(null);
  const [deletingBusinessMappingGroup, setDeletingBusinessMappingGroup] = useState(null);
  const [createBusinessMappingGroupModalOpen, setCreateBusinessMappingGroupModalOpen] = useState(false);
  const {
    deleteGlobalBusinessMappingGroup,
    updateGlobalBusinessMappingGroup,
    addGlobalBusinessMappingGroup,
    fetchGlobalBusinessMappingGroups,
    invalidateBusinessMappingGlobalGroups,
  } = useBusinessMapping();
  const { data: groups, isLoading: isGroupsLoading, isFetching: isGroupsFetching } = fetchGlobalBusinessMappingGroups();
  const invalidateQuery = () => {
    invalidateBusinessMappingGlobalGroups();
  };
  const { mutateAsync: editGroup } = updateGlobalBusinessMappingGroup({
    onSuccess: () => {
      invalidateQuery();
      setEditingBusinessMappingGroup(null);
    },
  });

  const { mutateAsync: deleteGroup } = deleteGlobalBusinessMappingGroup({
    onSuccess: () => {
      invalidateQuery();
      setDeletingBusinessMappingGroup(null);
    },
  });

  const { mutateAsync: addGroup } = addGlobalBusinessMappingGroup({
    onSuccess: () => {
      invalidateQuery();
      setCreateBusinessMappingGroupModalOpen(null);
      setCloningBusinessMappingGroup(null);
    },
  });

  return (
    <BusinessMappingTable
      usageStore={usageStore}
      invoiceStore={invoiceStore}
      usersStore={usersStore}
      groups={groups}
      columns={BUSINESS_MAPPING_GLOBAL_COLUMNS}
      isGlobal
      isGroupsFetching={isGroupsFetching}
      isGroupsLoading={isGroupsLoading}
      setCreateBusinessMappingGroupModalOpen={setCreateBusinessMappingGroupModalOpen}
      createBusinessMappingGroupModalOpen={createBusinessMappingGroupModalOpen}
      setDeletingBusinessMappingGroup={setDeletingBusinessMappingGroup}
      deletingBusinessMappingGroup={deletingBusinessMappingGroup}
      setCloningBusinessMappingGroup={setCloningBusinessMappingGroup}
      cloningBusinessMappingGroup={cloningBusinessMappingGroup}
      setEditingBusinessMappingGroup={setEditingBusinessMappingGroup}
      editingBusinessMappingGroup={editingBusinessMappingGroup}
      addGroup={addGroup}
      deleteGroup={deleteGroup}
      editGroup={editGroup}
    />
  );
};

BusinessMappingGlobalTable.propTypes = {
  usageStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};

export default observer(BusinessMappingGlobalTable);
