import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { FixedSizeList as List } from 'react-window';

import Spinner from 'shared/components/andtComponents/Spinner';
import { debounce } from 'lodash';
import { ReactComponent as CaseSensitiveIcon } from 'shared/img/icons/case-sensitive.svg';
import { ReactComponent as TriangleDown } from 'shared/img/icons/triangle-down.svg';
import { ReactComponent as EmptyState } from 'shared/img/icons/empty-state.svg';
import { ReactComponent as NoOptions } from 'shared/img/icons/no-options.svg';
import { ReactComponent as Check } from 'shared/img/icons/checked.svg';
import { ReactComponent as UnCheck } from 'shared/img/icons/unchecked.svg';
import Chip from 'shared/components/andtComponents/Chip';
import CustomTooltip from 'shared/components/andtComponents/Tooltip';
import { OPERATORS, OPERATORS_KEYS } from 'shared/constants/appConstants';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import classes from './FieldSidebarFilter.module.scss';
import Checkbox from '../andtComponents/Checkbox';

const customStyles = (hasBackKey) => ({
  option: (base) => ({
    ...base,
    width: '100%',
    height: '100%',
    borderRadius: 0,
    borderWidth: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    backgroundColor: 'unset',
    '&:active': {
      backgroundColor: 'unset',
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&:focus': {
      backgroundColor: 'unset',
    },
  }),
  container: (base) => ({
    ...base,
    width: '100%',
    margin: 0,
    backgroundColor: 'white',
    borderTopLeftRadius: hasBackKey ? 0 : 6,
    borderTopRightRadius: hasBackKey ? 0 : 6,
    boxShadow: 'rgba(0, 0, 36, 0.25) 0px 4px 12px -2px',
    zIndex: 10,
    marginTop: hasBackKey ? 0 : 13,
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #C9DBFF',
    boxShadow: 'none',
    padding: '0 15px',
    borderRadius: 'unset',
    borderTopLeftRadius: hasBackKey ? 0 : 6,
    borderTopRightRadius: hasBackKey ? 0 : 6,
    borderBottom: '1px solid #C9DBFF',
    backgroundColor: '#F7FAFF',
    '> div': {
      display: 'flex',
      padding: 'unset',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    '&:hover': {
      borderBottom: '1px solid #C9DBFF',
    },
  }),
  indicatorSeparator: () => ({}),
  menu: (base) => ({
    ...base,
    width: '100%',
    margin: 0,
    border: '1px solid #C9DBFF',
    borderTop: 'none',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    position: 'relative',
    boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
    overflowX: 'auto',
    zIndex: 2,
  }),
  input: () => ({
    width: '100%',
  }),
});

function EmptyComponent() {
  return <span />;
}
function SubMenuOptionMulti(props) {
  const { isSelected, label, isFocused, data } = props;
  const { specialOption } = data;
  const uniqueId = `submenu-option-${data.value}`;
  return (
    <components.Option {...props}>
      {!specialOption ? (
        <div
          className={`${classes.option} ${classes.subMenu} ${classes.longText} ${isFocused ? classes.focused : null}`}
        >
          <label
            className={`${classes.label} ${isSelected ? classes.activeOption : ''}`}
            style={{
              width: `${label.length * 8}px`,
              fontWeight: data?.bold ? 'bold' : 'normal',
            }}
            htmlFor={uniqueId}
          >
            {label}
          </label>
          <GenerateIcon className={classes.marginLeft} iconName={ICONS.chevronRight.name} id={uniqueId} />
        </div>
      ) : (
        <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
          <input
            className={classes.Checkbox}
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
            id={uniqueId}
          />
          <label
            className={`${classes.label} ${isSelected ? classes.activeOption : ''}`}
            style={{
              width: `${label.length * 8}px`,
              fontWeight: data?.bold ? 'bold' : 'normal',
            }}
            htmlFor={uniqueId}
          >
            <span className={classes.clear}>{label}</span>
            <InfoPopover isSimple>Group the not tagged cost across all tag keys</InfoPopover>
          </label>
        </div>
      )}
    </components.Option>
  );
}
SubMenuOptionMulti.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
};
SubMenuOptionMulti.defaultProps = {
  isSelected: false,
  isFocused: false,
};

function OptionMulti(props) {
  const { isSelected, label, isFocused, isMulti, data, selectProps } = props;
  const { likeParams } = selectProps;
  const { likeMode } = likeParams;
  const uniqueId = `option-${data.value}`;
  if (likeMode) {
    return (
      <components.Option {...props} isDisabled>
        <div>
          <label
            className={`${classes.limitSelectLabel}`}
            style={{
              width: `${label.length * 8}px`,
              fontWeight: data?.bold ? 'bold' : 'normal',
            }}
            htmlFor="cb-item"
          >
            {label}
          </label>
        </div>
      </components.Option>
    );
  }
  return !isMulti ? (
    <components.Option {...props}>
      <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
        {isSelected ? <Check className={classes.radioIcon} /> : <UnCheck className={classes.radioIcon} />}
        <label
          className={`${classes.label} ${isSelected ? classes.activeOption : ''}`}
          style={{
            width: `${label.length * 8}px`,
            fontWeight: data?.bold ? 'bold' : 'normal',
          }}
          htmlFor="cb-item"
        >
          {label}
        </label>
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>
      <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
        <input className={classes.Checkbox} type="checkbox" checked={isSelected} onChange={() => null} id={uniqueId} />
        <label
          className={`${classes.label} ${isSelected ? classes.activeOption : ''}`}
          style={{
            width: `${label.length * 8}px`,
            fontWeight: data?.bold ? 'bold' : 'normal',
          }}
          htmlFor={uniqueId}
        >
          {label}
        </label>
        {data.chip && <Chip label={data.chip} color="blue" size="small" className={classes.itemLabelChip} />}
      </div>
    </components.Option>
  );
}
OptionMulti.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  isMulti: PropTypes.bool,
  data: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};
OptionMulti.defaultProps = {
  isSelected: false,
  isFocused: false,
  isMulti: false,
};

function MenuList(props) {
  const { children, selectProps, filteredOptions } = props;
  const {
    components,
    isOptionsLoading,
    emptyStateText,
    likeParams,
    searchValue,
    setIsOpen,
    selectedItems,
    afterLoading,
  } = selectProps;
  const { MenuListFooter = null } = components;
  const isEmpty = !Array.isArray(children);
  const rowRenderer = ({ index, style }) => (
    <CustomTooltip title={filteredOptions[index]?.label} enterDelay={500} enterNextDelay={300}>
      <div style={style}>{children[index]}</div>
    </CustomTooltip>
  );

  if (isOptionsLoading) {
    return (
      <div className={classes.likeModeWrapper}>
        <Spinner />
      </div>
    );
  }
  if (!isOptionsLoading && isEmpty) {
    return (
      <>
        {emptyStateText && !afterLoading ? (
          <div className={classes.emptyStateWrapper}>
            <EmptyState />
            <span>{emptyStateText}</span>
          </div>
        ) : (
          <div className={`${classes.emptyStateWrapper} ${classes.emptyState}`}>
            <NoOptions />
            <span>No Results</span>
          </div>
        )}
      </>
    );
  }
  if (!isOptionsLoading && !isEmpty && !likeParams?.likeMode) {
    return (
      <>
        <List className={classes.menuList} height={230} itemSize={37} itemCount={children?.length}>
          {rowRenderer}
        </List>
        {MenuListFooter}
      </>
    );
  }
  if (!isOptionsLoading && !isEmpty && likeParams?.likeMode) {
    return (
      <>
        {searchValue ? (
          <>
            <div className={classes.selectAllItem}>
              <Checkbox
                isChecked={selectedItems?.find((s) => s.value === searchValue)}
                primary
                onChange={() => {
                  setTimeout(() => {
                    likeParams.searchHandler(searchValue);
                    if (setIsOpen) {
                      setIsOpen(false);
                    }
                  }, 100);
                }}
              >
                <div className={classes.freeSearchWrapper}>
                  <span className={classes.boldText}>Select all containing: * {searchValue} *</span>
                  <p> ({children?.length})</p>
                </div>
              </Checkbox>
            </div>
            <List className={classes.menuList} height={220} itemSize={37} itemCount={children?.length} {...props}>
              {rowRenderer}
            </List>
          </>
        ) : (
          <div className={classes.emptyStateWrapper}>
            <EmptyState />
            <span>Start typing to see results</span>
          </div>
        )}
      </>
    );
  }
  return <></>;
}

MenuList.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object,
};
MenuList.defaultProps = {
  children: null,
  selectProps: {},
};

const SearchInput = (props, onSearchClick, inputValue) => {
  const { selectProps } = props;
  const { likeParams } = selectProps;
  const { likeOperator, likeMode, handleChangeFilterType, field, likeCaseConfig } = likeParams || {};
  if (!selectProps.menuIsOpen) {
    return <components.Input {...props} />;
  }

  return (
    <div className={classes.InputSearch} onBlur={selectProps.onMenuClose} data-like-mode={!!likeMode}>
      {likeOperator && (
        <div className={classes.operatorDropdown}>
          <ButtonDropdown
            text={likeMode ? OPERATORS.LIKE : OPERATORS.IS}
            overrideMenuStyles={{ position: 'fixed', minWidth: '50px', left: 20 }}
            iconPlacement="right"
            icon={TriangleDown}
            overrideButtonStyles={{ paddingRight: '3px' }}
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <li onClick={!likeMode ? undefined : () => handleChangeFilterType(field, null, OPERATORS_KEYS.LIKE)}>
              <span>{OPERATORS.IS}</span>
            </li>
            <li onClick={likeMode ? undefined : () => handleChangeFilterType(field, null, OPERATORS_KEYS.LIKE)}>
              <span>{OPERATORS.LIKE}</span>
            </li>
          </ButtonDropdown>
        </div>
      )}
      <span className={`${classes.Placeholder} ${likeOperator ? classes.left : ''}`}>
        {!selectProps.inputValue ? 'Search' : null}
      </span>
      <div className={`${classes.inputEnv} ${likeOperator ? classes.left : ''}`}>
        <components.Input {...props} autoFocus />
      </div>
      {likeMode && likeCaseConfig ? (
        <CustomTooltip title={likeCaseConfig?.get(field) ? 'Case sensitive is on' : 'Case sensitive is off'}>
          <div
            onClick={() => {
              likeCaseConfig.set(field, !likeCaseConfig.get(field));
            }}
            className={classes.caseButton}
            data-off={!likeCaseConfig.get(field)}
          >
            <CaseSensitiveIcon />
          </div>
        </CustomTooltip>
      ) : (
        <div
          onClick={() => {
            if (onSearchClick) {
              onSearchClick(inputValue);
            }
          }}
        >
          <GenerateIcon iconName={ICONS.magnifyingGlass.name} className={classes.IconGray} />
        </div>
      )}
    </div>
  );
};
SearchInput.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

function FooterComponent({ selectedCount, onClick, optionsCount, isLoading, hideSelectAll, children }) {
  const buttonText = selectedCount > 0 ? 'Clear All' : 'Select All';
  return (
    <div className={classes.ActionBar}>
      <div>{children}</div>
      {!isLoading && !hideSelectAll && (
        <div className={classes.ActionBtn} onClick={onClick}>
          <span role="presentation" className={classes.clear}>
            {buttonText}
          </span>
          <span>{`(${selectedCount > 0 ? selectedCount : optionsCount})`}</span>
        </div>
      )}
    </div>
  );
}
FooterComponent.propTypes = {
  selectedCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  optionsCount: PropTypes.number.isRequired,
  children: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const FilterSelect = ({
  options,
  subOptions,
  subMenuOpenTagKey,
  setSubMenuOpenTagKey,
  onChange,
  isLoading,
  field,
  selectedItems,
  onMenuBlur,
  isOpen,
  setIsOpen,
  isMulti,
  hasSubItems,
  hideFooter,
  likeParams,
  searchHandler,
  onSearchClick,
  emptyStateText,
  limitOnBlurInId,
  specialOptionChange,
  hideSelectAll,
  showKeysOfSubMenuLoading,
}) => {
  const [selected, setSelected] = useState(selectedItems || []);
  const [filteredOptions, setFilteredOptions] = useState(options || subOptions || []);
  const [formattedOptions, setFormattedOptions] = useState(options || subOptions || []);
  const [inputValue, setInputValue] = useState('');
  const [startLoading, setStartLoading] = useState(false);
  const [afterLoading, setAfterLoading] = useState(false);
  const [selectProps, setSelectProps] = useState({
    isOptionsLoading: isLoading && (!hasSubItems || showKeysOfSubMenuLoading) && isOpen,
    emptyStateText,
    likeParams,
    searchValue: inputValue,
    setIsOpen,
    selectedItems,
    afterLoading,
  });

  useEffect(() => {
    setSelected(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    setSelectProps({
      ...selectProps,
      likeParams,
      emptyStateText,
      isOptionsLoading: isLoading && (!hasSubItems || showKeysOfSubMenuLoading) && isOpen,
      afterLoading,
      selectedItems,
    });
  }, [
    likeParams,
    isLoading,
    showKeysOfSubMenuLoading,
    hasSubItems,
    isOpen,
    emptyStateText,
    selectedItems,
    afterLoading,
  ]);
  useEffect(() => {
    if (isOpen) {
      const tempOptions = [];
      const isValid = (val) => val !== undefined && val !== null && val !== '';
      let i = 0;
      const optionsTemp = options ? [...options] : [...(subOptions || [])];
      for (i; i < optionsTemp.length; i++) {
        const { value, label } = optionsTemp[i];
        if (isValid(value) || isValid(label)) {
          if (!isValid(value)) {
            if (!selected?.some((item) => item.value === label)) {
              tempOptions.push({ value: label, label });
            }
          } else if (!isValid(label)) {
            if (!selected?.some((item) => item.value === value)) {
              tempOptions.push({ value, label: value });
            }
          } else if (Array.isArray(selected) && !selected?.some((item) => item.value === value)) {
            tempOptions.push(optionsTemp[i]);
          }
        }
      }
      const tempSelectedItems =
        selected && Array.isArray(selected) ? [...(selected || [])].sort((a, b) => a.label.localeCompare(b.label)) : [];
      const formatted = [...tempSelectedItems, ...tempOptions];
      setFormattedOptions(formatted);
      if ((options?.length || subOptions?.length) && !inputValue) {
        setFilteredOptions(options || subOptions);
      }
    }
  }, [options, subOptions, isOpen]);

  useEffect(() => {
    if (!isLoading) {
      if (options?.length || subOptions?.length) {
        setFilteredOptions(options || subOptions);
      }
      if (startLoading) {
        setAfterLoading(true);
        setStartLoading(false);
      }
    } else {
      setStartLoading(true);
      setAfterLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!inputValue || inputValue.length < 3) {
      setAfterLoading(false);
    }
  }, [inputValue]);
  const selectItem = (item) => {
    if (onChange) {
      onChange(item);
    } else if (item.specialOption && specialOptionChange) {
      specialOptionChange({ ...item, isChecked: !selected.find((s) => s.value === item.value) });
    } else {
      const lastItem = isMulti ? item.filter((obj) => !selected?.includes(obj)) : null;
      setSubMenuOpenTagKey(lastItem ? lastItem[0] : item);
    }
    setSelected(item);
  };

  const onBlur = (event) => {
    if (!limitOnBlurInId || event.relatedTarget?.id !== limitOnBlurInId) {
      onMenuBlur(event);
    }
    setFilteredOptions(formattedOptions);
  };

  const selectedOptionsCount = () => {
    if (!filteredOptions.length) {
      return selected?.length || 0;
    }
    const selectedFilterOption = filteredOptions.filter((fo) => selected?.find?.((s) => s.value === fo.value));
    return selectedFilterOption.length;
  };

  const onFooterClick = () => {
    let tempItems = [];
    const selectedFilteredOptions = selected
      ? selected.filter((fo) => filteredOptions.find((s) => s.value === fo.value))
      : [];
    const selectedUnfilteredOptions = selected
      ? selected.filter((fo) => !filteredOptions.find((s) => s.value === fo.value))
      : [];
    if (selectedFilteredOptions.length > 0) {
      tempItems = selectedUnfilteredOptions;
    } else {
      tempItems = [...selectedUnfilteredOptions, ...filteredOptions.filter((opt) => !opt.isDisabled)];
    }
    selectItem(tempItems);
  };

  const customComponents = {
    DropdownIndicator: EmptyComponent,
    MultiValue: EmptyComponent,
    SingleValue: EmptyComponent,
    MultiValueRemove: EmptyComponent,
    Placeholder: EmptyComponent,
    ClearIndicator: EmptyComponent,
    Option: hasSubItems ? SubMenuOptionMulti : OptionMulti,
    MenuList: (props) => MenuList({ ...props, filteredOptions }),
    Input: (props) => SearchInput(props, onSearchClick, inputValue),
    MenuListFooter: hideFooter ? (
      EmptyComponent
    ) : (
      <FooterComponent
        selectedCount={selectedOptionsCount()}
        onClick={onFooterClick}
        optionsCount={filteredOptions.filter((opt) => !opt.isDisabled).length}
        isLoading={isLoading}
        hideSelectAll={hideSelectAll && !inputValue && !selected?.length}
      />
    ),
  };

  const filterOption = (option, rawInput) => {
    const caseSensitive = likeParams?.likeCaseConfig?.get(field);
    const compare = (str1, str2) =>
      caseSensitive ? str1.includes(str2) : str1.toLowerCase().includes(str2.toLowerCase());

    if (option.value.endsWith('no_tag')) {
      return true;
    }
    return compare(option.label, rawInput) || compare(option.value, rawInput);
  };

  const searchInputChanged = (val, reason) => {
    if (reason.action === 'set-value' || (reason.action !== 'input-change' && !val && !inputValue)) {
      return;
    }
    if (reason.action !== 'menu-close') {
      setInputValue(val);
      setSelectProps({ ...selectProps, searchValue: val });
    }
    const searchOptions = () => {
      if (reason.action === 'menu-close' && reason.prevInputValue && reason.prevInputValue !== '') {
        setFilteredOptions(formattedOptions);
        return;
      }
      if (reason.action === 'input-change') {
        const optionsTemp = options ? [...options] : [...(subOptions || [])];
        const o = optionsTemp.filter((opt) => filterOption(opt, val));
        setFilteredOptions(o);
        searchHandler(val);
      } else if (!inputValue) {
        setFilteredOptions(formattedOptions);
      }
    };
    debounce(searchOptions, 200)();
  };

  return (
    <div>
      {isOpen && (
        <>
          <Select
            menuIsOpen
            onChange={selectItem}
            onInputChange={searchInputChanged}
            options={subMenuOpenTagKey && subOptions ? subOptions : options}
            filterOption={filterOption}
            isOptionDisabled={() => likeParams?.likeMode}
            components={customComponents}
            value={selected}
            styles={customStyles(subMenuOpenTagKey)}
            isMulti={isMulti}
            autoFocus
            closeMenuOnSelect={false}
            escapeClearsValue
            onBlur={onBlur}
            inputValue={inputValue}
            hideSelectedOptions={false}
            {...selectProps}
          />
        </>
      )}
    </div>
  );
};

FilterSelect.propTypes = {
  selectedItems: PropTypes.array,
  onMenuBlur: PropTypes.func.isRequired,
  options: PropTypes.array,
  subOptions: PropTypes.array,
  field: PropTypes.string.isRequired,
  setSubMenuOpenTagKey: PropTypes.func.isRequired,
  subMenuOpenTagKey: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isMulti: PropTypes.bool,
  likeParams: PropTypes.object,
  hasSubItems: PropTypes.bool,
  hideFooter: PropTypes.bool,
  onChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  emptyStateText: PropTypes.string,
  limitOnBlurInId: PropTypes.string,
  searchHandler: PropTypes.func.isRequired,
  specialOptionChange: PropTypes.func,
};

FilterSelect.defaultProps = {
  selectedItems: [],
  options: null,
  subOptions: null,
  isLoading: false,
  isOpen: false,
  setIsOpen: null,
  isMulti: false,
  hasSubItems: false,
  hideFooter: false,
  subMenuOpenTagKey: null,
  likeParams: {},
  onChange: null,
  emptyStateText: null,
  limitOnBlurInId: null,
  onSearchClick: null,
  specialOptionChange: null,
};
