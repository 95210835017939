/* eslint-disable max-len */
import { mapRecommendationsTypeToDisplay } from 'recommendations/constants/recommendationsConstants';
import { RecommTypeValuesToFilterText } from 'recommendations/containers/RecommendationsHistory/constants/recommHistoryConstants';

export const RECOMM_HISTORY_TABLE_COLUMNS = [
  // { name: 'key', title: 'Id', getCellValue: row => row.key },
  {
    name: 'linkedAccount',
    title: 'Linked Account',
    getCellValue: (row) =>
      row.linkedAccountName && row.linkedAccountName !== row.linkedAccountId
        ? `${row.linkedAccountName} (${row.linkedAccountId})`
        : row.linkedAccountId,
  },
  { name: 'type', title: 'Type', getCellValue: (row) => mapRecommendationsTypeToDisplay.get(row.type) },
  {
    name: 'resource',
    title: 'Resource',
    getCellValue: (row) => {
      if (row.resourceName && row.resourceId) {
        return `${row.resourceName} (${row.resourceId})`;
      } else if (row.resourceId) {
        return row.resourceId;
      } else if (row.multiResourceId) {
        return 'Multiple Resources';
      }
      return '-';
    },
  },
  { name: 'status', title: 'Status', getCellValue: (row) => RecommTypeValuesToFilterText.get(row.status) },
  { name: 'costPotentialSavings', title: 'Saving Amount', getCellValue: (row) => row.costPotentialSavings },
  { name: 'updateTime', title: 'Date', getCellValue: (row) => row.updateTime },
  { name: 'excludeMessage', title: 'Exclude Reason', getCellValue: (row) => row.excludeMessage || '' },
  { name: 'resourceTags', title: 'Tags', getCellValue: (row) => (row.resourceTags || {}).toString() || '' },
  { name: 'link', title: ' ' },
  { name: 'actionButton', title: ' ' },
];
export const RECOMM_HISTORY_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'linkedAccount', width: 200 },
  { columnName: 'type', width: 180 },
  { columnName: 'resource', width: 180 },
  { columnName: 'status', width: 150 },
  { columnName: 'costPotentialSavings', width: 150 },
  { columnName: 'updateTime', width: 150 },
  { columnName: 'excludeMessage', width: 130 },
  { columnName: 'resourceTags', width: 130 },
  { columnName: 'link', width: 130 },
  { columnName: 'actionButton', width: 130 },
];

export const RECOMM_HISTORY_TABLE_COLUMNS_EXTENSIONS = [
  { columnName: 'linkedAccount', width: 200 },
  { columnName: 'type', width: 180 },
  { columnName: 'status', width: 150 },
  { columnName: 'costPotentialSavings', align: 'center', width: 150 },
  { columnName: 'updateTime', width: 150 },
  { columnName: 'link', width: 100 },
  { name: 'actionButton', width: 100 },
  { columnName: 'excludeMessage', width: 130 },
  { columnName: 'resourceTags', width: 130 },
];
