/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { KinesisLowUtilizationRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class KinesisLowUtilizationRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new KinesisLowUtilizationRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.KINESIS_LOW_UTILIZATION;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Kinesis`;
  }

  build() {
    super.build();
    const {
      starting_time: startingTime,
      arn,
      // status,
      stream_name: streamName,
      max_put_record_bytes: maxPutRecordBytes,
      max_put_records_bytes: maxPutRecordsBytes,
      put_record_bytes: putRecordBytes,
      put_records_bytes: putRecordsBytes,
      zone_tag: region,
      days_to_check: daysToCheck,
    } = this._rawRecommendation;

    this._recommendation.startingTime = startingTime;
    // this._recommendation.status = status;
    this._recommendation.arn = arn;
    this._recommendation.putRecordsBytes = putRecordsBytes;
    this._recommendation.maxPutRecordsBytes = maxPutRecordsBytes;
    this._recommendation.putRecordBytes = putRecordBytes;
    this._recommendation.maxPutRecordBytes = maxPutRecordBytes;
    this._recommendation.streamName = streamName;
    this._recommendation.region = region;
    this._recommendation.daysToCheck = daysToCheck;
    // BETA
    // this._recommendation.isBeta = true;
  }
}
