/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { RecommTypeValuesToFilterText } from 'recommendations/containers/RecommendationsHistory/constants/recommHistoryConstants';

export default class RecommStatusBadge extends PureComponent {
  static propTypes = {
    badgeType: PropTypes.string.isRequired,
    addLeftMargin: PropTypes.bool.isRequired,
  };

  render() {
    const { badgeType, addLeftMargin } = this.props;
    const statusText = RecommTypeValuesToFilterText.get(badgeType);
    const leftMarginClassName = addLeftMargin ? 'add_left_margin' : null;
    const mainClassName = `recomm_status_badge_${statusText} ${leftMarginClassName}`;
    return (
      <Badge color="inherit" className={mainClassName}>
        {statusText}
      </Badge>
    );
  }
}
