/* TODO - Refactore this component from class to function,
and take from shared compoenents to recommendations componenets and addd it's css file. */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  UncontrolledDropdown,
} from 'reactstrap';
import CustomModal from 'shared/components/andtComponents/Modal';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import Tooltip from '@mui/material/Tooltip';
import {
  mapRecommendationsTypeToDisplay,
  RecommendationExcludePeriod,
  RecommendationExcludePeriodInDays,
  RecommendationExcludeScope,
} from '../../constants/recommendationsConstants';

export default class ModalComponent extends PureComponent {
  static propTypes = {
    rec: PropTypes.object,
    recs: PropTypes.object,
    recExcludeHandler: PropTypes.func.isRequired,
    afterActionHandler: PropTypes.func.isRequired,
  };
  static defaultProps = {
    rec: null,
    recs: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      excludeScope: this.getDefaultExcludeScope(props.rec || props.recs[0]),
      excludePeriod: RecommendationExcludePeriod.ThreeMonths,
      reason: '',
      reasonError: false,
    };
    this.getDefaultExcludeScope = this.getDefaultExcludeScope.bind(this);
    this.getExcludeScopeDisplay = this.getExcludeScopeDisplay.bind(this);
    this.getExcludeScopeShortDisplay = this.getExcludeScopeShortDisplay.bind(this);
    this.getExcludeScopeObject = this.getExcludeScopeObject.bind(this);
    this.getExcludePeriodDisplay = this.getExcludePeriodDisplay.bind(this);
    this.getExcludeMessageMap = this.getExcludeMessageMap.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onSelectScope = (scope) => {
    this.setState({
      excludeScope: scope,
    });
  };

  onSelectPeriod = (period) => {
    this.setState({
      excludePeriod: period,
    });
  };
  getDefaultExcludeScope = (rec) => {
    let scope =
      rec.type === 'ec2-savings-plans'
        ? RecommendationExcludeScope.LINKED_ACCOUNT_ID
        : RecommendationExcludeScope.RESOURCE_ID;
    if (!rec.resourceId) {
      scope = RecommendationExcludeScope.LINKED_ACCOUNT_ID;
    }
    return scope;
  };
  getExcludeScopeDisplay = (recKey = null) => {
    const { rec, recs } = this.props;
    const { type, resourceId, linkedAccountId, subscriptionId } =
      rec || recs.find(({ key }) => key === recKey) || recs[0];
    return new Map([
      [
        RecommendationExcludeScope.RESOURCE_ID,
        `'${mapRecommendationsTypeToDisplay.get(type)}' recommendations with the same Resource ID (${resourceId})`,
      ],
      [
        RecommendationExcludeScope.LINKED_ACCOUNT_ID,
        `'${mapRecommendationsTypeToDisplay.get(type)}' recommendations with the same ${
          subscriptionId ? 'Subscription ID' : 'Linked Account ID'
        } (${linkedAccountId})`,
      ],
    ]);
  };

  getExcludeScopeShortDisplay = () => {
    const { rec, recs } = this.props;
    const linkedAccountIds = rec || recs.map(({ linkedAccountId }) => linkedAccountId).join(', ');
    const resourceIds = rec ? rec.resourceId : recs.map(({ resourceId }) => resourceId).join(', ');
    return new Map([
      [RecommendationExcludeScope.RESOURCE_ID, `Resource IDs (${resourceIds})`],
      [RecommendationExcludeScope.LINKED_ACCOUNT_ID, `Linked Account IDs (${linkedAccountIds})`],
    ]);
  };

  getExcludeScopeObject = () => {
    const { rec, recs } = this.props;
    return new Map([
      [
        RecommendationExcludeScope.RESOURCE_ID,
        (recs || [rec]).reduce(
          (acc, { resourceId, type, key }) => ({
            ...acc,
            [key]: { resourceId, type },
          }),
          {},
        ),
      ],
      [
        RecommendationExcludeScope.LINKED_ACCOUNT_ID,
        (recs || [rec]).reduce(
          (acc, { linkedAccountId, type, key }) => ({
            ...acc,
            [key]: { linkedAccountId, type },
          }),
          {},
        ),
      ],
    ]);
  };

  getExcludePeriodDisplay = () =>
    new Map([
      [RecommendationExcludePeriod.OneMonth, '1 Month'],
      [RecommendationExcludePeriod.ThreeMonths, '3 Months'],
      [RecommendationExcludePeriod.SixMonths, '6 Months'],
      [RecommendationExcludePeriod.TwelveMonths, '12 Months'],
      [RecommendationExcludePeriod.Permanently, 'Permanently'],
    ]);

  getExcludeMessageMap = () => {
    const { rec, recs } = this.props;
    const excludeMessageMap = new Map();
    const { excludeScope, excludePeriod, reason } = this.state;
    (recs || [rec]).forEach(({ key }) => {
      const scope = this.getExcludeScopeDisplay(key).get(excludeScope);
      const period = this.getExcludePeriodDisplay().get(excludePeriod);
      const excludeMessage = `${scope} for ${period}${reason ? ` (${reason})` : ''}`;
      excludeMessageMap.set(key, excludeMessage);
    });
    return excludeMessageMap;
  };

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  handleCommentChange = (event) => {
    this.setState({
      reason: event.target.value,
    });
  };

  render() {
    const { rec, recs, recExcludeHandler, afterActionHandler } = this.props;
    const { excludeScope, excludePeriod, reason, modal, reasonError } = this.state;
    const { type } = rec || recs[0];
    const excludeScopeShortDisplay = this.getExcludeScopeShortDisplay();
    const excludeScopeObject = this.getExcludeScopeObject();
    const excludePeriodDisplay = this.getExcludePeriodDisplay();
    return (
      <CustomModal
        open={modal}
        onClose={this.toggle}
        title="We want to give you the best recommendations"
        closeOnSave={false}
        onSave={() => {
          if (!reason) {
            this.setState({ reasonError: true });
            return;
          }
          const excludeData = {
            scopeMap: excludeScopeObject.get(excludeScope),
            period: RecommendationExcludePeriodInDays.get(excludePeriod),
            reason,
          };
          const excludeMessageMap = this.getExcludeMessageMap();
          recExcludeHandler(rec || recs, excludeData, excludeMessageMap);
          this.toggle();
          if (afterActionHandler) {
            afterActionHandler();
          }
          this.setState({ reasonError: false });
        }}
      >
        <div className="modal__body">
          Tell us which {"'"}
          {mapRecommendationsTypeToDisplay.get(type)}
          {"'"} recommendations to exclude
        </div>
        <div>
          <Form className="modal__body">
            <div className="d-flex flex-column">
              <Label style={{ paddingTop: '20px', fontSize: '1.2em' }}>Exclude</Label>
              <Tooltip title={excludeScopeShortDisplay.get(excludeScope)} placement="top">
                <ButtonGroup style={{ width: '100%' }}>
                  <Button
                    disabled
                    color="white"
                    size="sm"
                    outline
                    style={{ marginBottom: '0', color: 'black', fontSize: '1.125em', width: '100%' }}
                  >
                    {excludeScopeShortDisplay.get(excludeScope)}
                  </Button>
                </ButtonGroup>
              </Tooltip>
              <Label style={{ paddingTop: '20px', fontSize: '1.2em' }}>For the next</Label>
              <UncontrolledDropdown>
                <ButtonGroup style={{ width: '100%' }}>
                  <Button
                    disabled
                    color="white"
                    size="sm"
                    outline
                    style={{ color: 'black', fontSize: '1.125em', width: '100%' }}
                  >
                    {excludePeriodDisplay.get(excludePeriod)}
                  </Button>
                  <DropdownToggle outline className="icon icon--right">
                    <ChevronDownIcon />
                  </DropdownToggle>
                </ButtonGroup>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem onClick={() => this.onSelectPeriod(RecommendationExcludePeriod.OneMonth)}>
                    {excludePeriodDisplay.get(RecommendationExcludePeriod.OneMonth)}
                  </DropdownItem>
                  <DropdownItem onClick={() => this.onSelectPeriod(RecommendationExcludePeriod.ThreeMonths)}>
                    {excludePeriodDisplay.get(RecommendationExcludePeriod.ThreeMonths)}
                  </DropdownItem>
                  <DropdownItem onClick={() => this.onSelectPeriod(RecommendationExcludePeriod.SixMonths)}>
                    {excludePeriodDisplay.get(RecommendationExcludePeriod.SixMonths)}
                  </DropdownItem>
                  <DropdownItem onClick={() => this.onSelectPeriod(RecommendationExcludePeriod.TwelveMonths)}>
                    {excludePeriodDisplay.get(RecommendationExcludePeriod.TwelveMonths)}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <Label style={{ paddingTop: '20px', fontSize: '1.2em' }}>Reason</Label>
              <Input type="textarea" value={reason} id="reason" onChange={this.handleCommentChange} />
              {reasonError ? (
                <p style={{ color: 'red', marginTop: '15px' }}>Please add exclude reason to save the exclusion</p>
              ) : null}
            </div>
          </Form>
        </div>
      </CustomModal>
    );
  }
}
