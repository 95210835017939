import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { AWS_ONBOARDING_FIELDS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import { Progress } from '@pileus-cloud/anodot-frontend-common';
import { palette } from 'shared/constants/colorsConstants';
import LoadingImg3 from '../../AwsOnboarding/assets/loading3.gif';
import IllustrationImg from './assets/illustration.png';
import WarningsList from '../WarningsList/WarningsList';
import styles from './ProcessDataPage.module.scss';

const ProcessDataPage = () => {
  const { getValues, setValue } = useFormContext();
  const accountId = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID);
  const invoiceSuccess = getValues(AWS_ONBOARDING_FIELDS.INVOICE_SUCCESS);
  const { fetchOnboardingStatus, fetchOnboardingInvoiceStatus } = useOnboarding();
  const [invoiceFailed, setInvoiceFailed] = useState(false);
  const { data: status } = fetchOnboardingStatus(accountId, {
    enabled: !invoiceFailed && !invoiceSuccess,
    refetchInterval: 15 * 1000,
  });
  const { data: invoiceStatusDetailed } = fetchOnboardingInvoiceStatus(getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID), {
    refetchInterval: 15 * 1000,
    enabled: !invoiceSuccess,
  });
  const { invoiceStatus } = status || {};
  useEffect(() => {
    const isFailed = invoiceStatus?.status === 'FAILED' && status.invoiceShouldStart;
    const isSuccess = invoiceStatus?.status === 'SUCCESS';
    if (isSuccess) {
      setValue(AWS_ONBOARDING_FIELDS.INVOICE_SUCCESS, true);
      return;
    }
    if (isFailed) {
      setInvoiceFailed(true);
    }
  }, [status]);
  return (
    <div className={styles.container}>
      <WarningsList warnings={invoiceStatus?.errors || []} />
      {invoiceSuccess ? (
        <>
          <img className={styles.illustration} src={IllustrationImg} alt="" />
          <h2 className={styles.title}>Congrats!</h2>
          <p className={styles.desc}>We’ve successfully finished processing your data.</p>
        </>
      ) : (
        <>
          <img className={styles.illustration} src={LoadingImg3} alt="Loading" />
          <p className={styles.title}>Processing your Cost & Usage Report</p>
          <p className={styles.desc}>
            Please note that processing time can take up to 48 hours. You may close this window.
          </p>
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              <Progress backgroundColor={palette.blue['300']} value={invoiceStatusDetailed?.progress || 0} />
            </div>
            {Math.round(invoiceStatusDetailed?.progress || 0)}%
          </div>
        </>
      )}
    </div>
  );
};

export default ProcessDataPage;
