import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  IntegratedSorting,
  DataTypeProvider,
  SortingState,
  PagingState,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { Grid, TableHeaderRow, PagingPanel, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { useRootStore } from 'app/contexts/RootStoreContext';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import Button from 'shared/components/andtComponents/Button';
import toast from 'shared/components/andtComponents/Toast';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import { ReactComponent as EmptyUserIcon } from 'shared/img/images/empty-user.svg';
import { CategoryAction, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import {
  USERS_COLUMN_EXTENSIONS,
  USERS_TABLE_COLUMNS,
} from 'divisions/containers/ManageCustomers/constants/customersContants';
import {
  useLinkedAccountsTableState,
  useUsersTableState,
} from 'divisions/containers/ManageCustomers/contexts/customersContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { Routes } from 'shared/constants/routes';
import styles from './UsersTable.module.scss';

const UsersTable = ({ history, users, customerId, addUserHandler, addUserDisabled }) => {
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [deletedUsersLoading, setDeletedUsersLoading] = useState(false);
  const { NewTableSelectionBar, NewNoDataCell } = useTable();
  const { usersStore, appStore } = useRootStore();
  const pageSizes = [10, 20, 30];
  const { anySelected: linkedAccountsChecked } = useLinkedAccountsTableState(customerId);
  const { selectedRows, setSelectedRows, page, setPage } = useUsersTableState(customerId);

  const renderNoDataCell = (props) => (
    <NewNoDataCell {...props}>
      <div className={styles.emptyCell}>
        <EmptyUserIcon />
        <p>Would you like to add Users to this Customer?</p>
        <ReadOnlyDisplayWrapper
          isHide={false}
          category={OrganizationEntityCategory.ResellerCustomers}
          action={CategoryAction.Create}
        >
          <Button
            text="Add Users"
            isTextButton
            automationId="addUsersModalButton"
            disabled={addUserDisabled}
            icon={() => <GenerateIcon iconName={ICONS.addUser.name} />}
            onClick={addUserHandler}
          />
        </ReadOnlyDisplayWrapper>
      </div>
    </NewNoDataCell>
  );

  const impersonateFormatter = (data) => (
    <Tooltip title="User impersonation">
      <span>
        <Button
          onClick={async () => {
            await usersStore.handleDisplayedUserChange(data.row.userKey);
            history.push(Routes.LANDING);
          }}
          text=""
          automationId="impersonateUserButton"
          icon={() => <GenerateIcon iconName={ICONS.userSecret.name} />}
          isTextButton
        />
      </span>
    </Tooltip>
  );

  const userDeleteWarningHandler = async (action) => {
    if (action === 'delete') {
      setDeletedUsersLoading(true);
      try {
        await usersStore.usersModel.deleteSubUsersBulk(
          deletedUsers.map(({ userKey, accountKey }) => ({ userKey, accountKey })),
          appStore.isKeyCloakManagement,
        );
      } catch (e) {
        toast.error('Something went wrong, please try again later');
      }
      setDeletedUsersLoading(false);
      setSelectedRows([]);
    }
    setDeletedUsers([]);
  };

  return (
    <div className={styles.container} data-disabled={!!linkedAccountsChecked}>
      <div className={styles.title}>
        Users <span>({users.length})</span>
      </div>
      <div className={`sub-table ${styles.tableWrapper}`}>
        <Grid rows={users} columns={USERS_TABLE_COLUMNS}>
          <SortingState />
          <IntegratedSorting />
          <SelectionState selection={selectedRows} onSelectionChange={(data) => setSelectedRows(data)} />
          <IntegratedSelection />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={pageSizes[1]}
            currentPage={page}
            onCurrentPageChange={setPage}
          />
          <IntegratedPaging />
          <DataTypeProvider for={['impersonate']} formatterComponent={impersonateFormatter} />
          <TableWrapper noDataCellComponent={renderNoDataCell} columnExtensions={USERS_COLUMN_EXTENSIONS} />
          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll />
          <PagingPanel pageSizes={pageSizes} />
          <NewTableSelectionBar selectedRows={selectedRows}>
            <div className={styles.selectedUsers}>
              <Button
                isTextButton
                isLoading={deletedUsersLoading}
                automationId="deleteUsersButton"
                text="Delete"
                icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
                onClick={() => {
                  setDeletedUsers(users.filter((_, ind) => selectedRows.includes(ind)));
                }}
              />
            </div>
          </NewTableSelectionBar>
        </Grid>
        <DeleteWarningModal
          deletedItemName={`${deletedUsers.length} users`}
          isOpen={deletedUsers.length > 0}
          handleDelete={userDeleteWarningHandler}
          warningMessage="Be advise you are about to delete "
          modalTitle="Delete Users"
        />
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  addUserDisabled: PropTypes.bool.isRequired,
  customerId: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  addUserHandler: PropTypes.func.isRequired,
};

export default withRouter(withRolesContextConsumer(UsersTable));
