import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line max-len
import RecommendationOptionButton from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationOptionButton';

import classes from './recommendationOption.module.scss';

const RecommendationOptionsPanel = ({
  recommendation,
  alternatives,
  currentProperties,
  alternativeProperties,
  isRecommendedOption,
  minHeight,
  noticeComment,
}) => (
  <div className={classes.optionsContainer}>
    <RecommendationOptionButton
      recommendation={recommendation}
      properties={currentProperties}
      minHeight={minHeight}
      noticeComment={noticeComment}
      isCurrent
      isRecommendedOption={isRecommendedOption}
    />
    {alternatives.map((_, index) => (
      <RecommendationOptionButton
        recommendation={recommendation}
        optionIndex={index}
        minHeight={minHeight}
        properties={alternativeProperties}
        isRecommendedOption={isRecommendedOption}
      />
    ))}
  </div>
);

RecommendationOptionsPanel.propTypes = {
  alternatives: PropTypes.array.isRequired,
  currentProperties: PropTypes.object.isRequired,
  alternativeProperties: PropTypes.object.isRequired,
};

export default RecommendationOptionsPanel;
