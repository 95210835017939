import React from 'react';
import PropTypes from 'prop-types';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomizedAxisTick from 'shared/components/CustomizedAxisTick';
import { kIntFormmater, numberWithCommas } from 'shared/utils/strUtil';

const CHART_COLOR = '#7d0756';

const renderLabelsWithColor = (value, entry) => <span style={{ color: entry.color }}>{value}</span>;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload?.length) {
    return (
      <div className="chart-tooltip p-2">
        <p className="label">{payload[0].date}</p>
        <p className="label" style={{ color: CHART_COLOR }}>
          Max Actual Throughput: {numberWithCommas(payload[0].value, 3)}
        </p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
};

const MaxThroughputChart = ({ data }) => (
  <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
    {data?.throughputData && (
      <LineChart
        width={550}
        height={284}
        data={data.throughputData}
        margin={{ top: 0, right: 20, bottom: 0, left: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick fontSize={12} />} />
        <YAxis
          type="number"
          label={{
            value: 'THROUGHPUT',
            offset: 0,
            angle: -90,
            position: 'left',
          }}
          tickFormatter={(value) => kIntFormmater(value)}
          domain={[0, 'dataMax + 500']}
        />
        <Tooltip content={({ active, payload }) => <CustomTooltip active={active} payload={payload} data={data} />} />
        <Legend
          payload={[{ value: 'Max Actual Throughput', type: 'line', color: CHART_COLOR, id: '1' }]}
          formatter={renderLabelsWithColor}
        />
        <Line type="linear" dataKey="throughput" dot={false} stroke={CHART_COLOR} strokeWidth={2} name="THROUGHPUT" />
      </LineChart>
    )}
  </ResponsiveContainer>
);

MaxThroughputChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MaxThroughputChart;
