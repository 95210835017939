/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

const DiscountTypeRadioButtons = (props) => {
  const buttonGroupStyle = {
    margin: '5px 0px',
    display: 'flex',
    alignItems: 'center',
  };
  const labelStyle = {
    margin: '0 0 0 5px',
    fontSize: '13px',
  };
  const columnWidths = {
    xl: 'auto',
    lg: 'auto',
    md: 'auto',
    sm: 'auto',
    xs: 'auto',
  };
  return (
    <div className="d-flex flex-wrap flex-row">
      <Col {...columnWidths} style={buttonGroupStyle}>
        <input
          id="percentage"
          type="radio"
          name="discountRadioButton"
          checked={props.inputSelectedOption.marginType === 'percentage'}
          onChange={props.handleDiscountTypeChange}
        />
        <label style={labelStyle} htmlFor="percentage">
          Percentage
        </label>
      </Col>
      <Col {...columnWidths} style={buttonGroupStyle}>
        <input
          id="fix"
          type="radio"
          name="discountRadioButton"
          checked={props.inputSelectedOption.marginType === 'fix'}
          onChange={props.handleDiscountTypeChange}
        />
        <label style={labelStyle} htmlFor="fix">
          Fixed cost
        </label>
      </Col>
      <Col {...columnWidths} style={buttonGroupStyle}>
        <input
          id="rate"
          type="radio"
          name="discountRadioButton"
          disabled={!props.isFixedRateSupportedService}
          checked={props.inputSelectedOption.marginType === 'rate'}
          onChange={props.handleDiscountTypeChange}
        />
        <label style={labelStyle} htmlFor="rate">
          Fixed rate
        </label>
      </Col>
      <Col {...columnWidths} style={buttonGroupStyle}>
        <input
          id="custom-service-calc-cost"
          type="radio"
          name="discountRadioButton"
          checked={props.inputSelectedOption.marginType === 'custom-service-calc-cost'}
          onChange={props.handleDiscountTypeChange}
        />
        <label style={labelStyle} htmlFor="custom-service-calc-cost">
          Custom Service Aggregated %
        </label>
      </Col>
      {props.cloudType === CLOUD_TYPE_IDS.AWS && (
        <Col {...columnWidths} style={buttonGroupStyle}>
          <input
            id="calc-cost"
            type="radio"
            name="discountRadioButton"
            // disabled={!props.isCalcCostSupportedService}
            checked={props.inputSelectedOption.marginType === 'calc-cost'}
            onChange={props.handleDiscountTypeChange}
          />
          <label style={labelStyle} htmlFor="calc-cost">
            AWS Support
          </label>
        </Col>
      )}
      {props.cloudType === CLOUD_TYPE_IDS.AWS && (
        <Col {...columnWidths} style={buttonGroupStyle}>
          <input
            id="remove-support"
            type="radio"
            name="discountRadioButton"
            checked={props.inputSelectedOption.marginType === 'remove-support'}
            onChange={props.handleDiscountTypeChange}
          />
          <label style={labelStyle} htmlFor="remove-support">
            Remove AWS Support
          </label>
        </Col>
      )}
    </div>
  );
};

DiscountTypeRadioButtons.propTypes = {
  isFixedRateSupportedService: PropTypes.bool.isRequired,
  isCalcCostSupportedService: PropTypes.bool.isRequired,
  inputSelectedOption: PropTypes.object.isRequired,
  handleDiscountTypeChange: PropTypes.object.isRequired,
  cloudType: PropTypes.number.isRequired,
};

export default DiscountTypeRadioButtons;
