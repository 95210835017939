import PropTypes from 'prop-types';
import React from 'react';
import RecommendationOptionButton from 'recommendations/shared/components/RecommendationOptionButton';

import classes from './recommendationOption.module.scss';

const RecommendationOptionsPanel = ({ alternatives, current, recommendationData }) => (
  <div className={classes.optionsPanel}>
    <div className={classes.optionsContainer}>
      <RecommendationOptionButton option={current} recommendationData={recommendationData} isCurrent />
      {alternatives.map((alternative, index) => (
        <RecommendationOptionButton
          isRecommended={index === 0 || false}
          option={alternative}
          optionNumber={index}
          recommendationData={recommendationData}
          current={current}
        />
      ))}
    </div>
    <div className={classes.optionsFooter} />
  </div>
);

RecommendationOptionsPanel.propTypes = {
  alternatives: PropTypes.array.isRequired,
  current: PropTypes.object.isRequired,
  recommendationData: PropTypes.object.isRequired,
};

export default RecommendationOptionsPanel;
