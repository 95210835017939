/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { StringToColor } from 'shared/utils/graphicUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const SimplePieChart = ({ data, height, isLegend }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <ResponsiveContainer width="100%" height={height || 300}>
      <PieChart>
        <Pie
          data={data}
          cx={height / 2 || 150}
          cy={height / 3 || 100}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
        >
          {data.map((entry, index) => (
            <Cell fill={StringToColor.next(entry.name ? entry.name : index)} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `${getCurrencyNumber(value, 0, { roundNumber: true })}`} />
        {isLegend ? <Legend /> : null}
      </PieChart>
    </ResponsiveContainer>
  );
};

SimplePieChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  isLegend: PropTypes.bool,
};

SimplePieChart.defaultProps = {
  isLegend: false,
};

export default SimplePieChart;
