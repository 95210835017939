import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
// eslint-disable-next-line max-len
import BusinessMappingTable from '../BusinessMapping/components/BusinessMappingGroupsTable/BusinessMappingViewpointInfoTable';

const BusinessMapping = (props) => (
  <div className="ps-4 pe-4">
    <BusinessMappingTable {...props} />
  </div>
);

BusinessMapping.propTypes = {
  kubernetesStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};

export default observer(BusinessMapping);
