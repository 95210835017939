/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Col, Collapse, Row } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { ClickAwayListener } from '@mui/material';

class RecommendationDropdown extends PureComponent {
  static propTypes = {
    recHandlers: PropTypes.object.isRequired,
    selectedRec: PropTypes.object,
  };

  static defaultProps = {
    selectedRec: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleRevert = () => {
    const { recHandlers } = this.props;
    recHandlers.recRevertHandler();
  };

  toggle = () => {
    this.setState((state) => ({
      open: !state.open,
    }));
  };

  closeMenu = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { selectedRec } = this.props;
    if (selectedRec.length === 0) {
      return null;
    }
    return (
      <ClickAwayListener onClickAway={this.closeMenu}>
        <div
          className="d-flex date-picker-container position-relative"
          style={{ width: '180px', flexWrap: 'nowrap' }}
          onClick={this.toggle}
        >
          <Col xl="auto" lg="auto" sm="auto" xs="auto">
            <div style={{ display: 'flex', width: '80%' }}>
              <h5 style={{ whiteSpace: 'nowrap' }}>Batch Operation</h5>
            </div>
          </Col>
          <Col style={{ padding: '1px 0px' }} xs={2} md={2} lg={2} xl={2}>
            <LinkIconButton className="topbar__avatar">
              <ChevronDown size={22} />
            </LinkIconButton>
          </Col>
          <Collapse isOpen={this.state.open} className="billing-topbar__menu-wrap">
            <div className="drop__menu">
              <div className="collapse-item" style={{ display: 'flex' }} onClick={() => this.handleRevert()}>
                <p>Revert</p>
              </div>
            </div>
          </Collapse>
        </div>
      </ClickAwayListener>
    );
  }
}

export default RecommendationDropdown;
