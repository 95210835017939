/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import Panel from 'shared/components/Panel';

export default function AzureDataFactory() {
  return (
    <Container>
      <PageHeader title={PageNames.AzureDataFactory} showDate />
      <Panel divider title="Hybrid data integration service that simplifies ETL at scale">
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Improve productivity with shorter time to market</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Reduce overhead costs</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Transfer data using prebuilt connectors</span>
          </h5>
        </p>
        <p style={{ padding: '10px 0px', margin: '0px' }}>
          <h5 className="inner-wizard-input-legend">
            <span className="lnr lnr-checkmark-circle" style={{ color: '#4E98CB' }} />
            <span style={{ marginLeft: '10px' }}>Integrate data cost-effectively</span>
          </h5>
        </p>
      </Panel>
    </Container>
  );
}
