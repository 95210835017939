import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { RoleUsersType } from 'users/constants/usersConstants';
import { ROLE_TYPES } from 'users/containers/RolesAndUsers/constants/rolesAndUsersConstants';
import { ReactComponent as AdminIcon } from '../assets/admin.svg';
import { ReactComponent as EditorIcon } from '../assets/editor.svg';
import { ReactComponent as ViewerIcon } from '../assets/viewer.svg';

const PermissionIcon = ({ role, isReadOnlyUser }) => {
  if (role.roleType === ROLE_TYPES.DEFAULT_ADMIN) {
    return (
      <Tooltip title="Admin">
        <AdminIcon />
      </Tooltip>
    );
  }
  if ((isReadOnlyUser === null && +role.roleUserType === RoleUsersType.EDITOR) || isReadOnlyUser === false) {
    return (
      <Tooltip title="Editor">
        <EditorIcon />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Viewer">
      <ViewerIcon />
    </Tooltip>
  );
};

PermissionIcon.propTypes = {
  role: PropTypes.object.isRequired,
  isReadOnlyUser: PropTypes.bool,
};

PermissionIcon.defaultProps = {
  isReadOnlyUser: null,
};

export default PermissionIcon;
