import React from 'react';
import PropTypes from 'prop-types';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import TabsSelector from 'shared/components/andtComponents/TabsSelector/TabsSelector';
import RoleUsersTab from './RoleUsersTab';
import RoleSubRolesTab from './RoleSubRolesTab';
import EffectivePermissionsTab from './EffectivePermissionsTab';
import DataAccessTab from './DataAccess/DataAccessTab';

export const ROLE_ROW_TABS = {
  USERS: {
    iconName: ICONS.mainUserRegular.name,
    label: 'Users',
    id: 'users',
    isSelected: true,
    getComponent: (row) => <RoleUsersTab row={row} />,
  },
  ROLES: {
    iconName: ICONS.userGear.name,
    label: 'Roles',
    id: 'roles',
    getComponent: (row) => <RoleSubRolesTab row={row} />,
  },
  EFFECTIVE_PERMISSIONS: {
    iconName: ICONS.clipboard.name,
    label: 'Effective Permissions',
    id: 'effectivePermissions',
    getComponent: (row) => <EffectivePermissionsTab row={row} />,
  },
  DATA_ACCESS: {
    iconName: ICONS.cloud.name,
    label: 'Data Access',
    id: 'dataAccess',
    getComponent: (row) => <DataAccessTab row={row} />,
  },
};

const RoleExpandedData = ({ row }) => <TabsSelector tabsInfo={Object.values(ROLE_ROW_TABS)} row={row} />;

RoleExpandedData.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RoleExpandedData;
