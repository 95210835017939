/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens,arrow-body-style,max-len, comma-dangle, function-paren-newline */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { percentStr, strGbToSize } from 'shared/utils/strUtil';
import { mapAzureRegionToDisplay } from 'shared/constants/azureConstants';
import { timeDurationToSeconds } from 'shared/utils/dateUtil';

const createCol = (colName, colTitle, getCellValueFunc = (row) => row[colName]) => {
  return {
    name: colName,
    title: colTitle,
    getCellValue: getCellValueFunc,
  };
};

// const ACTION_COL = createCol('action', 'Action');
const AGE_COL = createCol('age', 'Age (days)');
const ACTION_COL = -1; // removed from table PIL-420
const ENVIRONMENT_COL = createCol('environment', 'Environment');
const PROJECT_COL = createCol('project', 'Project');
const REGION_COL = createCol('region', 'Region');
const AZURE_REGION_COL = createCol('region', 'Region', (row) => mapAzureRegionToDisplay.get(row.region));
const MODEL_COL = createCol('instance', 'Model');
const SAVING_AMOUNT_COL = createCol(
  'savingAmount',
  'Saving Amount',
  (row) => parseFloat(row.currTotalCost) - parseFloat(row.recommTotalCost),
);
const AZURE_RI_SAVING_AMOUNT_COL = createCol('savingAmount', 'Saving Amount');

// const SAVING_AMOUNT_COL = createCol('savingAmount', 'Saving Amount', row =>
//   costStr(parseFloat(row.currTotalCost) - parseFloat(row.recommTotalCost)),
// );
const AZURE_SUBSCRIPTION_NAME_COL = createCol(
  'linkedAccountId',
  'Subscription Name',
  (row) => row.linkedAccountName || row.subscriptionId || row.linkedAccountId,
);
const AZURE_RESOURCE_GROUP_COL = createCol('resourceGroup', 'Resource Group');
const RI_SAVING_AMOUNT_COL = createCol(
  'savingAmount',
  'Saving Amount',
  (row) => parseFloat(row.currTotalCost) - parseFloat(row.recommTotalCost),
);
const KEY_COL = createCol('key', 'Id');
const USER_ACTION_COL = createCol('userAction', 'Options');
const OS_COL = createCol('os', 'OS');
const GCP_PROJECT_COL = createCol('projectName', 'Project');
const BUCKET_SIZE_COL = createCol('totalSizeGB', 'Bucket Size');

const EC2_RI_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('numOfRecommRIs', 'Quantity'),
  createCol('riType', 'Type'),
  MODEL_COL,
  OS_COL,
  REGION_COL,
  RI_SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_SPOT_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  MODEL_COL,
  createCol('interruptionFreq', 'Interruption Frequency'),
  REGION_COL,
  OS_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_VU_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('resourcesQuantity', 'Quantity'),
  REGION_COL,
  MODEL_COL,
  createCol('recommInstance', 'Suggested Model', (row) => row.recommEc2Instance.instance),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

// EC2 Region migration headings
// const EC2_REGION_TABLE_COLUMNS = [
//   ACTION_COL,
//   MODEL_COL,
//   { name: 'resourceId', title: 'Resource ID', getCellValue: (row) => row.resourceId },
//   {
//     name: 'service',
//     title: 'Service',
//     getCellValue: (row) => row.service,
//   },
//   REGION_COL,
//   { name: 'recommRegion', title: 'Suggested Region', getCellValue: (row) => row.recommRegion },
//   SAVING_AMOUNT_COL,
//   USER_ACTION_COL,
// ];

// Operating system
const EC2_OS_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  MODEL_COL,
  REGION_COL,
  OS_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_IP_UNATTACHED_TABLE_COLUMNS = [
  ACTION_COL,
  createCol('publicIp', 'Public IP'),
  AGE_COL,
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_IDLE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  // createCol('resourceName', 'Resource Name'),
  // createCol('resourceId', 'Resource ID'),
  createCol('numOfDays', 'Num Of Days'),
  MODEL_COL,
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_EBS_UNATTACHED_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('ebsType', 'EBS Type'),
  createCol('ebsSize', 'EBS Size', (row) => strGbToSize(row.ebsSize)),
  createCol('detachedEbsId', 'Detached EBS Id'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_IDLE_LOAD_BALANCER_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('loadBalancerName', 'Load Balancer Name'),
  createCol('loadBalancerType', 'Load Balancer Type'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const EC2_REGION_MIGRATION_TABLE_COLUMNS = [ACTION_COL, AGE_COL, REGION_COL, SAVING_AMOUNT_COL, USER_ACTION_COL];
const EC2_LOW_CPU_USAGE_TABLE_COLUMNS = [
  ACTION_COL,
  REGION_COL,
  SAVING_AMOUNT_COL,
  AGE_COL,
  MODEL_COL,
  createCol('recommInstance', 'Recommended Model', (row) => row.recommEc2Instance.instance),
  createCol('memAverageUtil', 'Memory Average Utilization', (row) => row.memUsedPercentStatisticsMonthlyAvgUsage),
  createCol('cpuAverageUtil', 'Cpu Average Utilization', (row) => row.cpuUtilStatisticsMonthlyAvgUsage),
  // createCol('cpuUtil', 'CPU Utilization', row => (row.cpuUtil ? percentStr(row.cpuUtil) : 'Not Available')),
  KEY_COL,
  USER_ACTION_COL,
];
const DDB_IDLE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('tableName', 'Table Name'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  USER_ACTION_COL,
];
const RDS_RI_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('numOfRecommRIs', 'Quantity'),
  createCol('riType', 'Type'),
  MODEL_COL,
  REGION_COL,
  RI_SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const ELASTICACHE_RI_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('numOfRecommRIs', 'Quantity'),
  createCol('riType', 'Type'),
  REGION_COL,
  RI_SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const OPEN_SEARCH_RI_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('numOfRecommRIs', 'Quantity'),
  createCol('riType', 'Type'),
  REGION_COL,
  RI_SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const RDS_IDLE_TABLE_COLUMNS = [ACTION_COL, AGE_COL, MODEL_COL, REGION_COL, SAVING_AMOUNT_COL, USER_ACTION_COL];

const RDS_VERSION_UPGRADE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('resourcesQuantity', 'Quantity'),
  REGION_COL,
  MODEL_COL,
  createCol('recommInstance', 'Suggested Model', (row) => row.recommEc2Instance.instance),
  createCol('dbType', 'DB Type'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const RDS_CLASS_CHANGE_TABLE_COLUMNS = [
  SAVING_AMOUNT_COL,
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('instanceType', 'Model'),
  createCol('recommendedInstanceType', 'Recommended Model'),
  createCol('cpuMax', 'Max CPU Utilization (%)'),
  createCol('cpuP95', '95PCT CPU Utilization'),
  createCol('memP95', '95PCT Mem Utilization'),
  createCol('memMax', 'Max Memory'),
  createCol('maxIops', 'Max IOPS'),
  createCol('storage', 'Storage'),

  USER_ACTION_COL,
];

const RDS_IOPS_CHANGE_TABLE_COLUMNS = [
  SAVING_AMOUNT_COL,
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('instanceType', 'Model'),
  createCol('recommendedIops', 'Recommended Iops'),
  createCol('provisionedIops', 'Max Provisioned IOPS Utilization'),
  createCol('maxIops', 'Max IOPS'),
  USER_ACTION_COL,
];

const S3_MULTIPART_UPLOAD_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('bucketName', 'Bucket Name'),
  createCol('totalUploadSize', 'Total Upload Size'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const S3_IDLE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('bucketName', 'Bucket'),
  createCol('numOfDays', 'Num Of Idle Days'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EBS_TYPE_CHANGE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('ebsType', 'EBS Type'),
  createCol('newEbsType', 'New EBS Type'),
  createCol('ebsSize', 'EBS Size', (row) => strGbToSize(row.ebsSize)),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const EBS_TYPE_AND_SIZE_CHANGE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('ebsType', 'EBS Type'),
  createCol('ebsSize', 'EBS Size', (row) => strGbToSize(row.ebsSize)),
  createCol('newEbsType', 'New EBS Type'),
  createCol('gp2Size', 'New Size', (row) => strGbToSize(row.gp2Size)),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const RDS_TYPE_CHANGE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('currentStorageType', 'Current Storage Type'),
  createCol('recommendedStorageType', 'Recommended Storage Type'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_SCHEDULE_TABLE_COLUMNS = [ACTION_COL, AGE_COL, MODEL_COL, REGION_COL, SAVING_AMOUNT_COL, USER_ACTION_COL];

const RECOMM_HOURLY_COMMITMENT = {
  name: 'recommendedHourlyCommitment',
  title: 'Recommended Hourly Commitment',
};

const EC2_SAVINGS_PLANS_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('savingsPlanType', 'Savings Plan Type'),
  createCol('paymentOption', 'Payment Option'),
  createCol('term', 'Term'),
  RECOMM_HOURLY_COMMITMENT,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EBS_OUTDATED_SNAPSHOT_TABLE_COLUMNS_SORTING = [
  {
    columnName: 'elapsedTimeSinceCreation',
    compare: (a, b) => {
      const aDuration = timeDurationToSeconds(a);
      const bDuration = timeDurationToSeconds(b);
      if (aDuration === null || bDuration === null) {
        return 0;
      }
      return timeDurationToSeconds(a) - timeDurationToSeconds(b);
    },
  },
];
const EBS_OUTDATED_SNAPSHOT_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  // createCol('ebsType', 'EBS Type'),
  // createCol('newEbsType', 'New EBS Type'),
  SAVING_AMOUNT_COL,
  createCol('elapsedTimeSinceCreation', 'Snapshot Age'),
  KEY_COL,
  USER_ACTION_COL,
];

const EBS_UPGRADE_TABLE_COLUMNS = [
  REGION_COL,
  createCol('ebsType', 'EBS Type'),
  createCol('newEbsType', 'New EBS Type'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_UNNECESSARY_DATA_TRANSFER_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('availabilityZone', 'Availability Zone'),
  createCol('dtType', 'Data Transfer Type'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const EC2_STOPPED_INSTANCE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  // createCol('resourceName', 'Resource Name'),
  // createCol('resourceId', 'Resource ID'),
  createCol('instance', 'Model'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const REDSHIFT_LOW_UTIL_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('dbName', 'DB Name'),
  createCol('nodeType', 'Node Type'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const CLOUDTRAIL_DUPLICATE_TRAILS_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const ELASTICSEARCH_LOW_UTIL_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('domainName', 'Domain Name'),
  createCol('instanceType', 'Instance Type'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const ELASTICACHE_LOW_UTIL_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('engine', 'Engine'),
  createCol('maxConnections', 'Max Connections'),
  createCol('nodeType', 'Node Type'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const NAT_GATEWAY_LOW_UTIL_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('maxActiveConnections', 'Max Active Connections'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const DOCUMENT_DB_LOW_UTIL_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  // createCol('arn', 'Arn'),
  createCol('engine', 'Engine'),
  createCol('maxConnections', 'Max Connections'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const NEPTUNE_LOW_UTIL_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  // createCol('arn', 'Arn'),
  createCol('engine', 'Engine'),
  createCol('primaryDbInstanceId', 'DB Instance Id'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const KINESIS_LOW_UTIL_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('streamName', 'Stream Name'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

// AZURE ***************************************

const AZURE_DB_RI_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('tableName', 'Table Name'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  USER_ACTION_COL,
];
const AZURE_VM_STOPPED_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('resourceName', 'Resource Name'),
  createCol('resourceId', 'Resource Id'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const AZURE_DB_IDLE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('dbName', 'Database Name'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const AZURE_VM_RIGHT_SIZING_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('recommInstance', 'Model', (row) => row.currentVersion),
  createCol('currentModel', 'Current Model RI Coverage', (row) => row.isCurrentReserved),
  createCol('targetModel', 'Target Model RI Coverage', (row) => row.isRecommendedReserved),
  createCol('os', 'OS', (row) => row.os),
  createCol('instance', 'Recommended Model', (row) => row.recommVersion),
  createCol('cpuPercentile', '95P CPU Utilization', (row) => percentStr(row.cpuPercentile)),
  createCol('memUsedAvg', 'Avg Used Memory', (row) => row.memUsedAvgNumber),
  createCol('memAvailableAvg', 'Avg Available Memory', (row) => row.memAvailableAvgNumber),
  createCol('memSizeGb', 'Memory GB Size', (row) => row.memSizeGb),
  createCol('memUtilizationPercentage', 'Memory Utilization', (row) => row.memUtilizationPercentageNumber),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const AZURE_STORAGE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('tableName', 'Table Name'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  USER_ACTION_COL,
];
const AZURE_COSMOS_RIGHT_SIZING_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  MODEL_COL,
  createCol('recommInstance', 'Recommended Model', (row) => row.recommEc2Instance.instance),
  createCol('cpuUtil', 'Max CPU Utilization', (row) => percentStr(row.cpuUtil)),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const AZURE_RI_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('quantity', 'Quantity'),
  createCol('riType', 'Type'),
  createCol('skuName', 'SKU Name'),
  REGION_COL,
  // createCol('linkedAccountId', 'Subscription Id'),
  RI_SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const AZURE_DISK_UNATTACHED_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('diskSkuName', 'SKU Name'),
  createCol('unattachedDiskId', 'Detached Disk Id'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const AZURE_VM_IDLE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  // createCol('resourceName', 'Resource Name'),
  // createCol('resourceId', '§'),
  createCol('numOfDays', 'Num Of Days'),
  createCol('model', 'Model'),
  // MODEL_COL,
  // REGION_COL,
  SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const AZURE_IP_UNATTACHED_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('publicIp', 'Public IP'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const AZURE_IDLE_LOAD_BALANCER_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('loadBalancerName', 'Load Balancer Name'),
  createCol('loadBalancerType', 'Load Balancer Type'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const AZURE_DISK_TYPE_CHANGE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  createCol('diskType', 'Disk Type'),
  createCol('newDiskType', 'New Disk Type'),
  SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const AZURE_RESERVED_CAPACITY_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('service', 'Service'),
  REGION_COL,
  AZURE_RI_SAVING_AMOUNT_COL,
  // AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const AZURE_SNAPSHOT_MIGRATION_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('service', 'Service'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const AZURE_OUTDATED_SNAPSHOT_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('service', 'Service'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const AZURE_KUSTO_UNUSED_DATA_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('service', 'Service'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const AZURE_APP_SERVICE_RESERVED_CAPACITY_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('service', 'Service'),
  REGION_COL,
  AZURE_RI_SAVING_AMOUNT_COL,
  // AZURE_RESOURCE_GROUP_COL,
  createCol('recommendedPlan', 'Recommended Plan'),
  createCol('scope', 'Scope'),
  KEY_COL,
  USER_ACTION_COL,
];

// GCP ***************************************

const GCP_IP_IDLE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('publicIp', 'Public IP'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const GCP_USAGE_COMMITMENT_TABLE_COLUMNS = [
  GCP_PROJECT_COL,
  createCol('recommendedCommitmentTitle', 'Recommended Commitment'),
  createCol('commitmentType', 'Commitment Type'),
  createCol('instanceTypeModel', 'Instance Type Model'),
  ACTION_COL,
  createCol('numOfDays', 'Num Of Days'),
  SAVING_AMOUNT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const GCP_VM_IDLE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  // createCol('resourceName', 'Resource Name'),
  // createCol('resourceId', '§'),
  createCol('numOfDays', 'Num Of Days'),
  createCol('model', 'Model'),
  // MODEL_COL,
  // REGION_COL,
  SAVING_AMOUNT_COL,
  GCP_PROJECT_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const GCP_VM_STOPPED_TABLE_COLUMNS = [ACTION_COL, AGE_COL, REGION_COL, SAVING_AMOUNT_COL, KEY_COL, USER_ACTION_COL];

const GCP_VM_RIGHT_SIZING_TABLE_COLUMNS = [
  // ACTION_COL,
  // createCol('model', 'Model'),
  // SAVING_AMOUNT_COL,
  // GCP_PROJECT_COL,
  // USER_ACTION_COL,
  ACTION_COL,
  AGE_COL,
  createCol('numOfDays', 'Num Of Days'),
  createCol('instanceType', 'Instance Type'),
  createCol('recommendedInstanceType', 'Recommended Instance Type'),
  SAVING_AMOUNT_COL,
  GCP_PROJECT_COL,
  KEY_COL,
  USER_ACTION_COL,
];
const GCP_DISK_UNATTACHED_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  createCol('numOfDays', 'Num Of Days'),
  REGION_COL,
  SAVING_AMOUNT_COL,
  // AZURE_RESOURCE_GROUP_COL,
  KEY_COL,
  USER_ACTION_COL,
];

const KMS_OLD_TABLE_COLUMNS = [ACTION_COL, AGE_COL, REGION_COL, SAVING_AMOUNT_COL, USER_ACTION_COL];

const KMS_IDLE_TABLE_COLUMNS = [ACTION_COL, AGE_COL, REGION_COL, SAVING_AMOUNT_COL, USER_ACTION_COL];

const S3_STORAGE_CLASS_TABLE_COLUMNS = [
  SAVING_AMOUNT_COL,
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  BUCKET_SIZE_COL,
  USER_ACTION_COL,
];

const S3_VERSIONING_TABLE_COLUMNS = [
  SAVING_AMOUNT_COL,
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  BUCKET_SIZE_COL,
  USER_ACTION_COL,
];

const ECS_FARGATE_TABLE_COLUMNS = [
  ACTION_COL,
  AGE_COL,
  REGION_COL,
  SAVING_AMOUNT_COL,
  createCol('currentMemory', 'Current Memory'),
  createCol('recommendedMemory', 'Recommended Memory'),
  createCol('memoryChange', 'Memory change (%)'),
  createCol('currentCpu', 'Current CPU'),
  createCol('recommendedCpu', 'Recommended CPU'),
  createCol('cpuChange', 'CPU change (%)'),
  USER_ACTION_COL,
];

const IP_PUBLIC_IPV4_TABLE_COLUMNS = [
  REGION_COL,
  AGE_COL,
  { ...SAVING_AMOUNT_COL, showSavingTooltip: true },
  USER_ACTION_COL,
];

export const mapRecommendationsTypeToTableColumns = new Map([
  [RecommendationTypes.IP_PUBLIC_IPV4, IP_PUBLIC_IPV4_TABLE_COLUMNS],
  [RecommendationTypes.ECS_FARGATE, ECS_FARGATE_TABLE_COLUMNS],
  [RecommendationTypes.KMS_IDLE, KMS_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.KMS_OLD, KMS_OLD_TABLE_COLUMNS],
  ['All', []],
  [RecommendationTypes.EC2_RI, EC2_RI_TABLE_COLUMNS],
  [RecommendationTypes.EC2_VERSION_UPGRADE, EC2_VU_TABLE_COLUMNS],
  [RecommendationTypes.EC2_OPERATION_SYS, EC2_OS_TABLE_COLUMNS],
  [RecommendationTypes.EC2_IP_UNATTACHED, EC2_IP_UNATTACHED_TABLE_COLUMNS],
  [RecommendationTypes.EC2_IDLE, EC2_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.EC2_EBS_UNATTACHED, EC2_EBS_UNATTACHED_TABLE_COLUMNS],
  [RecommendationTypes.EC2_IDLE_LOAD_BALANCER, EC2_IDLE_LOAD_BALANCER_TABLE_COLUMNS],
  [RecommendationTypes.EC2_REGION_MIGRATION, EC2_REGION_MIGRATION_TABLE_COLUMNS],
  [RecommendationTypes.EC2_LOW_CPU_USAGE, EC2_LOW_CPU_USAGE_TABLE_COLUMNS],
  [RecommendationTypes.DDB_IDLE, DDB_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.RDS_RI, RDS_RI_TABLE_COLUMNS],
  [RecommendationTypes.ELASTICACHE_RI, ELASTICACHE_RI_TABLE_COLUMNS],
  [RecommendationTypes.OPEN_SEARCH_RI, OPEN_SEARCH_RI_TABLE_COLUMNS],
  [RecommendationTypes.RDS_IDLE, RDS_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.RDS_VERSION_UPGRADE, RDS_VERSION_UPGRADE_TABLE_COLUMNS],
  [RecommendationTypes.RDS_TYPE_CHANGE, RDS_TYPE_CHANGE_TABLE_COLUMNS],
  [RecommendationTypes.RDS_CLASS_CHANGE, RDS_CLASS_CHANGE_TABLE_COLUMNS],
  [RecommendationTypes.RDS_IOPS_CHANGE, RDS_IOPS_CHANGE_TABLE_COLUMNS],
  [RecommendationTypes.S3_IDLE, S3_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.S3_VERSIONING, S3_VERSIONING_TABLE_COLUMNS],
  [RecommendationTypes.S3_STORAGE_CLASS, S3_STORAGE_CLASS_TABLE_COLUMNS],
  [RecommendationTypes.S3_MULTIPART_UPLOAD, S3_MULTIPART_UPLOAD_TABLE_COLUMNS],
  [RecommendationTypes.EBS_TYPE_CHANGE, EBS_TYPE_CHANGE_TABLE_COLUMNS],
  [RecommendationTypes.EBS_TYPE_SIZE_CHANGE, EBS_TYPE_AND_SIZE_CHANGE_TABLE_COLUMNS],
  [RecommendationTypes.EC2_SCHEDULE, EC2_SCHEDULE_TABLE_COLUMNS],
  [RecommendationTypes.EC2_SPOT, EC2_SPOT_TABLE_COLUMNS],
  [RecommendationTypes.EC2_SAVINGS_PLANS, EC2_SAVINGS_PLANS_TABLE_COLUMNS],
  [RecommendationTypes.EBS_OUTDATED_SNAPSHOT, EBS_OUTDATED_SNAPSHOT_TABLE_COLUMNS],
  [RecommendationTypes.EBS_UPGRADE, EBS_UPGRADE_TABLE_COLUMNS],
  [RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER, EC2_UNNECESSARY_DATA_TRANSFER_TABLE_COLUMNS],
  [RecommendationTypes.EC2_STOPPED_INSTANCE, EC2_STOPPED_INSTANCE_TABLE_COLUMNS],
  [RecommendationTypes.REDSHIFT_LOW_UTILIZATION, REDSHIFT_LOW_UTIL_TABLE_COLUMNS],
  [RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION, ELASTICSEARCH_LOW_UTIL_TABLE_COLUMNS],
  [RecommendationTypes.ELASTICACHE_LOW_UTILIZATION, ELASTICACHE_LOW_UTIL_TABLE_COLUMNS],
  [RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION, NAT_GATEWAY_LOW_UTIL_TABLE_COLUMNS],
  [RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION, DOCUMENT_DB_LOW_UTIL_TABLE_COLUMNS],
  [RecommendationTypes.NEPTUNE_LOW_UTILIZATION, NEPTUNE_LOW_UTIL_TABLE_COLUMNS],
  [RecommendationTypes.KINESIS_LOW_UTILIZATION, KINESIS_LOW_UTIL_TABLE_COLUMNS],
  [RecommendationTypes.CLOUDTRAIL_DUPLICATES_TRAILS, CLOUDTRAIL_DUPLICATE_TRAILS_TABLE_COLUMNS],

  // Azure ***********************************
  [RecommendationTypes.AZURE_VM_RI, AZURE_RI_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_IP_UNATTACHED, AZURE_IP_UNATTACHED_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_DISK_UNATTACHED, AZURE_DISK_UNATTACHED_TABLE_COLUMNS],
  // [RecommendationTypes.AZURE_VM_IDLE, AZURE_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_VM_IDLE, AZURE_VM_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_IDLE_LOAD_BALANCER, AZURE_IDLE_LOAD_BALANCER_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_APP_RIGHT_SIZING, []],
  [RecommendationTypes.AZURE_DB_RI, AZURE_DB_RI_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_MYSQL_DB_IDLE, AZURE_DB_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_MARIA_DB_IDLE, AZURE_DB_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_COSMOS_DB_IDLE, AZURE_DB_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_POSTGRESQL_DB_IDLE, AZURE_DB_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_SQL_DB_IDLE, AZURE_DB_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_VM_STOPPED, AZURE_VM_STOPPED_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_VM_RIGHT_SIZING, AZURE_VM_RIGHT_SIZING_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_STORAGE, AZURE_STORAGE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_COSMOS_RIGHT_SIZING, AZURE_COSMOS_RIGHT_SIZING_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_DISK_TYPE_CHANGE, AZURE_DISK_TYPE_CHANGE_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_COSMOS, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_DATA_EXPLORER, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_MY_SQL, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_PG, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_REDIS, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_RESERVED_CAPACITY_SQL_DATA_WH, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_SNAPSHOT_MIGRATION, AZURE_SNAPSHOT_MIGRATION_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_OUTDATED_SNAPSHOT, AZURE_OUTDATED_SNAPSHOT_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_KUSTO_UNUSED_DATA, AZURE_KUSTO_UNUSED_DATA_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_APP_SERVICE_RESERVED_CAPACITY, AZURE_APP_SERVICE_RESERVED_CAPACITY_TABLE_COLUMNS],
  [RecommendationTypes.AZURE_DB_RESERVED_CAPACITY, AZURE_RESERVED_CAPACITY_TABLE_COLUMNS],

  // GCP ***********************************
  [RecommendationTypes.GCP_IP_IDLE, GCP_IP_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.GCP_VM_IDLE, GCP_VM_IDLE_TABLE_COLUMNS],
  [RecommendationTypes.GCP_USAGE_COMMITMENT, GCP_USAGE_COMMITMENT_TABLE_COLUMNS],
  [RecommendationTypes.GCP_VM_STOPPED, GCP_VM_STOPPED_TABLE_COLUMNS],
  [RecommendationTypes.GCP_VM_RIGHT_SIZING, GCP_VM_RIGHT_SIZING_TABLE_COLUMNS],
  [RecommendationTypes.GCP_DISK_UNATTACHED, GCP_DISK_UNATTACHED_TABLE_COLUMNS],
]);

export const mapRecommendationsTypeToTableColumnsSorting = new Map([
  [RecommendationTypes.EBS_OUTDATED_SNAPSHOT, EBS_OUTDATED_SNAPSHOT_TABLE_COLUMNS_SORTING],
]);

export const mapRecommendationTypeToColumnsWidthArray = new Map([
  [
    RecommendationTypes.EC2_RI,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'numOfRecommRIs', width: 150 },
      { columnName: 'userAction', align: 'right', width: 90 },
      { columnName: 'action', align: 'right', width: 150 },
      { columnName: 'riType', width: 250 },
    ],
  ],
  [
    RecommendationTypes.EC2_IDLE,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'action', align: 'right', width: 150 },
      { columnName: 'userAction', align: 'right', width: 90 },
      { columnName: 'resourceName', width: 250 },
      { columnName: 'numOfDays', width: 150 },
    ],
  ],
  [
    RecommendationTypes.EC2_VERSION_UPGRADE,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_OPERATION_SYS,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_IP_UNATTACHED,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_EBS_UNATTACHED,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_IDLE_LOAD_BALANCER,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_REGION_MIGRATION,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_LOW_CPU_USAGE,
    [
      {
        columnName: 'maxMemory',
        align: 'center',
        width: 300,
      },
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      { columnName: 'memUsedPercentStatisticsMonthlyAvgUsage', align: 'left', width: 250 },
      { columnName: 'cpuUtilStatisticsMonthlyAvgUsage', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.DDB_IDLE,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.RDS_RI,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.RDS_VERSION_UPGRADE,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.S3_VERSIONING,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.S3_MULTIPART_UPLOAD,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.S3_IDLE,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EBS_TYPE_CHANGE,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EBS_UPGRADE,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_SCHEDULE,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_SPOT,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.RDS_IDLE,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.RDS_CLASS_CHANGE,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.RDS_IOPS_CHANGE,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      {
        columnName: 'resource',
        align: 'left',
        width: 250,
      },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EBS_OUTDATED_SNAPSHOT,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_STOPPED_INSTANCE,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.EC2_SAVINGS_PLANS,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.REDSHIFT_LOW_UTILIZATION,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.ELASTICACHE_LOW_UTILIZATION,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.NAT_GATEWAY_LOW_UTILIZATION,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.DOCUMENT_DB_LOW_UTILIZATION,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [
    RecommendationTypes.NEPTUNE_LOW_UTILIZATION,
    [
      {
        columnName: 'linkedAccount',
        align: 'left',
        width: 250,
      },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  // Azure *********************************************

  [
    RecommendationTypes.AZURE_VM_RI,
    [
      { columnName: 'action', align: 'right', width: 150 },
      { columnName: 'userAction', align: 'right', width: 90 },
      { columnName: 'quantity', width: 150 },
      { columnName: 'riType', width: 250 },
    ],
  ],
  [RecommendationTypes.AZURE_IP_UNATTACHED, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [RecommendationTypes.AZURE_DISK_UNATTACHED, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [
    RecommendationTypes.AZURE_VM_IDLE,
    [
      { columnName: 'action', align: 'right', width: 150 },
      // { columnName: 'resourceName', width: 260 },
      { columnName: 'numOfDays', width: 150 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
  [RecommendationTypes.AZURE_IDLE_LOAD_BALANCER, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [RecommendationTypes.AZURE_APP_RIGHT_SIZING, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [RecommendationTypes.AZURE_DB_RI, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [RecommendationTypes.AZURE_VM_RIGHT_SIZING, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [RecommendationTypes.AZURE_STORAGE, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [RecommendationTypes.AZURE_COSMOS_RIGHT_SIZING, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [RecommendationTypes.AZURE_DISK_TYPE_CHANGE, [{ columnName: 'userAction', align: 'right', width: 90 }]],
  [
    RecommendationTypes.GCP_VM_STOPPED,
    [
      { columnName: 'linkedAccount', align: 'left', width: 250 },
      { columnName: 'resource', align: 'left', width: 250 },
      { columnName: 'userAction', align: 'right', width: 90 },
    ],
  ],
]);
