/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */

import { descendingNumSortComparatorFunc } from 'shared/utils/sortUtil';
import { RecommFieldsAndFiltersValues } from '../constants/recommendationsConstants';

class RecsStatsCenter {
  static getNumOfCompletedRecs = (recs) => RecsStatsCenter.getFilteredCompletedRecs(recs, true).length;

  static getNumOfUncompletedRecs = (recs) => RecsStatsCenter.getFilteredCompletedRecs(recs, false).length;

  static calcActualCost = (recommendations, divisionFactor = 1) => {
    const actualCost =
      recommendations.reduce((accumulator, currRec) => accumulator + currRec.currTotalCost, 0) / divisionFactor;

    return actualCost;
  };

  static sortRecommendations = (recommendations) =>
    recommendations?.sort((curr, prev) =>
      descendingNumSortComparatorFunc(
        curr.currTotalCost - curr.recommTotalCost,
        prev.currTotalCost - prev.recommTotalCost,
      ),
    );

  static calcPotentialCost = (recommendations, divisionFactor = 1) => {
    const potentialCost =
      recommendations.reduce((accumulator, currRec) => accumulator + currRec.recommTotalCost, 0) / divisionFactor;

    return potentialCost;
  };

  static getFilteredCompletedRecs = (recs, isCompleted) =>
    recs.filter((rec) =>
      isCompleted
        ? rec.status === RecommFieldsAndFiltersValues.COMPLETED
        : rec.status === RecommFieldsAndFiltersValues.OPEN ||
          rec.status === RecommFieldsAndFiltersValues.DISMISSED_BY_USER,
    );

  static getDistinctTypes = (recs) => {
    const types = recs.map((rec) => rec.type);
    const arrDistinctTypes = Array.from(new Set(types));
    return arrDistinctTypes;
  };
  static getDistinctLinkedAccIds = (recs) => {
    const linkedAccountIds = recs.map((rec) => rec.linkedAccountId).filter((id) => !!id);
    const arrLinkedAccountIds = Array.from(new Set(linkedAccountIds));
    return arrLinkedAccountIds;
  };

  static getFilteredRecsByStatus = (recs, status) => recs.filter((rec) => rec.status === status);

  static getFilteredRecsByType = (recs, type) => recs.filter((rec) => rec.type === type);
  static isValuesinCriteria = (criteria = [], values = []) => {
    try {
      const arrCriterias = Array.isArray(criteria) ? [...criteria] : [criteria];
      const arrValues = Array.isArray(values) ? [...values] : [values];
      const result = arrCriterias.some((crt) => arrValues.includes(crt));
      return result;
    } catch (error) {
      return false;
    }
  };
  static getFilteredRecsByCriteriaObject = (criteriaAndValuesObj, allRecs, enrichmentFileData) => {
    let filteredRecs = [...allRecs];
    try {
      const criteriasKeys = Object.keys(criteriaAndValuesObj);
      filteredRecs = allRecs.filter((rec) => {
        let testResult = true;
        criteriasKeys.forEach((key) => {
          if (key === 'type' && criteriaAndValuesObj[key].includes('All')) {
            testResult = testResult && true;
            return;
          }
          if (key === 'resourceTags' && !!rec.rawResourceTags) {
            const filterTags = criteriaAndValuesObj[key];
            const recommTags = rec.rawResourceTags;
            if (!recommTags) {
              testResult = false;
              return;
            }
            const tagMatches = (Array.isArray(filterTags) ? filterTags : [filterTags]).some((filterTag) => {
              const [tagKey, tagValue] = filterTag.split(': ');
              if (Object.keys(recommTags).includes(tagKey)) {
                return recommTags[tagKey] === tagValue;
              }
              return Object.keys(recommTags).some((resourceKey) => recommTags[resourceKey][tagKey] === tagValue);
            });
            testResult = testResult && tagMatches;
            return;
          }
          if (key === 'accountTags' && !!rec.linkedAccountId) {
            const filterTags = criteriaAndValuesObj[key];
            const enrichmentObject = enrichmentFileData.get(rec.linkedAccountId);
            const tagMatches = (Array.isArray(filterTags) ? filterTags : [filterTags]).some((filterTag) => {
              const [tagKey, tagValue] = filterTag.split(': ');
              return enrichmentObject && enrichmentObject[tagKey] === tagValue;
            });
            testResult = testResult && tagMatches;
            return;
          }
          testResult = testResult && RecsStatsCenter.isValuesinCriteria(criteriaAndValuesObj[key], rec[key]);
        });
        return testResult;
      });
    } catch (error) {
      return [...allRecs];
    }
    return filteredRecs;
  };
  static getFilteredRecsByTypeAndLinkedAcc = (recs, type, linkedAccountId) => {
    if (linkedAccountId === 'All') {
      return recs.filter((rec) => rec.type === type);
    }
    if (type === 'All') {
      return recs.filter((rec) => rec.linkedAccountId === linkedAccountId);
    }
    return recs.filter((rec) => rec.type === type && rec.linkedAccountId === linkedAccountId);
  };
  // static getFilteredRecsByTypeAndLinkedAccIds = (recs, type, linkedAccountIds) => {
  //   if (type === 'All') {
  //     return recs.filter((rec) => linkedAccountIds.includes(rec.linkedAccountId));
  //   }
  //   if (linkedAccountIds.length === 0) {
  //     return recs.filter((rec) => rec.type === type);
  //   }
  //   return recs.filter((rec) => (rec.type === type && linkedAccountIds.includes(rec.linkedAccountId)));
  // }
  static filterRecsByIdsObject = (recs, ids) => {
    if (!ids.length) {
      return [];
    }
    const idsObj = ids.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
    const filteredRecs = [];
    recs.forEach((rec) => {
      if (idsObj[rec.key]) {
        filteredRecs.push(rec);
      }
    });
    return filteredRecs;
  };
  // staticGetFilterRecsByFilterParams = (filterParamObj) => {

  // }
  // eslint-disable-next-line no-unused-vars
  static getFilteredRecsByTypeAndIds = (recs, type, Ids) => {
    if (!type || type === 'All') {
      // if (Ids.length >= recs.length) { // no filters applied
      //   return recs;
      // } else if (!Ids.length) {
      //   return [];
      // }
      return recs;

      // return RecsStatsCenter.filterRecsByIdsObject(recs, Ids);
      // return recs.filter((rec) => Ids.includes(rec.key));
    }
    // if (linkedAccountIds.length === 0) {
    //   return recs.filter((rec) => rec.type === type);
    // }
    const filteredByType = recs.filter((rec) => rec.type === type);
    if (filteredByType.length) {
      return filteredByType;
      // return RecsStatsCenter.filterRecsByIdsObject(filteredByType, Ids);
    }
    return [];
  };
}

export default RecsStatsCenter;
