/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureIpRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureIpCostRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureIpRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_IP_UNATTACHED;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} IP Unattached`;
  }

  build() {
    super.build();
    const {
      zone_tag: tagZone,
      public_ip: publicIp,
      ip_name: ipName,
      ip_version: ipVersion,
      allocation,
      resource_guid: resourceGuid,
      resource_group: resourceGroup,
      resource_id: resourceId,
      // potential_savings: potentialSavings,
      linked_account_id: linkedAccountID,
    } = this._rawRecommendation;

    this._recommendation.region = tagZone;
    this._recommendation.publicIp = publicIp;
    this._recommendation.allocation = allocation;
    this._recommendation.ipName = ipName;
    this._recommendation.ipVersion = ipVersion;
    this._recommendation.resourceGuid = resourceGuid;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.resourceId = resourceId;
    // this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.linkedAccountID = linkedAccountID;
  }
}
