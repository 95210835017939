import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { CostUsageStates, K8S_GROUP_BY_LOV } from 'usage/constants/costAndUsageConstants';
import Panel from 'shared/components/Panel';
import {
  buildStartAndEndDate,
  buildTimeDiffDateFromBaseDate,
  createTimeZoneAgnosticDateFromStr,
} from 'shared/utils/dateUtil';
import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import SingleGroupByButton from 'shared/components/SingleGroupByButton';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import ChartsPanel from 'kubernetes/containers/KubernetesDashboard/components/ChartsPanel/ChartsPanel';
import { useKubernetes } from '../../hooks/useKubernetes';
import {
  COST_BREAKDOWN_TABLE_COLUMNS,
  COST_BREAKDOWN_TABLE_COLUMNS_EXTENSIONS,
  COST_BREAKDOWN_TABLE_COLUMNS_WIDTHS,
  COST_BREAKDOWN_TABLE_COST_COLUMNS,
  mapCostBreakDownGroupByDisplayToValue,
  mapCostBreakDownGroupByValueToDisplay,
} from '../../constants/k8sConstants';

const defaultDates = () => {
  // eslint-disable-next-line prefer-const
  let { startDate, endDate } = buildStartAndEndDate(null, null);
  const fourteenDaysDate = buildTimeDiffDateFromBaseDate(endDate, -14, 'd').startDate;
  startDate = new Date(fourteenDaysDate) > new Date(startDate) ? startDate : fourteenDaysDate;
  const agnosticStartDate = createTimeZoneAgnosticDateFromStr(startDate);
  const agnosticEndDate = createTimeZoneAgnosticDateFromStr(endDate);
  return { startDate, endDate, agnosticStartDate, agnosticEndDate };
};

const K8ScostAndUsagePage = ({
  invoiceStore,
  location = {},
  kubernetesStore,
  usersStore,
  history,
  appStore,
  usageStore,
  isPpApplied,
}) => {
  const [startDate, setStartDate] = useState(defaultDates().agnosticStartDate);
  const [endDate, setEndDate] = useState(defaultDates().agnosticEndDate);
  const [costBreakdownTableGroupBy, setCostBreakdownTableGroupBy] = useState(CostUsageStates.CLUSTER);
  const { fetchCostBreakDownData } = useKubernetes(startDate, endDate, costBreakdownTableGroupBy);
  const { data: costBreakDownData, isLoading: isCostBreakLoading } = fetchCostBreakDownData();

  const handleDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    const start = buildStartAndEndDate(newStartDate, newEndDate).startDate;
    const end = buildStartAndEndDate(newStartDate, newEndDate).endDate;
    setStartDate(start);
    setEndDate(end);
  };

  const renderCostBreakTable = (data) => (
    <>
      <Row style={{ marginBottom: '25px' }}>
        <Col xs="12" md="12" lg="12" xl="12">
          <SingleGroupByButton
            title="Group By: "
            items={[K8S_GROUP_BY_LOV.BY_CLUSTER, K8S_GROUP_BY_LOV.BY_NAMESPACE, K8S_GROUP_BY_LOV.BY_NODE_GROUP]}
            handler={setCostBreakdownTableGroupBy}
            selectedItem={mapCostBreakDownGroupByValueToDisplay.get(costBreakdownTableGroupBy)}
            itemsValuesMap={mapCostBreakDownGroupByDisplayToValue}
            isIconButton
          />
        </Col>
        <Col xs="12" md="12" lg="12" xl="12">
          <DatePickerFilter
            onDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            currPeriodGranLevel="day"
          />
        </Col>
      </Row>
      {data.length > 0 && (
        <Row>
          <Col xs="12" md="12" lg="12" xl="12">
            <DevExpSearchAndVisibilityTable
              data={data}
              columns={COST_BREAKDOWN_TABLE_COLUMNS}
              costColumnsArray={COST_BREAKDOWN_TABLE_COST_COLUMNS}
              currenciesColumns={COST_BREAKDOWN_TABLE_COST_COLUMNS}
              tableColumnExtensions={COST_BREAKDOWN_TABLE_COLUMNS_EXTENSIONS}
              csvTitle="k8s-waste"
              columnWidths={COST_BREAKDOWN_TABLE_COLUMNS_WIDTHS}
              defaultHiddenColumnNames={[]}
              defaultSorting={[]}
              isResize
              isPaging
              isVisibility={false}
            />
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <Container>
      <PageHeader title={PageNames.K8S_USAGE} showDate />
      <ChartsPanel
        usageStore={usageStore}
        location={location}
        appStore={appStore}
        usersStore={usersStore}
        kubernetesStore={kubernetesStore} // todo - remove
        invoiceStore={invoiceStore}
        history={history}
        isPpApplied={isPpApplied}
      />
      <Panel divider title="COST BREAKDOWN" isColPaddingZero>
        {isCostBreakLoading ? (
          <div style={{ height: 400 }}>
            <Spinner />
          </div>
        ) : (
          renderCostBreakTable(costBreakDownData)
        )}
      </Panel>
    </Container>
  );
};

K8ScostAndUsagePage.propTypes = {
  invoiceStore: PropTypes.object.isRequired,
  kubernetesStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  isPpApplied: PropTypes.bool.isRequired,
};

export default observer(K8ScostAndUsagePage);
