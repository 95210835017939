import React from 'react';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { Container } from 'reactstrap';

const Recommendation360 = () => (
  <TableHeaderProvider>
    <Container>
      <PageHeader showDate title={PageNames.RECOMMENDATION_360} />
    </Container>
  </TableHeaderProvider>
);

export default Recommendation360;
