import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'recharts';

const CustomizedAxisTick = (props) => {
  const { x, y, payload, fontSize } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <Text dy={6} fontSize={fontSize} textAnchor="middle" verticalAnchor="start" width={40}>
        {payload.value}
      </Text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
  fontSize: PropTypes.number.isRequired,
};

export default CustomizedAxisTick;
