import React from 'react';
import PropTypes from 'prop-types';
import { RDS_PROPERTIES } from 'recommendationsNew/components/detailedRecommendation/recommendationPropertyUtils';

import BaseRecommendation from '../baseRecommendation';

const ECS_FARGATE_PROPERTIES = {
  AGE: {
    label: 'Age (days)',
    getterFunction: (recommendation) => recommendation.recData?.age,
  },
  CURRENT_CPU: {
    label: 'Current CPU',
    getterFunction: (recommendation) => recommendation.recData?.current_cpu_config,
  },
  RECOMMENDED_CPU: {
    label: 'Recommended CPU',
    getterFunction: (recommendation) => recommendation.recData?.recommended_cpu,
  },
  CPU_CHANGE: {
    label: 'CPU Change (%)',
    isPercent: true,
    getterFunction: (recommendation) =>
      (Math.abs(recommendation.recData?.current_cpu_config - recommendation.recData?.recommended_cpu) /
        recommendation.recData?.current_cpu_config) *
      100,
  },
  CURRENT_MEMORY: {
    label: 'Current Memory',
    getterFunction: (recommendation) => recommendation.recData?.current_memory_config,
  },
  RECOMMENDED_MEMORY: {
    label: 'Recommended Memory',
    getterFunction: (recommendation) => recommendation.recData?.recommended_memory,
  },
  MEMORY_CHANGE: {
    label: 'Memory Change (%)',
    isPercent: true,
    getterFunction: (recommendation) =>
      (Math.abs(recommendation.recData?.current_memory_config - recommendation.recData?.recommended_memory) /
        recommendation.recData?.current_memory_config) *
      100,
  },
};

const EcsFargate = ({ recommendation }) => {
  const description = `Your ECS Fargate containers may need tuning to improve utilization and cost. 
                        Refer to AWS documentation for more details`;

  return (
    <BaseRecommendation
      recommendation={recommendation}
      dynamicProps={{ RDS_PROPERTIES, ...ECS_FARGATE_PROPERTIES }}
      description={description}
    />
  );
};

EcsFargate.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default EcsFargate;
