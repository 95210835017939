import React from 'react';
import Modal from 'shared/components/andtComponents/Modal';
import t from '../texts.ts';

export interface DeleteConfirmationModalProps {
  onClose(): void;
  onDelete(): void;
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ onClose, onDelete }) => (
  <Modal title={t('DELETE_ALERT_TITLE')} saveTitle={t('DELETE')} onClose={onClose} open onSave={onDelete} saveWarning>
    <div>{t('DELETE_ALERT_DESCRIPTION')}</div>
  </Modal>
);

export default DeleteConfirmationModal;
