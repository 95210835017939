import React, { useId } from 'react';

const AzureSquare = ({ ...props }) => {
  const id = useId();
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.541626"
        y="0.25"
        width="29.5"
        height="29.5"
        rx="3.75"
        fill="white"
        stroke="#D4D9E0"
        strokeWidth="0.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.7641 6.39767H17.3243L11.5523 23.4996C11.493 23.6753 11.38 23.828 11.2294 23.9362C11.0787 24.0444 10.8979 24.1026 10.7124 24.1026H6.38523C6.2447 24.1026 6.10619 24.0692 5.98115 24.0051C5.85611 23.9409 5.74814 23.8479 5.66617 23.7338C5.5842 23.6196 5.53059 23.4876 5.50977 23.3486C5.48895 23.2097 5.50152 23.0677 5.54644 22.9346L10.9241 7.00072C10.9834 6.82491 11.0963 6.67213 11.247 6.56391C11.3977 6.45569 11.5786 6.39768 11.7641 6.39767Z"
        fill={`url(#paint0_linear_2842_${id})`}
      />
      <path
        // eslint-disable-next-line max-len
        d="M19.8438 17.8682H11.0267C10.9447 17.8681 10.8646 17.8927 10.7968 17.9388C10.729 17.9848 10.6767 18.0503 10.6466 18.1265C10.6165 18.2028 10.6101 18.2863 10.6282 18.3663C10.6463 18.4462 10.6881 18.5189 10.748 18.5748L16.4137 23.8629C16.5787 24.0168 16.7959 24.1023 17.0214 24.1023H22.0141L19.8438 17.8682Z"
        fill="#0078D4"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.7641 6.39796C11.5765 6.39724 11.3937 6.45651 11.2422 6.56711C11.0907 6.67772 10.9785 6.83387 10.9221 7.01274L5.55303 22.9203C5.50508 23.0539 5.49003 23.1972 5.50915 23.3379C5.52826 23.4785 5.58098 23.6126 5.66284 23.7286C5.7447 23.8446 5.8533 23.9392 5.97944 24.0043C6.10559 24.0695 6.24557 24.1033 6.38755 24.1029H10.8264C10.9918 24.0734 11.1463 24.0005 11.2742 23.8917C11.4022 23.7829 11.4989 23.6421 11.5546 23.4836L12.6253 20.3281L16.4498 23.8953C16.6101 24.0279 16.8112 24.1012 17.0191 24.1029H21.9932L19.8116 17.8688L13.4521 17.8703L17.3444 6.39796H11.7641Z"
        fill={`url(#paint1_linear_2842_${id})`}
      />
      <path
        // eslint-disable-next-line max-len
        d="M18.8659 7.00014C18.8067 6.82461 18.6939 6.67209 18.5434 6.56406C18.3929 6.45603 18.2123 6.39793 18.0271 6.39795H11.8303C12.0156 6.39796 12.1961 6.45607 12.3466 6.5641C12.4971 6.67213 12.6099 6.82463 12.6691 7.00014L18.0469 22.9346C18.0919 23.0678 18.1045 23.2098 18.0837 23.3488C18.0629 23.4878 18.0093 23.6199 17.9273 23.7341C17.8454 23.8482 17.7374 23.9413 17.6123 24.0055C17.4873 24.0696 17.3487 24.1031 17.2082 24.1031H23.4051C23.5457 24.1031 23.6842 24.0696 23.8092 24.0054C23.9343 23.9412 24.0422 23.8482 24.1242 23.734C24.2061 23.6198 24.2597 23.4877 24.2805 23.3487C24.3013 23.2097 24.2887 23.0678 24.2437 22.9346L18.8659 7.00014Z"
        fill={`url(#paint2_linear_2842_${id})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_2842_${id}`}
          x1="13.7906"
          y1="7.70967"
          x2="8.01627"
          y2="24.7687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#114A8B" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_2842_${id}`}
          x1="15.5944"
          y1="15.6599"
          x2="14.2587"
          y2="16.1115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="0.071" stopOpacity="0.2" />
          <stop offset="0.321" stopOpacity="0.1" />
          <stop offset="0.623" stopOpacity="0.05" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_2842_${id}`}
          x1="14.8598"
          y1="7.2124"
          x2="21.1984"
          y2="24.0995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CCBF4" />
          <stop offset="1" stopColor="#2892DF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AzureSquare;
