import React from 'react';
import PropTypes from 'prop-types';
import { copyStrToClipboard } from 'shared/utils/strUtil';
import icons from 'shared/components/chartComponents/ChartButtonsMenu/icons';
import toast from 'shared/components/andtComponents/Toast';
import Button from 'shared/components/andtComponents/Button';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './ChartButtonsMenu.module.scss';
import CostTypeMenu from './components/CostTypeMenu';
import ChartTypeMenu from './components/ChartTypeMenu';
import ViewMenu from './components/ViewMenu';
import DownloadMenu from './components/DownloadMenu';

const { ResetIcon, CopyIcon } = icons;

const ChartButtonsMenu = ({
  isResetButtonDisabled,
  trendLineHandler,
  trendRowHandler,
  cumulativeHandler,
  eventsHandler,
  goalsHandler,
  costUsageHandler,
  chartTypeHandler,
  resetHandler,
  saveReportHandler,
  overwriteReportHandler,
  saveGoalHandler,
  saveDashboardHandler,
  overwriteDashboardHandler,
  exportToCSVHandler,
  exportToPNGHandler,
  wasteCostAllocationHandler,
  viewMenuDisabled,
  onlyTrendRowHandler,
}) => (
  <div className={styles.chartButtonsMenu}>
    <CostTypeMenu costUsageHandler={costUsageHandler} />
    <ChartTypeMenu chartTypeHandler={chartTypeHandler} />
    <ViewMenu
      eventsHandler={eventsHandler}
      goalsHandler={goalsHandler}
      trendLineHandler={trendLineHandler}
      trendRowHandler={trendRowHandler}
      onlyTrendRowHandler={onlyTrendRowHandler}
      cumulativeHandler={cumulativeHandler}
      wasteCostAllocationHandler={wasteCostAllocationHandler}
      disabled={viewMenuDisabled}
    />
    <Tooltip title="Reset All" arrow disabled={isResetButtonDisabled}>
      <div>
        <Button
          onClick={resetHandler}
          text=""
          icon={ResetIcon}
          isSecondary
          disabled={isResetButtonDisabled}
          automationId="resetAll"
        />
      </div>
    </Tooltip>
    <Tooltip title="Copy Link" arrow>
      <div>
        <Button
          onClick={() => {
            if (window.location.href.length > 8192) {
              toast.warning('URL is above maximum length');
              return;
            }
            copyStrToClipboard(window.location.href);
            toast.success('URL copied');
          }}
          text=""
          icon={CopyIcon}
          isSecondary
          automationId="copyLink"
        />
      </div>
    </Tooltip>
    <DownloadMenu
      saveReportHandler={saveReportHandler}
      saveGoalHandler={saveGoalHandler}
      overwriteReportHandler={overwriteReportHandler}
      saveDashboardHandler={saveDashboardHandler}
      overwriteDashboardHandler={overwriteDashboardHandler}
      exportToCSVHandler={exportToCSVHandler}
      exportToPNGHandler={exportToPNGHandler}
    />
  </div>
);

ChartButtonsMenu.propTypes = {
  isResetButtonDisabled: PropTypes.bool,
  trendLineHandler: PropTypes.object.isRequired,
  trendRowHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onlyTrendRowHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  cumulativeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  eventsHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  goalsHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  costUsageHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  chartTypeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  resetHandler: PropTypes.func.isRequired,
  viewMenuDisabled: PropTypes.bool.isRequired,
  saveReportHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  saveGoalHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  overwriteReportHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  saveDashboardHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  overwriteDashboardHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  exportToCSVHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  exportToPNGHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  wasteCostAllocationHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

ChartButtonsMenu.defaultProps = {
  isResetButtonDisabled: false,
  wasteCostAllocationHandler: null,
  exportToPNGHandler: null,
  overwriteReportHandler: null,
  saveReportHandler: null,
  trendRowHandler: null,
  onlyTrendRowHandler: null,
};

export default ChartButtonsMenu;
