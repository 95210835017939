import React from 'react';
import PropTypes from 'prop-types';
import ImmediateFilterBar from 'shared/components/ImmediateFilterBar';
import DateFilter from 'shared/modules/dateFilter';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { datePickerSyntaxDates } from 'usage/containers/AzureVirtualMachines/constants/constants';
import RecDashboardLineChart from 'recommendations/containers/Dashboard/components/RecDashboardLineChart';

const OpportunitiesChart = ({
  recommendationsOpportunities,
  handleDateChange,
  handleChangeGranLevel,
  currPeriodGranLevel,
  startDate,
  endDate,
  maxDate,
  isDateRangeError,
  recommType,
  recTypeDisplay,
  isShowActualCost,
  numStrAbriviaionByDisplayMetric,
}) => (
  <>
    <div>
      <ImmediateFilterBar
        // DatePicker
        startDate={datePickerSyntaxDates(startDate, endDate).startDate}
        endDate={datePickerSyntaxDates(startDate, endDate).endDate}
        maxDate={maxDate}
        handleDateChange={handleDateChange}
        isDateRangeError={isDateRangeError}
        renderDatePickerFilter
        // Period Gran
        currPeriodGranLevel={currPeriodGranLevel}
        handleChangeGranLevel={handleChangeGranLevel}
        containerStyle={{ marginLeft: '0' }}
        // Filters
      />
    </div>
    <div className="d-flex" style={{ width: '100%', margin: '0 -15px' }}>
      <RecDashboardLineChart
        data={recommendationsOpportunities}
        dateFilter={DateFilter}
        showHeader
        height={220}
        recommType={recommType}
        recTypeDisplay={recTypeDisplay}
        isShowActualCost={isShowActualCost}
        numStrAbriviaionByDisplayMetric={numStrAbriviaionByDisplayMetric}
      />
    </div>
  </>
);

OpportunitiesChart.propTypes = {
  recommendationsOpportunities: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleChangeGranLevel: PropTypes.func.isRequired,
  currPeriodGranLevel: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  isDateRangeError: PropTypes.bool.isRequired,
  recommType: PropTypes.string.isRequired,
  recTypeDisplay: PropTypes.string,
  isShowActualCost: PropTypes.bool,
  numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
};

OpportunitiesChart.defaultProps = {
  recTypeDisplay: null,
  isShowActualCost: false,
};

const observerOpportunitiesChart = withUserSettingsConsumer(OpportunitiesChart);
export default observerOpportunitiesChart;
