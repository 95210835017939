import React from 'react';
import DescriptionWrapper from 'recommendations/shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>
      This RDS instance has a low Provisioned IOPS utilization. We recommend optimizing the Provisioned IOPS which will
      reduce your cost.
    </p>
  </DescriptionWrapper>
);

export default Description;
