import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using CLI:</li>
      <CodeBlock
        text="az vm stop --name {{resourceName}} --g {{resourceGroup}}"
        variables={{
          resourceName: rec.resourceName,
          resourceGroup: rec.resourceGroup,
        }}
      />
      <br />
      <CodeBlock
        text="az vm deallocate --name {{resourceName}} --g {{resourceGroup}}"
        variables={{
          resourceName: rec.resourceName,
          resourceGroup: rec.resourceGroup,
        }}
      />
      <br />
      <NavLink
        rel="noopener noreferrer"
        target="_blank"
        href="https://build5nines.com/properly-shutdown-azure-vm-to-save-money"
      >
        Properly Shutdown Azure VM to Save Money
      </NavLink>
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
