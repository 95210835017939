import React from 'react';
import PropTypes from 'prop-types';
import RecommendationOptionsPanel from 'recommendations/shared/components/RecommendationOptionsPanel';

const Options = ({ recommendation }) => {
  if (Array.isArray(recommendation?.alternatives)) {
    const topThreeAlternatives = recommendation?.alternatives
      .slice(0, 3)
      .map((alternative) => ({ ...alternative, isMultiAz: alternative.isMultiAz ? 'Y' : 'N' }));
    const current = {
      instanceType: recommendation?.instanceType,
      model: recommendation?.model,
      totalCostRecommended: recommendation?.totalCostRecommended,
      annualCost: recommendation?.currentAnnualCost,
      engine: recommendation?.engine,
      engineVersion: recommendation?.engineVersion,
      storageType: recommendation?.storageType,
      dbType: recommendation?.dbType,
      dbName: recommendation?.dbName,
      isMultiAz: Number(recommendation?.isMultiAz) === 1 ? 'Y' : 'N',
      riCoverage: recommendation?.riCoverage,
    };
    const recommendationData = [
      { propertyTitle: 'Instance Type', property: 'instanceType' },
      { propertyTitle: 'Model', property: 'model' },
      { propertyTitle: 'Total Cost', property: 'totalCostRecommended', displayCurrency: true },
      {
        propertyTitle: 'RI Compatible',
        property: 'riCoverage',
        // eslint-disable-next-line max-len
        info: 'It is possible to purchase RIs for this configuration, please validate current RIs before choosing this option',
      },
      { propertyTitle: 'Hourly Cost', property: 'hourlyCost', showInAlternative: true },
      { propertyTitle: 'Engine', property: 'engine', showInCurrent: true },
      { propertyTitle: 'Engine Version', property: 'engineVersion', showInCurrent: true },
      { propertyTitle: 'Storage', property: 'storageType', showInCurrent: true },
      { propertyTitle: 'DB Type', property: 'dbType', showInCurrent: true },
      { propertyTitle: 'DB Name', property: 'dbName', showInCurrent: true },
      { propertyTitle: 'Multi AZ', property: 'isMultiAz' },
    ];

    return (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        current={current}
        recommendationData={recommendationData}
      />
    );
  }
  return null;
};

Options.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Options;
