import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [usersFilters, setUsersFilters] = useState({
    isUserEnabled: true,
    isUserDisabled: false,
    searchText: '',
  });

  const [tableFilters, setTableFilters] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  const [paginationToken, setPaginationToken] = useState(null);
  const [backwardPaginationToken, setBackwardPaginationToken] = useState(null);

  const [showFilters, setShowFilters] = useState(false);

  const [isAllExpanded, setIsAllExpanded] = useState(false);

  return (
    <UsersContext.Provider
      value={{
        backwardPaginationToken,
        isAllExpanded,
        setIsAllExpanded,
        paginationToken,
        showFilters,
        setShowFilters,
        setUsersFilters,
        selectedRows,
        setSelectedRows,
        setBackwardPaginationToken,
        setPaginationToken,
        setTableFilters,
        tableFilters,
        usersFilters,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

UsersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUsersContext = () => useContext(UsersContext);
