import moment from 'moment';
import { palette } from './theme';

const timezoneOffset = new Date().getTimezoneOffset();

export const checkIsToday = (mSeconds) =>
  new Date().getTime() - mSeconds < 86400000 && new Date().getDate() === new Date(mSeconds).getDate();

export const formatDate = (secs, format = 'MM/DD/YYYY') => {
  const addZero = (v) => `0${v}`.slice(-2);
  const d = new Date(secs * 1000);
  const hours = `${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
  const date = format
    .replace('DD', addZero(d.getDate()))
    .replace('MM', addZero(d.getMonth() + 1))
    .replace('YYYY', addZero(d.getYear() - 100));
  return (checkIsToday(secs * 1000) ? 'Today ' : `${date} `) + hours;
};

export const getPluoral = (n, base, suffix = 's') => base + (n === 1 ? '' : suffix);
export const formatDuration = (seconds) => {
  const minutes = Math.round(seconds / 60);
  if (minutes < 60) {
    return `${minutes} ${getPluoral(minutes, 'minute')}`;
  }
  const roundedHours = Math.round(minutes / 30) / 2;
  return `${roundedHours} ${getPluoral(roundedHours, 'hour')}`;
};

export function getChartsOptions({
  areaData,
  lineData,
  multilinesData,
  anomalies,
  chartClassNames = '',
  otherAnomalies = [],
  isDark,
  isMulti,
  timeInterval,
  width,
  height = 280,
}) {
  const config = {
    title: {
      text: '',
    },
    chart: {
      height,
      width: width ? width - 16 : undefined,
      zoomType: 'xy',
    },
    exporting: {
      enabled: false, // hide context menu
    },
    xAxis: {
      min: timeInterval?.startDate ? timeInterval?.startDate : undefined,
      max: timeInterval?.endDate ? timeInterval?.endDate : undefined,
      showFirstLabel: true,
      type: 'datetime',
      tickPosition: 'inside',
      gridLineWidth: 1,
      tickLength: 0,
      crosshair: {
        dashStyle: 'Dash',
        className: 'crosshair-line',
      },
      labels: {
        style: {
          color: isDark ? palette.gray[325] : palette.gray[400],
        },
      },
      dateTimeLabelFormats: {
        hour: '%l:%m %P',
      },
    },
    yAxis: {
      title: '',
      gridLineWidth: 1,
      labels: {
        style: {
          color: isDark ? palette.gray[325] : palette.gray[400],
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      crosshairs: true,
      formatter() {
        const { zone = {}, series, color, y, x } = this.point;
        const { anomaly: tooltipAnomaly } = zone;
        if (series.name === 'line' || (isMulti && series.name.includes('line'))) {
          const anomalyRows =
            tooltipAnomaly &&
            `
                <div class="anomaly-rows">
                    <div><span>Score:</span> <span class="value">${
                      Math.round(tooltipAnomaly[3] * 10000) / 100
                    }</span></div>
                    <div><span>Delta:</span> <span class="value">${Math.round(tooltipAnomaly[5])}</span></div>
                    <div><span>Duration:</span> <span class="value">${formatDuration(
                      tooltipAnomaly[1] - tooltipAnomaly[0],
                    )}</span></div>
                </div>
          `;
          const momentTime = moment(x).format('ddd, MMM D [@] h:mm A');
          // const timeStr = formatDate(x / 1000, timeFormat);

          return `
            <div class="anodot-chart-tooltip">
                <div class="timestamp">${momentTime}</div>
                <div class="bage-wrapper ${tooltipAnomaly ? '' : 'metrics'}">
                    <div class="bage" style="background: ${color}"><b>${Math.round(y * 100) / 100}</b></div>
                </div>
                ${anomalyRows || ''}
            </div>
          `;
        }
        return false;
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stickyTracking: false,
      },
      arearange: {
        accessibility: {
          enabled: false,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
        tooltip: {
          enabled: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    series: [],
    time: {
      timezoneOffset,
    },
  };

  if (chartClassNames) {
    config.chart.className = chartClassNames;
  }

  if (areaData) {
    config.series.push({
      type: 'arearange',
      data: areaData,
      zIndex: 0,
      className: 'anodot-area',
      states: {
        hover: {
          enabled: false,
        },
      },
    });
  }
  if (lineData) {
    config.series.push({
      type: 'line',
      zIndex: 1,
      data: lineData,
      className: 'anodot-line',
      name: 'line',
      color: palette.blue[500],
      marker: {
        symbol: 'circle',
        enabled: false,
      },
    });
  }

  if (multilinesData) {
    if (chartClassNames) {
      config.chart.className = chartClassNames;
    }
    multilinesData.forEach((d, i) => {
      config.series.push({
        type: 'line',
        color: d.color,
        zIndex: 1,
        data: d,
        className: 'anodot-line',
        name: `line-${i}`,
        marker: {
          enabled: false,
        },
      });
    });
  }

  if (anomalies) {
    const anomalyZones = anomalies.map((anomaly) => [
      {
        value: anomaly[0] * 1000,
        className: 'neutral-line',
      },
      {
        value: anomaly[1] * 1000,
        className: 'anomalies-part',
        color: palette.mango[500],
        stroke: palette.mango[500],
        anomaly,
      },
    ]);

    const otherZones = [];
    otherAnomalies.forEach((a) => {
      otherZones.push({
        value: a.startDate * 1000,
        className: 'neutral-line',
      });
      otherZones.push({
        value: a.endDate * 1000,
        className: 'other-anomalies',
        color: palette.navyblue[400],
        stroke: palette.navyblue[400],
        anomaly: [a.startDate, a.endDate, null, a.score, null, a.peakValue],
      });
    });

    const zones = otherZones.concat(...anomalyZones).sort((a, b) => a.value - b.value);
    zones.push({ className: 'neutral-line' });
    const i = config.series.findIndex((s) => s.type === 'line');
    if (i !== -1) {
      /* set zones for line-serie only */
      config.series[i].zoneAxis = 'x';
      config.series[i].zones = zones;
    }
  }
  return config;
}

export const multiplyX = (a) =>
  a.map((b) => {
    const [x, ...rest] = b;
    return [x * 1000, ...rest];
  });
