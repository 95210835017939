import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CubeletIcon } from 'shared/img/icons/cubelet.svg';
import { strToTitleCase } from 'shared/utils/strUtil.js';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import ExploreAnomaly from './ExploreAnomaly';
import styles from './Cubelet.module.scss';

const Cubelet = ({ anomaly, path }) => (
  <div className={styles.cubelet}>
    <header>
      <div>
        <CubeletIcon />
        <h3>Anomaly Root Cause</h3>
        <Tooltip title="At least 70% of the spike appears in these items" arrow placement="top">
          <span className={styles.explanationTooltip}>
            <IconFromPng size="14px" matchHeight="14px" icon={ICONS.info} />
          </span>
        </Tooltip>
      </div>
      <ExploreAnomaly row={anomaly} path={path} enableCubeletFilters />
    </header>
    <div className={styles.cubeletBody}>
      {anomaly.cubeletSplits?.length ? (
        anomaly.cubeletSplits.map((split, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.cubeletRow} key={index}>
            <h4>{strToTitleCase(split.dimensionName.split('_').join(' '))}:</h4>
            <p>{split.dimensionValue}</p>
          </div>
        ))
      ) : (
        <h4>No specific property caused this anomaly.</h4>
      )}
    </div>
  </div>
);

Cubelet.propTypes = {
  anomaly: PropTypes.shape({
    cubeletSplits: PropTypes.array,
  }),
  path: PropTypes.string.isRequired,
};

Cubelet.defaultProps = {
  anomaly: {
    cubeletSplits: [],
  },
};

export default Cubelet;
