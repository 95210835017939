import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul>
      <li style={{ 'list-style-type': 'none' }}>Using the AWS CLI:</li>
      <li>
        In case of <span className="bold-text">IP:</span>
      </li>
      <CodeBlock
        /* eslint-disable-next-line max-len */
        text="aws ec2 release-address --public-ip {{publicIp}}"
        variables={{
          publicIp: rec.publicIp,
        }}
      />
      <li>
        In case of <span className="bold-text">VPC:</span>
      </li>
      <CodeBlock
        text="aws ec2 release-address --allocation-id {{publicIp}}"
        variables={{
          publicIp: rec.publicIp,
        }}
      />
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
