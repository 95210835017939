import { makeObservable, observable } from 'mobx';

export default class LinkedAccount {
  constructor(rawLinkedAccount) {
    this.id = rawLinkedAccount.id;
    this.awsAccountId = rawLinkedAccount.awsAccountId || rawLinkedAccount.accountId;
    this.linkedAccountId = rawLinkedAccount.linkedAccountId;
    this.linkedAccountName = rawLinkedAccount.linkedAccountName;
    this.externalId = rawLinkedAccount.externalId;
    this.customerName = rawLinkedAccount.customerName;
    this.allowUpdates = rawLinkedAccount.allowUpdates;
    this.isVerified = rawLinkedAccount.isVerified || rawLinkedAccount.isConnected;
    this.pileusRoleArn = rawLinkedAccount.pileusRoleArn;
    this.isConnectMeSent = false;
    makeObservable(this, {
      isVerified: observable,
    });
  }

  getIsVerified() {
    return this.isVerified;
  }

  getLinkedAccountName() {
    return this.linkedAccountName;
  }

  getLinkedAccountId() {
    return this.linkedAccountId;
  }

  getPileusRoleArn() {
    return this.pileusRoleArn;
  }

  setIsVerified(isVerified) {
    this.isVerified = isVerified;
  }

  setLinkedAccountId(linkedAccountId) {
    this.linkedAccountId = linkedAccountId;
  }

  setLinkedAccountName(linkedAccountName) {
    this.linkedAccountName = linkedAccountName || '';
  }

  setConnectMeSent(result) {
    this.isConnectMeSent = result;
  }

  updatePileusRoleArn(pileusRoleArn) {
    this.pileusRoleArn = pileusRoleArn;
  }
}
