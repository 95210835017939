import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { DB_PROPERTIES, ENGINE_PROPERTIES, RDS_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const NEPTUNE_UTIL_LOW_PROPERTIES = {
  ARN: {
    label: 'Arn',
    getterFunction: (recommendation) => recommendation?.recData?.arn,
  },
  MAX_GREMLIN: {
    label: 'Max Gremlin Requests Per Sec',
    getterFunction: (recommendation) =>
      recommendation?.recData?.max_gremlin_requests_per_sec === 0
        ? '0'
        : recommendation?.recData?.max_gremlin_requests_per_sec,
  },
  MAX_SPARQL: {
    label: 'Max Sparql Requests Per Sec',
    getterFunction: (recommendation) =>
      recommendation?.recData?.max_sparql_requests_per_sec === 0
        ? ''
        : recommendation?.recData?.max_sparql_requests_per_sec,
  },
  DB_CLUSTER_ID: {
    label: 'DB Cluster ID',
    getterFunction: (recommendation) => recommendation?.recData?.db_cluster_id,
  },
  DB_CLUSTER_PARAMETER_GROUP: {
    label: 'DB Cluster Parameter Group',
    getterFunction: (recommendation) => recommendation?.recData?.db_cluster_parameter_group,
  },
  DB_CLUSTER_RESOURCE_ID: {
    label: 'DB Cluster Resource Id',
    getterFunction: (recommendation) => recommendation?.recData?.db_cluster_resource_id,
  },
  PRIMARY_DB_INSTANCE_ID: {
    label: 'Primary DB Instance ID',
    getterFunction: (recommendation) => recommendation?.recData?.primary_db_instance_id,
  },
};

const NeptuneUtilLow = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `delete-db-instance --db-instance-identifier ${recommendation?.recData?.primary_db_instance_id}`,
        },
      ],
    },
  ];

  const description =
    'The Neptune service has been underutilized for the past 7 days. We recommend terminating this service.';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...DB_PROPERTIES, ...RDS_PROPERTIES, ...ENGINE_PROPERTIES, ...NEPTUNE_UTIL_LOW_PROPERTIES }}
    />
  );
};

NeptuneUtilLow.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default NeptuneUtilLow;
