import React from 'react';
import PropTypes from 'prop-types';
import styles from './Stepper.module.scss';

const Stepper = ({ steps, activeStepIndex }) => (
  <div className={styles.container}>
    <div className={styles.progressLine}>
      <div
        style={{
          width:
            activeStepIndex === steps.length - 1
              ? '100%'
              : `calc(50% - ${(110 * steps.length) / 2 - 110 * (activeStepIndex + 1)}px)`,
        }}
      />
    </div>
    <div className={styles.steps}>
      {steps.map((step, index) => (
        <div
          key={step.id}
          data-active={activeStepIndex === index}
          data-done={activeStepIndex > index}
          data-dotted={step.isDotted}
          className={styles.step}
        >
          <div className={styles.stepCounter}>
            <span>{index + 1}</span>
          </div>
          <div className={styles.stepTitle}>{step.title}</div>
          {step.subTitle && <div className={styles.stepSubTitle}>{step.subTitle}</div>}
        </div>
      ))}
    </div>
  </div>
);

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      isDotted: PropTypes.bool,
      subTitle: PropTypes.string,
    }),
  ).isRequired,
  activeStepIndex: PropTypes.number.isRequired,
};

export default Stepper;
