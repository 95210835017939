import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import EditRoleLinkedAccountsModal from '../roleModals/EditRoleLinkedAccountsModal/EditRoleLinkedAccountsModal';

import styles from './AddToRoleButton.module.scss';

const EditRoleLinkedAccounts = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        automationId="editRoleLinkedAccounts"
        className={{ button: styles.addUsers }}
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.addLinkedAccounts.name} className={styles.addUsersIcon} />}
        onClick={onClick}
        text=""
      />
      <EditRoleLinkedAccountsModal isOpen={isOpen} setIsOpen={setIsOpen} role={row} />
    </>
  );
};

EditRoleLinkedAccounts.propTypes = {
  row: PropTypes.string.isRequired,
};

export default EditRoleLinkedAccounts;
