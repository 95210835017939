/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import VirtualTagsTable from './components/VirtualTagsTable';

const VirtualTags = (props) => (
  <Container>
    <PageHeader showDate title={PageNames.VIRTUAL_TAGS} />
    <Card>
      <CardBody>
        <VirtualTagsTable {...props} />
      </CardBody>
    </Card>
  </Container>
);

VirtualTags.propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};
const ObserverVirtualTags = observer(VirtualTags);
export default ObserverVirtualTags;
