const dateByGranLevel = (date, hour) => {
  if (hour || hour === 0) {
    return new Date(date).setHours(hour);
  }
  return date;
};

export const prepareEC2TopPanelData = (rawData) =>
  rawData.map((rawDataObj) => ({
    groupBy: rawDataObj.group_by,
    usageDate: dateByGranLevel(rawDataObj.usage_date, rawDataObj.usage_hour),
    totalUsage: rawDataObj.total_usage_quantity,
    totalQuantity: rawDataObj.group_num_of_items,
    totalCost: rawDataObj.total_cost || 0,
  }));
