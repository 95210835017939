/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes, S3AttentionComment } from 'recommendations/constants/recommendationsConstants';
import { S3StorageClassRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class S3StorageClassRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new S3StorageClassRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.S3_STORAGE_CLASS;
  }

  buildRecommTitle() {}

  build() {
    super.build();
    const {
      storage_class: currentStorageClass,
      bucket_name: bucketName,
      total_size_gb: totalSizeGB,
    } = this._rawRecommendation;

    this._recommendation.currentStorageClass = currentStorageClass;
    this._recommendation.bucketName = bucketName;
    this._recommendation.totalSizeGB = totalSizeGB;
    this._recommendation.attentionComment = S3AttentionComment;
    const { zone_tag: region } = this._rawRecommendation;
    if (region && !this._recommendation.region) {
      this._recommendation.region = region;
    }
  }
}
