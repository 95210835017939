import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const ES_UTIL_LOW_PROPERTIES = {
  DOMAIN_ID: {
    label: 'Domain Id',
    getterFunction: (recommendation) => recommendation?.recData?.domain_id,
  },
  DOMAIN_NAME: {
    label: 'Domain Name',
    getterFunction: (recommendation) => recommendation?.recData?.domain_name,
  },
  INSTANCE_TYPE: {
    label: 'instance Type',
    getterFunction: (recommendation) => recommendation?.recData?.instance_type,
  },
  INSTANCE_COUNT: {
    label: 'instance Count',
    getterFunction: (recommendation) => recommendation?.recData?.instance_count,
  },
  MAX_INDEXING_RATE: {
    label: 'Max Indexing Rate',
    getterFunction: (recommendation) => recommendation?.recData?.max_indexing_rate,
  },
  MAX_SEARCH_RATE: {
    label: 'Max Search Rate',
    getterFunction: (recommendation) => recommendation?.recData?.max_search_rate,
  },
  IDLE_STARTING_TIME: {
    label: 'Idle Starting time',
    getterFunction: (recommendation) => recommendation?.recData?.starting_time,
  },
};

const EcUtilLow = ({ recommendation }) => {
  const description =
    'The Elasticsearch service has been underutilized for the past 7 days. We recommend terminating this service.';
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `delete-elasticsearch-domain --domain-name ${recommendation?.recData?.domain_name}`,
        },
      ],
    },
  ];

  return (
    <BaseRecommendation
      recommendation={recommendation}
      commandsList={commandsList}
      description={description}
      dynamicProps={{ ...ES_UTIL_LOW_PROPERTIES }}
    />
  );
};

EcUtilLow.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default EcUtilLow;
