/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { ChartTypeDisplayTypes, ChartTypeValues } from 'usage/constants/costAndUsageConstants';
import ButtonDropdown from '../../../andtComponents/ButtonDropdown';
import icons from '../icons';

const CHART_TYPE_ICON_MAP = {
  [ChartTypeValues.PIE]: icons.chartTypes.pieChartIcon,
  [ChartTypeValues.TABLE]: icons.chartTypes.tableChartIcon,
  [ChartTypeValues.AREA]: icons.chartTypes.areaChartIcon,
  [ChartTypeValues.BAR]: icons.chartTypes.barChartIcon,
  [ChartTypeValues.LINE]: icons.chartTypes.lineChartIcon,
};

const ChartTypeMenu = ({ chartTypeHandler }) => (
  <ButtonDropdown
    text=""
    icon={CHART_TYPE_ICON_MAP[chartTypeHandler.value]}
    tooltipText="Graph Options"
    disabled={chartTypeHandler.disabled}
    automationId="chartTypeMenu"
  >
    {ChartTypeDisplayTypes.map((type) => {
      const Icon = CHART_TYPE_ICON_MAP[type.value];
      return (
        <li
          onClick={() => chartTypeHandler.onChange(type.value)}
          data-selected={chartTypeHandler.value === type.value}
          id={type.value}
        >
          <span>
            <Icon /> {type.name}
          </span>
        </li>
      );
    })}
  </ButtonDropdown>
);

ChartTypeMenu.propTypes = {
  chartTypeHandler: PropTypes.object.isRequired,
};

export default ChartTypeMenu;
