/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-parens */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import { numberWithCommas } from 'shared/utils/strUtil';
import { mapRecommendationsTypeToServiceName } from 'recommendations/constants/recommendationsConstants';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const GOAL = 10;
const CustomTooltip = (props) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const { payload, active, label } = props;
  const prepareValue = (item) => {
    if (item.dataKey === 'wastePercent') {
      return `${numberWithCommas(item.value, item.value < 1 ? 1 : 0)}%`;
    }
    return `${getCurrencyNumber(item.value, item.value < 1 ? 2 : 0)}`;
  };

  let newPayload = props && payload ? [...payload] : [];
  newPayload = newPayload.sort(({ value: a }, { value: b }) => b - a);
  if (payload && payload[0]?.payload?.wastePercent) {
    newPayload.push({
      name: 'Waste Percent',
      value: payload[0].payload.wastePercent,
      dataKey: 'wastePercent',
    });
  }
  if (active && newPayload.length) {
    return (
      <div className="chart-tooltip fs-exclude" style={{ opacity: 0.9, padding: 10 }}>
        <p style={{ padding: 4 }}>{label}</p>
        {newPayload.map((item) => (
          <div>
            <p className="label" style={{ color: item.color, padding: 4 }}>{`${item.name}: ${prepareValue(item)}`}</p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

export default class RecDashboardLineChart extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    title: PropTypes.number.isRequired,
    showHeader: PropTypes.bool.isRequired,
    // showOnlyBarChart: PropTypes.bool.isRequired,
    height: PropTypes.number,
    recommType: PropTypes.string,
    recTypeDisplay: PropTypes.string,
    isShowActualCost: PropTypes.bool,
    overrideCurrency: PropTypes.string,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };
  static defaultProps = {
    height: 220,
    recommType: null,
    recTypeDisplay: null,
    isShowActualCost: false,
    overrideCurrency: null,
  };

  state = {
    showLabelId: null,
  };

  getMaxDomain = (dataMax, referenceLineYaxisValue) => {
    if (dataMax * 1.1 > referenceLineYaxisValue) {
      return dataMax * 1.1;
    }
    return referenceLineYaxisValue * 1.1;
  };

  toggleLabel = (dot, e) => {
    const newIndex = e.type === 'mouseenter' ? dot.index : null;
    this.setState({ showLabelId: newIndex });
  };
  activeDot = {
    onMouseEnter: (e, dot) => this.toggleLabel(e, dot),
    onMouseLeave: (e, dot) => this.toggleLabel(e, dot),
  };

  renderLineChart = (data, maxYaxis) => {
    const { numStrAbriviaionByDisplayMetric, overrideCurrency } = this.props;
    // TODO - Delete the reference line Y axis value by actual cost avg if don't needed
    const totalActualCost = data.reduce((acc, datum) => {
      acc += +datum.actualCost;
      return acc;
    }, 0);
    const referenceLineYaxisValue = ((totalActualCost / data.length) * GOAL) / 100;
    return (
      <ResponsiveContainer height={this.props.height || 300}>
        <AreaChart
          data={data}
          // height={250}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 20,
          }}
        >
          <XAxis
            dataKey="usageDate"
            tickFormatter={(value) => {
              if (value.toLowerCase().indexOf('w') > -1) {
                return value;
              }
              if (value.length > 0 && value.length < 9) {
                return new Date(value).toLocaleString('en-US', { month: 'short', year: 'numeric' });
              }
              return new Date(value).toLocaleString('en-US', { month: 'short', day: 'numeric' });
            }}
          />
          <YAxis
            yAxisId="left"
            tickFormatter={(value) =>
              numStrAbriviaionByDisplayMetric(maxYaxis, value, DisplayMetricTypes.COST, overrideCurrency)
            }
            type="number"
            domain={[0, (dataMax) => dataMax]}
            interval={0}
            allowDataOverflow="true"
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip />} />
          <Area
            yAxisId="left"
            name={this.props.recTypeDisplay ? `Target waste of ${this.props.recTypeDisplay}` : 'Target waste'}
            strokeWidth={0}
            activeDot={this.activeDot}
            dot={false}
            type="monotone"
            dataKey="targetWaste"
            stroke="#e9cc55fa"
            fill="#e9cc55fa"
            stackId="1"
          />
          <Area
            yAxisId="left"
            name={this.props.recTypeDisplay ? `Over waste of ${this.props.recTypeDisplay}` : 'Over waste'}
            strokeWidth={0}
            activeDot={this.activeDot}
            dot={false}
            type="monotone"
            dataKey="overWaste"
            stroke="#f55f5f"
            fill="#f55f5f"
            stackId="1"
          />
          {this.props.isShowActualCost ? (
            <Area
              yAxisId="left"
              name={
                this.props.recommType
                  ? `Actual ${mapRecommendationsTypeToServiceName.get(this.props.recommType)} Cost`
                  : 'Actual Cost'
              }
              fillOpacity={0}
              dataKey="actualCost"
              stroke="#1c5995"
              fill="#1c5995"
              stackId="3"
            />
          ) : null}
          {/* <ReferenceLine yAxisId="left" y={referenceLineYaxisValue} label={{ position: 'top', value: `${GOAL}%`, fill: '#43A547', fontSize: 14 }} stroke="#43A547" strokeDasharray="5" /> */}
          <Legend />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  render() {
    const { data, title, height, showHeader, isShowActualCost } = this.props;
    const maxYaxis =
      data && data.length
        ? data.reduce((acc, datum) => {
            const currDateGoalCost = datum.actualCost * (GOAL / 100);
            if (datum.waste > currDateGoalCost) {
              datum.targetWaste = currDateGoalCost;
              datum.overWaste = datum.waste - currDateGoalCost;
            } else {
              datum.targetWaste = datum.waste;
            }
            if (isShowActualCost) {
              if (Math.abs(datum.actualCost) > acc) {
                acc = Math.abs(datum.actualCost);
              }
            } else {
              if (Math.abs(datum.targetWaste) > acc) {
                acc = Math.abs(datum.targetWaste);
              }
              if (Math.abs(datum.overWaste) > acc) {
                acc = Math.abs(datum.overWaste);
              }
            }
            return acc;
          }, 0)
        : 0;
    const finalChartId = `ec2-pricing-chart-${random5DigitNumber()}`;
    // data.forEach((object, idx) => { object.index = idx; });

    return this.renderLineChart(data, maxYaxis);
  }
}
