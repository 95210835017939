import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CustomModal from 'shared/components/andtComponents/Modal';

const ViewSubmitEditModal = ({ isOpen, handleApplyForAll, handleApplyFromNow, onClose }) => (
  <CustomModal
    open={isOpen}
    onClose={onClose}
    title="Update View History"
    cancelTitle="No"
    onCloseClick={() => handleApplyFromNow()}
    onSave={() => handleApplyForAll()}
    saveTitle="Yes"
  >
    <span>Should this change apply view’s historical data?</span>
  </CustomModal>
);

ViewSubmitEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleApplyForAll: PropTypes.func.isRequired,
  handleApplyFromNow: PropTypes.func.isRequired,
};

const ObserverSaveModal = observer(ViewSubmitEditModal);
export default ObserverSaveModal;
