import { useMutation } from 'react-query';
import { queryClient } from 'queryClient';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';

const root = '/api/v1/user-management/register-codes';

async function sendCodeApi(email) {
  return API.post('billings', root, { body: { email } });
}

export async function verifyCodeApi(email, code) {
  return API.post('billings', `${root}/verify`, { body: { email, code } });
}

export default function useRegistrationCode() {
  const queryKey = [apiConstants.QUERY_KEYS.REGISTRATION_CODES];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    sendCode: (options) => useMutation(({ email }) => sendCodeApi(email), options),
    verifyCode: () => useMutation(({ email, code }) => verifyCodeApi(email, code)),
  };
}
