import React, { useState } from 'react';
import classNames from 'classnames';
import toast from 'shared/components/andtComponents/Toast';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { USER_STATUS_OPTIONS } from '../consts';

import styles from './UserStatusSwitchButton.module.scss';

const getUserStatusOptions = (enabled) => [
  { label: USER_STATUS_OPTIONS.ENABLED, isSelected: enabled === true },
  { label: USER_STATUS_OPTIONS.DISABLED, isSelected: enabled === false },
];

const UserStatusSwitchButton = ({ row, isViewOnly }) => {
  const [isEnableDisableInProgress, setIsEnableDisableInProgress] = useState(false);
  const { enableDisableUsers } = useUsers();

  const optionsList = Object.values(getUserStatusOptions(row?.enabled));

  const changeUserState = async (e, option) => {
    if (isViewOnly) {
      return;
    }
    e.stopPropagation();
    try {
      setIsEnableDisableInProgress(true);
      await enableDisableUsers.mutateAsync({
        usersIds: [row?.id],
        enable: option.label === USER_STATUS_OPTIONS.ENABLED,
      });
    } catch (error) {
      toast.error('Error while changing user status');
      setIsEnableDisableInProgress(false);
    }
  };

  return (
    optionsList?.length > 0 && (
      <div className={classNames(styles.container, { [styles.inProgress]: isEnableDisableInProgress })}>
        <div className={styles.switchOptionsContainer}>
          {optionsList.map((option) => (
            <div
              key={option.label}
              className={classNames(styles.option, {
                [styles.selected]: option.isSelected,
                [styles.viewOnly]: option.isSelected && isViewOnly,
                [styles.notSelected]: !option.isSelected,
                [styles.disabled]: isViewOnly,
              })}
              onClick={(e) => changeUserState(e, option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default UserStatusSwitchButton;
