import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SORT_BY_OPTIONS } from 'recommendationsNew/consts';

const HeatMapContext = createContext();

const initGroupBy = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const groupByParam = searchParams.get('groupBy');
  if (groupByParam) {
    return JSON.parse(groupByParam);
  }
  return [];
};
export const HeatMapProvider = ({ children }) => {
  const [heatMapGroupByOptions, setHeatMapGroupByOptions] = useState(initGroupBy());
  const [heatMapSortByOption, setHeatMapSortByOption] = useState(SORT_BY_OPTIONS.items.SAVINGS.property);

  const [isHeatMapClosed, setIsHeatMapClosed] = useState(false);

  const [isWasteChart, setIsWasteChart] = useState(window.location.search.includes('isWasteChart=true'));

  return (
    <HeatMapContext.Provider
      value={{
        heatMapSortByOption,
        setHeatMapSortByOption,
        heatMapGroupByOptions,
        setHeatMapGroupByOptions,
        isWasteChart,
        setIsWasteChart,
        isHeatMapClosed,
        setIsHeatMapClosed,
      }}
    >
      {children}
    </HeatMapContext.Provider>
  );
};

HeatMapProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export const useHeatMapContext = () => useContext(HeatMapContext);
