import React, { PureComponent } from 'react';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class CheckBoxField extends PureComponent {
  static propTypes = {
    onChangeFunc: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    labelClassName: PropTypes.string,
    automationId: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    // defaultChecked: false,
    disabled: false,
    className: '',
    color: '',
    labelClassName: '',
    automationId: '',
  };

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const { disabled, className, name, value, onChangeFunc, label, color, labelClassName, automationId } = this.props;
    const CheckboxClass = classNames({
      'checkbox-btn': true,
      disabled,
    });

    const handleClick = () => {
      segmentEvent({
        target: automationId || label || 'CheckBox',
        automationId,
      });
      onChangeFunc();
    };

    return (
      <label className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`}>
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          id={name}
          name={name}
          onChange={handleClick}
          checked={value}
          disabled={disabled}
        />
        <span className="checkbox-btn__checkbox-custom" style={color ? { background: color, borderColor: color } : {}}>
          <CheckIcon />
        </span>
        {className === 'button' ? (
          <span className="checkbox-btn__label-svg">
            <CheckIcon className="checkbox-btn__label-check" />
            <CloseIcon className="checkbox-btn__label-uncheck" />
          </span>
        ) : (
          ''
        )}
        <span className={`checkbox-btn__label ${labelClassName}`}>{label}</span>
      </label>
    );
  }
}

const renderCheckBoxField = (props) => (
  <CheckBoxField
    {...props.input}
    label={props.label}
    defaultChecked={props.defaultChecked}
    disabled={props.disabled}
    className={props.className}
    color={props.color}
  />
);

renderCheckBoxField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onChangeFunc: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

renderCheckBoxField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  className: '',
  color: '',
};

export default CheckBoxField;
