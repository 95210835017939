import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FilterTypes } from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { PAYMENT_OPTIONS, TERMS } from 'commitment/containers/spAnalyzerNew/utils/consts';

const SpAnalyzerContext = createContext();
export const SpAnalyzerProvider = ({ children }) => {
  const paymentOptions = PAYMENT_OPTIONS;
  const terms = TERMS;
  const [recommendationPlanData, setRecommendationPlanData] = useState(null);
  const [planDataByKey, setPlanDataByKey] = useState({});
  const [daysToCheck, setDaysToCheck] = useState(null);
  const [coverage, setCoverage] = useState(null);
  const [hourlyCommitment, setHourlyCommitment] = useState(null);
  const [preferences, setPreferences] = useState(null);
  const [modifiedPlanKeyHourlyCommitment, setModifiedPlanKeyHourlyCommitment] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [filterStatusType, setFilterStatusType] = useState({
    [AwsCommonFields.LINKED_ACCOUNT_ID]: FilterTypes.INCLUDE,
  });

  useEffect(() => {
    if (preferences) {
      setCoverage(preferences.coveragePercentage * 100);
      setDaysToCheck(preferences.daysToCheck);
    }
  }, [preferences]);

  return (
    <SpAnalyzerContext.Provider
      value={{
        filterStatusType,
        recommendationPlanData,
        setRecommendationPlanData,
        setFilterStatusType,
        selectedOptions,
        setSelectedOptions,
        daysToCheck,
        setDaysToCheck,
        coverage,
        setCoverage,
        paymentOptions,
        terms,
        hourlyCommitment,
        setHourlyCommitment,
        modifiedPlanKeyHourlyCommitment,
        setModifiedPlanKeyHourlyCommitment,
        preferences,
        setPreferences,
        planDataByKey,
        setPlanDataByKey,
      }}
    >
      {children}
    </SpAnalyzerContext.Provider>
  );
};

SpAnalyzerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const spAnalyzerContext = () => useContext(SpAnalyzerContext);
