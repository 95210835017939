import React, { useMemo, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { useRolesContext } from './contexts/rolesContext';
import DeleteRolesModal from './roleModals/DeleteRolesModal';

import styles from './RolesBulkOperations.module.scss';

const RolesBulkOperations = ({ roles }) => {
  const [isDeleteRolesModalOpen, setIsDeleteRolesModalOpen] = useState(false);

  const { selectedRows, setSelectedRows } = useRolesContext();

  const selectedRoles = useMemo(() => selectedRows?.map((row) => roles[row]), [selectedRows, roles]);

  const onBulkOperationSuccess = () => {
    setSelectedRows([]);
  };

  return (
    <div className={styles.container}>
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} className={styles.icon} />}
        isTextButton
        onClick={() => setIsDeleteRolesModalOpen(true)}
        text="Delete"
      />
      <DeleteRolesModal
        setIsOpen={setIsDeleteRolesModalOpen}
        isOpen={isDeleteRolesModalOpen}
        roles={selectedRoles}
        onSuccess={onBulkOperationSuccess}
      />
    </div>
  );
};

export default RolesBulkOperations;
