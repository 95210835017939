import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, Label } from 'reactstrap';
import { mapReportDatePeriodToDisplayName, ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import SelectList from 'shared/components/SimpleSelectWithPropList';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import { convertObjToMap } from '../utils/apiUtil';
import CustomModal from './andtComponents/Modal';
import RadioButtonList from './andtComponents/RadioButtonList';

class NewCustomDashboardPanelModal extends PureComponent {
  static propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getCurrentCauParams: PropTypes.func.isRequired,
    customDashboardStore: PropTypes.object.isRequired,
    existingDashboardsNamesAndIds: PropTypes.array.isRequired,
    helpers: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    selectedPanels: PropTypes.object,
    defaultPanelName: PropTypes.string,
    isExistingPanel: PropTypes.bool,
    isCustomDbModeLoading: PropTypes.bool,
    state: PropTypes.object,
    arrBreadCrumbs: PropTypes.object,
    isSelectTimePeriodEnabled: PropTypes.bool,
    usageStore: PropTypes.object.isRequired,
  };
  static defaultProps = {
    selectedPanels: null,
    isExistingPanel: false,
    isCustomDbModeLoading: false,
    state: {},
    arrBreadCrumbs: [],
    isSelectTimePeriodEnabled: true,
    defaultPanelName: '',
  };

  constructor(props) {
    super(props);
    const { selectedPanels = [], type, defaultPanelName } = this.props;
    const selectedPanel = selectedPanels ? selectedPanels[0] || {} : {};
    this.state = {
      customDashboardName: '',
      customDashboardPanelName: selectedPanel.name || defaultPanelName,
      relativeDatesPeriod: ReportPeriodTime.THIRTY_DAYS,
      granularity: 'month',
      isNewDashboard: true,
      existingDashboard: null,
      dashboardNameExistsOrNoNameError: '',
      panelNameExistsOrNoNameError: '',
      savingDashboard: false,
      isPredefined: (type || selectedPanel.type) === 'kpis' && selectedPanel && !selectedPanel.accountId,
    };
  }

  baseState = {
    isModalValid: false,
    customDashboardName: '',
    customDashboardPanelName: '',
    relativeDatesPeriod: ReportPeriodTime.THIRTY_DAYS,
    granularity: 'month',
    isNewDashboard: true,
    existingDashboard: null,
    dashboardNameExistsOrNoNameError: '',
    savingDashboard: false,
  };

  handleCancel = (e) => {
    this.setState({ ...this.baseState });
    this.props.onClose(e);
  };

  toggleNewOrExistingDashboard = (val) => {
    this.setState(
      () => ({ isNewDashboard: val === 'new-db' }),
      () => {
        if (!this.state.isNewDashboard && this.props.existingDashboardsNamesAndIds?.length) {
          const { name, uuid } = this.props.existingDashboardsNamesAndIds[0];
          this.setState({ existingDashboard: { label: name, value: uuid }, dashboardNameExistsOrNoNameError: '' });
        }
        if (this.state.isNewDashboard) {
          this.setState({ existingDashboard: null });
        }
      },
    );
  };
  handleInputChange = (e) => {
    e.preventDefault();
    const attr = e.target.name;
    const val = e.target.value;
    if (attr === 'customDashboardName') {
      let { dashboardNameExistsOrNoNameError } = this.state;
      dashboardNameExistsOrNoNameError = !val ? 'Dashboard name missing' : '';
      const nameExists = this.props.existingDashboardsNamesAndIds
        ?.map(({ name }) => name)
        ?.some((name) => name.toLowerCase() === val.toLowerCase());
      dashboardNameExistsOrNoNameError = nameExists
        ? 'Dashboard name already exists'
        : dashboardNameExistsOrNoNameError;
      this.setState({ [attr]: val, dashboardNameExistsOrNoNameError });
      return;
    }
    let { panelNameExistsOrNoNameError } = this.state;
    panelNameExistsOrNoNameError = !val ? 'Panel name missing' : '';
    const nameExists = this.props.usageStore.customDbSubStore.customDashboardModel.panels.some(
      ({ name, displayStatus }) => name && name.toLowerCase() === val.toLowerCase() && displayStatus,
    );
    panelNameExistsOrNoNameError = nameExists ? 'Panel name already exists' : panelNameExistsOrNoNameError;
    this.setState({ [attr]: val, panelNameExistsOrNoNameError });
  };
  saveNewPanel = async (e) => {
    const {
      customDashboardName,
      customDashboardPanelName,
      relativeDatesPeriod,
      granularity,
      existingDashboard,
      isPredefined,
    } = this.state;
    this.setState({ savingDashboard: true });
    const { selectedPanels, state, arrBreadCrumbs, customDashboardStore } = this.props;
    const selectedPanel = selectedPanels && selectedPanels[0];
    const {
      newCustomDashboardPanelModalIsOpen,
      saveModalName,
      saveModalRelativeDates,
      filtersMap,
      excludedFiltersStatusMap,
      selectedOptionsMap,
      whereParamsMap,
      chartTableData, // dont save table data to panel
      ...rest
    } = state;
    const type = this.props.type || selectedPanels[0].type;
    const baseParams = this.props.helpers.prepareBaseParams({
      customDashboardName,
      existingDashboard,
      customDashboardPanelName,
      type,
      state: {
        ...rest,
        filtersMap: convertObjToMap(filtersMap),
        excludedFiltersStatusMap: convertObjToMap(excludedFiltersStatusMap),
        selectedOptionsMap: convertObjToMap(selectedOptionsMap),
        whereParamsMap: convertObjToMap(whereParamsMap),
        arrBreadCrumbs: arrBreadCrumbs.breadCrumbs,
      },
    });
    if (isPredefined) {
      selectedPanels[0] = await customDashboardStore.createPanelFromTemplate(
        selectedPanel.uuid,
        customDashboardPanelName,
      );
    }
    let preparedParams = {};
    const selectedPanelsUuids = selectedPanels ? selectedPanels.map(({ uuid }) => uuid) : null;
    if (selectedPanels) {
      // creating a new dashboard from existing panel
      preparedParams = { ...baseParams };
    } else {
      const periodParams = this.props.helpers.preparePeriodParams({ relativeDatesPeriod, granularity });
      const routeParams = this.props.getCurrentCauParams();
      preparedParams = this.props.helpers.createNewCustomDashboardPanelObj(baseParams, periodParams, routeParams);
    }
    try {
      await this.props.customDashboardStore.createOrAddNewDashboardPanel(preparedParams, selectedPanelsUuids);
      this.handleCancel(e);
      return;
    } catch (error) {
      console.log(error);
      this.setState({ savingDashboard: false });
    }
    this.handleCancel(e);
  };
  validateForm = (e) => {
    const { customDashboardPanelName, customDashboardName, isNewDashboard } = this.state;
    let valid = true;
    if (!customDashboardName && isNewDashboard) {
      this.setState({ dashboardNameExistsOrNoNameError: 'Dashboard name missing' });
      valid = false;
    }
    if (!customDashboardPanelName) {
      this.setState({ panelNameExistsOrNoNameError: 'Panel name missing' });
      valid = false;
    }

    if (!valid) {
      throw new Error('form validation failed');
    } else {
      this.saveNewPanel(e);
    }
  };
  handleSelectExistingDashboardChange = (filterType, selectedOption) => {
    this.setState({ existingDashboard: selectedOption });
  };

  renderNewOrExistingRadioButtons = () => (
    <>
      <h5 style={{ textAlign: 'left', width: '100%' }}>Dashboard</h5>
      <RadioButtonList
        direction="row"
        options={[
          {
            value: 'new-db',
            label: 'New',
            primary: true,
          },
          {
            value: 'existing-db',
            label: 'Existing',
            primary: true,
          },
        ]}
        className="mb-4 mt-2"
        value={this.state.isNewDashboard ? 'new-db' : 'existing-db'}
        onChange={(val) => {
          this.toggleNewOrExistingDashboard(val);
        }}
      />
    </>
  );

  renderNewDashboardOrExistingInput = (isNewDashboard, existingDashboards) => {
    const { selectedPanels, usageStore } = this.props;
    const validDashbaordsList = selectedPanels?.length
      ? usageStore.customDbSubStore.customDashboardModel.removeDashboardsWherePanelAlreadyExists(
          existingDashboards,
          selectedPanels[0] && selectedPanels[0].uuid,
        )
      : existingDashboards;
    const existingDashboardsOptions = validDashbaordsList?.map((db) => ({ label: db.name, value: db.uuid }));
    const defaultValue = existingDashboardsOptions?.length && existingDashboardsOptions[0];
    if (isNewDashboard) {
      return (
        <InputGroup size="sm">
          <h5 style={{ textAlign: 'left', width: '100%' }}>
            <Label for="customDashboardName">Dashboard Name</Label>
          </h5>
          <Input
            type="text"
            bsSize="sm"
            className="general-input"
            name="customDashboardName"
            id="customDashboardName"
            formnovalidate="formnovalidate"
            placeholder="Enter Dashboard Name"
            onChange={(event) => this.handleInputChange(event)}
            value={this.state.customDashboardName}
            autocomplete="customDashboardName"
          />
        </InputGroup>
      );
    }
    return (
      <div style={{ marginTop: '15px', width: '100%' }}>
        <OneChoiceFieldFilter
          type="existingDashboard"
          value={this.state.existingDashboard || defaultValue}
          options={existingDashboardsOptions}
          handleChange={this.handleSelectExistingDashboardChange}
        />
      </div>
    );
  };

  render() {
    const {
      modalIsOpen,
      isExistingPanel,
      isCustomDbModeLoading,
      isSelectTimePeriodEnabled,
      selectedPanels,
      existingDashboardsNamesAndIds,
    } = this.props;
    const {
      customDashboardPanelName,
      dashboardNameExistsOrNoNameError,
      relativeDatesPeriod,
      panelNameExistsOrNoNameError,
      isNewDashboard,
      savingDashboard,
      isPredefined,
    } = this.state;
    if (savingDashboard || isCustomDbModeLoading) {
      return <Spinner />;
    }
    const periodListItems = Object.values(ReportPeriodTime).map((period) => period);
    return (
      <CustomModal
        open={modalIsOpen}
        title="New Custom Dashboard Panel"
        onClose={this.handleCancel}
        onSave={this.validateForm}
        saveDisabled={dashboardNameExistsOrNoNameError || panelNameExistsOrNoNameError}
      >
        <>
          <div>{this.renderNewOrExistingRadioButtons()}</div>
          <div>{this.renderNewDashboardOrExistingInput(isNewDashboard, existingDashboardsNamesAndIds)}</div>
          {dashboardNameExistsOrNoNameError && (
            <div>
              <p style={{ color: 'red', marginTop: '15px' }}>{dashboardNameExistsOrNoNameError}</p>
            </div>
          )}
          <br />
          <hr />
          <div>
            <InputGroup size="sm">
              <h5 style={{ textAlign: 'left', width: '100%' }}>
                <Label for="customDashboardPanelName">Panel Name</Label>
              </h5>
              <Input
                type="text"
                bsSize="sm"
                className="general-input"
                name="customDashboardPanelName"
                id="customDashboardPanelName"
                formnovalidate="formnovalidate"
                placeholder="Enter Panel Name"
                onChange={(event) => this.handleInputChange(event)}
                value={customDashboardPanelName}
                autocomplete="customDashboardPanelName"
                disabled={selectedPanels && !isPredefined}
              />
            </InputGroup>
            {panelNameExistsOrNoNameError && (
              <div>
                <p style={{ color: 'red', marginTop: '15px' }}>{panelNameExistsOrNoNameError}</p>
              </div>
            )}
            {!isExistingPanel && isSelectTimePeriodEnabled && (
              <div style={{ marginTop: '15px' }}>
                <SelectList
                  handler={this.handleInputChange}
                  name="relativeDatesPeriod"
                  value={relativeDatesPeriod}
                  placeHolder="Select Panel Time Period"
                  label="Time Period:"
                  mapValuesToDescription={mapReportDatePeriodToDisplayName}
                  arrValues={periodListItems}
                  selectStyle={{ width: '100%' }}
                />
              </div>
            )}
          </div>
        </>
      </CustomModal>
    );
  }
}

export default NewCustomDashboardPanelModal;
