/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import DismissRecModal from './DismissRecResModal';

export default class DismissRecButton extends Component {
  static propTypes = {
    rec: PropTypes.object.isRequired,
    recExcludeHandler: PropTypes.func.isRequired,
    afterActionHandler: PropTypes.func.isRequired,
    cloudTypeId: PropTypes.number.isRequired,
  };

  render() {
    return (
      <div>
        <Button
          onClick={() => {
            this._dismissRecModal.toggle();
          }}
          size="sm"
          className="icon icon--right recs__dismiss-btn"
          style={{ backgroundColor: '#ffff' }}
          color="#ffff"
        >
          Exclude...
        </Button>
        <DismissRecModal
          rec={this.props.rec}
          recExcludeHandler={this.props.recExcludeHandler}
          afterActionHandler={this.props.afterActionHandler}
          cloudTypeId={this.props.cloudTypeId}
          ref={(dismissRecModal) => {
            this._dismissRecModal = dismissRecModal;
          }}
        />
      </div>
    );
  }
}
