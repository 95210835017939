import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from '../../../shared/components/ActionComponents';

const Action = ({ rec }) => (
  <ActionContainer>
    <ActionHeader />
    <ul style={{ 'list-style-type': 'none' }}>
      <li>Using the AZURE CLI:</li>
      <CodeBlock
        text="az snapshot delete --name {{resourceName}} --resource-group {{resourceGroup}} --subscription {{linkedAccountId}}"
        variables={{
          resourceGroup: rec.resourceGroup,
          resourceName: rec.resourceName,
          linkedAccountId: rec.linkedAccountId,
        }}
      />
      <br />
    </ul>
  </ActionContainer>
);

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
