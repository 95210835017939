import React, { useState } from 'react';
import { StatusSelection } from './statusSelection';

import classes from './statusOptionTemplate.module.scss';

export const StatusOptionTemplate = ({ option, isSelected, onOptionSelect, isExpandable, hideSelection, children }) => {
  const [isExpanded, setIsExpanded] = useState(isExpandable && isSelected);

  const childrenWithProp = () =>
    isExpanded
      ? React.Children.map(children, (child) => React.cloneElement(child, { ...child.props, isExpanded }))
      : null;

  const onOptionClick = () => {
    setIsExpanded(true);
    onOptionSelect(option);
  };

  return (
    <div className={classes.statusFilterTitleAndSelectContainer}>
      {!hideSelection ? (
        <StatusSelection
          isSelected={isSelected}
          onClick={onOptionClick}
          automationId={`status-filter-selection-for-${option.id}-option`}
        />
      ) : (
        <div />
      )}
      <div className={classes.optionContainer}>
        <div className={classes.titleContainer}>
          <div>{option.label}</div>
        </div>
        <div className={classes.filtersContainer}>{isExpandable ? childrenWithProp() : children}</div>
      </div>
    </div>
  );
};
