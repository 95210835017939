/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { SpotRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class SpotEc2CostRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new SpotRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_SPOT;
  }

  buildRecommTitle() {
    const { suggested_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action} EC2 Spot Instances`;
  }

  build() {
    super.build();
    const {
      environment_group_name: environment,
      // total_usage_in_hours_current: currTotalUsageInHours,
      // saving_over_on_demand_precent_suggested_spot: recommSavingOverOnDemand,
      frequency_label: interruptionFreq,
    } = this._rawRecommendation;
    this._recommendation.environment = environment;
    this._recommendation.interruptionFreq = interruptionFreq;
  }
}
