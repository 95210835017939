import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './Avatar.module.scss';

const Avatar = ({ userName }) => (
  <Tooltip title={userName}>
    <span className={styles.container}>
      {userName
        .split(' ')
        .map((word) => word.slice(0, 1))
        .join('')
        .slice(0, 2)}
    </span>
  </Tooltip>
);

Avatar.propTypes = {
  userName: PropTypes.string,
};

Avatar.defaultProps = {
  userName: 'No Name',
};

export default Avatar;
