import { mapRecommendationsTypeToDisplay } from '../../constants/recommendationsConstants';

export const typeFormatter = (data) => {
  const formattedKey =
    mapRecommendationsTypeToDisplay.get(data.preferenceKey) ||
    data.preferenceKey
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  return formattedKey;
};
