import React from 'react';
import PropTypes from 'prop-types';
import {
  RI_COVERAGE_COLUMN_WIDTHS,
  RI_COVERAGE_CURRENCIES_COLUMNS,
  RI_COVERAGE_DEFAULT_HIDDEN_COLUMN_NAMES,
  RI_COVERAGE_DEFAULT_SORTING,
  RI_COVERAGE_TABLE_COLUMN_EXTENSIONS,
  RI_COVERAGE_TABLE_COLUMNS,
} from 'usage/containers/Ec2Usage/constants/RiCoverageTableConstants';
import DateFilter from 'shared/modules/dateFilter';
import DevExpFilterColumnTable from './DevExpFilterColumnTable';

const RiCoverageTable = ({ data }) => (
  <>
    <DevExpFilterColumnTable
      data={data}
      columns={RI_COVERAGE_TABLE_COLUMNS}
      columnWidths={RI_COVERAGE_COLUMN_WIDTHS}
      tableColumnExtensions={RI_COVERAGE_TABLE_COLUMN_EXTENSIONS}
      defaultHiddenColumnNames={RI_COVERAGE_DEFAULT_HIDDEN_COLUMN_NAMES}
      defaultSorting={RI_COVERAGE_DEFAULT_SORTING}
      currenciesColumns={RI_COVERAGE_CURRENCIES_COLUMNS}
      csvTitle={`RI_Coverage_Data_${DateFilter.month}_${DateFilter.year}`}
    />
  </>
);

export default RiCoverageTable;
RiCoverageTable.propTypes = {
  data: PropTypes.string.isRequired,
};
