import React from 'react';

import Video from '../../assets/CUR.mp4';
import commonStyles from '../Pages.module.scss';
import styles from './InstructionsPage.module.scss';

const InstructionsPage = () => (
  <div className={styles.container}>
    <p className={commonStyles.description}>Watch the tutorial for step-by-step instructions.</p>
    <div className={styles.content}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video width={560} height={316} controls="controls" className={styles.video}>
        <source src={Video} type="video/mp4" />
      </video>
    </div>
  </div>
);

export default InstructionsPage;
