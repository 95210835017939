import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SimpleRadialChart from 'commitment/containers/SpAnalyzer/components/SimpleRadialChart';
import { Col, Row } from 'reactstrap';
import UserDataContext from 'users/utils/contexts/UserSettingsContext';
import Tooltip from '@mui/material/Tooltip';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';

const SavingsChart = ({ potentialCost, currCost, percentages }) => {
  const { currencyCode, getCurrencyNumber } = useContext(UserDataContext);
  const innerRadiusText = `${!percentages || percentages === (Infinity || -Infinity) ? 0 : Math.trunc(percentages)}%`;
  return (
    <Row style={{ maxWidth: '400px' }}>
      <Col>
        <SimpleRadialChart percentages={percentages} innerRadiusText={innerRadiusText} />
      </Col>
      <Col style={{ padding: '5% 0' }}>
        <div className="savigns-chart__content-container">
          <h5 className="savigns-chart__header">
            {currencyCode && currencyCode !== CURRENCY_DETAILS.USD ? (
              <Tooltip title={CURRENCY_DETAILS.SAVING_CALCULATE_TOOLTIP}>
                <span>Potential Savings</span>
              </Tooltip>
            ) : (
              'Potential Savings'
            )}
          </h5>
          <h5 className="savigns-chart__text">
            Monthly On-Demand Cost: {getCurrencyNumber(currCost / 12, 0, { roundNumber: true })}
          </h5>
          <h5 className="savigns-chart__text">
            Monthly Potential Cost: {getCurrencyNumber(potentialCost / 12, 0, { roundNumber: true })}
          </h5>
          <h5 className="savigns-chart__text summery">
            Monthly Savings:{' '}
            {getCurrencyNumber(potentialCost ? (currCost - potentialCost) / 12 : 0, 0, { roundNumber: true })}
          </h5>
        </div>
      </Col>
    </Row>
  );
};
export default SavingsChart;

SavingsChart.propTypes = {
  percentages: PropTypes.number.isRequired,
  potentialCost: PropTypes.number.isRequired,
  currCost: PropTypes.number.isRequired,
};
