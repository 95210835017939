import React from 'react';

import classes from './cloudWasteChartLegend.module.scss';

export const CloudWasteChartLegend = ({ payload }) => {
  const uniqueArray = payload.filter((item, index) => payload?.findIndex((t) => t.dataKey === item.dataKey) === index);

  return (
    <div className={classes.customizedLegend}>
      {uniqueArray.map((entry) => {
        const entryId = `${entry.payload.id}Legend`;
        return (
          <div className={classes.legendPair} id={entryId}>
            <div className={classes.legendSymbol} style={{ backgroundColor: entry.color }} />
            <div className={classes.legendLabel}>{entry.value}</div>
          </div>
        );
      })}
    </div>
  );
};
