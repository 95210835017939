import React from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import styles from './channels.module.scss';

export default function DeleteChannelModal({ isOpen, onClose, onDelete, title, content }) {
  return (
    <CustomModal
      onClose={onClose}
      onCloseClick={onClose}
      open={isOpen}
      headerMode={MODAL_MODES.SIMPLE_NEW}
      onSave={onDelete}
      automationId="delete-role-modal"
      saveTitle={
        <>
          <GenerateIcon iconName={ICONS.deleteRegular.name} /> Delete
        </>
      }
      saveWarning
      title={title}
    >
      <div className={styles.deleteBulkChannels}>
        <div>{content}</div>
      </div>
    </CustomModal>
  );
}
