import moment from 'moment';
import LabelCoordinator from 'shared/modules/labelCoordinator';

const getCreditCustomerName = (divisionName) => {
  if (divisionName === 'Internal_Pileus:Account_id:All_Customers') {
    return 'All Customers';
  }
  return LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', divisionName);
};

const getFormattedDate = (date) => (date ? moment(date).format('YYYY-MM') : '');

export const creditsColumns = [
  { name: 'name', title: 'Credit Name' },
  {
    name: 'divisionName',
    title: 'Customer',
    getCellValue: ({ divisionName }) => getCreditCustomerName(divisionName || ''),
  },
  { name: 'amount', title: 'Amount' },
  { name: 'monthlyAmountLimit', title: 'Monthly limit' },
  { name: 'amountUsed', title: 'Amount used' },
  { name: 'amountLeft', title: 'Amount left', getCellValue: ({ amountUsed, amount }) => +amount - +amountUsed },
  { name: 'startDate', title: 'Start month', getCellValue: ({ startDate }) => getFormattedDate(startDate) },
  {
    name: 'expirationDate',
    title: 'End month',
    getCellValue: ({ expirationDate }) => getFormattedDate(expirationDate),
  },
  { name: 'alert', title: 'Alert' },
  { name: 'userActions', title: ' ' },
];
export const creditsColumnsWidths = [
  { columnName: 'name', width: '276px' },
  {
    columnName: 'divisionName',
    width: '15%',
  },
  { columnName: 'amount', width: '130px' },
  { columnName: 'monthlyAmountLimit', width: '10%' },
  { columnName: 'amountUsed', width: '10%' },
  { columnName: 'amountLeft', width: '10%' },
  { columnName: 'startDate', width: '7%' },
  { columnName: 'expirationDate', width: '7%' },
  { columnName: 'alert', width: '5%' },
  { columnName: 'userActions', width: '5%' },
];

export const keysToTitle = {
  divisionName: 'Customers',
  service: 'Services',
  includeService: 'Services',
  excludeService: 'Exclude Services',
  excludeAwsMarketplace: 'Exclude Aws Marketplace',
  alerts: 'Credit Alerts',
};

export const CREDIT_ERROR_MESSAGES = {
  EMPTY_NAME: 'Credit name is mandatory',
  TOO_LONG_NAME: 'Credit name should be less than 100 characters',
  DUPLICATE_NAME: "You can't use old credit name",
  CUSTOMERS: 'Customer filter is mandatory',
  SERVICE: 'Service filter is mandatory',
  SERVICE_RELATION: 'Service filter relation is mandatory',
  AMOUNT: 'Credit amount should be greater than 0',
  MONTHLY_AMOUNT_LIMIT: 'Credit monthly amount limit should be less than or equal to the credit amount',
  EMPTY_START_DATE: 'Please selet credit start date',
  OLD_START_DATE: 'Credit start date should be GTE to',
  EXPIRATION_DATE: 'Expiration date should be greater than start date',
};

export const ALERT_ERROR_MESSAGES = {
  EMPTY_RECIPIENTS: 'Recipient is mandatory',
  INVALID_RECIPIENTS: 'One or more recipients email are not valid',
  UTILIZATION_PERCENTAGE: 'Utilization percentage is mandatory',
  UTILIZATION_AMOUNT: 'Utilization amount is mandatory',
};
