/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { CostTypeModes } from 'usage/constants/costAndUsageConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import ButtonMultiDropdown from 'shared/components/andtComponents/ButtonMultiDropdown';
import icons from '../icons';
import RateBlock from './RateBlock';
import styles from './CostTypeMenu.module.scss';

const { CostIcon, UsageIcon, RateIcon } = icons.costUsage;

const getLabel = (value, type) => {
  if (!type) {
    return value;
  }
  if (!value || value === type) {
    return type;
  }
  return `${type}: ${value}`;
};

const CostTypeMenu = ({ costUsageHandler }) => {
  const {
    costOptions = [],
    costValue,
    usageOptions = [],
    usageValue,
    onChange,
    costTypeMode,
    isRateDisabled,
    costDefaultType,
  } = costUsageHandler;
  const costOptionName = (costOptions.find((c) => c.value === costValue) || {}).name || costDefaultType || 'Cost';
  const usageOptionName = ((usageOptions || []).find((c) => c.value === usageValue) || {}).name;
  const isCostContainOptions = !isEmptyArray(costOptions);
  const isUsage = costTypeMode === CostTypeModes.USAGE;
  const isCost = costTypeMode === CostTypeModes.COST;
  const isRate = costTypeMode === CostTypeModes.RATE;
  const labelByType = {
    [CostTypeModes.COST]: costOptionName,
    [CostTypeModes.USAGE]: usageOptionName,
    [CostTypeModes.RATE]: 'Rate',
  };
  const tooltipByType = {
    [CostTypeModes.COST]: getLabel(costOptionName, 'Cost'),
    [CostTypeModes.USAGE]: getLabel(usageOptionName, 'Usage'),
    [CostTypeModes.RATE]: getLabel(
      `${costOptionName === 'Cost' ? costValue : costOptionName} / ${usageOptionName}`,
      'Rate',
    ),
  };
  const options = [
    {
      id: 'cost',
      label: getLabel(isCost ? costOptionName : null, costDefaultType || 'Cost'),
      selected: isCost,
      icon: CostIcon,
      simpleOption: !isCostContainOptions,
      onClick: isCostContainOptions ? null : () => onChange(costValue, null, CostTypeModes.COST),
      content: isCostContainOptions ? (
        <>
          {costOptions.map((o) => (
            <li
              onClick={() => {
                onChange(o.value, null, CostTypeModes.COST);
              }}
              data-selected={o.value === costValue && isCost}
              key={o.value}
              id={o.value}
            >
              <span>{o.name}</span>
            </li>
          ))}
        </>
      ) : null,
    },
  ];
  if (usageOptions) {
    options.push({
      id: 'usage',
      label: getLabel(isUsage ? usageOptionName : null, 'Usage'),
      selected: isUsage,
      icon: UsageIcon,
      content: (
        <>
          {usageOptions.map((o) => (
            <li
              onClick={() => {
                onChange(null, o.value, CostTypeModes.USAGE);
              }}
              data-selected={o.value === usageValue && isUsage}
              key={o.value}
              id={o.value}
            >
              <span className="w-100">
                {o.name}
                {o.tooltip ? (
                  <span className="ms-auto">
                    <InfoPopover isSimple>{o.tooltip}</InfoPopover>
                  </span>
                ) : null}
              </span>
            </li>
          ))}
        </>
      ),
    });
  }
  if (!isRateDisabled) {
    options.push({
      id: 'rate',
      label: 'Rate',
      selected: isRate,
      icon: RateIcon,
      content: <RateBlock costUsageHandler={costUsageHandler} />,
    });
  }
  return (
    <div className={styles.container}>
      <ButtonMultiDropdown
        dropdownProps={{
          text: labelByType[costTypeMode],
          icon: icons.menu.costTypeIcon,
          tooltipText: tooltipByType[costTypeMode],
          overrideMenuStyles: { minWidth: 200 },
        }}
        className={styles.dropDown}
        items={options}
        automationId="costTypeMenu"
      />
    </div>
  );
};

CostTypeMenu.propTypes = {
  costUsageHandler: PropTypes.object.isRequired,
};

export default CostTypeMenu;
