import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Button from 'shared/components/andtComponents/Button';
import {
  mapDisplayRecommendationsTypeToType,
  mapRecommendationsTypeToDisplay,
} from 'recommendations/constants/recommendationsConstants';
import { propComparator } from 'shared/utils/sortUtil';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import FieldFilter from 'shared/components/FieldFilter';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { prepareLinkAccountLabel } from '../helpers/dataPrepareHelpers';

const mapDisplayToType = new Map(mapDisplayRecommendationsTypeToType.entries());
mapDisplayToType.set('All', 'All');
const mapTypeToDisplay = new Map(mapRecommendationsTypeToDisplay.entries());
mapTypeToDisplay.set('All', 'All');

class RecommendationFilterBar extends PureComponent {
  static propTypes = {
    handleApplyFiltersButtonClick: PropTypes.func.isRequired,
    filterSelectedValues: PropTypes.object,
    filterValues: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    recDbCustomTagsFetcher: PropTypes.object.isRequired,
    accountTagsFetcher: PropTypes.object.isRequired,
    getAccountTagsKeys: PropTypes.func.isRequired,
  };
  static defaultProps = {
    filterSelectedValues: new Map(),
  };
  state = {
    filterSelectedValues: this.props.filterSelectedValues || new Map(),
    // actual selected values - type, status and linked Accounts
  };
  handleFilterChange = (filterType, selectedOptions) => {
    const { filterSelectedValues } = this.state;
    const newSelectedOptionsMap = new Map(filterSelectedValues);
    if (selectedOptions && selectedOptions.length) {
      newSelectedOptionsMap.set(filterType, selectedOptions);
    } else if (newSelectedOptionsMap.has(filterType)) {
      newSelectedOptionsMap.delete(filterType);
    }
    this.setState({ filterSelectedValues: newSelectedOptionsMap });
  };
  createOptions = (fieldValues, filterType) =>
    fieldValues.map((fieldValue) => {
      let value = '';
      let label = '';
      if (filterType === 'linkedaccid') {
        value = fieldValue.linkedAccountId;
        label = fieldValue.displayLabel;
      } else {
        value = fieldValue;
        label = fieldValue;
      }
      return { value, label };
    });

  renderFilter = (title, filter, width) => (
    <Col sm={width} xs={12}>
      <div className="w-100">
        <h5 className="filter-bar-inner-col-title mb-3">{title}</h5>
        {filter}
      </div>
    </Col>
  );

  render() {
    const {
      filterValues,
      handleApplyFiltersButtonClick,
      usersStore,
      recDbCustomTagsFetcher,
      accountTagsFetcher,
      getAccountTagsKeys,
    } = this.props;
    const { filterSelectedValues } = this.state;

    const linkedAccountsOptions =
      filterValues && filterValues.has('linkedaccid')
        ? this.createOptions(filterValues.get('linkedaccid'), 'linkedaccid')
        : [];
    const accountTagsOptions = accountTagsFetcher?.values
      ? this.createOptions(accountTagsFetcher.values, AwsCommonFields.ACCOUNT_TAGS)
      : null;
    const tags = recDbCustomTagsFetcher?.values
      ? this.createOptions(recDbCustomTagsFetcher.values, 'recdbcustomtags')
      : [];
    const accountTagsPresent = getAccountTagsKeys()?.length > 0;
    return (
      <Row>
        {this.renderFilter(
          prepareLinkAccountLabel(usersStore.currDispUserCloudAccountType, false),
          <FieldFilter
            type="linkedaccid"
            value={filterSelectedValues.get('linkedaccid')}
            options={
              linkedAccountsOptions ? linkedAccountsOptions.sort(propComparator('label')) : linkedAccountsOptions
            }
            handleChange={this.handleFilterChange}
            isLoading={linkedAccountsOptions.length === 0}
            automationId="linkedAccId"
          />,
          accountTagsPresent ? 4 : 5,
        )}
        {this.renderFilter(
          'Tags',
          <FieldFilter
            type="customtags"
            value={filterSelectedValues.get('customtags')}
            options={tags ? tags.sort(propComparator('label')) : tags}
            handleChange={this.handleFilterChange}
            onLoad={recDbCustomTagsFetcher.onLoad}
            isLoading={recDbCustomTagsFetcher.isLoading}
            isAutoComplete
            placeHolder="Type to search"
            automationId="customTags"
          />,
          accountTagsPresent ? 3 : 5,
        )}
        {accountTagsPresent &&
          this.renderFilter(
            'Enrichment Tags',
            <FieldFilter
              type={AwsCommonFields.ACCOUNT_TAGS}
              value={filterSelectedValues.get(AwsCommonFields.ACCOUNT_TAGS)}
              options={(accountTagsOptions || []).sort(propComparator('label'))}
              handleChange={this.handleFilterChange}
              onLoad={accountTagsFetcher.onLoad}
              isLoading={accountTagsFetcher.isLoading}
              isAutoComplete
              placeHolder="Type to search"
              automationId="enrichmentTags"
            />,
            accountTagsPresent ? 3 : 5,
          )}
        <Col
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flext-start',
          }}
          xs={2}
        >
          <Button
            onClick={() => handleApplyFiltersButtonClick(filterSelectedValues)}
            text="Apply"
            automationId="recommendationsFiltersApply"
          />
        </Col>
      </Row>
    );
  }
}

export default withInvoiceFiltersContextConsumer(RecommendationFilterBar);
