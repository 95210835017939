import React, { useState } from 'react';
import { ReactComponent as add } from 'shared/img/icons/add.svg';
import { ReactComponent as addBlue } from 'shared/img/icons/add-blue.svg';
import { ReactComponent as orderAsc } from 'shared/img/icons/order-asc.svg';
import Button from 'shared/components/andtComponents/Button';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import SwitchButton from 'shared/components/andtComponents/Switch';
import { palette } from 'shared/constants/colorsConstants';
import styles from './DesignSystem.module.scss';
import ButtonDropdown from '../andtComponents/ButtonDropdown';
import ButtonMultiDropdown from '../andtComponents/ButtonMultiDropdown';
import CustomModal from '../andtComponents/Modal';
import Chip, { CHIP_COLORS } from '../andtComponents/Chip';

const DesignSystem = () => {
  const buttonText = 'Button text';
  const buttonIcon = add;
  const buttonIconBlue = addBlue;
  const buttonAction = () => {
    alert('clicked');
  };

  const buttonSection = (
    <div className={styles.component} id="button">
      <h2>Button</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <Button onClick={buttonAction} icon={buttonIcon} text="Button text" />
        <Button onClick={buttonAction} text={buttonText} />
        <Button onClick={buttonAction} icon={buttonIcon} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Loading :</span>
        <Button isLoading onClick={buttonAction} icon={buttonIcon} text={buttonText} />
        <Button isLoading onClick={buttonAction} text={buttonText} />
        <Button isLoading onClick={buttonAction} icon={buttonIcon} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Disabled:</span>
        <Button onClick={buttonAction} disabled icon={buttonIcon} text={buttonText} />
        <Button onClick={buttonAction} disabled text={buttonText} />
        <Button onClick={buttonAction} disabled icon={buttonIcon} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Disabled loading:</span>
        <Button onClick={buttonAction} disabled isLoading icon={buttonIcon} text={buttonText} />
        <Button onClick={buttonAction} disabled isLoading text={buttonText} />
        <Button onClick={buttonAction} disabled isLoading icon={buttonIcon} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Ghost :</span>
        <Button onClick={buttonAction} isGhost icon={buttonIconBlue} text={buttonText} />
        <Button onClick={buttonAction} isGhost text={buttonText} />
        <Button onClick={buttonAction} isGhost icon={buttonIconBlue} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Ghost loading:</span>
        <Button onClick={buttonAction} isGhost isLoading icon={buttonIconBlue} text={buttonText} />
        <Button onClick={buttonAction} isGhost isLoading text={buttonText} />
        <Button onClick={buttonAction} isGhost isLoading icon={buttonIconBlue} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Ghost disabled:</span>
        <Button onClick={buttonAction} disabled isGhost icon={buttonIconBlue} text={buttonText} />
        <Button onClick={buttonAction} disabled isGhost text={buttonText} />
        <Button onClick={buttonAction} disabled isGhost icon={buttonIconBlue} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Ghost disabled loading:</span>
        <Button onClick={buttonAction} disabled isLoading isGhost icon={buttonIconBlue} text={buttonText} />
        <Button onClick={buttonAction} disabled isLoading isGhost text={buttonText} />
        <Button onClick={buttonAction} disabled isLoading isGhost icon={buttonIconBlue} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Error :</span>
        <Button onClick={buttonAction} isError icon={buttonIcon} text={buttonText} />
        <Button onClick={buttonAction} isError text={buttonText} />
        <Button onClick={buttonAction} isError icon={buttonIcon} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Error Loading:</span>
        <Button onClick={buttonAction} isError isLoading icon={buttonIcon} text={buttonText} />
        <Button onClick={buttonAction} isError isLoading text={buttonText} />
        <Button onClick={buttonAction} isError isLoading icon={buttonIcon} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Text button:</span>
        <Button onClick={buttonAction} isTextButton icon={orderAsc} text={buttonText} />
        <Button onClick={buttonAction} isTextButton text={buttonText} />
        <Button onClick={buttonAction} isTextButton icon={orderAsc} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Text button loading:</span>
        <Button onClick={buttonAction} isLoading isTextButton icon={orderAsc} text={buttonText} />
        <Button onClick={buttonAction} isLoading isTextButton text={buttonText} />
        <Button onClick={buttonAction} isLoading isTextButton icon={orderAsc} />
      </div>
    </div>
  );
  const spinnerSection = (
    <div className={styles.component} id="spinner">
      <h2>Spinner</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.X_BIG} />
        </div>
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Size :</span>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.X_SMALL} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.SMALL} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.MEDIUM} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.BIG} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.X_BIG} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.XX_BIG} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner size={SPINNER_SIZES.XXX_BIG} />
        </div>
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Colors :</span>
        <div className={styles.spinnerWrapper}>
          <Spinner color={palette.yellow[500]} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner color={palette.gray[500]} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner color={palette.blue[500]} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner color={palette.red[500]} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner color={palette.green[500]} />
        </div>
        <div className={styles.spinnerWrapper}>
          <Spinner color={palette.purple[500]} />
        </div>
      </div>
    </div>
  );
  const checkboxSection = (
    <div className={styles.component} id="checkbox">
      <h2>Checkbox</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <Checkbox isChecked text="Checked" onChange={() => {}} />
        <Checkbox isChecked={false} text="Unchecked" onChange={() => {}} />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Disabled :</span>
        <Checkbox isDisabled isChecked text="Checked" onChange={() => {}} />
        <Checkbox isDisabled isChecked={false} text="Unchecked" onChange={() => {}} />
      </div>
    </div>
  );
  const radioButtonSection = (
    <div className={styles.component} id="radio-button">
      <h2>Radio Button</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <RadioButtonList
          options={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2, disabled: true },
          ]}
          value={1}
          onChange={() => {}}
        />
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Color :</span>
        <RadioButtonList
          options={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2, disabled: true },
          ]}
          value={1}
          onChange={() => {}}
        />
        <RadioButtonList
          options={[
            { label: 'Option 1', value: 1, primary: true },
            { label: 'Option 2', value: 2, primary: true, disabled: true },
          ]}
          value={1}
          onChange={() => {}}
        />
      </div>
    </div>
  );
  const switchSection = (
    <div className={styles.component} id="switch">
      <h2>Switch</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <SwitchButton label="Switched" isChecked onChange={() => {}} />
        <SwitchButton label="Not Switched" isChecked={false} onChange={() => {}} />
      </div>
    </div>
  );
  const buttonDropdownSection = (
    <div className={styles.component} id="buttonDropdown">
      <h2>Button Dropdown</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <ButtonDropdown text="Button text" icon={null}>
          <li data-disabled onClick={(e) => e.stopPropagation()}>
            <div>Long option name</div>
          </li>
          <li onClick={buttonAction}>
            <div>Option</div>
          </li>
        </ButtonDropdown>
        <ButtonDropdown text="" icon={buttonIconBlue}>
          <li onClick={buttonAction}>
            <span>Long option name</span>
          </li>
          <li onClick={buttonAction}>
            <span>Option</span>
          </li>
          <li onClick={buttonAction} data-selected>
            <span>Option 3</span>
          </li>
        </ButtonDropdown>
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Loading :</span>
        <ButtonDropdown text="Button text" icon={null} isLoading>
          <li data-disabled onClick={(e) => e.stopPropagation()}>
            <div>Long option name</div>
          </li>
          <li onClick={buttonAction}>
            <div>Option</div>
          </li>
        </ButtonDropdown>
        <ButtonDropdown text="" icon={buttonIconBlue} isLoading disabled>
          <li onClick={buttonAction}>
            <span>Long option name</span>
          </li>
          <li onClick={buttonAction}>
            <span>Option</span>
          </li>
          <li onClick={buttonAction} data-selected>
            <span>Option 3</span>
          </li>
        </ButtonDropdown>
      </div>
      <div className={styles.line}>
        <span className={styles.description}>With Tooltip :</span>
        <ButtonDropdown icon={buttonIconBlue} tooltipText="Description">
          <li>
            <Checkbox isChecked onChange={() => {}} text="Option with checkbox 1" />
          </li>
          <li>
            <Checkbox onChange={() => {}} text="Option with checkbox 2" />
          </li>
          <li>
            <Checkbox isChecked onChange={() => {}} text="Option with checkbox 3" />
          </li>
        </ButtonDropdown>
      </div>
      <div className={styles.line}>
        <span className={styles.description}>Don`t close on select :</span>
        <ButtonDropdown icon={buttonIconBlue} closeOnSelect={false}>
          <li>
            <Checkbox isChecked onChange={() => {}} text="Option with checkbox 1" />
          </li>
          <li>
            <Checkbox onChange={() => {}} text="Option with checkbox 2" />
          </li>
          <li>
            <Checkbox isChecked onChange={() => {}} text="Option with checkbox 3" />
          </li>
        </ButtonDropdown>
      </div>
    </div>
  );
  const buttonMultiDropdownSection = (
    <div className={styles.component} id="buttonMultiDropdown">
      <h2>Button Multi Dropdown</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <ButtonMultiDropdown
          dropdownProps={{
            text: 'Dropdown',
          }}
          items={[
            {
              id: 1,
              label: 'First option',
              content: (
                <>
                  <li>
                    <span>Long option name</span>
                  </li>
                  <li>
                    <span>Option</span>
                  </li>
                  <li>
                    <Checkbox isChecked onChange={() => {}} text="Option with checkbox" />
                  </li>
                </>
              ),
            },
            {
              id: 2,
              label: 'Second option with long name',
              selected: true,
              content: (
                <>
                  <li>
                    <span>Val 1</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li>
                    <span>Val 2</span>
                  </li>
                  <li data-selected>
                    <span>Option with long name selected</span>
                  </li>
                </>
              ),
            },
            {
              id: 3,
              label: 'Option without sub menu',
              simpleOption: true,
              onClick: buttonAction,
            },
          ]}
        />
      </div>
    </div>
  );
  const [modal1Open, setModal1Open] = useState(false);
  const modalSection = (
    <div className={styles.component} id="modal">
      <h2>Modal</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <Button text="Open Modal" onClick={() => setModal1Open(true)} />
        <CustomModal
          open={modal1Open}
          onClose={setModal1Open}
          onSave={() => {}}
          title="Simple Modal"
          menuContent={
            <>
              <li>
                <span>Val 1</span>
              </li>
              <li>
                <span>Val 2</span>
              </li>
            </>
          }
        >
          I am simple modal with text
        </CustomModal>
      </div>
    </div>
  );
  const chipsSection = (
    <div className={styles.component} id="chip">
      <h2>Chip</h2>
      <div className={styles.line}>
        <span className={styles.description}>Default :</span>
        <Chip label="I am default one" color={CHIP_COLORS.DEFAULT} />
        <Chip label="I am red one" color={CHIP_COLORS.RED} />
        <Chip label="I am blue one" color={CHIP_COLORS.BLUE} />
      </div>
    </div>
  );
  const colorsSection = () => (
    <div className={styles.component} id="colors">
      <h2>Colors</h2>
      {Object.entries(palette).map(([pal, colors]) => {
        return (
          <div className={styles.colorTitle} key={pal}>
            {pal}
            {Object.entries(colors).map(([key, color]) => {
              return (
                <span key={key} style={{ backgroundColor: color }} className={styles.color}>
                  {key}
                </span>
              );
            })}
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={styles.wrapper}>
      <h1>Design System</h1>
      {colorsSection()}
      {buttonSection}
      {buttonDropdownSection}
      {buttonMultiDropdownSection}
      {spinnerSection}
      {checkboxSection}
      {radioButtonSection}
      {switchSection}
      {modalSection}
      {chipsSection}
    </div>
  );
};

export default DesignSystem;
