/* eslint-disable arrow-parens */
import {
  mapRecommendationsTypeToDisplay,
  RecommendationTypes,
} from 'recommendations/constants/recommendationsConstants';
import { Routes } from 'shared/constants/routes';

export const getBasePathByRecType = (recType) => {
  if (recType === RecommendationTypes.EC2_SAVINGS_PLANS) {
    const basePath = Routes.SAVINGS_PLANS_ANALYZER;
    return basePath;
  }
  const isTypeExists = Object.keys(RecommendationTypes).some((k) => RecommendationTypes[k] === recType);
  const basePath = isTypeExists ? `${Routes.RECOMMENDATIONS}/${recType}-recs` : undefined;

  return basePath;
};

export const getRecommDisplayLabelByType = (recType) => {
  let label = '';
  if (recType) {
    label = mapRecommendationsTypeToDisplay.has(recType.toLowerCase())
      ? mapRecommendationsTypeToDisplay.get(recType)
      : recType;
  }
  return label;
};

export const prepareRecommendationsFiltersMap = (filtersArr) => {
  const filterMap = new Map([]);
  if (filtersArr && filtersArr.length) {
    filtersArr.forEach((filter) => {
      filterMap.set(filter[0], filter[1]);
    });
  }
  return filterMap;
};

export const prepareFiltersMap = (filtersMap = '') => {
  const prepFilterMap = new Map();
  if (filtersMap) {
    filtersMap.forEach((values, key) => {
      const prepValue = values ? values.map(({ value }) => value) : [];
      const prepKey = key === 'linkedaccname' ? 'linkedaccid' : key;
      prepFilterMap.set(prepKey, prepValue);
    });
  }
  return prepFilterMap;
};
