import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import Paper from '@mui/material/Paper';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, TableColumnResizing, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@mui/material/IconButton';
import Spinner from 'shared/components/andtComponents/Spinner';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { formatFiltersToText } from 'shared/utils/dataPrepareUtil';
import { Action, CategoryAction, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import TableWrapper from 'shared/components/tables/TableWrapper';
import * as constants from 'usage/containers/FilterGroup/constants/FilterGroupConstants';
import FilterGroupActionButton from 'usage/containers/FilterGroup/components/FilterGroupActionButton';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import FilterGroupModal from 'usage/containers/FilterGroup/components/FilterGroupModal';
import { withFilterGroupsContextConsumer, withFilterGroupsContextProvider } from 'usage/contexts/filterGroupContext';
import { withVirtualTagsContextProvider } from 'usage/contexts/virtualTagsContext';

class FilterGroupTable extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    filterGroups: PropTypes.array.isRequired,
    addFilterGroup: PropTypes.object.isRequired,
    updateFilterGroup: PropTypes.object.isRequired,
    deleteFilterGroup: PropTypes.object.isRequired,
    invalidatePlainFilters: PropTypes.func.isRequired,
    virtualTags: PropTypes.array.isRequired,
  };

  state = {
    editingCategory: null,
    deletingCategory: null,
    createCategoryModalOpen: false,
    columnWidths: constants.FILTER_GROUP_COLUMN_WIDTHS,
  };

  setEditingCategory = (category) => {
    this.setState({ editingCategory: category });
  };

  setDeletingCategory = (deletingCategory) => {
    this.setState({ deletingCategory });
  };

  setCreateCategory = (open) => {
    this.setState({ createCategoryModalOpen: open });
  };

  handleEditCategory = async (data, id) => {
    const { updateFilterGroup, invalidatePlainFilters } = this.props;
    await updateFilterGroup.mutateAsync({ id, data });
    invalidatePlainFilters();

    this.setState({ editingCategory: null });
  };

  handleDeleteCategory = async (action, category) => {
    const { deleteFilterGroup, invalidatePlainFilters } = this.props;
    if (action === 'delete') {
      await deleteFilterGroup.mutateAsync({ id: category.uuid });
      invalidatePlainFilters();
    }
    this.setState({ deletingCategory: null });
  };

  handleAddCategory = async (data) => {
    const { addFilterGroup, invalidatePlainFilters } = this.props;
    await addFilterGroup.mutateAsync({ data });
    invalidatePlainFilters();
    this.setCreateCategory(null);
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  renderActions = (data) => (
    <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostAllocation} action={Action.Update}>
      <FilterGroupActionButton
        category={data.row}
        handleDelete={this.setDeletingCategory}
        handleEdit={this.setEditingCategory}
      />
    </ReadOnlyDisplayWrapper>
  );

  renderExpandedInfo = ({ row }) => {
    const { virtualTags } = this.props;
    const { filters, excludedFilters } = row || {};
    const { included, excluded } = formatFiltersToText(filters, excludedFilters);

    const renderFilterInfo = (filter) => {
      const isVirtualCustomTags = filter[0] === 'virtualcustomtags';
      return (
        <Row className="mt-2 mb-2">
          <Col className="category-table__item-name flex-grow-0">{LabelCoordinator.getFieldLabel(filter[0])}:</Col>
          <Col>
            {isVirtualCustomTags
              ? filter[1]
                  .map((f) => {
                    const vt = virtualTags?.find((v) => v.uuid === f);
                    return (vt || {}).name || f;
                  })
                  .join(', ')
              : filter[1].join(', ')}
          </Col>
        </Row>
      );
    };
    return (
      <div className="card">
        <Row className="category-table__filter">
          <Col className="flex-grow-0">
            <h5 className="category-table__item-header mt-2">Included filters:</h5>
          </Col>
          <Col>{included.map(renderFilterInfo)}</Col>
        </Row>
        <Row className="category-table__filter">
          <Col className="flex-grow-0">
            <h5 className="category-table__item-header mt-2">Excluded filters:</h5>
          </Col>
          <Col>{excluded.map(renderFilterInfo)}</Col>
        </Row>
      </div>
    );
  };

  render() {
    const { usersStore, invoiceStore, filterGroups, isLoading } = this.props;
    const { editingCategory, deletingCategory, columnWidths, createCategoryModalOpen } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div className="categories__category-table">
        <div>
          <ReadOnlyDisplayWrapper
            isHide={false}
            category={OrganizationEntityCategory.CostAllocation}
            action={CategoryAction.Create}
          >
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Row className="dotted_box" style={{ userSelect: 'none' }}>
                    <button
                      type="button"
                      className="dotted_box-title btn-no-style"
                      onClick={() => this.setCreateCategory(true)}
                    >
                      <span>Create Filter Group</span>
                    </button>
                    <br />
                    <div>
                      <IconButton onClick={() => this.setCreateCategory(true)} size="large">
                        <IconFromPng size="auto" icon={ICONS.plus} />
                      </IconButton>
                    </div>
                  </Row>
                )}
              </Col>
            </Row>
          </ReadOnlyDisplayWrapper>
        </div>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Paper>
                <Grid rows={filterGroups} columns={constants.FILTER_GROUP_TABLE_COLUMNS}>
                  <RowDetailState />
                  <DataTypeProvider for={['userAction']} formatterComponent={this.renderActions} />
                  <TableWrapper />
                  <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
                  <TableHeaderRow />
                  <TableRowDetail contentComponent={this.renderExpandedInfo} />
                </Grid>
              </Paper>
            )}
          </Col>
        </Row>
        {createCategoryModalOpen && (
          <FilterGroupModal
            isOpen
            onSave={this.handleAddCategory}
            usersStore={usersStore}
            categories={filterGroups}
            isLoading={isLoading}
            invoiceStore={invoiceStore}
            closeModal={() => this.setCreateCategory(null)}
          />
        )}
        {editingCategory && (
          <FilterGroupModal
            isOpen
            isEditMode
            categories={filterGroups}
            usersStore={usersStore}
            invoiceStore={invoiceStore}
            onSave={this.handleEditCategory}
            isLoading={isLoading}
            category={editingCategory}
            closeModal={() => this.setEditingCategory(null)}
          />
        )}
        {deletingCategory && (
          <DeleteWarningModal
            isOpen
            handleDelete={(action) => this.handleDeleteCategory(action, deletingCategory)}
            modalTitle="Delete Filter Group"
            warningMessage={`Be advised, You are about to delete ${
              deletingCategory && deletingCategory.name
            } Filter Group`}
          />
        )}
      </div>
    );
  }
}

const ObserverFilterGroupTable = withVirtualTagsContextProvider(
  withInvoiceFiltersContextConsumer(
    withFilterGroupsContextProvider(withFilterGroupsContextConsumer(observer(FilterGroupTable))),
  ),
);
export default ObserverFilterGroupTable;
