import React from 'react';
import DescriptionWrapper from '../../../shared/components/DescriptionWrapper';

const Description = () => (
  <DescriptionWrapper>
    <p>This VM instance is stopped but carries additional charges. You should consider terminating it.</p>
  </DescriptionWrapper>
);

Description.propTypes = {};

export default Description;
