export const VM_AND_STORAGE_COST_TABLE_COLUMNS = {
  REGION: { name: 'region', title: 'Region', getCellValue: (row) => row.regionTagName },
  LINKED_ACCOUNT_ID: {
    name: 'linkedAccountId',
    title: 'Subscription ID',
    getCellValue: (row) => row.linkedAccountId,
  },
  LINKED_ACCOUNT_NAME: {
    name: 'linkedAccountName',
    title: 'Subscription Name',
    getCellValue: (row) => row.linkedAccountName,
  },
  CUSTOMER_NAME: { name: 'customerName', title: 'Customer Name', getCellValue: (row) => row.customerName },
  RESOURCE: { name: 'resource', title: 'Resource', getCellValue: (row) => row.resourceName },
  METER_NAME: { name: 'meterName', title: 'Meter Name', getCellValue: (row) => row.meterName },
  RESOURCE_GROUP: { name: 'resourceGroup', title: 'Resource Group', getCellValue: (row) => row.resourceGroup },
  PURCHASE_OPTION: { name: 'purchaseOption', title: 'Purchase Option', getCellValue: (row) => row.purchaseOption },
  STORAGE_COST: { name: 'storageCost', title: 'Cost of Storage', getCellValue: (row) => row.storageCost },
  MACHINE_COST: { name: 'machineCost', title: 'Cost of Instance', getCellValue: (row) => row.machineCost },
  TOTAL_COST: { name: 'totalVMCost', title: 'Total VM Cost', getCellValue: (row) => row.totalCost },
};

export const VM_AND_STORAGE_COST_COLUMN_WIDTHS = [
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.REGION.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.LINKED_ACCOUNT_ID.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.LINKED_ACCOUNT_NAME.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.CUSTOMER_NAME.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.RESOURCE.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.METER_NAME.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.RESOURCE_GROUP.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.PURCHASE_OPTION.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.STORAGE_COST.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.MACHINE_COST.name, width: 'auto' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.TOTAL_COST.name, width: 'auto' },
];

export const VM_AND_STORAGE_COST_TABLE_COLUMN_EXTENSIONS = [
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.REGION.name },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.LINKED_ACCOUNT_ID.name },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.LINKED_ACCOUNT_NAME.name },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.CUSTOMER_NAME.name },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.RESOURCE.name },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.METER_NAME.name },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.RESOURCE_GROUP.name },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.PURCHASE_OPTION.name, align: 'center' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.STORAGE_COST.name, align: 'center' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.MACHINE_COST.name, align: 'center' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.TOTAL_COST.name, align: 'center' },
];

export const VM_AND_STORAGE_COST_DEFAULT_SORTING = [
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.TOTAL_COST.name, direction: 'desc' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.MACHINE_COST.name, direction: 'desc' },
  { columnName: VM_AND_STORAGE_COST_TABLE_COLUMNS.STORAGE_COST.name, direction: 'desc' },
];

export const VM_AND_STORAGE_COST_CURRENCIES_COLUMNS = [
  VM_AND_STORAGE_COST_TABLE_COLUMNS.STORAGE_COST.name,
  VM_AND_STORAGE_COST_TABLE_COLUMNS.MACHINE_COST.name,
  VM_AND_STORAGE_COST_TABLE_COLUMNS.TOTAL_COST.name,
];
