import { useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { AWS_RECOMMENDATIONS_ROOT, BILLINGS_API_NAME } from 'recommendations/hooks/react-query/api';

const fetchRecommendationExclusions = async () =>
  API.get(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/exclude-rules`);

const deleteRecommendationExclusion = async (exclusionId) =>
  API.del(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/exclude-rules/${exclusionId}`);

export default function useRecommendationExclusions() {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATION_EXCLUSIONS];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchRecommendationExcludeRules: () =>
      useQuery(queryKey, () => fetchRecommendationExclusions(), {
        retry: false,
      }),
    deleteRecommendationExclusion: async (exclusionId) => {
      try {
        await deleteRecommendationExclusion(exclusionId);
      } catch (e) {
        throw e;
      } finally {
        await queryClient.invalidateQueries(queryKey);
      }
    },
  };
}
