import React from 'react';
import { useFormContext } from 'react-hook-form';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { AZURE_ONBOARDING_FIELDS } from 'app/containers/Onboarding/AzureOnboarding/AzureOnboardingConstants';
import styles from './DataFetchSimulationPage.module.scss';
import DataPreview from './DataPreview/DataPreview';

const DataFetchSimulationPage = () => {
  const { getValues } = useFormContext();
  const accountId = getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_ID);
  const { fetchOnboardingStatus } = useOnboarding();
  const { data: status } = fetchOnboardingStatus(accountId, {
    enabled: true,
    refetchInterval: 15 * 1000,
  });
  const { validationAzureDataSimulation } = status || {};
  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          helpWidth: 460,
          hideControl: true,
        }}
        list={[
          {
            key: 'main-body',
            render: () => (
              <div>
                <p className={styles.description}>
                  Based on the information you provided us in the previous step, we found the following exports under
                  the following hierarchy.
                </p>
                <div className={styles.dataTreeView}>
                  <DataPreview data={validationAzureDataSimulation} />
                </div>
              </div>
            ),
          },
        ]}
        help={{
          'main-body': {
            component: () => (
              <p className={styles.helpText}>
                To proceed with this export click <span>Next</span>.
                <br />
                To make changes to the export, click <span>Back</span>.
              </p>
            ),
          },
        }}
      />
    </div>
  );
};

export default DataFetchSimulationPage;
