import PropTypes from 'prop-types';
import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CliCommandBlock from './cliCommandBlock';

import classes from './recommendationCommand.module.scss';

export const COMMAND_TYPES = {
  CLI: { type: 'CLI', title: 'Using the CLI' },
  CONSOLE: { type: 'CONSOLE', title: 'Using the Console' },
  POWERSHELL: { type: 'POWERSHELL', title: 'Using Azure PowerShell' },
};

const RecommendationCommand = ({ commandsList }) => {
  const getFormattedActionText = (instruction) => {
    const content = instruction.actionLink ? (
      <>
        {instruction.additionalText && <span>{instruction.additionalText}</span>}
        <a href={instruction.actionLink} target="_blank" rel="noreferrer" className={classes.actionCommandUrl}>
          {instruction.actionText}
        </a>
      </>
    ) : (
      instruction.actionText
    );

    return <>{content}</>;
  };

  const renderCommand = ({ instructions, type, comment, commentUrl }) =>
    Array.isArray(instructions) && instructions.length > 0 ? (
      <div className={classes.commandSubContainer}>
        <div className={classes.subTitleText}>{type.title}:</div>
        {comment && (
          <div className={classes.commandComment}>
            <span className={classes.notice}>Notice:</span> {comment}
            {commentUrl && (
              <a href={commentUrl.url} target="_blank" rel="noreferrer">
                {commentUrl.text}
              </a>
            )}
          </div>
        )}
        {instructions.map((instruction, index) => (
          <div className={classes.actionContainer}>
            {instruction.actionText ? (
              <div className={classes.actionInstruction}>
                {`${instructions.length > 1 && instruction.showIndex ? `${index + 1}. ` : ''}`}
                {getFormattedActionText(instruction)}
              </div>
            ) : null}
            {instruction.actionCommand ? <CliCommandBlock cliCommand={instruction.actionCommand} /> : null}
          </div>
        ))}
      </div>
    ) : null;

  return commandsList?.length ? (
    <div className={classes.commandContainer}>
      <div className={classes.titleContainer}>
        <GenerateIcon iconName={ICONS.gearCode.name} className={classes.titleIcon} />
        <div className={classes.titleText}>How to Apply</div>
      </div>
      <>
        {commandsList.map((command) => (
          <div className={classes.instructions}>{renderCommand(command)}</div>
        ))}
      </>
    </div>
  ) : null;
};

RecommendationCommand.propTypes = {
  commandsList: PropTypes.array,
};

RecommendationCommand.defaultProps = {
  commandsList: [],
};

export default RecommendationCommand;
