import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'shared/components/andtComponents/Toast';
import { anomalyCommentsProvider } from 'usage/containers/AnomalyDetection/hooks/react-query/useAnomalyDetection';
import CommentColumn from 'shared/components/commentColumn/commentColumn';

const propTypes = {
  row: PropTypes.object.isRequired,
};

const AnomalyComments = ({ row }) => {
  const [deletingComments, setDeletingComments] = useState([]);

  const anomalyCommentsHook = anomalyCommentsProvider(row.uuid);
  const { mutateAsync: mutationAddComment, isLoading: isAddingComment } = anomalyCommentsHook.createComment({
    onError: () => {
      toast.error('Failed to add comment');
    },
  });
  const { mutateAsync: mutationDeleteComment, isLoading: isDeletingComment } = anomalyCommentsHook.deleteComment({
    onError: () => {
      toast.error('Failed to delete comment');
      setDeletingComments([]);
    },
  });
  const { mutateAsync: mutationUpdateComment, isLoading: isUpdatingComment } = anomalyCommentsHook.updateComment({
    onError: () => {
      toast.error('Failed to update comment');
    },
  });
  return (
    <CommentColumn
      row={row}
      mutationAddComment={mutationAddComment}
      mutationDeleteComment={mutationDeleteComment}
      mutationUpdateComment={mutationUpdateComment}
      isAddingComment={isAddingComment}
      isDeletingComment={isDeletingComment}
      isUpdatingComment={isUpdatingComment}
      deletingComments={deletingComments}
      setDeletingComments={setDeletingComments}
    />
  );
};

AnomalyComments.propTypes = propTypes;
export default AnomalyComments;
