import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { DB_PROPERTIES, ENGINE_PROPERTIES, RDS_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';

const REDSHIFT_UTIL_LOW_PROPERTIES = {
  CLUSTER_STATUS: {
    label: 'Cluster Status',
    getterFunction: (recommendation) => recommendation?.recData?.cluster_status,
  },
  NUM_OF_NODES: {
    label: 'Number Of Nodes',
    getterFunction: (recommendation) => recommendation?.recData?.num_nodes,
  },
  NODE_TYPE: {
    label: 'Nodes Type',
    getterFunction: (recommendation) => recommendation?.recData?.node_type,
  },
  MAX_NUM_OF_CONNECTIONS: {
    label: 'Max Number Of Connections',
    getterFunction: (recommendation) =>
      recommendation?.recData?.max_connections === 0 ? '0' : recommendation?.recData?.max_connections,
  },
};

const RedshiftUtilLow = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: 'Delete a Cluster with No Final Cluster Snapshot',
          // eslint-disable-next-line max-len
          actionCommand: `aws redshift delete-cluster --cluster-identifier ${recommendation?.recData?.cluster_id} --skip-final-cluster-snapshot`,
        },
        {
          actionText: 'Delete a Cluster, Allowing a Final Cluster Snapshot',
          // eslint-disable-next-line max-len
          actionCommand: `aws redshift delete-cluster --cluster-identifier ${recommendation?.recData?.cluster_id}  --final-cluster-snapshot-identifier myfinalsnapshot`,
        },
      ],
    },
  ];
  const description =
    // eslint-disable-next-line max-len
    'The Redshift service has been underutilized for the past 7 days, the max number of connections has been 0.0. We recommend terminating this service.';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...RDS_PROPERTIES, ...ENGINE_PROPERTIES, ...DB_PROPERTIES, ...REDSHIFT_UTIL_LOW_PROPERTIES }}
    />
  );
};

RedshiftUtilLow.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RedshiftUtilLow;
