export const flattenArrayDeep = (arrOrObj) => {
  const arr = Array.isArray(arrOrObj) ? arrOrObj : [arrOrObj];
  return arr.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flattenArrayDeep(val)) : acc.concat(val)), []);
};

export const nestedObjToFlattenArray = (nestedUser) => {
  const arrAllParentsAndChilds = [];
  let childArrLen = 0;

  let userArrayToCheck = [nestedUser];
  arrAllParentsAndChilds.push(...userArrayToCheck);
  do {
    const childs = [];
    userArrayToCheck.forEach((user) => {
      childs.push(...user.getChildren());
    });
    // clear the array of first children inorder to insert instead the new children
    userArrayToCheck.splice(0);
    if (childs && childs.length > 0) {
      const arry = Array.isArray(childs) ? childs : [childs];
      arrAllParentsAndChilds.push(...arry);
      userArrayToCheck = [...arry];
    }
    childArrLen = userArrayToCheck.length;
  } while (childArrLen > 0);

  return arrAllParentsAndChilds;
};

export const isPrimitiveArraysEqual = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

export const numberRangeArrayOfSize = (size, startAt = 0) => [...Array(size).keys()].map((i) => i + startAt);

export const isEmptyArray = (array) => !Array.isArray(array) || array.length === 0;

export const randomItemFromArray = (array) => array[Math.floor(Math.random() * array.length)];
