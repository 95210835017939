/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len,react/no-unused-prop-types,arrow-body-style */
import React, { PureComponent } from 'react';
import { Badge } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import PropTypes from 'prop-types';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import CpuChart from './CpuChart';
import CompareTable from './CompareTable';
import NetworkChart from './NetworkChart';
import MemoryChart from './ MemoryChart';

class Statistics extends PureComponent {
  static propTypes = {
    rec: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      update: false,
    };
  }

  createNetworkChartData = (rec) => {
    const { maxNetwork, networkInStatisticsUsage, networkOutStatisticsUsage } = rec;
    if (!networkInStatisticsUsage || !networkOutStatisticsUsage) {
      return undefined;
    }

    // Netowrk in data
    const networkInDataEntries = Object.entries(networkInStatisticsUsage).sort((a, b) => b[0].localeCompare(a[0]));
    networkInDataEntries.reverse();
    const networkInData = networkInDataEntries.map(([key, value]) => {
      return { date: createDateDisplayStr(key), in: parseFloat(value) };
    });

    // Netowrk out data
    const networkOutDataEntries = Object.entries(networkOutStatisticsUsage).sort((a, b) => b[0].localeCompare(a[0]));
    networkOutDataEntries.reverse();
    const networkOutData = networkOutDataEntries.map(([key, value]) => {
      return { date: createDateDisplayStr(key), out: parseFloat(value) };
    });

    const networkData = networkInData.map((currInData, idx) => {
      return { ...currInData, out: networkOutData[idx].out };
    });

    return {
      maxNetwork,
      networkData,
    };
  };

  createCpuChartData = (rec) => {
    const { maxCpuUtil, cpuUtilStatisticsUsage, cpuUtilRecommendedUsage, cpuUtilStatisticsAvgUsage } = rec;
    let cpuUtilData = [];
    let maxCpuUtilNum = 100;
    try {
      const arrOfDates = Array.from(
        new Set([...Object.keys(cpuUtilStatisticsUsage), ...Object.keys(cpuUtilRecommendedUsage)]),
      );
      arrOfDates.sort((a, b) => b.localeCompare(a)).reverse();
      cpuUtilData = arrOfDates.map((date) => ({
        date: createDateDisplayStr(date),
        cpu: parseFloat(cpuUtilStatisticsUsage[date]),
        cpuRecomm: parseFloat(cpuUtilRecommendedUsage[date]),
        cpuAvg: cpuUtilStatisticsAvgUsage ? parseFloat(cpuUtilStatisticsAvgUsage[date]) : null,
      }));
      // const cpuUtilDataEntries = Object.entries(cpuUtilStatisticsUsage).sort((a, b) => b[0].localeCompare(a[0]));
      // cpuUtilDataEntries.reverse();
      // const cpuUtilData = cpuUtilDataEntries.map(([key, value]) => {
      //   return { date: createDateDisplayStr(key), cpu: parseFloat(value) };
      // });
      maxCpuUtilNum = parseFloat(maxCpuUtil) * 100 || 100;
    } catch (error) {
      // Error
    }

    return {
      cpuUtilData,
      maxCpuUtilNum,
    };
  };
  createMemoryChartData = (rec) => {
    const { memUsedPercentStatisticsUsage, memUsedPercentStatisticsAvgUsage } = rec;
    if (!memUsedPercentStatisticsUsage) {
      return null;
    }
    const modifiedData = Object.entries(memUsedPercentStatisticsUsage).map(([key]) => {
      const dataObj = {
        date: key,
        usageDate: createDateDisplayStr(key),
        usage: parseFloat(memUsedPercentStatisticsUsage[key]),
        usageAvg: memUsedPercentStatisticsAvgUsage ? parseFloat(memUsedPercentStatisticsAvgUsage[key]) : null,
      };
      return dataObj;
    });
    modifiedData.sort((a, b) => {
      const [aYear, aMonth, aDay] = a.date.split('-');
      const [bYear, bMonth, bDay] = b.date.split('-');
      if (+bYear !== +aYear) {
        return aYear - bYear;
      }
      if (+bMonth !== +aMonth) {
        return aMonth - bMonth;
      }
      if (+bDay !== +aDay) {
        return aDay - bDay;
      }
      return 1;
    });
    return modifiedData;
  };

  prepareModelCompareTable = (rec) => {
    const { model, modelRecommended } = rec;
    const { getCurrencyNumber } = this.props;
    // const columns = model ? Object.keys(model).map(key => ({ name: key, title: key })) : [];
    // const columnWidths = model ? Object.keys(model).map(key => ({ columnName: key, width: 200 })) : [];
    const columns = [
      {
        name: 'Option',
        title: 'Option',
        getCellValue: (row) => (row.Option === 'Option 1' ? 'Recommended' : row.Option),
      },
      { name: 'Instance type', title: 'Instance type' },
      { name: 'Memory', title: 'Memory' },
      { name: 'Storage', title: 'Storage' },
      { name: 'Network', title: 'Network' },
      { name: 'vCPUs', title: 'vCPUs' },
      {
        name: 'On-Demand Price',
        title: 'On-Demand Price',
        getCellValue: (row) => `${getCurrencyNumber(row['On-Demand Price'])}`,
      },
      {
        name: 'Price difference',
        title: 'Price difference',
        getCellValue: (row) => (row['Price difference'] ? `${getCurrencyNumber(row['Price difference'])}` : ' '),
      },
    ];
    const columnWidths = [
      { columnName: 'Option', width: 150 },
      { columnName: 'Instance type', width: 150 },
      { columnName: 'Memory', width: 150 },
      { columnName: 'Storage', width: 150 },
      { columnName: 'Network', width: 150 },
      { columnName: 'vCPUs', width: 150 },
      { columnName: 'On-Demand Price', width: 150 },
      { columnName: 'Price difference', width: 150 },
    ];
    const tableData = model && modelRecommended ? [model, modelRecommended] : [];
    return { tableData, columns, columnWidths };
  };

  renderMemoryChart = (memoryData) => {
    if (!memoryData || !memoryData.length) {
      return null;
    }
    return (
      <>
        <hr />
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">Memory Performance</p>
          <br />
          <MemoryChart data={memoryData} />
        </div>
      </>
    );
  };

  renderNetworkChart = (networkData) => {
    if (networkData) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">Network Performance</p>
            <br />
            {networkData && networkData.networkData.length ? (
              <NetworkChart data={networkData} />
            ) : (
              <Badge color="inherit" style={{ backgroundColor: '#70bbfd', padding: '5px' }}>Data not available</Badge>
            )}
          </div>
        </>
      );
    }
    return <></>;
  };
  renderCompareTable = (tableData, columns, columnWidths) => {
    if (tableData && tableData.length) {
      return (
        <>
          <hr />
          <div style={{ width: '100%' }}>
            <p className="project-summary__statistic-title">Models Comparison</p>
            <br />
            <CompareTable data={tableData} columns={columns} columnWidths={columnWidths} />
          </div>
        </>
      );
    }
    return <></>;
  };

  render() {
    const { rec } = this.props;

    const { update } = this.state;
    const cpuData = this.createCpuChartData(rec);
    const { tableData, columns, columnWidths } = this.prepareModelCompareTable(rec);
    const networkData = this.createNetworkChartData(rec);
    const memoryChartData = this.createMemoryChartData(rec);
    return (
      <div>
        {update ? (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ''
        )}
        {this.renderCompareTable(tableData, columns, columnWidths)}
        {this.renderNetworkChart(networkData)}
        {this.renderMemoryChart(memoryChartData)}
        <hr />
        <div style={{ width: '100%' }}>
          <p className="project-summary__statistic-title">CPU Performance</p>
          <br />
          {cpuData && cpuData.cpuUtilData.length ? (
            <CpuChart data={cpuData} />
          ) : (
            <Badge color="inherit" style={{ backgroundColor: '#70bbfd', padding: '5px' }}>Data not available</Badge>
          )}
        </div>
        <hr />
      </div>
    );
  }
}

const ObserverStatistics = withUserSettingsConsumer(Statistics);
export default ObserverStatistics;
