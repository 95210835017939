import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import Eye from 'mdi-react/EyeIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};
const tableColumns = [
  { name: 'fileName', title: 'File Name' },
  { name: 'fileType', title: 'File Type' },
  { name: 'uploadDate', title: 'Upload Date' },
  { name: 'userAction', title: ' ' },
];

class FilesTable extends Component {
  static propTypes = {
    uploadedFiles: PropTypes.object.isRequired,
    deleteHandler: PropTypes.object.isRequired,
    viewFileDataHandler: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      deleteFileModalIsOpen: false,
      fileSelectedForDelete: null,
    };
  }
  getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };
  getTableColumnsWidths = () => {
    const columnsWidths = [];
    return columnsWidths || [];
  };

  deleteFileHandler = (action) => {
    if (action === 'cancel') {
      this.setState({ deleteFileModalIsOpen: false, fileSelectedForDelete: null });
    } else if (action === 'delete') {
      const { fileSelectedForDelete } = this.state;
      this.props.deleteHandler(fileSelectedForDelete);
      this.setState({ deleteFileModalIsOpen: false, fileSelectedForDelete: null });
    }
  };
  toggleDeleteModal = (row) => {
    this.setState({ deleteFileModalIsOpen: true, fileSelectedForDelete: row });
  };
  userActionsFormatter = (data) => (
    <i>
      <Row>
        <Col>
          <ReadOnlyDisplayWrapper
            isHide={false}
            category={OrganizationEntityCategory.CostAllocation}
            action={Action.Update}
          >
            <LinkIconButton containerStyle={buttonContainerStyle}>
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleDeleteModal(data.row);
                }}
              />
              <Delete />
            </LinkIconButton>
          </ReadOnlyDisplayWrapper>
        </Col>
        <Col>
          <LinkIconButton containerStyle={buttonContainerStyle}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.viewFileDataHandler();
              }}
            />
            <Eye />
          </LinkIconButton>
        </Col>
      </Row>
    </i>
  );

  render() {
    const { uploadedFiles } = this.props;
    const { deleteFileModalIsOpen, fileSelectedForDelete } = this.state;
    return (
      <div className="card">
        <Grid rows={uploadedFiles} columns={tableColumns}>
          <DataTypeProvider for={['userAction']} formatterComponent={this.userActionsFormatter} />
          <TableWrapper columnExtensions={this.getTableColumnsWidths(uploadedFiles)} />
          <TableHeaderRow />
        </Grid>
        <DeleteWarningModal
          isOpen={deleteFileModalIsOpen}
          handleDelete={this.deleteFileHandler}
          deletedItemName={fileSelectedForDelete ? fileSelectedForDelete.fileName : ''}
          warningMessage="Be advised you are about to delete the following file:"
          modalTitle="Enrichment Tags File Delete"
        />
      </div>
    );
  }
}

const ObserverFilesTable = observer(FilesTable);
export default ObserverFilesTable;
