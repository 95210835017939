/* eslint-disable no-unused-vars */
import { API } from 'shared/utils/apiMiddleware';

export default class CustomDashboardApi {
  fetchCustomDashboardsTemplates = () => {
    const result = API.get('billings', '/api/v1/usage/custom-dashboard/dashboards-templates');
    return result;
  };
  createCustomDashboardTemplate = (dashboardId) => {
    const result = API.post(
      'billings',
      `/api/v1/usage/custom-dashboard/dashboards-templates?dashboardId=${dashboardId}`,
    );
    return result;
  };
  updateCustomDashboardTemplate = (template) => {
    const result = API.put('billings', `/api/v1/usage/custom-dashboard/dashboards-templates/${template.uuid}`, {
      body: template,
    });
    return result;
  };
  deleteCustomDashboardTemplate = (templateId) => {
    const result = API.del('billings', `/api/v1/usage/custom-dashboard/dashboards-templates/${templateId}`);
    return result;
  };
  fetchCustomDashboards = () => {
    const result = API.get('billings', '/api/v1/usage/custom-dashboard/dashboards');
    return result;
  };
  fetchCustomDbrdPanels = () => {
    const result = API.get('billings', '/api/v1/usage/custom-dashboard/panels');
    return result;
  };
  fetchPanelById = (id, accountId) => {
    let url = `/api/v1/usage/custom-dashboard/panels/${id}`;
    if (accountId) {
      url += `?accountId=${accountId}`;
    }
    const result = API.get('billings', url);
    return result;
  };
  createNewCustomDashboard = async (dashboardParams, panelParams) => {
    const body = { dashboard: dashboardParams, panel: panelParams };
    const result = API.post('billings', '/api/v1/usage/custom-dashboard/dashboard', { body });
    return result;
  };
  cloneCustomDashboard = async (
    dashboardParams,
    panelsIdsToClonedIds,
    cloneIdsToCloneNames,
    dbAccountId,
    isFromTemplate,
  ) => {
    const body = {
      dashboard: dashboardParams,
      panelsIdsToClonedIds,
      cloneIdsToCloneNames,
      isFromTemplate: !!isFromTemplate,
      dbAccountId,
    };
    const result = API.post('billings', '/api/v1/usage/custom-dashboard/dashboard/clone', { body });
    return result;
  };
  updateExistingCustomDashboard = async (dashboardParams, panelParams) => {
    const body = { dashboard: dashboardParams, panel: panelParams };
    const result = API.put('billings', '/api/v1/usage/custom-dashboard/dashboard', { body });
    return result;
  };
  removeDashboard = async (dashboardId) => {
    const body = { uuid: dashboardId };
    const result = API.del('billings', '/api/v1/usage/custom-dashboard/dashboard', { body });
    return result;
  };
  updateDashboard = async (dashboard) => {
    const body = { dashboard };
    const result = API.put('billings', '/api/v1/usage/custom-dashboard/dashboard', { body });
    return result;
  };
  updatePanel = async (panel) => {
    const body = { panel };
    const result = API.put('billings', '/api/v1/usage/custom-dashboard/panel', { body });
    return result;
  };
  createPanelFromTemplate = async (id, name) => {
    const result = API.post('billings', `/api/v1/usage/custom-dashboard/panel/predefined-copy/${id}`, {
      body: { name },
    });
    return result;
  };
  createCustomFilter = async (dbId, filter) => {
    const body = { filter, dbId };
    return API.post('billings', '/api/v1/usage/custom-filter', { body });
  };
  updateCustomFilter = async (dbId, filter) => {
    const body = { filter, dbId };
    return API.put('billings', `/api/v1/usage/custom-filter/${filter.uuid}`, { body });
  };
  deleteCustomFilter = async (filterId) => API.del('billings', `/api/v1/usage/custom-filter/${filterId}`);
}
