import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import RecipientsSelect from 'shared/components/RecipientsSelect.jsx';
import Button from 'shared/components/andtComponents/Button.jsx';
import { ReactComponent as SlackIcon } from 'shared/img/icons/slackIcon.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from 'shared/components/andtComponents/Modal';
import Select, { createFilter } from 'react-select';
import useSlack from 'shared/components/slackIntegration/hooks/useSlack';
import { palette } from 'shared/constants/colorsConstants';
import styles from './AddSlackChannelModal.module.scss';
import { ReactComponent as ChannelsEmptyState } from './assets/channels-empty-state.svg';

const selectStyles = {
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? palette.blue[400] : 'transparent',
    backgroundColor: state.isDisabled ? palette.gray[100] : palette.gray[200],
    borderWidth: '2px',
    borderRadius: '6px',
    boxShadow: 'unset',
    minHeight: '0',
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: '2px 4px',
  }),
  dropdownIndicator: (baseStyle) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    color: state.isDisabled ? palette.gray[400] : palette.gray[450],
  }),
};

export default function AddSlackChannelModal({ channels, selectedChannel, onChannelSelect, onClose }) {
  const [recipients, setRecipients] = useState([]);

  const { getPossibleRecipients, addRecipients, testRecipients } = useChannels();
  const { data: possibleRecipients, isLoading } = getPossibleRecipients(selectedChannel?.channelId);
  const { mutateAsync: handleAddRecipients, isLoading: isAddingRecipients } = addRecipients();
  const { mutateAsync: handleTest, isLoading: isTestingRecipients } = testRecipients();

  const { getSlackInstallUrl } = useSlack();
  const { data: slack } = getSlackInstallUrl();

  const runGoToSlack = async () => {
    window.location.href = slack.INSTALL_URL;
  };

  const channelOptions = channels.map((c) => ({ value: c.channelId, label: c.name, ...c }));

  const EmptySlackChannels = ({ onClose, onClick }) => (
    <CustomModal
      open
      title="Add Slack Channel"
      headerMode="add"
      headerIcon={<SlackIcon height={36} width={36} />}
      onClose={onClose}
      saveHidden
      footerBorder
      className={styles.emptyModal}
    >
      <div className={styles.content}>
        <ChannelsEmptyState />
        <h4>Currently you have no connected Workspaces</h4>
        <Button text="Create Slack Workspace" onClick={onClick} isGhost icon={() => <SlackIcon />} />
      </div>
    </CustomModal>
  );

  if (!channelOptions.length) {
    return <EmptySlackChannels onClose={onClose} onClick={runGoToSlack} />;
  }

  return (
    <CustomModal
      open
      title="Add Slack Channel"
      headerMode="add"
      headerIcon={<SlackIcon height={36} width={36} />}
      onClose={onClose}
      saveDisabled={!recipients?.length}
      leftButton={
        <span className={styles.leftButtonWrapper}>
          <Button
            text={
              <>
                Create Slack Workspace <GenerateIcon iconName={ICONS.chevronRight.name} />
              </>
            }
            onClick={runGoToSlack}
            isTextButton
            icon={() => <SlackIcon />}
            overrideStyles={{ padding: 0 }}
          />
        </span>
      }
      saveTitle={
        <>
          <GenerateIcon iconName={ICONS.plus.name} /> Add Channel
        </>
      }
      onSave={() => handleAddRecipients({ channelId: selectedChannel.channelId, recipients })}
      isLoading={isAddingRecipients}
      className={styles.addSlackChannelModal}
      footerBorder
    >
      <label htmlFor="channel-select">Workspace</label>
      <Select
        placeholder="Select"
        value={selectedChannel ? { value: selectedChannel.channelId, label: selectedChannel.name } : null}
        options={channelOptions}
        onChange={({ value, label, ...rawChannel }) => onChannelSelect(rawChannel)}
        filterOption={createFilter({ stringify: ({ data: { name } }) => name || '' })}
        components={{ IndicatorSeparator: () => null }}
        styles={selectStyles}
        openMenuOnFocus
        inputId="channel-select"
        className={styles.channelSelect}
        isDisabled={channelOptions?.length <= 1 && selectedChannel}
      />
      <label htmlFor="recipients-select">
        Channel
        {possibleRecipients?.length >= 0 && <span> ({possibleRecipients.length})</span>}
      </label>
      <div className={styles.recipientsSelectContainer}>
        <RecipientsSelect
          channels={!possibleRecipients ? [] : [{ ...selectedChannel, recipients: possibleRecipients }]}
          savedRecipients={recipients || []}
          isLoading={isLoading}
          onChangeHandler={setRecipients}
          isDisabled={!selectedChannel}
        />
        <Button
          text="Test Connection"
          onClick={() => handleTest({ channelId: selectedChannel.channelId, additionalRecipients: recipients })}
          icon={() => <GenerateIcon iconName={ICONS.hexagonCheck.name} />}
          isGhost
          disabled={!recipients?.length}
          isLoading={isTestingRecipients}
        />
      </div>
    </CustomModal>
  );
}

AddSlackChannelModal.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedChannel: PropTypes.object,
  onChannelSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddSlackChannelModal.defaultProps = {
  selectedChannel: null,
};
