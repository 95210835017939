import React from 'react';
import PropTypes from 'prop-types';

const DescriptionWrapper = ({ children }) => (
  <div className="recommendations__description" style={{ 'line-height': '30px', 'font-size': '13px' }}>
    {children}
  </div>
);

DescriptionWrapper.propTypes = {
  children: PropTypes.object.isRequired,
};

export default DescriptionWrapper;
