import {
  mapCloudBaseCostUsageStateToAttributesMap,
  mapCloudBaseGroupByToCostUsageStateMap,
} from 'usage/constants/costAndUsageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';

export default class MetricsDataState {
  constructor(context, type) {
    this.data = null;
    this.context = context;
    this.type = type;
  }

  getDataStateType = () => this.type;

  getData = () => {
    const { invoiceStore } = this.context.props;
    const stateType = this.getDataStateType();
    this.data = invoiceStore.metricDataStates[stateType];
    return this.data;
  };

  modifyDataKey = (dataKey, currGroupBy) => {
    if (currGroupBy === AwsCommonFields.PAYER_ACCOUNT) {
      return dataKey.split(' (')[1].replace(')', '');
    }
    return dataKey;
  };

  handleFetch = (newDataState) => {
    this.fetchDataByState(newDataState);
  };

  handleFilterChange = (
    currentGroupBy,
    selectedMetric,
    startDate,
    endDate,
    currPeriodGranLevel,
    fieldToFilterdValuesMap,
    selectedDimensions,
    excludedFiltersStatusMap,
  ) => {
    this.context.setState({
      filteredKeys: [],
      favourites: [],
      isInitialDataKeyFilterLoad: true,
    });
    const stateType = this.getDataStateType();

    this.context.props.invoiceStore.fetchMetricsData({
      currDataState: stateType,
      start: startDate,
      end: endDate,
      groupBy: currentGroupBy,
      filtersMap: fieldToFilterdValuesMap,
      excludedFiltersMap: excludedFiltersStatusMap,
      periodGranLevel: currPeriodGranLevel,
      dimensions: selectedDimensions,
      metric: selectedMetric,
    });
  };

  getPreparedNewGroupBy = (newGroupBy) => {
    let preparedNewGroupBy = '';
    switch (true) {
      case newGroupBy.split(':')[0] === 'customtags':
        preparedNewGroupBy = 'customtags';
        break;
      default:
        preparedNewGroupBy = newGroupBy;
        break;
    }
    return preparedNewGroupBy;
  };

  handleGroupBy = (newGroupBy) => {
    const { filterBarGroupBy } = this.context.state;
    const { usersStore } = this.context.props;
    // if GroupBy is the same - No required change
    if (newGroupBy === filterBarGroupBy) {
      return;
    }
    const preparedNewGroupBy = this.getPreparedNewGroupBy(newGroupBy);
    const { currDispUserCloudAccountType } = usersStore;
    const mapByCloudType = mapCloudBaseGroupByToCostUsageStateMap.get(currDispUserCloudAccountType);
    // Group by resets the bread crumbs
    const newDataState = mapByCloudType.get(preparedNewGroupBy);

    this.context.setState({
      filteredKeys: [],
      favourites: [],
      isInitialDataKeyFilterLoad: true,
      currDataState: newDataState,
      selectedGroupByItem: null,
      currentGroupBy: newGroupBy,
      selectedFilterBarGroupBy: filterBarGroupBy,
    });
    this.fetchDataByState(newDataState, newGroupBy);
  };

  fetchDataByState = (dataState, newGroupBy) => {
    const { usersStore, invoiceStore } = this.context.props;
    const {
      startDate,
      endDate,
      excludedFiltersStatusMap,
      selectedDimensions,
      selectedMetric,
      selectedGranLevel,
      metrics,
    } = this.context.state;
    const { currDispUserCloudAccountType } = usersStore;
    const mapCloudBase = mapCloudBaseCostUsageStateToAttributesMap.get(currDispUserCloudAccountType);
    const stateAttribute = mapCloudBase.get(dataState);
    invoiceStore.fetchMetricsData({
      currDataState: dataState,
      start: startDate,
      end: endDate,
      groupBy: stateAttribute || newGroupBy,
      filtersMap: this.context.fieldToFilterdValuesMap,
      excludedFiltersMap: excludedFiltersStatusMap,
      periodGranLevel: selectedGranLevel,
      dimensions: selectedDimensions,
      metric: (metrics.find((m) => m.name === selectedMetric) || {}).id,
    });
  };
}
