import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';

const CompareTable = (props) => (
  <div className="card">
    <Grid rows={props.data} columns={props.columns}>
      <TableWrapper columnExtensions={props.columnWidths} />
      <TableHeaderRow />
    </Grid>
  </div>
);

CompareTable.propTypes = {
  data: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  columnWidths: PropTypes.object.isRequired,
};

export default CompareTable;
