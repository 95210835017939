/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import CustomDashboardsPanelsTable from './components/CustomDashboardsPanelsTable';

const CustomDashboardsPanels = (props) => (
  <Container>
    <PageHeader showDate title={PageNames.CUSTOM_DASHBOARDS_PANELS} />
    <Card>
      <CardBody>
        <CustomDashboardsPanelsTable {...props} />
      </CardBody>
    </Card>
  </Container>
);

CustomDashboardsPanels.propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

export default CustomDashboardsPanels;
