import React from "react";
import PropTypes from 'prop-types';
import InfoPopover from "~/shared/components/andtComponents/InfoPopover/index.jsx";
import { calculateFieldValue, getPropertyValue } from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils.js';
import styles from './selectedProperties.module.scss';
import {useUserSettingsContext} from "~/users/utils/contexts/UserSettingsContext.jsx";

const SelectedProperties = ({  selectedProperties, shareProperties, plan, overrideStyles}) => {

  const { getCurrencyNumber } = useUserSettingsContext();
  return (
      <div className={styles.propertiesWrapper}>
          {selectedProperties?.map((property) => (
             <div key={property} className={styles.propertyWrapper} style={overrideStyles || {}}>
                     {property.name}:
                      <span className={styles.propertyValue}
                          automation-id={`${property.key}Value`}>
                          {property.calculatedFields?.length ? calculateFieldValue(property, shareProperties, plan, getCurrencyNumber) : getPropertyValue(property, shareProperties, plan, getCurrencyNumber)}
                          {property.info && (
                               <InfoPopover isSimple mode="outline">
                                   {property.info}
                               </InfoPopover>
                          )}
                      </span>
             </div>
          ))}
      </div>
  );
}

SelectedProperties.propTypes = {
    selectedProperties: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            calculatedFields: PropTypes.array,
            info: PropTypes.string,
        })
    ).isRequired,
    shareProperties: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    overrideStyles: PropTypes.object,
};
export default SelectedProperties;
