import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';

export const AZURE_VM_USAGE_TITLES = {
  AZURE_RI_COVERAGE: 'RI Coverage',
  AZURE_VIRTUAL_MACHINE_AND_STORAGE_COST: 'Virtual Machine & Storage Cost',
  AZURE_VIRTUAL_MACHINE_USAGE: 'Pay as You Go - Virtual Machine',
};

export const datePickerSyntaxDates = (start, end) => {
  let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
  startDate = createTimeZoneAgnosticDateFromStr(startDate);
  endDate = createTimeZoneAgnosticDateFromStr(endDate);
  return { startDate, endDate };
};
