/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import { replaceAt } from 'shared/utils/strUtil';
import { graphColors } from 'shared/constants/colorsConstants';

export const hashCode = (str) => {
  let hash = 0;
  const partedStr = [];

  // swapping the first and last chars in case the last char is number
  const idxLastChar = str.length - 1;
  const num = parseInt(str[idxLastChar], 10);
  const isOddNumber = !isNaN(num) && num % 2 === 1;
  if (isOddNumber) {
    const first = str[0];
    const last = str[idxLastChar];
    str = replaceAt(str, 0, last);
    str = replaceAt(str, idxLastChar, first);
  }

  for (let i = 0; i < str.length; i += str.length / 3) partedStr.push(str.slice(i, i + str.length / 3));
  const combStr = `${partedStr[1]}${partedStr[0]}${partedStr[2]}${partedStr[1]}${partedStr[2]}${partedStr[0]}${partedStr[0]}${partedStr[0]}${partedStr[1]}${partedStr[0]}`;

  for (let i = 0; i < combStr.length; i++) {
    hash = combStr.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const pickColor = (str) => `hsl(${hashCode(str) % 360}, 100%, 80%)`;

export const StringToColor = (() => {
  let instance = null;

  return {
    next: function stringToColor(str) {
      if (instance === null) {
        instance = {};
        instance.stringToColorHash = {};
        instance.nextVeryDifferntColorIdx = 0;
        instance.veryDifferentColors = graphColors;
      }
      if (!instance.stringToColorHash[str]) {
        if (instance.nextVeryDifferntColorIdx === instance.veryDifferentColors.length - 1) {
          // reached the last color
          instance.nextVeryDifferntColorIdx = 0;
        }
        instance.stringToColorHash[str] = instance.veryDifferentColors[instance.nextVeryDifferntColorIdx++];
      }
      return instance.stringToColorHash[str];
    },
  };
})();

export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
};
