import React from 'react';
import Tooltip from '../../andtComponents/Tooltip';
import HOLIDAYS from '../constants';
import { ReactComponent as PumpkinIcon } from '../assets/halloween/pumpkin.svg';
import { ReactComponent as BooIcon } from '../assets/halloween/boo.svg';
import candle from '../assets/warIsrael/candle.gif';
import styles from '../HolidayThemedIcons.module.scss';

const HolidayBottomLeftIcon = ({ holiday }) => {
  switch (holiday) {
    case HOLIDAYS.HALLOWEEN:
      return (
        <span className={styles.bottomLeftIcon}>
          <Tooltip title={<BooIcon />} blank arrow={false}>
            <span>
              <PumpkinIcon />
            </span>
          </Tooltip>
        </span>
      );
    case HOLIDAYS.STAND_WITH_ISRAEL:
      return (
        <span className={styles.bottomLeftIcon}>
          <span>
            <img src={candle} alt="candle" />
          </span>
        </span>
      );
    default:
      return null;
  }
};

export default HolidayBottomLeftIcon;
