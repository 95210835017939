/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'shared/components/CodeBlock';
import { ActionContainer, ActionHeader } from 'recommendations/shared/components/ActionComponents';

const Action = ({ rec }) => {
  const actionCommandText = rec?.command;
  const actionCommandComment = rec?.commandComment;

  return (
    <ActionContainer>
      <ActionHeader />
      <ul style={{ 'list-style-type': 'none' }}>
        <li>Using the AWS CLI:</li>
        {actionCommandComment ? <li className="red-text">{actionCommandComment}</li> : null}
        <CodeBlock text={actionCommandText} variables={{}} />
        <br />
      </ul>
    </ActionContainer>
  );
};

Action.propTypes = {
  rec: PropTypes.object.isRequired,
};

export default Action;
