import React, { useEffect, useMemo, useState } from 'react';
import { GenerateIcon, ICONS, Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import {
  calculateRange,
  CLOSED_AND_DONE_RECS_DATES_FILTERS,
  CLOSED_AND_DONE_RECS_DATES_OPERATOR,
  DATE_RANGE_OPTIONS,
  FILTERS,
} from 'recommendationsNew/consts';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import { palette } from 'shared/constants/colorsConstants';
import {
  CompletedStatus,
  DoneStatus,
  OpenStatus,
} from 'recommendationsNew/components/shared/recommendationStatusSymbol';
import { useFilterStatus } from 'recommendationsNew/hooks/useFilterStatus';
import { DateRangeDropDown } from './dateRangeDropDown';

import classes from './dateRangeFilter.module.scss';

export function DateRangeFilter() {
  const [isOpen, setIsOpen] = useState(false);

  const {
    recommendationFilters: filtersContext,
    setRecommendationFilters: setFiltersContext,
    showOpenOnly,
    showDoneCompletedOnly,
  } = useRecommendationsNewContext();
  const statusFilter = useFilterStatus(filtersContext);
  const [localFiltersCopy, setLocalFiltersCopy] = useState({
    [FILTERS.OPEN_RECS_CREATION_DATE.id]: filtersContext?.[FILTERS.OPEN_RECS_CREATION_DATE.id],
    [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: filtersContext?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
  });

  useEffect(() => {
    setLocalFiltersCopy({
      [FILTERS.OPEN_RECS_CREATION_DATE.id]: filtersContext?.[FILTERS.OPEN_RECS_CREATION_DATE.id],
      [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: filtersContext?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
    });
  }, [filtersContext]);

  const onFilterValuesChange = (selection, filter, subFilter = null) => {
    if (filter === FILTERS.OPEN_RECS_CREATION_DATE.id) {
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.OPEN_RECS_CREATION_DATE.id]: selection,
      });
    } else if (filter === FILTERS.CLOSED_AND_DONE_RECS_DATES.id) {
      const tmp = { ...localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id], [subFilter]: selection };
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: tmp,
      });
    }
  };

  const onOpenDateRangeFilterChange = () => {
    const newIsOpenState = !isOpen;
    setIsOpen(newIsOpenState);

    if (newIsOpenState === false) {
      setFiltersContext({
        ...filtersContext,
        [FILTERS.OPEN_RECS_CREATION_DATE.id]: localFiltersCopy[FILTERS.OPEN_RECS_CREATION_DATE.id],
        [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
      });
    }
  };

  const operatorButtonStyle = {
    background: palette.blue[100],
    fontSize: 16,
    fontWeight: 400,
    height: '24px',
    padding: '0 4px',
  };

  const openRecsDateRange = (
    <div className={classes.dateRangeContainer}>
      <div className={classes.recStatusContainer}>
        <OpenStatus withBorder />
      </div>
      <div className={classes.dateFilter}>
        <div className={classes.title}>Creation Date</div>
        <DateRangeDropDown
          onPeriodChange={(selectedOption) => onFilterValuesChange(selectedOption, FILTERS.OPEN_RECS_CREATION_DATE.id)}
          selected={localFiltersCopy[FILTERS.OPEN_RECS_CREATION_DATE.id]}
        />
      </div>
    </div>
  );

  const completedAndDoneRecsDateRange = (
    <div className={classes.dateRangeContainer}>
      <div className={classes.recStatusContainer}>
        {statusFilter.isDone ? <DoneStatus withBorder /> : null}
        {statusFilter.isCompleted ? <CompletedStatus withBorder /> : null}
      </div>
      <div className={classes.dateFilter}>
        <div className={classes.title}>Completed Date</div>
        <DateRangeDropDown
          onPeriodChange={(selectedOption) =>
            onFilterValuesChange(
              selectedOption,
              FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
              CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE,
            )
          }
          selected={
            localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
              CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE
            ]
          }
        />
      </div>
      <div className={classes.optionalDateFilter}>
        <div className={classes.operatorDropdown}>
          <ButtonDropdown
            text={
              localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR] ||
              CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND
            }
            overrideMenuStyles={{}}
            iconPlacement="right"
            icon={() => <GenerateIcon iconName={ICONS.caretDown.name} className={classes.caretDown} />}
            overrideButtonStyles={operatorButtonStyle}
          >
            <div
              className={classes.operatorItem}
              onClick={() =>
                onFilterValuesChange(
                  CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND,
                  FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
                  CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR,
                )
              }
            >
              <span>{CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND}</span>
            </div>
            <div
              className={classes.operatorItem}
              onClick={() =>
                onFilterValuesChange(
                  CLOSED_AND_DONE_RECS_DATES_OPERATOR.OR,
                  FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
                  CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR,
                )
              }
            >
              <span>{CLOSED_AND_DONE_RECS_DATES_OPERATOR.OR}</span>
            </div>
          </ButtonDropdown>
        </div>
        <div className={`${classes.dateRangeContainer} ${classes.noFrame}`}>
          <div className={classes.dateFilter}>
            <div className={classes.title}>Creation Date</div>
            <DateRangeDropDown
              onPeriodChange={(selectedOption) =>
                onFilterValuesChange(
                  selectedOption,
                  FILTERS.CLOSED_AND_DONE_RECS_DATES.id,
                  CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE,
                )
              }
              selected={
                localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
                  CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE
                ]
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const dateRangeComponent = () => {
    if (showOpenOnly) {
      return openRecsDateRange;
    }
    if (showDoneCompletedOnly) {
      return completedAndDoneRecsDateRange;
    }
    return (
      <>
        {openRecsDateRange}
        {completedAndDoneRecsDateRange}
      </>
    );
  };

  const dateRangeFilterTitle = useMemo(() => {
    const custom = DATE_RANGE_OPTIONS.CUSTOM.label;
    if (showOpenOnly && !showDoneCompletedOnly) {
      return calculateRange(localFiltersCopy[FILTERS.OPEN_RECS_CREATION_DATE.id])?.label;
    }
    if (!showOpenOnly && showDoneCompletedOnly) {
      if (
        !localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE]
      ) {
        return calculateRange(
          localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
            CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE
          ],
        )?.label;
      }
    }
    if (showOpenOnly && showDoneCompletedOnly) {
      if (
        localFiltersCopy[FILTERS.OPEN_RECS_CREATION_DATE.id] ===
        localFiltersCopy[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]
      ) {
        return calculateRange(localFiltersCopy[FILTERS.OPEN_RECS_CREATION_DATE.id])?.label;
      }
    }
    return custom;
  }, [localFiltersCopy, showOpenOnly, showDoneCompletedOnly]);

  return (
    <div
      className={`${classes.dateRangeFilterContainer} ${isOpen ? classes.opened : ''}`}
      automation-id="date-range-filter"
    >
      <Popover open={isOpen} onOpenChange={onOpenDateRangeFilterChange}>
        <PopoverTrigger className={classes.popoverTrigger}>
          <Tooltip title={`Date Range (${dateRangeFilterTitle})`} placement="top">
            <div className={classes.dateRangeTitleContainer}>
              <span className={classes.title}>{dateRangeFilterTitle}</span>
              <GenerateIcon iconName={ICONS.caretDown.name} className={classes.arrowDown} />
            </div>
          </Tooltip>
        </PopoverTrigger>

        <PopoverContent align="start" sideOffset={7} className={classes.popoverContent}>
          <div className={classes.filtersContainer}>{dateRangeComponent()}</div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
