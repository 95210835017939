import React, { createContext, useRef } from 'react';
import PropTypes from 'prop-types';

const RecommendationDetailsWidthContext = createContext();

export const RecommendationDetailsWidthProvider = ({ children }) => {
  const recommendationDetailsWidthRef = useRef({ current: null });

  return (
    <RecommendationDetailsWidthContext.Provider value={{ recommendationDetailsWidthRef }}>
      {children}
    </RecommendationDetailsWidthContext.Provider>
  );
};

RecommendationDetailsWidthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RecommendationDetailsWidthContext;
