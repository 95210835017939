import { useEffect, useState } from 'react';

const MarginAppliedReloader = ({ isPpApplied, accountKey = '', onChange }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }
    onChange();
  }, [isPpApplied, accountKey]);
  return null;
};

export default MarginAppliedReloader;
