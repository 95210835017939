/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { isFamilyTypeHasTypes } from 'shared/utils/cloudUtils';
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class DbEngineDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    return CostUsageStates.TYPE;
  }

  getDataStateType() {
    return CostUsageStates.DB_ENGINE;
  }
}
