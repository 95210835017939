import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getBasePathByRecType } from 'recommendations/utils/recommUtils';
// import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';

const RecommendationLink = ({ rec, recHandler, title, recHelpers, color }) => (
  <Link
    style={{ color, 'font-weight': 'normal' }}
    key={rec.key}
    recHelpers={recHelpers}
    to={{
      pathname: `${getBasePathByRecType(rec.type)}/${rec.key}`,
      // rec.type === RecommendationTypes.EC2_SAVINGS_PLANS
      //   ? `${getBasePathByRecType(rec.type)}/${rec.key}`
      //   : `${getBasePathByRecType(rec.type)}/${rec.key}`,
      rec: { rec },
      recHandler: { recHandler },
      backToPage: { backToPage: window.location.pathname },
      recHelpers: { recHelpers },
    }}
  >
    {title}
  </Link>
);

RecommendationLink.propTypes = {
  rec: PropTypes.object.isRequired,
  recHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  recHelpers: PropTypes.object.isRequired,
  color: PropTypes.string,
};

RecommendationLink.defaultProps = {
  color: 'white',
};

export default RecommendationLink;
