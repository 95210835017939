/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RdsCostRecommendation } from 'recommendations/models/recommTypes';
import { RdsInstanceBasicData } from 'recommendations/models/recommCommonTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class RdsCostRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RdsCostRecommendation();
  }

  build() {
    super.build();
    const {
      zone_tag: region,
      instance_type_model: model,
      instance_type_current: currFullModel,
      instance_type_recommended: recommendedFullModel,
      db_name: name,
      db_type: type,
      instance_type_size: size,
      db_storage_size: dbStorageSize,
      is_multi_az: isMultiAz,
    } = this._rawRecommendation;
    const rdsInstance = new RdsInstanceBasicData(model, size, name, type, region);
    // FLAT OBJECT FOR NOW
    this._recommendation.region = region;
    this._recommendation.model = rdsInstance.model;
    this._recommendation.size = rdsInstance.size;
    this._recommendation.dbStorageSize = dbStorageSize ?? 'Not Available';
    this._recommendation.isMultiAz = Boolean(isMultiAz);
    this._recommendation.dbName = rdsInstance.name;
    this._recommendation.dbType = rdsInstance.type;
    this._recommendation.instance = rdsInstance.instance;
    this._recommendation.currInstance = currFullModel;
    this._recommendation.recommendedInstance = recommendedFullModel;
  }
}
