import React, { useContext } from 'react';
import { observer } from 'mobx-react';

const RootStoreContext = React.createContext();

export const useRootStore = () => useContext(RootStoreContext);

export function withRootStoreContextProvider(Component, rootStore) {
  function RootStoreContextProvider({ ...props }) {
    return (
      <RootStoreContext.Provider
        value={{
          ...rootStore,
        }}
      >
        <Component {...props} />
      </RootStoreContext.Provider>
    );
  }
  return observer(RootStoreContextProvider);
}

export function withRootStoreContextConsumer(Component) {
  return function RootStoreContextConsumer({ ...props }) {
    return <RootStoreContext.Consumer>{(data) => <Component {...data} {...props} />}</RootStoreContext.Consumer>;
  };
}
