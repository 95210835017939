import { cloneDeep } from 'lodash';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import {
  AZURE_BILLING_HISTORY_TABLE_COLUMNS,
  AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
  FLEXIBILITY_TABLE_COLUMNS_WIDTHS,
} from '../../constants/customersConstants';
import { BILLING_HISTORY_TABLE_COLUMNS } from '../CustomersHistory';
import { BillingHistoryRow } from './types.ts';

export function getHistoryTableColumnsAndWidths(isAzure: boolean, tableType?: string) {
  if (isAzure) {
    return {
      tableColumns: AZURE_BILLING_HISTORY_TABLE_COLUMNS,
      columnWidths: AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
    };
  }
  return {
    tableColumns: BILLING_HISTORY_TABLE_COLUMNS,
    columnWidths: FLEXIBILITY_TABLE_COLUMNS_WIDTHS(tableType),
  };
}

export const LINES_IN_PAGE = 15;

export const prepareCsvModifiedRows = (rows: BillingHistoryRow[]) => {
  const csvModifiedRows = cloneDeep(rows || []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return csvModifiedRows.map(({ accountKey, divisionId, divisionTypeId, ...restRow }) => ({ ...restRow }));
};

export const prepareServerExportRows = (rows: BillingHistoryRow[]) =>
  cloneDeep(rows || []).map((row: BillingHistoryRow) => {
    const { divisionId, customerName, accountKey, month, year } = row;
    const customerDisplayName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', customerName);
    return {
      divisionId,
      customerName,
      customerDisplayName,
      accountKey,
      month,
      year,
    };
  });
