/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
// import DateFilter from 'shared/modules/dateFilter';
import { CostTypes, CostUsageStates } from 'usage/constants/costAndUsageConstants';

export default class BaseRepotParams {
  constructor() {
    this.startDate = null;
    this.endDate = null;
    this.baseState = this.createBaseState(this.startDate, this.endDate);
    this.saveModalKeepDates = true;
    this.fieldToFilterdValuesMap = new Map();
    this.dataKeyToWhereParamsMap = new Map();
  }

  get getReportParams() {
    const savedParams = {
      state: this.baseState,
      saveModalKeepDates: this.saveModalKeepDates,
      fieldToFilterdValuesMap: this.fieldToFilterdValuesMap,
      dataKeyToWhereParamsMap: this.dataKeyToWhereParamsMap,
    };
    return savedParams;
  }

  defaultDates = (start, end) => {
    let { startDate, endDate } = buildStartAndEndDate(start || null, end || null);
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    this.startDate = startDate;
    this.endDate = endDate;
  };

  createBaseState = (_startDate, _endDate) => {
    this.defaultDates(_startDate, _endDate);
    const baseState = {
      currCostType: [CostTypes.COST, CostTypes.DISCOUNT],
      currDataState: CostUsageStates.SERVICE,
      currentGroupBy: 'service',
      currPeriodGranLevel: 'month',
      endDate: this.endDate,
      startDate: this.startDate,
    };
    return baseState;
  };

  updateBaseReportParams(objNewStateParams, objNewParams) {
    if (objNewStateParams) {
      this.baseState = { ...this.baseState, ...objNewStateParams };
    }
    if (objNewParams) {
      for (const key in objNewParams) {
        if (this.hasOwnProperty(key)) {
          this[key] = objNewParams[key];
        }
      }
    }
  }

  updateDates = (year, currMonth, currDay, prevMonth) => {
    let { startDate, endDate } = buildStartAndEndDate(
      new Date(year, prevMonth - 1, 1),
      new Date(year, currMonth - 1, currDay),
    );
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    this.updateBaseReportParams({ startDate, endDate });
  };
}
