export function validateNumberInput(val, min, max) {
  let value = val;
  value = value && +value < min ? `${min}` : `${value}`;
  value = value && +value > max ? `${max}` : `${value}`;
  value = +value % 1 !== 0 ? `${parseInt(+value, 0)}` : `${value}`;
  return value;
}

export function isIncludesAll(values) {
  if (!values) {
    return false;
  }
  if (!Array.isArray(values)) {
    // eslint-disable-next-line no-param-reassign
    values = [values];
  }
  return values.some((val) => {
    if (val.value) {
      return val.value.toLowerCase() === 'all';
    }
    return val.toLowerCase() === 'all';
  });
}

export function sortByLabel(arr) {
  return arr?.sort((a, b) => a.label.localeCompare(b.label));
}

export function formatValuesForSelect(values, options) {
  if (!Array.isArray(values)) {
    // eslint-disable-next-line no-param-reassign
    values = [values];
  }
  return values.map((value) => {
    let label = value;
    if (options) {
      options.some((obj) => {
        if (value.toLowerCase() === obj.value.toLowerCase()) {
          // eslint-disable-next-line prefer-destructuring
          label = obj.label;
          return true;
        }
        return false;
      });
    }
    return { value, label };
  });
}
