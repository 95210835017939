import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  ENGINE_PROPERTIES,
  getNumericValue,
  RDS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';

const RDS_IOPS_CHANGE_PROPERTIES = {
  MAX_CPU: {
    label: 'Max CPU Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation) => getNumericValue(recommendation.recData?.cpu_max)?.toFixed(2),
  },
  MAX_MEM: {
    label: 'Max Mem Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation) => recommendation.recData?.mem_max,
  },
  MAX_IOPS: {
    label: 'Max IOPS',
    getterFunction: (recommendation) => recommendation.recData?.max_iops,
  },
  PROVISIONED_IOPS: {
    label: 'Provisioned IOPS',
    getterFunction: (recommendation) => recommendation.recData?.provisioned_iops,
  },
  RECOMMENDED_IOPS: {
    label: 'Recommended IOPS',
    getterFunction: (recommendation) => recommendation.recData?.recommended_iops,
  },
};

const RdsIopsChange = ({ recommendation }) => {
  const properties = {
    ...RDS_PROPERTIES,
    ...RDS_IOPS_CHANGE_PROPERTIES,
    ...COST_PROPERTIES,
    ...ENGINE_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const chartsData = [
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'IOPS Performance',
      yAxisLabel: 'IOPS',
      maxDataProperty: recommendation.recData?.iops_max_data,
      avgDataProperty: recommendation.recData?.iops_avg_data,
      percentileDataProperty: recommendation.recData?.iops_p95_data,
      percentile99DataProperty: recommendation.recData?.iops_p99_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'CPU Performance',
      yAxisLabel: 'CPU',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      maxDataProperty: recommendation.recData?.cpu_max_data,
      avgDataProperty: recommendation.recData?.cpu_avg_data,
      percentileDataProperty: recommendation.recData?.cpu_p95_data,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'Memory Performance',
      yAxisLabel: 'Memory',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      maxDataProperty: recommendation.recData?.mem_utilization_max_data,
      avgDataProperty: recommendation.recData?.mem_utilization_avg_data,
      percentileDataProperty: recommendation.recData?.mem_utilization_p95_data,
    },
  ];

  const description = `This RDS instance has a low Provisioned IOPS utilization.
   We recommend optimizing the Provisioned IOPS which will reduce your cost.`;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecComponent = recommendation.resourceId ? (
    <OtherResourceIdRecommendations resourceId={recommendation.resourceId} />
  ) : null;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const actionCommand = recommendation?.recData?.command;
  const commandsList = actionCommand
    ? [{ type: COMMAND_TYPES.CLI, instructions: [{ actionText: '', actionCommand }] }]
    : [];

  const commandComponent = <RecommendationCommand commandsList={commandsList} />;

  const chartsComponent = <RecommendationChartData chartsData={chartsData} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecComponent}
      details={detailsComponent}
      command={commandComponent}
      charts={chartsComponent}
      preferences={preferencesComponent}
    />
  );
};

RdsIopsChange.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default RdsIopsChange;
