import React from 'react';
import PropTypes from 'prop-types';

const NewTabLink = ({ link, displayText }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    {displayText}
  </a>
);

export default NewTabLink;
NewTabLink.propTypes = {
  link: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
};
