import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  OPTIONS_PROPERTIES,
  RESERVED_INSTANCE_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';

const GCP_USAGE_COMMITMENT_PROPERTIES = {
  RECOMMENDED_COMMITMENT: {
    label: 'Recommended Commitment',
    getterFunction: (recommendation) => recommendation?.recData?.recommended_commitment,
  },
  COMMITMENT_TYPE: {
    label: 'Recommended Type',
    getterFunction: (recommendation) => recommendation?.recData?.commitment_type,
  },
  COMMITMENT_PLAN: {
    label: 'Recommended Plan',
    getterFunction: (recommendation) => recommendation?.recData?.commitment_plan,
  },
  INSTANCE_TYPE_MODEL: {
    label: 'Instance Type Model',
    getterFunction: (recommendation) => recommendation?.recData?.instance_type_model,
  },
  RAM_SIZE: {
    label: 'RAM Size',
    isGBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.ram_size,
  },
  NUM_OF_CPU: {
    label: 'Number of CPUs',
    getterFunction: (recommendation) => recommendation?.recData?.vcpus,
  },
};

const GcpUsageCommitment = ({ recommendation }) => {
  const riProperties  = {
    RI_TYPE: { ...RESERVED_INSTANCE_PROPERTIES.RI_TYPE, label: 'Current CUD Type' },
    RECOMMENDED_RI_TYPE: { ...RESERVED_INSTANCE_PROPERTIES.RECOMMENDED_RI_TYPE, label: 'Recommended CUD Plan'},
    EXPECTED_ROI_PERIOD: { ...RESERVED_INSTANCE_PROPERTIES.EXPECTED_ROI_PERIOD},
}
  const properties = {
    ...BASE_PROPERTIES,
    ...COST_PROPERTIES,
    ...riProperties,
    ...GCP_USAGE_COMMITMENT_PROPERTIES,
  };

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
           
          actionCommand: `gcloud compute commitments create COMMITMENT_NAME  --region ${recommendation.region} --resources vcpu=${recommendation?.recData?.vcpus},memory=${recommendation?.recData?.ram_size}GB --plan ${recommendation?.recData?.commitment_plan} --type ${recommendation?.recData?.commitment_type}`,
        },
      ],
    },
  ];
  const description = 'We recommend to purchase committed use contract to reduce cost';

  const CURRENT_PROPERTIES = [properties.RI_TYPE, properties.CURRENT_ANNUAL_COST];

  const ALTERNATIVE_PROPERTIES = [
    properties.RECOMMENDED_RI_TYPE,
    properties.RECOMMENDED_ANNUAL_COST,
    OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
  ];

  const topThreeAlternatives = useMemo(() => {
    let alternatives = [];
    if (Array.isArray(recommendation?.recData?.alternatives)) {
      alternatives = recommendation?.recData?.alternatives?.slice(0, 3);
    }
    return alternatives;
  }, [recommendation]);

  const optionsPanelComponent =
    Array.isArray(topThreeAlternatives) && topThreeAlternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        recommendation={recommendation}
      />
    ) : null;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const commandComponent = <RecommendationCommand commandsList={commandsList} />;
  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const breakEvenChartComponent = (
    <RecommendationChartData
      chartsData={[
        {
          chartTitle: 'Break Even point',
          recommendation,
          chartType: CHART_TYPES.BREAK_EVEN,
        },
      ]}
    />
  );

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      optionsPanel={optionsPanelComponent}
      details={detailsComponent}
      command={commandComponent}
      charts={breakEvenChartComponent}
      preferences={preferencesComponent}
    />
  );
};

GcpUsageCommitment.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default GcpUsageCommitment;
