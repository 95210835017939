import React, { useState, useEffect, useRef, ReactNode, HTMLProps, RefObject } from 'react';
import { EXTRA_ROWS_TO_VIRTUALISE } from '../constants.ts';

interface VirtualScrollingProps extends Omit<HTMLProps<HTMLDivElement>, 'children'> {
  children: (start: number, end: number) => ReactNode;
  className: string;
  rowHeight: number;
  rowsDataLength: number;
  bodyWrapperRef: RefObject<HTMLDivElement>;
}
const VirtualScrollingList: React.FC<VirtualScrollingProps> = ({
  rowHeight,
  rowsDataLength,
  children,
  className,
  bodyWrapperRef,
  ...divProps
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const visibleContainerRef = useRef<HTMLDivElement>(null);
  const [visibleContainerHeight, setVisibleContainerHeight] = useState<number | null>(null);
  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState<number | null>(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateWindowHeight = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateWindowHeight);
    return () => {
      window.removeEventListener('resize', updateWindowHeight);
    };
  }, []);

  useEffect(() => {
    if (visibleContainerRef.current) {
      const handleScroll = () => {
        setScrollTop(visibleContainerRef?.current?.scrollTop || 0);
      };
      visibleContainerRef?.current?.addEventListener('scroll', handleScroll);
      return () => {
        visibleContainerRef?.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (bodyWrapperRef?.current && bodyWrapperRef.current?.offsetTop && windowHeight) {
      const height = windowHeight - bodyWrapperRef.current?.offsetTop;
      setVisibleContainerHeight(height);
      setNumberOfVisibleItems(Math.floor(height / rowHeight) + 1);
    }
  }, [windowHeight, bodyWrapperRef?.current?.offsetTop, rowHeight]);

  useEffect(() => {
    if (bodyWrapperRef.current?.offsetTop && windowHeight) {
      const height = windowHeight - bodyWrapperRef.current?.offsetTop;
      setVisibleContainerHeight(height);
      setNumberOfVisibleItems(Math.floor(height / rowHeight) + 1);
    }
  }, [windowHeight, bodyWrapperRef?.current?.offsetTop, rowHeight]);

  const start = Math.max(0, Math.floor(scrollTop / rowHeight) - EXTRA_ROWS_TO_VIRTUALISE);
  const end = Math.min(rowsDataLength, start + (numberOfVisibleItems || 0) + EXTRA_ROWS_TO_VIRTUALISE);

  return (
    <div
      className={className}
      ref={visibleContainerRef}
      style={{
        height: 'fit-content',
        maxHeight: visibleContainerHeight ? visibleContainerHeight - 70 : 0,
      }}
      {...divProps}
    >
      <div
        style={{
          height: rowsDataLength * rowHeight,
          paddingTop: start * rowHeight,
          boxSizing: 'border-box',
        }}
      >
        {children?.(start, end)}
      </div>
    </div>
  );
};

export default VirtualScrollingList;
