import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/usage/idle-instances';

const getIdleInstancesCriteria = () => API.get('billings', `${root}/criteria`);

const createIdleInstancesCriteria = (body) => API.post('billings', `${root}/criteria`, { body });
const deleteIdleCriteria = (id) => API.del('billings', `${root}/criteria?criteriaId=${id}`);

const updateIdleCriteriaRule = (body) => API.put('billings', `${root}/criteria`, { body });

const getIdleInstancesTableData = async (body) => {
  const linkedAccounts = body.linkedAccounts.map((la) => `&linkedAccounts=${la}`).join('');
  const criteriaIds = body.criteriaIds ? body.criteriaIds.map((cn) => `&criteriaIds=${cn}`).join('') : '';
  const apiQuery = `${root}/data?startDate=${body.startDate}&endDate=${body.endDate}${linkedAccounts}${criteriaIds}`;
  const result = await API.get('billings', apiQuery);
  return result;
};

const getIdleInstanceRowData = (criteriaId, instanceId, startDate, endDate) =>
  API.get(
    'billings',
    `${root}/instanceData?criteriaId=${criteriaId}&instanceId=${instanceId}&startDate=${startDate}&endDate=${endDate}`,
  );

export function useIdleInstances() {
  const queryKey = [apiConstants.QUERY_KEYS.IDLE_INSTANCES_CRITERIA];
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries(queryKey);
  };
  return {
    fetchIdleInstancesRowData: (criteriaId, instanceId, startDate, endDate) =>
      useQuery([apiConstants.QUERY_KEYS.IDLE_INSTANCES_ROW, criteriaId, instanceId, startDate, endDate], () =>
        getIdleInstanceRowData(criteriaId, instanceId, startDate, endDate),
      ),
    fetchIdleInstancesTableData: (body) =>
      useQuery(
        [apiConstants.QUERY_KEYS.IDLE_INSTANCES_TABLE, JSON.stringify(body)],
        () => getIdleInstancesTableData(body),
        { enabled: false },
      ),
    fetchIdleInstancesCriteria: () => useQuery(queryKey, () => getIdleInstancesCriteria()),
    useCreateCriteria: () => useMutation(queryKey, createIdleInstancesCriteria, { onSuccess }),
    useDeleteCriteria: () => useMutation(queryKey, deleteIdleCriteria, { onSuccess }),
    useUpdateCriteria: () => useMutation(queryKey, updateIdleCriteriaRule, { onSuccess }),
  };
}
