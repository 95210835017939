import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

const root = '/api/v1/kubernetes';
const queryKey = apiConstants.QUERY_KEYS.KUBERNETES;
const queryKeyWeights = apiConstants.QUERY_KEYS.KUBERNETES_WEIGHTS;

const getCostBreakDownData = async (startDate, endDate, groupBy) => {
  const apiQuery = `${root}/usage/costBreakDown?groupBy=${groupBy}&startDate=${moment(startDate).format(
    'YYYY-MM-DD',
  )}&endDate=${moment(endDate).format('YYYY-MM-DD')}`;
  return API.get('billings', apiQuery);
};

const formatWeightsData = (weight) => ({
  cpu: weight.cpu || 0,
  memory: weight.memory || 0,
  network: weight.network || 0,
  instance: weight.instance.value,
});
const fetchWeightsData = async () => API.get('billings', `${root}/weights`);
const addWeightsData = async (weightsParams) => {
  const body = { ...formatWeightsData(weightsParams) };
  return API.post('billings', `${root}/weights`, { body });
};
const deleteWeightsData = async (id) => API.del('billings', `${root}/weights/${id}`);
const updateWeightsData = async ({ currEditedId, weightsParams }) => {
  const body = {
    id: currEditedId,
    accId: weightsParams.accountId,
    ...formatWeightsData(weightsParams),
  };
  return API.put('billings', `${root}/weights`, { body });
};

export const useKubernetes = (startDate, endDate, groupBy) => {
  const queryClient = useQueryClient();
  return {
    fetchCostBreakDownData: () =>
      useQuery([queryKey, startDate, endDate, groupBy], () => getCostBreakDownData(startDate, endDate, groupBy)),
    fetchWeightsData: () => useQuery([queryKey, queryKeyWeights], fetchWeightsData),
    addWeightsData: () =>
      useMutation(addWeightsData, {
        onSuccess: () => queryClient.invalidateQueries([queryKey, queryKeyWeights]),
        onError: () => {
          toast.error('Error adding weights');
        },
      }),
    updateWeightsData: () =>
      useMutation(updateWeightsData, {
        onSuccess: () => queryClient.invalidateQueries([queryKey, queryKeyWeights]),
        onError: () => {
          toast.error('Error updating weights');
        },
      }),
    deleteWeightsData: () =>
      useMutation(deleteWeightsData, {
        onSuccess: () => queryClient.invalidateQueries([queryKey, queryKeyWeights]),
        onError: () => {
          toast.error('Error deleting weights');
        },
      }),
  };
};
