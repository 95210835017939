import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { IntegratedSorting, RowDetailState, SortingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableRowDetail,
  TableHeaderRow,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui';
import useTable from 'shared/hooks/customHooks/useTable';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import moment from 'moment';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import Spinner from 'shared/components/andtComponents/Spinner';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as BillingRulesEmptyState } from 'shared/img/icons/empty-billing-rules.svg';
import AddBillingRuleModal from './components/AddBillingRuleModal';
import BillingRuleDetails from './components/BillingRuleDetails';
import styles from './billingRulesNew.module.scss';
import { columnExtensions, getMarginToDisplay, marginTypeToDisplayName } from './billingRulesHelpers';
import Filters from './components/Filters';
import useBillingRules from './hooks/react-query/useBillingRules';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import DeleteBillingRuleModal from './components/DeleteBillingRuleModal';
import TextCopy from 'shared/components/andtComponents/TextCopy';

const EmptyBillingRules = () => (
  <div className={styles.emptyBillingRules}>
    <BillingRulesEmptyState />
    <h4>There are currently no Billing Rules defined</h4>
    <p>Create Billing Rules and impact the costs of your customers.</p>
  </div>
);

const BillingRules = () => {
  const { usersStore } = useRootStore();
  const [billingRuleToEdit, setBillingRuleToEdit] = useState(null);
  const [tableFilters, setTableFilters] = useState({
    isActiveOnly: false,
    startMonth: moment().subtract(3, 'months').format('YYYY-MM'),
    endMonth: moment().add(3, 'months').format('YYYY-MM'),
  });
  const [sorting, setSorting] = useState([]);
  const [isAddBillingRuleModalOpen, setIsAddBillingRuleModalOpen] = useState(false);
  const [billingRuleToDelete, setBillingRuleToDelete] = useState(null);

  const { getBillingRules, deleteBillingRule } = useBillingRules();
  const { data: billingRules, isLoading: isLoadingBillingRules } = getBillingRules();
  const { mutateAsync: handleDeleteBillingRule, isLoading: isDeletingBillingRule } = deleteBillingRule();

  const { NewTableWrapper, NewTableRow } = useTable();
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const columns = [
    { name: 'ruleName', title: 'Rule Name', getCellValue: (row) => row.description.name },
    {
      name: 'customerNames',
      title: 'Customer Names',
      getCellValue: (row) => row.scope.customerNames?.join(', ') || 'All',
    },
    {
      name: 'linkedAccountIds',
      title: usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS ? 'Linked Account IDs' : 'Subscription IDs',
      getCellValue: (row) => row.scope.linkedAccountIds?.join(', ') || 'All',
    },
    { name: 'margin', title: 'Margin', getCellValue: (row) => `${getMarginToDisplay(row.margin)}` },
    { name: 'marginType', title: 'Margin Type', getCellValue: (row) => marginTypeToDisplayName[row.margin.type] },
    {
      name: 'frequency',
      title: 'Frequency',
      getCellValue: (row) => (row.scope.startMonth === row.scope.endMonth ? 'One Time' : 'Recurring'),
    },
    {
      name: 'startMonth',
      title: 'Start Month',
      getCellValue: (row) => moment(row.scope.startMonth).format('MMM YYYY'),
    },
    {
      name: 'endMonth',
      title: 'End Month',
      getCellValue: (row) => moment(row.scope.endMonth).format('MMM YYYY'),
    },
    { name: 'ruleId', title: 'Rule ID', getCellValue: (row) => <TextCopy text={row.ruleId} /> },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row) => {
        if (moment().isAfter(moment(row.scope.endMonth).endOf('month'))) {
          return (
            <span className={styles.expiredStatus}>
              Expired
              <GenerateIcon iconName={ICONS.calendarExclamation.name} />
            </span>
          );
        } else if (moment().isBefore(moment(row.scope.startMonth).startOf('month'))) {
          return (
            <span className={styles.upcomingStatus}>
              Upcoming
              <GenerateIcon iconName={ICONS.binocularsSolid.name} />
            </span>
          );
        } else {
          return (
            <span className={styles.activeStatus}>
              Active
              <GenerateIcon iconName={ICONS.check.name} />
            </span>
          );
        }
      },
    },
    {
      name: 'edit',
      title: ' ',
      getCellValue: (row) => (
        <Tooltip title="Edit" placement="top">
          <span>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setBillingRuleToEdit(row);
                setIsAddBillingRuleModalOpen(true);
              }}
              isTextButton
              overrideStyles={{ padding: 0, fontSize: '16px' }}
              icon={() => <GenerateIcon iconName={ICONS.pencilLight.name} />}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      name: 'clone',
      title: ' ',
      // eslint-disable-next-line no-unused-vars
      getCellValue: ({ ruleId, ...ruleData }) => (
        <Tooltip title="Clone" placement="top">
          <span>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setBillingRuleToEdit({
                  ...ruleData,
                  description: { ...ruleData.description, name: `${ruleData.description.name} (Copy)` },
                });
                setIsAddBillingRuleModalOpen(true);
              }}
              isTextButton
              overrideStyles={{ padding: 0, fontSize: '16px' }}
              icon={() => <GenerateIcon iconName={ICONS.cloneSolid.name} />}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      name: 'delete',
      title: ' ',
      getCellValue: (billingRule) => (
        <Tooltip title="Delete" placement="top">
          <span>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setBillingRuleToDelete(billingRule);
              }}
              isTextButton
              isLoading={isDeletingBillingRule}
              overrideStyles={{ padding: 0, fontSize: '16px' }}
              icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} />}
            />
          </span>
        </Tooltip>
      ),
    },
  ];

  const billingRulesToDisplay = billingRules?.filter((row) => {
    if (tableFilters.ruleIds?.length && !tableFilters.ruleIds.some((f) => f.value === row.ruleId)) {
      return false;
    }
    if (
      tableFilters.customerNames?.length &&
      !tableFilters.customerNames.some((f) => row.scope.customerNames.includes(f.value))
    ) {
      return false;
    }
    if (
      tableFilters.linkedAccountIds?.length &&
      !tableFilters.linkedAccountIds.some((f) => row.scope.linkedAccountIds.includes(f.value))
    ) {
      return false;
    }
    if (
      tableFilters.frequencies?.length &&
      !tableFilters.frequencies.some(
        (f) => f.value === (row.scope.startMonth === row.scope.endMonth ? 'One Time' : 'Recurring'),
      )
    ) {
      return false;
    }
    if (tableFilters.marginTypes?.length && !tableFilters.marginTypes.some((f) => f.value === row.margin.type)) {
      return false;
    }
    if (tableFilters.startMonth && row.scope.endMonth < tableFilters.startMonth) {
      return false;
    }
    if (tableFilters.endMonth && row.scope.startMonth > tableFilters.endMonth) {
      return false;
    }
    const isBillingRuleActive = moment().isBetween(
      moment(row.scope.startMonth).startOf('month'),
      moment(row.scope.endMonth).endOf('month'),
    );
    return !tableFilters.isActiveOnly || isBillingRuleActive;
  });

  const fetchCSVData = () => [
    {
      data: billingRules.map((rule) => ({
        ruleId: rule.ruleId,
        ruleName: rule.description.name,
        description: rule.description.description,
        customerNames: rule.scope.customerNames.join(', '),
        linkedAccountIds: rule.scope.linkedAccountIds.join(', '),
        margin: getMarginToDisplay(rule.margin),
        marginType: marginTypeToDisplayName[rule.margin.type],
        frequency: rule.scope.startMonth === rule.scope.endMonth ? 'One Time' : 'Recurring',
        startMonth: moment(rule.scope.startMonth).format('MMM YYYY'),
        endMonth: moment(rule.scope.endMonth).format('MMM YYYY'),
        status: moment().isAfter(moment(rule.scope.endMonth).endOf('month'))
          ? 'Expired'
          : moment().isBefore(moment(rule.scope.startMonth).startOf('month'))
          ? 'Upcoming'
          : 'Active',
        filters: JSON.stringify(rule.filters)?.replace(/"/g, "'"),
      })),
      filename: 'billing_rules.csv',
    },
  ];

  if (isLoadingBillingRules) {
    return <Spinner />;
  }

  return (
    <Container>
      <PageHeader title={PageNames.BILLING_RULES_NEW} />
      <TableHeaderProvider>
        <TableHeader
          titleComponent={
            <div className={styles.titleComponent}>
              <h4>
                <span>
                  {billingRulesToDisplay?.length}/{billingRules?.length}
                </span>{' '}
                Billing Rules
              </h4>
              <Checkbox
                className={styles.activeCheckbox}
                text="Active only"
                isChecked={tableFilters.isActiveOnly}
                primary
                onChange={() => setTableFilters({ ...tableFilters, isActiveOnly: !tableFilters.isActiveOnly })}
              />
            </div>
          }
          expanded={expandedRowIds.length > 0}
          onCreate={() => setIsAddBillingRuleModalOpen(true)}
          setExpanded={(shouldExpand) => setExpandedRowIds(shouldExpand ? billingRules.map((_, i) => i) : [])}
          fetchCsvData={fetchCSVData}
          tableName="Billing Rules"
          includeDetails
          hideSearch
          isInline
        />
      </TableHeaderProvider>
      <Filters filters={tableFilters} setFilters={setTableFilters} billingRules={billingRules || []} />
      {!billingRulesToDisplay?.length ? (
        <EmptyBillingRules />
      ) : (
        <NewTableWrapper>
          <Grid rows={billingRulesToDisplay} columns={columns}>
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
            <SortingState sorting={sorting} columnExtensions={columnExtensions} onSortingChange={setSorting} />
            <IntegratedSorting columnExtensions={columnExtensions} />
            <Table
              rowComponent={(props) => (
                <NewTableRow
                  setExpandedRowIds={setExpandedRowIds}
                  expandedRowIds={expandedRowIds}
                  {...props}
                  className={
                    moment().isAfter(moment(props.row.scope.endMonth).endOf('month')) && styles.expiredBillingRule
                  }
                />
              )}
              columnExtensions={columnExtensions}
            />
            <TableRowDetail
              contentComponent={({ row }) => (
                <BillingRuleDetails
                  billingRule={row}
                  onEdit={() => {
                    setBillingRuleToEdit(row);
                    setIsAddBillingRuleModalOpen(true);
                  }}
                />
              )}
            />
            <TableColumnResizing resizingMode="nextColumn" defaultColumnWidths={columnExtensions} columnExtensions={columnExtensions} />
            <DragDropProvider />
            <TableColumnReordering defaultOrder={columns.map((c) => c.name)} />
            <TableHeaderRow
              messages={{ sortingHint: '' }}
              contentComponent={({ column, children, ...restProps }) => (
                <TableHeaderRow.Content column={column} {...restProps}>
                  {children}
                </TableHeaderRow.Content>
              )}
              showSortingControls
            />
          </Grid>
        </NewTableWrapper>
      )}
      {isAddBillingRuleModalOpen && (
        <AddBillingRuleModal
          billingRuleToEdit={billingRuleToEdit}
          onClose={() => {
            setIsAddBillingRuleModalOpen(false);
            setBillingRuleToEdit(null);
          }}
        />
      )}
      <DeleteBillingRuleModal
        isOpen={billingRuleToDelete}
        onClose={() => setBillingRuleToDelete(null)}
        onDelete={() => handleDeleteBillingRule(billingRuleToDelete.ruleId)}
        title="Delete Billing Rule"
        content={`
          Are you sure you want to delete the billing rule ${billingRuleToDelete?.description.name}?`}
      />
    </Container>
  );
};

export default BillingRules;
