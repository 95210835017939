/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this,arrow-parens */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureReservedCapacityRecommendation } from 'recommendations/models/recommTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';
import { calcNumOfMonthsToBreakEven } from 'shared/utils/calcUtil';

export default class AzureReservedCapacityRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureReservedCapacityRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_RESERVED_CAPACITY;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = action ? `${action} reserved instance` : 'Buy reserved instance';
  }

  build() {
    super.build();
    const {
      quantity,
      sku_name: skuName,
      sku_display_name: skuDisplayName,
      account_id: accountId,
      yearly_cost_current: currYearlyCost,
      yearly_cost_recommended: recommYearlyCost,
      total_cost_recommended: recommMonthlyCost,
      total_cost_current: currMonthlyCost,
      recommended_plan: recommPlan,
      annual_savings_value: savingAmount,
      // action,
      zone_tag: zoneTag,
      scope,
      service,
    } = this._rawRecommendation;

    this._recommendation.quantity = parseInt(quantity, 0);

    const yearlyRecommCost = new RecommendationCost(currYearlyCost, recommYearlyCost);
    this._recommendation.breakEvenMonth = calcNumOfMonthsToBreakEven(recommYearlyCost, currYearlyCost);
    this._recommendation.region = zoneTag;
    this._recommendation.skuName = skuName;
    this._recommendation.skuDisplayName = skuDisplayName;
    this._recommendation.yearlyCost = yearlyRecommCost;
    this._recommendation.recommPlan = recommPlan;
    this._recommendation.savingAmount = parseFloat(savingAmount);
    // this._recommendation.action = action;
    this._recommendation.accountId = accountId;
    this._recommendation.scope = scope;
    this._recommendation.service = service;
  }
}
