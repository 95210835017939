import React from 'react';
import {
  useAddFilterGroup,
  useDeleteFilterGroup,
  useFilterGroups,
  useUpdateFilterGroup,
} from 'usage/hooks/react-query/useFilterGroups';

const FilterGroupsContext = React.createContext();

export function withFilterGroupsContextProvider(Component) {
  return function FilterGroupsContextProvider({ ...props }) {
    const { data: filterGroups, isFetching } = useFilterGroups();
    const addFilterGroup = useAddFilterGroup();
    const updateFilterGroup = useUpdateFilterGroup();
    const deleteFilterGroup = useDeleteFilterGroup();
    return (
      <FilterGroupsContext.Provider
        value={{
          filterGroups,
          addFilterGroup,
          updateFilterGroup,
          deleteFilterGroup,
          isLoading:
            isFetching || addFilterGroup.isLoading || updateFilterGroup.isLoading || deleteFilterGroup.isLoading,
        }}
      >
        <Component {...props} />
      </FilterGroupsContext.Provider>
    );
  };
}

export function withFilterGroupsContextConsumer(Component) {
  return function FilterGroupsContextConsumer({ ...props }) {
    return <FilterGroupsContext.Consumer>{(data) => <Component {...data} {...props} />}</FilterGroupsContext.Consumer>;
  };
}
