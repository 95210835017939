/* eslint-disable no-useless-constructor */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RdsVersionUpgradeRecommendation } from 'recommendations/models/recommTypes';
import { RdsInstanceBasicData } from 'recommendations/models/recommCommonTypes';
import RdsCostRecommendationBuilder from 'recommendations/models/builders/rdsCostRecommBuilder';
import { isEmptyArray } from 'shared/utils/arrayUtils';

export default class RdsVersionUpgradeRecommendationBuilder extends RdsCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RdsVersionUpgradeRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.RDS_VERSION_UPGRADE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} RDS Generation Upgrade`;
  }

  build() {
    const {
      zone_tag: region,
      quantity: resourcesQuantity,
      resources,
      db_name: name,
      db_type: type,
      additional_recommended_models: additionalRecModels,
      specs_details_current: specsCurr,
      specs_details_recommended: specsRecomm,
      cpu_util: cpuUtil,
      new_model: newModel,
      instance_type_size: size,
      resource_name: resourceName,
      resource_id: resourceId,
      instance_type_current: instanceType,
      total_cost_recommended: totalCostRecommended,
      current_annual_cost: currentAnnualCost,
      potential_savings: potentialSavings,
      saving_amount: savingAmount,
      engine: engine,
      engine_version: engineVersion,
      storage_type: storageType,
      is_multi_az: isMultiAz,
      ri_coverage: riCoverage,
    } = this._rawRecommendation;

    const recommRdsInstance = new RdsInstanceBasicData(newModel, size, name, type, region, '');
    this._recommendation.cpuUtil = cpuUtil;
    this._recommendation.region = region;
    this._recommendation.recommEc2Instance = recommRdsInstance;
    this._recommendation.resourceId = resourceId;
    this._recommendation.resourcesQuantity = resourcesQuantity;
    this._recommendation.resources = resources;
    this._recommendation.additionalRecModels = additionalRecModels;
    this._recommendation.specsCurr = specsCurr;
    this._recommendation.specsRecomm = specsRecomm;
    this._recommendation.resourceName = resourceName;
    this._recommendation.resourceId = resourceId;
    // old version of this recommendation contains resources as array
    this._recommendation.separateResource = resourceId && resourceName && isEmptyArray(resources);
    this._recommendation.totalCostRecommended = totalCostRecommended;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.currentAnnualCost = currentAnnualCost;
    this._recommendation.savingAmount = savingAmount;
    this._recommendation.instanceType = instanceType;
    this._recommendation.engine = engine;
    this._recommendation.engineVersion = engineVersion;
    this._recommendation.storageType = storageType;
    this._recommendation.isMultiAz = isMultiAz;
    this._recommendation.riCoverage = riCoverage;

    super.build();
  }
}
