import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useEventsChartBlock from 'shared/components/events/EventsChartBlock';

export function withCUEEventsChartBlockProvider(Component) {
  function CUEGoalsContextProvider({ ...props }) {
    const { isEventsOpen, startDate, endDate, data, onEventCollapse, granLevel } = props;
    const [dataStartDate, setDataStartDate] = React.useState(startDate);
    const [dataEndDate, setDataEndDate] = React.useState(endDate);
    useEffect(() => {
      setDataEndDate(endDate);
      setDataStartDate(startDate);
    }, [JSON.stringify(data)]);
    const [eventsComponents] = useEventsChartBlock({
      enabled: isEventsOpen,
      startDate: dataStartDate,
      endDate: dataEndDate,
      granLevel,
      onEventCollapse,
    });
    return <Component {...props} renderEventsComponents={eventsComponents} />;
  }
  CUEGoalsContextProvider.propTypes = {
    data: PropTypes.array.isRequired,
    isEventsOpen: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    onEventCollapse: PropTypes.func.isRequired,
    granLevel: PropTypes.string.isRequired,
  };
  return CUEGoalsContextProvider;
}
