import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import Button from 'shared/components/andtComponents/Button';
import OnboardingLinks from 'app/containers/Onboarding/utils/OnboardingLinks';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Input from 'shared/components/andtComponents/Input';
import SharedAccountImg from 'app/containers/Onboarding/AwsOnboarding/pages/AccountDetailsModal/assets/shared-img.png';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import styles from './AccountType.module.scss';

const AccountType = () => {
  const { usersStore } = useRootStore();
  const currentAccount = usersStore.getCurrDisplayedAccount;
  const {
    autoAssignLinkedAccounts: currentAutoAssignLinkedAccounts,
    resellerAccountType,
    isCustomerSelfManaged,
    excludedLinkedAccountMatch: currentExcludedLinkedAccountMatch,
  } = currentAccount;
  const [accountType, setAccountType] = useState(resellerAccountType);
  const [autoAssignLinkedAccounts, setAutoAssignLinkedAccounts] = useState(currentAutoAssignLinkedAccounts);
  const [excludedLinkedAccountMatch, setExcludedLinkedAccountMatch] = useState(currentExcludedLinkedAccountMatch);
  const handleSave = useMutation(
    async () =>
      usersStore.updateResellerAccountConfig({ accountType, autoAssignLinkedAccounts, excludedLinkedAccountMatch }),
    {
      onSuccess: () => {
        toast.success('Changes saved.');
      },
    },
  );
  const anyFieldChanged = useMemo(
    () =>
      accountType !== resellerAccountType ||
      +autoAssignLinkedAccounts !== +currentAutoAssignLinkedAccounts ||
      excludedLinkedAccountMatch !== currentExcludedLinkedAccountMatch,
    [accountType, autoAssignLinkedAccounts, excludedLinkedAccountMatch],
  );
  return (
    <div className="w-100">
      <div className="mb-4">
        <TableHeaderProvider>
          <TableHeader tableName="Account Type" hideSearch isCreatable={false} />
        </TableHeaderProvider>
      </div>
      <p className={styles.subTitle}>Which type of account do you want?</p>
      <div className={styles.accountTypes}>
        <div
          className={classNames(styles.accountTypeBlock, {
            [styles.active]: accountType === 'dedicated',
            [styles.disabled]: resellerAccountType === 'shared',
          })}
        >
          <div className={styles.accountTypeHeader}>
            <RadioButtonList
              options={[{ value: 'dedicated', label: 'Dedicated Account', primary: true }]}
              value={accountType}
              onChange={(val) => {
                setAccountType(val);
              }}
            />
          </div>
          <div className={styles.accountTypeBody}>
            <p className={styles.description}>
              This is for a single customer. If this is not the case, please select <span>Shared Account</span>.{' '}
              <Button
                onClick={() => window.open(OnboardingLinks.AWS.customerType, '_blank')}
                text="Learn more"
                isTextButton
                overrideStyles={{ paddingLeft: 0, height: 20 }}
              />
            </p>
            <div className={styles.customerInfo} data-disabled={accountType !== 'dedicated'}>
              <Checkbox
                primary
                className={styles.checkbox}
                isChecked={!!autoAssignLinkedAccounts}
                isDisabled={isCustomerSelfManaged}
                onChange={() => {
                  setAutoAssignLinkedAccounts(!autoAssignLinkedAccounts);
                  if (autoAssignLinkedAccounts) {
                    setExcludedLinkedAccountMatch('');
                  }
                }}
                text="Auto assign linked accounts to the customer"
              />
              {!!autoAssignLinkedAccounts && (
                <div>
                  <label className={styles.excludeLinkedAccountsLabel} htmlFor="customer-name">
                    Excluded linked accounts
                    <span>
                      Define the pattern for linked accounts that are excluded from the auto-assignment process of
                      accounts to customers. You can use ‘*’ as a wildcard in this pattern.
                      <br />
                      For example: *SP*. (to exclude any linked accounts with SP in their name)
                    </span>
                  </label>
                  <Input
                    name="customer-excluded-linked-accounts"
                    value={excludedLinkedAccountMatch}
                    placeholder="Type pattern"
                    onChange={(e) => {
                      setExcludedLinkedAccountMatch(e.target.value);
                    }}
                    className={styles.input}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classNames(styles.accountTypeBlock, { [styles.active]: accountType === 'shared' })}>
          <div className={styles.accountTypeHeader}>
            <RadioButtonList
              options={[{ value: 'shared', label: 'Shared Account', primary: true }]}
              value={accountType}
              onChange={(val) => {
                setAccountType(val);
                setAutoAssignLinkedAccounts(false);
                setExcludedLinkedAccountMatch('');
              }}
            />
          </div>
          <div className={styles.accountTypeBody}>
            <p>
              This is for multiple customer, every linked account must be manually assigned to the relevant customer.
            </p>
            <Button
              onClick={() => window.open(OnboardingLinks.AWS.customerType, '_blank')}
              text="Learn more"
              isTextButton
              overrideStyles={{ paddingLeft: 0, height: 20 }}
            />
            <img src={SharedAccountImg} className={styles.accountTypeIllustration} alt="illustration" />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          disabled={!anyFieldChanged}
          onClick={handleSave.mutate}
          isLoading={handleSave.isLoading}
          overrideStyles={{ width: 85 }}
          text="Save"
        />
      </div>
    </div>
  );
};

export default observer(AccountType);
